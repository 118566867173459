<template>
  <transition name="modal">
    <modal-window
        @close="cancel"
        @success="confirm"
        ok-button-style="btn-primary"
        modal-dialog-class="subnet-setlot-modal"
    >
      <template v-slot:header>
        <h5 class="modal-title">Продажа сети</h5>
      </template>
      <template v-slot:body>
        <div v-if="value === true">
          Вы точно хотите выставить сети тикета на продажу?

          <div class="d-flex align-content-center align-items-center mt-1">
            Дата окончания:

            <date-picker v-model="date"
                         :is24hr="true"
                         :min-date="minDate"
                         mode="datetime"
                         locale="ru"
                         class="ps-1"
                         ref="datepicker"
                         @update:model-value="setDate"
                         @dayclick="formatMinutes"
                         is-required>
              <template v-slot="{ inputValue, inputEvents }">
                <input
                    ref="editInput"
                    class="form-control"
                    :class="{'is-invalid': !isValidDate()}"
                    @click="inputEvents.click"
                    @focusin="inputEvents.focusin"
                    @focusout="inputEvents.focusout"
                    v-model="dateProxy"
                />
              </template>
            </date-picker>
          </div>
        </div>
        <div v-else>
          Вы точно хотите снять с продажи все сети тикета?
        </div>
      </template>
      <template v-slot:footer>
        <div class="double-buttons-group w-100">
          <div>
            <button type="button" class="btn btn-secondary close" @click="cancel">Отмена</button>
          </div>
          <div>
            <button type="button" class="btn btn-primary save" :disabled="disabled" @click="confirmAllSubnets" ref="submit">{{value ? 'Выставить':'Снять' }}</button>
          </div>
        </div>
      </template>
    </modal-window>
  </transition>
</template>

<script>
import ModalWindow from "../../../../ModalWindow";
import EditableDate from "../../../../../../Editable/EditableDate.vue";
import moment from "moment/moment";
import {DatePicker} from "v-calendar";

export default {
  name: "SetLotModal",

  components: {
    DatePicker,
    EditableDate,
    ModalWindow,
  },

  props: {
    subnet: {
      type: String,
      require: true,
    },
    value: {
      default: false,
    }
  },

  emits: [
    'cancel',
    'confirm',
    'confirmAllSubnets',
  ],

  data() {
    return {
      date: moment().add(1, 'month').toDate(),
      incorrectProxyDateValue: null,
      format: 'DD.MM.YYYY HH:mm',
      minDate: moment().add(1, 'day').toDate()
    }
  },

  mounted() {
    this.$refs.submit.focus();
  },

  computed: {
    dateProxy: {
      get() {
        let dateProxy;
        if(this.incorrectProxyDateValue !== null) {
          dateProxy = this.incorrectProxyDateValue;
        } else {
          dateProxy = moment(this.date).isValid() ? moment(this.date).format(this.format) : this.date;
        }
        return dateProxy;
      },
      set(value) {
        if(value.match(/\d{2}\.\d{2}\.\d{4}\s\d{2}:\d{2}/) && moment(value, this.format).isValid()) {
          this.incorrectProxyDateValue = null;
          this.date = moment(value, this.format).toDate();
        } else {
          this.incorrectProxyDateValue = value;
        }
      }

    },

    disabled() {
      return this.value && !this.isValidDate();
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');
    },
    confirmAllSubnets() {
      this.$emit('confirmAllSubnets', this.value ? moment(this.date).format() : null);
    },

    isValidDate(date = null) {
      date = date ?? this.date;

      return (
          this.incorrectProxyDateValue === null &&
          moment(date).isValid() &&
          (moment(date) >= moment().add(1, 'day'))
      );
    },

    setDate() {
      this.incorrectProxyDateValue = null;
    },

    formatMinutes() {
      let date = moment(this.date);
      // если выбранная дата - завтра
      if(moment().add(1, 'day').format('yMD') === date.format('yMD')) {
        date = date.add(date.minutes() > 55 ? 2 : 1, 'hours').minutes(0);
        this.date = date.toDate();
      }
    }
  }
}
</script>

<style scoped>

</style>