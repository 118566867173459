<template>
  <div  :class="[{'position-absolute': absolute}, customDivClass]" style="position: relative" :style="absolute ? 'width: 260px;' : ''" v-if="asInput" ref="group">
    <label v-if="showLabel" for="price-input" class="col-sm-2 col-form-label w-auto pe-2">{{label}}:</label>
    <input type="text"  :class="[!isValid ? 'is-invalid': '', inputClass, formControl]"
           @keyup.esc.stop="cancelForm" @keyup.enter.stop="save" id="price-input" @input="inputPrice" v-model="proxy.price" ref="input">
    <select  v-if="!onePrice" @keyup.esc.stop="cancelForm" @keyup.enter.stop="save" :class="customSelectClass"  @change="changeQuantity" v-model="proxy.quantity">
      <option value="single">За IP</option>
      <option value="all">Всё</option>
   </select>
  </div>
  <div v-else @click="">
    <template v-if="showLabel">{{label}}: </template>
    <span :class="priceClass" :role="role" @dblclick="showForm">
      <template v-if="prices && onePrice" >
         <popper :disabled="disabledPopper(prices?.all)" class="light" :placement="popperPlacement" :hover="true" :interactive="false" :arrow="true">
          <template v-if="prices.single !== null">
            &nbsp;<span :class="contentClass" class="nobr" v-price-clipboard.byContent="clipboard"
                        :content="prices.allNumber"  >{{prices.all}}</span>
          </template>
          <template v-else>
            <span :class="contentClass" class="nobr"
                  v-price-clipboard.byContent="clipboard"  :content="prices.allNumber"
            >{{prices.all}}</span>
          </template>
          <template  v-if="!isHasDate" #content>
            <div v-if="getCurrencyPriceArray(rawPrices.all).length">
              <div v-for="price in getCurrencyPriceArray(rawPrices.all)" class="nobr">
                {{ price }}
              </div>
            </div>
            <div v-else>...</div>
          </template>
          <template  v-if="isHasDate" #content>
            <div v-if="getCurrenciesArrayWithDate(rawPrices.all).length">
              <div v-for="price in getCurrenciesArrayWithDate(rawPrices.all)" class="nobr">
                <span v-if="price.date" >{{ price.value }}<span > ({{price.rateValue}} {{ price.type }} на {{
                    prepareDate(price.date)
                  }})</span></span>
                 <span v-else>{{ price.value }} <span v-if="price.text">({{price.text}})</span></span>
              </div>
            </div>
            <div v-else>...</div>
          </template>
        </popper>
      </template>
      <template v-else-if="prices && !onePrice">
        <popper v-show="prices.single !== null" :disabled="disabledPopper(prices?.single)" class="light" :placement="popperPlacement" :hover="true" :interactive="false" :arrow="true">
          <template v-if="isHasDate" #content>
            <div v-if="getCurrenciesArrayWithDate(rawPrices.single).length">
              <div v-for="price in getCurrenciesArrayWithDate(rawPrices.single)" class="nobr">
                <span v-if="price.date" >{{ price.value }}<span> ({{price.rateValue}} {{ price.type }} на {{
                    prepareDate(price.date)
                  }})</span>
                </span>
                <span v-else>{{ price.value }} <span v-if="price.text">({{price.text}})</span></span>
              </div>
            </div>
            <div v-else>...</div>
          </template>
          <template v-if="!isHasDate" #content>
            <div v-if="getCurrencyPriceArray(rawPrices.single).length">
              <div v-for="price in getCurrencyPriceArray(rawPrices.single)" class="nobr">
                {{ price }}
              </div>
            </div>
            <div v-else>...</div>
          </template>
          <span :class="contentClass" class="nobr" v-price-clipboard.byContent="clipboard"
                 :content="prices.singleNumber" >{{prices.single}}</span>
        </popper>
        <popper :disabled="disabledPopper(prices?.all)" class="light" :placement="popperPlacement" :hover="true" :interactive="false" :arrow="true">
          <template v-if="prices.single !== null">
            &nbsp;<span :class="contentClass" class="nobr" v-price-clipboard.byContent="clipboard"
                       :content="prices.allNumber"  >({{prices.all}})</span>
          </template>
          <template v-else>
            <span :class="contentClass" class="nobr"
                  v-price-clipboard.byContent="clipboard"  :content="prices.allNumber"
            >{{prices.all}}</span>
          </template>
          <template  v-if="!isHasDate" #content>
            <div v-if="getCurrencyPriceArray(rawPrices.all).length">
              <div v-for="price in getCurrencyPriceArray(rawPrices.all)" class="nobr">
                {{ price }}
              </div>
            </div>
            <div v-else>...</div>
          </template>
          <template v-if="isHasDate" #content>
            <div v-if="getCurrenciesArrayWithDate(rawPrices.all).length">
              <div v-for="price in getCurrenciesArrayWithDate(rawPrices.all)" class="nobr">
                <span v-if="price.date" >{{ price.value }}<span > ({{price.rateValue}} {{ price.type }} на {{
                    prepareDate(price.date)
                  }})</span></span>
                <span v-else>{{ price.value }} <span v-if="price.text">({{price.text}})</span></span>
              </div>
            </div>
            <div v-else>...</div>
          </template>
        </popper>
      </template>
      <template v-else>{{this.default}}</template>
    </span>
  </div>
</template>

<script>
import moment from "moment";
import {Form} from "./Form";
import {PricePrettyView} from "../../../../../../library/PricePrettyView";
import {PriceCalc} from "./PriceCalc";
import getComputedRates from "../../../../../common/Rates/Computeds";
import {showAbsoluteInput} from "../../../../../Editable/InputAbsolute";

export default {
  name: "Price",

  emits: ['update'],

  props: {
    label:{
      default:'Цена',
      type:String,
    },
    notEmptyField:{
      default:false,
    },
    focusOutCancel:{
      type:Boolean,
      default:true,
    },
    recalculatePrice:{
      type:Boolean,
      default:true,
    },
    currencyToShow:{
      type:String,
      default:null,
    },
    contentClass:{
      default:'',
    },
    price: {
      default: null,
    },
    onePrice:{
      default:false,
    },
    price1: {
      default: null,
    },
    currency: {
      default: null,
    },
    quantity: {
      default: null,
    },
    subnets: {
      default: null,
    },
    prefix: {
      default: null,
    },
    showLabel: {
      default: true,
    },
    canChange:{
      default:true,
    },
    inputClass:{
      type:String,
      default:'',
    },
    selectClass: {
      type: String,
      default: '',
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    withoutCurrencySpace: {
      type: Boolean,
      default: false,
    },
    ratesData: {
      type: Object,
      default: null,
    },
    spanClass:{
      type:String,
      default:"",
    },
    default: {
      type: String,
      default: '—',
    },
    clipboard: {
      type: Boolean,
      default: false,
    },
    customDivClass:{
      type:String,
      default:'input-group',
    },
    formControl:{
      type:String,
      default:'form-control'
    },
    customSelectClass:{
      type:String,
      default:'form-control p-0 text-center',
    },
    precisionAll:{
      type:Number,
      default:0
    },
    precisionSingle:{
      type:Number,
      default:2
    },
    selectText: {
      type: Boolean,
      default: false,
    },
    popperPlacement: {
      type: String,
      default: 'top',
    },
  },


  setup(props){
    const { rates } = getComputedRates();
    return {rates}
  },

  data: function() {
    return {
      asInput: false,
      isValid: true,
      isHasDate:false,
      proxy: {},

      form: new Form(this),
      fields: ['price', 'price1', 'currency', 'quantity', 'currencyToShow'],

      quantityWasChanged: true,
    }
  },

  mounted() {
    if(!this.focusOutCancel){
      this.form.setSaveByOutClick(true);
    }
    if(this?.ratesData?.['USD']?.date){
      this.isHasDate= true;
    }
    this.setPrice();
  },

  watch:{
    'asInput'(to){
        if(to){
          this.$nextTick(()=>{
            this.$refs.input.focus();
            if(this.selectText) {
              if (this.$refs.input?.setSelectionRange) {
                this.$refs.input.setSelectionRange(0, this.$refs.input.value.length);
              } else {
                this.$refs.input.select();
              }
            }
          });
        }
    },

    'price' () {
      this.setPrice();
    }
  },

  computed: {
    priceCalc: function() {
      return new PriceCalc({
        price: this.emptyStringToNull(this.price),
        price1: this.emptyStringToNull(this.price1),
        currency: this.currency,
        quantity: this.actualQuantity,
        subnets: this.subnets,
        prefix: this.prefix,
      });
    },

    priceClass() {
      return this.spanClass + (this.canChange ? ' text-primary' : '');
    },

    rawPrices: function() {
      return this.priceCalc.getPrices();
    },

    prices: function (){
      let out;
      if(this.currencyToShow === null){
        out = this.priceCalc.getPrettyPrices(this.precisionAll, this.precisionSingle , this.currencyToShow !== 'USD');
      }else {
        let format = (el, precision = 2) => {
          return (el === null) ? '—' : new PricePrettyView(el, this.currencyToShow).getPrettyPrice('—',
              true, precision , this.currencyToShow !== 'USD', this.withoutCurrencySpace ? "" :  " "  );
        }
        let prices =  this.priceCalc.getPrices();
        if(prices !== null){
          prices.all = this.getCurrenciesArray(prices.all)?.[this.currencyToShow];
          prices.single = this.getCurrenciesArray(prices.single)?.[this.currencyToShow];
        }
        let allObject = new PricePrettyView( prices?.all, this.currencyToShow)
        let singleObject = new PricePrettyView( prices?.single, this.currencyToShow)
        out = prices ? {
          allNumber: allObject.getReducePricesWithCurrency('' ,
              2, this.currencyToShow !== 'USD'),
          all:typeof prices.all !=='undefined' && prices.all !== null
              ? format(prices.all, this.precisionAll) : null,
          single: typeof prices.single !=='undefined' && prices.single !== null
              ? format(prices.single) : null,
          singleNumber:  singleObject.getReducePricesWithCurrency('',
              this.precisionSingle, this.currencyToShow !== 'USD'),
        } : null;
      }
      return out;
    },

    inputValue: function() {
      let price = this.price;
      if(this.quantity === 'single' && this.price1 !== null) {
        price = this.price1;
      }

      let string = new PricePrettyView(price, this.currency).getPrettyPrice('—',true);
      return string === '—' ? '' : string;
    },

    countIPs: function() {
      return this.priceCalc.getCountIPs();
    },

    actualQuantity: function (){
      return this.proxy.quantity ?? this.quantity;
    },

    actualRates() {
      let result = null;
      if (typeof this.ratesData !== 'undefined' && this.ratesData !== null) {
        result = {};
        for (let currency in this.ratesData){
          let value = this.ratesData[currency];
          if(value?.value){
            result[currency] = value.value;
          }else {
            result[currency] = value;
          }
        }
      } else if (this.rates !== null) {
        result = {
          "USD": this.rates['USD'].Value,
          "EUR": this.rates['EUR'].Value,
          'EUR/USD': this.rates['EUR/USD'].Value,
        }
      }
      return result;
    },
    role() {
      return this.canChange ? 'button' : '';
    },


  },

  methods: {
    prepareDate(date){
      if(date){
        return moment(date).format("DD.MM.YYYY")
      }
      return ''
    },
    disabledPopper(price) {
      return price === '—';
    },

    setPrice() {
      if(this.$route.name === 'Ticket') {
        this.$store.commit('thread/setPrice', {
          price: this.price,
          price1: this.price1,
          currency: this.currency,
        });
      }
    },

    emptyStringToNull( value ){
      return typeof value === 'string' ? (value.trim() === "" ? null : value ) : value;
    },

    getCurrencyPrice(price) {
      let string = '...';
      let currencies = {}

      if(this.actualRates !== null) {
        price = String(price).replace(" ", "").replace(",")
        switch(this.currency) {
          case 'USD':
            currencies['RUB'] = price * this.actualRates['USD'];
            currencies['EUR'] = currencies['RUB'] / this.actualRates['EUR'];
            break;
          case 'EUR':
            currencies['RUB'] = price * this.actualRates['EUR'];
            currencies['USD'] = currencies['RUB'] / this.actualRates['USD'];
            break;
          case 'RUB':
            ['USD', 'EUR'].forEach(currency => currencies[currency] = price / this.actualRates[currency]);
            break;
        }

        string = Object.entries(currencies).map(el => el = new PricePrettyView(el[1], el[0]).getPrettyPrice('—',true) )
            .join('; ');
      }
      return string;
    },
    getCurrenciesArray(price){
      return  PricePrettyView.getCurrenciesArray(price,this.currency ,  this.actualRates)
    },

    getCurrenciesArrayWithDate(price){
      let result = [];
      let currencies = {};

      if(this.actualRates !== null) {
        price = String(price).replace(" ", "").replace(",", '.')
        let c;
        let currency;
        if(this.currencyToShow){
          currency = this.currencyToShow;
        }else {
          currency = this.currency
        }
        switch(currency) {
          case 'USD':
            c  = PricePrettyView.getCurrenciesArrayWithDate(price, this.currency ,  this.ratesData);

            delete c['USD']
            break;
          case 'EUR':
            c  = PricePrettyView.getCurrenciesArrayWithDate(price, this.currency ,  this.ratesData);
            delete c['EUR']
            break;
          case 'RUB':
            c  = PricePrettyView.getCurrenciesArrayWithDate(price, this.currency ,  this.ratesData);
            delete c['RUB']
            break;
        }

        for (let [key, value] of Object.entries(c)){
          value.value = new PricePrettyView( value.value, key).getPrettyPrice('—',true);
          if(value?.rateValue){
            value.rateValue = value.rateValue.toFixed(4).replace('.' , ',');
          }
          if(this.currency === key){
            value.text = 'валюта сделки'
          }

          result.push(value)
        }
      }

      return result;
    },
    getCurrencyPriceArray(price) {
      let result = [];
      let currencies = {};
      if(this.actualRates !== null) {
        price = String(price).replace(" ", "").replace(",", '.')
        let c;
        let currency;
        if(this.currencyToShow){
          currency = this.currencyToShow;
        }else {
          currency = this.currency
        }
        switch(currency) {
          case 'USD':
            c  = this.getCurrenciesArray(price);
            currencies['RUB'] = c['RUB']
            currencies['EUR'] =  c['EUR']
            break;
          case 'EUR':
            c  = this.getCurrenciesArray(price);
            currencies['RUB'] = c['RUB'];
            currencies['USD'] = c['USD'];
            break;
          case 'RUB':
            c  = this.getCurrenciesArray(price);
            currencies['USD'] = c['USD']
            currencies['EUR'] = c['EUR']
            break;
        }

        result = Object.entries(currencies).map(el => el = new PricePrettyView(el[1], el[0]).getPrettyPrice('—',true));
      }

      return result;
    },


    inputPrice: function() {
      this.isValid = true; // при изменении в поле цены сбарсываем валидацию

      if(!this.quantityWasChanged && this.parsePrice(this.proxy.price) !== false) {
        let currency = this.parseCurrency(this.proxy.price);
        this.proxy.quantity = (this.parsePrice(this.proxy.price) > this.getPriceBetweenSingleAndAllIPs(currency)) ? 'all' : 'single';
      }
    },

    getPriceBetweenSingleAndAllIPs: function(currency) {
      let price = 300;
      if(this.actualRates !== null) {
        switch(currency) {
          case 'EUR':
            price = (price * this.actualRates['USD']) / this.actualRates['EUR'];
            break;
          case 'RUB':
            price = price * this.actualRates['USD'];
            break;
        }
      }

      return price;
    },

    changeQuantity: function() {
      this.quantityWasChanged = true
      if(this.recalculatePrice){
        if(this.countIPs > 0 && this.price !== null) {
          if(this.proxy.quantity === 'single') {
            this.proxy.price = this.clearPrice(this.proxy.price) / this.countIPs;
          } else {
            this.proxy.price = this.clearPrice(this.proxy.price) * this.countIPs;
          }

          this.proxy.price += ' ' + PricePrettyView.getCurrencySymbol(this.proxy.currency);
        }
      }

    },

    clearPrice: function(price) {
      return price.replace(/[^\d,\.]/g, '')
                  .replace(',', '.');
    },

    showForm: function() {
      if(this.canChange){
        this.form.showForm();
        this.proxy.price = this.inputValue;
        if(this.proxy.quantity === null) {
          this.proxy.quantity = 'single';
          this.quantityWasChanged = false;
        }

        if(this.absolute) {
          this.$nextTick(() => {
            showAbsoluteInput( $(this.$refs.group) );
          });
        }
      }

    },

    cancelForm: function() {
      this.form.cancel();
      this.proxy.price = this.price;
      this.proxy.quantity = this.quantity;
    },

    parseCurrency: function(string) {
      return PricePrettyView.parseCurrency(string);
    },
    getUpdated(){
      let updated = null;
      let price = this.proxy.price;
      let currency = this.parseCurrency(price);


      if(this.notEmptyField && price == ''){
        this.isValid = false;
        return updated;
      }

      if(price !== '') {
        price = this.parsePrice(price);
        if(price === false) {
          this.isValid = false;
        }
      }

      let quantity = (price === '' || price === null) ? null : this.proxy.quantity;
      if(this.isValid) {
        updated = {
          price: price,
          currency: currency,
          quantity: quantity,
        }
      }
      return updated;
    },
    save: function() {
      let updated = this.getUpdated();
      if(updated != null){
        this.form.save(updated);
      }

    },

    parsePrice: function(price) {
      price = this.clearPrice(price);
      price = parseFloat(price);

      return Number.isNaN(price)
                ? false
                : price.toFixed(2).replace('.00', '')
    }
  }
}
</script>

<style scoped>

</style>