import mutations from "./mutations";
import actions from "./actions";
import  getters from "./getters";

const state = {
    topLevel:[],
    selectionControl:{
        id:null,
        projectName:null,
        filter:null,
    },
    showAll: false,
}


export const moduleLeftMenu = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters
}