import { createStore, createLogger } from 'vuex'

import commonMutations from "../common/mutations"
import {shareState} from "../common/composition";
import {moduleDocument} from "./composition";

export default createStore({
    modules:{
        document: moduleDocument,
    },
    state:() => (shareState),

    mutations:commonMutations,
    plugins: []
})
