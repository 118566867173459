import {CrmApi} from "../../../library/CrmApi";
import {User} from "../../../library/api/User";


export const initRates = ({commit}, {date}) => {
    return new Promise((resolve, reject) => {
        CrmApi.getRates(date).then(response => {
            commit('setRates', response.data);
        }).finally(()=>{
            resolve();
        });
    });
};


export const updateUserSettings = ({commit, getters}, {settings} ) => {
    return new Promise((resolve , reject) =>{
        let oldSettings = Object.assign({} , getters.getUserSettings);
        commit('updateUserSettings', settings);
        User.updateSettings({settings:settings}).then((result)=>{
            resolve(result)
        }).catch((error)=>{
            commit('setUserSettings', oldSettings);
            reject(error);
        })
    });
}