<template>
  <popper class="light" placement="top" :arrow="true" :hover="true" :interactive="false" >
    <span v-if="changeContentsEvents.length > 0" class="text-muted" >{{text}}</span>
    <template #content>
      <div style="width: max-content;">
        <div v-for="ev in changeContentsEvents">
          <user-date style="white-space: nowrap;" :date="ev.date" :name="ev.params?.user?.name" :login="ev.params?.user?.login" ></user-date>
        </div>
      </div>
    </template>
  </popper>
</template>

<script>
import moment from "moment";
import UserDate from "../../common/userDate";

export default {
  name: "LastEditing",
  components: {UserDate},
  props:{
    comment:{
      type:Object,
      require:true,
    },
    showLastDate: {
      type: Boolean,
      default: false,
    }
  },
  methods:{
    getUser:function (params){
      return params?.user?.name ?? params.user.login;
    },
    getDate:function (date, time = false) {
      return moment(date ).format(time ? 'YYYY-MM-DD HH:mm' : 'DD.MM.YYYY');
    }
  },
  computed:{
    text:function (){
      let text = '';

      if(this.changeContentsEvents.length) {
        text = 'Изменено';

        if(this.changeContentsEvents.length > 1){
          text += ' ' + this.changeContentsEvents.length + ' раз(а)'
        }

        if(this.showLastDate) {
          text += ' ' + this.getDate( this.changeContentsEvents[ this.changeContentsEvents.length - 1 ].date, true );
        }
      }

      return text;
    },
    changeContentsEvents:function (){
      let events = [];
      if(this.comment?.events){
        events =  this.comment.events.filter((el)=> el.type === 'CHANGE_CONTENT' );
      }
      return events;
    }
  }
}
</script>

<style scoped>
.light {
  display: block;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 5px -6px rgba(0, 0, 0, 0.25);
}
.padding-5{
  padding: 5px;
}
</style>