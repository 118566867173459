<template>
  <div v-if="!isEmptyValue" >
    <div  @mouseleave="dragLeave" :style="dialogStyle"    class="template-quick-modal-mask">
      <div  class="template-quick-modal-dialog modal-content">
        <div v-on:mousedown="holdOn($event)"
             v-on:mouseup="holdOff()" class="dialog-header">
          <div class="size-change" style="z-index: 99999" >
            <span  class="close" data-dismiss="alert"  @click="sizeChange()" >o</span>
          </div>
          <div class="close-window" style="z-index: 99999">
            <span class="close" data-dismiss="alert" @click="$emit('close')" >&times;</span>
          </div>
        </div>

        <div class="modal-body" style="overflow: auto; padding: 15px 15px;">
          <slot name="body">
            <div>
              <span  v-html="getMarkdown()" class=" max-width" ref="html-message"></span>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getMarkdown} from "../../ticket/Api/api";
import {mapGetters} from "vuex";

export default {
  name: "MovingModal",
  data:function (){
    return {
      widths:[300, 500 , 800],
      drag:false,
      right: 0,
      bottom: 0,
      zIndex:99,
      currentWidthIndex:0,
      offset:{
        x:0,
        y:0
      }
    }
  },
  beforeUnmount () {
    window.removeEventListener('mousemove', this.doDrag)
  },
  mounted() {
    this.right = 0;
    this.bottom = -23;
    //this.top = window.innerHeight - 400 - 80;
    //this.left = window.innerWidth - this.widths[0] - 40;
    //this.left = window.screen.height + 140;
    // this.top = -200;
    window.addEventListener('mousemove',this.doDrag)
    // this.$el.addEventListener('dragstart', this.dragStart)
    //window.addEventListener('dragover',this.dragOver)
  },
  emits:['close'],
  props:{
    value:{
      type:String,
      default:"",
    }
  },
  computed:{
    isEmptyValue(){
      return this.value.trim() === '';
    },
    dialogStyle () {
      let style = { right: this.right + 'px', bottom: this.bottom + 'px' }
      style.width = this.width + 'px';
      if (this.zIndex) style.zIndex = this.zIndex
      if (this.drag) {
        style['user-select'] = 'none'
        style.cursor ='all-scroll'
      }
      return style
    },
    width(){
      return this.widths[this.currentWidthIndex] ;
    },
  },
  methods:{
    getMarkdown(){
      return getMarkdown(this.value , false);
    },
    sizeChange(){

      this.drag = false;
      let i = this.currentWidthIndex + 1;
      if(typeof this.widths[i] === 'undefined' ){
        i = 0;
      }
      this.currentWidthIndex = i;

    },
    dragOver(event){
      this.doDrag(event);
    },
    dragLeave(event){
      if(event.buttons !== 1){
        this.drag = false;
      }
    },


    dragStart (event) {
      event.dataTransfer.setData('text', event.target.id)
      this.startMove(event)
    },
    holdOn(event){
      this.drag = true;
      this.startMove(event);
    },
    holdOff(){
      this.drag = false;
    },

    doDrag(event){
      if(this.drag){
        let x = -event.clientX;
        let y = -event.clientY;
        this.right = (x + this.offset.x)
        this.bottom = (y + this.offset.y)
      }
    },
    startMove (event) {
      let x = this.right + event.clientX
      let y = this.bottom + event.clientY
      this.offset = { x, y }
    },
  }
}
</script>

<style scoped>
.size-change{
  right: 0px;
  top:0px;
  cursor: pointer;
  font-size: 15px;
  text-align:end;
  height: 1px;
  margin-right: 28px;
  border-color: white;
  border-radius: 1px;
}
.close-window{
  right: 0px;
  top:0px;
  cursor: pointer;
  font-size: 15px;
  text-align:end;
  margin-right: 8px;
  height: 2px;
}

.modal-content{
  background: whitesmoke;
}
.template-quick-modal-dialog{
  max-width: 802px;
  margin: 1.75rem auto;
}
.template-quick-modal-mask {
  position: absolute;

  transition-duration: 500ms;
  transition-property: margin-right;
}
.dialog-header{
  background-color: #1aad8d;
  color: #fff;
  height: 20px;
}
.modal-body{
  height: 400px;
}

</style>