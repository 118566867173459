import {CrmApi} from "../CrmApi";

export {BILLmanager}

class BILLmanager {

    static makeBillmgrInvoice(threadId, request) {
        return CrmApi.call(this.threadLink(threadId) + 'billmgrinvoice', request);
    }

    static getInvoiceMeta(threadId) {
        return CrmApi.call(this.threadLink(threadId) + 'billmgrinvoice/meta');
    }

    static threadLink = threadId => '/thread/' + encodeURIComponent(window.getId(threadId)) + '/';
}