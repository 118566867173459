<template>
  <div v-if="name">
    <value-or-trim :is-need-trim="isScreenSmall" :value="name"></value-or-trim>
    <div v-if="email">
      <value-or-trim :is-need-trim="isScreenSmall" :value="email"></value-or-trim>
    </div>
  </div>
  <div v-else-if="email">
    <value-or-trim :is-need-trim="isScreenSmall" :value="email"></value-or-trim>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ValueOrTrim from "../../../common/ValueOrTrim";

export default {
  name: "Client",
  components: {ValueOrTrim},
  props: {
    offer: {},
  },

  methods: {
    offerPerson() {
      return this.$store.getters['thread/offers/getPersonFromTicket'](this.offer);
    }
  },

  computed: {
    ...mapGetters({
      'isScreenSmall':'isScreenSmall'
    }),
    name() {
      return this.offerPerson().name;
    },

    email() {
      return this.offerPerson().email;
    }
  }
}
</script>

<style scoped>

</style>