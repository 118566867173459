<template>
  <span v-if="keyword.showDeleteBtn" class="btn btn-outline-danger" @click="keyword.delete">
    <i class="fa fa-times"></i>
  </span>
</template>

<script>
export default {
  name: "Delete",

  props: {
    keyword: Object
  },

}
</script>

<style scoped>

</style>