<template>
  <tr v-if="show" :data-type="type">
    <th>
      <div class="row" v-if="type === 'buy'">
        <div class="col-6">
          <filter-input
            id="sell_subnet"
            ref="sell_subnet"
            v-if="getFilterByName('subnet')"
            :object="getFilterByName('subnet')"
            :placeholder="getFilterByName('subnet')?.placeholder"
            :field="getFilterByName('subnet')?.field"
          ></filter-input>
        </div>
        <div class="col-6">
          <filter-select
            id="sell_subnet_type"
            ref="sell_subnet_type"
            v-if="getFilterByName('subnets_type')"
            :object="getFilterByName('subnets_type')"
            :placeholder="getFilterByName('subnets_type')?.placeholder"
            :field="getFilterByName('subnets_type')?.field"
            ></filter-select>
        </div>
      </div>
      <filter-input
          v-if="type === 'sell' && getFilterByName('buy')"
          id="buy_buy"
          ref="buy_buy"
          :object="getFilterByName('buy')"
          :placeholder="getFilterByName('buy')?.placeholder"
          :field="getFilterByName('buy')?.field"
      ></filter-input>
    </th>
    <th>
      <filter-input
          id="price_min"
          ref="price_min"
          v-if="getFilterByName('price')"
          :object="getFilterByName('price')"
          :placeholder="getFilterByName('price')?.placeholder"
          :field="getFilterByName('price')?.field"
      ></filter-input>
    </th>
    <th>
      <filter-input
          id="date"
          ref="date"
          v-if="getFilterByName('date')"
          :object="getFilterByName('date')"
          :placeholder="getFilterByName('date')?.placeholder"
          :field="getFilterByName('date')?.field"
      ></filter-input>
    </th>
    <th>

    </th>
    <th>
      <filter-input
          id="persona"
          ref="persona"
          v-if="getFilterByName('persona')"
          :object="getFilterByName('persona')"
          :placeholder="getFilterByName('persona')?.placeholder"
          :field="getFilterByName('persona')?.field"
      ></filter-input>
    </th>
    <th>
      <filter-input
          id="comment"
          ref="comment"
          v-if="getFilterByName('comment')"
          :object="getFilterByName('comment')"
          :placeholder="getFilterByName('comment')?.placeholder"
          :field="getFilterByName('comment')?.field"
      ></filter-input>
    </th>
  </tr>
</template>

<script>
import {mapGetters} from "vuex";
import FilterInput from "./FilterInput";
import FilterSelect from "./FilterSelect";

export default {
  name: "FilterTable",

  components: {FilterInput, FilterSelect},

  props: {
    type: {
      type: String,
      require: true,
      default: '',
    }
  },

  computed: {
    ...mapGetters({
      show: 'thread/offers/filters/showFilter',
      getFilterByName: 'thread/offers/filters/getFilterByName',
    }),
  },
}
</script>

<style scoped>

</style>