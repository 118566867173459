<template>
  <div class="form-group mb-0" v-if="asInput" @keyup.esc.stop="form.cancel" @keydown.enter.stop="save" ref="group">
    <input type="text" class="form-control" :class="{'is-invalid': !isValid}" @input="isValid = true" v-if="!proxy.oursubnet" v-model="proxy.seller" placeholder="Введите ссылку на тикет" ref="input">
    <div class="form-check">
      <label class="form-check-label" for="oursubnet">Продавать свою сеть(и)</label>
      <input class="form-check-input" type="checkbox" v-model="proxy.oursubnet" @change="changeOurSubnet" id="oursubnet">
    </div>
  </div>
  <div v-else :role="role" class="d-flex" :class="{'flex-column': seller}" @dblclick="showForm">
    Продавец:&nbsp;<span v-if="oursubnet" :class="{'text-decoration-underline': userCanChange}">наша сеть</span>
                   <template v-else-if="sellerTicket.subnets || sellerPerson.trim() !== ''">
                     <popper :content="sellerPopperString" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                       <span :class="{'text-primary': userCanChange || canOpenTicket}" v-single-click="openTicket">
                         <span v-if="sellerTicket.person" class="d-flex text-muted">
                           <string-trim :string="sellerPerson" :show-popper="false"/>
                         </span>

                         <span>
                           <div v-for="subnet in sellerTicket.subnets">
                             {{subnet.subnet}} <span v-if="subnet.type" class="badge" :class="subnet.type === 'PA' ? 'bg-primary' : 'bg-warning'">{{subnet.type}}</span>
                           </div>
                         </span>
                       </span>
                     </popper>
                   </template>

    <span v-else :class="{'text-primary': userCanChange || canOpenTicket}" v-single-click="openTicket" style="display: contents;">{{sellerString}}</span>
  </div>

  <modal-confirm-our-subnet v-if="showOurSubnetModal" @cancel="cancelOurSubnet" @confirm="confirmOurSubnet" />
</template>

<script>
import {CrmApi} from "../../../../../../library/CrmApi";
import {Form} from "./Form";
import StringTrim from "../../../../../string/StringTrim";
import {parseTicketId} from "../../../../../../library/ID";
import ModalConfirmOurSubnet from "./ModalConfirmOurSubnet";

export default {
  name: "Seller",

  components: {ModalConfirmOurSubnet, StringTrim},
  emits: ['update'],

  props: {
    seller: {},
    oursubnet: {
      default: null,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  data: function() {
    return {
      asInput: false,
      isValid: true,

      proxy: {},
      validation: this.isValidId,
      beforeSave: this.bSave,
      afterSave: this.aSave,
      beforeCancel: this.cancelForm,

      ignoreClickElements: ['.modal-our-subnet'],

      sellerDefault: null,

      sellerTicket: {
        _id: null,
        name: null,
        person: null,
        subnets: null,
      },

      showOurSubnetModal: false,

      form: new Form(this),
      fields: ['seller', 'oursubnet'],

      canOpenTicket: false,
    }
  },

  mounted: function() {
    this.sellerDefault = this.sellerTicket;
    this.proxy.oursubnet = this.oursubnet ?? false;
    if(typeof this.seller === 'string') {
      this.getSellerInfo();
    }
  },

  unmounted() {
    if(this.asInput) {
      this.form.cancel();
    }
  },

  computed: {
    sellerString: function() {
      return this.seller ? 'задан' : 'не задан';
    },

    sellerPerson: function() {
      let person = [];
      let hasName = false;

      if(this.seller) {
        if(this.isNonEmptyString(this.sellerTicket?.person?.name)) {
          person.push(this.sellerTicket?.person?.name.trim());
          hasName = true;
        }
        if(this.isNonEmptyString(this.sellerTicket?.person?.email)) {
          let email = this.sellerTicket?.person?.email.trim();
          email = hasName ? '(' + email + ')' : email;
          person.push(email);
        }
      }

      return person.join(' ');
    },

    sellerPopperString: function() {
      let sellerPopperString = "";

      if( this.seller ){

        let name = this.isNonEmptyString(this.sellerTicket?.person?.name) ? this.sellerTicket?.person?.name.trim() : null;
        let email = this.isNonEmptyString(this.sellerTicket?.person?.email) ? this.sellerTicket?.person?.email.trim() : null;

        if( name ){
          sellerPopperString = name + ( email ? " (" + email + ")" : "");
        } else if ( email ) {
          sellerPopperString = email;
        } else {
          sellerPopperString = this.sellerTicket.name;
        }
      }

      return sellerPopperString;
    },

    role() {
      return this.userCanChange || this.canOpenTicket ? 'button' : '';
    },

    confirmedOffer() {
      return this.$store.getters['thread/offers/getConfirmedOffer'];
    }
  },

  methods: {
    changeOurSubnet() {
      if(this.proxy.oursubnet) {
        this.showOurSubnetModal = true;
        this.$store.commit('setActiveWindow', 'ourSubnetModal');
      } else {
        this.$store.dispatch('thread/offers/showSellerOffersTable', () => {
          if(this.$store.getters['thread/offers/getConfirmedOffer'] === null) {
            this.form.cancel();
          }
        });
      }
    },

    cancelOurSubnet() {
      this.showOurSubnetModal = false;
      this.$store.commit('removeActiveWindow', 'ourSubnetModal');
      this.proxy.oursubnet = false;
    },

    confirmOurSubnet() {
      this.showOurSubnetModal = false;
      this.$store.commit('removeActiveWindow', 'ourSubnetModal');
      this.form.save();
    },

    save() {
      if(this.proxy.seller !== this.seller || this.proxy.oursubnet !== this.oursubnet) {
        this.form.save();
      } else {
        this.form.cancel();
      }
    },

    showForm() {
      if(this.userCanChange) {
        this.form.showForm();
        if (this.proxy.oursubnet !== true) {
          this.$store.dispatch('thread/offers/showSellerOffersTable', () => {
            if (this.$store.getters['thread/offers/getConfirmedOffer'] === null) {
              this.form.cancel();
            }
          });
        }
      }
    },

    cancelForm() {
      this.$store.dispatch('thread/offers/closeOffersTable')
    },

    openTicket: function() {
      if (this.canOpenTicket) {
        window.open(window.location.origin + '/tickets/' + window.getId(this.seller));
      }
    },

    isNonEmptyString: function(object) {
      return object != null &&
          typeof object === 'string' &&
          object.trim() !== "";
    },

    isValidId: function(updated) {
      return (updated.oursubnet || updated.oursubnet !== this.oursubnet) ? true : (updated.seller === '' || updated.seller === null ? true : Boolean(parseTicketId(updated.seller)));
    },

    bSave: function(updated) {
      updated.seller = parseTicketId(updated.seller);
      updated.seller = updated.seller === false ? '' : updated.seller;

      return updated;
    },

    aSave: function() {
      Object.keys(this.sellerTicket).forEach((name) => {
        this.sellerTicket[name] = null;
      });

      if(parseTicketId(this.proxy.seller)) {
        this.getSellerInfo();
      }
    },

    getSellerInfo: function(id = null) {
      id = id ?? this.seller;

      if(id !== null) {
        CrmApi.getTicketList({_id: id}).then((result) => {
          if(typeof result?.data.data[0] !== 'undefined') {
            let ticket = result.data.data[0];
            this.canOpenTicket = true;
            let fields = ticket?.assigned_product?.fields ?? {};
            CrmApi.getThreadPerson(ticket._id).then((result) => {
              this.sellerTicket.person = result.data.person;
            });
            this.sellerTicket = {
              _id: ticket._id,
              name: ticket.name,
              person: ticket.person ?? null,
              subnets: fields.subnets ?? null,
            };
          } else {
            this.canOpenTicket = false;
          }
        });
      }
    },
  },

  watch: {
    seller(to, from){
      if( to !== from ){
        this.sellerTicket = this.sellerDefault;
        if( typeof to === 'string' ) {
          this.getSellerInfo(to);
        }
      }
    },

    oursubnet(to, from) {
      if( to !== from ){
        this.proxy.oursubnet = to ?? false;
      }
    },

    'proxy.oursubnet' (to) {
      this.form.setCloseByOutClick(to);
    },

    confirmedOffer(offer) {
      if(offer !== null) {
        this.proxy.seller = window.getId(offer._id);
        this.form.save();
        this.$store.commit('thread/offers/setConfirmedOffer', null);
      }
    }
  }

}
</script>

<style scoped>
</style>