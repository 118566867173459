<template>
  <ticket-row
      v-if="$route.name !== 'Tasks'"
      :id="'ticket-body-row-'+ ticket._id"     v-for="ticket in getTicketList"
      @click="clickEvent($event, ticket)"
      @click.exact="selectElement($event , ticket)"
              :class="{'loading-row':isLoading}"
               @click.ctrl.exact="selectElements($event , ticket)"

              @click.meta.exact="selectElements($event , ticket)"
               @click.shift.exact ="multySelectElements($event , ticket)"
               @click.middle ="openTicketInNewWindow($event , ticket)"
               v-on:dblclick="showTicket(ticket)"
               :ref="'ticket-row-' + ticket._id"
               :ticket="ticket"
  ></ticket-row>
  <tasks-row v-if="$route.name === 'Tasks'"
             v-for="message in getTicketList"
             @click="clickEvent($event, message)"
             @click.exact="selectElement($event , message)"
             :class="{'loading-row':isLoading}"
             @click.ctrl.exact="selectElements($event , message)"
             @click.meta.exact="selectElements($event , message)"
             @click.shift.exact ="multySelectElements($event , message)"
             @click.middle ="openTicketInNewWindow($event , message)"
             v-on:dblclick="showTicket(message)"
             :ref="'ticket-row-' + message._id"
             :message="message"
  ></tasks-row>
</template>

<script>
import TicketRow from "./TicketRow";

import {mapActions, mapGetters} from 'vuex'
import { mapMutations } from 'vuex'
import TasksRow from "./tasks/TasksRow";

export default {
  name: "TableBody",
  components: {TasksRow, TicketRow},

  data: function (){
    return {
      lastSelectedElem: null,
      onselectstart:null,
    }
  },

  methods:{
    clickEvent:function (event, message){
      if(event.shiftKey && event.metaKey){
        event.preventDefault();
        this.openTicketInNewWindow(event , message)
      }
    },
    ...mapMutations("tickets",[
      "clearSelect",
      "addToSelectedControl",
        "unSelectTicket"
    ]),
    ...mapMutations({
      "setSelectMessageExistInTicket": "thread/setSelectMessageExistInTicket",
    }),
    ...mapActions({
      "actionMultySelectElements":"tickets/multySelectElements",
      "actionSelectElement":'tickets/selectElement'
    }),
    showTicket:function (ticket){

      if(!this.isTicketLoading){

        if(this.$route.name === 'Billmanager') {
          window.open('https://my.i7.net/billmgr?elid=' + ticket._id + '&plid=&startform=ticket_all.edit', '_blank');
        } else if (this.$route.name === 'Calls' || this.$route.name === 'Tasks')  {
          this.setSelectMessageExistInTicket(true);
          this.$router.push({
            name: 'Ticket',
            params: {id: window.getId(ticket.ticket._id)},
            query: {message: window.getId(ticket._id)}
          });
        } else {
          let query = {};
          let routeName = (typeof ticket.type !== 'undefined' && ticket.type === 'notification') ? 'Notification' : 'Ticket';
          if (
              typeof this.searchString === 'string' &&
              this.searchString.trim() !== '' &&
              typeof ticket?.sub_bodysearch === 'object' &&
              Array.isArray(ticket.sub_bodysearch) &&
              ticket.sub_bodysearch.length
          ) {
            query.search = this.searchString.trim();
          }
          this.$router.push({name: routeName, params: {id: ticket._id}, query: query});
        }
      }

    },
    openTicketInNewWindow(event , ticket){
      if(this.$route.name === 'Billmanager') {
        window.open('https://my.i7.net/billmgr?elid=' + ticket._id + '&plid=&startform=ticket_all.edit', '_blank');
        return;
      }
      let query = {
        params: {id: window.getId(ticket._id)},
        query: {},
      };

      if(['Tasks', 'Calls',].includes(this.$route.name)){
        query = {
          params: {id: window.getId(ticket.ticket._id)},
          query: {message: window.getId(ticket._id)}
        };
      }
      query.name = typeof ticket?.type !== 'undefined' && ticket.type === 'notification' ? 'Notification' : 'Ticket';
      if (
          typeof this.searchString === 'string' &&
          this.searchString.trim() !== '' &&
          typeof ticket?.sub_bodysearch === 'object' &&
          Array.isArray(ticket.sub_bodysearch) &&
          ticket.sub_bodysearch.length
      ) {
        query.query.search = this.searchString.trim();
      }
      let routeData = this.$router.resolve(query);
      window.open(routeData.href, '_blank');
    },

    selectElement:function (event, ticket){

      if(!this.isTicketLoading){

        event.preventDefault();
        this.actionSelectElement(ticket);

      }

    },
    selectElements:function (event, ticket){
      if(!this.isTicketLoading){
        if(typeof this.getSelectedObjects[ticket._id] === 'undefined' ){
          event.preventDefault();
          this.addToSelectedControl(ticket);
        }else {
          this.unSelectTicket(ticket);
        }

      }
    },

    multySelectElements:function (event , ticket){
      if(!this.isTicketLoading){
        event.preventDefault();
        this.actionMultySelectElements(ticket);
      }

    }
  },
  created() {
    this.onselectstart = Object.assign({}  ,  document.onselectstart);
    ["keyup","keydown"].forEach((event) => {
      window.addEventListener(event, (e) => {
        document.onselectstart = function() {
          return !((e.key == "Shift" || e.key == 'Meta') && e.shiftKey);
        }
      });
    });

  },
  destroyed() {
    document.onselectstart = this.onselectstart
  },
  computed:{
    ...mapGetters({
      "getTicketList":  "tickets/getTicketList",
      "isTicketLoading": "tickets/isTicketLoading",
      "getSelectedObjects":"tickets/getSelectedObjects",
      "isLoading":  "tickets/isAllLoading",
      "searchString": 'tickets/filters/getSearchString',
    }),

  }
}
</script>

<style scoped>
:deep( td){
  font-size: 13px;
  padding-bottom: 2px !important;
  padding-top: 2px !important;
}
.loading-row{
  pointer-events: none;
  opacity: 50%;
}
</style>