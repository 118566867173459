<template>
  <div v-if="showContextMenu" style="position: absolute" ref="menu" :style="{
    left:params.event.pageX + 'px',
    top:params.event.pageY + 'px'
  }" id="context-menu">
    <ul class="dropdown-item-text" style="background-color: rgb(181, 181, 181); color: white; border-top-left-radius: 5px; border-top-right-radius: 5px; padding: 5px; z-index: 989; margin-bottom: 0px;">
      <li style="list-style: none"><a class="dropdown-item" style="cursor: pointer" @click.stop.prevent="copyString()">Копировать</a></li>
    </ul>
    <ul class="dropdown-item-text" style="background-color: red; color: white; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px; padding: 5px; z-index: 989; margin-bottom: 0px;">
      <li style="list-style: none"><a class="dropdown-item" style="cursor: pointer" @click.stop.prevent="showDeleteModal = true; showContextMenu = false;">Удалить</a></li>
    </ul>
  </div>
  <delete-history-madal :params="params" :show-delete-modal="showDeleteModal" @close="close()"></delete-history-madal>
</template>

<script>

import {mapGetters} from "vuex";
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import DeleteHistoryMadal from "./DeleteHistoryModal";
import {HistoryCopy} from "./HistoryCopy";

export default {
  name: "ContextMenu",
  components: {DeleteHistoryMadal, ModalWindow},
  props:{
  },
  data:function (){
    return {
      style:{

      },

    }
  },
  methods:{
    close:function (){
      this.$store.dispatch('offerHistory/closeDeleteModalWindow');
    },
    copyString:function (){
      this.copyObject.copy(this.currentCurrency)
      this.showContextMenu = false;
      this.$store.commit('offerHistory/setContextMenuParams', null)
    },
  },
  mounted() {

  },
  computed:{
    copyObject:function (){
      return HistoryCopy.initObject(this.params.offerHistory);
    },
    showDeleteModal:{
      get(){
        return this.$store.getters['offerHistory/getShowDeleteModal']
      },
      set(value){
        this.$store.commit('offerHistory/setShowDeleteModal', value)
      }
    },
    showContextMenu:{
      get(){
        return this.$store.getters['offerHistory/isShowContextMenu']
      },
      set(value){
        this.$store.commit('offerHistory/showContextMenu', value)
      },
    },
    ...mapGetters({
      'currentCurrency':'offerHistory/getCurrentCurrency',
      'params':"offerHistory/getContextMenuParams",
    }),
  }
}
</script>

<style scoped>

</style>