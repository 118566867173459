<template>
  <div>
    <span>{{ipCount}}</span>
  </div>
  <!-- div>
    <popper  :hover="true"  :arrow="true" zIndex="999" placement="top"  >
      <span>{{ipCount}}</span>
      <template #content>
        <div style="z-index: 100; background-color: #d6fad0; padding: 5px; border-radius: 3px"  >
          <span>{{prices}}</span>
        </div>
      </template>
    </popper>

  </div -->
</template>

<script>
import {mapGetters} from "vuex";
import getComputedRates from "../../common/Rates/Computeds";
import {PricePrettyView} from "../../../library/PricePrettyView";

export default {
  name: "IPs",
  props:{
    'ipCount':{
    },
    historyObject:{
      type:Object,
    }
  },
  setup(props){
    const { rates } = getComputedRates();
    return {rates}
  },
  methods:{
    getPrettyPriceByCurrency(price , currencyToShow){
      let pr =  new PricePrettyView(price, currencyToShow)
      let value = 0;
      switch (currencyToShow){
        case 'USD':
          value = pr.getPrettyPrice('', false, 2, false , "" );
          break;
        case 'EUR':
        case 'RUB':
          value = pr.getPrettyPrice('', false, 2, true , "" );
          break;
      }
      return value;
    }
  },
  computed:{
    prices:function (){
      /*
      let obj = {};
      let sellpriceusd = this.historyObject?.sellprice?.priceusd;
      let buypriceusd =  this.historyObject?.buyprice?.priceusd;
      let sell = PricePrettyView.getCurrenciesArray(sellpriceusd , 'USD' , this.actualRates)
      let buy = PricePrettyView.getCurrenciesArray(buypriceusd , 'USD' , this.actualRates);

      return this.getPrettyPriceByCurrency( sell[this.getCurrentCurrency] - buy[this.getCurrentCurrency] , this.getCurrentCurrency);

       */
    },
    ...mapGetters({
      'getCurrentCurrency':'offerHistory/getCurrentCurrency',
    }),
    actualRates() {
      let result = null;
      if ( this.historyObject?.rates) {
        result =this.historyObject.rates;
      } else if (this.rates !== null) {
        result = {
          "USD": this.rates['USD'].Value,
          "EUR": this.rates['EUR'].Value,
        }
      }
      return result;
    },

  }
}
</script>

<style scoped>

</style>