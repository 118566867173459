<template>
  <button
      @click="showModalFunction(true)"
      :class="{'text-muted': loading, 'custom-disabled': loading}"
      :disabled="disabled"
      v-show="ticketId || idList.length > 0"
      class="btn btn-light btn-outline-dark col-12"
  ><i class="fa fa-forward button-icon menu-button"></i>Передать</button>

  <teleport to="body">
    <transfer-modal
      v-if="showModal"
      :ticket-id="ticketId"
      :callback="callback"
      @close="showModalFunction(false)"
    ></transfer-modal>
  </teleport>
</template>

<script>
import ModalWindow from "../ModalWindow";
import ModalBody from "../transfer/ModalBody";
import { mapGetters} from "vuex";
import StringTrim from "../../../string/StringTrim";
import TransferModal from "./TransferModal";

export default {
  name: "TransferButton",
  data: function () {
    return {
      showModal: false,
    }
  },
  props:{
    ticketId: {
      default: null,
    },
    callback: {
      default: null,
    },
    disabled: {
      default: false,
    }
  },
  components: {
    TransferModal,
    ModalBody,
    ModalWindow,
    StringTrim,
  },
  computed: {
    ...mapGetters({
      "idList": "tickets/getSelectedArray",
      "loading": "tickets/isTicketLoading",
    }),
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
    },
  },

}
</script>

<style scoped>

</style>