let jquery = require('jquery');
var interval = null;

let fromTopToBottom = null;
let fromBottomToTop = null;
let oneMoreFromBottomToTop = null;
let oneMoreFromTopToBottom = null;
let nextFolder = null;
let prevFolder = null;
let withCtrlUpFunction = null
let withCtrlDownFunction = null

let withAltUpFunction = null
let withAltDownFunction = null
let source = {
    additionalKeysDown:function (event){
        let additionalKeysDown = {
            length:0,
        }
        let keysEvent = ['altKey' , 'ctrlKey' , 'shiftKey' , 'metaKey'];
        for(let el of keysEvent){
            if(typeof event[el] !== 'undefined' && event[el]){
                additionalKeysDown[el] = true;
                additionalKeysDown.length++;
            }else {
                additionalKeysDown[el] = false;
            }
        }
        return additionalKeysDown;
    }
}
const setIntervalFunctionForCmd = function (func){
    if(interval === null){
        if(typeof func !== 'undefined' && func!== null){
            func();
            interval = setInterval(()=> {
                func();
            }, 300);
        }

    }
}
const setIntervalFunction = function (func){
    if(interval === null){
        if(typeof func !== 'undefined' && func!== null){
            func();

            interval = setInterval(()=> {

                func();
            }, 200);
        }

    }
}

const clear = function (){
    if(interval !== null){
        clearInterval(interval);
        interval = null;
    }
}

const arrowEventKeyUP = function (e){
    switch(e.code){
        case "ArrowUp":
            e.preventDefault(); break;
        case "ArrowDown":
            e.preventDefault(); break;
        default: break;
    }
    clear()
}

const  arrowEventKeyDown= function (e){
    switch(e.code){
        case "ArrowUp":
            if(fromBottomToTop !== null && source.additionalKeysDown(e).length === 0){
                setIntervalFunction(fromBottomToTop);
                e.preventDefault();
            }
            if(oneMoreFromBottomToTop !== null && source.additionalKeysDown(e).length === 1 && source.additionalKeysDown(e).shiftKey){
                setIntervalFunction(oneMoreFromBottomToTop)
                e.preventDefault();
            }
            if(withCtrlUpFunction !== null && source.additionalKeysDown(e).length === 1 &&
                (source.additionalKeysDown(e).ctrlKey || source.additionalKeysDown(e).metaKey) ){
                if(source.additionalKeysDown(e).ctrlKey){
                    setIntervalFunction(withCtrlUpFunction)
                }
                if( source.additionalKeysDown(e).metaKey){
                    setIntervalFunctionForCmd(withCtrlUpFunction)
                }
                e.preventDefault();
            }
            if(withAltUpFunction !== null && source.additionalKeysDown(e).length === 1 && (source.additionalKeysDown(e).altKey) ){
                setIntervalFunction(withAltUpFunction)
                e.preventDefault();
            }
            if(prevFolder !== null && source.additionalKeysDown(e).length === 2 && (
                source.additionalKeysDown(e).ctrlKey || source.additionalKeysDown(e).metaKey)
                && source.additionalKeysDown(e).shiftKey){
                if(source.additionalKeysDown(e).ctrlKey){
                    setIntervalFunction(prevFolder)
                }
                if( source.additionalKeysDown(e).metaKey){
                    setIntervalFunctionForCmd(prevFolder)
                }
                e.preventDefault();
            }
            break;
        case "ArrowDown":
            if(fromTopToBottom !== null && source.additionalKeysDown(e).length === 0){
                setIntervalFunction(fromTopToBottom);
                e.preventDefault();
            }
            if(withCtrlDownFunction !== null && source.additionalKeysDown(e).length === 1 &&
                (source.additionalKeysDown(e).ctrlKey || source.additionalKeysDown(e).metaKey ) ){

                if(source.additionalKeysDown(e).ctrlKey){
                    setIntervalFunction(withCtrlDownFunction)
                }
                if( source.additionalKeysDown(e).metaKey){
                    setIntervalFunctionForCmd(withCtrlDownFunction)
                }
                e.preventDefault();
            }
            if(withAltDownFunction !== null && source.additionalKeysDown(e).length === 1 && (source.additionalKeysDown(e).altKey) ){
                setIntervalFunction(withAltDownFunction)
                e.preventDefault();
            }
            if(oneMoreFromTopToBottom !== null && source.additionalKeysDown(e).length === 1 && source.additionalKeysDown(e).shiftKey){
                setIntervalFunction(oneMoreFromTopToBottom)
                e.preventDefault();
            }
            if(nextFolder !== null &&
                source.additionalKeysDown(e).length === 2 &&
                (source.additionalKeysDown(e).ctrlKey || source.additionalKeysDown(e).metaKey ) && source.additionalKeysDown(e).shiftKey){
                if(source.additionalKeysDown(e).ctrlKey){
                    setIntervalFunction(nextFolder)
                }
                if( source.additionalKeysDown(e).metaKey){
                    setIntervalFunctionForCmd(nextFolder)
                }
                e.preventDefault();
            }
               break;
        default: break;
    }
}

jquery(window).blur(function() {
    clear();
});

export function disableArrowKeysManagement(){
    window.removeEventListener("keydown" , arrowEventKeyDown , false);
    window.removeEventListener("keyup", arrowEventKeyUP, false);
    clear();
    fromTopToBottom = null;
    fromBottomToTop = null;
    oneMoreFromBottomToTop = null;
    oneMoreFromTopToBottom = null;
    withCtrlUpFunction = null
    withCtrlDownFunction = null
    withAltUpFunction = null
    withAltDownFunction = null
}

export function enableArrowKeysManagement(selectFromBottomToTop = null , selectFromTopToBottom =null, addOneMoreFromBottomToTop = null, addOneMoreFromTopToBottom = null){
    fromTopToBottom=  selectFromTopToBottom;
    fromBottomToTop= selectFromBottomToTop;
    oneMoreFromBottomToTop = addOneMoreFromBottomToTop;
    oneMoreFromTopToBottom = addOneMoreFromTopToBottom;
    init();
}
export function init(){
    window.addEventListener("keydown" , arrowEventKeyDown , false);
    window.addEventListener("keyup",arrowEventKeyUP, false);
}

export function initMovingFromFoldersFunctions(goToNextFolder = null , goToPrevFolder  = null){
    nextFolder = goToNextFolder;
    prevFolder = goToPrevFolder;

}

export function initCtrlFunctions(ctrlDownFunction = null , ctrlUpFunction = null){
    withCtrlDownFunction = ctrlDownFunction
    withCtrlUpFunction = ctrlUpFunction

}

export function initAltFunctions(altDownFunction  = null, altUpFunction = null){
    withAltDownFunction = altDownFunction
    withAltUpFunction = altUpFunction
}