import axios from "axios";

export {CrmApi};

class CrmApi {

    static loading = {
        getThreadPerson: {},
        getRates: null,
    };

    static abortControllers = [
        new AbortController()
    ];


    static getTicketTemplate(id){
        let url = '/thread/' + encodeURIComponent(window.getId(id)) + '/templates/';
        return this.call(url);
    }

    static editTemplate(template){
        return this.call("/template/" + encodeURIComponent(window.getId(template._id)), template);
    }


    static createTemplate(template){
        return this.call("/template/new", template);
    }

    static editFilter(filter){
        let data = {
            data:filter
        }
        let url = window.location.origin+  "/filter/" + encodeURIComponent(window.getId(filter._id));

        return  axios.post(url, data,  {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static productLists(query){
        return this.call("/products/list"+ ( typeof query !== 'undefined' && query !== null ? ("?"+ Object.keys(query).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
        }).join('&')) : "") );
    }

    static updateThreadParams(ticketId, params) {
        let url = '/thread/' + encodeURIComponent(window.getId(ticketId)) + '/params/';
        return this.call(url, params);
    }

    static getProducts(ticketId) {
        let url = '/thread/' + encodeURIComponent(window.getId(ticketId)) + '/products/';
        return this.call(url);
    }

    static getProjectProducts(projectId) {
        return this.call('/project/' + encodeURIComponent(window.getId(projectId)) + '/products/');
    }

    static editProduct(product){
        let url = window.location.origin + "/ajax/products/" + encodeURIComponent(window.getId(product._id)) + "/";
        return  axios.post(url, product,  {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    static editStep(step){
        let url = window.location.origin + "/ajax/products/steps/" + encodeURIComponent(window.getId(step._id)) + "/";

        return  axios.post(url, step,  {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static deleteFilters(idsArray){
        return this.deleteEntityList("filters",idsArray);
    }

    static addFilter(filter){
        let data = {
            data:filter
        }
        return this.call("/filters/new/" , data);
    }

    static addProduct(product){
        return this.call("/products/new/" , product);
    }

    static getFiltersList(query){
        return this.getEntityList("filters", query);
    }

    static deleteProducts(idsArray){
        return this.deleteEntityList("products",idsArray);
    }

    static getProductsList(query){
        return this.getEntityList("products", query);
    }

    static getEntityList( entityName, query ){
        return this.call("/" + encodeURIComponent(entityName) + "/list/" + ( typeof query !== 'undefined' ? ("?"+ Object.keys(query).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
        }).join('&')) : ""));
    }

    static deleteEntityList( entityName, idsArray ){
        let ids = {
            ids:idsArray,
        }
        return this.call("/" + encodeURIComponent(entityName) + "/delete/" , ids)
    }

    static getBillmanagerProjects() {
        return this.call('/project/billmanager-list/');
    }

    static getTags(searchString){
        let search = '';
        if(typeof searchString === 'string' && searchString.trim() != ''){
            search = '?searchString='+encodeURIComponent(searchString.trim());
        }
        return this.call("/ticket/tags/" + search );
    }

    static addTags(ids , tags ){
        let data = {
            ids:ids,
            tags: tags
        }

        return this.call("/ticket/tags/" , data);
    }

    static addIfSet( object, key, value){
        if( typeof value !== 'undefined' ){
            object[key] = value;
        }
    }

    static getProjectsList( {orderBy,orderDirection} ){
        let query = {};
        this.addIfSet(query, "orderBy", orderBy);
        this.addIfSet(query, "orderByDirection", orderDirection);

        return this.call("/project/list/" + (Object.keys(query).length > 0 ? "?" + (new URLSearchParams(query)).toString() : ""));
    }

    static getProjectsNames(){
        return this.call("/project/names/");
    }

    static saveAttachments(threadId, attachments) {
        let formData = new FormData();

        for(let index in attachments) {
            formData.append('attachments[' + index + ']', attachments[index]);
        }

        return this.call("/thread/" + threadId + '/upload/', formData, { headers: {'Content-type': 'multipart/form-data'} });
    }

    static sendThreadAnswer(answerType, threadId, message, attachments = null, headers = null, template = null) {
        let data = {
            thread: threadId,
            message: message,
        };
        if(attachments !== null && attachments.length > 0) {
            data.attachments = attachments;
        }
        if(headers !== null) {
            data.to = headers.to;
            data.from = headers.from;
            data.subject = headers.subject;
            data.headers = headers.headers;
        }
        if (template !== null) {
            data.template = template;
        }

        return (answerType === 'internal') ?
                    this.call("/thread/internal_answer", data) :
                    this.call("/thread/answer/", data);
    }

    static updateMessage(messageId, threadId, message, attachments = null, headers = null , otherParams = null) {
        let data = {
            thread: threadId,
            message: message,
        };
        if(attachments !== null && attachments.length > 0) {
            data.attachments = attachments;
        }
        if(headers !== null) {
            data.to = headers.to;
            data.from = headers.from;
            data.subject = headers.subject;
            data.headers = headers.headers;
        }
        if(otherParams !== null){
            if(otherParams?.replyto){
                data.replyto = otherParams.replyto
            }
        }

        return this.call('/message/' + encodeURIComponent(window.getId(messageId)) + '/update/', data);
    }

    static getTicketList( queryQ , filters, abortController = null ){
        let url = (typeof filters?.filter !== 'undefined' && filters.filter === 'billmanager' ? '/billmanager' : '') + '/ticket/list/';
        let query =  Object.assign({},queryQ);
        if(filters != null && typeof filters.filter !== 'undefined') {
            let  mainFilter = {
                alias:filters.filter,

            };
            if(typeof filters.projectName !== 'undefined'){
                query.projectName = filters.projectName;
            }

            query.filter = JSON.stringify(mainFilter);
        }

        return this.call(url + ( typeof query !== 'undefined' ? "?"+ Object.keys(query).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
        }).join('&') : ""), undefined, null, true, abortController);
    }

    static getTemplatesList( filters ){
        return this.call("/template/list/" + ( typeof filters !== 'undefined' ? "?"+ Object.keys(filters).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        }).join('&') : ""));
    }

    static getThreadMetaData(threadId){
        return this.call("/thread/" +encodeURIComponent(threadId) + "/meta/" );
    }

    static getThreadPerson(threadId, reload = false) {
        let id = encodeURIComponent(window.getId(threadId));
        let request;

        if(!reload && typeof this.loading?.getThreadPerson[id] !== 'undefined') {
            request = this.loading.getThreadPerson[id];
        } else {
            request = this.call('/thread/' + id + '/person/');
            this.loading.getThreadPerson[id] = request;
        }
        return request;
    }

    static getThreadPersonRelations(threadId, relationType) {
        let url = '/thread/' + encodeURIComponent(window.getId(threadId)) + '/person/relations';

        if(typeof relationType === 'string') {
            url += '?type=' + relationType;
        }

        return this.call(url);
    }

    static updateThreadPerson(threadId, params) {
        return this.call('/thread/' + encodeURIComponent(window.getId(threadId)) + '/person/', params);
    }

    static getThread(ticketid, messageId){
        let uri = "/thread/" + encodeURIComponent(ticketid )+ "/updates/" + "?_id=" +encodeURIComponent(messageId);
        return this.call(uri);
    }

    static getTemplateGroups( search, projectId , dataCustom = {}){
        let data = Object.assign({} , dataCustom);
        if( search != null ){
            data.search = search;
        }
        if( projectId != null ){
            data.project = projectId;
        }

        return this.call('template/group/list', data);
    }

    static setNotReaded(ids){
        let data = {
            ids:ids,
        }
        return this.call('/thread/unreaded', data);
    }
    static setReaded(ids){
        let data = {
            ids:ids,
        }
        return this.call('/thread/readed', data);
    }

    static setAllRead(project) {
        return this.call('/thread/readed', {
            all: true,
            projectName: project,
        });
    }

    static setFavorite(ids) {
        return this.call('/thread/favorite', {set: ids});
    }

    static unsetFavorite(ids) {
        return this.call('/thread/favorite', {unset: ids});
    }

    static removeTickets(ids){
        let data = {
            ids:ids,
        }
        return this.call('/thread/remove', data);
    }

    static deleteTickets(ids, withTag){
        let data = {
            ids:ids,
            withTag:withTag,
        }
        return this.call('/thread/delete', data);
    }

    static restoreTickets(ids){
        let data = {
            ids:ids,
        }
        return this.call('/thread/restore', data);
    }

    static takeToWork(id){
        return this.call('/thread/'+ encodeURIComponent(id)+'/lock/');
    }

    static unlockTicket(id) {
        return this.call('/thread/'+ encodeURIComponent(id)+'/unlock/');
    }

    static updateUser( user ){
        return this.call('settings', user);
    }



    /**
     * @param path
     * @param data
     * @param config
     * @param catchSessionError
     * @param abortController
     * @returns {Promise<AxiosResponse<any>>}
     */
    static call(path, data, config = null, catchSessionError = true, abortController = null){
        if( path[0] === '/' ){
            path = path.substr(1);
        }
        if(config === null) {
            if(typeof data !== 'undefined') {
                config = {
                    headers: {'Content-Type': 'application/json'}
                };
            } else {
                config = {};
            }
        }

        if(!(abortController instanceof AbortController)) {
            abortController = this.abortControllers[0];
        } else if (!this.abortControllers.find(ac => ac === abortController)) {
            this.abortControllers.push(abortController);
        }

        config.signal = abortController.signal;

        let url = window.location.origin+  "/ajax/" + path;

        let promise = typeof data === 'undefined'
                         ? axios.get(url, config)
                         : axios.post(url, data, config)

        if(catchSessionError) {
            promise.catch(this.sessionErrorHandler);
        }

        return promise;
    }

    static sessionErrorHandler(error) {
        if(typeof error.response !== 'undefined' && error.response.status === 401) {
            window.location.href = '/login/';
        } else if(axios.isCancel(error)) {
            // do nothing
        } else {
            throw error;
        }
    }

    /**
     * @param error
     * @returns {array}
     */
    static getErrorMessage(error) {
        let errorMessage = [];

        if( error?.response != null ) {
            if( error.response?.data?.errors != null ) {
                for(let msg in error.response.data.errors) {
                    errorMessage.push(error.response.data.errors[msg][0]);
                }
            } else if( error.response?.data?.message != null ) {
                errorMessage = [error.response.data.message];
            }
        }
        if(!errorMessage.length) {
            errorMessage = [error?.message ?? error];
        }

        return errorMessage;
    }

    static getSmtpList(query) {
        return this.getEntityList("smtp", query);
    }

    static editSmtp(object) {
        let url = window.location.origin + "/ajax/smtp/" + encodeURIComponent(window.getId(object._id)) + "/";
        return axios.post(url, object, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static changePrimary(object) {
        let url = window.location.origin + "/ajax/smtp/" + encodeURIComponent(window.getId(object._id)) + "/changePrimary";
        return axios.post(url, object, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    static deleteSmtp(idsArray) {
        return this.deleteEntityList("smtp", idsArray);
    }

    static addSmtp(object) {
        return this.call("/smtp/new/", object);
    }

    static addTicket(ticket) {
        return this.call("/ticket/new/", ticket);
    }

    static getStatistic({period, project, type}) {
        let query = typeof period !== 'undefined' ? Object.keys(period).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(period[key])
        }).join('&') : '';

        if (typeof project !== 'undefined' && project) {
            query += (query.length > 0 ? '&' : '') + 'project=' + project;
        }

        if (typeof type !== 'undefined' && type) {
            query += (query.length > 0 ? '&' : '') + 'type=' + type;
        }

        return this.call('/statistic/list/?' + query);
    }

    static getRates(date = null) {
        if(this.loading.getRates === null) {
            let path = '/utility/rates/';
            if(date){
                path += "?date="+encodeURIComponent(date);
            }
            this.loading.getRates = this.call(path);
        }
        return this.loading.getRates;
    }


    static getRateQuery(date = null){
        let path = '/utility/rates/';
        if(date){
            path += "?date="+encodeURIComponent(date);
        }
        return this.call(path);
    }


    static prepareSubnet(subnet) {
        return encodeURIComponent( subnet.replace('/', '|') );
    }

    static getNetHistory(subnet, date = null) {
        let params = {subnet: subnet};
        if(date !== null) {
            params.date = date;
        }

        return this.call('/utility/nethistory?' + new URLSearchParams(params).toString());
    }

    static getSubnetPerson(subnet, date, type = null, date_type ) {
        return this.call('/history/subnetperson/?' +
            new URLSearchParams({subnet: subnet, date: date, type:type, date_type:date_type}).toString());
    }

    static setCallbackCall(calls) {
        return this.call('/call/callback', {ids: calls});
    }

    static logout() {
        this.abortControllers.forEach(ac => ac.abort());
        window.location.href = '/logout';
    }

    static removeAbortController(abortController) {
        this.abortControllers = this.abortControllers.filter(ac => ac !== abortController);
    }
}
