import {Block} from "./Block";
import {Keyword} from "./Keyword";

export class Document {

    _id = null;
    name = '';
    saved = false;

    template = {
        id: null,
        name: null,
        filename: null,
        mimeType: null,
        size: null,
    };

    projects = [];
    default_company = null;

    /**
     * @type {Block[]}
     */
    blocks = [];

    /**
     * @type {Keyword[]}
     */
    keywords = [];


    constructor(document = null) {
        if(document !== null) {
            this.set(document);
        }
    }

    set(document, setOver = false) {
        this._id = document._id ?? this._id ?? null;
        this.template = {
            id: window.getId(document.template.id),
            filename: document.template.filename,
            name: document.template.name ?? document.template.filename.substr(0, document.template.filename.lastIndexOf('.')),
            'mime-type': document.template['mime-type'],
            size: document.template.size,
        };

        this.setKeywords(document.params.filter(param => param.type !== 'textblock'), setOver);
        this.setBlocks(document.params.filter(param => param.type === 'textblock'), setOver);
        this.setProjects(document.projects ?? [], setOver);

        if(typeof document?.name === 'string') {
            this.name = document.name;
        } else if(this.name.length === 0) {
            this.name = this.template.filename.replace(/\..*$/, '');
        }

        this.default_company = document.default_company ?? null;
    }

    /**
     * Создание документа поверх существующего
     * От обычного создания отличается отображением кнопки удаления у полей, которые не используются в новой версии документа
     * @param document
     */
    setOver(document) {
        this.set(document, true)
    }

    setBlocks(blocks, setOver) {
        blocks = blocks.map(block => {
            block = new Block(block);
            block.setChildren( this.getKeywords().filter(keyword => keyword.isChildOf(block)) );
            return block;
        });

        if(setOver) {
            this.getBlocks().forEach(existBlock => {
                let foundedBlock = blocks.find(block => block.getUniqueName() === existBlock.getUniqueName());
                if(foundedBlock) {
                    // блокам которые были в предыдущей версии документа, перезаписываем параметры которые могли измениться
                    existBlock.setOver(foundedBlock);
                } else {
                    // блокам которые были в предыдущей версии документа, но нету в новой - добавляем кнопку удаления
                    existBlock.showDeleteBtn = true;
                }
            })
            blocks = blocks.filter(block => !this.blocks.some(b => b.getUniqueName() === block.getUniqueName()));
        } else {
            this.blocks = [];
        }

        blocks.forEach(block => this.blocks.push(block));
    }

    setKeywords(keywords, setOver) {
        keywords = keywords.map(keyword => new Keyword(keyword));

        if(setOver) {
            this.getKeywords().forEach(existKeyword => {
                let foundedKey = keywords.find(keyword => keyword.getUniqueName() === existKeyword.getUniqueName());
                if(foundedKey) {
                    // блокам которые были в предыдущей версии документа, перезаписываем параметры которые могли измениться
                    existKeyword.setOver(foundedKey);
                } else {
                    // ключам которые были в предыдущей версии документа, но нету в новой - добавляем кнопку удаления
                    existKeyword.showDeleteBtn = true;
                }
            });
            keywords = keywords.filter(keyword => !this.keywords.some(k => k.getUniqueName() === keyword.getUniqueName()));
        } else {
            this.keywords = [];
        }

        keywords.forEach(keyword => this.keywords.push(keyword));
    }

    setProjects(projects, setOver) {
        projects = projects.map((project) => window.getId(project._id));

        if(setOver && this.projects) {
            projects = _.uniq( projects.concat(this.projects) );
        }

        this.projects = projects;
    }

    getGroups() {
        return _.filter(
            _.uniq( this.blocks.map(block => block.group) )
        );
    }

    /**
     * Возвращает массив ключей по названию или названиям групп(ы)
     * @param {string|array} groupName
     * @returns {Keyword[]}
     */
    getKeywordsByGroup(groupName) {
        return this.getKeywords()
                   .filter(keyword => {
                        let filter = false;
                        if(Array.isArray(keyword.parent) && keyword.parent.length === 1) {
                            let parentGroup = keyword.parent[0].group;
                            filter = typeof groupName === 'string' ? groupName === parentGroup : groupName.includes(parentGroup);
                        }
                        return filter;
                    });
    }

    /**
     * Возвращает массив ключей по названию или названиям групп(ы)
     * @param {string|array} groupName
     * @returns {Block[]}
     */
    getBlocksByGroup(groupName) {
        if(typeof groupName === 'string') {
            groupName = [groupName];
        }
        return this.getBlocks()
                   .filter(block => groupName.includes(block.group));
    }

    getParamsByGroup(groupName) {
        return this.getKeywordsByGroup(groupName)
                   .concat( this.getBlocksByGroup(groupName) );
    }

    getKeywordsByType(type) {
        return this.getKeywords().filter(keyword => keyword.type === type);
    }

    /**
     * Возвращает ключи разложенные по группам
     * Ключи вне блоков, а также ключи из нескольких блоков, - в отдельном свойстве возвращаемого объекта - ungrouped
     * @returns {{}}
     */
    getGroupedParams() {
        let groupedParams = {
            ungrouped: []
        };

        this.getKeywords().filter(keyword => keyword.parent === null || keyword.parent?.length > 1)
                          .forEach(k => groupedParams.ungrouped.push(k));

        this.getGroups().forEach(
            group => groupedParams[group] = this.getKeywordsByGroup(group)
        );

        Object.entries(groupedParams).forEach(([group, params]) => {
            let order = 0;
            groupedParams[group] = _.orderBy(params, 'order').map(param => {
                if(typeof param?.order === 'number') {
                    order = param.order + 1;
                } else {
                    param.order = order;
                    order++;
                }
                return param;
            });
        });

        return groupedParams;
    }

    /**
     * @param {String} group
     * @returns {Block[]}
     */
    getGroupBlocks(group) {
        let blocks = this.getBlocks()
                                  .filter(block => block.group === group);
        return _.orderBy(blocks, 'order');
    }

    getUnitedGroups() {
        let unitedGroups = [];
        let params = [];

        Object.entries(this.getGroupedParams())
              .forEach(([name, keywords]) => {
                    params.push({
                        group: name,
                        keywords: keywords
                    });
                });

        params.forEach(param => {
            let existsGroup;

            if(param.keywords.length > 0) {
                 existsGroup = unitedGroups.find(params =>
                    _.isEqual(
                        params.keywords.map(k => k.keyword).sort(),
                        param.keywords.map(k => k.keyword).sort()
                    ));
            }

            if(typeof existsGroup !== 'undefined') {
                existsGroup.groups.push(param.group);
            } else {
                unitedGroups.push({
                    groups: [param.group],
                    keywords: param.keywords,
                    options: this.getGroupBlocks(param.group)
                });
            }
        });

        return unitedGroups;
    }

    /**
     * @returns {Keyword[]}
     */
    getKeywords() {
        return this.keywords.filter(keyword => keyword?.deleted !== true);
    }

    /**
     * @returns {Block[]}
     */
    getBlocks() {
        return this.blocks.filter(block => block?.deleted !== true);
    }

    /**
     * Проверет валидность документа, включает подсветку ошибок валдиации у невалдиных полей
     * @returns {boolean}
     */
    isValid() {
        let valid = true;
        this.getBlocks()
            .forEach(block => {
                if(!block.checkValidation()) {
                    block.showNotValid = true;
                    valid = false;
                }
            });
        return valid;
    }

    /**
     * Возвращает подготовленный для сохранения документ
     * @returns Object
     */
    prepareForSave() {
        let data = {
            name: this.name,
            projects: this.projects,
            template: this.template,
            default_company: this.default_company,
        };

        if(document._id !== null) {
            data._id = this._id;
        }

        data.params = this.getKeywords()
                          .concat( this.getBlocks())
                                       .map(item => item.prepareForSave() );
        return data;
    }

}