import {CrmApi} from "../CrmApi";

export {Offer};
class Offer {


    static getList(type ,  filters = undefined){
        let url = '/offers/';
        url = url + '?type='+encodeURIComponent(type);

        if(filters !== undefined && Object.keys(filters).length !== 0){
            url = url + "&" +  Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join("&");
        }

        return CrmApi.call(url);
    }

    static getSteps(type) {
        return CrmApi.call('/offers/steps/?type=' + encodeURIComponent(type));
    }

    static getSearchMeta(type, filters = undefined) {
        let url =
                '/offers/searchmeta/?type=' +
                encodeURIComponent(type) +
                (filters !== undefined && Object.keys(filters).length !== 0 ?
                    "&" + Object.keys(filters).
                                 map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])).
                                 join("&") :
                    '');

        return CrmApi.call(url);
    }
}