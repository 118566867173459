import {computed} from 'vue';
import commonStore from "../../../store/main/index";


export default function getComputedRates(){
    let ratesLoading = false;
    const rates = computed(() => {
        if( !ratesLoading && commonStore.getters["getRates"] === null ){
            ratesLoading = true;
            commonStore.dispatch("initRates" , {date:null});
        }
        return commonStore.getters["getRates"];
    });

    return {rates};
}