<template>
  <div :class="{loading: loading}">
    <price :price="price"
           :price1="price1"
           :currency="currency"
           :quantity="quantity"
           :prefix="prefix"
           :show-label="false"
           :absolute="true"
           :can-change="canChange"
           @update="update"
           :currency-to-show="currentCurrency"
           :clipboard="true"
    />
  </div>
</template>

<script>
import getBuyFields from "../Buy/OfferBuyCompiteds";
import {reactive, toRef} from "vue";
import Price from "../../../tiketMenu/components/Params/Product/Field/Price";
import updateFields from "../../source/updateFields";
import {mapGetters} from "vuex";

export default {
  name: "PriceBuy",

  components: {Price},

  props:{
    offer:{
      type:Object,
      require:true,
    }
  },

  data: function() {
    return {
      loading: false,
    }
  },

  setup(props){
    const offer = toRef(props, 'offer')
    const { currency, price , price1, quantity, prefix, canChange } = getBuyFields({offer:offer});
    return {
      currency,
      price,
      price1,
      quantity,
      prefix,
      canChange
    }
  },
  computed:{
    ...mapGetters({
      'currentCurrency':'offer/getCurrentCurrency'
    }),
  },
  methods:{
    update: function(values, afterSave = null, beforeSave = null) {
      this.loading = true;
      updateFields(this.offer, values, afterSave, beforeSave).then(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>