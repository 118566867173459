<template>
  <div>
    <entity-index :new-link="newLink">
      <div>
        <entity-table
             settings-name="smtp"
            :do-reload-page="reloadPage"
            :show-edit="showEdit"
            :columns="columns"
            :actions="actions"
            :get-entity-list="getSmtpList"
            @changePrimary="changePrimary"
            @showDeleteModalWindow="showDeleteModalWindow($event)"
        >
          <template v-slot:[`cell.name`]="{ item }">
            <i v-if="item?.primary === true" class="fa fa-star pe-1 text-success" title="SMTP-сервер по умолчанию" aria-hidden="true"></i>
            {{ item?.name ?? '' }}
          </template>
          <template v-slot:[`cell.server`]="{ item }">
            {{ item?.type === 'php' ? '-' : item?.server }}
          </template>
          <template v-slot:[`cell.authparams.login`]="{ item }">
            {{ item?.type === 'php' ? '-' : item?.authparams?.login }}
          </template>
        </entity-table>
      </div>
    </entity-index>
    <modal-window v-if="showDeleteModal" @close="showDeleteModal = false"  @success="deleteSmtp" ok-button-style="btn-danger" >
      <template v-slot:header>
        <h5 class="modal-title text-danger">Удаление настроек SMTP</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите навсегда удалить настройку SMTP {{smtpToDelete.name}}?</span>
      </template>
    </modal-window>
  </div>
  <alert-list></alert-list>
</template>

<script>
import EntityIndex from "../common/EntityList";
import EntityTable from "../common/EntityTable";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import {CrmApi} from "../../library/CrmApi";
import AlertList from "../ticket/Alert/AlertList";
import {mapGetters} from "vuex";

export default {
  name: "SmtpList",

  components: {
    AlertList,
    EntityIndex,
    EntityTable,
    ModalWindow
  },

  props: {
    canCreate: {
      type: Boolean,
      require: true
    }
  },

  data: function () {
    return {
      reloadPage: {
        value:false,
      },
      smtpToDelete: {},
      showDeleteModal: false,
      columns: [
        {
          name: "Название",
          value: "name",
          width: 30,
        },
        {
          name: "Сервер",
          value: "server",
          width: 30,
        },
        {
          name: "Логин",
          value: "authparams.login",
          width: 30,
        }
      ],
      defaultActions: [
        {
          name: "Редактировать",
          handler: function (object) {
            this.showEdit(object);
          }
        },
        {
          name: function (item) {
            return item.primary === true ? 'Сделать не основным' : 'Сделать основным';
          },
          handler: function (object) {
            this.$emit('changePrimary', object);
          }
        },
        {
          name: "Удалить",
          handler: function (object) {
            this.$emit('showDeleteModalWindow', object);
          },
          disabled: function (item) {
            return item?.type === 'php';
          },
          class: "link-danger",
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      'getUserSettings':'getUserSettings'
    }),
    actions: function() {
      return this.canCreate ? this.defaultActions : [];
    },
    newLink: function () {
      return this.canCreate ? '/smtp/new/' : '';
    }
  },

  methods: {
    showDeleteModalWindow: function (object) {
      if (object.type === 'php') {
         return;
      }
      this.smtpToDelete = object;
      this.showDeleteModal = true;
    },
    showEdit: function (object) {
      window.location.href = window.location.origin + "/smtp/" + encodeURIComponent(object._id);
    },
    getSmtpList: function (query) {
      if(this.getUserSettings?.smtp?.onpage && !query?.onpage){
        query.onpage = this.getUserSettings.smtp.onpage
      }
      return CrmApi.getSmtpList(query);
    },
    changePrimary: function (object) {
      const obj = {
        _id: object._id,
        primary: object.primary !== true
      };
      CrmApi.changePrimary(obj).then(() => {
        object.primary = obj.primary;
        if (obj.primary === true) {
          this.reloadPage.value = true;
        }
      }).catch((error)=>{
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      });
    },
    deleteSmtp: function () {
      this.reloadPage.value = false;
      CrmApi.deleteSmtp([this.smtpToDelete._id]).then(() => {
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.showDeleteModal = false;
        this.reloadPage.value = true;
      });
    }
  }
}
</script>

<style scoped>

</style>