<template>
  <div style="padding-bottom: 8px">
    <div class="row">
      <div class="col-11 pe-0">
        <div class="input-group">
          <input
              style="border-radius: 0 0 0 5px"
              ref="search"
              name="search"
              class="form-control"
              type="text"
              placeholder="Поиск"
              v-model.trim="search"
          >
          <button
              class="btn btn-secondary"
              type="button"
              @click="showHelpModal = true"
          >?</button>
        </div>
      </div>
      <div class="col-1">
        <show-filter-button></show-filter-button>
      </div>
    </div>
    <div class="text-muted ps-2 meta-string" v-if="metaString">{{ metaString }}</div>
  </div>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showHelpModal"
          @close="showHelpModal = false"
          @success="false"
          :show-footer="false"
          modal-dialog-class="modal-dialog modal-xl modal-dialog-scrollable modal-help"
      >
        <template v-slot:header>
          <h5 class="modal-title">Справка по фильтрам в бирже</h5>
        </template>
        <template v-slot:body>
          <table class="table table-striped table-bordered mb-0">
            <thead>
            <tr>
              <th style="width: 25%;">Фильтр</th>
              <th style="width: 40%;">Описание</th>
              <th style="width: 35%;">Примечание</th>
            </tr>
            </thead>
            <tbody>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Дата] - [Дата]</span><br>
                  <span class="mark">[Дата] - </span><br>
                  <span class="mark">- [Дата]</span><br>
                  <span class="mark">[Дата]</span>
                </td>
                <td class="filter-description">
                  Поиск по интервалу дат<br>
                  Поиск по дате от<br>
                  Поиск по дате до<br>
                  Поиск по точной дате
                </td>
                <td class="filter-mods">
                  Поддерживаемые форматы дат:<br>
                  YYYY-MM-DD<br>
                  DD.MM.YYYY<br>
                  YYYY.MM.DD<br>
                  MM/DD/YYYY
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">2023-01-01</span> <span class="mark">02.05.2023 - 09.05.2023</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Валюта][цена] [мод.]</span><br>
                  <span class="mark">[Цена] [Валюта] [мод.]</span>
                </td>
                <td class="filter-description">
                  Поиск по цене за 1 IP-адрес с конвертацией валют<br><br>
                  * При поиске в отличной от заданной для таблицы валюте (в правом верхнем углу, USD / EUR / RUB),
                  будет выведена равнозначная цена в заданной для таблицы валюте с пересчетом на данный день по курсу
                  <a
                    href="https://cbr.ru/currency_base/daily/" target="_blank">ЦБ</a>/<a
                    href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/eurofxref-graph-usd.en.html"
                    target="_blank">ECB</a>
                </td>
                <td class="filter-mods">
                  Доступны также модификаторы + и - для поиска от и до определенной цены.<br>
                  Валюты - USD, EUR, RUB и их алиасы
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">$7</span> <span class="mark">300 EUR +</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">/[число от 1 до 32][мод.]</span>
                </td>
                <td class="filter-description">
                  Поиск по префиксу сети
                </td>
                <td class="filter-mods">
                  Доступны также модификаторы + и - для поиска сетей с большим или меньшим кол-вом адресов: <br>
                  Для + ищет сети с префиксом меньше или равным заданному.<br>
                  Для - ищет сети с префиксом больше или равным заданному.
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">/24</span> <span class="mark">/22-</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Тип сети]</span>
                </td>
                <td class="filter-description">
                  Поиск по типу сети
                </td>
                <td class="filter-mods">
                  Тип сети = pa|pi|пи|па|зш|зф<br>
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">pi</span> <span class="mark">pi/pa</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Сеть]</span>
                </td>
                <td class="filter-description">
                  Поиск по сети
                </td>
                <td class="filter-mods">
                  Сеть в формате диапазона или с префиксом
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">195.38.8.0 - 195.38.8.255</span> <span class="mark">195.38.8.0/22</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">[ORG]</span></td>
                <td class="filter-description">
                  Фильтр по ORG
                </td>
                <td class="filter-mods">
                  Ищет по ORG внутри данных клиента, и для раздела "Покупают" также в данных тикета.
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">ORG-OTIS1-RIPE</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">[LirId]</span></td>
                <td class="filter-description">
                  Фильтр по LirId
                </td>
                <td class="filter-mods">
                  Ищет по LirId внутри данных клиента, и для раздела "Покупают" также в данных тикета.
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">uk.opalnet</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import addToQueryWithTimer from "./timer";
import ShowFilterButton from "./ShowFilterButton";
import {getMetaMoreOrLess} from "../../../library/Numerals";
import ModalWindow from "../../tiketMenu/components/ModalWindow";

export default {
  name: "SearchString",
  components: {
    ShowFilterButton,
    ModalWindow,
  },
  setup(){
    return {
      addToQueryWithTimer
    };
  },
  data: function () {
    return {
      showHelpModal: false,
    }
  },
  computed:{
    search:{
      set(value){
        this.setSearch(value);
      },
      get(){
        return this.getSearch;
      }
    },

    metaString: function () {
      let result = '';
      let metasNetwork = '';
      let search = '';
      let withNet = '';

      if (typeof this.searchMeta?.prefix === 'string') {
        metasNetwork += ' ' + getMetaMoreOrLess(this.searchMeta.prefix);
        withNet = ' с сетью ';
      }

      if (typeof this.searchMeta?.type === 'string') {
        metasNetwork += ' типа ' + this.searchMeta.type;
        withNet = ' с сетью ';
      }

      if (typeof this.searchMeta?.lirid === 'string') {
        metasNetwork += ` с LIRid '${this.searchMeta.lirid}'`;
      }

      if (typeof this.searchMeta?.org === 'string') {
        metasNetwork += ` с ORG '${this.searchMeta.org}'`;
      }

      if (typeof this.searchMeta?.price === 'string') {
        metasNetwork += ' с ценой за 1 IP ' + getMetaMoreOrLess(this.searchMeta.price);
      }

      if (typeof this.searchMeta?.subnet === 'string') {
        metasNetwork += ` с сетью '${this.searchMeta?.subnet}'`;
      }

      if (typeof this.searchMeta?.date === 'string') {
        metasNetwork += ` с датой сделки ${this.searchMeta?.date}`;
      }

      if (typeof this.searchMeta?.search === 'string' && this.searchMeta?.search.trim().length > 0) {
        search = `, где встречается строка '${this.searchMeta.search.trim()}'`;
      }

      if (metasNetwork !== '' || search !== '') {
        result = 'Поиск тикетов' + withNet + metasNetwork + search;
      }

      return result;
    },

    ...mapGetters({
      'getSearch':'offer/filters/getSearchString',
      'searchMeta': 'offer/getSearchMeta',
    }),
  },
  methods:{
    ...mapMutations({
      'setSearch':'offer/filters/setSearchString',
    }),

  },
  watch:{
    search(to, from){
      this.addToQueryWithTimer(to , 'search');
    }
  }
}
</script>

<style scoped>
.meta-string {
  font-size: 12px;
  margin: 2px 0;
}
:deep(.modal-dialog-scrollable.modal-help .modal-body) {
  max-height: calc(100vh - 130px);
  margin-top: 0;
  padding-bottom: 0;
}
:deep(.mark) {
  margin-bottom: 2px;
  display: inline-block;
  --bs-highlight-bg: var(--bs-gray-300);
  border-radius: 4px;
}
</style>