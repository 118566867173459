<template>
  <div class="row mde-text-area" @click="setEditorHeight(true)">
    <div style="position: relative">
      <markdown-modal v-if="showModal" @close="showModal = false" ></markdown-modal>
    </div>
    <div>
             <textarea
                 ref="simplemde"
                 class="vue-simplemde-textarea"
                 :name="name"
                 :value="modelValue"
                 @input="handleInput($event.target.value)"
             />
    </div>
  </div>
</template>

<script>
import SimpleMDE from './src/js/simplemde'
import MarkdownModal from "../../markdown-guide/markdown-modal";

export default {
  name: "MdeTextArea",
  components: {MarkdownModal},

  emits: ['focusin', 'focusout'],

  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    value: String,
    modelValue: String,
    name: String,
    previewClass: String,
    autoinit: {
      type: Boolean,
      default() {
        return true;
      },
    },
    highlight: {
      type: Boolean,
      default() {
        return false;
      },
    },
    sanitize: {
      type: Boolean,
      default() {
        return false;
      },
    },
    configs: {
      type: Object,
      default() {
        return {};
      },
    },
    previewRender: {
      type: Function,
    },
    toggleHeight: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      showModal:false,
      isValueUpdateFromInner: false,
      fullHeight: false,
    };
  },
  mounted() {
    if (this.autoinit) this.initialize();
  },
  deactivated() {
    const editor = this.simplemde;
    if (!editor) return;
    const isFullScreen = editor.codemirror.getOption('fullScreen');
    if (isFullScreen) editor.toggleFullScreen();
  },
  methods: {
    guideEvent(){
      this.showModal = !this.showModal;
    },
    initialize() {
      const configs = Object.assign({
        element: this.$refs.simplemde,
        styleSelectedText:false,
        spellChecker:false,
        initialValue: this.modelValue ,
        previewRender: this.previewRender,
        renderingConfig: {},
        showIcons:[
          'strikethrough',  'heading-1', 'heading-2',
          'heading-3', 'code', 'clean-block', 'table',
          'horizontal-rule' , 'undo' ,'redo' ],
        guideEvent:this.guideEvent,
      }, this.configs);

      if (configs.initialValue) {
        this.$emit('update:modelValue', configs.initialValue);
      }

      if (this.highlight) {
        configs.renderingConfig.codeSyntaxHighlighting = true;
      }
      this.simplemde = new SimpleMDE(configs);
      const className = this.previewClass || '';
      this.addPreviewClass(className);
      this.bindingEvents();

      this.$nextTick(() => {
        this.$emit('initialized', this.simplemde);
      });

      if(this.toggleHeight) {
        this.setEditorHeight(false);
      }
    },
    bindingEvents() {
      this.simplemde.codemirror.on('change', (instance, changeObj) => {
        if (changeObj.origin === 'setValue') {
          return;
        }
        const val = this.simplemde.value();
        this.handleInput(val);
      });

      this.simplemde.codemirror.on('blur', () => {
        const val = this.simplemde.value();
        this.handleBlur(val);
      });
    },
    addPreviewClass(className) {
      const wrapper = this.simplemde.codemirror.getWrapperElement();
      const preview = document.createElement('div');
      wrapper.nextSibling.className += ` ${className}`;
      preview.className = `editor-preview ${className}`;
      wrapper.appendChild(preview);
    },
    handleInput(val) {
      this.isValueUpdateFromInner = true;
      this.$emit('update:modelValue', val);
    },
    handleBlur(val) {
      this.isValueUpdateFromInner = true;
      this.$emit('blur', val);
    },
    setEditorHeight(full = true) {
      let height = full ? 300 : 50;
      let elements = $(this.simplemde.codemirror.getWrapperElement());
      elements = elements.add($(elements).find('.CodeMirror-scroll'));

      elements.css({height: height, 'min-height': height});
      this.fullHeight = full;
    }
  },
  destroyed() {
    this.simplemde = null;
  },
  watch: {
    'modelValue'(val , to) {

      if (this.isValueUpdateFromInner) {
        this.isValueUpdateFromInner = false;
      } else {
        this.simplemde.value(val ?? '');
      }
    },

    'fullHeight' (to) {
      if(to) {
        this.$emit('focusin');
        $(document).on('click.mta', (e) => {
          if(!$(e.target).closest('.mde-text-area').length) {
            this.setEditorHeight(false);
          }
        })
      } else {
        this.$emit('focusout');
        $(document).off('click.mta');
      }
    },
  },
}
</script>

<style scoped>

</style>