<template>
  <select v-model="selectedCompany" class="form-select mt-2" :class="{'is-invalid': !valid && showNotValid}" @change="selectCompany" ref="select">
    <option :value="null" disabled selected>От компании</option>
    <option v-for="company in companies" :value="company._id">{{company.name}}</option>
  </select>
</template>

<script>
export default {
  name: "Company",

  emits: ['selectCompany'],

  props: {
    company: String,
    companies: Array,
  },

  data() {
    return {
      valid: false,
      showNotValid: false,
      selectedCompany: null,
    }
  },

  mounted() {
    this.valid = this.validation();
  },

  methods: {
    validation() {
      return (typeof this.selectedCompany === 'string' && this.selectedCompany.length > 0);
    },

    isValid() {
      this.showNotValid = true;
      this.valid = this.validation();
      return this.valid;
    },

    selectCompany() {
      this.valid = true;
      this.$emit('selectCompany', this.selectedCompany ?? null);
    },

    focus() {
      if(this.$refs.select) {
        this.$refs.select.focus();
      }
    },

    setSelectedCompany(company) {
      this.selectedCompany = this.companies.find(c => c._id === company)?._id ??
                             null;
    }
  },

  watch: {
    company(company) {
      this.setSelectedCompany(company)
    },
    companies() {
      this.setSelectedCompany(this.company)
    }
  }
}
</script>

<style scoped>

</style>