<template>
<div>
  <span class="cursor-pointer" :class="{'fw-bolder': this.currentCurrency === 'USD'}" @click="changeCurrency('USD')">USD</span> / <span
    class="cursor-pointer"  :class="{'fw-bolder': this.currentCurrency === 'EUR'}" @click="changeCurrency('EUR')" >EUR</span> / <span
    class="cursor-pointer"  :class="{'fw-bolder': this.currentCurrency === 'RUB'}" @click="changeCurrency('RUB')" >RUB</span>
</div>
</template>

<script>
export default {
  name: "CurrencyBar",
  methods:{
   changeCurrency:function (currency){
     if (this.getStorePrefix()) {
       this.$store.dispatch(this.getStorePrefix() + '/changeCurrency', currency);
     }
   },
    getStorePrefix(){
     let prefix = null;
      if(['Buy', 'Sell'].indexOf(this.$route.name) !== -1){
        prefix = "offer";
      }else if(this.$route.name === 'History' ){
        prefix = "offerHistory";
      }
     return prefix;
    }
  },
  computed:{
    currentCurrency:function (){
      let code = "USD";
      if( this.getStorePrefix() ) {
        code = this.$store.getters[this.getStorePrefix() + "/getCurrentCurrency"];
      }
      return code;
    }
  }
}
</script>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
</style>