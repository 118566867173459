import store from "../../store/main/document";

export class Keyword {

    keyword;
    parent = null; // массив блоков, к которых встречается ключ  [{block: String (keyword), group: String}, ...]
    type = 'text';
    label = '';
    groupname = null;
    required = null;
    showConditions = [];
    warnings = null;

    showDeleteBtn = false;
    deleted = false;

    order = null;

    specialFields = [
        'ticketparam', 'personparam', 'companyparam',
        'pattern', 'dateformat', 'wordCase',
        'short', 'format', 'shortable',
        'declinable'
    ];
    declensionTypes = ['personparam', 'companyparam'];
    shortableOptions = {
        personparam: ['name', 'company.signer.name'],
        companyparam: ['company.signer.name']
    };

    options = {
        person: store.getters['document/getPersonParamOptions'],
        company: store.getters['document/getCompanyOptions'],
        declensionOptions: store.getters['document/getDeclensionOptions'],
    }

    formats = [
        {
            name: 'price',
            //default: '0 000,00 {symbol}',
            options: ['ticket.price1', 'ticket.priceall'],
            conventionalUnits: false,
        },
        {
            name: 'subnet_seller',
            //default: '{ip}/{prefix} {count} {type}',
            options: ['ticket.subnets.subnet'],
        },
        {
            name: 'subnet_buyer',
            //default: '/{prefix} {type}',
            options: ['ticket.prefix'],
        },
        {
            name: 'address',
            //default: '{index}, {city}, {address}',
            options: ['company.address.legal', 'company.address.real']
        }
    ]

    /**
     * @param keyword
     */
    constructor(keyword) {
        this.keyword = keyword.keyword ?? keyword.key;
        this.setParent(keyword?.parent ?? null);
        this.label = keyword?.label ?? '';
        this.groupname = keyword?.groupname ?? null;
        this.required = keyword?.required ?? null;
        this.showConditions = keyword?.showConditions ?? [];
        this.warnings = keyword?.warnings ?? null;
        this.showConditions = keyword?.showConditions ?? null;
        this.order = keyword?.order ?? null;

        if(typeof keyword?.type === 'string') {
            this.type = keyword.type;
        } else {
            this.autoSetFieldType();
        }
        this.specialFields.forEach(field => {
            if(typeof keyword[field] !== 'undefined' && keyword[field] !== null) {
                this[field] = keyword[field];
            }
        });
    }

    /**
     * Выполняется при загрузке шаблона поверх существующего
     * Здесь прописываются параметры, которые могли быть изменены в самом шаблоне
     * @param keyword
     */
    setOver(keyword) {
        this.setParent(keyword?.parent ?? null);
        this.required = keyword?.required ?? null;
        this.warnings = keyword?.warnings ?? null;
    }

    /**
     * Возвращает ключ, или группа:ключ, если у ключа один родитель с группой
     * @returns {string}
     */
    getUniqueName() {
        let name = this.keyword;
        if(Array.isArray(this.parent) &&
           this.parent.length === 1 &&
           typeof this.parent[0]?.group === 'string') {

            name  = `${this.parent[0].group}:${name}`;
        }
        return name;
    }

    /**
     * @param {null|string|array} parent
     */
    setParent(parent) {
        if(parent !== null) {
            if(typeof parent === 'string') {
                parent = [parent];
            }
            this.parent = parent.map(item => {
                let group, block;

                if(typeof item === 'string') {
                    if(item.includes(':')) {
                        [group, block] = item.split(':')
                    } else {
                        group = null;
                        block = item;
                    }
                } else {
                    return item;
                }

                return {group, block};
            });
        }
    }

    /**
     *
     * @param {string} type
     * @param typeParamValue
     */
    setType(type, typeParamValue = '') {
        this.specialFields.forEach(param => this[param] = null);

        this.type = type;

        if(['ticketparam', 'personparam', 'companyparam'].includes(type)) {
            this.setOption(typeParamValue)
        } else if(type === 'autogenerated') {
            this.pattern = '';
        } else if(type === 'date') {
            this.dateformat = 'DD.MM.YYYY';
        }
    }

    setOption(option, label = null) {
        if(label !== null) {
            this.label = label;
        }

        this.specialFields.forEach(field => this[field] = undefined);
        this[this.type] = option;

        this.shortable = this.isShortable();
        this.declinable = this.isDeclinable();
        this.format = this.getFormat();
    }

    /**
     * Пытается определить тип поля по ключу, и устанавливает его
     */
    autoSetFieldType() {
        if(this.keyword.indexOf('client.') === 0) {
            let replaced = this.keyword.replace('client.', '');

            if(replaced === 'email') {
                replaced = 'contact_emails.mail';
            }

            if (typeof this.options.person[replaced] === 'string') {
                this.label = this.options.person[replaced];
                this.setType('personparam', replaced);
            }
        }
        else if(this.keyword.indexOf('company.') === 0) {
            if(typeof this.options.company[this.keyword] === 'string') {
                this.label = this.options.company[this.keyword];
                this.setType('companyparam', this.keyword);
            }
        }
    }

    /**
     * Выбран тип который имеет склоняемые опции, и опция, у которой может быть выбран падеж
     * @returns {boolean}
     */
    isDeclinable() {
        return this.declensionTypes.includes(this.type) &&
               this.options.declensionOptions.includes(this[this.type] ?? null);
    }

    /**
     * Выбрана опция, значение которой может быть сокращено
     */
    isShortable() {
        let shortable = false;

        if(Object.keys(this.shortableOptions).includes(this.type)) {
            if(this.shortableOptions[this.type].includes(this?.[this.type])) {
                shortable = true;
            }
        }

        return shortable;
    }

    getFormat() {
        let format = null;

        if(this.specialFields.includes(this.type)) {
            let option = this[this.type] ?? null;
            if(option !== null) {
                format = this.formats.find(format => format.options.includes(option)) ?? null;
                if(format !== null) {
                    format = _.cloneDeep(format);
                    format.value = format.default;
                    format.options = undefined;
                }
            }
        }
        if(format !== null) {
            if(typeof this.format !== 'string') {
                this.format = format.default;
            }
        } else {
            this.format = undefined;
        }

        return format;
    }

    /**
     * @param {Block} block
     * @returns {boolean}
     */
    isChildOf(block) {
        return Array.isArray(this.parent) && this.parent.length === 1 &&
               ((this.parent[0].group ?? null) === (block.group ?? null)) && this.parent[0].block === block.keyword;
    }

    prepareForSave() {
        let result = {
            key: this.keyword,
            label: this.label,
            type: this.type,
            required: this.required,
            showConditions: this.showConditions,
            order: this.order,
        };

        let fieldsList = [...this.specialFields].concat(['group', 'groupname']);
        fieldsList.forEach(field => this[field] && (result[field] = this[field]));

        if(this.parent !== null) {
            result.parent = this.parent.map(parent =>
                parent?.group
                    ? `${parent.group}:${parent.block}`
                    : parent.block
            );
        }

        return result;
    }

    delete() {
        this.deleted = true;
    }

    deleteParent(block) {
        // удаляем блок block из списка родителей
        this.parent = this.parent.filter(parent => !(parent.block === block.keyword &&
                                                    parent.group === block.group));
        if(this.parent.length === 0) {
            // если после удаления не осталось родителей, то значит ключ больше нигде не используется - удаляем
            this.delete();
        }
    }

}