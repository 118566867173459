<template>
  <template v-for="events  in getListByRow" >
    <tbody>
      <events-row v-for="event in events" :class="{'loading-row':isLoading}"  :offer-event="event" :id="event.key"
                  style="padding-bottom: 5px"
      ></events-row>
    </tbody>
  </template>
</template>

<script>
import EventsRow from "./EventsRow";
import {mapGetters} from "vuex";
export default {
  name: "EventsBody",
  components: {EventsRow},
  computed:{
    ...mapGetters({
      'getList':'offerEvents/getEvents',
      'getListByRow':'offerEvents/getListByRow',
      'isLoading':'offerEvents/getLoading',
    }),
  },
}
</script>

<style scoped>
.loading-row{
  pointer-events: none;
  opacity: 50%;
}

tbody:nth-child(odd) {
  background: #f2f2f2;
}

tbody:hover td[rowspan], tr:hover td {
  background:rgba(0, 0, 0, 0.075);
}
</style>