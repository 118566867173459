<template>
  <button class="btn btn-light btn-outline-dark col-12" @click="open" v-if="ticketsState.ticketSelectedControl.length === 1"><i class="fa fa-folder-open button-icon"></i> Открыть</button>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: "BillmanagerMenu",

  computed: {
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getSelectedArray": "tickets/getSelectedArray",
      'countSelect':'tickets/selectedCount'
    }),
  },

  methods: {
    open: function() {
      window.open('https://my.i7.net/billmgr?elid=' + this.getSelectedArray[0] + '&plid=&startform=ticket_all.edit', '_blank');
    }
  }
}
</script>

<style scoped>

</style>