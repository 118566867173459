<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Клиент изменил свое имя с <span>"{{params.old}}"</span> на
      <span>"{{params.new}}"</span>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "ChangePersonName",
  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  methods: {
  }
}
</script>

<style scoped>

</style>