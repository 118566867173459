import * as getters from "../common/getters";
import * as actions from "../common/actions";

import {shareState} from "../common/composition";
import commonMutations from "../common/mutations";
import { createStore} from "vuex";
import {moduleOffers} from "./offerState";


export default createStore ( {
    namespaced: true,
    state:() => (shareState),
    actions:  actions,
    getters: getters,
    mutations:commonMutations,
    modules:{
        'offer':moduleOffers,
    },
    plugins: []
})