<template>
  <div>
    <!-- div style="margin-bottom: 10px;">{{getUpperText}}</div>
    <div class="form-check" style="margin-bottom:10px;">
      <input class="form-check-input" type="checkbox" @change="resetState()"  v-model="source.handOver"  id="handOver">
      <label class="form-check-label" for="handOver" >
        Передать полностью
      </label>
    </div -->
    <div class="row" style=" padding-bottom: 10px">
      <div style="display: flex">
        <div class="col-4">
          <input type="radio" class="btn-check" name="options-outlined" id="connect-projects" autocomplete="off" checked value="connect" v-model="source.handOver">
          <label style="border-radius: 4px 0px 0px 4px;" class="d-grid btn btn-outline-secondary switch-btn" for="connect-projects">Подключить проект(ы)</label>
        </div>
        <div class="col-4" >
          <input type="radio" class="btn-check" name="options-outlined" id="transfer-to-project" autocomplete="off" value="transferProject" v-model="source.handOver" >
          <label style="border-radius: unset; border-left: none; border-right: none;" class="d-grid btn btn-outline-secondary switch-btn" for="transfer-to-project">Перенести в проект</label>
        </div>
        <popper :disabled="!disabledAttachToTicket" content="Тикет с заполненным полем Продавец или Покупатель, нельзя прикрепить к другому запросу" class="w-100 warning" placement="top" :hover="true" :interactive="false" :arrow="true">
          <div>
            <input type="radio" class="btn-check" name="options-outlined" id="transfer-to-ticket" autocomplete="off" value="transferTicket" v-model="source.handOver" >
            <label style="border-radius: 0px 4px 4px 0px;" class="d-grid btn btn-outline-secondary switch-btn" :class="{disabled: disabledAttachToTicket}" for="transfer-to-ticket">Объединить с тикетом</label>
          </div>
        </popper>
      </div>
    </div>

    <div>

      <div v-if="source.handOver === 'connect'">
        <multiselect
          v-model="values"
          @clear="connectSelectClear"
          @deselect="connectSelectDeselect"
          @select="connectSelectSelect"
          :placeholder="placeholder"
          :mode="'tags'"
          :close-on-select="false"
          :searchable="true"
          :options="projects"
          ref="multy"
        ></multiselect>
        <div v-if="!isAllSimilar" class="text-muted mt-1" style="font-size: 12px;">
          Групповая операция может быть выполнена только для общих проектов тикетов
        </div>
      </div>

      <div v-if="source.handOver ==='transferProject'">
        <select @change="changeState([oneValue])" v-model="oneValue" :class="{'select-grey': (oneValue === '')}"  id="select_project" class="form-select  form-select-sm" aria-label="nothing">
          <option disabled style="color: #9ba3af" value="">Выберите проект, который станет основным</option>
          <option  v-for="project in projects" :value="project.value" >
            {{ project.label }}
          </option>
        </select>
      </div>

      <template v-if="source.handOver ==='transferTicket'">
        <search-ticket
            ref="selT"
            :selected-objects="selectedObjects"
            @selectElement="setSelectedTicket"
        />
      </template>

    </div>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {CrmApi} from "../../../../library/CrmApi";
import {mapGetters, mapMutations} from "vuex";
import {changeMultySelectStyles} from "../../../ticket/Api/api";
import SearchTicket from "../addToProject/SearchTicket";
import ModalWindow from "../ModalWindow";

export default {
  name: "ModalBody",

  components: {Multiselect, SearchTicket, ModalWindow},
  emits: ["changeState", 'changeTicket', 'showAttachField', 'changeStateConnect',],

  data:function (){
    return {
      projects:[],
      showSearch: false,
      values:[],
      oneValue:'',
      ticketId: null,

      currentProjects:[],
      countObjects: 0,
      placeholder:"Выберите проект",
      isAllSimilar: true,
      sharedValues: [],
    }
  },

  props:{
    "selectedObjects":{
      type: Object,
    },
    "source":{
      type:Object
    }
  },
  methods:{
    showTickets(value){
      this.showSearch = value;
      this.$emit('showAttachField', value);

      if(!value){
        if(this.$refs?.selT){
          this.$refs.selT.reset();
        }


        this.setSelectedTicket('');
      }else {
        this.$nextTick(()=>{
          if( this.$refs?.selT){
            this.$refs.selT.reset();
          }

          this.setSelectedTicket('');
        })
      }
    },
    changeValues(){
      let o =  {ticketId:this.ticketId , projectId:this.oneValue};

      this.$emit("changeValues", o)
    },

    connectSelectSelect(value) {
      this.changeStateConnect(value, 'select');
    },

    connectSelectDeselect(value) {
      this.changeStateConnect(value, 'deselect');
    },

    connectSelectClear(value) {
      this.changeStateConnect(value, 'clear');
    },

    changeStateConnect(value, action) {
      if (this.isAllSimilar) {
        this.changeState(value);
      } else {
        this.$emit('changeStateConnect', action === 'clear' ? this.sharedValues : value, action);
      }
    },

    changeState(value) {
      if(this.showSearch && this.$refs?.selT){
        this.$refs.selT.reset();
      }

      this.setSelectedTicket('');
      this.$emit('changeState', (value === 'reset') ? 'reset' : (this.$refs.multy?.plainValue ?? value));
    },

    changeTicket(value, title) {
      this.$emit('changeTicket', value, title);
    },

    resetState(){
      this.values = [];
      this.oneValue = "";
      this.changeState('reset');
      this.changeTicket();
      this.showTickets(false);
    },

    setSelectedTicket(id, title){
      this.ticketId = id;
      this.changeTicket(id, title);
    },

    ...mapMutations({
      'extendedErrorPush':'extendedErrorPush',
    }),

  },
  computed:{
    disabledAttachToTicket: function() {
      let disabled = false;
      Object.values(this.selectedObjects).forEach(ticket => {
        if(window.isId(ticket?.assigned_product?.fields?.buyer) ||
           window.isId(ticket?.assigned_product?.fields?.seller)) {

            disabled = true;
        }
      });
      return disabled;
    },

    getProjectName:function (){
      return this.currentProjects.join(", ");
    },
    getUpperText:function (){
      let t = this.countObjects > 1 ? "тикетов" : "тикета";
      let p = this.currentProjects.length > 1 ? "Основные проекты" : "Основной проект";
      return p + " "+ t+": " + this.getProjectName;
    }
  },
  mounted() {
    this.source.handOver = 'connect';

    changeMultySelectStyles(document);

    let uniqueProjectNames = {};
    let sharedProjects = {};
    let isAllSimilar = true;
    this.countObjects = 0;
    let isAllTicketsHasNotAssigned = true;

    for (let key in this.selectedObjects) {
      let object = this.selectedObjects[key];
      this.countObjects++;
      uniqueProjectNames[object.project.name] = object.project.name;
      if(typeof object.assigned_projects !== 'undefined' && object.assigned_projects.length !== 0){
        isAllTicketsHasNotAssigned = false;
        for (let project of object.assigned_projects){
          let obj;
          if (typeof sharedProjects[window.getId(project._id)] !== 'undefined') {
            obj = sharedProjects[window.getId(project._id)];
          } else {
            obj = {
              value: window.getId(project._id),
              label: project.name,
            }
          }
          if (typeof obj.count === 'undefined') {
            obj.count = 1;
          } else {
            obj.count++;
          }
          sharedProjects[window.getId(project._id)] = obj;
        }
      }
    }
    if (isAllTicketsHasNotAssigned) {
      this.placeholder = this.countObjects > 1 ?
          "Тикеты не имеют назначенных проектов" :
          "Тикет не имеет назначенных проектов";
    }
    let sharedIDs = [];
    for (let key in sharedProjects) {
      let sharedProject = sharedProjects[key];
      if (sharedProject.count === this.countObjects) {
        sharedIDs.push(sharedProject.value);
      } else {
        isAllSimilar = false;
        this.placeholder = "Выбранные тикеты имеют различные назначенные проекты";
      }
    }

    this.source.connectType = isAllSimilar ? 'full' : 'partial';
    this.isAllSimilar = isAllSimilar;
    this.currentProjects = Object.keys(uniqueProjectNames);
    this.projects = [];

    for (let elem of this.$store.getters['getProjects']) {
      if (this.currentProjects.length !== 1 || this.currentProjects.indexOf(elem.name) === -1) {
        this.projects.push({value: elem._id, label: elem.name});
      }
    }
    this.values = sharedIDs;
    this.sharedValues = sharedIDs;
  },

  watch: {
    'source.handOver' () {
      this.resetState();
    }
  }
}
</script>

<style scoped>
.select-grey {
  color: #9ba3af;
}
.warning {
  display: block !important;
  width: fit-content;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
</style>