<template>
<div>
  <table id="table-offer-list" class="table table-bordered table-hover table-striped table-container" >
    <thead class="thead-dark" style="z-index:20">
    <filter-table type="buy"></filter-table>
    <tr>
      <th
          @click="sortBy('assigned_product.fields.prefix')"
          :class="sortedClass('assigned_product.fields.prefix')"
          class="text-center"
          style="min-width: 150px; width: 13%; cursor: pointer;"
      >Подсеть</th>
      <th
          @click="sortBy('assigned_product.fields.price1usd')"
          :class="sortedClass('assigned_product.fields.price1usd')"
          class="text-center"
          style="cursor: pointer; min-width: 200px; width: 19%;"
      >Цена</th>
      <th
          @click="sortBy('assigned_product.fields.date')"
          :class="sortedClass('assigned_product.fields.date')"
          class="text-center"
          style="cursor: pointer; min-width: 120px; width: 11%;"
      >Дата покупки</th>
      <th class="text-center" style="min-width: 200px; width: 19%;">Статус</th>
      <th
          @click="sortBy('person.email')"
          :class="sortedClass('person.email')"
          class="text-center"
          style="min-width: 200px; width: 19%; cursor: pointer;"
      >Клиент</th>
      <th class="text-center" style="min-width: 200px;width: 19%;">Комментарий</th>
    </tr>
    </thead>
    <tbody>
      <buy-body></buy-body>
    </tbody>
  </table>
  <loading-list v-if="loading"></loading-list>
</div>
</template>

<script>
import BuyBody from "./BuyBody";
import {getLoading, getOrderBy} from "../Computeds";
import LoadingRow from "../LoadingRow";
import sortBy from "../../source/sortBy";
import FilterTable from "../../filters/FilterTable";
import LoadingList from "../../../ticket/List/LoadingList";

export default {
  name: "BuyList",
  components: {LoadingRow, BuyBody, FilterTable, LoadingList},
  setup(props){
    const {loading} = getLoading();
    const {getOrderByField, getOrderByDirection} = getOrderBy();

    return {
      loading,
      sortBy,
      getOrderByField,
      getOrderByDirection,
    }
  },
  methods: {
    sortedClass(field) {
      return {
        'sorted-down': this.getOrderByField === field && this.getOrderByDirection === 1,
        'sorted-up': this.getOrderByField === field && this.getOrderByDirection === -1,
      }
    }
  }
}
</script>

<style scoped>
.table{
  table-layout: auto;
}
.sorted-down::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.sorted-up::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}
</style>