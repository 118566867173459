<template>
  <div class="tags">
    <span class="additional-info" v-for="tag in tags">
      <a
        :class="popperClass(tag)"
        href=""
        @click.prevent="openModal"
      >{{tag}}</a>
    </span>
    <teleport to="body">
      <transition name="modal">
        <tags-modal :callback="callbackModal" v-if="showModal" @close="closeModal"></tags-modal>
      </transition>
    </teleport>
  </div>
</template>

<script>
import TagsModal from "../tags/TagsModal";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  name: "Tags",
  components: {
    TagsModal,
  },
  props: {
    'tags': {
      type: Array,
      require: true,
    },
    type: {
      type: String,
      default: 'ticket',
    },
    ticket: {
      default: null,
    }
  },

  data: function () {
    return {
      showModal: false,
    }
  },

  computed: {
    ticketId() {
      return this.$route.params.id ?? ((this.ticket !== null) ? window.getId(this.ticket._id) : null);
    },
    callbackModal() {
      return this.type === 'ticket' ? this.callback : null;
    },
  },

  methods: {
    popperClass(tag) {
      return {
        "spam": this.isSpam(tag),
      }
    },
    isSpam(tag) {
      return tag.match(/спам/i);
    },
    openModal() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketTags');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketTags');
    },
    callback() {
      this.reloadTicket();
      this.$store.commit('successPush', 'Метки успешно изменены', {root:true});
    },

    reloadTicket: function() {
      if (this.$route?.name === "Ticket") {
        // TODO replace in task https://track.i7.org/issue/CRM-448/Perenos-v-store-obnovlenie-elementov-v-tikete
        return CrmApi.getTicketList({
          "_id": this.ticketId,
          "withDeleted": true,
        }).then((result) => {
          this.$store.commit('thread/setTicket', result.data.data[0]);
          this.$store.dispatch('tickets/selectElement', result.data.data[0]);
        });
      }
    },
  },
}
</script>

<style scoped>
.tags {
  list-style:none;
  display: contents;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 12px;
}
.tags span, .tags a {
  display: inline-block;
  height:12px;
  line-height:12px;
  position:relative;
}
.tags {
  --tag-color: #0089e0;
}
.tags a {
  margin-left:10px;
  padding:0 3px 0 3px;
  background: var(--tag-color);
  color:#fff;
  text-decoration:none;
  -moz-border-radius-bottomright:4px;
  -webkit-border-bottom-right-radius:4px;
  border-bottom-right-radius:4px;
  -moz-border-radius-topright:4px;
  -webkit-border-top-right-radius:4px;
  border-top-right-radius:4px;
}
.tags a::before {
  content:"";
  float:left;
  position:absolute;
  top:0;
  left:-8px;
  width:0;
  height:0;
  border-color:transparent var(--tag-color) transparent transparent;
  border-style:solid;
  border-width:6px 8px 6px 0;
}
.tags a::after {
  content:"";
  position:absolute;
  top:5px;
  left:-3px;
  float:left;
  width:3px;
  height:3px;
  -moz-border-radius:2px;
  -webkit-border-radius:2px;
  border-radius:10px;
  background:#fff;
  -moz-box-shadow:-1px -1px 2px var(--tag_color);
  -webkit-box-shadow:-1px -1px 2px var(--tag_color);
  box-shadow:-1px -1px 2px var(--tag_color);
}
.tags a:hover {
  background:#555;
}

.tags a:hover::before {
  border-color:transparent #555 transparent transparent;
}

.spam {
  --tag-color: red;
}
</style>