<template>
  <template v-for="keyword in keywords">

    <person-param v-if="keyword.type === 'personparam'"
                 :keyword="keyword"
                 :params="params"
                 :disabled="keyword.disabled ?? loading.params"
                 :can-change-person="keyword.canChange ?? true"
                 :input-type="keyword.inputType ?? 'input'"
                 :required="keyword.required ?? true"
                 :option-callback="keyword.optionCallback ?? null"
                 :option-set-first-element-as-value="false"
                 :dropdown-label="keyword.dropdownLabel ?? null"
                 @update="(val, autoset) => update(keyword.key, val, autoset)"
                 ref="field" />

    <!--text-param v-if="keyword.type === 'text'"
                :label="keyword.label"
                @update="val => update(keyword.key, val)" /-->

  </template>
</template>

<script>
import PersonParam from "./Params/PersonParam";
import TextParam from "./Params/TextParam";
import {Documents} from "../../../../library/api/Documents";
import {CrmApi} from "../../../../library/CrmApi";
import {mapGetters} from "vuex";

export default {
  name: "TransferAgreement",

  emits: ['generated', 'update'],

  components: {
    PersonParam,
    TextParam
  },

  props: {
    ticketId: {},
  },

  data() {
    return {
      params: {
        type: ['Offering', 'Receiving'],
      },
      loading: {
        params: false,
        o_namefunction: false,
        r_namefunction: false,
        companies: false,
      },
      valid: true,

      fields: {
        subnets: null,
        offering_ogrn: null,
        receiving_ogrn: null,
        receiving_org: null,
        name: null,
        function: null,
      },

      companies: null,

      keywords: [
        {
          key: 'subnets',
          label: 'IPs / AS',
          type: 'personparam',
          personparam: 'subnets',
          inputType: 'textarea'
        },
        {
          key: 'offering_ogrn',
          label: 'Offering OGRN',
          type: 'personparam',
          personparam: 'offering_ogrn',
          canChange: false,
          disabled: () => this.loading.params || this.loading.companies,
          optionCallback: (companyName) => this.setCompany('o', companyName),
          dropdownLabel: 'Компания'
        },
        {
          key: 'receiving_ogrn',
          label: 'Receiving OGRN',
          type: 'personparam',
          personparam: 'receiving_ogrn',
          canChange: false,
          disabled: () => this.loading.params || this.loading.companies,
          optionCallback: (companyName) => this.setCompany('r', companyName),
          dropdownLabel: 'Компания'
        },
        {
          key: 'receiving_org',
          label: 'Receiving ORG',
          type: 'personparam',
          personparam: 'receiving_org',
          canChange: false,
        },
        {
          key: 'o_namefunction',
          label: 'Offering Name of authorised person, Function',
          type: 'personparam',
          personparam: 'o_namefunction',
          canChange: false,
          disabled: () => this.loading.params || this.loading.o_namefunction,
          required: false,
        },
        {
          key: 'r_namefunction',
          label: 'Receiving Name of authorised person, Function',
          type: 'personparam',
          personparam: 'r_namefunction',
          canChange: false,
          disabled: () => this.loading.params || this.loading.r_namefunction,
          required: false,
        }
      ],
    }
  },

  mounted() {
    this.loadParams();
    this.loadCompanies();
  },

  computed: {
    ...mapGetters({
      ticket: "thread/getTicket",
    }),
  },

  methods: {
    focusInvalid() {
      if(Array.isArray(this.$refs?.field)) {
        this.$refs.field.some(field => {
          if(!field.isValid()) {
            field.focus();
            return true;
          }
        })
      }
    },

    submit() {
      return Documents.generateTransferAgreement(this.ticketId, this.fields).then(result => {
        this.$emit('generated', result.data.file);
      }).catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));
    },

    isValid() {
      let valid = true;

      if(Array.isArray(this.$refs?.field)) {
        let validArray = [];
        this.$refs.field.forEach(field => {
          validArray.push(field.isValid());
        });
        valid = !validArray.some(value => !value);
      }

      return valid;
    },

    update(key, value, autoset = false) {
      if(this.fields[key] !== value && ['offering_ogrn', 'receiving_ogrn'].includes(key)) {
        this.loadNameFunction(key, value);
      }

      this.fields[key] = value;
      this.params[key] = value;

      if(!autoset) {
        this.$emit('update');
      }
    },

    loadCompanies() {
      if(this.ticket?.assigned_product?.fields?.buyforus || this.ticket?.assigned_product?.fields?.oursubnet?.our) {
        this.loading.companies = true;
        Documents.getTransferAgreementCompanies(this.ticketId)
                 .then(result => this.companies = result.data)
                 .finally(() => this.loading.companies = false);
      }
    },

    setCompaniesOptions() {
      let options = {};
      this.companies.forEach(company => options[company.name] = company.ogrn);

      if(this.ticket?.assigned_product?.fields?.buyforus) {
        this.params.receiving_ogrn = options;
      } else if(this.ticket?.assigned_product?.fields?.oursubnet) {
        this.params.offering_ogrn = options;
      }
    },

    setCompany(type, companyName) {
      let company = this.companies.find(company => company.name === companyName);
      if(company?.signer) {
        this.params[type + '_namefunction'] = company.signer?.name +
                                                (typeof company.signer?.position === 'string'
                                                            ? ', ' + company.signer.position
                                                            : '');
      }
    },

    loadParams() {
      this.loading.params = true;

      Documents.threadTransferAgreementParam(this.ticketId).then(result => {
        Object.entries(result.data)
              .forEach(([key, value]) => this.params[key] = value);
      }).catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
        .finally(() => this.loading.params = false);
    },

    loadNameFunction(field, value) {
      let namefunctionField = field[0] + '_namefunction';

      if(value.match(/^([0-9]{13}|[0-9]{15})$/)) {
        this.loading[namefunctionField] = true;

        Documents.threadTransferAgreementParam(this.ticketId, value).then(result => {
          if(this.fields[field] === value && result.data.namefunction) {
            this.params[namefunctionField] = result.data.namefunction;
          }
        }).finally(() => this.loading[namefunctionField] = false);
      }
    },
  },

  watch: {
    companies(companies) {
      if(Array.isArray(companies) && companies.length > 0) {
        this.setCompaniesOptions();
      }
    }
  }
}
</script>

<style scoped>

</style>