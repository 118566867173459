<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} снял(а) {{ params.subnet || params.subnets.join(', ') }} с продажи
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "RemoveLotFromSubnet",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event)
}
</script>

<style scoped>

</style>