<template>
  <div style="margin-top: 20px;">
    <div class="col-md-7 m-auto">
      <div class="row">
        <div class="col-lg-12 pb-2 justify-content-end" v-if="newLink">
          <a :href="newLink" class="btn btn-primary pull-right me-2">
            <i class="fa fa-plus-square pe-1" aria-hidden="true"></i>
            Добавить
          </a>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "EntityList",
  props:{
    'newLink':{
      type:String,
      require:true,
    }
  }
}
</script>

<style scoped>

</style>