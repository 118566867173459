<template>
  <div :class="{disabled: disabled}" class="editable-date-container">
    <span
        v-if="!asInput"
        v-clipboard="clipboard"
        class="editable-text text-primary"
        @dblclick="showInput()"
    >{{ showValue }}</span>
    <date-picker
      v-if="asInput"
      v-model="textProxy"
      ref="datepicker"
      :is24hr="true"
      :mode="mode"
      :model-config="modelConfig"
      :locale="locale"
      @dayclick="inputFocus"
      @focusout="focusoutCheck"
      @keyup.enter.stop="edit"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <input
            ref="editInput"
            v-on="inputEvents"
            :class="classValue"
            :value="inputValue"
            :placeholder="placeholder"
            @keyup.enter.stop="edit"
            @keyup.esc.stop="cancel"
        />
      </template>
    </date-picker>
  </div>
</template>

<script>
import moment from "moment";
import {DatePicker} from "v-calendar";
import 'v-calendar/dist/style.css';

export default {
  name: "EditableDate",

  components: {
    DatePicker
  },

  emits: ["change", "close", "open"],

  props: {
    text: {
      require: true
    },
    default: {
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    callback: {
      type: Function,
    },
    cancelCallback: {
      type: Function,
    },
    callbackParams: {
      default: {},
    },
    inputClass: {
      type: String,
      default: "text-center"
    },
    placeholder: {
      type: String,
      default: ""
    },
    inputStyle: {
      type: String,
    },
    startAsInput: {
      type: Boolean,
      default: false,
    },
    clipboard: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    userChangeParam: {
      type: Boolean,
      default: null
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    formatLabel: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    formatDatepicker: {
      type: String,
      default: 'DD.MM.YYYY',
    },
    mode: {
      type: String,
      default: 'date',
    }
  },

  data: function () {
    return {
      textProxy: null,
      asInput: false,
      stopDocumentKeydownHandler: null,
      isValid: false,
      locale: "ru"
    }
  },

  computed: {
    showValue: function() {
      let result = '';
      if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
        result = this.default;
      } else {
        let text = this.mode === 'date' && this.text.indexOf('T') !== -1 ? this.text.split('T')[0] : this.text;
        result = this.parseMoment(text, this.formatDatepicker, this.formatLabel, this.default);
      }
      return result;
    },

    classValue: function() {
      let c = this.inputClass;
      if(!this.isValid) {
        c = c + ' is-invalid';
      }
      return c;
    },

    modelConfig() {
      return {
        type: 'string',
        mask: (this.formatDatepicker) ? this.formatDatepicker : 'DD.MM.YYYY'
      };
    }
  },

  mounted: function() {
    this.textProxy = this.text;
    if (this.startAsInput) {
      this.asInput = true;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      })
    }
  },

  methods: {
    parseMoment(value, backupFormat = 'DD.MM.YYYY', outputFormat = 'DD.MM.YYYY', defaultOutput = '') {

      let momentObject = moment(value);
      if (!momentObject.isValid()) {
        momentObject = moment(value, backupFormat);
      }
      return momentObject.isValid() ? momentObject.format(outputFormat) : defaultOutput;
    },
    openText: function (){

    },
    inputFocus() {
      this.$refs.editInput.focus();
    },
    focusoutCheck(event) {
      let can = true;
      if (typeof event.explicitOriginalTarget !== 'undefined' && typeof this.$refs.datepicker?.$el !== 'undefined') {
        if (
            event.explicitOriginalTarget === this.$refs.datepicker.$el ||
            this.$refs.datepicker.$el.contains(event.explicitOriginalTarget)
        ) {
          can = false;
        }
      }

      if (can && (
          !this.asInput ||
          typeof this.$refs?.datepicker?.$el === 'undefined' ||
          event.relatedTarget === null ||
          !this.$refs.datepicker.$el.contains(event.relatedTarget))
      ) {
        this.cancel();
      }
    },
    momentFormat(value) {
      let result = value;
      if (this.asInput && this.$refs['datepicker'] && typeof this.$refs['datepicker']?.$locale?.masks?.L !== 'undefined') {
        result = moment(result, this.$refs['datepicker'].$locale.masks.L).format(this.formatLabel);
      }
      return value;
    },
    edit: function(e) {
      this.checkValidation();

      if(this.isValid) {
        if (typeof this.callback === 'function') {
          this.callback(
              moment(this.textProxy, this.formatDatepicker),
              this.callbackParams
          );
        }

        this.$emit("change", this.textProxy, this.callbackParams);
        this.$emit("close", this.textProxy, this.callbackParams);
        this.asInput = false;
      } else if (this.textProxy === '') {
        this.textProxy = this.text;
      }
    },

    cancel: function() {
      this.textProxy = this.text;
      document.onkeydown = this.stopDocumentKeydownHandler;
      this.$emit("close", this.text, this.callbackParams);
      this.asInput = false;
    },

    showInput: function() {
      this.asInput = true;

      this.checkValidation(this.text);

      this.stopDocumentKeydownHandler = document.onkeydown;
      document.onkeydown = null;

      this.$nextTick(function() {
        this.$refs.editInput.focus();
        this.$refs.editInput.select();
      }.bind(this));

      this.$emit('open');
    },

    checkValidation: function(text = null) {
      text = (typeof text === 'string') ? text : this.textProxy;

      if(typeof this.validation === 'function') {
        this.isValid = this.validation(text);
      } else {
        this.isValid = this.required ? !['', null].includes(text) : true;
      }
    }
  },
  watch:{
    text(to, from){
      if(to !== this.textProxy){
        this.textProxy = to;
      }
    }
  }
}
</script>

<style scoped>
.editable-text {
  cursor: pointer;
}
.disabled {
  pointer-events: none;
}
.disabled > span {
  color: black!important;
}
.editable-date-container:deep(.vc-time-content) {
  flex-grow: 1;
}
.editable-date-container:deep(.vc-time-select) {
  width: 100%;
}
.editable-date-container:deep(.vc-time-select .vc-select) {
  width: calc(50% - 4px);
}
.editable-date-container:deep(.vc-time-select .vc-select > select) {
  width: 100%;
}
</style>