<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Тема изменена: <span :class="{'fst-italic': emptyString(params.old)}">"{{getTicketName(params.old)}}"</span> на
                     <span :class="{'fst-italic': emptyString(params.new)}">"{{getTicketName(params.new)}}"</span>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "ChangeSubject",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  methods: {
    emptyString(field) {
      return typeof field !== 'string' || field.length === 0;
    },

    getTicketName(field) {
      return this.emptyString(field) ? 'Без темы' : field;
    }
  }
}
</script>

<style scoped>

</style>