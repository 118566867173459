import {Offer} from "../../../../library/api/Offer";
import {CrmApi} from "../../../../library/CrmApi";

function checkObjects(paramsToCheck, first, second) {
    let isParamsChange = false;
    for (let key in second) {
        if (typeof paramsToCheck[key] !== 'undefined') {
            if (typeof first[key] === 'undefined' || second[key] !== first[key]) {
                isParamsChange = true;
                break;
            }
        }
    }
    return isParamsChange;
}
function isParamsChange(oldQuery , newQuery, filters) {
    let isParamsChange = false;
    let paramsToCheck = {
        page:true,
        onpage:true,
        search:true,
    }
    isParamsChange = checkObjects(paramsToCheck, oldQuery , newQuery);
    if(!isParamsChange){
        isParamsChange = checkObjects(paramsToCheck , newQuery , oldQuery)
    }
    paramsToCheck = {};
    if(!isParamsChange){
        for (let el of filters){
            paramsToCheck[el.field] = true;
        }
        isParamsChange = checkObjects(paramsToCheck, oldQuery , newQuery);
    }
    if(!isParamsChange){
        isParamsChange = checkObjects(paramsToCheck , newQuery , oldQuery)
    }

    return isParamsChange;
}

const offerActions = {
    setPagination({commit, dispatch, getters} , {page, onpage, total, paginator_links}){
        let paginate = getters.getPaginate;
        let updateOffers = (paginate.page != page || paginate.onpage != onpage);

        page            && commit('setPage', page);
        onpage          && commit('setOnPage', onpage);
        total           && commit('setTotal', total);
        paginator_links && commit('setLinks', paginator_links);

        updateOffers && dispatch('updateOffersList');
    },

    updateOffersList({commit, dispatch}) {
        commit('setLoading' , true);
        dispatch('getOffers', {}).finally(() => {
            commit('setLoading' , false);
        })
    },

    getOffers({state, getters, rootGetters, commit, dispatch}) {
        commit('setLoading', true);
        return new Promise((resolve , reject)=>{
            let type = state.offersTableType === 'buyer' ? 'buy' : 'sell';
            let request = { ...getters.getFilters };
            request.possibleToAssign = true;

            let ticketFields = rootGetters['thread/getTicket']?.assigned_product?.fields;
            if( ticketFields?.buyer  ||
                ticketFields?.seller ) {

                request.possibleToAssignExcept = window.getId(ticketFields?.buyer ?? ticketFields?.seller);
            }

            request.page = getters.getPaginate.page;
            request.onpage = getters.getPaginate.onpage;
            let oldpaginator = Object.assign({}, state.paginator);

            if(typeof request.page !== 'undefined') {
                commit('setPage', request.page)
            }
            if(typeof request.onpage !== 'undefined') {
                commit('setOnPage', request.onpage)
            }
            if(typeof request.oB !== 'undefined') {
                request.oB = JSON.stringify(request.oB);
            }

            let oldVal = Object.assign({}, getters.getFilters);
            Offer.getList(type, request,)
                .then((result)=>{
                    if(!isParamsChange(oldVal , getters.getFilters, getters['filters/getFiltersArray'])) {
                        let list = result.data.data;

                        commit('clearOffers');
                        commit('clearPersons');
                        commit('setPersons', {persons: list.map(item => item.person)});
                        commit('setOffers', {offers:list});
                        dispatch('setPagination',result.data );
                    }

                    resolve();
                })
                .catch((error)=>{
                    if (error?.message !== 'Request aborted') {
                        commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
                    }
                    dispatch('setPagination',oldpaginator)
                    reject();
                }).finally(() => commit('setLoading', false));
        })
    },

    showTable({state}) {
        if($('.messages-background').length) {
            state.ticketScrollPosition = $('.messages-background').scrollTop();
        }
        state.showOffersTable = true;
    },

    showSellerOffersTable({state, dispatch}, callback = null) {
        state.offersTableType = 'seller';
        state.offersCloseCallback = callback;
        dispatch('showTable');
    },

    showBuyerOffersTable({state, dispatch}, callback = null) {
        state.offersTableType = 'buyer';
        state.offersCloseCallback = callback;
        dispatch('showTable');
    },

    closeOffersTable({state, dispatch}) {
        state.showOffersTable = false;
        dispatch('toDefaultParams');
    },

    toDefaultParams({commit}) {
        commit('clearOffers');
        commit('clearPaginator');
        commit('filters/clearFilters');
    }
}

export default offerActions;