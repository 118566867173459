<template>
  <div v-esc:ticketEscId="escHandler" ref="backplate" class="backplate backplate-height">
    <div class="col-12 d-flex justify-content-between pb-1 px-2 message-block-subject" ref="messagesubject" v-if="messages.length">
      <div class="mx-auto text-center d-flex" style="overflow-wrap: anywhere;">
        <string-trim v-if="messages[0].subject" :string="messages[0].subject" popper-placement="bottom" />
        <span v-else class="fst-italic">[Без темы]</span>
      </div>

      <notification-menu :user-can="userCan"></notification-menu>
    </div>
    <div class="col-12 p-1 messages-background" ref="messagesbox">
      <span v-if="messages.length">
        <span v-for="message in messages">
          <event v-if="eventsBefore(message)" :events="eventsBefore(message)" />
          <message-from :message="message"></message-from>
          <event v-if="eventsAfter(message)" :events="eventsAfter(message)" />
        </span>
      </span>
      <div class="col-12 h-100 h5 align-items-center d-flex" v-else>
        <div class="col-12 text-center" v-if="loading">Загрузка...</div>
      </div>
    </div>
  </div>
</template>

<script>
import MessageFrom from "./Message/From";
import MessageTo from "./Message/To";
import {CrmApi} from "../../library/CrmApi";
import TemplateSelect from "./TemplateSelect";
import {mapActions, mapGetters} from "vuex";
import NotificationMenu from "./NotificationMenu";
import StringTrim from "../string/StringTrim";
import {UserPermission} from "../../users/UserPermission";
import Event from "./Event/Event";

export default {
  name: "Notification",
  components: {
    TemplateSelect,
    StringTrim,
    NotificationMenu,
    "message-from": MessageFrom,
    "message-to": MessageTo,
    Event,
  },

  data: function (){
    return {
      messages: [],
      loading: false,
      loadingSetAsWatched: false,
      notificationId: this.$route.params.id,
      userCan: {
        convert: false,
        delete: false,
      },
      escPressed: false,
    }
  },
  created() {
    this.$store.commit('thread/setTicket', null);
    this.$store.commit('thread/setLastSeen', null);
    this.$store.dispatch('tickets/saveSelectedIds');
    this.$store.commit('tickets/clearSelect');
    this.$store.commit('setActiveWindow', 'ticket');
  },

  mounted: function() {
    new ResizeObserver(this.messagesboxHeight).observe(this.$refs.backplate);

    this.loading = true;

    CrmApi.call('/notification/' + encodeURIComponent(this.notificationId)).then((result) => {
      let ticket = result.data.data;
      this.messages.push(ticket);
      this.$store.commit('thread/setTicket', this.ticketFromNotification(ticket));
      this.$store.dispatch('tickets/selectElement',  this.ticketFromNotification(ticket));
      if (this.$store.getters['thread/isItDirectLink']) {
        this.$store.dispatch('tickets/saveSelectedIds');
      }
      this.getPermissions(
          ticket != null && ticket.ticket?.project?.name ?
              ticket.ticket?.project?.name :
              null
      );
    }).finally(() => {
      this.loading = false;
      this.messagesboxHeight();
    });
  },
  beforeUnmount() {
    this.$store.commit('thread/setTicket', null);
    this.$store.commit('thread/setTicketType', null);
    this.$store.commit('tickets/clearSelect');
  },
  computed:{
    ...mapGetters({
      "ticket":  "thread/getTicket",
    }),
  },
  methods:{
    ticketFromNotification(data){
      data.name = data.subject;
      data.person = {};
      data.person.email = data.headers.from;
      data.updated = data.created;
      if(typeof data.ticket !== 'undefined' ){
        data.project = {};
        data.project._id = data.ticket.project._id;
        data.project.name = data.ticket.project.name;
      }
      data.type = 'notification';
      return data;
    },
    messagesboxHeight: function() {
      $(this.$refs.messagesbox).height(
          $(this.$refs.backplate).height() -
          ($(this.$refs.messagesubject).height() ?? 0) -
          ($(this.$refs.setAsWatchedButton).height() ?? 0) - 35
      );
    },

    getPermissions(projectName = null) {
      let permissions = [
        projectName + ".notification_delete",
        projectName + ".notifications_convert",
      ];
      let rights = [
        'delete',
        'convert',
      ];

      UserPermission.can(permissions).catch(() => {}); // preloading

      this.userCan.delete = false;
      this.userCan.convert = false;

      rights.forEach((name, index) => {
        if (name && typeof permissions[index] !== 'undefined' && typeof this.userCan[name] !== 'undefined') {
          UserPermission.can(permissions[index]).then(() => {
            this.userCan[name] = true;
          }).catch(() => {
            this.userCan[name] = false;
          });
        }
      });
    },

    escHandler() {
      if (this.escPressed) {
        this.escPressed = false;
        this.redirectToTickets();
      } else {
        this.escPressed = true;
        setTimeout(() => {
          this.escPressed = false
        }, 500);
      }
    },

    eventsBefore(message) {
      return typeof message.events === 'object' && Array.isArray(message?.events) ? message.events.filter(event => {
        return event.position !== 'after' && (event.position === 'before' || moment(event.date) < moment(message.created));
      }) : null;
    },

    eventsAfter(message) {
      return typeof message.events === 'object' && Array.isArray(message?.events) ? message.events.filter(event => {
        return event.position !== 'before' &&  (event.position === 'after' || moment(event.date) >= moment(message.created));
      }) : null;
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    }),
  },
  watch: {
    "$store.state.thread.ticket" (ticket) {
      if(typeof ticket.ticket?.project !== 'undefined' && ticket.ticket?.project !== null) {
        this.getPermissions(ticket.ticket?.project.name);
        this.$store.dispatch('tickets/selectElement', ticket);
      }
    },
  }
}
</script>

<style scoped>
.cursor-wait{
  cursor: wait;
}
.backplate-height {
  height: calc(100vh - 60px);
}
</style>