import offerHistoryFiltersGetters from "./offerHistoryFiltersGetters";
import offerHistoryFiltersMutations from "./offerHistoryFiltersMutations";
import offerHistoryFiltersActions from "./offerHistoryFiltersActions";

const offerHistoryFiltersState = {
    searchString:"",

};



export const  offerHistoryFilters  = {
    namespaced: true,
    state:() => offerHistoryFiltersState,
    getters:offerHistoryFiltersGetters,
    actions:offerHistoryFiltersActions,
    mutations:offerHistoryFiltersMutations,
}

