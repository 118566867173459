



const offerFiltersMutations = {
    setSearchString(state , text){
        state.searchString = text;
    },
    clearFilters(state ){
        state.searchString = '';
    },
    setShowFilter(state, value) {
        state.showFilter = value;
    },
    setFilterValue(state, obj) {
        if (state.filters.find(el => el.field === obj.field)) {
            state.filters.find(el => el.field === obj.field).value = obj.value;
        }
    },
    clearFilterValues(state) {
        state.filters.forEach((filter) => filter.value = '');
    }
}


export default offerFiltersMutations;