import moment from "moment";


const offerHistoryAddEditMutations = {
    setSubnetType(state , value){
        state.subnetType = value;
    },
    setIsAs(state , value){
        state.isAs = value;
    },
    setThreadId(state, id) {
        state.threadId = id;
    },
    setHistoryObject(state, value) {
        state.historyObject = value;
    },
    setSeller(state , value){
        state.historyObject.seller = value;
    },
    setBuyer(state , value){
        state.historyObject.buyer = value;
    },

    setDate(state, date){
        let d = moment(date, 'DD.MM.YYYY')
        state.historyObject.date = d.format();
    },
    setIsOurSeller(state, value){
        state.isourSeller = value;
    },
    setIsOurBuyer(state, value){
        state.isourBuyer = value;
    },
    resetClient(state, type){
        state.historyObject[type] = {
            name:'',
            phone:[
                {
                    type:'',
                    number:'',
                }
            ],
            email:'',
            contact_emails:[ ],
            org:'',
            lirid:'',
        }
    },
    resetPrice(state, type){
        state.historyObject[type] = {
            price:0,
            currency:'USD',
            description:'',
            comment:'',
        }
    }
}


export default offerHistoryAddEditMutations;