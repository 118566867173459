import {countries} from "../../library/Client";

export class Block {

    keyword;
    group;
    type = 'textlbock';
    label = '';
    groupname = null;
    condition = '';
    conditionparam = null;

    correct = true; // блок корректно удалось спарсить из документа
    valid = false; // выбрано условие вывода и дополнительный параметр (если требуется)
    showNotValid = false; // блок может быть не валидным, но уведомление об этом не отображается

    children = [];

    showDeleteBtn = false;
    deleted = false;

    order = null;

    /**
     * @param {array} keyword
     */
    constructor(keyword) {
        this.keyword = keyword.keyword ?? keyword.key;
        this.group = keyword?.group ?? null;
        this.groupname = keyword?.groupname ?? null;
        this.label = keyword?.label ?? '';
        this.correct = keyword?.correct ?? true;
        this.condition = keyword?.condition ?? '';
        this.conditionparam = keyword?.conditionparam ?? null;
        this.order = keyword?.order ?? null;

        if(this.condition === '') {
            this.autoSetBlockCondition();
        }
    }

    setOver(block) {
        this.group = block.group ?? null;
        this.correct = block.correct ?? true;
    }

    autoSetBlockCondition() {
        if(this.group === 'client_company_type') {

            if([null, ''].includes(this.groupname)) {
                this.groupname = 'Тип клиента';
                this.children.forEach(child => child.groupname = this.groupname);
            }
            switch(this.keyword) {
                case 'company':
                    this.label = 'Компания'
                    this.conditionparam = 'company';
                    break;
                case 'ip':
                case 'entrepreneur':
                    this.label = 'ИП'
                    this.conditionparam = 'entrepreneur';
                    break;
                case 'phy':
                case 'person':
                    this.label = 'Физ. лицо'
                    this.conditionparam = 'person';
                    break;
                default:
                    return;
            }
            this.condition = 'legaltype';

        } else if(this.group === 'our_company_type') {

            if([null, ''].includes(this.groupname)) {
                this.groupname = 'Наше юридическое лицо';
                this.children.forEach(child => child.groupname = this.groupname);
            }

            if(this.keyword === 'company') {
                this.condition = 'company';
            } else if(['ip', 'entrepreneur'].includes(this.keyword)) {
                this.condition = 'entrepreneur';
            }

        } else if(this.group === 'region') {

            if(typeof countries[this.keyword] === 'string') {
                this.condition = 'country';
                this.label = countries[this.keyword];
                this.conditionparam = countries[this.keyword];
            }

        }
    }

    /**
     * Возвращает ключ, или группа:ключ, если у блока есть группа
     * @returns {string}
     */
    getUniqueName() {
        return this.group !== null ? `${this.group}:${this.keyword}` : this.keyword;
    }

    /**
     * @param {Keyword[]} children
     */
    setChildren(children) {
        this.children = children.sort((a, b) => a.order > b.order);
    }

    checkValidation() {
        this.valid = (this.condition.length > 0 && this.correct);

        if(this.valid && ['country', 'legaltype'].includes(this.condition)) {
            this.valid = (typeof this.conditionparam === 'string' && this.conditionparam.length);
        }

        return this.valid;
    }

    prepareForSave() {
        let result =  {
            key: this.keyword,
            group: this.group,
            groupname: this.groupname,
            type: 'textblock',
            condition: this.condition,
            label: this.label,
            order: this.order,
        };

        if(this.conditionparam !== null) {
            result.conditionparam = this.conditionparam;
        }

        return result;
    }

    delete() {
        this.deleted = true;
        this.children.forEach(keyword => keyword.deleteParent(this));
    }

}