



const offerHistoryFiltersMutations = {
    setSearchString(state , text){
        state.searchString = text;
    },
    clearFilters(state ){
        state.searchString = '';
    },

}


export default offerHistoryFiltersMutations;