<template>
  <div id="history-list" ref="history-list">
    <div style="font-size: 14px; " ref="table-list" id="table-list"  class="table-scroll w-100">
      <table id="table--history-list" class="table table-bordered table-hover table-striped table-container" style=" margin-bottom: 5px;">
        <thead class="thead-dark" style="z-index:20">
        <tr>
          <th
              @click="sortBy('subnet')"
              :class="sortedClass('subnet')"
              class="text-center"
              style="min-width: 195px; width: 195px; cursor: pointer"
          >Подсеть / AS</th>
          <th
              @click="sortBy('count')"
              :class="sortedClass('count')"
              class="text-center"
              style="min-width: 65px;width: 65px; cursor: pointer"
          >IPs</th>
          <th
              @click="sortBy('date')"
              :class="sortedClass('date')"
              class="text-center"
              style="min-width: 100px;width: 100px; cursor: pointer"
          >Дата</th>
          <th
              @click="sortBy('seller_email_order')"
              :class="sortedClass('seller_email_order')"
              class="text-center"
              style="cursor: pointer; min-width: 230px;width: 230px;"
          >Продавец</th>
          <th
              v-if="!getSellManagerCollapsed"
              @click="sortBy('sellmanager_order')"
              :class="sortedClass('sellmanager_order')"
              style="cursor: pointer;min-width: 110px;width: 110px;"
              class="text-center"
          >Менеджер</th>
          <th
              v-if="getSellManagerCollapsed"
              @click="sellCollapsed"
              class="text-center"
              role="button"
              title="Менеджер"
              style="min-width: 70px;width: 70px;"
          >М</th>
          <th
              @click="sortBy('buyer_email_order')"
              :class="sortedClass('buyer_email_order')"
              class="text-center"
              style="cursor: pointer; min-width: 230px;width: 230px;"
          >Покупатель</th>
          <th
              v-if="!getBuyManagerCollapsed"
              @click="sortBy('buymanager_order')"
              :class="sortedClass('buymanager_order')"
              class="text-center"
              style="cursor: pointer;min-width: 110px;width: 110px;"
          >Менеджер</th>
          <th
              v-if="getBuyManagerCollapsed"
              @click="buyCollapsed"
              class="text-center"
              role="button"
              title="Менеджер"
              style="min-width: 70px;width: 70px;"
          >М</th>
          <th
              @click="sortBy('buyprice.priceusd')"
              :class="sortedClass('buyprice.priceusd')"
              class="text-center"
              style="cursor: pointer; min-width: 200px;width: 200px;"
          >Номинал</th>
          <th
              @click="sortBy('sellprice.priceusd')"
              :class="sortedClass('sellprice.priceusd')"
              class="text-center"
              style="cursor: pointer; min-width: 200px;width: 200px;"
          >Продажа</th>
        </tr>
        </thead>
        <tbody>
          <history-body></history-body>
        <!-- loading-row  v-else :loading="loading" col-span-default="9"></loading-row -->
        </tbody>
      </table>
    </div>
    <loading-list v-if="loading"></loading-list>
  </div>
</template>

<script>
import LoadingRow from "../../offer/table/LoadingRow";
import HistoryBody from "./HistoryBody";
import {mapGetters} from "vuex";
import Paginate from "../../paginate/Paginate";
import LoadingList from "../../ticket/List/LoadingList";
import {PricePrettyView} from "../../../library/PricePrettyView";
export default {
  name: "OffersHistoryList",
  components: {LoadingList, HistoryBody, LoadingRow, Paginate},
  computed:{
    ...mapGetters({
      'loading':'offerHistory/getLoading',
      'getSellManagerCollapsed':'offerHistory/getSellManagerCollapsed',
      'getBuyManagerCollapsed':'offerHistory/getBuyManagerCollapsed',
      "getRoute": "getRoute",
    }),
  },
  data:function (){
    return {
      orderObject:{},
      resizeObserver:null,
      maxHeight:1024,
      orderByField: null,
      orderByDirection: null,
    }
  },

  mounted() {
    this.$router.isReady().then(()=>{
      let query = this.getRoute?.query;
      if (typeof query?.oB !== 'undefined' && query?.oB) {
        try {
          let oB = JSON.parse(query.oB);
          this.orderByField = oB?.f ?? null;
          this.orderByDirection = oB?.v ?? null;
        } catch (nothing) {}
      }
    });
  },

  methods:{
    buyCollapsed:function (){
      this.$store.commit('offerHistory/setBuyManagerCollapsed', !this.getBuyManagerCollapsed)
      this.$store.commit('offerHistory/setSellManagerCollapsed', !this.getSellManagerCollapsed)
    },
    sellCollapsed:function (){
      this.$store.commit('offerHistory/setBuyManagerCollapsed', !this.getBuyManagerCollapsed)
      this.$store.commit('offerHistory/setSellManagerCollapsed', !this.getSellManagerCollapsed)
    },
    sortBy:function(fieldName){
      let query = Object.assign({}, this.$store.getters['getRoute'].query);
      if (['seller.email', 'seller_email_order', 'sellmanager_order', 'buyer.email', 'buyer_email_order', 'buymanager_order'].includes(fieldName)) {
        this.orderObject[fieldName] = this.orderByField === fieldName ? this.orderObject[fieldName]*-1 : 1;
      } else {
        if(typeof this.orderObject[fieldName] === 'undefined' ){
          this.orderObject[fieldName] = -1;
        }else {
          this.orderObject[fieldName]  =  this.orderObject[fieldName]*-1;
        }
      }
      this.orderByField = fieldName;
      this.orderByDirection = this.orderObject[fieldName];
      let orderValue = this.orderObject[fieldName]
      let oB = {
        f: fieldName,
        v: orderValue,
      };
      query.oB = JSON.stringify(oB);

      this.$router.push({
        name:this.$store.getters['getRoute'].name,
        query:query,
      })
    },
    sortedClass(field) {
      return {
        'sorted-down': this.orderByField === field && this.orderByDirection === -1,
        'sorted-up': this.orderByField === field && this.orderByDirection === 1,
      }
    }
  },
}
</script>

<style scoped>
.table-wrapper {
  position:relative;
}
.sorted-down::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.sorted-up::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}
</style>