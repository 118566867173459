<template>
  <popper :content="popperContent" class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :disabled="!popperContent">
    <i class="fa fa-gavel p-1"
       :class="{'opacity4': !lot.maxbid}"
       :role="lot?.bids?.length ? 'button' : 'none'"
       @click="showBidsModal"></i>
  </popper>

  <teleport v-if="showModal" to="body">
    <transition name="modal">
      <modal-window
          @close="closeBidsModal"
          :show-footer="false"
          modal-dialog-class="modal-lg bids-modal"
      >
        <template v-slot:header>
          <h5 class="modal-title">Ставки</h5>
        </template>
        <template v-slot:body>
          <Loading v-model:active="loading" />

          <div class="border bids" v-if="Array.isArray(bids) && bids.length">
            <table class="table table-bordered table-striped mb-0">
              <thead class="sticky">
                <tr>
                  <th>Клиент</th>
                  <th>Ставка</th>
                  <th>Статус</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="bid in bids">
                  <td>
                    <a target="_blank" :href="getTicketLink(bid)">
                      <template v-if="bid?.person?.name && bid?.person?.email">
                        {{bid.person.name}} ({{bid.person.email}})
                      </template>
                      <template v-else-if="bid?.person?.email">
                        {{bid.person.email}}
                      </template>
                    </a>
                  </td>
                  <td>
                    {{ new PricePrettyView(bid.price1usd, '$').getPrettyPrice('—', false, 2, false) }}
                  </td>
                  <td>
                    <span v-if="bid.approved && bid.active" class="text-success">Active</span>
                    <span v-else-if="bid.approved && !bid.active" class="text-danger">Outbid</span>
                    <span v-else class="text-warning">Approving</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import ModalWindow from "../../../../ModalWindow.vue";
import Loading from "vue3-loading-overlay";
import {CrmApi} from "../../../../../../../library/CrmApi";
import {PricePrettyView} from "../../../../../../../library/PricePrettyView";
import {Thread} from "../../../../../../../library/api/Thread";
import {getTicketLink} from "../../../../../../../library/Locations";

export default {
  name: "Bids",
  components: {Loading, ModalWindow},

  props: {
    subnet: null,
    ticket: [Object,null],
  },

  data() {
    return {
      showModal: false,
      bids: null,
      loading: false,
    }
  },

  computed: {
    lot:function () {
      return this.ticket?.assigned_product?.fields?.lot;
    },
    PricePrettyView() {
      return PricePrettyView
    },
    popperContent() {
      let content = null;

      if(Array.isArray(this.lot?.bids) && this.lot.bids.length) {
        content = `Ставок: ${this.lot.bids.length}`;
      } else {
        content = "Сети тикета выставлены на аукцион. Ставок нет.";
      }

      return content;
    },

    api() {
      return this.$store.getters['thread/getApi'];
    }
  },

  methods: {
    showBidsModal() {
      if(this.lot?.bids?.length) {
        this.showModal = true;
        this.$store.commit('setActiveWindow', 'bidsModal');
        this.loadBids();
      }
    },

    closeBidsModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'bidsModal');
    },

    loadBids() {

        this.loading = true;

        (this.api ?? new Thread(this.ticket?._id))
              .getBids(this.subnet)
              .then(result => this.setBids(result.data))
              .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
              .finally(() => this.loading = false);

    },

    getTicketLink(bid){
      return bid?._id ? getTicketLink({
        ticket_id: bid?._id
      }) : null;
    },

    setBids(bids) {
      // сортировка по цене
      bids = _.orderBy(bids, 'price1usd', 'desc');

      // сначала активная, затем перебитые, затем ожидающие подтверждения
      this.bids = [
        ...bids.filter(bid => bid.approved && bid.active),
        ...bids.filter(bid => bid.approved && !bid.active),
        ...bids.filter(bid => !bid.approved)
      ];
    }
  }
}
</script>

<style scoped>
.opacity4 {
  opacity: .4;
}

.bids {
  max-height: 30em;
  overflow: auto;
}
</style>