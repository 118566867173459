<template>
  <select v-if="keyword.declinable" v-model="keyword.wordCase" class="form-control form-select form-select-sm">
    <option :value="undefined" selected>Без склонения</option>
    <option value="именительный">Именительный</option>
    <option value="родительный">Родительный</option>
    <option value="дательный">Дательный</option>
    <option value="винительный">Винительный</option>
    <option value="творительный">Творительный</option>
    <option value="предложный">Предложный</option>
  </select>
</template>

<script>
export default {
  name: "Declination",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>