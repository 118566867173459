<template>
  <span class="inline-copy-badge">
    <div v-show="show" class="badge-element" >
      <i timeout-time="0"  v-clipboard.byContent="true" :content="content" custom-popover-class="clipboard-copy-popover"
         class=" fa fa-files-o" aria-hidden="true"></i>
    </div>
  </span>
</template>

<script>
export default {
  name: "CopyBadge",
  data:function (){
    return {

    }
  },
  props:{
    show:{
      type:Boolean,
      default:true,
    },
    content:{
      type:String,
      default: "",
    }
  },

}
</script>

<style scoped>
.badge-element{
  cursor: pointer;

  font-size: 14px;
}
.inline-copy-badge{
  display: inline-flex;
  padding-left: 4px;
  min-width: 20px;
}
</style>
<style>
.clipboard-copy-popover{
  --bs-popover-body-padding-x: 6px;
  --bs-popover-body-padding-y: 3px;
  --bs-popover-font-size: 0.80rem;
}

</style>