<template>
  <select :value="keyword.type" name="keyword-type" class="form-control form-select form-select-sm" @change="(e) => keyword.setType(e.target.value)">
    <option value="" disabled>—</option>
    <option value="text">Текстовое поле</option>
    <option value="date">Дата</option>
    <option value="ticketparam">Параметр тикета</option>
    <option value="personparam">Параметр клиента</option>
    <option value="companyparam">Параметр компании</option>
    <option value="autogenerated">Макрос</option>
  </select>
</template>

<script>
export default {
  name: "Type",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>