<template>
<div style="display: flex;flex-wrap: nowrap;flex-direction: column;" class="thread-form" id="answer-form">
  <div ref="templateselect" style="margin-bottom: 5px;" >
    <template-select
        @calculateFormSize="$parent.calculateFormSize"
        @resizeMessagesbox="px => $emit('resizeMessagesbox', px)"
        @updateHeaders="updateHeadersHandler"
        :assign-template="assignTemplate"
        :project="getId(ticket.project._id)"
        :ticketId="getId(ticket._id)"
        ref="template-attachment-panel"
        :type="type"
    ></template-select>
  </div>
  <div ref="quote-message" >
    <quote  v-if="messageObject && messageObject.type === 'comment' && replyto && quoteMessage" :message="quoteMessage"></quote>
  </div>
  <div>
      <textarea
          ref="answer_message"
          v-model="message"
          :class="{'cursor-wait': isSending, 'comment': type === 'comment', 'with-signature': showSignature}"
          @keyup.ctrl.enter.exact.stop="updateMessage"
          @keydown.enter.stop="sendAnswerByEnter"
          :disabled="isSending"
          class="form-control"
          style="resize: none"
          @focus="onFocus"
          @focusout="$emit('textareaFocusOut')"
          @input="handleInput($event.target.value)"
      ></textarea>
  </div>
  <div v-if="showSignature">
    <textarea
        class="form-control signature-control"
        ref="signatureTextarea"
        style="resize: none; overflow: hidden; padding-bottom: 10px;"
        :disabled="true"
    >{{ signatureText }}</textarea>
  </div>
  <div ref="answer_button" style=" display: inline-flex;" >
    <div class="col-6 " style="padding-right: 2px;" >
      <button  style="margin-top: 5px; margin-bottom: 5px;" type="button" :class="{'cursor-wait': isSending}"
               :disabled="isSending"  v-esc:ticketEscId.ctrl="closeEditForm" @click="closeEditForm"
              class="btn btn-secondary full-wight">Отмена</button>
    </div>
    <div class="col-6" style="padding-left: 2px;" >
      <button style="margin-top: 5px; margin-bottom: 5px"  type="button" :class="{'cursor-wait': isSending}"
              :disabled="isSendDisabled" @click="updateMessage" class="btn btn-primary full-wight">Сохранить изменения</button>
    </div>
  </div>
</div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TemplateSelect from "../TemplateSelect";
import Quote from "../Comment/Quote";

export default {
  name: "Update",

  emits: ['newMessage', 'resizeMessagesbox' , 'textareaFocusOut' , 'textareaFocus', 'textareaInput',],

  components: {
    Quote,
    'template-select': TemplateSelect
  },

  props: {
    ticket: {
      type: Object,
      require: true
    },
  },

  data: function() {
    return {
      messageObject:null,
      message: '',
      lastHeight: null,
      reduced: false,
      reduceHandler: null,
      sendByEnter:true,
      changedFrom: null,
    }
  },

  mounted: function() {
    this.sendByEnter = true;
    $(this.$parent.$refs.messagesbox).height("60%");

    let msg = this.threadState.editMessage.message;
    this.$refs['template-attachment-panel'].clearAttachments();
    if(typeof msg.attachments !== 'undefined' && Array.isArray(msg.attachments)) {
      this.$refs['template-attachment-panel'].addExistsAttachments([...msg.attachments], msg._id);
    }
    if(typeof this.threadState.editMessage.messageObject !== 'undefined'){
      this.messageObject = this.threadState.editMessage.messageObject;
    }

    this.message = msg.body;
    this.$nextTick(()=>{
      this.$refs['answer_message'].focus();
    })
    $(this.$refs['answer_message']).height('30px');
    /*
    this.reduceHandler = (e) => {
      if(!this.$parent.resize &&
         !this.reduced &&
         this.lastHeight &&
         !$(e.target).closest('#answer-form').length) {


          this.$emit('resizeMessagesbox', (this.lastHeight - 30));
          this.$parent.calculateFormSize();
          this.reduced = true;
      }
    };
    document.addEventListener('mousedown', this.reduceHandler);
     */
    this.deleteSignature(window.getId(this.ticket._id));
  },

  unmounted() {
    document.removeEventListener('mousedown', this.reduceHandler);
    this.hideSignature();
    this.deleteSignature(window.getId(this.ticket._id));
  },

  computed: {
    replyto:function (){
      let replyto = null;
      if(this.messageObject?.type === 'comment' && this.quoteMessage){
            replyto = window.getId(this.quoteMessage._id)
      }
      return replyto;
    },
    quoteMessage:function (){
      return this.$store.getters['thread/getCommentMessage'];
    },

    type: function() {
      return (typeof this.threadState.editMessage.message === 'object' &&
              this.threadState.editMessage.message.type === 'comment') ? 'comment' : 'message';
    },

    isSending: function() {
      return this.$parent.sending;
    },

    isSendDisabled: function () {
      return this.$parent.loading || !this.message || this.isSending || this.uploadingAttachments;
    },

    uploadingAttachments: function() {
      return this.$refs['template-attachments-panel'] !== null && this.$refs['template-attachment-panel'].upload;
    },

    signatureText() {
      return this.getSignature(window.getId(this.ticket._id), this.changedFrom) ?? '';
    },

    ...mapState( {
      threadState: state => state.thread,
    }),

    ...mapGetters({
      "getShowCommentForm":  'thread/getShowCommentForm',
      "showSignature": "thread/showSignature",
      "getSignature": "thread/getSignature",
    }),
  },

  methods: {
    assignTemplate(tpl) {
      this.message = tpl;
      this.$nextTick(() => this.$emit('textareaInput'));
    },
    updateSignature() {
      let signature = this.getSignature(window.getId(this.ticket._id), this.changedFrom);
      if (this.showSignature || (typeof signature !== 'undefined' && signature !== null)) {
        this.loadSignature({
          ticketId: window.getId(this.ticket._id),
          alias: this.changedFrom,
          message: this.threadState?.editMessage?.message?._id ?? null,
          show: this.showSignature
        });
      }
    },
    updateHeadersHandler(header, value) {
      if(header === 'from') {
        this.changedFrom = value;
      }

      this.updateSignature();
    },
    sendAnswerByEnter:function(event){
      if(this.sendByEnter && event.metaKey && !event.altKey &&  !event.shiftKey && !event.ctrlKey){
        this.sendByEnter = false;
        this.updateMessage();
      }
    },
    handleInput(val) {
      this.$emit('textareaInput');
    },
    onFocus: function() {
      this.$emit('textareaFocus');
    },

    updateMessage: function() {
      if(this.$refs['template-attachment-panel'].isShowingHeadersForm() && !this.$refs['template-attachment-panel'].validationHeadersForm()) {
        this.sendByEnter = true;
        return;
      }

      this.sending();
      let otherParams = null;
      if(this.messageObject && this.messageObject?.type === 'comment'){
        otherParams=  {};
        otherParams.replyto = this.replyto;
      }
      CrmApi.updateMessage(this.threadState.editMessage.message._id,
        this.ticket._id,
        this.message,
        this.$refs['template-attachment-panel'].savedAttachments,
        this.$refs['template-attachment-panel'].getHeaders(),
        otherParams).then((result) => {
        this.threadState.editMessage.updated();
        this.$store.commit('thread/closeEditForm');
        let index = this.$parent.messages.findIndex(msg => msg._id === result.data.message._id);
        if(index !== -1){
          this.$parent.messages[index] = result.data.message;
        }
        if (result.data.message?.type === 'comment') {
          this.$store.commit('thread/updatePinnedMessage', result.data.message);
        }
        this.$parent.calculateFormSize();
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.sending(false);
        this.sendByEnter = true;
      });
    },

    closeEditForm: function() {
      this.threadState.editMessage.close();
      this.$store.commit('thread/closeEditForm');
    },

    getId(id) { return window.getId(id); },

    sending(status = true) { this.$parent.sending = status; },

    calculateSignatureTextareaSize() {
      let block = this.$refs.signatureTextarea;
      if (block) {
        let scrollHeight = Math.round(block.scrollHeight);
        let clientHeight = Math.round(block.clientHeight);
        let height = clientHeight;
        if (scrollHeight >= clientHeight) {
          height = scrollHeight;
        }
        if (this.signatureText === '') {
          height = 40;
        }
        if (height < 40) {
          height = 40;
        }
        if (height > 250) {
          height = 250;
        }
        block.style.height = '';
        block.style.height = height + 'px';

        this.$nextTick(this.$parent.calculateFormSize);
      }
    },

    ...mapActions({
      "loadSignature": "thread/loadSignature",
    }),

    ...mapMutations({
      "hideSignature": "thread/hideSignature",
      "deleteSignature": "thread/deleteSignature",
    }),
  },

  watch: {
    '$parent.resize' (to) {
      if(!to && this.lastHeight !== null) {
        this.lastHeight = $(this.$refs['answer_message']).height();
      }
    },
    'showSignature' (to) {
      if (to) {
        this.$nextTick(this.calculateSignatureTextareaSize);
      }
    },
    'signatureText' () {
      if (this.showSignature) {
        this.$nextTick(this.calculateSignatureTextareaSize);
      }
    },
    'ticket.lang' () {
      this.updateSignature();
    }
  }
}
</script>

<style scoped>
.comment {
  background-color: #d8f9da;
}
.full-wight {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}
.with-signature {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.signature-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>