<template>
  <popper class="hitString" :style="style" :disableClickAway="true" :disabled="!isTrimmed || !showPopper" :placement="popperPlacement" :hover="true" :interactive="false" :arrow="true">
    <div class="trim-parent" :style="style" :class="classString" >

      <span class="trim" :id="stringID" v-clipboard="clipboard">
        {{string}}
      </span>
    </div>

    <template #content>
      <div class="hint-popper">{{string}}</div>
    </template>
  </popper>
</template>

<script>

export default {
  name: "StringTrim",

  props: {
    string: {
      type: String,
      default: '',
    },
    showPopper: {
      type: Boolean,
      default: true,
    },
    popperPlacement: {
      type: String,
      default: 'top',
    },
    popperPosition: {
      type: String,
      default: 'absolute'
    },
    style: {
      type: [String, Object],
    },
    classString:{
      type: [String, Object],
      default:"",
    },
    clipboard: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      show: false,
      length: null,
      stringID: 'trimstr' + String(Math.random()).substr(2),
      isTrimmed: false,
    }
  },

  mounted: function() {
    this.$nextTick(()=> {
      let el = $('#' + this.stringID);
      if( el.length > 0 ) {
        new ResizeObserver(() => {

          this.isTrimmed = Number(el.width().toFixed()) < Number(el[0].scrollWidth.toFixed());

        }).observe(el[0]);
      }
    });
  }
}
</script>

<style scoped>
.hitString:deep(.popper) {
  position: v-bind(popperPosition) !important;
}
.hitString {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 14px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
.trim-parent {
  display: grid;
}
.trim {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.hint-popper {
  overflow: hidden;
  overflow-wrap: break-word;
}
</style>