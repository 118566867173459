<template>
  <div class="pt-5 col-md-6 m-auto">
    <form method="post" @submit.prevent="submit()" v-esc="cancel">
      <div class="mb-3">
        <label for="input-language" class="form-label">Язык</label>
        <select v-model="edit.lang" class="form-select" name="language" id="input-language">
          <option :value="null">—</option>
          <option value="RU">Русский</option>
          <option value="EN">Английский</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="input-language" class="form-label">Тип организации</label>
        <select v-model="edit.legaltype" class="form-select" name="language" id="input-legaltype">
          <option v-if="!edit?.legaltype" :value="null">—</option>
          <option value="company">Организация</option>
          <option value="entrepreneur">ИП</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="input-name" class="form-label">Название</label>
        <input type="text" v-model="edit.name" class="form-control" name="name" id="input-name">
      </div>

      <div class="mb-3">
        <label for="input-fullname" class="form-label">Полное название</label>
        <input type="text" v-model="edit.fullname" class="form-control" name="fullname" id="input-fullname">
      </div>

      <div class="mb-3">
        <label for="input-vatnum" class="form-label">ИНН</label>
        <input type="text" v-model="edit.vatnum" class="form-control" name="vatnum" id="input-vatnum">
      </div>

      <div class="mb-3">
        <label for="input-ogrn" class="form-label">ОГРН</label>
        <input type="text" v-model="edit.ogrn" class="form-control" name="ogrn" id="input-ogrn">
      </div>

      <div class="mb-3">
        <label for="input-kpp" class="form-label">КПП</label>
        <input type="text" v-model="edit.kpp" class="form-control" name="kpp" id="input-kpp">
      </div>

      <div class="mb-3">
        <label for="input-email" class="form-label">Email</label>
        <input type="email" v-model="edit.email" class="form-control" name="email" id="input-email">
      </div>

      <div class="mb-3">
        <label for="input-site" class="form-label">Site</label>
        <input type="text" v-model="edit.site" class="form-control" name="site" id="input-site">
      </div>

      <div class="mb-3">
        <label for="input-phone" class="form-label">Телефон</label>
        <phone type="phone" id="input-phone" input-class="w-100" v-model="edit.phone"></phone>
      </div>

      <div class="card mb-3">
        <div class="card-header">Подписант</div>
        <div class="card-body">
          <div class="mb-3">
            <label for="input-signer-name" class="form-label">ФИО</label>
            <input type="text" v-model="edit.signer.name" class="form-control" name="signer-name" id="input-signer-name">
          </div>
          <div class="mb-3">
            <label for="input-signer-position" class="form-label">Должность</label>
            <input type="text" v-model="edit.signer.position" class="form-control" name="signer-position" id="input-signer-position">
          </div>
          <div class="">
            <label for="input-signer-documenttype" class="form-label">Документ подтверждающий полномочия</label>
            <input type="text" v-model="edit.signer.documenttype" class="form-control" name="signer-documenttype" id="input-signer-documenttype">
          </div>
        </div>
      </div>

      <div class="mb-3 d-flex flex-wrap justify-content-between align-items-center">
        <label for="input-address-legal" class="form-label">Юридический адрес</label>
        <span class="address-inputs-button pull-right link-primary small"
              role="button"
              @click="toggleFullAddress('legal')">
            {{ showFullAddress.legal ? 'Скрыть' : 'Изменить' }}
        </span>
        <input :value="addressLegal"
               @click="toggleFullAddress('legal')"
               type="text"
               role="button"
               readonly
               class="form-control input-disabled"
               name="address-legal"
               id="input-address-legal">
      </div>

      <full-address-fields v-if="showFullAddress.legal"
                           :address="edit.address.legal"
                           type="CompaniesNewEdit"
                           @update="(field, value) => edit.address.legal[field] = value" />

      <div class="mb-3 d-flex flex-wrap justify-content-between align-items-center">
        <label for="input-address-real" class="form-label">Фактический адрес</label>
        <span class="address-inputs-button pull-right link-primary small"
              role="button"
              @click="toggleFullAddress('real')">
            {{ showFullAddress.real ? 'Скрыть' : 'Изменить' }}
        </span>
        <input :value="addressReal"
               @click="toggleFullAddress('real')"
               type="text"
               role="button"
               readonly
               class="form-control input-disabled"
               name="address-real"
               id="input-address-real">
      </div>

      <full-address-fields v-if="showFullAddress.real"
                           :address="edit.address.real"
                           type="CompaniesNewEdit"
                           @update="(field, value) => edit.address.real[field] = value" />

      <div class="card mb-3">
        <div class="card-header">
          <div class="d-flex align-items-center">
            <div class="company-group-title">Банки</div>
            <div class="ms-auto">
              <button class="btn btn-outline-success" type="button" id="button-addon1" @click="addBank">
                <i class="fa fa-plus-square pe-1" aria-hidden="true"></i> Добавить
              </button>
            </div>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item" v-for="(bank, index) in edit.bank">
            <div class="row mb-3 align-items-center" style="--bs-gutter-x: 12px;">
              <div class="col-auto">
                <popper class="light" :hover="true" :interactive="false" :arrow="true" placement="top" content="Сделать по умолчанию">
                  <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="bank.default"
                      :name="'input-bank-default-' + index"
                      @change="changeBankDefault(index)"
                      :id="'input-bank-default-' + index"
                  />
                </popper>
              </div>
              <div class="col">
                <input type="text" v-model="bank.label" class="form-control" :name="'input-bank-label' + index" :id="'input-bank-label' + index">
              </div>
              <div class="col-auto">
                <button class="btn text-danger p-1" type="button" @click="removeBank(index)">
                  <i class="fa fa-remove" aria-hidden="true"></i>
                </button>
              </div>
            </div>
            <hr>
            <div class="mb-3">
              <label :for="'input-bank-name' + index" class="form-label">Название банка</label>
              <input type="text" v-model="bank.name" class="form-control" :name="'input-bank-name' + index" :id="'input-bank-name' + index">
            </div>
            <div class="mb-3">
              <label :for="'input-bank-bik' + index" class="form-label">БИК</label>
              <input type="text" v-model="bank.bik" class="form-control" :name="'input-bank-bik' + index" :id="'input-bank-bik' + index">
            </div>
            <div class="mb-3">
              <label :for="'input-bank-account' + index" class="form-label">Расчетный счет</label>
              <input type="text" v-model="bank.account" class="form-control" :name="'input-bank-account' + index" :id="'input-bank-account' + index">
            </div>
            <div class="mb-3">
              <label :for="'input-bank-coraccount' + index" class="form-label">Корр. счет</label>
              <input type="text" v-model="bank.coraccount" class="form-control" :name="'input-bank-coraccount' + index" :id="'input-bank-coraccount' + index">
            </div>
          </li>
        </ul>
      </div>

      <div class="double-buttons-group">
        <div>
          <button @click="cancel" type="button" class="col-6 btn btn-secondary">Назад</button>
        </div>
        <div>
          <button
              v-if="canEdit"
              type="submit"
              :disabled="loading"
              class="col-6 btn btn-primary"
          >Сохранить</button>
        </div>
      </div>
    </form>
  </div>
  <alert-list></alert-list>
</template>

<script>
import AlertList from "../ticket/Alert/AlertList";
import {CrmApi} from "../../library/CrmApi";
import {Companies} from "../../library/api/Companies";
import Phone from "../common/Phone/Phone";
import FullAddressFields from "../tiketMenu/components/Client/Address/FullAddressFields.vue";
let joinAddressParams = function ( address ){
  return Object.values(address)
               .filter(value => value != null && value.trim() !== "")
               .map(part => part.trim())
               .join(', ');
}
export default {
  name: "CompaniesNewEdit",

  components: {
    FullAddressFields,
    AlertList,
    Phone,
  },

  props: {
    company: {
      type: Object,
      required: true,
    },
    canEdit: {
      default: false,
    },
  },

  data: function () {
    return {
      edit: {
        "_id": null,
        "lang": null,
        "legaltype": null,
        "name": "",
        "vatnum": "",
        "fullname": "",
        "ogrn": "",
        "kpp": "",
        "email": "",
        "site": "",
        "phone": "",
        "signer": {
          "name": "",
          "position": "",
          "documenttype": "",
        },
        "address": {
          "legal": {
            country: '',
            index: '',
            state: '',
            city: '',
            street: ''
          },
          "real": {
            country: '',
            index: '',
            state: '',
            city: '',
            street: ''
          },
        },
        "bank": [

        ],
      },

      showFullAddress: {
        legal: false,
        real: false
      },

      loading: false,
    }
  },

  created() {
    if (this.company) {
      Object.assign(this.edit, this.company);
    }
  },

  computed: {
    addressReal() {
      return joinAddressParams(this.edit.address.real);
    },

    addressLegal() {
      return joinAddressParams(this.edit.address.legal);
    }
  },

  methods: {
    toggleFullAddress(type) {
      this.showFullAddress[type] = !this.showFullAddress[type];
    },

    submit() {
      if (this.canEdit) {
        this.loading = true;
        Companies.editCompany(this.edit._id, this.edit).then(() => {
          this.$store.commit('successPush', 'Компания обновлена');
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    cancel() {
      window.location.replace( window.location.origin + "/companies/");
    },
    addBank() {
      this.edit.bank.push({
        "label": "",
        "name": "",
        "bik": "",
        "account": "",
        "coraccount": "",
        "default": false,
      });
    },
    removeBank(index) {
      this.edit.bank.splice(index, 1);
    },
    changeBankDefault(index) {
      this.edit.bank.forEach((value, index1) => {
        if (index !== index1) {
          value.default = false;
        }
      })
    },
  }
}
</script>

<style scoped>

</style>