<template>
<div style="padding-bottom: 8px">
  <div class="d-flex">
    <add-button v-if="canChange" style="min-width: 8.5em;" class="me-2"></add-button>
    <div class="search-input input-group">
      <input
          ref="search"
          name="search"
          class="form-control"
          type="text"
          placeholder="Поиск"
          v-model="search"
          @input="change($event.target.value)"
      >
      <button
          class="btn btn-secondary"
          type="button"
          @click="showHelpModal = true"
      >?</button>
    </div>
  </div>
  <div class="text-muted pe-2 meta-string" v-if="metaString">{{ metaString }}</div>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showHelpModal"
          @close="showHelpModal = false"
          @success="false"
          :show-footer="false"
          modal-dialog-class="modal-dialog modal-xl modal-dialog-scrollable modal-help"
      >
        <template v-slot:header>
          <h5 class="modal-title">Справка по фильтрам в истории биржи</h5>
        </template>
        <template v-slot:body>
          <table class="table table-striped table-bordered mb-0">
            <thead>
              <tr>
                <th style="width: 25%;">Фильтр</th>
                <th style="width: 40%;">Описание</th>
                <th style="width: 35%;">Примечание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">$название</span></td>
                <td class="filter-description">Поиск по способу оплаты </td>
                <td class="filter-mods">
                  Название способа оплаты - буквы и цифры в любом регистре; первый символ - буква
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">$i7</span> <span class="mark">$ип</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Валюта][цена] [мод.]</span><br>
                  <span class="mark">[Цена] [Валюта] [мод.]</span>
                </td>
                <td class="filter-description">Поиск по цене с конвертацией валют на дату сделки</td>
                <td class="filter-mods">
                  Доступны также модификаторы + и - для поиска от и до определенной цены.<br>
                  Валюты - USD, EUR, RUB и их алиасы
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">$7</span> <span class="mark">300 EUR +</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Сеть] [Тип сети]</span><br>
                  <span class="mark">[Тип сети]</span>
                </td>
                <td class="filter-description">
                  Поиск по типу сети и самой сети
                </td>
                <td class="filter-mods">
                  Тип сети = pa|pi|пи|па|зш|зф<br>
                  Сеть в формате диапазона или с префиксом
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">pi</span> <span class="mark">195.38.8.0/22 pa</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Адрес]</span><br>
                  <span class="mark">[Сеть]</span>
                </td>
                <td class="filter-description">
                  Поиск по адресу/сети
                </td>
                <td class="filter-mods">
                  Адрес - одиночный IP-адрес<br>
                  Сеть в формате CIDR
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">195.38.8.4</span> <span class="mark">195.38.8.0/22</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">/[число от 1 до 32][мод.]</span>
                </td>
                <td class="filter-description">
                  Поиск по префиксу сети
                </td>
                <td class="filter-mods">
                  Доступны также модификаторы + и - для поиска сетей с большим или меньшим кол-вом адресов: <br>
                  Для + ищет сети с префиксом меньше или равным заданному.<br>
                  Для - ищет сети с префиксом больше или равным заданному.
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">/24</span> <span class="mark">/22-</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">our</span></td>
                <td class="filter-description">Наша сделка (и в покупке, и в продаже)</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">oursell</span></td>
                <td class="filter-description">Наша сделка в продаже ("Наша сеть")</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">ourbuy</span></td>
                <td class="filter-description">Наша сделка в покупке ("Покупаем себе")</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">ourwo</span></td>
                <td class="filter-description">Не наша сделка (ни в покупке, ни в продаже)</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">ourwobuy</span></td>
                <td class="filter-description">Не наша сделка в покупке (не "Покупаем себе")</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">[Дата] - [Дата]</span><br>
                  <span class="mark">[Дата] - </span><br>
                  <span class="mark">- [Дата]</span><br>
                  <span class="mark">[Дата]</span>
                </td>
                <td class="filter-description">
                  Поиск по интервалу дат<br>
                  Поиск по дате от<br>
                  Поиск по дате до<br>
                  Поиск по точной дате
                </td>
                <td class="filter-mods">
                  Поддерживаемые форматы дат:<br>
                  YYYY-MM-DD<br>
                  DD.MM.YYYY<br>
                  YYYY.MM.DD<br>
                  MM/DD/YYYY
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">2023-01-01</span> <span class="mark">02.05.2023 - 09.05.2023</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="fw-bold filter-name"><span class="mark">AS[число]</span></td>
                <td class="filter-description">Поиск по введенной AS</td>
                <td class="filter-mods"></td>
              </tr>
              <tr>
                <td class="fw-bold filter-name">
                  <span class="mark">@[2-3 англ. буквы]</span>
                </td>
                <td class="filter-description">Поиск по менеджеру из сделки</td>
                <td class="filter-mods">
                  Принимаются только буквы и только 2 или 3 символа
                  <div class="filter-example">
                    Примеры запросов: <span class="mark">@aa</span> <span class="mark">@ab</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </modal-window>
    </transition>
  </teleport>
</div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AddButton from "./AddButton";
import addToQueryWithTimer from "../table/Timer";
import {getMetaMoreOrLess} from "../../../library/Numerals";
import ModalWindow from "../../tiketMenu/components/ModalWindow";

export default {
  name: "SearchString",
  components: {
    AddButton,
    ModalWindow,
  },
  data: function () {
    return {
      showHelpModal: false,
    }
  },
  methods:{
    ...mapMutations({
      'setSearch':'offerHistory/filters/setSearchString',
    }),
    change:function (value){
      addToQueryWithTimer(value, 'search')
    }

  },
  computed:{
    search:{
      set(value){
        this.setSearch(value);
      },
      get(){
        return this.getSearch;
      }
    },

    metaString() {
      let result = '';
      let metasNetwork = '';
      let search = '';
      let withNet = '';
      let deals = 'сделок';
      let dealsParts = [];

      if (typeof this.searchMeta?.our === 'string') {
        dealsParts.push('"Наша сеть" и "Покупаем себе"');
      }
      if (typeof this.searchMeta?.oursell === 'string') {
        dealsParts.push('"Наша сеть"');
      }
      if (typeof this.searchMeta?.ourbuy === 'string') {
        dealsParts.push('"Покупаем себе"');
      }
      if (typeof this.searchMeta?.ourwobuy === 'string') {
        dealsParts.push('НЕ "Покупаем себе"');
      }
      if (typeof this.searchMeta?.ourwo === 'string') {
        dealsParts.push('НЕ "Наша сеть" и НЕ "Покупаем себе"');
      }

      if (dealsParts.length) {
        deals += ' (' + dealsParts.join(' И ') + ')';
      }

      if (typeof this.searchMeta?.prefix === 'string') {
        metasNetwork += ' с префиксом сети ' + getMetaMoreOrLess(this.searchMeta.prefix);
      }

      if (typeof this.searchMeta?.type === 'string') {
        metasNetwork += this.searchMeta.type;
      }

      if (typeof this.searchMeta?.price === 'string') {
        metasNetwork += ' с ценой ' + getMetaMoreOrLess(this.searchMeta.price);
      }

      if (typeof this.searchMeta?.dateinterval === 'string') {
        metasNetwork += ` с датой сделки ${this.searchMeta?.dateinterval}`;
      }

      if (typeof this.searchMeta?.asn === 'string') {
        metasNetwork += ` c ${this.searchMeta?.asn}`;
      }

      if (typeof this.searchMeta?.manager === 'string') {
        metasNetwork += ` с менеджером ${this.searchMeta.manager}`;
      }

      if (typeof this.searchMeta?.subnet === 'string') {
        metasNetwork += `, где встречается сеть/IP ${this.searchMeta.subnet}`;
      }

      if (typeof this.searchMeta?.payment === 'string') {
        metasNetwork += `, где метод оплаты '${this.searchMeta.payment}'`;
      }

      if (typeof this.searchMeta?.search === 'string' && this.searchMeta?.search.trim().length > 0) {
        search = `, где встречается строка '${this.searchMeta.search.trim()}'`;
      }

      if (dealsParts.length || metasNetwork !== '' || search !== '') {
        result = 'Поиск ' + deals + metasNetwork + search;
      }
      return result;
    },

    ...mapGetters({
      'getSearch':'offerHistory/filters/getSearchString',
      'canChange':'offerHistory/canChange',
      'searchMeta': 'offerHistory/getSearchMeta',
    }),
  },
}
</script>

<style scoped>
.search-input {
  width: -moz-available;
  width: -webkit-fill-available;
}
.meta-string {
  font-size: 12px;
  margin: 2px 0;
}
:deep(.modal-dialog-scrollable.modal-help .modal-body) {
  max-height: calc(100vh - 130px);
  margin-top: 0;
  padding-bottom: 0;
}
:deep(.mark) {
  margin-bottom: 2px;
  display: inline-block;
  --bs-highlight-bg: var(--bs-gray-300);
  border-radius: 4px;
}
</style>