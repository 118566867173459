<template>
  <popper :content="text" class="light" placement="top" :show="showPopper" :interactive="false" :arrow="true">
    <span class="clipboard-string" v-clipboard @click="copy" @mouseenter="showPopper = true" @mouseleave="showPopper = false; copied = false;">{{string}}</span>
  </popper>
</template>

<script>
export default {
  name: "StringClipboard",

  props: {
    string: {
      type: String,
      require: true,
    }
  },

  data: function() {
    return {
      showPopper: false,
      copied: false,
    }
  },

  computed: {
    text: function() {
      return this.copied ? 'Скопировано' : 'Скопировать';
    }
  },

  methods: {
    copy: function() {
      this.copied = true;
    },
  }
}
</script>

<style scoped>
.clipboard-string {
  cursor: pointer;
}
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>