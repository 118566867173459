<template>
  <div class="mb-3" >
    <label for="commentc" class="form-label">Шаблон (EN)</label>
    <mde-text-area id="commentc" v-model="template.body_en"  ref="comment"  ></mde-text-area>
  </div>
</template>

<script>
import MdeTextArea from "./MdeTextArea";

export default {
  name: "Simplemde",
  components:{
    MdeTextArea
  },
  data: function () {
    return {}
  },
  props:{
    'template':{
      type:Object
    }
  },

  mounted() {

  }
}
</script>

<style scoped>

</style>