import RatesMain from "../../components/rates/RatesMain";
import {createRouter, createWebHistory} from "vue-router/dist/vue-router";



const routes = [

    {
        path:"/offers/rates/",
        name:"Rates",
        components:{
            main:RatesMain
        },
    },

]



const ratesRouter =  createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'
    }
)

ratesRouter.beforeEach((to, from, next) => {
    next();
});

export default ratesRouter;