<template>
  <button  @click="showModal = true"  :class="{'text-muted':loading, 'custom-disabled':loading}"  class="btn btn-light btn-outline-dark col-12"><i class="fa fa-forward button-icon menu-button"></i>Добавить в проект</button>
  <teleport to="body">
    <transition name="modal">
      <modal-window  @close="showModal = false" v-if="showModal" @success="mergeTickets()" modal-dialog-class="modal-lg" >
        <template v-slot:header>
          <h5 class="modal-title" >Прикрепить запросы к проекту</h5>
        </template>
        <template v-slot:body>
          <modal-body @changeValues="changeValues($event)" ></modal-body>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary"  @click="showModal = false">Отмена</button>
          <button type="button" class="btn btn-primary" :disabled="isSuccessDisabled()"  @click="mergeTickets()">Прикрепить</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import ModalWindow from "./ModalWindow";
import ModalBody from "./addToProject/ModalBody";

export default {
  name: "AddToProject",
  components: {ModalBody, "modal-window":ModalWindow},
  data: function (){
    return {
      showModal:false,
      ticketId:"",
      projectId: "",
    }
  },
  props:{
    callBack:{
      type:Function
    },
    'route':{
      type:Function,
      default:null
    },
  },
  computed:{
    loading:function (){
      return this.$store.getters["tickets/isTicketLoading"];
    },
    selectedArray:function (){
      return this.$store.getters["tickets/getSelectedArray"];
    }
  },
  methods:{
    changeValues({ticketId, projectId} ){
      this.ticketId = ticketId;
      this.projectId = projectId;
    },
    isSuccessDisabled(){
      return this.ticketId == '' &&  this.projectId == '';
    },

    mergeTickets:function (){
      if( !this.isSuccessDisabled()){
        let object = {
          tickets: this.selectedArray,
          project: this.projectId,
          ticket: this.ticketId,
          route:this.$route,
        }
        if(this.route !== null){
          object.route = this.route;
        }

        if (this.ticketId === '') {
          object.action = 'transferProject';
        } else {
          object.action = 'transferTicket';
          object.from = object.tickets;
          object.to = this.ticketId;
        }

        this.$store.dispatch('tickets/mergeTicketsAndProjects', object).then(result => {
          if (result?.data?.success === true) {
            if (this.selectedArray.length > 1) {
              this.$store.commit('successPush', 'Тикеты успешно добавлены в проект', {root:true});
            } else {
              this.$store.commit('htmlSuccessPush', 'Тикет успешно добавлен в проект <a href="/tickets/' +
                  (object.action === 'transferProject' ? this.selectedArray[0] : this.ticketId) +
                  '" target="_blank">&#10095;&#10095;</a>');
            }
          }
        }).catch(() => {});
        this.showModal = false;
        if(this.callBack){
          this.callBack();
        }
      }

    }
  }
}
</script>

<style scoped>

</style>