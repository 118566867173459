import {CrmApi} from "../CrmApi";

export {Person};
class Person {

    static getPerson( filters = undefined){
        let url = '/person/';
        if(filters !== undefined && Object.keys(filters).length !== 0){
            url = url + "?" +  Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join("&");
        }
        return CrmApi.call(url);
    }

    static edit(id, params){
        id = window.getId(id);
        let url ='/person/' + encodeURIComponent(window.getId(id));
        return CrmApi.call(url, params);
    }
}