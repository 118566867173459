import * as api from "../../../components/ticket/Api/api";
import {CrmApi} from "../../../library/CrmApi";
import {Ticket} from "../../../library/api/Ticket";


export const addTags = ({commit, dispatch}, {ids, tags, tags_data, route}) => {
  dispatch('setLoading', {
    loading: true,
    ids: ids,
  });
  Ticket.changeTags({ids, tags, tags_data}).then(() => {
    // reload or callback
    if (typeof route === 'function') {
      route(tags);
    } else {
      dispatch('getAllTickets', route);
    }
  }).catch((message) => {
    commit('errorPush', CrmApi.getErrorMessage(message), {root: true});
  }).finally(() => {
    dispatch('setLoading', {
      loading: false,
      ids: ids,
    });
  });
}

/**
 *
 * @param commit
 * @param dispatch
 * @param ticketIds
 * @param project
 * @param ticket
 * @param assigned_projects
 * @param route
 * @returns {boolean} true в случае если все запросы прошли успешно, false если при мерджах были ошибки
 */
export const mergeTickets = ({commit, dispatch} , { ticketIds ,  project , ticket , assigned_projects , route}) => {
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });

  return new Promise((resolve, reject) => {
    let request;
    for (let ticketId of ticketIds) {
      request = api.updateTicket({
            ticketId: ticketId,
            project: project,
            ticket: ticket,
            assigned_projects: assigned_projects
          }, () => {
          },
          message => {
            commit('errorPush', message, {root: true});
            reject(message);
          }, () => {
            commit('addLoading', {
              loading: false,
              id: ticketId,
            });
          })
    }
    request.finally(() => {
      dispatch('setLoading', {
        loading: false,
        ids: ticketIds,
      });
      // reload or callback
      if (typeof route === 'function') {
        route(project, assigned_projects);
      } else {
        dispatch('getAllTickets', route);
      }
      resolve(true);
    });
  });
}

export const mergeTicketsAndProjects = ({commit, dispatch}, {tickets, project, assigned_projects, assigned_projects_data,  route, action, from, to}) => {
  dispatch('setLoading', {
    loading: true,
    ids: tickets,
  });

  return new Promise((resolve, reject) => {
    let request;
    if (action === 'transferTicket') {
      request = Ticket.mergeTickets({from, to});
    } else {
      request = Ticket.changeProject({tickets, project, assigned_projects, assigned_projects_data});
    }
    request.then((result) => {
      resolve(result);
    }).catch((error) => {
      commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
      reject(error)
    }).finally(() => {
      dispatch('setLoading', {
        loading: false,
        ids: tickets,
      });
      if (typeof route === 'function') {
        route(project, assigned_projects);
      } else {
        dispatch('getAllTickets', route);
      }
    });
  });
}

export const addToProject = ({commit, dispatch}, {ticketIds, project, ticket, assigned_projects, route}) => {
  dispatch('setLoading', {
    loading: true,
    ids: ticketIds,
  });
  let promises = ticketIds.map((ticketId) => {
    return api.updateTicket({
      ticketId: ticketId,
      project: project,
      ticket: ticket,
      assigned_projects: assigned_projects
    },
    () => {},
    message => {
      commit('errorPush', message, {root: true});
    },
    () => {
      commit('addLoading', {
        loading: false,
        id: ticketId,
      });
    });
  });
  return new Promise((resolve, reject) => {
    Promise.all(promises).then(() => {
      resolve(true);
    }).catch(() => {
      reject(false);
    }).finally(() => {
      dispatch('setLoading', {
        loading: false,
        ids: ticketIds,
      });
      if (typeof route === 'function') {
        route(project, assigned_projects);
      } else {
        dispatch('getAllTickets', route);
      }
    });
  });
}

export const setNotificationWatched = ({commit, dispatch } , {ticketIds, route }) => {
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });

  return api.setNotificationWatched({ticketIds} , result => {
  }, message => {
    commit('errorPush', message, {root: true});
  }, ()=>{
    commit('clearLoading');
    commit('clearSelect');
    // reload
    dispatch('getAllTickets' , route);
  })
}

export const setNotificationUnWatched = ({commit, dispatch}, {ticketIds, route}) => {
  dispatch('setLoading', {
    loading: true,
    ids: ticketIds,
  });

  return api.setNotificationUnWatched({ticketIds}, result => {
  }, message => {
    commit('errorPush', message, {root: true});
  }, ()=>{
    commit('clearLoading');
    commit('clearSelect');
    // reload
    dispatch('getAllTickets', route);
  })
}

export const takeTicketsToWork = ({commit, dispatch , getters } , {ticketIds, route, finallyCallback}) =>{
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });
  let promise;
  ticketIds.forEach(function(element, index) {
    promise = api.takeTicketToWork({"ticketId": element} , (result , elem ) => {
     commit('addLoading' , {
        loading:false,
        id:elem,
      });
     let name = '...';
     if(getters.getSelectedObjects?.[elem]?.name){
       name = '"'+ getters.getSelectedObjects[elem].name + '"';
       if(getters.getSelectedObjects[elem].name.length > 15){
         name = '"'+ getters.getSelectedObjects[elem].name.substring(0 , 15) + "..." + '"';
       }
     }
      commit('successPush' ,'Тикет '+ name +' взят в работу', {root:true});
    }, (massage , elem ) => {
      commit('addLoading' , {
        loading:false,
        id:elem,
      });
      let text = "Id: " + elem + ". Неизвестная ошибка";
      if(typeof massage !== 'undefined' && massage !== null && massage !== ""){
        text =  "Id: " + elem + ". " + massage;
      }
      commit('errorPush' ,text, {root:true});
    } , ()=> {

    })
  })
  promise.finally(() => {
    commit('clearLoading');
    // reload
    dispatch('getTickets', {
      payload: route,
      finallyCallback: finallyCallback,
    });
  })
}

export const unlockTickets = ({commit, dispatch, getters}, {ticketIds, route, finallyCallback}) => {
  dispatch('setLoading', {
    loading: true,
    ids: ticketIds,
  });
  let promise;
  ticketIds.forEach(function (element) {
    promise = api.unlockTicket({"ticketId": element}, (result, elem) => {
      commit('addLoading', {
        loading: false,
        id: elem,
      });
      let name = '...';
      if(getters.getSelectedObjects?.[elem]?.name){
        name = '"'+ getters.getSelectedObjects[elem].name + '"';
        if(getters.getSelectedObjects[elem].name.length > 15){
          name = '"'+ getters.getSelectedObjects[elem].name.substring(0 , 15) + "..." + '"';
        }
      }
      commit('successPush' ,'Тикет '+ name +' разблокирован', {root:true});
    }, (massage, elem) => {
      commit('addLoading', {
        loading: false,
        id: elem,
      });
      let text = "Id: " + elem + ". Неизвестная ошибка";
      if (typeof massage !== 'undefined' && massage !== null && massage !== "") {
        text = "Id: " + elem + ". " + massage;
      }
      commit('errorPush', text, {root: true});
    }, () => {

    });
  });
  promise.finally(() => {
    commit('clearLoading');
    // reload
    dispatch('getTickets', {
      payload: route,
      finallyCallback: finallyCallback,
    });
  });
}

export const setAllRead = ({commit, dispatch }, {project, route}) => {
  api.setAllRead({project}, result => {

  }, message => {
    commit('errorPush', message, {root: true});
  }, ()=>{
    commit('clearLoading');
    // reload
    dispatch('getAllTickets' , route);
  });
}

export const setReadedOrNotReaded = ({commit, dispatch } , {ticketIds, route , readed = "setReaded", valueRead, callback}) => {
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });

  api[readed]({ticketIds} , result => {
    if(readed === "setReaded"){
      valueRead.isTicketsReaded = true;
      valueRead.isTicketsNotReaded = false;
    }else {
      valueRead.isTicketsReaded = false;
      valueRead.isTicketsNotReaded = true;
    }
    if(typeof callback === 'function') {
      callback();
    }
  }, massage => {
    commit('errorPush', CrmApi.getErrorMessage(massage), {root: true});
  }, ()=>{
    commit('clearLoading');
    // reload
    dispatch('getAllTickets' , route);
  })
}

export const restoreTickets = ({commit, dispatch , state }, {ticketIds, route } ) =>{
    dispatch('setLoading' , {
      loading:true,
      ids: ticketIds,
    });
  api.restoreTickets({ticketIds} , result => {
    if(ticketIds.length > 1) {
      commit('successPush', 'Тикеты успешно восстановлены', {root:true});
    } else {
      commit('htmlSuccessPush', 'Тикет успешно восстановлен <a href="/tickets/' +
          ticketIds[0] +
          '" target="_blank">&#10095;&#10095;</a>', {root:true});
    }
  }, message => {
    commit('errorPush', message, {root: true});
  }, ()=>{
    commit('clearLoading');
    commit('clearSelect');
    // reload or callback
    if(typeof route === 'function') {
      route();
    } else {
      dispatch('getAllTickets', route);
    }
  })
}

export const removeTickets = ({commit, dispatch , state } , {ticketIds, route }) => {
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });

  api.removeTickets({ticketIds} , result => {
    let text = "Тикеты успешно удалены";
    if(state.ticketSelectedControl.length == 1){
      text = "Тикет успешно удален";
    }
    commit('successPush' , text , {root:true});
  }, message => {
    commit('errorPush', message, {root:true});
  }, ()=>{
    commit('clearLoading');
    commit('clearSelect');
    // reload or callback
    if(typeof route === 'function') {
      route();
    } else {
      dispatch('getAllTickets', route);
    }
  })
}

export const deleteTickets = ({commit, dispatch , state } , {ticketIds, route , withTag }) => {
  dispatch('setLoading' , {
    loading:true,
    ids: ticketIds,
  });
  api.deleteTicket({ticketIds , withTag} , result => {
    let text = "Тикет успешно удален";
    if(state.ticketSelectedControl.length > 1){
      text = "Тикеты успешно удалены";
    }
    commit('successPush' , text , {root:true});
  }, message => {
    commit('errorPush', message, {root: true});
  }, ()=>{
    commit('clearLoading');
    commit('clearSelect');
    // reload or callback
    if(typeof route === 'function') {
      route();
    } else {
      dispatch('getAllTickets', route);
    }
  })
}

export const deleteNotifications = ({commit, dispatch, state}, {ids, route}) => {
  dispatch('setLoading', {
    loading: true,
    ids: ids,
  });
  Ticket.deleteNotifications({ids}).then((result) => {
    let text = state.ticketSelectedControl.length > 1 ? "Рассылки успешно удалены" : "Рассылка успешно удалена";
    commit('successPush', text, {root: true});
    // reload or callback
    if (typeof route === 'function') {
      route();
    } else {
      dispatch('getAllTickets', route);
    }
    //commit('clearSelect');
  }).catch((message) => {
    commit('errorPush', CrmApi.getErrorMessage(message), {root: true});
  }).finally(() => {
    commit('clearLoading');
  });
}


export const getAllTickets = ({state , commit, dispatch, rootGetters} , payload) =>{
  if(rootGetters.getUserSettings?.tickets?.onpage && !payload?.query?.onpage){
    payload.query.onpage = rootGetters.getUserSettings.tickets.onpage
  }
  dispatch('getTickets', {payload: payload});
}

export const getTickets = ({state, commit, dispatch , rootGetters}, {payload, finallyCallback}) => {
  let route = {};
  route.query = Object.assign({} , payload.query);
  route.params = Object.assign({} ,  payload.params);
  route.name =  payload.name;
  commit('setLoadingAll', true)
  api.getTicketList(route,result => {
    if(!isParamsChange(route , rootGetters.getRoute)){
      commit('setTickets', result.data.data )
      commit('setPagination', getPaginationData(result))
      dispatch('returnSelectedTickets');
      dispatch('updateSelectedTickets')
      commit('setLoadingAll', false)
    }

  }, message => {
    commit('errorPush', message, {root: true});
    commit('setLoadingAll', false)
  }, () => {

      commit('resetTicketListAutoUpdate')
      if(api.isItTicketsListRoute(route.name)){
        dispatch('setAutoUpdateTicket' , payload);
      }
      if (typeof finallyCallback === 'function') {
        finallyCallback();
      }
  });
}

export const disableAutoUpdate = ({commit}) =>{
  commit('setAutoUpdateTrigger' , false)
  commit('resetTicketListAutoUpdate')
}

export const enableAutoUpdate = ({commit}) =>{
  commit('setAutoUpdateTrigger' , true)
}

export function checkQuery(paramsToCheck , payload , query) {
  let isParamsChange = false;
  for (let key in query.query){
    if(typeof paramsToCheck[key] !== 'undefined'){
      if(typeof  payload.query[key] === 'undefined' || query.query[key] !== payload.query[key]){
        isParamsChange = true;
        break;
      }
    }
  }
  return isParamsChange;
}

// old route - payload
// new route - query
function isParamsChange(payload , query){

  let isParamsChange = false;

  if(query.name !== payload.name || query.params.filter !== payload.params.filter
      || query.params.project !== payload.params.project){
    isParamsChange = true;
    return isParamsChange;
  }

  let paramsToCheck = {
    page:true,
    onpage:true,
    name:true,
    updated:true,
    'person.email':true,
    'lastMessage.headers.to':true,
    search:true,
    oB:true,
  }
  isParamsChange = checkQuery(paramsToCheck, payload , query);
  if(!isParamsChange){
    isParamsChange = checkQuery(paramsToCheck , query , payload)
  }
  if(!isParamsChange){
    if(
        typeof payload.query.search === 'undefined' ||
        payload.query.search === ''
    ){
      if(
          typeof payload.query.searchParams !== 'undefined' && typeof query.query.searchParams === 'undefined' ||
          typeof payload.query.searchParams === 'undefined' && typeof query.query.searchParams !== 'undefined' ||
          typeof payload.query.searchParams != null && typeof query.query.searchParams == null ||
          typeof payload.query.searchParams == null && typeof query.query.searchParams != null
      ){
        isParamsChange = true;
      } else if (typeof payload.query.searchParams !== 'undefined' && typeof query.query.searchParams !== 'undefined') {
        let searchParamsL = JSON.parse(payload.query.searchParams);
        let searchParamsR = JSON.parse(query.query.searchParams);
        if (searchParamsL.withAttachment !== searchParamsR.withAttachment) {
          isParamsChange = true;
        }
        if (searchParamsL.unread !== searchParamsR.unread) {
          isParamsChange = true;
        }
        if (searchParamsL.new !== searchParamsR.new) {
          isParamsChange = true;
        }
      }
    }else if(typeof payload.query.search !== 'undefined'  && payload.query.search !== ''){
      if(!isParamsChange){
        paramsToCheck = {
          searchAlias:true,
          searchProduct: true,
          searchParams:true,
        }
        isParamsChange = checkQuery(paramsToCheck, payload , query);
        if(!isParamsChange){
          isParamsChange = checkQuery(paramsToCheck , query , payload)
        }
      }
    }
  }

  return isParamsChange ;

}
export const autoUpdateTicketList = ({commit, getters, state, dispatch , rootGetters} , payload) =>{
  commit('resetTicketListAutoUpdate');
  let route = {};
  route.query = Object.assign({} , payload.query);
  route.params = Object.assign({} ,  payload.params);
  route.name =  payload.name;
  if(!getters.isAllLoading && !getters.isTicketLoading ){
    api.getTicketList(route,result => {

      if(!getters.isAllLoading && !getters.isTicketLoading && api.isItTicketsListRoute(route.name) ){
        if(!isParamsChange(route , rootGetters.getRoute)){
          let selectedElements = {};
          for (let value of result.data.data) {
            if(typeof getters.getSelectedObjects[value._id] !== 'undefined'){
              selectedElements[value._id] = value._id;
            }
          }
          for (let value of getters.getSelectedArray) {
            if(typeof selectedElements[value] === 'undefined'){
              commit('unSelectTicket' , {_id:value});
            }
          }
          commit('setTickets', result.data.data )
          commit('setPagination', getPaginationData(result))
        }
      }
    }, massage => {
      console.error(massage)
    }, ()=>{
        dispatch('updateSelectedTickets')
        dispatch('setAutoUpdateTicket' , payload);
    } )
  }
}

export const updateSelectedTickets = ({commit,  state}) =>{
  if(state.ticketSelectedControl.length > 0){
    let updatedTickets = {};
    let c = 0;
    for (let obj of state.ticketList){
      let id = window.getId(obj._id);
      if(typeof  state.ticketSelectedControl.objects[id] !== 'undefined'){
        updatedTickets[id] = obj;
        c++;
        commit('updateSelected', {ticket:obj});
      }
    }
    if(c !==  state.ticketSelectedControl.length){
      for (let key in state.ticketSelectedControl.objects){
        let obj =  state.ticketSelectedControl.objects[key];
        let id = window.getId(obj._id);
        if(typeof updatedTickets[id] === 'undefined' ){
          commit('unSelectTicket',obj);
        }
      }
    }
  }
}

export const setAutoUpdateTicket = ({commit, dispatch, getters} ,payload ) => {
  if(getters.isAutoUpdateOn){
    commit('setTicketListAutoUpdate' , () => {
      dispatch('autoUpdateTicketList' , payload)
    })
  }

}


export const setLoading = ({commit}, settings) =>{
  let ids = settings.ids;
  let loading = settings.loading;
  ids.forEach(function (id, index) {
    commit('addLoading' , {
      id:id,
      loading:loading,
    })
  });
}

export const reset = ({commit , dispatch} , withList = false) =>{
  commit('clearLoading');
  commit('clearAlerts' ,{}, {root:true});
  commit('clearSelect');
  if(withList){
    commit('filters/clearSearch');
    dispatch('filters/returnFilterValuesToDefault')
    commit('resetTicketListAutoUpdate');
    commit('clearList');

  }
}

export const selectElement = ({commit} , ticket) =>{
  commit('clearSelect');
  commit('addToSelectedControl' , ticket);
}


export const selectFromTopToBottom = ({commit , state, dispatch , getters }) =>{
  let lastTicket = null;
  if(getters.getTicketList.length > 0){
    let lastId = null;
    if(getters.getSelectedArray.length === 1){

      lastId = getters.getSelectedArray[0];
    }

    let isHasSelected = false;
    let line = getters.getTicketList.length-1;
    for (let i = getters.getTicketList.length-1; i >= 0; i--){
      let ticket = getters.getTicketList[i];
      if(typeof getters.getSelectedObjects[window.getId(ticket._id)] !== 'undefined'){
        isHasSelected = true
        break;
      }
      line = i;
      lastTicket = ticket;
    }
    if( lastTicket === null){
      lastTicket = getters.getTicketList[ getters.getTicketList.length - 1];
    }
    if(!isHasSelected){
      line = 0;
      lastTicket = getters.getTicketList[0];
    }
    if(lastId ===null || lastId !== window.getId(lastTicket._id)){
      let rows = document.querySelectorAll('#table-ticket-list tr');
      if(typeof rows[line+2] !== 'undefined' &&  rows[line+2] !== null && typeof rows[line+2].scrollIntoView !== 'undefined' ){
        rows[line+2].scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });
      }else {
        if(typeof rows[line] !== 'undefined' &&  rows[line] !== null && typeof rows[line].scrollIntoView !== 'undefined'){
          rows[line].scrollIntoView({
            behavior: 'auto',
            block: 'center'
          });
        }

      }

      commit('clearSelect');
      dispatch('selectElement' ,  lastTicket);
    }

  }
}

export const saveSelectedIds = ({commit , state, dispatch , getters }) =>{
  state.lastSelectedIds.ids = [];
   for (let selected of getters.getSelectedArray){
     commit('addLastSelectId' , selected)
   }
}

export const returnSelectedTickets = ({commit , state, dispatch , getters })  => {
  if(state.toLastSelect){
    dispatch('setPreventSelectedIds')
  }else {
    dispatch('setNextSelectedIds')
  }
}

export const setNextSelectedIds = ({commit , state, dispatch , getters }) => {
  if(getters.getLastSelectedIds.length > 0){
    let lastSelected = {};
    if( state.ticketList.length === 0){
      return;
    }
    for (let selected of getters.getLastSelectedIds){
      lastSelected[selected] = selected;
    }
    let isSelectedTicketInList;
    for (let i in state.ticketList){
      let obj = state.ticketList[i];
      let id = window.getId(obj._id);
      if(typeof  lastSelected[id] !== 'undefined'){
         isSelectedTicketInList = true;
         break;
      }
    }
     let p = state.lastSelectedIds.lastSelectedPosition;

    if(isSelectedTicketInList){
      p++;
    }
    if(typeof state.ticketList[p] === 'undefined'){
      p = 0;
    }
    commit('addToSelectedControl', state.ticketList[p]);
    let scrollLine = p;
    dispatch('scrollToLine' , {scrollLine:scrollLine})
    commit('resetLastSelectedIds')
    state.toLastSelect = true;
  }

}

export const setPreventSelectedIds = ({commit , state, dispatch , getters }) =>{
  let lastSelected = {};
  if(getters.getLastSelectedIds.length > 0){
    if( state.ticketList.length === 0){
      return;
    }
    for (let selected of getters.getLastSelectedIds){
      lastSelected[selected] = selected;
    }

    let scrollLine = null;
    for (let i in state.ticketList){
      let obj = state.ticketList[i];
      let id = window.getId(obj._id);
      if(typeof  lastSelected[id] !== 'undefined'){
        if(scrollLine === null){
          scrollLine = i;
        }
        commit('addToSelectedControl', obj);
      }
    }
    dispatch('scrollToLine' , {scrollLine:scrollLine})

    commit('resetLastSelectedIds')
  }
}


export const scrollToLine = ({commit , state, dispatch , getters } , {scrollLine} )=> {
  if(typeof scrollLine !== 'undefined' && scrollLine !== null){
    let rows = document.querySelectorAll('#table-ticket-list tr');
    if(typeof  rows[scrollLine] !== 'undefined'  && typeof rows[scrollLine].scrollIntoView !== 'undefined'){
      rows[scrollLine].scrollIntoView({
        behavior: 'auto',
        block: 'center'
      });
      state.tryToScrollAfterTick = null;
    }else {
      state.tryToScrollAfterTick = scrollLine;
    }
  }

}



export const addOneMoreFromTopToBottom = ({commit , state, dispatch , getters } )=>{
  if(getters.selectedCount === 0){
    dispatch('selectFromTopToBottom');
  }else {
    let nextSelected = null;

    for (let i = getters.getTicketList.length - 1; i >= 0 ; i--) {
      let el =  getters.getTicketList[i]

      if(el._id === state.ticketSelectedControl.lastSelectedElem){
        if(nextSelected !== null){
          if(typeof  state.ticketSelectedControl.objects[nextSelected._id] !== 'undefined' ){
            commit('unSelectTicket',  el)
            state.ticketSelectedControl.lastSelectedElem = nextSelected._id;
          }else {
            commit('addToSelectedControl' , nextSelected);
          }
        }else if(getters.selectedCount === 1){
          commit('unSelectTicket',  el)
        }
        let rows = document.querySelectorAll('#table-ticket-list tr');
        rows[i].scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });
        break;
      }
      nextSelected = el;
    }


  }
}

export const addOneMoreFromBottomToTop = ({commit , state, dispatch , getters } )=>{
  if(getters.selectedCount === 0){
    dispatch('selectFromBottomToTop');
  }else {
    let preventSelected = null;
    let line = 0;
    for (let el of getters.getTicketList) {
        line++;
        if(el._id === state.ticketSelectedControl.lastSelectedElem){
          if(preventSelected !== null){
            if(typeof  state.ticketSelectedControl.objects[preventSelected._id] !== 'undefined' ){
              commit('unSelectTicket',  el)
              state.ticketSelectedControl.lastSelectedElem = preventSelected._id;
            }else {
              commit('addToSelectedControl' , preventSelected);
            }
          }else if(getters.selectedCount === 1) {
            commit('unSelectTicket',  el)
          }
          let rows = document.querySelectorAll('#table-ticket-list tr');
          rows[line].scrollIntoView({
            behavior: 'auto',
            block: 'center'
          });
          break;
        }
       preventSelected = el;
    }
  }
}

export const selectFromBottomToTop  = ({commit , state, dispatch , getters }) =>{
  let lastTicket = null;
  if(getters.getTicketList.length > 0){
    let lastId = null;
    if(getters.getSelectedArray.length === 1){

      lastId = getters.getSelectedArray[0];
    }

    let isHasSelected = false;
    let line = 0;
    for (let ticket of getters.getTicketList){
      line++;
      if(typeof getters.getSelectedObjects[window.getId(ticket._id)] !== 'undefined'){
        isHasSelected = true
        break;
      }
      lastTicket = ticket;
    }
    if(lastTicket === null){
      lastTicket = getters.getTicketList[0];
    }
    if(!isHasSelected ){
      line= getters.getTicketList.length - 1;
      lastTicket = getters.getTicketList[ getters.getTicketList.length - 1];
    }
    if(lastId ===null || lastId !== window.getId(lastTicket._id)){

      let rows = document.querySelectorAll('#table-ticket-list tr');

      if(typeof rows[line-2] !== 'undefined' && rows[line-2] !== null && typeof  rows[line-2].scrollIntoView !== 'undefined' ){
        rows[line-2].scrollIntoView({
          behavior: 'auto',
          block: 'center'
        });
      }else {
        if(typeof rows[line] !== 'undefined' && rows[line] !== null && typeof  rows[line].scrollIntoView !== 'undefined'){
          rows[line].scrollIntoView({
            behavior: 'auto',
            block: 'center'
          });
        }

      }
      commit('clearSelect');
      dispatch('selectElement' ,  lastTicket);
    }

  }

}

export const multySelectElements = ({commit , state, dispatch , getters } , ticket) =>{
  let lastElementId = state.ticketSelectedControl.lastSelectedElem;
  if(ticket._id === lastElementId){
    dispatch('selectElement' ,  ticket);
  }else {
    let startId = null;
    let endId = null;
    commit('clearSelect');
    let breakObject = {};
    try {
      getters.getTicketList.forEach((value, key) => {
        if(lastElementId === null){
          lastElementId = value._id;
        }
        if(startId === null){
          if( value._id === ticket._id || value._id === lastElementId){
            startId = value._id;
          }
        }

        if(startId !== null){
          commit('addToSelectedControl' , value);
          if(endId === null && startId !== value._id &&  (value._id === ticket._id || value._id === lastElementId ) ){
            endId = value._id;
            throw breakObject;
          }
        }
      })
    }catch (breakObject){}

  }
}

function getPaginationData(result){
  return {
    page: parseInt(result.data.page),
    onpage: parseInt(result.data.onpage),
    total: parseInt(result.data.total),
    paginator_links: result.data.paginator_links
  }
}
