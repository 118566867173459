

export const getMovingModalContent = state => {
    return  state.movingModal.value;
}


export const isScreenSmall = state => {
    return window.screen.width <  1600 && window.screen.height < 1000 // 1440x900
}


export const isHasMovingModalContent = state => {
    return state.movingModal.value.trim() !== '';
}

export const isMovingModalOpen = state  => {
    return state.movingModal.isOpen;
}

export const getRoute = state => {
    return state.route;
}

export const getRates = state => state.rates;

export const getProjects = state => {
    return (state.leftMenu.topLevel ?? []).filter(item => item?._id?.length === 24);
}

export const getActiveWindow = state => state.activeWindows[state.activeWindows.length - 1];

export const getUserSettings = state => state.userSettings;