<template>
  <tr v-if="data" :class="tdClass">
    <td>{{ userName ?? '&mdash;' }}</td>
    <td class="text-center">
      <span v-if="total && data?.countOutCalls">{{ data?.countOutCalls }}</span>
      <a v-else-if="data?.countOutCalls" :href="hrefOutcome" target="_blank">{{ data.countOutCalls }}</a>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <a v-if="data.minOutCalls && hrefMinOutCall" :href="hrefMinOutCall" target="_blank">
        <timediff v-if="data.minOutCalls" :from="0" :to="data.minOutCalls * 1000"></timediff>
      </a>
      <timediff v-else-if="data.minOutCalls" :from="0" :to="data.minOutCalls * 1000"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <timediff v-if="data.avgOutCalls" :from="0" :to="data.avgOutCalls * 1000"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <a v-if="data.maxOutCalls && hrefMaxOutCall" :href="hrefMaxOutCall" target="_blank">
        <timediff v-if="data.maxOutCalls" :from="0" :to="data.maxOutCalls * 1000"></timediff>
      </a>
      <timediff v-else-if="data.maxOutCalls" :from="0" :to="data.maxOutCalls * 1000"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <timediff v-if="data.sumOutCalls" :from="0" :to="data.sumOutCalls * 1000"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <span v-if="total && data?.countInCalls">{{ data?.countInCalls }}</span>
      <a v-else-if="data?.countInCalls" :href="hrefIncome" target="_blank">{{ data.countInCalls }}</a>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <a v-if="data.minInCalls && hrefMinInCall" :href="hrefMinInCall" target="_blank">
        <timediff v-if="data.minInCalls" :from="0" :to="data.minInCalls"></timediff>
      </a>
      <timediff v-else-if="data.minInCalls" :from="0" :to="data.minInCalls"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <timediff v-if="data.avgInCalls" :from="0" :to="data.avgInCalls"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <a v-if="data.maxInCalls && hrefMaxInCall" :href="hrefMaxInCall" target="_blank">
        <timediff v-if="data.maxInCalls" :from="0" :to="data.maxInCalls"></timediff>
      </a>
      <timediff v-else-if="data.maxInCalls" :from="0" :to="data.maxInCalls"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <timediff v-if="data.sumInCalls" :from="0" :to="data.sumInCalls"></timediff>
      <span v-else>&mdash;</span>
    </td>
  </tr>
</template>

<script>
import Timediff from "../common/timediff";
import {getName, getTdClass} from './statisticFunctions';
import {toRef} from "vue";
import {getTicketLink, getUserMessagesLink} from "../../library/Locations";

export default {
  name: "StatisticRowCalls",

  components: {
    Timediff
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
    total: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      require: true,
    },
    from: {

    },
    to: {

    },
    project: {
      type: String,
      default: 'all',
    }
  },

  setup(props) {
    const data = toRef(props, 'data');
    const total = toRef(props, 'total');
    const {userName} = getName({data});
    const {tdClass} = getTdClass({total});
    return {
      userName,
      tdClass,
    }
  },

  computed: {
    hrefIncome() {
      return getUserMessagesLink({
          searchAlias: 'calls',
          searchUser: this.id,
          searchFrom: this.from,
          searchTo: this.to,
          call_type: 'incoming',
          page: 1,
          withoutEmpty: true,
        },
        this.project ?? 'all',
        'calls'
      );
    },
    hrefOutcome() {
      return getUserMessagesLink({
          searchAlias: 'calls',
          searchUser: this.id,
          searchFrom: this.from,
          searchTo: this.to,
          call_type: 'outgoing',
          page: 1,
          withoutEmpty: true,
        },
        this.project ?? 'all',
        'calls'
      );
    },
    hrefMinInCall() {
      return typeof this.data?.minInCalls_data !== 'undefined' && this.data.minInCalls_data ? getTicketLink(this.data.minInCalls_data) : '';
    },
    hrefMaxInCall() {
      return typeof this.data?.maxInCalls_data !== 'undefined' && this.data.maxInCalls_data ? getTicketLink(this.data.maxInCalls_data) : '';
    },
    hrefMinOutCall() {
      return typeof this.data?.minOutCalls_data !== 'undefined' && this.data.minOutCalls_data ? getTicketLink(this.data.minOutCalls_data) : '';
    },
    hrefMaxOutCall() {
      return typeof this.data?.maxOutCalls_data !== 'undefined' && this.data.maxOutCalls_data ? getTicketLink(this.data.maxOutCalls_data) : '';
    },
  }
}
</script>

<style scoped>
.total-td > td{
  border-top: 2px solid;
  font-weight: bold;
  color: rgba(33, 37, 41, 0.75) !important;
}
</style>