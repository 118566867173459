<template>
  <span @mouseover="showCopy= true"
        @mouseleave="showCopy = false"
        :class="{'loading': loading, 'align-items-center': !(asInput && absolute), 'justify-content-between': contentBetween}"
        class="d-inline-flex w-100 position-relative">
    <popper :content="nameToShow" :class="{'w-100': contentBetween}" class="light email-width" placement="top" :hover="true" :interactive="false" :arrow="true"  >
      <span class="d-inline-flex">
        <editable-text
            v-if="userCanChange"
            :text="email"
            :callback="change"
            :clipboard="clipboard"
            :input-class="inputClass"
            :input-style="inputStyle"
            :validation="validation"
            :required="main"
            :trim="trim"
            :absolute="absolute"
            :absolute-parent="absoluteParent"
            :dynamic-absolute-width="dynamicAbsoluteWidth"
            :trim-popper-placement="trimPopperPlacement"
            :disabled="loading"
            @open="asInput = true"
            @close="asInput = false"
            ref="email-field"
        />
        <string-trim v-else-if="trim" :string="email" />
        <span v-else>{{email}}</span>

        <span v-show="showCopy && !asInput">
          <copy-badge :content="email" />
        </span>
      </span>
    </popper>

    <template v-if="editableType && (userCanChange || type)">
      <span class="ps-1">
            (<editable-select
              v-if="userCanChange"
              :options="options"
              :selected="type"
              @change="changeEmailType"
              select-class="m-1 d-inline text-center form-control"
              select-style="width: 7em"
              ref="type-field"
            /><template v-else>{{type}}</template>)
      </span>
    </template>
    <span v-else-if="type" class="ps-1">({{getType(type)}})</span>
  </span>
</template>

<script>
import {CrmApi} from "../../../../../library/CrmApi";
import EditableText from "../../../../Editable/EditableText";
import EditableSelect from "../../../../Editable/EditableSelect";
import {Email} from "../../../../../library/Email";
import CopyBadge from "../../../../common/CopyBadge";
import {mapGetters, mapMutations} from "vuex";
import StringTrim from "../../../../string/StringTrim";
export default {
  name: "Email",

  components: {StringTrim, EditableText, EditableSelect, CopyBadge},
  emits: ['changeEmail', 'changeType', 'update'],

  props: {
    name:{
      type:String,
      default:null,
    },
    person: {
      require: true,
    },
    ticketId: {
      require: true,
    },
    email: {
      type: String,
      require: true,
    },
    type: {
      type: String,
    },
    main: {
      type: Boolean,
      default: false,
      // true - persons.email, false - persons.contact_emails.mail
    },
    clipboard: {
      type: Boolean,
      default: true,
    },
    editableType: {
      type: Boolean,
      default: false,
    },
    cutType: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: 'form-control d-inline',
    },
    inputStyle: {
      type: String,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false
    },
    absoluteParent: {
      default: null
    },
    dynamicAbsoluteWidth: {
      type: Boolean,
      default: false,
    },
    trimPopperPlacement: {
      type: String,
      default: 'top',
    },
    userCanChange: {
      type: Boolean,
      default: false,
    },
    contentBetween: {
      type: Boolean,
      default: true
    }
  },

  data: function() {
    return {
      showCopy: false,
      asInput: false,
      loading: false,
      options: ['личный', 'рабочий'],
      index:"",
    }
  },
  mounted() {
    if(this.person?.contact_emails !== null){
      this.index = this.main ? 0 : this.person?.contact_emails.findIndex(mail => mail.mail === this.email);
    }
  },
  computed: {
    nameToShow:function (){
      let nameToShow = this.name;
      if(typeof nameToShow === 'string'){
        nameToShow = nameToShow.trim()
      }
      return nameToShow ?? null;
    },
    change: function() {
      return this.editableType ? (this.main ? this.changeMainEmail : this.changeEmail) : this.editPerson;
    },
    validation: function() {
      return Email.validate;
    },
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },

  methods: {
    getType: function (type) {
      return (this.cutType && type.length > 4) ? type.substr(0, 3) + '.' : type;
    },

    changeEmailType: function(type) {
      let el = this.person.contact_emails[this.index];
      let prev = el.type ?? undefined;
      el.type = type;

      this.updatePerson({contact_emails: this.person.contact_emails}, () => {
        el.type = prev;
      });
    },

    changeMainEmail: function (email) {
      let prev = this.person.email;

      if(prev !== email) {
        this.person.email = email;

        return this.updatePerson({email: email}, () => {
          this.person.email = prev;
        });
      }
    },

    changeEmail: function (email) {
      let send = [ ...this.person.contact_emails ].map(item => {
        return {...item};
      });
      let el = send[this.index];

      if (email === '') {
        send.splice(this.index, 1);
      } else {
        el.mail = email;
      }

      return this.updatePerson({contact_emails: send});
    },

    remove: function() {
      this.person.contact_emails.splice(this.index, 1);
    },

    updatePerson: function (data, catchCallback = null, finallyCallback = () => { this.loading = false; }) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        CrmApi.updateThreadPerson(this.ticketId, data).then((result) => {
          this.setPerson(result.data.person);
          this.$emit('update', result.data.person);
          resolve(result.data);
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          if (catchCallback !== null) {
            catchCallback();
          }
          reject(error);
        }).finally(() => {
          finallyCallback();
        });
      });
    },

    editPerson: function (email, catchCallback = null, finallyCallback = () => { this.loading = false; }) {
      if(email !== this.email) {
        let data = {}
        if (email !== '') {
          data.add = {
            email: {}
          }
          data.add.email[this.main ? 0 : this.index + 1] = email;
        }
        data.rem = {
          email: [
              this.email
          ]
        }
        this.loading = true;

        return new Promise((resolve, reject) => {
          this.api.editPerson(data).then((result) => {
            if (typeof result.data?.person === 'object' && result.data.person !== null) {
              this.setPerson(result.data.person);
              this.$emit('update', result.data.person);
            }
            resolve(result.data);
          }).catch((error) => {
            this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
            if (catchCallback !== null) {
              catchCallback();
            }
            reject(error);
          }).finally(() => {
            finallyCallback();
          });
        });
      }
    },

    ...mapMutations({
      setPerson: 'thread/setPerson',
    })
  },
}
</script>

<style scoped>
.email-width {
  display: inline-flex;
  max-width: 300px;
}
:deep(.email-width > div.popper) {
  max-width: 100%;
}
:deep(.email-width > div:not(.popper)) {
  display: inline-flex;
  width: 100%;
}
</style>