<template>
  <div class="py-2" :class="{loading: loading}">
    <template v-if="type === 'buy_ip'">

      <prefix :prefix="fields.prefix" :type="fields.type" @update="update" :user-can-change="userCanChange"/>
      <lirid v-if="showLIRid" :lirid="fields.lirid" :user-can-change="userCanChange" @update="(val) => update(val, reloadPerson)" />
      <org v-if="showORG" :org="fields.org" :user-can-change="userCanChange" @update="(val) => update(val, reloadPerson)" />
      <price :price="fields.price"
             :price1="fields.price1"
             :currency="fields.currency"
             :quantity="fields.quantity"
             :prefix="fields.prefix"
             :can-change="userCanChange"
             @update="update" />
      <date-field :date="fields.date" :disabled="loading" :user-can-change="userCanChange" popper-text="Дата покупки" @update="update"></date-field>
      <seller v-if="seller || !fields.auction" :seller="seller" :oursubnet="fields.oursubnet?.our" :user-can-change="userCanChange" @update="update" />
      <auction v-if="fields.auction" :auction="fields.auction" />

    </template>

    <template v-else-if="type === 'sell_ip'">
      <div class="sell-ip-subnets">
        <subnets-menu @addSubnet="addSubnet" v-if="subnetsCount > 1" />
        <subnets ref="sell_ip_subnets" :class="{'ps-2':subnetsCount > 1}"
                 :subnets="fields.subnets"
                 :show-label="subnetsCount <= 1"
                 :show-add="subnetsCount <= 1"
                 :user-can-change="userCanChange"
                 :user-can-set-lot="userCanSetLot"
                 :transfer-popper="true"
                 :show-auctions="subnetsCount <= 1"
                 :lot-all-subnets="fields?.lot ?? null"
                 :operations="operations"
                 @update="update"
                 :show-bids="subnetsCount == 1"
                 @updateFields="updateFields" />
      </div>
        <price :label="product.fields?.lot?.pricetype == 'buyout'? 'Цена (BuyNow)' : 'Цена'" :price="fields.price"
               :price1="fields.price1"
               :currency="fields.currency"
               :quantity="fields.quantity"
               :subnets="fields.subnets"
               :can-change="userCanChange"
               @update="update" />
      <price v-if="product.fields?.lot?.buyout_price1" :label="'Цена (BuyNow)'"
             :price="product.fields?.lot.buyout_price"
             :price1="product.fields?.lot.buyout_price1"
             :currency="buyout.currency"
             :quantity="buyout.quantity"
             :subnets="fields.subnets"
             :can-change="userCanChange"
             @update="updateBuyOut" />
        <date-field
            :date="fields.date"
            :disabled="loading"
            :user-can-change="userCanChange"
            popper-text="Дата продажи"
            @update="update"
            :has-lot="hasLotDate"
        ></date-field>
        <buyer :buyer="buyer" :buyforus="fields.buyforus" :user-can-change="userCanChange" @update="update" />

    </template>

    <template v-else-if="type === 'ip'">

      <subnet :subnet="fields.subnet" :prefix="fields.prefix" :type="fields.type" :user-can-change="userCanChange" @update="update" />

    </template>
  </div>
</template>

<script>
import {CrmApi} from "../../../../../library/CrmApi";
import Prefix from "./Field/Prefix";
import Price from "./Field/Price";
import Subnet from "./Field/Subnet";
import Subnets from "./Field/Subnets";
import Buyer from "./Field/Buyer";
import Seller from "./Field/Seller";
import LIRid from "./Field/LIRid";
import ORG from "./Field/ORG";
import DateField from "./Field/DateField";
import {mapActions, mapGetters} from "vuex";
import Auction from "./Field/Auction.vue";
import SubnetsMenu from "./Field/SubnetsMenu.vue";


export default {
  name: "FieldGroup",
  components: {
    SubnetsMenu,
    Auction,
    lirid: LIRid,
    Buyer,
    Seller,
    Subnets,
    Subnet,
    Prefix,
    Price,
    DateField,
    org: ORG,
  },

  props: {
    ticketId: {
      require: true,
    },

    product: {
      type: Object,
      require: true,
    },

    type: {
      type: String,
      require: true,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },

    userCanSetLot: {
      type: Boolean,
      default: false
    },

    operations: {
      type: Array,
      default: [],
    },
  },

  data: function() {
    return {
      fields: { ...this.product.fields ?? {} },
      buyout:{
        currency:'USD',
        quantity:'single',
      }
    }
  },
  created() {
    if(this.fields?.date === undefined){
      this.fields.date = '';
    }
    if(this.fields?.subnets === undefined){
      this.fields.subnets = [];
    }
  },
  computed: {
    loading:{
      set(value){
        this.$store.commit('thread/setLoading', value);
      },
      get(){
        return this.$store.getters['thread/loading'];
      }
    },
    showLIRid() {
      return !this.fields?.type || this.fields.type === 'PA';
    },

    subnetsCount(){
      return this.fields.subnets?.length ?? 0;
    },

    showORG() {
      return !this.fields?.type || this.fields.type === 'PI';
    },

    buyer: function() {
      return window.getId(this.fields.buyer);
    },
    seller: function() {
      return window.getId(this.fields.seller);
    },

    hasLotDate() {
      return typeof this.fields?.lot?.date !== 'undefined' && Boolean(this.fields?.lot?.date);
    },

    ...mapGetters({
      getProduct: 'tickets/filters/getProduct',
    }),
  },

  methods: {
    addSubnet(){
      if(this.$refs?.sell_ip_subnets){
        this.$refs?.sell_ip_subnets.addSubnet(true)
      }
    },
    updateBuyOut:function (values, afterSave = null, failSave = null) {
        let buyout = {
          buyout_price_edit:{
            buyout_price:values.price,
            currency:values.currency,
            quantity:values.quantity
          }
        }
        this.update(buyout, afterSave, failSave)
    },
    update: function(values, afterSave = null, failSave = null) {

      Object.entries(values).forEach(field => {
        let [name, value] = field;

        if(typeof value === 'undefined') {
          values[name] = null;
        }
        if(typeof value === 'string') {
          values[name] = value.trim();
        }

        if(typeof value === 'object') {
          if(JSON.stringify(value) === JSON.stringify(this.product?.fields?.[name])) {
            delete values[name];
          }
        } else if(this.product?.fields?.[name] === value) {
          delete values[name];
        }
      });

      if(Object.keys(values).length) {
        this.loading = true;

        CrmApi.updateThreadParams(this.ticketId, {product_fields: values}).then((response) => {

          this.updateFields(response.data.fields);
          if (typeof afterSave === 'function') {
            afterSave();
          }

        }).catch((error) => {

          if (typeof failSave === 'function') {
            failSave(error);
          } else {
            if (typeof error?.response?.data?.html !== 'undefined' && error.response.data.html === true) {
              this.$store.commit('htmlErrorPush', error.response.data.message);
            } else {
              this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
            }
          }

        }).finally(() => {
          this.loading = false;
        });

      } else {
        if(typeof afterSave === 'function') {
          afterSave(false);
        }
      }
    },

    updateFields(fields) {
      this.fields = Object.assign(this.fields, fields);
    },

    ...mapActions({
      'reloadPerson': 'thread/reloadPerson'
    })
  },

  watch:{
    product(to) {
      if(
          window.getId(this.fields?.buyer) !== window.getId(to?.fields?.buyer) ||
          window.getId(this.fields?.seller) !== window.getId(to?.fields?.seller)
      ){
        this.fields.seller = to?.fields?.seller;
        this.fields.buyer = to?.fields?.buyer;
      }

      if(to?.fields !== null && typeof to?.fields === 'object') {
        if(typeof to?.fields?.subnets === 'object') {
          if(typeof this.fields?.subnets === 'object' && to.fields.subnets.length === this.fields.subnets.length) {
            to.fields.subnets.forEach((subnet, key) => {
              if(JSON.stringify(subnet) !== JSON.stringify(this.fields.subnets[key])) {
                this.fields.subnets[key] = subnet;
              }
            })
          }
        }

        if(to?.fields?.auction !== null && typeof to?.fields?.auction === 'object') {
          Object.entries(to.fields.auction).forEach(([key, value]) => {
            if(typeof this.fields?.auction !== 'object') {
              this.fields.auction = {};
            }
            this.fields.auction[key] = value;
          });
        }

        if(to?.fields?.lot !== null && typeof to?.fields?.lot === 'object') {
          Object.entries(to.fields.lot).forEach(([key, value]) => {
            if(typeof this.fields?.lot !== 'object') {
              this.fields.lot = {};
            }
            this.fields.lot[key] = value;
          });
        }

        Object.entries(to.fields).forEach(([key, value]) => {
          if(
              ['string', 'number'].includes(typeof value) ||
              value === null
          ) {
            this.fields[key] = value;
          }
        });
        Object.keys(this.fields).forEach(key => {
          if(typeof to.fields[key] === 'undefined') {
            delete this.fields[key];
          }
        });
      } else {
        this.fields = {};
      }
    }
  }
}
</script>

<style scoped>
.sell-ip-subnets:hover :deep(.add-to-auction){
   display: inline-block;
}
</style>