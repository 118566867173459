<template>
  <span v-if="!modal.show">
    <span>Обнаружен трансфер сети:&nbsp;</span>
    <template v-for="(transfer, index) in transfers">
      <template v-if="canAddToHistory">
        <span class="text-primary" role="button" v-single-click="() => showModal(transfer)">{{transfer.subnet}}</span>
        <i class="fa fa-plus-circle ms-1" @click="() => showModal(transfer)" style="color: rgb(98, 98, 245); cursor: pointer;"></i>
      </template>
      <span v-else>{{transfer.subnet}}</span>

      <template v-if="transfers.length > 1 && index < transfers.length - 1">,&nbsp;</template>
    </template>
  </span>

  <teleport to="body">
    <add-edit
        v-if="modal.show"
        :transfer="modal.transfer"
        :thread-id="ticketId"
        :after-create-function="(data) => $emit('updateFields', data)"
        @close="hideModal"
    />
  </teleport>
</template>

<script>
import AddEdit from "../../../../../../offerHistory/Modal/AddEdit";
import {IPv4Net} from "../../../../../../../library/IPv4Net";

export default {
  name: "Transfers",

  components: {
    AddEdit
  },

  emits: ['updateFields', 'showModal', 'hideModal'],

  props: {
    subnetType: {
      type: String,
      default: null,
    },
    transfers: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
      modal: {
        show: false,
        transfer: null,
      }
    }
  },

  computed: {
    ticket() {
      return this.$store.getters['thread/getTicket'];
    },
    ticketId() {
      return window.getId(this.ticket._id);
    },
    person() {
      return this.$store.getters['thread/getPerson'];
    },
    buyer() {
      return this.$store.getters['thread/getBuyer'];
    },
    canAddToHistory() {
      return this.$store.getters['thread/userCanAnswer'] === 'direct';
    }
  },

  methods: {
    showModal(transfer) {
      this.modal.transfer = this.setTransferFields(transfer);
      this.modal.show = true;
      this.$emit('showModal');
    },

    hideModal() {
      this.modal.transfer = null;
      this.modal.show = false;
      this.$emit('hideModal');
    },

    setTransferFields(transfer) {
      let ipv4net = new IPv4Net(transfer.subnet);
      let seller = transfer.seller;
      let buyer = transfer.buyer;
      let prices = {};

      seller.name = this.person?.company?.name ?? this.person?.name ?? seller.name ?? '';
      seller.email = this.person.email;
      seller.ticket = window.getId(this.ticket._id);

      prices.buy = {
        price: this.ticket?.assigned_product?.fields?.price1 * ipv4net.count() ?? 0,
        currency: this.ticket?.assigned_product?.fields?.currency ?? 'USD'
      };

      if(this.subnetType === 'PI' ||
        (this.subnetType === 'PA' && buyer.lirid === this.buyer?.assigned_product?.fields?.lirid)) {

        buyer.name = this.buyer?.person?.company?.name ?? this.buyer?.person?.name ?? buyer.name ?? '';
        buyer.email = this.buyer?.person?.email ?? buyer?.email ?? '';
        buyer.ticket = window.getId(this.buyer._id);

        prices.sell = {
          price: ((this.buyer?.assigned_product?.fields?.price1 ?? 0) * ipv4net.count()),
          currency: this.buyer?.assigned_product?.fields?.currency ?? 'USD'
        };
      } else if(this.subnetType === 'PA' && this.buyer?._id) {
        buyer.showLiridWarning = true;
      }

      buyer.buyforus = (this.ticket.assigned_product.fields?.buyforus === true);

      transfer.seller = seller;
      transfer.buyer = buyer;
      transfer.prices = prices;

      return transfer;
    },
  }
}
</script>

<style scoped>

</style>