<template>
<div>
  <span v-for="(relation, index) in preparedRelations" >
    <span v-if="index !== 0">
      <span v-if="relation?.attribute"><span style="font-weight: 600; font-size: 0.75em">&nbsp;[{{relation.attribute}}]</span>&nbsp;→&nbsp;</span>
      <span v-else>&nbsp;→&nbsp;</span>
    </span>
    <span><a style="text-decoration: none;" :href="getHref(relation.type , relation.object)" target="_blank"
    >{{relation.object}}</a><span v-if="relation.smile" class="smile-m">{{relation.smile}}</span></span>
  </span>
</div>
</template>

<script>
import {IPv4Net} from "../../../library/IPv4Net";
import getTypeSmile from "../source/typeSmile";
import getHref from "../source/objectHref";

export default {
  name: "RelationRow",
  props:{
    'relations':{
      type:[Array,Object],
      require: true,
    }
  },
  data:function (){
    return {
    };
  },
  methods:{
    getHref:function (type , value){
      return getHref(type, value);
    }
  },
  computed:{

    preparedRelations:function (){
      let prepared = [];
      for (let key in this.relations){
        let el = this.relations[key];
        let v = Object.assign({} , el)
        if(el.type == 'inetnum'){
          let ip = new IPv4Net(el.object);
          if(ip.isIp() && ip.isNormalizedCIDR(ip.getPrefixLength())  ){
            v.object = ip.getFirst()  + "/" + ip.getPrefixLength();
          }
        }
        v.smile = getTypeSmile(el.type);
        prepared.push(v);
      }
      return prepared;
    },
    stringToShow:function (){
      let string = '';
      let i = 0;
      do {
        let el = this.preparedRelations[i];

        if(i !== 0){
          if(el?.attribute){
            string += "-["+el.attribute + "]->";
          }else {
            string += "-->";
          }
        }

        string += el.object


        i++;
      }while (i < this.preparedRelations.length);


      return string;

    }
  },

}
</script>

<style scoped>

</style>