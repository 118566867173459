<template>
  <div class="d-inline dropdown " v-for="value in smartTemplates">
    <button  v-show="isHasTemplates(value)"  type="button" :class="buttonClass" class=" background-smart btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown"  aria-expanded="false">{{value.name}}</button>
    <ul class="dropdown-menu">
      <li v-for="template in value.templates" class="dropend" :class="{'disableTemplate':isTemplateDisable(template)}">
        <a  :class="{'disableTemplateLink':isTemplateDisable(template)}"  class="dropdown-item" @click="chooseTemplate(template)" href="#">

          <PopperComment v-if="isCommentExist(template)" :value="template.name" :markdown="template.comment"></PopperComment>
          <span v-else>{{template.name}}</span>
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters, mapMutations, mapActions} from "vuex";
import DropDown from "./DropDown";
import PopperComment from "./PopperComment";

export default {
  name: "SmartGroup",

  components: {DropDown, PopperComment},

  props:{
    'assignTemplate':{
      type:Function,
      require:true,
    },
    'buttonClass':{
      type:Object,
      default:{},
    }
  },
  computed: {
    ...mapGetters({
      "getTicket": "thread/getTicket",
      "smartTemplates": "thread/getSmartTemplates",
    }),
    ticketLang(){
      let lang = "RU";
      if(typeof this.getTicket.lang !== 'undefined' && this.getTicket.lang != null ){
        lang = this.getTicket.lang;
      }
      return lang;
    },
  },
  watch:{
    'getTicket.assigned_product.current_step._id' (to, from) {
      if(typeof from !== 'undefined' && window.getId(from) !== window.getId(to)) {
        this.getTicketTemplates();
      }
    }
  },
  methods:{
    ...mapMutations({
      'selectTemplate':'thread/selectTemplate',
    }),
    ...mapActions({
      "getTicketTemplates": "thread/loadTicketTemplates",
    }),
    chooseTemplate(template){
      let body = this.chooseBody(template);
      this.assignTemplate(body);
      this.selectTemplate(template);
      if(typeof template.comment === 'undefined'){
        template.comment = '';
      }
      this.$store.commit('addMovingModuleContent' , template.comment);

    },
    isHasTemplates(group){
      return typeof group.templates !== 'undefined' &&  group.templates != null &&  group.templates.length > 0;
    },
    isCommentExist(template){
      return typeof template.comment !== 'undefined' && template.comment !== null && template.comment.trim() !== ''
    },
    isTemplateDisable:function(template){
      let isTemplateDisable = false;
      if(this.ticketLang === 'RU' &&  !template.body  ){
        isTemplateDisable =  true;
      }
      if(this.ticketLang === 'EN' &&  !template.body_en  ){
        isTemplateDisable =  true;
      }

      return isTemplateDisable;
    },
    chooseBody:function (template){
      let body = template.body ? template.body : template.body_en;
      if(this.ticketLang === 'RU'){
        body = template.body;
      }
      if(this.ticketLang === 'EN'){
        body = template.body_en;
      }
      return body;
    },
  },

}
</script>

<style scoped>
button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.background-smart{
  background-color: green;
}
</style>