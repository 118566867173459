<template>
  <div  v-show="options.length > 0" class="form-group row">
    <div class="col-sm-2">
      <label class="col-form-label">Связи</label>
    </div>
    <div class="col-sm-10"  style="padding-right: 0px;">
      <multiselect v-model="template.relations" :delay="800" :options="options"
                   :mode="'tags'"
                   :create-option="false" :searchable="true"
                   ref="multy"
                   valueProp="unic"
                   :object="true"
                   label="nameProduct"

      >
        <template v-slot:option="{ option }">
          <span v-if="option.type === 'step'" >{{ option.nameProduct }} | {{option.nameStep}}</span>
          <span v-if="option.type === 'product'" >{{ option.nameProduct }}</span>
        </template>

        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div :class="{'step': option.type === 'step'}" class="multiselect-tag is-user" >
            <a class="link-style" v-if="option.type === 'step'" target="_blank" :href="getStepEditLink(option)">{{ option.nameProduct }} | {{option.nameStep}} </a>
            <a class="link-style" v-if="option.type === 'product'" target="_blank" :href="getProductEditLink(option)" >{{ option.nameProduct }}</a>
            <span
                v-if="!disabled"
                class="multiselect-tag-remove"
                @mousedown.prevent="handleTagRemove(option, $event)"
            >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
          </div>
        </template>
      </multiselect>
    </div>
  </div>

</template>

<script>
import Multiselect from "@vueform/multiselect";
import {CrmApi} from "../../../library/CrmApi";
import {changeMultySelectStyles} from "../../ticket/Api/api";
import {getProductEditLink, getStepEditLink} from "../../../library/Locations";

export default {
  name: "Relations",
  components:{
    'multiselect':Multiselect,
  },
  data:function (){
    return {
      options:[],
    }
  },
  props:{
    'template':{
      type:Object,
    },
    'project':{
      type:String,
    }
  },
  methods:{
      getStepEditLink(object){
        return getStepEditLink(object._idStep , object._idProduct);
      },
      getProductEditLink(object){
        return getProductEditLink(object._idProduct);
      },
      getProducts(customProject){
        this.options = [];
        let query = null;
        if(typeof customProject !== 'undefined' && customProject != null){
          query = {};
          query.project = this.template.project;
        }else {
          if(typeof this.template.project !== 'undefined'  &&  this.template.project !== ''){
            query = {};
            query.project = this.template.project;
          }
        }

        CrmApi.productLists(query).then((result)=>{
          for (let el of result.data.data){
            let object = {
              type:'product',
              nameProduct: el.name,
              _idProduct:window.getId(el._id),
              unic:window.getId(el._id),

            }
            this.options.push(object);
            if(typeof el.steps !== 'undefined'){
              for (let step of el.steps){
                object = {
                  type:'step',
                  nameProduct:el.name,
                  _idProduct:window.getId(el._id),
                  nameStep:step.name,
                  _idStep:window.getId(step._id),
                  unic:window.getId(el._id) + window.getId(step._id),
                }
                this.options.push(object);
              }
            }

          }
        }).catch((error)=>{
          console.error(CrmApi.getErrorMessage(error));
        })
      }
  },

  mounted() {
    changeMultySelectStyles(document);
  },
  created() {

    this.getProducts(this.project);


  },
  watch:{
    'template.project'(to){
       this.template.relations = [];
       this.getProducts();
    }
  }
}
</script>

<style scoped>
@import './smMultyselect.scss';
@import '../../../library/multyselectionLinkStyle.scss';
.step{
 background-color:  #c158c1;
}

</style>