<template>
<div>
  <span>Сети:
    <bids v-if=" isHasLot && ticket != null"
            :subnet="subnet"
            :ticket="ticket" />
    <span class="ps-1" >
      <i class="fa fa-plus-circle form-add" style="color:#6262f5;cursor:pointer;" @click="addSubnet"></i>
    </span>
   <add-to-auction   v-if="!isHasLot" ></add-to-auction>
  </span>
</div>
</template>

<script>
import Bids from "./Subnet/Bids.vue";
import AddToAuction from "./Subnet/AddToAuction.vue";
import {AuctionSetup} from "./AuctionSetup";

export default {
  name: "SubnetsMenu",
  components: {AddToAuction, Bids},
  setup(props){
    return  AuctionSetup(props);
  },
  data:function (){
    return {
      subnet:null,
    }
  },
  props:{
    offer:{
      default:null,
    }
  },
  methods:{
    addSubnet:function (){
      this.$emit('addSubnet');
    }
  }
}
</script>


<style scoped>

</style>