import offerRouter from "../../../router/offer/offerRouter";
import offerStore from "../../../store/main/offer/index";

import {CrmApi} from "../../../library/CrmApi";

export default function openTicket(id) {
    let url = window.location.origin + "/tickets/" + window.getId(id);
    window.open(url )
}


