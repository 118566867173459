<template>
  <div>
    <outgoing-call v-if="message.author"
                   :message="message"
                   @play="(fileLink) => $emit('play', fileLink)"
                   @move-messages="(msg) => $emit('moveMessages', msg)"
                   :hasPermission="hasPermission"
                   :change-permission="changePermission" />

    <incoming-call v-else
                   :message="message"
                   @play="(fileLink) => $emit('play', fileLink)"
                   @move-messages="(msg) => $emit('moveMessages', msg)"
                   :hasPermission="hasPermission"
                   :change-permission="changePermission" />
  </div>
</template>

<script>
import IncomingCall from "./Source/IncomingCall";
import OutgoingCall from "./Source/OutgoingCall";
export default {
  name: "CallMessage",

  emits: ['play'],
  components: {OutgoingCall, IncomingCall},
  props:{
    message:{
      type:Object,
      require:true,
    },
    hasPermission: {
      type: Boolean,
      default: false,
    },
    changePermission: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>

</style>