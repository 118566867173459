<template>
  <popper :content="text" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
    <i class="fa fa-cogs ms-1" aria-hidden="true"></i>
  </popper>
</template>

<script>
export default {
  name: "CurrentOperations",

  props: {
    currentOperations: {
      type: Array,
      required: true
    }
  },

  computed: {
    text() {
      return "Обрабатывается: " + this.currentOperations.join(", ");
    },
  },

}
</script>

<style scoped>

</style>