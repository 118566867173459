
export const getTicket = state => {
    return state.ticket;
}

export const getLastSeen = state => {
    return state.lastSeen;
}

export const getPerson = state => {
    return state.person;
}


export const loading = state => {
  return state.loading
}

export const getBuyer = state => {
    return state.params.buyer;
}

export const getPrice = state => {
    return state.params?.price ?? {};
}

export const isHasNewChangesForDraft = state => {
    return state.hasNewChangesForDraft;
}

export const getSelectedTemplate = state => {
    return state.selectedTemplate;
}
export const isSelectedTemplateHasComment= state => {
    return typeof state.selectedTemplate.comment !== 'undefined' && state.selectedTemplate.comment !== null && state.selectedTemplate.comment.trim() != "";
}

export const isSelectMessageExistInTicket = state =>{
    return state.isSelectMessageExistInTicket;
}

export const userCanAnswer = state => {
    return state.userCanAnswer;
}

export const userCanChange = state => {
    return state.userCanChange;
}
export const userCanSetLot = state => {
    return state.userCanSetLot;
}

export const userCanCall = state => {
    return state.userCanCall;
}

export const isItDirectLink = state => {
    return state.isDirectLink;
}

export const getCommentMessage = state => {
    return state.commentMessage;
}

export const getMessages = state =>{
    return state.messages;
}

export const getMessagesIds = state => {
    return Array.isArray(state.messages) && state.messages.length ?
           state.messages.map(message => window.getId(message._id)) :
           [];
}

export const attachmentsFilter = state => state.attachmentsFilter;
export const tasksFilter = state => state.tasksFilter;
export const createNewTicket = state => state.createNewTicket;
export const isTicketSelectProduct = (state, getters, rootState, rootGetters ) =>{
    let isTicketSelectProduct = false;

    if(rootGetters['tickets/product/isHasProducts'] && typeof getters.getTicket.assigned_product !== 'undefined' && getters.getTicket.assigned_product !== null ){
        isTicketSelectProduct = true;
        /*
            for (let key in rootGetters['tickets/product/getProducts']){
                let product =  rootGetters['tickets/product/getProducts'][key]
                if(window.getId( product._id) === window.getId( getters.getTicket.assigned_product._id ) ){
                    isTicketSelectProduct = true;
                    break;
                }
            }

         */
    }

    if(!rootGetters['tickets/product/isHasProducts']){
        isTicketSelectProduct = true;
    }

    return isTicketSelectProduct;
}
export const isUserCallRecordPermission = state => {
    return state.userCallRecordPermission;
}

export const isUserTaskCreatePermission = state => state.userTaskCreatePermission;
export const isUserTaskDeletePermission = state => state.userTaskDeletePermission;
export const isUserUpdatePersonPermission = state => state.userUpdatePersonPermission;

export const isTicketWindowFullSize = state => state.ticketWindowFullSize;
export const changeWindowSizeCallback = state => {
    return typeof state.ticketChangeWindowSizeCallback === 'function'
            ? () => {
                state.ticketChangeWindowSizeCallback();
                state.ticketChangeWindowSizeCallback = null;
            }
            : null
};

export const isShowHeadersForm = state => state.showHeadersForm;
export const headersFormValid = state => state.headersFormValid;
export const showAddTaskForm = state => state.showAddTaskForm;
export const scrollToMessage = state => state.scrollToMessage;
export const scrollAndHighlight = state => state.scrollAndHighlight;
export const highlight = state => state.highlight;
export const getDocuments = state => state.documents;
export const getDocument = state => state.document;
export const getSelectMessageStatus = state => state.selectMessageStatus;
export const userAttachmentPermission = state => state.userAttachmentPermission;
export const userDocumentsPermission = state => state.userDocumentsPermission;
export const userRelationPermission = state => state.userRelationPermission;
export const userOpenClosePermission = state => state.userOpenClosePermission;

export const isLoadingSignature = state => state.loadingSignature;
export const showSignature = state => state.showSignature;
export const getSignature = (state) => (ticketId, alias = null) => {
    return (typeof ticketId !== 'undefined' && ticketId != null)
                ? state.signatures.list.get(window.getId(ticketId) + `_${alias}`)
                : null;
}

export const getShowCommentForm = (state) => state.showCommentForm;
export const getEditMessage = (state) => state.editMessage;

export const getApi = (state) => state.api;
export const getGreeting = (state) => state.greeting;
export const getSmartTemplates = (state) => state.smartTemplates;
export const getGroupOrder = (state) => state.groupOrder;
export const getLoadingTemplates = (state) => state.loadingTemplates;
export const isAnsweringTicket = (state) => state.isAnsweringTicket;
export const newTicketLang = (state) => state.newTicketLang;
export const newTicketLangChanged = (state) => state.newTicketLangChanged;
export const pinnedMessages = (state) => state.pinnedMessages;
export const showSearchField = (state) => state.showSearchField;
export const searchString = (state) => state.searchString;
export const isSettingDraft = (state) => state.settingDraft;