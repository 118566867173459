export {Form};

class Form {

    vue;
    handlerName;
    closeByOutClick = true;
    saveByOutClick = false;
    constructor(vue) {
        this.vue = vue;
        this.handlerName = 'click.' + String(Math.random()).substr(2);

        vue.$nextTick(() => {
            if(typeof vue.$data.fields !== 'undefined' && Array.isArray(vue.$data.fields)) {
                this.vue.fields.forEach(field => {
                    vue.$data.proxy[field] = vue.$props[field] ?? null;
                });
            }
        });
    }

    showForm(updateProxy = true) {
        if(this.closeByOutClick) {
            $(document).on(this.handlerName, (e) => {
            let prevent = false;

                if ($(e.target).closest(this.vue.$refs.group).length) {
                    prevent = true;
                }
                if (typeof this.vue.ignoreClickElements !== 'undefined') {
                    this.vue.ignoreClickElements.forEach(el => {
                        if ($(e.target).closest($(el)).length) {
                            prevent = true;
                        }
                    });
                }
                if (typeof this.vue.ignoreCLickClasses !== 'undefined') {
                    this.vue.ignoreCLickClasses.forEach(el => {
                        if ($(e.target).hasClass(el)) {
                            prevent = true;
                        }
                    });
                }

                if (
                    !$(e.target).hasClass('form-add') &&
                    !$(e.target).closest(this.vue.$refs.group).length &&
                    !prevent

                ) {
                    if(this.saveByOutClick){
                        this.save(this.vue.getUpdated());
                    }else {
                        if(this.closeByOutClick){
                            this.cancel();
                        }
                    }



                }

            });
        }

        this.vue.asInput = true;

        if(updateProxy) {
            this.vue.fields.forEach(field => {
                this.vue.proxy[field] = this.vue.$props[field] ?? null;
            });
        }

        this.vue.$nextTick(() => { this.vue.$refs?.input?.focus(); });
    }

    save(updated = null) {

        if(updated === null || (typeof updated === 'object' && typeof updated.target !== 'undefined')) {
            updated = {};

            this.vue.fields.forEach(field => {
                updated[field] = this.vue.proxy[field];
            });
        }

        if(typeof this.vue.validation === 'function') {
            this.vue.isValid = this.vue.validation(updated);
        }

        if(this.vue.isValid) {
            if(typeof this.vue.beforeSave === 'function') {
                updated = this.vue.beforeSave(updated);
            }

            this.vue.$emit('update', updated, (wasSaved = true) => {

                if(typeof this.vue.afterSave === 'function') {
                    this.vue.afterSave(wasSaved);
                }
                this.cancel();

            }, this.vue.failSave ?? null);
        }
    }

    cancel() {
        let mayCancel = true;
        if(typeof this.vue.beforeCancel === 'function') {
            let callbackResult = this.vue.beforeCancel();
            if(callbackResult === false) {
                mayCancel = false;
            }
        }

        if(mayCancel) {
            $(document).off(this.handlerName);

            this.vue.asInput = false;
            this.vue.isValid = true;
        }
    }

    clear() {
        this.vue.fields.forEach(field => {
            this.vue.$data.proxy[field] = null;
        });
    }

    /**
     * @param {Boolean} val
     */
    setCloseByOutClick(val) {
        this.closeByOutClick = val;
    }
    /**
     * @param {Boolean} val
     */
    setSaveByOutClick(val) {
        this.saveByOutClick = val;
    }
}