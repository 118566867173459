<template>
  <template v-if="subnets">
    <div v-for="subnet in subnets" :style="rowStyle">
      <history-subnet :history-object="historyObject"
                      :popper-placement="popperPlacement"
                      :subnet-class="classSubnet"
                      :subnet="subnet" />
    </div>
  </template>
</template>

<script>

import StringTrim from "../../string/StringTrim";
import HistorySubnet from "./HistorySubnet";
export default {
  name: "HistorySubnets",
  components: {HistorySubnet, StringTrim},
  props:{
    historyObject:{},
    popperPlacement: {
      type: String,
      default: 'top',
    },
    'subnets':{
      type:Array,
    },
    'classSubnet':{
      type:String,
    },
    'rowStyle': {
      type: String,
    }
  },
  setup(props){

  },
  methods:{

  }
}
</script>

<style scoped>
.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-weight: 700;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  font-size: 12px;
}

</style>