import {computed, reactive, toRef, unref, toRefs} from 'vue';
import {check} from "../Computeds";
import {PricePrettyView} from "../../../../library/PricePrettyView";
import getOffersFields from "../Computeds";




export default function getBuyFields({offer}) {

    const prefix = computed(()=>{
        return offer.value?.assigned_product?.fields?.prefix;
    })
    const lirid = computed(()=>{
        return check(offer.value?.assigned_product?.fields?.lirid);
    });
    const {currency, status , comment, typeNet, price, price1, quantity, date, id, canChange} = getOffersFields({offer:offer});

    const prettyPrice = computed(()=>{
        let p = new PricePrettyView(price.value, currency.value);
        return p.getPrettyPrice("" , true);
    })

    return {
        prefix,
        lirid,
        price,
        price1,
        quantity,
        typeNet,
        status,
        comment,
        currency,
        prettyPrice,
        date,
        id,
        canChange
    }
}