<template>
  <div class="modal modal-mask" :class="{'loading-price': isLoading }">
    <div class="modal-dialog modal-lg  edit-modal" style="padding: 5px 0px;" >
      <div class="modal-content" style=" position: relative; padding: 5px 10px 0px; max-height: 100vh; overflow-y: auto;" >
        <div style="position: absolute; right: 30px; top:10px; cursor: pointer; font-size: 21px;  ">
          <span class="close" data-dismiss="alert" @click="isNeedConfirmation()" >&times;</span>
        </div>
        <div class="modal-header" style="padding-bottom: 0px; padding-top: 0px;">
          <h3>Добавить сделку</h3>
        </div>

        <div class="pt-1 mb-2 col-md-12 m-auto"   >
          <div ref="body" class="modal-body" v-esc="isNeedConfirmation"   v-enter.targetBody.ctrlKey="success"          >
              <div class="modal-row" style=" margin-top: 1rem;">
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">{{!this.isAs ? 'Сеть(и)' : 'ASN' }}</label>
                </div>
                <div class="col-sm-9">
                  <div v-if="isAs" class="subnet-row">
                    <div class="subnet-line">
                    <input @keydown.enter.ctrl.prevent.stop="success" ref="as-input"   :disabled="isLoading" @keyup.enter.stop="{}" type="text" class="form-control form-control-sm" v-model="historyObject.asn.name" placeholder="Введите номер AS"  />
                    </div>
                      <div class="type-line" style=" margin-left: 5px;">
                      <select @keydown.enter.ctrl.prevent.stop="success" :disabled="isLoading" style="text-align:center"
                              @change="changeType()" name="nets" id="nets" class="form-control form-control-sm" v-model="subnetType">
                        <option  disabled value="" >Тип</option>
                        <option value="PA" >PA</option>
                        <option value="PI">PI</option>
                        <option value="ASN">ASN</option>
                      </select>
                      </div>


                  </div>

                  <subnets-edit v-if="!isAs" :loading="isLoading"
                                @enterCtrl = "success"
                                :subnets="historyObject.subnets"
                                :disabled="createByTransfer"
                                @interface="setSubnetInterface"
                                @editObject="editSubnet" @addObject="addSubnet"
                                ref="subnets" />
                </div>
              </div>
            <div class="modal-row text-muted pb-0"
                 style="font-size: 14px; ">
              <div style="margin: auto" v-if="transferInfoLoading">Поиск последнего трансфера {{subnetType === 'ASN' ? 'AS' : 'сети'}}...</div>
              <div style="margin: auto" v-if="subnetPersonsSellerLoading || subnetPersonsBuyerLoading">{{ textSearch }}</div>
            </div>
              <div class="modal-row" style="padding-bottom: 5px;margin-top: 1rem; ">
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Продавец</label>
                </div>
                <div class="col-sm-9">
                  <edit-person  v-model="seller"
                               :transfer-info-loading="transferInfoLoading"
                               :subnet-persons-loading="subnetPersonsSellerLoading"
                               type="seller"
                               :not-show-id="buyerId"
                               :disabled="createByTransferSellerDisabledFields"
                               :placeholder="'Email продавца'"
                               ref="seller" />
                </div>
              </div>
              <div class="modal-row" style="padding-bottom: 0;padding-top: 0;">
                <div class="col-sm-3">
                  <label for="ournetSeller" class="col-form-label  col-form-label-sm ng-binding">
                    <a :href="ourLinkHref" target="_blank" style=" text-decoration: none;">Наша сеть</a></label>
                </div>
                <div class="col-sm-9">
                  <input @keydown.enter.ctrl.prevent.stop="success" :disabled="loading" class="form-check-input" type="checkbox" value="" @change="ourSellerChange()" id="ournetSeller" v-model="isourSeller" >
                </div>
              </div>
              <hr class="hr-class"/>
              <div class="modal-row w-100">
                <div class="alert alert-warning p-2 small w-100 text-center" v-if="buyer.showLiridWarning">Используются данные из трансфера, т.к. LIRid покупателя не задан либо не соответствует LIRid трансфера</div>
                <div class="alert alert-warning p-2 small w-100 text-center" v-if="!isourBuyer && createByTransferBuyerDisabledFields.email && buyer.email?.length === 0">У <a :href="`/tickets/${buyer.ticket}/`" target="_blank">покупателя</a> не заполнен основной email</div>
              </div>
              <div class="modal-row">
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Покупатель</label>
                </div>
                <div class="col-sm-9">
                  <edit-person v-model="buyer"
                               :transfer-info-loading="transferInfoLoading"
                               :subnet-persons-loading="subnetPersonsBuyerLoading || loadingLirFromWhois"
                               :not-show-id="sellerId"
                               :disabled="createByTransferBuyerDisabledFields"
                               :placeholder="'Email покупателя'"
                               type="buyer"
                               ref="buyer" />
                </div>
              </div>
              <div class="modal-row" style="padding-bottom: 0;padding-top: 0;">
                <div class="col-sm-3">
                  <label for="ournetBuyer" class="col-form-label  col-form-label-sm ng-binding">
                    <span>Покупаем себе</span>
                  </label>
                </div>
                <div class="col-sm-9">
                  <input @keydown.enter.ctrl.prevent.stop="success" :disabled="loading" class="form-check-input" type="checkbox" value="" id="ournetBuyer" @change="ourBuyerChange()" v-model="isourBuyer" >
                </div>
              </div>
              <hr class="hr-class"/>
              <div class="modal-row" style="margin-top: 1rem; ">
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Дата трансфера</label>
                </div>
                <div class="col-sm-9">
                  <editable-date default="&mdash;"
                                 input-class="form-control form-control-sm offer-history-black dw"
                                 :disabled="createByTransfer || transferInfoLoading"
                                 :text="historyDate"
                                 :callback="updateDate" :format-label="'DD.MM.YYYY'">
                  </editable-date>
                </div>
              </div>
              <div class="modal-row" >
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Цена покупки</label>
                </div>
                <div class="col-sm-9">
                  <price-edit  @enterCtrl="success" @priceEmpty="buyPriceEmpty" :loading="loadingPrice"
                              :select-text="true" :show-description="!createByTransfer"
                              @priceChange="buyPriceChange" input-class="form-control form-control-sm"
                              :subnets="historyObject.subnets" v-model="historyObject.buyprice" ref="buyprice" ></price-edit>
                </div>
              </div>
              <!-- div class="modal-row" v-if="!createByTransfer" style="margin-top: 5px; margin-bottom: 5px" >
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Комментарий</label>
                </div>
                <div class="col-sm-9">
                  <comment :model="historyObject.buyprice"></comment>
                </div>
              </div -->
              <div class="modal-row" v-if="!isourBuyer">
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Цена продажи</label>
                </div>
                <div class="col-sm-9">
                  <price-edit @enterCtrl="success"  input-class="form-control-sm"
                               :select-text="true" :show-description="!createByTransfer"
                               :subnets="historyObject.subnets" v-model="historyObject.sellprice"  ref="sellprice"  ></price-edit>
                </div>
              </div>
              <div class="modal-row" v-if="!createByTransfer" style="margin-top: 5px; margin-bottom: 5px" >
                <div class="col-sm-3">
                  <label class="col-form-label  col-form-label-sm ng-binding">Комментарий</label>
                </div>
                <div class="col-sm-9">
                  <comment :model="historyObject"></comment>
                </div>
              </div>
            <div class="double-buttons-group" style=" margin-top: 1rem;">
              <div>
                <button type="button" class="btn btn-secondary btn-sm" :disabled="loading"  @click="isNeedConfirmation">Отмена</button>
              </div>
              <div>
                <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="success()"
                    :disabled="isSuccessDisable">
                      {{userHistoryObject === null ? 'Добавить' : 'Изменить'}}
                </button>
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
    <modal-window v-if="showCloseModal" @close="showCloseModal = false" @success="close" ok-button-style="btn-danger">
      <template v-slot:header>
        <h5 class="modal-title text-danger">Подтверждение действия</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите закрыть форму? Введенные вами данные не сохранятся.</span>
      </template>
    </modal-window>
  </div>
</template>

<script>
import SubnetsEdit from "../AddEdit/SubnetsEdit";
import EditPerson from "../AddEdit/EditPerson";
import EditableDate from "../../Editable/EditableDate";
import {mapActions, mapGetters, mapMutations} from "vuex";

import PriceEdit from "../AddEdit/PriceEdit";
import priceCalculate from "./priceCalculate";

import {CrmApi} from "../../../library/CrmApi";
import {Phone} from "../../../library/Phone";
import moment from "moment";
import {IPv4Net} from "../../../library/IPv4Net";
import {Utility} from "../../../library/api/Utility";
import ModalWindow from "../../tiketMenu/components/ModalWindow";

import {isNetworksRepeated} from "../AddEdit/computed";
import parseString from "./parseHistoryString";
import Comment from "../AddEdit/Comment";
import {getPrice} from "./priceCalculate";
export default {
  name: "AddEdit",
  components: {Comment, ModalWindow, PriceEdit, EditPerson, SubnetsEdit, EditableDate},
  emits: ['close'],

  props:{
    userHistoryObject:{
      default: null,
    },
    transfer: {
      type: Object,
      default: null,
    },
    threadId: {
      default: null,
    },
    afterCreateFunction: {
      type: Function,
      default: (result, _this) => {
        _this.exit();
      }
    },
  },
  data:function (){
    return {
      oldAsn:'',
      oldnames:{},
      dateChanged:false,
      showCloseModal:false,
      subnetInterface:{
      },
      subnetsBuyPrices:{},
      transferInfo: null,
      loadingPrice:false,
      transferInfoLoading: false,
      subnetPersonsBuyerLoading: false,
      subnetPersonsSellerLoading:false,
      updateStatusFromRipeListner: null,
      loadingLirFromWhois: false,
      olddate:'',
      oldisourSeller:'',
      oldisourBuyer:'',
      updateStatusFromRipeListnerTwo:null,
      oldComment:'',
      oldSellerEmail:'',
      oldBuyerEmail:'',
      isAllSubnetsWithSameLirValue: true,
      subnetsTransferInfoParams: new Map(),
      priceLastValues: {
        sell: 0,
        buy: 0,
      }
    }
  },

  mounted() {
    this.$nextTick(()=>{
      if(this.userHistoryObject === null){
        if(this.$refs?.['as-input']){
          this.$refs['as-input'].focus();
        }
        if(this.$refs?.['subnets']?.$refs?.['subnet0']?.[0].$refs?.['subnet-input']){
          this.$refs?.['subnets']?.$refs?.['subnet0']?.[0].$refs?.['subnet-input'].focus();
        }
      }

    })

    if(this.userHistoryObject?.comment){
      this.oldComment = this.userHistoryObject?.comment;
    }
    if( this.userHistoryObject?.asn?.name){
      this.oldAsn = this.userHistoryObject?.asn?.name;
      this.setIsAs(true)
      this.subnetType = 'ASN'
    }

    this.olddate = this.historyDate;
    if(this.getSeller && typeof this.getSeller.isour !== 'undefined'){
      this.isourSeller = this.getSeller.isour;
    }

    if(this.getBuyer && typeof this.getBuyer.isour !== 'undefined'){
      this.isourBuyer = this.getBuyer.isour;
    }

    this.oldisourBuyer = this.isourBuyer;

    this.oldisourSeller = this.isourSeller;

    if(this.createByTransfer) {
      this.initByExistTransfer();
    }
  },
  setup(props, context){
    let {prices, isPriceChange,} = priceCalculate(props, context);
    return {prices, isPriceChange,};
  },
  computed:{

    isLoading() {
      return this.subnetPersonsBuyerLoading || this.transferInfoLoading ||
             this.subnetPersonsSellerLoading || this.loading ||
             this.loadingPrice;
    },

    createByTransfer() {
      return this.transfer !== null;
    },

    createByTransferSellerDisabledFields() {
      return {
        name: typeof this.transfer?.seller?.name === 'string',
        email: typeof this.transfer?.seller?.email === 'string',
        ticket: typeof this.transfer?.seller?.ticket === 'string',
      };
    },

    createByTransferBuyerDisabledFields() {
      return {
        name: typeof this.transfer?.buyer?.name === 'string',
        email: typeof this.transfer?.buyer?.email === 'string',
        ticket: typeof this.transfer?.buyer?.ticket === 'string',
      };
    },

    textSearch:function (){
      if(this.subnetType !== 'ASN' &&
          this.historyObject?.subnets?.[0]?.type && this.historyObject?.subnets?.[0]?.type === "PI"){
        return  'Поиск ORG найденного трансфера и статуса сети...'
      }
      if(this.subnetType !== 'ASN' && this.historyObject?.subnets?.[0]?.type && this.historyObject?.subnets?.[0]?.type === "PA"){
        return  'Поиск LIRid найденного трансфера и статуса сети...'
      }
      return '';
    //  return 'Поиск ORG/LIRid найденного трансфера и статуса сети...'

    },
    ourLinkHref:function(){
      let link = 'https://ipanel.i7.org/#/pricecalc';
      if(this.historyObject?.subnets?.[0]?.subnet){
        let ip = new IPv4Net(this.historyObject?.subnets?.[0]?.subnet)
        if(ip.isIp()){
          link +="/"+ encodeURIComponent(this.historyObject.subnets[0].subnet.replace("/" , "|" ));
        }

      }
      return link;
    },
    subnetType:{
      set(value){
        return this.$store.commit('offerHistory/addEdit/setSubnetType' , value)
      },
      get(){
        return this.$store.getters['offerHistory/addEdit/getSubnetType'];
      }
    },
    isourSeller:{
      set(value){
        return this.setIsOurSeller(value)
      },
      get(){
        return this.getIsOurSeller;
      }
    },
    isourBuyer:{
      set(value){
        return this.setIsOurBuyer(value)
      },
      get(){
        return this.getIsOurBuyer;
      }
    },
    sellerId:function(){
      return this.seller?._id ?? '';
    },
    buyerId:function (){
      return this.buyer?._id ?? '';
    },
    seller:{
      set(value){
        this.setSeller(value);
      },
      get(){
        return this.getSeller;
      }
    },

    buyer:{
      set(value){
        this.setBuyer(value)
      },
      get(){
        return this.getBuyer
      }
    },
    historyObject: {
      get() { return this.$store.getters['offerHistory/addEdit/getHistoryObject']; },
      set(value) { this.$store.commit('offerHistory/addEdit/setHistoryObject', value); }
    },

    isNetworksRepeat() {
      return isNetworksRepeated(this.historyObject.subnets);
    },

    isSuccessDisable() {
      return this.isLoading || this.isDisabled || !this.isHasChange || this.isNetworksRepeat || !this.isAllSubnetsWithSameLirValue
    },

    isHasChange:function (){
      return  ( (this.subnetType === 'ASN' && this.historyObject?.asn.name !== this.oldAsn ) || (this.subnetType !== 'ASN' && this.$refs?.subnets && this.$refs.subnets.isHasChange())
          || (this.$refs?.seller && this.$refs.seller.isHasChange() )|| (this.$refs?.buyer && this.$refs.buyer.isHasChange()) ||
          ( this.$refs?.sellprice && this.$refs.sellprice.isHasChange()) ||( this.$refs?.buyprice && this.$refs.buyprice.isHasChange())
          ||     this.olddate !== this.historyDate ||
      this.oldisourSeller !== this.isourSeller || this.oldisourBuyer !== this.isourBuyer || this.oldComment !== this.historyObject?.comment  )
    },
    ...mapGetters({
      'getSeller':'offerHistory/addEdit/getSeller',
      'getBuyer':'offerHistory/addEdit/getBuyer',
      'historyDate':'offerHistory/addEdit/getDate',
      'loading':'offerHistory/addEdit/loading',
      'isDisabled':'offerHistory/addEdit/isDisabled',
      'getRoute':'getRoute',
      'getIsOurSeller':'offerHistory/addEdit/getIsOurSeller',
      'getIsOurBuyer':'offerHistory/addEdit/getIsOurBuyer',
      'isAs':'offerHistory/addEdit/isAs',
    }),
  },
  beforeUnmount() {
    if(this.dateChanged || this.userHistoryObject !== null){
      this.$store.commit('offerHistory/setRates' , null)
    }

  },
  methods:{
    addSubnetTransferInfo(subnet, result){
      if( this.subnetsTransferInfoParams === null){
        this.subnetsTransferInfoParams = new Map();
      }
      this.subnetsTransferInfoParams.set(subnet , result);
    },
    isSubnetAlreadyCheck(subnet){
      return this.subnetsTransferInfoParams.has(subnet)
    },

    getCashedSubnetTransferInfo(subnet){
      return this.subnetsTransferInfoParams.get(subnet);
    },

    changePrice(){
      if(this.isourSeller && !this.isPriceChange  ){
        if(typeof this.prices !== 'undefined' && Object.keys(this.prices).length > 0){
          let price = 0;
          let currency = "EUR";
          this.historyObject.subnets.map((value)=>{
            if(value.type === 'PA' &&  this.prices?.[value.subnet]?.price){
              price += this.prices[value.subnet].price;
            }
          });
          if(price !== this.historyObject.buyprice.price ||  this.historyObject.buyprice.currency !== currency ){
            this.historyObject.buyprice.price = price;
            this.historyObject.buyprice.currency = currency;
          }
        }
      }
    },
    ourBuyerChange(){
      if(this.isourBuyer){
        this.oldBuyerEmail =  this.historyObject.buyer.email;
        this.historyObject.buyer.email = '';
      } else if(this.createByTransfer) {
        this.historyObject.buyer.email = this.transfer?.buyer?.email ?? '';
      }else {
        if(typeof this.historyObject?.buyer?.email !== 'undefined' && this.historyObject.buyer.email == ''){
          this.historyObject.buyer.email = this.oldBuyerEmail ;
        }

      }
    },
    loadPrice(subnet){
      return new Promise((resolve, reject)=>{
        if(this.isourSeller && !this.isPriceChange  ){
          getPrice(subnet).then(()=>{
            resolve();
          }).catch(()=>{
            reject();
          })
        }
      });
    },
    loadPrices(){
      let promises = [];
      if(this?.historyObject?.subnets && !this.isPriceChange && this.isourSeller  ){
        for (let el of this?.historyObject?.subnets){
            if(el?.subnet && el.subnet.trim() !== '' && el.type === 'PA' && (new IPv4Net(el.subnet)).isIp() ){
                promises.push(this.loadPrice(el.subnet))
            }
        }
      }
      return Promise.all(promises);
    },

    ourSellerChange(){
      if(this.isourSeller){
        this.priceLastValues.buy = { ...this.historyObject.buyprice };
        this.oldSellerEmail = this.historyObject?.seller?.email;
        this.historyObject.seller.email = '';


        if(!this.isPriceChange && this?.historyObject?.subnets?.[0]?.subnet && this.subnetType  !== 'PI' && this.subnetType  !== 'ASN'){
          this.loadingPrice = true;
          this.loadPrices().then(()=>{
            this.changePrice();
          }).catch(()=>{
            this.errorPush("Ошибка при расчете нашей цены");
          }).finally(()=>{
            this.loadingPrice = false;
          })
        }

      } else if(this.createByTransfer) {
        this.historyObject.seller.email = this.transfer?.seller?.email ?? '';
        this.historyObject.buyprice = { ...this.priceLastValues.buy };
      }else {
        if( typeof  this.historyObject?.seller?.email !== 'undefined' && this.historyObject.seller.email == ''){
          this.historyObject.seller.email =  this.oldSellerEmail;
        }

      }
    },
    changeType(){
      if(this.subnetType === 'ASN'){
        this.setIsAs(true)
      }else {
        if(this.historyObject?.subnets){
          for (let el of this.historyObject.subnets){
            if(el.type === 'ASN'){
              el.type = 'PA';
            }
          }
        }
        if(typeof this.historyObject?.subnets?.[0]?.type !== 'undefined'  ){
          this.historyObject.subnets[0].type = this.subnetType;
        }else {
          this.historyObject.subnets  = []
          this.historyObject.subnets.push({
            type:this.subnetType,
            subnet:'',
          })
        }
        if( this.historyObject?.subnets?.[0]?.subnet){
          this.historyObject.subnets[0].subnet = '';
        }

        this.setIsAs(false)
      }
    },
    initByExistTransfer() {
      this.historyObject.subnets = [{subnet: this.transfer.subnet, type: ''}];
      this.historyObject.subnets = [{subnet: this.transfer.subnet, type: ''}];

      if(typeof this.transfer.prices?.sell !== 'undefined') {
        this.historyObject.sellprice = { ...this.transfer.prices.sell };
      }
      if(typeof this.transfer.prices?.buy !== 'undefined') {
        this.historyObject.buyprice = { ...this.transfer.prices.buy };
      }

      this.loadSubnetStatus({index: 0});
      this.buyer = { ...this.transfer.buyer };
      this.seller = { ...this.transfer.seller };

      this.isourBuyer = (this.buyer?.buyforus === true);

      this.setDate( moment(this.transfer.date).format("DD.MM.YYYY") );
      this.$store.commit('offerHistory/addEdit/setThreadId', this.threadId);
    },
    exit:function (){
      this.changeRoute(this.getRoute)
      this.$emit('close');
    },
    isNeedConfirmation:function (){
      if(this.isHasChange){
        this.showCloseModal = true;
      }else {
        this.$emit('close');
      }
    },
    buyPriceEmpty:function (){
      this.isPriceChange = false;
    },
    buyPriceChange:function (){
      this.isPriceChange = true;
    },
    setSubnetInterface:function (object){
      this.subnetInterface = object;
    },
    success:function (){
      if(this.isSuccessDisable){
        return;
      }
      let isValid = false;
      if(this.subnetInterface.validate && this.subnetType !== 'ASN'){
        isValid=  this.subnetInterface.validate()
      }else if(this.subnetType === 'ASN') {
        isValid = true;
      }

      if(!this.loading && isValid && !this.isNetworksRepeat){
        if(this.userHistoryObject === null || this.transfer){

          this.create().then((result)=>{
            if(typeof this.afterCreateFunction === 'function') {
              this.afterCreateFunction(result.data, this);
            }
            this.$emit('close');
          }).catch(error => this.errorPush(CrmApi.getErrorMessage(error)));

        }else {

          this.edit().then(()=>{
            this.changeRoute(this.getRoute);
            this.$emit('close');
          }).catch(error => this.errorPush(CrmApi.getErrorMessage(error)));

        }

      }

    },
    close:function (){
      this.$emit('close');
    },
    updateDate:function (newdate){
      this.dateChanged = true;

      this.$store.dispatch('offerHistory/initRates', {date:newdate});
      this.setDate(newdate)
    },
    updateSeller:function(value){
      this.setSeller(value)
    },
    addSubnet:function (object){
      this.historyObject.subnets.push(object)
    },
    isAllSubnetsWithSameLir(withError = true){
      let isAllSubnetsWithSameLir = true;
      let buyerLir = null;
      let sellerLir = null;
      for (let el of this.historyObject.subnets){
        if(el?.subnet){
          let ip = new IPv4Net(el?.subnet);
          if(ip.isIp()){
            let result =  this.getCashedSubnetTransferInfo(el.subnet);
            if(buyerLir !== null && result?.buyer?.[0]?.name && buyerLir !== result?.buyer?.[0]?.name){
              isAllSubnetsWithSameLir = false;
              if(withError){
                this.$store.commit('errorPush', "Сеть "
                    +el.subnet
                    +" принадлежит другому LIR и нужно создать отдельную сделку")
              }

              break;
            }
            if(buyerLir === null  && result?.buyer?.[0]?.name ){
              buyerLir = result?.buyer?.[0]?.name;
            }
            if(sellerLir !== null && result?.seller?.[0]?.name && sellerLir !== result?.seller?.[0]?.name){
              isAllSubnetsWithSameLir = false;
              if(withError){
                this.$store.commit('errorPush', "Сеть "
                    +el.subnet
                    +" принадлежит другому LIR и нужно создать отдельную сделку")
              }
              break;
            }
            if(sellerLir === null  && result?.seller?.[0]?.name ){
              sellerLir = result?.seller?.[0]?.name;
            }
          }
        }
      }
      return isAllSubnetsWithSameLir;
    },
    focusSubnet(){
      if(this.$refs?.['as-input']){
        this.$refs['as-input'].focus();
      }
      if(this.$refs?.['subnets']?.$refs?.['subnet0']?.[0].$refs?.['subnet-input']){
        this.$refs?.['subnets']?.$refs?.['subnet0']?.[0].$refs?.['subnet-input'].focus();
      }
    },
    editSubnet:function ({value , key , type}){
      let isNet = true;
      value = value.trim();
      if(
          this.userHistoryObject === null &&
          typeof value === 'string' &&
          value.trim() != '' &&
          (
              (new IPv4Net(value)).isIp() ||
              type === 'asn' ||
              (value[0] === '!' && new IPv4Net(value.substring(1)).isIp())
          )
      ){

        if(!this.isPriceChange && type  !== 'PI' && type  !== 'ASN' && this.isourSeller){
            this.loadingPrice = true;
            this.loadPrice(value).then(()=>{
              this.changePrice();
            }).catch(()=>{
              this.errorPush("Ошибка при расчете нашей цены");
            }).finally(()=>{
              this.loadingPrice = false;
            });
        }
        if(key == 0){
          if( (this.historyObject.subnets[key].subnet !== value && type !== 'asn') ||
              (type === 'asn')
               /*||  (this.historyObject.subnets[key]?.type &&
              this.historyObject.subnets[key].type !== type &&
              (this.historyObject.subnets[key].type.indexOf('PI') !== -1 || this.historyObject.subnets[key].type.indexOf('PA'))  ) */){

            let transferSearchOptions = {'getFirstTransfer':false};
            if(value[0] === '!'){
              value = value.substring(1);
              transferSearchOptions = {};
            }
            this.transferInfoLoading = false;
            this.subnetPersonsSellerLoading  = false;
            this.subnetPersonsBuyerLoading  = false;

            if( this.updateStatusFromRipeListner != null){
              clearTimeout(this.updateStatusFromRipeListner);

              this.updateStatusFromRipeListner = null;
            }

            this.updateStatusFromRipeListner = setTimeout(() => {
              if( (value === this.historyObject.subnets[key].subnet  && type !== 'asn') ||
                  (type === 'asn' && value.match(/^AS\d{2,}/i))
              ){

                this.oldnames = {
                  seller:'',
                  buyer:'',
                };
                ['seller', 'buyer'].forEach(type => {
                  if( this.historyObject?.[type]?.name){
                    this.oldnames[type] =  this.historyObject?.[type]?.name;
                  }

                  this.historyObject[type].name = '';

                  this.historyObject[type].email = '';
                  this.historyObject[type].contact_emails = []
                  this.historyObject[type].lirid = '';
                  this.historyObject[type].org = '';
                });
                if(type !== 'asn'){
                  this.loadSubnetStatus({index:key}).then(()=>{

                    if(key === 0) {
                      this.loadTransferInfo(value, key, type, transferSearchOptions);
                    }
                    this.updateStatusFromRipeListner = null;
                  });
                }else {
                  this.loadTransferInfo(value, key, type, transferSearchOptions);
                  this.updateStatusFromRipeListner = null;
                }

              }


            },700);
          }

        }else {

          if(this.updateStatusFromRipeListnerTwo !== null){
            this.updateStatusFromRipeListnerTwo= null;
          }
          if(this.historyObject.subnets[key].subnet !== value ){
            let transferSearchOptions = {'getFirstTransfer':false};
            if(value[0] === '!'){
              value = value.substring(1);
              transferSearchOptions = {};
            }

            this.updateStatusFromRipeListnerTwo = setTimeout(()=>{
              if(value === this.historyObject.subnets[key].subnet ){
                if(key !== 0){

                  this.loadSubnetStatus({index:key})
                  this.loading = true;
                  this.isAllSubnetsWithSameLirValue = false;
                  Utility.getTransferInfo(value , {'getFirstTransfer':false} ).then((result) => {
                    this.addSubnetTransferInfo(value , result.data,transferSearchOptions)
                    this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir();
                  }).finally(()=>{
                    this.loading = false;
                  });
                }
              }
            }, 700);

          }
        }
      }else {
        isNet = false;

      }
      let object = null;
      if(key === 0 && this.userHistoryObject === null && value != '' && !(new IPv4Net(value)).isIp()){
          object = parseString(value);

      }

      if(object === null){

        if( type !== 'asn'){
          this.historyObject.subnets[key].subnet = value;
          this.historyObject.subnets[key].type = type;
        }else {
          this.historyObject.asn.name = value;
        }
        if(!isNet){
          this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir(false);
        }


      }else {
        this.setIsAs(false);
        this.resetClient('seller')
        this.resetClient('seller')
        this.resetPrice('buyprice')
        this.resetPrice('sellprice')
        this.historyObject.date = '';
        this.$nextTick(()=>{
          if(object?.subnet && !object.subnets ) {
            if(Array.isArray( object.subnet ) ){
              this.historyObject.subnets = [];
              for (let el of object.subnet){
                let obj = {
                  subnet:el,
                  type:''
                };
                if(object?.subnettype){
                  obj.type = object.subnettype;
                }
                this.historyObject.subnets.push(obj)
              }
            }else {
              this.historyObject.subnets[key].subnet = object.subnet;
            }


            if( object?.subnettype ){
              this.historyObject.subnets[key].type = object.subnettype;
              if (!object?.date) {
                this.isAllSubnetsWithSameLirValue = false;
                Utility.getTransferInfo(object.subnet, {'getFirstTransfer': false}).then((result) => {
                  this.addSubnetTransferInfo(object.subnet , result.data)
                  if (result?.data?.readydate) {
                    this.$store.dispatch('offerHistory/initRates', {date: moment(result.data.readydate).format("DD.MM.YYYY") });
                    this.setDate(moment(result.data.readydate).format("DD.MM.YYYY"));
                  }
                  this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir();
                });
              }
            } else {
              this.historyObject.date = null;
              this.isAllSubnetsWithSameLirValue = false;
              this.loadSubnetStatus({index: key}).then(() => {
                if (!object?.date) {
                  Utility.getTransferInfo(object.subnet, {'getFirstTransfer': false}).then((result) => {
                    this.addSubnetTransferInfo(object.subnet , result.data)
                    if (result?.data?.readydate) {
                      this.$store.dispatch('offerHistory/initRates', {date: moment(result.data.readydate).format("DD.MM.YYYY") });
                      this.setDate(moment(result.data.readydate).format("DD.MM.YYYY"));
                    }
                    this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir();
                  });
                }
              })
            }
          }
          if(object?.subnets){
            this.historyObject.subnets = object.subnets;
            if (!object?.date) {
              this.isAllSubnetsWithSameLirValue = false
              Utility.getTransferInfo(this.historyObject.subnets[0].subnet, {'getFirstTransfer': false}).then((result) => {
                this.addSubnetTransferInfo(this.historyObject.subnets[0].subnet , result.data)
                if (result?.data?.readydate ) {
                  this.$store.dispatch('offerHistory/initRates', {date: moment(result.data.readydate).format("DD.MM.YYYY") });
                  this.setDate(moment(result.data.readydate).format("DD.MM.YYYY"));
                }
                this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir();
              });
            }
          }

          if(object?.date){
            this.$store.dispatch('offerHistory/initRates', {date: moment(object.date,"DD.MM.YYYY").format("DD.MM.YYYY") });
            this.setDate( moment(object.date,"DD.MM.YYYY").format("DD.MM.YYYY") );
          }
          if(object?.sellprice){
            this.historyObject.sellprice = object.sellprice;
          }
          if(object?.buyprice){
            this.historyObject.buyprice = object.buyprice;
          }
          this.historyObject.seller = object.seller;
          this.historyObject.buyer = object.buyer;
          if(object?.comment){
            this.historyObject.comment = object.comment
          }

        })

      }


    },


    loadTransferInfo(subnet, subnetKey, subnetType, options) {
      if(subnet !== '') {
        this.transferInfoLoading = true;


        ['seller', 'buyer'].forEach(type=> {
          this.historyObject[type].name = '';
          this.historyObject[type].email = '';
          this.historyObject[type].contact_emails = []
        });
        options = typeof options === 'object' ? options : {'getFirstTransfer':false};
        this.isAllSubnetsWithSameLirValue = false;
        Utility.getTransferInfo(subnet , options ).then((result) => {
            this.transferInfo = result.data;
          this.addSubnetTransferInfo(subnet , result.data)
            for(let type of ['seller', 'buyer']){
              if( this.transferInfo?.[type]?.[0]?.name &&  this.oldnames?.[type]  &&
                  this.oldnames?.[type] !== this.transferInfo?.[type]?.[0]?.name){
                let subnets = []
                subnets.push(
                    {
                      "subnet":subnet,
                      "type":this.historyObject.subnets[subnetKey].type,
                    }
                )
                subnets.push(
                    {
                      "subnet":"",
                      "type":"",
                    }
                )
                this.historyObject.subnets = subnets;
                break;
              }
            }


                ['seller', 'buyer'].forEach(type => {
              if( typeof this.transferInfo[type] !== "undefined" && typeof this.transferInfo[type][0] !== 'undefined'){


                  this.historyObject[type].name = this.transferInfo[type][0].name;
                  if(this.transferInfo[type][0].params){
                    let tmp = {
                      phone:[],
                      email:[],
                    }
                    for (let param of this.transferInfo[type][0].params){
                      if(param.key == 'Phone'){
                        if(param?.value){
                          tmp.phone.push({
                            'number': Phone.makePretty(param.value),
                            'type':'другой',
                          });
                        }else {
                          tmp.phone.push({
                            'number': '',
                            'type':'',
                          });
                        }

                      }
                      if(this.transferInfo?.[type]?.[0]?.isSameEmails){
                        if(param.key == "Email"){
                          tmp.email.push({
                            'mail':param.value,
                            'type':'рабочий',
                          });
                        }
                      }

                    }
                    /*
                    if(tmp.phone.length > 0){
                      this.historyObject[type].phone =  tmp.phone;
                    }*/
                    if(tmp.email.length > 0){
                      this.historyObject[type].email = tmp.email.shift().mail;
                      if(tmp.email.length > 0){
                        this.historyObject[type].contact_emails = tmp.email;
                      }
                    }
                  }

              }
            });

            if(typeof this.transferInfo.readydate !== 'undefined') {
                this.dateChanged = true;

                this.$store.dispatch('offerHistory/initRates', {date: moment(this.transferInfo.readydate).format("DD.MM.YYYY") });
                this.setDate( moment(this.transferInfo.readydate).format("DD.MM.YYYY") );
                if(subnetType !== 'asn'){
                  if(result.data.original_set?.[0]?.start_address ){
                    let original_set = result.data.original_set[0].start_address + " - "+  result.data.original_set[0].end_address
                    this.loadSubnetSellerInfo(original_set,
                        moment(this.transferInfo.readydate).subtract(1 , 'day')
                            .format('YYYY-MM-DD') , subnet , subnetKey);
                  }
                  if(result.data.transfer_set?.[0]?.start_address ){
                    let transfer_set  = result.data.transfer_set[0].start_address + " - "+  result.data.transfer_set[0].end_address
                    this.loadSubnetBuyerInfo(transfer_set,  moment(this.transferInfo.readydate).add(1 , 'day')
                        .format('YYYY-MM-DD') , subnet , subnetKey);
                  }
                }else {
                  this.loadSubnetSellerInfo(subnet,
                      moment(this.transferInfo.readydate).subtract(1 , 'day')
                          .format('YYYY-MM-DD') , subnet , subnetKey, 'asn');
                  this.loadSubnetBuyerInfo(subnet,  moment(this.transferInfo.readydate).add(1 , 'day')
                      .format('YYYY-MM-DD') , subnet , subnetKey, 'asn');
                }

            }
          this.isAllSubnetsWithSameLirValue = this.isAllSubnetsWithSameLir();

        }).finally(() => this.transferInfoLoading = false);
      }
    },

    getBuyerLiridFromWhois(subnetMain){
      this.loadingLirFromWhois = true;
      return Utility.getSubnetWhois(subnetMain).then((result) => {
        if(result.data?.netname){
          let split = result.data.netname.split('-')
          if(split?.[0] && split?.[1]){
            this.historyObject.buyer.lirid = (split?.[0] + "." + split?.[1]).toLowerCase();
          }
        }
      }).finally(()=>{
        this.loadingLirFromWhois = false;
      })
    },

    loadSubnetBuyerInfo(subnet, date ,  subnetMain , subnetKey, subnetType = ''){
      this.subnetPersonsBuyerLoading = true;
      this.historyObject.buyer.lirid = '';
      this.historyObject.buyer.org = '';
      let lirFound = false;
      date = moment(date).format('YYYY-MM-DD');

      let type;
      if(subnetType === 'asn'){
        type = 'ASN'
      }else {
        type = this.historyObject?.subnets?.[0]?.type;
      }


      CrmApi.getSubnetPerson(subnet, date , type, 'after').then(result => {
        this.$nextTick(() => {
          ['lirid', 'org'].forEach(param => {
            if(typeof result.data[param] !== 'undefined') {
              if(param === 'lirid'){
                lirFound = true;
              }
              this.historyObject.buyer[param] = result.data[param];
            }
          });
          if(
              type == "PA" &&
              ( this.historyObject.buyer["lirid"] == null || this.historyObject.buyer["lirid"] == this.historyObject.seller["lirid"])
          ){
            this.getBuyerLiridFromWhois(subnetMain);
          }
        })

      }).finally(()=>{
        this.subnetPersonsBuyerLoading = false;
      });
    },

    loadSubnetSellerInfo(subnet, date , subnetMain , subnetKey, subnetType = ''){
      this.subnetPersonsSellerLoading = true;
      this.historyObject.seller.lirid = '';
      this.historyObject.seller.org = '';
      let type;
      if(subnetType === 'asn'){
        type = 'ASN'
      }else {
        type = this.historyObject?.subnets?.[0]?.type;
      }
      date = moment(date).format('YYYY-MM-DD');
      CrmApi.getSubnetPerson(subnet, date , type , 'before').then(result => {

            this.$nextTick(() => {
              ['lirid', 'org'].forEach(param => {
                if(typeof result.data[param] !== 'undefined') {
                  this.historyObject.seller[param] = result.data[param];
                }
              });

              if(
                  type == "PA" &&
                  this.historyObject.seller["lirid"] != null &&
                  this.historyObject.buyer["lirid"] === this.historyObject.seller["lirid"]
              ){
                this.getBuyerLiridFromWhois(subnetMain);
              }
            })

      }).finally(()=>{
        this.subnetPersonsSellerLoading = false;
      });
    },

    loadSubnetPersons(subnet, date, subnetKey) {
      this.subnetPersonsLoading = true;
      ['seller', 'buyer'].forEach(type => {
        this.historyObject[type].lirid = '';
        this.historyObject[type].org = '';
      });
      date = moment(date).format('YYYY-MM-DD');
      CrmApi.getSubnetPerson(subnet, date , this.historyObject?.subnets?.[0]?.type).then(result => {
        if(this.subnetPersonsLoading){
          ['seller', 'buyer'].forEach(type => {
            this.$nextTick(() => {
              ['lirid', 'org'].forEach(param => {

                if(typeof result.data[type][param] !== 'undefined') {
                  if(this[type] === null) {
                    this[type] = {};
                  }
                  this.historyObject[type][param] = result.data[type][param];
                }


              });
            })
          })
        }

      }).finally(() => this.subnetPersonsLoading = false);
    },

    ...mapMutations({
      'setSeller':'offerHistory/addEdit/setSeller',
      'setBuyer':'offerHistory/addEdit/setBuyer',
      'setDate':'offerHistory/addEdit/setDate',
      'errorPush':'errorPush',
      'setIsOurSeller':'offerHistory/addEdit/setIsOurSeller',
      'setIsOurBuyer':'offerHistory/addEdit/setIsOurBuyer',
      'successPush':'successPush',
      'clearAlerts':'clearAlerts',
      'resetPrice':'offerHistory/addEdit/resetPrice',
      'resetClient':'offerHistory/addEdit/resetClient',
      'setIsAs':'offerHistory/addEdit/setIsAs'
    }),
    ...mapActions({
      'edit':'offerHistory/addEdit/edit',
      'changeRoute':'offerHistory/changeRoute',
      'init':'offerHistory/addEdit/init',
      'create':'offerHistory/addEdit/create',
      'loadSubnetStatus':"offerHistory/addEdit/loadSubnetStatusFromRIPE"
    }),
  },
  created() {
    if(this.userHistoryObject !== null){
      this.isPriceChange = true;
      if(this.userHistoryObject?.date){

        this.$store.dispatch('offerHistory/initRates', {date:this.userHistoryObject.date});
      }else {
        this.$store.dispatch('offerHistory/initRates', {date:null});
      }

    }else {
      this.$store.dispatch('offerHistory/initRates', {date:null});
    }
    this.init({'userHistoryObject':Object.assign({}, this.userHistoryObject)});
  },
  watch:{


    'historyObject.asn.name'(to){
      if(this.historyObject?.subnets){
        for (let el of this.historyObject.subnets){
          if(el.type === 'ASN'){
            el.type = 'PA';
          }
        }
      }
      if(typeof to == 'string' && to.trim().match(/^\d+/) && this.isAs){
        this.subnetType = 'PA';
        if(this.historyObject?.subnets?.[0]?.subnet){
          this.historyObject.subnets[0].subnet = '';
        }else {
          this.historyObject.subnets  = []
        }

        this.historyObject.asn.name = '';
        this.setIsAs(false);
        this.editSubnet({value:to,type:this.subnetType, key:0 })
        this.$nextTick(()=>{
            this.focusSubnet();
         // this.editSubnet({value:to,type:this.subnetType, key:0 })
        })
      }
      if(to && this.isAs){
        this.editSubnet({value:to,type:'asn', key:0 })
      }
    },
    'historyObject.subnets':{
      handler(val){
        if(  this.historyObject?.subnets?.[0]?.subnet && this.historyObject?.subnets?.[0]?.subnet.trim().match(/^AS/i) ){
          this.subnetType = 'ASN';
          this.setIsAs(true);
          if( this.historyObject.asn.name === ''){
            this.historyObject.asn.name = this.historyObject?.subnets?.[0]?.subnet
          }

        }
        if(
            typeof val !== 'undefined' &&
            this.isourSeller &&
            !this.isPriceChange
        ){
          if(typeof this.prices !== 'undefined' && Object.keys(this.prices).length > 0){
            let price = 0;
            let currency = "EUR";

            val.map((value)=>{
              if(typeof this.prices[value.subnet] !== 'undefined' && this.prices[value.subnet]?.price){
                price += this.prices[value.subnet].price;

              }
            });
            if( this.historyObject?.buyprice?.price && this.historyObject.buyprice.price !== price){
              this.historyObject.buyprice.price = price;
              this.historyObject.buyprice.currency = currency;

            }
          }

        }
      },
      deep:true,
    }
  }
}
</script>

<style scoped>

.modal{
  --bs-modal-margin:0px;
}
.modal-body{
  --bs-modal-padding: 0.5rem;
}
.modal-lg{
  --bs-modal-width:610px;

}
.modal-content{
  --bs-modal-width:610px;
  background-clip: padding-box;

  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modal-mask {
  position: fixed;
  z-index: 9995;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
.modal-row{
  display: flex;
  padding: 0px;
}
.loading-price{
  cursor: progress !important;
}
:deep(.loading-price *){
  cursor: progress !important;
}
.hr-class{
  margin: 8px 0px;
}
label.required:after
{
  color: red;
  content: "*";
}
:deep(.form-control-custom){
  display: block;
  width: 50%!important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
:deep(.dw) {
  width: 8rem;
}
:deep(.subnet-row){
  display: flex;
}
:deep(.subnet-line){
  width: 11rem;
}
:deep(.type-line){
  width: 3rem;
}
</style>
