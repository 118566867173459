import {computed} from "vue";
import {IPv4Net} from "../../../library/IPv4Net";

export default function  getFields({person}){
    let firstValue = ''
    const name = computed({
        set(value){
            person.value.name = value
        },
        get(){
            return person.value?.name && person.value?.name !='' ? person.value.name : '';
        }});


    const email = computed({
        set(value){
            person.value.email = value;
        },
        get(){
            return person.value?.email && person.value?.email !='' ? person.value.email : firstValue;
        }
    });

    const phones = computed({
        set(value){
            person.value.phone = value;
        },
        get(){
            let phone = [{
                number:'—',
                type:''
            }];
            if(person.value?.phone ){
                phone = [];
                person.value.phone.forEach((el)=>{
                    if(typeof el.number === 'string' && el.number.trim() ==''){
                        el.number = firstValue;
                    }
                    phone.push(el)
                })
            }
            return phone;
        }
    });

    const contact_emails = computed(
        {
            set(value){
                person.value.contact_emails = value;
            },
            get(){
                return typeof  person.value?.contact_emails !='undefined' ?  person.value?.contact_emails : [];
            }
        });

    const lirid = computed({
        set(value) { person.value.lirid = value; },
        get() { return person.value?.lirid &&  person.value?.lirid !='' ? person.value.lirid : firstValue }
    });

    const org = computed({
        set(value) { person.value.org = value; },
        get() { return person.value?.org && person.value?.org != '' ? person.value.org :  firstValue }
    });

    const ticket = computed({
        set(value) { person.value.ticket = value; },
        get() { return person.value?.ticket && person.value?.ticket != '' ? window.getId(person.value.ticket) :  firstValue }
    });

    return {name,email,phones ,lirid, contact_emails,org ,ticket }
}


export function isNetworksRepeated(subnets){
    let isNetworksRepeated = false;
    let subnetsHashMaps ={};
    for (let el of subnets){
        let ip = new IPv4Net(el.subnet);
        if(ip.isIp()){
            if(typeof subnetsHashMaps[ip.asRange()] === 'undefined'){
                subnetsHashMaps[ip.asRange()] = 1;
            }else {
                isNetworksRepeated = true;
                break;
            }
        }

    }
    return isNetworksRepeated;
}