<template>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showModal"
          @close="showModalFunction(false)"
          @success="closeForm"
          modal-dialog-class=""
      >
        <template v-slot:header>
          <h5 class="modal-title">Закрыть создание задачи</h5>
        </template>
        <template v-slot:body>
          Вы уверены, что хотите закрыть создание задачи? Все введенные вами данные будут удалены.
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn btn-danger" @click="closeForm">Закрыть</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import modalWindow from "../../tiketMenu/components/ModalWindow";
import StringTrim from "../../string/StringTrim";

export default {
  name: "AddTaskFormCloseModal",

  components: {
    modalWindow,
    StringTrim,
  },

  emits: [
    "close",
    "update",
  ],

  data() {
    return {
      showModal: true,
    }
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
      if (!this.showModal) {
        this.$emit('close');
      }
    },
    closeForm() {
      this.$emit('update');
    },
  },
}
</script>

<style scoped>

</style>