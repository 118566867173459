
import {computed} from 'vue';

import {IPv4Net} from "../../../library/IPv4Net";



export default function getHistoryFields({offerHistory}) {


    const id =  computed(() => {
        return window.getId(offerHistory.value?._id);
    });
    const subnets = computed(() => {
        return offerHistory.value?.subnets;
    });

    const date = computed(() => {
        return offerHistory.value?.date;
    });

    /*
    const ipCount = computed(()=>{
        let count = 0;
        if(typeof subnets.value !== 'undefined' &&  subnets.value != null){
            for (let el of subnets.value){
                let ip = new IPv4Net(el.subnet)
                if(ip.isIp()){
                    count += ip.count();
                }
            }
        }
        return count;
    })
    */
    const count = computed(()=>{
        return offerHistory.value?.count ?? 0;
    })

    const seller = computed( {
        set(value){
            offerHistory.value.seller = value;
        },
        get(){
            let seller = offerHistory.value?.seller;
            if(typeof seller?._id === 'undefined'){
                return  null;
            }
            seller._id = window.getId(seller._id)
            return seller;
        }
    });

    const isOurSeller = computed(() =>{

        return  seller?.value?.isour ?? false;
    })

    const buyer = computed({
        set(value){
            offerHistory.value.buyer = value;
        },
        get(){
            let buyer = offerHistory.value?.buyer;
            if(typeof buyer?._id === 'undefined'){
                return  null;
            }
            buyer._id = window.getId(buyer._id)
            return buyer;
        }
    });
    const isOurBuyer = computed(() =>{
        return  buyer?.value?.isour ?? false;
    })
    return {subnets, date, seller, buyer, count , id, isOurSeller, isOurBuyer}

}