<template>
  <div class="input-group param">
    <span class="border-start" :class="Object.assign({'padding-left-15': !this.ungrouped}, borderStyle)"></span>

    <div v-if="!block.correct" style="width: 220px;">
      <popper content="Блок задан некорректно, для решения проблемы попробуйте выделить название блока болдом или другим отличным от общего текста стилем"
              class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <input type="text" :value="block.keyword" disabled="disabled" class="form-control is-invalid w-100" style="border-radius: unset;" />
      </popper>
    </div>
    <span v-else class="form-control d-flex keyword-name bold">
      <i class="fa fa-bars drag-handle" aria-hidden="true"></i>
      <string-trim :string="block.keyword" />
    </span>

    <input v-if="!['company', 'entrepreneur'].includes(block.condition)" type="text" v-model="block.label" class="form-control" :class="{'border-danger': !block.correct}" placeholder="Название" />

    <select v-model="block.condition" @change="setValid" name="textblock-condition" class="form-control form-select form-select-sm" :class="{'border-danger': !block.correct || (!block.valid && block.showNotValid)}">
      <option value="" disabled selected>Условие вывода</option>
      <option value="company">Если от компании</option>
      <option value="entrepreneur">Если от ИП</option>
      <option value="choice">Выбор пользователя</option>
      <option value="country">Страна клиента</option>
      <option value="legaltype">Статус клиента</option>
    </select>

    <select v-if="block.condition === 'country'" v-model="block.conditionparam" @change="setValid" class="form-control" :class="{'border-danger': !block.correct || (!block.valid && block.showNotValid)}">
      <option :value="null">Не выбрана</option>
      <option v-for="country in countries" :value="country">{{country}}</option>
    </select>

    <select v-if="block.condition === 'legaltype'" v-model="block.conditionparam" @change="setValid" class="form-control" :class="{'border-danger': !block.correct || (!block.valid && block.showNotValid)}">
      <option :value="null">Не выбран</option>
      <option value="company">Компания</option>
      <option value="entrepreneur">ИП</option>
      <option value="person">Физ. лицо</option>
    </select>

    <span v-if="block.showDeleteBtn" class="btn btn-outline-danger" @click="block.delete()">
        <i class="fa fa-times"></i>
    </span>

    <draggable v-if="block.children.length"
               v-bind="dragOptions"
               :list="children"
               item-key="order"
               @change="changeOrder(children)"
               class="w-100"
               handle=".drag-handle">
      <template #item="{element}">
        <keyword :keyword="element"
                 :group="block.group"
                 :offset="ungrouped ? 15 : 30"
                 :key="element.keyword"
                 :draggable="children.length > 1"
                 :border="borderStyle" />
      </template>
    </draggable>
  </div>
</template>

<script>
import StringTrim from "../../string/StringTrim.vue";
import Keyword from "./Keyword.vue";
import {countries} from "../../../library/Client";
import Draggable from "vuedraggable";

export default {
  name: "Block",

  components: {StringTrim, Keyword, Draggable},

  props: {
    block: Object,
    ungrouped: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      countries: Object.values(countries),
      dragOptions: {
        animation: 200,
        ghostClass: 'drag-ghost'
      }
    }
  },

  computed: {
    children() {
      return _.sortBy(this.block.children, 'order')
              .filter(keyword => !keyword.deleted);
    },

    borderStyle() {
      let style = {'border-2': true};

      if(this.clientCompanyFields) {
        style['border-blue'] = true;
      } else if(this.ourCompanyFields) {
        style['border-green'] = true;
      } else {
        style['border-dark'] = true;
        style['border-2'] = false;
      }

      return style;
    },

    ourCompanyFields() {
      return ['company', 'entrepreneur'].includes(this.block?.condition);
    },

    clientCompanyFields() {
      return this.block?.condition === 'legaltype'
    },
  },

  methods: {
    changeOrder(keywords) {
      keywords.forEach((keywords, index) => (keywords.order = index));
    },

    setValid() {
      this.block.valid = true;
    }
  }
}
</script>

<style scoped>
.padding-left-15 {
  padding-left: 15px;
  margin-left: -1px;
}
.bold {
  font-weight: 550;
}
</style>