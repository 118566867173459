<template>
  <div class="">
    <button
        @click="onClick"
        :class="{'btn-light': !show , 'btn-primary':show } "
        class="btn btn-outline-secondary form-control"
        type="button"
    >
      <i :class="{'filter-light':show, 'filter-black':!show }" class="fa fa-filter"></i>
    </button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ShowFilterButton",

  data: function (){
    return {

    }
  },

  computed:{
    show:{
      get(){
        return this.showFilter;
      },
      set(value){
        this.setShowFilter(value);
      },
    },
    ...mapGetters({
      "showFilter":  "offer/filters/showFilter",
    }),
  },

  methods:{
    onClick() {
      this.show = !this.show;
      if (!this.show) {
        this.closeTableFilters();
      } else {
        this.openTableFilters();
      }
    },
    ...mapActions({
      'closeTableFilters': 'offer/filters/closeTableFilters',
      'openTableFilters': 'offer/filters/openTableFilters'
    }),
    ...mapMutations({
      setShowFilter: 'offer/filters/setShowFilter',
    }),
  },
}
</script>

<style scoped>
.filter-light{
  color: white;
}
.filter-black{
  color: rgba(128, 128, 128, 0.99);
}
</style>