<template>
  <div tabindex="0" class=" inner vld-overlay is-active" aria-busy="true" aria-label="Loading" style="z-index: 100;">
    <div class="vld-background" style="backdrop-filter: blur(2px);"></div>
    <div class="vld-icon"><svg viewBox="0 0 38 38"  width="64" height="64" stroke="#000">
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(1 1)" stroke-width="2">
        <circle stroke-opacity=".25" cx="18" cy="18" r="18"></circle>
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.8s" repeatCount="indefinite"></animateTransform>
        </path>
      </g>
      </g>
    </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingList"
}
</script>

<style scoped>
.vld-overlay.is-active {
  display: flex;
}
.inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.vld-overlay {
  width: 150px;
  height: 150px;



  display: none;

  overflow: hidden;
  z-index: 9999;
}
.vld-overlay .vld-background {
  background: #fff;
  opacity: 0.3;
  border-radius: 100px;
}
.vld-overlay .vld-icon, .vld-parent {
  position: relative;
}
img, svg {
  vertical-align: middle;
}
</style>