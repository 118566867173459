import {CrmApi} from "../../../../library/CrmApi";

const actions = {
    searchTickets: ({state, commit, dispatch, getters, rootGetters}, {page, onpage}) => {
        if ( getters.getSearchString ) {
            commit('setLoading', true);
            let searchString = getters.getSearchString;
            let selectedProject = getters.getSelectedProjectName;
            CrmApi.getTicketList({
                "page": page ?? 1,
                "onpage": onpage ?? 50,
                "person.email": JSON.stringify(getters.getSearchEmails),
            }, {
                'projectName': selectedProject,
                'filter': 'personemail',
            }).then((result) => {
                if(
                    searchString == getters.getSearchString &&
                    selectedProject == getters.getSelectedProjectName
                ) {
                    let tickets = result.data.data;
                    commit('setTickets', tickets);
                    commit('setPagination', {
                        page: parseInt(result.data.page),
                        onpage: parseInt(result.data.onpage),
                        total: parseInt(result.data.total),
                        paginator_links: result.data.paginator_links
                    });
                }
            }).catch((message) => {
                commit('errorPush' ,CrmApi.getErrorMessage(message), {root:true});
            }).finally(() => {
                if(
                    searchString == getters.getSearchString &&
                    selectedProject == getters.getSelectedProjectName
                ) {
                    commit('setLoading', false);
                }
            });
        } else {
            commit('setTickets', []);
            commit('setLoading', false);
        }
    },

    updateProducts: ({state, commit, dispatch, getters, rootGetters}) => {
        if (!getters.isProductsLoading) {
            commit('setProductsLoading', true);
            let project = getters.getProjectByName(getters.getSelectedProjectName);
            if (project) {
                CrmApi.getProjectProducts(project._id).then((result) => {
                    commit('setProducts', result.data.products);
                }).catch((message) => {
                    commit('errorPush' ,CrmApi.getErrorMessage(message), {root:true});
                }).finally(() => {
                    commit('setProductsLoading', false);
                });
            }
        }
    },
}

export default actions;