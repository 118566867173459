import {ref} from "vue";
import { useStore} from 'vuex'
import {  computed } from 'vue'
export const AddToAuctionSetup = (props)=> {
    const show = ref(false);
    const store = useStore()

    const loading = computed({
            set(value){
                store.commit('thread/setLoading', value);
            },
            get(){
                return store.getters['thread/loading'];
            }
    });
    const ticket = computed(()=>{
        return store.getters['thread/getTicket']
    });
    const subnetString = computed(()=>{
        return ticket.value?.assigned_product?.fields?.subnets.map((el)=>{
            return el.subnet;
        }).join(', ');
    });
    const isNeedSetOnAuction = computed(()=>{
        return !ticket.value?.assigned_product?.fields?.lot?.date;
    });

    const cancel = function() {
        show.value = false;
        store.commit('removeActiveWindow', 'setLotModal');
    }
    const saveSetLotAllSubnets =  function(date){
         if(loading.value){
            return;
        }
        store.dispatch('thread/setLotSubnets', {setLotAllSubnets: isNeedSetOnAuction.value, date: date})
        show.value = false;
        store.commit('removeActiveWindow', 'setLotModal');
    }

    return {
        saveSetLotAllSubnets, cancel, isNeedSetOnAuction, subnetString, show, loading
    }
}