<template>
  <div class="col-12 minheight" :class="{'cursor-wait': isLoadingSignature}">
    <div>
      <div class="d-inline pull-right m-1">
        <div class="d-inline" v-if="showSignatureButton">
          <button class="btn btn-sm btn-special my-0 me-1"
                  :disabled="signatureButtonDisabled"
                  @click="toggleSignatureButton">
            <i class="fa" :class="signatureButtonIconClass"></i> {{ signatureButtonText }}
          </button>
        </div>
        <div class="d-inline">
          <label for="attachment-input" class="btn btn-sm btn-special" :class="{disabled: upload}">
            <i class="fa fa-paperclip"></i> Вложить
          </label>
          <input type="file" id="attachment-input" multiple @change="addAttachment">
        </div>
        <div class="d-inline" v-if="showHeadersFormButton">
          <button class="btn btn-sm btn-special my-0" @click="toggleHeadersForm">
            <i class="fa fa-cog"></i> Заголовки
          </button>
        </div>
      </div>
      <template v-if="type === 'message'">
        <smart-group
            ref="smart-group"
            :assign-template="assignTemplate"
        ></smart-group>
        <simple-group :assign-template="assignTemplate" :groups="groups"></simple-group>
      </template>
    </div>
    <div  class="  col-12">
          <template-quick-button ></template-quick-button>
    </div>

    <headers-form v-if="type === 'message'"
        :projectId="project"
        :ticketId="ticketId"
        ref="headers-form"
        @updateFormSize="updateFormSize"
        @updateShowButton="updateShowHeadersFormButton"
        @resizeMessagesbox="px => $emit('resizeMessagesbox', px)"
        @updateHeaders="(header, value) => updateHeaders(header, value)"
    ></headers-form>

    <div class="p-1 small attach-list" v-if="files.length">
      <div v-for="(file, index) in files" class="px-1" :class="{uploading: file.process, 'text-muted': file.process}">
        <a :href="file.link" target="_blank" v-if="file.link">
          {{file.name}} ({{getFileSize(file)}})
        </a>
        <span v-else>
          {{file.name}} ({{getFileSize(file)}})
        </span>
        <i role="button" class="fa fa-close" @click="removeAttachment(index)" v-if="file.link"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import {mapActions, mapGetters, mapMutations} from "vuex";
import HeadersForm from "./HeadersForm";
import SmartGroup from "./TemplateSelect/SmartGroup";
import SimpleGroup from "./TemplateSelect/SimpleGroup";
import TemplateQuickButton from "./TemplateQuickView/TemplateQuickButton";

const prettyBytes = require('pretty-bytes');

export default {
  name: "TemplateSelect",

  emits: ['calculateFormSize', 'resizeMessagesbox', 'showSignature', 'updateHeaders'],

  components: {
    TemplateQuickButton,
    SimpleGroup,
    HeadersForm,
    SmartGroup,
  },

  props:{
    "project":{
      // project._id
      type: String,
    },
    "assignTemplate":{
      type: Function,
    },
    "ticketId":{
      type: String,
    },
    "type": {
      type: String,
      default: 'message',
    }
  },
  data:function (){
    return {
      groups:{
        loading: true,
        list: []
      },
      files: [],
      menuRefUnique: 1,
      upload: false,
      savedAttachments: [],
      showHeadersFormButton: false,
      changedFrom: null,
    }
  },
  mounted() {

    // не загружаем шаблоны если не передан проект (например открыли форму добавления комментария)
    if(typeof this.project !== 'undefined') {
      this.groups.loading = true;

      CrmApi.getTemplateGroups(null, this.project).then((result) => {
        this.groups.loading = false;
        this.groups.list = [];
        if (typeof result.data.data !== 'undefined') {
          result.data.data.forEach((name) => {
            this.groups.list.push({
              id: "dropdown-ref-x-" + (this.menuRefUnique++).toString(),
              name: name,
              loading: false,
              templates: null
            })
          });
          this.$nextTick(() => {
            this.$emit('calculateFormSize');
          })
        }
      });
    }

    if( this.getTicket != null && this.type === 'message' ) {
      this.$refs['headers-form'].checkPermission(this.getTicket);
    }

  },

  computed: {
    showSignatureButton() {
      return this.type === 'message';
    },

    signatureButtonIconClass() {
      return this.showSignature ? 'fa-eye-slash' : 'fa-eye';
    },

    signatureButtonText() {
      return this.showSignature ? 'Скрыть подпись' : 'Показать подпись';
    },

    signatureButtonDisabled () {
      return this.isLoadingSignature;
    },

    ...mapGetters({
      getTicket: "thread/getTicket",
      "isLoadingSignature": "thread/isLoadingSignature",
      "showSignature": "thread/showSignature",
    }),
  },

  methods:{
    ...mapMutations({
      setHasNewChangesForDraft: "thread/setHasNewChangesForDraft",
    }),

    showDropdown: function ( element ){
      $(element).dropdown("show");
    },

    hideDropdown: function ( element ){
      $(element).dropdown("hide");
    },

    updateHeaders(header, value) {
      if(header === 'from') {
        this.changedFrom = value;
      }

      this.$emit('updateHeaders', header, value);
    },

    toggleHeadersForm: function() {
      this.$refs['headers-form'].toggleHeadersForm();
    },

    getHeaders: function() {
      return (typeof this.$refs['headers-form'] !== 'undefined') ? this.$refs['headers-form'].getHeaders() : null;
    },
    getNewHeaders: function() {
      return (typeof this.$refs['headers-form'] !== 'undefined') ? this.$refs['headers-form'].getNewHeaders() : null;
    },

    validationHeadersForm: function() {
      return this.$refs['headers-form'].formValidation();
    },

    isShowingHeadersForm: function() {
      return (typeof this.$refs['headers-form'] !== 'undefined' && this.$refs['headers-form'].show);
    },

    isHeaderChangesExists: function() {
      return (typeof this.$refs['headers-form'] !== 'undefined' && this.$refs['headers-form'].changes.length);
    },

    addAttachment: function(e) {
      this.upload = true;

      let files = [ ...e.target.files ];



     // this.files = this.files.concat(files);
      for (let file of files){
        CrmApi.saveAttachments(this.ticketId, [file]).then((response) => {

          let attachments = response.data.attachments;

          attachments.forEach((attach) => {
            attach.name = attach.filename;
            attach.id = window.getId(attach.id);
            attach.link = '/attachment/' + attach.id + '/';
            if(typeof attach.date === 'undefined') {
              attach.date = moment(new Date()).format('YYYYMMDD');
            }
          });
          // триггер для vue
          this.files =  this.files.concat(attachments)

          this.savedAttachments = this.savedAttachments.concat(attachments);

        }).catch((error) => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.upload = false;
          e.target.value = '';
        });
      }

    },

    addExistsAttachments: function(attachments, messageId = null) {
      if(typeof attachments !== 'undefined' && attachments !== null && attachments.length) {
        attachments.forEach((attach) => {
          attach.name = attach.filename;
          attach.fromExistMessage = true;

          attach.link = '/message/' + window.getId(messageId) + '/attachment/' + window.getId(attach.id);
        });

        this.files = attachments;
        this.savedAttachments = attachments;
      }
    },

    clearAttachments: function() {
      this.files = [];
      this.savedAttachments = [];
    },

    addUploadedAttachments: function(attachments) {
      attachments.forEach((attach) => {
        attach.name = attach.filename;
        attach.id = window.getId(attach.id);
        attach.link = '/attachment/' + attach.id + '/';
        if(typeof attach.date === 'undefined') {
          attach.date = moment(new Date()).format('YYYYMMDD');
        }
      });
      this.files = this.files.concat(attachments);
      this.savedAttachments = this.savedAttachments.concat(attachments);
    },

    addHeadersDraft: function(draft) {
      for(let d in draft) {
        if(draft[d] !== null) {
          this.$refs['headers-form'].addDraft(draft);
          break;
        }
      }
    },

    getFileSize: function(file) {
      return prettyBytes(file.size);
    },

    removeAttachment: function(index) {
      let removeFunc = () => {
        this.savedAttachments = this.savedAttachments.filter(at => at.filename !== this.files[index].name);
        this.files.splice(index, 1);
      }

      if(typeof this.files[index].fromExistMessage !== 'undefined' && this.files[index].fromExistMessage === true) {
        // это вложение добавленное с помощью "создать как новое", оно удаляется только из списка аттачей в форме
        removeFunc();
      } else {
        this.files[index].process = true;
        this.files = this.files.concat();

        CrmApi.call('/thread/attachment/' + encodeURIComponent(this.files[index].id) + '/delete/').then(removeFunc).catch((error) => {
          delete this.files[index].process;

          if(error.response.status === 404 &&
             typeof error.response.data.code !== 'undefined' &&
             error.response.data.code === '404/2') {
                removeFunc();
          } else {
            this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
          }
        });
      }
    },

    updateFormSize: function() {
      this.$emit('calculateFormSize');
    },

    updateShowHeadersFormButton: function(show) {
      this.showHeadersFormButton = show;
    },

    toggleSignatureButton() {
      this.toggleSignature({ticketId: this.ticketId, alias: this.changedFrom});
      if (this.showSignature === false) {
        this.$emit('showSignature');
      }
    },

    ...mapActions({
      "toggleSignature": "thread/toggleSignature",
    })
  },
  watch:{
    'files':{
      handler(){
        this.setHasNewChangesForDraft(true);
      },
      deep: true,
    }
  }
}
</script>

<style scoped>
#attachment-input {
  display: none;
}
.uploading {
  cursor: wait;
}
button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.minheight{
  min-height: 2.4em;
}
.attach-list {
  display: flex;
  flex-wrap: wrap;
}
.btn-special{
  background-color: #ebebeb;
  border-color: #a0a0a0;
  color: #000;
}
.btn-special:hover{
  background-color: #dadada;
}
.cursor-wait {
  cursor: wait;
}
</style>