<template>
  <div class="event-block">
    <div class="event-date">{{ date }}</div>
    <div class="event-message">
      Клиент поднял ставку
      <span v-if="params.subnets">
        на {{params.subnets.length > 1 ? 'сети' : 'сеть'}} <a :href="sellerLink">{{subnets}}</a>
      </span>
      {{params.price.old}} USD -> {{params.price.new}} USD за 1 IP
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "BuyerRaiseBid",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    subnets() {
      return this.params.subnets.join(', ').trim();
    },

    sellerLink() {
      return '/tickets/' + window.getId(this.params.seller);
    }
  }
}
</script>

<style scoped>

</style>