import getUserName from "../../../library/userName";
import {computed} from "vue";
import {getFilterLink} from "../../../library/Locations";

/**
 *
 * @param  event
 * @returns {{date: string, eventId: *, params: *}}
 */
export default function parseEvent(event) {
    let params = event.params;
    let date = moment(event.date ?? event.created).format(window.dateFormatWeekDaySeconds);
    let eventId = window.getId(event._id);

    let parsedEvent = {
        params: params,
        date: date,
        eventId: eventId,
        user: null,
        byFilter: false,
        filter: {},
    };

    if(window.isId(params?.user?._id)) {
        parsedEvent.user = getUserName({managerRef: {value: event?.params?.user ?? {}}}).name.value;
    }

    if (parsedEvent.user == null && typeof params?.filter === 'object') {
        parsedEvent.filter = params.filter;
        if (typeof parsedEvent.filter?._id !== 'undefined' && parsedEvent.filter._id) {
            parsedEvent.filter.url = getFilterLink(window.getId(parsedEvent.filter?._id));
        }
        if (typeof parsedEvent.filter?.name !== 'string' || parsedEvent.filter.name.trim() === '') {
            parsedEvent.filter.name = '—';
        }
        parsedEvent.byFilter = true;
    }

    if(window.isId(params?.ticket)) {
        parsedEvent.ticketLink = '/tickets/' + encodeURIComponent(params.ticket);
    }

    if(typeof event.attachments === 'object' && Array.isArray(event.attachments)) {
        parsedEvent.attachments = event.attachments;
    }

    return parsedEvent;
}

export function parseReactiveEvent(event) {
    let params = computed(() => event.value?.params);
    let date = computed(() => moment(event.value?.date ?? event.value?.created).format(window.dateFormatWeekDaySeconds));
    let eventId = computed(() => window.getId(event.value?._id));

    let parsedEvent = {
        params: params,
        date: date,
        eventId: eventId,
    };

    if (window.isId(event.value?.params?.user?._id)) {
        parsedEvent.user = computed(() => getUserName({managerRef: {value: event.value?.params?.user ?? {}}}).name.value);
    }

    if (window.isId(event.value?.params?.ticket)) {
        parsedEvent.ticketLink = computed(() => '/tickets/' + encodeURIComponent(event.value?.params?.ticket));
    }

    if(typeof event.value?.attachments === 'object' && Array.isArray(event.value?.attachments)) {
        parsedEvent.attachments = computed(() => event.value?.attachments);
    }

    return parsedEvent;
}