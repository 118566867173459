
export default {

    setListByRow(state, list){
        state.events.listByRow = list;
    },

    setEvent(state, {event}){
        state.events.list.set(window.getId(event._id) , event);
    },

    setEvents(state, {events}){
        for (let event of events){
            state.events.list.set(window.getId(event._id) , event);
        }
    },
    clearEvents(state){
        state.events.list.clear();
    },

    clearPaginator(state){
        state.paginator = {
            page:1,
            onpage:50,
            links:[],
            total:0,
        }
    },


    deleteEvent(state , {_id}){
        state.events.list.delete(_id);
    },

    setLoading(state , value){
        state.loading = value;
    },
    setPage(state , value){
        state.paginator.page = value;
    },
    setOnPage(state, value){
        state.paginator.onpage = value;
    },
    setTotal(state, value){
        state.paginator.total = value;
    },
    setLinks(state, value){
        state.paginator.links = value;
    },

};
