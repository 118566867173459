<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
          <tr class="no-shadow">
            <th
                data-bs-toggle="collapse"
                :aria-expanded="!collapsed"
                class="expandable"
                data-bs-target="#billmanager-client-table-collapse"
                :colspan="3"
                scope="col">Клиенты BILLmanager ({{ relations.length }})</th>
          </tr>
      </thead>
    </table>

    <div class="relation-table collapse" id="billmanager-client-table-collapse" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
        <thead class="sticky">
          <tr>
            <th
                class="sortable"
                :class="sortedClass('id')"
                @click="sortBy('id')">ID</th>
            <th
                class="sortable"
                :class="sortedClass('title')"
                @click="sortBy('title')">Наименование</th>
            <th
                class="sortable"
                :class="sortedClass('providers')"
                @click="sortBy('providers')">Провайдер</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td><a :href="relation.link" target="_blank">{{relation.id}}</a></td>
            <td class="text-start">{{relation.title}}</td>
            <td>
              <span class="badge rounded-pill project-badge-bg" style="background-color: rgb(81 84 86);" v-for="provider in relation.providers">{{provider}}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {sortFunctions} from "../Relation";

export default {
  name: "Client",

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      sort: "id",
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {
        "id": "id",
      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
</style>