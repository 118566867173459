<template>
  <div class="d-flex flex-column align-content-center" @click="hideContextMenu()" id="history-list" v-key-n.ctrl="()=>{
    if(!$store.getters['offerHistory/isEditClientOpen']){
      $store.dispatch('offerHistory/closeDeleteModalWindow');
      isOpen = true
    }

  }">
    <div class="m-auto main-container" id="nav-menu">
      <div class="row">
        <div class="col-10">
          <nav-bar />
        </div>
        <div class="col-2" style="display: flex;align-items: flex-end;">
          <currency-bar style="width: 100%; text-align: right;" ></currency-bar>
        </div>
      </div>
      <search-string />
    </div>
    <div class="history-table flex-column align-items-center">
      <offers-history-list />
    </div>
    <div style="position: relative">
      <paginate margin-top-ul="0px" style="margin-right: 15px"  id="offer-history-paginate"  @changePage="goToPage($event)"
                :update-by-object="true"
                :paginator="paginator"
                ref="paginate"
                settings-name="history"
      >

        <template v-slot:additional-menu>
           <span v-if="canDownloadJson" class="json-load" >
             <span style="margin-right: 7px" >
                <a title="Backup" :href="getDownloadJsonHref()" style="color:black;" target="_blank"><i class="fa fa-floppy-o" aria-hidden="true"  ></i></a>
             </span>
             <span  style="margin-right: 7px">
                <a title="Stats" :href="getDownloadExcelHref()" style="color: green" target="_blank"><i class="fa fa-file-excel-o" aria-hidden="true"  ></i></a>
             </span>
              <span>
                <a title="Dump" :href="getDownloadDumpHref()" target="_blank"><i class="fa  fa-file-code-o" aria-hidden="true"  ></i></a>
             </span>

          </span>
          <span class="deals">
            <span >
              <span><span  v-price-clipboard.byContent="true"  :content="deals" >Сделок: </span>

                <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                  <span v-price-clipboard="true">{{countDeal}}</span>
                  <template #content>
                    <div class="text-center">
                       <div>Продавцов: {{getAdditionalCounts.unique_sellers_count + (getAdditionalCounts.our_net_count > 0 ? 1 : 0 )}}</div>
                       <div>Покупателей: {{getAdditionalCounts.unique_buyer_count + (getAdditionalCounts.buy_for_as_count > 0 ? 1 : 0 )}}</div>
                       <div hidden>Сделок с нашими сетями: {{getAdditionalCounts.our_net_count}}</div>
                       <div hidden>Покупок для нас: {{getAdditionalCounts.buy_for_as_count}}</div>
                    </div>
                  </template>
                </popper>
                (<popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                   <template #content>
                   <div class="text-center">
                      <div>
                        Min: {{minBuyPriceForIP}}
                      </div>
                      <div>
                        Avg: {{avgBuyPriceForIP}}
                      </div>
                       <div>
                        Max: {{maxBuyPriceForIP}}
                      </div>
                   </div>
                  </template>
                <span v-price-clipboard.byContent="true"  :content="reducePriceBuy">{{buyPrices['current']}}</span>
                </popper>
                <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                   <template #content>
                        <div  class="text-center">
                          <div v-for="(price) in getPrettyDifferences">{{price}}</div>
                        </div>
                   </template>
                  <span style="padding:0px 5px ">/</span>
                </popper>
                  <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                   <template #content>
                     <div class="text-center">
                      <div class="text-center">
                      <div>
                        Min: {{minSellPriceForIP}}
                      </div>
                      <div>
                        Avg: {{avgSellPriceForIP}}
                      </div>
                       <div>
                        Max: {{maxSellPriceForIP}}
                      </div>
                   </div>
                     </div>
                </template>
                <span v-price-clipboard.byContent="true"  :content="reducePriceSell">
                {{sellPrices['current']}}</span>
                   </popper>) на <span v-price-clipboard.byContent="true"  :content="ips" >{{countIP}}</span><span> IP</span>
                </span>
            </span>
          </span>
        </template>
      </paginate>
    </div>
    <teleport to="body">
      <add-edit v-if="isOpen" :user-history-object="historyEditObject" @close="close()" />
    </teleport>
    <context-menu></context-menu>
    <alert-list />
  </div>
</template>

<script>
import NavBar from "../offer/navigation/NavBar";
import AlertList from "../ticket/Alert/AlertList";
import OffersHistoryList from "./table/OffersHistoryList";
import SearchString from "./filters/SearchString";
import AddEdit from "./Modal/AddEdit";
import {mapGetters} from "vuex";
import ContextMenu from "./DataCells/ContextMenu";
import Paginate from "../paginate/Paginate";
import {PricePrettyView} from "../../library/PricePrettyView";
import CurrencyBar from "../offer/navigation/CurrencyBar";
import {UserPermission} from "../../users/UserPermission";
import moment from "moment";
export default {
  name: "History",
  components: {
    Paginate,
    CurrencyBar,
    ContextMenu,
    AddEdit,
    SearchString,
    OffersHistoryList,
    AlertList,
    NavBar,
  },
  data:function (){
    return {
      canDownloadJson:false,
      historyEditObject:null,
    }
  },
  props:{
      editObjectId:{
        default:null,
      },
      editPage:{

      },
      editOnPage:{

      }
  },
  created() {
    this.$store.dispatch('initRates' , moment().format("YYYY-MM-DD"));
    this.$router.isReady().then(()=>{
      if(this.editObjectId === null){
        this.$store.dispatch('offerHistory/init');
      }else {
        let  q = Object.assign({} , this.$store.getters['getRoute'].query);
        q.page = this.editPage;
        q.onpage= this.editOnPage;
        this.$router.push({
          'name': 'HistoryEdit',
          'params': {
            'historyId': this.editObjectId,
          },
          'query':q
           }).then(()=>{
          this.$store.dispatch('offerHistory/init');
            this.$store.commit('offerHistory/setEditObjectId', this.editObjectId)

            this.$store.dispatch('offerHistory/changeRoute', {}).then(()=>{
              this.$nextTick(()=>{
                this.$store.commit('offerHistory/changeOpenEdit')
                this.offersHistoryList.forEach((value, key, map)=>{
                  if(window.getId(value._id ) === this.editObjectId){
                    this.historyEditObject = value;
                    this.isOpen = true;
                    return;
                  }
                })

              })

            });
        });
      }
    })
    },
  mounted() {

    this.canDownloadJson = false;
    UserPermission.can('system.history_dump').then(()=>{
      this.canDownloadJson = true
    }).catch(()=>{});

    this.$nextTick(()=>{
      if(this.$refs?.['history-list']){
        this.$refs['history-list'].focus();
      }

    })

  },
  methods: {
    close(){
      this.historyEditObject = null;
      this.isEditClientOpen= false;
      this.isOpen = false;
      $("body").css({"overflow": "auto"});

      this.$store.commit('offerHistory/setEditObjectId' , null);
      this.$store.commit('offerHistory/setNeedChangeRoute', false);
      this.$router.push(
          {
            'name':'History',
            'query':Object.assign({} , this.$route.query),
          }
      )

    },
    prepareRates:function(rates){
      let result = {};
      for (let currency in rates){
        let value = rates[currency];
        if(value?.value){
          result[currency] = value.value;
        }else {
          result[currency] = value;
        }
      }
      return result;
    },
    getDownloadDumpHref(){
      let url = window.location.origin + '/ajax/offers/history/excel/dump';
      let filters = Object.assign({}  , this.getFilters);
      filters.currentCurrency = encodeURIComponent(this.getCurrentCurrency);
      if(filters !== undefined && Object.keys(filters).length !== 0){
        url = url + "?" +  Object.keys(filters).map(key => {
          return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
        }).join("&");
      }
      return url;
    },
    getDownloadExcelHref(){
      return window.location.origin + '/ajax/offers/history/excel/stats?currentCurrency=' + encodeURIComponent(this.getCurrentCurrency);
    },
    getDownloadJsonHref(){
      return window.location.origin + '/ajax/offers/history/backup';
    },
    getPrettyPriceByCurrency(price , currencyToShow, precision = 0){
      let pr =  new PricePrettyView(price, currencyToShow)
      return  pr.getPrettyPrice('', true, precision, currencyToShow !== 'USD' , " " );
    },



    goToPage:function ({page , onpage}){
      let q = Object.assign({} , this.$route.query);
      q.page = page;
      q.onpage = onpage;

      this.$router.push({
        name:this.$route.name,
        query:q,
      })
    },
    hideContextMenu:function (){
      this.$store.commit('offerHistory/showContextMenu', false)
      this.$store.commit('offerHistory/setContextMenuParams', null)
    },
    setObserver() {

      new ResizeObserver(() => {

        if($('.history-table').width() < $('.main-container').width()) {

          $('.history-table').width( $('.main-container').width() );
        }

      }).observe($('.history-table')[0]);

    }
  },
  computed:{
    isEditClientOpen:{
      set(value){
        this.$store.commit('offerHistory/setIsEditClientOpen', value)
      },
      get(){
        return this.$store.getters['offerHistory/isEditClientOpen']
      },
    },
    buyPrices:function (){
      let obj = {
        'USD':this.prettyFullBuyPriceUSD,
        'EUR':this.prettyFullBuyPriceEU,
        'RUB':this.prettyFullBuyPriceRu,
      }
      let buy = {
        'current':obj[this.getCurrentCurrency],
        'else':[],
      }
      for (let key in obj){
        if(key !== this.getCurrentCurrency){
          buy.else.push(obj[key])
        }
      }
      return buy;
    },
    sellPrices:function (){
      let obj = {
        'USD':this.prettyFullSellPriceUSD,
        'EUR':this.prettyFullSellPriceEU,
        'RUB':this.prettyFullSellPriceRu,
      }
      let sell = {
        'current':obj[this.getCurrentCurrency],
        'else':[],
      }
      for (let key in obj){
        if(key !== this.getCurrentCurrency){
          sell.else.push(obj[key])
        }
      }
      return sell;
    },

    countDeal:function (){
      return this.paginator.total;
    },
    getdifference:function (){
      let objSell = {
        'USD':this.getFullSellPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullSellPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullSellPriceRu'] ,
      }
      let objBuy = {
        'USD':this.getFullBuyPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullBuyPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullBuyPriceRu'] ,
      }

      let n = objSell[this.getCurrentCurrency] - objBuy[this.getCurrentCurrency];
      return this.getPrettyPriceByCurrency(n , this.getCurrentCurrency);
    },
    getPrettyDifferences:function (){
      let objSell = {
        'USD':this.getFullSellPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullSellPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullSellPriceRu'] ,
      }
      let objBuy = {
        'USD':this.getFullBuyPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullBuyPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullBuyPriceRu'] ,
      }
      let diffs = [];
      for (let currency of ['USD' , 'EUR' , 'RUB']){
        let value = objSell[currency] - objBuy[currency];
        diffs.push(this.getPrettyPriceByCurrency(value ,currency))
      }
      return diffs;
    },


    prettyFullSellPriceRu:function (){
      let p = new PricePrettyView(this.$store.getters['offerHistory/getFullSellPriceRu'] , 'RUB')
      return p.getPrettyPrice('',true, 0)
    },
    prettyFullSellPriceEU:function (){
      let p = new PricePrettyView(this.$store.getters['offerHistory/getFullSellPriceEu'] , 'EUR')
      return p.getPrettyPrice('',true, 0)
    },

    prettyFullBuyPriceRu:function (){
      let p = new PricePrettyView(this.$store.getters['offerHistory/getFullBuyPriceRu'] , 'RUB')
      return p.getPrettyPrice('',true, 0)
    },
    prettyFullBuyPriceEU:function (){
      let p = new PricePrettyView(this.$store.getters['offerHistory/getFullBuyPriceEu'] , 'EUR')
      return p.getPrettyPrice('',true, 0)
    },
    prettyFullSellPriceUSD:function (){
      let p = new PricePrettyView(this.getFullSellPriceUsd , 'USD')
      return p.getPrettyPrice('',true, 0,false)
    },
    prettyFullBuyPriceUSD:function (){
      let p = new PricePrettyView(this.getFullBuyPriceUsd , 'USD')
      return p.getPrettyPrice('',true, 0, false)
    },
    reducePriceSell:function (){
      let objSell = {
        'USD':this.getFullSellPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullSellPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullSellPriceRu'] ,
      }

      let p = new PricePrettyView(objSell[this.getCurrentCurrency] , this.getCurrentCurrency)
      return p.getReducePrices('',2)
    },
    reducePriceBuy:function (){
      let objBuy = {
        'USD':this.getFullBuyPriceUsd,
        'EUR':this.$store.getters['offerHistory/getFullBuyPriceEu'],
        'RUB':this.$store.getters['offerHistory/getFullBuyPriceRu'] ,
      }
      let p = new PricePrettyView(objBuy[this.getCurrentCurrency] , this.getCurrentCurrency)
      return p.getReducePrices('',2)
    },
    countIP:function (){
      let count = this.ips;
      return (count).toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    countAs:function (){
      let count = this.getCountAs;
      return (count).toLocaleString(undefined, {maximumFractionDigits: 2})
    },
    isOpen:{
      set(value){
        this.$store.commit('offerHistory/setEditModule', value);
      },
      get(){
        return this.isEditModuleOpen
      }
    },
    deals:function (){
      return "Сделок: " + this.countDeal + " (" + this.buyPrices['current'] + " / " +
          this.sellPrices['current'] + ") на " + this.countIP + " IP";
    },
    ...mapGetters({
      'getAdditionalCounts':'offerHistory/getAdditionalCounts',
      'getFullSellPriceUsd':'offerHistory/getFullSellPriceUsd',
      'getFullBuyPriceUsd':'offerHistory/getFullBuyPriceUsd',
      'paginator':'offerHistory/getPaginate',
      'isEditModuleOpen':'offerHistory/isEditModuleOpen',
      loading: 'offerHistory/getLoading',
      'ips':'offerHistory/getCountIP',
      'getCurrentCurrency':'offerHistory/getCurrentCurrency',
      'getCountAs':'offerHistory/getCountAs',
      'offersHistoryList':'offerHistory/getOffersHistoryList',
      'getRates':'getRates',
      'getGroups':'offerHistory/getGroups',
      'getFilters':'offerHistory/getFilters',
    }),
    getSellUSDPriceValues:function (){

      let obj = {
        min:null,
        max:null,
        avg:null,
      };
      for(let func of ['Min', 'Avg' , 'Max']){
        let key = this.getCurrentCurrency.toLowerCase() + func + 'ForOneIPsellprice';

        if(typeof this.getGroups[key] !== 'undefined'){
          obj[func.toLowerCase()] = this.getGroups[key]
        }
      }

      return obj
    },
    getBuyUSDPriceValues:function (){

      let obj = {
        min:null,
        max:null,
        avg:null,
      };
      for(let func of ['Min', 'Avg' , 'Max']){
        let key = this.getCurrentCurrency.toLowerCase() + func + 'ForOneIPbuyprice';
        if(typeof this.getGroups[key] !== 'undefined'){
             obj[func.toLowerCase()] = this.getGroups[key]
        }
      }

      return obj
    },
    minBuyPriceForIP:function (){
     // let values = PricePrettyView.getCurrenciesArray(this.getBuyUSDPriceValues['min'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency(this.getBuyUSDPriceValues['min'] , this.getCurrentCurrency , 2);
    },
    maxBuyPriceForIP:function (){
    //  let values = PricePrettyView.getCurrenciesArray(this.getBuyUSDPriceValues['max'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency( this.getBuyUSDPriceValues['max'] , this.getCurrentCurrency , 2);
    },
    avgBuyPriceForIP:function (){
      //let values = PricePrettyView.getCurrenciesArray(this.getBuyUSDPriceValues['avg'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency( this.getBuyUSDPriceValues['avg'] , this.getCurrentCurrency, 2);
    },
    minSellPriceForIP:function (){
     // let values = PricePrettyView.getCurrenciesArray(this.getSellUSDPriceValues['min'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency(  this.getSellUSDPriceValues['min'] , this.getCurrentCurrency, 2);
    },
    maxSellPriceForIP:function (){
      //let values = PricePrettyView.getCurrenciesArray(this.getSellUSDPriceValues['max'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency( this.getSellUSDPriceValues['max'] , this.getCurrentCurrency, 2);
    },
    avgSellPriceForIP:function (){
      //let values = PricePrettyView.getCurrenciesArray(this.getSellUSDPriceValues['avg'], 'USD' , this.getRates )
      return this.getPrettyPriceByCurrency( this.getSellUSDPriceValues['avg'] , this.getCurrentCurrency, 2);
    },
  },



  watch: {
    paginator:{
      handler(val){

        const updateRefPaginate = () => {
          let updated = false;
          if( this.$refs.paginate != null ){
            this.$refs.paginate.update(val);
            updated = true;
          }
          return updated;
        }
        if( !updateRefPaginate() ){
          this.$nextTick(() => {
            updateRefPaginate();
          })
        }
      },
      deep:true
    },
    'loading' (to) {
      if(!to) {
        this.setObserver();
      }
    },
    'isOpen'(to){
     if(to){
       $('body').css({"overflow":"hidden"})
       $('body').css({"position":"fixed"})
     }else {
       $("body").css({"overflow":"auto"});
       $('body').css({"position":"relative"})
     }
    }
  }
}
</script>

<style scoped>
.main-container {
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
}
.history-table {
  margin-left: 12px;
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
  overflow-x: auto;
}
.json-load{
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(30%, -12%);
  font-size: 14px;
}
.deals{
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
</style>