<template>
  <div class="create-ticket-backplate">
    <div class="create-ticket-form p-4 position-relative">
      <div class="create-ticket-form-close" @click="showCloseModalWindow" v-esc="showCloseModalWindow"><i class="fa fa-times"></i></div>

      <div class="form-group row" v-if="projectsCanCreate.length > 1">
        <label for="create-ticket-project" class="col-sm-1 col-form-label">Проект</label>
        <div class="col-sm-11">
          <select id="create-ticket-project" class="form-select" aria-label="Проект" v-model="selected.project" :disabled="isProductsLoading">
            <option disabled value="">Выберите проект</option>
            <option v-for="project in getProjects" :value="project._id">{{ project.name }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-if="selected.project && getProducts.length > 0">
        <label for="create-ticket-product" class="col-sm-1 col-form-label">Услуга</label>
        <div class="col-sm-11">
          <select id="create-ticket-product"
                  class="form-select"
                  :class="{'is-invalid': !isValidField('product')}"
                  aria-label="Услуга"
                  v-model="selected.product"
                  :disabled="!selected.project"
                  @change="!isValidField('product') ? validation('product') : null"
          >
            <option disabled value="">Выберите услугу</option>
            <option v-for="product in getProducts" :value="product._id">{{ product.name }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="create-ticket-from" class="col-sm-1 col-form-label">От</label>
        <div class="col-sm-11">
          <select id="create-ticket-from"
                  class="form-select"
                  :class="{'is-invalid': !isValidField('from')}"
                  aria-label="От"
                  v-model="selected.from"
                  :disabled="isFromAndToDisabled"
                  @change="!isValidField('from') ? validation('from') : null"
          >
            <option disabled value="">Выберете алиас</option>
            <option v-for="email in selectedProject?.emails ?? []" :value="email">{{ email }}</option>
          </select>
        </div>
      </div>

      <div class="form-group row">
        <label for="create-ticket-to" class="col-sm-1 col-form-label">Кому</label>
        <div class="col-sm-11">
          <input id="create-ticket-to"
                 type="text"
                 name="to"
                 v-model="selected.to"
                 placeholder="mail@example.com"
                 class="form-control search-input"
                 :class="{'is-invalid': !isValidField('to')}"
                 :disabled="isFromAndToDisabled"
                 @keyup="!isValidField('to') ? validation('to') : null"
                 @blur="validation('to')">
          <div class="invalid-feedback" :class="{'d-block': !isValidField('to')}">Некорректный email</div>
        </div>
      </div>

      <div class="create-ticket-found-tickets" v-if="getTicketList.length > 0 && !getForceCreate">
        <div class="table-scroll" :style="{'max-height': '300px'}">
          <table class="table table-bordered table-hover table-striped table-container">
            <thead class="thead-dark" style="z-index:20">
              <table-head-rows-projects :order-by="() => {}"></table-head-rows-projects>
            </thead>
            <tbody>
              <ticket-row v-for="ticket in getTicketList"
                          v-on:dblclick="showTicket(ticket)"
                          :id="'ticket-body-row-'+ ticket._id"
                          :ref="'ticket-row-' + ticket._id"
                          :ticket="ticket"
              ></ticket-row>
            </tbody>
          </table>
        </div>
        <paginate id="ticket-found-paginate"
                  ref="paginate"
                  settings-name="tickets"
                  @changePage="searchTickets($event)"
        ></paginate>
      </div>

      <div class="form-group row" v-show="getTicketList.length === 0 || getForceCreate">
        <label for="create-ticket-subject" class="col-sm-1 col-form-label">Тема</label>
        <div class="col-sm-11">
          <input id="create-ticket-subject"
                 :disabled="isSubjectAndBodyDisabled"
                 type="text"
                 v-model="selected.subject"
                 class="form-control"
                 name="subject"
                 :class="{'is-invalid': !isValidField('subject')}"
          >
        </div>
      </div>

      <div class="form-group row mb-0" v-if="(getTicketList.length === 0 || getForceCreate) && selected.project">
        <div class="col-12 d-flex">
          <div class="p-1 small attachment-list">
            <div v-for="(file, index) in files" class="px-1" :class="{uploading: file.process, 'text-muted': file.process}">
              <a :href="file.link" target="_blank" v-if="file.link">
                {{file.name}} ({{getFileSize(file)}})
              </a>
              <span v-else>
                {{file.name}} ({{getFileSize(file)}})
              </span>
              <i role="button" class="fa fa-close" @click="removeAttachment(index)" v-if="file.link"></i>
            </div>
          </div>
          <div class="d-inline-flex ms-auto">
            <div class="me-2 language-select-container">
              <span class="me-1">Язык:</span>
              <editable-select
                  :options="{RU: 'Русский', EN: 'Английский'}"
                  :selected="this.selected.lang ?? null"
                  :callback="changeLanguage"
              ></editable-select>
            </div>
            <div>
              <label for="attachment-input-new-ticket" class="btn btn-sm btn-link" :class="{disabled: upload}">
                Вложить
              </label>
              <input type="file" class="d-none" id="attachment-input-new-ticket" multiple @change="addAttachment">
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row" v-if="getTicketList.length === 0 || getForceCreate" ref="textarea">
        <div class="col-12">
          <expandable-text-area :aria-class="{'form-control':true, 'disabled': true, 'with-signature': showSignature, 'is-invalid': !isValidField('message')}"
                                id="greeting-template"
                                v-model="selected.message"
                                placeholder="Здравствуйте,"
                                :max-height="maxHeight"
                                :start-min-height="300"
                                :disabled="isSubjectAndBodyDisabled"
          ></expandable-text-area>
        </div>
        <div class="col-12" v-if="showSignature">
          <Loading loader="dots" v-model:active="loadingSignature" />
          <textarea
              class="form-control signature-control"
              ref="signatureTextarea"
              style="resize: none; overflow: hidden; padding-bottom: 10px;"
              :disabled="true"
          >{{ signature }}</textarea>
        </div>
      </div>

      <div class="form-group row" v-show="getTicketList.length === 0 || getForceCreate">
        <div class="col-12">
          <div @click="checkValidationIfDisabled">
            <popper :content="submitWarning" class="danger full-width" placement="top" :hover="true" :interactive="false" :arrow="true">
              <button type="button"
                      class="btn btn-primary w-100"
                      @click="sendTicket"
                      :disabled="submitDisabled"
              >Отправить</button>
            </popper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal-window v-if="showCloseModal" @close="showCloseModal = false" @success="closeForm" ok-button-style="btn-danger">
    <template v-slot:header>
      <h5 class="modal-title text-danger">Подтверждение действия</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите закрыть создание запроса? Введенные вами данные не сохранятся.</span>
    </template>
  </modal-window>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import TableHeadRowsProjects from "../List/projects/TableHeadRowsProjects";
import TicketRow from "../List/TicketRow";
import Paginate from "../../paginate/Paginate";
import {enableArrowKeysManagement} from "../List/ArrowScrolling";
import ExpandableTextArea from "../../common/textarea/ExpandableTextArea";
import {CrmApi} from "../../../library/CrmApi";
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import prettyBytes from "pretty-bytes";
import {Thread} from "../../../library/api/Thread";
import {Email} from "../../../library/Email";
import EditableSelect from "../../Editable/EditableSelect";

export default {
  name: "CreateTicketForm",

  components: {
    EditableSelect,
    ExpandableTextArea,
    TableHeadRowsProjects,
    TicketRow,
    Paginate,
    ModalWindow
  },

  data: function () {
    return {
      to: null,
      timer: null,
      selected: {
        project: '',
        product: '',
        from: null,
        to: '',
        subject: '',
        message: '',
        attachments: [],
        lang: null,
      },
      initialSelected: {
        project: '',
        product: '',
        from: null,
        to: '',
        subject: '',
        message: '',
      },
      _validation: {
        to: true,
        subject: true,
        message: true,
        product: true,
        from: true,
      },
      showCloseModal: false,
      maxHeight: 450,
      emailRegex: /^(([А-Яа-яёË\d\w"'_\-\.\s]+)\s<([^@]+)@([^>]+)>|([^@<\s]+)@([^>]+\.{1}[^>]+)$)/,
      files: [],
      upload: false,
      signature: null,
      loadingSignature: false,
      showSignature: false,
      api: new Thread(),
      greeting: null,
    }
  },

  methods: {
    validation(field) {
      switch(field) {
        case 'to':
          let value = this.selected[field].split(',');
          this._validation[field] = Boolean(value.every(email => Email.validateFullString(email)));
          break;
        case 'subject':
          this._validation[field] = typeof this.selected[field] === 'string' && this.selected[field].trim() !== '';
          break;
        case 'message':
          this._validation[field] = typeof this.selected[field] === 'string' && this.selected[field].trim() !== '';
          break;
        case 'product':
          this._validation[field] = typeof this.selected[field] === 'string' && this.selected[field].trim() !== '';
          break;
        case 'from':
          this._validation[field] = typeof this.selected[field] === 'string' && this.selected[field].trim() !== '';
          break;
      }
    },
    isValidField(field) {
      return this._validation[field] ?? true;
    },
    closeForm() {
      this.setShowCreateForm(false);
    },
    showCloseModalWindow() {
      if (this.inputsChanged) {
        this.showCloseModal = true;
      } else {
        this.closeForm();
      }
    },
    showTicket(ticket){
      if(!this.isTicketLoading){
        let routeName = (typeof ticket.type !== 'undefined' && ticket.type === 'notification') ? 'Notification' : 'Ticket';
        this.closeForm();
        this.$router.push({name: routeName,  params: { id: ticket._id } });
      }
    },
    sendTicket() {
      if (!this.getIsCreatingTicket) {
        this.setIsCreatingTicket(true);
        CrmApi.addTicket(this.selected).then((result) => {
          if (typeof result.data.ticket !== 'undefined') {
            let ticket = result.data.ticket;
            let routeName = (typeof ticket.type !== 'undefined' && ticket.type === 'notification') ? 'Notification' : 'Ticket';
            this.closeForm();
            if (this.getRoute?.name === 'Ticket' || this.getRoute?.name === 'Notification') {
              location.href = '/tickets/' + encodeURIComponent(window.getId(ticket._id));
            } else {
              this.$router.push({name: routeName, params: {id: ticket._id}, force: true});
            }
          }
        }).catch((message) => {
          this.$store.commit('errorPush' ,CrmApi.getErrorMessage(message), {root:true});
        }).finally(() => {
          this.setIsCreatingTicket(false);
        });
      }
    },
    addAttachment: function(e) {
      this.upload = true;
      let files = [ ...e.target.files ];
      for (let file of files){
        this.api.addNewTicketAttachments(this.selected.project, [file]).then((response) => {
          let attachments = response.data.attachments;
          attachments.forEach((attach) => {
            attach.name = attach.filename;
            attach.id = window.getId(attach.id);
            attach.link = '/attachment/' + attach.id + '/';
            if(typeof attach.date === 'undefined') {
              attach.date = moment(new Date()).format('YYYYMMDD');
            }
          });
          this.files =  this.files.concat(attachments);
          this.selected.attachments = this.selected.attachments.concat(attachments);
        }).catch((error) => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.upload = false;
          e.target.value = '';
        });
      }
    },
    getFileSize: function(file) {
      return prettyBytes(file.size);
    },
    removeAttachment: function(index) {
      let removeFunc = () => {
        this.selected.attachments = this.selected.attachments.filter(at => at.filename !== this.files[index].name);
        this.files.splice(index, 1);
      }
      if(typeof this.files[index].fromExistMessage !== 'undefined' && this.files[index].fromExistMessage === true) {
        // это вложение добавленное с помощью "создать как новое", оно удаляется только из списка аттачей в форме
        removeFunc();
      } else {
        this.files[index].process = true;
        this.files = this.files.concat();
        CrmApi.call('/thread/attachment/' + encodeURIComponent(this.files[index].id) + '/delete/').then(removeFunc).catch((error) => {
          delete this.files[index].process;
          if(error.response.status === 404 &&
              typeof error.response.data.code !== 'undefined' &&
              error.response.data.code === '404/2') {
            removeFunc();
          } else {
            this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
          }
        });
      }
    },
    changeLanguage(language) {
      this.selected.lang = language;
      this.loadSignature();
      this.processGreeting();
    },
    loadSignature() {
      if (this.selected.project && this.selected.from && this.selected.lang) {
        this.showSignature = false;
        let requestData = {
          alias: this.selected.from,
          lang: this.selected.lang,
        };
        this.api.getNewTicketSignature(this.selected.project, requestData).then(result => {
          if(
              requestData.alias === this.selected.from &&
              requestData.lang === this.selected.lang
          ) {
            if (typeof result?.data?.signature !== 'undefined' && result.data?.signature !== null) {
              this.signature = result?.data?.signature;
              this.showSignature = true;
            } else {
              this.showSignature = false;
            }
            this.calculateSignatureTextareaSize();
          }
        }).catch(error => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
        });
      }
    },

    processGreeting() {
      if (typeof this.selected.project !== 'undefined' && this.selected.project) {
        let project = this.getProjectById(this.selected.project);
        let name = '';
        let lang = this.selected?.lang ?? 'ru';
        let templateName = lang.toLowerCase() === 'en' ? 'greetingtemplate_en' : 'greetingtemplate';
        let greeting = '';
        if (project && typeof project[templateName] === 'string' && project[templateName]) {
          greeting = project[templateName];
          if (project[templateName].indexOf('{name}') > -1) {
            if (this.selected.to !== '') {
              name = Email.getFirstNameFromFullString(this.selected.to);
            }
            if (name) {
              greeting = greeting.replace('{name}', name);
            } else {
              greeting = greeting.replace(/\s?\{name\}\s?/, '');
            }
          }
        }
        if (this.selected.message.trim() === '' || this.selected.message.trim() === this.greeting.trim()) {
          this.selected.message = greeting;
        }
        this.greeting = greeting;
      }
    },

    calculateSignatureTextareaSize() {
      let block = this.$refs.signatureTextarea;
      if (block) {
        let scrollHeight = Math.round(block.scrollHeight);
        let clientHeight = Math.round(block.clientHeight);
        let height = clientHeight;
        if (scrollHeight >= clientHeight) {
          height = scrollHeight;
        }
        if (this.signature === '') {
          height = 40;
        }
        if (height < 40) {
          height = 40;
        }
        if (height > 250) {
          height = 250;
        }
        block.style.height = '';
        block.style.height = height + 'px';
      }
    },

    checkValidationIfDisabled() {
      if (this.submitDisabled) {
        ['to', 'subject', 'message', 'product', 'from'].forEach((value) => this.validation(value));
      }
    },

    ...mapActions({
      'selectFromBottomToTop':'tickets/selectFromBottomToTop',
      'selectFromTopToBottom':'tickets/selectFromTopToBottom',
      'addOneMoreFromBottomToTop':'tickets/addOneMoreFromBottomToTop',
      'addOneMoreFromTopToBottom':'tickets/addOneMoreFromTopToBottom',
      'searchTickets': 'tickets/create/searchTickets',
      "disableAutoUpdate":'tickets/disableAutoUpdate',
      "enableAutoUpdate":"tickets/enableAutoUpdate",
      "setAutoUpdateTicket": 'tickets/setAutoUpdateTicket',
      "updateProducts": 'tickets/create/updateProducts',
    }),
    ...mapMutations({
      'setShowCreateForm': 'tickets/create/setShowCreateForm',
      'setForceCreate': 'tickets/create/setForceCreate',
      "setSearchString": 'tickets/create/setSearchString',
      "setSelectedProjectName": 'tickets/create/setSelectedProject',
      "setLoading": 'tickets/create/setLoading',
      "setIsCreatingTicket": "tickets/create/setIsCreatingTicket",
    })
  },

  computed: {
    selectedProject: function () {
        return this.selected.project ? this.getProjectById(this.selected.project) : null;
    },

    productCheck: function () {
      return this.selected.product || this.getProducts.length === 0;
    },

    isFromAndToDisabled: function () {
      return !this.selected.project || this.isProductsLoading || !this.productCheck;
    },

    isSubjectAndBodyDisabled: function (){
      return this.inputsDisabled || this.isProductsLoading || !this.productCheck || !this.selected.project || !this.selected.from || !this.selected.to;
    },

    submitDisabled: function () {
      return !this.selected.project ||
          !this.productCheck ||
          !this.selected.from ||
          !this.selected.to ||
          !this.selected.subject ||
          !this.selected.message ||
          !this.selected.lang ||
          this.getIsCreatingTicket ||
          this.isProductsLoading ||
          this.inputsDisabled;
    },

    submitWarning() {
      let result = '';
      if (!this.selected.lang) {
        result = 'Необходимо выбрать язык переписки';
      }
      return result;
    },

    inputsChanged: function () {
      let result = false;
      for (const selectedKey in this.selected) {
        if (this.selected[selectedKey] !== this.initialSelected[selectedKey]) {
          result = true;
          break;
        }
      }
      return result;
    },

    forceCreate: {
      get(){
        return this.getForceCreate;
      },
      set(value){
        this.setForceCreate(value);
      },
    },
    ...mapState( {
      createTicketState: state => state.tickets.create,
    }),
    ...mapGetters({
      'currentProject': 'tickets/create/currentProject',
      'projectsCanCreate': 'tickets/create/projectsCanCreate',
      'getProjectByName': 'tickets/create/getProjectByName',
      'getProjectById': 'tickets/create/getProjectById',
      'getProjects': 'tickets/create/getProjects',
      'getTicketList': 'tickets/create/getTicketList',
      'isLoading': 'tickets/create/isLoading',
      'getForceCreate': 'tickets/create/getForceCreate',
      'inputsDisabled': 'tickets/create/inputsDisabled',
      'getPaginate': 'tickets/create/getPaginate',
      'getIsCreatingTicket': 'tickets/create/getIsCreatingTicket',
      'getTicket': 'thread/getTicket',
      'getRoute': 'getRoute',
      'getProducts': 'tickets/create/getProducts',
      'isProductsLoading': 'tickets/create/isProductsLoading',
    }),
  },

  watch: {
    'selected.project': function (to, from) {
      let project = this.getProjectById(to);
      if (project && typeof project.primarymail !== 'undefined') {
        if (!this.selected.from) {
          this.initialSelected.from = project.primarymail;
        }
        this.selected.from = project.primarymail;
        this.selected.lang = project.lang;
      }
      this.setSelectedProjectName(project.name);
      this.updateProducts();
      this.loadSignature();
      this.processGreeting();
      this.searchTickets({});
    },
    'getProducts': function () {
      if (!Array.isArray(this.getProducts) || this.getProducts.length === 0 || !this.getProducts.find((product) => window.getId(product._id) === this.selected.product)) {
        this.selected.product = '';
      }
    },
    'selected.from': function () {
      this.loadSignature();
    },
    'selected.subject': function () {
      this.validation('subject');
    },
    'selected.message': function () {
      this.validation('message');
    },
    'selected.to': function (to, from) {
      if (to.trim() !== from.trim()) {
        this.setSearchString(to);
        this.setLoading(true);
        let oldValue = to;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          if (
              oldValue === this.selected.to &&
              !this.getForceCreate
          ) {
            this.searchTickets({});
          }
        }, 500);
        this.processGreeting();
      }
    },
    'createTicketState.paginate': {
      handler(val){
        this.$nextTick(() => {
          if (this.$refs.paginate) {
            this.$refs.paginate.update({
              page: this.getPaginate.page,
              onpage: this.getPaginate.onpage,
              total: this.getPaginate.total,
              links: this.getPaginate.paginator_links
            });
          }
        });
      },
      deep: true
    },
    'getForceCreate': function (to, from) {
      if ( !to ) {
        this.searchTickets({});
      }
    }
  },

  mounted() {
    this.setSearchString(null);
    if (this.getRoute?.name === 'Ticket' && this.getTicket) {
      this.selected.project = window.getId(this.getTicket.project._id);
      this.selected.lang = this.getProjectByName(this.currentProject)?.lang;
    } else {
      if (this.currentProject && this.currentProject !== 'all') {
        this.selected.project = this.getProjectByName(this.currentProject)?._id;
        this.selected.lang = this.getProjectByName(this.currentProject)?.lang;
      }
      if (this.projectsCanCreate.length === 1) {
        this.selected.project = this.projectsCanCreate[0];
        this.selected.lang = this.getProjectByName(this.projectsCanCreate[0])?.lang;
      }
    }
    this.setSearchString('');
    this.initialSelected = Object.assign({}, this.selected);
    this.disableAutoUpdate();
  },

  beforeUnmount() {
    this.enableAutoUpdate();
    this.setAutoUpdateTicket(this.$route);
    enableArrowKeysManagement(this.selectFromBottomToTop, this.selectFromTopToBottom,
        this.addOneMoreFromBottomToTop, this.addOneMoreFromTopToBottom);
  }
}
</script>

<style scoped>
.col-form-label{
  text-align: right;
}
.create-ticket-form {
  background: #f8f8f8;
  border-radius: 5px;
  height: 100%;
}
.create-ticket-backplate{
  background-color: #cecece;
  border-radius: 5px;
  padding: 4px;
}
.create-ticket-form-close {
  cursor: pointer;
  padding: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
}

.create-ticket-form-close:hover {
  color: #ff3333;
}

.table-scroll {
  overflow: auto;
}
.attachment-list {
  display: flex;
  flex-wrap: wrap;
}
.uploading {
  cursor: wait;
}
.language-select-container {
  font-size: 14px;
  padding: 0.25rem;
  border: 1px solid transparent;
}
.full-width {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}
.danger {
  display: block !important;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
:deep(.with-signature) {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 0 !important;
}
.signature-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>