import {createRouter, createWebHistory} from "vue-router";


import moduleOfferEvents from "../../store/main/offerEvents/index";
import Events from "../../components/offerEvents/Events";




const routes =[
    {
        path:"/offers/events",
        name:"Events",
        components:{
            main:Events
        },
    },
]


const offerEventsRouter =  createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'
    }
)

offerEventsRouter.beforeEach((to, from, next) => {
    moduleOfferEvents.commit('SET_ROUTE' , to);
    moduleOfferEvents.dispatch('offerEvents/changeRoute', {from})
    next();
});

export default offerEventsRouter;