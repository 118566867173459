const actions = {
    goToTickets:function ({state , commit, dispatch, getters , rootGetters} , {content, withQuery = true, searchParams = false}){
        commit('tickets/create/setShowCreateForm', false, {root: true});
        if(!rootGetters['tickets/isTicketLoading']){
            let query1 = {};
            if(withQuery && rootGetters['leftMenu/getSelectionControl'].projectName + rootGetters['leftMenu/getSelectionControl'].filter
                ===    content.filter.project +  content.filter.type  ){
                query1 = rootGetters['tickets/filters/getFiltersQuery'];
            }

            if (searchParams) {
                if (content.filter.type === 'tasks') {
                    if (typeof searchParams['today'] !== 'undefined' && searchParams['today']) {
                        commit('tickets/filters/setType', 'current', {root: true});
                        delete query1['type'];
                    }
                    if (typeof searchParams['expired'] !== 'undefined' && searchParams['expired']) {
                        query1['type'] = 'expired';
                        commit('tickets/filters/setType', 'expired', {root: true});
                        delete searchParams['expired'];
                    }
                }
                if (!_.isEmpty(searchParams)) {
                    query1['searchParams'] = JSON.stringify(searchParams);
                    query1['searchAlias'] = content?.filter?.type ?? 'all';
                }
            }

            if( ! ['TicketsProjectFilter', 'Billmanager', 'Calls', 'Tasks'].includes(rootGetters.getRoute.name) ||
                content.filter.project +  content.filter.type  !== rootGetters.getRoute.params.project + rootGetters.getRoute.params.filter
            ){
                dispatch('tickets/reset', true, {root: true});
            }

            dispatch('chooseSelectionFilter' ,{filter:content.filter.type , projectName:content.filter.project });
            let routeName = "TicketsProjectFilter";
            if (content.filter.type === 'billmanager') routeName = 'Billmanager';
            if (content.filter.type === 'calls') routeName = 'Calls';
            if (content.filter.type === 'tasks') routeName = 'Tasks';
            window.router.push(
                {
                    name: routeName,
                    params: {
                        project: content.filter.project,
                        filter: content.filter.type
                    },
                    query:query1
                }
            )
        }
    },

    goToNextFolder({state , commit, dispatch, getters , rootGetters} ){
        let firstContent = null;
        let currentContent = null;
        let nothing = {}
        try {
            for (let project of getters.getTopLevel){
                if(typeof project.content !== 'undefined'){
                    for (let content of project.content){
                        if(firstContent === null){
                            firstContent = content;
                        }
                        if(currentContent !== null){

                            if(content.filter.type === 'unconfirmed' && ( (typeof content.isShow === 'undefined') || !content.isShow)){
                                continue;
                            }
                            dispatch('goToTickets' , {content:content})
                            throw nothing;
                        }
                        if(currentContent === null && (content.filter.type === getters.getSelectionControl.filter
                            && content.filter.project === getters.getSelectionControl.projectName) ){
                            currentContent = content;
                        }

                    }
                }
            }
        }catch (nothing){}

    },


    chooseSelectionFilter({state , commit, dispatch, getters , rootGetters} , { filter = null, projectName = null  }){
        commit('resetSelectionControl')
        commit('setSelectionFilter' , filter)
        commit('setSelectionProjectName' , projectName)
    },


    goToPrevFolder({state , commit, dispatch, getters , rootGetters} ){
        let firstContent = null;
        let prevContent = null;
        let nothing = {}
        try {
            for (let project of getters.getTopLevel){
                if(typeof project.content !== 'undefined'){
                    for (let content of project.content){
                        if(prevContent !== null && (content.filter.type === getters.getSelectionControl.filter
                            && content.filter.project === getters.getSelectionControl.projectName)){

                                dispatch('goToTickets' , {content:prevContent})
                                throw nothing;

                        }
                        if(content.filter.type === 'unconfirmed' && ( (typeof content.isShow === 'undefined') || !content.isShow)){
                            continue;
                        }

                        prevContent = content;



                    }
                }
            }
        }catch (nothing){}

    },


    openTabByTicket({state , commit, dispatch, getters , rootGetters} , {ticket}){
        if(typeof ticket.project !== 'undefined' && ticket.project !== null && typeof ticket.project.name !== 'undefined'){
            for (let projectInfo of getters.getTopLevel){
                if(projectInfo.name === ticket.project.name){
                    for (let content of projectInfo.content){

                    }
                }
            }
        }

        commit('setSelectionId' , 'answered_example.com')
    },


    chooseFilterInAdvance({dispatch} , {aliase , name , _id} ){

        if(typeof aliase !== 'undefined'){
            dispatch('chooseSelectionFilter' , {'filter':aliase , 'projectName':name})
        }
    }


}


export default actions;