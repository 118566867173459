<template>
  <span v-if="type" class="badge subnet-type-badge mx-1" :class="typeBadgeClass">{{type}}</span>

  <span v-else-if="userCanChange" class="d-flex type-select">
      [<editable-select
          :options="['PI', 'PA']"
          :selected="type"
          @change="(val) => { this.$emit('update', val); }"
      />]
  </span>
</template>

<script>
import EditableSelect from "../../../../../../Editable/EditableSelect";

export default {
  name: "EditType",

  components: {
    EditableSelect
  },

  emits: ['update'],

  props: {
    type: {
      default: null,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    typeBadgeClass() {
      let className = '';
      switch (this.type) {
        case 'PA':
          className = 'bg-primary';
          break;
        case 'PI':
          className = 'bg-warning';
          break;
      }
      return className;
    },
  }
}
</script>

<style scoped>
.type-select {
  margin-top: -1px;
  padding: 0 1px 0 2px;
}
.subnet-type-badge {
  margin: 2px 0;
  padding: 4px;
  height: 1.65em;
  max-width: 2.5em;
  min-width: 2.5em;
}
</style>