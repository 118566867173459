<template>
  <button :disabled="loading" v-if="isNeedSetOnAuction && showSetButton" @click="show = true"
          class="btn btn-light btn-outline-dark col-12"><i class="fa fa-shopping-cart" aria-hidden="true"></i> Выставить на продажу</button>
  <button :disabled="loading" v-if="!isNeedSetOnAuction" @click="show = true"
          class="btn btn-light btn-outline-dark col-12"><i class="fa fa-times" aria-hidden="true"></i> Снять с продажи</button>
  <teleport to="body">
    <set-lot-modal
        v-if="show"
        :subnet="subnetString"
        :value="isNeedSetOnAuction"
        @cancel="cancel"
        @confirm-all-subnets="saveSetLotAllSubnets"
        class="subnet-setlot-modal"
    ></set-lot-modal>
  </teleport>
</template>

<script>
import SetLotModal from "../Params/Product/Field/Subnet/SetLotModal";

import {AddToAuctionSetup} from "./AddToAuctionSetup";
export default {
  name: "AuctionMenu",
  components: {SetLotModal},
  props:{
    ticket: Object,
  },

  setup(props){
    return  AddToAuctionSetup(props);
  },

  computed: {
    showSetButton() {
      return (this.ticket?.assigned_product?.fields?.subnets ?? []).length > 0;
    }
  }

}
</script>

<style scoped>

</style>