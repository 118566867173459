<template>
  <div class="form-group row">
    <label for="input-group" class="col-sm-2 col-form-label">Группа</label>
    <div class="col-sm-10" style="margin-top: 5px;">
      <input
          v-model="groupInputValue"
          @focus="showGroups = true"
          @focusout="hideGroups"
          @input="getGroupsDelay"
          ref="groupInput"
          type="text" class="form-control" name="group" id="input-group" autocomplete="off">
    </div>
    <div class="list-group shadow template-groups" v-bind:class="{hide: !showGroups}" @click="$refs.groupInput.focus" ref="groupList">
      <div v-for="group in groups" tabindex="0" href="#" class="vbst-item list-group-item list-group-item-action template-group-item" @click="setGroup" v-on:keyup.enter="setGroup">{{group}}</div>
    </div>
  </div>

</template>

<script>
import {CrmApi} from "../../library/CrmApi";

export default {
  name: "TemplateFormGroup",

  data()  {
    return {
      searchTimeout: null,
      lastRequest: null,
      groups: [],
      groupInputValue: '',
      showGroups: false,

      wasCleared: false,
    }
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    'modelValue': {
      type:String,
      default:'',
    }
  },

  mounted: function() {
    this.$nextTick(function() {

      this.$refs.groupList.style.width = this.$refs.groupInput.offsetWidth + 'px';
      this.$refs.groupList.style.maxHeight = (this.$refs.groupInput.offsetHeight * 5) + 'px';
      this.getGroups();
      this.groupInputValue = this.modelValue;
    }.bind(this));
  },
  watch:{
    'groupInputValue'(to){
      this.$emit('update:modelValue', to);
    }
  },
  methods: {
    getGroups: function() {
      if(this.groupInputValue.length > 1 && (this.wasCleared || this.groupInputValue !== this.lastRequest)) {
        this.$data.lastRequest = this.groupInputValue;

        CrmApi.getTemplateGroups(this.groupInputValue).then((response) => {
          if(typeof response.data.data !== 'undefined') {
            this.groups = response.data.data
            this.showGroups = true;
          }
        });
      }
    },

    getGroupsDelay: function(e) {
      this.groupInputValue = e.target.value;
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.getGroups(e);
      }, 500);

    },

    setGroup: function(e) {
      this.groupInputValue = e.target.innerHTML;
      this.showGroups = false;
      this.$data.groups = [];
      this.wasCleared = true;
    },

    hideGroups: function(e) {
      if(e.relatedTarget === null ||
         typeof e.relatedTarget === 'undefined' ||
         typeof e.relatedTarget.classList === 'undefined' ||
         !e.relatedTarget.classList.contains('template-group-item')) {

        this.showGroups = false;
      }
    }
  }

}
</script>

<style scoped>
.hide {
  display: none;
}
.template-groups {
  overflow-y: auto;
  position: absolute;
  z-index: 999;
}
</style>