<template>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showModal"
          @close="showModalFunction(false)"
          @success="change"
          modal-dialog-class=""
      >
        <template v-slot:header>
          <h5 class="modal-title">{{ modalTitle }}</h5>
        </template>
        <template v-slot:body>
          {{ modalBodyText }}
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn" :class="{'btn-primary': !userActive, 'btn-danger': userActive}" @click="change">{{ submitBtnText }}</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import modalWindow from "../tiketMenu/components/ModalWindow";
import {getNameWithLogin} from "../../library/userName";

export default {
  name: "UserChangeStatusModal",

  components: {
    modalWindow
  },

  props: {
    user: {
      require: true,
    }
  },

  emits: [
    "close",
    "update",
  ],

  data: function () {
    return {
      showModal: true,
    }
  },

  computed: {
    userActive() {
      return this.user.status === 'active';
    },
    modalTitle() {
      return this.userActive ? 'Выключить пользователя' : 'Включить пользователя';
    },
    modalBodyText() {
      let name = getNameWithLogin(this.user);
      return this.userActive ? `Вы уверены, что хотите выключить пользователя ${name}?` : `Вы уверены, что хотите включить пользователя ${name}?`;
    },
    submitBtnText() {
      return this.userActive ? 'Выключить' : 'Включить';
    },
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
      if (!this.showModal) {
        this.$emit('close');
      }
    },

    change() {
      this.$emit('update');
    },
  }
}
</script>

<style scoped>

</style>