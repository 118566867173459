import {CrmApi} from "../CrmApi";


export {offersRates}

class offersRates {
    static getList(filters = undefined){
        let url ='/offers/rates';
        if(filters !== undefined && Object.keys(filters).length !== 0){
            url = url + "?" +  Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join("&");
        }

        return CrmApi.call(url);
    }
}