import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from './getters'

const state = {
    product: {
        name: "",
        project:{},
        templates:[],
        steps:[],
        tariffs:[],
        urls: {
            faq: ''
        }
    },
    oldStepOrders: [],
    loading: false,
    projects: {
        loading: false,
        list: []
    },
    haveNonSaveChanges:false,

    selectedStep:{
      currentStep:null,
      oldVersionsSteps:{},
    },
    stepForDelete:null,
}

export const moduleProduct = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters
}