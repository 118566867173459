<template>
  <div class="col-12" style="text-align: center">
    <div class="message message-event-blscan position-relative d-inline-block"
         @mouseover="showContextButton = true"
         @mouseout="showContextButton = false">
      <div v-show="showContextButton" class="contextFromButton">
        <context-button
            :custom-button-style="{
                'border-style': 'none',
                'background-color': 'transparent',
                'outline': 'none'
              }"
            :messageid="eventId"
        ></context-button>
      </div>
      <div class="col-12 datetime-stamp text-end">{{date}}</div>
      <div class="event-blscan-label">
        <span class="text-secondary" style="font-weight: bold;">
          BL-scan {{params?.network}}
        </span>
      </div>
      <div v-for="(value, system) in groupedData" class="bl-group">
        <div class="bl-group-header">
          <span class="text-danger" v-if="hasError(value, system)">Ошибка проверки: </span>
          {{system}} ({{ value.length }})
        </div>
        <ul class="bl-group-list mb-0">
          <li v-for="item in getPart(system, value)" class="bl-group-item">
            <a :href="getLink(system, item.ip)" target="_blank">{{ item.ip }}</a>
            <span v-if="(system + '').toLowerCase().indexOf('XBL'.toLowerCase()) !== -1">
              [<a :href="getLink('SBL', item.ip)">xbl</a>]
            </span>
          </li>
        </ul>
        <button
          type="button"
          v-if="value.length > 5 && collapsed[system] === true"
          @click="uncollapse(system)"
          class="btn btn-outline-primary btn-sm mt-1"
        >и еще {{ value.length - 4 }} IP ...</button>
        <button
          type="button"
          v-if="value.length > 5 && collapsed[system] === false"
          @click="collapse(system)"
          class="btn btn-outline-primary btn-sm mt-1"
        >свернуть</button>
      </div>
    </div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import ContextButton from "../Message/ContextMenu/ContextButton";

export default {
  name: "BlScan",

  components: {
    ContextButton,
  },

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  mounted() {
    for (let system in this.groupedData) {
      this.collapsed[system] = this.groupedData[system].length > 5;
    }
  },

  data: function () {
    return {
      showContextButton: false,
      collapsed: {},
      converter: require('ip-subnet-calculator'),
    }
  },

  computed: {
    groupedData() {
      let result = {};
      if (typeof this.params?.data === 'object' && Array.isArray(this.params?.data)) {
        let items = [...this.params.data].sort((a, b) => {
          return this.converter.toDecimal(a.ip) - this.converter.toDecimal(b.ip);
        });
        for (let item of items) {
          let system = item.system ?? '';
          if (typeof result[system] === 'undefined') {
            result[system] = [];
          }
          result[system].push(item);
        }
      }
      return result;
    },
  },

  methods: {
    getLink(system, ip) {
      let result = null;
      let address = encodeURIComponent(ip);
      let systemSearch = ((system ?? '') + '').toLowerCase();
      if (systemSearch.indexOf('SORBS'.toLowerCase()) !== -1) {
        result = 'http://www.sorbs.net/lookup.shtml?' + address;
      } else if (systemSearch.indexOf("BARRACUDA".toLowerCase()) !== -1) {
        result = 'http://www.barracudacentral.org/rbl/removal-request/' + address;
      } else if (systemSearch.indexOf("RKN".toLowerCase()) !== -1) {
        result = 'https://blocklist.rkn.gov.ru/?searchstring=' + address;
      } else if (systemSearch.indexOf("XBL".toLowerCase()) !== -1) {
        result = 'https://www.abuseat.org/lookup.cgi?ip=' + address;
      } else if (systemSearch.indexOf("SBL".toLowerCase()) !== -1 || systemSearch.indexOf("CSS".toLowerCase()) !== -1) {
        result = 'https://www.spamhaus.org/query/ip/' + address;
      }
      return result;
    },

    getPart(system, data) {
      return this.collapsed[system] === true ?
          data.slice(0, 4) :
          data;
    },

    uncollapse(system) {
      this.collapsed[system] = false;
    },

    collapse(system) {
      this.collapsed[system] = true;
    },

    hasError(data, name) {
      let result = false;
      if (name.indexOf('DNS query ERROR') > -1) {
        result = true;
      }
      return result;
    },
  },
}
</script>

<style scoped>
.message-event-blscan {
  text-align: left;
  background: #feeaea;
  width: 75%;
  border-radius: 20px;
}
.event-blscan-label {
  margin: 8px 0 16px;
}
.bl-group {
  margin-bottom: 16px;
}
.bl-group-header {
  font-weight: bold;
  margin-bottom: 4px;
}
.contextFromButton {
  right: 3px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>