
/**
 * Парсит айди из ссылки на тикет, возвращает false в случае если строка не подходит под формат
 * @param string
 * @returns {string|boolean}
 */
export function parseTicketId(string) {
    if(typeof string === 'string' && string.indexOf('tickets/') !== -1) {
        string = string.substr(string.indexOf('tickets/') + 8, 24);
    }

    return (typeof string === 'string' && string.length === 24) ? string : false;
}

export function getId(id) {
    return typeof id?.$oid !== 'undefined' ? id.$oid : id;
}

export function isId(id) {
    let isId = false;
    if(typeof id === 'string' && id.length === 24) {
        isId = true;
    }
    if(typeof id === 'object' && typeof id?.$oid !== 'undefined') {
        isId = true;
    }
    return isId;
}

export function getUniqueId(long = false) {
    let random = long ?
        Math.random().toString(36).substring(2) + Math.random().toString(36).substring(2) :
        Math.random().toString(36).substring(2);
    return Date.now().toString(36) + random;
}