<template>
  <div class="pt-5 col-md-6 m-auto">
    <div>
      <Loading loader="dots" :is-full-page="false" v-model:active="loading" />
    </div>
    <form  method="post" @submit.prevent="onSubmit()">
      <div class="form-group row">
        <label for="filterName" class="col-sm-2 col-form-label">Имя фильтра</label>
        <div class="col-sm-10">
          <input type="text" class="form-control "  v-model="filter.name" id="filterName"  placeholder="Введите имя фильтра">
        </div>
      </div>
      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-sm-2 pt-0">Тип</legend>
          <div class="col-sm-10">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="type" id="typeOr" v-model="filter.type" value="or">
              <label class="form-check-label" for="typeOr">
                Выполнение любого из условий
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="type" id="typeAnd" v-model="filter.type" value="and" >
              <label class="form-check-label" for="typeAnd">
                Выполнение всех условий
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <condition-select :conditions="filter.conditions"></condition-select>
      <div style="margin-bottom: 5px;margin-top: 15px;">
        <span>Производить следующие действия:</span>
      </div>
      <action-select :actions="filter.actions"></action-select>
      <div class="double-buttons-group">
        <div class="p-0">
          <button @click="cancel()" type="button" class="btn btn-secondary">Отменить</button>
        </div>
        <div class="p-0">
          <button type="submit" :disabled="isDisabled || loading" class="btn btn-primary">{{createButtonText}}</button>
        </div>
      </div>
    </form>
  </div>
  <alert-list />
</template>

<script>
import ConditionSelect from "./sources/ConditionSelect";
import ActionSelect from "./sources/ActionSelect";
import {CrmApi} from "../../library/CrmApi";
import AlertList from "../ticket/Alert/AlertList";

export default {
  name: "FilterNewEdit",
  components: {
    ActionSelect,
    'condition-select':ConditionSelect,
    'action-select':ActionSelect,
    AlertList,
  },
  data:function (){
    return {
      loading:false,
      isDisable:true,
      filter:{
        name:"",
        type:'or',
        conditions:[
          {
            target:"headers.subject",
            type:"~",
            value:"",
          }
        ],
        actions:[
          {
            type: "favourites",
            params:[

            ]
          }
        ],
      },
    }
  },
  props:{
    'filterEdit': {
      type:Object,
      default:null,
    }
  },
  methods:{
    onSubmit(){
      this.loading = true;
      this.prepareFilter();
      if(this.filterEdit === null){
        CrmApi.addFilter(this.filter).then(()=>{
             window.location.replace( window.location.origin + "/filter/")
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          this.loading = false;
        });
      }else{
        CrmApi.editFilter(this.filter).then(()=>{
          this.$store.commit('successPush', 'Фильтр обновлен');
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.loading = false;
        });
      }
    },

    prepareFilter() {
      this.filter.conditions = this.filter.conditions.filter(cond => cond.value.length);
    },

    cancel(){
      window.location.replace( window.location.origin + "/filter/")
    }
  },
  mounted() {
    if(this.filterEdit !== null){
      this.filter = Object.assign({} , this.filterEdit);
    }

  },
  computed:{
    isDisabled(){
      let isDisabled = false;
      if(this.filter.conditions[0].value.trim() == ''){
        isDisabled = true;
      }
      if(!['delete', 'favourites', 'disable_notify'].includes(this.filter.actions[0].type) && this.filter.actions[0].params.length == 0){
        isDisabled = true;
      }
      return isDisabled;
    },

    createButtonText(){
      let text = 'Создать';
      if(this.filterEdit !== null){
         text = 'Изменить';
      }
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'filters';
</style>