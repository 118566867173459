<template>
  <div v-if="message" class="alert" :class="'alert-' + type" role="alert" v-html="message"></div>
</template>

<script>
export default {
  name: "Alert",

  data: function() {
    return {
      message: null,
      type: null,
    }
  },

  methods: {
    show: function(message, type, duration = 4000) {
      this.message = message;
      this.type = type;

      if(duration > 0) {
        setTimeout(() => {
          this.message = null;
          this.type = null;
        }, duration)
      }
    },
    hide: function() {
      this.message = null;
      this.type = null;
    }
  }
}
</script>

<style scoped>

</style>