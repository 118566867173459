<template>

    <div style="position: relative"  @mouseover="showContextButton = true"  @mouseout="showContextButton = false"   :class="fromclass"  >
      <div class="datetime-stamp" >{{messageDate}}</div>
      <div>
        <div style="white-space: nowrap; display: flex; justify-content: space-between;">
          <div class="sender-block info" >
            <slot name="phone">
              <div>
                Телефон: {{prettyPhone}}
              </div>
            </slot>
            <slot name="who">
              <div v-if="isHasAnswerOperator">
                Ответил:
                <popper v-if="prettyPhoneTo" :content="prettyPhoneTo" class="light" placement="top" :arrow="true" :hover="true" :interactive="false" >
                  <span>{{answerOperator}}</span>
                </popper>
                <span v-else>{{ answerOperator }}</span>
              </div>
              <div v-else>
                На номер: {{prettyPhoneTo}}
              </div>
            </slot>
            <slot name="status">
              <div v-if="isHaveHeader" style="display: flex">
                <span style="padding-right: 3px;">Статус:</span><CallStatusInfo :message="message"
                                                                                @play="(fileLink) => $emit('play', fileLink)"
                                                                                :showCallbackExpandedStatus="true"
                                                                                :hasPermission="hasPermission" />
              </div>
            </slot>
          </div>
          <slot name="call">
          <div class="sender-block" style="margin-right: 20px;" >
            Входящий звонок
          </div>
          </slot>
        </div>
        <div v-show="showContextButton" class="contextFromButton" style="">
          <ContextButton
              :messageid="message._id"
              :custom-button-style="{
               'border-style':'none',
               'background-color': bc,
               'outline':'none'
                }"
              :show="contextButtons"
              :callbacks="{setCallback: setCallback, moveMessages: moveMessages}"
              :left-align="contextMenuLeftAlign"
          ></ContextButton>
        </div>
      </div>
        <div style="padding-top: 5px" v-if="message?.body">
          <call-body :body="message?.body"></call-body>
        </div>
      </div>

</template>

<script>
import {getDateFormat, getMarkdown} from "../../Api/api";
import {Phone} from "../../../../library/Phone";
import CallStatusInfo from "./CallStatusInfo";
import ContextButton from "../../Message/ContextMenu/ContextButton";

import CallBody from "./CallBody";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  name: "IncomingCall",

  emits: ['play'],
  components: {CallBody,  ContextButton, CallStatusInfo},
  data:function (){
    return {
      showContextButton: false,
    }
  },
  props:{
    message:{
      type:Object,
      require:true,
    },
    fromclass:{
      type:String,
      default:'message message-from message-left',
    },
    bc:{
      default: '#eef8ff',
    },
    contextMenuLeftAlign:{
      type:Boolean,
      default: false,
    },
    hasPermission: {
      type: Boolean,
      default: false,
    },
    changePermission: Boolean,
  },
  computed:{
    body(){
      if(typeof this.message.body === 'undefined' || this.message.body === null){
        return '';
      }
      return getMarkdown(this.message.body , false);
    },
    messageDate: function (){
      return getDateFormat(this.message.created , window.dateFormatWeekDaySeconds);
    },
    prettyPhone:function (){
      return typeof this.message.displayfrom !== 'undefined' ? Phone.makePretty(this.message.displayfrom) : 'Скрыт';
    },
    prettyPhoneTo:function (){
      return typeof this.message?.headers?.number ?
          Phone.makePretty(this.message?.headers?.number) + (this.message?.headers?.code ?"#" + this.message?.headers?.code : "" ) :
          'Скрыт';
    },

    isHaveHeader:function (){
      return typeof this.message.headers !== 'undefined' && this.message.headers !== null
          && this.message.headers.length !== 0;
    },
    isHasAnswerOperator:function (){
      let isHasAnswerOperator = false;
      if(typeof this.message?.headers?.operator?.name !== 'undefined' && this.message?.headers?.operator?.name !== null){
        isHasAnswerOperator = true;
      }
      return isHasAnswerOperator;
    },
    answerOperator:function (){
      let slug = '';
      if(typeof this.message?.headers?.operator?.slugList[0] !== 'undefined'){
        slug = " (" + this.message.headers.operator.slugList[0].toLowerCase() + ")";
      }
      return  this.message.headers.operator.name + slug
    },
    isCallMissed() {
      return (typeof this.message?.author === 'undefined' || !this.message.author) &&
          typeof this.message?.headers?.operator === 'undefined' &&
          (typeof this.message?.headers?.answered_operators === 'undefined' || this.message?.headers?.answered_operators.length === 0);
    },
    contextButtons() {
      let buttons = [];

      if (this.changePermission && this.ticket?.deleted !== true) {
        buttons.push('moveMessages');
      }
      if(this.isCallMissed && this.message.headers?.callback !== true) {
        buttons.push('setCallback');
      }

      return buttons;
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    }
  },

  methods: {
    moveMessages() {
      this.$emit('moveMessages', this.message);
    },

    setCallback() {
      CrmApi.setCallbackCall([window.getId(this.message._id)])
            .then(() => {
              this.$store.commit('thread/reloadMessage', window.getId(this.message._id));
              this.$store.commit('successPush', 'Звонок успешно помечен отвеченным');
            })
            .catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));
    }
  }
}
</script>

<style scoped>
.info{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
</style>