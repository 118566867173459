<template>
  <div class="relation-table border">
    <table class="table table-bordered thread-person-relation-table table-striped">
      <thead class="sticky">
        <tr>
          <th :colspan="2" scope="col" class="text-center">Доменные контакты</th>
        </tr>
        <tr>
          <th>ID</th>
          <th>Наименование</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="relation in sortRelations">
          <td><a :href="relation.link" target="_blank">{{relation.id}}</a></td>
          <td>{{relation.title}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {sort} from "../Relation";

export default {
  name: "DomainContact",

  props: {
    relations: {}
  },

  computed: {
    sortRelations: function() { return sort(this.relations); },
  },
}
</script>

<style scoped>
</style>