

const offerGetters = {
    getFilters:(state  , getters , rootState , rootGetter) =>{
        return rootGetter.getRoute.query;
    },

    getOffersList: (state, commit, dispatch, getters, rootGetters, rootState) => {
        return state.offers.list;
    },
    getPersonsList: (state) => {
        return state.persons.list;
    },
    getPersonFromTicket: (state) => (ticket) => {
        return typeof ticket.person._id !== 'undefined' ? state.persons.list.get(window.getId(ticket.person._id)) : null;
    },
    getLoading:(state)=>{
        return state.loading;
    },
    getPaginate:(state) => {
        return state.paginator;
    },
    getSteps: (state, getters, rootState, rootGetter) => {
        return state.steps[rootGetter.getRoute.name] ?? [];
    },
    getSearchMeta: (state) => {
        return state.searchMeta;
    },
    getChangeRights: (state) => {
        return state.changeRights;
    },
    getSetLotRights: (state) => {
        return state.setLotRights;
    },
    getCurrentCurrency:(state)=>{
        return state.currentCurrency;
    },
    getTotal:(state)=>{
        return state.paginator.total;
    },
    getOrderByField: (state) => {
        return state.orderByField;
    },
    getOrderByDirection: (state) => {
        return state.orderByDirection;
    },
}

export default offerGetters;