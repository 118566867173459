<template>
  <div class="col-12" style="text-align: center;" >
    <span class="message step" style="border-bottom-right-radius: 20px;"  >
      <div class=" col-12  datetime-stamp text-end">{{messageDate}}</div>
      <div class="sender-block ">
        <div class="row">
           <div class="col-sm-10" ref="subject"> {{message.subject}}</div>
           <div class="col-sm-2 text-end">Подсказка</div>
        </div>
      </div>
      <div  v-html="getMarkdown()" class="max-width" ref="html-message"></div>
    </span>
  </div>
</template>

<script>
import {getDateFormat, getMarkdown} from "./Api/api";
import {mapGetters} from "vuex";
import {baseHighlight} from "../../library/Functions";

export default {
  name: "StepHint",
  props:{
    'message':{
      type:Object,
      require:true,
    }
  },
  data:function (){
    return {

      loadImages:false,
    }
  },

  mounted() {
    this.highlight();
  },

  methods:{
    highlight() {
      if (this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        this.$nextTick(() => {
          baseHighlight(
              $(this.$refs['html-message']),
              this.searchString
          );
          baseHighlight(
              $(this.$refs['subject']),
              this.searchString
          );
        });
      }
    },
    getMarkdown:function (){
      return getMarkdown(this.message.body , this.loadImages);
    }
  },
  computed:{
    messageDate: function (){
      return getDateFormat(this.message.created, window.dateFormatWeekDaySeconds);
    },

    ...mapGetters({
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },


}
</script>

<style scoped>
.step{
  width: 90%;
  text-align: left;
  background: #eaffed;
  border-radius: 20px 20px 0 20px;
  padding-bottom: 0px;
}

</style>