<template>
<div class="col-12 thread-form" id="answer-form" style="display: flex;flex-wrap: nowrap;flex-direction: column;" >
  <div ref="comment-attachments-block" style="margin-bottom: 5px;" >
    <template-select ref="comment-attachments" :ticketId="ticketId" type="comment"></template-select>
  </div>
  <div ref="comment-quote-block">
    <quote :message="quoteMessage"></quote>
  </div>
  <div>
      <textarea
          ref="comment-textarea"
          v-model="comment"
          :class="{'cursor-wait': sending}"
          @keydown.enter.stop="sendAnswerByEnter"
          @keyup.ctrl.enter.exact.stop="send"

          :disabled="sending"
          class="form-control comment-form"
          style="resize: none;  overflow: hidden; padding-bottom: 10px;"
          @focus="onFocus"
          @focusout="$emit('textareaFocusOut')"
          @input="handleInput($event.target.value)"
      ></textarea>
  </div>
  <div ref="comment_button" style=" display: inline-flex;">
    <div class="col-6 " style="padding-right: 2px;">
      <button  style="margin-top: 5px; margin-bottom: 5px; " type="button" :class="{'cursor-wait': sending}"
               :disabled="sending" @click="cancel" class="btn btn-secondary full-wight">Отмена</button>
    </div>
    <div class="col-6" style="padding-left: 2px;">
      <button  style="margin-top: 5px; margin-bottom: 5px" type="button" :class="{'cursor-wait': sending}"
               :disabled="sending || !comment" @click="send"
               class="btn btn-success full-wight">Написать комментарий</button>
    </div>
  </div>
</div>
</template>

<script>
import TemplateSelect from "../TemplateSelect";
import Quote from "./Quote";
import {mapGetters} from "vuex";

export default {
  name: 'CommentForm',

  emits: ['send', 'cancel', 'resizeMessagesbox' , 'textareaFocusOut' , 'textareaFocus', 'textareaInput',],

  components: {
    Quote,
    'template-select': TemplateSelect
  },

  props: {
    ticketId: {
      require: true
    },

    draft: {
      default: null
    }
  },

  data: function() {
    return {
      comment: '',
      lastHeight: null,
      reduced: false,
      reduceHandler: null,
      sendByEnter:true,
    }
  },

  mounted: function() {
    this.sendByEnter = true;
    if(this.draft !== null) {
      if(this.comment === '') {
        this.comment = this.draft.comment;
      }
    }

    $(this.$refs['comment-textarea']).height(30);
    this.$nextTick(()=>{
      this.$refs['comment-textarea'].focus();
    })
  },

  unmounted() {
    document.removeEventListener('mousedown', this.reduceHandler);
  },

  computed: {
    sending: function () {
      return this.$parent.sending;
    },
    ...mapGetters({
      'quoteMessage':'thread/getCommentMessage'
    }),
  },

  methods: {
    sendAnswerByEnter:function(event){
      if(this.sendByEnter && event.metaKey && !event.altKey &&  !event.shiftKey && !event.ctrlKey){
        this.sendByEnter = false;
        this.send();
      }
    },
    handleInput(event){
      this.$emit('textareaInput');
    },
    onFocus: function() {
      this.$emit('textareaFocus');
    },

    cancel: function() {
      this.$store.commit('thread/setCommentMessage', null);
      this.$emit('cancel');
      },

    send: function() {
      let params = {};
      params.message = this.comment;
      if(this.$refs['comment-attachments'].savedAttachments.length) {
        params.attachments = this.$refs['comment-attachments'].savedAttachments;
      }
      let commentMessage =  this.$store.getters['thread/getCommentMessage'];
      if( commentMessage !== null){
        params.replyto = window.getId(commentMessage._id );
      }


      this.$emit('send', params);
      this.sendByEnter = true;
    },
  },

  watch: {
    '$parent.resize' (to) {
      if(!to && this.lastHeight !== null) {
        this.lastHeight = $(this.$refs['comment-textarea']).height();
      }
    }
  }
}
</script>

<style scoped>
.comment-form {
  background-color: #d8f9da;
}
.full-wight {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;

}
</style>