import vueEsc from './vue-esc'
let isEventAdd = false;
const plagin = {
    install (Vue) {
        Vue.directive('esc', {
            mounted(el, binding){
                vueEsc.update(el , binding)
            },
            beforeUnmount(el, binding) {
                vueEsc.unbind(el , binding)
            },
        });
        if(!isEventAdd){
            isEventAdd = true;
            document.addEventListener('keyup',  (event) =>{vueEsc.onEvent(event)});
            document.addEventListener('keydown',  (event) =>{vueEsc.downEven(event)});
        }
    }
}
export default plagin