import {createStore} from "vuex";
import {shareState} from "../common/composition";
import * as actions from "../common/actions";
import * as getters from "../common/getters";
import commonMutations from "../common/mutations";
import {moduleStatistic} from "./composition";

export default createStore({
    modules:{
        statistic: moduleStatistic,
    },
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    plugins: []
})
