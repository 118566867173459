<template>
<div>
  <div
      v-if="getBodyObject.yandex && this.getBodyObject.yandex.value != ''"
      style="padding-bottom: 10px"
      ref="yandex"
  >
    <hide-button
        :start-value="true"
        v-if="getBodyObject?.yandex"
        :text="this.getBodyObject.yandex.value"
        :text-to-button="'Yandex: ' + this.getBodyObject.yandex.text"
    ></hide-button>
  </div>
  <div
      v-if="getBodyObject?.tinkoff && this.getBodyObject.tinkoff.value != ''"
      ref="tinkoff"
  >
    <hide-button
        btn-name="Tinkoff"
        :text="this.getBodyObject.tinkoff.value"
        :text-to-button="'Tinkoff: ' + this.getBodyObject.tinkoff.text"
    ></hide-button>
  </div>
</div>
</template>

<script>
import HideButton from "../../../common/HideButton";
import {mapGetters} from "vuex";
import {baseHighlight} from "../../../../library/Functions";
export default {
  name: "CallBody",
  components: {HideButton},
  props:{
    'body':{
      type:String,
      default:true,
    }
  },

  mounted() {
    this.highlight();
  },

  computed:{
    getBodyObject(){
      let bodyObject = {};
      if(typeof this.body === "string"){

        let bodyArray = this.body.split("**");
        for (let i = 1; i < bodyArray.length - 1; i=i+2){
          if( bodyArray[i] != null && bodyArray[i+1] != null &&
              typeof bodyArray[i] !== "undefined" &&
              typeof bodyArray[i+1] !== "undefined"
          ){
            bodyObject[bodyArray[i]] = {};
            bodyArray[i+1] = bodyArray[i+1].trim();
            bodyArray[i+1] = bodyArray[i+1].replace(":", "");
            bodyObject[bodyArray[i]].text = bodyArray[i+1];
            bodyObject[bodyArray[i]].value = /* "**" + bodyArray[i].trim() + "**"+ */ bodyArray[i+1];
          }

        }

      }
      return bodyObject;
    },

    ...mapGetters({
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {
    highlight() {
      if (this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        this.$nextTick(() => {
          baseHighlight(
              $(this.$refs['yandex']),
              this.searchString,
              '.call-body-text'
          );
          baseHighlight(
              $(this.$refs['tinkoff']),
              this.searchString,
              '.call-body-text'
          );
        });
      }
    },
  }
}
</script>

<style scoped>

</style>