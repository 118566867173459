<template>
  <tr v-if="needShow" :class="{'border-bold':val == 'max'&& isAdminWithStatus }">
    <td class="text-left" :title="title" ><span :class="getClass()"  >{{name}}</span></td>
    <td class="text-center" :class="getClassMargin" >
      <div v-if="isAdmin">
        <span  style="margin-right: 5px">{{profit}}</span><span v-if="profitPercent">({{profitPercent}})</span>
      </div>
      <div v-else>
        <span v-if="profitPercent" >{{profitPercent}}</span>
        <span v-else>—</span>
      </div>
    </td>
    <td v-if="isAdminWithStatus" class="text-center" :class="getClassMargin" >
      <div  v-if="isAdmin" >
        <span  style="margin-right: 5px">{{margin}}</span><span v-if="marginPercent">({{marginPercent}})</span>
      </div>
      <div v-else>
        <span v-if="marginPercent" >{{marginPercent}}</span>
        <span v-else>—</span>
      </div>
    </td>

    <td v-if="isAdminWithStatus" class="text-center" :class="getClassIps">{{ips}}</td>
    <td v-if="isAdminWithStatus" class="text-center" :class="getClassOurBuy">{{ipsOurbuy}}</td>

  </tr>
</template>

<script>
import {mapGetters} from "vuex";
import {PricePrettyView} from "../../../library/PricePrettyView";
import moment from "moment";

export default {
  name: "KpiRow",
  props:{
    name:{
      type:String,
    },
    field:{ // firstQuarter  secondQuarter  thirdQuarter  fourthQuarter  years
      type:String,
    },
    val:{ // cur  avg  max
      type:String,
    },
    title:{
      type:String,
    },
    selectedYear:{
      type:String,
      default:null,
    },
    adminStatus:{
      type:Boolean,
      default: null
    },
  },
  data:function (){
    return {
      quarters:    [{
        'number': '1',
        'name': 'firstQuarter',
        'start': '01.01',
        'end': '01.04',
        },
        {
          'number': '2',
          'name': 'secondQuarter',
          'start': '01.04',
          'end': '01.07',
        },
        {
          'number': '3',
          'name': 'thirdQuarter',
          'start': '01.07',
          'end': '01.10',
        },
        {
          'number': '4',
          'name': 'fourthQuarter',
          'start': '01.10',
          'end': '01.01',
        }
      ]
    }
  },
  computed:{
    ...mapGetters({
      'kpi':'statistic/getKpi'
    }),
    needShow(){
      let needShow = true;
      if(this.val === 'cur'){
        let el = this.quarters.filter((item)=>{
            return item.name === this.field
        });
        if(el?.[0]?.start){
          let m = moment(el[0].start +"."+ this.selectedYear , "DD.MM.YYYY").unix();

          if(moment().unix() < m){
            needShow = false;
          }
        }

      }
      return needShow;
    },
    getClassMargin(){
      let classValue = this.getClass();
      if(this.val == 'cur'){
        let cur = null;
        if(this.kpi?.ourwo?.[this.field]?.sumMargin){
           cur =  Math.round(this.kpi?.ourwo?.[this.field]?.sumMargin)
        }
        let avg = null;
        if(this.kpi?.ourwo?.[this.field]?.avgSumMargin){
          avg = Math.round(this.kpi?.ourwo?.[this.field]?.avgSumMargin);
        }
        let max = null;
        if(this.kpi?.ourwo?.[this.field]?.maxSumMargin){
          max = Math.round(this.kpi?.ourwo?.[this.field]?.maxSumMargin);
        }
        if(cur !== null && avg !== null && cur >= avg){
          classValue = 'text-primary'
        }
        if(cur !== null && max !== null && cur >= max){
          classValue = 'text-danger'
        }
      }
      return classValue;
    },
    getClassIps (){
      let classValue = this.getClass();

      if(this.val == 'cur'){
        let cur = null;
        if(this.kpi?.all?.[this.field]?.sumIps){
          cur =  Math.round(this.kpi?.all?.[this.field]?.sumIps)
        }
        let avg = null;
        if(this.kpi?.all?.[this.field]?.avgSumIps){
          avg = Math.round(this.kpi?.all?.[this.field]?.avgSumIps);
        }
        let max = null;
        if(this.kpi?.all?.[this.field]?.maxSumIps){
          max = Math.round(this.kpi?.all?.[this.field]?.maxSumIps);
        }
        if(cur !== null && avg !== null && cur >= avg){
          classValue = 'text-primary'
        }
        if(cur !== null && max !== null && cur >= max){
          classValue = 'text-danger'
        }
      }
      return classValue;
    },
    getClassOurBuy(){
      let classValue = this.getClass();

      if(this.val == 'cur'){
        let cur = null;
        if(this.kpi?.ourbuy?.[this.field]?.sumIps){
          cur =  Math.round(this.kpi?.ourbuy?.[this.field]?.sumIps)
        }
        let avg = null;
        if(this.kpi?.ourbuy?.[this.field]?.avgSumIps){
          avg = Math.round(this.kpi?.ourbuy?.[this.field]?.avgSumIps);
        }
        let max = null;
        if(this.kpi?.ourbuy?.[this.field]?.maxSumIps){
          max = Math.round(this.kpi?.ourbuy?.[this.field]?.maxSumIps);
        }
        if(cur !== null && avg !== null && cur >= avg){
          classValue = 'text-primary'
        }
        if(cur !== null && max !== null && cur >= max){
          classValue = 'text-danger'
        }
      }
      return classValue;
    },
    ips:function (){
        let ips = this.kpi?.all?.[this.field]?.sumIps;
        if(this.val == 'avg'){
          ips = this.kpi?.all?.[this.field]?.avgSumIps;
        }
      if(this.val == 'max'){
        ips = this.kpi?.all?.[this.field]?.maxSumIps;
      }
        return this.getNumber(ips)
        //let ipspercent = this.kpi?.all?.[this.field]?.sumIpsPercent
    },
    ipsOurbuy:function (){
      let ips = this.kpi?.ourbuy?.[this.field]?.sumIps;
      if(this.val == 'avg'){
        ips = this.kpi?.ourbuy?.[this.field]?.avgSumIps;
      }
      if(this.val == 'max'){
        ips = this.kpi?.ourbuy?.[this.field]?.maxSumIps;
      }

      return this.getNumber(ips)
    },

    profit:function (){
      let profit = this.kpi?.all?.[this.field]?.sumProfit;

      if(this.val == 'avg'){
        profit = this.kpi?.all?.[this.field]?.avgSumProfit;

      }
      if(this.val == 'max'){
        profit = this.kpi?.all?.[this.field]?.maxSumProfit;

      }
      if(typeof profit === "undefined" || profit === null){
        return  '—';
      }
      return this.getPrettyPrice(profit);
    },
    profitPercent(){
      let profitPercent = this.kpi?.all?.[this.field]?.sumProfitPercent;

      if(this.val == 'avg') {
        profitPercent = this.kpi?.all?.[this.field]?.avgSumProfitPercent;
      }
      if(this.val == 'max') {
        profitPercent = this.kpi?.all?.[this.field]?.maxSumProfitPercent;
      }
      if(typeof profitPercent === "undefined" || profitPercent === null){
        return  null;
      }
      return this.getPercent(profitPercent);
    },
    margin:function (){
      let margin = this.kpi?.ourwo?.[this.field]?.sumMargin;

      if(this.val == 'avg'){
        margin = this.kpi?.ourwo?.[this.field]?.avgSumMargin;

      }
      if(this.val == 'max'){
        margin = this.kpi?.ourwo?.[this.field]?.maxSumMargin;

      }
      if(typeof margin === "undefined" || margin === null){
        return  '—';
      }
      return this.getPrettyPrice(margin);
    },
    marginPercent(){
      let marginPercent = this.kpi?.ourwo?.[this.field]?.sumMarginPercent;

      if(this.val == 'avg') {
        marginPercent = this.kpi?.ourwo?.[this.field]?.avgSumMarginPercent;
      }
      if(this.val == 'max') {
        marginPercent = this.kpi?.ourwo?.[this.field]?.maxSumMarginPercent;
      }
      if(typeof marginPercent === "undefined" || marginPercent === null){
        return  null;
      }
      return this.getPercent(marginPercent);
    },
    isAdmin:function (){
      return this.kpi.isAdmin;
    },
    isAdminWithStatus:function (){
      return this.adminStatus !== null ? this.adminStatus : this.kpi.isAdmin;
    },
  },
  methods:{
      getClass(){
        let classValue = '';
        if(this.val == 'cur'){
          classValue = 'text-success';
        }
        if(this.val == 'avg'){
          classValue = 'text-primary';
        }
        if(this.val == 'max'){
          classValue = 'text-danger';
        }
        return classValue;
      },
      getPrettyPrice(price){
        let pr = new PricePrettyView(price, 'USD')
        return pr.getPrettyPrice('—' , true, 0 , false);
      },
      getNumber(value){
        if(typeof value !== "undefined" && value !== null){
            let params = {maximumFractionDigits: 0}
            return  parseFloat(value).toLocaleString(undefined, params );
        }
        return '—';
      },
      getPercent(percent){
        return parseFloat(percent).toLocaleString(undefined,  {maximumFractionDigits: 0} )  +" %";
      }
  },
}
</script>

<style scoped>
.border-bold{
  border-bottom-width:thick;
}
</style>