<template>
<div class="d-flex" :class="{'align-items-center': subnetRangeParts.length > 0}">
  <span :class="spanClass" class="text-primary d-inline-flex" role="button"  @click="subnetClick">
    <div v-if="subnetRangeParts.length" class="subnet-ranges">
      {{ subnetRangeParts[0] }} -<br>
      {{ subnetRangeParts[1] }}
    </div>
    <string-trim :popper-placement="popperPlacement" v-else :string="subnetOne" />
  </span>
  <span v-if="type" class="badge subnet-type-badge ms-1" :class="typeBadgeClass" @click="toIpanelWhois"  >{{type}}</span>
  <teleport v-if="showEditModal"  to="body">
    <transition name="modal">
      <add-edit  :user-history-object="historyObject" @close="closeModal()" ></add-edit>
    </transition>
  </teleport>
</div>
</template>

<script>
import StringTrim from "../../string/StringTrim";
import AddEdit from "../Modal/AddEdit";
import {mapGetters} from "vuex";
export default {
  name: "HistorySubnet",
  components: {AddEdit, StringTrim},
  props:{
    historyObject:{},
    popperPlacement: {
      type: String,
      default: 'top',
    },
    subnet:{
      type:Object,
    },
    subnetClass:{
      type:String
    }
  },
  data:function (){
    return {
      showEditModal:false,
    }
  },
  computed:{
    ...mapGetters({
      'canChange':'offerHistory/canChange',
    }),
    isEditClientOpen:{
      set(value){
        this.$store.commit('offerHistory/setIsEditClientOpen', value)
      },
      get(){
        return this.$store.getters['offerHistory/isEditClientOpen']
      },
    },
    subnetOne:function (){
      return this.subnet?.subnet ??  '—';
    },
    type:function (){
      return this.subnet?.type ??  null;
    },
    typeBadgeClass: function() {
      let className = '';
      switch (this.type) {
        case 'PA':
          className = 'bg-primary';
          break;
        case 'PI':
          className = 'bg-warning';
          break;
      }
      return className;
    },
    spanClass() {
      let result = this.subnetClass;
      result += this.subnetRangeParts.length ? ' range-subnet' : ' single-subnet';
      return result;
    },

    subnetRangeParts() {
      return this.subnetOne.indexOf('-') > -1
          ? this.subnetOne.split('-').map(part => part.trim())
          : [];
    },

  },
  methods:{
    subnetClick() {
      if(this.subnet?.link) {
        window.open(this.subnet.link);
      } else {
        this.personEdit();
      }
    },

    personEdit () {
      if(this.canChange){

        if(! this.isEditClientOpen){

          this.isEditClientOpen= true;
          this.$router.push(
              {
                'name':'HistoryEdit',
                'params':{
                  'historyId':window.getId(this.historyObject._id),
                },
                'query':Object.assign({} , this.$route.query),
              }
          );

          this.showEditModal = true;
        }
      }

    },
    closeModal(){
      this.isEditClientOpen= false;
      this.showEditModal = false;
      $("body").css({"overflow": "auto"});

      this.$store.commit('offerHistory/setEditObjectId' , null);
      this.$store.commit('offerHistory/setNeedChangeRoute', false);
      this.$router.push(
          {
            'name':'History',
            'query':Object.assign({} , this.$route.query),
          }
      )
    },
    toIpanelWhois() {
        window.open('https://ipanel.i7.org/#/query/' + this.subnetOne.replace('/', '|'));
    },
    labelColor:function (type){
      return{
        'label-primary': type === 'PA',
        'label-warning': type !== 'PA',
      }
    },
  }
}
</script>

<style scoped>
.subnet-type-badge {
  margin: 0;
  padding: 4px;
  height: 18px;
  max-width: 2.5em;
  min-width: 2.5em;
}
.subnet-ranges {
  line-height: 1;
  /*margin-bottom: 2px;*/
  /*margin-top: 2px;*/
}
.single-subnet {
  line-height: 1;
  align-items: center;
}
</style>