import {Events} from "../../../library/api/Events";
import {CrmApi} from "../../../library/CrmApi";
import moment from "moment";


function  listByRows(list){

    let out = [];
    let i = 0;
    while ( i < list.length){
        let el = list[i];
        let rows = [];
        if(el?.rowspan){
            let r = el.rowspan
            for (let j = i; j < i + r; j++){
                el = list[j];
                rows.push(el)
            }
            i = i + r;
        }else {
            i++;
            rows.push(el)
        }
        out.push(rows)
    }

    return out;
}

function prepareList(list) {
    list.sort((a, b)=>{
        let comparison = 0;
        if (moment(a.date).unix() < moment(b.date).unix()) {
            comparison = 1;
        } else if (moment(a.date).unix() > moment(b.date).unix()) {
            comparison = -1;
        }
        return comparison;
    });

    let subnetHashMap = {};
    for (let el of list){
        if(typeof subnetHashMap[el.subnet] === 'undefined'){
            subnetHashMap[el.subnet] = [];
        }
        subnetHashMap[el.subnet].push(el);
    }
    let newList = {};
    for (let key in subnetHashMap){
        let elements = subnetHashMap[key];
        let currentTime = null;
        let dateArray = [];
        for (let el of elements){
            if(currentTime === null || moment(currentTime).subtract(10 ,"minutes").unix() > moment(el.date).unix()){
                if(dateArray.length > 0 && currentTime !== null){
                    let u = moment(currentTime).unix();
                    if(typeof newList[u] === 'undefined'){
                        newList[u]  = [];
                    }
                    dateArray[0].rowspan = dateArray.length;
                    newList[u].push(dateArray);
                    dateArray= [];
                }
                currentTime = el.date;
                el.key = window.getId(el._id);
                el.generalDate = currentTime;
                dateArray = [];
                dateArray.push(el)
            }else{
                el.generalDate = currentTime;
                el.key = window.getId(el._id);
                dateArray.push(el)
            }
        }
        if(dateArray.length > 0){
            let u = moment(dateArray[0].generalDate).unix();
            if(typeof newList[u] === 'undefined'){
                newList[u]  = [];
            }
            dateArray[0].rowspan = dateArray.length;
            newList[u].push(dateArray);
            dateArray = [];
        }
    }
    let out = [];

    for (let key of Object.keys(newList).sort(function(a, b) {
        return b - a;
    })){
        let objectArrays = newList[key];
        for (let el of objectArrays){
            for (let e of el){
                out.push(e);
            }
        }
    }


    return out;

}


const offerEventsActions = {

    init({state , commit, dispatch, getters , rootGetters, rootState}){
        let query = rootGetters.getRoute.query;

        if(typeof query.page !== 'undefined' && query.page !== null){
            commit('setPage', query.page);
        }
        if(typeof query.onpage !== 'undefined' && query.onpage !== null){
            commit('setOnPage', query.onpage);
        }
    },

    changeRoute({state , commit, dispatch, getters , rootGetters, rootState}, {from}){
        commit('setLoading' , true);
        commit('clearEvents')
        let oldQuery = Object.assign({} , getters.getFilters);
        dispatch('getEvents', {}).then((result)=>{
            let list = result.data.data;
            list = prepareList(list);
            commit('setListByRow' , listByRows(list))
            commit('setEvents', {events:list})
            dispatch('setPagination',result.data )
            commit('setLoading' , false);
        }).catch(()=>{
            commit('setLoading' , false);
        }).finally(()=>{

        })
    },




    getEvents({state , commit, dispatch, getters , rootGetters, rootState}, {}){
       return new Promise((resolve, reject)=>{
           let oldpaginator = Object.assign({}, state.paginator)
           if(typeof getters.getFilters.page !== 'undefined'){
               commit('setPage', getters.getFilters.page )
           }
           if(typeof getters.getFilters.onpage !== 'undefined'){
               commit('setOnPage', getters.getFilters.onpage )
           }else if(rootGetters.getUserSettings?.events?.onpage){
               getters.getFilters.onpage = rootGetters.getUserSettings.events.onpage;
               commit('setOnPage',rootGetters.getUserSettings.events.onpage )
           }
            Events.getList(getters.getFilters).then((result)=>{
                resolve(result);
            }).catch((error)=>{
                commit('errorPush' , CrmApi.getErrorMessage(error) , {root:true});
                dispatch('setPagination',oldpaginator)
                reject(error);
            })
        });
    },

    setPagination({commit} , {page, onpage, total, paginator_links}){
        commit('setPage' , page);
        commit('setTotal', total);
        commit('setOnPage', onpage);
        commit('setLinks', paginator_links)
    },
};


export default offerEventsActions ;
