<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{userPart}}
      <template v-if="hasSeller">
        <a :href="sellerTicketLink" target="_blank">{{ sellerTicketLabel }}</a>
        ({{params.seller.email}})
      </template>
      <template v-else>
        {{ sellerTicketLabel }}
      </template>
      для сделки
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "ResetSeller",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    userPart() {
      return this.user ? this.user + ' удалил(а)' : 'Удален';
    },
    sellerTicketLink() {
      return '/tickets/' + encodeURIComponent(window.getId(this.params.seller._id));
    },
    sellerTicketLabel() {
      return this.user ? 'продавца' : 'продавец';
    },
    hasSeller() {
      return typeof this.params.seller?._id !== 'undefined' && this.params.seller?._id;
    }
  }
}
</script>

<style scoped>

</style>