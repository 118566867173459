<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
        <tr class="no-shadow">
          <th
              data-bs-toggle="collapse"
              :aria-expanded="!collapsed"
              class="expandable"
              data-bs-target="#ticket-table-collapse"
              :colspan="4"
              scope="col">Запросы CRM ({{ relations.length }})</th>
        </tr>
      </thead>
    </table>

    <div class="relation-table collapse" id="ticket-table-collapse" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-1">
        <thead class="sticky">
          <tr>
            <th
                style="min-width: 175px;"
                class="sortable"
                :class="sortedClass('date')"
                @click="sortBy('date')">Дата</th>
            <th
                class="sortable"
                :class="sortedClass('name')"
                @click="sortBy('name')">Тема</th>
            <th
                class="sortable"
                style="min-width: 240px;"
                :class="sortedClass('client')"
                @click="sortBy('client')">Клиент</th>
            <th
                class="sortable"
                style="min-width: 100px;"
                :class="sortedClass('project')"
                @click="sortBy('project')">Проект</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td>{{formatDate(relation.date)}}</td>
            <td class="text-start">
              <a :href="relation.link" target="_blank" class="d-flex" style="text-decoration: none;">
                <string-trim v-if="relation.name" :string="relation.name" />
                <span v-else class="fst-italic">[Без темы]</span>
              </a>
            </td>
            <td class="client-td">{{relation.client}}</td>
            <td>{{relation.project}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import StringTrim from "../../../../../string/StringTrim";
import {sortFunctions} from "../Relation";

export default {
  name: "Ticket",

  components: {StringTrim},

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      sort: "date",
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {

      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    formatDate: function(date) {
      return moment(date).format('YYYY-MM-DD HH:mm')
    },
    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
.client-td {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>