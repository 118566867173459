<template>
  <div class="d-flex flex-column align-content-center">
    <div class="m-auto main-container" id="nav-menu">
      <div class="row">
        <div class="col-10">
          <nav-bar />
        </div>
        <div class="col-2" style="display: flex;align-items: flex-end;">
          <currency-bar style="width: 100%; text-align: right;"></currency-bar>
        </div>
      </div>
      <search-string />
    </div>

    <div class="offers-table flex-column align-items-center"   ref="offerTable">
      <offers-list />
    </div>
    <paginate style="margin-right: 15px" id="offer-paginate" @changePage="goToPage($event)"
              :update-by-object="true"
              :paginator="paginator"
              ref="paginate"
              settings-name="exchange"
    >
      <template v-slot:additional-menu>
          <span class="deals">
            <span >
              <span>{{ deal }}: <span v-price-clipboard="true">{{total}}</span>
              </span>
            </span>
          </span>
      </template>

    </paginate>
    <alert-list></alert-list>
  </div>
</template>

<script>
import NavBar from "./navigation/NavBar";
import SearchString from "./filters/SearchString";
import OffersList from "./table/OffersList";
import {mapActions, mapGetters} from "vuex";
import AlertList from "../ticket/Alert/AlertList";
import CurrencyBar from "./navigation/CurrencyBar";
import Paginate from "../paginate/Paginate";
export default {
  name: "OfferMain",
  components: {
    Paginate,
    CurrencyBar,
    AlertList,
    OffersList,
    SearchString,
    NavBar
  },
  mounted() {

    this.$router.isReady().then(()=>{
      if(this.$route.name === 'Offers'){
        this.$router.push({name:'Sell', query:this.$route.query});
      }
      this.initQuery();
    })

  },
  methods:{
    ...mapActions({
      'getOffers':'offer/getOffers',
      'initQuery':'offer/initQuery',
    }),
    goToPage:function ({page , onpage}){
      let q = Object.assign({} , this.$route.query);
      q.page = page;
      q.onpage = onpage;

      this.$router.push({
        name:this.$route.name,
        query:q,
      })
    },
  },

  computed: {
    deal:function (){
      let name = 'Сделок';
      if(this.$route.name === 'Buy'){
        name = 'Предложений';
      }
      return name;
    },
    ...mapGetters({
      loading: 'offer/getLoading',
      'paginator':'offer/getPaginate',
      'total':'offer/getTotal'
    }),
  },

  watch: {

    paginator:{
      handler(val){

        const updateRefPaginate = () => {
          let updated = false;
          if( this.$refs.paginate != null ){
            this.$refs.paginate.update(val);
            updated = true;
          }
          return updated;
        }
        if( !updateRefPaginate() ){
          this.$nextTick(() => {
            updateRefPaginate();
          })
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped>
.main-container {
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
}
.offers-table {
  margin-left: 12px;
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
  overflow-x: auto;
}
.deals{
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}
</style>