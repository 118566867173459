import {Document} from "../../../components/DocumentTemplates/Document";

export default {

    setDocument(state, document) {
        state.document = document instanceof Document
                            ? document
                            : new Document(document);
    },

    setSavedDocument(state, document) {
        state.document = document instanceof Document
                            ? document
                            : new Document(document);
        state.document.saved = true;
    },

    setProjects(state, projects) {
        state.projects = projects;
    },

    setHaveChanges(state , value){
        state.haveNonSaveChanges = value;
    },

    setLoading(state, loading) {
        state.loading = loading;
    }

}
