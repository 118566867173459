<template>
  <span v-if="keyword.shortable" class="input-group-text d-flex align-items-stretch">
    Сократить:
    <input type="checkbox" v-model="keyword.short" class="form-check-input ms-1" />
  </span>
</template>

<script>
export default {
  name: "Shortening",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>