<template>
  <span v-if="rir" class="badge bg-info rir-badge">{{ rir }}</span>
</template>

<script>
export default {
  name: "Rir",

  props: {
    rir: {
      type: String,
      default: '',
    }
  }
}
</script>

<style scoped>
.rir-badge {
  margin-left: 4px;
  font-size: 10px;
  line-height: 12px;
  padding: 4px;
  align-self: center;
}
</style>