import axios from "axios";
import {CrmApi} from "../../../library/CrmApi";



export function callPerson(ticketid, phone){
    return CrmApi.call("/thread/"+encodeURIComponent(ticketid)+"/person/call/"  +encodeURIComponent(phone), {});
}

export function hangUp(ticketid, callid){
    let promise = axios.delete(window.location.origin +'/ajax/thread/'+encodeURIComponent(ticketid)+'/person/call/'+ encodeURIComponent(callid) )
    promise.catch(CrmApi.sessionErrorHandler);
    return promise;
}

export const getCallInfoFromMessage = function (message){
    let callInfo = null;
    if(typeof message.headers !== 'undefined'){
        callInfo = message.headers;
    }
    return callInfo;
}

export const getCallInfoFromTicket = function (ticket , userId ){
    let callInfo = null;
    if(typeof ticket !== 'undefined' &&typeof ticket.calls !== 'undefined' ){
        for(let call of ticket.calls ){
            if(typeof call.from._id !=='undefined' && window.getId(call.from._id) === window.getId(userId)){
                callInfo = call;
                break;
            }
        }
    }
    return callInfo;
}



const STATE_START = "START"; // считаем 3 секунды до набора
const STATE_DIALING_NUMBER = "DIALING"; // набираем номер
const STATE_CALLING_DOWN = "DOWN"; // -  ожидает ответа оператора,
const STATE_CALLING_UP = "UP"; // идет разговор.
const STATE_CALLING_END = "END"; // разговор закончен
const STATE_OG_CLIENT_ANSWER = 'og_client_answer';
const STATE_OG_OPERATOR_ANSWER = 'og_operator_answer';

const CALL_STATES = {
    START:STATE_START,
    DIALING:STATE_DIALING_NUMBER,
    DOWN:STATE_CALLING_DOWN,
    UP:STATE_CALLING_UP,
    END:STATE_CALLING_END,
    OG_CLIENT_ANSWER:STATE_OG_CLIENT_ANSWER,
    OG_OPERATOR_ANSWER:STATE_OG_OPERATOR_ANSWER,
}

export function getStatus(status){
    if(typeof status === 'undefined' || status === null){
        return  null;
    }
    if(typeof CALL_STATES[status.toUpperCase()] === 'undefined'){
        return  status.toUpperCase();
    }
    return CALL_STATES[status.toUpperCase()];
}


export function getHintStatus(status, message){
    let hint = '';
    if(getStatus(status) === getStatus("start")){
        hint = 'Звонок начнется через:';
    }
    if(getStatus(status) === getStatus("DIALING")){
        hint = 'Набираем абоненту';
    }

    if(getStatus(status) === getStatus('down')  &&
        !isHasEvent(message,'og_client_answer') &&
        !isHasEvent(message,'og_operator_answer')){
        hint = 'Ожидаем ответа оператора';
    }
    if(isHasEvent(message,"og_operator_answer") && !isHasEvent(message,'og_client_answer')){
        hint = 'Ожидаем ответа клиента';
    }

    if(isHasEvent(message,'og_client_answer') ||
        ( getStatus(status) === getStatus("UP") && !isHasEvent(message,'og_client_answer') &&
            !isHasEvent(message,'og_operator_answer'))
    ) {
        hint = 'Идет разговор';
    }

    if(getStatus(status) === getStatus('end')){
        hint = 'Разговор завершен'
    }



    return hint;
}

export function isHasEvent(message , event){
    return typeof message?.headers?.event !=='undefined' && typeof message?.headers?.event[event.toLowerCase()] !== "undefined";
}