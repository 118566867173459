import {CrmApi} from "../CrmApi";

export {Task}
class Task {
    static createTask(params) {
        let url = '/tasks/new/';
        return CrmApi.call(url, params);
    }

    static editTask(id, params) {
        let url = '/tasks/' + encodeURIComponent(window.getId(id)) + '/';
        return CrmApi.call(url, params);
    }

    static deleteTasks(idsArray) {
        return CrmApi.call("/tasks/delete/", {messages: idsArray});
    }

    static loadTasks(id, params) {
        let url = '/thread/' + encodeURIComponent(window.getId(id)) + '/tasks/' +
            (typeof params !== 'undefined' && params !== null ? ("?"+ Object.keys(params).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&')) : "")
        return CrmApi.call(url);
    }
}