<template>
  <event-block :event-id="eventId" :date="date" :title="{left: 'Изменения ЕГРЮЛ', right: 'Изменение юр-адреса'}">
    <template #body>
      <span v-html="params.address"></span>
    </template>
  </event-block>
</template>

<script>
import parseEvent from "../EventParser";
import EventBlock from "../EventBlock";

export default {
  name: "AddressChange",
  components: {EventBlock},
  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event)
}
</script>

<style scoped>

</style>