<template>
  <span v-for="(item, key) in shownHeader" class="header-wrapper">
    <span
        v-if="item.name"
        :content="`${item.name} <${item.email}>`"
        copied="Имя и адрес скопированы"
        v-clipboard.byContent>{{item.name}}&nbsp;</span>
    <span :content="item.email" v-clipboard.byContent copied="Адрес скопирован">
      <template v-if="item.name">&lt;{{item.email}}&gt;</template>
      <template v-else>{{item.email}}</template>
    </span>
    <template v-if="key < (shownHeader.length - 1)">,&nbsp;</template>
  </span>
  <span v-if="showMorePart">&nbsp;(и еще {{ showMore }})</span>
</template>

<script>
export default {
  name: "HeaderEmailClipboard",

  props: {
    header: {
      type: String,
      require: true,
    },
    trim: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    parsedHeader() {
      return this.header.split(',').filter(email => email.trim() !== '').map(email => {
        email = email.trim();
        let match = email.match(/([^<]+)<(.*@.*)>/);
        if(match !== null) {
          email = {name: match[1].trim(), email: match[2].trim()};
        } else {
          email = email.replace(/(^<|>$)/g, '');
          email = {email: email};
        }
        return email;
      });
    },
    shownHeader() {
      let result = this.parsedHeader;
      if (this.showMorePart) {
        result = result.slice(0, 8);
      }
      return result;
    },
    showMorePart() {
      return this.parsedHeader.length > 10 && this.trim;
    },
    showMore() {
      return this.parsedHeader.length - 8;
    },
  }
}
</script>

<style scoped>

</style>