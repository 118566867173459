<template>
<span>
  <span v-if="!isNeedTrim">{{value}}</span>
  <string-trim v-else :string="value"></string-trim>
</span>
</template>

<script>
import StringTrim from "../string/StringTrim";
export default {
  name: "ValueOrTrim",
  components: {StringTrim},
  props:{
    isNeedTrim:{
      type:Boolean,
      default:false,
    },
    value:{
      type:String,
    }

  }
}
</script>

<style scoped>

</style>