<template>
  <div v-if="name" class="additional-info" :class="{'cursor-pointer': clickable}">
    <span :style="customStyle" class="line">{{name}}</span>
  </div>
</template>

<script>
export default {
  name: "ProjectLine",
  props:{
    project:{
      type: [Object, String],
      require:true,
    },
    customStyle:{
      type:Object,
      default:{},
    },
    clickable: {
      default: false,
    }
  },

  computed: {
    name: function() {
      return (typeof this.project === 'object' && this.project !== null) ? this.project.name : this.project;
    }
  }
}
</script>

<style scoped>
  .line{
    background-color: #7193f0;
    color: white;

    border-radius: 2px;
    padding:1px 3px;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>