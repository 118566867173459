<template>
<div v-if="phoneValue">
  <editable-phone

      v-model="phoneValue"
      @enter="updateAdditionalPhone"
      :is-call-permission="false"
      :phone="phoneObj"
      input-class="form-control form-control-sm"
      :update-model="false"
      ref="phone-input"
  />
  <span v-if="type " style="padding-left: 5px;  white-space: nowrap;">({{showType}})</span>
</div>
</template>

<script>
import Phone from "../../../tiketMenu/components/Client/Entity/Phone";
import EditablePhone from "../../../common/Phone/EditablePhone";
export default {
  name: "AdditionalPhone",
  emits:['editAdditionalPhone'],
  components: {EditablePhone, Phone},
  props:{
    phoneObj:{},
    phone:{},
    type:{},
    index:{},
  },
  computed:{
    phoneValue:{
      set(value){

      },
      get(){
        return this.phone;
      }
    },
    showType:function (){
      if(this.type == 'другой'){
        return 'др.';
      }
      return  this.type;
    }
  },
  methods:{
    updateAdditionalPhone:function (value){
      this.$emit('editAdditionalPhone' , {value:value , key:this.index, type:this.type})
    }
  }
}
</script>

<style scoped>

</style>