<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} подтвердил(а) участие клиента в аукционе {{subnets}}
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "Approve",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    subnets() {
      let result = '';
      if(
          this.params?.subnet ||
          (Array.isArray(this.params?.subnets) && this.params.subnets.length === 1)
      ) {
        result = 'по сети ' + (this.params?.subnet ?? this.params.subnets[0])
      } else {
        result = 'по сетям ' + this.params.subnets.join(', ');
      }

      return result;
    }
  }
}
</script>

<style scoped>

</style>