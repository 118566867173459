<template>
  <form @submit.prevent="save">
    <div class="mb-4">
      <h3 class="d-inline">Настройки</h3>
    </div>

    <alert ref="alert"></alert>

    <div class="mb-3">
      <label for="login" class="form-label">Логин</label>
      <input type="text" :value="user.login" class="form-control" name="login" id="login" disabled>
    </div>
    <div class="mb-3">
      <label for="name" class="form-label">ФИО</label>
      <popper content="ФИО для отображения в подписи" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <input type="text" v-model="user.name" class="form-control" name="name" id="name" placeholder="Фамилия И. О." disabled>
      </popper>
    </div>
    <div class="mb-3">
      <label for="phoneext" class="form-label">Добавочный номер</label>
      <popper content="Добавочный номер для прямого звонка" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
        <input type="text" v-model="user.phoneext"   class="form-control" name="phoneext" id="phoneext" placeholder="000" disabled>
      </popper>
    </div>
    <div class="mb-3">
      <div class="form-check mb-0">
        <input class="form-check-input" type="checkbox" v-model="disable_notify_unsorted" id="disable_notify_unsorted">
        <label class="form-check-label" for="disable_notify_unsorted">
          Отключить уведомления по неразобранным
        </label>
      </div>
    </div>
    <div class="accordion mt-4" v-if="user.projects.length">
      <p class="small text-muted">Здесь вы можете задать ФИО и добавочный номер, которые будут использоваться в выбранных проектах. Если поля оставить пустыми, в подписи будут отображаться данные из вашего профиля.</p>

      <draggable
          v-model="projects"
          item-key="order"
      >
        <template #item="{element}">
          <project-settings :user="user" :project="element"></project-settings>
        </template>
      </draggable>
    </div>

    <div class="double-buttons-group">
      <div>
        <a :href="this.backurl" class="btn btn-secondary col-6" v-bind:class="{disabled: saving}">Отмена</a>
      </div>
      <div>
        <button type="submit" class="btn btn-primary col-6" v-bind:class="{disabled: saving}">Сохранить</button>
      </div>
    </div>
  </form>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import ProjectSettings from "./ProjectSettings";
import Alert from "../alert/Alert";
import Draggable from "vuedraggable";

export default {
  name: "Settings",

  components: {
    'project-settings': ProjectSettings,
    'alert': Alert,
    Draggable
  },

  props: {
    user: {
      require: true
    },
    backurl: {
      require: true
    }
  },

  data: function() {
    return {
      saving: false,
      showNamePopper: false,
      showPhoneextPopper: false,
      projects: [],
    }
  },

  computed: {
    disable_notify_unsorted: {
      get() {
        return this.user?.settings?.disable_notify_unsorted ?? false;
      },
      set(value) {
        if(typeof this.user?.settings === 'undefined' || this.user?.settings === null) {
          this.user.settings = {}
        }
        this.user.settings.disable_notify_unsorted = value;
      }
    },
  },

  mounted() {
    let order = 1000;
    this.user.projects.forEach(project => {
      project.order =
          typeof this.user.projectsettings === 'object' &&
          typeof this.user.projectsettings != null &&
          typeof this.user.projectsettings[window.getId(project._id)] === 'object' &&
          this.user.projectsettings[window.getId(project._id)] != null
              ?
          this.user.projectsettings[window.getId(project._id)].order : order--;
    });
    this.user.projects.sort((a,b) => {
      if( a.order == b.order ){
        return 0;
      }
      return a.order < b.order ? 1 : -1;
    })

    this.projects = this.user.projects;
  },

  methods: {
    getProjectById: function(id){
      let foundProject = null;
      this.user.projects.forEach( project => {
        if( foundProject === null ) {
          let pId = window.getId(project._id);
          if (pId == id) {
            foundProject = project;
          }
        }
      });

      return foundProject;
    },

    save: function() {

      let order = 1000;
      this.projects.forEach(project => {
        this.user.projectsettings[window.getId(project._id)].order = order--;
      })

      CrmApi.updateUser(this.user).then(response => {
        if(typeof response.data._id !== 'undefined') {
          this.$refs.alert.show('Настройки сохранены', 'success');
          this.scrollTo('alert');
        } else {
          this.$refs.alert.show('Ошибка при сохранении настроек', 'danger');
        }
        this.saving = false;
      });
    },

    scrollTo: function(ref) {
      window.scrollTo(0, this.$refs[ref].offsetTop);
    }
  },

}
</script>

<style scoped>
.light {
  display: block !important;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>