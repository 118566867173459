<template>
  <tr>
    <td>
      <div class="d-flex ps-1">
        <div @mousemove="showMargin = true" @mouseleave="showMargin = false">
          <popper :show="showMargin" :arrow="true" zIndex="999" placement="top">
            <template v-if="row?.asn?.name">
              <a target="_blank" class="text-decoration-none" :class="boldClass" :href="'https://ipanel.i7.org/#/query/' + encodeURIComponent(row.asn.name)">{{row.asn.name}}</a>
            </template>
            <template v-else>
              <history-subnets
                  row-style="display: flex; align-items: center; margin: 4px 0;"
                  :history-object="row"
                  :popper-placement="popperPlacement"
                  :class-subnet="boldClass"
                  :subnets="subnets"
              />
            </template>
            <template #content>
              <margins :history-object="row" />
            </template>
          </popper>
        </div>
        <div v-if="row?.comment" class="position-relative" style="width: 14px;">
          <div style=" position: absolute; top: -5px; margin-left: 5px;">
            <popper :hover="true" class="light width-max-content" placement="right" :arrow="true" zIndex="999"  >
              <i class="fa fa-commenting-o" aria-hidden="true"></i>
              <template #content>
                <div class="text-break">{{row.comment}}</div>
              </template>
            </popper>
          </div>
        </div>
      </div>
    </td>

    <td>
      <i-ps v-if="!row?.asn?.name" :history-object="row" :ip-count="row.count ? row.count : '&mdash;'" />
      <span v-else>&mdash;</span>
    </td>

    <td>{{formattedDate}}</td>

    <td>
      <client type="seller" :client="row.seller" :history-object="row" :manager="row.sellmanager" />
    </td>

    <td>
      <client type="buyer" :client="row.buyer" :history-object="row" :manager="row.buymanager" />
    </td>

    <td>
      <nominal-price :without-currency-space="true"
                     :is-one-price="row?.asn?.name"
                     :class-price="boldClass"
                     :history-object-id="id"
                     name-field="buyprice"
                     :subnets="row.subnets"
                     :ip-count="row.count"
                     :buyprice="row.buyprice"
                     :popper-placement="pricePopperPlacement"
                     :rates="row.rates" />
    </td>

    <td>
      <nominal-price :without-currency-space="true"
                     :history-object-id="id"
                     name-field="sellprice"
                     :subnets="row.subnets"
                     :ip-count="row.count"
                     :buyprice="row.sellprice"
                     :rates="row.rates"
                     :popper-placement="pricePopperPlacement"
                     :is-one-price="row?.asn?.name" />
    </td>
  </tr>
</template>

<script>
import HistorySubnets from "../../../../../offerHistory/DataCells/HistorySubnets";
import Margins from "../../../../../offerHistory/DataCells/Margins";
import IPs from "../../../../../offerHistory/DataCells/IPs";
import Date from "../../../../../offerHistory/DataCells/Date";
import Client from "../../../../../offerHistory/DataCells/Client";
import NominalPrice from "../../../../../offerHistory/DataCells/NominalPrice";
import moment from "moment";

export default {
  name: "HistoryRow",

  components: {NominalPrice, Client, Date, IPs, HistorySubnets, Margins},

  props: {
    row: Object,
    isHistoryAdmin: Boolean,
  },

  data() {
    return {
      showMargin: false,
    }
  },

  computed: {
    subnets() {
      return this.row.subnets.map(subnet => {
        subnet.link = this.isHistoryAdmin
                          ? '/offers/history/' + encodeURIComponent (window.getId(this.row._id) )
                          : 'https://www2.whois7.ru/' + subnet.subnet + '#whois';
        return subnet;
      });
    },

    id() {
      return window.getId(this.row._id);
    },

    popperPlacement() {
      return this.showMargin ? 'bottom' : 'top';
    },

    boldClass() {
      return this.isOurSeller || this.isOurBuyer ? 'fw-bold' : '';
    },

    isOurSeller() {
      return this.row?.seller?.isour ?? false;
    },

    isOurBuyer() {
      return this.row?.buyer?.isour ?? false;
    },

    formattedDate() {
      return moment(this.row.date).format('DD.MM.YYYY')
    },

    pricePopperPlacement() {
      return window.innerWidth <= 1400 ? 'left': 'top';
    },
  },

}
</script>

<style scoped>
tr {
  text-align: center;
  vertical-align: top;
}
:deep(.width-max-content .popper) {
  width: max-content;
}
</style>