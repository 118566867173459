import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from './getters'
import {Document} from "../../../components/DocumentTemplates/Document";

const state = {
    document: new Document(),
    projects: {
        loading: false,
        list: []
    },
    loading: false,
    haveNonSaveChanges:false,

    // поля клиента (persons.*)
    personOptions: {
        'company.ripe.org': 'ORG',
        'company.ripe.lirid': 'LIRid',
    },
    // поля клиента (persons.*) и компании (company.*; только те, которые начинаются с company.)
    commonOptions: {
        name: 'Имя',
        'contact_emails.mail': 'Email',
        'phone.number': 'Телефон',
        'company.name': 'Сокращенное название компании',
        'company.fullname': 'Полное название компании',
        'company.vatnum': 'ИНН',
        'company.ogrn': 'ОГРН',
        'company.kpp': 'КПП',
        'company.signer.name': 'ФИО Подписанта',
        'company.signer.position': 'Должность подписанта',
        'company.signer.documenttype': 'Документ, подтверждающий полномочия',
        'company.address.legal': 'Юридический адрес',
        'company.address.real': 'Почтовый адрес',
        'company.bank.name': 'Название банка',
        'company.bank.bik': 'БИК',
        'company.bank.account': 'Расчетный счет',
        'company.bank.coraccount': 'Корреспондентский счет',
    },
    // поля тикета (tickets.*)
    ticketOptions: {
        'ticket.subnets.subnet': 'Сеть (продавец)',
        'ticket.prefix': 'Сеть (покупатель)',
        'ticket.priceall': 'Цена за всё',
        'ticket.price1': 'Цена за один IP'
    },
    // дополнительные поля компании (company.*), которых нету в persons.company.
    additionalCompanyParamOptions: {
        'company.email': 'Email',
        'company.site': 'Сайт',
        'company.phone': 'Телефон',
    },

    // склоняемые поля
    declensionOptions: [
        'name',
        'company.signer.position',
        'company.signer.name',
        'company.signer.documenttype',
    ]
}

export const moduleDocument = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions: actions,
    getters: getters
}