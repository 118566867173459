<template>
  <div>
    <button
        :class="{'text-muted':loading, 'custom-disabled':loading}"
        @click="openModal"
        class="btn btn-light btn-outline-dark col-12"
    ><i class="fa fa-tags button-icon menu-button"></i>Метки</button>
    <teleport to="body">
      <transition name="modal">
        <tags-modal :callback="callback" v-if="showModal" @close="closeModal"></tags-modal>
      </transition>
    </teleport>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TagsModal from "./tags/TagsModal";

export default {
  name: "TagsButton",

  components: {
    TagsModal,
  },

  props: {
    callback: {
      default: null,
    },
  },

  data:function (){
    return {
      showModal: false,
    }
  },

  computed:{
    ...mapGetters({
      "loading": 'tickets/isTicketLoading',
    }),
  },
  methods:{
    openModal() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketTags');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketTags');
    },
  }
}
</script>

<style scoped>

</style>