import {computed,  onBeforeMount,  reactive, ref, watch} from "vue";
import offerHistoryStore from "../../../store/main/offerHistory";
import {IPv4Net} from "../../../library/IPv4Net";
import {Utility} from "../../../library/api/Utility";

let prices = ref({});
let isPriceChange = ref(false);
let loading = ref(false);
export default function priceCalculate (props, context, dep){

    onBeforeMount(()=>{
        loading.value = false;
        prices.value = {};
        isPriceChange.value = false;

    })

    return {prices , isPriceChange, loading};
}



export function getPrice(net){
    return new Promise((resolve, reject) => {
        Utility.getPrice(net).then((result)=>{
            if(result.data?.result?.price){
                prices.value[net] = {
                    price:result.data.result.price,
                    currency:result.data.result.currency
                }
            }else {
                prices.value[net] = {
                    price:null,
                    currency:null
                }
            }
            resolve()
        }).catch(()=>{
            prices.value[net] = {
                price:null,
                currency:null
            }
            reject()
        }).finally(()=>{});
    });

}

export function getNet(props, context, dep){

    const index = ref(props.index)
    const subnet = ref(props.subnetProps)

    const type = ref(props.nettype);

    let isOur;
    isOur = computed(()=>{
        return offerHistoryStore.getters["offerHistory/addEdit/getIsOurSeller"];
    })



    watch(type , (val ,sources) => {
        offerHistoryStore.getters['offerHistory/addEdit/getHistoryObject'].subnets[index.value].type = val
        if(val === 'ASN'){

            offerHistoryStore.getters['offerHistory/addEdit/getHistoryObject'].asn.name = ''
            offerHistoryStore.commit('offerHistory/addEdit/setSubnetType' , 'ASN')
            offerHistoryStore.commit('offerHistory/addEdit/setIsAs' , true)
        }

    } );
    return{subnet, type}
}




