<template>
  <teleport to="body">
    <transition name="modal-confirm-offer">
      <modal-window
          @close="cancel"
          @success="confirm"
          modal-dialog-class="modal-sm modal-our-subnet"
      >
        <template v-slot:header>
          <h5 class="modal-title">Своя сеть</h5>
        </template>
        <template v-slot:body>
          <div>
            Задать, что продаем свою сеть?
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="cancel">Отмена</button>
          <button type="button" class="btn btn-primary" @click="confirm">Задать</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import ModalWindow from "../../../ModalWindow";

export default {
  name: "ModalConfirmOurSubnet",

  emits: ['cancel', 'confirm'],
  components: {ModalWindow},

  methods: {
    cancel() {
      this.$emit('cancel');
    },

    confirm() {
      this.$emit('confirm');
    },
  }
}
</script>

<style scoped>
:deep(.modal-content) {
  min-width: 310px;
}
:deep(.modal-body) {
  text-align: center;
}
</style>