import {createRouter, createWebHistory} from "vue-router";

import History from "../../components/offerHistory/History";
import moduleOfferHistory from "../../store/main/offerHistory";




const routes =[

    {
        path:"/offers/history/:historyId",
        name:"HistoryEdit",
        components:{
            main:History
        },
    },
    {
        path:"/offers/history",
        name:"History",
        components:{
            main:History
        },
    },

]








const offerHistoryRouter =  createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'
    }
)

offerHistoryRouter.beforeEach((to, from, next) => {

    if(moduleOfferHistory.getters.getUserSettings?.history?.onpage &&  !to.query?.onpage ){
        to.query.onpage = moduleOfferHistory.getters.getUserSettings?.history?.onpage;
    }

    if( !to.query?.onpage){
        to.query.onpage = moduleOfferHistory.getters["offerHistory/getPaginate"].onpage;
    }

    if(!to?.query?.page){
        to.query.page = 1;
    }
    moduleOfferHistory.commit('SET_ROUTE' , to);
    if(to.name !== 'HistoryEdit'){
        if(moduleOfferHistory.getters['offerHistory/getNeedChangeRoute']){
            moduleOfferHistory.dispatch('offerHistory/changeRoute', {from})
        }else {
            moduleOfferHistory.commit('offerHistory/setNeedChangeRoute', true)
        }

    }


    next();
});

export default offerHistoryRouter;