<template>
  <select v-if="['ticketparam', 'personparam', 'companyparam'].includes(keyword.type)"
          :value="keyword[keyword.type]"
          @change="(e) => keyword.setOption(e.target.value, getOptions(keyword.type)[e.target.value])"
          class="form-control">
    <option value="" disabled selected>—</option>
    <option v-for="(label, param) in getOptions(keyword.type)" :value="param">{{label}}</option>
  </select>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AutocompleteType",

  props: {
    keyword: Object
  },

  computed: {
    ...mapGetters({
      personparamOptions: 'document/getPersonParamOptions',
      ticketOptions: 'document/getTicketOptions',
      companyOptions: 'document/getCompanyOptions',
    })
  },

  methods: {
    getOptions(type) {
      return {
        personparam: this.personparamOptions,
        companyparam: this.companyOptions,
        ticketparam: this.ticketOptions
      }[type];
    },
  }

}
</script>

<style scoped>

</style>