<template>
  <template v-if="events" v-for="event in preparedEvents">
    <component :is="getComponentName(event.type)" :event="event" @update="onUpdate" />
  </template>
  <template v-else-if="event">
    <component :is="getComponentName(event.intname)" :event="event" @update="onUpdate" />
  </template>
</template>

<script>
import AddedToOfferHistory from "./AddedToOfferHistory";
import Approve from "./Approve";
import Autodeleted from "./Autodeleted";
import ChangeProduct from "./ChangeProduct";
import ChangeStep from "./ChangeStep";
import ChangeSubject from "./ChangeSubject";
import ConvertNotificationToTicket from "./ConvertNotificationToTicket.vue";
import DeletedByUser from "./DeletedByUser";
import MergeTicket from "./MergeTicket";
import MoveMessagesFrom from "./MoveMessagesFrom";
import MoveMessagesTo from "./MoveMessagesTo";
import NewRipeTransfer from "./NewRipeTransfer";
import Paid from "./Paid";
import RestoreTicket from "./RestoreTicket";
import SetBuyer from "./SetBuyer";
import SetProduct from "./SetProduct";
import SetSeller from "./SetSeller";
import Accept from "./Diadoc/Accept";
import Event from "./Diadoc/Event";
import Invite from "./Diadoc/Invite";
import Message from "./Diadoc/Message";
import Reject from "./Diadoc/Reject";
import DocumentGenerated from "./DocumentGenerated";
import AddThreadTags from "./AddThreadTags";
import RemoveThreadTags from "./RemoveThreadTags";
import NameChange from "./CM/NameChange";
import AddressChange from "./CM/AddressChange";
import EgrulChange from "./CM/EgrulChange";
import KonturWarning from "./CM/KonturWarning";
import Invoice from "./BILLmanager/Invoice";
import AddAssignedProjects from "./AddAssignedProjects";
import RemoveAssignedProjects from "./RemoveAssignedProjects";
import MoveToProject from "./MoveToProject";
import SetAnswered from "./SetAnswered";
import SetUnanswered from "./SetUnanswered";
import SetCompetitor from "./SetCompetitor";
import RemoveLotFromSubnet from "./RemoveLotFromSubnet";
import AddLotToSubnet from "./AddLotToSubnet";
import AuctionApprove from "./Auction/Approve";
import AuctionBuyerRaiseBid from "./Auction/BuyerRaiseBid";
import AuctionRaiseBidOnLot from "./Auction/RaiseBidOnLot";
import AuctionBuyerMadeBet from "./Auction/BuyerMadeBet";
import AuctionChangeLotPrice from "./Auction/ChangeLotPrice";
import AuctionChangeBidPrice from "./Auction/ChangeBidPrice";
import ChangePersonName from "./Auction/ChangePersonName.vue";
import AddPersonPhone from "./Auction/AddPersonPhone.vue";
import ChangePersonEmail from "./Auction/ChangePersonEmail.vue";
import ApproveCompanyChanges from "./Auction/ApproveCompanyChanges.vue";
import CompleteAuction from "./CompleteAuction";
import BuyerLoseLot from "./BuyerLoseLot";
import BuyerWinLot from "./BuyerWinLot";
import ReopenTicket from "./ReopenTicket";
import CloseTicket from "./CloseTicket";
import AutoReopenTicket from "./AutoReopenTicket";
import BlScan from "./BlScan";
import ResetBuyer from "./ResetBuyer";
import ResetSeller from "./ResetSeller";
import SetBuyforus from "./SetBuyforus";
import ResetBuyforus from "./ResetBuyforus";
import SetOursubnet from "./SetOursubnet";
import ResetOursubnet from "./ResetOursubnet";
import AddToNotificationsByFilter from "./AddToNotificationsByFilter";

export default {
  name: "Event",

  components: {
    AddedToOfferHistory,
    AddThreadTags,
    Approve,
    Autodeleted,
    ChangeProduct,
    ChangeStep,
    ChangeSubject,
    ConvertNotificationToTicket,
    DeletedByUser,
    MergeTicket,
    MoveMessagesFrom,
    MoveMessagesTo,
    NewRipeTransfer,
    Paid,
    RemoveThreadTags,
    RestoreTicket,
    SetBuyer,
    SetProduct,
    SetSeller,
    DocumentGenerated,

    DiadocAccept: Accept,
    DiadocEvent: Event,
    DiadocInvite: Invite,
    DiadocMessage: Message,
    DiadocReject: Reject,

    AuctionApprove,
    AuctionBuyerMadeBet,
    AuctionBuyerRaiseBid,
    AuctionRaiseBidOnLot,
    ChangePersonName,
    AddPersonPhone,
    ChangePersonEmail,
    AuctionChangeLotPrice,
    AuctionChangeBidPrice,

    ApproveCompanyChanges,
    CompleteAuction,
    BuyerLoseLot,
    BuyerWinLot,

    CmNameChange: NameChange,
    CmAddressChange: AddressChange,
    CmEgrulChange: EgrulChange,
    CmKonturWarning: KonturWarning,

    BillmanagerInvoice: Invoice,

    AddAssignedProjects,
    RemoveAssignedProjects,
    MoveToProject,
    SetAnswered,
    SetUnanswered,
    SetCompetitor,
    AddLotToSubnet,
    RemoveLotFromSubnet,
    CloseTicket,
    ReopenTicket,
    AutoReopenTicket,
    BlScan,
    ResetBuyer,
    ResetSeller,
    SetBuyforus,
    ResetBuyforus,
    SetOursubnet,
    ResetOursubnet,
    AddToNotificationsByFilter,
  },

  props: {
    events: { // old array of events
      type: Array,
    },
    event: { // new message type event
      type: Object,
    }
  },

  emits: [
    'update',
  ],

  computed: {
    preparedEvents: function() {
      return this.events.sort((first, second) => {
        return (moment(first.date) - moment(second.date));
      }).filter(event => !['CALL_START', 'CALL_END'].includes(event?.type));
    }
  },

  methods: {
    getComponentName(eventName) {
      let component = '';
      eventName = eventName.split('_');
      eventName.forEach(word => {
        word = word.toLowerCase();
        word = word.charAt(0).toUpperCase() + word.slice(1);
        component += word;
      });
      return component;
    },
    onUpdate(data) {
      this.$emit('update', data);
    },
  }
}
</script>

<style scoped>

</style>