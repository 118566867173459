<template>
  <div :class="{loading: loading}">
    <lirid
        :lirid="lirid"
        :with-label="false"
        :user-can-change="canChange"
        @update="update"
    />
  </div>
</template>

<script>
import LIRid from "../../../tiketMenu/components/Params/Product/Field/LIRid";
import updateFields from "../../source/updateFields";
import getBuyFields from "../Buy/OfferBuyCompiteds";
import {toRef} from "vue";

export default {
  name: "LiridBuy",

  components: {lirid: LIRid},

  props: {
    offer: {},
  },

  data: function() {
    return {
      loading: false,
    }
  },

  setup(props){
    const offer = toRef(props, 'offer')
    const { lirid, canChange } = getBuyFields({offer:offer});
    return {
      lirid,
      canChange
    }
  },

  methods:{
    update: function(values, afterSave = null, beforeSave = null) {
      this.loading = true;
      updateFields(this.offer, values, afterSave, beforeSave).then(() => this.loading = false);
    }
  }

}
</script>

<style scoped>

</style>