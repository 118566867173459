<template>
    <div class="player" ref="playerWindow" :style="playerPosition">
      <div v-if="file" class="player-close" @mousedown="holdOn" @mouseup="holdOff">
        <button v-on:click.prevent="close" type="button" class="close text-danger fa-2x" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
        <div  v-if="file" ref="player-controls" class="player-controls">
            <div id="play">
                <a v-on:click.prevent="playing = !playing" :title="(playing) ? 'Pause' : 'Play'" href="#">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path v-if="!playing" fill="currentColor" d="M15,10.001c0,0.299-0.305,0.514-0.305,0.514l-8.561,5.303C5.51,16.227,5,15.924,5,15.149V4.852c0-0.777,0.51-1.078,1.135-0.67l8.561,5.305C14.695,9.487,15,9.702,15,10.001z"/>
                        <path v-else fill="currentColor" d="M15,3h-2c-0.553,0-1,0.048-1,0.6v12.8c0,0.552,0.447,0.6,1,0.6h2c0.553,0,1-0.048,1-0.6V3.6C16,3.048,15.553,3,15,3z M7,3H5C4.447,3,4,3.048,4,3.6v12.8C4,16.952,4.447,17,5,17h2c0.553,0,1-0.048,1-0.6V3.6C8,3.048,7.553,3,7,3z"/>
                    </svg>
                </a>
            </div>
            <div id="seek">
                <div ref="player-timeline" class="player-timeline">
                    <div v-if="loaded">
                        <div :style="progressStyle" class="player-progress"></div>
                        <div v-on:click="seek" class="player-seeker" title="Seek"></div>
                    </div>
                    <div v-else>
                        Loading...
                    </div>
                </div>
                <div class="player-time">
                    <div class="player-time-current">{{ formattedCurrentTime }}</div>
                    <div class="player-time-total">{{ formattedTotalTime }}</div>
                </div>
            </div>
            <div id="download" v-show="!showVolume">
                <a v-on:click.prevent="download" href="#" title="Download">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path fill="currentColor" d="M15,7h-3V1H8v6H5l5,5L15,7z M19.338,13.532c-0.21-0.224-1.611-1.723-2.011-2.114C17.062,11.159,16.683,11,16.285,11h-1.757l3.064,2.994h-3.544c-0.102,0-0.194,0.052-0.24,0.133L12.992,16H7.008l-0.816-1.873c-0.046-0.081-0.139-0.133-0.24-0.133H2.408L5.471,11H3.715c-0.397,0-0.776,0.159-1.042,0.418c-0.4,0.392-1.801,1.891-2.011,2.114c-0.489,0.521-0.758,0.936-0.63,1.449l0.561,3.074c0.128,0.514,0.691,0.936,1.252,0.936h16.312c0.561,0,1.124-0.422,1.252-0.936l0.561-3.074C20.096,14.468,19.828,14.053,19.338,13.532z"/>
                    </svg>
                </a>
            </div>
            <div id="volume">
                <span class="a-like-span" :title="volumeTitle" href="#" @click.prevent="showVolume = !showVolume">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path fill="currentColor" d="M19,13.805C19,14.462,18.462,15,17.805,15H1.533c-0.88,0-0.982-0.371-0.229-0.822l16.323-9.055C18.382,4.67,19,5.019,19,5.9V13.805z"/>
                    </svg>
                    <input v-model.lazy.number="volume" v-show="showVolume" class="player-volume" type="range" min="0" max="100" @click.stop="" />
                </span>
            </div>
        </div>
        <audio :loop="looping" ref="audio" :src="file" v-on:timeupdate="update" v-on:loadeddata="load" v-on:pause="playing = false" v-on:play="playing = true" preload="auto" style="display: none;"></audio>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "Audioplayer",
    props: {
        autoPlay: {
            type: Boolean,
            default: true
        },
        loop: {
            type: Boolean,
            default: false
        }
    },
  mounted() {
    window.addEventListener('mousemove',this.doDrag)
  },
  beforeUnmount () {
    window.removeEventListener('mousemove', this.doDrag)
  },
  data: () => ({
    file: null,
    currentSeconds: 0,
    durationSeconds: 0,
    loaded: false,
    looping: false,
    playing: false,
    previousVolume: 35,
    showVolume: false,
    volume: 100,
    offset:{
      x:0,
      y:0
    },
    bottom: 0,
    right: 0,
  }),
    computed: {
        muted() {
            return this.volume / 100 === 0;
        },
        percentComplete() {
            return parseInt(this.currentSeconds / this.durationSeconds * 100);
        },
        progressStyle() {
            return { width: `${this.percentComplete}%` };
        },
        volumeTitle() {
            return `Volume (${this.volume}%)`;
        },
      playerPosition(){
        return "bottom: " +this.bottom + "px; right: " + this.right + "px";
      },
      formattedCurrentTime(){
          return this.formatSeconds(this.currentSeconds);
      },
      formattedTotalTime(){
        return this.formatSeconds(this.durationSeconds);
      }
    },
    watch: {
        playing(value) {
            if (value) { return this.$refs.audio.play(); }
            this.$refs.audio.pause();
        },
        volume(value) {
            this.$refs.audio.volume = this.volume / 100;
        },

    },
    methods: {
      formatSeconds(seconds){
        let format = "mm:ss";

        if( seconds >= 60*60 ){
          format = "HH:mm:ss"
        }
        if( seconds >= 60*60*24){
          format = "D HH:mm:ss"
        }
        return moment.utc(seconds * 1000).format(format);
      },
        download() {
            this.stop();
            window.open(this.file + '?download=true', 'download');
        },
        load() {
            if (this.$refs.audio.readyState >= 2) {
                this.loaded = true;
                this.durationSeconds = parseInt(this.$refs.audio.duration);

                // фикс бага расчета height timeline в safari/old chromium
                let timeline =this.$refs["player-timeline"];
                if(timeline.style.height == "") {
                    timeline.style.height = (this.$refs["player-controls"].clientHeight / 2)
                }

                return this.playing = this.autoPlay;
            }

            throw new Error('Failed to load sound file.');
        },
        mute() {
            if (this.muted) {
                return this.volume = this.previousVolume;
            }

            this.previousVolume = this.volume;
            this.volume = 0;
        },
        seek(e) {
            if (!this.loaded) return;

            const bounds = e.target.getBoundingClientRect();
            const seekPos = (e.clientX - bounds.left) / bounds.width;

            this.$refs.audio.currentTime = parseInt(this.$refs.audio.duration * seekPos);
        },
        stop() {
            this.playing = false;
            this.$refs.audio.currentTime = 0;
        },
        update(e) {
            this.currentSeconds = parseInt(this.$refs.audio.currentTime);
        },
      setFile(file) {
        this.currentSeconds = 0;
        if (this.file != file) {
          this.file = file;
          this.loaded = false;
          this.stop();
          this.$refs.audio.load();
        } else {
          if (this.loaded) {
            this.playing = true;
            this.$refs.audio.currentTime = 0;
          }
        }
      },
      close() {
        this.bottom = 0;
        this.right = 0;
        this.setFile(null);
      },
      doDrag(event){
        if(this.drag){
          event.preventDefault();
          let newX = this.offset.x - event.clientX;
          let newY = this.offset.y - event.clientY;

          let boxWidth = $(this.$refs.playerWindow).outerWidth();
          let boxHeight = $(this.$refs.playerWindow).outerHeight();

          if (newX < 0) {
            newX = 0;
          }
          if (newY < 0) {
            newY = 0;
          }

          if (boxWidth + newX > $(window).width()) {
            newX = $(window).width() - boxWidth;
          }

          if( boxHeight + newY > $(window).height() ){
            newY = $(window).height() - boxHeight;
          }

          this.right = newX;
          this.bottom = newY;
        }
      },
      startMove (event) {
        let x = this.right + event.clientX
        let y = this.bottom + event.clientY
        this.offset = { x, y }
      },
      holdOn(event){
        event.preventDefault();
        this.drag = true;
        this.startMove(event);
      },
      holdOff(){
        this.drag = false;
      },
    },
    created() {
        this.looping = this.loop;
    },
}
</script>

<style scoped>

.player {
    background-color: #7e7e7e;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
    color: #ffffff;
    display: inline-block;
    line-height: 1.5625;
  position: fixed;
  z-index: 100;
}

.player-controls {
    display: flex;
}
.player-controls > div {
    border-right: 1px solid #e0e0e0;
}
.player-controls > div:last-child {
    border-right: none;
}
.player-controls > div a {
    color: #ffffff;
    display: block;
    line-height: 0;
    padding: 1em;
    text-decoration: none;
}
.player-controls > div a svg {
    display: inline-block;
    width: 1.125rem;
}
.player-controls > div .a-like-span {
    color: #ffffff;
    display: block;
    line-height: 0;
    padding: 1em;
    text-decoration: none;
}
.player-controls > div .a-like-span svg {
    display: inline-block;
    width: 1.125rem;
}

.player-timeline {
    background-color: #e0e0e0;
    height: 50%;
    min-width: 200px;
    position: relative;
}
.player-timeline .player-progress,
.player-timeline .player-seeker {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}
.player-timeline .player-progress {
    background-color: #404040;
    z-index: 1;
}
.player-timeline .player-seeker {
    cursor: pointer;
    width: 100%;
    z-index: 2;
}

.player-time {
    display: flex;
    justify-content: space-between;
}
.player-time .player-time-current {
    padding-left: 5px;
}
.player-time .player-time-total {
    padding-right: 5px;
}

.player-volume {
    display: inline-block;
    height: 1.1rem;
    margin: 0 0 0 2px;
    width: 6rem;
  vertical-align: middle;
}

.player-close {
  width: 100%;
  height: 13px;
  background-color: #7e7e7e;
  cursor: move;
  border-bottom: 1px solid white;
}

button.close {
  line-height: 8px;
  vertical-align: middle;
  float: right;
  font-size: 15px;
  padding-right: 3px;
  border: 0;
  background: none;
  color: #ffffff !important;
}
</style>
