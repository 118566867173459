<template>

  <div class="row mb-2">
    <label class="form-label group-label">{{label}}</label>
    <div class="d-flex align-items-center flex-wrap">
      <span v-if="showCheckbox">
        <input type="checkbox" :id="params[0].group + params[0].key" class="form-check-input" :value="params[0].key" v-model="value" @change="update(params[0].key, value)" ref="checkbox" />
        <label :for="params[0].group + params[0].key" class="form-check-label ps-1">{{params[0].label ?? params[0].key}}</label>
      </span>
      <span v-else-if="showRadio" v-for="param in params" class="me-3">
        <label :for="param.group + param.key" ref="radio">{{param.label ?? param.key}}</label>
        <input :value="param.key" v-model="value" type="radio" :name="param.group" :id="param.group + param.key" class="form-check-input ms-1" :class="{'is-invalid': showNotValid && !valid}" @change="update(param.key)" />
      </span>
    </div>
  </div>

</template>

<script>
export default {
  name: "GroupedTextBlockParam",

  emits: ['update', 'radioValid', 'hideChild'],

  props: {
    groups: Array,
    params: Array,
    personParams: Object,
  },

  data() {
    return {
      value: 'off',
      valid: false,
      showNotValid: false,
    }
  },

  mounted() {
    if(this.params.length > 1) {
      // radio не валиден до выбора одного из значений
      this.setValid(false);
    } else {
      // checkbox валиден сразу
      this.setValid();
    }

    this.$nextTick(() => {
      this.params.forEach(param => this.$emit('hideChild', param.key, param.group ?? null));
      this.prepareTextBlocks();
    });
  },

  computed: {
    label() {
      return this.params[0].groupname ?? this.groups.join(', ');
    },
    showCheckbox() {
      return this.params.length === 1;
    },
    showRadio() {
      return this.params.length > 1;
    },
  },

  methods: {
    isValid() {
      this.showNotValid = true;
      return this.valid;
    },

    update(key, value = true, autoset = false) {
      this.groups.forEach(group => this.$emit('update', key, value, group, autoset));

      if(!autoset) {
        this.setValid(true);
      }
    },

    setValid(valid = true) {
      this.valid = valid;
      this.groups.forEach(group => this.$emit('radioValid', group, valid));
    },

    focus() {
      if(this.showCheckbox) {
        this.$refs.checkbox.focus();
      } else if(this.showRadio) {
        this.$refs.radio[0].focus();
      }
    },

    prepareTextBlocks() {
      if(Object.keys(this.personParams ?? {}).length) {
        ['country', 'legaltype'].forEach(type => {

          let clientParam = this.personParams.find(item => item.param === `company.${type}`) ?? null;

          if(typeof clientParam !== null) {
            clientParam = clientParam.value;

            if(type === 'country' && ['ДНР', 'ЛНР'].includes(clientParam)) {
              clientParam = [clientParam, 'Российская Федерация'];
            }

            let founded = this.params.find(param => {
              return Array.isArray(clientParam)
                        ? clientParam.includes(param?.conditionparam)
                        : clientParam === param?.conditionparam;
            });

            if(founded) {
              this.value = founded.key;
              this.update(founded.key, true, true);
              this.setValid(true);
            }
          }

        });
      }
    },
  },

  watch: {
    personParams: {
      handler(params) {
        if(Object.keys(params).length) {
          this.prepareTextBlocks();
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>