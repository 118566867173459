<template>
  <teleport to="body">
    <modal-window v-if="showDeleteModal" @close="showDeleteModal = false; $emit('close')"  @success="deleteHistory" ok-button-style="btn-danger" >
      <template v-slot:header>
        <h5 class="modal-title text-danger" >Удаление сделки</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите удалить сделку {{deal}}?</span>
      </template>
    </modal-window>
  </teleport>
</template>

<script>
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import {OfferHistory} from "../../../library/api/OfferHistory";

export default {
  name: "DeleteHistoryModal",
  components: {ModalWindow},
  emits:['close'],

  props:{
    showDeleteModal:{},
    params:{},
  },

  data:function (){
    return {}
  },
  computed:{
    deal:function (){
      let deal;
      if(this.params?.offerHistory?.asn?.name){
        deal = this.params?.offerHistory?.asn?.name;
      }else {
        deal = this.params?.offerHistory?.subnets?.[0]?.subnet;
      }
     return deal;
    }
  },
  methods:{
    deleteHistory:function (){

      OfferHistory.deleteObject(window.getId(this.params.offerHistory._id)).then(()=>{
        this.$store.commit('offerHistory/showContextMenu', false)
        this.$store.commit('offerHistory/setContextMenuParams', null)
        this.$emit('close')
        this.$store.dispatch('offerHistory/changeRoute' , {});

      })


    }
  },
}
</script>

<style scoped>

</style>