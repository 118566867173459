import offerActions from "./offerActions";
import offerGetters from "./offerGetters";
import offerMutations from "./offerMutations";
import offerState from "./offerState";
import {offerFilters} from "./filters/offerFiltersState";


export default {
    namespaced: true,
    state: offerState,
    actions: offerActions,
    getters: offerGetters,
    mutations: offerMutations,
    modules: {
        filters: offerFilters
    },
    plugins: []
}