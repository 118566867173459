<template>
  <div>
    <button
        v-backspace.ctrl="hotkeyModalDelete"
        v-delete.meta="hotkeyModalDelete"
        v-backspace.meta="hotkeyModalDelete"
        v-delete.ctrl="hotkeyModalDelete"
        :disabled="disabled"
        :class="{'text-muted':loading, 'custom-disabled':loading}"
        @click="showModalDelete"
        class="btn btn-light btn-outline-dark col-12"
    ><i class="fa fa-trash-o button-icon menu-button"></i>&nbsp;Удалить</button>
    <teleport to="body">
      <transition name="modal">
        <modal-window v-if="showModal" @close="closeModal" @success="deleteNotification()" ok-button-style="btn-danger">
          <template v-slot:header>
            <h5 class="modal-title text-danger">Удаление рассылки</h5>
          </template>
          <template v-slot:body>
            <span>Вы уверены, что хотите <b>безвозвратно</b> удалить{{getBody}}</span>
          </template>
          <template v-slot:footer>
            <div style="display: flex;  flex-wrap: nowrap;">
              <div class="btn-padding px-1">
                <button type="button" class="btn btn-secondary"  @click="closeModal">Нет</button>
              </div>
              <div class="btn-padding px-1">
                <button type="button" class="btn btn-danger" @click="deleteNotification()">Да</button>
              </div>
            </div>
          </template>
        </modal-window>
      </transition>
    </teleport>
  </div>
</template>

<script>
import ModalWindow from "./ModalWindow";
import {replaceTextObjects} from "../../ticket/Api/api";
import {getNumeralsWord} from "../../../library/Numerals";
import {mapGetters} from "vuex";

export default {
  name: "DeleteNotificationButton",

  components: {
    ModalWindow,
  },

  props: {
    callback: {
      default: null,
    },
    queue: {
      default: null,
    },
    disabled: {
      default: false,
    }
  },

  data: function (){
    return {
      showModal: false,
      hotkeyForWindows: ['ticket', 'ticketList'],
    }
  },
  methods:{
    hotkeyModalDelete() {
      if(this.hotkeyForWindows.includes(this.getActiveWindow)) {
        this.showModalDelete();
      }
    },
    showModalDelete() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'notificationDelete');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'notificationDelete');
    },
    deleteNotification() {
      let action = () => {
        this.closeModal();
        this.$store.dispatch('tickets/deleteNotifications', {
          ids: this.getSelectedArray,
          route: this.callback ?? this.$route,
        });
      }
      if(this.queue === null) {
        action();
      } else {
        this.queue(action);
      }
    },
  },

  computed: {
    getBody() {
      let body = " {{items}}";
      body = replaceTextObjects(body, this.getSelectedObjects, 2, "name", "Без темы");
      if (this.getSelectedArray.length > 2) {
        let n = (this.getSelectedArray.length - 2);
        body += " и еще " + getNumeralsWord(n, ['рассылка', 'рассылки', 'рассылок']);
      }
      body += "?";
      return body;
    },
    ...mapGetters({
      "getSelectedArray": "tickets/getSelectedArray",
      "getSelectedObjects": "tickets/getSelectedObjects",
      "loading": "tickets/isTicketLoading",
      "getActiveWindow": "getActiveWindow",
    }),
  },
}
</script>

<style scoped>

</style>