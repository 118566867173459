<template>
    <button  v-show="!isHidden"  :style="customButtonStyle" class=" btn btn-sm btn-light  " @click="fixMargin" type="button" :id="'message-context-button-' + messageid" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i  class="fa fa-bars" aria-hidden="true" ></i>
    </button>
    <div ref="menu" style=" padding-top: 5px; padding-bottom: 5px" class=" dropdown-menu " :aria-labelledby="'message-context-button-' +messageid">
        <div v-for="contextItem in enableContextItems">
          <context-link-item
              v-if="contextItem.type === 'link'"
              :user-href="contextItem.href"
              :messageid="messageid"
              :name="contextItem.name"
          ></context-link-item>
          <context-callback-item
              v-if="contextItem.type === 'callback'"
              :callback="getCallback(contextItem)"
              :messageid="messageid"
              :name="contextItem.name"
          ></context-callback-item>
        </div>
    </div>
</template>

<script>
import ContextLinkItem from "./ContextLinkItem";
import ContextCallbackItem from "./ContextCallbackItem";
import {mapGetters} from "vuex";
import {UserPermission} from "../../../../users/UserPermission";
import {copyToClipboard} from "../../Api/api";

export default {
  name: "ContextButton",
  components: {ContextLinkItem, ContextCallbackItem},
  props:{
    "customButtonStyle":{
      type:Object,
      default:{}
    },
    "messageid":{
      type:String,
      require:true,
    },
    "callbacks": {
      default:{},
    },
    "show": {
      type: Array,
      default:[]
    },
    "leftAlign": {
      type: Boolean,
      default: false,
    }
  },
  data:function (){
    return {
      localCallBacks:{
        "copyLink":this.copyLink
      },
      contextItems:[
        {
          id: 'hideHtml',
          name: 'Отобразить без HTML',
          type: 'callback',
        },
        {
          id: 'showHtml',
          name: 'Отобразить с HTML',
          type: 'callback',
        },
        {
          id:"copyLink",
          name: "Скопировать ссылку",
          type: "callback",
          show:true,
        },
        {
          id: "createAsNew",
          name: "Создать как новое",
          type: "callback",
        },
        {
          id: "loadImages",
          name: "Загрузить изображения",
          type: "callback",
        },
        {
          id: "hideImages",
          name: "Убрать изображения",
          type: "callback",
        },
        {
          id: "moveMessages",
          name: "Перенести",
          type: "callback",
        },
        {
          id: "callAttachment",
          name: "Добавить вложение",
          type: "callback",
        },
        {
          id: "deleteTask",
          name: "Удалить",
          type: "callback",
        },
        {
          id: "replaceAttachment",
          name: "Заменить",
          type: "callback",
        },
        {
          id: "showRevisions",
          name: "История версий",
          type: "callback",
        },
        {
          id: "hideRevisions",
          name: "Скрыть историю",
          type: "callback",
        },
        {
          id: 'setCallback',
          name: 'Отметить звонок отвеченным',
          type: 'callback',
        },
        {
          id: 'pinMessage',
          name: 'Закрепить',
          type: 'callback',
        },
        {
          id: 'unpinMessage',
          name: 'Открепить',
          type: 'callback',
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      "ticket":  "thread/getTicket",
    }),
    enableContextItems(){
      let enableContextItems = [];
      for (let el of this.contextItems){
        if(this.needShow(el , 'link')){
          enableContextItems.push(el)
        }else if(this.needShow(el , 'callback')){
          enableContextItems.push(el)
        }
      }
      return enableContextItems;
    },
    isHidden(){
      return this.enableContextItems.length === 0;
    }
  },
  mounted() {
    UserPermission.can(this.ticket.project != null ? this.ticket.project.name + ".message_source" : "system.message_source").then(()=>{
      this.contextItems.unshift({
        id: "openSource",
        name: "Исходный текст",
        type: "link",
        href: "/message/" + this.messageid + "/source/",
      });
    }).catch(()=>{})
  },
  methods: {
    copyLink:function (){
      let url = location.origin + location.pathname + "?message=" + this.messageid;
      copyToClipboard(url);
    },
    getCallback:function (contextItem) {
      let callBack = function (){};
      if(typeof this.callbacks[contextItem.id] !== 'undefined'){
        callBack = this.callbacks[contextItem.id];
      }else if(typeof this.localCallBacks[contextItem.id] !== 'undefined'){
        callBack = this.localCallBacks[contextItem.id];
      }

      return callBack;
    },
    needShow: function(item, type) {
      if(typeof item.show !== 'undefined'){
        return item.show;
      }
      return (this.show.indexOf(item.id) > -1 && item.type === type);
    },
    fixMargin: function (){
      if( this.leftAlign ) {
        this.$nextTick(() => {
          $(this.$refs.menu).css("margin-left", "-" + ($(this.$refs.menu).width() - 28) + "px")
        });
      }
    },

  }

}
</script>

<style scoped>

</style>