<template>
  <transition name="modal">
    <modal-window @close="$emit('cancel')" @success="confirm" ok-button-style="btn-primary" modal-dialog-class="modal-lg">
      <template v-slot:header>
        <h5 class="modal-title" >Whois {{subnet}}</h5>
      </template>

      <template v-slot:body>
        <div v-if="criticalChanges.loading" class="col-12 pb-2 text-center bg-light text-primary">Проверка...</div>
        <div v-if="criticalChanges.loaded" class="row" style="padding-left: 9.5px; padding-right: 9.5px;">
          <span v-if="criticalChanges.isRIPE" class="alert alert-warning">
            <i class="fa fa-warning"></i> Сеть не принадлежит RIPE
          </span>
          <template v-else>
            <span>Дата возможного трансфера сети:&nbsp;</span>

            <span v-if="criticalChanges.error" class="text-danger">
              не удалось проверить
            </span>

            <span v-else-if="availableDate" class="text-danger d-inline-flex">
              <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                {{availableDate}}
                <template #content>
                  <span v-html="criticalChangesPopperContent"></span>
                </template>
              </popper>
            </span>

            <span v-else class="text-success">
              трансфер сети в данный момент <b>доступен</b>
            </span>
          </template>
        </div>

        <div v-if="whois.loading" class="col-12 text-center bg-light">Загрузка whois...</div>
        <template v-if="whois.value">
          <div v-if="!whoisSameSubnet" class="alert alert-warning range-warning">Сеть является частью большего диапазона {{whoisSubnetsPrefixes}}</div>
          <div class="pre">
            <table style="overflow-x: auto;">
              <tr v-for="line in whois.value">
                <td style="min-width: 120px; width: 120px;">{{line.name}}:&nbsp;</td>
                <td>
                  <span v-if="typeof line.value === 'object' && typeof line.value.id !== 'undefined'">
                    <a :href="'https://ipanel.i7.org/#/query/' + line.value.id" target="_blank">{{line.value.id}}</a>
                  </span>
                  <span v-else>{{line.value}}</span>
                </td>
              </tr>
            </table>
          </div>
        </template>

        <span v-else-if="whois.notFound">Сеть не найдена. Все равно добавить {{subnet}}?</span>
        <span v-else-if="whois.error" class="text-danger">Ошибка при загрузке whois</span>

        <!-- div class="form-check mt-2" v-if="userCanSetLot">
          <label class="form-check-label text-start" for="setlot">Выставить на продажу</label>
          <input class="form-check-input" type="checkbox" v-model="setLot" id="setlot">
        </div -->
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary close" @click="$emit('cancel')">Отмена</button>
        <button type="button" class="btn btn-primary save" :disabled="confirmDisabled" @click="confirm">Добавить</button>
      </template>
    </modal-window>
  </transition>
</template>

<script>
import ModalWindow from "../../../../ModalWindow";
import {CrmApi} from "../../../../../../../library/CrmApi";
import Loading from "vue3-loading-overlay";
import {Utility} from "../../../../../../../library/api/Utility";
import {IPv4Net} from "../../../../../../../library/IPv4Net";

export default {
  name: "WhoisModal",

  components: {ModalWindow, Loading},
  emits: ['confirm', 'cancel'],

  props: {
    subnet: {
      type: String,
      require: true,
    },
    userCanSetLot: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      whois: {
        value: null,
        notFound: false,
        error: false,
        loading: false,
      },

      criticalChanges: {
        lastChange: null,
        availableDate: null,
        error: false,
        loading: false,
        loaded: false,
        isRIPE: true,
      },

      setLot: false,

      stopDocumentKeydownHandler: null,
      stopDocumentKeyupHandler: null,
      converter: require('ip-subnet-calculator'),
    }
  },

  mounted: function() {
    this.stopDocumentKeydownHandler = document.onkeydown;
    this.stopDocumentKeyupHandler = document.onkeyup;
    document.onkeydown = null;
    document.onkeyup = null;

    this.loadSubnetInfo();
  },

  unmounted: function() {
    document.onkeyup = this.stopDocumentKeyupHandler;
    document.onkedown = this.stopDocumentKeydownHandler;
  },

  computed: {
    confirmDisabled() {
      return (this.whois.loading || this.criticalChanges.loading);
    },

    availableDate() {
      return (this.criticalChanges.lastChange) ? moment(this.criticalChanges.lastChange.date).add(2, 'y')
                                                                                             .format('DD.MM.Y')
                                               : null;
    },

    criticalChangesPopperContent() {
      let content = null;
      let change = this.criticalChanges.lastChange;

      if(typeof change.type === 'string') {
        let date = moment(change.date).format('DD.MM.Y');

        switch(change.type) {
          case 'created':
            content = `${date} сеть была создана`;
            break;
          case 'change':
            content = `${date} у ${this.subnet} было `;
            if(change.param.old === null) {
              content += `добавлено поле <i>${change.changed_param}</i> со значением <i>${this.getParamValue(change.param.new)}</i>`;
            } else {
              content += `изменено поле <i>${change.changed_param}</i> с <i>${this.getParamValue(change.param.old)}</i> на <i>${this.getParamValue(change.param.new)}</i>`;
            }
            break;
        }
      }

      return content;
    },

    whoisSubnet(){
      let result = false;

      if(this.whois.value) {
        let range = this.whois.value.filter(line => ['NetRange', 'inetnum'].includes(line.name))[0]?.value;
        if(range) {
          result = new IPv4Net(range);
        }
      }

      return result;
    },

    whoisSubnetsPrefixes() {
      let result;
      if (this.whois.value) {
        let range = this.whois.value.filter(line => ['NetRange', 'inetnum'].includes(line.name))[0]?.value;
        if (range.indexOf("-") !== -1) {
          let ipStart;
          let ipEnd;
          [ipStart, ipEnd] = range.split('-');
          ipStart = ipStart.trim();
          ipEnd = ipEnd.trim();
          if (this.converter.isIp(ipStart) && this.converter.isIp(ipEnd)) {
            result = this.converter.calculate(ipStart, ipEnd);
            if (Array.isArray(result)) {
              result = result.map(net => net.prefixSize).filter(i => !!i).map(i => '/' + i).join('+');
            }
          }
        }
      }
      return result ?? ('/' + this.whoisSubnet.result.prefixSize);
    },

    whoisSameSubnet() {
      let result = false;

      if(this.whoisSubnet) {
        let ipv4net = new IPv4Net(this.subnet);
        if(this.whoisSubnet.asRange() === ipv4net.asRange()) {
          result = true;
        }
      }
      return result;
    }
  },

  methods: {
    confirm() {
      if(!this.confirmDisabled) {
        let data = this.userCanSetLot ? {setlot: this.setLot} : {};
        this.$emit('confirm', data);
      }
    },

    getParamValue( value ){
      return typeof value.id !== 'undefined' ? value.id : value;
    },

    loadSubnetInfo(subnet = this.subnet) {
      this.loadNetCriticalChanges(subnet)
      this.loadWhois(subnet);
    },

    loadWhois(subnet) {
      this.whois.loading = true;
      this.whois.notFound = false;
      this.whois.error = false;

      Utility.getSubnetWhois(subnet, {allrirs: "on"}).then(response => this.whois.value = this.handleWhois(response.data))
          .catch(error => {
            if (error.response.status === 404) {
              this.whois.notFound = true;
            } else {
              this.whois.error = true;
              this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
            }
          })
          .finally(() => this.whois.loading = false);
    },

    loadNetCriticalChanges(subnet) {
      this.criticalChanges.loading = true;

      Utility.getNetCriticalChanges(subnet).then(response => this.handleCriticalChanges(response.data))
            .catch(error => {
              this.criticalChanges.error = true;
            })
            .finally(() => {
              this.criticalChanges.loading = false
              this.criticalChanges.loaded = true;
            });
    },

    handleWhois(whois) {
      let arWhois = [];

      Object.entries(whois).forEach(([name, value]) => {
        if(Array.isArray(value)) {
          value.forEach(line => arWhois.push({name: name, value: line}));
        } else {
          if(name === 'netname' && value.indexOf('NON-RIPE-NCC-MANAGED-ADDRESS-BLOCK')) {
            this.criticalChanges.isRIPE = false;
          }

          arWhois.push({name: name, value: value});
        }
      });

      return arWhois;
    },

    handleCriticalChanges(criticalChanges) {
      if(criticalChanges.length) {
        this.criticalChanges.lastChange = criticalChanges.sort((a, b) => moment(b.date) - moment(a.date))[0];

        let match = this.criticalChanges.lastChange.type.match(/^change_(.*)/);
        if(match !== null) {
          this.criticalChanges.lastChange.type = 'change';
          this.criticalChanges.lastChange.changed_param = match[1];
        }
      }
    },
  }
}
</script>

<style scoped>
.pre {
  display: block;
  padding: 9.5px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
:deep(.modal-body) {
  overflow: auto;
  margin: 0;
  min-width: 20vw;
  text-align: left;
}
:deep(.modal-dialog) {
  min-width: 500px;
  width: fit-content;
}
:deep(.modal-content) {
  max-height: 90vh;
}
.range-warning{
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
  border-radius: 4px 4px 0 0;
}
</style>