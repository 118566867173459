<template>
  <div class="sidebar-item-body" v-if="show">
    <div :class="{'fw-bold': isSelected}" class="v-sidebar-item" @click="filterClick" >
      <span v-if="icon" class="icon-container">
        <i :class="this.iconStyle"></i>
      </span>
      {{label}}
      <read-unread
          v-if="count"
         :actual-count="actualCount"
         :count="count"
         :count-settings="countSettings"
         :filter="content.id"
         @open="openWith"
      ></read-unread>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import ReadUnread from "./ReadUnread";

export default {
  name: "Filter",
  components: {ReadUnread},

  props: {
    "label": {
      type: String,
      require: true,
    },
    "content": {
      type: Object,
      require: true,
    },
    "icon": {
      type: String,
      default:null
    },
    "id": {
      type: String,
      require: true,
    },
    countSettings: {
      type: Object
    }
  },

  data: function (){
    return {
      count: null,
      actualCount: false,
    }
  },

  computed:{
    iconStyle: function (){
      return "fa fa-" + this.icon;
    },

    isSelected:function () {
      let alias = typeof this.$route?.query?.searchAlias === 'string' && this.$route?.query?.searchAlias.trim() !== '' ?
          this.$route?.query?.searchAlias.trim() :
          '';
      let projectCheck = this.content.filter.project === this.getSelectionControl.projectName;
      let filterCheck = alias ? false : this.getSelectionControl.filter === this.content.filter.type;
      let aliasCheck = alias ? alias === this.content.filter.type : false;
      return projectCheck && (filterCheck || aliasCheck);
    },

    isFolderSelected: function () {
      return (this.getSelectionControl.filter === this.content.filter.type &&
          this.content.filter.project === this.getSelectionControl.projectName);
    },

    ...mapGetters({
      "isLoading":  "tickets/isTicketLoading",
      'getSelectionControl':'leftMenu/getSelectionControl',
      'getAdditionalSearchSettingsToShow':'tickets/filters/getAdditionalSearchSettingsToShow',
      "showAll": "leftMenu/getShowAll",
    }),
    show: function() {
      if (['unconfirmed', 'billmanager'].includes(this.content.filter.type)) {
        this.content.isShow = this.showAll || this.count !== null && this.count.total !== 0;
      }

      return this.showAll ||
             ['new', 'calls'].includes(this.content.filter.type) ||
             (this.count !== null && this.count.total !== 0);
    }
  },

  methods:{
    filterClick() {
      this.open(false);
    },

    open(name = false, updateSettingsShow = false) {
      let searchParams = false;
      if (name) {
        searchParams = {};
        searchParams[name] = true;
      }
      if(this.isFolderSelected) {
        if (['TicketsProjectFilter', 'Billmanager', 'Calls', 'Tasks'].includes(this.$route.name)) {
          let query = {};
          if (searchParams) {
            if (this.$route.name === 'Tasks') {
              if (typeof searchParams['today'] !== 'undefined' && searchParams['today']) {
                delete query['type'];
              }
              if (typeof searchParams['expired'] !== 'undefined' && searchParams['expired']) {
                query['type'] = 'expired';
                this.$store.commit('tickets/filters/setType', 'expired', {root: true});
                delete searchParams['expired'];
              }
            }
            if (!_.isEmpty(searchParams)) {
              query['searchParams'] = JSON.stringify(searchParams);
            }
          }
          if (!name) {
            this.$store.dispatch('tickets/filters/returnAdditionalFilterValuesToDefault');
          }
          if (typeof query['type'] === 'undefined' || query['type'] === null) {
            this.$store.commit('tickets/filters/setType', 'current', {root: true});
          }
          if (updateSettingsShow) {
            query.searchAlias = this.content?.filter?.type ?? 'all';
          }
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
          this.$store.commit('tickets/filters/updateSettingShow', updateSettingsShow);
          this.$store.commit('tickets/filters/setShowFilter', false);
          if (!updateSettingsShow) {
            this.$store.commit('tickets/filters/resetAliasSet');
          }
        } else {
          this.goToTickets({
            content: this.content,
            withQuery: false,
            "searchParams": searchParams
          });
        }
      } else {
        this.goToTickets({
          content: this.content,
          "searchParams": searchParams
        });
      }
    },

    openWith(name) {
      let filter = this.getAdditionalSearchSettingsToShow.find(el => el.id === name);
      if (filter) {
        filter.value = true;
        this.open(name, true);
      } else {
        this.open(name);
      }
    },

    ...mapMutations({
      "updateSettingShow":"tickets/filters/updateSettingShow",
    }),

    ...mapActions({
      'goToTickets':'leftMenu/goToTickets',
      'chooseSelectionFilter':'leftMenu/chooseSelectionFilter',
      getAllTickets: 'tickets/getAllTickets',
    }),

  },
  created() {
    if(this.$route.name === 'TicketsProjectFilter' || this.$route.name === 'Billmanager' || this.$route.name === 'Calls'
        || this.$route.name === 'Tasks') {

      let filter = this.$route.params.filter;
      switch (this.$route.name){
        case 'Billmanager':
        case 'Tasks':
        case 'Calls':
          filter = this.$route.name.toLowerCase();
          break;
      }

      if(
          this.$route.params.project === this.content.filter.project &&
         filter === this.content.filter.type
      ) {

         this.chooseSelectionFilter({filter: filter, projectName:this.content.filter.project});
      }
    }
  }
}
</script>

<style scoped>
.v-sidebar-item{
  cursor: pointer;
  padding-left: 5px;
  border-radius: 5px;
}
.v-sidebar-item:hover{
  background-color: #d3fad3;
}
.icon-container {
  width: 20px;
  display: inline-block;
  text-align: center;
}

hr{
  margin: 10px 0;
}
.v-sidebar-item-root{
  font-weight: 600;
}
.sidebar-item-body{
  color: rgba(0, 0, 0, 0.65);
  background-color: #ececec;
}
.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 100%;
    margin-top: 0px;
  }
}
</style>