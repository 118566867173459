<template>
  <div>
      <div @click="editClient" class="edit-client-block">
        <div v-if="clientName">
         <value-or-trim :is-need-trim="isScreenSmall" :value="clientName"></value-or-trim>
          <div v-if="clientEmail">
            <value-or-trim :is-need-trim="isScreenSmall" :value="clientEmail"></value-or-trim>
          </div>
        </div>
        <div v-else-if="clientEmail">
          <value-or-trim :is-need-trim="isScreenSmall" :value="clientEmail"></value-or-trim>
        </div>
        <subnet-notification v-if="clientAuctionNotification" :notification="clientAuctionNotification" />
        <div class="text-muted extra-search-lines" v-if="Array.isArray(extraClientLines) && extraClientLines.length">
          <div v-for="line in extraClientLines"><string-trim :string="line" /></div>
        </div>
      </div>
      <div class="text-left">
        <transition name="modal">
          <edit v-if="showEditModal" :person="person" :ticketId="offer._id" @closeModal="closeModal"></edit>
        </transition>
      </div>
  </div>
</template>

<script>
import getSellFields from "../Sell/OfferSellComputeds";
import {toRef} from "vue";
import Edit from "../../../tiketMenu/components/Client/Edit";
import {CrmApi} from "../../../../library/CrmApi";
import {mapMutations, mapGetters} from "vuex";
import StringTrim from "../../../string/StringTrim";
import ValueOrTrim from "../../../common/ValueOrTrim";
import SubnetNotification from "../../../tiketMenu/components/Client/SubnetNotification.vue";

export default {
  name: "Client",
  components: {
    SubnetNotification,
    ValueOrTrim,
    Edit,
    StringTrim,
  },
  setup(props){
    const offer = toRef(props, 'offer')
    const { clientEmail, clientName, extraClientLines, clientAuctionNotification } = getSellFields({offer});
    return {
      clientEmail,
      clientName,
      clientAuctionNotification,
      extraClientLines,
    }
  },
  props:{
    'offer':{
      type:Object,
      require:true,
    }
  },
  data: function() {
    return {
      showEditModal: false,
      person: null,
    }
  },
  computed:{
    ...mapGetters({
      'isScreenSmall':'isScreenSmall'
    })
  },
  methods: {
    editClient() {
      CrmApi.getThreadPerson(this.offer._id).then((result) => {
        this.person = result.data.person;
        this.showEditModal = true;
      }).catch(error => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      });
    },
    closeModal(person) {
      if (person) {
        this.setPerson((({_id, name, email, auction}) => ({_id, name, email, auction}))(person));
      }
      this.showEditModal = false;
    },
    ...mapMutations({
      "setPerson": "offer/setPerson",
    }),
  }
}
</script>

<style scoped>
.edit-client-block {
  cursor: pointer;
}
.text-left {
  text-align: left;
}
.extra-search-lines {
  font-size: 12px;
}
</style>