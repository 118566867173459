import {createRouter, createWebHistory} from "vue-router";
import statisticStore from "../store/main/statistic/index";

import Statistic from "../components/statistic/Statistic";






const routes =[
    {
        path:"/statistic",
        name:"Statistic",
        components:{
            main:Statistic
        },
    },

]








const  statisticRouter =  createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'
    }
)

statisticRouter.beforeEach((to, from, next) => {
    statisticStore.commit('SET_ROUTE' , to);
    next();
});

export default  statisticRouter;