<template>
  <multiselect @clear="clearState()" @deselect="changeState()" @select="changeState()"  :placeholder="'Выберите проекты'"
               v-model="values"
               :mode="'tags'"
               :close-on-select="false"
               :searchable="true"
               :options="projects"
               class="multiselect-sm"
  ></multiselect>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from "../../ticket/Api/api";

export default {
  name: "AssignTicketSelect",
  components:{
    'multiselect':Multiselect
  },
  data:function (){
    return {
       projects:[],
        values:[],

    }
  },
  props:{
    'action':{
      type:Object,
      require:true,
    }
  },
  methods:{
    changeState(){
      this.action.params = [];
      for (let elem of this.values){
        this.action.params.push(
            {
              key:"assignProject",
              value:elem,
            }
        );
      }
    },
    clearState(){
      this.action.params = [];
    }
  },
  created() {
    this.projects = []
    CrmApi.getProjectsNames().then((result)=> {
      for (let elem of result.data){
        this.projects.push({
          value:elem._id,
          label:elem.name,
        });
      }
      if(typeof this.action.params[0] !== 'undefined' && this.action.params[0].value !== ""){
        for (let elem of  this.action.params){
          if(elem.key === 'assignProject'){
            this.values.push(elem.value);
          }
        }
      }
    }).catch((error)=> {});
  },
  mounted() {
    changeMultySelectStyles(document);
  }
}
</script>

<style scoped>
.multiselect-sm{
  --ms-tag-bg: #D1FAE5;
  --ms-tag-color: #059669;
  --ms-py: 0.4rem;
  --ms-px: 0.875rem;

}
</style>