<template>
  <template v-if="date">{{formattedDate}}</template>
</template>

<script>
export default {
  name: "Date",

  props: {
    date: {}
  },

  computed: {
    formattedDate() {
      return moment(this.date).format('DD.MM.YYYY');
    }
  }
}
</script>

<style scoped>

</style>