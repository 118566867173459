import {CrmApi} from "../CrmApi";

export {Documents}

class Documents {

    static editDocument(document) {
        return CrmApi.call("/documents/" + encodeURIComponent(window.getId(document._id)) + "/", document);
    }

    static addDocument(document) {
        return CrmApi.call('/documents/new', document);
    }

    static uploadDocument(document) {
        let formData = new FormData();
        formData.append('document', document);

        return CrmApi.call('/documents/upload/', formData, { headers: {'Content-type': 'multipart/form-data'} });
    }

    static getDocumentsList(query) {
        return CrmApi.getEntityList('documents', query);
    }

    static deleteDocuments(idsArray) {
        return CrmApi.deleteEntityList("documents", idsArray);
    }

    static threadDocuments(threadId) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates');
    }

    static threadPersonParam(threadId, document) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates/personparam', {document});
    }

    static threadTransferAgreementParam(threadId, ogrn = null) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates/transfer-agreement/params/' + (ogrn !== null ? '?ogrn=' + ogrn : ''));
    }

    static getTransferAgreementCompanies(threadId) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates/transfer-agreement/companies');
    }

    static generateDocument(threadId, request) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates', request);
    }

    static generateTransferAgreement(threadId, request) {
        return CrmApi.call(this.threadLink(threadId) + 'doctemplates/transfer-agreement', request)
    }

    static getCompanies(threadId = null) {
        return threadId === null
                ? CrmApi.call('/documents/companies')
                : CrmApi.call(this.threadLink(threadId) + 'doctemplates/companies/');
    }

    static getGeneratedDocumentEvents(threadId, templateId) {
        return CrmApi.call(this.threadLink(threadId) + '?documenttemplate=' + templateId);
    }

    static replaceDocuments(threadId, messageId, attachments) {
        let formData = new FormData();

        for(let index in attachments) {
            formData.append('file[' + index + ']', attachments[index]);
        }

        return CrmApi.call(
            this.threadLink(threadId) + 'doctemplates/' + encodeURIComponent(window.getId(messageId)) + '/replace/',
            formData,
            {headers: {'Content-type': 'multipart/form-data'}}
        );
    }

    static threadLink = threadId => '/thread/' + encodeURIComponent(window.getId(threadId)) + '/';
}