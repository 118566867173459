<template>
  <div style="font-size: 14px; "  class="table-scroll w-100">
    <table id="table--history-list" class="table table-bordered  table-container" >
      <thead class="thead-dark" style="z-index:20">
      <tr>
        <th   class="text-center" style="min-width: 65px;  cursor: pointer" >Дата</th>
        <th    style="min-width: 190px; cursor: pointer"  class="text-center" >Подсеть</th>
        <th style="min-width: 100px; cursor: pointer" class="text-center" >Объект (handle)</th>
        <th  style="cursor: pointer"  class="text-center" >Изменения</th>
      </tr>
      </thead>
        <events-body></events-body>
    </table>
  </div>
  <paginate id="offer-history-paginate"
            @changePage="goToPage($event)"
            :update-by-object="true"
            :paginator="paginator"
            ref="paginate"
            settings-name="events"
  ></paginate>
  <loading-list v-if="loading"></loading-list>
</template>

<script>
import EventsBody from "./EventsBody";
import LoadingList from "../../ticket/List/LoadingList";
import Paginate from "../../paginate/Paginate";
import {mapGetters} from "vuex";
export default {
  name: "offersEventsList",
  components: {EventsBody, LoadingList, Paginate},
  computed:{
    ...mapGetters({
        'loading':'offerEvents/getLoading',
        'paginator':'offerEvents/getPaginate',
    }),
  },
  data:function (){
    return {
      maxHeight:1000,
    }
  },
  methods:{
    goToPage:function ({page , onpage}){
      let q = Object.assign({} , this.$route.query);
      q.page = page;
      q.onpage = onpage;

      this.$router.push({
        name:this.$route.name,
        query:q,
      })
    }
  },
  mounted() {
    let windowHeight = window.innerHeight;

   // this.maxHeight = (windowHeight - $("#main-nav-menu").height()   - $("#offer-history-paginate").height() - $("#nav-menu").height() - 70 ) + "px";
  },
  watch:{
    paginator:{
      handler(val){

        const updateRefPaginate = () => {
          let updated = false;
          if( this.$refs.paginate != null ){
            this.$refs.paginate.update(val);
            updated = true;
          }
          return updated;
        }
        if( !updateRefPaginate() ){
          this.$nextTick(() => {
            updateRefPaginate();
          })
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped>
.table{
  --bs-table-border-color: #acacad;

}


</style>