<template>
  <li class="list-group-item">
    <div class="row">
      <div class="col-1">
        <input class="form-check-input"
               type="checkbox"
               v-model="checkedValue"
               name="projects[]"
               @change="checkbox"
               :id="'project-' + project._id"
               :disabled="!canSubmit"
        />
      </div>
      <div class="col-7">
        <label class="form-check-label" :for="'project-' + project._id">
          {{project.name}}
        </label>
      </div>
      <div class="col-4">
        <a @click="showInputs = !showInputs" class="inputLink small text-end pull-right" v-if="checkedValue">Параметры проекта</a>
      </div>
    </div>
    <div class="row pt-2" v-if="showInputs">
      <hr>
      <div class="form-group offset-1 col-lg-10">
        <div class="form-check" style="margin-bottom:10px;">
          <input class="form-check-input" type="checkbox" v-model="form.hidden" :id="'checkbox-form-hidden' + project._id" @change="changeHidden">
          <label class="form-check-label" :for="'checkbox-form-hidden' + project._id">
            Исключить из "Все проекты"
          </label>
        </div>
      </div>

      <div class="form-group offset-1 col-lg-10">
        <div class="form-check" style="margin-bottom:10px;">
          <input class="form-check-input" type="checkbox" v-model="projectsettings.notifications_disabled" :id="'checkbox-form-notifications_disabled' + project._id" @change="changeInputs">
          <label class="form-check-label" :for="'checkbox-form-notifications_disabled' + project._id">
            Отключить уведомления
          </label>
        </div>
      </div>

      <div class="form-group offset-1 col-lg-10">
        <label :for="'date-input-' + project._id" class="form-label">Доступ с даты</label>
        <datepicker
            :placeholder="getRoleText"
            v-bind:class="{'is-invalid': errors.date}"
            :model-value="form.messagesFrom"
            minimum-view="day"
            inputFormat="yyyy-MM-dd HH:mm"
            :id="'date-input-' + project._id"
            @update:modelValue="changeDate"
            @change="changeDate"
            :disabled="!canSubmit"
            :typeable="true"
            :clearable="true"
            class="form-control"
        />
      </div>
      <div class="form-group offset-1 col-lg-10">
        <label :for="'name-input-' + project._id" class="form-label">ФИО</label>
        <input :id="'name-input-' + project._id"
               type="text"
               v-model="projectsettings.name"
               class="form-control"
               id="name"
               @input="changeInputs"
               placeholder="Фамилия И. О.">
      </div>
      <div class="form-group offset-1 col-lg-10">
        <label :for="'name-en-input-' + project._id" class="form-label">ФИО</label>
        <input :id="'name-en-input-' + project._id"
               type="text"
               v-model="projectsettings.name_en"
               class="form-control"
               id="name_en"
               @input="changeInputs"
               placeholder="Фамилия И. О. (EN)">
      </div>
      <div class="form-group offset-1 col-lg-10">
        <label :for="'phoneext-input-' + project._id" class="form-label">Добавочный номер</label>
        <input :id="'phoneext-input-' + project._id"
               type="text"
               v-model="projectsettings.phoneext"
               class="form-control"
               id="phoneext"
               @input="changeInputs"
               placeholder="000">
      </div>
    </div>
  </li>
</template>

<script>
import Datepicker from "vue3-datepicker";
import popper from "../../library/vue3-popper/popper.esm";
import {getDateFormat} from "../ticket/Api/api";
export default {
  name: "ProjectItem",

  components: { Datepicker , popper},

  props: {
    project: {
      require: true
    },
    user:{

    },
    userProject: {},
    userProjectSettings: {},

    checked: {
      type: Boolean,
      default: false,
    },

    messagesFrom: {
      default: null
    },

    canSubmit: {
      type: Boolean,
      default: true,
    }
  },

  data: function() {
    return {
      form: {
        messagesFrom: null,
        hidden: false,
      },
      projectsettings: {
        name: '',
        name_en: '',
        phoneext: '',
        notifications_disabled: false,
      },
      errors: {
        date: false,
      },
      showInputs: false,
      checkedValue:false,
    }
  },

  mounted: function() {
    this.checkedValue = this.checked;
    this.form.messagesFrom = this.messagesFrom !== null ? new Date(this.messagesFrom) : null;

    if (typeof this.userProject !== 'undefined') {
      this.form.hidden = this.userProject.hidden ?? false;
    }
    this.projectsettings.name = this.userProjectSettings?.name ?? '';
    this.projectsettings.name_en = this.userProjectSettings?.name_en ?? '';
    this.projectsettings.phoneext = this.userProjectSettings?.phoneext ?? '';
    this.projectsettings.notifications_disabled = this.userProjectSettings?.notifications_disabled ?? false;
    this.showInputs = this.checkedValue && this.showInputsCalc();
  },
  computed:{
    getRoleText(){
      return this.getReadPeriodByRole !== null ? ('Роль: '+this.getReadPeriodByRole) : '';
    },
    getReadPeriodByRole(){
      let rp = null;
      if(typeof this.user?.read_period !== 'undefined' && this.user.read_period !== null){
        rp = this.getDateFormat(this.user.read_period);
      }
      return rp;
    }
  },
  watch:{
    checked(to){
      this.checkedValue = to;
    }
  },
  methods: {
    getDateFormat(value){
      return getDateFormat(value , 'YYYY-MM-DD HH:mm')
    },
    changeDate: function(date) {
      let d = '';
      if (typeof date === 'undefined' || date === null) {
        this.form.messagesFrom = null;
        d = null;
        this.errors.date = false;
      } else if(date instanceof Date){
        this.form.messagesFrom = date;
        this.errors.date = false;
        d = moment(date).format('YYYY-MM-DD HH:mm')
      } else {
        if(moment(date.target?.value , 'YYYY-MM-DD HH:mm' , true).isValid()){
          d = moment(date.target?.value).format('YYYY-MM-DD HH:mm')
          this.form.messagesFrom = new Date(date.target?.value);
          this.errors.date = false;
        } else if(moment(date.target?.value, 'YYYY-MM-DD', true).isValid()){
          d = moment(date.target?.value).format('YYYY-MM-DD') + ' 00:00';
          this.form.messagesFrom = new Date(d);
          this.errors.date = false;
        } else {
          this.errors.date = true;
        }
      }

      this.$emit('changeProjectData', {
        project: this.project,
        messages_from: d,
        hidden: this.form.hidden}
      );
    },

    changeHidden: function() {
      this.$emit('changeProjectData', {project: this.project, messages_from: this.form.messagesFrom, hidden: this.form.hidden, notifications_disabled: this.projectsettings.notifications_disabled});
    },

    showInputsCalc: function () {
      return (this.form.messagesFrom !== null || this.form.hidden === true || this.projectsettings.notifications_disabled === true || this.projectsettings.name !== '' || this.projectsettings.name_en !== '' || this.projectsettings.phoneext !== '');
    },

    checkbox: function() {
      this.$emit('checkbox', {project: this.project, checkbox: this.checkedValue, form: this.form, projectsettings: this.projectsettings});
      this.showInputs = this.checkedValue === false ? false : this.showInputsCalc();
    },

    changeInputs: function () {
      this.$emit('changeProjectSettings', {project: this.project, projectsettings: this.projectsettings});
    }
  }
}
</script>

<style scoped>
.inputLink {
  cursor: pointer;
  text-decoration: none;
}
</style>