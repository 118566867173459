import {Statistic} from "../../../library/api/Statistic";
import {CrmApi} from "../../../library/CrmApi";


export const getKpi = ({state , commit, dispatch, getters}, params) =>{
    return new Promise((resolve, reject) => {
        Statistic.getKpi(params).then((result)=>{
            commit('setKpi',result.data);
            resolve(result);
        }).catch((error)=>{
            commit('errorPush', CrmApi.getErrorMessage(error), true);
            reject(error)
        })
    });

}


export const getWaitingOrders = ({state , commit, dispatch, getters}, params) =>{
    return new Promise((resolve, reject) => {
        Statistic.getWaitingOrders().then((result)=>{
            commit('setWaitingOrders',result.data.orders);
            resolve(result);
        }).catch((error)=>{
            commit('errorPush', CrmApi.getErrorMessage(error), true);
            reject(error)
        })
    });

}