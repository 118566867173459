import * as actions from '../common/actions';

import * as getters from '../common/getters';
import commonMutations from "../common/mutations";
import {moduleOffersEvents} from "./offerEventsState";
import {createStore} from "vuex";
import {shareState} from "../common/composition";
export default createStore({
    namespaced: true,
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    modules:{
        'offerEvents':moduleOffersEvents
    },
});
