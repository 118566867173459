<template>
  <div class="modal modal-mask" :class="{'loading-cursor':loading}">
    <div class="modal-dialog modal-lg modal-dialog-scrollable edit-modal">
      <div class="modal-content" style="position: relative">
        <div style="position: absolute; right: 10px; top:0px; font-size: 21px; z-index: 8; ">
          <span class="close btn" data-dismiss="alert" @click="showCloseModalWindow">&times;</span>
        </div>
        <div v-enter.targetBody="clickEnter"  v-esc="showCloseModalWindow" class="modal-body" ref="modal-body">
            <div class="pt-5 col-md-10 m-auto">
              <div class="form-group row" style="margin-bottom: 1px">
                <label for="name" class="col-sm-2 col-form-label">Имя</label>
                <div class="col-sm-10">
                  <input type="text" class="form-control" v-model="fields.name" id="name" placeholder="Не заполнено">
                </div>
                <!-- a @click="showAddCompany()" class="company-link" >Добавить информацию о компании</a -->
              </div>
              <div style="padding-top: 15px">
                <div class="d-flex align-items-center">
                  <div style="font-size: 14px; color: gray;">Информация о компании:</div>
                  <a v-if="showCompanyLink && showCompanyFields === false"
                     @click="showCompany"
                     class="company-show-link"
                  >Отобразить все поля</a>
                  <a v-if="showCompanyLink && showCompanyFields === true"
                     @click="hideCompany"
                     class="company-show-link"
                  >Скрыть пустые поля</a>
                </div>
                <hr v-if="showCompanyInfo || showCompanyFields" style="padding-bottom: 0; margin-bottom: 8px; margin-top: 0;padding-top: 0;" />
                <div v-if="showCompanyInfo || showCompanyFields">
                  <company-show ref="company-info"
                                :person="person"
                                :company="fields.company"
                                :ticket-id="ticketId"
                                :show-all="showCompanyFields"
                                @setLoading="setLoading"
                                @save="submit(false)"
                                @updateField="addCompanyElement"
                                @set-company="setCompany"
                                @set-show-company-link="setShowCompanyLink"
                                @set-disabled="setDisabled"
                                @resetCompanyFields="resetCompanyFields" />
                </div>
              </div>
              <hr style=" padding-bottom: 0px; margin-bottom: 5px; margin-top: 5px;" />
              <div class="form-group row">
                <label for="phones" class="col-sm-2 col-form-label">Телефон</label>
                <div id="phones" v-if="addedPhonesExists">
                  <div v-for="(phone, index) in fields.phone" class="input-group p-1">
                    <phone class="phone-flex" v-model="phone.number" placeholder="Телефон"></phone>
                    <input class="form-control" v-model="phone.name" placeholder="ФИО" v-show="phone.type === 'другой'">
                    <select class="form-control noflex w-20" v-model="phone.type">
                      <option :value="undefined" disabled hidden>Тип</option>
                      <option value="рабочий">Рабочий</option>
                      <option value="мобильный">Мобильный</option>
                      <option value="telegram">Telegram</option>
                      <option value="whatsapp">WhatsApp</option>
                      <option value="другой">Другой</option>
                    </select>
                    <span class="input-group-text d-inline-flex align-items-center btn-danger btn" @click="removePhone(index)">-</span>
                    <button class="input-group-text d-inline-flex align-items-center btn-success btn" @click="addPhone(index)" :disabled="canNotAddPhones">+</button>
                  </div>
                </div>
                <div class="w-auto" style="margin-left: auto" v-else>
                  <button class="input-group-text btn-success btn" @click="addPhone(index)" :disabled="canNotAddPhones">+</button>
                </div>
              </div>
              <div class="form-group row">
                <label for="emails" class="col-sm-2 col-form-label">Email</label>
                <div id="emails">
                  <div class="input-group p-1">
                    <input
                        class="form-control form-control phone-flex"
                        v-model="fields.email"
                        :class="{'is-invalid': isMainMailInvalid}"
                        @input="validateContactEmails"
                        @change="fields.email = fields.email.toLocaleLowerCase();validateContactEmails();"
                    >
                    <span class="input-group-text w-20-b noflex" id="basic-addon2">Основной</span>
                    <button class="input-group-text btn-success btn" @click="addEmail()" :disabled="canNotAddEmails">+</button>
                  </div>
                  <div v-for="(email, index) in fields.contact_emails" class="input-group p-1">
                    <input
                        class="form-control phone-flex"
                        :class="{'is-invalid': typeof email.invalid !== 'undefined' && email.invalid}"
                        v-model="email.mail"
                        placeholder="Email"
                        @input="validateContactEmails"
                        @change="contactEmailChange(email)">
                    <input class="form-control" v-model="email.name" placeholder="ФИО" @change="contactEmailNameChange(email)">
                    <select class="form-control  noflex" style="width: 20.5%;" v-model="email.type">
                      <option :value="undefined" disabled hidden>Тип</option>
                      <option value="рабочий">Рабочий</option>
                      <option value="личный">Личный</option>
                    </select>
                    <button class="input-group-text btn-danger btn" @click="removeEmail(index)">-</button>
                    <button class="input-group-text btn-success btn" @click="addEmail(index)" :disabled="canNotAddEmails">+</button>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="person.meta">
                <label for="meta" class="col-sm-12 col-form-label">Дополнительная информация</label>
                <div id="meta" class="m-2">
                  <meta-list
                      :ticket-id="ticketId"
                      :person="person"
                      :show-label="false"
                      :user-can-change="true" />
                </div>
              </div>

              <div class="">
                <label for="comment">Комментарий</label>
                <mde-text-area
                    @focusout="commentOutFocus"
                    @focusin="commentInFocus"
                    id="comment"
                    v-model="fields.comment"
                    :toggle-height="true"
                />
              </div>

              <div class="double-buttons-group">
                <div>
                  <button @click="showCloseModalWindow" type="button" class="col-6 btn btn-secondary">Назад</button>
                </div>
                <div>
                  <button type="button"  @click="submit()" :disabled="disabled || loading" class="col-6  btn btn-primary">ОК</button>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
    <modal-window v-if="showCloseModal" @close="showCloseModal = false" @success="cancel" ok-button-style="btn-danger">
      <template v-slot:header>
        <h5 class="modal-title text-danger">Подтверждение действия</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите закрыть форму редактирования клиента? Введенные вами данные не сохранятся.</span>
      </template>
    </modal-window>
  </div>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import MdeTextArea from "../../../template/source/MdeTextArea";
import Phone from "../../../common/Phone/Phone";
import addressParser from "address-rfc2822";
import ModalWindow from "../ModalWindow";
import {Email} from "../../../../library/Email";
import Meta from "./Entity/Meta";
import CompanyAdd from "./CompanyAdd";
import CompanyShow from "./CompanyShow";
import {hasChanged} from "../../../../library/Functions";

export default {
  name: "Edit",

  components: {
    CompanyShow,
    CompanyAdd,
    MetaList: Meta,
    Phone,
    'mde-text-area': MdeTextArea,
    ModalWindow,
  },

  data:function (){
    return {
      fields: {},
      loading: false,
      stopDocumentKeydownHandler: null,
      inFocus:false,
      showCloseModal: false,
      showCompanyFields: false,
      showCompanyLink: true,
      companyWasChanged: false,
      isDisabled: false,
    }
  },

  props:{
    person: {},
    ticketId: {},
    customUpdater:{
      default:null,
    }
  },

  emits: [
      'closeModal',
  ],

  created() {
    if(typeof this.person.company === 'undefined' || this.person.company === null){
      this.person.company = {};
    }

    this.fields = {...this.person};

    if( this.fields?.email == undefined){
      this.fields.email = '';
    }

    this.fields.contact_emails = [];
    this.fields.phone = [];
    this.fields.company = JSON.parse(JSON.stringify(this.person?.company ?? {}));

    if(typeof this.person.contact_emails !== 'undefined' && Array.isArray(this.person.contact_emails)) {
      this.person.contact_emails.forEach(email => {
        this.fields.contact_emails.push({nameChanged: email.name !== '', ...email});
      })
    }
    if(typeof this.person.phone !== 'undefined' && Array.isArray(this.person.phone)) {
      this.person.phone.forEach(phone => {
        this.fields.phone.push({...phone});
      })
    }
  },

  mounted() {
    let pos = $('.edit-modal')[0].getBoundingClientRect();
    $('.edit-modal').css({'--markdown-modal-left': pos.width + pos.x});
  },

  updated: function() {
    this.stopDocumentKeydownHandler = document.onkeydown;
    document.onkeydown = null;
  },

  computed: {
    emailsCount: function() {
      return (typeof this.person.contact_emails !== 'undefined' && Array.isArray(this.person.contact_emails)) ? this.person.contact_emails.length : 0;
    },

    phoneCount: function() {
      return (typeof this.person.phone !== 'undefined' && Array.isArray(this.person.phone)) ? this.person.phone.length : 0;
    },

    addedEmailsExists: function() {
      return (typeof this.fields.contact_emails !== 'undefined' && this.fields.contact_emails.length);
    },

    addedPhonesExists: function() {
      return (typeof this.fields.phone !== 'undefined' && this.fields.phone.length);
    },

    disabled: function() {
      let hasInvalidEmails = false;

      if (typeof this.fields.contact_emails !== 'undefined' && Array.isArray(this.fields.contact_emails) &&  this.fields.contact_emails.length) {
        this.fields.contact_emails.forEach((email) => {
          if (typeof email.invalid !== 'undefined' && email.invalid) {
            hasInvalidEmails = true;
          }
        });
      }

      return this.isDisabled ||
             this.isMainMailInvalid ||
             hasInvalidEmails ||
             this.loading ||
             !this.inputsChanged;
    },

    isMainMailInvalid() {
      let invalid = false;
      if (typeof this.fields?.email !== 'string' || this.fields?.email.trim() === '') {
        if (typeof this.person?.email === 'string' && this.person.email.trim() !== '') {
          invalid = true;
        }
      } else {
        if (!Email.validate(this.fields.email)) {
          invalid = true;
        } else if (typeof this.fields.contact_emails !== 'undefined' && Array.isArray(this.fields.contact_emails)) {
          this.fields.contact_emails.forEach(email => {
            if (typeof email?.mail === 'string' && this.fields.email.toLowerCase() === email.mail.toLowerCase()) {
              invalid = true;
            }
          });
        }
      }

      return invalid;
    },

    inputsChanged: function () {
      let changes = false;

      if (hasChanged(this.fields?.company ?? {}, this.person?.company ?? {})) {
        changes = true;
      }

      [
        [this.fields.name, this.person.name],
        [this.fields.email, this.person.email],
        [this.fields.comment, this.person.comment],
      ].forEach((el) => {
        if( (el[0] !== el[1]) ^
            (el[0] === '' && (typeof el[1] === 'undefined' || el[1] === null)) ) {

          changes = true;
        }
      });

      [
        {
          field: this.fields.phone,
          count: this.phoneCount,
          original: this.person.phone,
          params: ['number', 'type', 'name'],
          mainParam: 'number',
        },
        {
          field: this.fields.contact_emails,
          count: this.emailsCount,
          original: this.person.contact_emails,
          params: ['mail', 'type', 'name'],
          mainParam: 'mail',
        }
      ].forEach((item) => {
          if(typeof item.field !== 'undefined') {
            let filtered = item.field.filter((element) => {
              return typeof element[item.mainParam] === 'string' && element[item.mainParam].trim() !== '';
            });
            if (filtered.length !== item.count) {
              changes = true;
            } else {
              if (item.count) {
                for (let index in filtered) {
                  item.params.forEach((param) => {
                    if (filtered[index][param] !== item.original[index][param]) {
                      changes = true;
                    }
                  });
                }
              }
            }
          }
      });

      return changes;
    },

    canNotAddEmails() {
      let result = false;
      if (!this.fields.email) {
        result = true;
      } else {
        if (typeof this.fields.contact_emails !== 'undefined' && Array.isArray(this.fields.contact_emails)) {
          this.fields.contact_emails.forEach(email => {
            if (typeof email.mail === 'undefined' || email.mail.trim() === '') {
              result = true;
            }
          });
        }
      }
      return result;
    },

    canNotAddPhones() {
      let result = false;
      if (typeof this.fields.phone !== 'undefined' && Array.isArray(this.fields.phone) && this.fields.phone.length) {
        this.fields.phone.forEach(phone => {
          if (typeof phone.number === 'undefined' || phone.number.trim() === '') {
            result = true;
          }
        });
      }
      return result;
    },

    showCompanyInfo() {
      let hasNotNull = fields => Object.values(fields).some(field => {
        let result = false;
        if (typeof field === 'object') {
          if(Array.isArray(field)) {
            result = field.some(value => value !== null);
          } else if(field !== null) {
            result = Object.keys(field).length === 0 || Object.values(field).some(value => value !== null);
          }
        } else if (typeof field === 'string' && field.trim() !== '') {
          result = true;
        }
        return result;
      });
      return this.companyWasChanged ||
             hasNotNull(this.fields?.company ?? {}) ||
             hasNotNull(this.person?.company ?? {});
    }
  },

  methods: {
    setDisabled(value) {
      this.isDisabled = value;
    },
    setShowCompanyLink(value) {
      this.showCompanyLink = value;
    },
    showCompany() {
      if (typeof this.fields.company === 'undefined' || this.fields.company === null) {
        this.fields.company = {};
      }
      this.showCompanyFields = true;
    },
    hideCompany() {
      this.showCompanyFields = false;
      if (typeof this.$refs["company-info"].getShownItems === 'function') {
        this.$refs["company-info"].getShownItems();
      }
    },
    addCompanyElementWithUpdate:function ({value, type}){
      if(typeof this.fields.company === 'undefined' ||   this.fields.company ===null){
        this.fields.company = {};
      }
      this.fields.company[type] = value;
      if( this.$refs['company-info']){
        this.$refs['company-info'].updateText({value, type});
      }

    },
    setLoading(value){
      this.loading =value;
    },

    resetCompanyFields() {
      Object.keys(this.fields.company ?? {})
            .filter(key => key !== 'documents')
            .forEach(key => this.fields.company[key] = null);
    },

    addCompanyElement:function ({value, type}){
      this.companyWasChanged = true;
      if (typeof this.fields.company === 'undefined' || this.fields.company === null) {
        this.fields.company = {};
      } else if (Array.isArray(this.fields.company)) {
        this.fields.company = Object.assign({}, this.fields.company);
      }
      if(type.split('.').length > 1) {
        type = type.split('.');
        let field = this.fields.company;
        let i = 0;
        for(; i < type.length - 1; i++) {
          if(Array.isArray(field[type[i]] ?? null) && field[type[i]].length === 0) {
            field[type[i]] = {};
          }
          field[type[i]] = field[type[i]] ?? {};
          field = field[type[i]];
        }
        field[type[i]] = value;
      } else {
        this.fields.company[type] = value;
      }
    },

    setCompany(company) {
      Object.assign(this.fields.company, company);
    },

    commentInFocus:function (){
      $(this.$refs['modal-body']).scrollTop( this.$refs['modal-body'].scrollHeight );
      this.inFocus = true;
    },
    commentOutFocus:function (){
      this.inFocus = false;
    },
    removeEmail: function(index) {
      this.fields.contact_emails.splice(index, 1);
      this.validateContactEmails();
    },

    addEmail: function(index = -1) {
      this.fields.contact_emails.splice(index + 1, 0, {"type": "рабочий"});
    },

    removePhone: function(index) {
      this.fields.phone.splice(index, 1);
    },

    addPhone: function(index) {
      this.fields.phone.splice(index + 1, 0, {
        'number':'',
        'type': 'другой',
      });
    },
    clickEnter:function (){
      if(!this.inFocus){
        this.submit();
      }
    },
    submit: function(close = true) {
      if(this.disabled || this.loading){
        return;
      }
      let tmp =[];
      for (let phone of this.fields.phone){
          if(typeof phone.number !== 'undefined' && phone.number.trim() !== ''){
            tmp.push(phone);
          }
      }
      if (typeof this.fields.email === 'undefined' || this.fields.email === null || this.fields.email.trim() === '') {
        delete this.fields.email;
      }
      this.fields.phone = tmp;
      tmp = [];
      for (let email of this.fields.contact_emails){
          if(typeof email.mail !== 'undefined' && email.mail.trim() !== ''){
            tmp.push(email);
          }
      }
      this.fields.contact_emails = tmp;

      if(this.fields?.company?.name && this.fields.company.name.trim() == '—' ){
        this.fields.company.name = ''
      }
      if(this.fields?.company?.vatnum && this.fields.company.vatnum.trim() == '—' ){
        this.fields.company.vatnum = ''
      }

      Object.entries(this.fields).forEach(([name, value]) => {
        if(typeof value === 'object' && !Array.isArray(value) && value !== null) {
          if(Object.keys(value).length === 0) {
            this.fields[name] = null;
          }
        }
      });

      let updater;
      if(this.customUpdater !== null) {
        updater = this.customUpdater;
      }else {
        updater = (id , fields) => {
          return CrmApi.updateThreadPerson(id, fields)
        }
      }
      this.setLoading(true);
      updater(this.ticketId, this.fields).then((result) => {
        if(close) {
          this.cancel(result.data.person);
        }

        Object.entries(result.data.person).forEach((item) => {
          this.person[item[0]] = item[1];
        })
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally( () => this.setLoading(false));


    },

    showCloseModalWindow() {
      if (this.inputsChanged) {
        this.showCloseModal = true;
      } else {
        this.cancel();
      }
    },

    cancel: function(success = false) {
      this.$emit('closeModal', success);
    //  document.onkeydown = this.stopDocumentKeydownHandler;
    },
    contactEmailChange: function (email) {
      if (typeof email.name === 'undefined' || email.name === null || email.name.trim() === '') {
        try {
          email.name = addressParser.parse(email.mail)[0].name();
          email.mail = addressParser.parse(email.mail)[0].address;
          email.nameChanged = true;
        } catch (nothing) {}
      }
      email.mail = email.mail.toLocaleLowerCase();
      this.validateContactEmails();
    },

    validateContactEmails() {
      if (typeof this.fields.contact_emails !== 'undefined' &&
          Array.isArray(this.fields.contact_emails) &&
          this.fields.contact_emails.length
      ) {
        this.fields.contact_emails.forEach((email, index) => {
          let invalid = !Email.validate(email.mail);
          if (typeof email?.mail === 'string') {
            if (this.fields.email.toLowerCase() === email.mail.toLowerCase()) {
              invalid = true;
            }
            this.fields.contact_emails.forEach((innerEmail, innerIndex) => {
              if (
                  typeof innerEmail?.mail === 'string' &&
                  innerEmail.mail.toLowerCase() === email.mail.toLowerCase() &&
                  innerIndex !== index
              ) {
                invalid = true;
              }
            });
          } else {
            invalid = true;
          }
          email.invalid = invalid;
        });
      }
    },

    contactEmailNameChange: function (email) {
      if (email.name !== '') {
        email.nameChanged = true;
      }
    }
  },
}
</script>

<style scoped>
.loading-cursor{
 cursor: progress;
}
.company-link{
  color: blue;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  text-decoration: none;
}
.company-show-link {
  cursor: pointer;
  margin-left: auto;
  font-size: 12px;
  text-decoration: none;
}
.modal-mask {
  --markdown-modal:fixed;
  --markdown-modal-bottom: 0;
  position: fixed;
  z-index: 9900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  max-height: 95vh;
}
.noflex{
  flex: none !important;
}
.phone-flex{
  flex: 1 1 auto;
  width: 10%;
}
.w-20{
  width: 20%;
}
.w-20-b{
  width: calc(20% + 33px);
}
.p-1{
  padding: 1px !important;
}
</style>