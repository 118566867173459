<template>
  <div v-if="content === 'ticket'" v-esc:ticketEscId="escHandler" custom-event-id="ticketEscId" ref="backplate" class="backplate" :class="{'backplate-full': windowFullSize, 'backplate-height': !windowFullSize, 'transitioning': transitioning}" @mousemove="onMove" @mouseup="resize=false">
    <div class="d-flex justify-content-between pb-1 px-2 message-block-subject" ref="messagesubject" v-if="ticket">
      <span class="pe-2" style="left: 0.5em;">
        <span class="fa" role="button" :class="{'fa-window-maximize': !windowFullSize, 'fa-window-minimize': windowFullSize}" @click="toggleWindow"></span>
      </span>
      <div class="text-center d-flex" style="overflow-wrap: anywhere;">
        <string-trim v-if="ticket.name" :string="ticket.name" popper-placement="bottom" />
        <span v-else class="fst-italic">[Без темы]</span>
      </div>

      <div class="ps-1 d-inline-flex align-items-baseline">
        <span v-if="attachmentsFilter" class="position-absolute badge bg-secondary me-1" style="font-weight:normal; top: 0.5em; right: 2.5em;">
          Только вложения
          <i @click="setAttachmentsFilter(false)" class="fa fa-window-close pe-0 ps-1 header-remove-btn" style="cursor: pointer;"></i>
        </span>
        <span v-if="tasksFilter" class="position-absolute badge bg-secondary me-1" style="font-weight:normal; top: 0.5em; right: 2.5em;">
          Только задачи
          <i @click="setTasksFilter(false)" class="fa fa-window-close pe-0 ps-1 header-remove-btn" style="cursor: pointer;"></i>
        </span>
        <span class="dropstart">
          <span id="ticket-menu-button" role="button" class="fa fa-bars" data-bs-toggle="dropdown" aria-expanded="false"></span>
          <ticket-menu class="dropdown-menu ticket-menu" aria-labelledby="ticket-menu-button" />
        </span>
      </div>
    </div>
    <search-field
        v-if="showSearchField"
        ref="searchField"
    ></search-field>
    <pinned-messages
        v-if="pinnedMessages.length"
        ref="pinnedMessages"
    ></pinned-messages>
    <div class="col-12 p-1 messages-background" ref="messagesbox" @click="messagesFocus" :style="{'user-select': resize ? 'none': 'auto'}">
    <div v-if="attachmentsFilter && !loading && filteredMessages.length === 0" class="col-12 text-center h5">Вложений не найдено</div>
    <div v-if="tasksFilter && !loading && filteredMessages.length === 0" class="col-12 text-center h5">Задач не найдено</div>
    <div v-if="!loadingWithList && showSearchField && messages?.length === 0" class="col-12 text-center h5">Сообщений не найдено</div>
    <span v-if="messages !== null" tabindex="0" ref="messages" class="messages">
      <div v-if="loading" class="col-12 text-center bg-light">Loading...</div>
      <loading-list v-if="loadingWithList"></loading-list>
      <div v-show="textMessage !== null" class="col-12 text-center bg-light">{{textMessage}}</div>
      <span class="message-row-scroll" v-for="message in filteredMessages" :ref="message._id" :id="message._id" :key="message._id">

        <div :class="{'selected-message': highlighted.includes(message._id)}" >
          <event v-if="!attachmentsFilter && eventsBefore(message)" :events="eventsBefore(message)" />

          <comment
            v-if="message.type && message.type === 'comment'"
            :comment="message"
            :collapsed="attachmentsFilter"
            @editMessage="messageOnStartEdit"
          ></comment>

          <unconfirmed-message
            v-else-if="message.type && message.type === 'unconfirmed_message'"
            :message="message"
            :collapsed="attachmentsFilter"
            @createAsNew="createAsNew"
            @editMessage="messageOnStartEdit"
          ></unconfirmed-message>

          <event v-else-if="message.type && message.type === 'event'" :event="message" @update="updateMessage" />

          <step-hint v-else-if="message.type && message.type === 'step_hint'"  :message="message"></step-hint>

          <call-message v-else-if="message.type && message.type === 'call'"
                        :message="message"
                        @play="playCallRecord"
                        @moveMessages="moveMessages"
                        :hasPermission="callPermission"
                        :change-permission="changePermission" />

          <task :message="message" v-else-if="message.type && message.type === 'task'" @updateMessage="updateTask" @deleteMessage="deleteTask"></task>

          <message-to
            v-else-if="message.author"
            :message="message"
            :collapsed="attachmentsFilter"
            :changePermission="changePermission"
            @createAsNew="createAsNew"
            @moveMessages="moveMessages"
          ></message-to>

          <message-from v-else :message="message" :collapsed="attachmentsFilter" :changePermission="changePermission" @moveMessages="moveMessages"></message-from>

          <event v-if="!attachmentsFilter && eventsAfter(message)" :events="eventsAfter(message)" />

          <hr v-if="lastSeenMessageId == message._id && message._id !== lastMessage?._id" class="last-seen-message">
        </div>

      </span>
    </span>
      <div class="col-12 h-100 h5 align-items-center" style="display: flex" v-else>
        <div class="col-12 text-center" v-if="loading">Загрузка...</div>
        <div  class="col-12 text-center" v-if="textMessage !== null">{{textMessage}}</div>
      </div>
    </div>
    <div class="w-100 position-absolute opacity-0" ref="resizebox" style="border: 3px solid #9e9e9e; cursor: row-resize; " @mousedown="resize = true"></div>

    <template v-if="ticket !== null">
      <comment-form
          v-if="showCommentForm"
          :ticketId="ticketId"
          :draft="draft ?? null"
          @send="sendComment"
          @cancel="closeCommentForm"
          @resizeMessagesbox="resizeForms"
          @textareaFocus="textareaFocus"
          @textareaInput="textareaInput"
          ref="comment-form"

      ></comment-form>

      <add-task-form
          v-else-if="showAddTaskForm"
          :ticketId="ticketId"
          :ticket="ticket"
          :sending="sending"
          @setSending="setSending"
          @resizeMessagesbox="resizeForms"
          @textareaFocus="textareaFocus"
          @textareaInput="textareaInput"
          @updateTasks="updateTask"
          ref="answer-form"
      ></add-task-form>

      <update-form
          v-else-if="showEditForm"
          :ticket="ticket"
          @newMessage="pushNewMessages"
          @resizeMessagesbox="resizeForms"
          @textareaFocus="textareaFocus"
          @textareaInput="textareaInput"
          ref="answer-form"

      ></update-form>

      <answer-form
          v-else-if="userCanAnswer"
          :ticket="ticket"
          :draft="draft"
          :answer-type="answerType"
          @newMessage="pushNewMessages"
          @resizeMessagesbox="resizeForms"
          @textareaFocus="textareaFocus"
          @textareaInput="textareaInput"
          ref="answer-form"

      ></answer-form>

      <manage-unsorted
          v-else-if="!ticket?.deleted && (isProjectNull || ticket?.competitor === true)"
          :ticket="ticket"
          ref="unsorted"
      ></manage-unsorted>

      <div class="col-12" v-else-if="ticketIsClosed && openClosePermission">
        <div class="col-12 text-end" ref="lock_button">
          <button type="button"
                  :class="{'cursor-wait': sending}"
                  :disabled="sending"
                  v-enter.meta="reopenTicketCtrl"
                  v-enter.ctrl="reopenTicketCtrl"
                  @click="openReopenModal"
                  class="btn btn-primary col-12"
          >Переоткрыть</button>
        </div>
      </div>

      <div class="col-12" v-else-if="userCanTakeTicket && ticketType">
        <div class="col-12 text-end" ref="lock_button">
          <button type="button" :class="{'cursor-wait': sending}" :disabled="sending"  v-enter.meta="restoreTicketCtrl"  v-enter.ctrl="restoreTicketCtrl" @click="restoreTicket" class="btn btn-primary col-12" v-if="ticketType === 'trash'">Восстановить</button>
          <button type="button" :class="{'cursor-wait': sending}" :disabled="sending"  v-enter.meta="takeTicketInWorkCtrl" v-enter.ctrl="takeTicketInWorkCtrl"  @click="takeTicketInWork" class="btn btn-primary col-12" v-else>Взять в работу</button>
        </div>
      </div>

      <div class="col-12" v-else-if="ticketAssigned">
        <div class="col-12 text-center" ref="already_locked">
          <div class="alert alert-warning mb-0">Запрос в работе у {{assignedName}}<span v-if="assignedFromDate"> с {{assignedFromDate}}</span><span v-if="answerType === 'direct'"> [<a class="link-primary" role="button" v-esc:ticketEscId="escHandler"  v-enter.meta="takeTicketInWorkCtrl" v-enter.ctrl="takeTicketInWorkCtrl" @click="takeTicketInWork">забрать</a>]</span></div>
        </div>
      </div>

      <div class="player-wrapper" v-if="showPlayer">
        <audioplayer ref="player" />
      </div>
    </template>
  </div>

  <move-messages-modals
    v-if="messageToMove"
    :message="messageToMove"
    :ticket="ticket"
    @close="moveMessages(null)"
    @update="reloadMessagesOrSearch"
  ></move-messages-modals>

  <reopen-modal
    v-if="showReopenModal"
    @close="closeReopenModal"
    @success="reopenTicket"
  ></reopen-modal>

  <create-ticket-form v-if="content === 'createForm'" />

  <offers v-if="content === 'offers'" />
</template>

<script>
import MessageFrom from "./Message/From";
import MessageTo from "./Message/To";
import {CrmApi} from "../../library/CrmApi";
import TemplateSelect from "./TemplateSelect";
import Event from "./Event/Event";
import Comment from "./Comment/Comment";
import CommentForm from "./Comment/CommentForm";
import moment from "moment";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {UserPermission} from "../../users/UserPermission";
import UnconfirmedMessage from "./UnconfirmedMessage";
import Answer from "./Form/Answer";
import Update from "./Form/Update";
import Unsorted from "./Manage/Unsorted";
import StepHint from "./StepHint";
import {
  disableArrowKeysManagement, init,

  initAltFunctions,

} from "./List/ArrowScrolling";
import CreateTicketForm from "./Create/CreateTicketForm";
import CallMessage from "./CallsMessage/CallMessage";
import {getStatus} from "../../store/main/sip/PhonesFunctions";
import Audioplayer from "../media/Audioplayer";
import TicketMenu from "./TicketMenu";
import MoveMessagesModals from "./Message/Move/MoveMessagesModals";
import Offers from "./Offers/Offers";
import AddTaskForm from "./Task/AddTaskForm";
import Task from "./Task/Task";
import StringTrim from "../string/StringTrim";
import {Thread} from "../../library/api/Thread";
import PinnedMessages from "./PinnedMessages";
import {Ticket} from "../../library/api/Ticket";
import SearchField from "./SearchField";
import LoadingList from "./List/LoadingList";
import ReopenModal from "../tiketMenu/components/ReopenModal";


export default {
  name: "Ticket",
  components: {
    ReopenModal,
    PinnedMessages,
    StringTrim,
    TicketMenu,
    CallMessage,
    StepHint,
    TemplateSelect,
    MessageFrom,
    MessageTo,
    Event,
    Comment,
    CommentForm,
    UnconfirmedMessage,
    "answer-form": Answer,
    "update-form": Update,
    "manage-unsorted": Unsorted,
    CreateTicketForm,
    Audioplayer,
    MoveMessagesModals,
    Offers,
    AddTaskForm,
    Task,
    SearchField,
    LoadingList,
  },
  props:{
    'userid':{},
  },
  data: function (){
    return {
      "messages":null,
      "message":"",
      editMessage: '',
      "resize":false,
      "loading":true,
      "sending":false,
      "needUpdateScrollPosition":false,
      beforeMessagesLastLoadedID: null,
      "prevScrollPosition":null,
      ticketId: null,
      lastSeenMessageId: null,
      autoupdate: null,
      loadingMessages: false,
      loadingWithList: false,
      ticketHashMap: {},
      lastMessage:null,
      userId: null,
      answerType: null,
      draft: {},
      maxHeight:null,
      autoTextHeight:true,
      manualHeight: false,
      messagesBoxBeforeFocusHeight: null,
      canResize: false,
      minMessageBoxHeight:125,
      minTextBoxHeight:30,
      selectElem:null,
      textMessage:null,
      notEndCall:null,
      callPermission: false,
      scrollLastMessage: false,
      changePermission: false,
      messageToMove: null,
      transitioning: false,
      selectedTicket: null,
      highlighted: [],
      lastTimeDraftUpdated: null,
      escPressed: false,
      openClosePermission: false,
      searchTimeout: null,
      showReopenModal: false,
      canSearch: false,
      searchMessages: [],
    }
  },
  created() {
    this.api = new Thread(this.$route.params.id);
    this.autoupdate = null;
    this.ticketId = this.$route.params.id;
    this.lastSeenMessageId = null;
    this.lastMessage = null;
    this.$store.dispatch('sip/stopCheckCall')
    this.$store.commit('thread/setTicket', null);
    this.$store.commit('thread/setLastSeen', null);
    this.$store.dispatch('tickets/saveSelectedIds');
    this.$store.commit('tickets/clearSelect');
    this.$store.commit('setActiveWindow', 'ticket');
    if (typeof this.$route.query.search === 'string' && this.$route.query.search.trim() !== '') {
      this.$store.commit('thread/setShowSearchField', true);
      this.$store.commit('thread/setSearchString', this.$route.query.search.trim());
    } else {
      this.$store.commit('thread/setShowSearchField', false);
      this.$store.commit('thread/setSearchString', '');
    }
  },
  mounted() {
    this.enableArrowKeysManagement();
    init();

    this.$nextTick(() => {
      window.addEventListener('resize', this.calculateFormSize);
    })
    this.userId = this.$parent.$attrs.userid ?? this.userId ?? null;
    this.loading = true;
    this.maxHeight = $(this.$refs.backplate).height();
    this.textMessage = null;
    this.api.getTicketList({
      "_id": this.ticketId,
      "withDeleted":true,
    }, null).then((result) => {
      if( typeof result?.data?.data !== 'undefined'){
        if(result.data.data.length === 1) {
          this.ticket = result.data.data[0];
          this.loadPermissions();
          this.$store.dispatch('thread/loadTicketInfo');
          if(!this.isProjectNull) {
            this.loadDraft();
          }

          this.$store.dispatch('tickets/selectElement', this.ticket);
          if (typeof this.ticket.deleted !== 'undefined' && this.ticket.deleted === true) {
            this.$store.commit('thread/setTicketType', 'trash');
          } else if (this.isProjectNull) {
            this.$store.commit('thread/setTicketType', 'unsorted');
          } else {
            this.$store.commit('thread/setTicketType', 'ticket');
          }
          if(this.$store.getters['thread/isItDirectLink']){
            this.$store.dispatch('tickets/saveSelectedIds');
          }
          this.userId = result.data.auth_user ?? null;
          this.calculateFormSize();

          this.lastSeenMessageId = null;
          let scrollToMessage = null;
          if (Array.isArray(this.lastSeen) && this.lastSeen.length) {
            let userLastSeen = this.lastSeen.find((element) => {
              return window.getId(element._id) === window.userId;
            });
            if (userLastSeen && userLastSeen.messageid) {
              this.lastSeenMessageId = window.getId(userLastSeen.messageid);
              scrollToMessage = this.lastSeenMessageId;
            }
          }
          this.canSearch = true;
          if (typeof this.$route.query.search === 'string' && this.$route.query.search.trim() !== '') {
            this.searchInCurrentTicket();
          }

          if (typeof this.$route.query.message === 'undefined' || !this.isSelectMessageExistInTicket) {
            this.processScrollMessage(scrollToMessage);
          } else {
            this.setHighlight(this.$route.query.message);
            this.processScrollMessage(this.$route.query.message);
          }

          if (this.lastSeenMessageId === null) {
            this.setFirstBeforeMessages();
            this.setIntervalAutoupdate();
            this.setScrollListener();
          }

          if(this.getAnswerRef() !== null){
            this.$nextTick(()=>{
              let ref =  this.getAnswerRef();
              if(ref instanceof HTMLElement) {
                ref.focus();
              }
            })

          }
          this.loadTasks();
        }else {
          this.textMessage = 'Тикет не найден';
        }
      }else {
        this.textMessage = 'Тикет не найден';
      }
    }).catch(error => {
      if(!axios.isCancel(error)) {
        if (error?.response?.status === 404) {
          this.textMessage = 'Тикет не найден';
        } else {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }
      }
    }).finally(() => {
      this.loading = false;
      this.checkAbsentMessage();
    });
  },

  unmounted() {
    this.$store.dispatch('thread/closeThread');
    this.$store.commit('tickets/clearSelect');
    this.$store.commit('tickets/product/resetProducts')
    this.setMaximizeWindowSize(false);
    this.disableArrowKeysManagement()
    this.setAttachmentsFilter(false);
    this.setTasksFilter(false);
    this.$store.commit('thread/setMessages', null);
    this.$store.commit('thread/tasks/setTasks', []);
  },

  beforeUnmount() {
    clearInterval(this.autoupdate);
    this.$nextTick(() => {
      window.addEventListener('resize', this.calculateFormSize);
    })
    this.api.abort();
    this.$store.commit('thread/closeCommentForm');
    this.$store.commit('thread/closeEditForm');
    this.$store.commit('thread/setShowSearchField', false);
    this.$store.commit('thread/setSearchString', '');
    this.$store.commit('thread/setShowAddTaskForm', false);
    this.$store.commit('thread/setSelectMessageExistInTicket', false);
    this.$store.dispatch('sip/closeIfStart');
    this.$store.dispatch('sip/startCheckMessage', {message:undefined});
  },

  computed: {
    api: {
      set(api) { this.$store.commit('thread/setApi', api); },
      get() { return this.$store.getters['thread/getApi']; }
    },

    ticket:{
      set(value){
        this.$store.commit('thread/setTicket', value);
      },
      get(){
        return this.getTicket;
      }
    },

    filteredMessages() {
      let messages = this.isSearchingInTicket ? this.searchMessages : this.messages;

      if(this.attachmentsFilter) {
        messages = messages.filter(message => (message?.type !== 'call' &&
                                              typeof message.attachments === 'object' &&
                                              Array.isArray(message.attachments) &&
                                              message.attachments.filter(attachment => attachment?.inline !== true).length));
      }

      if (this.tasksFilter) {
        messages = messages.filter(message => message?.type === 'task');
      }

      return messages;
    },

    queryMessageId: function (){
      return this.$route?.query?.message;
    },

    selectedTicketId() {
      return this.selectedTicket ?? this.queryMessageId ?? null;
    },

    showCommentForm: function () {
      return this.threadState.showCommentForm;
    },

    showEditForm: function () {
      return this.threadState.showEditForm;
    },

    isProjectNull: function() {
      return !_.isObject(this.ticket?.project);
    },

    userCanAnswer: function () {
      return (this.ticket !== null &&
              typeof this.ticket.assigned !== 'undefined' &&
              this.userId === this.getAssignedId() &&
              !this.isProjectNull &&
              this.ticket?.competitor !== true);
    },

    isShowAnswerForm: function() {
      return this.refExists('answer-form');
    },

    uploadingAttachments: function () {
      return this.userCanAnswer && this.isShowAnswerForm && this.$refs['answer-form'].uploadingAttachments;
    },

    assignedFromDate: function () {
      return this.ticket.assigned != null && typeof this.ticket.assigned.date !== 'undefined' ?
          moment(this.ticket.assigned.date).format(window.dateFormatWeekDaySeconds) :
          "";
    },

    userCanTakeTicket: function () {
      return this.ticket !== null &&
             (typeof this.ticket.assigned === 'undefined' || this.ticket.assigned == null) &&
             this.answerType !== null &&
             !this.ticketIsClosed;
    },

    ticketAssigned: function () {
      return (this.ticket !== null && typeof this.ticket.assigned !== 'undefined' && this.ticket.assigned != null);
    },

    ticketIsClosed: function() {
      return this.ticket && this.ticket?.closed === true;
    },

    assignedName: function () {
      if (this.ticketAssigned) {
        return this.ticket.assigned.name ? this.ticket.assigned.name : this.ticket.assigned.login;
      } else {
        return '';
      }
    },

    ticketType: function() {
      return this.threadState.ticketType;
    },

    showPlayer: function() {
      return (Array.isArray(this.messages) && this.messages.filter(m => m.type === 'call').length > 0);
    },

    content() {
      let content;

      if(this.showCreateForm) {
        content = 'createForm';
      } else if(this.showOffersTable) {
        content = 'offers';
      } else {
        content = 'ticket';
      }

      return content;
    },

    isActive() {
      return this.getActiveWindow === 'ticket' && this.content === 'ticket';
    },

    isSearchingInTicket() {
      return this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '';
    },

    offersTicketScrollPosition: {
      set(pos) { this.$store.commit('thread/offers/setTicketScrollPosition', pos); },
      get() { return this.$store.getters['thread/offers/getTicketScrollPosition']; }
    },

    ...mapState( {
      threadState: state => state.thread,
    }),

    ...mapGetters({
      "getCurrentRouteParams":'leftMenu/getCurrentRouteParams',
      'getSelectedTemplate':'thread/getSelectedTemplate',
      'isSelectMessageExistInTicket':'thread/isSelectMessageExistInTicket',
      'getPaginate':'tickets/getPaginate',
      'showCreateForm': 'tickets/create/showCreateForm',
      'getTicket':'thread/getTicket',
      attachmentsFilter: 'thread/attachmentsFilter',
      tasksFilter: 'thread/tasksFilter',
      windowFullSize: 'thread/isTicketWindowFullSize',
      showHeadersForm: 'thread/isShowHeadersForm',
      showOffersTable: 'thread/offers/showOffersTable',
      scrollToMessage: "thread/scrollToMessage",
      scrollAndHighlight: 'thread/scrollAndHighlight',
      highlight: 'thread/highlight',
      showAddTaskForm: 'thread/showAddTaskForm',
      selectMessageStatus: 'thread/getSelectMessageStatus',
      lastSeen: 'thread/getLastSeen',
      isHasNewChangesForDraft: 'thread/isHasNewChangesForDraft',
      "showSignature": "thread/showSignature",
      "getActiveWindow": "getActiveWindow",
      "pinnedMessages": "thread/pinnedMessages",
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {
    reloadTicketData() {
      this.loadPermissions();
      this.$store.dispatch('thread/loadTicketInfo');
      if (typeof this.$refs['answer-form']?.$refs['template-attachment-panel'] !== 'undefined') {
        let panel = this.$refs['answer-form']?.$refs['template-attachment-panel'];

        if (typeof panel?.$refs['headers-form']?.loadData === 'function') {
          panel?.$refs['headers-form']?.loadData();
        }
      }
      this.$store.dispatch('thread/loadTicketTemplates');
      this.loadTasks();
    },
    loadPermissions() {
      let permissions = [
        "system.headers",
        "system.tasks_control",
        "system.edit_comment",
        "system.update_person",
        "system.tag_control",
      ];
      let relations = ['bitrix', 'billmanager', 'crm', 'history'];

      if(!this.isProjectNull) {
        let projectNames = [
          this.ticket.project.name
        ];
        permissions.push(this.ticket.project.name + ".attachments");
        permissions.push(this.ticket.project.name + ".documents");
        permissions.push(this.ticket.project.name + ".callrecord");
        permissions.push(this.ticket.project.name + ".message_source");
        permissions.push(this.ticket.project.name + ".headers_modify");
        permissions.push(this.ticket.project.name + ".headers");
        permissions.push(this.ticket.project.name + ".delete");
        permissions.push(this.ticket.project.name + ".ticket_delete");
        permissions.push(this.ticket.project.name + ".open_close");
        if (typeof this.ticket.assigned_projects !== 'undefined' && Array.isArray(this.ticket.assigned_projects)) {
          this.ticket.assigned_projects.forEach(project => {
            projectNames.push(project.name);
          });
        }
        projectNames.forEach(projectName => {
          ['answer', 'internal_answer', 'call', 'change', 'task_create', 'task_delete', 'setlot', 'open_close'].forEach(permissionName => {
            permissions.push(projectName + '.' + permissionName);
          });
        });
      } else {
        permissions.push("system.attachments");
        permissions.push("system.callrecord");
        permissions.push("system.read_pending");
        permissions.push("system.delete_pending");
        permissions.push("system.answer_pending");
      }

      relations.forEach((serviceName) => {
        permissions.push("system.person_relations{" + serviceName + "}");
      });

      UserPermission.getPermissions(permissions).then((p_result) => {
        permissions.filter(p => p.indexOf('.internal_answer') > 0).some(permission => {
          if (p_result[permission]) {
            this.answerType = 'internal';
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.answer') > 0).some(permission => {
          if (p_result[permission]) {
            this.answerType = 'direct';
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.change') > 0).some(permission => {
          if (p_result[permission]) {
            this.changePermission = true;
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.call') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserCanCall', true);
            return true;
          }
        });

        permissions.filter(p => p.indexOf('.task_create') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserTaskCreatePermission', true);
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.task_delete') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserTaskDeletePermission', true);
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.setlot') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserCanSetLot', true);
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.attachments') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserAttachmentPermission', true);
          } else {
            this.$store.commit('thread/setUserAttachmentPermission', false);
          }
          return true;
        });
        permissions.filter(p => p.indexOf('.documents') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserDocumentsPermission', true);
          } else {
            this.$store.commit('thread/setUserDocumentsPermission', false);
          }
          return true;
        });
        this.openClosePermission = false;
        this.$store.commit('thread/setUserOpenClosePermission', false);
        permissions.filter(p => p.indexOf('.open_close') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserOpenClosePermission', true);
            this.openClosePermission = true;
            return true;
          }
        });
        permissions.filter(p => p.indexOf('.callrecord') > 0).some(permission => {
          if (p_result[permission]) {
            this.$store.commit('thread/setUserCallRecordPermission', true);
            this.callPermission = true;
          } else {
            this.$store.commit('thread/setUserCallRecordPermission', false);
            this.callPermission = false;
          }
          return true;
        });
        if (p_result["system.tasks_control"]) {
          this.$store.commit('thread/tasks/setCanTasksControl', true);
        } else {
          this.$store.commit('thread/tasks/setCanTasksControl', false);
        }
        let setUserCanChange = false;
        if (typeof this.ticket?.project?.name !== 'undefined') {
          setUserCanChange = p_result[this.ticket.project.name + '.answer'] || p_result[this.ticket.project.name + '.internal_answer'];
        }
        let setUserRelationPermission = false;
        permissions.filter(p => p.indexOf('system.person_relations') > -1).some((item) => {
          if (p_result[item]) {
            setUserRelationPermission = true;
          }
          return p_result[item];
        })
        this.$store.commit('thread/setUserRelationPermission', setUserRelationPermission);
        this.$store.commit('thread/setUserCanChange', setUserCanChange);
        this.$store.commit('thread/setUserCanAnswer', this.answerType ?? false);
      });
    },
    checkAbsentMessage() {
      if (typeof this.$route.query.message !== 'undefined' && !this.isSelectMessageExistInTicket) {
        if (this.selectMessageStatus === false) {
          this.$store.commit('errorPush', 'Сообщение не найдено');
        } else if(typeof this.selectMessageStatus === 'string') {
          this.$store.commit(
              'htmlErrorPush',
              'Сообщение находится в другом тикете <a href="' +
              this.selectMessageStatus +
              '" target="_blank">&#10095;&#10095;</a>'
          );
        }
      }
    },

    eventsBefore(message) {
      return message?.events != null && typeof message.events === 'object' ? message.events.filter(event => {
        return event.position !== 'after' && (event.position === 'before' || moment(event.date) < moment(message.created));
      }) : null;
    },

    eventsAfter(message) {
      return message?.events != null && typeof message.events === 'object' ? message.events.filter(event => {
        return event.position !== 'before' &&  (event.position === 'after' || moment(event.date) >= moment(message.created));
      }) : null;
    },

    getFiltersForMessageRequest(filters = null) {
      filters = filters ?? {};

      if(this.attachmentsFilter) {
        filters.only_attachments = true;
      }

      if (this.tasksFilter) {
        filters.only_tasks = true;
      }

      return Object.keys(filters).length ? filters : undefined;
    },

    playCallRecord: function(fileLink) {
      this.$refs.player.setFile(fileLink);
    },

    processScrollMessage(messageId) {
      this.startWithMessage(messageId).then(() => {
        let line = this.messages.findIndex(message => window.getId(message._id) === messageId);
        let rows = document.getElementsByClassName('message-row-scroll');
        if (rows.length) {
          if (line === -1 || typeof rows[line] === 'undefined' || typeof rows[line].scrollIntoView === 'undefined') {
            line = rows.length - 1;
          }
          if (line === rows.length - 1) {
            this.scrollLastMessage = true;
          }
          this.$nextTick(() => {
            let scrollFn = () => {
              rows[line].scrollIntoView({
                behavior: 'auto',
                block: 'start',
                inline: "nearest"
              });
            }
            this.selectElem = line;
            const transitions = this.$refs.messagesbox.getAnimations().filter((anim) => anim instanceof CSSTransition);
            if (transitions.length) {
              this.$refs.messagesbox.addEventListener('transitionend', scrollFn, {once: true});
            } else {
              scrollFn();
            }
          });
        }
      }).catch(error => {
        if(!axios.isCancel(error)) {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          this.setFirstBeforeMessages();
        }
      }).finally(() => {
        this.setIntervalAutoupdate();
        this.setScrollListener();
      });
    },
    disableArrowKeysManagement:function (){
      disableArrowKeysManagement()
    },
    enableArrowKeysManagement:function (){
      initAltFunctions(  this.arrowScrollDown ,  this.arrowScrollUp );
    },

    arrowScrollDown:function (){
      this.$refs.messagesbox.scrollBy(0,30)
    },
    arrowScrollUp:function (){
      this.$refs.messagesbox.scrollBy(0,-30);
    },
    isScrolledIntoView:function(elem) {
      var docViewTop = $(window).scrollTop();
      var docViewBottom = docViewTop + $(window).height();

      var elemTop = $(elem).offset().top;
      var elemBottom = elemTop + $(elem).height();

      return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    },
    setFirstBeforeMessages(scrollToLast = false) {
      this.api.getTicketAndMessagesBefore(undefined, this.getFiltersForMessageRequest())
              .then(result => {
                this.addBeforeMessages(result.data);
                this.calculateFormSize();
                if(scrollToLast) {
                  this.$nextTick(() => {
                    this.scrollToLast();
                  });
                }
              }).catch(this.catch)
              .finally(() => {
                this.loading = false;
                this.loadingWithList = false;
                if(this.messages === null || this.messages.length === 0 ){
                  this.textMessage = 'Сообщения отсутствуют'
                }
              });
    },

    setIntervalAutoupdate:function (){
      if(this.autoupdate === null){
        this.autoupdate = setInterval(this.getNewMessages, 1000);
      }
    },

    setScrollListener:function () {
      if (this.$refs.messagesbox instanceof HTMLElement) {
        this.$refs.messagesbox.addEventListener("scroll", () => {
          if( !this.needUpdateScrollPosition && !this.loading &&
              (this.$refs.messagesbox.scrollTop / (this.$refs.messagesbox.scrollHeight / 100)) < 10) {

            this.loadBeforeMessage();
          }
          if( this.needUpdateScrollPosition ) {
            this.needUpdateScrollPosition = false;
          }
        });
      }
    },

    startWithMessage(id){
      return new Promise((resolve, reject)=>{
        let actions = [];
        actions.push(this.api.getTicketAndMessagesBefore(id, {notStrictly:true}));
        actions.push(this.api.getTicketAndMessagesAfter(id, {onpage:'all'}));

        Promise.all(actions).then((result)=>{
          let resultBefore = result[0].data;
          this.addBeforeMessages(resultBefore);
          let resultAfter = result[1].data;
          this.addAfterMessages(resultAfter, true);

          // фокус на окно с сообщениями
          if(!this.userCanAnswer) {
            this.$nextTick(() => {
              if(typeof this.$refs?.messages?.focus === 'function') {
                this.$refs.messages.focus();
              }
            });
          }

          resolve(result);
        }).catch(this.catch);
      });

    },

    addAfterMessages(data , scrollOff = false){
      if(
          typeof data.messages.data === 'object' &&
          data.messages.data.length > 0
      ) {
        this.pushNewMessages( data.messages.data.reverse() , scrollOff);
      }
      if(typeof data.ticket === 'object') {
        this.$nextTick(this.calculateFormSize);
        this.ticket = data.ticket;
      }
      this.loadingMessages = false;
    },

    addBeforeMessages(data){

      if( typeof data.messages.data === 'object' ) {
        this.messages = data.messages.data.reverse();
        this.lastMessage =   this.messages[  this.messages.length - 1];
        this.messages.forEach((message) => {
          if(typeof this.ticketHashMap[message._id] === 'undefined'){
            this.ticketHashMap[message._id] = 1;
          }
        });
        this.loading = false;
      }
      if( typeof data.ticket === 'object' ) {
        this.ticket = data.ticket;

        if(this.threadState.ticket === null) {

          this.$store.dispatch('tickets/selectElement', this.ticket);
        }

        if (this.threadState.ticketType === null) {
          if (typeof data.ticket.deleted !== 'undefined' && data.ticket.deleted === true) {
            this.$store.commit('thread/setTicketType', 'trash');
          } else if (typeof data.ticket.project !== 'object' || data.ticket.project.length === 0) {
            this.$store.commit('thread/setTicketType', 'unsorted');
          } else {
            this.$store.commit('thread/setTicketType', 'ticket');
          }
        }
      }
    },

    loadTasks() {
      this.$store.dispatch('thread/tasks/init', {id: this.ticketId});
    },

    updateTasks() {
      this.$store.dispatch('thread/tasks/loadTasks', {id: this.ticketId});
    },

    ...mapMutations({
      "selectTemplate":  'thread/selectTemplate',
      toggleWindowSize: 'thread/toggleTicketWindowSize',
      setMaximizeWindowSize: 'thread/setTicketWindowSize',
      setAttachmentsFilter: 'thread/setAttachmentsFilter',
      setTasksFilter: 'thread/setTasksFilter',
      setScrollToMessage: "thread/setScrollToMessage",
      setScrollAndHighlight: "thread/setScrollAndHighlight",
      setHighlight: "thread/setHighlight",
      setHasNewChangesForDraft: "thread/setHasNewChangesForDraft",
    }),

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
      "updateDraft": "thread/updateDraft",
    }),

    toggleWindow() {
      this.transitioning = true;
      this.toggleWindowSize();
      setTimeout(this.calculateFormSize, 200);
      setTimeout(() => {
        this.transitioning = false;
      }, 500);
    },

    textareaFocus() {
      if (this.manualHeight === false) {
        this.resizeMessagesbox(0);
        this.calculateFormSize();
      } else if (this.messagesBoxBeforeFocusHeight && (this.messagesBoxBeforeFocusHeight !== this.messagesbox())) {
        this.resizeMessagesbox(this.messagesBoxBeforeFocusHeight - this.messagesbox());
        this.calculateFormSize();
      }
      this.canResize = true;
    },

    messagesFocus() {
      let form = this.getAnswerRef();
      let height = 40;
      if (form && this.canResize) {
        this.messagesBoxBeforeFocusHeight = this.manualHeight ? this.messagesbox() : 0;
        let delta = $(form).outerHeight() > height ? $(form).outerHeight() - height : 1;
        this.resizeTextarea(delta);
        this.resizeMessagesbox(delta);
        // this.calculateFormSize();
        this.canResize = false;
      }
    },

    textareaInput() {
      if (this.manualHeight === false) {
        this.autoTextHeight = true;
        this.calculateFormSize();
      }
    },

    messageOnStartEdit() {
      if (this.manualHeight === false) {
        let form = this.getAnswerRef();
        this.canResize = true;
        this.autoTextHeight = true;
        if (form) {
          form.focus();
        }
      }
    },

    resizeForms(px) {
      if (this.manualHeight === false) {
        this.resizeMessagesbox(px);
      }
    },

    resizeMessagesbox: function(px) {
      if (px) {
        this.autoTextHeight = false;
        let msgbox = $(this.$refs.messagesbox);
        this.animateHeight(this.$refs.messagesbox, msgbox.outerHeight() + px);
      } else {
        this.autoTextHeight = true;
      }
    },
    resizeTextarea(px) {
      if (px && this.getAnswerRef()) {
        let textarea = $(this.getAnswerRef());
        this.animateHeight(textarea, textarea.outerHeight() - px);
      }
    },
    backplate:function (value = null){
      if(!this.refExists('backplate')){
        return 0;
      }
      if(value){
        $(this.$refs.backplate).height(value)
      }
      return $(this.$refs.backplate).height();
    },
    comment_textarea:function (value = null){
      if(!this.refExists('messagesbox')){
        return 0;
      }
      if(value){
        $(this.$refs["comment-form"].$refs['comment-textarea']).height(value)
      }
      return $(this.$refs["comment-form"].$refs['comment-textarea']).height();
    },
    messagesbox:function (value){
      if(!this.refExists('messagesbox')){
        return 0;
      }
      if(value){
        $(this.$refs.messagesbox).outerHeight(value)
      }
      return $(this.$refs.messagesbox).outerHeight();
    },
    comment_quote_block:function (value){
      if(!this.refExists('comment-form')){
        return 0;
      }
      if(value){
        $(this.$refs["comment-form"].$refs['comment-quote-block']).height(value);
      }
      return $(this.$refs["comment-form"].$refs['comment-quote-block']).height();
    },

    comment_attachments_block:function (value) {
      if(!this.refExists('comment-form')){
        return 0;
      }
      if(value){
        $(this.$refs["comment-form"].$refs['comment-attachments-block']).height(value);
      }
      return $(this.$refs["comment-form"].$refs['comment-attachments-block']).height();
    },
    comment_button:function (value){
      if(!this.refExists('comment-form')){
        return 0;
      }
      if(value){
        $(this.$refs["comment-form"].$refs.comment_button).height(value)
      }
      return  $(this.$refs["comment-form"].$refs.comment_button).height()
    },

    searchFieldHeight: function (value) {
      if (!this.refExists('searchField')) {
        return 0;
      }
      if (value) {
        $(this.$refs["searchField"].$el).height(value);
      }
      return $(this.$refs["searchField"].$el).height();
    },

    pinnedMessagesHeight: function (value) {
      if (!this.refExists('pinnedMessages')) {
        return 0;
      }
      if (value) {
        $(this.$refs["pinnedMessages"].$el).height(value);
      }
      return $(this.$refs["pinnedMessages"].$el).height();
    },

    answer_button:function (value){
      if(!this.refExists('answer-form')){
        return 0;
      }
      if(value){
        $(this.$refs["answer-form"].$refs.answer_button).height(value)
      }
      return  $(this.$refs["answer-form"].$refs.answer_button).height()
    },

    answer_message:function (value){
      if(!this.refExists('answer-form')){
        return 0;
      }
      if(value){
        $(this.$refs["answer-form"].$refs.answer_message).height(value)
      }

      return $(this.$refs["answer-form"].$refs.answer_message).height();
    },

    messagesubject:function (value){
      if(!this.refExists('answer-form')){
        return $(this.$refs.messagesubject).height() > 24 ? $(this.$refs.messagesubject).height() - 24 : 0;
      }
      if(value){
        $(this.$refs.messagesubject).height(value)
      }

      return $(this.$refs.messagesubject).height();
    },

    templateselect:function (){
      if(!this.refExists('answer-form')){
        return 0;
      }
      return this.$refs["answer-form"].$refs.templateselect.clientHeight;
    },
    quote_message:function (){
      if(!this.refExists('answer-form')){
        return 0;
      }
      return this.$refs["answer-form"].$refs['quote-message']?.clientHeight ?? 0;
    },

    already_locked:function (value) {
      if(!this.refExists('already_locked')){
        return 0;
      }
      if(value){
        $(this.$refs.already_locked).height(value)
      }

      return  $(this.$refs.already_locked).height();
    },
    unsorted:function (value){
      if(!this.refExists('unsorted')){
        return 0;
      }
      if(value){
        $(this.$refs.unsorted.$refs.add_to_project).height(value)
      }

      return  $(this.$refs.unsorted.$refs.add_to_project).height();
    },
    lock_button:function (value){
      if(!this.refExists('lock_button')){
        return 0;
      }
      if(value){
        $(this.$refs.lock_button).height(value)
      }
      return $(this.$refs.lock_button).height();
    },
    signatureTextarea() {
      if(!this.refExists('answer-form')){
        return 0;
      }
      return typeof this.$refs["answer-form"].$refs.signatureTextarea !== 'undefined' && this.$refs["answer-form"].$refs.signatureTextarea ?
             this.$refs["answer-form"].$refs.signatureTextarea.clientHeight
             : 0;
    },
    getExpectedAnswerMessageHeight() {
      let answer_message = this.showCommentForm ? this.comment_textarea() : this.answer_message();
      let box = this.getAnswerRef();
      if (box) {
        let boxHeight = $(box).height();
        $(box).height(0);
        answer_message = box.scrollHeight;
        $(box).height(boxHeight);
      }
      return answer_message;
    },

    getWindowCalculation(value){
        let fix = 5;
        if (this.showCommentForm) {
          fix = 30;
        }

        return this.backplate() -
            value -
            this.comment_button() -
            this.pinnedMessagesHeight() -
            this.searchFieldHeight() -
            this.answer_button() -
            this.messagesubject() -
            this.comment_attachments_block() -
            this.comment_quote_block() -
            this.templateselect() -
            this.already_locked() -
            this.unsorted() -
            this.lock_button() -
            this.quote_message() -
            this.signatureTextarea() -
            fix;
    },

    getAnswerRef(){
      let ref = null;
      if(this.refExists("comment-form") && typeof this.$refs["comment-form"].$refs['comment-textarea'] !== 'undefined'
          &&  this.$refs["comment-form"].$refs['comment-textarea'] !== null){
        ref = this.$refs["comment-form"].$refs['comment-textarea']
      }
      if(this.refExists("answer-form")   && typeof this.$refs["answer-form"].$refs.answer_message !== 'undefined'
          && this.$refs["answer-form"].$refs.answer_message !== null
        ){
        ref = this.$refs["answer-form"].$refs.answer_message;
      }
      return ref;
    },
    calculateFormSize: function () {
      let messagesbox;
      let answer_message;
      answer_message = this.getExpectedAnswerMessageHeight();
      messagesbox = this.getWindowCalculation(answer_message);

      if (this.minMessageBoxHeight > messagesbox) {
        messagesbox = this.minMessageBoxHeight;
        answer_message = this.getWindowCalculation(messagesbox);
      }

      if (this.minTextBoxHeight > answer_message) {
        answer_message = this.minTextBoxHeight;
        messagesbox = this.getWindowCalculation(answer_message);
      }

      if(this.getAnswerRef() !== null){

        if ($(this.getAnswerRef()).outerHeight() !== answer_message) {
          this.animateHeight(this.getAnswerRef(), answer_message);
        }

      }else {
        messagesbox += (answer_message - 23);
      }

      let messageBoxElement = this.$refs.messagesbox;
      let needToScrollToBottom = false;
      if (messageBoxElement && $(this.$refs.messagesbox).outerHeight() !== messagesbox) {
        if (messageBoxElement.scrollHeight - messageBoxElement.scrollTop - messageBoxElement.offsetHeight <= Math.abs($(messageBoxElement).height() - messagesbox) + 10) {
          needToScrollToBottom = true;
        }
        this.animateHeight(this.$refs.messagesbox, messagesbox);
        if (needToScrollToBottom) {
          messageBoxElement.scrollTop = messageBoxElement.scrollHeight;
        }
      }


      let style = CSS.supports('overflow', 'overlay') ? 'overlay' : 'auto';
      if(this.autoTextHeight) {
        if(this.getAnswerRef()?.scrollHeight < this.getWindowCalculation(this.minMessageBoxHeight )){
          style = 'hidden';
        }

      }

      this.setOverflow(style);

      if (this.scrollLastMessage && this.$refs.messagesbox) {
        let messageBox = this.$refs.messagesbox;
        if (messageBox.scrollTop + messageBox.clientHeight - messageBox.scrollHeight < 0) {
          messageBox.scrollTop = messageBox.scrollHeight;
          this.scrollLastMessage = false;
        }
      }
    },

    animateHeight(element, value, animate = false) {
      if (animate && !$(element).is(':animated')) {
        $(element).animate({height: value + 'px'});
      }
      $(element).outerHeight(value);
    },

    getNewMessages: function (){
      if (
          this.isHasNewChangesForDraft &&
          typeof this.getDraft() !== 'undefined' &&
          (
              this.lastTimeDraftUpdated == null ||
              (new Date()).getTime() - this.lastTimeDraftUpdated > 3000
          )
      ) {
        this.lastTimeDraftUpdated = (new Date()).getTime();
        this.setHasNewChangesForDraft(false);
        this.updateDraft({draft:this.getDraft()});
      }

      if(
          this.content === 'ticket' &&
          this.messages &&
          this.messages.length > 0 &&
          !this.loadingMessages &&
          !this.loading &&
          !this.loadingWithList &&
          !this.sending
      ) {
        this.loadingMessages = true;
        let lastMessage = (this.lastMessage !== null) ? this.lastMessage : this.messages[this.messages.length - 1];
        let filters = undefined;
        if (this.notEndCall !== null) {
          lastMessage = this.notEndCall;
          filters = {notStrictly: true};
        }
        if (typeof this.ticket?.updated_at !== 'undefined' && this.ticket?.updated_at !== null) {
          if (typeof filters === 'undefined' || filters === null) {
            filters = {};
          }
          filters.updated_at = this.ticket?.updated_at;
        }

        this.api.getMessagesAfterWithWait(lastMessage?._id, this.getFiltersForMessageRequest(filters)).then((result) => {
          if (
              typeof result.data.messages.data === 'object' &&
              Array.isArray(result.data.messages.data) &&
              result.data.messages.data.length > 0
          ) {
            if (this.isSearchingInTicket) {
              this.searchInCurrentTicket();
            }
            this.pushNewMessages(result.data.messages.data.reverse());
            let hasTasks = result.data.messages.data.filter(message => message?.type === 'task').length !== 0;
            if (hasTasks) {
              this.updateTasks();
            }
          }
          if (typeof result.data.ticket === 'object') {
            this.$nextTick(this.calculateFormSize);
            this.ticket = result.data.ticket;
          }
          if (typeof result.data.person === 'object' && result.data.person !== null) {
            this.$store.commit('thread/setPerson', result.data.person);
          }
        }).catch(error => {
          if(
              !axios.isCancel(error) &&
              error?.message !== 'Request aborted' &&
              error?.response?.status !== 404
          ) {
            this.$store.commit('errorPush' , CrmApi.getErrorMessage(error) );
          }
        }).finally(() => this.loadingMessages = false)
      }
    },

    onMove: function (event) {
      if (this.resize) {
        this.autoTextHeight = false;
        this.manualHeight = true;
        let yMove = event.clientY - $(this.$refs.resizebox).offset().top;
        let textarea = $(this.getAnswerRef());
        let messageBoxHeight = this.messagesbox();
        if (textarea.length && yMove > 0) {
          let maxMessageBoxHeight = this.getWindowCalculation(40);
          if ((yMove + messageBoxHeight) > maxMessageBoxHeight) {
            yMove = maxMessageBoxHeight - messageBoxHeight;
          }
        }
        if (yMove < 0) {
          if (yMove + messageBoxHeight < 20) {
            yMove = 0;
          }
        }
        if (yMove) {
          this.resizeTextarea(yMove);
          this.resizeMessagesbox(yMove);
        }
        if (this.messagesBoxBeforeFocusHeight) {
          this.messagesBoxBeforeFocusHeight = this.messagesbox();
        }
      }
    },
    scrollToLast:function (){
      let rows = document.getElementsByClassName('message-row-scroll');
      if (typeof rows[rows.length-1] !== 'undefined' && typeof rows[rows.length-1].scrollIntoView !== 'undefined') {
        this.$nextTick(() => {
          rows[rows.length-1].scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: "nearest"
          });
        });
      }
    },

    escHandler() {
      if (this.isActive) {
        if(this.windowFullSize) {
          this.setMaximizeWindowSize(false);
        } else {
          if (this.escPressed) {
            this.escPressed = false;
            this.redirectToTickets();
          } else {
            this.escPressed = true;
            setTimeout(() => {
              this.escPressed = false
            }, 500);
          }
        }
      }
    },

    sendComment: function(params) {
      this.sending = true;

      params.thread = this.ticket._id;
      return CrmApi.call('/thread/comment', params).then((response) => {
        this.commentDraft = '';
        this.pushNewMessages([response.data.message]);
        this.$store.commit('thread/closeCommentForm');
      }).catch(this.catch)
        .finally(() => { this.sending = false; this.calculateFormSize();});
    },

    closeCommentForm: function() {
      this.$store.commit('thread/closeCommentForm');
    },

    takeTicketInWorkCtrl: function() {
      if (this.isActive) {
        this.takeTicketInWork();
      }
    },

    takeTicketInWork: function() {
      if( this.sending){
        return;
      }
      this.sending = true;
       return this.api.lock().then((response) => {
        if(typeof response.data.success !== 'undefined') {
          this.ticket = response.data.data;
          $(this.$refs.messagesbox).height("60%");
          this.$nextTick(function() {
            this.$refs['answer-form'].$refs['template-attachment-panel'].$refs['headers-form'].checkPermission(this.ticket);
            this.calculateFormSize();
          });
        }
      }).finally(() => {
        this.sending = false;
         if(this.getAnswerRef() !== null){
           this.$nextTick(()=>{
             let ref =  this.getAnswerRef();
             ref.focus();
           })
         }
      });
    },

    restoreTicketCtrl: function() {
      if (this.isActive) {
        this.restoreTicket();
      }
    },

    restoreTicket: function() {
      if(this.sending){
        return;
      }
      this.sending = true;

      this.$store.dispatch('tickets/restoreTickets', {
        ticketIds: [this.ticketId],
        route: () => {
          this.$store.commit('thread/setTicketType', 'ticket');
          this.$store.dispatch('tickets/selectElement', this.ticket);
          this.sending = false;
        },
      });
    },

    reopenTicketCtrl: function() {
      if (this.isActive) {
        this.openReopenModal();
      }
    },

    reopenTicket: function() {
      this.sending = true;
      Ticket.reopenTickets([this.ticketId]).then(() => {
        this.closeReopenModal();
      }).finally(() => {
        this.sending = false;
      })
    },

    openReopenModal() {
      this.showReopenModal = true;
      this.$store.commit('setActiveWindow', 'reopenTicket');
    },

    closeReopenModal() {
      this.showReopenModal = false;
      this.$store.commit('removeActiveWindow', 'reopenTicket');
    },

    getBaseObjectId: function ( id ){
      return typeof id.$oid !== 'undefined' ? id.$oid : id;
    },


    setMessageText: function ( text ){
      this.message = text;
    },
    // Для исходящих проверяем что звонок не завершен или что он завершен но ждем запись
    isOutgoingCallMessageNeedCheck: function (message){
      let isOutgoingCallMessageNeedCheck = false;
      if (message?.type === 'call' && typeof message?.headers !== 'undefined' && message?.headers !== null) {
        if(typeof message.author !== 'undefined' && getStatus(message.headers.status) !== getStatus('end')){
          isOutgoingCallMessageNeedCheck = true;
        }

        if( message.attachments !== undefined && message.attachments !== null &&
            getStatus(message.headers.status) === getStatus('end') &&
            typeof message.author !== 'undefined' && ( message?.body === undefined ||
            message.body == null|| message.body ==='') && message.headers?.completed  !== undefined){
          if(moment(message.headers?.completed).unix() - moment(message.headers?.created_at).unix()  > 10 ){
            isOutgoingCallMessageNeedCheck =  moment().unix() - moment(message.headers?.completed).unix() <= 600
          }

        }
      }
      return  isOutgoingCallMessageNeedCheck;
    },
    pushNewMessages: function( messages , scrollOff = false ){
      let before = this.messages?.length;
      let messagesForUpdate = {};
      for(let key in messages){
        let message = messages[key];
        if(typeof this.ticketHashMap[message._id] === 'undefined') {
          if(
              message.type === 'bounce' &&
              typeof message.replyto !== 'undefined' &&
              message.replyto != null
          ) {
            // это bounce, ищем оригинальное сообщение
            let targetMsg = this.messages.filter(msg => msg._id === message.replyto);
            if(targetMsg.length) {
              // прописываем bounce чтобы вывести предупреждение что сообщение не дошло
              targetMsg[0].bounce = message._id;
            }
          }

          this.ticketHashMap[message._id] = 1;
          this.messages.push(message);
        }else {
          messagesForUpdate[window.getId(message._id)] = message;
        }
        this.lastMessage = message;
      }

      // ** обновляем messages
      for (let key in this.messages ){
        let message = this.messages[key];
        if(typeof  messagesForUpdate[window.getId(message._id)] !== 'undefined'){
          let updatedMessage = messagesForUpdate[window.getId(message._id)];
          if(message?.type === 'call' && (typeof message.body === 'undefined' || message.body === null)
          && (updatedMessage.body !== null &&  typeof updatedMessage.body !== 'undefined')
              && typeof this.$refs[message._id] !== 'undefined'
          ){
              this.$refs[message._id][0].scrollIntoView({
              behavior: 'auto',
              block: 'start',
              inline: "nearest"
            });
          }
          this.messages[key] = updatedMessage
        }
      }

      if(!scrollOff){


        let messageBox = this.$refs.messagesbox;
        if (messageBox && messageBox.scrollTop < 0) {
          let prevScrollHeight = messageBox.scrollHeight;
          this.$nextTick(()=>{
            messageBox.scrollTop = messageBox.scrollTop -  messageBox.scrollHeight + prevScrollHeight;
          })
        }

        if(before !== this.messages.length){
          let rows = document.getElementsByClassName('message-row-scroll');
          if (typeof rows[rows.length - 1] !== 'undefined' && typeof rows[rows.length - 1].scrollIntoView !== 'undefined') {
            this.$nextTick(() => {
              rows[rows.length - 1].scrollIntoView({
                behavior: 'auto',
                block: 'start',
                inline: "nearest"
              });
            });
          }
        }

      }

    },

    loadBeforeMessage: function (){
      let messageId = (this.messages !== null && typeof this.messages[0] !== 'undefined') ? this.messages[0]._id : null;

      if(this.loading || this.sending || messageId === this.beforeMessagesLastLoadedID || this.isSearchingInTicket) {
        return;
      }

      this.loading = true;
      this.api.getTicketAndMessagesBefore(messageId, this.getFiltersForMessageRequest(), this.getDraft())
            .then(result => {
              if(typeof result.data.messages.data === 'object') {
                let messages = result.data.messages.data;
                let messagesForUpdate = {};
                let scrollHeight = this.$refs.messagesbox.scrollHeight;

                messages.forEach(message => {

                  if(typeof this.ticketHashMap[message._id] === 'undefined') {
                    this.ticketHashMap[message._id] = 1;
                    this.messages.unshift(message);
                  } else {
                    messagesForUpdate[ window.getId(message._id) ] = message;
                  }

                });

                for(let key in this.messages) {
                  let message = this.messages[key];
                  if(typeof messagesForUpdate[ window.getId(message._id) ] !== 'undefined') {
                    this.messages[key] = messagesForUpdate[ window.getId(message._id) ];
                  }
                }

                if(messages.length > 0) {
                  this.needUpdateScrollPosition = true;
                }

                this.$nextTick(() => {
                  this.$refs.messagesbox.scrollTop = this.$refs.messagesbox.scrollTop + (this.$refs.messagesbox.scrollHeight - scrollHeight);
                });
              }

              if(typeof result.data.ticket === 'object') {
                this.ticket = result.data.ticket;
              }

              this.beforeMessagesLastLoadedID = messageId;
            }).catch(this.catch)
              .finally(() => this.loading = false);
    },

    getAssignedId: function() {
      let aId = null
      if( this.ticket.assigned != null ){
        aId = this.ticket.assigned._id.$oid ?? this.ticket.assigned._id ?? null;
      }
      return aId;
    },

    getDraft: function() {
      let draft = undefined;
      if(this.userCanAnswer && !this.showEditForm) {
        draft = {};

        if(this.refExists('answer-form')) {
          let answerForm = this.$refs["answer-form"];
          draft.body = answerForm.message ?? null;
          if(typeof answerForm.$refs['template-attachment-panel'] !== 'undefined' && answerForm.$refs['template-attachment-panel'] !== null) {

            draft.attachments = answerForm.$refs['template-attachment-panel'].savedAttachments;

            if(answerForm.$refs['template-attachment-panel'].isHeaderChangesExists()) {
              // добавляем заголовки из формы
              draft = { ...draft, ...answerForm.$refs['template-attachment-panel'].getHeaders(true) };
            }

          }
        }

        if(this.showCommentForm) {
          draft.comment = this.$refs['comment-form'].comment;
          this.commentDraft = draft.comment;
        }
        draft.template = this.getSelectedTemplate;

      }


      return draft;
    },

    createAsNew: function(message) {
      let fillForm = () => {
        this.$refs['answer-form'].message = message.body;
        this.$refs['answer-form'].$refs['template-attachment-panel'].clearAttachments();
        if(typeof message.attachments !== 'undefined' && Array.isArray(message.attachments)) {
          let attachments = [ ...message.attachments ];
          this.$refs['answer-form'].$refs['template-attachment-panel'].addExistsAttachments(attachments, message._id);
        }
      }

      if(this.userCanAnswer) {
        fillForm();
      } else {
        this.takeTicketInWork().then(fillForm);
      }
      this.textareaInput();
    },

    onKeyPress: function(e) {
      if(e.key === 'Escape') {
      //  this.redirectToTickets();
      }
    },

    loadDraft: function() {
      this.sending = true;

      return this.api.getDraft().then(response => {
        if(this.message === '' && typeof response.data.body !== 'undefined') {
          let draft = response.data;
          this.draft.message = draft.body;
          this.draft.comment = response.data.comment ?? '';
          this.draft.attachments = draft.attachments;
          this.draft.headers = {
            to: draft.to ?? null,
            from: draft.from ?? null,
            subject: draft.subject ?? null,
            headers: draft.headers ?? null
          };
          if(typeof draft.template !=='undefined' && draft.template != null ){
            this.selectTemplate(draft.template);
            if( typeof draft.template.comment !== 'undefined'){
              this.$store.commit('addMovingModuleContent' , draft.template.comment);
            }
          }

          this.$nextTick(() => {
            if (this.isShowAnswerForm) {
              this.$refs['answer-form'].setDraft(this.draft);
            }
          });
        }
      }).finally(() => {
        this.sending = false;
        if(this.getAnswerRef() !== null){
          this.$nextTick(()=>{
            let ref =  this.getAnswerRef();
            ref.focus();
          });
        }
      });
    },

    refExists: function(name) {
      return (typeof this.$refs[name] !== 'undefined' && this.$refs[name] !== null);
    },
    setOverflow:function (value) {
      if(this.getAnswerRef() !== null){
        this.getAnswerRef().style.overflow = value;

      }
    },

    moveMessages(message){
      if (message) {
        this.$store.commit('setActiveWindow', 'moveMessagesModal');
      } else {
        this.$store.commit('removeActiveWindow', 'moveMessagesModal');
      }
      this.messageToMove = message;
    },

    reloadMessages() {
      this.$store.commit('removeActiveWindow', 'moveMessagesModal');
      this.messages = [];
      this.loading = true;
      this.setFirstBeforeMessages(true);
    },

    reloadMessagesOrSearch() {
      if (!this.isSearchingInTicket) {
        this.reloadMessages();
      }
    },

    updateMessage(newMessage) {
      this.messages = this.messages.map(x => window.getId(x._id) === window.getId(newMessage._id) ? newMessage : x);
    },

    updateTask(task = null) {
      if (task) {
        this.updateMessage(task);
      }
      this.updateTasks();
    },

    deleteTask(task) {
      this.messages = this.messages.filter(x => window.getId(x._id) !== window.getId(task._id));
      this.updateTasks();
    },

    setSending(value) {
      this.sending = value;
    },

    setHighlight(value) {
      this.highlighted = value ? (Array.isArray(value) ? [...value] : [value]) : [];
    },

    doScrollToMessage(to) {
      if (this.refExists(to) && typeof this.$refs[to][0].scrollIntoView === 'function') {
        this.$refs[to][0].scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: "nearest"
        });
      } else {
        let path = this.ticket?.type === 'notification' ? '/notification/' : "/tickets/";
        let id = window.getId(this.ticketId) + '?message=' + window.getId(to);
        window.open(window.location.origin + path + id, '_blank');
      }
    },

    catch(error) {
      if(
          !axios.isCancel(error) &&
          error?.message !== 'Request aborted'
      ) {
        this.$store.commit('errorPush' , CrmApi.getErrorMessage(error) );
      }
    },

    changeSearchInTicket() {
      let query = Object.assign({}, this.$route.query);
      if (typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        query.search = this.searchString.trim();
      } else {
        delete query.search;
      }
      this.$router.push({name: this.$route.name, query: query});
    },

    scrollToFirstSearch() {
      if (
          this.searchMessages.length &&
          typeof this.searchString === 'string' &&
          this.searchString.trim() !== '' &&
          this.$refs['messages'] &&
          this.$refs['messages'].scrollHeight > this.$refs['messages'].clientHeight &&
          $(this.$refs['messages']).find('mark.highlight-text').length
      ) {
        $(this.$refs['messages']).find('mark.highlight-text').get(0)?.scrollIntoView({
          behavior: 'auto',
          block: 'start',
          inline: "nearest"
        });
      }
    },

    searchInCurrentTicket() {
      let query = Object.assign({}, this.$route.query);
      if (typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        this.loadingWithList = true;
        this.searchMessages = [];
        query.search = this.searchString.trim();
        this.api.searchInTicket(this.searchString).then((result) => {
          if (
              typeof result?.data?.data === 'object' &&
              Array.isArray(result?.data?.data) &&
              query.search === this.searchString?.trim()
          ) {
            this.searchMessages = result.data.data.reverse();
          }
        }).catch(this.catch)
          .finally(() => {
            if(
                typeof this.searchString !== 'string' ||
                this.searchString.trim() === '' ||
                query.search === this.searchString.trim()
            ) {
              this.loadingWithList = false;
            }
          })
      } else {
        this.loadingWithList = false;
      }
    },
  },

  watch: {
    'threadState.showCommentForm' (to) {
      if(to) {
        $(this.$refs.messagesbox).height("60%");
      } else {
        this.$nextTick(() => {
          this.calculateFormSize();
        });
      }
    },

    'content' (to) {
      if (to === 'ticket') {
        this.$nextTick(() => {
          this.calculateFormSize();
        });
      }
    },

    'userCanAnswer' (to) {
      if(!to) {
        this.$nextTick(() => {
          this.calculateFormSize();
        });
      }
    },

    'showAddTaskForm' (to) {
      this.$nextTick(() => {
        this.calculateFormSize();
      });
    },

    'threadState.showEditForm' (to) {
      if(!to) {
        this.$nextTick(() => {
          this.calculateFormSize();
        });
      }
    },

    'showSignature' () {
      this.$nextTick(() => {
        this.calculateFormSize();
      });
    },

    'messages':{
      handler(val){
        this.notEndCall = null;
        this.$store.commit('thread/setMessages', this.messages);
        for (let message of this.messages){
          if (message?.type === 'call' && typeof message?.headers !== 'undefined') {
            if(typeof message?.author === 'undefined' && (
                typeof message?.headers?.completed === 'undefined' || message.headers.completed === null )){
              if(  this.notEndCall === null){
                this.notEndCall = message;
              }
            }
            if(this.isOutgoingCallMessageNeedCheck(message)){
              if( this.notEndCall === null){
                this.notEndCall = message;
              }
            }
            if(typeof message?.author !== 'undefined'){
              this.$store.dispatch( 'sip/checkCurrentCall' , {message:message});
            }
          }

        }
      },
      deep:true,
    },
    'loading' (to) {
      this.threadState.loading = to;
    },

    'threadState.loading' (to) {
      if(to !== this.loading) {
        this.loading = to;
      }
    },

    'threadState.reloadMessage' (messageId) {
      if(messageId) {
        this.threadState.reloadMessages = false;
        let messageBefore = {_id: undefined};
        this.messages.some(msg => {
          if(window.getId(msg._id) === messageId) {
            return true;
          } else {
            messageBefore = msg;
          }
        });

        this.api.getTicketAndMessagesAfter(messageBefore._id, this.getFiltersForMessageRequest()).then((result) => {
          let loadedMessage = result.data.messages.data.find(msg => window.getId(msg._id) === messageId);
          this.messages[ this.messages.findIndex(msg => window.getId(msg._id) === messageId) ] = loadedMessage;
        });
      }
    },

    'threadState.reloadLastMessage' (to) {
      if(to) {
        this.threadState.reloadLastMessage = false;

        let message = this.messages.filter(msg => msg.type !== 'step_hint');
        message = message[ message.length - 2 ] ?? {_id: undefined};

        this.api.getTicketAndMessagesAfter(message._id, this.getFiltersForMessageRequest()).then((result) => {

          if(typeof message.created !== 'undefined') {
            let delete_ids = this.messages.filter(msg => new Date(msg.created) > new Date(message.created))
                                          .map(msg => window.getId(msg._id));

            this.messages = this.messages.filter(msg => !delete_ids.includes(window.getId(msg._id)));
            delete_ids.forEach((id) => { delete this.ticketHashMap[id] });
          } else {
            this.messages = [];
            this.ticketHashMap = {};
          }

          this.pushNewMessages(result.data.messages.data.reverse());
          this.$nextTick(() => { this.$refs.messagesbox.scrollTop = this.$refs.messagesbox.scrollHeight; });
        });
      }
    },

    'attachmentsFilter' () {
      // при изменении фильтра по вложениям, прокручиваем вниз к последним сообщениям
      this.$nextTick(() => this.$refs.messagesbox.scrollTop = this.$refs.messagesbox.scrollHeight);
    },

    'tasksFilter' () {
      // при изменении фильтра по вложениям, прокручиваем вниз к последним сообщениям
      this.$nextTick(() => this.$refs.messagesbox.scrollTop = this.$refs.messagesbox.scrollHeight);
    },

    'userCanTakeTicket' () {
      this.$nextTick(() => {
        this.calculateFormSize();
        if (this.scrollLastMessage) {
          this.$refs.messagesbox.scrollTop = this.$refs.messagesbox.scrollHeight
        }
      });
    },

    'showOffersTable' (show) {
      if (!show) {
        this.loadDraft();
        if (this.offersTicketScrollPosition !== null) {
          this.$nextTick(() => {
            $(this.$refs.messagesbox).scrollTop(this.offersTicketScrollPosition);
            this.offersTicketScrollPosition = null;
          });
        }
      }
    },

    'showHeadersForm' () {
      this.$nextTick(() => {
        this.calculateFormSize();
      });
    },

    showSearchField(to) {
      this.$nextTick(() => {
        this.calculateFormSize();
      });
      if (to === false && this.searchString.trim() !== '') {
        let query = Object.assign({}, this.$route.query);
        delete query.search;
        this.$router.push({name: this.$route.name, query: query});
        this.reloadMessages();
        this.$store.commit('thread/setSearchString', '');
      }
    },

    searchString() {
      this.changeSearchInTicket();
    },

    '$route.query.search' (to, from) {
      let hasSearch = typeof to === 'string' && to.trim() !== '';
      this.$store.commit('thread/setSearchString', hasSearch ? to.trim() : '');
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }
      if (hasSearch) {
        if (this.canSearch) {
          this.loadingWithList = true;
          this.searchTimeout = setTimeout(this.searchInCurrentTicket, 500);
        }
      } else {
        this.reloadMessages();
      }
    },

    'scrollToMessage' (to) {
      if (to) {
        this.setHighlight(to);
        this.doScrollToMessage(to);
        this.setScrollToMessage(null);
      }
    },

    'scrollAndHighlight' (to) {
      if (to) {
        if (Array.isArray(to) && to.length) {
          this.setHighlight(to);
          this.doScrollToMessage(to.slice(-1));
        }
        this.setScrollAndHighlight(null);
      }
    },

    highlight(to) {
      if (to) {
        if (Array.isArray(to) && to.length) {
          this.setHighlight(to);
          this.doScrollToMessage(to.slice(-1));
        }
        this.setScrollAndHighlight(null);
      }
    },
  }
}
</script>

<style scoped>
.messages {
  outline: none;
}
.selected-message{
  border-bottom: 1px solid #94ff95;
  border-top: 1px solid #94ff95;
  background-color:rgb(0 255 17 / 24%);
  padding-bottom: 10px;
  border-radius: 0;
}
.cursor-wait{
  cursor: wait;
}
.last-seen-message {
  color: var(--bs-danger);
  opacity: 1;
  height: 0.25rem;
  margin: 1rem -1rem;
}
.backplate-full {
  border-radius: 0;
  height: 100vh;
  padding: 3px;
  overflow: hidden;
}
.backplate-height {
  height: calc(100vh - 60px);
}
:deep(mark.highlight-text) {
  padding: 0;
  --bs-highlight-bg: var(--bs-warning);
}
</style>