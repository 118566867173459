<template>
  <template v-if="keyword.type === 'autogenerated'">

    <input type="text" v-model="keyword.pattern" class="form-control" placeholder="Паттерн" />
    <span class="input-group-text p-1">
      <popper class="light small" placement="top" :hover="true" :interactive="true" :arrow="true">
        <i class="fa fa-question-circle-o" aria-hidden="true"></i>
        <template #content>
          <b>index('идентификатор индекса')</b> – авто-итерируемый номер.
            Идентификатор позволяет задавать единую нумерацию между разными шаблонами документов.
            Если индекс начинается с "daily_", то он будет автоматически обнуляться в 00:00 MSK каждый день<br>
          <b>date('формат', 'время', 'локаль')</b> – генерация даты. Формат задается как комбинация символов из format-character
            (<a href="https://www.php.net/manual/en/datetime.format.php" target="_blank">https://www.php.net/manual/en/datetime.format.php</a>).
            Время - если это параметр не передать или передать null (без ковычек), то при генерации документа будет использоваться дата генерации.
            Локаль - если передать 'RU', то при генерации названия месецев будут переведены с англ. на русский (например format-character 'F').
            Пример: date('F d.m.Y',null,'RU') в случае если в момент генерации документа в тикете по такому шаблону дата будет 2023-05-16,
            то в итоговом документе будет - 'май 16.05.2023'
        </template>
      </popper>
    </span>

  </template>
</template>

<script>
export default {
  name: "Autogenerated",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>