import {createRouter,
    createWebHistory} from "vue-router";


import moduleOffer from "../../store/main/offer/index";

import BuyList from "../../components/offer/table/Buy/BuyList";
import SellList from "../../components/offer/table/Sell/SellList";

const routes = [
    {
        path:"/offers",
        name:"Offers",
        components:{
            main:SellList
        },
    },
    {
        path:"/offers/buy",
        name:"Buy",
        components:{
            main:BuyList
        },
    },
    {
        path:"/offers/sell",
        name:"Sell",
        components:{
            main:SellList
        },
    },
]

const offerRouter = createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'
    }
)

offerRouter.beforeEach((to, from, next) => {
    if (typeof to?.query.currentCurrency !== 'undefined' && to?.query.currentCurrency) {
        moduleOffer.commit('offer/setCurrentCurrency',to?.query.currentCurrency);
    } else if (to.name !== from.name) {
        moduleOffer.commit('offer/setCurrentCurrency','USD')
    }
    moduleOffer.commit('SET_ROUTE' , to);
    moduleOffer.dispatch('offer/changeRoute', {from})
    next();
});

export default offerRouter;