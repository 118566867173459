<template>
  <div class="input-group align-items-center flex-nowrap">
    Аукцион:&nbsp;
    <popper :content="statusPoperCaption" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
      <a :href="sellerLink" target="_blank" class="text-decoration-none" :class="subnetClass">{{subnetsLabel}}</a>
    </popper>
    <popper v-if="!auction.approved && canSetLot" content="Подтвердить участие в аукционе" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
      <span class="btn btn-sm btn-light p-0 approve-button" @click="setBidModal">
        <span @click="setBidModal" class="fa fa-check text-success px-1" aria-hidden="true"></span>
      </span>
    </popper>

    <modal-window v-if="showSetBidModal"
                  @close="closeSetBidModal"
                  @success="setBid"
                  :buttons-text="{close: 'Отмена', success: 'Подтвердить'}"
                  :loading="loading">

        <template v-slot:header>
          <h5 class="modal-title">Подтверждение участия в аукционе</h5>
        </template>

        <template v-slot:body>
          <span>Вы уверены что хотите подтвердить ставку '{{person.name}}' на {{ (subnets.length === 1 ? 'сеть ' : 'сети ') + subnets.join(', ') }}?</span>
        </template>

    </modal-window>
  </div>
</template>

<script>
import ModalWindow from "../../../ModalWindow.vue";
import {mapGetters} from "vuex";
import {CrmApi} from "../../../../../../library/CrmApi";

export default {
  name: "Auction",
  components: {ModalWindow},

  props: {
    auction: Object,
  },

  data() {
     return {
       canSetLot: false,
       showSetBidModal: false,
       loading: false,
     }
  },

  mounted() {
    UserPermission.can(this.ticket.project.name + '.setlot')
                  .then(() => this.canSetLot = true);
  },

  computed: {
    subnets() {
      let subnets;
      if(Array.isArray(this.auction?.subnets)) {
        subnets = this.auction.subnets.map(subnet => subnet.subnet)
      } else {
        // устаревшее, для обратной совместимости:
        subnets = [this.auction.subnet.subnet];
      }
      return subnets;
    },

    subnetsLabel() {
      let label;
      if(this.subnets.length === 1) {
        label = this.subnets[0]
      } else {
        let prefixes = {};
        this.subnets.forEach(subnet => {
          let prefix = subnet.slice(subnet.indexOf('/') + 1)
          prefixes[prefix] = (prefixes[prefix] ?? 0) + 1;
        });

        label = Object.entries(prefixes)
                      .map(([prefix, count]) => `${count}x/${prefix}`)
                      .join(' + ');
      }
      return label;
    },

    sellerLink() {
      return '/tickets/' + encodeURIComponent(this.auction.seller);
    },

    subnetClass() {
      let sclass = '';

      if(this.auction.approved) {
        sclass = 'text-' + (this.auction.active ? 'success' : 'warning');
      } else{
        sclass = 'text-muted';
      }

      return sclass;
    },

    statusPoperCaption() {
      let sclass = '';

      if(this.auction.approved) {
        sclass = this.auction.active ? 'Ставка активна' : 'Ставка перебита';
      } else{
        sclass = 'Ставка не подтверждена';
      }

      return sclass;
    },

    ...mapGetters({
      ticket: 'thread/getTicket',
      person: 'thread/getPerson',
      api: 'thread/getApi',
    }),
  },

  methods: {
    setBidModal() {
      this.showSetBidModal = true;
      this.$store.commit('setActiveWindow', 'setBidModal');
    },

    closeSetBidModal() {
      this.showSetBidModal = false;
      this.$store.commit('removeActiveWindow', 'setBidModal');
    },

    setBid() {
      this.loading = true;
      this.api.setBid()
              .then((result) => {
                Object.keys(result.data.auction)
                      .forEach((key) => this.auction[key] = result.data.auction[key]);

                this.closeSetBidModal();
              })
              .catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
              .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
.approve-button{
  background-color: #dddddd;
  border-color: #a5a5a5;
  margin-left: 4px;
}
.approve-button:hover{
  background-color: #e1e1e1;
  border-color: #6b6b6b;
}
</style>