import moment from "moment";
import {PricePrettyView} from "../../../library/PricePrettyView";

export class HistoryCopy {
    constructor(subjects,type, count, date, seller, buyer , sellprice , buyprice, actualRates) {
        this.subjects = subjects;
        this.type = type;
        this.count = count;
        this.date = date;
        this.seller = seller;
        this.buyer = buyer;
        this.sellprice = sellprice;
        this.buyprice = buyprice;
        this.actualRates = actualRates;
    }

    static initObject = function (historyObject){
        let subnets = [];
        if(historyObject?.asn?.name){
            subnets.push(historyObject?.asn?.name)
        }else {
            for (let key in historyObject.subnets){
                let subnet = historyObject.subnets[key];
                if(subnet?.subnet){
                    subnets.push(subnet.subnet.replaceAll(" ", ''))
                }
            }
        }

        let count = historyObject?.count ?? 0;
        let date = moment(historyObject.date).format("DD.MM.YYYY")
        let seller = historyObject?.seller ?? {};
        let buyer = historyObject?.buyer ?? {};
        let sellprice = historyObject?.sellprice ?? {};
        let buyprice = historyObject?.buyprice ?? {};
        let type ;
        if(historyObject?.asn?.name){
            type = 'ASN';
        }else {
            type =  historyObject.subnets?.[0]?.type;
        }
        return new HistoryCopy(subnets,  type , count, date, seller, buyer, sellprice, buyprice, historyObject.rates);
    }

    getCopyString(currencyToShow){

        let array = [];
        array.push(this.getSubnetsString( " "))
        array.push(this.count)
        array.push(this.date)
        array.push(this.getInfoString())
        let currencys = {
            buy:currencyToShow,
            sell:currencyToShow,
        }
        if(currencyToShow === null){
            currencys.buy = this.buyprice.currency;
            currencys.sell = this.sellprice.currency;
        }

        let buypriceObject  =  this.getPriceObject(currencys.buy, this.buyprice.price , this.buyprice.price/this.count ,this.buyprice.currency , this.actualRates )
        let usdpriceOne;
        if(this.count !== 0){
            usdpriceOne =  new PricePrettyView( this.buyprice.priceusd/this.count, 'USD')
            array.push(usdpriceOne.getPrettyPrice('', true, 2, false , "", false, {  useGrouping:false,})
                + " " + "("+buypriceObject.all + ")")
        }else {
            array.push(buypriceObject.all)
        }


        if(!this.buyer?.isour){
            let sellpriceObject  =  this.getPriceObject( currencys.sell, this.sellprice.price , this.sellprice.price/this.count ,this.sellprice.currency , this.actualRates )
            if(this.count !== 0){
                usdpriceOne =  new PricePrettyView( this.sellprice.priceusd/this.count, 'USD')

                array.push(usdpriceOne.getPrettyPrice('', true, 2, false , "" ,false, {  useGrouping:false,})
                    + " " + "("+sellpriceObject.all + ")")
            }else {
                array.push(sellpriceObject.all)
            }

        }


        return array.join("\t");
    }

    getPriceObject(currencyToShow, pricesAll ,pricesOne , priceCurrency , actualRates ){
        let out;


        let prices = {};
        prices.all = PricePrettyView.getCurrenciesArray(pricesAll, priceCurrency, actualRates)[currencyToShow];
        prices.single = PricePrettyView.getCurrenciesArray(pricesOne , priceCurrency, actualRates)[currencyToShow];
        let allObject = new PricePrettyView( prices?.all, currencyToShow)
        let singleObject = new PricePrettyView( prices?.single, currencyToShow)

        switch (currencyToShow){
            case 'USD':
                out = {
                    all:allObject.getPrettyPrice('', true, 2, false , "" , false, {  useGrouping:false,}),
                    single:singleObject.getPrettyPrice('', true, 2, false , "" , false, {  useGrouping:false,} ),
                }
                break;

            case 'EUR':
                out = {
                    all:allObject.getPrettyPrice('', true, 2, true , "" , false, {  useGrouping:false,} ),
                    single:singleObject.getPrettyPrice('', true, 2, true , "" , false, {  useGrouping:false,} ),
                }
                break;
            case 'RUB':
                out = {
                    all:allObject.getPrettyPrice('', true, 2, true , "" , true, {  useGrouping:false,}),
                    single:singleObject.getPrettyPrice('', true, 2, true , "" , true, {  useGrouping:false,}),
                }
                break;
        }

        return out;
    }

    copy(currencyToShow){
        fallbackCopyTextToClipboard(this.getCopyString(null))
    }

    getInfoString(){
        let seller = this.getClientString('seller');
        let buyer = this.getClientString('buyer');
        return seller + " to " + buyer;
    }

    getClientString(type){
        let array = [];
        array.push(this.getValue(this[type].name));
        let field = [];
        if(this.type === 'PI'){
            if(this[type]?.lirid){
                field.push(this.getValue(this[type].lirid))
            }
            if(this[type]?.org){
                field.push(this.getValue(this[type].org))
            }

        }else {
            if(this[type]?.lirid){
                field.push(this.getValue(this[type].lirid))
            }

        }
        if(this[type]?.email){
            field.push(this.getValue(this[type].email))
        }

        array.push("(" + field.join(", ") +")")

        return array.join(' ');
    }

    getSubnetsString(	delimiter = " "){
        let string = '';
        if(this.subjects.length > 0){
            string = this.subjects.join(delimiter);
        }
        return string;
    }
    getCountString(){
        return this.count;
    }

    getValue(value){
        let v = value ?? '';
        return v.trim();
    }

}



function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
    } catch (err) {

    }

    document.body.removeChild(textArea);
}