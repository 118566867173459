<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      <slot name="text">
        Клиент проиграл аукцион на сеть <a :href="'/tickets/' + params.seller_id"><span>{{params.subnets}}</span></a> со ставкой {{prettyPrice}} за 1 IP
      </slot>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import {PricePrettyView} from "../../../library/PricePrettyView";

export default {
  name: "BuyerLoseLot",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },
  computed:{
    prettyPrice:function (){
      let pr =  new PricePrettyView(this.params.price1, 'USD')
      return  pr.getPrettyPrice('', true, 2, false , " " );
    }
  },

  setup: (props) => parseEvent(props.event),
}
</script>

<style scoped>

</style>