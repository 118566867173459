export function getNumeralsWord(count, options) {
    return count + ' ' + getNumeral(count, options);
}

export function getNumeral(count, options) {
    let index = ((count % 10 === 1) && (count % 100 !== 11)) ?
                0 :
                (
                    ((count % 10 >= 2) && (count % 10 <= 4) && ((count % 100 < 10) || (count % 100 >= 20))) ?
                        1 :
                        2
                );
    return options[index];
}

export function getMetaMoreOrLess(value) {
    let mod = '';
    if (value.slice(-1) === '+') {
        mod = ' или больше';
    } else if (value.slice(-1) === '-') {
        mod = ' или меньше';
    }
    return mod === '' ? value : (value.slice(0, -1) + mod);
}