<template>

  <div class="row mb-2">
    <label class="form-label" :for="inputId">{{param.label ?? param.key}}</label>
    <div>
      <input type="text" :id="inputId" class="form-control disabled" :value="param.pattern ?? ''" disabled="disabled" />
    </div>
  </div>

</template>

<script>
export default {
  name: "Autogenerated",

  props: {
    param: {
      required: true,
    }
  },

  data() {
    return {
      inputId: `input-${this.param.key}`,
    }
  }
}
</script>

<style scoped>

</style>