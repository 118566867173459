<template>
<div>
  <div class="container-fluid">
    <div  style="max-width:500px; margin:auto;">
      <div>
        <h3 style="text-align:center;">Markdown Guide</h3>
        <h4>Emphasis</h4>
        <pre>**<strong>bold</strong>**
*<em>italics</em>*
~~strikethrough~~</pre>
        <h4>Headers</h4>
        <pre># Big header
## Medium header
### Small header
#### Tiny header</pre>
        <h4>Lists</h4>
        <pre>* Generic list item
* Generic list item
* Generic list item

1. Numbered list item
2. Numbered list item
3. Numbered list item</pre>
        <h4>Links</h4>
        <pre>[Text to display](http://www.example.com)</pre>
        <h4>Quotes</h4>
        <pre>> This is a quote.
> It can span multiple lines!</pre>
        <h4>Images &nbsp;</h4>
        <pre>![](http://www.example.com/image.jpg)</pre>
        <h4>Tables</h4>
        <pre>| Column 1 | Column 2 | Column 3 |
| -------- | -------- | -------- |
| John     | Doe      | Male     |
| Mary     | Smith    | Female   |

<em>Or without aligning the columns...</em>

| Column 1 | Column 2 | Column 3 |
| -------- | -------- | -------- |
| John | Doe | Male |
| Mary | Smith | Female |
</pre>
        <h4>Displaying code</h4>
        <pre>`var example = "hello!";`

<em>Or spanning multiple lines...</em>

```
var example = "hello!";
alert(example);
```</pre>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "markdown-guide"
}
</script>

<style scoped>
h4{
  margin-top:20px;
  margin-bottom:5px;
}
h4, h5, h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}
h4 small{
  font-style:italic;
}

pre {
  overflow:auto;
  word-wrap:normal;
  white-space:pre;
}

footer{
  margin:20px 0 30px;
  font-size:80%;
  color:#999;
  text-align:center;
}
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>