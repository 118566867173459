export {IPv4Net}

class IPv4Net {


    ipSubnetCalculator;


    /**
     invertedMask: 1023
     invertedMaskStr: "0.0.3.255"
     invertedSize: 10
     ipHigh: 2130707455
     ipHighStr: "127.0.3.255"
     ipLow: 2130706432
     ipLowStr: "127.0.0.0"
     prefixMask: 4294966272
     prefixMaskStr: "255.255.252.0"
     prefixSize: 22
     */
    result;
    isNormal
    realFirst;
    realLast;

    constructor(subnet) {
        this.result = null;
        this.ipSubnetCalculator = require('ip-subnet-calculator');
        this.isNormal = false;
        try {

            if(subnet.indexOf("/") !== -1){
                let net = null;
                let prefix = null;
                [net, prefix] = subnet.split('/');
                net = net.trim();

                if(this.ipSubnetCalculator.isIp(net) &&  !isNaN(parseInt(prefix))  &&  prefix.match(/^[0-9]+$/) ){
                    this.result =  this.ipSubnetCalculator.calculateSubnetMask(net, parseInt(prefix) );
                    this.realFirst = net;
                    this.realLast = this.ipSubnetCalculator.calculateSubnetMask(net, prefix).ipHighStr
                    this.isNormal = true;
                }

            }else {
                if(subnet.indexOf("-") !== -1){
                    let ipStart;
                    let ipEnd;
                    [ipStart, ipEnd] = subnet.split('-');
                    ipStart = ipStart.trim();
                    ipEnd = ipEnd.trim();

                    if(this.ipSubnetCalculator.isIp(ipStart) && this.ipSubnetCalculator.isIp(ipEnd) ){
                        this.realFirst = ipStart;
                        this.realLast = ipEnd;
                        this.result =  this.ipSubnetCalculator.calculate( ipStart, ipEnd )[0]
                        this.isNormal = true;
                    }


                }else {
                    if(this.ipSubnetCalculator.isIp(subnet)){
                        let net = subnet;
                        let prefix = 32;
                        this.realFirst = subnet;
                        this.realLast = subnet;
                        this.result = this.ipSubnetCalculator.calculateSubnetMask( net,prefix );
                        this.isNormal = true;
                    }
                }

            }

            if(this.result == null){
                throw {};
            }
        }catch (e ){
            this.isNormal = false;
            this.result = {
                invertedMask: 0,
                invertedMaskStr: "",
                invertedSize: 0,
                ipHigh: 0,
                ipHighStr: "",
                ipLow: 0,
                ipLowStr: "",
                prefixMask: 0,
                prefixMaskStr: "",
                prefixSize: 0
            };
        }

    }

    isNormalizedCIDR(range){
        return ((this.ipSubnetCalculator.getMask(32- range) & this.getStart()) === 0) &&
            ((this.ipSubnetCalculator.getMask(32 - range) & ~this.getStop()) === 0)
    }


    isIp(){
        return this.isNormal;
    }
    count(){
        return (this.getStop() - this.getStart()) + 1;
    }

    getLast(){
        return this.realLast;
    }
    getFirst(){
        return this.realFirst;
    }
    getPrefixLength(){
        return this.result['prefixSize'];
    }
    getStart(){
        return this.ipSubnetCalculator.toDecimal(this.realFirst);
    }
    getStop(){
        return this.ipSubnetCalculator.toDecimal(this.realLast);
    }

    asRange(){
        return this.getFirst() + " - " + this.getLast();
    }

}