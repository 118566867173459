import mutations from "./sipMutations";
import * as actions from "./sipActions";
import * as getters from './sipGetters'


const state = {
    phone:"",
    message:null,
    windowOpen:false,
    status:"",
    startTimer:false,
    currentCall:null,
    timeout:null,
    checkTicket:false,
    firstView:false,
    closeView:false,
    checkCount:0,
    realCheckMessageId:null,
    stopCheckIds:[],
}

export const moduleSip = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters
}