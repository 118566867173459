<template>
<div :class="mainDivClass" >
  <div v-if="oldValue"  class="whois-string red-string" >
    <div class="first-cell">
      -
    </div>
    <div class="key-cell">
      <span>{{keyObject}}:</span>
    </div>
    <div class="value-cell">
      <span v-if="!change?.old?.id">{{oldValue}}</span>
      <a v-if="change?.old?.id" :href="whois7Link(oldValue)"
         target="_blank">{{oldValue}}</a>
    </div>
  </div>
  <div v-if="newValue"  class="whois-string green-string" >
    <div class="first-cell">
      +
    </div>
    <div class="key-cell">
      <span>{{keyObject}}:</span>
    </div>
    <div class="value-cell">
      <span v-if="!change?.new?.id">{{newValue}}</span>
      <a v-if="change?.new?.id" :href="whois7Link(newValue)"
         target="_blank">{{newValue}}</a>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Change",
  props:{
    change:{
      type:Object,
      require:true,
    },
    mainDivClass:{
      type:[String, Object],

    }
  },
  methods: {
    getId:function (value){
      if(value?.id){
        return value.id;
      }
      return  value;
    },
    whois7Link(id){
      return "http://www2.whois7.ru/?s=manual&q=" + encodeURIComponent(id) + "&w=whois.ripe.net";
    },
  },
  computed:{
    keyObject:function (){
      return this.change?.key ?? '';
    },
    oldValue:function (){
      return this.getId(this.change?.old) ?? null;
    },
    newValue:function (){
      return this.getId(this.change?.new) ?? null;
    },

  }
}
</script>

<style scoped>
.green-string {
  background-color: #dff0d8;
  color: darkgreen;
}
.red-string {
  background-color: #ebcccc;
  color: darkred;
}
.whois-string {
  display: flex;
  flex-direction: row;
}
.first-cell{
  width: 2%;
  text-align: center;
}
.key-cell{
  text-align: left;
  white-space: nowrap;
  width: 30%;
  margin-left: 5px;
}
.value-cell{
  width: 70%;
  text-align: left;
  margin-left: 5px;
}
.padding-class{
  padding-bottom: 5px;
}
</style>