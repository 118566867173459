<template>
<div
    style="z-index: 100; background-color: #d6fad0; padding: 5px; border-radius: 3px"
    v-if="showMargins"
>
  <div v-for="(margin,key) in margins">{{margin.price}}<!--span v-if="key !== margins.length -1" style="margin-right: 5px">,</span --></div>
</div>
</template>

<script>
import getComputedRates from "../../common/Rates/Computeds";
import {PricePrettyView} from "../../../library/PricePrettyView";

export default {
  name: "Margins",
  props:{
    historyObject:{
      type:Object,
    }
  },
  data:function (){
    return {
      currency:"USD",
    }
  },
  setup(props){
    const { rates } = getComputedRates();
    return {rates}
  },
  methods:{
    getCurrenciesArray(price){
      return PricePrettyView.getCurrenciesArray(price, this.currency, this.actualRates);
    },
    isNull(value){
      return typeof value === 'undefined' || value === null;
    },
  },
  computed:{
    showMargins(){
      return this.historyObject?.buyer?.isour !== true &&
             !this.isNull(this.historyObject?.sellprice?.priceusd) &&
             !this.isNull(this.historyObject?.buyprice?.priceusd);
    },
    margins(){
      let margins = [];
      if( this.actualRates !== null){
        let sellpriceusd = this.historyObject?.sellprice?.priceusd;
        let buypriceusd =  this.historyObject?.buyprice?.priceusd;

        let u = new PricePrettyView(sellpriceusd - buypriceusd, 'USD')
        margins.push(
          {
            type:'USD',
            price: u.getPrettyPrice("" , true, 0),
          }
        );
        let e = new PricePrettyView(((sellpriceusd - buypriceusd) * this.actualRates['USD'] )/ this.actualRates['EUR'], 'EUR')
        margins.push({
          type:'EUR',
          price:  e.getPrettyPrice("" , true, 0),
        })
        let r = new PricePrettyView(((sellpriceusd - buypriceusd) * this.actualRates['USD'] ), 'RUB')
        margins.push({
          type:'RUB',
          price: r.getPrettyPrice("" , true, 0),

        })
      }

      return margins;
    },

    actualRates() {
      let result = null;
      if ( this.historyObject?.rates) {
        result = {};
        for (let currency in this.historyObject.rates){
          let value = this.historyObject.rates[currency];
          if(value?.value){
            result[currency] = value.value;
          }else {
            result[currency] = value;
          }
        }
      } else if (this.rates !== null) {
        result = {
          "USD": this.rates['USD'].Value,
          "EUR": this.rates['EUR'].Value,
        }
      }
      return result;
    },
  }

}
</script>

<style scoped>

</style>