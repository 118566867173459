import vueEnter from './vue-enter'

let isEventAdd = false;
const plagin = {
    install (Vue) {
        Vue.directive('enter', {
            mounted(el, binding){
                vueEnter.update(el , binding)
            },
            beforeUnmount(el, binding) {
                vueEnter.unbind(el , binding)
            },
        });
        if(!isEventAdd){
            isEventAdd =  true;
            document.addEventListener('keyup',  (event) =>{
                vueEnter.onEvent(event)})
            document.addEventListener('keydown',  (event) =>{vueEnter.downEven(event)})
        }
    }
}
export default plagin