<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      <template v-if="user">
        {{user}} задал(а) <a :href="sellerTicketLink" target="_blank">продавца</a>
      </template>
      <template v-else>
        Задан <a :href="sellerTicketLink" target="_blank">продавец</a>
      </template> ({{params.seller.email}}) для сделки
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "SetSeller",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    sellerTicketLink() {
      return '/tickets/' + encodeURIComponent(window.getId(this.params.seller._id));
    }
  }
}
</script>

<style scoped>

</style>