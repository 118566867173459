<template>
<div style="    margin-left: 8%;     width: 86%;">
  <change v-for="(change, index ) in changes" :change="change"  ></change>
</div>
</template>

<script>
import Change from "./Change";
export default {
  name: "Changes",
  components: {Change},
  props:{
    'changes':{
      type:Array,
      require:true,
    }
  }
}
</script>

<style scoped>
.padding{
  padding-bottom: 5px;
}
</style>