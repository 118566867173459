import {createLogger, createStore} from "vuex";
import * as actions from "../common/actions";
import * as getters from "../common/getters";
import commonMutations from "../common/mutations";
import {moduleOffersHistory} from "./offerHistoryState";
import {shareState} from "../common/composition";

export default createStore ( {
    namespaced: true,
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    modules:{
        'offerHistory':moduleOffersHistory
    },
    plugins: []
})