<template>
  <div  class="table-scroll w-100">
    <router-view name="main" v-slot="{ Component }" >
      <component ref="mainSpace" :is="Component"  />
    </router-view>
  </div>
</template>

<script>

export default {
  name: "OffersList",

}
</script>

<style scoped>

</style>
<style>
.offer-table-text{
  text-align: center;
  vertical-align: top;
}

</style>