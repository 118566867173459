<template>
<div class="text-center">
    <div v-if="callinfo.completed">
      <span v-if="isCallMissed">
        <span class="text-danger">Звонок пропущен</span>
        <duration :callinfo="callinfo" />
        <span v-if="userSetAnswered && showCallbackExpandedStatus"> / {{userSetAnswered}} отметил(-а) как отвеченный</span>
      </span>
      <span v-else>
        <span>Звонок завершен</span>
        <duration :callinfo="callinfo" />
      </span>

      <span v-if="isCanReadRecord" @click="play" class="text-primary pointer ms-1"><i class="fa fa-play-circle"></i></span>
    </div>
    <div v-else>
        <div v-if="!callinfo.startf">Голосовое меню...</div>
        <div v-else>
            <div v-if="!callinfo.answered">Ожидает ответа...</div>
            <div v-else-if="redirecting">
                Переадресация на {{redirecting}}
            </div>
            <div v-else>
                Разговаривает с {{operatorInfo}}
            </div>
        </div>
    </div>
</div>
</template>

<script>



import Timediff from "../../../common/timediff";
import Duration from "./Duration.vue";

export default {
    name: "CallStatusInfo",

    emits: ['play'],
    components:{
      Duration,
      Timediff

    },
    props: {
      message:{
        type:Object,
        require:true,
      },
      hasPermission: {
        type: Boolean,
        default: null,
      },
      showCallbackExpandedStatus: {
        type: Boolean,
        default: false,
      }
    },

    computed: {
      attachmentid:function (){
        return window.getId(this.message.attachments[0].id);
      },
      messageid:function (){
        return window.getId(this.message._id);
      },
      isCanReadRecord:function (){
        let allow = false;
        if (typeof this.message.attachments !== 'undefined' &&
            this.message.attachments !== null &&
            this.message.attachments.length > 0 &&
            this.hasPermission
        ) {
          allow = true;
        }
        return allow;
      },
      callinfo:function (){
        return this.message.headers;
      },
      operatorInfo: function () {
          let operatorInfo = null;
          if( this.callinfo.ecode != null ){
              operatorInfo = this.callinfo.ecode;
              if( this.callinfo.operator != null){
                  operatorInfo = this.callinfo.operator.name + " (" + this.callinfo.ecode.toLowerCase() + ")";
              }
          }

          return operatorInfo;
      },
      redirecting: function () {
          let redirecting = null;
          if( this.callinfo.answered_operators != null && this.callinfo.answered_operators.length > 0 ){
              let last = this.callinfo.answered_operators[this.callinfo.answered_operators.length - 1];

              if( last.answered == null ){
                  redirecting = last.ecode;
                  if( last.operator != null){
                      redirecting = last.operator.name + " (" + last.ecode + ")";
                  }
              }
          }

          return redirecting;
      },

      fileLink: function() {
        return '/message/' + this.messageid + '/attachment/' + this.attachmentid;
      },

      isCallMissed() {
        return (typeof this.message?.author === 'undefined' || !this.message.author) &&
            this.message.headers?.completed &&
            (typeof this.message?.headers?.ecode === 'undefined' || !this.message?.headers?.ecode);
      },

      userSetAnswered() {
        return typeof this.message.headers?.userSetAnswered !== 'undefined'
                  ? (this.message.headers.userSetAnswered.name ?? this.message.headers.userSetAnswered.login)
                  : false;
      }
    },
  methods: {
    play: function() {
      this.$emit('play', this.fileLink);
    },
  }

}
</script>

<style scoped>
.record-played{
    color: green;
}
.pointer {
  cursor: pointer;
}
</style>
