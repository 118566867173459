import offerMutations from "./offerMutations";
import offerGetters from "./offerGetters";
import offerActions from "./offerActions";
import {offerFilters} from "./filters/offerFiltersState";

const state = () => (
    {
        offers: {
          list:new Map(),
        },
        persons: {
            list: new Map(),
        },
        steps: {
            Sell: [],
            Buy: [],
        },
        paginator:{
            page:1,
            onpage:256,
            links:[],
            total:0,
        },
        loading:false,
        searchMeta: null,
        changeRights: {},
        setLotRights: {},
        currentCurrency:'USD',
        orderByField: null,
        orderByDirection: null,
    }
);


export const moduleOffers = {
    namespaced: true,
    state:state,
    mutations: offerMutations,
    actions:  offerActions ,
    getters: offerGetters,
    modules:{
        "filters":offerFilters,
    }
}