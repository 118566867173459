<template>
  <div v-esc="clearSelect" v-enter="openTicketsSameWindow"   v-enter.meta="openTicketsNewTab" v-enter.ctrl="openTicketsNewTab"  v-if="showCreateForm === false">
    <div   class="table-wrapper" >
      <div ref="search">
        <search :is-empty-filters="isEmptyFilters"></search>
      </div>
      <div id="table-scroll" ref="table-scroll"  class="table-scroll" :style="{'max-height': maxHeight}" >
        <table id="table-ticket-list" class="ticket-table table table-bordered table-hover table-striped table-container" >
          <thead class="thead-dark" style="z-index:20">
            <table-head-rows-projects v-if="showDefaultTableHeader" :order-by="orderBy"></table-head-rows-projects>
            <table-head-rows-unsorted  v-if="$route.name === 'Unsorted'" :order-by="orderBy"></table-head-rows-unsorted>
            <table-head-rows-billmanager v-if="$route.name === 'Billmanager'" :order-by="orderBy" />
            <table-head-rows-calls v-if="$route.name === 'Calls'" :order-by="orderBy" />
            <table-head-rows-tasks v-if="$route.name === 'Tasks'" :order-by="orderBy" ></table-head-rows-tasks>
          </thead>
          <tbody>
            <table-body></table-body>
          </tbody>
        </table>
      </div>
    </div>
    <paginate
      settings-name="tickets"
      id="ticket-paginate"
      ref="paginate"
      @changePage="goToPage($event)"
      :show-total="true"
    ></paginate>
    <br />
  </div>
  <create-ticket-form v-if="showCreateForm === true"></create-ticket-form>
  <loading-list v-if="loading"></loading-list>
</template>

<script>

import Paginate from "../paginate/Paginate";
import Search from "./tableFilter/Search";
import {mapActions, mapState} from 'vuex'
import { mapGetters } from 'vuex'
import { mapMutations } from 'vuex'
import TicketRow from "./List/TicketRow";
import TableBody from "./List/TableBody";
import {addUrlParams,  isItTicketsListRoute} from "./Api/api";


import {getTryToScrollAfterTick, isAllLoading, selectedCount} from "../../store/main/tickets/getters";

import TableHeadRowsProjects from "./List/projects/TableHeadRowsProjects";
import TableHeadRowsUnsorted from "./List/unsorted/TableHeadRowsUnsorted";
import TableHeadRowsBillmanager from "./List/billmanager/TableHeadRowsBillmanager";
import TableHeadRowsTasks from "./List/tasks/TableHeadRowsTasks";
import {disableArrowKeysManagement, enableArrowKeysManagement} from "./List/ArrowScrolling";

import LoadingList from "./List/LoadingList";
import {scrollToLine} from "../../store/main/tickets/actions";
import CreateTicketForm from "./Create/CreateTicketForm";
import TableHeadRowsCalls from "./List/calls/TableHeadRowsCalls";

export default {
  name: "TicketList",

  components: {
    TableHeadRowsTasks,
    LoadingList,
    TableBody,
    TicketRow,
    Search,
    Paginate,
    TableHeadRowsProjects,
    TableHeadRowsUnsorted,
    TableHeadRowsBillmanager,
    TableHeadRowsCalls,
    CreateTicketForm
  },
  props: {
    "open":{
      type: Boolean,
    },
    "project":{
      type: Object,
    },
    "ticketsList":{
      type:Array,
      require:true,
    },
    userid: {},
  },

  data: function (){
    return {
      maxHeight:1000,
      ticketModel: {},
      fullPageL:false,
      orderByValue:1,
      autoLoading:false,
    }
  },
  created: function(){
    this.initFiltersFromUrl();
    if(isItTicketsListRoute(this.$route.name) ) {
      let r = Object.assign({}, this.$route);
      this.getAllTickets(r);
    }
    this.$store.commit('setActiveWindow', 'ticketList');
  },
  mounted() {

    enableArrowKeysManagement(this.selectFromBottomToTop , this.selectFromTopToBottom,
        this.addOneMoreFromBottomToTop , this.addOneMoreFromTopToBottom);

    this.calcMaxHeight();
    this.enableAutoUpdate();
    this.setAutoUpdateTicket(this.$route);
    this.handleQuery();

    new ResizeObserver(this.calcMaxHeight)
        .observe(this.$refs.search);
  },

  watch:{
    'tryToScrollAfterTick'(to){
      if(to!== null){
        this.$nextTick(()=>{
          this.scrollToLine({scrollLine:to});
          this.setTryToScrollAfterTick(null);
        })
      }
    },
    '$route.params' (to, from){
      if(isItTicketsListRoute(this.$route.name) ) {
        if(!this.escapeRefresh){
          this.reset();
          this.handleQuery();
          this.getAllTickets(this.$route);
        }else {
          this.escapeRefresh = false;
        }
      }
    },
    'ticketsState.paginate':{
      handler(val){
        const updateRefPaginate = () => {
          let updated = false;
          if( this.$refs.paginate != null ){
            this.$refs.paginate.update({
              page: this.getPaginate.page,
              onpage: this.getPaginate.onpage,
              total: this.getPaginate.total,
              links: this.getPaginate.paginator_links
            });
            updated = true;
          }
          return updated;
        }
        if( !updateRefPaginate() ){
          this.$nextTick(() => {
            updateRefPaginate();
          })
        }

      },
      deep: true
    }
  },

  methods: {
    calcMaxHeight() {
      let paginateHeight = $("#ticket-paginate").height() > 54 ? $("#ticket-paginate").height() : 54;
      this.maxHeight =
          (window.innerHeight -
            $(this.$refs.search).height() -
            $("#main-nav-menu").height() -
            paginateHeight -
            32
          ) + "px";
    },
    handleQuery() {
      if(typeof this.$route.query !== 'undefined') {
        let query = this.$route.query;
        let defaultAlias = this.$route?.params?.filter === 'notifications'
                              ? 'notifications'
                              : 'all';
        [
          ['search', 'setSearchString', ''],
          ['searchAlias', 'updateAlias', defaultAlias],
          ['searchProduct', 'updateProduct'],
          ['searchUser', 'updateUser'],
          ['ticketStatus', 'setTicketStatus'],
          ['projectName', 'setSearchProject', 'all'],
          ['oB', 'setOrderBy']
        ].forEach(([param, mutation, def = null]) => {
          this.$store.commit(`tickets/filters/${mutation}`, query[param] ?? def);
        });

        if(typeof query.searchParams !== 'undefined') {
          let routeSettings = JSON.parse(query.searchParams);
          let defaultValues = this.$store.getters['tickets/filters/getSearchDefaultValues'];

          if (!_.isEmpty(routeSettings)) {
            this.$store.commit('tickets/filters/updateSettingShow', true);
          }

          [].concat(
              this.$store.getters['tickets/filters/getSearchSettingsToShow'],
              this.$store.getters['tickets/filters/getAdditionalSearchSettingsToShow']
          ).forEach(item => item.value = routeSettings[item.field] ?? defaultValues[item.field] ?? false);
        }

        if (this.$route?.name === 'Tasks') {
          if (typeof query?.type !== 'undefined' && query?.type !== null && query?.type !== '' && query?.type !== 'current') {
            this.$store.commit('tickets/filters/updateSettingShow', true);
          }
        }
      }
    },

    isEmptyFilters:function () {
    },
    orderBy:function (field){
      let query = {};
      this.orderByValue = this.orderByValue * -1;
      let oB = {
        f: field,
        v: this.orderByValue,
      };
      query.oB = JSON.stringify(oB);
      this.$store.commit('thread/offers/filters/setOrderBy', query.oB);
      addUrlParams(this.$route, query , this.$route.name, this.$router)
    },

    goToPage:function ({page , onpage}){
      let query = {};
      query.page = page;
      query.onpage = onpage;
      this.reset();
      addUrlParams(this.$route, query ,  this.$route.name , this.$router)
    },
    openTicketsSameWindow:function (){
      this.openTickets(false);
    },
    openTicketsNewTab:function (){
      this.openTickets(true);
    },
    getTicketUrl: function (ticket) {
      let path = "/tickets/", id = window.getId(ticket._id);
      if (typeof ticket.type !== 'undefined') {
        if (ticket.type === 'notification') {
          path = '/notification/';
        } else if (ticket.type === 'call') {
          id = window.getId(ticket.ticket._id) + '?message=' + window.getId(ticket._id);
        }
      }
      return window.location.origin + path + id;
    },
    getTicketRouteObject: function (ticket) {
      let result = {
        name: 'Ticket',
        params: {
          id: window.getId(ticket._id),
        },
      };
      if (typeof ticket.type !== 'undefined') {
        if (ticket.type === 'notification') {
          result['name'] = 'Notification';
        } else if (ticket.type === 'call') {
          result['params'] = {
            id: window.getId(ticket.ticket._id)
          };
          result['query'] = {
            message: window.getId(ticket._id)
          };
        }
      }
      return result;
    },
    openTickets: function (newTags = false) {
      if (this.selectedCount > 0) {
        let c = 10;
        let firstElement = null;
        for (let el of this.getTicketList) {
          let id = window.getId(el._id);
          if (this.getSelectedObjects[id]) {
            c--;
            if (firstElement !== null) {
              window.open(this.getTicketUrl(this.getSelectedObjects[id]));
            } else {
              firstElement = this.getSelectedObjects[id];
            }
            if (c <= 0) {
              break;
            }
          }
        }
        if (firstElement !== null) {
          if (newTags) {
            window.open(this.getTicketUrl(firstElement));
          } else {
            if(this.$route.name === 'Billmanager') {
              window.open('https://my.i7.net/billmgr?elid=' + firstElement._id + '&plid=&startform=ticket_all.edit', '_blank');
            } else {
              this.$router.push(this.getTicketRouteObject(firstElement));
            }
          }
        }
      }
    },
    ...mapActions('tickets' ,[
      'getAllTickets',
      'setAutoUpdateTicket',
        'reset',
    ]),
    ...mapActions({
      'selectFromBottomToTop':'tickets/selectFromBottomToTop',
      'selectFromTopToBottom':'tickets/selectFromTopToBottom',

      "disableAutoUpdate":'tickets/disableAutoUpdate',
      "enableAutoUpdate":"tickets/enableAutoUpdate",
      'addOneMoreFromBottomToTop':'tickets/addOneMoreFromBottomToTop',
      'addOneMoreFromTopToBottom':'tickets/addOneMoreFromTopToBottom',
      'initFiltersFromUrl':'tickets/filters/initFiltersFromUrl',
      'scrollToLine':'tickets/scrollToLine',
    }),

    ...mapMutations({
      'clearSelect':'tickets/clearSelect',
      'setTryToScrollAfterTick':'tickets/setTryToScrollAfterTick',
    }),
  },
  beforeUnmount: function (){
    this.$store.commit('tickets/setLastSelectedPosition')
    disableArrowKeysManagement()
    this.disableAutoUpdate();
  },
  computed:{
    escapeRefresh:{
      get(){
        return this.ticketsState.escapeRefresh;
      },
      set(value){
        this.$store.commit('tickets/escapeRefresh' , value)
      }
    },
    showDefaultTableHeader() {
      return ['TicketsProjectFilter', 'Trash', 'Competitor', 'Recent'].includes(this.$route.name);
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),

    ...mapGetters('tickets' , [
      "isTicketLoading",
      "getPaginate",
        "filters/getSearch",
    ]),
    ...mapGetters({
      'getUserSettings':'getUserSettings',
      'getTicketList':'tickets/getTicketList',
      'getSelectedObjects':'tickets/getSelectedObjects',
      'selectedCount':'tickets/selectedCount',
      "loading":  "tickets/isAllLoading",
      'tryToScrollAfterTick':'tickets/getTryToScrollAfterTick',
      'showCreateForm': 'tickets/create/showCreateForm',
    }),


  }
}
</script>

<style scoped>



.table-wrapper {
  position:relative;
}
.table-scroll {

  overflow:auto;
}
.table-wrapper table {
  width:100%;
}

.table-scroll thead th {
  position: sticky; top: 0;
}
.table-scroll tbody th {
  position: sticky; left: 0;
}

.table-scroll th     {
  background:#eee;
}
.table-scroll th, td {
  padding: 8px 16px;
}

:deep(thead),
:deep(tbody),
:deep(tfoot),
:deep(tr),
:deep(td),
:deep(th) {
  border-color: inherit;
  border-style: solid;
  border-width: 1px ;
}
</style>