<template>
  <div  v-show="countSelect > 0"  @click="clearSelect()"  class="close-select">&#10006;</div>
  <div v-show="countSelect > 0" >
    <add-to-project></add-to-project>
    <read-and-not-read-buttons :id-list="getSelectedArray"></read-and-not-read-buttons>
    <delete-button  v-if="isDeletePermission" ></delete-button>
    <delete-with-spam-button v-if="isDeletePermission"
                             :ipspam="false"
                             :ipspam-value="ipspamValue"
                             :competitor-callback="competitorCallback" />
  </div>
</template>

<script>
import {UserPermission} from "../../users/UserPermission";
import DeleteButton from "./components/DeleteButton";
import {mapGetters, mapMutations, mapState} from "vuex";
import AddToProject from "./components/AddToProject";
import ReadAndNotReadButtons from "./components/ReadAndNotReadButtons";
import DeleteWithSpamButton from "./components/DeleteWithSpamButton.vue";


export default {
  name: "UnsortedMenu",
  components: {DeleteWithSpamButton, AddToProject, DeleteButton, ReadAndNotReadButtons,},
  data: function (){
    return {
       isDeletePermission:false,
    }
  },

  created() {
    this.isDeletePermission = false;
    UserPermission.can('system.delete_pending').then(
        (result) => {
          this.isDeletePermission = true;
        }
    ).catch(()=>{
      let deletePermissions = [];
      this.$store.getters['leftMenu/getTopLevel']
                 .filter(item => item?._id?.length === 24)
                 .forEach(item => deletePermissions.push(item.name + '.delete'));
      if(deletePermissions.length) {
        UserPermission.can(deletePermissions).then(() => this.isDeletePermission = true);
      }
    });
  },
  methods:{
    competitorCallback() {
      this.$store.commit('successPush', 'Обновлено', {root:true});
      this.$store.dispatch('tickets/autoUpdateTicketList', this.$route);
    },

    ...mapMutations({
      'clearSelect':'tickets/clearSelect',
    }),
  },
  computed:{
    ipspamValue() {
      let result = false;
      let values = [];
      this.$store.getters["tickets/getSelectedArray"].forEach((object)=> {
        values.push(this.$store.getters["tickets/getSelectedObjects"][object]?.competitor ?? false);
      });
      if (values.filter((v, i, a) => a.indexOf(v) === i).length === 1) {
        result = values[0];
      }
      return result;
    },

    loading:function (){
      return this.$store.getters["tickets/isTicketLoading"];
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getSelectedArray": "tickets/getSelectedArray",
      'countSelect':'tickets/selectedCount'
    }),
  }
}
</script>

<style scoped>
.close-select{
  cursor: pointer;
  font-size: 14px;
  padding-left: 3px;
  display: inline-flex;
}
</style>