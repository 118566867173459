<template>
  <div v-if="collapsed === false" style="background-color: rgb(210, 210, 210)">
    <button @click="toggleCollapsed" type="button" class="btn btn-secondary btn-sm text-center col-12" >Скрыть</button>
    <div ref="value">
      {{value}}
    </div>
  </div>
  <button v-else @click="toggleCollapsed" type="button" class="btn btn-secondary btn-sm col-12 text-start" >{{previewText}}</button>
</template>

<script>
import {mapGetters} from "vuex";
import {baseHighlight} from "../../../../library/Functions";

export default {
  name: "MessageEntityReply",

  props: {
    "value": {
      type: String,
      require: true
    }
  },

  data: function () {
    return {
      "collapsed": true,
    }
  },

  mounted() {
    if (typeof this.searchString === 'string' && this.searchString.trim() !== '') {
      let regexp = new RegExp(_.escapeRegExp(this.searchString).replace(/\s+/g, '\\s+'), 'gi');
      if (this.value.match(regexp)) {
        this.collapsed = false;
      }
      this.$nextTick(this.highlight);
    }
  },

  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
      this.$nextTick(this.highlight);
    },
    highlight() {
      if (
          this.collapsed === false &&
          this.showSearchField &&
          typeof this.searchString === 'string' &&
          this.searchString.trim() !== ''
      ) {
        baseHighlight(
            $(this.$refs.value),
            this.searchString
        );
      }
    },
  },

  computed:{
    previewText: function(){
      let firstStringPosition = this.value.search(/^\s*>\s*[^\s]+\s*/m);
      let firstStringStart = "";
      if( firstStringPosition === -1 ){
        firstStringStart = "Цитата"
      } else {
        firstStringStart = this.value.substr(firstStringPosition + 1);
      }
      let lnPos = firstStringStart.search("\n");
      return  (lnPos === -1 ? firstStringStart : firstStringStart.substr(0,lnPos )) + "...";
    },

    ...mapGetters({
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  }
}
</script>

<style>
.message-reply-pointer{
  cursor: pointer;

}
</style>