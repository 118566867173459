<template>
  <div>
    <input
      @click="settingShow = true"
      v-key-f.meta.shift="focusOnSearch"
      v-key-f.meta="focusOnSearch"
      v-key-f.ctrl="focusOnSearch"
      v-key-f.ctrl.shift="focusOnSearch"
      @input="change"
      autocomplete="off"
      @focusin="clickedOnSearch"
      @keyup.enter.stop="reloadList()"
      v-model.trim="searchString"
      ref="search"
      name="search"
      class="form-control"
      type="text"
      placeholder="Поиск"
    >
  </div>
  <search-settings v-if="$route.name !== 'Tasks'" v-show="settingShow" ref="searchSettings" @dblclick="closeSearchMenu"></search-settings>
  <search-settings-tasks v-if="$route.name === 'Tasks'" v-show="settingShow" ref="searchSettings" @dblclick="closeSearchMenu" ></search-settings-tasks>
</template>

<script>
import SearchSettings from "./SearchSettings";
import ShowFilterButtom from "./ShowFilterButtom";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

import {getAllTickets} from "../../../store/main/tickets/actions";
import SearchSettingsTasks from "../List/tasks/SearchSettingsTasks";
export default {
  name: "Search",
  components: {SearchSettingsTasks, ShowFilterButtom, SearchSettings},
  data: function (){
    return {
        timer:null,
        mouseOnSearch:false,
    }
  },
  props:{
    "isEmptyFilters":{
      type:Function
    }
  },
  watch:{

  },

  computed:{
    searchString:{
      get () {
        return this.getSearchString
      },
      set (value) {
        clearTimeout(this.timer);
        this.timer =  setTimeout(() => {
          if(value === this.getSearchString){
            this.searchStringSearch(this.getSearchString);
          }
        }, 500);
        this.$store.commit('tickets/filters/setSearchString', value)
      }
    },
    settingShow:{
      get(){
        return this.isSettingShow;
      },
      set(value){
        this.updateSettingShow(value);
      }
    },
    ...mapState( {
      ticketsState: state => state.tickets,

    }),

    ...mapGetters({
      "isLoading":  "tickets/isTicketLoading",
      "getSearchString":"tickets/filters/getSearchString",
      'isSettingShow':'tickets/filters/isSettingShow',
      "showFilter":  "tickets/filters/showFilter",
    }),

  },
  methods:{

    clickedOnSearch() {
      if (!this.settingShow) {
        this.settingShow = true;
      }
      if (!this.showFilter) {
        this.setShowFilter(true);
        this.openTableFilters();
      }
    },

    focusOnSearch(){

      this.$refs.search.focus();
    },

    change(ev){

      if(this.searchString !== ''){
          this.settingShow = true;
      }
    },
    ...mapMutations({
      'updateSettingShow':'tickets/filters/updateSettingShow',
      setShowFilter:'tickets/filters/setShowFilter',
    }),
    ...mapActions({
      "getAllTickets":"tickets/getAllTickets",
      'closeSearchMenu':'tickets/filters/closeSearchMenu',
      "searchStringSearch": "tickets/filters/searchStringSearch",
      'closeTableFilters':'tickets/filters/closeTableFilters',
      'openTableFilters':'tickets/filters/openTableFilters',
      }
    ),

    reloadList(){
      this.getAllTickets(this.$route);
    },

  },
}
</script>

<style scoped>

</style>