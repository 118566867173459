<template>
  <teleport to="body">
    <transition name="modal-confirm-offer">
      <modal-window
          @close="close"
          @success="confirm"
          modal-dialog-class="offer-confirm-modal"
      >
        <template v-slot:header>
          <h5 class="modal-title">Задать {{type === 'seller' ? 'продавца' : 'покупателя'}}</h5>
        </template>
        <template v-slot:body>
          <div>
            Задать для тикета {{type === 'seller' ? 'продавца' : 'покупателя'}}?
            <span class="text-primary">
                <span v-if="offer.person" class="d-flex text-muted">
                  {{personString(offer.person)}}
                </span>

                <span v-if="type === 'seller' && typeof offer.assigned_product.fields.subnets === 'object' && offer.assigned_product.fields.subnets.length">
                  <div v-for="subnet in offer.assigned_product.fields.subnets">
                    {{subnet.subnet}} <span v-if="subnet.type" class="badge" :class="subnet.type === 'PA' ? 'bg-primary' : 'bg-warning'">{{subnet.type}}</span>
                  </div>
                </span>

                <span v-if="type === 'buyer'">{{buyerString()}}</span>

              </span>
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="close">Отмена</button>
          <button type="button" class="btn btn-primary" @click="confirm">Задать</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import {PriceCalc} from "../../tiketMenu/components/Params/Product/Field/PriceCalc";

export default {
  name: "ModalConfirmOffer",

  emits: ['close', 'confirm'],
  components: {ModalWindow},

  props: {
    offer: {
      type: Object,
      require: true,
    },
    type: {
      type: String,
      require: true,
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('confirm');
    },

    personString() {
      let personArr = [];
      let hasName = false;

      if(typeof this.offer.person?.name === 'string' && this.offer.person.name.length) {
        personArr.push(this.offer.person.name.trim());
        hasName = true;
      }
      if(typeof this.offer.person?.email === 'string' && this.offer.person.email.length) {
        let email = this.offer.person.email.trim();
        email = hasName ? '(' + email + ')' : email;
        personArr.push(email);
      }

      return personArr.join(' ');
    },

    buyerString() {
      let fields = this.offer.assigned_product.fields ?? {};

      let prices = new PriceCalc({
        price: fields.price,
        price1: fields.price1,
        currency: fields.currency,
        quantity: fields.quantity,
        prefix: fields.prefix,
      }).getPrettyPrices();

      let string = (fields.type ?? '') +
                   (typeof fields?.prefix !== 'undefined' ?  ' /' + fields.prefix : '');

      if(prices !== null) {
        string += ' ' + prices.single + (prices.all !== null ? ' (' + prices.all + ')' : '');
      }

      return string;
    }
  },

}
</script>

<style scoped>
:deep(.modal-body) {
  margin: 10px 0;
}
:deep(.offer-confirm-modal) {
  --bs-modal-width: 600px;
}
</style>