<template>
  <span v-if="offset > 0" class="border-start" :class="border" :style="`padding-left: ${offset}px;`"></span>

  <div v-if="keyword.invalid" style="width: 220px;">
    <popper content="Блок задан некорректно, для решения проблемы попробуйте выделить название блока болдом или другим отличным от общего текста стилем"
            class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
      <input type="text" :value="keyword.keyword" disabled="disabled" class="form-control is-invalid w-100" style="border-radius: unset;" />
    </popper>
  </div>
  <template v-else>
    <span class="form-control d-flex keyword-name">
      <key-label-name :keyword="keyword" :draggable="draggable" :document="document" />
    </span>
  </template>


  <input type="text" v-model="keyword.label" class="form-control" :class="{'border-danger': keyword.invalid}" placeholder="Название" />
</template>

<script>
import StringTrim from "../../../string/StringTrim.vue";
import KeyLabelName from "./KeyLabelName.vue";

export default {
  name: "KeyLabel",

  components: {
    KeyLabelName,
    StringTrim,
  },

  props: {
    offset: {},
    keyword: Object,
    draggable: Boolean,
    border: Object,
  },

  computed: {
    document() {
      return this.$store.getters['document/getDocument'];
    },
  },

}
</script>

<style scoped>
:deep(.active-item) {
  background-color: #90c7ff;
}
:deep(.condition-remove-btn) {
  opacity: .6;
}
:deep(.condition-remove-btn:hover) {
  opacity: 1;
}
:deep(.v-contextmenu-item) {
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>