<template>
  <tr v-if="data" :class="tdClass">
    <td>{{ userName ?? '&mdash;' }}</td>
    <td class="text-center">{{ data?.count_read ?? '&mdash;'  }}</td>
    <td class="text-center">
      <span v-if="total">{{ getCount }}</span>
      <span v-else-if="getCount">
        <a :href="hrefAnswer" target="_blank">
          <template v-if="countUnconfirmed !== 0">
            <popper
                content="Одобрено"
                class="light"
                placement="top"
                :hover="true"
                :interactive="false"
                :arrow="true"
            >{{ data.countMessage ?? data.count ?? 0 }}</popper>
            (<popper
                content="Отправленные на премодерацию"
                class="light"
                placement="top"
                :hover="true"
                :interactive="false"
                :arrow="true"
            >
              {{ countUnconfirmed }}
              <template #content>
                <div class="mb-2 pb-2 fw-bold border-bottom">Отправлено на премодерацию</div>
                <table class="w-100">
                  <tbody class="text-start">
                    <tr><td class="pe-2">Создано:</td><td>{{ data?.create_unconfirmed_message ?? '&mdash;' }}</td></tr>
                    <tr><td class="pe-2">Изменено:</td><td>{{ data?.edit_unconfirmed_message ?? '&mdash;' }}</td></tr>
                    <tr><td class="pe-2">Сейчас на прем.:</td><td>{{ data?.countUnconfirmedMessage ?? '&mdash;' }}</td></tr>
                  </tbody>
                </table>
              </template>
            </popper>)
          </template>
          <template v-else>
            {{ getCount }}
          </template>
        </a>
      </span>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center">
      <a v-if="data.min && hrefMinAnswer" :href="hrefMinAnswer" target="_blank">
        <timediff v-if="data.min" :from="0" :to="data.min"></timediff>
      </a>
      <timediff v-else-if="data.min" :from="0" :to="data.min"></timediff>
      <span v-else>&mdash;</span>
    </td>
    <td class="text-center"><timediff v-if="data.avg" :from="0" :to="data.avg"></timediff><span v-else>&mdash;</span></td>
    <td class="text-center">
      <a v-if="data.max && hrefMaxAnswer" :href="hrefMaxAnswer" target="_blank">
        <timediff v-if="data.max" :from="0" :to="data.max"></timediff>
      </a>
      <timediff v-else-if="data.max" :from="0" :to="data.max"></timediff>
      <span v-else>&mdash;</span>
    </td>
  </tr>
</template>

<script>
import Timediff from "../common/timediff";
import {getTicketLink, getUserMessagesLink} from "../../library/Locations";
import {toRef} from "vue";
import {getName, getTdClass} from "./statisticFunctions";

export default {
  name: "StatisticRow",

  components: {
    Timediff
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
    total: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      require: true,
    },
    from: {

    },
    to: {

    },
    project: {
      type: String,
      default: 'all',
    }
  },

  setup(props) {
    const data = toRef(props, 'data');
    const total = toRef(props, 'total');
    const {userName} = getName({data});
    const {tdClass} = getTdClass({total});
    return {
      userName,
      tdClass,
    }
  },

  computed:{
    countUnconfirmed(){
      return (this.data?.create_unconfirmed_message ?? 0) + (this.data?.edit_unconfirmed_message ?? 0);
    },
    getCount() {
      let result = null;
      if (this.data) {
        if (this.countUnconfirmed) {
          result = (this.data?.countMessage ?? '0') + ' (' + this.countUnconfirmed + ')';
        } else if (typeof this.data.countMessage !== 'undefined' && this.data.countMessage) {
          result = this.data.countMessage;
        } else if (typeof this.data.count !== 'undefined' && this.data.count) {
          result = this.data.count;
        }
      }
      return result;
    },
    hrefAnswer() {
      return getUserMessagesLink({
        searchAlias: 'all',
        searchUser: this.id,
        searchFrom: this.from,
        searchTo: this.to,
        page: 1,
      },
      this.project ?? 'all');
    },
    hrefMinAnswer() {
      return typeof this.data?.min_data !== 'undefined' && this.data.min_data ? getTicketLink(this.data.min_data) : '';
    },
    hrefMaxAnswer() {
      return typeof this.data?.max_data !== 'undefined' && this.data.max_data ? getTicketLink(this.data.max_data) : '';
    },
  },

  methods: {

  }
}
</script>

<style scoped>
.total-td > td{
  border-top: 2px solid;
  font-weight: bold;
  color: rgba(33, 37, 41, 0.75) !important;
}
</style>