import {User} from "../../../../library/api/User";
import {UserPermission} from "../../../../users/UserPermission";
import {Task} from "../../../../library/api/Task";
import {CrmApi} from "../../../../library/CrmApi";

const taskActions = {
    init({dispatch}, {id}){
        //dispatch('loadPermissions');
        dispatch('loadUsers', id);
        // dispatch('loadTasks', {id});
    },

    loadPermissions({state, commit}) {
        UserPermission.can("system.task_control").then(() => {
            commit('setCanTasksControl', true);
        }).catch(() => {
            commit('setCanTasksControl', false);
        });
    },

    loadUsers({state, commit}, readticket = null) {
        commit('setUsers', []);
        User.getList(readticket).then((result) => {
            commit('setUsers', result.data);
        });
    },

    editTask({state, commit}, {id, params}) {
        return new Promise((resolve , reject)=> {
            Task.editTask(id, params).then((result) => {

                resolve(result);
            }).catch((error) => {

                reject(error);
            });
        });
    },

    loadTasks({state, commit}, {id}) {
        Task.loadTasks(id, {without_closed: true}).then((result) => {
            if (result.data.data) {
                commit('setTasks', result.data.data);
            }
        }).catch((error) => {
            commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
        })
    },

    createTask({state, commit}, params) {
        return new Promise((resolve, reject) => {
            Task.createTask(params).then((result) => {

                resolve(result);
            }).catch((error) => {

                reject(error);
            });
        });
    },
};

export default taskActions;