<template>
    <div class="v-sidebar-item-root v-sidebar-item d-flex">
      <!-- span v-if="project.icon">
        <i :class="this.iconStyle"></i>
      </span -->
      <span @click="toggleSlide" class="pe-1 cursor-pointer">
          <i class="fa fa-chevron-down" v-if="collapse"></i>
          <i class="fa fa-chevron-right" v-else></i>
      </span>
      <span @click="projectLabelClick" class="cursor-pointer w-100">
        <span class="pe-1">{{project.label}}</span>
      </span>
    </div>
    <div  class="overflow-hidden offset-1">
      <transition name="fade">
        <div v-show="collapse">
          <filter-element
              v-for="item in project.content"

              :label="item.label"
              :icon="item.icon"
              :content="item"
              :id="item.label"
              :key="item.id"
              :count-settings="countSettings"
              ref="menu-item"
          ></filter-element>
          <hr v-if="!isLastElement" />
        </div>
      </transition>
    </div>
</template>

<script>
import Filter from "./filter/Filter";

import {mapActions, mapGetters} from "vuex";

export default {
  name: "ProjectsSlide",
  emits: ["collapse"],
  components:{
    "filter-element":Filter
  },
  props: {
    "isLastElement":{
      type:Boolean,
    },
    "project": {
      type: Object,
      require: true,
    },
    "selectionControl":{
      type: Object,

    },
    "id": {
      type: String,
      require: true,
    },
    "clickevent": {
      type: Function,
    },
    countSettings: {
      type: Object
    }
  },
  data: function (){
    return {
      collapse: false,
      count: null,
      actualCount: false,
    }
  },
  computed:{
    iconStyle: function (){
      return "fa fa-" + this.project.icon;
    },

    getId:function (){
      return this.id;
    },
    ...mapGetters({
      'getSelectionControl':'leftMenu/getSelectionControl',
    }),
  },
  methods:{
    ...mapActions({
      'goToTickets':'leftMenu/goToTickets'
    }),

    setFilterCount(filterName, count) {
      let filter = this.$refs['menu-item'].filter(item => item.label === filterName)[0];
      filter.count = count;
      filter.actualCount = true;
    },

    toggleSlide() {
      this.setCollapse(!this.collapse);
      if(this.getSelectionControl.projectName !== this.project.name) {
        this.toNewFilter();
      }
    },

    projectLabelClick() {
      this.setCollapse(true);
      this.toNewFilter();
    },

    toNewFilter() {
      if(this.collapse) {;
        if(this.getSelectionControl.filter === 'new' && this.getSelectionControl.projectName === this.project.name) {
          this.$store.dispatch('tickets/getAllTickets', this.$route);
        } else {
          let item = this.project.content.filter(item => item.filter.type === 'new')
          if(item[0]) {
            this.goToTickets({content:item[0]})
          }
        }
      }
    },

    setCollapse(collapse) {
      if(collapse !== this.collapse && typeof this.project.content !== 'undefined') {
        this.collapse = collapse;
        this.$emit('collapse', this.collapse, this.project.name);
      }
    },

    tryCollapse: function() {
      if(this.$route.name === 'TicketsProjectFilter' || this.$route.name === 'Billmanager'  || this.$route.name === 'Calls' || this.$route.name === 'Tasks'){

        if(this.$route.params.project === this.project.name){
          this.collapse = true;
          this.$nextTick(function() {
            this.$emit('collapse', true, this.project.name);
          });
        }
      }
    },

    setCounts() {
      if(this.count !== null) {
        this.$nextTick(() => {
          for(let name in this.count) {
            let item = this.$refs["menu-item"].filter(item => item.content.filter.type === name);
            if(typeof item[0] !== 'undefined') {
              item[0].count = this.count[name];
              item[0].actualCount = this.actualCount;
            }
          }
        });
      }
    }
  },

  created() {


    this.tryCollapse();
    if( this.project.name === this.getSelectionControl.projectName){
       this.collapse = true;
      this.$nextTick(function() {
        this.$emit('collapse', true, this.project.name);
      });
    }
  },


  watch: {
    '$route.params'(to, from) {
      this.tryCollapse();
    },

    'count' (to) {
      this.setCounts();
    },

    'collapse' (to) {
      if(to && this.count !== null) {
        this.setCounts();
      }
    }
  }
}

</script>

<style scoped>

.cursor-pointer {
  cursor: pointer;
}
.v-sidebar-item{
  padding-left: 5px;
  border-radius: 5px;
}
.v-sidebar-item:hover{
  background-color: #d3fad3;
}

hr{
  margin: 10px 0;
}
.v-sidebar-item-root{
  font-weight: 600;
}

.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 100%;
    margin-top: 0px;
  }
}
</style>