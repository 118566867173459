<template>
  <div class="col-12">
    <button class="btn btn-light btn-outline-dark delete-with-tag-button" :class="{'col-6': deleteTag, 'col-12': !deleteTag}" @click="showModalDelete('spam')">
      <i class="fa fa-trash-o button-icon menu-button"></i>Спам
    </button>
    <button v-if="deleteTag" class="btn btn-light btn-outline-dark delete-with-tag-button col-6" @click="showModalDelete('deletetag')">
      <i class="fa fa-trash-o button-icon menu-button"></i>{{deleteTag}}
    </button>
    <teleport to="body">
      <transition name="modal">
        <modal-window v-if="showModal" @close="closeModal"  @success="deleteTicket()" ok-button-style="btn-danger" >
          <template v-slot:header>
            <h5 class="modal-title text-danger">Удаление тикета</h5>
          </template>
          <template v-slot:body>
            <span>Вы уверены, что хотите удалить выбранные тикеты?</span>
          </template>
          <template v-slot:footer>
            <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
            <button type="button" class="btn btn-danger" @click="deleteTicket()">Удалить с меткой {{tagName}}</button>
          </template>
        </modal-window>
      </transition>
    </teleport>
  </div>
</template>

<script>
import StringTrim from "../../string/StringTrim";
import ModalWindow from "./ModalWindow";

export default {
  name: "DeleteWithTagButton",
  components: {StringTrim, ModalWindow},
  props: {
    deleteTag: {},

    callback: {
      default: null
    },

    queue: {
      default: null
    }
  },

  data () {
    return {
      type: null,
      showModal: false,
    }
  },

  computed: {
    tagName() {
      let result = '';
      if (this.type === 'spam') {
        result = 'spam';
      } else if (this.type === 'deletetag') {
        result = this.deleteTag;
      }
      return result;
    },
  },

  methods: {
    deleteTicket() {
      let action = () => {
        this.$store.dispatch('tickets/deleteTickets', {
          ticketIds: this.$store.getters['tickets/getSelectedArray'],
          route: this.callback ?? this.$route,
          withTag: this.type,
        });
      }
      if(this.queue === null) {
        action();
      } else {
        this.queue(action);
      }
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketDeleteWithTag');
    },
    showModalDelete(type) {
      this.type = type;
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketDeleteWithTag');
    },
  }
}
</script>

<style scoped>
.delete-with-tag-button.col-6:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.delete-with-tag-button.col-6:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>