<template>
  <input  @keyup.enter.stop="{}"  @change="clickOnCheckBox(object)" class="form-check-input" type="checkbox" v-model="object.value" :id="'checkBoxSearch' + object.id" :title="object?.title">
  <label class="form-check-label" :for="'checkBoxSearch' + object.id" :title="object?.title">
    {{object.name}}
  </label>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "CheckBox",
  data: function (){
    return {
    }
  },
  props:{
    "object":{
      type:Object,
      require:true,
    },
  },

  methods:{
    ...mapActions({
      'clickOnCheckBox':'tickets/filters/clickOnCheckBox'
    }),
  },
  computed:{
    ...mapGetters({
      'getSearchParamsString':'tickets/filters/getSearchParamsString'
    })
  }

}
</script>

<style scoped>

</style>