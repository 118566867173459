<template>
  <transition name="search-settings" >
    <div v-show="settings.length > 0" class="search-settings-border">
      <span class="bordered-cell" style="z-index: 1">
        <div v-if="showSelect" class="d-inline-block me-2">
          <div class="form-label" :class="aliasClass">Ищем в</div>
          <select class="form-check form-check-inline px-1" @change="changeAlias" v-model="alias">
            <option value="all">Все папки</option>
            <option v-for="(name, value) in aliasSelect" :value="value">{{name}}</option>
          </select>
        </div>

        <div v-if="searchProduct && !isBillmanagerTickets" class="d-inline-block me-2">
          <div class="form-label">Услуга</div>
          <select class="form-check form-check-inline px-1" @change="changeProduct" v-model="product">
            <option :value="null" selected>Все тикеты</option>
            <option value="without">Не указано</option>
            <option v-for="product in products" :value="product._id">{{product.name}}</option>
          </select>
        </div>

        <div v-if="usersRequested" class="d-inline-block me-2">
          <div v-if="searchUser && usersOut.length">
            <div class="form-label">Сотрудник</div>
            <select class="form-check form-check-inline px-1" @change="changeUser" v-model="user">
              <option :value="null" selected>Не учитывать</option>
              <option v-for="userObj in usersOut" :value="userObj._id">{{getUserName(userObj)}}</option>
            </select>
          </div>
          <span v-else-if="user !== null" class="badge bg-secondary" style="margin: 2px; vertical-align: top;">
            Фильтр по пользователю
            <i @click="clearUserFilter" class="fa fa-window-close pe-0 ps-1 header-remove-btn" style="cursor: pointer;"></i>
          </span>
        </div>

        <div class="d-inline-block me-2" v-if="showStatusSelect">
          <div class="form-label">Статус</div>
          <select class="form-check form-check-inline px-1" @change="changeStatus" v-model="ticketStatus">
            <option :value="null" selected>Все статусы</option>
            <option value="with_tasks">С задачами</option>
            <option value="without_tasks">Без задач</option>
            <option value="not_active_one_week">Нет активности 1-2 нед.</option>
            <option value="not_active_two_weeks">Нет активности 2-4 нед.</option>
            <option value="not_active_one_month">Нет активности 1-3 мес.</option>
            <option value="not_active_three_months">Нет активности 3-6 мес.</option>
            <option value="not_active_six_months">Нет активности 6-12 мес.</option>
            <option value="not_active_one_year">Нет активности более года</option>
            <option value="success">Успешные</option>
            <option value="fail">Неуспешные</option>
          </select>
        </div>
        <div class="d-inline-block me-2" v-if="showProjectsSelect">
          <div class="form-label">Проект</div>
          <select class="form-check form-check-inline px-1" @change="changeProject" v-model="currentProject">
            <option v-for="project in projects" :value="project.name">{{ project?.label ?? project.name }}</option>
          </select>
        </div>
        <div class="d-inline-block me-2" v-if="showCallTypeSelect">
            <div class="form-label">Тип</div>
            <select class="form-check form-check-inline px-1" @change="changeCallType" v-model="callType">
              <option v-for="type in callTypes" :value="type.name">{{type.label}}</option>
            </select>
        </div>
        <div class="d-inline-block me-2" v-if="showCallStatusSelect">
            <div class="form-label">Статус</div>
            <select class="form-check form-check-inline px-1" @change="changeCallStatus" v-model="callStatus">
              <option v-for="type in callStatuses" :value="type.name">{{type.label}}</option>
            </select>
        </div>
        <br>
        <div v-for="object in settings" class="form-check form-check-inline">
          <check-box :ref="object.id" :object="object"></check-box>
        </div>
        <template v-if="!isBillmanagerTickets" >
          <div v-for="object in getAdditionalSearchSettingsToShow" class="form-check form-check-inline">
            <check-box :ref="object.id" :object="object"></check-box>
          </div>
        </template>
      </span>
    </div>
  </transition>
</template>

<script>
import CheckBox from "./CheckBox";
import AdditionalFilters from "./AdditionalFilters";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";

export default {
  name: "SearchSettings",
  components: {AdditionalFilters, CheckBox},
  data:function (){
    return {
      project: null,
      callTypes:[
        {
          label:'Не учитывать',
          name:''
        },
        {
          label:'Входящий',
          name:'incoming',
        },
        {
          label:'Исходящий',
          name:'outgoing',
        }
      ],
      callStatuses:[
        {
          label:'Не учитывать',
          name:''
        },
        {
          label:'Звонок завершен',
          name:'completed'
        },
        {
          label:'Звонок пропущен',
          name:'missing'
        },
        {
          label:'Идет разговор',
          name:'going'
        },
      ],
    }
  },

  mounted: function() {
    this.project = this.$route.params.project;
    if(this.searchProduct) {
      this.loadProducts();
    }
    this.user = this.$route.query.searchUser;
    if(this.searchUser) {
      this.loadUsers();
    }
    if( this.$route.query?.callStatus){
      this.callStatus = this.$route.query.callStatus;
    }

    if( this.$route.query?.call_type){
      this.callType = this.$route.query.call_type;
    }
  },

  computed:{
    settings: function() {
      return this.isBillmanagerTickets ? this.billmanagerSettingsToShow : this.getSearchSettingsToShow;
    },

    isBillmanagerTickets: function() {
      return (this.$route.name === 'Billmanager');
    },

    showStatusSelect() {
      return this.$route.name !== 'Calls';
    },
    showCallStatusSelect(){
      return this.$route.name === 'Calls';
    },
    showCallTypeSelect(){
      return this.$route.name === 'Calls';
    },

    showProjectsSelect() {
      return this.project === 'all';
    },
    callType:{
        get(){
          return this.$store.getters['tickets/filters/getCallType']
        },
        set(value){
          this.$store.commit('tickets/filters/setCallType', value);
        }
    },
    callStatus:{
      get(){
        return this.$store.getters['tickets/filters/getCallStatus']
      },
      set(value){
        this.$store.commit('tickets/filters/setCallStatus', value);
      }
    },
    'alias':{
      get () {
        return  this.$store.getters['tickets/filters/getAlias']
      },
      set (value) {
        this.$store.commit('tickets/filters/updateAlias', value)
      }
    },
    product: {
      get() { return this.getProduct; },
      set(value) { this.updateProduct(value); }
    },

    user: {
      get() { return this.getUser; },
      set(value) { this.updateUser(typeof value === 'undefined' ? null : value); }
    },

    ticketStatus: {
      get() {
        return this.getTicketStatus;
      },
      set(value) {
        this.setTicketStatus(typeof value === 'undefined' ? null : value);
      }
    },

    currentProject: {
      get() {
        return this.getSearchProject;
      },
      set(value) {
        this.setSearchProject(typeof value === 'undefined' ? 'all' : value);
      }
    },

    showSelect: function() {
      return !['Trash', 'Unsorted', 'Billmanager', 'Calls', 'Competitor', 'Recent'].includes(this.$route.name);
    },

    searchProduct: function() {
      return !(this.$route.name === 'Unsorted' || this.$route?.params?.filter === 'notifications');
    },

    products: function() {
      return ((this.project || this.$route.name === 'Trash' || this.$route.name === 'Competitor') && this.getProducts) ? this.getProducts[this.project ?? 'all'] ?? [] : [];
    },

    searchUser: function() {
      return !(this.isBillmanagerTickets || this.$route?.params?.filter === 'notifications');
    },

    usersOut() {
      return this.users.filter((user) => user.login.indexOf('test') === -1).sort((a, b) => {
        let aName = a?.name ?? '', bName = b?.name ?? '';

        if( aName === bName){
          return 0;
        }
        return aName === "" ? 1 : (bName === "" ? -1 : aName.localeCompare(bName));
      })
    },

    aliasClass() {
      return {
        'fw-bold': typeof this.$route?.query?.searchAlias === 'string' && this.$route?.query?.searchAlias.trim() !== '',
      };
    },

    ...mapGetters({
      'aliasSelect':'tickets/filters/getSidebarFilters',
      'settingsToShow':'tickets/filters/getSettingsToShow',
      billmanagerSettingsToShow: 'tickets/filters/getBillmanagerSettingsToShow',
      'getSearchSettingsToShow':'tickets/filters/getSearchSettingsToShow',
      'getAdditionalSearchSettingsToShow':'tickets/filters/getAdditionalSearchSettingsToShow',
      getProjects: 'tickets/projects/getProjects',
      getProducts: 'tickets/filters/getProducts',
      getProduct: 'tickets/filters/getProduct',
      users: 'tickets/filters/getUsers',
      getUser: 'tickets/filters/getUser',
      getTicketStatus: 'tickets/filters/getTicketStatus',
      usersRequested: 'tickets/filters/getUsersRequested',
      searchString: 'tickets/filters/getSearchString',
      'projects': 'leftMenu/getTopLevel',
      'getSearchProject': 'tickets/filters/getSearchProject',
    }),
  },

  methods:{
    ...mapActions({
      loadProducts: 'tickets/filters/loadProducts',
      changeProduct: 'tickets/filters/changeProduct',
      loadUsers: 'tickets/filters/loadUsers',
      changeUser: 'tickets/filters/changeUser',
      changeStatus: 'tickets/filters/changeStatus',
      changeProject: 'tickets/filters/changeProject',
      changeCallType:'tickets/filters/changeCallType',
      changeCallStatus:'tickets/filters/changeCallStatus',
      'changeAlias':'tickets/filters/changeAlias',
    }),

    ...mapMutations({
      updateProduct: 'tickets/filters/updateProduct',
      updateUser: 'tickets/filters/updateUser',
      setTicketStatus: 'tickets/filters/setTicketStatus',
      updateSettingsShow: 'tickets/filters/updateSettingShow',
      setSearchProject: 'tickets/filters/setSearchProject',
    }),

    getUserName(user) {
      return user?.name ? `${user?.name} (${user?.login})`: user.login;
    },

    clearUserFilter() {
      this.user = null;
      this.changeUser();
      if(this.searchString === null || this.searchString === '') {
        this.updateSettingsShow(false);
      }
    }
  },

  watch: {
    '$route.params.project' (project) {
      this.project = project;
    }
  }
}
</script>

<style scoped>
.bordered-cell{
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px 5px 5px;
  margin-right: 5px;
}
.search-settings-enter-active, .search-settings-leave-active {
  transition: opacity .5s ease;
}

.search-settings-enter-from, .search-settings-leave-to {
  opacity: 0;
}

.form-check-inline:last-child {
  margin-right: 0;
}
.search-settings-border{
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #21252a;
  border-right: 1px solid #dee2e6;
}
.form-label {
  margin-bottom: 0;
  font-size: 12px;
  color: #757575;
}
</style>