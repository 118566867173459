<template>
  <div class="d-flex" :class="{'text-muted': loading}">

    <div class="pe-1">Имя:</div>
    <div class="flex-grow-1" style=" display: flex;" @mouseleave="showCopyMenu =false" @mouseover="showCopyMenu = true">
      <editable-text
          v-if="userCanChange"
          :trim="true"
          :text="person.name"
          default="Не заполнено"
          :callback="changeName"
          input-class="name-input w-75 form-control form-control-sm"
      />
      <string-trim v-else :string="person.name ?? 'Не заполнено'" />

      <span v-if="person.name">
        <copy-badge :show="showCopyMenu" :content="person.name"></copy-badge>
      </span>
    </div>
  </div>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import EditableText from "../../../Editable/EditableText";
import CopyBadge from "../../../common/CopyBadge";
import StringTrim from "../../../string/StringTrim";
import {mapGetters} from "vuex";

export default {
  name: "Name",

  components: {
    StringTrim,
    CopyBadge,
    'editable-text': EditableText,
  },

  props: {
    person: {},
    ticketId: {
      require: true
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  emits: [
      "updatePerson",
  ],

  data: function() {
    return {
      loading: false,
      showCopyMenu:false,
    }
  },

  computed: {
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },

  methods: {
    changeName: function(name) {
      this.loading = true;
      let prevValue = this.person.name;
      this.person.name = name;

      this.api.editPerson({"change": {"name": name}}).then((result) => {
        if (typeof result.data?.person === 'object' && result.data.person !== null) {
          this.$emit('updatePerson', result.data.person);
        }
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.person.name = prevValue;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
}
</script>

<style scoped>
</style>