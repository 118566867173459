import {CrmApi} from "../CrmApi";

export class Thread {

    abortController;
    threadId;


    constructor(id = null) {
        this.abortController = new AbortController();
        this.threadId = id;
    }

    abort() {
        this.abortController.abort();
        CrmApi.removeAbortController(this.abortController);
    }

    getNewTicketSignature(project, data) {
        return this.call('/ticket/new/' + project + '/signature', data);
    }

    getSignature(message = null, alias = null) {
        let params = _.pickBy({message, alias});
        return this.call('/thread/' + this.getThreadId() + '/signature/?' + new URLSearchParams(params).toString());
    }

    getInfo() {
        return this.call('/thread/' + this.getThreadId() + '/info/');
    }

    getThreadHeaders() {
        return this.call('/thread/' + this.getThreadId() + '/headers/');
    }

    getTicketTemplates() {
        return this.call('/thread/' + this.getThreadId() + '/templates/');
    }

    addNewTicketAttachments(project, attachments) {
        let formData = new FormData();
        for(let index in attachments) {
            formData.append('attachments[' + index + ']', attachments[index]);
        }
        return this.call("/ticket/new/" + project + '/attachment', formData, {headers: {'Content-type': 'multipart/form-data'}});
    }

    uploadPersonDocument(document, unique) {
        let formData = new FormData();
        formData.append('unique', unique);
        formData.append('document', document);

        return this.call(
            '/thread/' + this.getThreadId() + '/person/document/',
            formData,
            {headers: {'Content-type': 'multipart/form-data'}}
        );
    }

    approveCompanyChanges(eventId) {
        return this.call('/thread/' + this.getThreadId() + '/person/approve', {message: eventId});
    }

    declineCompanyChanges(eventId, error_text) {
        return this.call(
            '/thread/' + this.getThreadId() + '/person/decline',
            {
                message: eventId,
                error_text: error_text,
            }
        );
    }

    getTicketList(query, filter) {
        return CrmApi.getTicketList(query, filter, this.abortController);
    }

    getMessagesBefore(messageId, filters = undefined, updates = false, draft = undefined) {
        let queryString =  ( typeof messageId !== 'undefined' && messageId != null ? "messagesBefore="+ messageId : "")
            + ( typeof filters !== 'undefined' ? "&"+ Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join('&') : "");

        let uri = "/thread/" + this.getThreadId() + "/" +
            ( updates ? 'updates/' : '' ) +
            ( queryString !== "" ? "?" + queryString  : "");

        return (typeof draft === 'undefined') ? this.call(uri) : this.call(uri, {draft: draft});
    }

    getMessagesAfter(messageId, filters = undefined, updates = false, draft = undefined) {
        let queryString =  ( typeof messageId !== 'undefined' && messageId != null ? "messagesAfter="+ messageId : "")
            + ( typeof filters !== 'undefined' ? "&"+ Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join('&') : "");

        let uri = "/thread/" + this.getThreadId() + "/" +
            ( updates ? 'updates/' : '' ) +
            ( queryString !== "" ? "?" + queryString  : "");

        return (typeof draft === 'undefined') ? this.call(uri) : this.call(uri, {draft: draft});
    }

    updateDraft(draft) {
        let uri = "/thread/" + this.getThreadId() + "/updatedraft"

        return this.call(uri, {draft: draft});
    }

    getMessagesAfterWithWait(messageId, filters = undefined) {
        let queryString =
            ( typeof messageId !== 'undefined' && messageId != null ? "messagesAfter="+ messageId : "")
            + ( typeof filters !== 'undefined' ? "&"+ Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join('&') : "");

        let uri = "/thread/" + this.getThreadId() + "/waitupdates/?random=" + Math.random() +
            ( queryString !== "" ? "&" + queryString  : "");

        return this.call(uri);
    }



    getTicketAndMessagesBefore(messageId, filters = undefined, draft = undefined) {
        return this.getMessagesBefore(messageId, filters, true, draft );
    }

    getTicketAndMessagesAfter(messageId, filters = undefined, draft = undefined) {
        return this.getMessagesAfter(messageId, filters, true, draft);
    }

    lock() {
        return this.call('/thread/' + this.getThreadId() + '/lock/');
    }

    getDraft() {
        return this.call('/thread/' + this.getThreadId() + '/draft/');
    }

    setBid() {
        return this.call('/thread/' + this.getThreadId() + '/changebid/', {approved: true});
    }


    getBids(subnet) {
        return this.call('/thread/' + this.getThreadId() + '/bids/', {subnet});
    }

    editPerson(data) {
        return this.call('/thread/' + this.getThreadId() + '/person/edit', data);
    }

    searchInTicket(search) {
        return this.call('/thread/' + this.getThreadId() + '/?search=' + search);
    }

    getThreadId() {
        if(this.threadId === null) {
            throw new Error('Не задан айди тикета');
        }
        return encodeURIComponent(this.threadId)
    }

    call(path, data, config) {
        return CrmApi.call(path, data, config, true, this.abortController);
    }
}