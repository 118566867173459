<template>
  <input
      type="text"
      class="form-control search-input"
      name="group"
      v-model="value"
      :placeholder="placeholder"
  >
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import addToQueryWithTimer from "./timer";

export default {
  name: "FilterInput",

  setup() {
    return {
      addToQueryWithTimer
    };
  },

  props: {
    'placeholder': {
      type: String,
      default: "",
    },
    'field': {
      type: String,
      require: true,
    },
    'object': {
      type: Object,
    }
  },

  computed: {
    value: {
      get() {
        return this.getFilterByName(this.field).value;
      },
      set(value) {
        let obj = Object.assign({}, this.object);
        obj.value = value;
        this.setFilterValue(obj);
        this.addToQueryWithTimer(value, this.field);
      }
    },
    ...mapGetters({
      "isLoading": "offer/getLoading",
      "show": "offer/filters/showFilter",
      "getFilterByName": "offer/filters/getFilterByName",
    }),
  },

  methods:{
    ...mapMutations({
      'setFilterValue':'offer/filters/setFilterValue',
    }),
  }
}
</script>

<style scoped>

</style>