import addressParser from "address-rfc2822";

const getters = {
    showCreateForm: (state) => {
        return state.showCreateForm;
    },

    getSearchString: (state) => {
        return state.searchTicketsString;
    },

    getSearchEmails: (state, getters) => {
        let result = [];

        if( getters.getSearchString != null ) {
            getters.getSearchString.split(/[, ]+/).forEach((str) => {
                try {
                    let adr = addressParser.parse(str).map(address => address.address);
                    result = result.concat(adr);
                } catch (nothing) {}
            });

            if( result.length === 0 ){
                result.push(getters.getSearchString.trim());
            }
        }

        return result;
    },

    getSelectedProjectName: (state) => {
      return state.selectedProject ?? 'all';
    },

    currentProject: (state, getters, rootState, rootGetters) => {
        return rootGetters['leftMenu/getSelectionControl'].projectName;
    },

    getProjects: (state, getters, rootState, rootGetters) => {
        return rootGetters['leftMenu/getTopLevel'].filter((project) => project._id !== 'all');
    },

    projectsCanAnswer: (state) => {
        return state.projectsCanAnswer;
    },

    projectsCanCreate: (state) => {
        return state.projectsCanCreate;
    },

    canCreateTicket: (state, getters) => {
        let name = getters.currentProject;
        let projects = getters.projectsCanCreate;
        return name && name !== 'all' ? projects.indexOf(name) !== -1 : projects.length !== 0;
    },

    getProjectByName: (state, getters) => (name) => {
        return getters.getProjects.find((project) => project.name === name);
    },

    getProjectById: (state, getters) => (id) => {
        return getters.getProjects.find((project) => window.getId(project._id) === id);
    },

    getTicketList: (state) => {
        return state.ticketList;
    },

    isLoading: (state) => {
        return state.isLoading;
    },

    getForceCreate: (state) => {
        return state.forceCreate;
    },

    inputsDisabled: (state, getters) => {
        return  !state.forceCreate && ( getters.getTicketList.length > 0 || getters.isLoading );
    },

    getPaginate: (state) => {
        return state.paginate;
    },

    getIsCreatingTicket: (state) => {
        return state.isCreatingTicket;
    },

    getProducts: (state) => {
        return state.products;
    },

    isProductsLoading: (state) => {
        return state.isProductsLoading;
    },
}

export default getters;