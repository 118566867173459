<template>
  <div @mouseenter="showAddBtn = true" @mouseleave="showAddBtn = false" ref="emaillist">
    <div class="pe-1 pb-0">Email:
      <i class="fa fa-plus-circle" style="color:#6262f5;cursor:pointer;" @click="showAddEmailField = true" v-if="userCanChange && showAddBtn"></i>
    </div>

    <div class="ps-2" :class="containerClass">
      <span class="email-item" v-if="person.email">
        <email
            :person="person"
            :ticket-id="ticketId"
            :email="person.email"
            :main="true"
            type="основной"
            :cut-type="true"
            :trim="true"
            :absolute="inputAbsolute"
            :absolute-parent="$refs.emaillist"
            trim-popper-placement="bottom"
            :clipboard="false"
            :user-can-change="userCanChange"
            @update="updatePerson"
        />
      </span>

      <span v-for="email in emails" class="email-item" v-if="emails.length" :key="email.mail">
        <email
            :person="person"
            :ticket-id="ticketId"
            :email="email.mail"
            :type="email.type"
            :name="email.name"
            :cut-type="true"
            :trim="true"
            :absolute="inputAbsolute || (email?.mail && email.mail.length > 30)"
            :absolute-parent="$refs.emaillist"
            :dynamic-absolute-width="true"
            trim-popper-placement="bottom"
            :clipboard="false"
            :user-can-change="userCanChange"
            @update="updatePerson"
        />
      </span>
      <div style="min-height: 40px;" :class="{'ps-2': !inputAbsolute, 'pt-1': inputAbsolute}" v-if="showAddEmailField">
        <editable-text
            text=""
            :callback="addEmail"
            @cancel="editCancel"
            :start-as-input="true"
            :default-valid="true"
            :validation="EmailValidate.validate"
            :disabled="loading"
            input-class="form-control d-inline"
            input-style="max-width: 300px;"
        />
      </div>
      <div v-if="trim && canBeTrimmed"
            @click="toggleShowTrimmed"
            class="angle-down-container"
      >
        <i v-if="!showTrimmed"
          class="fa fa-angle-up"
          aria-hidden="true"></i>
        <i v-if="showTrimmed"
           class="fa fa-angle-down"
           aria-hidden="true"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import EditableText from "../../../Editable/EditableText";
import StringClipboard from "../../../string/StringClipboard";
import {CrmApi} from "../../../../library/CrmApi";
import {Email as EmailValidate} from "../../../../library/Email";
import Email from "./Entity/Email";
import {mapGetters} from "vuex";

export default {
  name: "EmailList",

  components: {
    EditableText,
    StringClipboard,
    Email,
  },

  props: {
    person: {},
    ticketId: {
      require: true
    },
    trim: {
      type: Boolean,
      default: false,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      loading: false,

      showAddBtn: false,
      showAddEmailField: false,

      inputAbsolute: false,
      showTrimmed: true,
      addedEmails: [],
    }
  },

  computed: {
    EmailValidate() {
      return EmailValidate;
    },
    emails: function() {
      let result = [];
      if (typeof this.person?.contact_emails === 'object' && Array.isArray(this.person?.contact_emails)) {
        result = this.trim && this.showTrimmed ?
            this.person?.contact_emails.filter((item, index) => index <= 1 || item?.mail && this.addedEmails.includes(item?.mail)) :
            this.person?.contact_emails;
      }
      return result;
    },
    canBeTrimmed: function () {
      return this.trim &&
             typeof this.person?.contact_emails === 'object' &&
             Array.isArray(this.person?.contact_emails) &&
             this.person?.contact_emails.length > 1;
    },
    containerClass() {
      return {
        'trimmed': this.trim && this.showTrimmed && !this.showAddEmailField,
      }
    },
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },

  mounted() {
    this.inputAbsolute = ($(this.$refs.emaillist).width() < 260);
  },

  methods: {
    toggleShowTrimmed() {
      this.showTrimmed = !this.showTrimmed;
    },
    editCancel() {
      this.showAddEmailField = false;
    },
    addEmail: function(mail, index) {
      if (mail.trim() !== '') {
        this.loading = true;
        return new Promise((resolve, reject) => {
          let index;
          if (typeof this.person.email !== 'string') {
            index = 0;
          } else if (typeof this.person?.contact_emails === 'object' && Array.isArray(this.person?.contact_emails)) {
            index = this.person?.contact_emails.length + 1;
          } else {
            index = 1;
          }
          let mailObj = {}
          mailObj[index] = mail;
          this.api.editPerson({"add": {"email": mailObj}}).then((result) => {
            this.showAddEmailField = false;
            this.addedEmails.push(mail.trim());
            if (typeof result.data?.person === 'object' && result.data.person !== null) {
               this.updatePerson(result.data.person);
            }
            resolve(result.data);
          }).catch((error) => {
            this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
            reject(error);
          }).finally(() => {
            this.loading = false;
          });
        });
      } else {
        this.showAddEmailField = false;
      }
    },

    updatePerson(value) {
      this.$emit('update', value);
    }
  }
}
</script>

<style scoped>
.email-item {
  display: block;
}
.angle-down-container {
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
}
.trimmed {
  position: relative;
}
.trimmed .angle-down-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f9fa;
  background: linear-gradient(180deg, rgba(248,249,250,0.5) 0%, rgba(248,249,250,0.8) 30%, rgb(248, 249, 250) 100%);
  color: #6c757d;
}
</style>