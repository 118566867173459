


export default function getHref(type, object){
    let href = ''
    if(['inetnum','inet6num'].indexOf(type.toLowerCase())  !== -1){
        href =  'https://www2.whois7.ru/?q=' + encodeURIComponent(object) + "#whois";
    }else {
        href =  'https://www2.whois7.ru/?s=manual&q='+encodeURIComponent(object)+'&w=whois.ripe.net#whois';
    }
    return href;
}