import {computed, reactive} from "vue";


export default function getPersonFields({person} , firstValue = ''){
    const id = computed({
            set(value){
                person.value._id = value
            },
            get(){
                if(typeof person.value?._id == 'undefined'){
                    return null;
                }
                return window.getId(person.value?._id);
            }
        });

    const name = computed({
            set(value){
                person.value.name = value
            },
            get(){
            return person.value?.name ?? firstValue;
        }});


    const email = computed({
        set(value){
            person.value.email = value;
        },
        get(){
            return person.value?.email ?? firstValue;
        }
    });

    const phones = computed({
        set(value){
            person.value.phone = value;
        },
        get(){
            return typeof  person.value?.phone !='undefined' ?  person.value?.phone : [];
        }
    });

    const contact_emails = computed(
        {
            set(value){
                person.value.contact_emails = value;
            },
            get(){
                return typeof  person.value?.contact_emails !='undefined' ?  person.value?.contact_emails : [];
            }
    });

    const lirid = computed({
        set(value) { person.value.lirid = value; },
        get() { return person.value?.lirid ?? firstValue }
    });

    const org = computed({
        set(value) { person.value.org = value; },
        get() { return person.value?.org ??  firstValue }
    });

    const ticket = computed({
        set(value) { person.value.ticket = value; },
        get() { return person.value?.ticket ??  firstValue }
    });

    return {id, name , email, phones, contact_emails, lirid, org, ticket}
}

