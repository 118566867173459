import {CrmApi} from "../CrmApi";

export {User}
class User {

    static getList(ticketId = null){
        let readticket = (ticketId !== null) ? '?readticket=' + encodeURIComponent(ticketId) : '';
        return CrmApi.call('/user/shortlist' + readticket);
    }

    static getExtendedList( {withoutStatus} ){
        let request = {};

        if( withoutStatus != null ){
            request.without_status = withoutStatus;
        }

        let url = '/user/list?' + Object.keys(request).map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(request[key])
        }).join('&');

        return CrmApi.call(url);
    }

    static edit(id, params) {
        return CrmApi.call('/user/' + encodeURIComponent(window.getId(id)) + '/', params);
    }

    static updateSettings({settings,}){
        return CrmApi.call('/settings/' , {settings:settings} );
    }
}