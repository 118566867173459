<template>
  <div class="row" style="height: 45px;">
    <div class="col-11 pe-0" >
      <input
          ref="search"
          name="search"
          class="form-control"
          type="text"
          placeholder="Поиск"
          v-model.trim="search"
      >
    </div>
    <div class="col-1">
      <filter-button />
    </div>
  </div>
</template>

<script>
import FilterButton from "./FilterButton";
import {mapActions, mapMutations} from "vuex";

export default {
  name: "Search",

  components: {FilterButton},

  date() {
    return {
      timer: null
    }
  },

  computed: {
    search: {
      get() { return this.$store.getters['thread/offers/filters/getSearchString']; },
      set(search) {
        this.$store.commit('thread/offers/filters/setSearchString', search);
        this.setPage(1);

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.updateOffersList();
        }, 600)
      }
    },
  },

  methods: {
    ...mapActions({
      updateOffersList: 'thread/offers/updateOffersList',
      setPagination: 'thread/offers/setPagination',
    }),
    ...mapMutations({
      setPage: 'thread/offers/setPage',
    }),
  }
}
</script>

<style scoped>

</style>