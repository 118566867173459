<template>
  <template v-if="keyword.format">

    <input type="text" v-model="keyword.format.value" class="form-control" :placeholder="keyword.format.default" />
    <span class="input-group-text p-1">
        <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <i class="fa fa-question-circle-o" aria-hidden="true"></i>
            <template #content>
              <template v-if="keyword.format.name === 'price'">
                <b>{currency}</b> — USD/EUR/RUB<br/>
                <b>{currency_ru}</b> – дол./евро/руб./у. е.<br/>
                <b>{symbol}</b> — $/€/₽<br/>
                <b>{price_string_ru}</b> — число прописью на русском полностью, включая знаки после запятой и валюту (105,15 - сто пять рублей пятнадцать копеек)<br/>
                <b>{price_string_en}</b> — число прописью на английском, без знаков после запятой (105,15 - one hundred five)<br/>
                <b>{decimal}</b> – знаки после запятой (105,15 - 15)<br/>
                <b>0 000,00</b> — делитель тысячных - пробел, делитель целого числа - запятая, 2 символа после запятой
              </template>
              <template v-if="keyword.format.name === 'subnet_seller'">
                <b>{ip}</b> – первый адрес<br/>
                <b>{prefix}</b> – CIDR префикс<br/>
                <b>{count}</b> – количество адресов<br/>
                <b>{type}</b> – тип
              </template>
              <template v-if="keyword.format.name === 'subnet_buyer'">
                <b>{prefix}</b> – CIDR префикс<br/>
                <b>{count}</b> – количество адресов<br/>
                <b>{type}</b> – тип
              </template>
              <template v-if="keyword.format.name === 'address'">
                <b>{index}</b> – индекс<br/>
                <b>{country_ru}</b> – страна на русском<br/>
                <b>{country_en}</b> – страна на английском
                <b>{city}</b> – населенный пункт. Включает область (через запятую), если она есть в адресе<br/>
                <b>{address}</b> – остальная часть адреса (улица, дом, офис)<br/>
              </template>
            </template>
          </popper>
      </span>
    <popper v-if="keyword.format.name === 'price'" content="Условные единицы" class="light format-popper" placement="top" :hover="true" :interactive="true" :arrow="true">
        <span class="input-group-text d-flex align-items-baseline rounded-0">
          <span class="m-auto pe-1">у.е.</span>
          <input type="checkbox" v-model="keyword.format.conventionalUnits" class="form-check-input" />
        </span>
    </popper>

  </template>
</template>

<script>
export default {
  name: "Format",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>
.format-popper {
  border: none !important;
  margin: 0 !important;
}
</style>