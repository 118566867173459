import { useStore} from 'vuex'
import {computed, reactive} from "vue";
export const AuctionSetup = (props)=>{
    const store = useStore();
    let offer = null;
    if(props?.offer){
        offer = reactive(props.offer);
    }
    const ticket = computed(()=>{
        if(offer != null){
            return offer;
        }
        return store.getters['thread/getTicket']
    })
    const isHasLot =  computed(()=>{
        return !!ticket.value?.assigned_product?.fields?.lot?.date;
    })


    return {isHasLot, ticket}
}