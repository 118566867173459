<template>
  <nav id="pagination-component">
    <div class="small text-end position-relative d-flex">
      <span v-if="showTotal && total">
        Элементов: {{ total }}
      </span>
      <slot name="additional-menu"></slot>
      <span class="ms-auto">
       <editable-text :text="onpage" :callback="changeOnPage" input-class="text-center w2" ref="onpages"></editable-text>
       элементов на странице
      </span>
    </div>
    <ul v-if="links.length > 1" class="pagination justify-content-center" :style="{'margin-top': marginTopUl}">
      <li v-if="currentPage === 1 || disableFirst" class="page-item disabled" aria-disabled="true" aria-label="Назад">
        <span class="page-link" aria-hidden="true">&lsaquo;</span>
      </li>
      <li v-else class="page-item">
        <a class="page-link" rel="prev" aria-label="Назад" @click="toPage(currentPage - 1)">&lsaquo;</a>
      </li>

      <li v-for="link in links" class="page-item" v-bind:class="{active: (link.page == currentPage), disabled: link.page === 0}" aria-current="page">
        <span v-if="link.page == currentPage" class="page-link">{{link.page}}</span>
        <span v-else-if="link.page === 0" class="page-link">...</span>
        <a v-else class="page-link"  @click="toPage(link.page)">{{link.page}}</a>
      </li>

      <li v-if="currentPage < pages" class="page-item">
        <a class="page-link" rel="next" aria-label="Вперед" @click="toPage(currentPage + 1)">&rsaquo;</a>
      </li>
      <li v-else class="page-item disabled" aria-disabled="true" aria-label="Вперед">
        <span class="page-link" aria-hidden="true">&rsaquo;</span>
      </li>
    </ul>
  </nav>
</template>

<script>
import EditableText from "../Editable/EditableText";
import {mapActions} from "vuex";

export default {
  name: "Paginate",

  components: {
    'editable-text': EditableText
  },
  props:{
    settingsName:{
      type:String,
      default:null,
    },
    marginTopUl:{
      type:String,
      default:"-5px"
    },
    showTotal: {
      type: Boolean,
      default: false,
    }
  },
  emits:["changePage"],
  data: function() {
    return {
      editOnpage: false,
      disableFirst :false,
      currentPage: 0,
      total: 0,
      onpage: 0,
      pages: 0,
      links: [],
    }
  },
  methods: {
    ...mapActions({
      'updateUserSettings':'updateUserSettings'
    }),
    update: function(params) {
     // this.$refs.onpages.updateTextProxy(params.onpage)
      this.disableFirst = false;
      this.currentPage = params.page;
      this.total = params.total;
      this.onpage = params.onpage;
      this.pages = Math.ceil(this.total / this.onpage);
      let links = [];
      for(let link_ar in params.links) {
        if(typeof params.links[link_ar] === 'object') {
          for(let link in params.links[link_ar]) {
            links.push({page: link});
          }
        } else {
            links.push({page: 0});
        }
      }
      if( this.total != 0 && this.pages < this.currentPage  ){
        if( this.currentPage - this.pages > 1 ){
          links.push({page: 0});
          this.disableFirst = true;
        }
        links.push({page: this.currentPage, disabled:true} )
      }

      this.links = links;
    },

    toPage: function(page) {

      this.$emit("changePage" , {page:page , onpage: this.onpage});
    },

    changeOnPage: function(onPage) {
      try {
        onPage = parseInt(onPage);
        if(onPage >= 1 && this.onpage != onPage) {
          this.onpage = onPage;
          if(this.settingsName !== null){
            let settings = {};
            settings[this.settingsName] ={};
            settings[this.settingsName].onpage = onPage;
            this.updateUserSettings({settings:settings}).then(()=>{
              this.toPage(1);
            });
          }else {
            this.toPage(1);
          }

        }
      }catch (e){

      }

    },
  }

}
</script>

<style scoped>
a.page-link {
  cursor: pointer;
}
.w2{
  width:2em;
}
</style>