<template>
  <div v-if="date">
    <span v-if="!canChange">{{formattedDate}}</span>
    <editable-date default="&mdash;"
                   v-if="canChange"
     input-class="form-control offer-history-black"
     :text="date"
     :callback="updateDate" :format-label="'DD.MM.YYYY'">
    </editable-date>
  </div>
</template>

<script>
import EditableDate from "../../Editable/EditableDate";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "Date",
  components: {EditableDate},
  props:{
     offerHistory:{
       type:Object,
     }
  },
  computed:{
    date() {
      if(typeof this.offerHistory?.date === 'undefined'){
        return null;
      }
      return moment(this.offerHistory?.date).format()
    },
    formattedDate() {
      return moment(this.date).format('DD.MM.YYYY')
    },
    ...mapGetters({
      'canChange':'offerHistory/canChange',
    })
  },
  methods:{
    updateDate:function (newdate){
        let olddate = this.offerHistory.date
        this.offerHistory.date = newdate;
        this.editObject({id:this.offerHistory._id, params:{"date":newdate}}).catch(()=>{
          this.offerHistory.date = olddate;
        })

    },
    ...mapActions({
      'editObject':'offerHistory/editObject'
    })
  }
}
</script>

<style >
.offer-history-black{
  color: black!important;

}
</style>