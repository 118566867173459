

const offerHistoryFiltersGetters = {
    getSearchString:(state  , getters , rootState) =>{
        return state.searchString;
    },
    getFilterValueByQueryName:(state, getters) => (name)=>{
        let value = '';
        switch (name){
            case "search":
                value = getters.getSearchString
                break;
            default:
                if (getters.getFilterByName(name)) {
                    value = getters.getFilterByName(name).value;
                }
        }
        return value;
    },
    getFilterByName: (state, getters) => (name) => {
        return state.filters.find(el => el.field === name);
    },

}


export default offerHistoryFiltersGetters;