<template>
  <div >
    <editable-date
        default="&mdash;"
        input-class="form-control"
        :disabled="!canChange"
        :format-datepicker="datePickerFormat"
        :mode="mode"
        :text="date"
        :callback="updateDate"
    ></editable-date>
  </div>
</template>

<script>
import {toRef} from "vue";
import getSellFields from "../Sell/OfferSellComputeds";
import EditableDate from "../../../Editable/EditableDate";
import {mapActions} from "vuex";

export default {
  name: "DateTD",
  components: {EditableDate},
  setup(props) {
    const offer = toRef(props, 'offer')
    const {date, id, canChange} = getSellFields({offer});
    return {
      date,
      id,
      canChange
    }
  },
  props: {
    'offer': {
      type: Object,
      require: true,
    }
  },

  computed: {
    datePickerFormat() {
      return this.hasLot ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    },

    mode() {
      return this.hasLot ? 'datetime' : 'date';
    },

    hasLot() {
      return typeof this.offer?.assigned_product?.fields?.lot !== 'undefined';
    }
  },

  methods: {
    updateDate(date, params) {
      this.updateOfferDate({
        id: this.id,
        date: date,
        offer: this.offer,
      });
    },
    ...mapActions({
      "updateOfferDate": "offer/updateOfferDate",
    })
  }
}
</script>

<style scoped>

</style>