<template>
  <tr class="offer-table-text" >
    <td @contextmenu="handler($event)" >
      <div style="    display: flex;
    justify-content: center;">
        <div @mousemove="showMargin = true" @mouseleave="showMargin = false" >
          <popper   :show="showMargin"  :arrow="true" zIndex="999" placement="top"  >
            <template v-if="!offerHistory?.asn?.name">
              <subnets
                  row-style="display: flex; justify-content: center; align-items: center; margin: 4px 0;"
                  :history-object="offerHistory"
                  :popper-placement="popperplacement"
                  :class-subnet="boldClassSubnets"
                  :subnets="subnets"
              ></subnets>
            </template>
            <template v-else>
              <a target="_blank" :class="boldClassSubnets" style="text-decoration:none" :href="'https://ipanel.i7.org/#/query/' + encodeURIComponent(offerHistory.asn.name)">{{offerHistory.asn.name}}</a>
            </template>
            <template #content>
              <margins :history-object="offerHistory"></margins>
            </template>
          </popper>
        </div>
        <div v-if="offerHistory?.comment">
          <popper :hover="true" class="light" placement="right" :arrow="true" zIndex="999">
            <div style="position: relative; width: 14px;top: -5px; margin-left: 5px;">
              <i class="fa fa-commenting-o" aria-hidden="true"></i>
            </div>
            <template #content>
              <div>
                <div style="overflow-wrap: break-word;">{{offerHistory.comment}}</div>
              </div>
            </template>
          </popper>
        </div>
      </div>

    </td>
    <td>
      <i-ps v-if="!offerHistory?.asn?.name" :history-object="offerHistory" :ip-count="count" ></i-ps>
      <span v-else>&mdash;</span>
    </td>
    <td>
      <date :offerHistory="offerHistory" ></date>
    </td>
    <td style="border-right: none;">
      <client type="seller" :client="seller" :history-object="offerHistory" ></client>
    </td>
    <td style="border-left:none;">
      <manager v-if="!offerHistory?.seller?.isour" :history-object-id="id"  :manager="offerHistory.sellmanager" field-name="sellmanager"></manager>
    </td>
    <td style="border-right: none;">
      <client :client="buyer" :history-object="offerHistory" ></client>
    </td>
    <td style="border-left: none;">
      <manager v-if="!offerHistory?.buyer?.isour" :history-object-id="id"  :manager="offerHistory.buymanager" field-name="buymanager"></manager>
    </td>
    <td :class="priceClass">
      <nominal-price :without-currency-space="true" :is-one-price="offerHistory?.asn?.name" :class-price="boldClass" :history-object-id="id" name-field="buyprice"  :subnets="subnets" :ip-count="count" :buyprice="offerHistory.buyprice" :rates="offerHistory.rates"></nominal-price>
    </td>
    <td  :class="priceClass">
      <span v-if="isOurBuyer" class="fw-bold"  >—</span>
      <nominal-price :without-currency-space="true" v-if="!isOurBuyer" :history-object-id="id" name-field="sellprice"
                     :subnets="subnets" :ip-count="count" :buyprice="offerHistory.sellprice"
                     :rates="offerHistory.rates" :is-one-price="offerHistory?.asn?.name"></nominal-price>
    </td>
  </tr>
</template>

<script>
import {toRef} from "vue";
import getHistoryFields from "./HistoryOfferComputeds";
import IPs from "../DataCells/IPs";
import Date from "../DataCells/Date";
import Client from "../DataCells/Client";
import NominalPrice from "../DataCells/NominalPrice";
import Manager from "../DataCells/Manager";
import HistorySubnets from "../DataCells/HistorySubnets";
import ContextMenu from "../DataCells/ContextMenu";
import Margins from "../DataCells/Margins";
import TemplateRow from "../../template/TemplateRow";
import {mapGetters} from "vuex";


export default {
  name: "HistoryRow",
  components: {
    TemplateRow,
    Margins,
    ContextMenu,
    Manager,
    NominalPrice,
    Client,
    Date,
    IPs,
    subnets:HistorySubnets
  },
  props:{
    offerHistory:{
      type:Object,
      require:true,
    }
  },
  data:function (){
    return {
      showMargin: false,
      priceClass:{
        'price-class-td':false,
      }
    }
  },
  methods:{
    handler:function (event){
      event.preventDefault();
      this.$store.commit('offerHistory/showContextMenu', false)
      this.$store.commit('offerHistory/setContextMenuParams', null)
      this.$nextTick(()=>{
        let ev= {}
        ev.pageX = event.pageX;
        ev.pageY = event.pageY;
        this.$store.commit('offerHistory/setContextMenuParams', {event:ev, offerHistory: this.offerHistory})
        this.$store.commit('offerHistory/showContextMenu', true)
      })
    },
  },
  mounted() {
    if(this.isScreenSmall){
      this.priceClass["price-class-td"] = true;
    }
  },
  computed:{
    popperplacement:function (){
      return this.showMargin ? 'bottom' : 'top';
    },
    ...mapGetters({
      'getSellManagerCollapsed':'offerHistory/getSellManagerCollapsed',
      'getBuyManagerCollapsed':'offerHistory/getBuyManagerCollapsed',
      'isScreenSmall':'isScreenSmall',
    }),
    boldClassSubnets:function (){
      return this.isOurSeller || this.isOurBuyer ? 'fw-bold' : '';
    },
    boldClass:function (){
      return this.isOurSeller || this.isOurBuyer ? 'fw-bold' : '';
    }
  },
  setup(props){

    const offerHistory = toRef(props, 'offerHistory')

    const { subnets,  seller, buyer, count , id , isOurSeller, isOurBuyer} = getHistoryFields({offerHistory:offerHistory});
    return {
      seller, buyer,id ,
      subnets, count, isOurSeller, isOurBuyer
    }
  },
}
</script>

<style scoped>
.light{
  --popper-word-break-custom:normal
}
.price-class-td{
  padding: 0.5rem 0 !important;
}
</style>