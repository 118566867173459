<template>
  <table class="table table-bordered table-hover table-striped table-container mt-1 mb-0">
    <thead>
      <tr>
        <th @click="sortBy('assigned_product.fields.subnets.prefix')" class="link" style="min-width: 150px;">Подсеть</th>
        <th @click="sortBy('assigned_product.fields.price1usd')" class="link" style="min-width: 150px;">Цена</th>
        <th @click="sortBy('assigned_product.fields.date')" class="link" style="min-width: 150px;">Дата покупки</th>
        <th style="min-width: 200px;">Статус</th>
        <th @click="sortBy('person.email')" class="link" style="min-width: 200px;">Клиент</th>
        <th style="min-width: 200px;">Комментарий</th>
      </tr>
    </thead>
    <tbody>
      <filter-table type="buy"></filter-table>

      <tr v-if="offers.size === 0">
        <td colspan="6" :class="{'loading-td': loading}">{{loading ? 'Загрузка' : 'Не найдено'}}</td>
      </tr>

      <tr v-for="[id, offer] in offers" @dblclick="setOffer(offer)" :class="{'offer-selected': isOfferSelected(offer), 'link': !isOfferSelected(offer)}">
        <td>
          <subnets :subnets="field('subnets', offer)"
                   :show-label="false"
                   :user-can-change="false"
              :show-auctions="false"
          />
        </td>

        <td>
          <price :price="field('price', offer)"
                 :price1="field('price1', offer)"
                 :currency="field('currency', offer)"
                 :quantity="field('quantity', offer)"
                 :subnets="field('subnets', offer)"
                 :show-label="false"
                 :can-change="false"
                 default=" " />
        </td>

        <td>
          <date :date="field('date', offer)" />
        </td>

        <td>
          <value-or-trim :is-need-trim="isScreenSmall" :value="status(offer)"></value-or-trim>
        </td>

        <td>
          <client :offer="offer" />
        </td>

        <td>
          <comment :text="field('comment', offer)" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Subnets from "../../../tiketMenu/components/Params/Product/Field/Subnets";
import Comment from "../TableComponents/Comment";
import Price from "../../../tiketMenu/components/Params/Product/Field/Price";
import Date from "../TableComponents/Date";
import Client from "../TableComponents/Client";
import FilterTable from "../Filters/FilterTable";
import {mapActions, mapGetters} from "vuex";
import ValueOrTrim from "../../../common/ValueOrTrim";

export default {
  name: "Buy",

  components: {ValueOrTrim, Subnets, Comment, Price, Date, Client, FilterTable},

  emits: ['setOffer'],

  data() {
    return {
      timer: null,
    }
  },

  computed: {
    ...mapGetters({
      'isScreenSmall':'isScreenSmall'
    }),
    offers() {
      return this.$store.getters['thread/offers/getOffersList'];
    },

    loading() {
      return this.$store.getters['thread/offers/getLoading'];
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    }
  },

  methods: {
    field(fieldName, offer) {
      let val = null;
      if(typeof offer?.assigned_product?.fields === 'object') {
        val = offer?.assigned_product?.fields[fieldName] ?? null;
      }
      return val;
    },

    status(offer) {
      return offer?.assigned_product?.current_step?.name ?? '';
    },

    isOfferSelected(offer) {
      return (typeof this.ticket?.assigned_product?.fields?.seller !== 'undefined' &&
              window.getId(this.ticket.assigned_product.fields.seller) === offer._id);
    },

    sortBy(fieldName) {
      this.$store.commit('thread/offers/filters/setOrderBy', fieldName);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.updateOffersList();
      }, 600)
    },

    setOffer(offer) {
      if(!this.isOfferSelected(offer)) {
        this.$emit('setOffer', offer);
      }
    },

    ...mapActions({
      updateOffersList: 'thread/offers/updateOffersList',
    })
  },
}
</script>

<style scoped>
th, td {
  text-align: center;
}
.link {
  cursor: pointer;
}
.table{
  table-layout: auto;
}
.loading-td {
  height: 10em;
  vertical-align: middle;
}
</style>