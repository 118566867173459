<template>
  <div style="display: inline-block;">
    <div>
      <span v-if="!asInput" class="editable-text text-primary" :class="spanClass" @dblclick="showSelect()">
        <template v-if="trim">
          <string-trim :string="textValue" />
        </template>
        <template v-else>
          {{textValue}}
        </template>
      </span>
    </div>
    <div>
      <popper placement="left" class="warning" v-if="asInput" :arrow="true" :show="showWarning" :content="popperContent">
        <div >
          <select
              @focusout="cancelFocusout"
              @keyup.esc.stop="cancel"
              @change="edit"

              :class="selectClass"
              :style="selectStyle"
              ref="editSelect">

            <option
                v-if="showNotSelected && (selected === null || !required)"
                selected
                :disabled="required"
            >{{notselected}}</option>

            <option
                v-for="option in getOptions"
                :value="option.value"
                :selected="option.value === selected || option.name === beforeLoadSelected"
                :disabled="option.disabled ?? false"
            >{{option.name}}</option>
          </select>
        </div>
      </popper>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import StringTrim from "../string/StringTrim";

export default {
  name: "EditableSelect",

  components: {StringTrim},
  emits: ["change", 'open'],

  props: {
    required: {
      type: Boolean,
      default: true,
    },

    showNotSelected: {
      default: true,
    },

    notselected: {
      default: '—'
    },

    options: {
      // можно передать как массив, объкт или функцию (которая загрузит options)
      require: true
    },

    selected: {
      default: null
    },
    beforeLoadSelected: {
      // если options передан как функция, то это значение будет как selected, до того как загрузятся options
      default: null
    },

    optionsParams: {
      // если options передан как функция, optionsParams задает какие поля использвуютя для name/value option,
      // к примеру optionsParams: {value: '_id', name: 'name'}
      type: Object
    },

    callback: {
      type: Function,
    },

    callbackParams: {
      default: null,
    },

    selectClass: {
      type: String,
      default: "text-center"
    },
    spanClass:{
      type:String,
    },
    selectStyle: {
      type: String,
    },

    trim: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      showWarning:false,
      popperContent:"",
      asInput: false,
      stopDocumentKeydownHandler: null,
      cachedOptions: null,
    }
  },

  computed: {

    textValue: function() {


      return this.beforeLoadSelected ??
                  ((this.selected === null)
                        ? this.notselected
                        : (this.getOptions.find(option => option.value === this.selected)?.name ?? this.selected));
    },

    getOptions: function() {
      let options = [];

      if(typeof this.options === 'function' && this.cachedOptions === null) {
        if(this.beforeLoadSelected !== null) {
          options.push({value: '', name: this.beforeLoadSelected, disabled: true});
        }
        options.push({value: '', name: 'Загрузка...', disabled: true});
      } else {

        if(this.cachedOptions !== null) {
          // options из кэша после их загрузки аяксом

          this.cachedOptions.forEach((option) => {
            // параметры, какие поля используются как name/value option
            let params = this.optionsParams ?? {name: 'name', value: 'value'};
            options.push({
              name: option[params.name],
              value: option[params.value]
            });
          });

        } else if (Array.isArray(this.options)) {
          // options передали массивом

          this.options.forEach((el) => {
            if(typeof el.name !== 'undefined' && typeof el.value !== 'undefined'){
              options.push({name: el.name, value: el.value});
            }else {
              options.push({name: el, value: el});
            }

          });
        } else if (typeof this.options === 'object') {
          // options передали объектом

          Object.entries(this.options).forEach((el) => {
            options.push({name: el[1], value: el[0]});
          });
        }

      }
      return options;
    }

  },

  methods: {
    focus:function (){
      this.showSelect()
      this.$nextTick(() =>{
        this.$refs['editSelect'].focus();
      })
    },
    setPopperContent(value){
      this.popperContent = value;
      this.showWarning =true;
    },
    edit: function(e) {
      if(this.showWarning){
        this.showWarning = false;
        this.popperContent ='';
      }

      if( typeof this.callback === 'function' ) {
        this.callback(e.target.value, this.callbackParams);
      }
      this.$emit("change", e.target.value);
      this.hideSelect();
    },

    cancelFocusout(e) {
      let can = true;
      if (typeof e.explicitOriginalTarget !== 'undefined') {
        if (e.explicitOriginalTarget === this.$refs.editSelect) {
          can = false;
        }
      }
      if (can) {
        this.cancel();
      }
    },

    cancel: function() {
      if(this.showWarning){
        return;
      }
      this.hideSelect();
    },

    showSelect: function() {
      if(typeof this.options === 'function' && this.cachedOptions === null) {
        // options переданы как функция (которая их загрузит), загружаем
        this.options().then((options) => { this.cachedOptions = options; });
      }

      this.stopDocumentKeydownHandler = document.onkeydown;
      document.onkeydown = null;
      this.asInput = true;

      this.$nextTick(function () {
        this.$refs.editSelect.focus();
      }.bind(this));

      this.$emit('open');
    },

    hideSelect: function() {
      document.onkeydown = this.stopDocumentKeydownHandler;
      this.asInput = false;
    },


  },
}
</script>

<style scoped>
select, option {
  text-align: left !important;
}
.editable-text {
  cursor: pointer;
}

.warning {
  display: block !important;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}

</style>