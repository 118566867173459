import offerFiltersGetters from "./offerFiltersGetters";
import offerFiltersMutations from "./offerFiltersMutations";
import offerFiltersActions from "./offerFiltersActions";

const offerFiltersState = {
    searchString:"",
    showFilter: false,
    filters: [
        {
            id:'subnet_filter',
            placeholder:"Подсеть",
            field:"subnet",
            value:"",
        },
        {
            id: "subnet_type_filter",
            placeholder: "Тип",
            field: "subnets_type",
            value: "",
            options: [
                {
                    value: "PI",
                    name: "PI",
                },
                {
                    value: "PA",
                    name: "PA",
                },
            ]
        },
        {
            id:'buy',
            placeholder:"Подсеть и тип сети",
            field:"buy",
            value:"",
        },
        {
            id:'price',
            placeholder:"Цена за IP",
            field:"price",
            value:"",
        },
        {
            id:'date',
            placeholder:"Дата",
            field:"date",
            value:"",
        },
        {
            id:'persona',
            placeholder:"Клиент",
            field:"persona",
            value:"",
        },
        {
            id:'comment',
            placeholder:"Комментарий",
            field:"comment",
            value:"",
        },
        {
            id: 'step',
            placeholder: 'Статус',
            field: "step",
            value: "",
        }
    ],
};



export const  offerFilters  = {
    namespaced: true,
    state:() => offerFiltersState,
    getters:offerFiltersGetters,
    actions:offerFiltersActions,
    mutations:offerFiltersMutations,
}

