<template>
  <div class="form-group mb-0" v-if="asInput" @keyup.esc.stop="form.cancel" @keydown.enter.stop="save" ref="group">
    <input type="text" class="form-control" :class="{'is-invalid': !isValid}" @input="isValid = true" v-if="!proxy.buyforus" v-model="proxy.buyer" placeholder="Введите ссылку на тикет" ref="input">
    <div class="form-check">
      <label class="form-check-label" for="buyforus">Покупаем для себя</label>
      <input class="form-check-input" type="checkbox" v-model="proxy.buyforus" @change="changeBuyForUs" id="buyforus">
    </div>
  </div>
  <div v-else :role="role" class="d-flex" :class="{'flex-column': buyer}" @dblclick="showForm">
    Покупатель:&nbsp;<span v-if="buyforus" :class="{'text-decoration-underline': userCanChange}">для себя</span>
                     <template v-else-if="buyerTicket.name">
                       <popper :content="buyerPopperString" class="light" placement="bottom" :hover="true" :interactive="false" :arrow="true">
                         <span v-single-click="openTicket" class="d-block" :class="{'text-primary': userCanChange || canOpenTicket}">
                           <span v-if="buyerTicket.person" class="d-flex text-muted">
                             <string-trim :string="buyerPerson" :show-popper="false"/>
                           </span>
                           <span style="min-width: fit-content;">{{buyerString}}</span>
                         </span>
                       </popper>
                     </template>

    <span v-else :class="{'text-primary': userCanChange}" style="display: contents;">{{buyerString}}</span>
  </div>

  <modal-confirm-buy-for-us v-if="showBuyForUsModal" @cancel="cancelBuyForUs" @confirm="confirmBuyForUs" />
</template>

<script>
import {CrmApi} from "../../../../../../library/CrmApi";
import {Form} from "./Form";
import StringTrim from "../../../../../string/StringTrim";
import {PriceCalc} from "./PriceCalc";
import {parseTicketId} from "../../../../../../library/ID";
import ModalConfirmBuyForUs from "./ModalConfirmBuyForUs";

export default {
  name: "Buyer",

  components: {StringTrim, ModalConfirmBuyForUs},
  emits: ['update'],

  props: {
    buyer: {},
    buyforus: {},

    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  data: function() {
    return {
      asInput: false,
      isValid: true,

      proxy: {},
      validation: this.isValidId,
      beforeSave: this.bSave,
      afterSave: this.aSave,
      beforeCancel: this.cancelForm,

      ignoreClickElements: ['.modal-buyforus'],

      buyerDefault: null,

      buyerTicket: {
        _id: null,
        name: null,
        type: null,
        prefix: null,
        price: null,
        price1: null,
        quantity: null,
        currency: null,
      },

      showBuyForUsModal: false,

      form: new Form(this),
      fields: ['buyer', 'buyforus'],
      canOpenTicket: false,
    }
  },

  mounted: function() {
    this.buyerDefault = this.buyerTicket;
    this.proxy.buyforus = this.buyforus ?? false;
    if(typeof this.buyer === 'string') {
      this.getBuyerInfo();
    }
  },

  unmounted() {
    if(this.asInput) {
      this.form.cancel();
    }
  },

  computed: {
    prices: function() {
      return new PriceCalc({
        price: this.buyerTicket.price,
        price1: this.buyerTicket.price1,
        currency: this.buyerTicket.currency,
        quantity: this.buyerTicket.quantity,
        prefix: this.buyerTicket.prefix,
      }).getPrettyPrices();
    },

    buyerString: function() {
      let string = 'не задан';

      if(this.buyer) {
        string = 'задан';

        if(this.buyerTicket.name !== null) {
          let prices = this.prices;

          string = (this.buyerTicket.type ?? '') +
                   (this.buyerTicket.prefix === null ? '' : ' /' + this.buyerTicket.prefix);

          if(prices !== null) {
            string += ' ' + prices.single + (prices.all !== null ? ' (' + prices.all + ')' : '');
          }
        }

        if(string === '') {
          string = 'задан';
        }
      }

      return string;
    },

    buyerPerson: function() {
      let person = [];
      let hasName = false;

      if(this.buyer) {
        if(this.isNonEmptyString(this.buyerTicket?.person?.name)) {
          person.push(this.buyerTicket?.person?.name.trim());
          hasName = true;
        }
        if(this.isNonEmptyString(this.buyerTicket?.person?.email)) {
          let email = this.buyerTicket?.person?.email.trim();
          email = hasName ? '(' + email + ')' : email;
          person.push(email);
        }
      }

      return person.join(' ');
    },

    buyerPopperString: function() {
      let buyerPopperString = "";

      if( this.buyer ){

        let name = this.isNonEmptyString(this.buyerTicket?.person?.name) ? this.buyerTicket?.person?.name.trim() : null;
        let email = this.isNonEmptyString(this.buyerTicket?.person?.email) ? this.buyerTicket?.person?.email.trim() : null;

        if( name ){
          buyerPopperString = name + ( email ? " (" + email + ")" : "");
        } else if ( email ) {
          buyerPopperString = email;
        } else {
          buyerPopperString = this.buyerTicket.name;
        }
      }

      return buyerPopperString;
    },

    role() {
      return this.userCanChange ? 'button' : '';
    },

    confirmedOffer() {
      return this.$store.getters['thread/offers/getConfirmedOffer'];
    }
  },

  methods: {
    changeBuyForUs() {
      if(this.proxy.buyforus) {
        this.showBuyForUsModal = true;
        this.$store.commit('setActiveWindow', 'buyforusModal');
      } else {
        this.$store.dispatch('thread/offers/showBuyerOffersTable', () => {
          if(this.$store.getters['thread/offers/getConfirmedOffer'] === null) {
            this.form.cancel();
          }
        });
      }
    },

    cancelBuyForUs() {
      this.showBuyForUsModal = false;
      this.$store.commit('removeActiveWindow', 'buyforusModal');
      this.proxy.buyforus = false;
    },

    confirmBuyForUs() {
      this.showBuyForUsModal = false;
      this.$store.commit('removeActiveWindow', 'buyforusModal');
      this.form.save();
    },

    save() {
      if(this.proxy.buyer !== this.buyer || this.proxy.buyforus !== this.buyforus) {
        this.form.save();
      } else {
        this.form.cancel();
      }
    },

    showForm() {
      if (this.userCanChange) {
        this.form.showForm();
        if(this.proxy.buyforus !== true) {
          this.$store.dispatch('thread/offers/showBuyerOffersTable', () => {
            if (this.$store.getters['thread/offers/getConfirmedOffer'] === null) {
              this.form.cancel();
            }
          });
        }
      }
    },

    cancelForm() {
      this.$store.dispatch('thread/offers/closeOffersTable');
    },

    openTicket: function() {
      if (this.canOpenTicket) {
        window.open(window.location.origin + '/tickets/' + window.getId(this.buyerTicket._id));
      }
    },

    isNonEmptyString: function(object) {
      return object != null &&
          typeof object === 'string' &&
          object.trim() !== "";
    },

    isValidId: function(updated) {
      return (updated.buyforus || updated.buyforus !== this.buyforus) ? true :
                (updated.buyer === '' || updated.seller === null) ? true :
                    Boolean(parseTicketId(updated.buyer));
    },

    bSave: function(updated) {
      updated.buyer = parseTicketId(updated.buyer);
      updated.buyer = updated.buyer === false ? '' : updated.buyer;

      return updated;
    },

    aSave: function() {
      Object.keys(this.buyerTicket).forEach((name) => {
        this.buyerTicket[name] = null;
      });

      if(parseTicketId(this.proxy.buyer)) {
        this.getBuyerInfo();
      } else {
        this.$store.commit('thread/setBuyer', {});
      }
    },

    getBuyerInfo: function(id = null) {
      id = id ?? this.buyer;

      if(id !== null) {
        CrmApi.getTicketList({_id: id ?? this.buyer}).then((result) => {
          if (typeof result?.data.data[0] !== 'undefined') {
            let ticket = result.data.data[0];
            this.canOpenTicket = true;
            let fields = ticket?.assigned_product?.fields ?? {};
            CrmApi.getThreadPerson(ticket._id).then((result) => {
              this.buyerTicket.person = result.data.person;
              ticket.person = result.data.person;
              this.$store.commit('thread/setBuyer', ticket);
            });
            this.buyerTicket = {
              _id: ticket._id,
              name: ticket.name,
              person: ticket.person ?? null,
              type: fields.type ?? null,
              prefix: fields.prefix ?? null,
              price: fields.price ?? null,
              price1: fields.price1 ?? null,
              quantity: fields.quantity ?? null,
              currency: fields.currency ?? null,
            };
          } else {
            this.canOpenTicket = false;
          }
        });
      }
    },
  },
  watch: {
    buyer(to, from){
      if( to !== from ){
        this.buyerTicket = this.buyerDefault;
        if( typeof to === 'string' ) {
          this.getBuyerInfo(to);
        }
      }
    },

    buyforus(to) {
      this.proxy.buyforus = to ?? false;
    },

    'proxy.buyforus' (to) {
      this.form.setCloseByOutClick(to);
    },

    confirmedOffer(offer) {
      if(offer !== null) {
        this.proxy.buyer = window.getId(offer._id);
        this.form.save();
        this.$store.commit('thread/offers/setConfirmedOffer', null);
      }
    }
  }
}
</script>

<style scoped>
</style>