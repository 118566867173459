<template>
  <div v-if="ticketsState.ticketSelectedControl.length > 0">
    <template v-if="ticketsState.ticketSelectedControl.length === 1">
      <button
          class="btn btn-light btn-outline-dark col-12"
          :disabled="!selectedCall"
          @click="openLink"
      ><i class="fa fa-folder-open button-icon"></i> Открыть</button>
      <button
          class="btn btn-light btn-outline-dark col-12"
          :disabled="!listenLink || !callPermission"
          @click="openListenLink"
      ><i class="fa fa-play-circle button-icon"></i> Прослушать</button>
    </template>

    <button
        class="btn btn-light btn-outline-dark col-12"
        :disabled="missedCalls.length === 0"
        @click="setCallback"
    ><i class="fa fa-check-circle button-icon"></i> Отметить звонок отвеченным</button>
  </div>
  <div v-show="listenLink || getListenLink" class="player-container">
    <audioplayer ref="player" />
  </div>
</template>

<script>
import {mapMutations, mapGetters, mapState} from "vuex";
import Audioplayer from "../media/Audioplayer";
import {UserPermission} from "../../users/UserPermission";
import {CrmApi} from "../../library/CrmApi";

export default {
  name: "CallsMenu",
  components: {
    Audioplayer
  },
  data() {
    return {
      callPermission: false,
    }
  },
  methods: {
    setCallback() {
      let count = this.missedCalls.length;
      CrmApi.setCallbackCall(this.missedCalls.map(call => call._id))
            .then(() => {
              this.missedCalls.forEach(call => call.headers.callback = true);
              this.$store.commit('successPush', count === 1
                                                    ? 'Звонок успешно помечен отвеченным'
                                                    : 'Звонки успешно помечены отвеченными');

              if(JSON.parse(this.$route.query?.searchParams ?? '{}')?.unread === true) {
                this.$store.dispatch('tickets/getAllTickets', this.$route);
              }
            })
            .catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));
    },
    openListenLink() {
      if (this.listenLink) {
        this.$refs.player.setFile(this.listenLink);
      }
    },
    openLink() {
      this.setSelectMessageExistInTicket(true);
      this.$router.push({
        name: 'Ticket',
        params: {id: window.getId(this.selectedCall.ticket._id)},
        query: {message: window.getId(this.selectedCall._id)}
      });
    },
    getCallPermission() {
      if (this.$route.name === 'Calls' && this.selectedCall && typeof this.selectedCall?.attachments !== 'undefined' &&
          this.selectedCall?.attachments !== null &&
          this.selectedCall?.attachments?.length > 0) {
        UserPermission.can(typeof this.selectedCall.ticket.project  !== 'undefined' &&
        this.selectedCall.ticket.project != null ? this.selectedCall.ticket.project.name + ".callrecord" : "system.callrecord").then(() => {
          this.callPermission = true;
        }).catch(() => {
          this.callPermission = false;
        });
      } else {
        this.callPermission = false;
      }
    },
    ...mapMutations({
      "setSelectMessageExistInTicket": "thread/setSelectMessageExistInTicket",
    }),
  },
  computed: {
    missedCalls: function() {
      return Object.values(this.getSelectedObjects)
          .filter(call => typeof call.headers?.operator === 'undefined' &&
                         (typeof call.author === 'undefined' || call.author === null) &&
                         (typeof call.headers?.answered_operators === 'undefined' || call.headers.answered_operators?.length === 0) &&
                         call.headers?.callback !== true);
    },
    selectedCall: function () {
      let result = null;
      if (this.getSelectedArray.length === 1) {
        result = this.getSelectedObjects[this.getSelectedArray[0]];
      }
      return result;
    },
    callLink: function () {
      let result = null;
      if (this.selectedCall) {
        result = '/tickets/' + window.getId(this.selectedCall.ticket._id) + '?message=' + window.getId(this.selectedCall._id);
      }
      return result;
    },
    listenLink: function () {
      let result = null;
      if (this.selectedCall && typeof this.selectedCall.attachments !== 'undefined' && typeof this.selectedCall.attachments[0] !== 'undefined') {
        result = window.location.origin + '/message/' + window.getId(this.selectedCall._id) + '/attachment/' + window.getId(this.selectedCall.attachments[0].id);
      }
      return result;
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getSelectedArray": "tickets/getSelectedArray",
      "getSelectedObjects": "tickets/getSelectedObjects",
      "getListenLink": "tickets/getListenLink",
    }),
  },
  watch: {
    'getListenLink'(to) {
      this.$refs.player.setFile(to);
    },
    selectedCall(to) {
      this.getCallPermission();
    }
  }
}
</script>

<style scoped>

</style>