<template>
  <div class="param-group" v-if="show">
    <div class="input-group group-param">
      <span class="form-control d-flex keyword-name" :style="labelStyle">
        <string-trim :string="groups.join(', ')" />
      </span>

      <input :value="groupName"
             @input="(e) => updateGroupName(e.target.value)"
             type="text"
             class="form-control"
             style="max-width: none !important; width: 70%;"
             placeholder="Название группы" />
    </div>

    <draggable v-bind="dragOptions"
               :list="groupBlocks"
               item-key="order"
               @change="changeOrder(groupBlocks)"
               handle=".drag-handle">
      <template #item="{element}">
        <block :block="element" :key="element.keyword" />
      </template>
    </draggable>

  </div>
</template>

<script>
import Block from "./Block";
import StringTrim from "../../string/StringTrim";
import {Document} from "../Document";
import Draggable from "vuedraggable";

export default {
  name: "UnitedGroupKeywords",

  components: {Block, StringTrim, Draggable},

  props: {
    groups: Array,
    keywords: Array,
    document: Document,
  },

  data() {
    return {
      dragOptions: {
        animation: 200,
        ghostClass: 'drag-ghost'
      }
    }
  },

  computed: {
    groupName() {
      return this.groupBlocks?.[0]?.groupname ?? '';
    },

    group() {
      return this.groups[0];
    },

    groupBlocks() {
      return this.document.getGroupBlocks(this.group);
    },

    show() {
      return this.keywords.length || this.document.getGroupBlocks(this.group).length;
    },

    labelStyle() {
      let style = '';

      if(this.ourCompanyFields) {
        style = 'color: var(--ourCompanyColor);';
      }
      if(this.clientCompanyFields) {
        style = 'color: var(--clientCompanyColor);';
      }

      return style;
    },

    ourCompanyFields() {
      return ['company', 'entrepreneur'].includes(this.groupBlocks?.[0]?.condition);
    },

    clientCompanyFields() {
      return this.groupBlocks?.[0]?.condition === 'legaltype'
    },
  },

  methods: {
    changeOrder(blocks) {
      blocks.forEach((blocks, index) => (blocks.order = index));
    },

    updateGroupName(groupName) {
      this.document.getParamsByGroup(this.groups)
                   .forEach(keyword => keyword.groupname = groupName);
    }
  }
}
</script>

<style scoped>

</style>