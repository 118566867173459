
export default {

    setLoading(state, value){
        state.loading = value;
    },
    setKpi(state, value){
        state.kpi = value;
    },
    setWaitingOrders(state, value){
        state.waitingOrders = value;
    },
}
