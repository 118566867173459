<template>

  <div class="row mb-2">
    <label class="form-label" :for="inputId">
      {{label}}
      <span v-if="additionalSettings" class="small ms-1">({{additionalSettings}})</span>
      <span v-if="isRequired" class="required-field">*</span>

      <popper v-if="keyword.format" :content="keyword.format.value" class="light fw-normal" placement="top" :hover="true" :interactive="true" :arrow="true">
        <span class="small text-secondary">формат</span>
      </popper>
    </label>
    <div class="d-flex align-items-center flex-wrap" :class="{'input-group': options}">

      <select v-if="inputType === 'select'"
              class="form-control form-select"
              v-model="text"
              :id="inputId"
              :disabled="isDisabled"
              @change="update"
              ref="input">
        <option v-for="[value, label] in Object.entries(_options)" :value="value">{{label}}</option>
      </select>

      <template v-else-if="inputType === 'input'">
        <input type="text"
               class="form-control"
               :class="{'is-invalid': !valid, 'border-warning': valid && personParam?.['format-warning']}"
               v-model="text"
               :id="inputId"
               :disabled="isDisabled"
               @input="input"
               @change="update"
               ref="input" />

        <div v-if="personParam?.['format-warning']" class="form-text">
          <template v-if="typeof personParam['format-warning'] === 'string'">{{personParam['format-warning']}}</template>
          <template v-else>Не удалось привести к заданному формату</template>
        </div>

        <div style="min-width: 25%;" v-if="hasOptions">
          <button
              class="btn btn-secondary border dropdown-toggle w-100"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
                {{this.dropdownLabel ?? keyword.label}}
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
            <li v-if="Object.keys(_options).length === 0" class="px-2">Список пуст</li>
            <li v-for="(value, label) in _options">
              <a class="dropdown-item" href="#" @click="optionClick(value, label)">{{label}}</a>
            </li>
          </ul>
        </div>
      </template>

      <expandable-text-area v-else-if="inputType === 'textarea'"
                            v-model="text"
                            :aria-class="{'form-control': true, 'is-invalid': !valid}"
                            :disabled="isDisabled"
                            :id="inputId"
                            :style="{width: '100%'}"
                            @input="input"
                            @update="update"
                            ref="input" />

    </div>

    <div v-show="showUpdateCheckbox && allowedForUpdatePerson && !personParam?.wordCase" class="mt-1">
      <input class="form-check-input" :id="inputId + '_update-client'" type="checkbox" v-model="updateClient" @change="changeUpdateClient">
      <label :for="inputId + '_update-client'" v-if="personHasField && !options">&nbsp;Обновить в клиенте</label>
      <label :for="inputId + '_update-client'" v-else>&nbsp;Добавить клиенту</label>
    </div>
  </div>

</template>

<script>
import ExpandableTextArea from "../../../../common/textarea/ExpandableTextArea";
import {Phone} from "../../../../../library/Phone";

export default {
  name: "PersonParam",

  emits: ['input', 'update', 'updateClient'],
  components: {ExpandableTextArea},

  props: {
    keyword: {
      type: Object,
      require: true,
    },
    personParam: {},
    canChangePerson: {
      type: Boolean,
      default: false,
    },
    inputType: { // input || textarea || select
      type: String,
      default: 'input'
    },
    required: {
      type: Boolean,
      default: true,
    },
    optionCallback: {
      type: Function,
      default: null
    },
    optionSetFirstElementAsValue: {
      type: Boolean,
      default: true,
    },
    dropdownLabel: {
      type: String,
      default: null,
    },
    disabled: {
      type: [Boolean, Function],
      default: false,
    }
  },

  data() {
    return {
      text: '',
      options: null,
      updateClient: true,
      showUpdateCheckbox: false,
      valid: true,
      inputId: `input-${this.keyword.key}`,
      personHasField: false,
    }
  },

  mounted() {
    this.setValue();
  },

  computed: {
    allowedForUpdatePerson() {
      return !['company.address.legal', 'company.address.real'].includes(this.keyword?.personparam);
    },

    label() {
      return this.keyword.label ?? this.keyword.key;
    },

    additionalSettings() {
      let settings = [];

      if(typeof this.personParam?.wordCase === 'string') {
        settings.push(`${this.personParam.wordCase} падеж`);
      }
      if(this.personParam?.short === true) {
        settings.push('сокращенный вариант');
      }

      return settings.length ? settings.join(', ') : null;
    },

    isDisabled() {
      return typeof this.disabled === 'function' ? this.disabled() : this.disabled;
    },

    isRequired() {
      return this.keyword?.required === true || typeof this.keyword?.required !== 'boolean';
    },

    hasOptions() {
      return (Array.isArray(this.options) && this.options.length > 0) ||
             (typeof this.options === 'object' && Object.keys(this.options ?? {}).length > 0);
    },

    _options() {
      let options = this.options ?? {};
      if(this.hasOptions) {
        if(Array.isArray(this.options)) {
          let optionsArr = [ ...options ];
          options = {};

          optionsArr.forEach(option => {
            options[option] = option;
          });
        }
      }
      return options;
    }
  },

  methods: {
    isValid() {
      this.valid = (this.keyword?.required === false ||
                   !this.required ||
                   ((typeof this.text === 'string' && this.text.length > 0) || typeof this.text === 'number'));
      return this.valid;
    },

    checkShowUpdateCheckbox() {
      let showUpdateCheckbox = this.canChangePerson;

      if(showUpdateCheckbox) {
        if(this.options === null) {
          if(this.personParam) {
            showUpdateCheckbox = this.text !== this.personParam?.value;
          } else {
            showUpdateCheckbox = false;
          }
        } else {
          if(this.personParam.key === 'client.phone.number') {
            if(this.text.charAt(0) === '@') {
              showUpdateCheckbox = !this.options.includes(this.text);
            } else {
              showUpdateCheckbox = !this.options.map(number => number.replace(/[^0-9]/g, ''))
                                        .includes(this.text.replace(/[^0-9]/g, ''));
            }
          } else {
            showUpdateCheckbox = !this.options.includes(this.text);
          }
        }
      }

      if(showUpdateCheckbox !== this.showUpdateCheckbox) {
        this.changeUpdateClient(showUpdateCheckbox);
      }
      this.showUpdateCheckbox = showUpdateCheckbox;
      return this.showUpdateCheckbox;
    },

    update(autoset = false) {
      if(autoset instanceof Event) {
        autoset = false;
      }

      this.$emit('update', this.text, autoset);
    },

    input() {
      this.isValid();

      if(this.checkShowUpdateCheckbox()) {
        if(this.personParam?.param === 'phone.number') {
          this.text = this.preparePhone(this.text);
        }
      }

      this.$emit('update', this.text);
    },

    changeUpdateClient() {
      this.$emit('updateClient', this.updateClient);
    },

    setValue() {
      if(this.personParam) {
        let loadedValue = this.personParam.value;

        if(
            typeof loadedValue === 'object' &&
            loadedValue !== null
        ) {
          if(this.inputType === 'input') {
            this.options = loadedValue;

            if(this.optionSetFirstElementAsValue) {
              this.text = Array.isArray(this.options)
                  ? (this.options[0] ?? '')
                  : (Object.values(this.options)[0] ?? '');
            }
          } else if(this.inputType === 'textarea') {
            this.text = loadedValue.join('\n');
          }

        } else {
          this.text = loadedValue;
        }

        if(typeof this.text === 'string' && this.text.length > 0) {
          this.personHasField = true;
        }

        this.update(true);
      }
    },

    optionClick(option, label = null) {
      this.text = option;
      this.update();
      this.isValid();
      this.checkShowUpdateCheckbox();

      if(typeof this.optionCallback === 'function') {
        this.optionCallback(label ?? option);
      }
    },

    focus() {
      this.$refs.input.focus();
    },

    preparePhone(phone){
      let newPhone = phone;
      if (phone.charAt(0) === '@' || phone.match(/^\s*[0-9a-zA-Z]*[a-zA-Z]+[0-9a-zA-Z]*/) !== null) {
        newPhone = newPhone[0] === "@" ? newPhone : "@" + newPhone;
      } else {
        newPhone = Phone.makePretty(phone);
      }

      return newPhone;
    },
  },

  watch: {
    personParam: {
      handler(to) {
        let value = (to?.value ?? null);

        if(
            (typeof value === 'object' && !_.isEmpty( (value ?? null) )) ||
            value
        ) {
          this.setValue();
        }
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>