

export const getTicketList = state => {
    return state.ticketList;
}

export const getPaginate = state => {
    return state.paginate;
}

export const isTicketLoading = state => {
    return state.ticketLoadingControl.length !== 0 ;
}

export const isAllLoading =  state => {
    return state.ticketLoadingControl.loadingAll;
}



export const getSelectedObjects = state => {
    return state.ticketSelectedControl.objects;
}


export const getSelectedProjects = state => {
    let projects = {length:0};

    for (let key in state.ticketSelectedControl.objects){
        let el = state.ticketSelectedControl.objects[key]
        if(typeof el.project !== 'undefined'){
            projects[window.getId(el.project._id)] = el.project;
            projects.length++;
        }
    }
    return projects;
}

export const selectedCount = state => {
    return state.ticketSelectedControl.length;
}

export const getSelectedArray = state => {
    return Object.keys(state.ticketSelectedControl.objects);
}

export const getLastSelectedIds = state => {
    return state.lastSelectedIds.ids;
}


export const isAutoUpdateOn = state =>{
    return state.autoUpdateTrigger;
}

export const getTryToScrollAfterTick = state =>{
    return state.tryToScrollAfterTick;
}

export const getListenLink = state => {
    return state.listenLink;
}
