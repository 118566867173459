<template>
  <div :class="{loading: loading}">
    <price :price="price"
           :price1="price1"
           :currency="currency"
           :quantity="quantity"
           :subnets="subnetsForPrice"
           :show-label="false"
           :absolute="true"
           :can-change="canChange"
           @update="update"
           :currency-to-show="currentCurrency"
           :clipboard="true"
    />
  </div>
</template>

<script>

import getSellFields from "../Sell/OfferSellComputeds";
import {toRef} from "vue";
import Price from "../../../tiketMenu/components/Params/Product/Field/Price";
import updateFields from "../../source/updateFields";
import {mapGetters} from "vuex";

export default {
  name: "PriceSell",

  components: {Price},

  props:{
    offer:{
      type:Object,
      require:true,
    }
  },

  setup(props){
    const offer = toRef(props, 'offer')
    const { currency, price, price1, quantity, subnets, canChange } = getSellFields({offer});
    return {
      currency,
      price,
      price1,
      quantity,
      subnets,
      canChange
    }
  },

  data: function() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters({
      'currentCurrency':'offer/getCurrentCurrency'
    }),
    subnetsForPrice: function() {
      return this.subnets?.length ? this.subnets : undefined;
    }
  },

  methods: {
    update: function(values, afterSave = null, failSave = null) {
      this.loading = true;
      updateFields(this.offer, values, afterSave, failSave).then(() => this.loading = false);
    }
  }

}
</script>

<style scoped>
</style>