import { createStore, createLogger } from 'vuex'

import {moduleTickets} from "./tickets/composition";
import { moduleThread} from "./thread/composition"
import commonMutations from "./common/mutations"
import * as actions from "./common/actions";
import * as getters from "./common/getters";
import {moduleLeftMenu} from "./leftMenu/composition";
import {moduleSip} from "./sip/sipComposition";
import {shareState} from "./common/composition";
import {moduleOffersHistory} from "./offerHistory/offerHistoryState";


export default createStore({
  modules:{
    tickets: moduleTickets,
    thread: moduleThread,
    leftMenu:moduleLeftMenu,
    sip:moduleSip,
    offerHistory: moduleOffersHistory,
  },
  state:() => (shareState),
  actions:  actions ,
  getters: getters,
  mutations:commonMutations,
  plugins: []
})
