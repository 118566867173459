<template>
  <span v-if="callinfo.accepted && callinfo.completed"
        class="ms-1"
    >(<timediff :from="callinfo.accepted" :to="callinfo.completed" />)</span>
</template>

<script>
import Timediff from "../../../common/timediff.vue";

export default {
  name: "Duration",

  components: {Timediff},

  props: {
    callinfo: {
      type: Object,
      require: true,
    },
  }
}
</script>

<style scoped>

</style>