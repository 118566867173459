export const shareState = {
    alerts:[],
    movingModal:{
        value:"",
        isOpen:false,
    },
    rates: null,
    route:{},
    activeWindows: [],
    userSettings:{},
}