<template>
  <td v-single-click="toggleTrim" v-double-click="openComment" class="comment-td" :class="{loading: loading}">
    <div :style="commentClass">
      <editable-text
          v-if="canChange"
          :autofocus="true"
          input-class="form-control"
          :as-text-area="true"
          :text="comment"
          :trim="trim"
          :trim-show-popper="false"
          :callback="update"
          :user-change-param="commentAsInput"
          @close="closeComment"
          :required="false"
      />
      <div v-else>
        <template v-if="trim"><string-trim :string="comment" :show-popper="false" /></template>
        <template v-else>{{comment}}</template>
      </div>
    </div>
  </td>

</template>

<script>

import EditableText from "../../../Editable/EditableText";
import {toRef} from "vue";
import getOffersFields from "../Computeds";
import updateFields from "../../source/updateFields";
import StringTrim from "../../../string/StringTrim";

export default {
  name: "CommentTD",

  components: {EditableText, StringTrim},

  data:function (){
    return{
      commentAsInput:false,
      loading: false,
      trim: true,
    }
  },
  props:{
    offer:{
      type:Object,
      require:true,
    },
  },
  setup(props){
    const offer = toRef(props, 'offer')
    const { comment, canChange  } = getOffersFields({offer});

    return {comment, canChange}
  },

  computed: {
    commentClass: function() {
      return this.trim ? '' : 'white-space: pre-line; text-align: left;';
    }
  },

  methods:{
    update: function(comment) {
      this.loading = true;
      updateFields(this.offer, {comment: comment}).then(() => this.loading = false);
    },
    openComment:function () {
      this.commentAsInput = true;
    },
    closeComment(){
      this.commentAsInput= false
    },

    toggleTrim: function() {
      this.trim = !this.trim;
    }
  }
}
</script>

<style scoped>
.comment-td {
  max-width: 17em;
  word-break: break-word;
}
</style>