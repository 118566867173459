

const doubleClick = {
    install (Vue) {
        Vue.directive('doubleClick', {
            mounted(el, binding){
                el.addEventListener('dblclick', function (e) {
                    if(typeof binding.value === "function"){
                        binding.value();
                    }

                });
            },
            beforeUnmount(el, binding) {
                el.removeEventListener('dblclick' , el)
            },
        });
    },

};

export default doubleClick;