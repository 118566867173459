import {Offer} from "../../../library/api/Offer";

import {CrmApi} from "../../../library/CrmApi";
import updateDate from "../../../components/offer/table/update";
import {UserPermission} from "../../../users/UserPermission";

import offerRouter from "../../../router/offer/offerRouter";

function checkObjects(paramsToCheck, first, second) {
    let isParamsChange = false;
    for (let key in second) {
        if (typeof paramsToCheck[key] !== 'undefined') {
            if (typeof first[key] === 'undefined' || second[key] !== first[key]) {
                isParamsChange = true;
                break;
            }
        }
    }
    return isParamsChange;
}
function isParamsChange(oldQuery , newQuery, filters) {
    let isParamsChange = false;
    let paramsToCheck = {
        page:true,
        onpage:true,
        search:true,
    }
    isParamsChange = checkObjects(paramsToCheck, oldQuery , newQuery);
    if(!isParamsChange){
        isParamsChange = checkObjects(paramsToCheck , newQuery , oldQuery)
    }
    paramsToCheck = {};
    if(!isParamsChange){
        for (let el of filters){
            paramsToCheck[el.field] = true;
        }
        isParamsChange = checkObjects(paramsToCheck, oldQuery , newQuery);
    }
    if(!isParamsChange){
        isParamsChange = checkObjects(paramsToCheck , newQuery , oldQuery)
    }

    return isParamsChange;
}
const offerActions = {
    initQuery({state , commit, dispatch, getters , rootGetters, rootState} , ){
        let query = rootGetters.getRoute.query, hasFilters = false;
        if(typeof query.search !== 'undefined' && query.search !== null){
            commit('filters/setSearchString', query.search);
        }
        if(typeof query.page !== 'undefined' && query.page !== null){
            commit('setPage', query.page);
        }
        if(typeof query.onpage !== 'undefined' && query.onpage !== null){
            commit('setOnPage', query.onpage);
        }
        if (typeof query.oB !== 'undefined' && query.oB !== null) {
            try {
                let oB = JSON.parse(query.oB);
                if (typeof oB.f !== 'undefined' && oB.f) {
                    commit('setOrderByField', oB.f);
                }
                if (typeof oB.v !== 'undefined' && oB.v) {
                    commit('setOrderByDirection', oB.v);
                }
            } catch (nothing) {}
        }
        state.filters.filters.forEach((filter) => {
            if (typeof query[filter.field] !== 'undefined' && query[filter.field] !== null) {
                filter.value = query[filter.field];
                commit('filters/setFilterValue', filter);
                hasFilters = true;
            }
        });
        commit('filters/setShowFilter', hasFilters);
    },
    setPagination({commit} , {page, onpage, total, paginator_links}){
        commit('setPage' , page);
        commit('setTotal', total);
        commit('setOnPage', onpage);
        commit('setLinks', paginator_links)
    },
    changeRoute({state , commit, dispatch, getters , rootGetters, rootState}, {from}){
        if(from.name !== rootGetters.getRoute.name){
            commit('filters/clearFilters');
            commit('clearPaginator');
            commit('filters/setShowFilter', false);
            commit('filters/clearFilterValues');
            commit('clearOffers');
            commit('clearPersons');
        }
        if (Array.isArray(state.steps[rootGetters.getRoute.name]) && state.steps[rootGetters.getRoute.name].length === 0) {
            dispatch('getOfferSteps', {}).then(() => {});
        }
        if(rootGetters.getRoute.name == "Buy" || rootGetters.getRoute.name == "Sell"){
            commit('setLoading' , true);
            dispatch('getOffers', {}).then(()=>{

            }).finally(()=>{
            })
        }

    },
    getOffers({state , commit, dispatch, getters , rootGetters, rootState}, {}){
        return new Promise((resolve , reject)=>{
            let type = '';

            if(typeof rootGetters.getRoute.query !== 'undefined') {
                let query = rootGetters.getRoute.query;

                commit('filters/setSearchString', query?.search ?? '');

                getters['filters/getFiltersArray'].forEach(filter => filter.value = query[filter.field] ?? '');
            }

            if(rootGetters.getRoute.name == "Buy"){
                type= rootGetters.getRoute.name;
            }
            if(rootGetters.getRoute.name == "Sell"){
                type= rootGetters.getRoute.name;
            }
            let oldpaginator = Object.assign({}, state.paginator)
            if(typeof getters.getFilters.page !== 'undefined'){
                commit('setPage', getters.getFilters.page )
            }
            if(typeof getters.getFilters.onpage !== 'undefined'){
                commit('setOnPage', getters.getFilters.onpage )
            }else if(rootGetters.getUserSettings?.exchange?.onpage){
                getters.getFilters.onpage = rootGetters.getUserSettings.exchange.onpage;
                commit('setOnPage', rootGetters.getUserSettings.exchange.onpage);
            }

            commit('clearSearchMeta');
            dispatch('getSearchMeta', {type:type, filters: getters.getFilters});
            let oldVal = Object.assign({}  , getters.getFilters);
            Offer.getList(type, getters.getFilters,)
                .then((result)=>{
                    if (type === rootGetters.getRoute.name && !isParamsChange(oldVal , getters.getFilters, getters['filters/getFiltersArray'])){
                        commit('clearOffers');
                        commit('clearPersons');
                        let list = result.data.data;
                        commit('setPersons', {persons: list.map(item => item.person)});
                        commit('setOffers', {offers:list})
                        dispatch('setPagination',result.data )
                        dispatch('checkRights');
                        commit('setLoading' , false);
                    }

                    resolve();
                })
                .catch((error)=>{
                    if (error?.message !== 'Request aborted') {
                        commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
                    }
                    dispatch('setPagination',oldpaginator)
                    reject();
                }).finally(()=>{

            });
        })

    },

    checkRights({getters, commit}) {
        let offers = getters['getOffersList'];
        let projects = [];
        let assignedProjects = [];
        let rights = {};
        let setLotRights = {};
        let permissions = [];
        let setLotPermissions = [];

        offers.forEach(offer => projects.push(offer?.project?.name));
        offers.forEach(offer => {
            if (typeof offer?.assigned_projects !== 'undefined' && Array.isArray(offer?.assigned_projects)) {
                offer?.assigned_projects.forEach((project) => {
                    assignedProjects.push(project.name);
                })
            }
        });
        projects = [ ... new Set(projects) ];
        assignedProjects = [ ... new Set(assignedProjects) ];
        projects.forEach(project => {
            rights[project] = false;
            setLotRights[project] = false;
        });
        assignedProjects.forEach(project => {
            setLotRights[project] = false;
            setLotPermissions.push(project + '.setlot');
        });

        projects.forEach(project => {
            permissions.push(project + '.answer', project + '.internal_answer');
            setLotPermissions.push(project + '.setlot');
        });
        setLotPermissions = setLotPermissions.filter((v, i, a) => a.indexOf(v) === i);
        UserPermission.getPermissions([].concat(permissions, setLotPermissions)).then(result => {
            Object.entries(result).forEach(([permission, value]) => {
                let project = permission.substr(0, permission.lastIndexOf('.'))

                if(permissions.includes(permission) && value && typeof rights[project] !== 'undefined') {
                    rights[project] = true;
                }
                if (setLotPermissions.includes(permission) && value && typeof setLotRights[project] !== 'undefined') {
                    setLotRights[project] = true;
                }
            });

            commit('setChangeRights', rights);
            commit('setSetLotRights', setLotRights);
        });
    },

    getSearchMeta({state , commit, dispatch, getters , rootGetters, rootState}, {type, filters}) {
        let oldVal = Object.assign({}, getters.getFilters);
        return Offer.getSearchMeta(type, filters).then((result) => {
            if (typeof result?.data?.data !== 'undefined' && type === rootGetters.getRoute.name && !isParamsChange(oldVal , getters.getFilters, getters['filters/getFiltersArray'])){
                commit('setSearchMeta', result.data.data);
            }
        }).catch((error) => {});
    },

    updateOfferDate({state, commit, dispatch, getters, rootGetters, rootState}, {id, date, offer}) {
        return updateDate(id, date).then((result) => {
            if (result.data.success) {
                if (offer && typeof offer?.assigned_product !== 'undefined') {
                    if (typeof offer.assigned_product?.fields === 'undefined') {
                        offer.assigned_product.fields = {};
                    }
                    offer.assigned_product.fields.date = result.data.fields.date ?? null;
                }
            }
        }).catch((error) => {
            commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
        })
    },

    getOfferSteps({state, commit, dispatch, getters, rootGetters, rootState}, {type}) {
        return new Promise((resolve, reject) => {
            if (!type) {
                if (rootGetters.getRoute.name === "Buy" || rootGetters.getRoute.name === "Sell") {
                    type = rootGetters.getRoute.name;
                }
            }

            Offer.getSteps(type).then((result) => {
                if (Array.isArray(result.data.data) && result.data.data.length) {
                    let data = {};
                    for (let el of result.data.data){
                        if(el?.name && !el?.offerhide){
                            data[el.name] = el.name;
                        }
                    }
                    commit('setSteps', {type: type, data: Object.keys(data)});
                }
                resolve();
            }).catch((error) => {
                if (error?.message !== 'Request aborted') {
                    commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
                }
                reject();
            }).finally(() => {
            });
        });
    },

    changeCurrency({getters, commit, rootGetters}, newCurrency) {
        commit('setCurrentCurrency', newCurrency);
        let n = rootGetters.getRoute.name;
        let q = Object.assign({},  rootGetters.getRoute.query);
        if (
            newCurrency !== '' &&
            (
                typeof q?.search !== 'undefined' && q?.search ||
                typeof q?.price !== 'undefined' && q?.price
            )
        ) {
            q.page = 1;
            q['currentCurrency'] = newCurrency;

            offerRouter.push({
                name: n,
                query: q
            });
        }
    },
}



export default offerActions;