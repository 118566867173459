import addressParser from "address-rfc2822";

export const Email = {
    validate(email) {
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24})$/;
        return regex.test(email);
    },

    validateFullString(email, strict = true) {
        let result = false;
        if (email) {
            try {
                addressParser.parse(String(email).trim());
                result = true;
            } catch (nothing) {
                if(strict) {
                    result = false;
                } else {
                    let clearEmail = email.match(/<(([^@]+)@([^>]+))>/);
                    if(clearEmail) {
                        result = this.validate(clearEmail[1]);
                    }
                }
            }
        }
        return result;
    },

    getFirstNameFromFullString(email) {
        let result = '';
        if (email) {
            try {
                result = addressParser.parse(String(email).trim())[0]?.name() ?? '';
            } catch (nothing) {}
        }
        return result;
    },
}