<template>
  <div v-if="email" style="word-break: break-word;">
    <editable-text :focus-out-cancel="focusOutCancel" :default="'—'" input-class="form-control form-control-sm" :callback="updateAdditionalEmail" :text="email" :default-valid="true" :required="false" ></editable-text>
    <span v-if="type" style="padding-left: 5px; white-space: nowrap;">({{showType}})</span>
  </div>
</template>

<script>
import EditableText from "../../../Editable/EditableText";
export default {
  name: "AdditionalEmail",
  components: {EditableText},
  emits:['editAdditionalEmail'],
  props:{
    index:{},
    email:{},
    type:{},
    focusOutCancel:{
      type:Boolean,
      default:true,
    },
  },
  computed:{
    showType:function (){
      if(this.type == 'рабочий'){
        return 'paб.';
      }
      return  this.type;
    }
  },
  methods:{
    updateAdditionalEmail:function (value){
      this.$emit('editAdditionalEmail' , {value:value , key:this.index, type:this.type})
    }
  }
}
</script>

<style scoped>

</style>