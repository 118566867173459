<template>
  <div v-if="totalLength" class="pinned-messages-container">
    <div class="current-message" @click="clickPinnedMessage">
      <div class="pinned-messages-header">
        <i class="fa fa-thumb-tack p-1 me-1 flex-shrink-0" aria-hidden="true"></i>
        <span class="fw-bold">Закрепленный комментарий</span>
      </div>
      <div class="trim-parent">
        <div class="trim" v-html="markdown"></div>
      </div>
    </div>
    <popper
        :content="popperText"
        class="light"
        placement="right"
        :show="showPopper"
        :interactive="false"
        :arrow="true"
    >
      <div class="pinned-messages-buttons flex-shrink-0" v-if="totalLength > 1">
        <button
          class="btn btn-sm"
          :disabled="index === 0"
          @click="index--"
        ><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
        <button
          class="btn btn-sm"
          :disabled="index === totalLength - 1"
          @click="index++"
          style="border-top: 0;"
        ><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
      </div>
    </popper>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import StringTrim from "../string/StringTrim";
import {TextRenderer} from "marked";
import {getInlineMarkdown, getMarkdown} from "./Api/api";

export default {
  name: "PinnedMessages",

  components: {
    StringTrim,
  },

  data: function () {
    return {
      index: null,
      showPopper: false,
      showPopperTimeout: null,
    }
  },

  computed: {
    currentMessage() {
      let result = null;
      if (typeof this.messages[this.index] !== 'undefined' && this.messages[this.index]) {
        result = this.messages[this.index];
      }
      return result;
    },
    markdown() {
      let renderer = new TextRenderer();
      renderer.strong = (text) => `<span class="fw-bold">${text}</span>`;
      renderer.em = (text) => `<span class="fst-italic">${text}</span>`;
      return this.comment ? getInlineMarkdown(this.comment, {renderer: renderer}) : '';
    },
    comment() {
      return this.currentMessage ? (this.currentMessage?.body ?? '') : '';
    },
    totalLength() {
      return Array.isArray(this.messages) ? this.messages.length : 0;
    },
    popperText() {
      return (this.index + 1) + '/' + this.totalLength;
    },
    ...mapGetters({
      "messages": "thread/pinnedMessages",
    }),
  },

  mounted() {
    this.index = this.messages.length - 1;
  },

  methods: {
    clickPinnedMessage() {
      if (this.currentMessage) {
        this.setScrollAndHighlight([window.getId(this.currentMessage._id)]);
      }
    },
    ...mapMutations({
      setScrollAndHighlight: "thread/setScrollAndHighlight",
    }),
  },

  watch: {
    index(to, from) {
      if( from !== null) {
        clearTimeout(this.showPopperTimeout);
        this.showPopper = true;
        this.showPopperTimeout = setTimeout(() => this.showPopper = false, 1000);
      }
    },

    totalLength(to, from) {
      if (this.index >= to) {
        this.index = Math.max(to - 1, 0);
      } else if (this.index === from - 1 && to > from) {
        this.index = to - 1;
      }
    }
  }
}
</script>

<style scoped>
.pinned-messages-container {
  background-color: #fff3d0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
}
.current-message {
  margin: 0 8px;
  cursor: pointer;
  flex-grow: 1;
  font-size: 14px;
}
.pinned-messages-buttons {
  display: flex;
  flex-direction: column;
}
.pinned-messages-buttons button {
  background-color: #ebebeb;
  border-color: #a0a0a0;
  color: #000;
  font-size: 13px;
  line-height: 20px;
  --bs-btn-padding-y: 0px;
  --bs-btn-border-radius: 0;
}
.trim-parent {
  display: grid;
}
.trim {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pinned-messages-header{
  color: #6c757d;
}
.pinned-messages-buttons > button:disabled {
  --bs-text-opacity: 1;
  opacity: 1;
  color: #6c757d;
}
</style>