<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Обнаружена проблема с <a :href="link" target="_blank">юр. лицом</a>: {{params.description}}
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "KonturWarning",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    link() {
      return 'https://focus.kontur.ru/search?&query=' + this.params.identity;
    }
  }
}
</script>

<style scoped>

</style>