<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
        <tr class="no-shadow">
          <th
              data-bs-toggle="collapse"
              :aria-expanded="!collapsed"
              class="expandable"
              data-bs-target="#billmanager-item-table-collapse"
              :colspan="5"
              scope="col">Услуги BILLmanager ({{ sortedRelations.length }})</th>
        </tr>
      </thead>
    </table>

    <div class="relation-table collapse" id="billmanager-item-table-collapse" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
        <thead class="sticky">
          <tr>
            <th
                class="sortable"
                :class="sortedClass('id')"
                @click="sortBy('id')">ID</th>
            <th
                class="sortable"
                :class="sortedClass('title')"
                @click="sortBy('title')">Название</th>
            <th
                class="sortable"
                :class="sortedClass('client')"
                @click="sortBy('id')">Клиент</th>
            <th
                class="sortable"
                :class="sortedClass('expiredate')"
                @click="sortBy('expiredate')">Дата окончания</th>
            <th
                class="sortable"
                :class="sortedClass('status')"
                @click="sortBy('status')">Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td><a :href="relation.link" target="_blank">{{relation.id}}</a></td>
            <td class="text-start">{{relation.title}}</td>
            <td>{{relation.client}}</td>
            <td>{{relation.expiredate}}</td>
            <td :class="statusClass(relation.status)">{{status(relation.status)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {sortFunctions} from "../Relation";

export default {
  name: "Item",

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      sort: 'id',
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {
        "id": "id",
      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    status(status) {
      switch(status) {
        case 1:
          status = 'Заказан';
          break;
        case 2:
          status = 'Активен';
          break;
        case 3:
          status = 'Остановлен';
          break;
        case 5:
          status = 'Обрабатывается';
          break;
      }
      return status;
    },

    statusClass(status) {
      let color = '';
      switch(status) {
        case 2:
          color = 'text-success';
          break;
        case 3:
          color = 'text-danger';
          break;
      }

      return color;
    },

    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
</style>