const offerHistoryMutations = {

    setOfferHistory(state, {offerHistory}){
        state.offersHistory.list.set(window.getId(offerHistory._id) , offerHistory);
    },

    setOffersHistory(state, {offersHistory}){
        for (let offerHistory of offersHistory){
            state.offersHistory.list.set(window.getId(offerHistory._id) , offerHistory);
        }
    },
    clearOffers(state){
        state.offersHistory.list.clear();
    },

    clearPaginator(state){
        state.paginator = {
            page:1,
            onpage:50,
            links:[],
            total:0,
        }
    },
    setContextMenuParams(state, params){
        state.contextMenuParams = params
    },
    setGroups(state, value){
      state.groups = value;
    },
    showContextMenu(state, value){
        state.showContextMenu = value
    },
    deleteOffer(state , {_id}){
        state.offersHistory.list.delete(_id);
    },

    setLoading(state , value){
        state.loading = value;
    },
    setPage(state , value){
        state.paginator.page = value;
    },
    setOnPage(state, value){
        state.paginator.onpage = value;
    },
    setTotal(state, value){
        state.paginator.total = value;
    },
    setLinks(state, value){
        state.paginator.links = value;
    },


    setEditModule(state, value){
        state.showEditModal = value
    },

    updatePerson(state, person){
        let personID= window.getId(person._id);
        state.offersHistory.list.forEach(function (value, key, map) {
            if( window.getId(value.buyer._id) == personID){
                value.buyer.name = person.name;
                value.buyer.email = person.email;
            }
            if(window.getId(value.seller._id) == personID){
                value.seller.name = person.name;
                value.seller.email = person.email;
            }
        })
    },
    setIsEditClientOpen(state, value){
        state.isEditClientOpen = value
    },

    setPopperClientOpen(state, value){
        state.popperClientOpen = value
    },

    setUsers(state, users){
      state.users = users;
    },

    setFullSellPriceUsd(state, value){
        state.fullSellPriceUsd = value;
    },

    setFullBuyPriceUsd(state, value){
        state.fullBuyPriceUsd = value;
    },

    setFullBuyPriceRu(state, value){
         state.fullBuyPriceRu = value;
    },
    setFullSellPriceEu(state, value){
         state.fullSellPriceEu = value;
    },
    setFullBuyPriceEu(state, value){
         state.fullBuyPriceEu = value;
    },
    setFullSellPriceRu(state, value){
         state.fullSellPriceRu = value;
    },


    setCurrentCurrency(state, value){
        state.currentCurrency = value;
    },
    setEditObjectId(state, value){
        state.editObjectId = value;
    },
    setNeedChangeRoute(state, value){
        state.needChangeRoute = value
    },
    changeOpenEdit(state){
        state.openEdit = !state.openEdit
    },
    setRates(state, rates) {
        state.rates = rates
    },
    setCountIP(state, value){
        state.countIP= value;
    },
    setCountAs(state, value){
        state.countas = value;
    },
    setShowDeleteModal(state, value){
        state.showDeleteModal = value;
    },
    setAdditionalCounts(state, {our_net_count, buy_for_as_count, unique_sellers_count , unique_buyer_count}){
        state.additionalCounts = {};
        state.additionalCounts = {"our_net_count":our_net_count, "buy_for_as_count":buy_for_as_count, "unique_sellers_count":unique_sellers_count , "unique_buyer_count":unique_buyer_count};
    },

    setSellManagerCollapsed(state, value) {
        state.sellManagerCollapsed = value
    },

    setBuyManagerCollapsed(state, value) {
        state.buyManagerCollapsed = value
    },

    clearSearchMeta(state) {
        state.searchMeta = null;
    },

    setSearchMeta(state, values) {
        state.searchMeta = values;
    },
}

export default offerHistoryMutations;