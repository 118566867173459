<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{userPart}}
      <template v-if="hasBuyer">
        <a :href="buyerTicketLink" target="_blank">{{ buyerTicketLabel }}</a>
        ({{params.buyer.email}})
      </template>
      <template v-else>
        {{ buyerTicketLabel }}
      </template>
      для сделки
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "ResetBuyer",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    userPart() {
      return this.user ? this.user + ' удалил(а)' : 'Удален';
    },
    buyerTicketLink() {
      return '/tickets/' + encodeURIComponent(window.getId(this.params.buyer._id));
    },
    buyerTicketLabel() {
      return this.user ? 'покупателя' : 'покупатель';
    },
    hasBuyer() {
      return typeof this.params.buyer?._id !== 'undefined' && this.params.buyer?._id;
    }
  }
}
</script>

<style scoped>

</style>