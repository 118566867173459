<template>
  <div class="d-flex align-content-center justify-content-center">
    <fieldset class="form-group mt-5 col-4">
      <alert ref="alert"></alert>
      <div class="form-group mb-4">
        <label>Имя пользователя</label>
        <input type="text" name="username" v-model="form.login" class="form-control" required />
      </div>
      <div class="form-group mb-4">
        <label>Роль</label>
        <select class="form-select" v-model="form.role">
          <option v-for="role in roles" :value="role">{{role}}</option>
        </select>
      </div>
      <div class="form-group mb-4">
        <label>Проекты</label>

        <ul class="list-group py-1" id="project-emails">
          <project-item v-for="project in projects"
                        :project="project"
                        :key="project._id"
                        @checkbox="projectCheckbox"
                        @changeProjectData="projectMessagesFrom"
          ></project-item>
        </ul>

      </div>

      <div class="double-buttons-group">
        <div>
          <a class="btn btn-secondary" href="/users/">Назад</a>
        </div>
        <div>
          <button type="submit" class="btn btn-primary" @click="save" :disabled="disabledUpdate || saving">Сохранить</button>
        </div>
      </div>
    </fieldset>
    <alert-list></alert-list>
  </div>
</template>

<script>
import ProjectItem from "./ProjectItem";
import Alert from "../alert/Alert";
import {CrmApi} from "../../library/CrmApi";
import AlertList from "../ticket/Alert/AlertList";

export default {
  name: "UserCreateForm",

  components: {
    AlertList,
    'project-item': ProjectItem,
    'alert': Alert,
  },

  props: {
    roles: {
      require: true
    },

    projects: {
      require: true
    },
  },

  data: function() {
    return {
      form: {
        login: '',
        role: '',
        projects: [],
      },
      saving: false
    }
  },

  computed: {
    disabledUpdate: function() {
      return (this.form.login === '' ||
              this.form.role === '');
    }
  },

  methods: {
    projectCheckbox(data) {
      if(!data.checkbox) {
        if(this.form.projects.find(a_proj => this.getId(a_proj._id) === data.project._id)) {
          this.form.projects = this.form.projects.filter(p => p._id !== data.project._id);
        }
      } else {
        this.form.projects.push(data.project);
      }
    },

    projectMessagesFrom(data) {
      if(this.form.projects.find(a_proj => this.getId(a_proj._id) === data.project._id)) {
        this.form.projects.find(a_proj => this.getId(a_proj._id) === data.project._id).messages_from = data.messages_from;
        this.form.projects.find(a_proj => this.getId(a_proj._id) === data.project._id).hidden = data.hidden;
      }
    },

    getId: function(id) { return id.$oid ?? id; },

    save: function() {
      this.saving = true;

      CrmApi.call('/user/new', this.form).then((response) => {
        window.location.href = '/users/';
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error) )
        /*
        this.$refs.alert.show(CrmApi.getErrorMessage(error), 'danger');
        window.scrollTo(0, this.$refs['alert'].offsetTop);

         */
        this.saving = false;
      });
    }
  }
}
</script>

<style scoped>
@import 'project.css';
</style>