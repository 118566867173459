<template>
  <div :style="customStyle" class="elem-style additional-info">
    <div v-for="(messages, name) in groupByAuthor" class="unconfirmed-author-block d-inline" @click="searchUser(messages)">
      <popper class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
        <span>
          <template v-if="messages.length > 1">{{messages.length}}x</template>
          <i class="fa fa-graduation-cap"></i>
          <span>{{name}}</span>
        </span>

        <template #content>
          <div v-for="date in popperDates(messages)">{{date}}</div>
        </template>
      </popper>
    </div>
  </div>
</template>

<script>
import {getDateFormat} from "../Api/api";

export default {
  name: "UnconfirmedLine",

  props: {
    unconfirmed: {
      type: Array,
    },
    customStyle:{
      type:Object,
      default:{},
    },
  },

  mounted: function() {
  },

  computed: {
    groupByAuthor: function() {
      let messages = {};
      let authors = this.unconfirmed.map(el => { return el.author.name ?? el.author.login; });
      authors.forEach(author => {
        messages[author] = this.unconfirmed.filter(el => (el.author.name ?? el.author.login) === author);
      });

      return messages;
    }
  },

  methods: {
    popperDates(messages) {
      return messages.map(msg => getDateFormat(msg.created, window.dateFormatWeekDay)).reverse();
    },

    searchUser(messages) {
      this.$store.commit('tickets/filters/updateUser', window.getId(messages[0].author._id));
      this.$store.dispatch('tickets/filters/changeUser');
      this.$store.commit('tickets/filters/updateSettingShow', true);
    }
  }
}
</script>

<style scoped>
.elem-style {
  color: grey;
}
.elem-style:hover {
  color: black;
}
.unconfirmed-author-block:not(:last-child) {
  margin-right: 5px;
}
.elem-style div i {
  margin-right: 2px;
}

.light {
  display: inline-block!important;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>