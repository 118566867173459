<template>
  <div class="pt-5 col-md-6 m-auto">
    <form method="post" @submit.prevent="onSubmit()" v-esc="cancel">
      <div class="form-group row">
        <label for="smtp-name" class="col-sm-3 col-form-label">Название</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model="smtp.name" id="smtp-name" placeholder="Введите название">
        </div>
      </div>

      <div class="form-group row" v-if="countPhp === 0">
        <label for="smtp-type" class="col-sm-3 col-form-label">Тип подключения</label>
        <div class="col-sm-9">
          <select id="smtp-type" class="form-select" aria-label="Тип подключения" v-model="smtp.type">
            <option value="smtp">SMTP</option>
            <option value="php">PHP</option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-show="smtp.type !== 'php'">
        <label for="smtp-server" class="col-sm-3 col-form-label">Адрес сервера</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model.lazy="smtp.server" id="smtp-server" placeholder="Введите адрес сервера" ref="smtp_server">
        </div>
      </div>

      <div class="row">
        <div class="col col-md-6" v-show="smtp.type !== 'php'">
          <div class="form-group row">
            <label for="smtp-port" class="col-sm-6 col-form-label">Порт</label>
            <div class="col-sm-6">
              <input type="number" class="form-control" v-model="smtp.port" id="smtp-port" placeholder="Введите порт сервера">
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-check">
            <label class="form-check-label" for="smtp-primary">SMTP-сервер по умолчанию</label>
            <input class="form-check-input" type="checkbox" v-model="smtp.primary" id="smtp-primary">
          </div>
        </div>
      </div>

      <div class="form-group row" v-show="smtp.type !== 'php'">
        <label for="smtp-secure" class="col-sm-3 col-form-label">Защита соединения</label>
        <div class="col-sm-9">
          <select id="smtp-secure" class="form-select" aria-label="Защита соединения" v-model="smtp.secure">
            <option value="STARTTLS">STARTTLS</option>
            <option value="SSL/TLS">SSL/TLS</option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-show="smtp.type !== 'php'">
        <label for="smtp-authparams-login" class="col-sm-3 col-form-label">Логин</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" v-model="smtp.authparams.login" id="smtp-authparams-login" placeholder="Логин">
        </div>
      </div>

      <div class="form-group row" v-show="smtp.type !== 'php'">
        <label for="smtp-authparams-password" class="col-sm-3 col-form-label">Пароль</label>
        <div class="col-sm-9">
          <input type="password" class="form-control" v-model="smtp.authparams.password" id="smtp-authparams-password" placeholder="Пароль">
        </div>
      </div>

      <div class="double-buttons-group">
        <div>
          <button @click="cancel()" type="button" class="col-6 btn btn-secondary">Назад</button>
        </div>
        <div>
          <button type="submit" :disabled="isDisabled || loading" class="col-6 btn btn-primary">Сохранить</button>
        </div>
      </div>
    </form>
  </div>
  <alert-list></alert-list>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import AlertList from "../ticket/Alert/AlertList";

export default {
  name: "SmtpNewEdit",

  components: {
    AlertList
  },

  props: {
    'smtpEdit': {
      type: Object,
      default: null,
    },
    'countPhp': {
      type: Number,
      default: 1,
    }
  },

  data: function () {
    return {
      smtp: {
        name: '',
        type: 'smtp',
        server: '',
        port: 587,
        primary: false,
        secure: 'STARTTLS',
        auth: 'raw',
        authparams: {
          login: '',
          password: ''
        }
      },
      loading: false,
      nameChanged: false,
    }
  },

  computed: {
    isDisabled() {
      let disabled = false;
      if (this.smtp.type === 'php') {
        disabled = !this.smtp.name;
      } else {
        if (!this.smtp.server
            || !this.smtp.port
            || !this.smtp.secure
            || !this.smtp.auth
            || !this.smtp.authparams.login
            || !this.smtp.authparams.password) {
          disabled = true;
        }
      }
      return disabled;
    }
  },

  methods: {
    onSubmit: function () {
      this.loading = true;
      let promise;
      if( typeof this.smtp._id !== 'undefined' && this.smtp._id != null ){
        promise = CrmApi.editSmtp(this.smtp).then((result) => {
          this.$store.commit('successPush', 'SMTP обновлен');
        });
      } else {
        promise = CrmApi.addSmtp(this.smtp).then((result) => {
          window.location.replace(window.location.origin + "/smtp/")
        });
      }
      promise.catch((error) => { this.$store.commit('errorPush', CrmApi.getErrorMessage(error)); })
          .finally(() => { this.loading = false; });
    },
    cancel(){
      window.location.replace( window.location.origin + "/smtp/");
    },
    getId(id){
      return window.getId(id);
    },
  },

  created() {
    if (this.smtpEdit) {
      if (this.smtpEdit.name !== '') {
        this.nameChanged = true;
      }
      Object.assign(this.smtp, this.smtpEdit);
    }
  },

  mounted() {
    if (typeof this.$refs.smtp_server !== 'undefined' && this.$refs.smtp_server) {
      this.$refs.smtp_server.focus();
    }
  },

  watch: {
    'smtp.server': function (to) {
      if (this.nameChanged === false) {
        this.smtp.name = to;
        this.nameChanged = true;
      }
    },
    'smtp.name': function (to) {
      if (this.smtp.name !== '') {
        this.nameChanged = true;
      }
    }
  }
}
</script>

<style scoped>

</style>