<template>
  <sell-row class="offer-table-text" v-for="[key, offer] in getList" :offer="offer" :id="key"
  ></sell-row>
</template>

<script>
import SellRow from "../Sell/SellRow";
import {mapGetters} from "vuex";
export default {
  name: "SellBody",
  components: {SellRow},
  setup(props){

    return {

    }
  },
  computed:{
    ...mapGetters({
      'getList':'offer/getOffersList',
    })
  },
}
</script>

<style scoped>

</style>

