<template>
  <div v-show="showButtons">

    <template v-if="twoButtons">
      <button :class="{'text-muted': disableSetAnswered, 'custom-disabled': disableSetAnswered}"
              @click="setAnswered"
              class="btn btn-light btn-outline-dark col-12">
        <i class="fa fa-book button-icon menu-button"></i>Отметить отвеченным
      </button>
      <button :class="{'text-muted': disableSetUnanswered, 'custom-disabled': disableSetUnanswered}"
              @click="setUnanswered"
              class="btn btn-light btn-outline-dark col-12">
        <i class="fa fa-file-text  button-icon menu-button"></i>Отметить не отвеченным
      </button>
    </template>

    <template v-else>
      <button @click="oneButtonClick"
              class="btn btn-light btn-outline-dark col-12">
        <i class="fa button-icon menu-button" :class="oneButtonIcon"></i>{{oneButtonLabel}}
      </button>
    </template>

  </div>
</template>

<script>
import {Ticket} from "../../../library/api/Ticket";
import {mapActions} from "vuex";

export default {
  name: "ToggleAnswered",

  props: {
    twoButtons: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    showButtons() {
      return Object.keys(this.selectedObjects).length > 0;
    },

    selectedObjects() {
      return this.$store.getters['tickets/getSelectedObjects'];
    },

    showSetAnswered() {
      return Object.values(this.selectedObjects).find(ticket => ticket.open === true);
    },

    showSetUnanswered() {
      return Object.values(this.selectedObjects).find(ticket => ticket.open !== true);
    },

    currentButton() {
      return this.showSetAnswered ? 'answered' : 'unanswered';
    },

    oneButtonLabel() {
      return this.currentButton === 'answered' ? 'Отметить отвеченным' : 'Отметить не отвеченным';
    },

    oneButtonIcon() {
      return this.currentButton === 'answered' ? 'fa-book' : 'fa-file-text';
    },

    disableSetAnswered() {
      return this.loading || !this.showSetAnswered;
    },

    disableSetUnanswered() {
      return this.loading || !this.showSetUnanswered;
    }
  },

  methods: {
    oneButtonClick() {
      if(this.currentButton === 'answered') {
        this.setAnswered();
      } else {
        this.setUnanswered();
      }
    },

    setAnswered() {
      this.loading = true;
      let ids = Object.keys(this.selectedObjects);
      Ticket.setAnswered(ids)
            .then(() => {
              Object.values(this.selectedObjects).forEach(ticket => ticket.open = ticket.new = false);
              this.$store.commit('successPush', ids.length === 1
                  ? 'Тикет помечен как отвеченный'
                  : 'Тикеты помечены как отвеченные')

              if (this.$route?.name === 'Ticket') {
                this.closeTicket();
              } else {
                this.reloadList();
              }
            }).finally(() => this.loading = false);
    },

    setUnanswered() {
      this.loading = true;
      let ids = Object.keys(this.selectedObjects);
      Ticket.setUnanswered(ids)
            .then(() => {
              Object.values(this.selectedObjects).forEach(ticket => ticket.open = true);
              this.$store.commit('successPush', ids.length === 1
                  ? 'Тикет помечен как не отвеченный'
                  : 'Тикеты помечены как не отвеченные')
              this.reloadList();
            }).finally(() => this.loading = false);
    },

    closeTicket() {
      this.$store.commit('tickets/toLastSelect', false);
      this.redirectToTickets();
    },

    reloadList() {
      if(['answered', 'closed', 'new'].includes(this.$route?.params?.filter)) {
        this.$store.dispatch('tickets/getAllTickets', this.$route);
      }
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    })
  }
}
</script>

<style scoped>

</style>