import offerStore from "../../../store/main/offer";
import offerRouter from "../../../router/offer/offerRouter";
let v = 1;
export default function sortBy(fieldName){
    let query = Object.assign({}, offerStore.getters['getRoute'].query);
    v = v * -1;
    let oB = {
        f: fieldName,
        v: v,
    };
    offerStore.commit('offer/setOrderByField', fieldName);
    offerStore.commit('offer/setOrderByDirection', v);
    query.oB = JSON.stringify(oB);
    offerRouter.push({
        name:offerStore.getters['getRoute'].name,
        query:query,
    })
}