<template>
  <div>
    <multiselect  placeholder="Проекты"
                  v-model="projects"
                  :mode="'tags'"
                  :close-on-select="false"
                  :searchable="false"
                  :create-option="false"
                  :delay="800"
                  :options="loadedProjects"
                  @select="selectValue($event)"
                  @clear="clear"
                  @deselect="deselect"
                  class="multiselect-sm"
                  ref="multy"
    ></multiselect>
  </div>
</template>

<script>
import {changeMultySelectStyles} from "../../ticket/Api/api";
import Multiselect from "@vueform/multiselect";

export default {
  name: "ProjectNotificationSelect",

  components:{
    Multiselect
  },

  data:function () {
    return {
      projects: []
    }
  },

  props:{
    action:{
      type:Object,
      require:true
    },
    projectList: {
      type: Array,
    },
  },

  created() {
    if (typeof this.action.params[0] !== 'undefined' && this.action.params[0].value !== "") {
      for (let elem of this.action.params) {
        if (elem.key === 'project') {
          this.projects.push(elem.value);
        }
      }
    }
  },

  mounted() {
    changeMultySelectStyles(document);
  },

  computed: {
    loadedProjects() {
      return Array.isArray(this.projectList) ? this.projectList.map((project) => {
        return {
          value: window.getId(project._id),
          label: project.name
        };
      }) : [];
    },
  },

  methods: {
    deselect(value) {
      let arr = [];
      for (let elem of this.action.params) {
        if (elem.value !== value) {
          arr.push(elem);
        }
      }
      this.action.params = arr;
    },
    clear() {
      this.action.params = [];
    },
    selectValue(event) {
      this.action.params.push({
        key: 'project',
        value: event
      });
    },
  },
}
</script>

<style scoped>
.multiselect-sm{
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
  --ms-py: 0.4rem;
  --ms-px: 0.875rem;
}
</style>