<template>
  <div v-if="fieldsToShow.length > 0">
    <div class="dropdown " style="  display: flex;flex-direction: row-reverse;">
      <button @click="align" class="dropdown-toggle add-dropdown font-size-menu" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
        Добавить информацию о компании
      </button>
      <ul ref="dropdown" id="dropdownMenu2_dropitems" class="dropdown-menu dropdown-menu-lg-end" aria-labelledby="dropdownMenu2">
        <li v-for="field in fieldsToShow"><button class="dropdown-item font-size-menu" type="button"  @click="addElement(field.key, field.type ?? 'string')" >{{field.name}}</button></li>
        <!-- li><button class="dropdown-item font-size-menu" type="button" @click="addElement('vatnum')" >ИНН</button></li -->
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: "CompanyAdd",
  emits:['addElement'],
  props:{
    company:{
       type:Object,
    }
  },
  data:function (){
    return {
      value:'',
      fields:[
        {
          name: 'Название (полное)',
          key: 'fullname'
        },
        {
          name:"Название",
          key:"name"
        },
        {
          name: 'Страна',
          key: 'country'
        },
        {
          name: 'Статус',
          key: 'legaltype'
        },
        {
          name:"ИНН",
          key:'vatnum',
        },
        {
          name: 'ОГРН',
          key: 'ogrn'
        },
        {
          name: 'КПП',
          key: 'kpp'
        },
        {
          name: 'Подписант',
          key: 'signer',
          type: 'group',
        },
        {
          name: 'Адреса',
          key: 'address',
          type: 'group',
        },
        {
          name: 'Банковские реквизиты',
          key: 'bank',
          type: 'group'
        },
        {
          name: 'RIPE',
          key: 'ripe',
          type: 'group'
        }
      ]
    }
  },

  methods: {
    align:function (){
      if( this.$refs?.dropdown){
        this.resize()

      }else {
        this.$nextTick(()=>{
          this.resize()

        });
      }

    },
    resize(entries, observer){
      this.$refs.dropdown.style.transform = "translate3d(0.2727px, 26.3636px, 0px)";
    },
    addElement(field, type = 'string') {
      let el = {type: field};

      switch(type) {
        case 'string':
          el.value = '';
          break;
        case 'group':
          el.value = {};
          break;
      }

      this.$emit('addElement', el);
    },

    isAlreadyHas: function(type){
      let has = false;

      if(typeof this.company === 'object' && this.company !== null) {
        if(typeof this.company[type] === 'string') {
          has = true;
        } else if(typeof this.company[type] === 'object' &&
                  this.company[type] !== null &&
                  !Array.isArray(this.company[type])) {
          let values = Object.values(this.company[type]);
          has = (values.length === 0 || values.filter(subfield => subfield !== null).length > 0);
        }
      }

      return has;
    }
  },
  computed:{
    fieldsToShow:function (){
      let fieldsToShow = [];
      for (let el of this.fields){
        if(!this.isAlreadyHas(el.key)){
          fieldsToShow.push(el)
        }
      }
      return fieldsToShow;
    }
  }
}
</script>

<style scoped>

.dropdown-item {
  --bs-dropdown-item-padding-y:2px;
}
.font-size-menu{
  font-size: 14px;
}
.add-dropdown{
  border: none;
  background-color: white;
  margin-top: 3px;
  padding: 0px ;
}

</style>