import offerStore from "../../../store/main/offer/index";
import offerRouter from "../../../router/offer/offerRouter";
let timer = null;

export default function addToQueryWithTimer(value, name){
    clearTimeout(timer);
    let currentValue = value;
    timer = setTimeout(() =>{
        if(currentValue === offerStore.getters["offer/filters/getFilterValueByQueryName"](name)){

            let n = offerStore.getters.getRoute.name;
            let q = Object.assign({},  offerStore.getters.getRoute.query)
            q.page = 1
            if (currentValue !== '') {
                q[name] = currentValue;
                if (name === 'search' || name === 'price') {
                    q['currentCurrency'] = offerStore.getters['offer/getCurrentCurrency'];
                }
            } else {
                delete q[name];
                if (typeof q['search'] === 'undefined' && typeof q['price'] === 'undefined') {
                    delete q?.['currentCurrency'];
                }
            }

            offerRouter.push({
                name:n,
                query:q
            })
        }
    }, 600);
}

