<template>
  <div class="widget sip-border-style">
    <div class="number-position">
      <span class="number-style">{{prettyPhone}}</span>
    </div>
    <div class="info-window" style="padding-top: 15px;" >
      <div  class="hint" style="display: flex;" >
        <div>
          Конт:&nbsp;
        </div>
        <div>
          {{prettyRealFromPhone}}
        </div>
      </div>
      <div class="hint"  style="display: flex;" >
        <div>
          Доб:&nbsp;
        </div>
        <div>
          {{getRealManagerExt}}
        </div>
      </div>
      <div  v-if="getStatusHint !== ''" style="display: flex; justify-content: center;" class="hint" >
        <div>
          {{getStatusHint}}
        </div>
        <div v-if="isCalling" style="display: flex; padding-left: 5px;">
          (<call-duretion :from-unix="getClientAnswerTimestamp"></call-duretion>)
        </div>
      </div>

      <div  v-if="isTimerStart" >
        <timer  @finish="finishTimer"></timer>
      </div>
    </div>
    <div class="button-position" >
      <button class="end-call" @click="closeWindow()"></button>
    </div>
  </div>
</template>

<script>
import Timer from "./Timer";
import {mapActions, mapGetters, mapMutations} from "vuex";

import {closeWindow, timerEnd} from "../../../store/main/sip/sipActions";
import {Phone} from "../../../library/Phone";
import Timediff from "../../common/timediff";
import CallDuretion from "./CallDuretion";
import {getClientAnswerTimestamp} from "../../../store/main/sip/sipGetters";


export default {
  name: "SipForm",
  components: {
    CallDuretion,
    Timediff,
    Timer,
  },

  data:function (){
    return {
      showTimer:false,
    }
  },
  methods:{
    finishTimer:function (){
      this.timerEnd();
      //this.showTimer = false;
    },
    ...mapActions({
      'closeWindow':'sip/closeWindow',
      'timerEnd':'sip/timerEnd',
      'reset':'sip/reset',
    }),
    ...mapMutations({
      'resetCurrentCall':'sip/resetCurrentCall'
    }),

  },
  beforeUnmount() {
    this.reset();
  },
  computed:{
    prettyPhone:function (){
      return Phone.makePretty(this.phone);
    },
    prettyRealFromPhone:function (){
      return this.getRealFromPhone;
    },

    ...mapGetters({
      "getClientAnswerTimestamp":"sip/getClientAnswerTimestamp",
      "getRealFromPhone":"sip/getRealFromPhone",
      "getRealManagerExt":"sip/getRealManagerExt",
      "phone":"sip/getPhone",
      'isTimerStart':'sip/isTimerStart',
      'getStatusHint':'sip/getHint',
      'getStatus':'sip/getStatus',
      'isCalling':'sip/isCalling',

    }),
  },

}
</script>

<style scoped>
.widget{
  min-width: 255px;
  width: fit-content;
  height: 226px;
  position: relative;
  margin-top: 8px;
  overflow: hidden;
  padding: 8px 16px;

  border-radius: 16px;
}

.end-call{
  height: 60px;
  width: 60px;
  border: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image:url('../../../../img/end-call.svg');
}
.widget, .end-call{
  background-color:  #f5f5ff;
}
.info-window{
  height: 91px;
  width: 100%;
}
.button-position{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.number-position{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}
.number-style{
  font-size: 20px;
}
.hint{
  color: #bbbbbb;
  font-size: 14px;
}
.sip-border-style{
  border-width: 10px;
  border-style: double;
  border-color: #9bc5ff;
  box-shadow: 0 0 5px 0 #888888;
}
</style>