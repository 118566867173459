<template>
  <div>
    <div>
      <Multiselect ref="msel"
        v-model="ticket"
        label="name"
        :options="async function(query) { let data = await getSearchFunction(query); return filterCurrent(data); }"
        :filter-results="false"
        :min-chars="1"
        :resolve-on-load="false"
        :delay="600"
        :searchable="true"
        placeholder="Введите название тикета или email клиента"
        noOptionsText="Список тикетов пуст"
        @clear="reset"
        @select="selectTicket"
        @keyup="parseTicketId"
      >
        <template v-slot:singlelabel="{ value }">
          <div class="multiselect-single-label">
            <div class="multiselect-single-label-inner">
              <div>{{value.name}}</div>
              <div style="font-size: 12px" class="text-muted">{{value.email}}</div>
            </div>
          </div>
        </template>

        <template v-slot:option="{ option }">
          <div>
            <div>{{option.name}}</div>
            <div class="text-muted">{{option.email}}</div>
          </div>
        </template>
      </Multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import {getTicketListForSearch} from "../../Api/api";

export default {
  name: "searchTicket",
  components: {
    Multiselect,
  },
  emits: [
    "selectElement",
  ],
  props: {
    'show': {
      type: Boolean
    },
    'except': {
      default: '',
    },
  },
  data() {
    return {
      ticket: "",
      showElem: 0,
    }
  },
  methods: {
    parseTicketId: function () {
      let search = this.$refs.msel.search;
      if (typeof search === 'string' && search.indexOf('tickets/') !== -1) {
        search = search.substr(search.indexOf('tickets/') + 8, 24);
        if (search.length === 24) {
          this.$refs.msel.search = search;
        }
      }
    },
    reset: function () {
      this.ticket = "";
      this.$emit('selectElement', "")
    },
    getTotal: function (result) {
      this.showElem = result.total - 2;
    },
    getSearchFunction: function (query) {
      return getTicketListForSearch({search: query, specialRights: "change"}, this.getTotal);
    },
    selectTicket: function () {
      this.$emit('selectElement', this.ticket, this.$refs.msel.ariaLabel)
    },
    filterCurrent(data) {
      return data.filter((element) => {
        return window.getId(element.value) !== window.getId(this.except);
      });
    }
  },
}
</script>

<style scoped>
.multiselect-single-label-inner {
  max-width: 100%;
}
.multiselect-single-label-inner > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>