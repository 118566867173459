<template>
  <div class="d-flex">

    <div class="sidebar-container" ref="left-side">
      <ticket-sidebar></ticket-sidebar>
    </div>

    <div class="main-container flex-grow-1 mx-2" ref="main">
      <h3 v-if="withoutProjects" class="text-center position-absolute" style="top: 40%; width: inherit;">
        На данный момент не подключено ни одного проекта.<br/>Пожалуйста, обратитесь к руководителю.
      </h3>

      <router-view name="main"
                   :userid="userid"
                   v-slot="{ Component }" >
        <component ref="mainSpace" :is="Component"  />
      </router-view>
    </div>

    <div class="menu-container right-menu" ref="right-side">
        <router-view name="menu"> </router-view>
    </div>

    <alert-list></alert-list>
    <moving-modal v-if="isMovingModalOpen" :value="getMovingModalContent" @close="closeMovingModule()"  ></moving-modal>
    <sip-menu v-if="isSipMenuOpen"></sip-menu>
  </div>
</template>

<script>
import TicketsSidebar from "../sidebar/TicketsSidebar";
import TicketList from "./TicketList";
import Menu from "../tiketMenu/Menu";
import AlertList from "./Alert/AlertList";
import MovingModal from "../common/movingModal/MovingModal";
import SipMenu from "../sip/SipMenu";
import {mapGetters,mapActions} from "vuex";
export default {
  name: "TicketMain",

  components: {
    SipMenu,
    MovingModal,
    AlertList,
    'ticket-menu':Menu,
    "ticket-sidebar": TicketsSidebar,
    "ticket-list": TicketList
  },

  props: {
    userid: {},
  },

  methods:{
    closeMovingModule(){
      this.$store.commit('closeMovingModule')
    },
    ...mapActions({
      takeWindowsSizeCallback: "thread/takeWindowsSizeCallback"
    }),
  },

  computed:{
    userProjects() {
      return this.$store.getters['tickets/projects/getProjects'];
    },

    withoutProjects() {
      return (Array.isArray(this.userProjects) && this.userProjects.length === 0);
    },

    'getMovingModalContent':function (){
      return this.$store.getters.getMovingModalContent;
    },
    'isMovingModalOpen':function (){
      return this.$store.getters.isMovingModalOpen;
    },
    ...mapGetters({
      'isSipMenuOpen':'sip/isWindowOpen',
      ticketWindowFullSize: 'thread/isTicketWindowFullSize',
      changeWindowSizeCallback: 'thread/changeWindowSizeCallback',
      showOffersTable: 'thread/offers/showOffersTable',
    })

  },

  watch: {
    'ticketWindowFullSize' (isFull) {
      let sides = $(this.$refs['left-side']).add(this.$refs['right-side']);
      let main = $(this.$refs.main);
      let nav = $('#main-nav-menu');

      sides.addClass('resize-process')
           .animate({width: 'toggle'}, () => {
             if(!isFull) {
               sides.removeClass('resize-process')
               main.addClass('mx-2');
             } else {
               main.removeClass('mx-2');
             }

             this.takeWindowsSizeCallback().then((callback) => {
               if(typeof callback === 'function') {
                 callback();
               }
             });
           });

      nav.addClass('resize-process').animate({height: 'toggle', padding: 'toggle'}, () => {
        if(!isFull) {
          nav.removeClass('resize-process');
        }
      });
    },

    'showOffersTable' () {
      $(this.$refs['left-side']).addClass('resize-process')
                                .animate({width: 'toggle'}, () => {
         $(this.$refs['left-side']).removeClass('resize-process');
      });
    },

    '$route' () {
      this.$store.commit('clearActiveWindows');
    },
  }

}
</script>

<style scoped>
.sidebar-container {
  width: 16vw;
  min-width: 210px;
  overflow: hidden;
  white-space: nowrap;
}
.menu-container {
  width: 16vw;
  min-width: 280px;
}
.main-container {
  width: 63.2vw;
}

.right-menu {
  position:relative;
  border: 2px solid #ececec;
  padding-left: 0;
  max-height: 92vh;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: thin;
}

.right-menu::-webkit-scrollbar {
  width: 3px;
}
.right-menu:hover::-webkit-scrollbar-track {
  background: transparent;
}
.right-menu:hover::-webkit-scrollbar-thumb {
  background-color: #7a7d74;
  border-radius: 20px;
  border: transparent;
}

.resize-process {
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
  min-height: 0;
}
</style>