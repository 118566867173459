<template>
  <div  class="d-inline dropdown" v-for="(group, index) in groups.list">
    <button type="button" class="btn btn-sm btn-secondary dropdown-toggle" ref="dropdownToggle" data-bs-toggle="dropdown" @click="loadingTemplates(group, index)" aria-expanded="false">{{group.name}}</button>
    <ul class="dropdown-menu">
      <li v-if="group.loading"><span class="dropdown-item-text text-center">Loading...</span></li>
      <li v-else v-for="template in group.templates" :class="{'dropend':template.body && template.body_en , 'disableTemplate':isTemplateDisable(template)}">
        <a  :class="{'disableTemplateLink':isTemplateDisable(template)}" class="dropdown-item" @click="chooseTemplate(template)" href="#">
          <PopperComment v-if="isCommentExist(template)" :value="template.name" :markdown="template.comment"></PopperComment>
          <span v-else>{{template.name}}</span></a>
      </li>
    </ul>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import {mapGetters, mapMutations} from "vuex";
import PopperComment from "./PopperComment";
import {Dropdown} from "bootstrap";

export default {
  name: "SimpleGroup",
  components: {PopperComment},
  props:{
    groups:{
      type:Object,
      require:true
    },
    "assignTemplate":{
      type: Function,
    },
  },
  computed:{

    ...mapGetters({
      getTicket: "thread/getTicket"
    }),
    ticketLang(){
      let lang = "RU";
      if(typeof this.getTicket.lang !== 'undefined' && this.getTicket.lang != null ){
        lang = this.getTicket.lang;
      }
      return lang;
    },
  },
  methods:{
    updateDropDownPosition(index) {
      if (typeof this.$refs?.dropdownToggle[index] !== 'undefined') {
        Dropdown.getOrCreateInstance(this.$refs.dropdownToggle[index]).update();
      }
    },
    ...mapMutations({
      'selectTemplate':'thread/selectTemplate',
    }),
    chooseTemplate(template){
      let body = this.chooseBody(template);
      this.assignTemplate(body);
      this.selectTemplate(template);
      if(typeof template.comment === 'undefined'){
        template.comment = '';
      }
      this.$store.commit('addMovingModuleContent' , template.comment);

    },
    isCommentExist(template){
      return typeof template.comment !== 'undefined' && template.comment !== null && template.comment.trim() !== ''
    },
    loadingTemplates(group, index) {
      if( group.templates === null && !group.loading ) {
        group.loading = true;
        CrmApi.getTemplatesList({
          "group-exact": group.name
        }).then((result) => {
          group.templates = [];

          if( typeof result.data.data !== 'undefined' ){
            group.templates = result.data.data;
          }
          group.loading = false;
          this.$nextTick(() => this.updateDropDownPosition(index));
        })
      }
    },
    isTemplateDisable:function(template){
      let isTemplateDisable = false;
      if(this.ticketLang === 'RU' &&  !template.body  ){
        isTemplateDisable =  true;
      }
      if(this.ticketLang === 'EN' &&  !template.body_en  ){
        isTemplateDisable =  true;
      }

      return isTemplateDisable;
    },
    chooseBody:function (template){
      let body = template.body ? template.body : template.body_en;
      if(this.ticketLang === 'RU'){
        body = template.body;
      }
      if(this.ticketLang === 'EN'){
        body = template.body_en;
      }
      return body;
    },

  }
}
</script>

<style scoped>
.dropdown-menu {
  max-height: 60vh;
  overflow-y: auto;
}
button {
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
</style>