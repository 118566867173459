import {prepareStep} from "./commonFunctions";

export default {

    setProduct(state , product){
        state.product = Object.assign({} , product);

        if( typeof state.product.templates === 'undefined' || state.product.templates == null ){
            state.product.templates = [];
        }
        if( typeof state.product.steps === 'undefined' || state.product.steps == null ){
            state.product.steps = [];
        }
        if( typeof state.product.urls === 'undefined' || state.product.urls === null || state.product.urls.faq === null ){
            state.product.urls = {faq: ''};
        }

        state.product.steps.sort((a,b) => {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            return 0;
        })
        for (let step of state.product.steps){
            prepareStep(step);
        }
    },

    setOldStepOrders(state) {
        state.oldStepOrders = [];
        for (let value of  state.product.steps){
            let tmp = Object.assign({}, value);
            state.oldStepOrders.push(tmp);
        }
    },

    deleteStep(state , step){
        let steps = [];
        for (let value of state.product.steps){
            if(value.uid !== step.uid){
                steps.push(value);
            }
        }
        state.product.steps = steps;
    },

    addStep(state, newStepName) {
        let step = {
            name: newStepName,
            order: state.product.steps.length + 1,
        }
        prepareStep(step);
        state.product.steps.push(step);
    },

    changeStep(state, step){
        let steps = [];
        for (let value of state.product.steps){
            if(value.uid === step.uid){
                steps.push(step);
            }else {
                steps.push(value);
            }
        }
        state.product.steps = steps;
    },

    setProjects(state , products){
        state.projects.list = products;
    },

    setHaveChanges(state , value){
        state.haveNonSaveChanges = value;
    },

    selectStep(state , currentOpenedStep){
        prepareStep(currentOpenedStep);
        state.selectedStep.currentStep = Object.assign({} , currentOpenedStep);
        if(typeof  state.selectedStep.oldVersionsSteps[currentOpenedStep.uid] === 'undefined'){
            state.selectedStep.oldVersionsSteps[currentOpenedStep.uid] = Object.assign({} , currentOpenedStep);

        }
    },

    resetStep(state){
        state.selectedStep.currentStep = null;
    },


    selectStepForDelete(state , step){
        state.stepForDelete = step;
    },

    resetDeleteStep(state){
        state.stepForDelete = null;
    }


}
