<template>
<div v-if="message && message?.body" style="padding: 2px 5px; background-color: #ffd187; position: relative; border-radius: 5px 5px 0 0; font-size: 11px;">
  <div style="position: absolute;right: 5px; font-size: large; cursor: pointer; top: -5px;" @click="close()">
    <span >&times;</span>
  </div>
  <div v-if="message?.author">
     <span class="text-muted">
      Автор: <span v-if="message?.author?.name">{{message.author.name}}</span><span v-else>{{message?.author?.login}}</span>
    </span>
  </div>
  <string-trim :string="body"></string-trim>
</div>
</template>

<script>
import StringTrim from "../../string/StringTrim";
export default {
  name: "Quote",
  components: {StringTrim},
  props:{
    message:{
      type:Object,
      default:null,
    }
  },
  methods:{
    close:function (){
      this.$store.commit('thread/setCommentMessage', null);
    }
  },
  computed:{
    body:function (){
      let body = this.message.body.replace('\n' , ' ');
      return body.replace(/\s+/g, ' ' );
    }
  }
}
</script>

<style scoped>

</style>