<template>

  <div class="company-group-title d-flex align-items-center">
    <span class="fw-bold">Документы</span>

    <div class="d-inline m-1">
      <label for="document-input" class="btn btn-sm border-secondary d-flex upload-label">
        <i class="fa fa-plus"></i>
      </label>
      <input type="file" id="document-input" class="d-none" multiple @change="uploadDocument">
    </div>
  </div>

  <div class="list">
    <document v-for="document in company.documents"
              :document="document"
              :saved="isDocumentSaved(document)"
              :ticket-id="ticketId"
              @delete="showDeleteModal(document)" />
  </div>

  <teleport to="body">
    <modal-window v-if="deleteModal.show"
                  @close="closeDeleteModal"
                  @success="confirmDeleteModal"
                  ok-button-style="btn-danger"
                  :buttons-text="{close: 'Отмена', success: 'Удалить'}">
      <template v-slot:header>
        <h5 class="modal-title text-danger">Удаление файла</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите удалить файл <i>{{deleteModal.document.filename}}</i>?</span>
      </template>
    </modal-window>
  </teleport>

</template>

<script>
import Document from "./Document.vue";
import {CrmApi} from "../../../../library/CrmApi";
import ModalWindow from "../ModalWindow.vue";
import {getUniqueId} from "../../../../library/ID";

export default {
  name: "Documents",
  components: {ModalWindow, Document},

  props: {
    person: Object,
    company: Object,
    ticketId: {},
  },

  emits: [
      'setDisabled',
  ],

  data() {
    return {
      upload: false,
      deleteModal: {
        show: false,
        document: null,
      }
    }
  },

  computed: {
    api() {
      return this.$store.getters['thread/getApi'];
    },
  },

  methods: {
    isDocumentSaved(document) {
      let saved = false;
      if(typeof document.id !== 'undefined') {
        if((this.person?.company?.documents ?? []).find(doc => window.getId(doc.id) === window.getId(document.id))) {
          saved = true;
        }
      }
      return saved;
    },

    uploadDocument(e) {
      let files = [ ...e.target.files ];

      let promises = [];
      for(let file of files) {
        if(!Array.isArray(this.company.documents)) {
          this.company.documents = [];
        }
        let unique = getUniqueId();
        let document = {
          filename: file.name,
          unique: '' + unique,
        };

        this.company.documents.push(document);
        this.$emit('setDisabled', true);
        promises.push(
            this.api.uploadPersonDocument(file, document.unique).then((response) => {
              let docIndex = this.company.documents.findIndex(doc => doc.unique === unique);
              this.company.documents[docIndex] = response.data.document;
              this.company.documents[docIndex]['file'] = file;
            }).catch((e) => {
              let docIndex = this.company.documents.findIndex(doc => doc.unique === unique);
              this.company.documents.splice(docIndex, 1);
              this.$store.commit('errorPush', CrmApi.getErrorMessage(e));
            })
        );
      }
      Promise.allSettled(promises).then(() => {
        this.$emit('setDisabled', false);
      });
    },

    showDeleteModal(document) {
      this.deleteModal.show = true;
      this.deleteModal.document = document;
    },

    closeDeleteModal() {
      this.deleteModal.show = false;
      this.deleteModal.document = null;
    },

    confirmDeleteModal() {
      this.deleteDocument(this.deleteModal.document.id);
      this.closeDeleteModal();
    },

    deleteDocument(id) {
      this.company.documents = this.company.documents.filter(doc => window.getId(doc.id) !== window.getId(id));
    }
  }
}
</script>

<style scoped>
.upload-label {
  padding: 4px 6px;
  font-size: 10px;
  margin: 5px;
}
.upload-label:hover {
  border-color: black !important;
}
</style>