<template>
  <tr class="offer-table-text" style="cursor: pointer;   "  >
    <td style="cursor: default;">
      <buy-type :offer="offer"></buy-type>
      <lirid-buy :offer="offer" />
    </td>
    <td style="cursor: default;">
      <price-buy :offer="offer"></price-buy>
    </td>
    <td>
      <date-td :offer="offer"></date-td>
    </td>
    <td v-on:dblclick="openTicket(offer._id)" >
      <value-or-trim :is-need-trim="isScreenSmall" :value="status"></value-or-trim>
    </td>
    <td>
      <Client :offer="offer"></Client>
    </td>
    <comment-td :offer="offer"></comment-td>
  </tr>
</template>

<script>
import getBuyFields from "./OfferBuyCompiteds";
import BuyType from "../DataCells/BuyType";
import PriceBuy from "../DataCells/PriceBuy";
import EditableText from "../../../Editable/EditableText";
import openTicket from "../Methods";

import { toRef} from "vue";
import CommentTD from "../DataCells/CommentTD";
import Client from "../DataCells/Client";
import LiridBuy from "../DataCells/LiridBuy";
import DateTD from "../DataCells/DateTD";
import {mapGetters} from "vuex";
import ValueOrTrim from "../../../common/ValueOrTrim";


export default {
  name: "BuyRow",
  components: {
    ValueOrTrim,
    LiridBuy,
    'comment-td':CommentTD,
    EditableText,
    PriceBuy,
    BuyType,
    Client,
    'date-td': DateTD
  },
  setup(props){


    const offer = toRef(props, 'offer')

    const { status  } = getBuyFields({offer:offer});
    return {
      status, openTicket
    }
  },
  data:function (){
    return {
      commentAsInput:false,
    }
  },
  props:{
    'offer':{
      type:Object,
      require:true,
      default:{},
    }
  },
  computed:{
    ...mapGetters({
      'isScreenSmall':'isScreenSmall'
    }),
  }
}
</script>

<style scoped>

</style>