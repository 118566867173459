<template>
<div :style="divStyle" v-if="relation_path.length !== 0">
  <popper  >
      <i class="fa fa-question-circle-o" aria-hidden="true" role="button"></i>
      <template #content>
        <div style="max-width: 70vw !important;" class="popper popper-relations" >
          <div v-for="relations in relation_path">
            <relation-row v-if="relations.length !== 0" :relations="relations"></relation-row>
          </div>
        </div>
      </template>
  </popper>
</div>
</template>

<script>
import RelationRow from "./RelationRow";
export default {
  name: "Relations",
  components: {RelationRow},
  props:{
    relation_path:{
      type:Array,
      require:true,
    },
    divStyle:{
      type:String
    }
  }

}
</script>

<style scoped>
:deep(.popper){
  --popper-max-wight-custom:70vw;
}
.popper-relations{
  border: 1px solid #acacad;
  background-color: #f9fffd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px;
  border-radius: 7px 7px;
}
</style>