import {CrmApi} from "../CrmApi";

export {Product}
class Product {

    /**
     * @param {String} search
     * @param {String|Array} except
     */
    static searchBillmanagerTariffs(search, except = null) {
        if(typeof except === 'string' && except.length) {
            except = [except];
        }
        if(Array.isArray(except) && except.length) {
            except = '&except=' + encodeURIComponent(except.join(','));
        } else {
            except = '';
        }

        return CrmApi.call('/products/billmanager/prices?search=' + encodeURIComponent(search) + except);
    }

}