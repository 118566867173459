<template>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showModal"
          @close="showModalFunction(false)"
          @success="remove"
          modal-dialog-class="modal-lg"
      >
        <template v-slot:header>
          <h5 class="modal-title">Удаление файла</h5>
        </template>
        <template v-slot:body>
          Вы действительно хотите удалить {{ attachment.filename }} из задачи?
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn btn-danger" @click="remove">Удалить</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import modalWindow from "../../tiketMenu/components/ModalWindow";

export default {
  name: "RemoveAttachmentModal",

  components: {
    modalWindow,
  },

  props: {
    attachment: {
      type: Object,
    },
  },

  emits: [
    "close",
    "update",
  ],

  data() {
    return {
      showModal: true,
    }
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
      if (!this.showModal) {
        this.$emit('close');
      }
    },

    remove() {
      this.$emit('update', this.attachment);
    },
  },
}
</script>

<style scoped>

</style>