<template>
  <div>
    <div v-if="showLabel" class="mb-2">Дополнительная информация</div>
    <div v-if="metaSystem" v-for="(value, name) in metaSystem" class="meta-system">
      <template v-if="name === 'referer_url'">
        <span :class="fieldClass" :style="fieldCss">Referer:&nbsp;</span>
        <a :href="value" target="_blank">
          <string-trim :string="value" />
        </a>
      </template>
      <template v-if="name === 'domain'">
        <span :class="fieldClass" :style="fieldCss">Домен:&nbsp;</span>
        <a :href="'https://www2.whois7.ru/' + encodeURIComponent(value)+'#whois'" target="_blank">
          <string-trim :string="value" />
        </a>
      </template>
      <template v-if="name === 'ip'">
        <span :class="fieldClass" :style="fieldCss">IP клиента:&nbsp;</span>
        <a :href="'https://www2.whois7.ru/' + encodeURIComponent(value) + '#whois'" target="_blank">{{value}}</a>
        <template v-if="geo"> ({{geo}})</template>
      </template>
    </div>

    <div v-if="meta" v-for="(value, name) in meta">
      <meta-item
          :name="name"
          :value="value"
          :ticket-id="ticketId"
          :person="person"
          :field-class="fieldClass"
          :field-css="fieldCss"
          :userCanChange="userCanChange"
      />
    </div>
  </div>
</template>

<script>
import MetaItem from "./MetaItem";
import StringTrim from "../../../../string/StringTrim";

export default {
  name: "Meta",

  components: {StringTrim, MetaItem},

  props: {
    ticketId: {},
    person: {},
    showLabel: {
      type: Boolean,
      default: true,
    },
    fieldClass: {},
    fieldCss: {},
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      metaSystemFields: ['referer_url', 'ip', 'domain'],
    }
  },

  computed: {
    meta: function() {
      let without = this.metaSystemFields;
      without.push('ip_geo');

      return (typeof this.person.meta === 'object')
                ? Object.fromEntries( Object.entries(this.person.meta ?? {})
                                            .filter(el => !without.includes(el[0])) )
                : {};
    },

    metaSystem: function() {
      return (typeof this.person.meta === 'object')
                ? Object.fromEntries( Object.entries(this.person.meta ?? {})
                                            .filter(el => this.metaSystemFields.includes(el[0])) )
                : {};
    },

    geo: function() {
      let geo = Object.entries(this.person.meta).filter(el => el[0] === 'ip_geo');
      return geo.length ? geo[0][1] : null;
    },
  }
}
</script>

<style scoped>
tbody, tr, td {
  border: none !important;
}
a {
  text-decoration: none;
}
.meta-system {
  display: flex;
  min-height: 30px;
}
</style>