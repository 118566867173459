<template>
  <div v-if="!withoutProjects" class="mb-2">
    <button @click="onClick()" class="btn btn-primary form-control" type="button" :disabled="!canCreateTicket">
      <i class="fa fa-plus"></i> Создать диалог
    </button>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {disableArrowKeysManagement} from "../List/ArrowScrolling";

export default {
  name: "AddTicketButton",

  methods: {
    onClick() {
      this.show = !this.show;
      disableArrowKeysManagement();
    },
    ...mapMutations({
      setShowCreateForm: 'tickets/create/setShowCreateForm',
    }),
  },

  computed:{
    show:{
      get(){
        return this.showCreateForm;
      },
      set(value){
        this.setShowCreateForm(value);
      },
    },

    withoutProjects() {
      return (Array.isArray(this.projects) && this.projects.length === 0);
    },

    ...mapGetters({
      "showCreateForm": "tickets/create/showCreateForm",
      'canCreateTicket': 'tickets/create/canCreateTicket',
      projects: 'tickets/projects/getProjects',
    }),
  }
}
</script>

<style scoped>

</style>