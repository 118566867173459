<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} удалил(а) {{ label }} {{ content }}
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "RemoveThreadTags",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    label() {
      return Array.isArray(this.params?.tags) && this.params.tags.length > 1 ? 'метки:' : 'метку';
    },
    content() {
      return Array.isArray(this.params?.tags) ? this.params.tags.map(tag => "'" + tag + "'").join(', ') : '';
    }
  }
}
</script>

<style scoped>

</style>