<template>
  <div class="params-item" :class="{'text-muted': loading}">
    <popper content="Этот выбор влияет на подстановку шаблонных ответов" class="light" placement="top" open-delay="350" :hover="true" :interactive="true" :arrow="true">
      <span>
        Язык переписки:
        <template v-if="userCanChange">
          <text-select
            :options="{RU: 'Русский', EN: 'Английский'}"
            :selected="this.language ?? null"
            :callback="changeLanguage"
          />
        </template>
        <template v-else>
          <span>{{this.language ?? '—'}}</span>
        </template>
      </span>
    </popper>
  </div>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import EditableSelect from "../../../Editable/EditableSelect";

export default {
  name: "Language",

  components: {
    'text-select': EditableSelect,
  },

  props: {
    ticket: {},
    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  data: function() {
    return {
      language: null,
      loading: false,
    }
  },

  methods: {
    changeLanguage: function(language) {
      this.loading = true;

      let prevValue = this.language;
      this.language = language;

      CrmApi.updateThreadParams(this.ticket._id, {language: language}).then(() => {
        this.ticket.lang = this.language;
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.language = prevValue;
      }).finally(() => {
        this.loading = false;
      })
    },
  },

  watch: {
    ticket (ticket) {
      if(ticket !== null && typeof ticket.lang !== 'undefined') {
          this.language = ticket.lang;
      }
    }
  }
}
</script>

<style scoped>
.light {
  width: fit-content;
  display: block;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>