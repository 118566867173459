<template>
  <div v-if="selectedTemplate !== null">
    <div class="quick-tags">
      <div class="quick-template" style="margin-bottom: -8px">
        <a class="link-style" @click="click()" >{{selectedTemplate.name}}</a>
        <span
            class="multiselect-tag-remove"
            @mousedown.prevent="remove()"
        >
  <span class="multiselect-tag-remove-icon"></span>
</span>
      </div>
    </div>
  </div>


</template>

<script>
import TemplateQuickModal from "./TemplateQuickModal";
import {mapGetters, mapActions} from "vuex";

export default {
  name: "TemplateQuickButton",
  components: { TemplateQuickModal},
  data:function (){
    return {
    }
  },
  methods:{
    ...mapActions({
      'removeSelectedTemplate':'thread/removeSelectedTemplate',
    }),
    remove(){
        this.removeSelectedTemplate();

    },
    click(){
      if(this.$store.getters.isMovingModalOpen){
        this.$store.commit('closeMovingModule');
      }else {
        this.$store.commit('openMovingModule');

      }
    }
  },
  computed:{
    ...mapGetters({
      "selectedTemplate":  "thread/getSelectedTemplate",
      "isSelectedTemplateHasComment":"thread/isSelectedTemplateHasComment",
    }),
  }
}
</script>

<style scoped>
@import '../../../library/multyselectionLinkStyle.scss';
.quick-template {
  background: var(--tq-tag-bg, #1aad8d);
  color: var(--tq-tag-color,#fff);
  font-size: var(--tq-tag-font-size,.875rem);
  line-height: var(--tq-tag-line-height,1.25rem);
  font-weight: var(--tq-tag-font-weight,600);
  padding: var(--tq-tag-py,.125rem) 0 var(--tq-tag-py,.125rem) var(--tq-tag-px,.5rem);
  border-top-right-radius:4px;
  border-top-left-radius: 4px;
  margin-right: var(--tq-tag-mx,.25rem);
  margin-bottom: var(--tq-tag-my,0px);
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.quick-tags{
  flex-direction: row-reverse;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-wrap: wrap;
  margin: var(--tq-tag-my,.25rem) 0 0;
  padding-left: var(--tq-py,.5rem);
  align-items: center;
}

</style>