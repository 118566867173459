

const state = {
    projects: null,

}

const getters = {
    getProjects: (state, getters, rootState) => {
        return state.projects;
    },

    getProject: state => name => {
        let project = null;
        if(Array.isArray(state.projects) && state.projects.length) {
            let search = state.projects.filter(project => project.name === name);
            if(search.length) {
                project = search[0];
            }
        }
        return project;
    },

    getProjectByAlias: state => alias => {
        let result = null;
        if (Array.isArray(state.projects) && state.projects.length) {
            result = state.projects.find((proj) => {
                return typeof proj?.emails === 'object' && Array.isArray(proj.emails) && proj.emails.includes(alias);
            });
        }
        return result;
    }
}

// actions
const actions = {

}

const mutations = {
    setProjects (state,projects ) {
        state.projects = projects;
    },

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}