<template>
  <modal-window @close="close" @success="success()">
    <template v-slot:header>
      <h5 class="modal-title text-danger">Закрытие {{ titlePart }}</h5>
    </template>
    <template v-slot:body>
      <span>Вы точно хотите закрыть {{ bodyPart }}?</span>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="close">Нет</button>
      <button type="button" class="btn btn-primary" @click="success()">Да</button>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "./ModalWindow";

export default {
  name: "CloseModal",

  components: {
    ModalWindow,
  },

  emits: [
      'success',
      'close',
  ],

  computed: {
    isSingle() {
      return Object.keys(this.selectedObjects).length === 1;
    },
    selectedObjects() {
      return this.$store.getters['tickets/getSelectedObjects'];
    },
    titlePart() {
      return this.isSingle ? 'тикета' : 'тикетов';
    },
    bodyPart() {
      return this.isSingle ? 'тикет' : 'тикеты';
    },
  },

  methods: {
    success() {
      this.$emit('success');
    },

    close() {
      this.$emit('close');
    },
  }
}
</script>

<style scoped>

</style>