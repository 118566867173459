import {  computed , onMounted } from 'vue';
import getOffersFields, {check} from "../Computeds";
import {PricePrettyView} from "../../../../library/PricePrettyView";
import offerStore from "../../../../store/main/offer/index";



export default function getSellFields({offer}) {

    const subnets = computed(() => {
        if(typeof offer.value?.assigned_product?.fields?.subnets === 'undefined') {
            if(typeof offer.value.assigned_product === 'undefined') {
                offer.value.assigned_product = {};
            }
            if(typeof offer.value.assigned_product.fields === 'undefined') {
                offer.value.assigned_product.fields = {};
            }
            offer.value.assigned_product.fields.subnets = [];
        }

        return offer.value.assigned_product.fields.subnets;
    });

    const clientName = computed(() => {
        return check(offerStore.getters['offer/getPersonFromTicket'](offer.value)?.name);
    });

    const clientEmail = computed(() => {
        return check(offerStore.getters['offer/getPersonFromTicket'](offer.value)?.email);
    });

    const clientAuctionNotification = computed(() => {
        return offerStore.getters['offer/getPersonFromTicket'](offer.value)?.auction?.notification;
    });

    const extraClientLines = computed(() => {
        let extraClient = check(offer?.value?.extra);
        return typeof extraClient !== 'string' ? Object.keys(extraClient).map((key) => {
            return key + ': ' + extraClient[key];
        }) : [];
    });

    const {price, price1, quantity, currency , status , comment, date, id, canChange, canSetLot}= getOffersFields({offer});

    const prettyPrice = computed(()=>{
        if(price.value === ''){
            return '';
        }
        let p = new PricePrettyView(price.value, currency.value);
        return p.getPrettyPrice("" , true);
    })

    return {
        currency,
        price,
        price1,
        quantity,
        subnets,
        status,
        clientName,
        clientEmail,
        clientAuctionNotification,
        comment,
        prettyPrice,
        date,
        id,
        canChange,
        extraClientLines,
        canSetLot,
    }
}