<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} перенес(ла) в <a :href="ticketLink" target="_blank">другой запрос</a>:
      <a v-if="eventLink" :href="eventLink" target="_blank">{{messagesWord}}</a>
      <span v-else>{{messagesWord}}</span>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import {getNumeralsWord} from "../../../library/Numerals";

export default {
  name: "MoveMessagesFrom",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    messagesWord() {
      return getNumeralsWord(this.params.count, ['сообщение', 'сообщения', 'сообщений']);
    },
    eventLink() {
      let result = null;
      if (typeof this.params?.messages !== 'undefined' && Array.isArray(this.params?.messages) && this.params?.messages.length === 1) {
        result = this.ticketLink + '?message=' + this.params?.messages[0];
      } else if (typeof this.params?.related_event !== 'undefined' && this.params?.related_event) {
        result = this.ticketLink + '?message=' + this.params.related_event;
      }
      return result;
    },
  }
}
</script>

<style scoped>

</style>