import Keys from "../Keys";

const keyCode = 27;
const vueEsc = new Keys(keyCode);

export default vueEsc


/*
const vueEsc = {}

vueEsc.cb = new Map() // key: HTMLElement, value: callback

vueEsc.onEvent = function (event) {
    if (event.keyCode === 27) {
        for (const [element, cb] of vueEsc.cb.entries()) {
            cb && cb.call(element, event)
            vueEsc.unbind(element);
        }
    }
}

vueEsc.update = function (el, binding) {

    if (typeof binding.value !== 'function') {
        throw new Error('Argument must be a function')
    }

    vueEsc.cb.set(el, binding.value)
}

vueEsc.unbind = function (el) {
    vueEsc.cb.delete(el)
}

export default vueEsc
 */