<template>
  <tr>
    <th @click="orderBy('headers.deadlinedate')" style="cursor: pointer;">Срок</th>
    <th @click="orderBy('subject')"  style="cursor: pointer;">Название</th>
    <th @click="orderBy('headers.assignee.login')"  style="cursor: pointer;" >Ответственный</th>
    <th @click="orderBy('ticket.person.email')"  style="cursor: pointer; width: 240px;">Клиент</th>
    <th v-if="$route.params.project === 'all'" @click="orderBy('ticket.project.name')" class="text-center" style="cursor: pointer; width: 160px;">Проект</th>
  </tr>
</template>

<script>
export default {
  name: "TableHeadRowsTasks",
  props:{
    'orderBy':{
      type:Function,
      require:true,
    }
  }
}
</script>

<style scoped>

</style>