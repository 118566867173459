<template>
  <span :class="this.class" :role="userCanChange ? 'button' : 'none'" v-single-click="toIpanelWhois" @dblclick="$emit('showForm')">
    <div v-if="subnetRangeParts.length" class="text-start me-1 subnet-ranges">
      {{ subnetRangeParts[0] }} -<br>
      {{ subnetRangeParts[1] }}
    </div>
    <string-trim v-else-if="isSubnetSet" :string="subnetString" popper-placement="bottom" />
    <span v-else>—</span>
  </span>
</template>

<script>
import StringTrim from "../../../../../../string/StringTrim";

export default {
  name: "SubnetString",

  components: {
    StringTrim,
  },

  emits: ['showForm'],

  props: {
    subnet: {},
    showLabel: {
      type: Boolean,
      default: true,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    userCanChange: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    class() {
      let classes = {'d-flex': true};

      if(!this.isSubnetSet && !this.showLabel && this.userCanChange) {
        classes['w-100'] = true;
      }
      if(this.showError) {
        classes['text-danger'] = true;
      } else if(this.subnetString !== '—' || this.userCanChange) {
        classes['text-primary'] = true;
      }

      return classes;
    },

    isSubnetSet() {
      return (typeof this.subnet === 'string' && this.subnet !== '');
    },

    subnetString() {
      return this.isSubnetSet ? this.subnet : '—';
    },

    subnetRangeParts() {
      return this.subnetString.indexOf('-') > -1
          ? this.subnetString.split('-').map(part => part.trim())
          : [];
    }
  },

  methods: {
    toIpanelWhois() {
      if(this.isSubnetSet) {
        window.open('https://www2.whois7.ru/' + this.subnet + "#whois");
      }
    },
  }
}
</script>

<style scoped>
.subnet-ranges {
  line-height: 1;
  margin-bottom: 2px;
  margin-top: 2px;
}
</style>