<template>
  <div class="table-period bg-light" v-if="periodOut">
    {{ periodOut }}
  </div>
  <div class="table-data" v-if="data">
    <table class="table table-bordered table-hover table-striped table-container">
      <thead class="thead-dark" style="z-index:20">
      <tr>
        <th @click="sortBy('login')" class="text-center" style="width: 179px; cursor: pointer;" rowspan="2">Менеджер</th>
        <th class="text-center" colspan="5">Исходящие звонки</th>
        <th class="text-center" colspan="5">Входящие звонки</th>
      </tr>
      <tr>
        <th @click="sortBy('countOutCalls')" class="text-center" style="width: 160px; cursor: pointer;">Звонков</th>
        <th @click="sortBy('minOutCalls')" class="text-center" style="width: 160px; cursor: pointer;">Мин. длит.</th>
        <th @click="sortBy('avgOutCalls')" class="text-center" style="width: 160px; cursor: pointer;">Ср. длит.</th>
        <th @click="sortBy('maxOutCalls')" class="text-center" style="width: 160px; cursor: pointer;">Макс. длит.</th>
        <th @click="sortBy('sumOutCalls')" class="text-center" style="width: 160px; cursor: pointer;">Общ. время разговора</th>
        <th @click="sortBy('countInCalls')" class="text-center" style="width: 160px; cursor: pointer;">Отвечено</th>
        <th @click="sortBy('minInCalls')" class="text-center" style="width: 160px; cursor: pointer;">Мин. ответ</th>
        <th @click="sortBy('avgInCalls')" class="text-center" style="width: 160px; cursor: pointer;">Ср. ответ</th>
        <th @click="sortBy('maxInCalls')" class="text-center" style="width: 160px; cursor: pointer;">Макс. ответ</th>
        <th @click="sortBy('sumInCalls')" class="text-center" style="width: 160px; cursor: pointer;">Общ. время разговора</th>
      </tr>
      </thead>
      <tbody>
        <statistic-row-calls
          v-for="item in keys"
          :id="item"
          :data="data[item]"
          :from="period.from"
          :to="period.to"
          :project="project"
        ></statistic-row-calls>
        <statistic-row-calls
          :total="true"
          :data="total"
        ></statistic-row-calls>
      </tbody>
    </table>
  </div>
</template>

<script>
import {getPeriodOut, sortFunctions} from "./statisticFunctions";
import {toRef} from "vue";
import StatisticRowCalls from "./StatisticRowCalls";

export default {
  name: "StatisticTableCalls",
  components: {StatisticRowCalls},
  props: {
    period: {
      type: Object,
      default: null
    },

    data: {
      type: Object,
      default: null
    },

    project: {
      type: String,
      default: 'all',
    },
  },

  setup(props) {
    const period = toRef(props, 'period');
    const {periodOut} = getPeriodOut({period});
    return {
      periodOut,
    }
  },

  data: function () {
    return {
      keys: [],
      sort: 0,
      sorted: null,
    }
  },

  mounted() {
    this.keys = Object.keys(this.data);
    this.sortBy('login');
  },

  computed: {
    total() {
      let result = null;
      if (this.data) {
        result = {
          name: 'Всего',
          countOutCalls: 0,
          minOutCalls: null,
          avgOutCalls: null,
          maxOutCalls: null,
          sumOutCalls: 0,
          countInCalls: 0,
          minInCalls: null,
          avgInCalls: null,
          maxInCalls: null,
          sumInCalls: 0,
        }
        let avgOutCalls = [], avgInCalls = [];
        Object.keys(this.data).forEach((row) => {
          let data = this.data[row];
          if (typeof data.avgOutCalls === 'number') {
            avgOutCalls.push(data.avgOutCalls);
          }
          if (data.countOutCalls) {
            result.countOutCalls += data.countOutCalls;
          }
          if (data.sumOutCalls) {
            result.sumOutCalls += data.sumOutCalls;
          }
          if (typeof data.minOutCalls === 'number') {
            if (result.minOutCalls === null || data.minOutCalls < result.minOutCalls) {
              result.minOutCalls = data.minOutCalls;
            }
          }
          if (typeof data.maxOutCalls === 'number') {
            if (result.maxOutCalls === null || data.maxOutCalls > result.maxOutCalls) {
              result.maxOutCalls = data.maxOutCalls;
            }
          }
          if (typeof data.avgInCalls === 'number') {
            avgInCalls.push(data.avgInCalls);
          }
          if (data.countInCalls) {
            result.countInCalls += data.countInCalls;
          }
          if (data.sumInCalls) {
            result.sumInCalls += data.sumInCalls;
          }
          if (typeof data.minInCalls === 'number') {
            if (result.minInCalls === null || data.minInCalls < result.minInCalls) {
              result.minInCalls = data.minInCalls;
            }
          }
          if (typeof data.maxInCalls === 'number') {
            if (result.maxInCalls === null || data.maxInCalls > result.maxInCalls) {
              result.maxInCalls = data.maxInCalls;
            }
          }
        });
        if (avgOutCalls.length) {
          result.avgOutCalls = avgOutCalls.reduce((a, b) => a + b) / avgOutCalls.length;
        }
        if (avgInCalls.length) {
          result.avgInCalls = avgInCalls.reduce((a, b) => a + b) / avgInCalls.length;
        }
      }
      return result;
    },
  },

  methods: {
    sortBy(field) {
      if (this.sort && this.sorted === field) {
        this.sort = -this.sort;
      } else {
        this.sort = 1;
        this.sorted = field;
      }
      let keys = Object.keys(this.data);
      const sortFunction = sortFunctions(this.data, this.sort);
      if (this.sort !== 0 && typeof sortFunction[field] !== 'undefined') {
        keys.sort(sortFunction[field]);
      }
      this.keys = keys;
    },
  }
}
</script>

<style scoped>
.table-period {
  padding: 1rem;
  text-align: center;
  border: 1px solid #dee2e6;
  font-weight: 700;
}
.table-data th {
  color: rgba(33, 37, 41, 0.75);
}
</style>