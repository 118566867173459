<template>
  <div :class="{'company-field': isCompanyShow, 'mb-3': !isCompanyShow}">
    <div class="col-sm-3">{{label('Страна')}}</div>
    <div :class="{'col-sm-9': isCompanyShow}">
      <select class="form-select"
              :class="{'form-select-sm': isCompanyShow}"
              :disabled="disabled"
              :value="address?.country"
              @change="update('country', $event.target.value)">
        <option value="">Не выбрана</option>
        <option v-for="country in countries" :value="country">{{country}}</option>
      </select>
    </div>
  </div>

  <div :class="{'company-field': isCompanyShow, 'mb-3': !isCompanyShow}">
    <div class="col-sm-3">{{label('Индекс')}}</div>
    <div :class="{'col-sm-9': isCompanyShow}">
      <input :value="address?.index"
             class="form-control"
             :class="{'form-control-sm': isCompanyShow}"
             :disabled="disabled"
             @input="update('index', $event.target.value)" />
    </div>
  </div>

  <div :class="{'company-field': isCompanyShow, 'mb-3': !isCompanyShow}">
    <div class="col-sm-3">{{label('Регион')}}</div>
    <div :class="{'col-sm-9': isCompanyShow}">
      <input :value="address?.state"
             class="form-control"
             :class="{'form-control-sm': isCompanyShow}"
             :disabled="disabled"
             @input="update('state', $event.target.value)" />
    </div>
  </div>

  <div :class="{'company-field': isCompanyShow, 'mb-3': !isCompanyShow}">
    <div class="col-sm-3">{{label('Город')}}</div>
    <div :class="{'col-sm-9': isCompanyShow}">
      <input :value="address?.city"
             class="form-control"
             :class="{'form-control-sm': isCompanyShow}"
             :disabled="disabled"
             @input="update('city', $event.target.value)" />
    </div>
  </div>

  <div :class="{'company-field': isCompanyShow, 'mb-3': !isCompanyShow}">
    <div class="col-sm-3">{{label('Адрес')}}</div>
    <div :class="{'col-sm-9': isCompanyShow}">
      <input :value="address?.street"
             class="form-control"
             :class="{'form-control-sm': isCompanyShow}"
             :disabled="disabled"
             @input="update('street', $event.target.value)" />
    </div>
  </div>
</template>

<script>
import {countries} from "../../../../../library/Client";

export default {
  name: "FullAddressFields",

  emits: ['update'],

  props: {
    address: Object,
    type: String, // CompanyShow | CompaniesNewEdit
    disabled: Boolean
  },

  data() {
    return {
      countries: Object.values(countries),
    }
  },

  computed: {
    isCompanyShow() {
      return (this.type === 'CompanyShow');
    }
  },

  methods: {
    label(label) {
      return this.isCompanyShow ? `${label}:` : label;
    },

    update(field, value) {
      this.$emit('update', field, value);
    },
  }
}
</script>

<style scoped>

</style>