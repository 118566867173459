<template>
  <a style="padding: 0px 10px" @click="callback" class="dropdown-item">{{name}}</a>
</template>

<script>
export default {
  name: "ContextCallbackItem",
  props:{
    "name":{
      require:true
    },
    "messageid":{
      type:String
    },
    "callback": {
      type: Function,
    }
  },
}
</script>

<style scoped>
a {
  cursor: pointer;
}
</style>