import {CrmApi} from "../../../library/CrmApi";
import {prepareStep} from "./commonFunctions";

export const setProjects = ({state , commit} ,payload ) => {
    state.projects.loading = true;
    CrmApi.getProjectsList({}).then(( result )=>{
        commit('setProjects' ,result.data );
    }).catch((error)=>{
        commit('errorPush' , CrmApi.getErrorMessage(error) , true)
    }).finally(()=>{
        state.projects.loading = false;
    })
}

export const openExistStep = ({state , commit , } , step  ) =>{
    let breakObject = {}
    let currentStep = null
    try {
        for (st of state.product.steps){
            if(window.getId(step._id) === window.getId(st._id)){
                currentStep = step;
                throw breakObject;
            }
        }
    }catch (breakObject ){}

    if(currentStep !== null){
        prepareStep(currentStep);
        commit('selectStep', currentStep);
    }
}

export const openNewStep = ({state, commit}  )=>{

    let step = {
        name: "",
        order: state.product.steps.length + 1,
    }
    prepareStep(step);
    state.product.steps.push(step);
    commit('selectStep', step);
}