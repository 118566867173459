<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
      <tr class="no-shadow">
        <th
            data-bs-toggle="collapse"
            :aria-expanded="!collapsed"
            class="expandable"
            data-bs-target="#billmanager-account-table-collapse"
            :colspan="6"
            scope="col">Счета BILLmanager ({{ sortedRelations.length }})</th>
      </tr>
      </thead>
    </table>

    <div class="relation-table collapse" id="billmanager-account-table-collapse" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
        <thead class="sticky">
        <tr>
          <th
              class="sortable"
              :class="sortedClass('account')"
              @click="sortBy('account')">ID</th>
          <th
              class="sortable"
              :class="sortedClass('project')"
              @click="sortBy('project')">Провайдер</th>
          <th
              class="sortable"
              :class="sortedClass('balance')"
              @click="sortBy('balance')">Баланс</th>
          <th
              class="sortable"
              :class="sortedClass('annually')"
              @click="sortBy('annually')">Расходы за год</th>
          <th
              class="sortable"
              :class="sortedClass('annually_expense')"
              @click="sortBy('annually_expense')">Годовой оборот</th>
          <th
              class="sortable"
              :class="sortedClass('active')"
              @click="sortBy('active')">Статус</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td><a :href="relation.link" target="_blank">{{relation.account}}</a></td>
            <td class="text-start">{{relation.project}}</td>
            <td>{{getMoneyString(relation.balance, relation.currency)}}</td>
            <td>{{getMoneyString(relation.annually,relation.currency)}}</td>
            <td>{{getMoneyString(relation.annually_expense, relation.currency)}}</td>
            <td :class="getStatusClass(relation.active)">{{getStatus(relation.active)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {sortFunctions} from "../Relation";

export default {
  name: "Account",

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      sort: 'account',
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {
        "account": "id",
        "balance": "number",
      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    getMoneyString: function(count, currency) {
      count = (count === null) ? '0,00' : count;
      count = count.replace('.', ',');

      let symbol;
      switch(currency) {
        case 'RUB':
          symbol = '₽';
          break;
        case 'USD':
          symbol = '$';
          break;
        case 'EUR':
          symbol = '€';
          break;
      }

      return count + ' ' + symbol;
    },

    getStatus: function(active) {
      return (active === 'on') ? 'Активен' : 'Остановлен';
    },

    getStatusClass: function(active) {
      return 'text-' + ((active === 'on') ? 'success' : 'danger');
    },

    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },

    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>

</style>