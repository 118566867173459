import offerHistoryMutations from "./offerHistoryMutations";
import offerHistoryActions from "./offerHistoryActions";
import offerHistoryGetters from "./offerHistoryGetters";

import {offerHistoryFilters} from "./filters/offerHistoryFiltersState";
import {offerHistoryAddEdit} from "./addEdit/offerHistoryAddEditState";


const state = () => (
    {
        offersHistory: {
            list:new Map(),
        },
        paginator:{
            page:1,
            onpage:50,
            links:[],
            total:0,
        },
        loading:false,
        persons:{

        },
        isEditClientOpen:false,
        popperClientOpen:{},
        users:[],
        canChange:false,
        showEditModal:false,
        contextMenuParams:null,
        fullSellPriceUsd:0,
        fullBuyPriceUsd:0,

        fullSellPriceRu:0,
        fullSellPriceEu:0,

        fullBuyPriceRu:0,
        fullBuyPriceEu:0,
        countas:0,
        currentCurrency:'USD',
        editObjectId:null,
        needChangeRoute:true,
        openEdit:false,
        showContextMenu:false,
        showDeleteModal:false,
        rates:null,
        countIP:0,

        additionalCounts:{
            our_net_count:0,
            buy_for_as_count:0,
            unique_sellers_count:0,
            unique_buyer_count:0,
        },
        groups:{},
        sellManagerCollapsed:true,
        buyManagerCollapsed:true,
        searchMeta: null,
    }
);
export const moduleOffersHistory = {
    namespaced: true,
    state:state,
    mutations: offerHistoryMutations,
    actions:  offerHistoryActions ,
    getters: offerHistoryGetters,
    modules:{
        "filters":offerHistoryFilters,
        "addEdit":offerHistoryAddEdit,
    }
}