<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped mb-0 flex-grow-0 flex-shrink-0">
      <thead class="sticky">
        <tr class="no-shadow">
          <th
              data-bs-toggle="collapse"
              :aria-expanded="!collapsed"
              class="expandable text-center"
              data-bs-target="#history-table-collapse"
              :colspan="7"
              scope="col"
          >История трансферов ({{ relations.length }})</th>
        </tr>
      </thead>
    </table>
    <div class="relation-table collapse" :class="{'show': !collapsed}" id="history-table-collapse">
      <table class="table table-bordered thread-person-relation-table table-striped mb-1">
        <thead class="sticky">
          <tr>
            <th
                style="min-width: 195px;"
                class="sortable"
                :class="sortedClass('subnets')"
                @click="sortBy('subnets')"
            >Подсеть / AS</th>
            <th
                style="min-width: 65px;"
                class="sortable"
                :class="sortedClass('count')"
                @click="sortBy('count')"
            >IPs</th>
            <th
                style="min-width: 100px;"
                class="sortable"
                :class="sortedClass('date')"
                @click="sortBy('date')"
            >Дата</th>
            <th
                style="min-width: 230px;"
                class="sortable"
                :class="sortedClass('seller')"
                @click="sortBy('seller')"
            >Продавец</th>
            <th
                style="min-width: 230px;"
                class="sortable"
                :class="sortedClass('buyer')"
                @click="sortBy('buyer')"
            >Покупатель</th>
            <th
                class="sortable"
                :class="sortedClass('buyprice')"
                @click="sortBy('buyprice')"
            >Номинал</th>
            <th
                class="sortable"
                :class="sortedClass('sellprice')"
                @click="sortBy('sellprice')"
            >Продажа</th>
          </tr>
        </thead>
        <tbody>
          <history-row v-for="relation in sortedRelations" :row="relation" :is-history-admin="isHistoryAdmin" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import HistoryRow from "./HistoryRow";
import {UserPermission} from "../../../../../../users/UserPermission";
import {sortFunctions} from "../Relation";

export default {
  name: "History",

  components: {HistoryRow},

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      isHistoryAdmin: false,
      collapsed: this.collapse,
      sort: "date",
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {
        "count": "number",
        "buyprice": "price1Field",
        "sellprice": "price1Field",
        "seller": "clientName",
        "buyer": "clientName",
      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  mounted() {
    UserPermission.can('system.crm_history')
                  .then(() => this.isHistoryAdmin = true);
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
th {
  text-align: center;
}
</style>