import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from './getters'
const state = {
    loading:false,
    kpi:{},
    waitingOrders:[],
}

export const moduleStatistic= {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters,

}