<template>
  <button
      v-if="showButtons"
      @click="openModal"
      class="btn btn-light btn-outline-dark col-12"
  >
    <i class="fa button-icon menu-button fa-check-circle-o"></i> Закрыть
  </button>
  <teleport to="body">
    <transition name="modal">
      <close-modal
          v-if="showModal"
          @close="closeModal"
          @success="closeTickets"
      ></close-modal>
    </transition>
  </teleport>
</template>

<script>
import {Ticket} from "../../../library/api/Ticket";
import {mapActions} from "vuex";
import CloseModal from "./CloseModal";

export default {
  name: "CloseButton",

  components: {
    CloseModal,
  },

  data() {
    return {
      loading: false,
      showModal: false,
    }
  },

  computed: {
    showButtons() {
      return Object.keys(this.selectedObjects).length > 0;
    },

    selectedObjects() {
      return this.$store.getters['tickets/getSelectedObjects'];
    },
  },

  methods: {
    openModal() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'closeTicket');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'closeTicket');
    },
    closeTickets() {
      this.loading = true;
      let ids = Object.keys(this.selectedObjects);
      Ticket.closeTickets(ids).then(() => {
        Object.values(this.selectedObjects).forEach(ticket => {
          ticket.closed = true;
          ticket.open = ticket.new = false;
        });
        this.$store.commit('successPush', ids.length === 1
            ? 'Тикет закрыт'
            : 'Тикеты закрыты')

        if (this.$route?.name === 'Ticket') {
          this.closeTicket();
        } else {
          this.reloadList();
        }
      }).finally(() => this.loading = false);
    },

    closeTicket() {
      this.$store.commit('tickets/toLastSelect', false);
      this.redirectToTickets();
    },

    reloadList() {
      if(['answered', 'closed', 'new'].includes(this.$route?.params?.filter)) {
        this.$store.dispatch('tickets/getAllTickets', this.$route);
      }
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    })
  },
}
</script>

<style scoped>

</style>