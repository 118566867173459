<template>
  <div  class="col-12" style="text-align: right">
      <span class="message message-to position-relative" :class="{'has-bounce': typeof message.bounce !== 'undefined'}" @mouseover="showContextButton = true"  @mouseout="showContextButton = false">
        <div class="col-12 datetime-stamp text-end">{{messageDate}}</div>
        <div class="" style="position: relative">

         <div class="sender-block">
           <headers-block
               :headers="message.headers"
               :message="message"
               :author="message.author"
               :is-project-null="isProjectNull"
           ></headers-block>
         </div>

         <div v-show="showContextButton" class="contextFromButton" style="">
             <context-button
                 :messageid="message._id"
                 :custom-button-style="{
                   'border-style':'none',
                    'background': '#f5fbea',
                   'outline':'none'
                    }"
                 :show="showFunctions"
                 :callbacks="{
                   createAsNew: createAsNew,
                   moveMessages: moveMessages,
                   hideHtml: hideHtml,
                   showHtml: showHtml
                 }"
                 :left-align="true"
             ></context-button>
         </div>
        </div>

        <div class="attachments-block" v-if="message.attachments">
          <attachment v-for="attachment in message.attachments" :attachment="attachment" :messageid="message._id" :key="attachment.id" :can-open-attachment="isAuthor"></attachment>
        </div>

        <message-text :message="message" :collapsed="collapsed" :as-html="asHtml"></message-text>
        <bounce-icon :message="message"></bounce-icon>
      </span>
  </div>
</template>

<script>
import Attachment from "./Attachment";
import HeadersBlock from "./HeadersBlock";
import MessageText from "./Text";
import ContextButton from "./ContextMenu/ContextButton";
import BounceIcon from "./BounceIcon";
import {getDateFormat} from "../Api/api";
import {mapGetters} from "vuex";

export default {
  name: "MessageTo",

  components: {
    BounceIcon,
    ContextButton,
    MessageText,
    HeadersBlock,
    Attachment
  },

  props:{
    "message": {
      type:Object,
      require:true
    },

    collapsed: {
      type: Boolean,
      default: false,
    },

    changePermission: {
      type: Boolean,
      default: false,
    },
  },

  computed:{
    messageDate: function (){
      return getDateFormat(this.message.created, window.dateFormatWeekDaySeconds);
    },

    isProjectNull: function() {
      return !_.isObject(this.message.ticket?.project);
    },

    showFunctions: function() {
      let functions = ['openSource'];
      if (!this.isProjectNull && this.userCanAnswer && !this.isTicketDeleted) {
        functions.push('createAsNew');
      }
      if (this.changePermission && !this.isTicketDeleted) {
        functions.push('moveMessages');
      }
      if (this.message.bodytype === 'html') {
        functions.push(this.asHtml ? 'hideHtml' : 'showHtml');
      }
      return functions;
    },

    isAuthor() {
      return typeof this.message.author._id !== 'undefined' && window.getId(this.message.author._id) === window.userId;
    },

    isTicketDeleted() {
      return (this.ticket?.deleted === true);
    },

    ...mapGetters({
      "userCanAnswer": "thread/userCanAnswer",
      ticket: "thread/getTicket",
    }),
  },

  data: function (){
    return {
      showContextButton: false,
      asHtml: true,
    }
  },

  methods: {
    hideHtml: function () {
      this.asHtml = false;
    },
    showHtml: function () {
      this.asHtml = true;
    },
    createAsNew: function() {
      this.$emit('createAsNew', this.message);
    },
    moveMessages: function() {
      this.$emit('moveMessages', this.message);
    }
  }
}
</script>

<style scoped>
.contextFromButton{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1030;
}
.has-bounce {
  padding-bottom: 30px;
}
</style>