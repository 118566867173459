<template>
    <tr @dblclick="editTemplateDblclick">
      <td :class="{'text-decoration-line-through': !userActive}">{{user.login}}</td>
      <td><roles-list :role="user.role"></roles-list></td>
      <td>{{user.name}}</td>
      <td>{{user.phoneext}}</td>
      <td>
        <div v-if="user.projects.length">
          <span v-for="project in user.projects" class="badge rounded-pill project-badge-bg mb-1 me-1">{{project.name}}</span>
        </div>
      </td>
      <td>
        <div class="dropdown pull-right text-md-start">
          <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Функции
          </button>
          <div class="dropdown-menu " aria-labelledby="dropdownMenu2">
            <a @click="editTemplate" class="dropdown-item">Редактировать</a>
            <a @click="showModal = !showModal" class="dropdown-item">{{ modalCallBtn }}</a>
          </div>
        </div>
      </td>
    </tr>
  <user-change-status-modal
    v-if="showModal"
    :user="user"
    @close="showModal = false"
    @update="changeStatus"
  ></user-change-status-modal>
</template>

<script>
import RolesList from "./RolesList";
import UserChangeStatusModal from "./UserChangeStatusModal";
import {User} from "../../library/api/User";
import {CrmApi} from "../../library/CrmApi";
export default {
  name: "UserRow",
  components: {
    UserChangeStatusModal,
    RolesList,
  },
  props: {
    'user': {
      type: Object,
      require: true
    },
    'editUrl': {
      type: String,
      require: true,
    }
  },

  emits: [
      'load'
  ],

  data: function() {
    return {
      showProjects: false,
      showModal: false,
    }
  },

  computed: {
    userActive() {
      return this.user.status === 'active';
    },
    modalCallBtn() {
      return this.userActive ? 'Выключить' : 'Включить';
    }
  },

  methods: {
    editTemplateDblclick: function(e) {
      if(['A', 'BUTTON'].includes(e.target.tagName)) {
        return;
      }
      this.editTemplate();
    },

    editTemplate: function() {
      window.location.href = this.editUrl.replace('_ID_', this.user._id);
    },

    changeStatus() {
      User.edit(window.getId(this.user._id), {
        "status": this.userActive ? 'disabled' : 'active',
      }).then((result) => {
        this.showModal = false;
        this.$emit('load');
      }).catch(error => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      });
    },
  },
}
</script>

<style scoped>
tr {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.ldap-text-td {
  height: 32px;
}
.ldap-text {
  font-size: 10px;
}
.project-badge-bg{
  background-color: rgb(81 84 86);
}
</style>