<template>
  <div class="accordion-item">
    <h2 class="accordion-header" :id="'accordion_' + projectId">
      <button class="accordion-button" v-bind:class="{collapsed: !openCollapse}" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' + projectId" aria-expanded="true" aria-controls="collapseOne">
        {{project.name}}
      </button>
    </h2>
    <div :id="'collapse_' + projectId" class="accordion-collapse collapse" v-bind:class="{show: openCollapse}" @click="stopProp" @pointerdown.stop="" @mousedown.stop="" aria-labelledby="headingOne" :data-bs-parent="'#accordion_' + projectId">
      <div class="accordion-body">
        <div class="mb-3">
          <label :for="'name_' + projectId" class="form-label">ФИО</label>
          <popper content="ФИО для отображения в подписи" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <input :id="'name_' + projectId" type="text" v-model="user.projectsettings[projectId].name"  class="form-control"  id="name" placeholder="Фамилия И. О.">
          </popper>
        </div>
        <div class="mb-3">
          <label :for="'name_en_' + projectId" class="form-label">ФИО (EN)</label>
          [<a href="https://blog.reedsy.com/character-name-generator/language/english/" target="_blank">?</a>]
          <popper content="ФИО для подписи на английском языке" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <input :id="'name_en_' + projectId" type="text" v-model="user.projectsettings[projectId].name_en"  class="form-control"  id="name_en" placeholder="Фамилия И. О. (EN)">
          </popper>
        </div>
        <div class="mb-3">
          <label :for="'phoneext_' + projectId" class="form-label">Добавочный номер</label>
          <popper content="Добавочный номер для прямого звонка" class="light" placement="top"  :hover="true" :interactive="false" :arrow="true">
            <input type="text"   class="form-control" v-model="user.projectsettings[projectId].phoneext" :id="'phoneext_' + projectId" placeholder="000">
          </popper>
        </div>
        <div class="mb-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="user.projectsettings[projectId].notifications_disabled" :id="'notifications_disabled_' + projectId">
            <label class="form-check-label" :for="'notifications_disabled_' + projectId">
              Отключить уведомления
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ProjectSettings.vue",

  props: {
    project: {
      required: true
    },
    user: {
      type: Object,
      required: true
    },
  },

  data: function() {
    return {
      showNamePopper: false,
      showPhoneextPopper: false,
    }
  },
  created() {
    if( typeof this.user.projectsettings === 'undefined' || this.user.projectsettings === null){
      this.user.projectsettings = {};
    }
    if( typeof this.user.projectsettings[this.projectId] !== 'object') {
      this.user.projectsettings[this.projectId] = {};
    }
  },

  computed: {
    projectId: function() {
      return window.getId(this.project._id);
    },
    openCollapse: function() {
      return (typeof this.user.projectsettings[this.projectId].name !== 'undefined' &&
             this.user.projectsettings[this.projectId].name !== '' &&
             this.user.projectsettings[this.projectId].name !== null) ||
             (typeof this.user.projectsettings[this.projectId].name_en !== 'undefined' &&
             this.user.projectsettings[this.projectId].name_en !== '' &&
             this.user.projectsettings[this.projectId].name_en !== null) ||
             (typeof this.user.projectsettings[this.projectId].phoneext !== 'undefined' &&
             this.user.projectsettings[this.projectId].phoneext !== '' &&
             this.user.projectsettings[this.projectId].name !== null);
    },
  },
}
</script>

<style scoped>
.light {
  display: block !important;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>