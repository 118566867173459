<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} отключил(а) тикет от {{ label }}
      <template v-if="Array.isArray(this.params?.projects) && this.params.projects.length > 0">
        <span v-for="project in params.projects" class="badge rounded-pill bg-secondary mx-1">{{project}}</span>
      </template>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "RemoveAssignedProjects",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    label() {
      return Array.isArray(this.params?.projects) && this.params.projects.length > 1 ? 'проектов:' : 'проекта';
    }
  }
}
</script>

<style scoped>

</style>