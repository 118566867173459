<template>
  <div v-if="!userCanChange">
    <template v-if="showLabel">Префикс:&nbsp;</template>
    <span v-if="prefix || showLabel" :class="{'prefix-badge': typeAs === 'badge'}">
      {{prefixString}}
      <template v-if="type">
        <template v-if="typeAs === 'text'">{{type}}</template>
        <span v-if="typeAs === 'badge'" class="badge subnet-type-badge ms-1" :class="typeBadgeClass">
          {{type}}
        </span>
      </template>
    </span>
  </div>
  <div class="input-group" v-else-if="asInput" @keyup.esc.stop="form.cancel" @keydown.enter.stop="save" ref="group">
    <label for="prefix-input" class="col-sm-2 col-form-label w-auto pe-2" v-if="showLabel">Префикс:</label>
    <input type="text" class="form-control" :class="{'is-invalid': !isValid}" id="prefix-input" @input="this.isValid = true" v-model="proxy.prefix" ref="input">
    <select class="form-control" v-model="proxy.type">
      <option selected disabled :value="null">Тип</option>
      <option value="PA">PA</option>
      <option value="PI">PI</option>
    </select>
  </div>
  <div v-else>
    <template v-if="showLabel">Префикс:&nbsp;</template>
    <span class="text-primary" :role="role" @dblclick="form.showForm" :class="{'prefix-badge': typeAs === 'badge'}">
      <i v-if="!prefix && !showLabel" class="fa fa-plus-circle form-add" style="color:#6262f5;cursor:pointer;" @click="form.showForm"></i>
      <template v-else>
        {{prefixString}}
        <template v-if="type">
          <template v-if="typeAs === 'text'">{{type}}</template>
          <span v-if="typeAs === 'badge'" class="badge subnet-type-badge ms-1" :class="typeBadgeClass">
            {{type}}
          </span>
        </template>
      </template>
    </span>
  </div>
</template>

<script>
import {Form} from "./Form.js";

export default {
  name: "Prefix",

  emits: ['update'],

  props: {
    prefix: {},
    type: {},
    showLabel: {
      type: Boolean,
      default: true,
    },
    typeAs: { // text || badge
      type: String,
      default: 'text'
    },
    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  data: function() {
    return {
      asInput: false,
      isValid: true,

      proxy: {},

      form: new Form(this),
      fields: ['prefix', 'type'],
    }
  },

  computed: {
    prefixString: function() {
      let string = '—';

      if((typeof this.prefix === 'string' || typeof this.prefix === 'number') && this.prefix !== '') {
        string = '/' + this.prefix;
      }

      return string;
    },

    save: function() {
      let prefix = this.proxy.prefix.toString().replace(/[^\d,\.]/g, '');
      prefix = parseInt(prefix);

      if(prefix > -1 && prefix < 33 || prefix === '') {
        if(this.proxy.prefix === '') {
          this.proxy.type = null;
        }

        this.form.save({prefix: prefix, type: this.proxy.type});
      } else {
        this.isValid = false;
      }
    },

    role() {
      return this.userCanChange ? 'button' : '';
    },

    typeBadgeClass() {
      let className = '';
      switch (this.type) {
        case 'PA':
          className = 'bg-primary';
          break;
        case 'PI':
          className = 'bg-warning';
          break;
      }
      return className;
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.prefix-badge {
  display: inline-flex;
  align-items: center;
}
</style>