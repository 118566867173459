import {computed} from 'vue';
import offerStore from "../../../store/main/offer/index";


export default function getOffersFields({offer}){

    const price = computed(() => {
        return offer.value?.assigned_product?.fields?.price;
    });

    const price1 = computed(() => {
        return offer.value?.assigned_product?.fields?.price1;
    })

    const currency = computed(() => {
        return  check(offer.value?.assigned_product?.fields?.currency);
    });

    const quantity = computed(() => {
        return offer.value?.assigned_product?.fields?.quantity;
    });

    const comment = computed(() => {
        return check(offer.value?.assigned_product?.fields?.comment);
    });

    const status = computed(()=>{
        return check(offer.value?.assigned_product?.current_step?.name);
    });

    const date = computed(() => {
        return check(offer.value?.assigned_product?.fields?.date);
    });


    const typeNet = computed(() => {
        return offer.value?.assigned_product?.fields?.type;
    });

    const id = computed(() => {
        return check(window.getId(offer.value?._id));
    });

    const canChange = computed(() => {
        let project = offer.value?.project?.name ?? false;
        return project ? (offerStore.getters['offer/getChangeRights'][project] ?? false) : false;
    });

    const canSetLot = computed(() => {
        let can = false;
        let project = offer.value?.project?.name ?? false;
        if (project) {
            can = offerStore.getters['offer/getSetLotRights'][project] ?? false;
            if (!can &&
                typeof offer.value?.assigned_projects !== 'undefined' &&
                Array.isArray(offer.value?.assigned_projects)
            ) {
                for (project of offer.value.assigned_projects) {
                    if (typeof project?.name === 'string') {
                        if ((offerStore.getters['offer/getSetLotRights'][project] ?? false) === true) {
                            can = true;
                            break;
                        }
                    }
                }
            }
        }
        return can;
    });

    return {price, price1, currency, typeNet, quantity, comment, status, date, id, canChange, canSetLot};
}

export function getLoading(){
    const loading = computed({
        set(value){
            offerStore.commit('offer/setLoading' , value)
        },
        get(){
            return offerStore.getters['offer/getLoading'];
        }
    })
    return {loading}
}

export function getOrderBy() {
    const getOrderByField = computed(() => {
        return offerStore.getters['offer/getOrderByField'];
    });
    const getOrderByDirection = computed(() => {
        return offerStore.getters['offer/getOrderByDirection'];
    });
    return {getOrderByField, getOrderByDirection}
}

export const  check = function (value) {
    if(value === null || value === undefined){
        value = ''
    }
    return value;
}