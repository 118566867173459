<template>
  <button class="btn btn-light btn-outline-dark col-12" @click="showModal">
    <i class="fa fa-credit-card"></i> Счет
  </button>

  <teleport to="body">

    <transition name="modal">
      <modal-window v-if="isModalOpen" @close="closeModal" @success="submit" modal-dialog-class="modal-lg modal-dialog-scrollable invoice-modal">
        <template v-slot:header>
          <h5 class="modal-title">Создание счета</h5>
        </template>
        <template v-slot:body>
          <div v-if="(selectedAccount === null || createNewAccount) && accountValidationWarnings.length" class="alert alert-warning" role="alert">
            <div v-for="(warning, index) in accountValidationWarnings" v-html="(index + 1) + '. ' + warning"></div>
            <div style="text-align: center; margin-top: 10px;"><button class="btn btn-sm btn-primary" type="button" @click="openClientModal">Изменить клиента</button></div>
          </div>
          <div class="form-floating mb-3">
            <select id="account-select" class="form-select" v-model="selectedAccount" @change="selectAccount" :disabled="loading.meta || loading.save">
              <option disabled="disabled" value="">Выберите аккаунт</option>
              <option v-for="account in accounts" :value="account.id">{{account.name}}</option>
              <option v-if="freeEmails.length" v-for="email in freeEmails" :value="email">Создать новый аккаунт с {{ email }}</option>
            </select>
            <label for="account-select">Аккаунт BILLmanager</label>
          </div>

          <div v-if="selectedProfile === null && profileValidationWarnings.length" class="alert alert-warning" role="alert">
            <div v-for="warning in profileValidationWarnings" v-html="warning"></div>
            <div style="text-align: center; margin-top: 10px;"><button class="btn btn-sm btn-primary" type="button" @click="openClientModal">Изменить клиента</button></div>
          </div>
          <div v-if="showProfileSelect" class="form-floating mb-3">

            <select id="profile-select" class="form-select" v-model="selectedProfile" :disabled="loading.meta || loading.save">
              <option disabled="disabled" value="">Выберите плательщика</option>
              <option v-for="profile in profiles" :value="profile.id">{{profile.name}}</option>
              <option :value="'new'">Создать новый</option>
            </select>
            <label for="profile-select">Плательщик</label>
          </div>

          <div class="mb-2">
            <div class="d-flex justify-content-between">
              <span class="">Услуги</span>
              <div class="dropdown position-unset">
                <button class="btn btn-sm btn-primary dropdown-toggle" :disabled="loading.meta || loading.save" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Добавить
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                  <li v-for="tariff in tariffs"><span class="dropdown-item" role="button" @click="addTariff(tariff)">{{tariff.name}} <span v-if="tariff?.project?.name" style="margin-left: 10px;" class="badge bg-secondary">{{tariff?.project?.name}}</span></span></li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="selectedTariffs.length" class="card-body">
            <table class="table table-hover table-bordered table-striped">
              <thead>
              <tr>
                <th width="6%">id</th>
                <th width="45%">Название</th>
                <th width="11%" class="text-center">Период</th>
                <th class="text-center">Цена</th>
                <th class="text-center">Скидка</th>
                <th width="4%"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(tariff, index) in selectedTariffs">
                <td><a :href="priceListLink(tariff.id)" target="_blank">{{tariff.id}}</a></td>
                <td>{{tariff.name}}</td>

                <td class="text-center">
                  <editable-select :options="getPeriods(tariff.periods)"
                                   :selected="getSelectedPeriod(tariff.periods)"
                                   @change="(period) => changeSelectedPeriod(period, tariff.periods)" />
                </td>

                <td class="text-center">
                  <span v-if="hasDiscount(tariff)">
                    <div class="text-danger fw-bold"> {{ getPriceWithDiscountCaption(tariff) }} </div>
                    <div class="text-decoration-line-through small"> {{ getPriceCaption(tariff) }}<div v-if="getSetupPrice(tariff)">
                       <popper content="Установка (единоразово)" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
                         <span class="text-decoration-line-through small">+ {{getSetupPriceCaption(tariff)}}</span>
                       </popper>
                    </div></div>
                  </span>
                  <span v-else>{{ getPriceCaption(tariff) }}<span v-if="getSetupPrice(tariff)"><br/>
                    <popper content="Установка (единоразово)" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
                    + {{getSetupPriceCaption(tariff)}}
                    </popper>
                  </span></span>
                </td>

                <td class="text-center">
                  <span v-if="tariff.discount.type !== null" class="me-1">
                    <editable-text :text="tariff.discount.value"
                                   :validation="(value) => discountValueValidation(value, tariff.discount.type, getPrice(tariff))"
                                   :absolute="true"
                                   @change="(value) => changeDiscountValue(value, tariff)" />
                  </span>
                  <editable-select :options="getDiscountTypeOptions(getCurrency(tariff))"
                                   :selected="tariff.discount.type"
                                   @change="(type) => changeDiscountType(type, tariff)" />
                  <span v-if="tariff.discount.type === '1'"> (фикс. цена)</span>
                </td>

                <td class="text-center">
                  <i @click="removeTariff(index)" role="button" class="fa fa-trash-o text-danger"></i>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

        </template>

        <template v-slot:footer>
          <div class="w-100 double-buttons-group">
            <div>
              <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
            </div>
            <div>
              <button type="button" class="btn btn-primary" @click="submit" :disabled="submitDisabled">
                <span v-if="loading.submit" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Создать
              </button>
            </div>
          </div>
        </template>>
      </modal-window>
    </transition>

  </teleport>
</template>

<script>
import {BILLmanager} from "../../../../library/api/BILLmanager";
import {CrmApi} from "../../../../library/CrmApi";
import {getNumeralsWord} from "../../../../library/Numerals";
import ModalWindow from "../ModalWindow";
import EditableSelect from "../../../Editable/EditableSelect";
import EditableText from "../../../Editable/EditableText";
import {PricePrettyView} from "../../../../library/PricePrettyView";
import {mapGetters, mapMutations} from "vuex";

const INVOICE_MODALWINDOW_NAME = "invoiceCreateModal";

export default {
  name: "Invoices",

  components: {EditableText, ModalWindow, EditableSelect},

  props: {
    ticketId: {
      require: true
    },
  },

  data() {
    return {
      modal: {
        show: false
      },

      meta: null,
      selectedAccount: '', // null - создать новый
      selectedProfile: '', // null - создать новый
      selectedTariffs: [],

      loading: {
        meta: false,
        save: false,
        submit: false,
      },

      personFieldsRequiredForAccount: {
        'phone.0.number': 'Телефон',
        'company.country': 'Страна',
        'any1': {
          'name': 'Имя',
          'company.name': 'Название компании',
          'company.fullname': 'Полное название компании'
        }
      },
      personFieldsRequiredForProfile: {
        'phone.0.number': 'Телефон',
        'company.signer.name': 'Подписант ФИО',
        'company.legaltype': 'Статус компании',
        'company.country': 'Страна',
        'company.address.legal': 'Юридический адрес',
        'company.address.real': 'Фактический адрес',
        'company.vatnum': 'ИНН',
        'company.ogrn': 'ОГРН',
        'company.bank.name': 'Название банка',
        'company.bank.bik': 'БИК',
        'company.bank.account': 'Расчетный счет',
        'company.bank.coraccount': 'Корреспондентский счет',
        'any1': {
          'company.name': 'Название компании',
          'company.fullname': 'Полное название компании'
        }
      }
    }
  },

  computed: {
    accountValidationWarnings() {
      return this.getWarningsPersonRequiredFields(
          // если создается аккаунт, то также к нему создается и профиль
          Object.assign({ ...this.personFieldsRequiredForProfile }, { ...this.personFieldsRequiredForAccount })
      );
    },

    profileValidationWarnings() {
      let optionalFields = {};

      if(this.person?.company?.legaltype === 'company') {
        optionalFields['company.signer.position'] = 'Должность подписанта';
        optionalFields['company.kpp'] = 'КПП';
      }

      return this.getWarningsPersonRequiredFields(
          Object.assign({ ...this.personFieldsRequiredForProfile }, { ...optionalFields })
      );
    },

    showProfileSelect() {
      return ![null, ''].includes(this.selectedAccount) && this.profiles.length > 0;
    },

    accounts() {
      return this.meta?.accounts ?? [];
    },

    profiles() {
      let result = [];
      if (this.accounts.length && this.selectedAccount) {
        let currentAccount = this.accounts.find(item => item.id === this.selectedAccount);
        if (currentAccount && typeof currentAccount?.profiles !== 'undefined') {
          result = currentAccount?.profiles;
        }
      }
      return result;
    },

    freeEmails() {
      let result = this.meta?.emails ?? [];
      if (Array.isArray(result) && result.length && this.person?.email && result.includes(this.person?.email)) {
        result = result.filter(item => item !== this.person.email);
        result.unshift(this.person?.email);
      }
      return this.meta?.emails ?? [];
    },

    tariffs() {
      let tariffs = this.meta?.tariffs ?? [];

      if(this.selectedTariffs.length > 0) {
        // если добавлена хотя бы одна услуга, то в списке услуг доступных для добавления,
        // оставляем только из того же проекта
        let project = this.selectedTariffs[0].project.id;
        tariffs = Object.fromEntries(
            Object.entries(tariffs).filter(([,details]) => details.project.id === project)
        );
      }

      return tariffs;
    },

    person() {
      return this.$store.getters['thread/getPerson'];
    },

    submitDisabled() {
      return Object.values(this.loading).some(loading => loading) ||
             this.selectedTariffs.length === 0 ||
             this.selectedAccount === '' ||
             (this.selectedProfile === null && this.showProfileSelect) ||
             ((this.selectedAccount === null || this.createNewAccount) && this.accountValidationWarnings.length) ||
             (this.selectedProfile === null && this.profileValidationWarnings.length);
    },

    createNewAccount() {
      return this.freeEmails.includes(this.selectedAccount);
    },

    isModalOpen(){
      return this.modal.show && this.getActiveWindow !== "ticketEditClient";
    },

    ...mapGetters({
      "getActiveWindow" : "getActiveWindow",
    }),
  },

  methods: {
    ...mapMutations({
      "setActiveWindow": "setActiveWindow",
      "removeActiveWindow": "removeActiveWindow",
    }),

    submit() {
      this.loading.submit = true;

      let request = {
        account: this.selectedAccount,
        profile: this.selectedProfile,
        items: this.selectedTariffs.map(tariff => {
          return {
            pricelist: tariff.id,
            period: this.getSelectedPeriod(tariff.periods),
            discount: tariff.discount
          };
        })
      };

      if(
          ![null,''].includes(request.account) &&
          [null,''].includes(request.profile)
      ){
        let currentAccount = this.accounts.find(item => item.id === request.account);
        request.email = currentAccount?.email;
      }

      if (this.freeEmails.includes(String(request.account))) {
        request.email = request.account;
        request.account = null;
        request.profile = null;
      }

      BILLmanager.makeBillmgrInvoice(this.ticketId, request)
                 .then(this.closeModal)
                 .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
                 .finally(() => this.loading.submit = false);
    },

    openClientModal(){
      this.$store.commit('setActiveWindow', 'ticketEditClient');
    },

    hasDiscount( item ){
      return item.discount?.value !== null &&
          ((item.discount?.type + '') !== '1' || Number(item.discount?.value) !== this.getPrice(item)) &&
          ((item.discount?.type + '') !== '0' || item.discount?.value > 0 );
    },

    getWarningsPersonRequiredFields(fields) {
      let warnings = [];
      let requiredMissing = [];

      let isPersonfieldNotfilled = ( fieldName ) => {
        let value = _.get(this.person, fieldName);
        return typeof value === 'undefined' ||
            value === '' ||
            value === null
      };

      Object.entries(fields).forEach(([field, label]) => {
        if(typeof label === 'string') {
          if( isPersonfieldNotfilled(field) ) {
            requiredMissing.push(label);
          }
        } else {
          if( !Object.keys(label).some(key => !isPersonfieldNotfilled(key)) ) {
            warnings.push('Хотя бы одно из этих полей клиента должно быть заполнено: <i>' + Object.values(label).join(', ') + '</i>')
          }
        }
      })

      if(requiredMissing.length > 0) {
        warnings.push('Не заполнены обязательные поля клиента: <i>' + requiredMissing.join(', ') + '</i>')
      }
      if(this.person?.company?.legaltype === 'person') {
        warnings.push('Тип организации не может быть "физическое лицо"');
      }

      return warnings;
    },

    getDiscountTypeOptions(currency) {
      return {
        0: '%',
        1: PricePrettyView.getCurrencySymbol(currency) //`Фикс. ${currency}`
      };
    },

    discountValueValidation(value, discountType, price) {
      value = String(value).replace(',', '.');
      discountType = Number(discountType);
      let valid = value.match(/[0-9\.]+/) && Number(value) > -1;
      if(valid) {
        if(discountType === 0) {
          valid = Number(value) <= 100;
        } else if(discountType === 1) {
          valid = Number(value) <= price;
        }
      }

      return valid;
    },

    changeDiscountType(type, tariff) {
      tariff.discount.type = type;
      tariff.discount.value = 0;
    },

    changeDiscountValue(value, tariff) {
      if(value.length === 0) {
        tariff.discount.type = tariff.discount.value = null;
      } else {
        tariff.discount.value = value;
      }
    },

    changeSelectedPeriod(period, periods) {
      Object.entries(periods).forEach(([p, details]) => details.selected = (p === period));
    },

    selectAccount() {
      // сбрасываем выбор профиля при изменении аккаунта
      // Если создается новый аккаунт (значение null), то профиль тоже создается
      if (this.selectedAccount === null || this.createNewAccount || this.profiles.length === 0) {
        this.selectedProfile = null;
      } else {
        this.selectedProfile = this.getMaxId(this.profiles);
      }
    },

    getMaxId(array) {
      return array.reduce((acc, item) => {
        return typeof item?.id !== 'undefined' && item.id > acc ? item.id : acc;
      }, 0);
    },

    initAccount() {
      if (this.accounts.length) {
        let id = null;
        if (typeof this.person?.email !== 'undefined' && this.person?.email) {
          id = this.accounts.find(item => item?.email === this.person.email)?.id;
        }
        if (typeof id === 'undefined' || id === null) {
          id = this.getMaxId(this.accounts);
        }
        if (typeof id !== 'undefined') {
          this.selectedAccount = id;
          this.selectAccount();
        }
      } else {
        if(this.freeEmails.length){
          this.selectedAccount = this.freeEmails[0];
        }
      }
    },

    priceListLink(id) {
      return `https://my.i7.net/billmgr?elid=${id}&plid=&startform=pricelist.edit`;
    },

    addTariff(tariff) {
      let tariffToAdd = _.cloneDeep(tariff);
      tariffToAdd.discount = {
        type: null,
        value: null,
      };
      this.selectedTariffs.push( tariffToAdd );
    },

    removeTariff(index) {
      this.selectedTariffs.splice(index, 1);
    },

    getPeriods(periods) {
      let options = {};

      Object.keys(periods ?? {})
            .forEach(months => {
              let numerals = ['мес.', 'мес.', 'мес.'];
              let period = months;

              if(months % 12 === 0) {
                period = months / 12;
                numerals = ['год', 'года', 'лет'];
              }
              options[months] = getNumeralsWord(period, numerals);
            });

      return options;
    },

    getCurrency(tariff) {
      return tariff.periods[ this.getSelectedPeriod(tariff.periods) ].currency;
    },

    getPrice(tariff) {
      return Number(tariff.periods[ this.getSelectedPeriod(tariff.periods) ].price);
    },
    getPriceCaption(tariff) {
      return (new PricePrettyView(this.getPrice(tariff), this.getCurrency(tariff))).getPrettyPrice();
    },
    getSetupPrice(tariff){
      return tariff.setup?.price ? Number(tariff.setup?.price) : 0;
    },
    getSetupPriceCaption(tariff){
      return (new PricePrettyView(this.getSetupPrice(tariff), tariff.setup?.currency)).getPrettyPrice();
    },

    getPriceWithDiscountCaption(tariff) {
      let price;

      if( this.hasDiscount(tariff) ){
        price = this.getPrice(tariff) + this.getSetupPrice(tariff);

        let prefix = '';
        switch (tariff.discount.type + ''){
          case '0':
            price = Math.round( (price - price * tariff.discount.value / 100) * 100 ) / 100;
            prefix = "≈";
            break;

          case '1':
            price = tariff.discount.value;
            break;
        }

        price = prefix + (new PricePrettyView(price, this.getCurrency(tariff))).getPrettyPrice();
      } else {
        price = this.getPriceCaption(tariff)
      }

      return price;
    },

    getSelectedPeriod(periods) {
      // признак выбранного периода, по уменьшению приоритета:

      // есть период с selected, значит его задал пользователь
      let selected = Object.entries(periods).find(([,details]) => details?.selected)?.[0] ?? null;

      if(!selected) {
        // есть период по умолчанию
        selected = Object.entries(periods).find(([,details]) => details?.default)?.[0] ?? null;
      }
      if(!selected) {
        // максимальный период из доступных
        selected = _.max(Object.keys(periods).map( period => parseInt(period))) ?? null;
      }

      return selected + "";
    },

    showModal() {
      this.modal.show = true;
      this.loadMeta();
      this.setActiveWindow(INVOICE_MODALWINDOW_NAME)
    },

    closeModal() {
      this.modal.show = false;
      this.removeActiveWindow(INVOICE_MODALWINDOW_NAME);
      this.selectedAccount = '';
      this.selectedProfile = '';
      this.selectedTariffs = [];
    },

    loadMeta() {
      this.loading.meta = true;
      BILLmanager.getInvoiceMeta(this.ticketId)
                 .then(result => {
                   this.meta = result.data;
                   this.initAccount();
                 })
                 .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
                 .finally(() => this.loading.meta = false);
    }
  }
}
</script>

<style scoped>
:deep(.invoice-modal > .modal-content > .modal-body) {
  margin: 0;
  position: unset !important;
}
:deep(.invoice-modal > .modal-content) {
  position: unset !important;
}
.position-unset {
  position: unset !important;
}
</style>