<template>
  <div class="pt-5 col-md-9 m-auto">
    <div>
      <Loading loader="dots" :is-full-page="false" v-model:active="loading" />
    </div>
    <form  method="post" @submit.prevent="onSubmit()">
      <div class="col-md-12 m-auto">

        <div class="form-group row">
          <label for="documentname" class="col-sm-3 col-form-label">Название</label>
          <div class="col-sm-9">
            <input type="text" class="form-control"  v-model="document.name" id="documentname"  placeholder="Введите название документа">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Проект</label>
          <div class="col-sm-9">
            <multiselect
                placeholder="Добавить проект"
                v-model="document.projects"
                :mode="'tags'"
                :close-on-select="false"
                :searchable="true"
                :create-option="false"
                :options="loadedProjects"
                ref="multi-project"
            >
              <template v-slot:tag="{ option, handleTagRemove, disabled }">
                <div class="multiselect-tag is-user">
                  <span>{{ option.label }}</span>
                  <span
                      v-if="!disabled"
                      class="multiselect-tag-remove"
                      @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
        <div class="form-group row" v-if="showDefaultCompanySelect && this.companies !== null">
          <label class="col-sm-3 col-form-label">Компания по умолчанию</label>
          <div class="col-sm-9">
            <select v-model="document.default_company" class="form-select mt-2">
              <option :value="null" selected>—</option>
              <option v-for="company in companies" :value="company._id">{{company.name}}</option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label for="document-input" class="col-sm-3 col-form-label" :class="{disabled: this.documentFile.uploading}">Шаблон документа</label>
          <div class="col-sm-9">
            <span v-if="document.template?.filename" class="mt-1">

              <a v-if="document.saved" :href="templateDownloadLink">{{document.template.filename}} ({{prettyBytes(document.template.size)}})</a>
              <template v-else>
                {{document.template.filename}} ({{prettyBytes(document.template.size)}})
              </template>

              <i role="button" class="fa fa-close ms-1" @click="deleteTemplateFromDocument"></i>

            </span>
            <input v-else type="file" accept=".docx" id="document-input" @change="uploadDocument" ref="document-file-input">
          </div>
        </div>

        <div class="form-group row" v-if="document.template">
          <label for="document-input" class="col-sm-3 col-form-label" :class="{disabled: this.documentFile.uploading}">Шаблон названия файла</label>
          <div class="col-sm-9">
            <input type="text" class="form-control"  v-model="document.template.name" id="documentname"  placeholder="Введите шаблон названия документа">
          </div>
        </div>

      </div>
      <template v-if="document.getKeywords().length || document.getBlocks().length">
        <div class="keywords">
          <!--div class="d-flex justify-content-between pb-2 fw-bold" style="padding: 0 0.9em">
            <span>Ключ</span>
            <span>Настройки</span>
            <span>
              <popper content="Обязательное поле" class="light fw-normal" placement="top" :hover="true" :interactive="false" :arrow="true">
                <i class="fa fa-asterisk" style="font-size: 14px" />
              </popper>
            </span>
          </div-->
          <template v-for="group in document.getUnitedGroups()">

            <template v-if="group.groups[0] === 'ungrouped'">
              <draggable v-bind="dragOptions"
                         :list="group.keywords"
                         item-key="order"
                         @change="changeOrder(group.keywords)"
                         handle=".drag-handle">
                <template #item="{element}">
                  <keyword :keyword="element" :draggable="true" />
                </template>
              </draggable>

              <draggable v-bind="dragOptions"
                         :list="ungroupedBlocks"
                         item-key="order"
                         @change="changeOrder(ungroupedBlocks)"
                         handle=".drag-handle">
                <template #item="{element}">
                  <ungrouped-blocks :block="element"
                                    :ungrouped="true" />
                </template>
              </draggable>
            </template>

            <united-group-keywords v-else
                                   :groups="group.groups"
                                   :keywords="group.keywords"
                                   :document="document" />

          </template>
        </div>
      </template>

      <div class="double-buttons-group mt-3 mb-2 col-md-12 m-auto">
        <div class="ps-0">
          <button @click="cancel()" type="button" class="btn btn-secondary">Назад</button>
        </div>
        <div class="pe-0">
          <button type="submit" :disabled="isDisabled" class="btn btn-primary">{{submitButton}}</button>
        </div>
      </div>
    </form>
    <alert-list></alert-list>
  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import {Documents} from "../../library/api/Documents";
import Loading from "vue3-loading-overlay";
import AlertList from "../ticket/Alert/AlertList";
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from  "../ticket/Api/api";
import prettyBytes from "pretty-bytes";
import {mapGetters, mapMutations} from "vuex";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import StringTrim from "../string/StringTrim";
import UnitedGroupKeywords from "./Keywords/UnitedGroupKeywords";
import {Document} from "./Document";
import Keyword from "./Keywords/Keyword";
import Block from "./Keywords/Block";
import Draggable from "vuedraggable";

export default {
  name: "DocumentsNewEdit",

  components: {
    Keyword,
    UnitedGroupKeywords,
    StringTrim,
    Loading,
    AlertList,
    Multiselect,
    ModalWindow,
    UngroupedBlocks: Block,
    Draggable,
  },

  props: {
    type: {
      require: true,
    }
  },

  data() {
    return {
      documentFile: {
        uploading: false,
        uploaded: false,
      },
      isDisable: true,
      selectedProject: "",
      loadedProjects: [],
      hasInvalidBlock: false,
      companies: null,

      dragOptions: {
        animation: 200,
        ghostClass: 'drag-ghost'
      }
    }
  },

  methods:{
    changeOrder(keywords) {
      keywords.forEach((keyword, index) => (keyword.order = index));
    },

    prettyBytes(size) {
      return prettyBytes(size);
    },

    deleteTemplateFromDocument() {
      delete this.document.template;
    },

    uploadDocument(e) {
      if(e.target.files.length) {
        this.documentFile.uploading = true;
        this.hasInvalidBlock = false;

        let file = e.target.files[0];
        Documents.uploadDocument(file).then(result => {
          this.document.setOver(result.data);
          this.document.saved = false;
        }).catch(error => {
          console.error(error);
          e.target.value = '';
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => this.documentFile.uploading = false);
      }
    },

    onSubmit(){
      if(!this.document.isValid()) {
        // если документ не прошел валидацию, останавливаем сохранение и подсвечиваем поля с ошибками
        return;
      }

      this.loading = true;

      let document = this.document.prepareForSave();

      if(this.type === 'edit') {
        Documents.editDocument(document).then((result)=>{
          if (result.data.message) {
            this.$store.commit('errorPush', result.data.message);
          } else {
            this.$store.commit('successPush', 'Документ обновлен');
          }
          this.document = new Document(result.data.document);
          this.document.saved = true;
          this.loadCompanies();
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.loading = false;
        });
      } else {
        Documents.addDocument(document).then(() => {
          window.location.replace( window.location.origin + "/documents/")
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.loading = false;
        });
      }
    },

    cancel() {
      window.location.replace( window.location.origin + "/documents/")
    },

    loadCompanies() {
      if(this.showDefaultCompanySelect && this.companies === null) {
        Documents.getCompanies()
            .then(response => this.companies = response.data.data)
            .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));
      }
    },

    ...mapMutations({
      'setDocument': 'document/setDocument',
    }),
  },

  beforeCreate() {
    if(!this.document instanceof Document) {
      this.document = new Document();
    }
  },

  mounted() {
    changeMultySelectStyles(document);
    this.loadCompanies();
  },

  computed:{
    ungroupedBlocks() {
      return this.document.getGroupBlocks(null);
    },

    loading: {
      get() { return this.$store.getters['document/isLoading'] },
      set(loading) { this.$store.commit('document/setLoading', loading); }
    },

    document: {
      get() { return this.$store.getters['document/getDocument'] },
      set(document) { this.$store.commit('document/setDocument', document); }
    },

    ...mapGetters({
      projects: 'document/getProjects',
      personparamOptions: 'document/getPersonParamOptions',
      ticketOptions: 'document/getTicketOptions',
      additionalCompanyParamOptions: 'document/getAdditionalCompanyOptions',
      companyOptions: 'document/getCompanyOptions',
    }),

    showDefaultCompanySelect() {
      return this.document && this.document.getKeywordsByType('companyparam').length > 0;
    },

    templateDownloadLink() {
      return `/documents/${window.getId(this.document._id)}/download`;
    },

    submitButton() {
      return this.type === 'new' ? 'Создать' : 'Изменить';
    },

    isDisabled(){
      let isDisabled = false;

      if(this.loading) {
        isDisabled = true;
      } else if(this.hasInvalidBlock) {
        isDisabled = true;
      } else if(this.document?.name?.trim() === ''){
        isDisabled = true;
      } else if(this.documentFile.uploading) {
        isDisabled = true;
      } else if(typeof this.document?.template?.filename !== 'string') {
        isDisabled = true;
      } else if(Array.isArray(this.document.params)) {

        paramsLoop:
        for(let i = 0; i < this.document.params.length; i++) {
          let param = this.document.params[i];
          let empty = (str) => typeof str !== 'string' || str === '';

          switch(param.type) {
            case 'textblock':
              if(empty(param.condition)) {
                isDisabled = true;
                break paramsLoop;
              }
              break;
            case 'personparam':
              if(empty(param.personparam)) {
                isDisabled = true;
                break paramsLoop;
              }
              break;
            case 'companyparam':
              if(empty(param.companyparam)) {
                isDisabled = true;
                break paramsLoop;
              }
              break;
            case 'autogenerated':
              if(empty(param.pattern)) {
                isDisabled = true;
                break paramsLoop;
              }
              break;
            case 'date':
              if(empty(param.dateformat)) {
                isDisabled = true;
                break paramsLoop;
              }
          }
        }
      }

      return isDisabled;
    }
  },

  watch:{
    'projects'(to) {
      this.loadedProjects = Array.isArray(to) ? to.map((project) => {
        return {
          value: window.getId(project._id),
          label: project.name
        };
      }) : [];
    },

    showDefaultCompanySelect(to) {
      if(to) {
        this.document.default_company = this.document.default_company ?? null;
        if(this.companies === null) {
          this.loadCompanies();
        }
      } else {
        delete this.document.default_company;
      }
    }
  }
}
</script>

<style scoped>
@import '../../library/multyselectionLinkStyle.scss';

:deep(.keyword-name) {
  background-color: #e9ecef;
  width: 220px;
  max-width: 220px;
}
:deep(.popper) {
  word-break: normal !important;
}
.keywords {
  padding: 1rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid #dee2e6;
  margin: 0.2rem 0;
  position: relative;
}

.keywords .param > input:first-child, .keywords .param > input:nth-child(2) {
  max-width: 25% !important; width: 25% !important;
}

.keyword .group-param input:first-child {
  width: 29%;
}
.keyword .group-param input:nth-child(2) {
  width: 70%;
}
</style>