<template>
  <div>
    <subnet v-for="(subnet, key) in subnets"
            :key="key"
            :subnet="subnet.subnet"
            :prefix="subnet.prefix"
            :type="subnet.type"
            :lot="lotAllSubnets ?? subnet?.lot"
            :rir="subnet?.rir"
            :current-operations="getOperations(subnet.subnet)"
            :critical-change="subnet.critical_change"
            :transfers="subnet.transfers"
            :transfer-popper="transferPopper"
            :showAdd="showAdd && userCanChange && subnet.subnet && key === subnets.length - 1"
            :show-bids="showBids"
            :index="key"
            :show-label="showLabel"
            :exists-subnets="subnets.map(s => s.subnet)"
            :user-can-change="userCanChange"
            :user-can-set-lot="userCanSetLot"
            :offer="offer"
            :lot-all-subnets="lotAllSubnets"
            :show-auctions="showAuctions"
            @addSubnet="addSubnet"
            @update="update"
            @updateFields="updateFields"
            :withoutAddToAuction="withoutAddToAuction"
            :show-table="showTable"
            ref="subnet" />
    <subnet v-if="userCanChange && needAddSubnet"
            :withoutAddToAuction="withoutAddToAuction"
            :is-not-saved="true"
            :show-auctions="false"
            :show-label="showLabel"
            :show-table="showTable"
            :user-can-change="userCanChange"
            :user-can-set-lot="userCanSetLot"
            :exists-subnets="subnets.map(s => s.subnet)"
            @update="update"
            @close="close"
            ref="add-subnet" />
  </div>
</template>

<script>
import Subnet from "./Subnet";

export default {
  name: "Subnets",
  components: {Subnet},

  emits: ['update', 'updateFields'],

  props: {
    showBids:{
      type:Boolean,
      default:true,
    },
    withoutAddToAuction:{
      type:Boolean,
      default:false,
    },
    showAuctions:{
      type:Boolean,
      default:true,
    },
    showAdd:{
      type:Boolean,
      default:true,
    },
    subnets: {
      type: Array,
      default: []
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    showTable: {
      type: Boolean,
      default: false
    },
    transferPopper: {
      type: Boolean,
      default: false
    },

    lotAllSubnets: {
      default: null,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },

    userCanSetLot: {
      type: Boolean,
      default: false
    },

    offer: {
      type:[Object,null],
      default:null,
    },

    operations: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      showAddSubnet: false,
    }
  },

  computed: {
    needAddSubnet: function() {
      return (this.showAddSubnet ||
              (typeof this.subnets !== 'undefined' && Array.isArray(this.subnets) && this.subnets.length === 0));
    },
  },

  methods: {
    update: function(value, afterSave = null, failSave = null) {
      let subnets = [ ...this.subnets ];

      if(typeof value.index !== 'undefined') {
        let index = value.index;
        delete value.index;

        if(value.subnet === '' || value.subnet === null) {
          subnets.splice(index, 1);
        } else {
          subnets[index] = value;
        }
      } else {
        subnets.push(value);
      }

      subnets = subnets.filter(s => s.subnet !== null);

      let request = {subnets: subnets};

      if(typeof value?.lotAllSubnets === 'boolean') {
          request.setLotAllSubnets = value.lotAllSubnets;
          request.subnets = request.subnets.map(subnet => {
            subnet.lotAllSubnets = undefined;
            return subnet;
          });
      }

      this.$emit('update', request, afterSave, failSave);
    },

    updateFields(fields) {
      this.$emit('updateFields', fields);
    },

    addSubnet: function(openAndFocus = false) {
      this.showAddSubnet = true;
      if(openAndFocus) {
        this.$nextTick(() => this.$refs['add-subnet'].showForm());
      }
    },

    close() {
      this.showAddSubnet = (this.subnets.length === 0);
    },

    getOperations: function(subnet) {
      let result = [];
      let itemFound = null;
      if (
          typeof this.operations === 'object' &&
          Array.isArray(this.operations) &&
          (itemFound = this.operations.find((item) => item.subnet === subnet))
      ) {
        result = itemFound?.operations ?? [];
      }
      return result;
    },
  },
}
</script>

<style scoped>

</style>