<template>
  <div>
    <div id="steps" class="btn-group w-100" :class="{'text-muted': loading}">
      <div class="btn-group" role="group" v-if="userCanChange && stepsBefore.length">
        <button id="steps-before-btn" type="button" class="btn btn-sm btn-outline-secondary" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'disabled': loading.step}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"></path>
          </svg>
        </button>
        <div class="dropdown-menu" aria-labelledby="steps-after-btn">
          <button
              v-for="step in stepsBefore"
              class="dropdown-item"
              :class="{'dropdown-item-danger': isStepFinal(step)}"
              @click="changeStep(step._id)"
          >{{step.label}}</button>
        </div>
      </div>

      <button
          v-if="currentStep"
          type="button"
          class="btn btn-sm cursor-default d-flex"
          :class="buttonClass"
      >
        <string-trim :string="currentStep.label" />
      </button>

      <div class="btn-group" role="group" v-if="userCanChange && stepsAfter.length">
        <button id="steps-after-btn" type="button" class="btn btn-sm btn-outline-secondary" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'disabled': loading.step}">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
          </svg>
        </button>
        <div class="dropdown-menu" aria-labelledby="steps-after-btn">
          <button
              v-for="step in stepsAfter"
              class="dropdown-item"
              :class="{'dropdown-item-danger': isStepFinal(step)}"
              @click="changeStep(step._id)"
          >{{step.label}}</button>
        </div>
      </div>
    </div>
    <div v-if="currentStep?.result?.status === 'fail' && currentStep?.result?.description" class="text-muted text-center">
      <string-trim :string="currentStep?.result?.description"></string-trim>
    </div>
    <teleport to="body">
      <transition name="modal">
        <step-final-modal
            v-if="finalStep"
            :step="finalStep"
            @cancel="finalStepClose"
            @submit="finalStepSubmit"
        ></step-final-modal>
      </transition>
    </teleport>
  </div>
</template>

<script>
import {CrmApi} from "../../../../../library/CrmApi";
import StringTrim from "../../../../string/StringTrim";
import StepFinalModal from "./StepFinalModal";

export default {
  name: "Steps",

  components:{
    'string-trim': StringTrim,
    StepFinalModal
  },

  props:{
    ticketId:{
      required: true,
    },
    product:{
      type: Object,
      required: true
    },
    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  data:function (){
    return {
      loading: false,
      finalStep: null,
    };
  },

  computed:{
    steps: function() {
      let steps = this.product != null && typeof this.product.steps !== 'undefined' ? this.product.steps : null;

      if(
          typeof steps === 'object' &&
          steps != null
      ){
        steps.forEach( (step, i) => {
          step.label = (i+1).toString() + ". " + step.name;
        });
      }

      return steps;
    },

    stepsBefore: function() {
      return (Array.isArray(this.steps) && this.currentStep) ? this.steps.filter(step => step.order < this.currentStep.order).reverse() : []
    },

    stepsAfter: function() {
      return (Array.isArray(this.steps) && this.currentStep) ? this.steps.filter(step => step.order > this.currentStep.order) : []
    },

    currentStep: function() {
      let step = null;
      if(
          Array.isArray(this.steps) &&
          this.steps.length &&
          typeof this.product.current_step !== 'undefined'
      ) {

        step = this.product.current_step;
        if(typeof step._id !== 'undefined'){

          step.order = this.getStepById(step._id) != null ? this.getStepById(step._id).order : 0;
          step.label = this.getStepLabel(step);
        }
      }

      return step;
    },
    buttonClass() {
      let isFinalStep = this.isStepFinal(this.currentStep);
      return {
        'disabled': this.loading,
        'btn-primary': !isFinalStep,
        'btn-danger': isFinalStep && this.currentStep?.result?.status !== 'success',
        'btn-success': isFinalStep && this.currentStep?.result?.status === 'success',
        'active': !(isFinalStep && this.currentStep?.result?.status === 'success'),
      }
    },
    firstFinalStepOrder() {
      let result = null;
      if (Array.isArray(this.steps)) {
        let step = this.steps.find(step => step?.type === 'final');
        if (step && typeof step?.order !== 'undefined' && step?.order !== null) {
          result = step.order;
        }
      }
      return result;
    },
  },
  methods:{
    isStepFinal(step) {
      return step?.type === 'final' ||
          this.firstFinalStepOrder !== null && step?.order && step?.order >= this.firstFinalStepOrder;
    },
    getStepLabel(step) {
      let i = 0;
      let number = null;
      this.steps.forEach( cStep => {
        if( number === null ) {
          i++;
          if ( window.getId(cStep._id) == window.getId(step._id)) {
            number = i;
          }
        }
      })

      return (number != null ? number + ". " : "") + step.name;
    },

    changeStep: function(stepId) {
      let step = this.getStepById(stepId);
      if (this.isStepFinal(step) && !this.isStepFinal(this.currentStep)) {
        this.finalStep = step;
        this.$store.commit('setActiveWindow', 'finalStep');
      } else {
        $('#steps .dropdown-menu').removeClass('show');
        this.loading = true;

        CrmApi.updateThreadParams(window.getId(this.ticketId), {
          current_step: window.getId(stepId)
        }).then(() => {
          this.$store.commit('thread/reloadLastMessage');
          this.product.current_step = this.getStepById(stepId);
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    getStepById(id) {
      let step = this.steps.filter(s => window.getId(s._id) === window.getId(id));
      return step.length ? step[0] : null;
    },

    finalStepClose() {
      this.finalStep = null;
      this.$store.commit('removeActiveWindow', 'finalStep');
    },

    finalStepSubmit({stepId, result}) {
      this.finalStepClose();
      $('#steps .dropdown-menu').removeClass('show');
      this.loading = true;

      CrmApi.updateThreadParams(window.getId(this.ticketId), {
        current_step: window.getId(stepId),
        result: result,
      }).then(() => {
        this.$store.commit('thread/reloadLastMessage');
        this.product.current_step = this.getStepById(stepId);
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.cursor-default {
  cursor: default;
}
.dropdown-menu {
  position: fixed!important;
  z-index: 1060;
}
.dropdown-item-danger {
  background: #f8d7da;
  color: #842029;
}
</style>