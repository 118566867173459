<template>
<div>
  <Loading loader="dots" v-model:active="loading" />
  <table class="table table-striped table-hover">
    <thead>
    <tr>
      <th style="border: 0"></th>
      <td colspan="3" class="ldap-text-td">
        <div class="text-muted ldap-text text-center">Данные из LDAP актуальны по последней авторизации юзера в CRM</div>
      </td>
      <th style="border: 0"></th>
      <th style="border: 0"></th>
    </tr>
      <tr>
        <th width="20%">Пользователь</th>
        <th width="15%">Роль</th>
        <th width="20%">ФИО</th>
        <th width="15%">Добавочный номер</th>
        <th width="20%" >Проекты</th>
        <th width="10%"></th>
      </tr>
    </thead>
    <tbody>
      <user-row v-for="user in users" :editUrl="editurl" :user="user" :key="user._id" @load="load"></user-row>
    </tbody>
  </table>
  <div class="text-end" v-if="showChangeWithout">
    <a @click="changeWithout" class="text-primary cursor-pointer">{{changeWithoutText}}</a>
  </div>
</div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import UserRow from "./UserRow";
import {User} from "../../library/api/User";

export default {
  name: "UserList",

  components: {
    'user-row': UserRow
  },

  props: {
    editurl: {
      require: true
    },
  },

  data: function() {
    return {
      withoutDisabled: true,
      loading: false,
      users: [],
    }
  },

  computed: {
    changeWithoutText() {
      return this.withoutDisabled ? 'Показать выключенных' : 'Скрыть выключенных';
    },
    showChangeWithout() {
      let result = true;
      if (typeof window.isProduction !== "undefined" && window.isProduction === true) {
        result = false;
      }
      return result;
    },
  },

  created: function() {
    this.load();
  },

  methods: {
    changeWithout() {
      this.withoutDisabled = !this.withoutDisabled;
      this.load();
    },

    load: function() {
      this.loading = true;
      User.getExtendedList({withoutStatus: this.withoutDisabled ? "disabled" : null } ).then(response => {
        this.users = response.data;
      }).finally(()=>{
        this.loading = false;
      });
    }
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>