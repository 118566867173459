<template>
  <div class="subnet-row" :class="{ 'loading-price':loading}">
    <div class="subnet-line">
      <input @keydown.enter.ctrl.prevent.stop="$emit('enterCtrl')" ref="subnet-input" :class="{'is-invalid':!isValid, 'loading-price':loading}" :disabled="disabled" @keyup.enter.stop="{}" type="text" class="form-control form-control-sm" v-model="subnet" placeholder="Введите адрес сети"  />
      <div v-if="!isValid" class="invalid">
       {{errorMessage}}
      </div>
    </div>
    <div class="type-line" style=" margin-left: 5px;">
      <select @keydown.enter.ctrl.prevent.stop="$emit('enterCtrl')" :class="{'is-invalid':isEmptyType}" :disabled="disabled || getLoadingNetStatus?.[index]" style="text-align:center"
              @change="isEmptyType =false" name="nets" id="nets" class="form-control form-control-sm" v-model="type">
        <option  disabled value="" >Тип</option>
        <option value="PA" >PA</option>
        <option value="PI">PI</option>
        <option value="ASN">ASN</option>
      </select>
    </div>
  </div>
</template>

<script>
import {IPv4Net} from "../../../library/IPv4Net";
import {getNet} from "../Modal/priceCalculate";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "Subnet",
  props:{
    loading:{
      type:Boolean
    },
    subnetProps:{
      type:String,
      default: null,
    },
    nettype:{
      type:String,
      default: null,
    },

    index:{

    },

    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits:['addNew' , 'interface' , 'enterCtrl'],
  data:function (){
    return {
      isEmptyType:false,
      isValid:true,
      errorMessage:"",
    }
  },
  setup(props, context){
    let dep = {}
    let {type, subnet} = getNet(props, context, dep);
    return {type, subnet };
  },
  computed:{
    ...mapGetters({
      'getLoadingNetStatus':'offerHistory/addEdit/getLoadingNetStatus',
      'historyObject':'offerHistory/addEdit/getHistoryObject',

    }),
  },
  mounted() {
      if(this.subnet !== '' && !this.type ){
       // this.isEmptyType =true;
      }

      this.$emit('addNew', {value:this.subnet, type:this.type,  key:this.index })
      this.initInterface();
  },
  methods:{
    ...mapMutations({

    }),
    initInterface:function (){
      this.$emit('interface', {
        'validate':()=> this.validateNet(),
      } )
    },
    validateNet:function (){
      let isValid = true;
      let isAllEmpty = true;
      for(let el of this.historyObject.subnets){
        if(el.subnet.trim() != ''){
          isAllEmpty = false;
        }
      }
      if(isAllEmpty){
        isValid = false;
      }
      this.isValid = isValid;
      if(isValid && this.subnet.trim() !== ''){
        if(this.subnet.indexOf('-') === -1 && this.subnet.indexOf("/") === -1){
          isValid = false;
          this.errorMessage = 'Неверный формат сети'
        }else {
          let ip = new IPv4Net(this.subnet);

          if(!ip.isIp() ){
            isValid = false;
            this.errorMessage = 'Неверный формат сети'
          }
        }

        this.isValid = isValid;
        if(!this.type){
          this.isEmptyType = true;
          isValid = false;
        }
      }
      return isValid;
    },

  },
  watch:{
    subnet(value){
      if(!this.isValid){
        this.isValid = true;
        this.isEmptyType = false;
      }

      this.$emit('addNew', {value:value, type:this.type,  key:this.index })
      /*
      if(this.index !== 0){
        let subnetsHashMaps ={};
        for (let key in this.historyObject.subnets){
          let el = this.historyObject.subnets[key];
          let ip = new IPv4Net(el.subnet);
          if(ip.isIp()){
            if(typeof subnetsHashMaps[ip.asRange()] === 'undefined'){
              subnetsHashMaps[ip.asRange()] = 1;
            }else {
              if(key != 0 && key == this.index){
                this.isValid = false;
                this.errorMessage = "Сеть принадлежит другому LIR и нужно создать отдельную сделку"
              }
            }
          }

        }
      }
      */

    },
    nettype: {
      handler(val) {
        this.type = val;
      }
    },
    subnetProps:{
      handler(val) {
        this.subnet = val;
      }
    }
  }
}
</script>

<style scoped>
.invalid{
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}


</style>