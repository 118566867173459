<template>
  <event-block :event-id="eventId" :date="date" :title="{left: 'Изменение профиля клиента в аукционе'}">
    <template #body>
      <div v-if="Object.keys(companyParams).length > 0">
        <div v-for="param in companyParams" :class="{'text-muted opacity-50': paramMuted(param)}">
          <span class="fw-bold">{{param.label}}:</span>
          <span class="ps-1">{{param.value}}</span>
        </div>
      </div>
      <div   class="mb-2" >
        <div>
          <span class="fw-bold pe-1 "  :class="{'text-muted opacity-50':!isHasChange(addressParams.legal)}" >{{companyFields['address.legal']}}:</span>
          <span v-for="(param, index) in addressParams.legal"  :class="{'text-muted opacity-50': paramMuted(param)}" >
             <span class="pe-1">{{param.value}}<span v-if="index !=  addressParams.real.length-1">,</span></span>
        </span>
        </div>
        <div>
          <span class="fw-bold pe-1 " :class="{'text-muted opacity-50':!isHasChange(addressParams.real)}" >{{companyFields['address.real']}}:</span>
          <span v-for="(param, index)  in addressParams.real"  :class="{'text-muted opacity-50': paramMuted(param)}" >
             <span class="pe-1">{{param.value}}<span v-if="index !=  addressParams.real.length-1">,</span></span>
        </span>
        </div>
      </div>

      <div v-if="params.company?.documents">
        <span>Документы:</span>
        <span class="ps-1">
          <span v-if="showDocumentsList">

            <span v-if="params.company.documents"
                  v-for="document in params.company.documents"
                  class="document-item" :class="{'text-muted opacity-50': documentMuted(document)}">
              <a :href="documentLink(document)" target="_blank">{{document.filename}}</a>
            </span>

            <span v-if="deletedDocuments"
                  v-for="document in deletedDocuments"
                  class="document-item">
              <s>{{document.filename}}</s>
            </span>

          </span>
          <span v-else>—</span>
        </span>
      </div>

      <div class="double-buttons-group mt-3" v-if="!['approved', 'declined'].includes(this.params.status ?? null)">
        <div>
          <button @click="showDeclineModal = true" class="btn btn-danger col-6" :class="{disabled: loading}">Отклонить</button>
        </div>
        <div>
          <button @click="approve" class="btn btn-primary col-6" :class="{disabled: loading}">Принять</button>
        </div>
      </div>
      <div v-else-if="typeof this?.user === 'string'" class="mt-2 fw-bold">
        <span v-if="this.params.status === 'approved'" class="text-success">Изменения принял(а): {{user}} ({{handleDate}})</span>
        <span v-else-if="this.params.status === 'declined'" class="text-danger">Изменения отклонил(а): {{user}} ({{handleDate}}) – {{params.error_text}}</span>
      </div>
    </template>
  </event-block>

  <teleport to="body" v-if="showDeclineModal">
    <transition name="modal">
      <modal-window
          @close="closeDeclineModal"
          @success="decline"
          modal-dialog-class="modal-md"
          custom-modal-body="marginNull"
      >
        <template v-slot:header>
          <h5 class="modal-title">Отклонение изменений профиля клиента</h5>
        </template>
        <template v-slot:body>
          <div>
            <label for="decline_error_text" class="form-label">Причина отклонения</label>
            <input v-model="declineErrorText" type="text" class="form-control" id="decline_error_text" required :disabled="loading">
            <div class="invalid-feedback d-block small">
              Причина будет отправлена клиенту в уведомлении в неизменном виде
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" :disabled="loading" @click="closeDeclineModal">Отмена</button>
          <button type="button" class="btn btn-primary" :disabled="disabledDecline" @click="decline">Отправить</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import EventBlock from "../EventBlock.vue";
import parseEvent from "../EventParser";
import {CrmApi} from "../../../../library/CrmApi";
import StringTrim from "../../../string/StringTrim.vue";
import modalWindow from "../../../tiketMenu/components/ModalWindow.vue";
import moment from "moment";

export default {
  name: "ApproveCompanyChanges",

  components: {modalWindow, StringTrim, EventBlock},

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  data() {
    return {
      loading: false,
      showDeclineModal: false,
      declineErrorText: null,

      companyFields: {
        lang: 'Язык',
        legaltype: 'Тип организации',
        name: 'Название компании',
        vatnum: 'ИНН',
        fullname: 'Полное название организации',
        ogrn: 'ОГРН',
        kpp: 'КПП',
        'signer.name': 'ФИО Подписанта',
        'signer.position': 'Должность подписанта',
        'signer.documenttype': 'Документ, подтверждающий полномочия',
        'address.legal': 'Юридический адрес',
        'address.real': 'Почтовый адрес',
        'bank.name': 'Название банка',
        'bank.bik': 'БИК',
        'bank.account': 'Расчетный счет',
        'bank.coraccount': 'Корреспондентский счет',
      },
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    disabledDecline() {
      return this.loading ||
             (typeof this.declineErrorText !== 'string' || this.declineErrorText.length === 0);
    },

    showDocumentsList() {
      return [
        this.params?.company.documents,
        this.params?.deletedDocuments
      ].some(docs => Array.isArray(docs) && docs.length > 0);
    },

    addressParams(){
      let params = {
        'address':this.params.company.address,
      }
      params = this.toDotNotation(params);
      let result = {
        'real':[],
        'legal':[],
      };
      ['real', 'legal'].forEach(adrType => {

        for (let key in params){
          if(key.indexOf(adrType) !== -1){
            let el = params[key]
            result[adrType].push(
                {
                  key:key,
                  value:el,
                }
            )
          }
        }
      });
      return result
    },

    companyParams() {
      let result = [];
      let params = { ...this.params.company };
      params.documents = undefined;

      /*
      ['real', 'legal'].forEach(adrType => {
        if(
            typeof params?.address?.[adrType] === 'object' &&
            params.address[adrType] !== null
        ) {
          params.address[adrType] = Object.values(params.address[adrType]).join(', ');
        }
      });
       */

      params = this.toDotNotation(params);

      Object.keys(params)
            .forEach(key => {
              if(key !== 'country' && this.companyFields?.[key]) {
                result.push({
                  key: key,
                  label: this.companyFields[key] ?? key,
                  value: params[key]
                });
              }
            });
      return result;
    },

    deletedDocuments() {
      return (this.params?.deletedDocuments ?? []).concat(
          this.params?.status
              ? []
              : (this.person?.company?.documents ?? []).filter(
                  doc => typeof doc?.externalid !== 'undefined' &&
                         (doc?.deleted !== true) &&
                         !(this.params?.company?.documents ?? []).concat(this.params?.deletedDocuments ?? [])
                              .find(pDoc => pDoc.externalid === doc.externalid)
          )
      )
    },

    api() {
      return this.$store.getters['thread/getApi'];
    },

    person() {
      return this.$store.getters['thread/getPerson'];
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    },

    handleDate() {
      return this.params?.handleDate ? moment(this.params.handleDate).format(window.dateFormatWeekDaySeconds) : '';
    }
  },

  methods: {
    isHasChange(object){
      let isHasChange = false;
      for (let el of object){
        if(!this.paramMuted(el)){
          isHasChange = true;
          break;
        }
      }
      return isHasChange;
    },
    closeDeclineModal() {
      this.declineErrorText = null;
      this.showDeclineModal = false;
    },

    paramMuted(param) {
      let muted = false;

      if(typeof this.params?.diff === 'object' && this.params.diff !== null) {
        if(typeof this.params.diff?.[param.key] === 'undefined') {
          if(!this.diffExists(param.key)) {
            muted = true;
          }
        }
      } else if(!this.params?.status && this.isSameValue(param)) {
        muted = true
      }

      return muted;
    },

    diffExists(key) {
      return typeof this.params.diff?.[key] !== 'undefined';
    },

    documentMuted(document) {
      let muted = false;

      if(typeof this.params?.diff === 'object' && this.params.diff !== null) {
        let diffDocumentIds = _.filter(this.params.diff, (value, key) => {
          return key.match(/documents\..*\.id/);
        });
        if( !diffDocumentIds.includes(document?.id) ) {
          muted = true;
        }
      } else if(!this.params?.status && this.documentExists(document)) {
        muted = true;
      }

      return muted;
    },

    isSameValue(param) {
      let value = param.value;
      let currentValue = _.get( (this.person?.company ?? {}), param.key );

      if(
          ['address.real', 'address.legal'].includes(param.key) &&
          (typeof currentValue === 'object' && currentValue !== null)
      ) {
        currentValue = Object.values(currentValue);
        currentValue = currentValue.join(', ');
      }

      return value === currentValue;
    },

    decline() {
      this.loading = true;

      this.api.declineCompanyChanges(this.eventId, this.declineErrorText)
          .then(response => {
            this.setOverEvent(response.data);
            this.closeDeclineModal();
          })
          .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
          .finally(() => this.loading = false);
    },

    approve() {
      this.loading = true;

      this.api.approveCompanyChanges(this.eventId)
          .then(response => this.setOverEvent(response.data))
          .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
          .finally(() => this.loading = false);
    },

    setOverEvent(data) {
      let event = parseEvent(data.event);
      Object.assign(this, event);
    },

    toDotNotation(obj, current, result = {}) {
      for(let key in obj) {
        let value = obj[key];
        let newKey = (current ? `${current}.${key}` : key);
        if(value && typeof value === 'object') {
          this.toDotNotation(value, newKey, result);
        } else if(typeof value !== 'undefined') {
          result[newKey] = value;
        }
      }

      return result;
    },

    documentLink(document) {
      let link = null;

      if(typeof document.id !== 'undefined') {
        link = `/message/${this.eventId}/document/${window.getId(document.id)}`;
      } else {
        let id = (this.person.company?.documents ?? [])
                                    .find(doc => doc.externalid === document.externalid)?.id ?? null;
        if(id !== null) {
          link = `/tickets/${this.ticket._id}/person/document/${window.getId(id)}/`;
        }
      }

      return link;
    },

    documentExists(document) {
      return (this.person?.company?.documents ?? []).find(doc => doc.externalid === document.externalid);
    }
  }
}
</script>

<style scoped>
:deep(.marginNull){
  margin: 0px!important;
}
.document-item:not(:last-child) {
  padding-right: 5px;
}
.document-item:not(:last-child):after {
  content: ',';
  color: black;
  opacity: 1;
}
</style>