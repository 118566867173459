<template>
  <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
    <span style="padding-bottom: 1px">{{ value }}</span>
    <template #content>
      <span  v-html="getMarkdown()" class=" max-width" ref="html-message"></span>
    </template>
  </popper>
</template>

<script>
import {getMarkdown} from "../Api/api";

export default {
  name: "PopperComment",
  props:{
    'value':{
      type:String,
      require:true,
    },
    'markdown':{
      type:String,
      require: true,
    }
  },
  data:function (){
    return{
      loadImages:false,
    }
  },
  methods:{
    getMarkdown:function (){
      return getMarkdown(this.markdown , this.loadImages);
    }
  },
}
</script>

<style scoped>
.popper-comment{
  z-index: 999;
  padding: 10px;
}

.light {
  display: block;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 5px -6px rgba(0, 0, 0, 0.25);
}
</style>