<template>
  <div class="small ps-2 pt-2">
    Заголовки изменены:
    <span class="badge bg-secondary me-1 mb-1" style="font-weight:normal" v-for="change in changes">
    <b>{{getFieldName(change.field)}}:</b> {{change.value}}
    <popper :content="change.popper" class="light" placement="top" :hover="true" :arrow="true" :interactive="false">
      <i class="fa fa-window-close pe-0 ps-1 header-remove-btn" @click="removeChange(change.name ?? change.field)"></i>
    </popper>
  </span>
  </div>
</template>

<script>
export default {
  name: "Changes",

  emits: ['remove'],

  props: {
    changes: {
      type: Array,
      require: true,
    }
  },

  methods: {
    getFieldName(fieldName) {
      switch(fieldName.toLowerCase()) {
        case 'to':
          fieldName = 'Кому';
          break;
        case 'from':
          fieldName = 'От';
          break;
        case 'subject':
          fieldName = 'Тема';
          break;
      }

      return fieldName;
    },

    removeChange: function(fieldName) { this.$emit('remove', fieldName); }
  }

}
</script>

<style scoped>
.header-remove-btn {
  cursor: pointer;
  color: #f8f9fa;
  opacity: .9;
}
.header-remove-btn:hover {
  color: white;
  opacity: 1;
}
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>