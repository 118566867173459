<template>
  <div class="tags">
    <span class="additional-info" v-for="tag in tags">
      <popper v-if="isSpam(tag)" class="light popper-width" style="width: max-content;" :disabled="!spamPopperContent" placement="top" :hover="true" :interactive="false" :arrow="true">
        <a href="" class="spam" @click="searchTag(tag)">{{tag}}</a>
        <template #content>
          <div style="width: max-content;">{{spamPopperContent}}</div>
        </template>
      </popper>
      <a v-else href="" @click="searchTag(tag)">{{tag}}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: "Tags",
  props:{
    'tags':{
      type:Array,
      require:true,
    },
    autodelete_at: {
      default: null,
    }
  },

  computed: {
    spamPopperContent() {
      return this.autodelete_at !== null ? 'Тикет удалится ' + moment(this.autodelete_at).fromNow() : false;
    }
  },

  methods:{
    searchTag:function (tag){
      this.$store.getters['tickets/filters/getSearchSettingsToShow']
                 .forEach(item => item.value = false);
      this.$store.commit('tickets/filters/setSearchString', tag)
      this.$store.dispatch('tickets/filters/searchStringSearch', tag)
      this.$store.commit('tickets/filters/updateSettingShow', true);
    },

    isSpam(tag) {
      return tag.match(/спам/i);
    },
  },
}
</script>

<style scoped>
.tags{
  list-style:none;
  display: contents;
  padding-left: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.tags span, .tags a{
  float:left;
  height:12px;
  line-height:12px;
  position:relative;

}
.tags {
  --tag-color: #0089e0;
}
.tags a{
  margin-left:10px;
  padding:0 3px 0 3px;
  background: var(--tag-color);
  color:#fff;
  text-decoration:none;
  -moz-border-radius-bottomright:4px;
  -webkit-border-bottom-right-radius:4px;
  border-bottom-right-radius:4px;
  -moz-border-radius-topright:4px;
  -webkit-border-top-right-radius:4px;
  border-top-right-radius:4px;
}
.tags a:before{
  content:"";
  float:left;
  position:absolute;
  top:0;
  left:-8px;
  width:0;
  height:0;
  border-color:transparent var(--tag-color) transparent transparent;
  border-style:solid;
  border-width:6px 8px 6px 0;
}
.tags a:after{
  content:"";
  position:absolute;
  top:5px;
  left:-3px;
  float:left;
  width:3px;
  height:3px;
  -moz-border-radius:2px;
  -webkit-border-radius:2px;
  border-radius:10px;
  background:#fff;
  -moz-box-shadow:-1px -1px 2px var(--tag_color);
  -webkit-box-shadow:-1px -1px 2px var(--tag_color);
  box-shadow:-1px -1px 2px var(--tag_color);
}
.tags a:hover{background:#555;}

.tags a:hover:before{border-color:transparent #555 transparent transparent;}

.spam {
  --tag-color: red;
}
</style>