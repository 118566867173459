<template>
<div>
  <div class="form-row">
    <label for="select_project" class="form-label" style="font-weight: 600;">Проект</label>
    <select  v-model="selectProjectName" @change="changeProject()" id="select_project" class="form-select" aria-label="nothing">
      <option disabled :value="''">Выберите проект</option>
      <option  v-for="project in projects" :value="project.name">
        {{ project.name }}
      </option>
    </select>
  </div>
  <a v-show="selectProjectName != ''" class="link" @click="showTickets(!showSearch)">Перенести в тикет</a>

  <search-ticket v-show="showSearch"
                 ref="selT"
                 @selectElement="setTicketId($event)"
                 :project="selectProjectName"
                 style="margin-top: 20px" />
</div>
</template>

<script>

import {CrmApi} from "../../../../library/CrmApi";
import {mapGetters, mapMutations} from "vuex";
import SearchTicket from "./SearchTicket";
export default {
  name: "Body",
  components: {SearchTicket},
  data: function (){
    return {
      showSearch:false,
      selectProjectName:"",
      ticketId:"",
      resetSelection:false
    }
  },
  emits:[
    'changeValues'
  ],
  methods:{
    changeProject(){
      this.$refs.selT.reset();
      this.changeValues()
    },
    showTickets(value){
      this.showSearch = value;
      if(!value){
        this.$refs.selT.reset();
        this.ticketId='' ;
      }
    },
    changeValues(){
      let o =  {ticketId:this.ticketId , projectId:this.getProjectId()};

      this.$emit("changeValues", o)
    },
    getProjectId(){
      let breakObj = {};
      let projectId= "";
      try {
        for(let project of this.projects){

          if(project.name == this.selectProjectName){
            projectId = project._id;
            throw breakObj;
          }
        }
      }catch (nothing) {}
      return projectId;
    },
    setTicketId(id){
      this.ticketId = id;
      this.changeValues();
    },
    ...mapMutations({
      'extendedErrorPush':'extendedErrorPush',
    }),
  },
  computed:{
    ticket() {
      return this.$store.getters['thread/getTicket'];
    },
    ...mapGetters({
      "projects": "getProjects",
    }),
  },
  mounted() {
    if(typeof this.ticket?.project?.name === 'string') {
      this.selectProjectName = this.ticket.project.name;
      this.changeProject();
    }
  }
}
</script>

<style scoped>
.link{
  color: #0d6efd;
  text-decoration: underline;
  cursor: pointer;
}
</style>