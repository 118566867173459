const mutations = {

    setType(state, value){
      state.type = value;
    },

    updateSettingShow(state , value){
        state.isSettingShow = value;
    },
    setSidebarFilters(state, filters) {
        state.sidebar.filters = filters;
    },
    updateAlias(state, alias){
        state.alias = alias;
    },
    updateProduct(state, product) {
        state.product = product;
    },

    setOrderBy(state, orderBy) {
        state.orderBy = orderBy;
    },

    clearSearch(state){
        state.search.searchString = '';
        state.search.showFilter = false;
    },

    setSearchString(state , text){
        state.search.searchString = text;
    },

    setShowFilter(state , needShow){
        state.search.showFilter = needShow;
    },

    setProducts(state, products) {
        state.search.products = products;
    },

    setUsers(state, users) {
        state.search.users = users;
    },

    setUsersRequested(state, value) {
        state.usersRequested = value;
    },

    updateUser(state, user) {
        state.user = user;
    },

    setTicketStatus(state, value) {
        state.ticketStatus = value;
    },

    setSearchProject: (state, value) => {
        state.searchProject = value;
    },

    setAliasSet(state, value) {
        state.aliasSet = value;
    },

    resetAliasSet(state) {
        state.aliasSet = false;
    },

    setCallType(state, value){
        state.callType = value;
    },

    setCallStatus(state, value){
        state.callStatus = value;
    }
}

export default mutations;