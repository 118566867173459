import {CrmApi} from "../../../library/CrmApi";
import {Email} from "../../../library/Email";
import {UserPermission} from "../../../users/UserPermission";
import {Messages} from "../../../library/api/Messages";



export const setLotSubnets = ({commit, dispatch, state, getters}, {setLotAllSubnets, date})=>{
    return new Promise((resolve, reject) => {
        commit('setLoading', true);
        let values = {
            'setLotAllSubnets':setLotAllSubnets,
            date: date
        };
        CrmApi.updateThreadParams(window.getId(getters.getTicket._id), {product_fields: values}).then((result)=> {
            commit('setTicketFields' , result.data.fields);
            resolve(result);
        }).catch((error)=>{

                if (typeof error?.response?.data?.html !== 'undefined' && error.response.data.html === true) {
                    commit('htmlErrorPush', error.response.data.message, {root:true});
                } else {
                    commit('errorPush', CrmApi.getErrorMessage(error),  {root:true});
                }
                reject(error)
            }).finally(()=>{
            commit('setLoading', false)
        })
    });

}

export const additionWithTimeOutExample = ({commit} ,count ) => {
    setTimeout(()=>{
        commit('additionExampleCount', count)
    }, 5000);
}

export const removeSelectedTemplate = ({commit, state , getters})=>{
    commit('removeSelectedTemplate');
    commit('closeMovingModule' ,{}, {root:true});
    commit('clearMovingModuleContent' ,{}, {root:true});
}

export const closeThread = ({commit, dispatch, state, getters}) => {
    commit('setIsDirectLink', false);
    commit('setTicket', null);
    commit('setTicketType', null);
    commit('setPerson', null);
    dispatch('removeSelectedTemplate');
}
export const takeWindowsSizeCallback = ({commit, state}) => {
    let callback = state.ticketChangeWindowSizeCallback;
    commit("setTicketChangeWindowSizeCallback", null);

    return callback;
}

export const reloadPerson = ({commit, getters}) => {
    CrmApi.getThreadPerson( window.getId(getters.getTicket._id), true )
          .then((result) => {
            commit('setPerson', result.data.person);
          });
}

export const checkUpdatePersonPermission = ({commit}) => {
    commit('setUserUpdatePersonPermission', false);
    UserPermission.can('system.update_person').then(() => commit('setUserUpdatePersonPermission', true));
}

export const getAliases = ({commit, state, getters}) => {
   return new Promise(((resolve, reject) => {
       if (typeof getters?.getTicket?._id !== 'undefined') {
           CrmApi.call('/thread/' + encodeURIComponent(window.getId(getters.getTicket._id)) + "/aliases/")
               .then((response) => {
                   commit('setProjectAliases', response.data.project);
                   commit('setTicketAliases', response.data.ticket)
                   resolve(response);
               }).catch((error) => {
               reject(error)
           });
       } else {
           reject('There is no ticket in the store');
       }
   }));
}

export const toggleSignature = ({commit, state, getters, dispatch}, {ticketId, alias = null}) => {
    let isShown = getters.showSignature;
    if (isShown) {
        commit('hideSignature');
    } else {
        let obj = {
            ticketId: ticketId,
            alias: alias,
        };
        if (typeof getters.getEditMessage?.message?._id !== 'undefined' && getters.getEditMessage?.message?._id) {
            obj.message = getters.getEditMessage?.message?._id;
        }
        dispatch('loadSignature', obj);
    }
}

export const loadSignature = ({commit, state, getters}, {ticketId, message = null, alias = null, show = true}) => {
    if (getters.getSignature(ticketId, alias)) {
        if(show) {
            commit('showSignature');
        }
    } else {
        commit('setLoadingSignature', true);
        getters['getApi'].getSignature(message, alias).then(result => {
            if (typeof result?.data?.signature !== 'undefined' && result.data?.signature !== null) {
                commit('setSignature', {
                    _id: ticketId,
                    alias: alias,
                    text: result.data.signature,
                });
                if (show) {
                    commit('showSignature');
                }
            }
        }).catch((error) => {
            commit('errorPush', CrmApi.getErrorMessage(error), true);
        }).finally(() => {
            commit('setLoadingSignature', false);
        });
    }
}

export const updateDraft = ({commit, state, getters}, {draft}) => {
    getters['getApi'].updateDraft(draft).then(result => {
    }).catch((error) => {
    });
}

export const loadTicketInfo = ({commit, state, getters}) => {
    getters['getApi'].getInfo().then((result) => {
        if (typeof result.data?.person !== 'undefined' && result.data?.person) {
            commit('setPerson', result.data?.person);
        }
        if (typeof result.data?.products !== 'undefined' && result.data?.products) {
            commit('tickets/product/setProducts', result.data.products, {root:true});
        }
        if (typeof result.data?.tasks !== 'undefined' && result.data?.tasks) {
            commit('tasks/setTasks', result.data.tasks);
        }
        if (typeof result.data?.doctemplates?.data !== 'undefined' && result.data?.doctemplates?.data) {
            commit('setDocuments', result.data.doctemplates.data);
        }
        if (typeof result.data?.pinned !== 'undefined' && result.data?.pinned) {
            commit('setPinnedMessages', result.data.pinned);
        }
    }).catch((error) => {
        if(!axios.isCancel(error)) {
            commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
        }
    })
}

export const redirectToTickets = ({rootGetters}) => {
    let params = rootGetters['leftMenu/getCurrentRouteParams'];
    let obj;
    let getPaginate = rootGetters['tickets/getPaginate'];
    if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'unsorted') {
        obj = {
            name: 'Unsorted',
            params: {},
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'trash') {
        obj = {
            name: 'Trash',
            params: {},
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'competitor') {
        obj = {
            name: 'Competitor',
            params: {},
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'recent') {
        obj = {
            name: 'Recent',
            params: {},
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'calls') {
        obj = {
            name: 'Calls',
            params: params,
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'billmanager') {
        obj = {
            name: 'Billmanager',
            params: params,
        }
    } else if (typeof params.filter === "string"
        && params.filter.toLowerCase() === 'tasks') {
        obj = {
            name: 'Tasks',
            params: params,
        }
    } else {
        obj = {
            name: 'TicketsProjectFilter',
            params: params,
        }
    }
    obj.query = rootGetters['tickets/filters/getFiltersQueryWithDifferentSearchAliasSubstitution'];

    if (typeof getPaginate?.page !== 'undefined') {
        obj.query.page = getPaginate.page;
    }
    if (typeof getPaginate?.onpage !== 'undefined') {
        obj.query.onpage = getPaginate.onpage;
    }
    window.router.push(obj);
}

export const setGreetingData = ({commit, state, getters}, data) => {
    if (typeof data === 'object' && Array.isArray(data) && data.length > 0) {
        let now = new Date();
        now.setHours(0);
        now.setMinutes(0);
        now.setSeconds(0);
        const fields = {
            "projects": {},
            "original": [...data],
            "name": data[0]?.substituteName ?? '',
            "current": {
                "name": data[0]?.substituteName ?? '',
            },
            "currentTemplate": '',
            "currentValue": '',
            "counter": getters['getGreeting']?.counter ? getters['getGreeting'].counter + 1 : 1,
        };
        for (const item of data) {
            let setGreeting = true;
            if (typeof item?.last_answer !== 'undefined' && item?.last_answer) {
                if (new Date(item?.last_answer) > now) {
                    setGreeting = false;
                }
            }
            let newData = {
                "date": item?.last_answer ?? null,
                "project_lang": item?.project_lang ?? null,
                "canUse": setGreeting,
            };
            if (item.type === 'greeting') {
                newData.ru = item?.body;
            }
            if (item.type === 'greeting_en') {
                newData.en = item?.body;
            }

            fields.projects[item.project] = Object.assign({}, fields.projects[item.project] ?? {}, newData);
        }
        commit("setGreeting", fields);
    } else {
        commit('resetGreeting');
    }
}

export const processGreeting = ({commit, getters, rootGetters}, {from, to, lang, ticket_lang, init = false}) => {
    let data = getters['getGreeting'].current;
    if (typeof from !== 'undefined' && from) {
        let project = rootGetters['tickets/projects/getProjects'].find((project) => {
            return typeof project?.emails === 'object' && Array.isArray(project.emails) && project.emails.includes(from);
        });
        data.project = project?._id ?? null; // window.getId ?
    }
    if (typeof to !== 'undefined' && to) {
        if (!init || !data?.name) {
            data.name = Email.getFirstNameFromFullString(Array.isArray(to)? to.join(', ') : to);
        }
    }
    if (typeof ticket_lang !== 'undefined' && ticket_lang) {
        data.ticket_lang = ticket_lang;
    }
    if (typeof lang !== 'undefined' && lang) {
        data.lang = lang;
    }
    commit('setCurrentGreetingSettings', data);
    let template = '';
    if (data.project) {
        let projectData = getters['getGreeting'].projects[data.project] ?? null;
        let language = data.ticket_lang ?? projectData?.project_lang ?? null;
        if (projectData && projectData.canUse && typeof language === 'string') {
            template = projectData[language.toLowerCase()] ?? '';
        }
    }
    let value = template;
    if (template.indexOf('{name}') !== -1) {
        value = data?.name && data.name !== '' ?
            value.replace('{name}', data.name) :
            value.replace(/\s?\{name\}\s?/, '');
    }
    commit('setCurrentGreeting', {"currentTemplate": template, "currentValue": value});
}

export const loadTicketTemplates = ({commit, getters, dispatch}) => {
    if (getters['isAnsweringTicket']) {
        let types = ['greeting', 'greeting_en'];
        commit('setLoadingTemplates', true);
        getters['getApi'].getTicketTemplates().then((result) => {
            let templates = result.data;

            let greeting = templates.filter(tpl => types.includes(tpl.type));
            if (greeting.length) {
                dispatch('setGreetingData', greeting);
                templates = templates.filter(tpl => !types.includes(tpl.type));
            }
            let orderTemplates = [];

            for (let order of getters.getGroupOrder) {
                for (let template of templates) {
                    if (template.type === order) {
                        orderTemplates.push(template);
                    }
                }
            }
            commit('setSmartTemplates', orderTemplates);
        }).catch((error) => {
            if (!axios.isCancel(error)) {
                commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
            }
        }).finally(() => {
            commit('setLoadingTemplates', false);
        });
    }
}

export const pinMessage = ({commit, getters, dispatch}, message) => {
    return Messages.pinMessage(window.getId(message._id)).then((result) => {
        if (result.data?.success === true) {
            commit('addToPinnedMessages', message);
        }
        return result.data?.success ?? false;
    }).catch((error) => {
        if (!axios.isCancel(error)) {
            commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
        }
    });
}

export const unpinMessage = ({commit, getters, dispatch}, message) => {
    return Messages.unpinMessage(window.getId(message._id)).then((result) => {
        if (result.data?.success === true) {
            commit('removeFromPinnedMessages', message);
        }
        return result.data?.success ?? false;
    }).catch((error) => {
        if (!axios.isCancel(error)) {
            commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
        }
    });
}