import {createRouter,
    createWebHistory} from "vue-router";

import Ticket from "../components/ticket/Ticket";
import Notification from "../components/ticket/Notification";
import TicketList from "../components/ticket/TicketList";
import Menu from "../components/tiketMenu/Menu";
import TicketMenu from "../components/tiketMenu/TicketMenu";
import UnsortedMenu from "../components/tiketMenu/UnsortedMenu";
import CompetitorMenu from "../components/tiketMenu/CompetitorMenu.vue";
import BillmanagerMenu from "../components/tiketMenu/BillmanagerMenu";
import TrashMenu from "../components/tiketMenu/TrashMenu";

import ticketStore from './../store/main/index'
import CallsMenu from "../components/tiketMenu/CallsMenu";

const routes = [
    {
        path:"/tickets",
        name:"Tickets",
        component:TicketList,

    },
    {
        path:"/tickets/trash",
        name: "Trash",
        components:{
            "main":TicketList,
            "menu":TrashMenu,
        },
    },

    {
        path:"/tickets/unsorted",
        name:"Unsorted",
        components:{
            "main":TicketList,
            "menu":UnsortedMenu,
        },
    },

    {
        path: "/tickets/competitor",
        name: "Competitor",
        components:{
            "main": TicketList,
            "menu": CompetitorMenu,
        },
    },

    {
        path: "/tickets/recent",
        name: "Recent",
        components: {
            "main": TicketList,
            "menu": Menu,
        },
    },

    {
        path: '/tickets/:project/calls',
        name: 'Calls',
        components: {
            main: TicketList,
            menu: CallsMenu,
        }
    },
    {
        path: '/tickets/:project/tasks',
        name: 'Tasks',
        components: {
            main: TicketList,
        }
    },
    {
        path: '/tickets/:project/billmanager',
        name: 'Billmanager',
        components: {
            main: TicketList,
            menu: BillmanagerMenu,
        }
    },

    {
        path:"/tickets/:project/:filter",
        name:"TicketsProjectFilter",
        components:{
            "main":TicketList,
            "menu":Menu,
        },
    },



    {
        path:"/tickets/:id",
        name:"Ticket",
        components:{
            "main": Ticket,
            "menu": TicketMenu,
        }
    },

    {
        path:"/notification/:id",
        name:"Notification",
        components: {
            "main": Notification
        }
    }
]

const router = createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'

    }
)
router.beforeEach((to, from, next) => {
    ticketStore.commit('SET_ROUTE' , to);
    next();
});

export default router;