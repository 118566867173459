<template>
<span :style="style">
  <span>{{dateFormat}}</span>:<span style="margin-left: 3px;">{{stringName}}</span>
</span>
</template>

<script>
import moment from "moment";

export default {
  name: "userDate",
  props:{
    style:{
      type:[String, Object],
    },
    date:{
      type:String
    },
    name:{
      type:[String,undefined]
    },
    login:{
      type:[String, undefined],
    }
  },
  computed:{
    dateFormat:function (){
      return moment(this.date ).format('DD.MM.YYYY HH:mm');
    },
    stringName:function (){
      return this.name ?? this.login;
    }
  }
}
</script>

<style scoped>

</style>