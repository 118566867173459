<template>
  <teleport to="body">
    <transition name="modal">
      <modal-window
          v-if="showModal"
          @close="showModalFunction(false)"
          @success="closeTask"
          modal-dialog-class=""
      >
        <template v-slot:header>
          <h5 class="modal-title">Завершение задачи</h5>
        </template>
        <template v-slot:body>
          Вы уверены, что хотите завершить выполнение задачи <span class="nobr">"<span><string-trim :string="message.subject" /></span>"</span>?
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn btn-primary" @click="closeTask">Завершить</button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import modalWindow from "../../tiketMenu/components/ModalWindow";
import StringTrim from "../../string/StringTrim";

export default {
  name: "TaskDoneModal",

  components: {
    modalWindow,
    StringTrim,
  },

  props: {
    message: {
      type: Object,
    },
    ticket: {
      type: Object,
    },
  },

  emits: [
    "close",
    "update",
  ],

  data() {
    return {
      showModal: true,
    }
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
      if (!this.showModal) {
        this.$emit('close');
      }
    },
    closeTask() {
      this.$emit('update');
    },
  },
}
</script>

<style scoped>
.nobr > span {
  display: inline-block;
  line-height: 1.2;
  height: 19px;
  vertical-align: text-bottom;
  max-width: calc(100% - 25px);
}
</style>