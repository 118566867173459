<template>
  <span v-for="role in roles" class="badge rounded-pill" :class="{'bg-danger':role==='admin','bg-secondary':role!=='admin'}" style="margin-left: 1px">{{role}}</span>
</template>

<script>
export default {
  name: "RolesList",
  props:{
    role:{
      required: true
    }
  },
  computed:{
    roles: function (){
      return typeof this.role === 'object' ? this.role :
          typeof this.role !== 'undefined' && this.role != '' ? [this.role] : [];
    }
  }
}
</script>

<style scoped>

</style>