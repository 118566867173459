import {PricePrettyView} from "../../../../../../library/PricePrettyView";

export {PriceCalc};

class PriceCalc {

    converter = require('ip-subnet-calculator');

    constructor({price = null, price1 = null, currency = null, quantity = null, subnets = null, prefix = null}) {
        this.price = price;
        this.price1 = price1;
        this.currency = currency;
        this.quantity = quantity;
        this.subnets = subnets;
        this.prefix = prefix;
    }

    getPrices() {
        let prices = {
            all: this.price,
            single: this.price1
        };

        if(prices.all !== null || prices.single !== null) {
            if(this.getCountIPs() > 0 && this.quantity === 'single') {
                prices.all = prices.single * this.getCountIPs();
            }
        } else {
            prices = null;
        }

        return prices;
    }

    getPrettyPrices(precisionAll = 0 , precisionSingle = 2, currencySymbolAtEnd) {
        let format = (el, precision = 2) => {
            return (el === null) ? '—' : new PricePrettyView(el, this.currency).getPrettyPrice('—',true, precision, currencySymbolAtEnd);
        }
        let prices = this.getPrices();
        let allObject = new PricePrettyView( prices?.all, this.currency)
        let singleObject = new PricePrettyView( prices?.single, this.currency)
        return prices ? {
            allNumber: allObject.getReducePricesWithCurrency('' , 2, currencySymbolAtEnd),
            all:typeof prices.all !=='undefined' && prices.all !== null
                 ? format(prices.all, precisionAll) : null,
            single: typeof prices.single !=='undefined' && prices.single !== null
                ? format(prices.single) : null,
            singleNumber:  singleObject.getReducePricesWithCurrency('' , precisionSingle , currencySymbolAtEnd),
        } : null;
    }

    getCountIPs() {
        let result = 0;

        if(this.prefix !== null) {
            result = Math.pow(2, 32 - this.prefix);
        } else if(this.subnets !== null) {
            result = 0;

            this.subnets.forEach((subnet) => {
                if(subnet.prefix != null) {
                    result += Math.pow(2, 32 - subnet.prefix);
                } else if( subnet.subnet != null && subnet.subnet.indexOf('-') !== -1) {
                    let range = subnet.subnet.split('-').map(ip => ip.trim());
                    result += (this.converter.toDecimal(range[1]) - this.converter.toDecimal(range[0])) + 1;
                } else if ( subnet.subnet != null && subnet.subnet.indexOf('/') !== -1){
                    let subnetParts = subnet.subnet.split('/');
                    if(  subnetParts[1] != null){
                        let prefix = subnetParts[1].replace(/[^0-9]/g,"");
                        if( prefix != null ){
                            result += Math.pow(2, 32 - prefix);
                        }
                    }
                }
            });
        }

        return result;
    }
}