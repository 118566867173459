
import {createRouter, createWebHistory} from "vue-router";
import ProductsNewEdit from "./ProductsNewEdit";

const routes = [
    {
        path:"/products/:product",
        name:"Products",
        component:ProductsNewEdit,

    },
    {
        path:"/products/:product/step/:step",
        name:"Step",
        component:ProductsNewEdit,

    },
]

const routerProduct = createRouter(
    {
        mode: 'history',
        history:createWebHistory(process.env.BASE_URL),
        routes,
        linkActiveClass: 'active'

    }
)


export default routerProduct;