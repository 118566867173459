<template>
  <div style="background-color: rgb(210, 210, 210);" >
    <button
        @click="btnHide = !btnHide"
        v-if="btnHide"
        class="btn btn-secondary btn-sm col-12 text-start"
    >{{btnName}}</button>
    <div
        v-show="btnHide"
        v-html="body"
        class="call-body-text max-width"
        style="white-space: break-spaces; padding-left: 5px; padding-top: 5px;"
        ref="html-message"></div>
    <button
        v-if="!btnHide"
        class="btn btn-sm col-12 text-start"
        style=" white-space: nowrap; overflow: hidden;  text-overflow: ellipsis;"
        @click="btnHide = !btnHide"
    >{{getButtonText}}</button>
  </div>
</template>

<script>
import {getMarkdown} from "../ticket/Api/api";

export default {
  name: "HideButton",
  props:{
    text:{
      type:String,
      require:true,
    },
    btnName:{
      type:String,
      default:"Скрыть",
    },
    textToButton:{
      type:String,
      default:'',
    },
    startValue:{
      type:Boolean,
      default: false,
    }
  },
  data:function (){
    return {
      btnHide:false,
    }
  },
  mounted() {
    this.btnHide = this.startValue;
  },
  computed:{
    body(){
      if(typeof this.text === 'undefined' || this.text === null){
        return '';
      }
      return getMarkdown(this.text , false);
    },
    getButtonText(){
      if(this.textToButton !== ''){
        return this.textToButton;
      }else {
        return  this.text;
      }
    },
  },

}
</script>

<style scoped>

</style>