<template>
<span v-if="isNotInline">
  <a
      v-if="canView"
      :href="attachmentLink"
      target="_blank"
      :style="linkStyle"
  >{{name ?? attachment.filename}}</a>
  <span v-else class="attachment-name">{{name ?? attachment.filename}}</span>
</span>
</template>

<script>
export default {
  name: "SimpleAttachment",

  props: {
    "messageid": {
      require: true
    },

    "attachment": {
      type: Object,
      require: true
    },

    "canView": {
      type: Boolean,
      default: false
    },

    linkStyle: {
      type: String,
      default: 'padding-right: 5px'
    },

    name: String,
  },

  computed: {
    attachmentLink() {
      return '/message/' + this.messageid + '/attachment/' + window.getId(this.attachment.id);
    },

    isNotInline() {
      return typeof this.attachment?.inline === 'undefined' || this.attachment.inline === null || this.attachment.inline !== true;
    }
  },
}
</script>

<style scoped>

</style>