<template>
  <div>
    <add-ticket-button></add-ticket-button>
    <div v-if="topLevelLoading">
      Loading...
    </div>
    <div v-else-if="topLevel.length > 0" class="sidebar-item-body">
      <span class="show-hide-button-container">
        <popper
            class="light"
            :hover="true"
            :interactive="false"
            :arrow="true"
            placement="top"
            :content="showHidePopperText"
        ><i class="fa cursor-pointer" :class="showHideIconClass" @click="showAll = !showAll"></i></popper>
      </span>
      <project-item
          v-for="(item, index) in topLevel"
          :is-last-element="index === (topLevel.length -1)"
          :id="item.label"
          :key="item.id + '_project_item'"
          :project="item"

          :count-settings="countSettings"
          @collapse="clickCollapse"
          ref="project-item"
      ></project-item>

      <hr style="margin-top: 5px; margin-bottom: 0px;"/>

      <additional-list
        v-for="item in additionalList"

        :route-name="item.route"
        :label="item.label"
        :key="item.label"
        :icon="item.icon"
        :type="item.type"
        :style="item.style"

        :count-settings="countSettings"
        ref="additional-item"
      ></additional-list>
    </div>

  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import SidebarItem from "./SidebarItem";
import ProjectsSlide from "./ProjectsSlide";


import {UserPermission} from "../../users/UserPermission";
import AdditionalList from "./AdditionalList";
import ReadUnread from "./filter/ReadUnread";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {initMovingFromFoldersFunctions} from "../ticket/List/ArrowScrolling";
import AddTicketButton from "../ticket/Create/AddTicketButton";


export default {
  name: "TicketSidebar",
  components:{
    AddTicketButton,
    ReadUnread,
    AdditionalList,
    "sidebar-item": SidebarItem,
    "project-item":ProjectsSlide
  },
  props: {
    ticketLoad:{
      type:Function,
    },
  },

  data: function (){
    return {

      "topLevelLoading": true,
      "projectList": [],
      additionalList: [],
      showUnsorted: false,
      showTrash: false,
      showCompetitor: false,

      openedItem: null, // project.name
      countInterval: null,
      counts: {},
      countSettings: {
        reset: false,
      },
      autoUpdate:false,
      permissionsLoaded: false,
    }
  },
  created: function() {
    CrmApi.getProjectsList({orderBy:"personal_order"}).then((result) => {

      this.$store.commit('tickets/projects/setProjects',  result.data);
      let p_names = result.data.map(p => p.name);
      let projects = [];
      // Если поектов больше одного то добавляем в меню пункт.
      let allProjects = null;
      if(result.data.length > 1){
        let allProjects = {};
        allProjects._id = 'all';
        allProjects.name = 'all';
        allProjects.label = "Все проекты"
        result.data.unshift(allProjects);
      }



      // перенаправляем на "Новые заявки" в первом проекте
      if(this.$route.name === 'Tickets' && result.data.length > 0) {
        this.chooseSelectionFilter({filter: 'new', projectName:result.data[0].name});
        this.$router.push({
          name: 'TicketsProjectFilter',
          params: {
            project: result.data[0].name,
            filter: 'new'
          }
        });
      }

      result.data.forEach((projectInfo) => {
        if(typeof projectInfo.label === 'undefined'){
          projectInfo.label = projectInfo.name;

        }

        let answered = this.makeItem(projectInfo, 'Отвеченные', 'answered', 'book');
        let newRequest = this.makeItem(projectInfo, 'Новые', 'new', 'plus');
        let closed = this.makeItem(projectInfo, 'Закрытые', 'closed', 'check-circle-o');
        let favorite = this.makeItem(projectInfo, 'Избранные','favorite', 'star');
        let tasks = this.makeItem(projectInfo, 'Задачи' ,'tasks' , 'tasks' )
        projectInfo.content = [
          newRequest,
          answered,
          closed,
          favorite,
          tasks,
        ];

        projects.push(projectInfo);
        this.countSettings[projectInfo.name] = {};
      });

      if(projects.length) {
        let filters = {};
        projects[0].content.forEach(item => {
          if (['BILLmanager', 'Звонки', 'Задачи'].indexOf(item.label) === -1) {
            filters[item.filter.type] = item.label;
          }
        });
        this.$store.commit('tickets/filters/setSidebarFilters', filters);
      }

      this.additionalList = [];

      if (projects.filter(project => project?.ipspam === true).length) {
        this.additionalList.push(
          this.makeAdditionalItem(
            'Конкуренты',
            'competitor',
            'user-times',
            this.showUnsorted,
            {
              label: 'margin-left: 3px;'
            }
          )
        );
        this.countSettings.competitor = {};
        this.showCompetitor = true;
      }

      this.additionalList.push(
          this.makeAdditionalItem(
              'История просмотров',
              'recent',
              'history',
              true,
              {
                label: 'margin-left: 7px;'
              }
          )
      );

      let delete_rights = ['system.deleted', "system.read_pending"];
      let notifications_rights = p_names.map(p => p + '.notifications');
      let call_rights = p_names.map(p => p + '.call');
      let call_record_rights = p_names.map(p => p + '.callrecord');
      let answer_rights = p_names.map(p => p + '.answer');
      let create_rights = p_names.map(p => p + '.create');
      let billmanager_rights = p_names.map(p => p + '.billmanager');

      UserPermission.getPermissions(
          delete_rights.concat(notifications_rights)
              .concat(call_rights)
              .concat(call_record_rights)
              .concat(answer_rights)
              .concat(create_rights)
              .concat(billmanager_rights)
      ).then(rights => {
        if (rights["system.read_pending"] === true) {
          this.additionalList.unshift(
              this.makeAdditionalItem('Неразобранная','unsorted', 'cubes', this.showUnsorted)
          );
          this.countSettings.unsorted = {};
        }
        if (rights['system.deleted'] === true) {
          this.showTrash = true;
          let trash = this.makeAdditionalItem('Удалённые', 'trash', 'trash-o', true, {
            label: 'margin-left: 7px;',
            icon: 'margin-left: 2px'
          });

          this.additionalList.push(trash);
          this.countSettings.trash = {};
        }
        let toAllCalls = false;
        call_rights.some(name => {
          if (rights[name] === true) {
            toAllCalls = true;

            let projectName = name.substr(0, name.indexOf('.call'));
            this.topLevel.find(level => level.name === projectName)
                .content.splice(
                  this.indexOfFilter(this.topLevel.find(level => level.name === projectName).content, 'calls'),
                  0,
                this.makeItem(
                    result.data.find(p => p.name === projectName),
                    'Звонки',
                    'calls',
                    'phone'
                )
            );
          }
        });

        if(toAllCalls) {
          let allItem = this.topLevel.find(level => level.name === 'all');

          if(allItem) {
            allItem.content.splice(
                this.indexOfFilter(allItem.content, 'calls'),
                0,
                this.makeItem(
                    result.data.find(p => p.name === 'all'),
                    'Звонки',
                    'calls',
                    'phone'
                )
            );
          }
        }

        let toAllUnconfirmed = false;

        answer_rights.some(name => {
          if (rights[name] === true) {
            toAllUnconfirmed = true;
            let projectName = name.substr(0, name.indexOf('.answer'));
            this.topLevel.find(level => level.name === projectName)
                .content.splice(
                1,
                0,
                this.makeItem(
                    result.data.find(p => p.name === projectName),
                    'Премодерация',
                    'unconfirmed',
                    'graduation-cap'
                )
            );
          }
        });

        if (toAllUnconfirmed) {
          let allItem = this.topLevel.find(level => level.name === 'all');

          if(allItem) {
            allItem.content.splice(
                1,
                0,
                this.makeItem(
                    result.data.find(p => p.name === 'all'),
                    'Премодерация',
                    'unconfirmed',
                    'graduation-cap'
                )
            );
          }
        }

        let toAll = false;
        notifications_rights.some(name => {
          if(rights[name] === true) {
            toAll = true;

            let projectName = name.substr(0, name.indexOf('.notifications'))
            this.topLevel.filter(level => level.name === projectName)[0]
                         .content.push( this.makeItem(result.data.filter(p => p.name === projectName)[0], 'Рассылки', 'notifications', 'bell') );
          }
        });

        if(toAll) {
          let allItem = this.topLevel.filter(level => level.name === 'all');

          if(allItem.length) {
            allItem[0].content.push( this.makeItem(result.data.filter(p => p.name === 'all')[0], 'Рассылки', 'notifications', 'bell') );
          }
        }

        let toBillManagerAll = false;

        billmanager_rights.some(name => {
          if (rights[name] === true) {
            toBillManagerAll = true;

            let projectName = name.substr(0, name.indexOf('.billmanager'));
            this.topLevel.find(level => level.name === projectName)
                .content.push(
                this.makeItem(
                    result.data.find(p => p.name === projectName),
                    'BILLmanager',
                    'billmanager',
                    'columns'
                )
            );
          }
        });

        if (toBillManagerAll) {
          let allItem = this.topLevel.find(level => level.name === 'all');

          if(allItem) {
            allItem.content.push(
                this.makeItem(
                    result.data.find(p => p.name === 'all'),
                    'BILLmanager',
                    'billmanager',
                    'columns'
                )
            );
          }
        }

        let projectsCanAnswer = [];
        let projectsCanCreate = [];
        p_names.some(name => {
          if(rights[name + '.answer'] === true) {
            projectsCanAnswer.push(name);
          }
          if(rights[name + '.create'] === true) {
            projectsCanCreate.push(name);
          }
        });

        this.setProjectsCanAnswer(projectsCanAnswer);
        this.setProjectsCanCreate(projectsCanCreate);

        this.updateStoreFilters();

        this.setCountInterval();
      }).finally(() => {
        this.permissionsLoaded = true;
        this.updateCount();
      });

      this.topLevel = projects;
      this.updateStoreFilters();

      this.topLevelLoading = false;
    });
  },
  mounted() {
    initMovingFromFoldersFunctions(this.goToNextFolder , this.goToPrevFolder);
    this.showUnsorted = false;
    UserPermission.can("system.read_pending").then(
        (result)=> {
          this.showUnsorted = true;
        }
    ).catch(()=>{
    });
  },
  methods:{

    updateStoreFilters: function() {
      if(this.topLevel.length) {
        let filters = {};
        this.topLevel[0].content.forEach(item => {
          if (['BILLmanager', 'Звонки', 'Задачи'].indexOf(item.label) === -1) {
            filters[item.filter.type] = item.label;
          }
        });
        this.$store.commit('tickets/filters/setSidebarFilters', filters);
      }
    },


    /*
    setSelectionControl: function (){
      if(this.$route.name === 'TicketProjectsFilter'){
        if(typeof this.$route.params["project._id"] !== 'undefined' && typeof this.$route.params.open !== 'undefined' ){
            let action = "archive";
            if(this.$route.params.open == true ||  this.$route.params.open == "true"){
              action = "active";
            }
            this.selectionControl.id = this.getId(action , this.$route.params["project._id"])
            this.selectionControl.project = this.$route.params["project._id"];
        }
      }
    },
     */

    getId:function (action , id){
      return action + "_" + id;
    },

    makeItem: function(project, label, type, icon = null) {
      let item = {
        id: this.getId(type, project.name),
        label: label,
        icon: icon,
        projectId: project._id,
        filter: {
          type: type,
          project: project.name,
        },
        projectName: project.name,
      };

      return item;
    },

    indexOfFilter(filters, find) {
      return filters.map(i => i?.filter?.type).indexOf(find);
    },

    makeAdditionalItem: function(label, type, icon = null, show = true, style = null) {
      let item = {
        label: label,
        route: type.charAt(0).toUpperCase() + type.slice(1),
        icon: icon,
        style: style,
        type: type,
        show: show,
      }

      return item;
    },

    updateCount(forceUpdate = false) {
      if(!forceUpdate){
        if (this.countInterval) {
          clearTimeout(this.countInterval);
        }
      }

      let projectName = this.openedItem;
      let request = {
        projectName: projectName,
        aliases: {},
      }

      if(projectName !== null && typeof  this.topLevel[0] !== 'undefined' && typeof this.topLevel[0].content !== 'undefined'){
        let item = this.getMenuItemByProjectName(projectName);
        let aliases = item.project.content.map(item => item.filter.type).filter(alias => alias !== 'billmanager');

        aliases.forEach((alias) => {
          request.aliases[alias] = {};

          if(['unconfirmed', 'unread'].includes(alias)) {
            request.aliases[alias] = {count: true};
          } else if(typeof item.countSettings[projectName][alias] !== 'undefined') {
            request.aliases[alias] = {
              count: item.countSettings[projectName][alias].count ?? false,
              unread: item.countSettings[projectName][alias].unread ?? true,
            };
          }

          return alias;
        });
      }

      if(this.showTrash) {
        request.aliases.trash = {};
        if(typeof this.countSettings.trash !== 'undefined') {
          request.aliases.trash = {
            count: this.countSettings.trash.count ?? false,
            unread: this.countSettings.trash.unread ?? true,
          };
        }
      }
      if(this.showUnsorted) {
        request.aliases.unsorted = {};
        if(typeof this.countSettings.unsorted !== 'undefined') {
          request.aliases.trash = {
            count: this.countSettings.unsorted.count ?? false,
            unread: this.countSettings.unsorted.unread ?? true,
          };
        }
      }
      if (this.showCompetitor) {
        request.aliases.competitor = {};
        if(typeof this.countSettings.competitor !== 'undefined') {
          request.aliases.trash = {
            count: this.countSettings.competitor.count ?? false,
            unread: this.countSettings.competitor.unread ?? true,
          };
        }
      }

      if(Object.entries(request.aliases).length && this.permissionsLoaded) {

        CrmApi.call('/billmanager/ticket/count/', {projectName: projectName}).then((response) => {
          let item = this.getMenuItemByProjectName(projectName);
          if(item !== null) {
            item.setFilterCount('BILLmanager', {total: response.data.count, unread: 0});
          }
        }).catch(error => {});

        CrmApi.call('/ticket/count/', request).then((response) => {
          if(typeof this.counts[projectName] === 'undefined') {
            this.counts[projectName] = {};
          }


          if(projectName !== null){
            let item = this.getMenuItemByProjectName(projectName);

            if(item !== null) {
              if(item.count === null) {
                item.count = {};
              }

              let count = {};
              for(let a in response.data) {
                count[a] = { ...item.count[a] ?? {}, ...response.data[a] };
                this.countSettings[projectName][a] = {};
                if(response.data[a].total !== 'undefined') {
                  this.countSettings[projectName][a].actual = true;
                }
              }
              item.count = count;

              item.actualCount = true;
            }


            this.counts[projectName] = {timeChecking: window.moment().unix()};
          }

          // обновление количества для additional list
          if(typeof this.$refs['additional-item'] !== 'undefined') {
            for(let el of this.$refs['additional-item']) {
              if(typeof response.data[el.type] !== 'undefined') {
                let count = el.count;
                for(let countType in response.data[el.type]) {
                  count[countType] = response.data[el.type][countType];
                  if(countType === 'total') {
                    this.countSettings[el.type].actual = true;
                  }
                }
                el.count = count;
                el.actualCount = true;
              }
            }
          }

        }).catch(error => {})
          .finally(() => {
          if(!forceUpdate){
            if (this.countInterval) {
              clearTimeout(this.countInterval);
            }
            if(this.autoUpdate){
              this.countInterval = setTimeout(this.updateCount, 10000);
            }
          }

        });

      }
    },

    clickCollapse: function(collapse, projectName) {
      this.openedItem = collapse ? projectName : null;
      let items = collapse ?
          this.$refs['project-item'].filter(item => item.project.name !== projectName) :
          this.$refs['project-item'];

      items.forEach(item => {
        item.collapse = false;
        item.actualCount = false
      });
    },

    setCountInterval: function() {
      // clearInterval(this.countInterval);
      // this.countInterval = false;
      if (!this.countInterval && !this.autoUpdate) {
        this.autoUpdate = true;
        this.updateCount();
      }
    },

    getMenuItemByProjectName: function(projectName) {
      return this.$refs['project-item'].filter(item => item.project.name === projectName)[0] ?? null;
    },

    getAdditionalItemByType: function(type) {
      return this.$refs['additional-item'].filter(item => item.type.toLowerCase() === type.toLowerCase())[0] ?? null;
    },
    ...mapMutations({

      'setTopLevel':'leftMenu/setTopLevel',
      'setProjectsCanAnswer': 'tickets/create/setProjectsCanAnswer',
      'setProjectsCanCreate': 'tickets/create/setProjectsCanCreate',
      "setShowAll": "leftMenu/setShowAll",
    }),
    ...mapActions({
      'chooseSelectionFilter':'leftMenu/chooseSelectionFilter',
      'goToNextFolder':'leftMenu/goToNextFolder',
      'goToPrevFolder':'leftMenu/goToPrevFolder',
    }),
  },
  computed:{
    topLevel:{
      get(){
        return this.getTopLevel
      },
      set(value){
        this.setTopLevel(value)
      }
    },
    showAll: {
      get() {
        return this.getShowAll;
      },
      set(value) {
        this.setShowAll(value);
      }
    },
    showHidePopperText() {
      return this.showAll ? 'Не отображать скрытые папки' : 'Отобразить скрытые папки';
    },
    showHideIconClass() {
      return this.showAll ? 'fa-eye' : 'fa-eye-slash';
    },
    ...mapGetters({
      'getTopLevel':'leftMenu/getTopLevel',
      'selectionControl':'leftMenu/getSelectionControl',
      "getShowAll": "leftMenu/getShowAll",
    }),
  },
  watch: {
    'openedItem' (to , from) {
      if(to !== null){
        this.$nextTick(()=>{
          this.updateCount(true)
        });
      }
    },

    'countSettings.reset' (to) {
      if(to) {
        this.countSettings.reset = false;
        this.updateCount(true);
      }
    },

    '$store.state.tickets.resetCounter' (to) {
      if(to) {
        this.$store.state.tickets.resetCounter = false;
        this.updateCount(true);
      }
    },

    'countSettings.getCount' (params) {
      if(params !== null) {
        CrmApi.call('/ticket/count/', params).then((response) => {
          if(typeof params.projectName === 'undefined') {
            // getCount запрашивается для пункта из additional list
            for (let name in response.data) {
              let item = this.getAdditionalItemByType(name);
              if(item !== null) {
                let count = item.count;
                for (let countType in response.data[name]) {
                  count[countType] = response.data[name][countType];
                }
                item.count = count;
              }
              this.countSettings[name].actual = true;
            }
          } else {
            // getCount для фильтра в проекте
            let item = this.getMenuItemByProjectName(params.projectName);
            let count = item.count;
            for (let alias in response.data) {
              for (let countType in response.data[alias]) {
                count[alias][countType] = response.data[alias][countType];
                this.countSettings[params.projectName][alias].actual = true;
              }
            }
            item.count = count;
          }
        }).catch(error => {});

        this.countSettings.getTotal = null;
      }
    },
  }
}
</script>

<style scoped>


hr{
  margin: 10px 0;
}


.sidebar-item-body{
  color: rgba(0, 0, 0, 0.65);
  background-color: #ececec;
  position: relative;
}
.show-hide-button-container {
  position: absolute;
  right: 6px;
  top: 0;
}
.show-hide-button-container :deep(.popper) {
  --popper-max-wight-custom: 50vw;
  position: fixed !important;
  z-index: 1200;
}
.cursor-pointer {
  cursor: pointer;
}
</style>