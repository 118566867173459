<template>
  <div class="params-item">
    <div class="d-flex" :class="{'text-muted': loading.product}" v-if="productSelected || userCanChange">
      Услуга:&nbsp;
      <span class="nobr">
        <template v-if="userCanChange">
          <text-select
            :options="getProductsList"
            :options-params="{value: '_id', name: 'name'}"
            :selected="selectedProductId ?? null"
            :before-load-selected="productSelected"
            :callback="changeProduct"
            select-style="width: 10em; max-width: 100%;"
            :trim="true"
            ref="select"
          />
        </template>

        <template v-else-if="productSelected ">
          <span>
            <string-trim :string="productSelected" />
          </span>
        </template>
      </span>
      <span>
        <a :href="getProductUrl('faq')" v-if="getProductUrl('faq')" class="p-1" style="color:black;" target="_blank"><i class="fa fa-question-circle-o"></i></a>
      </span>
    </div>

    <steps v-if="isAssigned && product != null"
           :product="product"
           :ticket-id="ticket._id"
           :user-can-change="userCanChange" />

    <field-group v-if="additionalFields"
                :type="additionalFields.type"
                :product="ticket.assigned_product"
                :ticket-id="ticket._id"
                :operations="ticket?.current_operations?.subnets ?? []"
                :user-can-set-lot="userCanSetLot"
                :user-can-change="userCanChange" />
  </div>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import EditableSelect from "../../../Editable/EditableSelect";
import StringTrim from "../../../string/StringTrim";
import Steps from "./Product/Steps";
import {mapGetters} from "vuex";
import FieldGroup from "./Product/FieldGroup";

export default {
  name: "Product",

  components: {
    'text-select': EditableSelect,
    StringTrim,
    Steps,
    FieldGroup
  },

  props: {
    ticket: {},
    userCanChange: {
      type: Boolean,
      default: false
    },
    userCanSetLot: {
      type: Boolean,
      default: false
    },
  },

  data: function() {
    return {
      newName:null,
      loading: {
        product: false,
        step: false
      },
      selectedProductId: null,
    }
  },

  watch:{
    focusSelect(to){
      if(to){
        this.$refs.select.focus();
        this.$refs.select.setPopperContent("Для отправки ответа необходимо выбрать услугу");
      }
    },
    product(to) {
      if (to && window.getId(to?._id) !== this.selectedProductId) {
        this.selectedProductId = window.getId(this.product._id);
      }
    }
  },

  computed: {
    ...mapGetters({
      'focusSelect':"tickets/product/getFocusSelect",
      "getProducts": "tickets/product/getProducts",
    }),

    additionalFields: function() {
      return (typeof this.product === 'object' &&
             typeof this.product.additional_fields !== 'undefined' &&
             Array.isArray(this.product.additional_fields) &&
             this.product.additional_fields.length) ? this.product.additional_fields[0] : null;
    },

    productSelected: function() {
      return (this.product !== null &&
          typeof this.product === 'object' &&
          typeof this.product.name !== 'undefined') ? this.product.name : null;
    },

    isAssigned: function() {
      return (!this.loading.product && this.ticket !== null && typeof this.ticket.assigned_product !== 'undefined');
    },

    product:{
      set(value){
        this.ticket.assigned_product = value;
        },
      get(){
        let value = JSON.parse(
            JSON.stringify(this.ticket.assigned_product ?? {})
        );

        if( this.newName !== null){
          value.name =  this.newName;
        }
        return value;
      }
    }
  },

  methods: {
    getProductUrl(type) {
      return (this.isAssigned &&
              typeof this.ticket.assigned_product.urls !== 'undefined' &&
              typeof this.ticket.assigned_product.urls[type] !== 'undefined' &&
              this.ticket.assigned_product.urls[type] != null &&
              this.ticket.assigned_product.urls[type].length) ? this.ticket.assigned_product.urls[type] : null;
    },

    async getProductsList() {
      return this.getProducts;
    },

    changeProduct: function(product) {
      this.loading.product = true;
      this.newName = null;
      for (let pr of this.getProducts){
        if(pr._id === product){
          this.newName = pr.name;
          break;
        }
      }
     this.$store.commit('thread/loading');

      CrmApi.updateThreadParams(this.ticket._id, {product: product}).then(() => {
        this.$store.commit('thread/reloadLastMessage');
        this.$store.commit('tickets/updateSelected', {
          callback: () => {  }
        });
        this.$store.commit('thread/reloadLastMessage');
        CrmApi.getTicketList( {_id: this.ticket._id} ).then((result) => {
          this.$store.commit('thread/setTicket', result.data.data[0]);
          this.$store.dispatch('tickets/selectElement', result.data.data[0]);
        }).finally(()=>{
          this.loading.product = false;
            this.newName = null;

        })
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.loading.product = false;
        this.newName = null;
      }).finally(() => {

        this.$store.commit('thread/unloading');
      });

    },
  },

  mounted() {
    this.selectedProductId = typeof this.product?._id !== 'undefined' ? window.getId(this.product._id) : null;
  }
}
</script>

<style scoped>
:deep(.popper) {
  position: fixed !important;
  z-index: 1000;
}
</style>