<template>
    <div class="template-quick-modal-mask" @mouseleave="dragLeave"  :style="dialogStyle"  v-on:mousedown="holdOn($event)"
          v-on:mouseup="holdOff()" >
      <div>
        <sip-form></sip-form>
      </div>
    </div>
</template>

<script>
import MovingModal from "../common/movingModal/MovingModal";

import SipForm from "./library/SipForm";

export default {
  name: "SipMenu",
  components: {
    SipForm,
    MovingModal
  },
  data:function (){
    return {
      minWidth:300,
      drag:false,
      right: 0,
      bottom: 0,
      zIndex:null,
      currentWidthIndex:0,
      offset:{
        x:0,
        y:0
      }
    }
  },
  methods:{
    sizeChange(){

      this.drag = false;
      let i = this.currentWidthIndex + 1;
      if(typeof this.widths[i] === 'undefined' ){
        i = 0;
      }
      this.currentWidthIndex = i;

    },
    dragOver(event){
      this.doDrag(event);
    },
    dragLeave(event){
      if(event.buttons !== 1){
        this.drag = false;
      }
    },

    dragStart (event) {
      event.dataTransfer.setData('text', event.target.id)
      this.startMove(event)
    },
    holdOn(event){

      this.drag = true;
      this.startMove(event);
    },
    holdOff(){

      this.drag = false;
    },

    doDrag(event){
      if(this.drag){
        let x = -event.clientX;
        let y = -event.clientY;
        this.right = (x + this.offset.x)
        this.bottom = (y + this.offset.y)
      }
    },
    startMove (event) {
      let x = this.right + event.clientX
      let y = this.bottom + event.clientY
      this.offset = { x, y }
    },

  },
  beforeUnmount () {
    window.removeEventListener('mousemove', this.doDrag)
  },
  mounted() {
    let w = window.innerWidth/2;
    let h = window.innerHeight/2;
    this.right = w - 255/2;
    this.bottom = h - 255/2;
    window.addEventListener('mousemove',this.doDrag)
  },
  computed:{
    dialogStyle () {
      let style = { right: this.right + 'px', bottom: this.bottom + 'px' }
      style['min-width'] = this.minWidth + 'px';
      if (this.zIndex) style.zIndex = this.zIndex
      if (this.drag) {
        style['user-select'] = 'none'
        style.cursor ='all-scroll'
      }

      style['margin-left'] = 'auto';
      style['margin-right'] = 'auto';
      return style
    },
  }
}
</script>

<style scoped>
.template-quick-modal-mask {
  position: absolute;
  z-index: 99999;
  transition-duration: 500ms;
  transition-property: margin-right;
}
</style>