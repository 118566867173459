<template>
  <div class="table-period bg-light" v-if="period">
    {{ periodOut }}
  </div>
  <div class="table-data" v-if="data">
    <table class="table table-bordered table-hover table-striped table-container">
      <thead class="thead-dark" style="z-index:20">
      <tr>
        <th @click="sortBy('login')" class="text-center" style="width: 179px; cursor: pointer;">Менеджер</th>
        <th @click="sortBy('count_read')" class="text-center" style="width: 160px; cursor: pointer;">Просмотров</th>
        <th @click="sortBy('countMessage')" class="text-center" style="width: 160px; cursor: pointer;">Ответов</th>
        <th @click="sortBy('min')" class="text-center" style="width: 160px; cursor: pointer;">Мин. ответ</th>
        <th @click="sortBy('avg')" class="text-center" style="width: 160px; cursor: pointer;">Ср. ответ</th>
        <th @click="sortBy('max')" class="text-center" style="width: 160px; cursor: pointer;">Макс. ответ</th>
      </tr>
      </thead>
      <tbody>
        <statistic-row
            v-for="item in keys"
            :id="item"
            :data="data[item]"
            :from="period.from"
            :to="period.to"
            :project="project"
        ></statistic-row>
        <statistic-row
            :total="true"
          :data="total"
        ></statistic-row>
      </tbody>
    </table>
  </div>
</template>

<script>
import StatisticRow from "./StatisticRow";
import {toRef} from "vue";
import {getPeriodOut, sortFunctions} from "./statisticFunctions";

export default {
  name: "StatisticTable",

  components: {
    StatisticRow
  },

  props: {
    period: {
      type: Object,
      default: null
    },

    data: {
      type: Object,
      default: null
    },

    project: {
      type: String,
      default: 'all',
    },
  },

  setup(props) {
    const period = toRef(props, 'period');
    const {periodOut} = getPeriodOut({period});
    return {
      periodOut,
    }
  },

  data: function () {
    return {
      keys: [],
      sort: 0,
      sorted: null,
    }
  },

  computed: {
    total() {
      let result = null;
      if (this.data) {
        result =  {
          name: 'Всего',
          count_read: 0,
          countMessage: 0,
          min: null,
          avg: null,
          max: null,
        }
        let avg = [];
        Object.keys(this.data).forEach((row) => {
          let data = this.data[row];
          if (typeof data.avg === 'number') {
            avg.push(data.avg);
          }
          if (data.count_read) {
            result.count_read += data.count_read;
          }
          if (data.countMessage) {
            result.countMessage += data.countMessage;
          }
          if (typeof data.min === 'number') {
            if (result.min === null || data.min < result.min) {
              result.min = data.min;
            }
          }
          if (typeof data.max === 'number') {
            if (result.max === null || data.max > result.max) {
              result.max = data.max;
            }
          }
        });
        if (avg.length) {
          result.avg = avg.reduce((a, b) => a + b) / avg.length;
        }
      }
      return result;
    },
  },

  mounted() {
    this.keys = Object.keys(this.data);
    this.sortBy('login');
  },

  methods: {
    sortBy(field) {
      if (this.sort && this.sorted === field) {
        this.sort = -this.sort;
      } else {
        this.sort = 1;
        this.sorted = field;
      }
      let keys = Object.keys(this.data);
      const sortFunction = sortFunctions(this.data, this.sort);
      if (this.sort !== 0 && typeof sortFunction[field] !== 'undefined') {
        keys.sort(sortFunction[field]);
      }
      this.keys = keys;
    },
  }
}
</script>

<style scoped>
.table-period {
  padding: 1rem;
  text-align: center;
  border: 1px solid #dee2e6;
  font-weight: 700;
}
.table-data th {
  color: rgba(33, 37, 41, 0.75);
}
</style>