import {CrmApi} from "../../../../library/CrmApi";


const state = {
    products: null,
    focusSelect:false,
}


const getters = {
    getProducts: (state) => {
        return state.products;
    },
    isHasProducts:(state) =>{
        return  state.products !== null && state.products.length > 0
    },
    getFocusSelect:(state) =>{
        return state.focusSelect;
    }
}

// actions
const actions = {


    initProducts({ commit , rootGetters }){
        let id = window.getId(rootGetters['thread/getTicket']._id);
        CrmApi.getProducts(id).then((response) => {
                commit('setProducts' , response.data.products)
            }
        ).catch((error)=>{
            commit('setProducts' , []);
            if( error?.response?.status != 403 ) {
                commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
            }
        })
    },

}

const mutations = {
    setProducts (state, products ) {
        state.products = products;
    },
    focusSelectMenu(state){
        state.focusSelect = true;
    },
    unFocusSelectMenu(state){
        state.focusSelect = false;
    },
    resetProducts(state ){
        state.products = null;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}