<template>
  <div class="col-12">
    <button class="btn btn-light btn-outline-dark delete-with-tag-button" :class="{'col-6': hasIpSpam, 'col-12': !hasIpSpam}" @click="showModalDelete('spam')">
      <i class="fa fa-trash-o button-icon menu-button"></i>Спам
    </button>
    <button v-if="hasIpSpam"
            class="btn btn-light btn-outline-dark delete-with-tag-button col-6 px-1"
            :class="{loading: loading}"
            @click="sendIpSpam"
            :disabled="loading">
      <i class="fa fa-trash-o button-icon menu-button"></i><nobr>{{ipspamButtonText}}</nobr>
    </button>
    <teleport to="body">
      <transition name="modal">
        <modal-window v-if="showModal" @close="closeModal"  @success="deleteTicket()" ok-button-style="btn-danger" >
          <template v-slot:header>
            <h5 class="modal-title text-danger">Удаление тикета</h5>
          </template>
          <template v-slot:body>
            <span>Вы уверены, что хотите удалить выбранные тикеты?</span>
          </template>
          <template v-slot:footer>
            <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
            <button type="button" class="btn btn-danger" @click="deleteTicket()">Удалить с меткой spam</button>
          </template>
        </modal-window>
      </transition>
    </teleport>
  </div>
</template>

<script>
import StringTrim from "../../string/StringTrim";
import ModalWindow from "./ModalWindow";
import {Ticket} from "../../../library/api/Ticket";
import {CrmApi} from "../../../library/CrmApi";

export default {
  name: "DeleteWithSpamButton",

  components: {
    StringTrim,
    ModalWindow,
  },
  props: {
    ipspam: {
      default: false,
    },

    ipspamValue: {
      default: null,
    },

    callback: {
      default: null
    },

    queue: {
      default: null
    },

    competitorCallback: {
      default: null,
    }
  },

  data () {
    return {
      type: null,
      showModal: false,
      showIPSpamProjectsModal: false,
      ipspamProject: null,
      loading: false,
    }
  },

  computed: {
    ipspamButtonText() {
      let result = '';
      if(this.hasIpSpam) {
        result = this.ipspamValue ? 'не IP SPAM' : 'IP SPAM';
      }
      return result;
    },
    hasIpSpam() {
      let ipSpam = this.ipspam;
      let checkProjectIPSpam = (ticket) => ((ticket?.project ?? null) === null || ticket.project?.ipspam === true);

      if(!ipSpam && this.ipSpamProjects.length > 0) {
        if(this.ticket !== null) {
          ipSpam = checkProjectIPSpam(this.ticket);
        } else {
          if(this.$route.name === 'Unsorted') {
            ipSpam = true;
          } else  {
            ipSpam = Object.values(this.selectedTickets ?? {}).some(ticket => checkProjectIPSpam(ticket));
          }
        }
      }

      return ipSpam && (typeof this.ipspamValue === 'boolean');
    },

    ipSpamProjects() {
      return this.ipspam
                ? []
                : this.$store.getters['leftMenu/getTopLevel'].filter(item => item?.ipspam);
    },

    selected() {
      return this.$store.getters['tickets/getSelectedArray'];
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    },

    selectedTickets() {
      return this.$store.getters['tickets/getSelectedObjects'];
    }
  },

  methods: {
    deleteTicket() {
      let action = () => {
        this.closeModal();
        this.$store.dispatch('tickets/deleteTickets', {
          ticketIds: this.selected,
          route: this.callback ?? this.$route,
          withTag: this.type,
        });
      }
      if(this.queue === null) {
        action();
      } else {
        this.queue(action);
      }
    },
    closeIPSpamProjectsModal() {
      this.showIPSpamProjectsModal = false;
      this.$store.commit('removeActiveWindow', 'selectIPSpamProject');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketDeleteWithTag');
    },
    showModalDelete(type) {
      this.type = type;
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketDeleteWithTag');
    },
    sendIpSpam() {
      this.loading = true;
      Ticket.competitor({
        ids: this.selected,
        competitor: !this.ipspamValue
      }).then((result) => {
        if (typeof this.competitorCallback === 'function') {
          this.competitorCallback(result);
        }
      }).catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
        .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>