<template>
  <button class="btn btn-light btn-outline-dark col-12" v-if="showDocumentsButton" @click="showModal">
    <i class="fa fa-file-text-o"></i> Документы
  </button>

  <teleport to="body">

    <transition name="modal">
      <modal-window v-if="modal.show" @close="closeModal" @success="false" modal-dialog-class="modal-lg modal-dialog-scrollable" :show-footer="showParams">
        <template v-slot:header>
          <h5 class="modal-title">Генерация документов</h5>
        </template>
        <template v-slot:body>
          <select class="form-select" v-model="modal.selectedDocument" @change="selectTemplate">
            <option disabled="disabled" :value="null">Выберите шаблон документа для генерации</option>
            <option v-for="document in documents" :value="document._id">{{document.name}}</option>
            <option v-if="showTransferAgreement" value="transfer-agreement">Transfer Agreement</option>
          </select>

          <div class="alert alert-warning my-3" v-if="generatedEventLink">
            Ранее в этом тикете документ по этому шаблону уже был сгенерирован.
            <br/><a :href="generatedEventLink">Перейти к документу &#10095;&#10095;</a>
          </div>
          <div class="alert alert-danger mt-3 mb-0" v-if="shouldFillTicketPrice">
            Для генерации этого документа необходимо заполнить поле "Цена" в тикете.
          </div>

          <company-select v-if="showCompanySelect && !shouldFillTicketPrice"
                          :company="company"
                          :companies="companies"
                          @select-company="selectCompany"
                          ref="companySelect" />

          <bank-select v-if="showBankSelect"
                       :bank="bank"
                       :banks="selectedCompany.bank"
                       @select-bank="selectBank"
                       ref="bankSelect" />

          <div v-if="showParams" class="mt-3">
            <span v-if="selectedDocument?.name !== 'transfer-agreement'">Параметры:</span>

            <transfer-agreement v-if="selectedDocument?.name === 'transfer-agreement'"
                                :ticket-id="ticketId"
                                @update="formChanged = true"
                                @generated="successGenerated"
                                ref="transfer-agreement" />
            <template v-else-if="selectedDocument?.params?.length">
              <div class="parameters">
                <div v-for="group in unitedGroups" :key="group" :class="{'group-block': group.groups.length && group.params.length}">

                  <grouped-text-block-param v-if="group?.params?.length > 0"
                                            :groups="group.groups"
                                            :params="group.params"
                                            :person-params="personParams"
                                            @update="(key, value, groupName, autoset) => updateParamValue(key, value, groupName, autoset)"
                                            @radioValid="(group, valid) => updateRadioValid(group, valid)"
                                            @hide-child="(key, g) => hideChildParams(key, g)"
                                            ref="field" />

                  <div v-for="(param, index) in getChildByGroup(group)" :key="index + param.key" :class="{'group-params': group.groups.length && group.params.length}">

                    <template v-if="param?.show !== false && (typeof param.showIn !== 'string' || group.groups.includes(param.showIn)) && checkShowCondition(param)">
                      <text-param v-if="param.type === 'text'"
                                  :param="param"
                                  @update="(val) => updateParamValue(param.key, val)" ref="field" />

                      <date-param v-if="param.type === 'date'"
                                  :param="param"
                                  @update="(val) => updateParamValue(param.key, val)" ref="field" />

                      <ticket-param v-if="param.type === 'ticketparam'"
                                    :keyword="param"
                                    :personParam="findPersonParam(param)"
                                    :disabled="loading.personparam"
                                    @update="(val, autoset) => updateParamValue(param.key, val, null, autoset)"
                                    ref="field" />

                      <person-param v-if="param.type === 'personparam'"
                                    :keyword="param"
                                    :personParam="findPersonParam(param)"
                                    :can-change-person="canChangePerson"
                                    :disabled="loading.personparam"
                                    @update="(val, autoset) => updateParamValue(param.key, val, null, autoset)"
                                    @updateClient="(val) => setUpdateClient(param.key, val)"
                                    ref="field" />

                      <!--autogenerated v-if="param.type === 'autogenerated'"
                                    :param="param" /-->

                      <text-block-param v-if="param.type === 'textblock' && param.condition === 'choice'"
                                        :param="param"
                                        @update="(val) => updateParamValue(param.key, val)"
                                        @hide-child="(key) => hideChildParams(key)" />
                    </template>

                  </div>

                </div>

              </div>
            </template>
          </div>

        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
          <button type="button" class="btn btn-primary" @click="generateDocument" :disabled="submitDisabled">
            <span v-if="loading.generate" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Создать
          </button>
        </template>
      </modal-window>
    </transition>

    <modal-window v-if="modal.showClose" @close="modal.showClose = false" @success="_closeModal" ok-button-style="btn-danger">
      <template v-slot:header>
        <h5 class="modal-title text-danger">Подтверждение действия</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите закрыть форму генерации документа? Введенные вами данные не сохранятся.</span>
      </template>
    </modal-window>
  </teleport>
</template>

<script>
import {mapGetters} from "vuex";
import {CrmApi} from "../../../../library/CrmApi";
import {Documents} from "../../../../library/api/Documents";
import ModalWindow from "../ModalWindow";
import TextParam from "./Params/TextParam";
import PersonParam from "./Params/PersonParam";
import Autogenerated from "./Params/Autogenerated";
import TextBlockParam from "./Params/TextBlockParam";
import GroupedTextBlockParam from "./Params/GroupedTextBlockParam";
import DateParam from "./Params/DateParam";
import TransferAgreement from "./TransferAgreement";
import Company from "./Select/Company";
import Bank from "./Select/Bank";

export default {
  name: "Documents",

  components: {
    Autogenerated,
    TextBlockParam,
    PersonParam,
    TicketParam: PersonParam,
    TextParam,
    GroupedTextBlockParam,
    DateParam,
    ModalWindow,
    TransferAgreement,
    CompanySelect: Company,
    BankSelect: Bank,
  },

  props: {
    ticketId: {
      require: true
    },
  },

  data() {
    return {
      modal: {
        show: false,
        selectedDocument: null,
        showClose: false,
      },

      personParams: [],
      canChangePerson: false,

      updateClient: {},

      showCompanySelect: false,
      showBankSelect: false,
      companies: null,
      company: null,
      bank: null,

      radioValid: {},
      formChanged: false,

      loading: {
        documents: false,
        companies: false,
        personparam: false,
        generate: false,
      },
      generatedEventId: null,
    }
  },

  computed: {
    documents: {
      get() {
        return this.$store.getters['thread/getDocuments'];
      },
      set(documents) {
        this.$store.commit('thread/setDocuments', documents);
      }
    },

    document: {
      get() {
        return this.$store.getters['thread/getDocument'];
      },
      set(document) {
        this.$store.commit('thread/setDocument', document);
      }
    },

    submitDisabled() {
      return (Object.values(this.loading).some(loading => loading));
    },

    selectedDocument() {
      let document = null;

      if(this.modal.selectedDocument !== null) {
        if(this.isPresetTemplate) {
          document = {name: this.modal.selectedDocument};
        } else {
          document = this.documents.filter(document => document._id === this.modal.selectedDocument)[0];
        }
      }

      return document;
    },

    showParams() {
      return this.selectedDocument !== null &&
             !this.shouldFillTicketPrice &&
             (!this.showCompanySelect || this.company !== null) &&
             (!this.showBankSelect || this.bank !== null)
    },

    isPresetTemplate() {
      return ['transfer-agreement'].includes(this.modal.selectedDocument);
    },

    selectedCompany() {
      return this.company !== null
                ? this.companies.filter(company => company._id === this.company)[0]
                : null;
    },

    groups() {
      return typeof this.selectedDocument === 'object'
                ? this.selectedDocument.params.map(param => param.group)
                                              .filter((group, index, groups) => (typeof group === 'string') &&
                                                                               (groups.indexOf(group) === index))
                : [];
    },

    unitedGroups() {
      let groups = [];
      this.groups.forEach(group => {
        let groupParams = this.getGroupedParams(group);
        let existsGroup = groups.find(g => {
          return _.isEqual(g.params.map(param => param.key).sort(), groupParams.map(param => param.key).sort())
        });
        if(typeof existsGroup !== 'undefined') {
          existsGroup.groups.push(group);
        } else {
          groups.push({groups: [group], params: groupParams});
        }
      });

      groups.push({groups: [], children: this.unGroupedParams});

      return groups;
    },

    unGroupedParams() {
      return this.selectedDocument.params.filter(p => typeof p.group !== 'string');
    },

    showDocumentsButton() {
      return Array.isArray(this.documents) && this.documents.length;
    },

    showTransferAgreement() {
      return (this.ticket?.assigned_product?.additional_fields ?? []).some(field => ['sell_ip', 'buy_ip'].includes(field.type));
    },

    generatedEventLink() {
      let result = '';
      if (this.generatedEventId) {
        result = '/tickets/' +
                 encodeURIComponent(window.getId(this.ticketId)) +
                 '?message=' +
                 encodeURIComponent(window.getId(this.generatedEventId));
      }
      return result;
    },

    shouldFillTicketPrice() {
      if(this.selectedDocument === null) {
        return false;
      }

      let priceParams = this.selectedDocument?.params?.filter(param => param.type === 'ticketparam' && param?.format?.name === 'price');

      let priceRequired = priceParams?.some(param => {
        if(param.required) {
          return true;
        }

        // проверяем каждое поле в документе, которому нужно значение price
        if(param?.parent.length) {
          let parent = param.parent[0].split(':')[0];
          let groupVariants = this.getGroupedParams(parent);

          // находим группы в котором оно используется, и проверяем что price нужен в каждой группе - то есть поля обязательное
          if(groupVariants?.length) {
            return groupVariants.every(variant => {
              return this.getChildParams(variant.key, variant.group)?.some(param => param?.format?.name === 'price');
            })
          }
        }
      });

      return priceRequired && !this.ticket?.assigned_product?.fields?.price;
    },

    ...mapGetters({
      ticket: "thread/getTicket",
    }),
  },

  methods: {
    getRequiredFields() {
      let fields = [];

      if(this.showCompanySelect) {
        fields.push(this.$refs.companySelect);
      }
      if(this.showBankSelect) {
        fields.push(this.$refs.bankSelect);
      }

      if(Array.isArray(this.$refs?.field)) {
        fields = fields.concat(this.$refs.field);
      } else if(this.isPresetTemplate && typeof this.$refs[this.selectedDocument?.name] !== 'undefined') {
        fields = fields.concat([this.$refs[this.selectedDocument.name]]);
      }

      return fields;
    },

    isFormValid() {
      let valid = true;
      let requiredFields = this.getRequiredFields();

      if(requiredFields.length > 0) {
        let validArray = [];
        requiredFields.forEach(field => {
          validArray.push(field.isValid());
        });
        valid = !validArray.some(value => !value);
      }

      return valid;
    },

    successGenerated(file) {
      window.open(file);

      if(Object.values(this.updateClient).some(value => value)) {
        CrmApi.getThreadPerson(this.ticketId, true).then((result) => {
          this.$store.commit('thread/setPerson', result.data.person);
        });
      }

      this.modal.selectedDocument = null;
      this.document.params = [];
      this.company = null;

      this._closeModal();
    },

    generateDocument() {
      if(!this.isFormValid()) {
        if(this.isPresetTemplate) {
          if(typeof this.$refs[this.selectedDocument.name] !== 'undefined') {
            this.$refs[this.selectedDocument.name].focusInvalid();
          }
        } else {
          let requiredFields = this.getRequiredFields();
          for(let i = 0; i < requiredFields.length; i++) {
            if(!requiredFields[i].valid) {
              requiredFields[i].focus();
              break;
            }
          }
        }

        return;
      }

      this.loading.generate = true;

      // если это вшитый шаблон с кастомным методом генерации, вызываем его
      if(this.isPresetTemplate) {
        let tpl = this.$refs[this.selectedDocument.name] ?? null;
        if(tpl !== null && typeof tpl.submit === 'function') {
          tpl.submit().finally(() => this.loading.generate = false);
          return;
        }
      }

      let request = {
        document: this.selectedDocument._id,
        params: []
      };

      if(this.company !== null) {
        request.company = window.getId(this.company);
      }

      if(this.bank !== null) {
        request.bank = this.bank;
      }

      this.document.params.forEach(param => {
        let item = {
          key: param.key,
          value: param.value,
          type: param.type,
        };
        if(typeof param?.group === 'string') {
          item.group = param.group;
        }
        request.params.push(item);
      });

      if(Object.keys(this.updateClient).length) {
        Object.entries(this.updateClient).forEach(([key, update]) => {
          if(update) {
            let param = this.personParams.find(item => item.key === key && !item?.wordCase);
            if(param && this.getDocumentParam(key).value !== param.value) {
              request.params.filter(param => param.key === key)[0].update = true;
            }
          }
        });
      }

      Documents.generateDocument(this.ticketId, request)
               .then(response => this.successGenerated(response.data.file))
               .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
               .finally(() => this.loading.generate = false);
    },

    setUpdateClient(key, value) {
      this.updateClient[key] = value;
    },

    getChildParams(key, group = null) {
      key = (group === null) ? key : `${group}:${key}`;
      return this.selectedDocument.params.filter(param => Array.isArray(param.parent) && param.parent.includes(key));
    },

    getChildByGroup(group) {
      let child = group.children ?? group?.params?.map(param => this.getChildParams(param.key, param?.group)).flat() ?? [];

      child = _.uniqBy(child, 'key');
      child = _.orderBy(
          child,
          [el => (el.parent?.length ?? 1) > 1, 'order'],
          ['desc', 'asc']
      );

      return child;
    },

    hideChildParams(key, group = null) {
      this.getChildParams(key, group).forEach(param => param.show = false);
    },

    updateParamValue(key, value, group = null, autoset = false) {
      if(!autoset) {
        this.formChanged = true;
      }

      if(value === true && group !== null) {
        // у блоков с переключателем radio, устанавливаем значения всех остальных полей в false
        this.getGroupedParams(group).filter(param => param.key !== key &&
                                                    typeof param?.condition === 'string' &&
                                                    ['choice', 'country', 'legaltype'].includes(param.condition))
                                    .forEach(param => this.updateParamValue(param.key, false, group, autoset));
      }

      let dp = this.getDocumentParam(key, group);
      if(dp !== null) {
        dp.value = value;
        if(dp.type === 'textblock') {
          this.setShowChildParams(key, group, value);
        }
      }
    },

    checkShowCondition(keyword) {
      let show = true;

      if(Array.isArray(keyword.showConditions ?? null) && keyword.showConditions.length) {

        show = keyword.showConditions.some(showCondition => {

          if(typeof showCondition === 'string') {
            // deprecated format
            showCondition = [showCondition];
          }

          let condAnd = [];
          showCondition.forEach(condition => {
            let condOr = [];
            let [group, keys] = condition.split(':');

            keys.split(',').forEach(key => condOr.push(group + ':' + key));
            condAnd.push(condOr);
          });

          return condAnd.every(
              condOr => condOr.some(
                  condParam => this.document.params.find(
                      docParam => docParam?.group &&
                                  docParam.value &&
                                  condParam === `${docParam.group}:${docParam.key}`
                  )
              )
          );
        });

      }

      return show;
    },

    findPersonParam(param) {
      return this.personParams.find(item => item.key === param.key);
    },

    setShowChildParams(key, group, show = true) {
      let child = this.getChildParams(key, group);

      if(show) {
        child.forEach(param => {
          param.show = true;
          if(typeof param.showIn !== 'string') {
            param.showIn = group;
          }
        });
      } else {
        child.forEach(param => {
          if(param.parent.length === 1) {
            // если параметр входит только в один блок текста, то сразу скрываем
            param.show = false;
          } else {
            // перебираем блоки текста в которые входит параметр, если хотя бы один блок выбран, то выводим поле
            param.show = param.parent.some(parent => {
              let [group, k] = parent.split(':');
              parent = this.document.params.find(param => param.type === 'textblock' &&
                                                          param.key === k &&
                                                          param.group === group);

              return parent !== null && parent?.value === true;
            });
          }

          if(param.show === false) {
            param.showIn = undefined;
          }
        })
      }
    },

    updateRadioValid(group, valid) {
      this.radioValid[group] = valid;
    },

    getGroupedParams(group) {
      let params = [];

      if(group !== '') {
        params = this.selectedDocument.params.filter(param => param.group === group &&
                                                              param.type === 'textblock' &&
                                                              ['choice', 'country', 'legaltype'].includes(param.condition));
      }

      return params;
    },

    toDefaultValues() {
      this.showCompanySelect = false;
      this.showBankSelect = false;
      this.company = null;
      this.bank = null;
      this.document.params = [];
      this.updateClient = {};
    },

    getDocumentParam(key, group = null) {
      let result = null;
      if(Array.isArray(this.document?.params)) {
        let filter = this.document.params.filter(param => (param.key === key && (group === null || param.group === group)));
        if (filter.length) {
          result = filter[0];
        }
      }
      return result;
    },

    loadGeneratedTemplates() {
      this.generatedEventId = null;
      Documents.getGeneratedDocumentEvents(this.ticketId, this.modal.selectedDocument).then((result) => {
        if (typeof result.data?.data !== 'undefined' && Array.isArray(result.data?.data) && result.data.data.length) {
          let element = result.data.data[0];
          if (typeof element._id !== 'undefined' && element._id) {
            this.generatedEventId = window.getId(element._id);
          }
        }
      }).catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));
    },

    selectTemplate() {
      this.formChanged = false;
      this.loadGeneratedTemplates();
      this.$nextTick(() => {
        this.toDefaultValues();
        (this.documents.filter(document => document._id === this.modal.selectedDocument)[0]?.params ?? [])
                       .forEach(param => {
                         param = {...param};
                         param.value = (param.type === 'textblock') ? false : '';
                         this.document.params.push(param);
                       });

        if(!Array.isArray(this.selectedDocument?.params)) {
          this.selectedDocument.params = [];
        }

        let loadCompanies = false;

        this.selectedDocument.params = _.orderBy(this.selectedDocument.params, 'order');

        for(let i = 0; i < this.selectedDocument.params.length; i++) {
          let param = this.selectedDocument.params[i];
          if(param.type === 'companyparam' || (param.type === 'textblock' && ['company', 'entrepreneur'].includes(param.condition))) {
            this.showCompanySelect = true;
            if(this.companies === null) {
              loadCompanies = true;
              this.loadCompanies(true);
            }
            break;
          }
        }

        if(!loadCompanies) {
          this.setDefaultCompany();
        }
      });
    },

    setDefaultCompany() {
      if(typeof this.selectedDocument.default_company !== 'undefined' &&
          this.selectedDocument.default_company !== null) {

        this.company = window.getId(this.selectedDocument.default_company);
        // триггер выбора компании, чтобы отобразить селект банка, если он нужен
        this.selectCompany(this.company);
      }
    },

    loadCompanies(setDefaultCompany = false) {
      this.loading.companies = true;
      Documents.getCompanies(this.ticketId)
               .then(response => {
                 this.companies = response.data.data;
                 if(setDefaultCompany) {
                   this.setDefaultCompany();
                 }
               })
               .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
               .finally(() => this.loading.companies = false);
    },

    selectCompany(company) {
      this.company = company;
      this.bank = null;
      this.showBankSelect = false;

      if(Array.isArray(this.selectedCompany?.bank) && this.selectedCompany.bank.length > 0) {
        if(this.selectedCompany.bank.length > 1) {
          if (this.selectedDocument.params.some(param => param?.companyparam?.indexOf('company.bank.') === 0)) {
            this.showBankSelect = true;
            this.bank = this.selectedCompany.bank.find(bank => bank.default)?.name ?? null;
          }
        } else if(this.selectedCompany.bank.length === 1) {
          this.bank = this.selectedCompany.bank[0].name;
        }
      }
    },

    selectBank(bank) {
      this.bank = bank;
    },

    showModal() {
      this.modal.show = true
    },

    closeModal() {
      if(this.formChanged) {
        this.modal.showClose = true;
      } else {
        this._closeModal();
      }
    },

    _closeModal() {
      this.toDefaultValues();
      this.modal.showClose = false;
      this.modal.selectedDocument = null;
      this.modal.show = false
      this.generatedEventId = null;
    }
  },

  watch: {
    'modal.selectedDocument' (to) {
      if(to !== null) {
        this.personParams = [];
        let document = this.selectedDocument;
        if(document !== null && typeof document?.params === 'object') {

          let params = document.params.filter(
                                          param => ['personparam', 'ticketparam'].includes(param.type) ||
                                                   param.type === 'textblock' && ['country', 'legaltype'].includes(param?.condition)
                                      );

          if(params.length) {

            this.loading.personparam = true;
            Documents.threadPersonParam(this.ticketId, this.selectedDocument._id).then(result => {
              this.personParams = result.data.params;
              this.personParams.forEach(param => this.updateClient[param.key] = false);

              params.filter(param => param.type === 'textblock')
                    .forEach(param => {

                      // проверяем текстовые блоки документа, которые используют подгруженные значения
                      // клиента, как условия их вывода. Если условие неверно, то скрываем поля которые входят в эти блоки
                      if(this.personParams.find(pp => pp.key === param.key)?.value !== param?.conditionparam) {
                        this.hideChildParams(param.key, param?.group ?? null);
                      }
              });

              this.canChangePerson = true;
            }).catch(error => {
              if(error?.response?.status !== 403) {
                this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
              }
            }).finally(() => this.loading.personparam = false);

          }
        }
      }
    }
  }
}
</script>

<style scoped>
.parameters {
  padding: 1.5rem;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border: 1px solid #dee2e6;
  margin: 0.2rem 0 0;
  position: relative;
}
:deep(.modal-body) {
  max-height: 70vh;
}
:deep(.required-field) {
  color: red;
  padding: 2px;
}
.group-block {
  padding: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #80808061;
}
.group-block :deep(.group-label) {
  font-weight: bold;
  font-size: 1.05em;
}
.group-params {
  padding-left: 10px;
}
</style>