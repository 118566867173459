import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from './getters'
import filters from "./filters/filters";
import create from "./create/create";
import projects from "./projects/projects";
import product from "./product/product";
const state = {
    ticketList:[],
    autoupdate:null,
    autoUpdateTrigger:true,
    listenLink: null,
    paginate:{
    },
    ticketSelectedControl: {
        length:0,
        objects:{},
        lastSelectedElem:null,
    },
    lastSelectedIds: {
        ids:[],
        lastSelectedPosition:null,
    },
    ticketLoadingControl:{
        length:0,
        objects:{},
        loadingAll: false,
    },

    search:{
        searchString:"",
        showFilter:false,
    },

    resetCounter: false,
    escapeRefresh:false,

    toLastSelect:true,
    tryToScrollAfterTick:null,
}

export const moduleTickets = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters,
    modules:{
        filters,
        create,
        projects,
        product,
    }
}