<template>
  <modal-window @close="closeModal" @success="setTags">
    <template v-slot:header>
      <h5 class="modal-title text-success" ><i class="fa fa-tags button-icon "></i>Добавление меток</h5>
    </template>
    <template v-slot:body>
      <tags-body :source="source" @setDisabled="setDisabled" @changeState="changeState"></tags-body>
    </template>
    <template v-slot:footer>
      <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
      <button type="button" class="btn btn-primary" v-if="canChangeTags" :disabled="disabled" @click="setTags">Изменить метки</button>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "../ModalWindow";
import TagsBody from "./TagsBody";
import {UserPermission} from "../../../../users/UserPermission";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "TagsModal",

  components: {
    ModalWindow,
    TagsBody,
  },

  emits: [
    'close',
  ],

  props:  {
    callback: {
      default: null,
    },
  },

  mounted() {
    UserPermission.can("system.tag_control").then(()=>{
      this.canChangeTags = true;
    }).catch(()=>{});
  },

  data:function (){
    return {
      canChangeTags: false,
      disabled: false,
      source: {
        tags: [],
        changes: {
          add: [],
          remove: [],
        },
        full: true,
      }
    }
  },

  computed: {
    ...mapGetters({
      "getSelectedArray": 'tickets/getSelectedArray',
      'getSelectedObjects': 'tickets/getSelectedObjects',
    }),
  },

  methods: {
    setDisabled(value) {
      this.disabled = value;
    },

    setTags() {
      let data = {
        ids:this.getSelectedArray,
        route: this.callback ?? this.$route,
      }
      if (this.source.full) {
        data.tags = this.source.tags;
      } else {
        data.tags_data = this.source.changes;
      }
      this.addTags(data);
      this.closeModal();
    },

    closeModal() {
      this.$emit('close');
    },

    changeState(value, action) {
      switch (action) {
        case 'select':
          this.source.changes.add.push(value);
          break;
        case 'deselect':
          this.source.changes.remove.push(value);
          break;
        case 'clear':
          this.source.changes.remove = this.source.changes.remove.concat(value);
          break;
      }
      let newAdd = this.source.changes.add.filter(x => !this.source.changes.remove.includes(x));
      let newRemove = this.source.changes.remove.filter(x => !this.source.changes.add.includes(x));
      this.source.changes.add = newAdd;
      this.source.changes.remove = newRemove;
    },

    ...mapActions({
      "addTags": "tickets/addTags",
    }),
  }
}
</script>

<style scoped>

</style>