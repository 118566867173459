<template>
    <span>{{diff}}</span>
</template>

<script>
import moment from "moment";

export default {
    name: "timediff",
    props:{
        /** Difference between from time ... */
        from:{
            require: true,
        },

        /** and to time */
        to:{
            require: true,
        },

        defaultValue: {
          default: "undefined",
        },
    },
    data:function(){
        return {
            dateUnitMap:[
                {
                    unitName: "years",
                    slug: function ( number ){
                      let slug = "лет";

                      let unitMap = {
                        "0": "лет",
                        "1": "год",
                        "2": "годa",
                        "3": "годa",
                        "4": "годa",
                        "5": "лет",
                        "6": "лет",
                        "7": "лет",
                        "8": "лет",
                        "9": "лет",
                      };
                      if(  number != null ) {
                        let determiner = number.toString().substring(number.toString().length-2);

                        if (determiner.length !== 2 || determiner[0] !== "1") {
                          slug = unitMap[determiner.substring(determiner.length-1)];
                        }
                      }

                      return slug;
                    }
                },
                {
                    unitName: "months",
                    slug: "мес."
                },
                {
                    unitName: "weeks",
                    slug: "нед."
                },
                {
                    unitName: "days",
                    slug: "д."
                },
                {
                    unitName: "hours",
                    slug: "ч."
                },
                {
                    unitName: "minutes",
                    slug: "м."
                },
                {
                    unitName: "seconds",
                    slug: "с."
                },
            ]
        };
    },
    methods:{
      getSlug(unitName){
        let BreakException = {};
        let slug = "";
        try {
          this.dateUnitMap.forEach((unitMeta) => {
            if(unitMeta.unitName === unitName){
              slug = this.buildSlug(unitMeta.slug, null);
              throw BreakException;
            }
          });
        }catch (e){}

        return slug;
      },

      buildSlug(slug, amount){
        return typeof slug === "function" ? slug(amount) : slug;
      }
    },
    computed:{
        diff(){
            let foundGraterThanNul = false;
            let stopCycle = false;
            let diff = this.defaultValue;
            let momentFrom;
            let momentTo;
            let sign = ''
            if(moment(this.from).unix() <=  moment(this.to).unix()){
              momentFrom = moment(this.from);
              momentTo = moment(this.to);
            }else {
              sign = '- '
              momentFrom = moment(this.to);
              momentTo = moment(this.from);
            }

            if (momentTo.unix() - momentFrom.unix() === 0) {
              diff = "0 " + this.getSlug('seconds');
            } else {
                this.dateUnitMap.forEach((unitMeta) => {
                    if( stopCycle ){
                        return;
                    }
                    let currentDiff = momentTo.diff(momentFrom, unitMeta.unitName);
                    if( foundGraterThanNul ){
                        stopCycle = true;
                        if( currentDiff > 0 ){
                            diff += " " + currentDiff + " " + this.buildSlug(unitMeta.slug, currentDiff);
                        }
                    } else {
                        if( currentDiff > 0){
                            foundGraterThanNul = true;
                            diff = currentDiff + " " + this.buildSlug(unitMeta.slug, currentDiff);
                            momentFrom.add(currentDiff,unitMeta.unitName);
                        }
                    }
                });
            }

            return sign + diff;
        }
    }
}
</script>

<style scoped>

</style>
