<template>
  <div class="col-12" style="text-align: center">
    <div class="message message-task position-relative d-inline-block" :class="{'message-task-done': isDone, 'message-task-sending': sending || upload}" @mouseover="showContextButton = true"  @mouseout="showContextButton = false">
      <div class="col-12 datetime-stamp text-end">{{messageDate}}</div>
      <div class="message-task-label">
        <span class="text-secondary" style="margin-right: 22px" v-if="isDone">
          <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="completedDateOutput">Задача завершена</popper>
        </span>
        <span class="text-secondary" style="margin-right: 22px" v-else>Задача</span>

        <div v-show="showContextButton" class="contextFromButton">
          <context-button
              :custom-button-style="{
                'border-style':'none',
                'background-color':'#FBE8DF',
                'outline':'none'
              }"
              :messageid="message._id"
              :show="showFunctions"
              :callbacks="{callAttachment: callAttachment, deleteTask: openTaskDeleteModal}"
          ></context-button>
        </div>
      </div>
      <div class="message-task-top" :data-bs-toggle="isDone ? 'collapse' : 'none'" :data-bs-target="'#message-task-bottom-' + message._id" :aria-expanded="!isDone">
        <div class="message-task-header" ref="message-task-header">
          <editable-text
              v-if="canChangeSubjectAndAssignee"
              :trim="true"
              :text="message.subject"
              default="&mdash;"
              :callback="changeName"
              :required="true"
              :validation="t => !!t"
              :stringStyle="subjectStringStyle"
              input-class="name-input form-control form-control-sm text-center"
          ></editable-text>
          <string-trim v-else :string="message.subject ?? '&mdash;'" :style="subjectStringStyle" />
        </div>
        <div class="message-task-completed-by text-muted text-center" v-if="isDone && completedByInfo">
          {{ completedByInfo }}
        </div>
      </div>
      <div :id="'message-task-bottom-' + message._id" :class="{collapse: isDone}" class="message-task-bottom">
        <div class="sender-block mt-0">
          <div class="message-task-meta">
            <div>Постановщик: {{ authorName }}</div>

            <div>
              Ответственный:
              <span v-if="canChangeSubjectAndAssignee">
                <editable-select
                  :options="users"
                  :selected="assigneeId"
                  @change="setAssignee"
                ></editable-select>
              </span>
              <span v-else>{{assigneeName}}</span>
            </div>

            <div v-if="isDone && completedByName">
              Выполнил(а): <span>{{ completedByName }}</span>
            </div>

            <div class="message-task-watchers" v-if="!isDone || watchers.length">
              Наблюдатели:
              <span v-for="(watcher, index) in watchers" class="watcher">
                {{getUserName(watcher)}}<span v-if="!isDone" @click="removeWatcher(watcher)" class="remove-watcher blue-icon mx-1"><i class="fa fa-minus-circle"></i></span><template v-if="index !== watchers.length - 1">, </template>
              </span>
              <span v-if="!isDone && usersForWatchersSelect.length" class="watchers-add blue-icon mx-1" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fa fa-plus-circle"></i></span>
              <div v-if="!isDone && usersForWatchersSelect.length" class="dropdown-menu watchers-add-menu" aria-labelledby="steps-after-btn">
                <div
                    v-for="watcher in usersForWatchersSelect"
                    class="dropdown-item btn"
                    @click="addWatcher(watcher.value)"
                >{{ watcher.name }}</div>
              </div>
            </div>

            <div v-if="!isDone" class="datepicker-group">Срок:
              <span class="d-inline-flex" :class="deadlineDateClass">
                <editable-date
                    v-if="canEdit"
                    default="&mdash;"
                    input-class="form-control"
                    mode="datetime"
                    :required="true"
                    :validation="validateDate"
                    :text="deadlineDate"
                    :callback="updateDate"
                    :format-label="formatDate"
                    :format-datepicker="formatDate"
                ></editable-date>
                <span v-else> {{ deadlineDateOutput }}</span>
                <span v-if="deadlineDate" class="ms-1">
                  <span v-if="!isExpired">(осталось <timediff :from="now" :to="deadlineDate" default-value="0 с."></timediff>)</span>
                  <span v-else>(просрочена на <timediff :from="deadlineDate" :to="now" default-value="0 с."></timediff>)</span>
                </span>
              </span>
            </div>

            <div v-if="isDone">
              Выполнена:
              <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="'Срок: ' + deadlineDateOutput">
                <span :class="completedDateClass">
                {{ completedDateOutput ?? '&mdash;' }}
                  <span v-if="isExpired">
                    (просрочена на <timediff :from="deadlineDate" :to="completedDate" default-value="0 с."></timediff>)
                  </span>
                </span>
              </popper>
            </div>

            <div class="attachments-block" v-if="message.attachments">
              <span
                v-for="attachment in message.attachments"
                class="single-attachment me-1"
              >
                <attachment
                  :attachment="attachment"
                  :messageid="message._id"
                  :key="attachment.id"
                  :can-open-attachment="isAssignee || isAuthor"
                ></attachment>
                <span v-if="canEdit" class="remove-attachment blue-icon me-1" @click="openRemoveAttachmentModal(attachment)"><i class="fa fa-minus-circle"></i></span>
              </span>
              <span v-if="canEdit" class="add-attachment ms-1">
                <label :for="'attachment-input_' + message._id" class="blue-icon" :class="{disabled: upload}">
                  <i class="fa fa-plus-circle"></i>
                </label>
              </span>
            </div>
            <input v-if="canEdit" ref="attachment" type="file" :id="'attachment-input_' + message._id" class="d-none" @change="uploadAttachment">
          </div>
        </div>
        <div class="message-task-body" ref="message-task-body">
          <editable-text
              v-if="canEdit"
              default="&mdash;"
              :text="message.body"
              :callback="changeBody"
              :asTextArea="true"
              :markdown="true"
              :markdown-options="{linksInNewTab: true}"
              :expandable="true"
              :prevent-focus-out="true"
              stringStyle="--bs-primary-rgb: black;"
              input-class="name-input form-control form-control-sm"
          ></editable-text>
          <span v-else><span v-html="getMarkdown()"></span></span>
        </div>
        <div class="message-task-buttons">
          <button type="button" class="btn btn-sm btn-primary" v-if="!isDone && (isAuthor || isAssignee || canTasksControl)" @click="closeTask">Завершить</button>
          <button type="button" class="btn btn-sm btn-primary" v-if="isDone && (isAuthor || isAssignee || canTasksControl)" @click="reopenTask">Переоткрыть</button>
        </div>
      </div>
    </div>
    <task-done-modal
      v-if="showDoneModal"
      :message="message"
      @close="showDoneModal = false"
      @update="closeTask"
    ></task-done-modal>
    <task-delete-modal
      v-if="showDeleteModal"
      :message="message"
      @close="closeTaskDeleteModal"
      @update="deleteTask"
    ></task-delete-modal>
    <remove-attachment-modal
      v-if="attachmentToRemove"
      :attachment="attachmentToRemove"
      @close="closeRemoveAttachmentModal"
      @update="removeAttachment"
    ></remove-attachment-modal>
  </div>
</template>

<script>
import {getDateFormat, getMarkdown} from "../Api/api";
import ContextButton from "../Message/ContextMenu/ContextButton";
import {getNameWithLogin} from "../../../library/userName";
import {mapActions, mapGetters} from "vuex";
import EditableSelect from "../../Editable/EditableSelect";
import EditableText from "../../Editable/EditableText";
import StringTrim from "../../string/StringTrim";
import EditableDate from "../../Editable/EditableDate";
import moment from "moment";
import Timediff from "../../common/timediff";
import Attachment from "../Message/Attachment";
import TaskDoneModal from "./TaskDoneModal";
import {CrmApi} from "../../../library/CrmApi";
import RemoveAttachmentModal from "./RemoveAttachmentModal";
import SimpleSelect from "../../common/SimpleSelect";
import TaskDeleteModal from "./TaskDeleteModal";
import {Task} from "../../../library/api/Task";
import {baseHighlight, popperHighlight} from "../../../library/Functions";

export default {
  name: "Task",

  components: {
    ContextButton,
    EditableSelect,
    EditableText,
    EditableDate,
    StringTrim,
    Timediff,
    Attachment,
    TaskDoneModal,
    RemoveAttachmentModal,
    SimpleSelect,
    TaskDeleteModal,
  },

  props:{
    message: {
      type: Object,
      require:true
    },
  },

  emits: [
      'updateMessage',
      'deleteMessage',
  ],

  data: function (){
    return {
      showContextButton: false,
      showWatchersButton: false,
      showDoneModal: false,
      showDeleteModal: false,
      formatDate: 'DD.MM.YYYY HH:mm',
      attachmentToRemove: null,
      upload: false,
      sending: false,
      now: moment(),
      interval: null,
    }
  },

  mounted() {
    let self = this;
    this.interval = setInterval(() => self.now = moment(), 1000);
    this.highlight();
  },

  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  },

  computed: {
    messageDate: function () {
      return getDateFormat(this.message.created, window.dateFormatWeekDaySeconds);
    },
    authorName() {
      return getNameWithLogin(this.message?.author);
    },
    taskStatus() {
      return this.message?.headers?.status ?? '';
    },
    isDone() {
      return this.taskStatus === 'DONE';
    },
    watchers() {
      return this.message?.headers?.watchers ?? [];
    },
    assignee() {
      return this.message?.headers?.assignee ?? null;
    },
    completedBy() {
      return this.message?.headers?.completed_by ?? null;
    },
    completedByName() {
      return this.completedBy ? getNameWithLogin(this.completedBy) : '';
    },
    completedByInfo() {
      let result = '';
      if (this.completedBy) {
        result = this.completedDateOutput + ' ' + getNameWithLogin(this.completedBy) + ' закрыл(а) задачу';
      }
      return result;
    },
    assigneeName() {
      return this.assignee ? getNameWithLogin(this.assignee) : '&mdash;';
    },
    assigneeId() {
      let result = null;
      if (typeof this.message?.headers?.assignee?._id !== 'undefined' && this.message?.headers?.assignee?._id) {
        result = window.getId(this.message?.headers?.assignee?._id);
      }
      return result;
    },
    isAuthor() {
      return window.getId(this.message?.author?._id) === window.userId;
    },
    isAssignee() {
      return this.assigneeId === window.userId;
    },
    deadlineDate() {
      return this.message?.headers?.deadlinedate ?? null;
    },
    deadlineDateOutput() {
      return this.deadlineDate ? moment(this.deadlineDate).format(this.formatDate) : null;
    },
    isExpired() {
      return moment(this.deadlineDate).isBefore(this.now);
    },
    deadlineDateClass() {
      return this.deadlineDate && this.isExpired ? 'text-danger' : '';
    },
    completedDate(){
      return this.message?.headers?.completed ?? null;
    },
    completedDateOutput() {
      return this.completedDate ? moment(this.completedDate).format(this.formatDate) : null;
    },
    completedDateClass() {
      return this.completedDate && this.deadlineDate && this.isExpired ? 'text-danger' : '';
    },
    users() {
      let userId = window.getId(window.userId);
      let result = {};
      result[userId] = getNameWithLogin(window.userData ?? {});

      if (Array.isArray(this.getUsers) && this.getUsers.length) {
        this.getUsers.filter(user => window.getId(user._id) !== window.getId(window.userId)).forEach(user => {
          result[window.getId(user._id)] = getNameWithLogin(user);
        });
      } else if (typeof this.getUsers === 'object') {
        Object.entries(this.getUsers).forEach((el) => {
          if (window.getId(el[0]) !== window.getId(window.userId)) {
            result[el[0]] = el[1];
          }
        });
      }

      if (this.assigneeId && (typeof result[this.assigneeId] === 'undefined' || result[this.assigneeId] === null)) {
        result[this.assigneeId] = getNameWithLogin(this.assignee);
      }

      return result;
    },

    usersForWatchersSelect() {
      let userId = window.getId(window.userId);
      let authorId = window.getId(this.message?.author?._id);
      let result = [];

      if (!this.isUserInWatchers(userId) && authorId !== userId) {
        result.push({value: userId, name: getNameWithLogin(window.userData ?? {})});
      }

      if (Array.isArray(this.getUsers) && this.getUsers.length) {
        this.getUsers.filter(user => window.getId(user._id) !== window.getId(window.userId) && !this.isUserInWatchers(window.getId(user._id)) && authorId !== window.getId(user._id)).forEach(user => {
          result.push({value: window.getId(user._id), name: getNameWithLogin(user)});
        });
      } else if (typeof this.getUsers === 'object') {
        Object.entries(this.getUsers).forEach((el) => {
          if (window.getId(el[0]) !== window.getId(window.userId) && !this.isUserInWatchers(window.getId(el[0])) && authorId !== window.getId(el[0])) {
            result.push({value: el[0], name: el[1]});
          }
        });
      }

      return result;
    },

    subjectStringStyle() {
      let result = 'justify-content: center;';
      if (this.isDone) {
        result += '--bs-primary-rgb: #6c757d; text-decoration: line-through;font-weight: 400;';
      } else {
        result += '--bs-primary-rgb: black;';
      }
      return result;
    },

    showFunctions() {
      let result = [];
      if (this.canEdit) {
        result.push('callAttachment');
      }
      if (this.canDeleteTask) {
        result.push('deleteTask');
      }
      return result;
    },

    canDeleteTask() {
      return this.isUserTaskDeletePermission && this.isAuthor || this.canTasksControl;
    },

    canEdit() {
      return !this.isDone && (this.isAuthor || this.canTasksControl || this.isAssignee);
    },

    canChangeSubjectAndAssignee() {
      return !this.isDone && (this.isAuthor || this.canTasksControl);
    },

    ...mapGetters({
      "canTasksControl": "thread/tasks/canTasksControl",
      "getUsers": "thread/tasks/getUsersForSelect",
      "isUserTaskDeletePermission": "thread/isUserTaskDeletePermission",
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {
    highlight() {
      if (this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        this.$nextTick(() => {
          popperHighlight(
              $(this.$refs['message-task-header']),
              this.searchString
          );
          baseHighlight(
              $(this.$refs['message-task-body']),
              this.searchString,
              this.canEdit ? '.editable-text > span' : null
          );
        });
      }
    },
    setAssignee(newId) {
      let assignee = {
        _id: newId
      };
      if (newId === '') {
        assignee = null;
      }
      if (newId !== this.assigneeId) {
        this.edit({
          headers: {
            "assignee": assignee
          }
        });
      }
    },
    changeName(newName) {
      if (newName !== this.message.subject) {
        this.edit({subject: newName});
      }
    },
    edit(params) {
      this.sending = true;
      return this.editTask({id: this.message._id, params: params}).then((result) => {
        if (typeof result.data?.message !== 'undefined') {
          this.$emit('updateMessage', result.data?.message);
        }
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.sending = false;
        this.highlight();
      });
    },
    sendAction(action, value) {
      this.edit({"action": {type: action, "value": value}});
    },
    addAsWatcher() {
      this.sendAction('ADD_WATCHER', window.userId);
    },
    addWatcher(watcher) {
      if (watcher) {
        this.sendAction('ADD_WATCHER', watcher);
      }
    },
    removeWatcher(watcher) {
      this.sendAction('REMOVE_WATCHER', window.getId(watcher._id));
    },
    isUserWatcher(watcher) {
      return window.getId(watcher._id) === window.userId;
    },
    updateDate(newDate) {
      this.edit({headers: {deadlinedate: moment(newDate, this.formatDate).format()}});
    },
    getUserName(user) {
      return getNameWithLogin(user);
    },
    closeTask() {
      this.edit({headers: {status: 'DONE'}}).then(() => {
        this.showDoneModal = false;
      });
    },
    reopenTask() {
      this.edit({headers: {status: 'OPEN'}});
    },
    changeBody(newBody) {
      if (newBody !== this.message?.body) {
        this.edit({body: newBody});
      }
    },
    getMarkdown() {
      if(!this.message?.body){
        return '';
      }
      return getMarkdown(this.message?.body, false, {linksInNewTab: true});
    },
    uploadAttachment(e) {
      let file = [...e.target.files];
      this.upload = true;
      CrmApi.saveAttachments(window.getId(this.message.ticket._id), file).then((response) => {
        this.addAttachment(response.data.attachments[0]);
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.upload = false;
        e.target.value = '';
      });
    },
    addAttachment(attachment) {
      this.sendAction('ADD_ATTACHMENT', window.getId(attachment.id));
    },
    openRemoveAttachmentModal(attachment) {
      this.attachmentToRemove = attachment;
      this.$store.commit('setActiveWindow', 'removeAttachmentModal');
    },
    closeRemoveAttachmentModal() {
      this.attachmentToRemove = null;
      this.$store.commit('removeActiveWindow', 'removeAttachmentModal');
    },
    removeAttachment(attachment) {
      this.sendAction('REMOVE_ATTACHMENT', window.getId(attachment.id));
      this.closeRemoveAttachmentModal();
    },
    callAttachment() {
      this.$refs.attachment.click();
    },
    openTaskDeleteModal() {
      this.showDeleteModal = true;
      this.$store.commit('setActiveWindow', 'taskDeleteModal');
    },
    closeTaskDeleteModal() {
      this.showDeleteModal = false;
      this.$store.commit('removeActiveWindow', 'taskDeleteModal');
    },
    deleteTask() {
      this.sending = true;
      return Task.deleteTasks([this.message._id]).then((result) => {
        this.closeTaskDeleteModal();
        this.sending = false;
        this.$emit('deleteMessage', this.message);
      }).catch((error) => {
        this.sending = false;
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      });
    },
    isUserInWatchers(userId) {
      let result = false;
      if (this.watchers.length) {
        result = this.watchers.filter((watcher) => window.getId(watcher._id) === userId).length !== 0;
      }
      return result;
    },
    validateDate(value) {
      return moment(value).isValid() || moment(value, this.formatDate).isValid();
    },
    ...mapActions({
      editTask: "thread/tasks/editTask",
    }),
  }
}
</script>

<style scoped>
.message-task {
  min-width: 75%;
  background: #FBE8DF;
  text-align: left;
  border-radius: 20px;
  padding-bottom: 0;
}
.message-task-bottom {
  margin-bottom: 16px;
}
.message-task-top[aria-expanded="false"] {
  padding-bottom: 8px;
}
.message-task-label {
  position: absolute;
  top: 4px;
  right: 16px;
}
.contextFromButton {
  right: -6px;
  top: -4px;
}
.message-task-header {
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  max-width: calc(100% - 77px - 77px);
  margin: 4px auto 0;
}
.message-task-done .message-task-header {
  max-width: calc(100% - 150px - 150px);
}
.watcher .remove-watcher {
  display: none;
}
.watcher:hover .remove-watcher {
  display: inline-block;
}
.blue-icon {
  color: #6262f5;
  cursor: pointer;
}
.message-task-buttons {
  text-align: center;
  margin-top: 8px;
}
.message-task-body {
  margin-top: 8px;
  word-break: break-word;
}
.message-task-body:deep(p) {
  margin-bottom: 8px;
}
.watchers-add {
  display: none;
}
.message-task-watchers:hover .watchers-add {
  display: inline-block;
}
.add-attachment {
  display: none;
}
.attachments-block:hover .add-attachment {
  display: inline-block;
}
.remove-attachment {
  display: none;
}
.single-attachment:hover .remove-attachment {
  display: inline-block;
}
.message-task-sending {
  cursor: wait !important;
  opacity: 0.5;
}
.message-task-sending * {
  cursor: wait !important;
}
.watchers-add-menu {
  max-height: 300px;
  overflow-y: auto;
  z-index: 1040;
}
.watchers-add-menu .dropdown-item {
  font-size: 12px;
  line-height: 16px;
}
.message-task-completed-by {
  font-size: 10px;
}
[aria-expanded="true"] .message-task-completed-by {
  display: none;
}
</style>