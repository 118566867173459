const tasksMutations = {
    setUsers(state, users) {
        state.users = users;
    },
    setCanTasksControl(state, can) {
        state.canTasksControl = can;
    },
    setCanUserList(state, can) {
        state.canUserList = can;
    },
    setTasks(state, tasks) {
        state.tasks = tasks;
    },
};

export default tasksMutations;