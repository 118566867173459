<template>
  <div class="pull-right" v-if="hasBounce">
    <popper content="Ошибка при доставке сообщения" class="light pre-like" placement="top" :hover="true" :interactive="false" :arrow="true">
      <a :href="bounceLink" target="_blank">
        <div class="position-absolute alert alert-warning bounce-box">
          <span class="text-danger me-1 fw-bold">Ошибка при доставке сообщения</span>
          <i class="fa fa-exclamation-triangle text-danger"></i>
        </div>
      </a>
    </popper>
  </div>
</template>

<script>
export default {
  name: "BounceIcon",

  props: {
    message: {
      require: true,
    }
  },

  computed: {
    hasBounce: function() {
      return typeof this.message.bounce !== 'undefined';
    },

    bounceLink: function() {
      return this.hasBounce ?
          '/tickets/' + encodeURIComponent( window.getId( this.message.ticket._id )) + '?message=' + encodeURIComponent( window.getId( this.message.bounce ) ) :
          '';
    }
  }
}
</script>

<style scoped>
.light {
  width: 100%;
  max-width: 100%;
  display: block;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 5px -6px rgba(0, 0, 0, 0.25);
}
.bounce-box{
  right: 0.5em;
  bottom: 6px;
  margin: 0;
  padding: 2px 5px;
  font-size: 1.2em;
}
</style>