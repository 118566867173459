<template>
  <tr>
    <th @click="orderBy('date_last')" class="text-center" style="cursor: pointer">Дата</th>
    <th @click="orderBy('name')" class="text-center" style="cursor: pointer;">Название</th>
    <th @click="orderBy('account.name')" class="text-center " style="  cursor: pointer; ">Клиент</th>
    <th v-if="$route.params.project === 'all'" @click="orderBy('project.name')" class="text-center " style="cursor: pointer; width: 160px;">Проект</th>
  </tr>
</template>

<script>
export default {
  name: "TableHeadRowsBillmanager",
  props:{
    'orderBy':{
      type:Function,
      require:true,
    }
  }
}
</script>

<style scoped>

</style>