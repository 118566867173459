<template>
  <div class="col-12" style="text-align: center;">
  <div @mouseover="showContextButton = true" @mouseout="showContextButton = false" class="position-relative message message-to diadoc-event col-sm-12 pb-3">
    <div class="col-12 datetime-stamp text-end">{{date}}</div>

    <div class="sender-block me-1">
      <div class="pull-right d-flex">
        <span class="text-secondary me-1">Диадок</span>
      </div>

      <div v-if="from">От: {{from}}</div>
      <div v-if="to">Кому: {{to}}</div>
    </div>

    <div v-show="showContextButton" class="contextFromButton">
      <context-button
          :messageid="eventId"
          :custom-button-style="contextStyle"
          :left-align="true" />
    </div>

    <div class="comment-quotes max-width pt-1">
      <slot name="body" />
    </div>
  </div>
  </div>
</template>

<script>
import contextButton from "../../Message/ContextMenu/ContextButton";

export default {
  name: "DiadocEvent",

  components: {contextButton},

  props: {
    date: {
      require: true
    },
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    eventId: {},
  },

  data() {
    return {
      showContextButton: false,
      contextStyle: {
        'border-style':'none',
        background: 'transparent',
        outline:'none'
      }
    }
  }
}
</script>

<style scoped>
.sender-block {
  min-height: 1.5em;
}
.contextFromButton {
  right: 3px;
}
</style>