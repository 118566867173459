<template>
  <div class="modal modal-mask">
    <div  class="modal-dialog">
      <div class="modal-content" style="position: relative">
        <div style="position: absolute; right: 10px; top:0px; cursor: pointer; font-size: 21px;  ">
          <span class="close" data-dismiss="alert" @click="cancel()" >&times;</span>
        </div>
        <div class="modal-header">Как завершена сделка?</div>
        <div class="modal-body">
          <div v-if="stage === 1">
            <div class="double-buttons-group">
              <div>
                <button type="button" class="btn btn-danger" @click="stage = 2">Неуспешно</button>
              </div>
              <div>
                <button type="button" class="btn btn-success" @click="submitFirst">Успешно</button>
              </div>
            </div>
          </div>
          <div v-if="stage === 2">
            <div>
              <div class="form-group mb-4">
                <label>Причина:</label>
                <select v-model="selectedDescription" class="form-select">
                  <option disabled :value="null">Выберите причину</option>
                  <option v-for="option in selectOptions" :value="option">{{ option }}</option>
                  <option value="Другое">Другое</option>
                </select>
              </div>
              <div v-if="selectedDescription === 'Другое'" class="mb-4">
                <textarea class="form-control"
                          rows="4"
                          v-model="textDescription"
                          @keyup.ctrl.enter.exact.stop="submitSecond"
                ></textarea>
              </div>
            </div>
            <div class="double-buttons-group">
              <div>
                <button type="button" class="btn btn-secondary" @click="stage = 1">Назад</button>
              </div>
              <div>
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="submitSecond"
                    :disabled="submit2Disabled"
                >Завершить</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepFinalModal",

  data:function (){
    return {
      stage: 1,
      selectedDescription: null,
      textDescription: '',
      selectOptions: [
        "Сделка провалена по нашей вине",
        "Далеко отложенный спрос",
        "Дорого (я отрабатывал, но клиент отказался)",
        "Заказал у конкурентов",
        "Клиент не вышел на связь",
        "Клиент отказался, причину выяснить не удалось",
        "Отказ с нашей стороны",
      ]
    };
  },

  emits: ['cancel', 'submit'],

  props: {
    step: {
      type: Object,
      required: true
    }
  },

  computed: {
    submit2Disabled() {
      return !this.selectedDescription || this.selectedDescription === 'Другое' && this.textDescription.trim() === '';
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },

    submitFirst() {
      this.$emit('submit', {
        stepId: window.getId(this.step._id),
        result: {
          status: 'success',
        }
      });
    },

    submitSecond() {
      if (this.stage === 2 && !this.submit2Disabled) {
        let description = this.selectedDescription && this.selectedDescription !== 'Другое' ?
                          this.selectedDescription :
                          this.textDescription;
        this.$emit('submit', {
          stepId: window.getId(this.step._id),
          result: {
            status: 'fail',
            description: description,
          }
        });
      }
    },
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}
</style>