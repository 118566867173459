<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Поступила оплата {{prettyPayment}}
      <template v-if="params?.paymethod"> в {{params.paymethod}}</template>
      <template v-if="params?.sender"> от {{params.sender}}</template>
      <template v-if="params?.purpose">: '{{params.purpose}}'</template>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import {PricePrettyView} from "../../../library/PricePrettyView";

export default {
  name: "Paid",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    prettyPayment() {
      return (new PricePrettyView(this.params.amount.value, this.params.amount.currency)).getPrettyPrice();
    }
  }
}
</script>

<style scoped>

</style>