<template>
<div>
  <ul class="nav nav-tabs border-0">
    <li class="nav-item">
      <a class="nav-link" aria-current="page"  style="cursor: pointer"
         :class="{active:$route.name === 'Sell' } " @click.middle="openNewWindow('Sell')"  @click="goToRoute('Sell')">Продают</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" aria-current="page"  style="cursor: pointer"
         :class="{active:$route.name === 'Buy' } "  @click.middle="openNewWindow('Buy')"  @click="goToRoute('Buy')">Покупают</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active:$route.name === 'History' || $route.name === 'HistoryEdit'} " :href="historyLink" tabindex="-1" aria-disabled="true">История</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" :class="{active:$route.name === 'Events' } " :href="eventLink" tabindex="-1" aria-disabled="true">События</a>
    </li>
    <li class="nav-item" v-if="crm_offers_rates">
      <a class="nav-link" :class="{active:$route.name === 'Rates' } " :href="ratesLink" tabindex="-1" aria-disabled="true">Цены</a>
    </li>
  </ul>
</div>
</template>

<script>
import {UserPermission} from "../../../users/UserPermission";

export default {
  name: "NavBar",
  data:function (){
    return {
      crm_offers_rates:false,
    }
  },
  created() {
    UserPermission.can('system.crm_offers_rates').then(()=>{
      this.crm_offers_rates = true
    }).catch(()=>{});
  },
  computed:{
    ratesLink:function (){
      return window.location.origin + "/offers/rates/"
    },
    eventLink:function (){
      return window.location.origin + "/offers/events/"
    },
    historyLink:function (){
      return window.location.origin + "/offers/history/"
    }
  },
  methods:{
    openNewWindow(name){
      window.open( window.location.origin + "/offers/" + name.toLowerCase(), '_blank');
    },
    goToRoute:function (name){
      if(['History', 'Events' , 'Rates' ].indexOf(this.$route.name) !== -1){
        window.location = window.location.origin + "/offers/" + name.toLowerCase()
      } else {
        this.$store.commit('offer/setOrderByField', null);
        this.$router.push(
            {
              name: name,
            }
        );
      }


    }
  }
}
</script>

<style scoped>
.bold{
  font-weight: bold;
}
</style>