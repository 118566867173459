<template>
  <table class="table table-bordered table-hover table-striped table-container mt-1 mb-0">
    <thead>
      <tr>
        <th @click="sortBy('assigned_product.fields.prefix')" class="link" style="min-width: 150px">Подсеть</th>
        <th @click="sortBy('assigned_product.fields.price1usd')" class="link" style="min-width: 150px">Цена</th>
        <th @click="sortBy('assigned_product.fields.date')" class="link" style="min-width: 100px">Дата продажи</th>
        <th style="min-width: 200px">Статус</th>
        <th @click="sortBy('person.email')" class="link" style="min-width: 200px">Клиент</th>
        <th style="min-width: 200px;">Комментарий</th>
      </tr>
    </thead>
    <tbody>
      <filter-table type="sell"></filter-table>

      <tr v-if="offers.size === 0">
        <td colspan="6" :class="{'loading-td': loading}">{{loading ? 'Загрузка' : 'Не найдено'}}</td>
      </tr>

      <tr v-for="[id, offer] in offers" @dblclick="setOffer(offer)" :class="{'offer-selected': isOfferSelected(offer), 'link': !isOfferSelected(offer)}">
        <td>
          <span v-if="field('prefix', offer)">
            /{{ field('prefix', offer) }} {{ field('type', offer) }}
          </span>
        </td>

        <td>
          <price :price="field('price', offer)"
                 :price1="field('price1', offer)"
                 :currency="field('currency', offer)"
                 :quantity="field('quantity', offer)"
                 :prefix="field('prefix', offer)"
                 :show-label="false"
                 :can-change="false"
                 default=" " />
        </td>

        <td>
          <date :date="field('date', offer)" />
        </td>

        <td>
          {{ status(offer) }}
        </td>

        <td>
          <client :offer="offer" />
        </td>

        <td>
          <comment :text="field('comment', offer)" />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import Prefix from "../../../tiketMenu/components/Params/Product/Field/Prefix";
import Price from "../../../tiketMenu/components/Params/Product/Field/Price";
import EditableDate from "../../../Editable/EditableDate";
import Comment from "../TableComponents/Comment";
import Date from "../TableComponents/Date";
import Client from "../TableComponents/Client";
import FilterTable from "../Filters/FilterTable";
import {mapActions} from "vuex";

export default {
  name: "Sell",

  components: {Prefix, Price, EditableDate, Comment, Date, Client, FilterTable},

  emits: ['setOffer'],

  computed: {
    offers() {
      return this.$store.getters['thread/offers/getOffersList'] ?? [];
    },

    loading() {
      return this.$store.getters['thread/offers/getLoading'];
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    }
  },

  methods: {
    field(fieldName, offer) {
      let val = null;
      if(typeof offer?.assigned_product?.fields === 'object') {
        val = offer?.assigned_product?.fields[fieldName] ?? null;
      }
      return val;
    },

    status(offer) {
      return offer?.assigned_product?.current_step?.name ?? '';
    },

    isOfferSelected(offer) {
      return (typeof this.ticket?.assigned_product?.fields?.buyer !== 'undefined' &&
              window.getId(this.ticket.assigned_product.fields.buyer) === offer._id);
    },

    sortBy(fieldName) {
      this.$store.commit('thread/offers/filters/setOrderBy', fieldName);
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.updateOffersList();
      }, 600)
    },

    setOffer(offer) {
      if(!this.isOfferSelected(offer)) {
        this.$emit('setOffer', offer);
      }
    },

    ...mapActions({
      updateOffersList: 'thread/offers/updateOffersList',
    })
  }
}
</script>

<style scoped>
th, td {
  text-align: center;
}
.link {
  cursor: pointer;
}
.table{
  table-layout: auto;
}
.loading-td {
  height: 10em;
  vertical-align: middle;
}
</style>