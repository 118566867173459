<template>
  <span class="small" v-if="showCount">
    (<span :class="{'opacity-50': !actualCount}">
      <template v-if="filterName === 'new'">
          <popper
              class="light"
              placement="top"
              content="Непрочитанные"
              :hover="true"
              :interactive="false"
              :arrow="true"
          >
            <b @click.stop="clickEvent('unread')">{{ count?.unread_notnew ?? 0 }}&nbsp;</b>
          </popper>/<popper
              class="light"
              placement="top"
              content="Абсолютно новые"
              :hover="true"
              :interactive="false"
              :arrow="true"
          >
            <b @click.stop="clickEvent('new')" :class="{'text-danger': count?.new > 0}">&nbsp;{{ count?.new ?? 0 }}</b>
          </popper>
      </template>
      <template v-else-if="filterName === 'tasks'">
        <popper
            v-if="filterName === 'tasks'"
            class="light"
            placement="top"
            content="Задачи на сегодня"
            :hover="true"
            :interactive="false"
            :arrow="true"
        >
          <b @click.stop="clickEvent('today')">{{ count?.today ?? 0 }}&nbsp;</b>
        </popper>/<popper
            v-if="filterName === 'tasks'"
            class="light"
            placement="top"
            content="Просроченные"
            :hover="true"
            :interactive="false"
            :arrow="true"
        >
          <b @click.stop="clickEvent('expired')" :class="{'text-danger': count?.expired > 0}">&nbsp;{{ count?.expired ?? 0 }}</b>
        </popper>
      </template>
      <popper
          v-else-if="getPopperText"
          class="light"
          placement="top"
          :content="getPopperText"
          :hover="true"
          :interactive="false"
          :arrow="true"
      >
        <b
            v-if="isClickable"
            @click.stop="clickEvent('unread')"
            :class="popperInnerClass"
        >{{outputCount}}</b>
        <b
           v-else
           :class="popperInnerClass"
        >{{outputCount}}</b>
      </popper>
      <b
         v-else-if="isClickable"
         @click.stop="clickEvent('unread')"
         :class="popperInnerClass"
      >{{outputCount}}</b>
      <b
         v-else
         :class="popperInnerClass"
      >{{outputCount}}</b>
    </span>)
  </span>
</template>

<script>

export default {
  name: "readUnread",

  props:{
    actualCount:{
      type:Boolean,
      require:true,
    },

    count:{
      type:Object,
      require: true
    },

    filter: {},

    additionalType: {
      default: null
    },

    countSettings: {
      type: Object
    }
  },

  emits: [
      'open',
  ],

  data: function() {
    return {
      projectName: null,
      alias: null,
      setAsNotActual: null,
    }
  },

  computed: {
    isActualTotal: function() {
      let bool;
      if(this.additionalType === null) {
        bool = (this.projectName !== null &&
                typeof this.countSettings[this.projectName] !== 'undefined' &&
                this.countSettings[this.projectName][this.alias].actual === true);
      } else {
        bool = (typeof this.countSettings[this.additionalType] !== 'undefined' &&
               this.countSettings[this.additionalType].actual === true);
      }

      return bool;
    },

    filterName: function() {
      return typeof this.filter === 'string' ? this.filter.substr(0, this.filter.indexOf('_')) : null;
    },

    // bool нужно ли выводить popper и подгружать общее количество тикетов
    withTotalCount: function() {
      return !['unconfirmed', 'unread', 'billmanager'].includes(this.filterName);
    },

    outputCount() {
      return ['unconfirmed', 'unread', 'billmanager'].includes(this.filterName) ? (this.count?.total ?? 0) : (this.count?.unread ?? 0);
    },

    isClickable() {
      return !['unconfirmed'].includes(this.filterName);
    },

    popperInnerClass() {
      return {
        'text-danger': this.filterName === 'unconfirmed' ||
                        (this.filterName === 'calls' && this.count.unread > 0) ||
                        (this.filterName === 'tasks' && this.count.unread > 0),
      };
    },

    getPopperText() {
      let texts = {
        "calls": "Пропущенные звонки",
        "billmanager": "Неотвеченные",
        "notifications": "Непрочитаннные",
        "answered": "Непрочитанные",
        "closed": "Непрочитанные",
        "favorite": "Непрочитанные",
        "unsorted": "Непрочитанные",
        "competitor": "Непрочитанные",
        "trash": "Непрочитанные",
        "unconfirmed": "Всего"
      }
      return texts[this.filterName ? this.filterName : this.additionalType] ?? null;
    },

    showCount: function() {
      return ['unconfirmed', 'unread', 'billmanager'].includes(this.filterName) && this.count?.total > 0 ||
        Object.entries(this.count).filter(c => c[0] !== 'total').map(c => c[1]).reduce((a,b) => a + b, 0) > 0;
    }
  },

  methods: {
    getCount: function() {
      if(this.additionalType !== null) {
        //  если это additional list

        if(typeof this.countSettings[this.additionalType] === 'undefined') {
          this.countSettings[this.additionalType] = {};
        }

        this.countSettings[this.additionalType].count = true;

        if(typeof this.count.total === 'undefined' || !this.isActualTotal) {
          // общее количество запрашивают первый раз или значение не актуально
          let getCount = { aliases: {} };
          getCount.aliases[this.additionalType] = {count: true};
          this.countSettings.getCount = getCount;
        }

      } else {
        // если это алиас в меню проекта

        if (this.projectName === null) {
          let filter = this.filter.split(/_(.*)/g, 2);
          this.projectName = filter[1];
          this.alias = filter[0];
        }

        if(typeof this.countSettings[this.projectName][this.alias] === 'undefined') {
          this.countSettings[this.projectName][this.alias] = {};
        }

        this.countSettings[this.projectName][this.alias].count = true;

        if(typeof this.count.total === 'undefined' || !this.isActualTotal) {
          // общее количество запрашивают первый раз или значение не актуально
          let getCount = {
            projectName: this.projectName,
            aliases: {}
          };
          getCount.aliases[this.alias] = {count: true, unread: false};
          this.countSettings.getCount = getCount; // запрашиваем сразу
        }
      }
    },

    clickEvent(name) {
      this.$emit('open', name);
    },

    closePopper: function() {
      // поповер закрыли, убираем получение общего количества в следующем запросе каунтов
      if(this.additionalType !== null) {
        this.countSettings[this.additionalType].count = false;
      } else {
        this.countSettings[this.projectName][this.alias].count = false;
      }
    }
  },

  watch: {
    'isActualTotal' (to) {
      if(to) {
        clearInterval(this.setAsNotActual);
        this.setAsNotActual = setInterval(() => {
          if(this.additionalType !== null) {
            this.countSettings[this.additionalType].actual = false;
          } else {
            this.countSettings[this.projectName][this.alias].actual = false;
          }
        }, 10000);
      }
    }
  }
}
</script>

<style scoped>
.light {
  font-weight: normal;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>