<template>
  <div style="display: flex; justify-content: flex-end; ">
    <incoming-call bc="#f5fbea"
                   :message="message"
                   :context-menu-left-align="true"
                   :change-permission="changePermission"
                   fromclass="message message-to w70" @move-messages="(msg) => $emit('moveMessages', msg)">
      <template v-slot:phone>
        <div>
          Телефон: {{prettyPhone}}
        </div>
      </template>
      <template v-slot:who >
        <div >
          Звонил: <span v-if="message.author.name">{{message.author.name}}</span><span v-else>{{message.author.login}}</span>
        </div>
      </template>
      <template v-slot:call >
        <div class="sender-block" style="margin-right: 22px;" >
          Исходящий звонок
        </div>
      </template>
      <template v-slot:status>
        <div style="display: flex">
          <span style="padding-right: 3px;">Статус:</span><OutgoingStatusInfo :message="message" @play="(fileLink) => $emit('play', fileLink)" :hasPermission="hasPermission"></OutgoingStatusInfo>
        </div>
      </template>
    </incoming-call>
  </div>
</template>

<script>
import IncomingCall from "./IncomingCall";
import {Phone} from "../../../../library/Phone";
import OutgoingStatusInfo from "./OutgoingStatusInfo";
export default {
  name: "OutgoingCall",

  emits: ['play'],
  components: {OutgoingStatusInfo, IncomingCall},
  data:function (){
    return {
      showContextButton: false,
    }
  },
  props:{
    message:{
      type:Object,
      require:true,
    },
    hasPermission: {
      type: Boolean,
      default: false,
    },
    changePermission: Boolean,
  },
  computed:{
    prettyPhone:function (){
      if(typeof this.message?.headers?.anumber === 'undefined' || this.message?.headers?.anumber == null ){
        return 'Скрыт';
      }
      return Phone.makePretty(this.message.headers.anumber);
    },

  }


}
</script>

<style scoped>

</style>
<style>
.w70{
  width: 70%;
}
</style>