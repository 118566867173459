<template>
<div class="thread-form" id="answer-form" style="display: flex;flex-wrap: nowrap;flex-direction: column;">
  <div ref="templateselect" v-if="ticket" style="margin-bottom: 5px;">
    <template-select
         @calculateFormSize="$parent.calculateFormSize"
         @resizeMessagesbox="px => $emit('resizeMessagesbox', px)"
         @showSignature="showSignatureHandler"
         @updateHeaders="updateHeadersHandler"
         :assign-template="assignTemplate"
         :project="getId(ticket?.project?._id)"
         :ticketId="getId(ticket._id)"
         ref="template-attachment-panel"
    ></template-select>
  </div>
  <div >
      <textarea
          @keydown.enter.stop="sendAnswerByEnter"
          ref="answer_message"
          v-model="message"
          :class="{'cursor-wait': isSending, 'with-signature': showSignature}"
          @keyup.ctrl.enter.exact.stop="sendThreadAnswerWithCheck"
          :disabled="isSending"
          @focus="onFocus"
          @focusout="$emit('textareaFocusOut')"
          class="form-control"
          style="resize: none; overflow: hidden; padding-bottom: 10px;"
          @input="handleInput($event.target.value)"
      ></textarea>
  </div>
  <div v-if="showSignature">
    <textarea
        class="form-control signature-control"
        ref="signatureTextarea"
        style="resize: none; overflow: hidden; padding-bottom: 10px;"
        :disabled="true"
    >{{ signatureText }}</textarea>
  </div>
  <div ref="answer_button" style=" display: inline-flex;">
    <div class="col-6 " style="padding-right: 2px;">
      <button style="margin-top: 5px; margin-bottom: 5px;"  type="button" :class="{'cursor-wait': isSending}"
              v-esc:ticketEscId.ctrl="unlockTicket"   :disabled="isSending" @click="unlockTicket"
              class="btn btn-secondary full-wight">Разблокировать</button>
    </div>
    <div class="col-6" style="padding-left: 2px;">
      <popper  :content="acceptWarning" class="danger full-wight" placement="top" :hover="true" :interactive="false" :arrow="true">
        <button style="margin-top: 5px; margin-bottom: 5px"  type="button" :class="{'cursor-wait': isSending , 'opacity':!isSelectedProduct}"
                :disabled="isSendDisabled " @click="sendThreadAnswer" class="btn btn-primary full-wight ">{{sendButtonText}}</button>
      </popper>

    </div>
  </div>
</div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import TemplateSelect from "../TemplateSelect";
import {mapGetters, mapActions, mapMutations} from "vuex";
import EditableSelect from "../../Editable/EditableSelect";
import {Email} from "../../../library/Email";


export default {
  name: "Answer",

  emits: ['newMessage', 'resizeMessagesbox', 'textareaFocusOut' , 'textareaFocus', 'textareaInput'],

  components: {
    TemplateSelect,
    EditableSelect,
  },

  props: {
    ticket: {
      type: Object,
      require: true
    },

    answerType: {
      type: String,
      require: true,
    },

    draft: {
      type: Object,
      default: {}
    }
  },

  data: function() {
    return {
      message: '',
      lastHeight: null,
      reduced: false,
      reduceHandler: null,
      greeting: null,
      startMinHeight:30,
      sendByEnter:true,
      textChange:false,
      signature: '',
      changedFrom: null,
      messageChangeWatcher: null,
    }
  },

  mounted: function() {
    this.setNewTicketLangChanged(false);
    this.setAnsweringTicket(true);
    this.sendByEnter = true;
    if(this.draft.length) {
      this.setDraft(this.draft);
    } else if(this.greeting !== null) {
      this.message = this.greeting;
    }

    $(this.$refs['answer_message']).height(30);
    this.$nextTick(()=>{
      this.$refs['answer_message'].focus();
    });
    if (typeof this.ticket.lang === 'string' && this.ticket.lang.trim() !== '') {
      this.lang = this.ticket.lang;
    } else if (window.getId(this.ticket.project._id)){
      let project = this.getProjectById(window.getId(this.ticket.project._id));
      if (project && typeof project?.lang === 'string') {
        this.lang = project.lang;
      }
    }
    this.deleteSignature(window.getId(this.ticket._id));
    this.loadTicketTemplates();
  },

  unmounted() {
    document.removeEventListener('mousedown', this.reduceHandler);
    this.hideSignature();
    this.deleteSignature(window.getId(this.ticket._id));
    this.resetGreeting();
    this.setAnsweringTicket(false);
  },

  computed: {
    lang: {
      get() {
        return this.newTicketLang;
      },
      set(value) {
        this.setNewTicketLang(value);
      }
    },
    acceptWarning:function (){
      let acceptWarning = "";
      if(!this.isSelectedProduct){
        acceptWarning = 'Для отправки ответа необходимо выбрать услугу'
      }
      return acceptWarning;
    },
    isSending: function() {
      return this.$parent.sending;
    },

    isSendDisabled: function (){
      return this.$parent.loading || !this.message || this.isSending || this.$parent.uploadingAttachments || !this.headersValid ||
          (this.$refs['template-attachment-panel'].getNewHeaders().subnet == '' && this.isCreateNewTicket);
    },

    uploadingAttachments: function() {
      return this.$refs['template-attachments-panel'] !== null && this.$refs['template-attachment-panel'].upload;
    },
    isSelectedProduct : function (){
      let isSelectedProduct = true;
      if(this.answerType === 'direct'){
        if (Array.isArray(this.getProducts) && this.getProducts.length) {
          isSelectedProduct = typeof this.ticket?.assigned_product?._id !== 'undefined';
        }
      }
      return isSelectedProduct;
    },
    sendButtonText: function() {
      let text;

      switch(this.answerType) {
        case 'internal':
          text = 'Отправить на модерацию';
          break;
        case 'direct':
          if(this.isCreateNewTicket){
            text = 'Создать новый тикет';
          }else {
            text = 'Отправить клиенту';
          }

          break;
        default:
          text = '...';
      }

      return text;
    },

    signatureText() {
      return this.isCreateNewTicket
                ? this.signature
                : (this.getSignature(window.getId(this.ticket._id), this.changedFrom) ?? '');
    },

    ...mapGetters({
      selectedTemplate: "thread/getSelectedTemplate",
      headersValid: "thread/headersFormValid",
      "isCreateNewTicket":'thread/createNewTicket',
      "getProducts": "tickets/product/getProducts",
      "showSignature": "thread/showSignature",
      "getSignature": "thread/getSignature",
      'getProjectById': 'tickets/create/getProjectById',
      "api": "thread/getApi",
      "getGreeting": "thread/getGreeting",
      "person": 'thread/getPerson',
      "newTicketLang": "thread/newTicketLang",
      "langChanged": "thread/newTicketLangChanged",
      "isSettingDraft": "thread/isSettingDraft",
    }),
  },

  methods: {
    updateSignature(alias = null) {
      if (!this.isCreateNewTicket) {
        let signature = this.getSignature(window.getId(this.ticket._id), this.changedFrom);
        if (this.showSignature || (typeof signature !== 'undefined' && signature !== null)) {
          this.loadSignature({
            ticketId: window.getId(this.ticket._id),
            alias: alias,
            show: this.showSignature
          });
        }
      }
    },
    showSignatureHandler() {
      if (this.isCreateNewTicket) {
        this.loadNewTicketSignature();
      }
    },
    updateHeadersHandler(header, value) {
      if(header === 'from') {
        this.changedFrom = value;
        if (
            (typeof this.ticket?.lang !== 'string' || this.ticket.lang.trim() === '') &&
            this.isCreateNewTicket &&
            !this.langChanged
        ) {
          let project = this.$store.getters['tickets/projects/getProjectByAlias'](value);
          if (typeof project?.lang === 'string' && project.lang.trim() !== '') {
            this.lang = project.lang;
          }
        }
      }

      if (['from', 'to'].includes(header)) {
        let data = {}
        data[header] = value;
        this.processGreeting(data);
      }

      if (this.isCreateNewTicket) {
        this.loadNewTicketSignature();
      } else if(this.showSignature) {
        this.updateSignature(this.changedFrom);
      }
    },

    loadNewTicketSignature() {
      let selected =  this.$refs['template-attachment-panel'].getNewHeaders();
      if (this.isCreateNewTicket && window.getId(this.ticket.project._id) && selected.from && this.lang) {
        let requestData = {
          alias: selected.from,
          lang: this.lang,
        };
        this.api.getNewTicketSignature(window.getId(this.ticket.project._id), requestData).then(result => {
          if(
              requestData.alias === selected.from &&
              requestData.lang === this.lang &&
              this.isCreateNewTicket
          ) {
            if (typeof result?.data?.signature !== 'undefined' && result.data?.signature !== null) {
              this.signature = result?.data?.signature;
            }
            this.calculateSignatureTextareaSize();
          }
        }).catch(error => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        });
      }
    },
    handleInput(val) {
      this.textChange = true;
      this.$emit('textareaInput');
    },
    focus(){
      this.$refs['answer_message'].focus();
    },

    initGreeting() {
      let selected = this.$refs['template-attachment-panel'].getNewHeaders();
      let to = selected?.to ?? '';
      if (to) {
        to = Array.isArray(to) ? to[0] : to;
        if (Email.getFirstNameFromFullString(to).trim() === '') {
          if (this.person !== null) {
            let name = null;
            if (this.person?.email === to) {
              name = this.person?.name ?? null;
            } else if (Array.isArray(this.person?.contact_emails ?? null) && this.person?.contact_emails.length) {
              name = this.person.contact_emails.find(item => item.mail === to)?.name ?? null;
            }
            if (name) {
              to = name + ' <' + to + '>';
            }
          }
        }
      }
      this.processGreeting({
        "from": selected?.from ?? '',
        "to": to,
        "lang": this.lang ?? 'ru',
        'ticket_lang': this.ticket?.lang ?? null,
        "init": true,
      });
    },

    updateGreeting(newGreeting) {
      if (!this.message || this.message === '' || this.greeting === this.message) {
        this.message = newGreeting;
      }
      this.greeting = newGreeting;
    },

    assignTemplate: function(tpl, isFieldEmpty = false) {
      if(!isFieldEmpty || (isFieldEmpty && this.message === '')) {
        this.message = (this.greeting ?? '') + tpl;
        this.$nextTick(() => this.$emit('textareaInput'));
      }
    },

    onFocus: function() {
      this.$emit('textareaFocus');
    },

    sendAnswerByEnter:function(event){
      if(this.sendByEnter && event.metaKey && !event.altKey &&  !event.shiftKey && !event.ctrlKey){
        this.sendByEnter = false;
        this.sendThreadAnswerWithCheck();
      }
    },
    sendThreadAnswerWithCheck: function (){

      if( !this.isSendDisabled ){
        this.sendThreadAnswer()
      }else {
        this.sendByEnter = true
      }
    },

    sendThreadAnswer: function (){
      if(this.$refs['template-attachment-panel'].isShowingHeadersForm() && !this.$refs['template-attachment-panel'].validationHeadersForm()) {
        this.sendByEnter = true
        return;
      }
      if(!this.isSelectedProduct){
        let focusOnSelect = () => {
          this.$store.commit('tickets/product/focusSelectMenu')
          this.$nextTick(() => {
            this.$store.commit('tickets/product/unFocusSelectMenu');
          })
        }

        if(this.$store.getters['thread/isTicketWindowFullSize']) {
          this.$store.commit('thread/setTicketChangeWindowSizeCallback', focusOnSelect);
          this.$store.commit('thread/setTicketWindowSize', false);
        } else {
          focusOnSelect();
        }

        this.sendByEnter = true
        return;
      }

      this.sending();
      if(this.isCreateNewTicket){
        let selected =  this.$refs['template-attachment-panel'].getNewHeaders();
        let project = this.$store.getters['tickets/projects/getProjectByAlias'](selected?.from ?? null);
        selected.project = window.getId(project ? project._id : this.ticket.project._id);
        selected.product =  window.getId(this.ticket?.assigned_product?._id);
        selected.message =  this.message;
        if(
            selected.to != null &&
            typeof selected.to === 'object'
        ){
          selected.to = selected.to.join(", ");
        }
        selected.lang = this.lang;
        selected.parrent_ticket = window.getId(this.ticket._id);
        selected.headers = null;
        if(this.$refs['template-attachment-panel'].getHeaders()?.headers){
          selected.headers = this.$refs['template-attachment-panel'].getHeaders().headers
        }
        if (typeof this.$refs['template-attachment-panel']?.savedAttachments !== 'undefined' &&
            this.$refs['template-attachment-panel']?.savedAttachments !== null) {
          selected.attachments = this.$refs['template-attachment-panel'].savedAttachments;
        }

        CrmApi.addTicket(selected).then((result)=>{
          let path = "/tickets/";
          let id = encodeURIComponent(window.getId(result.data.ticket._id));
          window.location.assign(window.location.origin + path + id);
        }).catch((error) => {
          this.sending(false);
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.sendByEnter = true;
        });
      }else {
        CrmApi.sendThreadAnswer(this.answerType,
            this.ticket._id,
            this.message,
            this.$refs['template-attachment-panel'].savedAttachments,
            this.$refs['template-attachment-panel'].getHeaders(),
            this.selectedTemplate?._id)
            .then((result) => {
              this.removeSelectedTemplate();
              if( result.data.success ) {
                this.$emit('newMessage', [result.data.message]);
                this.message = "";
                this.$store.commit('tickets/toLastSelect' , false)
                this.redirectToTickets();
              }

            }).catch((error) => {

          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));

        }).finally(() => {
          this.sending(false);
          this.sendByEnter = true; });
      }

    },

    unlockTicket: function() {
      if(this.isSending || this.$parent.sending ){
        return;
      }
      this.sending();
      CrmApi.call('/thread/' + encodeURIComponent(this.ticket._id) + '/unlock/').then((response) => {
        this.removeSelectedTemplate();
        if(typeof response.data.success !== 'undefined') {
          this.$parent.ticket = response.data.data;

          this.$nextTick(this.$parent.calculateFormSize);
        }
      }).finally(() => {
        this.sending(false);
      });
    },

    setDraft: function(draft) {
      this.setSettingDraft(true);
      if(typeof this.draft.attachments !== 'undefined' && this.draft.attachments !== null && draft.attachments.length) {
        this.$refs['template-attachment-panel'].addUploadedAttachments(draft.attachments);
      }
      if(typeof this.draft.headers === 'object') {
        this.$refs['template-attachment-panel'].addHeadersDraft(draft.headers);
      }
      if((!this.message || this.message === '' || this.message === this.greeting) && this.draft.message) {
        this.message = this.draft.message ?? '';
      } else if(this.greeting !== null) {
        this.message = this.greeting;
      }
    },

    getId(id) { return window.getId(id); },

    sending(status = true) { this.$parent.sending = status; },

    calculateSignatureTextareaSize() {
      let block = this.$refs.signatureTextarea;
      if (block) {
        let scrollHeight = Math.round(block.scrollHeight);
        let clientHeight = Math.round(block.clientHeight);
        let height = clientHeight;
        if (scrollHeight >= clientHeight) {
          height = scrollHeight;
        }
        if (this.signatureText === '') {
          height = 40;
        }
        if (height < 40) {
          height = 40;
        }
        if (height > 250) {
          height = 250;
        }
        block.style.height = '';
        block.style.height = height + 'px';

        this.$nextTick(this.$parent.calculateFormSize);
      }
    },

    ...mapActions({
      'removeSelectedTemplate':'thread/removeSelectedTemplate',
      "redirectToTickets": "thread/redirectToTickets",
      "loadSignature": "thread/loadSignature",
      "processGreeting": "thread/processGreeting",
      "loadTicketTemplates": "thread/loadTicketTemplates",
    }),

    ...mapMutations({
      "hideSignature": "thread/hideSignature",
      "deleteSignature": "thread/deleteSignature",
      "resetGreeting": "thread/resetGreeting",
      "setHasNewChangesForDraft": "thread/setHasNewChangesForDraft",
      "setAnsweringTicket": "thread/setAnsweringTicket",
      "setNewTicketLang": "thread/setNewTicketLang",
      "setNewTicketLangChanged": "thread/setNewTicketLangChanged",
      "setSettingDraft": "thread/setSettingDraft",
    }),
  },

  watch: {
    '$parent.resize' (to) {
      if(!to && this.lastHeight !== null) {
        this.lastHeight = $(this.$refs['answer_message']).height();
      }
    },
    'message' () {
      if (!this.isSettingDraft) {
        if (this.messageChangeWatcher) {
          clearTimeout(this.messageChangeWatcher);
        }
        this.messageChangeWatcher = setTimeout(() => this.setHasNewChangesForDraft(true),1000);
      }
    },
    'showSignature' (to) {
      if (to) {
        this.$nextTick(this.calculateSignatureTextareaSize);
      }
    },
    'signatureText' () {
      if (this.showSignature) {
        this.$nextTick(this.calculateSignatureTextareaSize);
      }
    },
    'getGreeting.counter' (to) {
      if (to) {
        this.initGreeting();
      }
    },
    'getGreeting.currentValue' (to) {
      this.updateGreeting(to);
    },
    'ticket.lang' (to) {
      this.updateSignature();
      if (to) {
        this.processGreeting({"lang": to, "ticket_lang": to});
      }
    },
    'lang' () {
      this.loadNewTicketSignature();
    },
  }
}
</script>

<style scoped>
.full-wight {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
}
.danger {
  display: block !important;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
.opacity{
  opacity: 0.65;
}
.with-signature {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.signature-control {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>