<template>
  <div :class="this.class">
    <button class="btn btn-toggle bg-secondary bg-opacity-25 w-100 outline-none" data-bs-toggle="collapse" data-bs-target="#client-collapse-body" :aria-expanded="Boolean(person)" :disabled="!person">
      Клиент
    </button>
    <div class="collapse bg-light params" :class="{show: person}" v-if="person" id="client-collapse-body">
      <i v-if="canChange"
         class="fa fa-pencil edit-button link-primary"
         :class="{'edit-button-with-relations': userRelationPermission}"
         @click="openModal"
      ></i>
      <div class="params-list">
        <relations :person="person" :ticketId="ticketId" :user-can-change="canChange"></relations>
        <name style="margin-top: 8px;" :person="person" :ticketId="ticketId" @updatePerson="updatePerson" :user-can-change="canChange"></name>
        <subnet-notification v-if="person?.auction?.notification" :notification="person.auction.notification" />
        <company-name v-if="person?.company && person?.company?.name" :company="person?.company" :ticket-id="ticketId" :user-can-change="canChange" @updatePerson="updatePerson"></company-name>
        <vatnum v-if="person?.company && person?.company?.vatnum && !person?.company?.ogrn" :company="person?.company" :ticket-id="ticketId" :user-can-change="canChange" @updatePerson="updatePerson"></vatnum>
        <ogrn v-if="person?.company && person?.company?.ogrn" :company="person?.company" :ticket-id="ticketId" :user-can-change="canChange" @updatePerson="updatePerson"></ogrn>
        <phones :person="person" :ticketId="ticketId" @updatePerson="updatePerson" :user-can-change="canChange"></phones>
        <emails :person="person" :ticketId="ticketId" @update="updatePerson" :user-can-change="canChange" :trim="true"></emails>
        <comment :ticketId="ticketId" :person="person" @updatePerson="updatePerson" :user-can-change="canChange" :trim="true"></comment>
      </div>
    </div>
  </div>
  <teleport to="body">
    <transition name="modal">
      <edit v-if="isEditModalOpen" :person="person" :ticketId="ticketId" @closeModal="closeModal"></edit>
    </transition>
  </teleport>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import Name from "./components/Client/Name";
import PhoneList from "./components/Client/PhoneList";
import EmailList from "./components/Client/EmailList";
import Edit from "./components/Client/Edit";
import Comment from "./components/Client/Comment";
import Relations from "./components/Client/Relations";
import {mapActions, mapGetters, mapMutations} from "vuex";
import CompanyName from "./components/Client/CompanyName";
import Vatnum from "./components/Client/Vatnum";
import Ogrn from "./components/Client/Ogrn";
import SubnetNotification from "./components/Client/SubnetNotification.vue";

export default {
  name: "Client",

  components: {
    SubnetNotification,
    Vatnum,
    Ogrn,
    CompanyName,
    Name,
    Phones: PhoneList,
    Emails: EmailList,
    Edit,
    Comment,
    Relations,
  },

  props: {
    class: {}
  },

  data: function() {
    return {
      showEditBtn: false,
      showEditModal: false,
    }
  },

  mounted: function() {
    this.checkPermission();
  },

  computed: {
    canChange() {
      return this.hasChangePermission && this.ticket?.deleted !== true;
    },

    ticket: function() {
      return this.$store.getters["thread/getTicket"] ?? null;
    },

    ticketId: function() {
      return this.ticket !== null ? this.ticket._id : null;
    },

    person: {
      get() { return this.$store.getters['thread/getPerson']; },
      set(person) { this.$store.commit('thread/setPerson', person); }
    },

    isEditModalOpen(){
      return this.getActiveWindow === 'ticketEditClient';
    },

    ...mapGetters({
      "getActiveWindow": "getActiveWindow",
      "hasChangePermission": "thread/isUserUpdatePersonPermission",
      "userRelationPermission": "thread/userRelationPermission",
    })
  },

  methods: {
    getAndSetPerson: function() {
      if(this.person === null) {
        CrmApi.getThreadPerson(this.ticket._id).then((result) => {
          this.person = result.data.person;
        });
      }
    },

    updatePerson(person) {
      this.person = person;
    },

    openModal() {
      this.$store.commit('setActiveWindow', 'ticketEditClient');
    },
    closeModal(person = false) {
      this.$store.commit('removeActiveWindow', 'ticketEditClient');
      if (person) {
        this.person = person;
        this.setPerson(person);
      }
    },

    ...mapMutations({
      setPerson: 'thread/setPerson',
    }),

    ...mapActions({
      "checkPermission": "thread/checkUpdatePersonPermission",
    })
  },

  watch: {
    'ticket' (to, from) {
      if(
          to != null && to._id != null &&
          ( from == null || from._id==null || window.getId(to._id) !== window.getId(from._id) )
      ){
        this.getAndSetPerson();
      }
    }
  }
}
</script>

<style scoped>
.params {
  position: relative;
  border: 1px solid #d8d8d8;
  border-top: none;
}
.params-list {
  padding: 0.8em;
}

.params-list > div {
  padding: 0.2em 0.2em;
}

.outline-none:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}
.edit-button {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.edit-button-with-relations {
  top: 46px;
}
</style>