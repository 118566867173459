<template>
  <div class="modal modal-mask" v-enter="clickEnter" v-esc="cancel" >
    <div  class="modal-dialog">
      <div class="modal-content" style="position: relative">
        <div style="position: absolute; right: 10px; top:0px; cursor: pointer; font-size: 21px;  ">
          <span class="close" data-dismiss="alert" @click="cancel()" >&times;</span>
        </div>
        <div class="modal-body">
          <div class="pt-5 col-md-10 m-auto">
            <form  method="post" @submit.prevent="onSubmit()">
              <div class="form-group row">
                <label for="stepname" class="col-sm-2 col-form-label">Имя шага</label>
                <div class="col-sm-10">
                  <input ref="stepname" type="text" class="form-control"  v-model="step.name" id="stepname" placeholder="Введите имя шага">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-2 col-form-label">Шаблоны</label>
                <div class="col-sm-10">
                  <multiselect  placeholder="Добавить шаблоны к шагу"
                                v-model="templateIds"
                                :mode="'tags'"
                                :close-on-select="false"
                                :searchable="true"
                                :create-option="true"
                                :delay="800"
                                :options="updateTemplateList"
                                ref="multy"
                  >
                    <template v-slot:tag="{ option, handleTagRemove, disabled }">
                      <div  class="multiselect-tag is-user" >
                        <a class="link-style" target="_blank" :href="getTemplateEditLink(option)">{{ option.label }} </a>
                        <span
                            v-if="!disabled"
                            class="multiselect-tag-remove"
                            @mousedown.prevent="handleTagRemove(option, $event)"
                        >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="form-group">
                <div v-if="isLastStep || isFinalStep" class="form-check">
                  <label class="form-check-label" for="final-step">Завершающий шаг</label>
                  <input class="form-check-input" type="checkbox" v-model="isFinalStep" id="final-step">
                </div>

                <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                  <template #content>
                    <span>Не отображать тикет на этом шаге в разделе «Биржа Покупают / Продают»</span>
                  </template>
                  <div v-if="['buy_ip','sell_ip'].indexOf(type) !== -1" class="form-check">
                    <label class="form-check-label" for="show-offer">Не отображать в бирже</label>
                    <input class="form-check-input" type="checkbox" v-model="isNotShowInOffers" id="show-offer">
                  </div>
                </popper>

              </div>
              <div class="mb-3" >
                <label for="hint" class="col-form-label">Подсказка</label>
                <mde-text-area @focusout="commentOutFocus" @focusin="commentInFocus" id="hint" v-model="step.hint" ref="hint"  ></mde-text-area>
              </div>
              <div class="double-buttons-group">
                <div>
                  <button @click="cancel()" type="button" class="btn btn-secondary">Назад</button>
                </div>
                <div>
                  <button type="submit" :disabled="isDisabled " class="btn btn-primary">ОК</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from "../../ticket/Api/api";
import MdeTextArea from "../../template/source/MdeTextArea";
import {getTemplateEditLink} from '../../../library/Locations';
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "StepEditNewModal",
  components: {
    Multiselect,
    MdeTextArea
  },
  props:{
    additional_fields:{
      type:String,
    }
  },
  data:function (){
    return {
      templateIds:[],
      loadedTemplatesMap: {},
      allTemplatesArray: [],
      step:{},
      isFinalStep: false,
      isNotShowInOffers:false,
      inFocus:false,
    }
  },

  methods:{
    ...mapMutations({
      "changeStep":"product/changeStep",
      'resetCurrentStep':'product/resetStep',
    }),
    clickEnter:function (){
      if(!this.inFocus){
        this.onSubmit();
      }
    },
    commentInFocus:function (){
      this.inFocus = true;
    },
    commentOutFocus:function (){
      this.inFocus = false;
    },
    getTemplateEditLink(template){
      return getTemplateEditLink(template.value);
    },
    onSubmit(){
      if(this.isDisabled){
        return;
      }
      this.step.templates = [];
      this.templateIds.forEach((templateId) => {
        this.step.templates.push( this.loadedTemplatesMap[templateId] );
      });
      this.step.offerhide = this.isNotShowInOffers;
      if(this.isStepChange()){
        this.step.stepChange = true;
      }else {
        this.step.stepChange = false;
      }
      if (this.isFinalStep) {
        this.step.type = 'final';
      } else {
        delete this.step.type;
      }


      this.changeStep(this.step);
      this.$emit('submitForm' , this.step);
      if(this.product?._id){
        this.$router.push({
          name: "Products",
          params: {
            product: window.getId(this.product._id),
          }
        })
      }else {
        this.$router.push({
          name: "Products",
          params: {
            product: 'new',
          }
        })
      }

    },
    cancel(){
      this.changeStep(this.stepCurrent);
      this.$emit('closeModal', this.stepCurrent);
      if(this.product?._id){
        this.$router.push({
          name: "Products",
          params: {
            product: window.getId(this.product._id),
          }
        })
      }else {
        this.$router.push({
          name: "Products",
          params: {
            product:'new',
          }
        })
      }

    },
    isArrayEquals(arrayOne , arrayTwo){

      let isArrayEquals = true;
      let hashMap = {};
      for (let val of arrayOne){
        hashMap[val] = val;
      }
      if(arrayOne.length !== arrayTwo.length){
        isArrayEquals = false;
      }else{
        let breakObject = {};
        try {
          for (let val of arrayTwo){
            if(typeof hashMap[val] === 'undefined'){
              isArrayEquals = false;
              throw breakObject;
            }
          }
        }catch (breakObject){}
      }

      return isArrayEquals;
    },
    isStringEqual(oneString , twoString){
      let isStringEqual = false;
      if((typeof oneString ==='undefined' ||oneString === null || oneString == "") && (typeof twoString ==='undefined' || twoString === null || twoString == "")){
        isStringEqual = true;
      }else{
        isStringEqual = oneString === twoString;
      }
      return isStringEqual;

    },
    isStepChange(){
      let isStepChange = false;
      let oldIds = [];
      if(typeof this.oldStep.templates !== 'undefined' && this.oldStep.templates != null && this.oldStep.templates.length > 0 ){
        this.oldStep.templates.forEach((template) => {
          oldIds.push(window.getId(template._id));
        });
      }

      if(!this.isStringEqual(this.oldStep.name ,  this.step.name)){
        isStepChange = true;
      }else if(!this.isStringEqual(this.oldStep.hint , this.step.hint)){
        isStepChange = true;
      }else if(!this.isArrayEquals( this.templateIds , oldIds )){
        isStepChange = true;
      } else if (this.isFinalStep && this.oldStep?.type !== 'final') {
        isStepChange = true;
      } else if (!this.isFinalStep && this.oldStep?.type === 'final') {
        isStepChange = true;
      }else if(!this.isStringEqual(this.step.offerhide , this.oldStep.offerhide) ){
        isStepChange = true;
      }
      return isStepChange;
    },
    updateTemplateList(query){
      let filter = {};
      if( query !== null ){
        filter.name = query;
      }
      return CrmApi.getTemplatesList(filter).then((result)=>{
        this.allTemplatesArray = [];
        let unique = {};
        if(this.$refs.multy.search == '' || this.$refs.multy.search == null){
          this.initialTemplates.forEach((template) => {
            this.allTemplatesArray.push({
              value:window.getId(template._id),
              label:template.name,
            });
            unique[window.getId(template._id)] = true;
            this.addLoadedTemplate(template);
          });
        }
        result.data.data.forEach((template)=>{
          if(typeof unique[template._id] === 'undefined') {
            this.allTemplatesArray.push({
              value: window.getId(template._id),
              label: template.name,
            });
            this.addLoadedTemplate(template);
          }
        });
        return  this.allTemplatesArray;
      }).catch(()=> {
        return  this.allTemplatesArray;
      })
    },
    addLoadedTemplate( template ){
      let tmpTemplate = Object.assign({},template);
      tmpTemplate._id = window.getId(tmpTemplate._id);
      this.loadedTemplatesMap[tmpTemplate._id] = tmpTemplate;
    },

  },
  mounted() {
    changeMultySelectStyles(document);
    this.$refs.stepname.focus()


  },

  created() {
    this.step = Object.assign({} , this.stepCurrent );
    this.initialTemplates = this.step.templates;

    this.step.templates.forEach((template) => {
      this.templateIds.push(window.getId(template._id));
      this.addLoadedTemplate(template);
    });
    this.isNotShowInOffers = Boolean(this.step?.offerhide);
    this.isFinalStep = this.step?.type === 'final';
  },
  computed:{
    type:function (){
      return this?.additional_fields ?? this.product?.additional_fields?.[0]?.type;
    },

    isLastStep:function (){
      let isLastStep = false;
      let key = 0;
      for (let el of this.product.steps){

        if(el?.uid == this.step?.uid &&
            (key + 1) == this.product.steps.length
        ){
          isLastStep = true;
        }
        key++
      }
      return isLastStep;
    },
    ...mapGetters({
      "stepCurrent":'product/getCurrentOpenedStep',
      'product':'product/getProduct',
      'oldsteps':'product/getOldVersionsSteps',
    }),
    isDisabled(){
      let isDisabled = false;
      if(this.step.name.trim() === ''){
        isDisabled = true;
      }

      return isDisabled;
    },
    oldStep(){
      return this.oldsteps[this.step.uid];
    }
  }
}
</script>

<style scoped>

.modal-dialog {
  max-width: 1000px;
  margin: 1.75rem auto;
}
.modal-mask {
  position: fixed;
  z-index: 9900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}



.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}
</style>