<template>
  <select v-model="selectedBank" class="form-select mt-2" :class="{'is-invalid': !valid && showNotValid}" @change="selectBank" ref="select">
    <option :value="null" disabled>Банк</option>
    <option v-for="bank in banks" :value="bank.name">{{bank.label ?? bank.name}}</option>
  </select>
</template>

<script>
export default {
  name: "Bank",

  emits: ['selectBank'],

  props: {
    bank: String,
    banks: Array,
  },

  data() {
    return {
      valid: false,
      showNotValid: false,
      selectedBank: null,
    }
  },

  mounted() {
    this.selectedBank = this.bank;
    this.valid = (typeof this.bank === 'string' && this.bank.length > 0);
  },

  methods: {
    isValid() {
      this.showNotValid = true;
      return this.valid;
    },

    selectBank() {
      this.valid = true;
      this.$emit('selectBank', this.selectedBank ?? null);
    },

    focus() {
      if(this.$refs.select) {
        this.$refs.select.focus();
      }
    }
  },

  watch: {
    bank(bank) {
      this.selectedBank = bank;
    }
  }
}
</script>

<style scoped>

</style>