<template>
  <div>
    <multiselect  placeholder="Введите метки"
                  v-model="tags"
                  :mode="'tags'"
                  :close-on-select="false"
                  :searchable="true"
                  :create-option="true"
                  :delay="800"
                  :options="updateTags"
                  @select="selectTag($event)"
                  @clear="clear"
                  @deselect="deselect"
                  class="multiselect-sm"
                  ref="multy"
    ></multiselect>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from "../../ticket/Api/api";

export default {
  name: "TagSelect",
  components:{
    'multiselect':Multiselect
  },
  data:function (){
    return {
      tags:[],
      firstValues:{},
    }
  },
  props:{
    action:{
      type:Object,
      require:true
    }
  },
  methods:{
    deselect(tag){
      let arr = [];
      for (let elem of this.action.params){
        if(elem.value !== tag){
          arr.push(elem);
        }
      }
      this.action.params = arr;
    },
    clear(){
      this.action.params = [];
    },
    selectTag(event ){
      this.action.params.push({
        key:'tag',
        value:event
      });
    },
    updateTags(query){
      let allTagsArray = [];
      return CrmApi.getTags(query).then((result)=>{

        if(this.$refs.multy.search === '' || this.$refs.multy.search == null){
          for (let key in this.firstValues){
            allTagsArray.push({
              value:key,
              label:key,
            })
          }
        }
        let tags = result.data.data;
        for (let key in tags){
          let el = tags[key];
          allTagsArray.push({
            value:el.tags,
            label:el.tags,
          });
        }
        return  allTagsArray;
      }).catch(()=> {
        return  allTagsArray;
      })
    },
  },
  mounted() {
    changeMultySelectStyles(document);
  },
  created() {
    if(typeof this.action.params[0] !== 'undefined' && this.action.params[0].value !== ""){
      for (let elem of  this.action.params){
        if(elem.key === 'tag'){
          this.firstValues[elem.value] = elem.value;
          this.tags.push(elem.value);
        }
      }
    }

  }
}
</script>

<style scoped>
.multiselect-sm{
  --ms-tag-bg: #DBEAFE;
  --ms-tag-color: #2563EB;
  --ms-py: 0.4rem;
  --ms-px: 0.875rem;

}
</style>