export const getProduct = state => {
    return state.product;
}


export const isLoading = state => {
    return state.loading;
}

export const getProjects = state => {
    return state.projects;
}

export const getOldStepOrders = state => {
    return state.oldStepOrders;
}

export const haveNonSaveChanges = state =>{
    return state.haveNonSaveChanges;
}


export const getCurrentOpenedStep = state => {
    return state.selectedStep.currentStep;
}



export const getOldVersionsSteps = state => {
    return state.selectedStep.oldVersionsSteps;
}

export const getStepForDelete = state => {
    return state.stepForDelete;
}