<template>
  <div class="col-12 thread-form" id="answer-form" style="display: flex;flex-wrap: nowrap;flex-direction: column;">
    <div ref="templateselect">
      <div>
        <div class="d-inline pull-right m-1">
          <div class="d-inline">
            <label for="attachment-input" class="btn btn-sm btn-special" :class="{disabled: upload}">
              <i class="fa fa-paperclip"></i> Вложить
            </label>
            <input type="file" id="attachment-input" multiple @change="addAttachment">
          </div>
        </div>
      </div>

      <div class="input-group input-group-sm mb-1 d-flex">
        <span class="input-group-text" id="task-subject" style="width: 150px;">Название</span>
        <input
            ref="subjectInput"
            v-model="data.subject"
            type="text"
            class="form-control" aria-label="Название"
        >
        <button
            class="btn btn-special dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false">Выбрать название</button>
        <ul class="dropdown-menu dropdown-menu-end">
          <li v-for="option in subjectOptions"><a class="dropdown-item" href="#" @click="data.subject = option">{{ option }}</a></li>
        </ul>
      </div>

      <div class="input-group input-group-sm mb-1">
        <span class="input-group-text" id="task-assignee" style="width: 150px;">Ответственный</span>
        <simple-select
          select-class="form-select"
          notselected="Не назначен"
          :selected="data.assignee"
          :can-select-null="false"
          :options="users"
          :options-params="{value: 'value', name: 'name'}"
          @change="(value) => {data.assignee = value;}"
        ></simple-select>
      </div>

      <div class="datepicker-group mb-1">
        <date-picker
            v-model="data.deadlinedate"
            ref="datepicker"
            mode="datetime"
            locale="ru"
            :is24hr="true"
            :model-config="modelConfig"
            :popover="{visibility: 'focus'}"
            :attributes="attrs"
            @dayclick="inputFocus"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <div class="input-group input-group-sm">
              <span class="input-group-text" id="task-deadlinedate" style="width: 150px;">Крайний срок</span>
              <input
                  ref="editInput"
                  v-on="inputEvents"
                  class="form-control"
                  :value="inputValue"
                  :placeholder="''"
                  @keyup.ctrl.enter.exact.stop="sendWithCheck"
              />
            </div>
          </template>
        </date-picker>
      </div>

      <div class="p-1 small attach-list">
        <div v-for="(file, index) in files" class="px-1" :class="{uploading: file.process, 'text-muted': file.process}">
          <a :href="file.link" target="_blank" v-if="file.link">
            {{file.name}} ({{getFileSize(file)}})
          </a>
          <span v-else>
          {{file.name}} ({{getFileSize(file)}})
        </span>
          <i role="button" class="fa fa-close" @click="removeAttachment(index)" v-if="file.link"></i>
        </div>
      </div>
    </div>
    <div>
      <textarea
          ref="answer_message"
          style="resize: none; overflow: hidden; padding-bottom: 10px;"
          class="form-control add-task-textarea"
          v-model="data.body"
          :class="{'cursor-wait': sending}"
          :disabled="sending"
          @focus="$emit('textareaFocus')"
          @focusout="$emit('textareaFocusOut')"
          @input="handleInput($event.target.value)"
          @keyup.ctrl.enter.exact.stop="sendWithCheck"
      ></textarea>
    </div>
    <div ref="answer_button" class="d-flex">
      <div class="col-6" style="padding-right: 2px;">
        <button type="button"
                :class="{'cursor-wait': sending}"
                :disabled="sending"
                @click="cancel"
                class="btn btn-secondary full-width"
                v-esc="checkCancel"
        >Отмена</button>
      </div>
      <div class="col-6" style="padding-left: 2px;">
        <button type="button"
                :class="{'cursor-wait': sending}"
                :disabled="sending || !valid"
                @click="send"
                class="btn btn-primary full-width"
        >Создать задачу</button>
      </div>
    </div>
    <add-task-form-close-modal
      v-if="showCloseModal"
      @close="closeAddTaskFormCloseModal"
      @update="cancel"
    ></add-task-form-close-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getNameWithLogin} from "../../../library/userName";
import moment from "moment";
import {DatePicker} from "v-calendar";
import 'v-calendar/dist/style.css';
import {CrmApi} from "../../../library/CrmApi";
import prettyBytes from "pretty-bytes";
import SimpleSelect from "../../common/SimpleSelect";
import AddTaskFormCloseModal from "./AddTaskFormCloseModal";

export default {
  name: "AddTaskForm",

  components: {
    DatePicker,
    SimpleSelect,
    AddTaskFormCloseModal,
  },

  emits: [
    'textareaFocusOut',
    'textareaFocus',
    'textareaInput',
    'updateTasks',
    'setSending',
  ],

  props: {
    ticketId: {
      require: true,
    },
    sending: {},
  },

  data: function() {
    return {
      data: {
        subject: null,
        assignee: null,
        deadlinedate: null,
        body: null
      },
      modelConfig: {
        type: 'string',
        mask: 'DD.MM.YYYY HH:mm',
      },
      upload: false,
      files: [],
      savedAttachments: [],
      users: [],
      subjectOptions: [
        "Звонок",
        "Письмо",
        "Встреча",
        "Доставка документов",
      ],
      showCloseModal: false,
      attrs: [
        {
          key: 'today',
          highlight: {
            color: 'purple',
            fillMode: 'light',
          },
          dates: new Date(),
        },
      ]
    }
  },

  computed: {
    currentUser() {
      return window.getId(window.userId);
    },

    currentUserName() {
      return getNameWithLogin(window.userData ?? {});
    },

    valid() {
      return !!this.data.subject && moment(this.data.deadlinedate, this.modelConfig.mask).isValid();
    },

    ...mapGetters({
      "getUsers": "thread/tasks/getUsersForSelect",
    }),
  },

  mounted() {
    this.data.assignee = this.currentUser;
    this.users = this.countUsers();
    this.$nextTick(()=>{
      this.$refs['subjectInput'].focus();
    })
  },

  methods: {
    countUsers() {
      let result = {};
      result[this.currentUser] = this.currentUserName;
      if (typeof this.getUsers === 'object' && this.getUsers !== null) {
        Object.entries(this.getUsers).forEach((el) => {
          if (window.getId(el[0]) !== window.getId(window.userId)) {
            result[el[0]] = el[1];
          }
        });
      }
      return result;
    },

    checkCancel() {
      if (typeof this.data.subject === 'string' && this.data.subject.trim() !== '' ||
          typeof this.data.body === 'string' && this.data.body.trim() !== '' ||
          typeof this.data.assignee === 'string' && this.data.assignee.trim() !== window.userId ||
          typeof this.data.deadlinedate === 'string' && this.data.deadlinedate.trim() !== '' ||
          this.savedAttachments.length > 0
      ) {
        this.showCloseModal = true;
        this.$store.commit('setActiveWindow', 'addTaskFormCloseModal');
      } else {
        this.cancel();
      }
    },

    closeAddTaskFormCloseModal() {
      this.showCloseModal = false;
      this.$store.commit('removeActiveWindow', 'addTaskFormCloseModal');
    },

    cancel() {
      this.$store.commit('removeActiveWindow', 'addTaskFormCloseModal');
      this.setShowAddTaskForm(false);
    },

    send() {
      let data = {
        subject: this.data.subject,
        body: this.data.body,
        ticket: this.ticketId,
        assignee: this.data.assignee,
      };
      if (this.data.deadlinedate && moment(this.data.deadlinedate, this.modelConfig.mask).isValid()) {
        data.deadlinedate = moment(this.data.deadlinedate, this.modelConfig.mask).format();
      }
      if (this.savedAttachments.length > 0) {
        data.attachments = this.savedAttachments;
      }
      this.$emit('setSending', true);
      this.createTask(data).then(() => {
        this.$emit('setSending', false);
        this.$emit('updateTasks');
        this.cancel();
      }).catch((error) => {
        this.$emit('setSending', false);
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      });
    },

    sendWithCheck() {
      if (!this.sending && this.valid) {
        this.send();
      }
    },

    handleInput(){
      this.$emit('textareaInput');
    },

    inputFocus() {
      this.$refs.editInput.focus();
    },

    addAttachment(e){
      let files = [ ...e.target.files ];
      this.upload = true;
      for (let file of files) {
        CrmApi.saveAttachments(this.ticketId, [file]).then((response) => {
          let f = {};
          response.data.attachments[0].id = window.getId(response.data.attachments[0].id);
          let id = response.data.attachments[0].id;
          f.id = id;
          f.name = response.data.attachments[0].filename;
          f.link = '/attachment/' + id + '/';
          f.size = file.size;
          f.tipe = file.type;
          // триггер для vue
          this.files.push(f);
          this.savedAttachments = this.savedAttachments.concat(response.data.attachments);
        }).catch((error) => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.upload = false;
          e.target.value = '';
        });
      }
    },

    removeAttachment(index) {
      let removeFunc = () => {
        this.savedAttachments = this.savedAttachments.filter(at => at.filename !== this.files[index].name);
        this.files.splice(index, 1);
      }

      if(typeof this.files[index].fromExistMessage !== 'undefined' && this.files[index].fromExistMessage === true) {
        // это вложение добавленное с помощью "создать как новое", оно удаляется только из списка аттачей в форме
        removeFunc();
      } else {
        this.files[index].process = true;
        this.files = this.files.concat();

        CrmApi.call('/thread/attachment/' + encodeURIComponent(this.files[index].id) + '/delete/').then(removeFunc).catch((error) => {
          delete this.files[index].process;

          if(error.response.status === 404 &&
              typeof error.response.data.code !== 'undefined' &&
              error.response.data.code === '404/2') {
            removeFunc();
          } else {
            this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
          }
        });
      }
    },

    getFileSize: function(file) {
      return prettyBytes(file.size);
    },

    ...mapMutations({
      "setShowAddTaskForm": "thread/setShowAddTaskForm",
    }),

    ...mapActions({
      "createTask": "thread/tasks/createTask",
    }),
  }
}
</script>

<style scoped>
.full-width {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
#attachment-input {
  display: none;
}
.uploading {
  cursor: wait;
}
.btn-special {
  background-color: #ebebeb;
  border-color: #a0a0a0;
  color: #000;
}
.btn-special:hover {
  background-color: #dadada;
}
.add-task-textarea {
  background: #FBE8DF;
}
.datepicker-group:deep(.vc-time-content) {
  flex-grow: 1;
}
.datepicker-group:deep(.vc-select) {
  width: calc(50% - 4px);
}
.datepicker-group:deep(.vc-select > select) {
  width: 100%;
}
.datepicker-group:deep(.vc-time-select) {
  width: 100%;
}
</style>