<template>
  <span v-html="criticalChangeMessage" />
</template>

<script>
export default {
  name: "CriticalChange",

  props: {
    criticalChange: {},
  },

  computed: {
    criticalChangeMessage() {
      let content = null;
      let change = this.criticalChange;

      if(typeof change.type === 'string') {
        let date = moment(change.date).format('DD.MM.Y');

        switch(change.type) {
          case 'created':
            content = `${date} сеть была создана`;
            break;
          case 'change':
            content = `${date} было изменено поле <i>${change.attr}</i> с <i>${change.from}</i> на <i>${change.to}</i>`;
            break;
        }
      }

      return content;
    },
  }
}
</script>

<style scoped>

</style>