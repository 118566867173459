import {computed} from "vue";
import moment from "moment";

export function sortFunctions(data, sort) {
    return {
        "login": (a, b) => {
            let aLogin = data[a].login, bLogin = data[b].login;
            return sort * aLogin.localeCompare(bLogin);
        },
        "count_read": (a, b) => {
            let aCount = data[a].count_read ?? 0, bCount = data[b].count_read ?? 0;
            return sort * (bCount - aCount);
        },
        "countMessage": (a, b) => {
            let aCount = data[a].countMessage ?? -1,
                bCount = data[b].countMessage ?? -1,
                aCountUnconfirmed = (data[a].create_unconfirmed_message ?? 0) + (data[a].edit_unconfirmed_message ?? 0),
                bCountUnconfirmed = (data[b].create_unconfirmed_message ?? 0) + (data[b].edit_unconfirmed_message ?? 0);
            return (bCount !== aCount) ? sort * (bCount - aCount) : sort * (bCountUnconfirmed - aCountUnconfirmed);
        },
        "min": (a, b) => {
            let aVar = data[a].min ?? -1,
                bVar = data[b].min ?? -1;
            return sort * (aVar - bVar);
        },
        "avg": (a, b) => {
            let aVar = data[a].avg ?? -1,
                bVar = data[b].avg ?? -1;
            return sort * (aVar - bVar);
        },
        "max": (a, b) => {
            let aVar = data[a].max ?? -1,
                bVar = data[b].max ?? -1;
            return sort * (aVar - bVar);
        },
        "countOutCalls": (a, b) => {
            let aCount = data[a].countOutCalls ?? 0, bCount = data[b].countOutCalls ?? 0;
            return sort * (bCount - aCount);
        },
        "minOutCalls": (a, b) => {
            let aVar = data[a].minOutCalls ?? -1,
                bVar = data[b].minOutCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "avgOutCalls": (a, b) => {
            let aVar = data[a].avgOutCalls ?? -1,
                bVar = data[b].avgOutCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "maxOutCalls": (a, b) => {
            let aVar = data[a].maxOutCalls ?? -1,
                bVar = data[b].maxOutCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "sumOutCalls": (a, b) => {
            let aCount = data[a].sumOutCalls ?? 0, bCount = data[b].sumOutCalls ?? 0;
            return sort * (bCount - aCount);
        },
        "countInCalls": (a, b) => {
            let aCount = data[a].countInCalls ?? 0, bCount = data[b].countInCalls ?? 0;
            return sort * (bCount - aCount);
        },
        "minInCalls": (a, b) => {
            let aVar = data[a].minInCalls ?? -1,
                bVar = data[b].minInCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "avgInCalls": (a, b) => {
            let aVar = data[a].avgInCalls ?? -1,
                bVar = data[b].avgInCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "maxInCalls": (a, b) => {
            let aVar = data[a].maxInCalls ?? -1,
                bVar = data[b].maxInCalls ?? -1;
            return sort * (aVar - bVar);
        },
        "sumInCalls": (a, b) => {
            let aCount = data[a].sumInCalls ?? 0, bCount = data[b].sumInCalls ?? 0;
            return sort * (bCount - aCount);
        },
    };
}

export function getPeriodOut({period}) {
    const periodOut = computed(() => {
        let result = '', format = 'YYYY-MM-DD', periodValue = period.value;
        if (moment(periodValue.from).endOf('month').add(1, "day").format(format) === moment(periodValue.to).format(format)) {
            result = moment(periodValue.from).format('MMMM YYYY');
        } else {
            result = `Период с ${periodValue.from ?? ''} по ${periodValue.to ?? ''}`;
        }
        return result;
    });
    return {
        periodOut,
    };
}

export function getName({data}) {
    const userName = computed(() => {
        let dataValue = data.value, name = typeof dataValue.name !== 'undefined' && dataValue.name ? String(dataValue.name) : '',
            login = typeof dataValue.login !== 'undefined' && dataValue.login ? String(dataValue.login) : '',
            result = null;
        if (name) {
            result = name + (login ? ' (' + login + ')' : '');
        } else if (login) {
            result = login;
        }
        return result;
    });
    return {
        userName,
    };
}

export function getTdClass({total}) {
    const tdClass = computed(() => {
        return total.value ? "total-td" : "";
    })
    return {
        tdClass,
    };
}