<template>
  <div class="ps-1 d-inline-flex align-items-baseline">
    <span class="dropstart">
      <span id="notification-menu-button" role="button" class="fa fa-bars" data-bs-toggle="dropdown" aria-expanded="false"></span>
      <div class="ticket-menu dropdown-menu notification-menu" aria-labelledby="notification-menu-button">
        <button class="btn btn-light btn-outline-dark col-12" @click="unreadNotification"><i class="fa fa-eye-slash  button-icon menu-button"></i>Не прочитано</button>
        <template v-for="item in menuItems">
          <component
            v-if="typeof item?.display !== 'function' || item.display()"
            :is="item.component"
            :queue="item.queue"
            :callback="item.callback"
          ></component>
        </template>
      </div>
    </span>
  </div>
</template>

<script>
import ReadAndNotReadButtons from "../tiketMenu/components/ReadAndNotReadButtons.vue";
import ConvertNotificationButton from "../tiketMenu/components/ConvertNotificationButton.vue";
import DeleteNotificationButton from "../tiketMenu/components/DeleteNotificationButton";
import {mapActions} from "vuex";

export default {
  name: "NotificationMenu",

  components: {
    ReadAndNotReadButtons,
    DeleteNotificationButton
  },

  props: {
    userCan: {},
  },

  data: function() {
    return {
      queue: null,
      queueInterval: null,
    }
  },

  computed: {
    notification() {
      return this.$store.getters['thread/getTicket'];
    },

    menuItems() {
      return [
        {
          component: ConvertNotificationButton,
          display: () => {
            return this.userCan?.convert
          }
        },
        {
          component: DeleteNotificationButton,
          queue: this.addToQueue,
          callback: this.deleteCallback,
          display: () => {
            return this.userCan?.delete;
          }
        }
      ];
    }
  },

  methods: {
    unreadNotification() {
      let action = () => {
        this.$store.dispatch('tickets/setNotificationUnWatched', {
          ticketIds: [this.notification._id],
          route: this.$route,
        }).then(() => {
          this.$store.state.tickets.resetCounter = true;
          this.$store.commit('tickets/toLastSelect', false);
          this.redirectToTickets();
          this.$store.commit('successPush', "Рассылка помечена как непрочитанная");
        });
      }

      if(this.queue === null) {
        action();
      } else {
        this.addToQueue(action);
      }
    },

    addToQueue(func) {
      if(this.isLoading()) {
        this.queue = func;
      } else {
        this.setLoading();
        if(typeof func.finally === 'function') {
          func().finally(() => { this.setLoading(false); });
        } else {
          func();
        }
      }
    },

    isLoading() {
      return this.$store.state.thread.loading;
    },

    setLoading(status = true) {
      return this.$store.state.thread.loading = status;
    },

    getTicket() {
      return this.$store.state.thread.ticket;
    },

    deleteCallback() {
      this.$store.state.tickets.resetCounter = true;
      this.redirectToTickets();
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    }),
  },


  watch: {
    '$store.state.thread.loading' (to) {
      if(!to && this.queue !== null) {
        this.queueInterval = setInterval(() => {
          if(!this.isLoading()) {
            this.setLoading();
            this.queue().finally(() => {
              this.queue = null;
              this.setLoading(false);
            })
          }
        }, 3);
      } else {
        clearInterval(this.queueInterval);
      }
    }
  }
}
</script>

<style scoped>
.ticket-menu {
  z-index: 1100;
  padding: 0;
  width: 15em;
}
</style>