<template>
<span v-if="isNotInline" class="attachment-block" :class="{'text-muted':loading}">
  <a v-if="canViewLink" :href="'/message/' + messageid + '/attachment/' + getBaseObjectId(attachment.id)" target="_blank" style="padding-right: 5px"><i class="fa fa-file-o" aria-hidden="true"></i> {{attachment.filename}} ({{prettyBytes(attachment.size)}})</a>
  <span v-else class="attachment-name">
    <i class="fa fa-file-o" aria-hidden="true"></i> {{attachment.filename}} ({{prettyBytes(attachment.size)}})
  </span>

  <button v-if="canViewLink && canUpdatePerson && !checkAlreadyInPerson"
        class="add-to-person-btn blue-icon me-2"
        type="button"
          :class="{'disabled-icon':loading}"
        :disabled="loading"
        @click="addToPerson"
  >
        <popper content="Добавить файл в карточку клиента" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <i class="fa fa-plus-circle"></i>
        </popper>
  </button>
</span>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Messages} from "../../../library/api/Messages";
import {CrmApi} from "../../../library/CrmApi";

const prettyBytes = require('pretty-bytes');
export default {
  name: "Attachments",
  props:{
    "messageid":{
      require: true
    },
    "attachment":{
      type:Object,
      require:true
    },
    "canOpenAttachment": {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      loading: false,
    }
  },

  methods:{
    prettyBytes:function (arg){
      return prettyBytes(arg);
    },
    getBaseObjectId: function ( id ){
      return typeof id.$oid !== 'undefined' ? id.$oid : id;
    },
    addToPerson: function () {
      this.loading = true;
      Messages.addAttachmentToPerson({
        message: this.messageid,
        id: window.getId(this.attachment.id)
      }).then((result) => {
        if (result.data?.success === true) {
          this.$store.commit('successPush', 'Вложения добавлены');
        } else {
          this.$store.commit('errorPush', 'Вложения не добавлены');
        }
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  computed: {
    ...mapState( {
      threadState: state => state.thread,
    }),
    canViewLink: function () {
      return this.canOpenAttachment ||
          typeof this.threadState?.userAttachmentPermission !=='undefined' &&
          this.threadState.userAttachmentPermission === true;
    },
    isNotInline() {
      return typeof this.attachment?.inline === 'undefined' ||
             this.attachment.inline === null ||
             this.attachment.inline !== true;
    },
    checkAlreadyInPerson() {
      let result = false;
      if (typeof this.person?.company?.documents !== 'undefined' && Array.isArray(this.person?.company?.documents)) {
        result = this.person.company.documents.filter(doc => doc?.attachmentid)
                                              .map(doc => window.getId(doc?.attachmentid))
                                              .includes(window.getId(this.attachment.id));
      }
      return result;
    },
    ...mapGetters({
      "person": "thread/getPerson",
      "canUpdatePerson": "thread/isUserUpdatePersonPermission",
    }),
  }
}
</script>

<style scoped>
.attachment-name {
  text-decoration: underline #000000 dotted;
  padding-right: 5px;
}
.blue-icon {
  color: #6262f5;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}
.disabled-icon {
  opacity: 0.8;
  cursor: wait !important;
}
.attachment-block .add-to-person-btn {
  display: none;
}
.attachment-block:hover .add-to-person-btn {
  display: inline-block;
}
</style>