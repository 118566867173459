
import ratesActions from "./actions";
import ratesGetters from "./getters";
import ratesMutations from "./mutations";

const state = {
    ratesStatistic:{
    },
    loading:true,
};

export const moduleRates = {
    namespaced: true,
    state:() => (state),
    mutations: ratesMutations,
    actions: ratesActions,
    getters: ratesGetters
}