<template>
  <div v-if="client && id" :class="{'cursor-wait':person.loading}" >
    <div  @click="personEdit()" @mouseover="openPopper" @mouseleave="closePopper"  @mousemove="openPopper"  >
      <popper   :arrow="true"  :show="showPopper" placement="top"  >
        <div class="edit-client-block" style="cursor: pointer;display: flex; flex-direction: column; align-items: center;">
          <div v-if="name" style="  display: inline-flex;" >
            <string-trim :show-popper="false" popper-placement="bottom" v-if="isScreenSmall" :string="name" ></string-trim>
            <span v-if="!isScreenSmall"  >{{name}}</span>
            <span style="padding-left: 3px" v-if="name && ticket" @click.stop="{}">[<a  @click.stop="{}"  target="_blank" :href="'/tickets/' + ticket">t</a>]</span></div>
          <div v-if="email" style="  display: inline-flex;">
            <string-trim :show-popper="false" popper-placement="bottom" v-if="isScreenSmall" :string="email" ></string-trim>
            <span  v-if="!isScreenSmall" >{{email}}</span>
            <span  style="padding-left: 3px" v-if="!name && email&& ticket">[<a style="z-index: 150" target="_blank" :href="'/tickets/' + ticket">t</a>]</span>
          </div>
        </div>
        <template #content>
          <client-popper :loading="false"
                         :person="client"
                         :typeSubnet="subnetType"
                         :manager="manager" />
        </template>
      </popper>
      <div class="text-muted extra-search-lines" v-if="Array.isArray(extraClientLines) && extraClientLines.length">
        <div v-for="line in extraClientLines"><string-trim :string="line" /></div>
      </div>
    </div>
    <div class="text-left">
      <teleport  v-if="showEditModal" to="body">
        <transition name="modal">
          <add-edit :user-history-object="historyObject" @close="closeModal()" ></add-edit>
        </transition>
      </teleport>

    </div>

  </div>
</template>

<script>
import ClientPopper from "./ClientPopper";
import {Person} from "../../../library/api/Person";
import {CrmApi} from "../../../library/CrmApi";
import Edit from "../../tiketMenu/components/Client/Edit";
import {mapActions, mapGetters} from "vuex";
import AddEdit from "../Modal/AddEdit";
import StringTrim from "../../string/StringTrim";
export default {
  name: "Client",
  components: {
    StringTrim,
    AddEdit,
    ClientPopper,
    Edit,
  },
  setup(props){
  },
  props:{
    'client':{
      type:Object,
      require:true,
    },
    'historyObject':{
      type:Object,
      require: true,
    },
    type:{
      type:String,
      default:'',
    },
    manager: Object
  },
  data: function() {
    return {
      randId:null,
      showEditModal:false,
    }
  },
  created() {
    this.randId = this.getRandId() +this.getRandId();

  },
  mounted() {
    /*
    if(this.type === 'seller' && this.editObjectId !== null && this.editObjectId == window.getId(this.historyObject._id)){
     // this.personEdit();
    }

     */
  },
  computed:{
    subnetType:function (){
      return this.historyObject.subnets?.[0]?.type ?? 'ASN';
    },
    showPopper:function (){
      if(this.isEditClientOpen || this.id === null){
        return  false;
      }
      return typeof this.popperClientOpen[ this.randId] !== 'undefined'
    },
    isEditClientOpen:{
      set(value){
        this.$store.commit('offerHistory/setIsEditClientOpen', value)
      },
      get(){
        return this.$store.getters['offerHistory/isEditClientOpen']
      },
    },
    popperClientOpen:{
      set(value){
        this.$store.commit('offerHistory/setPopperClientOpen', value)
      },
      get(){
        return this.$store.getters['offerHistory/getPopperClientOpen']
      }
    },
    id:function (){
      return typeof this.client?._id === 'undefined' ? null :  this.client._id;
    },
    ticket:function (){
      return this.client.ticket ? window.getId(this.client.ticket) : null;
    },
    email:function (){
      return this.client.email ?? '';
    },
    name:function (){
      return this.client.name ?? '';
    },
    person:function () {
      return this.getPerson(this.id);
    },
    extraClientLines: function () {
      return this.client?.extra ?? [];
    },
    ...mapGetters({
      'getPerson':'offerHistory/getPerson',
      'canChange':'offerHistory/canChange',
      'isScreenSmall':'isScreenSmall',
      'editObjectId':'offerHistory/getEditObjectId',
      'getOpenEdit':'offerHistory/getOpenEdit'
    }),
  },
  methods: {
    closePopper:function (){
      this.popperClientOpen = {};
    },
    getRandId(){
      return '_' + Math.random().toString(36).substr(2, 16);
    },
    openPopper(){
      if(this.id !== null && typeof this.popperClientOpen[ this.randId] === 'undefined'){
        this.popperClientOpen = {};
        this.popperClientOpen[ this.randId] = true;
      }
    },

    edit(id , params){
      return '';
      return Person.edit(this.id, params).then((result)=>{
        return  new Promise((resolve ,reject) =>{
          this.$store.commit('offerHistory/updatePerson' , result.data.person)
          resolve(result);
        } );
      })
    },
    closeModal:function(person){
      this.isEditClientOpen= false;
      this.showEditModal = false;
      $("body").css({"overflow": "auto"});

      this.$store.commit('offerHistory/setEditObjectId' , null);
      this.$store.commit('offerHistory/setNeedChangeRoute', false);
      this.$router.push(
          {
            'name':'History',
            'query':Object.assign({} , this.$route.query),
          }
      )
    },
    personEdit:function (){
      if(this.canChange){

        if(! this.isEditClientOpen){
          this.closePopper();
          this.isEditClientOpen= true;
          this.$router.push(
              {
                'name':'HistoryEdit',
                'params':{
                  'historyId':window.getId(this.historyObject._id),
                },
                'query':Object.assign({} , this.$route.query),
              }
          );

          this.showEditModal = true;
          this.$nextTick(()=>{
            this.closePopper();
          })

        }

      }

    },
/*
    ...mapActions({
      'loadPerson':'offerHistory/loadPerson'
    }),*/

  },
  watch:{
    'showEditModal'(to , from){
      if(to){
        $('body').css({"overflow":"hidden"})
      }else {
        $("body").css({"overflow":"auto"});
      }
    }
  }
}
</script>

<style scoped>
.edit-client-block {
  font-size: 14px;
  color: black;

}
.text-left {
  text-align: left;
}
.cursor-wait{
  cursor: wait;
}
.extra-search-lines {
  font-size: 12px;
}
</style>