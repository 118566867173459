<template>
  <div v-if="tasks.length" class="mb-2" ref="tasksContainer">
    <button class="btn btn-toggle bg-secondary bg-opacity-25 w-100 outline-none" data-bs-toggle="collapse" data-bs-target="#tasks-collapse-body" aria-expanded="true">
      Задачи
    </button>
    <div class="collapse bg-light params show" id="tasks-collapse-body">
      <div class="params-list">
        <task-row
            v-for="task in tasks"
            :task="task"
            @scrollToTask="scrollToTask"
        ></task-row>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapGetters} from "vuex";
import TaskRow from "./components/tasks/TaskRow";

export default {
  name: "Tasks",

  components: {
    TaskRow,
  },

  computed: {
    ...mapGetters({
      "tasks": "thread/tasks/getTasks",
    })
  },

  methods: {
    ...mapMutations({
      "setScrollToMessage": "thread/setScrollToMessage",
    }),

    scrollToTask(id) {
      this.setScrollToMessage(id);
    },

    callScroll() {
      if ($(this.$refs?.tasksContainer)) {
        $(this.$refs.tasksContainer).parent().get(0)?.dispatchEvent(new CustomEvent('scroll'));
      }
      window.dispatchEvent(new Event('resize'));
    },
  },

  watch: {
    'tasks.length' () {
      this.callScroll();
    }
  }
}
</script>

<style scoped>
#tasks-collapse-body {
  border: 1px solid #d8d8d8;
  border-top: none;
}
#tasks-collapse-body .params-list {
  max-height: 224px;
  overflow-y: auto;
}
</style>