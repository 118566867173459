<template>
  <div>
    <button class="btn btn-toggle bg-secondary bg-opacity-25 w-100 outline-none" data-bs-toggle="collapse" data-bs-target="#params-collapse-body" aria-expanded="true">
      Параметры
    </button>
    <div class="collapse bg-light params show" id="params-collapse-body">
      <div class="params-list">
        <product
            v-if="!isProjectNull"
            :ticket="ticket"
            :user-can-change="userCanChange && !isDeleted"
            :user-can-set-lot="userCanSetLot"
        ></product>
        <language :ticket="ticket" :user-can-change="userCanChange && !isDeleted"></language>
        <ticket-projects :ticket="ticket"></ticket-projects>
        <ticket-tags :ticket="ticket"></ticket-tags>
        <affiliated :ticket="ticket"></affiliated>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "./components/Params/Product";
import Language from "./components/Params/Language";
import {mapGetters} from "vuex";
import Affiliated from "./components/Params/Affiliated";
import TicketTags from "./components/Params/TicketTags";
import TicketProjects from "./components/Params/TicketProjects";

export default {
  name: "Params",

  components: {
    TicketTags,
    Affiliated,
    Product,
    Language,
    TicketProjects,
  },

  data: function() {
    return {
    }
  },

  computed: {
    ticket: function() {
      return this.$store.getters["thread/getTicket"] ?? null;
    },

    isProjectNull: function() {
      return !_.isObject(this.ticket?.project);
    },

    userCanChange: function() {
      return Boolean(this._userCanChange);
    },

    isDeleted: function() {
      return this.ticket?.deleted;
    },

    ...mapGetters({
      "_userCanChange":  'thread/userCanChange',
      "userCanSetLot": "thread/userCanSetLot",
    }),
  },
}
</script>

<style scoped>
.params {
  border: 1px solid #d8d8d8;
  border-top: none;
}
.params-list {
  padding: 0.8em;
}
.params-item {
  padding: 0;
}
.outline-none:focus {
  box-shadow: none;
  border-color: rgba(0,0,0,.125);
}
</style>