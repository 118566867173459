<template>
  <div class="params-item" v-if="projects.length > 0">
    <span v-if="projects.length > 1" class="me-1">Проекты: </span>
    <span v-else class="me-1">Проект: </span>
    <projects
      :projects="projects"
      :ticket-id="ticketId"
    ></projects>
  </div>
</template>

<script>
import Projects from "./Projects";
export default {
  name: "TicketProjects",
  components: {Projects},
  props: {
    ticket: {},
  },

  computed: {
    projects() {
      let projects = [];
      if (typeof this.ticket?.project?.name === 'string' && this.ticket?.project?.name?.trim() !== '') {
        projects.push(this.ticket?.project?.name);
      }
      if (typeof this.ticket?.assigned_projects === 'object' && Array.isArray(this.ticket.assigned_projects)) {
        projects = projects.concat(this.ticket.assigned_projects.map(project => project.name));
      }
      return projects;
    },
    ticketId() {
      return window.getId(this.ticket?._id);
    },
  }
}
</script>

<style scoped>

</style>