import {CrmApi} from "./library/CrmApi";


import TemplateList from "./components/template/TemplateList";

import ProjectList from "./components/project/ProjectList";
import ProjectEditForm from "./components/project/ProjectEditForm";
import ProjectCreateForm from "./components/project/ProjectCreateForm";

import UserList from "./components/user/UserList";
import UserEditForm from "./components/user/UserEditForm";
import UserCreateForm from "./components/user/UserCreateForm";

import Paginate from "./components/paginate/Paginate";
import Settings from "./components/settings/Settings";
import TicketMain from "./components/ticket/TicketMain";
import 'es6-promise/auto'
import Loading from "vue3-loading-overlay";
import { createApp } from 'vue'
import router from './router/router'
import statisticRouter from "./router/statisticRouter";
import routerProduct from './components/products/productsRouter';
import offerRouter from './router/offer/offerRouter';
import offerHistoryRouter from "./router/offer/offerHistoryRouter";
import offerEventsRouter from "./router/offer/offerEventsRouter";
import ratesRouter from "./router/offer/ratesRouter";

import "vue3-loading-overlay/dist/vue3-loading-overlay.css";
import Popper from "./library/vue3-popper/popper.esm";
import Datepicker from "vue3-datepicker";

import ticketStore from './store/main/index';
import productStore from './store/main/product';
import documentStore from './store/main/document';
import userStore from './store/main/user/user';
import offerStore from './store/main/offer/index';
import commonStore from './store/main/common/index';
import statisticStore from './store/main/statistic/index';
import offerHistoryStore from "./store/main/offerHistory/index"
import offerEventsStore from "./store/main/offerEvents/index";

import ratesStore from './store/main/rates/index';

import FilterNewEdit from "./components/filters/FilterNewEdit";
import TemplateEditNew from "./components/template/TemplateEditNew";
import ProductList from "./components/products/ProductList";
import FilterList from "./components/filters/FilterList";
import ProductsNewEdit from "./components/products/ProductsNewEdit";
import StepEdit from "./components/products/steps/StepEdit";
import SmtpList from './components/smtp/SmtpList';
import SmtpNewEdit from './components/smtp/SmtpNewEdit'
import DocumentList from "./components/DocumentTemplates/DocumentList";
import DocumentNewEdit from "./components/DocumentTemplates/DocumentNewEdit";

import TemplateChangesPopper from "./components/template/source/TemplateChangesPopper";
import OfferMain from "./components/offer/OfferMain";

import History from "./components/offerHistory/History";
import Events from "../js/components/offerEvents/Events";

import ratesMain from "../js/components/rates/RatesMain";
require('./bootstrap');
import {UserPermission} from "./users/UserPermission";
import Esc from "./components/directives/keys/etc/esc";
import Enter from "./components/directives/keys/enter/enter";
import Delete from "./components/directives/keys/delete/delete";
import Clipboard from "./components/directives/string/clipboard";
import PriceClipboard from  "./components/directives/string/priceClipboard";
import singleClick from "./components/directives/singleClick";
import keyF from "./components/directives/keys/keyF/keyF";
import keyN from "./components/directives/keys/keyN/keyN";
import Backspace from "./components/directives/keys/backspace/backspace";
import Statistic from "./components/statistic/Statistic";
import doubleClick from "./components/directives/doubleClick";
import focus from "./components/directives/focus";
import CompaniesList from "./components/companies/CompaniesList";
import CompaniesNewEdit from "./components/companies/CompaniesNewEdit";

window.model = {};
window.routerProduct = routerProduct;
window.router = router;
window.statisticRouter = statisticRouter;
window.createApp = createApp;
window.ticketStore = ticketStore;
window.productStore = productStore;
window.documentStore = documentStore;
window.userStore = userStore;
window.offerRouter = offerRouter;
window.offerStore = offerStore;
window.statisticStore = statisticStore
window.commonStore = commonStore;
window.commonStore.commit('setUserSettings', window.userSettings);
window.ratesStore = ratesStore;
window.ratesRouter = ratesRouter;

window.offerHistoryStore = offerHistoryStore
window.offerHistoryRouter = offerHistoryRouter
window.ticketStore.commit('setUserSettings', window.userSettings);
window.offerStore.commit('setUserSettings', window.userSettings);
window.offerHistoryStore.commit('setUserSettings', window.userSettings);

window.offerEventsRouter = offerEventsRouter
window.offerEventsStore = offerEventsStore

window.offerEventsStore.commit('setUserSettings', window.userSettings);

window.UserPermission = UserPermission;
window.ditectives = {

};
window.ditectives.buttons = {
    Esc:Esc,
    Enter:Enter,
    Delete:Delete,
    Clipboard:Clipboard,
    PriceClipboard:PriceClipboard,
    singleClick: singleClick,
    keyF:keyF,
    keyN:keyN,
    Backspace:Backspace,
    doubleClick:doubleClick,
    focus:focus
};
window.companents = {
    ratesMain:ratesMain,
    Events:Events,
    History:History,
    OfferMain:OfferMain,
    Loading:Loading,
    TicketMain:TicketMain,
    paginate: Paginate,
    TemplateEditNew:TemplateEditNew,
    ProjectEditForm: ProjectEditForm,
    ProjectCreateForm: ProjectCreateForm,
    UserEditForm: UserEditForm,
    UserCreateForm: UserCreateForm,
    FilterNewEdit:FilterNewEdit,
    ProductList:ProductList,
    FilterList:FilterList,
    ProjectList:ProjectList,
    ProductNewEdit:ProductsNewEdit,
    StepEdit:StepEdit,
    TemplateChangesPopper:TemplateChangesPopper,
    popper: Popper,
    datepicker: Datepicker,
    SmtpList: SmtpList,
    CompaniesList: CompaniesList,
    CompaniesNewEdit: CompaniesNewEdit,
    SmtpNewEdit: SmtpNewEdit,
    Statistic: Statistic,
    DocumentList: DocumentList,
    DocumentNewEdit: DocumentNewEdit,
};
window.moment = require("moment");
moment.locale('ru');
window.dateFormatWeekDay = 'YYYY-MM-DD (ddd) HH:mm';
window.dateFormatWeekDaySeconds = window.dateFormatWeekDay  + ':ss';

window.readGetParams = function() {
    let urlParams = new URLSearchParams(window.location.search);

    let params = {};
    urlParams.forEach((value, key) => {
        params[key] = value;
    })

    return params;
}


window.backLink = function() {
    if(window.history.state.back !== null) {
        event.preventDefault();
        history.back();
    }
}
window.getId = function(id) {
    return typeof id?.$oid !== 'undefined' ? id.$oid : id;
}
window.isId = id => {
    let isId = false;
    if(typeof id === 'string' && id.length === 24) {
        isId = true;
    }
    if(typeof id === 'object' && typeof id?.$oid !== 'undefined') {
        isId = true;
    }
    return isId;
}


const app = createApp({
    data:function () {
        return window.model;
    },

    methods:{
        // TODO: replace this into component
        "deleteTemplate": function(tplId) {
            let e = event;
            CrmApi.call('/template/' + tplId + '/delete').then(function(response) {
                if(typeof response.data !== 'undefined') {
                    if(typeof response.data.success !== 'undefined' && response.data.success === 'success') {
                        $(e.target).closest('tr').remove()
                    }
                }
            });
        },
    }
});



app.component("Loading", Loading);
app.component( "template-list", TemplateList );
app.component("project-list", ProjectList);
app.component("user-list", UserList);
app.component( "paginate", Paginate );
app.component( "settings", Settings );
app.component('popper', Popper);
app.component('datepicker', Datepicker);
app.use(commonStore);
for (const key in window.ditectives.buttons){
    const button = window.ditectives.buttons[key];
    app.use(button);
}


$(document).ready(function(){
    if( $("#app").length > 0 ) {
        app.mount('#app');
    }
    $('#logout-btn').on('click', () => CrmApi.logout());
});