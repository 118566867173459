<template>
<div class="row" style="--bs-gutter-x: 0rem;">
  <div class="col-sm-2">
    <label class="col-form-label">Группа</label>
  </div>
 <div class="col-sm-10"  style="padding-right: 0px;">
   <multiselect v-model="template.group" :delay="800" :options="getGroups" :create-option="true" :searchable="true"></multiselect>
 </div>
</div>
</template>
<script>
import Multiselect from '@vueform/multiselect'
import {CrmApi} from "../../../library/CrmApi";
import {changeMultySelectStyles} from "../../ticket/Api/api";
export default {
  components:{
    'multiselect':Multiselect,
  },
  name: "Groups",
  data:function (){
    return {
    }
  },
  methods:{
    getGroups(value){
      return  CrmApi.getTemplateGroups(value, null , {page:1, onpage:100}).then((response) => {

        return response.data.data;
      });
    },
  },
  mounted() {
    changeMultySelectStyles(document);
  },
  props:{
    'template':{
      type:Object,
    }
  }
}
</script>

<style scoped>

.multiselect-sm{
  --ms-py: 0.4rem;
  --ms-px: 0.875rem;
}
</style>