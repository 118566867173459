<template>
  <button @click="takeToWork()"
          :class="{'text-muted':loading, 'custom-disabled':loading}"
          v-show="ids.toLock.length > 0"
          class="btn btn-light btn-outline-dark col-12"
  ><i class="fa fa-lock button-icon menu-button"></i>Взять в работу</button>
  <button @click="unlockTickets()"
          :class="{'text-muted':loading, 'custom-disabled':loading}"
          v-show="ids.toUnlock.length > 0"
          class="btn btn-light btn-outline-dark col-12"
  ><i class="fa fa-unlock button-icon menu-button"></i>Разблокировать</button>
</template>

<script>
import {canLockTicket, canUnlockTicket} from "../../ticket/Api/api";
import {UserPermission} from "../../../users/UserPermission";
import {mapGetters} from "vuex";

export default {
  name: "LockAndUnlockButtons",
  props: {
    'idList': {
      type: Array
    }
  },
  data: function () {
    return {
      ids: {
        toLock: [],
        toUnlock: [],
      }
    }
  },
  watch:{
    'idList'(to , from){
      this.updateIds();
    },
    'ticketsObject'(to , from){
      this.updateIds();
    },
  },
  mounted() {
    this.updateIds();
  },
  methods: {
    checkPermissions: function () {
      let permissions = [];
      let singlePermission;
      for (let key in this.ticketsObject) {
        let ticket = this.ticketsObject[key];
        if (typeof ticket?.project?.name !== 'undefined' && ticket?.project?.name !== '') {
          singlePermission = ticket?.project?.name + '.answer';
          if (permissions.indexOf(singlePermission) === -1 ) {
            permissions.push(singlePermission);
          }
        }
      }
      if (permissions) {
        UserPermission.getPermissions(permissions).then(() => {}).catch(() => {});
      }
    },
    updateIds: function () {
      this.ids.toLock = [];
      this.ids.toUnlock = [];
      this.checkPermissions();
      for (let key in this.ticketsObject) {
        let ticket = this.ticketsObject[key];
        if (canUnlockTicket(ticket, window.userId)) {
          this.ids.toUnlock.push(ticket._id);
          this.ids.toUnlock =  this.ids.toUnlock.filter((value, index, self)=>{
            return self.indexOf(value) === index;
          })
        }
        canLockTicket(ticket).then((result) => {
          if (result && !canUnlockTicket(ticket, window.userId)) {
            this.ids.toLock.push(ticket._id);
            this.ids.toLock =  this.ids.toLock.filter((value, index, self)=>{
               return self.indexOf(value) === index;
            })
          }
        }).catch(() => {});
      }
    },
    takeToWork: function () {
      if (this.ids.toLock.length > 0) {
        this.$store.dispatch('tickets/takeTicketsToWork', {
          ticketIds: this.ids.toLock,
          route: this.$route,
          finallyCallback: this.updateIds
        });
      }
    },
    unlockTickets: function () {
      if (this.ids.toUnlock.length > 0) {
        this.$store.dispatch('tickets/unlockTickets', {
          ticketIds: this.ids.toUnlock,
          route: this.$route,
          finallyCallback: this.updateIds
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      "loading": "tickets/isAllLoading",
      "ticketsObject": "tickets/getSelectedObjects",
    })
  }
}
</script>

<style scoped>

</style>