<template>
  <div :class="{'fw-bold':isBold}" class=" v-sidebar-item" @click="goToTickets()" v-if="show">
    <i  :class="icon ? 'fa fa-' + icon : ''" :style="style.icon" aria-hidden="true"></i> <span :style="style.label">{{label}} <read-unread
      :actual-count="actualCount"
      :count="count"
      :count-settings="countSettings"
      :additional-type="type"
      ref="read-unread"
      @open="openWith"
  ></read-unread></span>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ReadUnread from "./filter/ReadUnread";

export default {
  name: "AdditionalList",

  components: {
    'read-unread': ReadUnread,
  },

  data: function (){
    return {

      count: {},
      actualCount: false,
    }
  },
  emits:[

  ],
  props:{
    routeName:{
      type:String,
      require:true,
    },

    icon: {
      type: String,
    },
    style: {
      type: Object,
      default: {label: null, icon: null}
    },
    type: {
      type: String,
    },
    countSettings: {},
    label: {
      type: String,
    }
  },
  computed:{
    ...mapGetters({
      "isLoading":  "tickets/isTicketLoading",
      'getSelectionControl':'leftMenu/getSelectionControl',
    }),
    show() {
      return this.count !== null && this.count?.total > 0 || this.type === 'recent';
    },
    isBold:function (){
      if(this.getSelectionControl.filter === null || this.type === null){
        return false
      }
      return this.type.toLowerCase() === this.getSelectionControl.filter.toLowerCase();
    }
  },
  methods:{
    ...mapActions({
      'chooseSelectionFilter':'leftMenu/chooseSelectionFilter',
      "getAllTickets": "tickets/getAllTickets",
    }),

    goToTickets(){
      this.open(false);
    },

    open(name = false) {
      let query = {};
      if (name) {
        let searchParams = {};
        searchParams[name] = true;
        query['searchParams'] = JSON.stringify(searchParams);
      }
      if(!this.isLoading){
        this.$store.dispatch('tickets/reset' , true);
        this.chooseSelectionFilter({filter:this.routeName});
        this.$router.push({
          name: this.routeName,
          query: query,
        });
        if (this.isBold) {
          this.getAllTickets(this.$route);
        }
      }
    },

    openWith(name) {
      this.open(name);
    },
  },
  created() {
    if(this.$route.name === this.routeName){
      this.chooseSelectionFilter({filter:this.routeName})
    }
  },
}
</script>

<style scoped>
.v-sidebar-item{
  cursor: pointer;
  padding-left: 5px;
  border-radius: 5px;
}
.v-sidebar-item:hover{
  background-color: #d3fad3;
}

</style>