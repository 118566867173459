

export default {
    setTicket(state, ticket) {
        state.ticket = ticket;
    },
    setMessage(state , value){
      state.message =  value;
    },
    setPhone(state, value) {
        state.phone = value;
    },

    openWindow(state){
        state.windowOpen = true;
    },
    closeWindow(state){
        state.windowOpen = false;
    },
    setStatus(state , value){
        state.status = value
    },
    clearStatus(state){
        state.status = ''
    },
    startTimer(state){
        state.startTimer = true;
    },
    stopTimer(state){
        state.startTimer = false;
    },

    setCurrentCall(state , value){
        state.currentCall = value
    },
    resetCurrentCall(state ){
        state.currentCall = null
    },

    setRealCheckMessageId(state , value){
        state.realCheckMessageId = value;
    }

}
