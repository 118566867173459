<template>
  <transition name="search-settings" >
    <div class="search-settings-border">
      <span class="bordered-cell" style="z-index: 1">
        <div v-for="object in getSearchSettingsToShow" class="form-check form-check-inline">
          <check-box :ref="object.id" :object="object"></check-box>
        </div>
        <select class="form-check form-check-inline px-1" @change="changeType" v-model="type">
            <option value="current">В работе</option>
            <option value="expired">Просроченные</option>
            <option value="closed">Выполненные</option>
            <option value="all">Все</option>
        </select>
      </span>
    </div>

  </transition>
</template>

<script>
import CheckBox from "../../tableFilter/CheckBox";
import {mapActions, mapGetters} from "vuex";
export default {
  name: "SearchSettingsTasks",
  components: {CheckBox},
  data:function (){
    return {
    }
  },
  created() {
    this.type = 'current';
    if (['all', 'expired', 'closed'].includes(this.$route?.query?.type)) {
      this.type = this.$route?.query?.type;
    }
  },
  computed:{
    ...mapGetters({
      'getSearchSettingsToShow':'tickets/filters/getSearchSettingsToShow',
    }),
    type:{
      set(value){
        this.$store.commit('tickets/filters/setType' , value)
      },
      get(){
        return this.$store.getters['tickets/filters/getType']
      }
    },
  },
  methods:{
    ...mapActions({
      'changeType':'tickets/filters/changeTasksType',
    })
  },
}
</script>

<style scoped>
.bordered-cell{
  border-radius: 5px;
  display: inline-block;
  padding: 5px 10px 5px 5px;
  margin-right: 5px;
}
.search-settings-enter-active, .search-settings-leave-active {
  transition: opacity .5s ease;
}

.search-settings-enter-from, .search-settings-leave-to {
  opacity: 0;
}

.form-check-inline:last-child {
  margin-right: 0;
}
.search-settings-border{
  border-left: 1px solid #dee2e6;
  border-bottom: 1px solid #21252a;
  border-right: 1px solid #dee2e6;
}
</style>