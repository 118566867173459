<template>
  <button class="btn btn-light btn-outline-dark col-12"
          :class="{'text-muted':loading, 'custom-disabled': loading}"
          @click="showModalConvert"><i class="fa fa-sticky-note-o button-icon menu-button"></i>Конвертировать в тикет</button>

  <teleport to="body">
    <transition name="modal">
      <modal-window v-if="showModal" @close="closeModal" @success="convert">
        <template v-slot:header>
          <h5 class="modal-title">Конвертация рассылок</h5>
        </template>
        <template v-slot:body>
          <span>{{getBody}}</span>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="closeModal" :disabled="loading">Отмена</button>
          <button type="button" class="btn btn-primary" @click="convert" :disabled="loading">Конвертировать</button>
        </template>
      </modal-window>
    </transition>

    <Loading v-model:active="loading" />
  </teleport>

</template>

<script>
import Loading from "vue3-loading-overlay";
import {Ticket} from "../../../library/api/Ticket";
import {CrmApi} from "../../../library/CrmApi";
import ModalWindow from "./ModalWindow.vue";
import {getNumeralsWord} from "../../../library/Numerals";
import {getTicketLink} from "../../../library/Locations";

export default {
  name: "ConvertNotificationButton",

  components: {ModalWindow, Loading},

  data() {
    return {
      showModal: false,
      loading: false,
    }
  },

  computed: {
    getBody() {
      let count = this.$store.getters['tickets/getSelectedArray'].length;
      let notifications = count > 1
                            ? getNumeralsWord(count, ['рассылку', 'рассылки', 'рассылок'])
                            : 'рассылку';
      let tickets = count > 1 ? 'тикеты' : 'тикет';

      return `Вы уверены, что хотите сконвертировать ${notifications} в ${tickets}?`;
    },
  },

  methods: {
    showModalConvert() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketConvert');
    },

    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketConvert');
    },

    convert() {
      this.loading = true;
      let ids = this.$store.getters['tickets/getSelectedArray'];
      Ticket.convertNotifications(ids)
            .then((response) => {
              let converted = response.data.converted;
              if(this.$route.name === 'Notification') {
                window.location.replace( window.location.origin + '/tickets/' + converted[ ids[0] ]);
              } else {
                this.$store.dispatch('tickets/getTickets', {payload: this.$route});
                if(ids.length > 1) {
                  this.$store.commit('successPush', 'Рассылки сконвертированы в тикеты');
                }else {
                  let link = getTicketLink({ticket_id: converted[ids[0]]});
                  this.$store.commit('htmlSuccessPush', `Рассылка сконвертирована в <a href="${link}" target="_blank">тикет</a>`);
                }
              }
            }).catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
              .finally(() => this.loading = false);
    }
  }
}
</script>

<style scoped>

</style>