<template>
  <div class="ticket-menu" :class="this.class">
    <documents v-if="!isDeleted" :ticket-id="ticketId" />
    <invoices v-if="showInvoices" :ticket-id="ticketId" />
    <button class="btn btn-light btn-outline-dark col-12" @click="showAddTaskButton" v-if="canCreateTasks && !isDeleted"><i class="fa fa-sticky-note-o button-icon menu-button"></i> Создать задачу</button>
    <button class="btn btn-light btn-outline-dark col-12" @click="showCommentForm" v-if="userCan.comment && !isDeleted"><i class="fa fa-commenting"></i> Написать комментарий</button>

    <div class="dropstart" @mouseover="filtersShow = true" @mouseleave="filtersShow = false">
      <div class="btn btn-light btn-outline-dark col-12 dropdown-toggle" id="thread-filters" data-bs-toggle="dropdown" ref="filters-dropdown" :class="{'show': filtersShow}" :aria-expanded="filtersShow ? 'true' : 'false'">
        <i class="fa fa-filter"></i> Фильтры
      </div>
      <div class="dropdown-menu w-100 text-center menu-dropdown" aria-labelledby="thread-filters" ref="filters-list" :class="{'show': filtersShow}" :data-bs-popper="filtersShow ? 'none' : ''">
        <button
            class="btn btn-light btn-outline-dark col-12"
            @click="showSearchField"
        >
          <i class="fa fa-search button-icon menu-button"></i> Поиск
        </button>
        <attachments-filter />
        <tasks-filter></tasks-filter>
      </div>
    </div>

    <div v-if="showUrls" class="dropstart" @mouseover="urlsShow = true" @mouseleave="urlsShow = false">
      <div class="btn btn-light btn-outline-dark col-12 dropdown-toggle" :class="{show: urlsShow}" :aria-expanded="urlsShow" id="thread-urls" data-bs-toggle="dropdown" ref="urls-dropdown">
        <i class="fa fa-external-link"></i> Ссылки
      </div>
      <div class="dropdown-menu w-100 text-center menu-dropdown" aria-labelledby="thread-urls" ref="urls-list" :class="{show: urlsShow}" :data-bs-popper="urlsShow ? 'none': ''">
        <a class="btn btn-light btn-outline-dark col-12" v-if="getProjectUrl('faq')" :href="getProjectUrl('faq')" target="_blank">
          <i class="fa fa-question-circle-o"></i>&nbsp;FAQ (<string-trim :string="ticket.project.name" style="max-width: 8.5em;" :show-popper="false" />)
        </a>
        <a class="btn btn-light btn-outline-dark col-12" v-if="getProductUrl('faq')" :href="getProductUrl('faq')" target="_blank">
          <i class="fa fa-question-circle-o"></i>&nbsp;FAQ (<string-trim :string="ticket.assigned_product.name" style="max-width: 8.5em;" :show-popper="false" />)
        </a>
        <a class="btn btn-light btn-outline-dark col-12"
           v-if="getProjectUrl('glossary')"
           :href="getProjectUrl('glossary')"
           target="_blank"
        ><i class="fa fa-book"></i>&nbsp;Глоссарий</a>
      </div>
    </div>

    <div v-if="showActions" class="dropstart" @mouseover="actionsShow = true" @mouseleave="actionsShow = false">
      <div class="btn btn-light btn-outline-dark col-12 dropdown-toggle" :class="{show: actionsShow}" :aria-expanded="actionsShow" id="thread-actions" data-bs-toggle="dropdown" ref="actions-dropdown">
        <i class="fa fa-external-link"></i> Действия
      </div>

      <div class="dropdown-menu w-100 text-center menu-dropdown" aria-labelledby="thread-actions" ref="actions-list" :class="{show: actionsShow}" :data-bs-popper="actionsShow ? 'none' : ''">
        <auction-menu v-if="showAuctionButtons" :ticket="ticket" />
        <button v-if="showSetNotReadedButton" @click="setNotReaded()" class="btn btn-light btn-outline-dark col-12"><i class="fa fa-eye-slash  button-icon menu-button"></i>Не прочитано</button>
        <toggle-answered v-if="showToggleAnsweredButton" :two-buttons="false" />
        <transfer-button v-if="showTransferButton" :callback="transferCallback" :ticket-id="ticketId" />
        <favorite-button v-if="showFavoriteButton" />
        <close-button v-if="userCan.open_close && !isClosed" />
        <reopen-button v-if="userCan.open_close && isClosed" />
        <delete-button v-if="showDeleteButton" :queue="addToQueue" :callback="deleteCallback" />
        <remove-button v-if="showRemoveButton" :queue="addToQueue" :callback="deleteCallback" />
      </div>
    </div>
    <delete-with-spam-button v-if="showDeleteButton"
                             :queue="addToQueue"
                             :callback="deleteCallback"
                             :competitor-callback="competitorCallback"
                             :ipspam="project?.ipspam"
                             :ipspam-value="ticket?.competitor ?? false"  />
    <template v-if="ticketType === 'ticket'">
      <tags-button v-if="userCan.tags" :callback="tagsCallback" />
    </template>

    <template v-if="ticketType === 'unsorted'">
      <add-to-project v-if="userCan.transfer && !isDeleted" :route="()=>{}" :call-back="goToUnsortedList" />
    </template>
  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import {UserPermission} from "../../users/UserPermission";
import TransferButton from "../tiketMenu/components/transfer/TransferButton";
import TagsButton from "../tiketMenu/components/TagsButton";
import DeleteButton from "../tiketMenu/components/DeleteButton";
import RemoveButton from "../tiketMenu/components/RemoveButton";
import FavoriteButton from "../tiketMenu/components/FavoriteButton";
import AddToProject from "../tiketMenu/components/AddToProject";
import AttachmentsFilter from "../tiketMenu/components/AttachmentsFilter";
import StringTrim from "../string/StringTrim";
import DeleteWithTagButton from "../tiketMenu/components/DeleteWithTagButton";
import TasksFilter from "../tiketMenu/components/TasksFilter";
import {mapActions, mapGetters} from "vuex";
import Documents from "../tiketMenu/components/Documents/Documents";
import DeleteWithSpamButton from "../tiketMenu/components/DeleteWithSpamButton";
import ToggleAnswered from "../tiketMenu/components/ToggleAnswered";
import Invoices from "../tiketMenu/components/Invoices/Invoices";
import AuctionMenu from "../tiketMenu/components/auction/AuctionMenu";
import CloseButton from "../tiketMenu/components/CloseButton";
import ReopenButton from "../tiketMenu/components/ReopenButton";

export default {
  name: "TicketMenu",

  components: {
    AuctionMenu,
    Invoices,
    ToggleAnswered,
    DeleteWithSpamButton,
    Documents,
    TasksFilter,
    DeleteWithTagButton,
    StringTrim,
    AttachmentsFilter,
    AddToProject,
    RemoveButton,
    DeleteButton,
    TransferButton,
    TagsButton,
    FavoriteButton,
    CloseButton,
    ReopenButton,
  },

  props: {
    class: {},
  },

  data: function() {
    return {
      ticketType: null,
      queue: null,
      queueInterval: null,
      _project: null,
      userCan: {
        answer: false,
        headers: false,
        transfer: false,
        delete: false,
        remove: false,
        tags: false,
        comment: false,
        task_create: false,
        invoice: false,
        open_close: false,
      },
      filtersShow: false,
      urlsShow: false,
      actionsShow: false,
    }
  },

  created() {
    if(this.ticket !== null) {
      // если тикет уже загружен, сразу проверяем права
      this.loadPermissions();
    } else {
      // если тикет еще не загружен, ставим watch ожидающий его загрузки, и который сработает только один раз
      let loadThreadWatch = this.$watch('ticket', (ticket) => {
        if(ticket !== null) {
          this.loadPermissions();
          loadThreadWatch();
        }
      });
    }
  },

  computed: {
    showAuctionButtons(){
      return this.userCanSetLot && (this.ticket?.assigned_product?.additional_fields ?? []).map((el)=>{return el['type']}).includes('sell_ip');
    },
    showSetNotReadedButton() {
      return !this.isDeleted && ['ticket', 'unsorted'].includes(this.ticketType);
    },

    showToggleAnsweredButton() {
      return !this.isClosed && !this.isDeleted && this.userCan.answer && this.ticketType === 'ticket';
    },

    showTransferButton() {
      return !this.isDeleted && this.userCan.transfer && this.ticketType === 'ticket';
    },

    showFavoriteButton() {
      return !this.isDeleted && this.ticketType === 'ticket';
    },

    showDeleteButton() {
      return this.userCan.delete &&
             !this.isDeleted &&
             ['unsorted', 'ticket', null].includes(this.ticketType);
    },

    showRemoveButton() {
      return !this.showDeleteButton && this.userCan.remove && this.ticketType !== 'unsorted';
    },

    showActions() {
      return ['SetNotReaded', 'ToggleAnswered', 'Transfer', 'Favorite', 'Delete', 'Remove'].find(func => this[`show${func}Button`]);
    },

    showUrls: function() {
      return (this.getProjectUrl('faq') ||
              this.getProjectUrl('glossary') ||
              this.getProductUrl('faq'));
    },

    showInvoices() {
      return (this.userCan.invoice && (this.ticket?.assigned_product?.tariffs ?? []).length > 0);
    },

    isDeleted() {
      return this.ticket?.deleted === true;
    },

    isClosed() {
      return this.ticket?.closed === true;
    },

    project() {
      if(
          (this.ticket !== null && typeof this.ticket?.project !== 'undefined') &&
          (this._project === null || this._project._id !== this.ticket.project?._id)
      ) {
        this._project = this.$store.getters['tickets/projects/getProject'](this.ticket.project?.name);
      }
      return this._project;
    },

    ticketId() {
      return this.$route.params.id ?? ((this.ticket !== null) ? window.getId(this.ticket._id) : null);
    },

    canCreateTasks() {
      return this.canTasksControl || this.isUserTaskCreatePermission;
    },

    ...mapGetters({
      ticket: 'thread/getTicket',
      "isUserTaskCreatePermission": "thread/isUserTaskCreatePermission",
      "canTasksControl": "thread/tasks/canTasksControl",
      "userCanSetLot": "thread/userCanSetLot",
    }),
  },

  methods: {
    loadPermissions() {
      if(typeof this.ticket.project !== 'undefined' && this.ticket.project !== null) {
        this.getPermissions(this.ticket.project.name);
        this.ticketType = 'ticket';
        this.$store.dispatch('tickets/selectElement', this.ticket);
      } else {
        this.getPermissions(null);
      }
    },

    goToUnsortedList(){
      this.$router.push({name: 'Unsorted'});
    },
    getProjectUrl(type) {
      return (this.project !== null &&
          typeof this.project?.urls === 'object' &&
          typeof this.project.urls[type] === 'string' &&
          this.project.urls[type].length) ? this.project.urls[type] : null;
    },

    getProductUrl(type) {
      return ((this.ticket !== null && typeof this.ticket.assigned_product !== 'undefined') &&
          typeof this.ticket.assigned_product.urls !== 'undefined' &&
          typeof this.ticket.assigned_product.urls[type] !== 'undefined' &&
          this.ticket.assigned_product.urls[type] != null &&
          this.ticket.assigned_product.urls[type].length) ? this.ticket.assigned_product.urls[type] : null;
    },

    showCommentForm: function() {
      this.$store.dispatch('thread/removeSelectedTemplate');
      this.$store.commit('thread/showCommentForm');
      this.$store.commit('thread/setCommentMessage', null)
    },

    setNotReaded: function (){
      this.addToQueue(() => {
        return CrmApi.setNotReaded([this.ticketId]).then(() => {
          this.$store.state.tickets.resetCounter = true;
          this.$store.commit('tickets/toLastSelect', false);
          this.redirectToTickets();
          this.$store.commit('successPush', "Тикет помечен как непрочитанный");
        })
      });
    },

    deleteCallback: function() {
      this.$store.state.tickets.resetCounter = true;
      this.redirectToTickets();
    },

    tagsCallback: function() {
      this.reloadTicket();
      this.$store.commit('successPush', 'Метки успешно изменены', {root:true});
    },

    competitorCallback() {
      this.$store.commit('successPush', 'Обновлено', {root:true});
      this.redirectToTickets();
    },

    transferCallback: function(project, assigned_projects) {
      this.reloadTicket().then(() => {
        let text = '';

        if(typeof project !== 'undefined' && project === this.ticket.project._id) {
          text = 'Тикет успешно передан в проект ' + this.ticket.project.name;
        } else if(typeof assigned_projects !== 'undefined') {
          if(assigned_projects.length) {
            let assigned = this.ticket.assigned_projects.filter(project => assigned_projects.includes( window.getId(project._id) ))
                                                        .map(project => project.name)
                                                        .join(', ');
            text = 'Тикет назначен в ' + ((assigned_projects.length === 1) ? 'проект ' : 'проекты ') + assigned;
          } else {
            text = 'Теперь тикет не назначен в дополнительные проекты';
          }
        }

        if (text !== '') {
          this.$store.commit('successPush', text, {root: true});
        }
      });
    },

    reloadTicket: function() {
      return CrmApi.getTicketList( {
        "_id": this.ticketId,
        "withDeleted": true,
      }).then((result) => {
        this.$store.commit('thread/setTicket', result.data.data[0]);
        this.$store.dispatch('tickets/selectElement', result.data.data[0]);

        if (typeof this.$parent.reloadTicketData === 'function') {
          this.$parent.reloadTicketData();
        }
      })
    },

    addToQueue: function(func) {
      if(this.isLoading()) {
        this.queue = func;
      } else {
        this.setLoading();
        if(typeof func.finally === 'function') {
          func().finally(() => { this.setLoading(false); });
        } else {
          func();
        }
      }
    },

    isLoading: function() {
      return this.$store.state.thread.loading;
    },

    setLoading: function(status = true) {
      return this.$store.state.thread.loading = status;
    },

    getPermissions(projectName = null) {
      let permissions = [
        projectName ? projectName + ".headers" : "system.headers",
        projectName ? projectName + ".change" : "system.read_pending",
        projectName ? projectName + '.delete' : "system.delete_pending",
        projectName ? projectName + '.ticket_delete' : "system.delete_pending",
        "system.tag_control",
        projectName ? projectName + '.answer' : 'system.answer_pending',
        projectName ? projectName + '.internal_answer' : 'system.answer_pending',
      ];

      if(projectName) {
        permissions.push(`${projectName}.billmgrinvoice`);
        permissions.push(`${projectName}.open_close`);
      }

      let rights = [
          'headers',
          'transfer',
          'delete',
          'remove',
          'tags',
          null,
          null,
      ];
      UserPermission.can(permissions).catch(() => {}); // preloading purpose
      this.userCan.comment = false;
      rights.forEach((name, index) => {
        if (name && typeof permissions[index] !== 'undefined' && typeof this.userCan[name] !== 'undefined') {
          UserPermission.can(permissions[index]).then(() => {
            this.userCan[name] = true;
          }).catch(() => {
            this.userCan[name] = false;
          });
        }
      });
      UserPermission.can(permissions[5]).then(() => {
        this.userCan.comment = true;
        this.userCan.answer = true;
      }).catch(() => {});
      UserPermission.can(permissions[6]).then(() => {
        this.userCan.comment = true;
      }).catch(() => {});
      if(typeof permissions[7] === 'string') {
        UserPermission.can(permissions[7]).then(() => {
          this.userCan.invoice = true;
        });
      }
      this.userCan.open_close = false;
      if(projectName === null) {
        UserPermission.can(permissions[2]).catch(() => {
          let deletePermissions = [];
          this.$store.getters['leftMenu/getTopLevel']
                     .filter(item => item?._id?.length === 24)
                     .forEach(item => deletePermissions.push(item.name + '.delete'));
          if(deletePermissions.length) {
            UserPermission.can(deletePermissions).then(() => this.userCan.delete = true);
          }
        });
      } else {
        UserPermission.can(`${projectName}.open_close`).then(() => this.userCan.open_close = true);
      }

      this.userCan.task_create = this.canTasksControl || this.isUserTaskCreatePermission;
    },

    showAddTaskButton() {
      this.$store.commit('thread/setShowAddTaskForm', true);
      this.$store.commit('thread/closeCommentForm');
    },

    showSearchField() {
      this.$store.commit('thread/setShowSearchField', true);
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    }),
  },

  watch: {
    "$store.state.thread.ticketType" (type) {
      if(type !== null) {
        if(type === 'unsorted') {
          this.getPermissions(null);
        }

        this.ticketType = type;
      }
    },

    '$store.state.thread.loading' (to) {
      if(!to && this.queue !== null) {
        this.queueInterval = setInterval(() => {
          if(!this.isLoading()) {
            this.setLoading();
            this.queue().finally(() => {
              this.queue = null;
              this.setLoading(false);
            })
          }
        }, 3);
      } else {
        clearInterval(this.queueInterval);
      }
    }
  }
}
</script>

<style scoped>
.menu-dropdown {
  border-radius: unset;
  margin: 0 !important;
  padding: 0;
  border: none;
}
.ticket-menu {
  z-index: 1100;
  padding: 0;
  width: 15em;
}
.flex-link {
  display: flex;
  align-items: center;
}
.dropstart .dropdown-toggle::before {
  float: left;
  margin-top: 7px;
}
</style>