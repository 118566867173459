<template>
  <teleport to="body">
    <transition name="modal">
      <modal-window
        v-if="showModal"
        @close="showModalFunction(false)"
        @success="moveMessages"
        modal-dialog-class="modal-lg"
      >
        <template v-slot:header>
          <h5 class="modal-title">Перенос сообщений</h5>
        </template>
        <template v-slot:body>
          <search-ticket
            @selectElement="setTicket"
            :except="this.message.ticket._id"
          ></search-ticket>

          <div class="mt-3" v-if="message.type !== 'call'">
            <div class="form-check">
              <label class="form-check-label" for="with-chain">Перенести всю цепочку</label>
              <input class="form-check-input" type="checkbox" v-model="withChain" id="with-chain">
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn btn-primary" :disabled="submitDisabled || loading" @click="moveMessages">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Перенести
          </button>
        </template>
      </modal-window>
    </transition>
    <transition name="modal-confirm">
      <modal-window
        v-if="showModalTwo"
        @close="showModalFunction(false)"
        @success="moveTicket"
        modal-dialog-class="modal-confirm"
      >
        <template v-slot:header>
          <h5 class="modal-title">Перенос сообщений</h5>
        </template>
        <template v-slot:body>
          <div>
            <div>После переноса выбранного сообщения в текущем тикете не останется сообщений. Перенести тикет полностью?</div>
            <div>Данное действие безвозвратно удалит запрос и заданные в нем параметры услуги.</div>
          </div>
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="showModalFunction(false)">Отмена</button>
          <button type="button" class="btn btn-primary" @click="moveTicket" :disabled="mergeLoading || mergeRedirecting">
            <span v-if="mergeLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Перенести
          </button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import modalWindow from "../../../tiketMenu/components/ModalWindow";
import searchTicket from "./SearchTicket";
import {Messages} from "../../../../library/api/Messages";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  name: "MoveMessagesModals",

  components: {
    modalWindow,
    searchTicket
  },

  props: {
    message: {
      type: Object,
    },
    ticket: {
      type: Object,
    },
  },

  emits: [
      "close",
      "update",
  ],

  data() {
    return {
      showModal: true,
      showModalTwo: false,
      selectedTicket: null,
      loading: false,
      mergeLoading: false,
      mergeRedirecting: false,
      withChain: false,
    }
  },

  computed: {
    submitDisabled() {
      return !this.selectedTicket;
    }
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
      if (!this.showModal) {
        this.$emit('close');
      }
    },
    setTicket(value) {
      this.selectedTicket = value;
    },
    moveMessages() {
      if (!this.submitDisabled) {
        this.loading = true;
        Messages.move({
          message: window.getId(this.message._id),
          ticket: this.selectedTicket,
          withChain: this.withChain,
        }).then((result) => {
          this.showModalFunction(false);
          this.$store.commit('successPush', 'Сообщения перенесены');
          this.$emit('update');
        }).catch((error) => {
          let errors = CrmApi.getErrorMessage(error);
          if (Array.isArray(errors) && errors.length === 1 && errors[0] === 'После переноса выбранного сообщения в текущем тикете не останется сообщений.') {
            this.showModalTwo = true;
            this.showModal = false;
          } else {
            this.$store.commit('errorPush', errors);
          }
        }).finally(() => {
          this.loading = false;
        });
      }
    },
    moveTicket() {
      if (this.mergeLoading || this.mergeRedirecting) {
        return;
      }
      this.mergeLoading = true;
      this.$store.dispatch('tickets/mergeTicketsAndProjects', {
        tickets: [window.getId(this.message.ticket._id)],
        from: [window.getId(this.message.ticket._id)],
        to: this.selectedTicket,
        action: 'transferTicket',
        route: this.callback ?? this.$route,
      }).then(() => {
        this.mergeRedirecting = true;
        window.location.href = '/tickets/' + encodeURIComponent(window.getId(this.selectedTicket));
      }).finally(() => {
        this.mergeLoading = false;
      });
    },
  },

}
</script>

<style scoped>
:deep(.modal-confirm .modal-body) {
  margin: 0;
}
</style>