import {
    callPerson,
    getCallInfoFromMessage,
    getStatus,
    hangUp,
    isHasEvent
} from "./PhonesFunctions";
import {CrmApi} from "../../../library/CrmApi";


export const reset = ({state , commit, dispatch, getters}) =>{
    dispatch('stopCheckCall')
    state.firstView = false;
    state.realCheckMessageId = null;
    commit('setMessage' , null);
    state.phone  = "";
    state.status = '';
    state.checkCount = 0;

    commit('resetCurrentCall');

}



export const callToPerson = ({state , commit, dispatch, getters}, {busyCallback, loadingCallback}) => {
    let call = null;
    if(typeof getters.getTicket?._id !== 'undefined'){
        if(typeof loadingCallback === 'function') {
            loadingCallback(true);
        }

        callPerson(getters.getTicket._id , getters.getPhone )
            .then((result)=>{
                commit('openWindow');
                commit('setStatus' , getStatus("DIALING"))
                call = getCallInfoFromMessage(result.data.message);
                commit('setMessage' , result.data.message)
                commit('setRealCheckMessageId', window.getId(result.data.message._id))
                if(getStatus(getters.getStatus) === getStatus('end')){
                    dispatch('closeWindow')
                }
            }).catch((message)=>{
                commit('setMessage', null);
                commit('setStatus', getStatus('end'));

                if(message.response.status === 409 && typeof busyCallback === 'function') {
                    busyCallback(message.response.data.message);
                } else {
                    commit('errorPush', CrmApi.getErrorMessage(message), {root: true});
                }
            }).finally(() =>{
                if(typeof loadingCallback === 'function') {
                    loadingCallback(false);
                }

                if(state.status === getStatus('end')){
                    dispatch('endCall' , false, true)
                }else {
                    if(call !== null){
                        state.firstView = true;
                        state.checkCount= 0

                        commit('setStatus' , getStatus(call.status))
                        commit('setCurrentCall', call)
                    }else {
                        commit('setStatus' , getStatus('end'))
                    }
                }

            });
    } else {
        dispatch('endCall')
    }

}

export const openWindow  = ({state , commit, dispatch, getters}, {phone, busyCallback, loadingCallback}) => {
    dispatch('reset')
    commit('setPhone' , phone);
    dispatch('callToPerson', {busyCallback: busyCallback, loadingCallback: loadingCallback});
}
const needToContinueCheck = function ({state , commit, dispatch, getters} , call , message){

    if(state.stopCheckIds.includes(window.getId(message._id))){
        return true;
    }

    if(state.firstView){
        state.checkCount++
        if(call === null && state.checkCount < 10){
            return true;
        }
        state.checkCount = 0;
        state.firstView = false;
    }


    if(state.closeView){
        state.checkCount++
        if(call !== null && state.checkCount < 10){
            return true;
        }
        state.checkCount = 0;
        state.closeView = false;
    }

    return false;
}
export const checkCurrentCall = ({state , commit, dispatch, getters} , {message}) =>{
    if(state.realCheckMessageId !== null && state.realCheckMessageId  !==  window.getId(message._id)
    ){
        return;
    }
    if(window.getId(message.author._id) !== window.userId ){
        return;
    }

    let call = getCallInfoFromMessage(message);
    if(needToContinueCheck({state , commit, dispatch, getters} , call , message)){
        return;
    }
    if(state.realCheckMessageId !== null){
        commit('setStatus' , getStatus( call.status));
        commit('setMessage', message)
    }


    if(state.realCheckMessageId !== null && (isHasEvent(message, 'end') || getStatus( call.status) === getStatus('end'))){
        commit('resetCurrentCall');
        state.stopCheckIds.push(window.getId(getters.getMessage._id))
        dispatch('endCall', true , true)
    }else {
        if((state.realCheckMessageId === null || getters.getCurrentCall === null) && call !== null
            && getStatus( call.status) !== getStatus('end') ){
            dispatch('reset')
            commit('setMessage' , message)
            commit('setRealCheckMessageId', window.getId(message._id))
            commit('setPhone' , call.anumber)
            commit('setCurrentCall' , call)
            commit('setStatus' , getStatus( call.status));
            commit('openWindow')
        }
    }



}

export const closeWindow= ({state , commit, dispatch, getters}) => {
    return new Promise((resolve, reject) => {
        commit('stopTimer');
        commit('setStatus' , getStatus('end'));
        if(getters.getCurrentCall !== null && getters.getMessage !== null){
            state.stopCheckIds.push(window.getId(getters.getMessage._id))
            state.closeView = true;
            state.checkCount = 0;
            hangUp( window.getId(getters.getMessage.ticket._id ),  getters.getCurrentCall.callid).then(()=>{
                resolve()
            } ).catch((message)=>{
                commit('errorPush' ,CrmApi.getErrorMessage(message), {root:true});
                reject()
            }).finally(()=>{
                dispatch('endCall', true)
            })
        }else {
            commit('closeWindow')
            resolve()
        }
    })

}

export const endCall= ({state , commit, dispatch, getters} , withoutStopCheck = false , withTimer = false) =>{
    commit('setStatus' , getStatus('end'));
    state.realCheckMessageId = null;
    if(!withoutStopCheck){
        state.closeView = true;
        state.checkCount = 0;
    }
    if(withTimer){
        window.setTimeout(()=>{
            commit('closeWindow')
            dispatch('reset')
        }, 500)
    }else {
        commit('closeWindow')
        dispatch('reset')
    }

}

export const timerEnd = ({state , commit, dispatch, getters}) =>{
    commit('stopTimer');
    dispatch('callToPerson')
}

export const closeIfStart= ({state , commit, dispatch, getters} ) =>{
    if(state.status === getStatus('start')){
        dispatch('closeWindow')
        dispatch('reset')
    }
}

export const stopCheckCall = ({state , commit, dispatch, getters} ) =>{
    state.checkTicket = false;
    window.clearTimeout(state.timeout );
    state.timeout = null;
}

export const startCheckMessage = ({state , commit, dispatch, getters} , {} ) =>{
    if(state.realCheckMessageId !== null &&  getStatus( getters.getMessage?.headers?.status) !== getStatus('end')  ){
        state.checkTicket = true;
        dispatch('checkMessage' , {message:getters.getMessage})
    }else {
        dispatch('reset')
        commit('closeWindow')
    }
}

export const checkMessage = ({state , commit, dispatch, getters} , ) => {
    window.clearTimeout(state.timeout );
    if(state.realCheckMessageId !== null && getStatus( getters.getMessage?.headers?.status) !== getStatus('end')){
        state.timeout = window.setTimeout(() => {
            CrmApi.getThread( window.getId(getters.getMessage.ticket._id), window.getId(getters.getMessage._id) ).then((result) => {
                if(state.checkTicket && result.data?.messages?.data[0] ){
                    dispatch('checkCurrentCall' , {message:result.data.messages.data[0]}).then(()=>{
                        dispatch('checkMessage' , {message:result.data.messages.data[0]});
                    })
                }else {
                    dispatch('reset')
                    commit('closeWindow')
                }
            }).catch((message)=>{
                dispatch('reset')
                commit('closeWindow')
                commit('errorPush' ,CrmApi.getErrorMessage(message), {root:true});

            })
        } , 1000)
    }else {
        dispatch('reset')
        commit('closeWindow')
    }
}