<template>
  <span v-if="!asInput" class="busy-popper d-flex">
    <popper placement="left" class="warning" :arrow="true" :show="busyPopper.show" :content="busyPopper.message" offset-distance="0">
      <span class="editable-text d-inline-block"
            :class="{'cursor-pointer': isCursorPointer, 'text-primary': userCanChange, 'opacity-75': loading}" @click="doActionByType()"
            @dblclick="showInput()">
        <template v-if="trim"><string-trim :string="value" /></template>
        <template v-else>{{value}}</template>
      </span>
    </popper>
  </span>
  <phone
      v-else
      :select-on-mount="true"
      v-model.trim="value"
      v-focus="autofocus"
      :input-class="inputClass"
      :input-style="inputStyle"
      :update-model="updateModel"
      :absolute="absolute"
      :absolute-parent="absoluteParent"
      :dynamic-absolute-width="true"
      @focusout="focus"
      @keyup.esc.stop="cancel"
      @keyup.enter.stop="edit"
      :disabled="disabled"
      ref="phone"
  />
</template>

<script>
import Phone from "./Phone";
import StringTrim from "../../string/StringTrim";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "EditablePhone",
  components: {Phone, StringTrim},
  props:{
    'phone':{
      type:Object,
      default:{}
    },
    'modelValue':{
      type:String,
      require:true,
    },
    'openInput':{
      default:false,
    },
    isCallPermission:{
      default:false,
    },
    callback: {
      type: Function,
    },
    autofocus:{
      type: Boolean,
      default: false,
    },
    callbackParams: {
      default: null,
    },
    updateModel: {
      default: true,
    },
    inputClass: {
      type: String,
    },
    inputStyle: {
      type: String,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    absoluteParent: {
      default: null,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    startAsInput: {
      type: Boolean,
      default: false,
    },
  },
  data:function (){
    return{
      asInput: false,
      oldValue:'',
      busyPopper: {
        show: false,
        message: null
      },
      loading: false,
    }
  },
  emits: ['update:modelValue', 'enter', 'close', "cancel", 'open'],
  computed: {
    isItTelegram(){
      return this.phone.type === 'telegram' ||  (this.phone.type === 'другой' && this.phone.number.charAt(0) === '@')
    },
    isWhatsapp(){
      return this.phone.type === 'whatsapp'
    },
    isPhone(){
      return this.phone.type === 'мобильный' ||  this.phone.type === 'рабочий' || (this.phone.type === 'другой' && this.phone.number.charAt(0) === '+')
    },
    isCursorPointer(){
      let isCursorPointer = false;
      let options = [
          this.isItTelegram,
          this.isWhatsapp,
          this.isPhone
      ]
      for (let el of options){
        if(el){
          isCursorPointer = true
          break;
        }
      }

      return isCursorPointer && !this.loading && (this.userCanChange || this.isCallPermission);

    },
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        if(this.updateModel) {
          this.$emit('update:modelValue', value)
        }
      }
    },
    ...mapGetters({
      "isSipWindowOpen":"sip/isWindowOpen"
    }),
  },
  mounted() {
    this.oldValue = this.value;
    if (this.value === '' || this.startAsInput) {
      this.asInput = true;
    }
  },

  methods: {
    ...mapActions({
      'openWindow':'sip/openWindow',
      'closeSip':'sip/closeWindow'
    }),

    setLoading(loading) {
      this.loading = loading;
    },

    doActionByType:function (){
      if(this.isItTelegram){
        if(this.phone.number !== ''){
          let nick = this.phone.number.substr(1);
          this.openWithTimeout('https://telegram.me/' + encodeURIComponent(nick) );
        }
      }else if(this.isWhatsapp){
        if(this.phone.number.charAt(0) === '@'){
          this.openWithTimeout('https://wa.me/' +encodeURIComponent(this.phone.number.substr(1)));
        }else {
          let nick = this.phone.number.match( /\d+/g);
          if(nick.length > 0){
            this.openWithTimeout('https://wa.me/' +encodeURIComponent(nick.join('')));
          }else {
            this.openWithTimeout('https://wa.me/' +encodeURIComponent(this.phone.number));
          }
        }


      }else if(this.isPhone){
        this.actionIfPhone()
      }

    },

    openWithTimeout(url , target = '_blank'){
      setTimeout(()=>{
        if(!this.asInput){
          window.open(url, target);
        }
      }, 200);
    },

    // TODO действие при клике на звонок
     actionIfPhone(){
        if(this.isCallPermission){
          setTimeout(()=>{
            if(!this.asInput){
              if(this.isSipWindowOpen){
                this.closeSip().then(
                    ()=>{
                      this.$nextTick(()=>{
                        this.openWindow({phone:this.phone.number,
                                         busyCallback: this.showBusyPopper,
                                         loadingCallback: this.setLoading});
                      })

                    }
                );

              }else {
                this.openWindow({phone:this.phone.number,
                                 busyCallback: this.showBusyPopper,
                                 loadingCallback: this.setLoading});
              }

            }
          }, 200);
        }
    },

    showBusyPopper(error) {
      this.busyPopper.message = error;
      this.busyPopper.show = true;

      setTimeout(() => {
        this.busyPopper.show = false;
        this.busyPopper.message = null;
      }, 2000)
    },

    showInput: function() {
      if(this.userCanChange) {
        this.asInput = true;
        this.$emit('open');
      }
    },
    focus(e) {
      let can = true;
      if (typeof e.explicitOriginalTarget !== 'undefined' && typeof this.$refs.phone?.$el !== 'undefined') {
        if (e.explicitOriginalTarget === this.$refs.phone.$el) {
          can = false;
        }
      }
      if (can) {
        if (this.openInput) {
          this.edit();
        } else {
          this.cancel();
        }
      }
    },
    edit:function (e){
      let value = e?.target?.value ?? this.value;

      if ((this.openInput && this.value.trim() !== '') || !this.openInput) {
        if (typeof this.callback === 'function') {
          let result = this.callback(value, this.callbackParams);
          if (
              typeof result === 'undefined' ||
              result === null ||
              result === true
          ) {
            this.success(value);
          } else if (
              (typeof result === "object" || typeof result === "function") &&
              typeof result.then === "function"
          ) {
            result.then((a) => {
              this.success(value);
            }, (err) => {
              this.gotError(err);
            }).catch((err) => {
              this.gotError(err);
            });
          }
        } else {
          this.success(value);
        }
      }
    },

    success(value) {
      this.oldValue = value;
      if (this.updateModel) {
        this.$emit('update:modelValue', value);
      }
      this.$emit('enter', value, this.callbackParams);
      this.$emit('close', value, this.callbackParams);
      this.asInput = false;
    },

    gotError(error) {
      if (this.$refs?.phone && typeof this.$refs?.phone?.select === 'function') {
        this.$refs.phone.select();
      }
    },

    cancel() {
      if( (this.openInput && this.value.trim() !== '') || !this.openInput ){
        if (this.asInput) {
          this.value = this.oldValue;
          this.$emit('close', this.value, this.callbackParams);
          this.$emit('cancel', this.value, this.callbackParams);
          this.asInput = false;
        }
      }
    },
  },
}
</script>

<style scoped>
.cursor-pointer{
  cursor: pointer;
}
.busy-popper:deep(.popper) {
  position: fixed !important;
  word-break: normal! important;
  z-index: 1000;
}
.warning {
  display: inline-block !important;
  --popper-theme-background-color: #f5a35b;
  --popper-theme-background-color-hover: #f5a35b;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 5px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
</style>