const plugin = {
    install (Vue) {
        Vue.directive('singleClick', {
            mounted(el, binding) {
                if(typeof binding.value === 'function') {
                    el.style.cursor = 'pointer';

                    let timeout = null;

                    $(el).on('click.vueSingleClick', () => {
                        if (timeout === null) {
                            timeout = setTimeout(() => {
                                binding.value();
                                timeout = null;
                            }, 500);
                        } else {
                            clearTimeout(timeout);
                            timeout = null;
                        }
                    });
                } else {
                    console.warn('Only function allows as parameter for singleClick');
                }
            },
        });
    }
}

export default plugin