const offerState = {
        showOffersTable: false,
        offersTableType: null,
        offersCloseCallback: null,

        ticketScrollPosition: null,

        offers: {
            list: new Map(),
        },

        persons: {
            list: new Map(),
        },

        paginator: {
            page: 1,
            onpage: 50,
            links: [],
            total: 0,
        },

        confirmedOffer: null,

        loading: false,
        updateList: false,
};

export default offerState;