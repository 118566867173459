<template>

  <i class="fa fa-bars drag-handle" aria-hidden="true" v-if="draggable"></i>

  <string-trim :string="keyword.keyword" />

  <popper v-if="keyword.showConditions?.length"
          class="light conditions-popper"
          placement="top"
          :hover="true"
          :interactive="false"
          :arrow="true">
    <i class="fa fa-exclamation text-secondary px-1" aria-hidden="true"></i>
    <template #content>
      <span v-html="showCondtionsText"></span>
    </template>
  </popper>

  <popper v-if="keyword?.required === false"
          content="Поле не обязательно для заполнения"
          class="light conditions-popper"
          placement="top"
          :hover="true"
          :interactive="false"
          :arrow="true">
    <i class="fa fa-info-circle text-secondary px-1" aria-hidden="true"></i>
  </popper>

  <popper v-if="keyword?.warnings?.length"
          class="light conditions-popper"
          placement="top"
          :hover="true"
          :interactive="false"
          :arrow="true">

    <i class="fa fa-exclamation-triangle text-warning ps-1" aria-hidden="true"></i>

    <template #content>
      <template v-for="warning in keyword.warnings">

        <!--div v-if="warning === 'missingSpaces'" class="small">
          <b>Возможна потеря пробелов вокруг ключа</b>: ключ находится в одном теге вместе с пробелом
        </div-->

      </template>
    </template>

  </popper>

</template>

<script>
import StringTrim from "../../../string/StringTrim.vue";

export default {
  name: "KeyLabelName",
  components: {StringTrim},

  props: {
    keyword: Object,
    document: Object,
    draggable: Boolean,
  },

  computed: {
    showCondtionsText() {
      let text = '';
      if(this.keyword.showConditions?.length) {
        text = 'Этот параметр отображается при: <br>';
        let conditionsText = [];

        this.keyword.showConditions.forEach(conditions => {
          // поддержка старого формата хранения условий
          if(typeof conditions === 'string') {
            conditions = [conditions]
          }

          let condAnd = [];
          conditions.forEach(param => {
            let [group, keys] = param.split(':');
            let condOr = [];

            let i = 0;
            keys = keys.split(',');
            keys.forEach(key => {
              let parentParam = this.document.getBlocksByGroup(group).find(keyword => keyword.keyword === key);
              let part = (i === 0 ? ((parentParam?.groupname ?? parentParam.group) + ' - ') : '') +
                         (parentParam?.label ?? parentParam.keyword);
              condOr.push(part);
              i++;
            })
            condAnd.push(condOr.join(' <b>или</b> '));
          })

          conditionsText.push(condAnd.join(' <b>и</b> '));
        });

        text += conditionsText.map((val, key) => (key + 1) + '. ' + val).join('<br>');
      }
      return text;
    }
  }

}
</script>

<style scoped>
.conditions-popper {
  margin: auto -5px auto auto;
  cursor: default;
}
.conditions-popper:deep(.popper) {
  width: max-content;
}
</style>