<template>
  <div class="col-12" style="text-align: center;">
    <div @mouseover="showContextButton = true"  @mouseout="showContextButton = false" class="position-relative message message-to block-event col-sm-12 pb-3">
      <div class="col-12 datetime-stamp text-end">{{date}}</div>

      <div class="sender-block me-1">
        <span v-if="titleLeft" class="text-secondary me-1">{{titleLeft}}</span>
        <div v-if="titleRight" class="pull-right d-flex">
          <span class="text-secondary me-1">{{titleRight}}</span>
        </div>
      </div>

      <div v-show="showContextButton" class="contextFromButton">
        <context-button
            :messageid="eventId"
            :custom-button-style="contextStyle"
            :left-align="true" />
      </div>

      <div class="comment-quotes max-width pt-1">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import contextButton from "../Message/ContextMenu/ContextButton";

export default {
  name: "EventBlock",

  components: {contextButton},

  props: {
    date: {
      require: true
    },
    title: {}, // object {left: String, right: String} || String (right title)
    eventId: {},
  },

  data() {
    return {
      showContextButton: false,
      contextStyle: {
        'border-style':'none',
        background: 'transparent',
        outline:'none'
      }
    }
  },

  computed: {
    titleLeft() {
      return this.title?.left ?? null;
    },

    titleRight() {
      let title;
      if(typeof this.title === 'string') {
        title = this.title;
      } else {
        title = this.title?.right ?? null;
      }
      return title;
    }
  }
}
</script>

<style scoped>
.sender-block {
  min-height: 1.5em;
}
.contextFromButton {
  right: 3px;
}
</style>