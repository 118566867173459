import offerHistoryAddEditGetters from "./offerHistoryAddEditGetters";
import offerHistoryAddEditMutations from "./offerHistoryAddEditMutations";
import offerHistoryAddEditActions from "./offerHistoryAddEditActions";

const offerHistoryAddEditState = {
    historyObject:{
        date:'',
        kpi:'',
        subnets:[],
        asn:{
            name:''
        },
        sellprice:{
            price:'',
            currency:'USD',
            description:'',
            comment:'',
        },
        buyprice:{
            price:'',
            currency:'USD',
            description:'',
            comment:'',
        },
        sellmanager:{

        },
        buymanager:{

        },

        buyer:{
            name:'',
            phone:[
                {
                    type:'',
                    number:'',
                }
            ],
            email:'',
            contact_emails:[ ],
            org:'',
            lirid:'',
        },
        seller:{
            name:'',
            phone:[
                {
                    type:'',
                    number:'',
                }
            ],
            email:'',
            contact_emails:[ ],
            org:'',
            lirid:'',

        },
        comment:'',
    },
    subnetType:'PA',
    isAs:false,
    threadId: null,
    loading:false,
    isourSeller:false,
    isourBuyer:false,
    loadingNetStatus:{},
};



export const  offerHistoryAddEdit  = {
    namespaced: true,
    state:() => offerHistoryAddEditState,
    getters:offerHistoryAddEditGetters,
    actions:offerHistoryAddEditActions,
    mutations:offerHistoryAddEditMutations,
}

