<template>
<div>
  <div  v-for="(subnet, key) in subnets" >
    <subnet
        @enterCtrl="$emit('enterCtrl')"
        :ref="'subnet' + key"
        :loading="loading"
        @interface="setOptions"
        :index="key"
        :nettype="subnet.type"
        :subnet-props="subnet.subnet"
        :disabled="disabled"
        @addNew="addSubnet"
        style="padding-bottom: 9px"
    />
  </div>
</div>
</template>

<script>
import Subnet from "./Subnet";
export default {
  name: "SubnetsEdit",
  components: {Subnet},
  emits:['addObject', 'editObject' , 'interface' , 'enterCtrl'],
  data:function (){
    return {
      interface:[],
      oldValues:'',
    }
  },
  props:{
    loading:{
      type:Boolean
    },
    subnets:{
      default:[],
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  created() {
      for (let el of this.subnets){
        if(el?.subnet){
          this.oldValues  += el.subnet.trim();
          if(el?.type){
            this.oldValues  += el?.type;
          }
        }
      }
      if(this.subnets.length === 0){
        this.$emit('addObject' , {
          type:"",
          subnet:"",
        })
      }
  },
  mounted() {
    this.initInterface();
  },
  methods:{
    isHasChange(){
      let values = '';
      for (let el of this.subnets){
        if(el?.subnet){
          values  += el.subnet.trim();
          if(el?.type){
            values  += el?.type;
          }
        }
      }
      return values !== this.oldValues;
    },
    initInterface(){
      this.$emit('interface', {
        'validate':()=>this.validate(),
      })
    },
    setOptions:function (object){
      this.interface.push(object)
    },
    validate:function (){
      let isValidate = true;
        for (let el of this.interface){

          if(el?.validate){
             let v = el.validate();
             if(!v){
               isValidate = v;
             }
          }
        }
        return isValidate

    },
    addSubnet:function ({value ,type,  key}){
      if(!this.disabled) {
        this.$emit('editObject', {value, type, key})
        if (this.subnets[this.subnets.length - 1].subnet.length >= 5) {
          this.$emit('addObject', {
            type: "",
            subnet: "",
          })
        }
      }
    },



  }
}
</script>

<style scoped>

</style>