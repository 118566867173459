import offerHistoryStore from "../../../store/main/offerHistory/index";
import offerHistoryRouter from "../../../router/offer/offerHistoryRouter";

let timer = null;

export default function addToQueryWithTimer(value, name){
    clearTimeout(timer);
    let currentValue = value;
    timer = setTimeout(() =>{
        if(currentValue === offerHistoryStore.getters["offerHistory/filters/getFilterValueByQueryName"](name)){

            let n = offerHistoryStore.getters.getRoute.name;
            let q = Object.assign({},  offerHistoryStore.getters.getRoute.query)
            q.page = 1
            if(currentValue !== ''){
                q[name] = currentValue;
                if(name === 'search'){
                    q['currentCurrency'] = offerHistoryStore.getters['offerHistory/getCurrentCurrency'];
                }
            }else {
                delete q[name];
                if(name === 'search'){
                    delete q?.['currentCurrency'];
                }
            }

            offerHistoryRouter.push({
                name:n,
                query:q
            })
        }
    }, 600);
}
