<template>
  <div class="card mt-2 mb-2">
    <div class="card-header">
      <span class="align-middle">Тарифы BILLmanager</span>
      <button @click="showPricesModal" type="button" class="btn btn-sm btn-primary pull-right">Добавить +</button>
    </div>
    <div v-if="tariffs.length || isChanged" class="card-body">
      <div v-show="isChanged" class="attention">
        <span>Тарифы были изменены, но не сохранены. Для изменения нажмите на кнопку "Сохранить"</span>
      </div>

      <table v-if="tariffs.length" class="table table-hover table-bordered table-striped">
        <thead>
          <tr>
            <th width="6%">id</th>
            <th width="58%">Название</th>
            <th>Период по умолчанию</th>
            <th width="4%"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="tariff in tariffsSorted">
            <td><a :href="priceListLink(tariff.id)" target="_blank">{{tariff.id}}</a></td>
            <td>
              <span v-if="tariff.name"><span>{{tariff.name}}</span><span v-if="tariff.project" style="margin-left: 10px;" class="badge bg-secondary">{{tariff.project}}</span></span>
              <span v-else class="fst-italic">Тариф не найден: выключен или удален</span>
            </td>
            <td>
              <editable-select v-if="canProductControl"
                               :options="getPeriods(tariff)"
                               :selected="tariff.default_period"
                               @change="(period) => tariff.default_period = period" />
              <template v-else>{{tariff.default_period ?? '—'}}</template>
            </td>
            <td class="text-center">
              <i @click="remove(tariff.id)" role="button" class="fa fa-trash-o text-danger"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <teleport to="body">
    <modal-window v-if="addTariffModal.show" @close="close" modal-dialog-class="modal-md">
      <template v-slot:header>
        <h5 class="modal-title">Добавление тарифа BILLmanager</h5>
      </template>
      <template v-slot:body>
        <div class="mb-1">
          <input class="form-control" type="text" v-model="addTariffModal.search" @input="search" placeholder="Поиск" />
        </div>

        <div style="max-height: 500px; overflow-y: scroll">
        <table v-if="foundedTariffs.length" class="table table-hover table-bordered table-striped">
          <thead>
          <tr>
            <th width="8%">id</th>
            <th width="60%">Название</th>
            <th>Проект</th>
            <th width="6%"></th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="item in foundedTariffs">
              <td><a :href="priceListLink(item.id)" target="_blank">{{item.id}}</a></td>
              <td>{{item.name}}</td>
              <td>{{item.project}}</td>
              <td><i @click="add(item)" role="button" class="fa fa-plus text-success" aria-hidden="true"></i></td>
            </tr>
          </tbody>
        </table>
        </div>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="close">
          <span v-show="addTariffModal.searchLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Закрыть
        </button>
      </template>
    </modal-window>
  </teleport>
</template>

<script>
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import {Product} from "../../../library/api/Product";
import {CrmApi} from "../../../library/CrmApi";
import EditableSelect from "../../Editable/EditableSelect";
import {UserPermission} from "../../../users/UserPermission";
import {getNumeralsWord} from "../../../library/Numerals";

export default {
  name: "BillmanagerPrices",

  components: {EditableSelect, ModalWindow},
  emits: ['add', 'remove'],

  props: {
    tariffs: {
      type: Array,
      default: []
    }
  },

  data() {
     return {
       canProductControl: false,

       addTariffModal: {
         show: false,
         search: '',
         searchTimer: null,
         searchLoading: false,
         founded: []
       },

       tariffsInitialJson: null,
     }
  },

  mounted() {
    UserPermission.can('system.products_control')
                  .then(() => this.canProductControl = true);

    this.setInitialTariffs();
  },

  computed: {
    isChanged() {
      return JSON.stringify(this.tariffs) !== this.tariffsInitialJson;
    },

    foundedTariffs() {
      return this.addTariffModal.founded.filter(item => !this.tariffs.some(tariff => tariff.id === item.id));
    },

    tariffsSorted() {
      return _.orderBy(this.tariffs, ['project', 'name']);
    }
  },

  methods: {
    search() {
      clearInterval(this.addTariffModal.searchTimer);

      this.addTariffModal.searchTimer = setTimeout(() => {

        this.addTariffModal.searchLoading = true;
        Product.searchBillmanagerTariffs(this.addTariffModal.search, this.tariffs.map(tariff => tariff.id))
            .then(result => this.addTariffModal.founded = result.data)
            .catch(error => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)))
            .finally(() => this.addTariffModal.searchLoading = false);

      }, 500);
    },

    showPricesModal(){
      this.addTariffModal.show = true;
      this.search();
    },

    priceListLink(id) {
      return `https://my.i7.net/billmgr?elid=${id}&plid=&startform=pricelist.edit`;
    },

    getPeriods(tariff) {
      let options = {};

      Object.keys(tariff.periods ?? {})
            .forEach(months => {
              let numerals = ['месяц', 'месяца', 'месяцев'];
              let period = months;

              if(months % 12 === 0) {
                period = months / 12;
                numerals = ['год', 'года', 'лет'];
              }
              options[months] = getNumeralsWord(period, numerals);
            });

      return options;
    },

    add(item) {
      this.$emit('add', item)
    },

    remove(id) {
      this.$emit('remove', id);
    },

    setInitialTariffs() {
      this.tariffsInitialJson = JSON.stringify(this.tariffs);
    },

    close() {
      clearTimeout(this.addTariffModal.searchTimer);
      this.addTariffModal.search = '';
      this.addTariffModal.founded = [];
      this.addTariffModal.show = false
    }
  }
}
</script>

<style scoped>
.attention{
  background-color: #fef3cd;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
.table {
  margin-bottom: 0!important;
}
</style>