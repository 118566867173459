<template>
  <transition name="modal">
    <modal-window @close="closeModal" v-if="showModal" @success="beforeMergeTickets" modal-dialog-class="modal-lg">
      <template v-slot:header>
        <h5 class="modal-title">Перенос тикета</h5>
      </template>
      <template v-slot:body>
        <modal-body
            style="padding-top: 0px;"
            :selected-objects="tickets"
            :source="source"
            @changeState="changeState($event)"
            @changeStateConnect="changeStateConnect"
            @changeTicket="changeTicket"
            @showAttachField="(isShow) => this.showAttachField = isShow"
            ref="mBody"
        />
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="closeModal">Отмена</button>
        <button type="button" class="btn btn-primary" :disabled="submitDisabled" @click="beforeMergeTickets">
          <span v-if="mergeLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          {{source.handOver === 'connect' ? 'Подключить' : 'Перенести'}}
        </button>
      </template>
    </modal-window>
  </transition>

  <transition name="modal-confirm">
    <modal-window
        v-if="showConfirmMergeModal"
        @close="showConfirmMergeModal = false"
        @success="mergeTickets"
        ok-button-style="btn-danger"
        :buttons-text="{close: 'Отмена', success: 'Объединить'}"
        modal-dialog-class="modal-confirm"
    >
      <template v-slot:header>
        <h5 class="modal-title text-danger">Объединение запросов</h5>
      </template>
      <template v-slot:body>
        <div v-if="idList.length === 1 && ticket !== null">
          <div>Вы уверены, что хотите объединить запрос <span class="nobr">"<span><string-trim :string="ticket.name" /></span>"</span> с запросом <span class="nobr">"<span><string-trim :string="selectTicket.title" /></span>"?</span></div>
          <div>Данное действие безвозвратно удалит запрос <span class="nobr">"<span><string-trim :string="ticket.name" /></span>"</span> и заданные в нем параметры услуги.</div>
        </div>
        <div v-else>
          Вы уверены что, хотите объединить {{idList.length}} {{ requestWord }} с запросом <span class="nobr">"<span><string-trim :string="selectTicket.title" /></span>"?</span><br>
          Данное действие безвозвратно удалит {{idList.length}} выбранных {{ requestWord }} и заданные в них параметры услуг.
        </div>
      </template>
    </modal-window>
  </transition>
</template>

<script>
import ModalWindow from "../ModalWindow";
import ModalBody from "./ModalBody";
import StringTrim from "../../../string/StringTrim";
import { mapGetters} from "vuex";
import {getNumeral} from "../../../../library/Numerals";

export default {
  name: "TransferModal",

  components: {
    ModalWindow,
    ModalBody,
    StringTrim,
  },

  props: {
    ticketId: {
      default: null,
    },
    callback: {
      default: null,
    },
  },

  emits: [
      'close',
  ],

  data: function (){
    return {
      showModal: false,
      selectProjects: [],
      selectTicket: {id: null, title: null},
      showAttachField: false,
      showConfirmMergeModal: false,
      oldAssignedProjects: {},
      newAssignedData: {
        "add": [],
        "remove": [],
      },
      source: {
        handOver: "connect",
        connectType: "full",
      },
      mergeLoading: false,
    }
  },

  mounted() {
    this.showModal = true;
    this.$store.commit('setActiveWindow', 'transferTicket');
    this.setOldAssignedProjects();
    this.$nextTick(() => {
      this.changeState('reset');
    });
  },

  beforeUnmount() {
    this.showModal = false;
  },

  unmounted() {
    this.showModal = false;
    this.$store.commit('removeActiveWindow', 'transferTicket');
  },

  computed: {
    submitDisabled: function () {
      let disabled = (this.mergeLoading || !this.isChanged);

      if (!disabled) {
        if (this.source.handOver === 'transferTicket') {
          disabled = typeof this.selectTicket.id !== 'string' || this.selectTicket.id === '';
        }
      }

      return disabled;
    },

    ticket: function () {
      let ticket = null;
      if (this._ticket !== null) {
        ticket = this._ticket;
      } else if (this.idList.length === 1) {
        ticket = this.tickets[this.idList[0]];
      }
      return ticket;
    },

    isChanged() {
      let changed = false;

      switch (this.source.handOver) {
        case 'connect':
          Object.entries(this.oldAssignedProjects).forEach((el) => {
            let [id, projects] = el;
            if (this.selectProjects.length !== projects.length) {
              changed = true;
            } else {
              if (projects.sort().toString() !== this.selectProjects.sort().toString()) {
                changed = true;
              }
            }
          });
          break;
        case 'transferProject':
          changed = this.selectProjects.length > 0;
          break;
        case 'transferTicket':
          changed = typeof this.selectTicket.id === 'string' && this.selectTicket.id !== '';
          break;
      }

      return changed;
    },

    requestWord() {
      return getNumeral(this.idList.length, ['запрос', 'запроса', 'запросов']);
    },

    ...mapGetters({
      "idList": "tickets/getSelectedArray",
      "loading": "tickets/isTicketLoading",
      _ticket: 'thread/getTicket',
      tickets: 'tickets/getSelectedObjects'
    }),
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    setOldAssignedProjects() {
      Object.entries(this.tickets).forEach(el => {
        let [id, ticket] = el;
        this.oldAssignedProjects[id] = (typeof ticket.assigned_projects === 'object')
            ? ticket.assigned_projects.map(project => window.getId(project._id))
            : [];
      });
    },

    changeStateConnect(value, action) {
      switch (action) {
        case 'select':
          this.newAssignedData.add.push(value);
          break;
        case 'deselect':
          this.newAssignedData.remove.push(value);
          break;
        case 'clear':
          this.newAssignedData.remove = this.newAssignedData.remove.concat(value);
          break;
      }
      let newAdd = this.newAssignedData.add.filter(x => !this.newAssignedData.remove.includes(x));
      let newRemove = this.newAssignedData.remove.filter(x => !this.newAssignedData.add.includes(x));
      this.newAssignedData.add = newAdd;
      this.newAssignedData.remove = newRemove;
    },

    changeState:function (event){
      if(event === 'reset') {
        this.selectProjects = (this.source.handOver === 'connect' && this.idList.length === 1)
            ? this.oldAssignedProjects[this.idList[0]]
            : [];
        this.newAssignedData.add = [];
        this.newAssignedData.remove = [];
        this.$refs.mBody.values = this.selectProjects;
      } else {
        this.selectProjects = event;
      }
    },

    changeTicket: function(id, title) {
      this.selectTicket = {id: id, title: title};
    },

    beforeMergeTickets: function() {
      if(typeof this.selectTicket.id === 'string' && this.selectTicket.id !== '') {
        this.showConfirmMergeModal = true;
      } else {
        this.mergeTickets();
      }
    },

    mergeTickets:function (){
      this.showConfirmMergeModal = false;
      if (!this.submitDisabled) {
        this.mergeLoading = true;
        let ticketIds = this.ticketId !== null ? [this.ticketId] : this.idList;

        let object = {
          tickets: Array.isArray(ticketIds) ? ticketIds : [ticketIds],
          route: this.callback ?? this.$route,
          action: this.source.handOver,
        }

        switch (this.source.handOver) {
          case 'transferProject':
            object.project = this.selectProjects[0];
            break;
          case 'transferTicket':
            this.$store.getters['thread/getApi']?.abort();
            object.from = object.tickets;
            object.to = this.selectTicket.id;
            break;
          case 'connect':
            if (this.source.connectType === 'full') {
              object.assigned_projects = this.selectProjects;
            } else {
              object.assigned_projects_data = this.newAssignedData;
            }
            break;
        }

        this.$store.dispatch('tickets/mergeTicketsAndProjects', object).then(() => {
          if (typeof this.selectTicket.id === 'string' && this.selectTicket.id !== '') {
            if (ticketIds.length === 1) {
              window.location.href = '/tickets/' + encodeURIComponent(window.getId(this.selectTicket.id));
            }
          } else if (this.source.handOver === 'transferProject' && this.$route.name === 'Ticket') {
            let name = this.$store.state.tickets.projects.projects.find(el => el._id === this.selectProjects[0])?.name;
            this.$store.commit('successPush', 'Тикет передан в проект ' + name);
          }
          this.closeModal();
        }).finally(() => {
          this.mergeLoading = false;
        });
      }
    },
  }
}
</script>

<style scoped>
.nobr > span {
  display: inline-block;
  line-height: 1.2;
  height: 19px;
  vertical-align: text-bottom;
  max-width: calc(100% - 20px);
}
.nobr > span:deep(.popper) {
  --popper-max-wight-custom: 90vw;
}
:deep(.modal-confirm .modal-body) {
  margin: 0;
}
</style>