export const sort = function(relations) {
    return relations.sort((a,b) => {
        if( a.id > b.id ){
            return -1;
        }
        if( a.id < b.id ){
            return 1;
        }
        return 0;
    });
}

export function sortFunctions(field, sort, type = "default") {
    const data = {
        "default": (a,b) => {
            let aField = String(a[field] ?? '');
            let bField = String(b[field] ?? '');
            return sort * aField.localeCompare(bField, 'ru');
        },
        "id": (a, b) => {
            let aCount = Number(a[field] ?? 0);
            let bCount = Number(b[field] ?? 0);
            return sort * (aCount - bCount);
        },
        "number": (a, b) => {
            let aCount = Number(a[field] ?? 0);
            let bCount = Number(b[field] ?? 0);
            return sort * (aCount - bCount);
        },
        "priceField": (a, b) => {
            let aPrice = Number(a[field]?.priceusd ?? 0);
            let bPrice = Number(b[field]?.priceusd ?? 0);
            return sort * (aPrice - bPrice);
        },
        "price1Field": (a, b) => {
            let aPrice = Number(a[field]?.priceusd ?? 0) / (a?.count ? a?.count : 1);
            let bPrice = Number(b[field]?.priceusd ?? 0) / (b?.count ? b?.count : 1);
            return sort * (aPrice - bPrice);
        },
        "clientName": (a, b) => {
            let aField = String(a[field]?.name ?? '');
            let bField = String(b[field]?.name ?? '');
            return sort * aField.localeCompare(bField, 'ru');
        },
    }

    return data[field] ?? data[type] ?? data["default"];
}