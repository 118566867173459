<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
        <tr class="no-shadow">
          <th
              data-bs-toggle="collapse"
              :aria-expanded="!collapsed"
              class="expandable"
              :data-bs-target="'#' + className"
              :colspan="3"
              scope="col">{{title}}</th>
        </tr>
      </thead>
    </table>

    <div class="relation-table collapse" :id="className" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
        <thead class="sticky">
          <tr>
            <th
                class="sortable"
                :class="sortedClass('ENTITY_ID')"
                @click="sortBy('ENTITY_ID')">ID</th>
            <th
                class="sortable"
                :class="sortedClass('title')"
                @click="sortBy('title')">Название</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td><a :href="relation.link" target="_blank">{{relation.ENTITY_ID}}</a></td>
            <td class="text-start">{{relation.title}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {getUniqueId} from "../../../../../../library/ID";
import {sortFunctions} from "../Relation";

export default {
  name: "Entity",

  props: {
    relations: {},
    title: {
      type: String,
    },
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      id: getUniqueId(),
      sort: null,
      sortDirection: -1,
    }
  },

  computed: {
    className() {
      return 'bitrix-ticket-table-collapse-' + this.id;
    },
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {

      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
</style>