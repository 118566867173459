<template>
  <div class="d-flex" :class="{'text-muted': loading}">
    <div class="pe-1">ИНН:</div>
    <div class="flex-grow-1" style=" display: flex;" @mouseleave="showCopyMenu =false" @mouseover="showCopyMenu = true">
      <span v-if="userCanChange">
        <editable-text
            :text="vatnum"
            :callback="changeVatnum"
            :trim="true"
            :one-click="clickLink"
            default="Не заполнено"
            input-class="name-input w-100 form-control form-control-sm"
        />
      </span>
      <a v-else :href="hrefLink" target="_blank"><string-trim :string="vatnum" /></a>

      <span>
        <copy-badge :show="showCopyMenu" :content="vatnum"></copy-badge>
      </span>
    </div>

    <change-company :params="changeCompany"
                    :company="company"
                    :ticket-id="ticketId"
                    type="vatnum"
                    @close="closeChangeCompany"
                    @cancel="cancelChangeCompany" />
  </div>
</template>

<script>
import EditableText from "../../../Editable/EditableText";
import CopyBadge from "../../../common/CopyBadge";
import {CrmApi} from "../../../../library/CrmApi";
import modalWindow from "../ModalWindow";
import StringTrim from "../../../string/StringTrim";
import ChangeCompany from "./changeCompany";
import {isRUVatnum} from "../../../../library/Client";
import {mapGetters} from "vuex";

export default {
  name: "Vatnum",
  components: {
    ChangeCompany,
    StringTrim,
    modalWindow,
    CopyBadge,
    'editable-text': EditableText,
  },
  props:{
    company:{
      type:Object,
      require:true,
    },
    ticketId: {
      require: true
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["updatePerson"],
  data:function (){
    return {
      loading:false,
      showCopyMenu:false,
      changeCompany: null,
      loadingChange: false,
    }
  },
  computed:{
    vatnum:function (){
      return this.company?.vatnum;
    },
    hrefLink:function () {
      let link;
      if((typeof this.vatnum !== 'undefined' && isRUVatnum(this.vatnum)) ||
         !this.company?.name) {

        link = 'https://focus.kontur.ru/search?&query=' + encodeURIComponent(this.vatnum);
      } else {
        link = 'https://opencorporates.com/companies?q=' + encodeURIComponent(this.company.name);
      }
      return link;
    },
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },
  methods: {
    closeChangeCompany() {
      this.changeCompany = null;
      this.$store.commit('removeActiveWindow', 'changeCompanyModal');
    },

    cancelChangeCompany() {
      this.company.vatnum = this.changeCompany.prev;
      this.closeChangeCompany();
    },

    clickLink() {
      window.open(this.hrefLink, '_blank');
    },

    changeVatnum: function(vatnum) {
      this.loading = true;

      if(vatnum === '') {
        vatnum = null;
      }

      let prevValue = this.company.vatnum;
      this.company.vatnum = vatnum;

      this.api.editPerson({"change": {"company.vatnum": vatnum}}).then((result)=>{
        if (result.data?.success) {
          if (result.data?.person) {
            this.$emit('updatePerson', result.data.person);
            if (result.data.person?.company?.name) {
              this.company.name = result.data.person.company.name;
            }
          }
        } else {
          this.changeCompany = {
            company: result.data.company,
            prev: prevValue
          };
          this.$store.commit('setActiveWindow', 'changeCompanyModal');
        }
      }).catch((error) => {
        if(error.response.status === 404) {
          this.$store.commit('infoPush', `Не найдена информация о компании по ИНН ${vatnum} или компания ликвидирована`);
        } else {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }
        this.company.vatnum = prevValue;
      }).finally(() => {
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>

</style>