import {CrmApi} from "../../../../library/CrmApi";
import {User} from "../../../../library/api/User";
import {commit} from "lodash/seq";


function getSettingsValue(settings , field){
    let value = null
    for (let el of settings){
        if(el.field == field){
            value = el.value;
        }
    }
    return value;
}
const actions = {

    initFiltersFromUrl({state , commit, dispatch, getters , rootGetters}){
        let query = Object.assign({}, rootGetters.getRoute.query);
        if(typeof query.searchParams !== 'undefined'){
            let searchParams = JSON.parse(query.searchParams);
            for (let el of getters.getSettingsParams){
                if(typeof searchParams[el.field] !== 'undefined' ){
                    state.isSettingShow = true;
                    el.value = searchParams[el.field]
                }
            }
        }

        if(typeof query.searchAlias !== 'undefined'){
            state.isSettingShow = true;
            state.alias = query.searchAlias;
            state.aliasSet = true;
        }

        if(typeof query.searchProduct !== 'undefined') {
            state.isSettingShow = true;
            state.product = query.searchProduct;
        }

        if(typeof query.searchUser !== 'undefined') {
            state.isSettingShow = true;
            state.user = query.searchUser;
        }

        if(typeof query.ticketStatus !== 'undefined') {
            state.isSettingShow = true;
            state.ticketStatus = query.ticketStatus;
        }

        if(typeof query.projectName !== 'undefined') {
            state.isSettingShow = true;
            state.searchProject = query.projectName;
        } else {
            state.searchProject = 'all';
        }

        if(typeof query.openFilters !== 'undefined'){
            state.search.showFilter = query.openFilters;
        }

        if(typeof query.search !== 'undefined' &&  query.search != null  &&
            query.search.trim() != ""){
            state.isSettingShow = true;
            commit( 'setSearchString' ,query.search) ;
        }

        let filters = (router.currentRoute.value.name === 'Billmanager') ? state.billmanagerTableFilters : state.tableFilters;
        if(router.currentRoute.value.name === 'Calls'){
            filters = state.callsTableFilters;
        }
        if(router.currentRoute.value.name === 'Tasks'){
            state.type = 'current';
            if(query?.type){
                state.type = query.type;
                if (state.type !== 'current') {
                    state.isSettingShow = true;
                }
            }
            filters = state.tasksTableFilters;
        }
        for(let el of filters){
            if(typeof query[el.field] !== 'undefined'){
                el.value = query[el.field];
                state.search.showFilter = true;
            }
        }

    },

    loadProducts: function({state, commit, dispatch, getters, rootGetters}) {
        if(state.search.products === null) {
            CrmApi.productLists({onpage: -1}).then((result) => {
                let products = result.data.data;
                let group = {all: products};
                let projects = [... new Set(products.map(product => product.projects.map(project => project.name)).flat()) ];
                projects.forEach(project => {
                    group[project] = products.filter(product => product.projects.map(project => project.name)
                        .flat()
                        .indexOf(project) !== -1);
                });

                commit('setProducts', group);
            });
        }
    },

    loadUsers: function ({state, commit, dispatch, getters, rootGetters}) {
        if (state.search.users.length === 0 && state.usersRequested === false) {
            User.getList().then((result) => {
                commit('setUsers', result.data);
            }).finally(() => {
                commit('setUsersRequested', true);
            });
        }
    },

    closeSearchMenu:function ({state , commit, dispatch, getters , rootGetters}){
        state.isSettingShow = false;
       /*
        if(state.search.searchString.trim() === '' && getters.isSearchSettingsInDefault){
            state.isSettingShow = false;
        }

        */
    },

    changeAlias: function ({state , commit, dispatch, getters , rootGetters}) {
        let query = getters.getFullQuery;
        query.searchAlias = state.alias;
        commit('setAliasSet', true);
        query.page = 1;
        window.router.push({ name: rootGetters.getRoute.name, query: query});
    },

    changeProduct: function({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' &&  isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.searchProduct = (state.product !== null) ? state.product : undefined;
        query.page = 1;

        window.router.push({ name: rootGetters.getRoute.name, query: query});
    },

    changeUser: function({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' &&  isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.searchUser = state.user ? state.user : undefined;
        query.page = 1;

        window.router.push({ name: rootGetters.getRoute.name, query: query});
    },

    changeCallType:function ({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' && isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.call_type = state?.callType  ? state.callType : undefined;
        query.page = 1;

        window.router.push({name: rootGetters.getRoute.name, query: query});
    },

    changeCallStatus:function ({state, commit, dispatch, getters, rootGetters}){
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' && isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.callStatus = state?.callStatus  ? state.callStatus : undefined;
        query.page = 1;

        window.router.push({name: rootGetters.getRoute.name, query: query});
    },

    changeStatus: function({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' && isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.ticketStatus = state.ticketStatus ? state.ticketStatus : undefined;
        query.page = 1;

        window.router.push({name: rootGetters.getRoute.name, query: query});
    },

    changeProject: function({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' && isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.projectName = state.searchProject && state.searchProject !== 'all' ? state.searchProject : undefined;
        query.page = 1;

        window.router.push({name: rootGetters.getRoute.name, query: query});
    },

    changeTasksType:function({state, commit, dispatch, getters, rootGetters}) {
        let query = getters.getFullQuery;
        if (typeof query.searchAlias === 'undefined' &&  isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
            commit('setAliasSet', true);
        }
        query.type = state.type ? state.type : undefined;
        query.page = 1;

        window.router.push({ name: rootGetters.getRoute.name, query: query});
    },

    returnFilterValuesToDefault ({ state, getters, commit }) {
        state.alias = 'all';
        state.product = null;
        state.user = null;
        state.searchProject = 'all';
        state.isSettingShow = false;
        commit('resetAliasSet');
        for(let el of getters.getSettingsParams){
            if(typeof el.type == 'undefined' && typeof getters.getSearchDefaultValues[el.field] !== 'undefined'){
                el.value = getters.getSearchDefaultValues[el.field]
            }
            if(typeof el.type != 'undefined' && el.type === 'select'){
                el.value = el.default.key;
            }
        }
    },

    returnAdditionalFilterValuesToDefault({getters}) {
        for (let el of getters.getAdditionalSearchSettingsToShow) {
            if(typeof el.type == 'undefined' && typeof getters.getSearchDefaultValues[el.field] !== 'undefined'){
                el.value = getters.getSearchDefaultValues[el.field]
            }
        }
    },

    openTableFilters({state , commit , rootGetters}){
        let query = Object.assign({}, rootGetters.getRoute.query);
        query.openFilters = true;
        commit('tickets/escapeRefresh' , true , {root:true})
        window.router.push({ name: rootGetters.getRoute.name, query: query})
    },

    closeTableFilters({state ,getters, commit , rootGetters}){
        let query = getters.getFullQuery;
        let isAllEmpty = true;
        for (let el of getters.getTableFilters){
            if(el.type && el.type === 'select'){
                if(el.value !== el.default.key){
                    isAllEmpty = false
                }
                el.value = el.default.key
            }else {
                if(el.value.trim() !== ''){
                    isAllEmpty =false
                }
                el.value = '';
                if(typeof query[el.field] !== 'undefined'){
                    delete query[el.field];
                }
            }

        }
        query.page = 1;

        delete query.openFilters;


        if(isAllEmpty){
            commit('tickets/escapeRefresh' , true , {root:true})
        }

        window.router.push({ name: rootGetters.getRoute.name, query: query})

    },

    clickOnCheckBox({state , commit, dispatch, getters , rootGetters} , object){
        let query = getters.getFullQuery;
        let searchParams = rootGetters.getRoute.name === 'Billmanager' ?
                                getters.getBillmanagerSearchParamsString :
                                getters.getSearchParamsString;

        if (typeof query.searchAlias === 'undefined' &&  isNeedSearchAlias(rootGetters.getRoute)) {
            query.searchAlias = state.alias;
        }

        if(typeof query.searchParams !== 'undefined'){
            delete  query.searchParams
        }
        if(searchParams !== '{}'){
            query.searchParams = searchParams;
        }
        let needReload = true;
        if(state.search.searchString.trim() === ''){
            needReload = false;
            if(object?.reloadWhenSearchStringEmpty){
                needReload = true;
            }
        }
        if(needReload){
            query.page = 1;
        }else {
            commit('tickets/escapeRefresh' , true , {root:true})
        }

        window.router.push({ name: rootGetters.getRoute.name, query: query})
    },

    searchStringSearch({state, commit, dispatch, getters, rootGetters}, newString) {
        if (!rootGetters["tickets/isTicketLoading"]) {
            let query = getters.getFullQuery;
            query.search = newString;
            if (typeof newString == "undefined" || newString == null || newString.trim() === "") {
                delete query.search;
                delete query.searchParams;
            }

            query.page = 1;
            dispatch('tickets/reset', false, {root: true});
            window.router.push({name: rootGetters.getRoute.name, query: query});
        }
    },
}

export function isNeedSearchAlias(route){
 return !['Trash', 'Unsorted', 'Billmanager', 'Calls', 'Competitor', 'Recent'].includes(route.name);
}

export default actions;