<template>

  <div class="row mb-2">
    <div class="d-flex align-items-center">
      <input type="checkbox" :id="inputId" class="form-check-input" v-model="show" @change="update" />
      <label class="form-check-label m-2" :for="inputId">{{param.label ?? param.key}}</label>
    </div>
  </div>

</template>

<script>
export default {
  name: "TextBlockParam",

  emits: ['update', 'hideChild'],

  props: {
    param: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      show: false,
      inputId: `input-${this.param.key}`,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.$emit('hideChild', this.param.key);
    });
  },

  methods: {
    update() {
      this.$emit('update', this.show);
    }
  }
}
</script>

<style scoped>

</style>