const plugin = {
    install (Vue) {
        Vue.directive('price-clipboard', {
            mounted(el, binding) {
                if(typeof binding.value === 'undefined' || binding.value === true || binding.value === 'true') {
                    el.style.cursor = 'pointer';

                    let timeout = null;

                    let custom_class = 'clipboard-popover clipboard-copy-popover';
                    if(typeof $(el).attr("custom-popover-class") !== 'undefined' && $(el).attr("custom-popover-class") !== null){
                        custom_class = custom_class + ' ' + $(el).attr("custom-popover-class")
                    }

                    $(el).attr({
                        'data-toggle': 'popover',
                        'data-bs-content': 'Скопировано',
                        'data-bs-trigger': 'focus',
                        'data-bs-placement': 'top',
                        'data-bs-custom-class': custom_class,
                        'animation':true,
                    });


                    /*
                    $(el).on('mouseenter', function() {

                        let text;
                        if(binding.modifiers?.byContent &&
                            typeof $(el).attr("content") !== 'undefined' && $(el).attr("content") !== null
                        ){
                            text = $(el).attr("content");
                        }else {
                            text = $(el).text();
                        }

                        if(['','—'].indexOf(text) === -1){

                        }

                    })
                    */
                    let timeoutTime = 500;
                    if(typeof $(el).attr("timeout-time") !== 'undefined' && $(el).attr("timeout-time") !== null){
                        timeoutTime = $(el).attr("timeout-time") * 1;
                    }

                    $(el).on('click', () => {
                        if (timeout === null) {
                            timeout = setTimeout(() => {
                                let text;
                                if(binding.modifiers?.byContent &&
                                    typeof $(el).attr("content") !== 'undefined' && $(el).attr("content") !== null
                                ){
                                    text = $(el).attr("content");
                                }else {
                                    text = $(el).text();
                                }
                                if(['','—'].indexOf(text) === -1){
                                    text = text.replace("." , ',');
                                    let temp = $("<input style='opacity: 0'>");
                                    $("body").append(temp);
                                    temp.val(text).select();
                                    document.execCommand("copy");
                                    temp.remove();




                                    $(el).popover('show');
                                    setTimeout(()=>{
                                        $(el).popover('hide');
                                    }, 500)

                                }

                                timeout = null;
                            }, timeoutTime);
                        } else {
                            $(el).popover('hide');
                            clearTimeout(timeout);
                        }
                    });
                }
            },
        });
    }
}

export default plugin