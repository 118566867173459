<template>

  <div class="row mb-2">
    <label class="form-label" :for="inputId">
      {{label}}
      <span v-if="isRequired" class="required-field">*</span>
    </label>
    <div class="d-flex align-items-center">
      <input type="text" :id="inputId" class="form-control" :class="{'is-invalid': !valid}" v-model="text" @change="update" ref="input" />
    </div>
  </div>

</template>

<script>
export default {
  name: "Text",

  emits: ['update'],

  props: {
    param: Object,
  },

  data() {
    return {
      text: '',
      valid: true,
      inputId: 'input-' + String(Math.random()).substr(2),
    }
  },

  computed: {
    label() {
      return this.param.label ?? this.param.key;
    },

    isRequired() {
      return this.param?.required === true || typeof this.param?.required !== 'boolean';
    },
  },

  methods: {
    update() {
      this.isValid();
      this.$emit('update', this.text);
    },

    isValid() {
      this.valid = (this.param?.required === false || this.text.length > 0);
      return this.valid;
    },

    focus() {
      this.$refs.input.focus();
    }
  }
}
</script>

<style scoped>

</style>