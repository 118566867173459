<template>
  <div class="pt-5 col-md-6 m-auto">
    <div>
      <Loading loader="dots" :is-full-page="false" v-model:active="loading" />
    </div>
    <form  method="post" @submit.prevent="onSubmit()">
      <div class="form-group row">
        <label for="stepname" class="col-sm-2 col-form-label">Имя шага</label>
        <div class="col-sm-10">
          <input type="text" class="form-control"  v-model="step.name" id="stepname" placeholder="Введите имя шага">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Шаблоны</label>
        <div class="col-sm-10">
          <multiselect  placeholder="Добавить шаблоны к шагу"
                        v-model="templateIds"
                        :mode="'tags'"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="true"
                        :delay="800"
                        :options="updateTemplateList"
                        ref="multy"
          >
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div  class="multiselect-tag is-user" >
                <a class="link-style" target="_blank" :href="getTemplateEditLink(option)">{{ option.label }} </a>
                <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="mb-3" >
        <label for="hint" class="col-form-label">Подсказка</label>
        <mde-text-area id="hint" v-model="step.hint" ref="hint"  ></mde-text-area>
      </div>
      <div  style=" margin-top: 20px;">
        <button @click="cancel()" type="button" class="col-6 btn btn-secondary">Отменить</button>
        <button type="submit" :disabled="isDisabled || loading" class="col-6  btn btn-primary">Изменить</button>
      </div>
    </form>
    <alert-list></alert-list>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";
import Loading from "vue3-loading-overlay";
import AlertList from "../../ticket/Alert/AlertList";
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from "../../ticket/Api/api";
import MdeTextArea from "../../template/source/MdeTextArea";
import {getTemplateEditLink} from '../../../library/Locations';
export default {
  name: "StepEdit",
  components: {
    Loading,
    AlertList,
    Multiselect,
    MdeTextArea
  },
  data:function (){
    return {
      step: {
        name: "",
        project:{},
        templates:[],
        steps:[],
        hint:"",
      },
      loading:false,
      templateIds:[],
      loadedTemplatesMap: {},
      allTemplatesArray: []
    }
  },
  props:{
    stepEdit:{
      type: Object,
      default: null
    }
  },
  methods:{
    getTemplateEditLink(template){
      return getTemplateEditLink(template.value);
    },
    onSubmit(){
      this.loading = true;
      this.step.templates = [];
      this.templateIds.forEach((templateId) => {
        this.step.templates.push( this.loadedTemplatesMap[templateId] );
      });


      CrmApi.editStep(this.step).then(()=>{
        window.location.reload();
      }).catch((error)=>{
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(()=>{
        this.loading = false;
      });
    },
    cancel(){
      window.location.replace( window.location.origin + "/products/")
    },

    updateTemplateList(query){
      let filter = {};
      if( query !== null ){
        filter.name = query;
      }
      return CrmApi.getTemplatesList(filter).then((result)=>{
        this.allTemplatesArray = [];
        let unique = {};
        if(this.$refs.multy.search == '' || this.$refs.multy.search == null){
          this.initialTemplates.forEach((template) => {
            this.allTemplatesArray.push({
              value:window.getId(template._id),
              label:template.name,
            });
            unique[window.getId(template._id)] = true;
            this.addLoadedTemplate(template);
          });
        }

        result.data.data.forEach((template)=>{
          if(typeof unique[template._id] === 'undefined') {
            this.allTemplatesArray.push({
              value: window.getId(template._id),
              label: template.name,
            });
            this.addLoadedTemplate(template);
          }
        });

        return  this.allTemplatesArray;
      }).catch(()=> {
        return  this.allTemplatesArray;
      })
    },
    addLoadedTemplate( template ){
      let tmpTemplate = Object.assign({},template);
      tmpTemplate._id = window.getId(tmpTemplate._id);
      this.loadedTemplatesMap[tmpTemplate._id] = tmpTemplate;
    }
  },
  mounted() {
    changeMultySelectStyles(document);

  },
  created() {
    if(this.stepEdit !== null){
      this.step = Object.assign(this.step , this.stepEdit);
      if( typeof this.step.templates === 'undefined' ){
        this.step.templates = [];
      }

    }
    this.initialTemplates = this.step.templates;

    this.step.templates.forEach((template) => {
      this.templateIds.push(window.getId(template._id));
      this.addLoadedTemplate(template);
    })
  },
  computed:{
    isDisabled(){
      let isDisabled = false;
      if(this.step.name.trim() === ''){
        isDisabled = true;
      }

      return isDisabled;
    },
  }
}
</script>

<style scoped>
@import '../../../library/multyselectionLinkStyle.scss';
</style>