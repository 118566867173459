import {computed, ref} from "vue";

export function getName(manager){
    let name = '—';
    if(manager?.name){
        name = manager.name
    }else {
        if(manager?.login){
            name = manager.login;
        }
    }
    return name;
}

export function getUserNameOrId(manager, defaultValue = '—') {
    let name = window.getId(manager?._id ?? defaultValue);
    if (manager?.name) {
        name = manager.name
    } else if (manager?.login) {
        name = manager.login;
    }
    return name;
}

export function getNameWithLogin(manager, defaultv = '—') {
    let name = defaultv;
    if(manager?.name){
        name = manager.name + (manager?.login ? ' (' + manager?.login + ')' : '');
    } else {
        if(manager?.login){
            name = manager.login;
        }
    }
    return name;
}

export default function getUserName({managerRef}) {


    const name =  computed(() => {
        return getName(managerRef.value);
    });
    const login = computed(() => {
        return managerRef.value?.login;
    });
    const id = computed(() => {
        if(typeof managerRef.value?._id === 'undefined'){
            return null;
        }
        return window.getId(managerRef.value._id);
    });

    return {name, login, id};
}