<template>
  <tr @dblclick="editTemplate">
    <td>
      <popper :content="template.body" class="light pre-like" placement="top" :hover="true" :interactive="false" :arrow="true">
        <span>{{template.name}}</span>
      </popper>
    </td>
    <td>{{template.project.name}}</td>
    <td>
      <popper v-if="changed.date" :content="changed.date" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <span>{{changed.author}}</span>
      </popper>
      <span v-else>{{changed.author}}</span>
    </td>
    <td>
      {{template.group}}
    </td>
    <td class="text-end">
      <div class="dropdown">
        <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Функции
        </button>
        <div class="dropdown-menu " aria-labelledby="dropdownMenu2">
          <a @click="editTemplate" class="dropdown-item">Редактировать</a>
          <a @click="deleteTemplate" class="link-danger dropdown-item" :class="canDelete">Удалить</a>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";

export default {
  name: "TemplateRow",

  props: {
    'template': {
      type: Object,
      require: true
    },
    'editurl': {
      require: true,
    }
  },
  emits:['deleteTemplate'],
  computed: {
    canDelete: function() {
      return this.template.canDelete ? '' : 'disabled';
    },

    changed: function() {
      let author = '';
      let date = null;

      if(typeof this.template.last_change !== 'undefined') {
        author = this.template.last_change.author
        date = this.template.last_change.date;
      } else {
        author = this.template.author;
        date = this.template.created ?? null;
      }

      date = (date !== null) ? moment(date).format("YYYY-MM-DD HH:mm:ss") : null;

      return {author: author.name ?? author.login, date: date};
    }
  },

  methods: {
    deleteTemplate:function () {
      this.$emit("deleteTemplate" , this.template);
    },
    editTemplate: function() {
      window.location.href = this.editurl.replace('_ID_', this.template._id);
    }
  },
}
</script>

<style scoped>
tr {
  cursor: pointer;
}
.line{
  background-color: #7193f0;
  color: white;

  border-radius: 2px;
  padding:1px 3px;
}
.light {
  display: block;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.pre-like{
  white-space: pre;
}
</style>
