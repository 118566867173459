<template>
  <div class="input-group pb-1">
    <span class="input-group-text"><i class="fa fa-search"></i></span>
    <input
        type="text"
        class="form-control"
        placeholder="Поиск"
        aria-label="Поиск"
        ref="input"
        v-model="search"
        @keyup.esc.stop="cancel"
        @focusout="checkFocusout"
    >
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "SearchField",

  mounted() {
    this.$refs.input.focus();
  },

  computed: {
    search: {
      set(value) {
        this.setSearchString(value);
      },
      get() {
        return this.searchString;
      }
    },

    ...mapGetters({
      "searchString": "thread/searchString",
    })
  },

  methods: {
    cancel() {
      this.setShowSearchField(false);
    },

    checkFocusout(e) {
      let can = true;
      if (typeof e.explicitOriginalTarget !== 'undefined') {
        if (e.explicitOriginalTarget === this.$refs.input) {
          can = false;
        }
      }
      if (can && (typeof this.search !== 'string' || this.search.trim() === '')) {
        this.cancel();
      }
    },

    ...mapMutations({
      "setShowSearchField": "thread/setShowSearchField",
      "setSearchString": "thread/setSearchString",
    }),
  },
}
</script>

<style scoped>

</style>