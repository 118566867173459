import mutations from "./mutations";
import * as actions from "./actions";
import * as getters from './getters'
import offers from "./offers/offers";
import tasks from "./tasks/tasksComposition";


const state = {
    showCommentForm: false,
    showEditForm: false,
    showHeadersForm: false,
    showAddTaskForm: false,

    headersFormValid: true,

    editMessage: null,
    ticketType: null,
    loading: false,
    reloadLastMessage: false,
    reloadMessage: false,
    selectedTemplate:null,
    isSelectMessageExistInTicket:false,
    selectMessageStatus: true,

    userCanAnswer: false,
    userCanChange: false,
    userCanCall: false,
    userCanSetLot: false,
    userAttachmentPermission: false,
    userDocumentsPermission: false,
    userCallRecordPermission:false,
    userTaskCreatePermission: false,
    userTaskDeletePermission: false,
    userUpdatePersonPermission: false,
    userRelationPermission: false,
    userOpenClosePermission: false,

    isDirectLink:false,
    ticket: null,
    lastSeen: null,
    person: null,
    attachmentsFilter: false,
    tasksFilter: false,

    params: {
        buyer: {},
        price: {}
    },

    commentMessage:null,
    messages:null,

    ticketWindowFullSize: false,
    ticketChangeWindowSizeCallback: null,

    scrollToMessage: null,

    scrollAndHighlight: null,
    highlight: null,

    documents: null,
    document: {params: []},
    aliases:{
        project:[],
        ticket:[],
    },
    createNewTicket:false,

    loadingSignature: false,
    showSignature: false,
    signatures: {
        list: new Map(),
    },
    api: null,

    greeting: {
        original: null,
        name: null,
        projects: {},
        current: {},
        currentTemplate: '',
        currentValue: '',
        counter: 0,
    },

    hasNewChangesForDraft: false,
    settingDraft: false,
    smartTemplates: [],
    groupOrder: ['step' , 'product'],
    loadingTemplates: false,
    isAnsweringTicket: false,
    newTicketLang: null,
    newTicketLangChanged: false,
    pinnedMessages: [],

    showSearchField: false,
    searchString: '',
}

export const moduleThread = {
    namespaced: true,
    state:() => (state),
    mutations: mutations,
    actions:  actions ,
    getters: getters,

    modules: {
        offers,
        tasks,
    }
}