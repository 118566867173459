<template>
  <buy-row class="offer-table-text" v-for="[key, offer] in getList" :offer="offer" :id="key"

  ></buy-row>
</template>

<script>
import {mapGetters} from "vuex";
import BuyRow from "../Buy/BuyRow";


export default {
  name: "BuyBody",
  components: {BuyRow},
  setup(props){
    return {}
  },
  computed:{
    ...mapGetters({
      'getList':'offer/getOffersList',
    })
  },
}
</script>

<style scoped>

</style>