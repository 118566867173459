<template>
<div>
  <entity-index new-link="/products/new">
    <div>
      <entity-table
          settings-name="products"
          :do-reload-page="reloadPage"
          @showDeleteModalWindow="showDeleteModalWindow($event)"
          :show-edit="showEdit"
          :columns="columns"
          :actions="actions"
          :get-entity-list="getProductList"
      >
        <template v-slot:[`cell.projects`]="{ item }">
          <div v-if="Array.isArray(item.projects) && item.projects.length">
            <span v-for="project in item.projects" class="badge rounded-pill project-badge-bg mb-1 me-1">{{project.name}}</span>
          </div>
        </template>
      </entity-table>
    </div>
  </entity-index>
  <modal-window v-if="showDeleteModal" @close="showDeleteModal = false"  @success="deleteProduct" ok-button-style="btn-danger" >
    <template v-slot:header>
      <h5 class="modal-title text-danger" >Удаление услуги</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите навсегда удалить услугу {{productToDelete.name}}?</span>
    </template>
  </modal-window>
  <alert-list></alert-list>
</div>

</template>

<script>
import EntityIndex from "../common/EntityList";
import EntityTable from "../common/EntityTable";
import {CrmApi} from "../../library/CrmApi";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import AlertList from "../ticket/Alert/AlertList";
import {mapGetters} from "vuex";


export default {
  name: "ProductList",
  components:{
    AlertList,

    EntityIndex,
    EntityTable,
    ModalWindow
  },

  data:function (){
    return {
      reloadPage:{
        value:false,
      },
      productToDelete:{},
      showDeleteModal:false,
      columns:[
        {
          name: "Название",
          value: "name",
          width: 60,
        },
        {
          name: "Проекты",
          value: "projects",
          width:30
        }
      ],
      actions:[
        {
          name: "Редактировать",
          handler: function (product){
            this.showEdit(product);
          }
        },
        {
          name: "Удалить",
          handler: function (product){
            this.$emit('showDeleteModalWindow' , product);
          },
          class: "link-danger",
        },
      ],
    }
  },

  computed:{
    ...mapGetters({
      'getUserSettings':'getUserSettings'
    })
  },
  methods:{
    deleteProduct:function (){
      this.reloadPage.value = false;
      CrmApi.deleteProducts([ this.productToDelete._id]).then((result) => {
            if(result.data?.status !== 'error'){
              this.reloadPage.value = true;
            }else {
              if(result.data?.message !== undefined){
                this.$store.commit('errorPush', result.data.message);
              }else {
                this.$store.commit('errorPush', "Ошибка удаления услуги");
              }

            }

          })
          .catch((error) => {
            this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          })
          .finally(()=>{
            this.showDeleteModal = false;
          });
    },
    showDeleteModalWindow:function (event) {
      this.productToDelete = event;
      this.showDeleteModal = true;
    },
    showEdit:function( product ){
      window.location.href = window.location.origin + "/products/" + encodeURIComponent(product._id);
    },
    getProductList: function (query){

      if(this.getUserSettings?.products?.onpage && !query?.onpage){
        query.onpage = this.getUserSettings.products.onpage
      }
      return CrmApi.getProductsList(query);
    }
  }
}
</script>

<style scoped>
.project-badge-bg {
  background-color: rgb(81 84 86);
}
</style>