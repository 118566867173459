<template>
  <div @click="trim = !trim" class="comment">
    <template v-if="trim"><string-trim :string="text" :show-popper="false" /></template>
    <template v-else>{{text}}</template>
  </div>
</template>

<script>
import StringTrim from "../../../string/StringTrim";

export default {
  name: "Comment",

  components: {StringTrim},

  props: {
    text: {
      type: String,
      default: ''
    }
  },

  data: function() {
    return {
      trim: true,
    }
  }
}
</script>

<style scoped>
.comment {
  word-break: break-word;
}
</style>