<template>
  <select
      @change="edit"
      :class="selectClass"
      :style="selectStyle"
      ref="editSelect">

    <option v-if="!canSelectNull && selected === null" selected disabled>{{notselected}}</option>
    <option v-if="canSelectNull" :value="null">{{ notselected }}</option>

    <option
        v-for="option in getOptions()"
        :value="option.value"
        :selected="option.value === selected || option.name === beforeLoadSelected"
        :disabled="option.disabled ?? false"
    >{{option.name}}</option>
  </select>
</template>

<script>
export default {
  name: "SimpleSelect",

  props: {
    notselected: {
      default: '—'
    },

    canSelectNull: {
      type: Boolean,
      default: true
    },

    options: {
      // можно передать как массив, объкт или функцию (которая загрузит options)
      require: true
    },

    selected: {
      default: null
    },

    beforeLoadSelected: {
      // если options передан как функция, то это значение будет как selected, до того как загрузятся options
      default: null
    },

    optionsParams: {
      // если options передан как функция, optionsParams задает какие поля используютcя для name/value option,
      // к примеру optionsParams: {value: '_id', name: 'name'}
      type: Object
    },

    callback: {
      type: Function,
    },

    callbackParams: {
      default: null,
    },

    selectClass: {
      type: String,
      default: "text-center"
    },

    selectStyle: {
      type: String,
    },
  },

  emits: [
      "change"
  ],

  data: function() {
    return {
      cachedOptions: null,
    }
  },

  methods: {
    edit(e) {
      if (typeof this.callback === 'function') {
        this.callback(e.target.value, this.callbackParams);
      }
      this.$emit("change", e.target.value);
    },

    getOptions() {
      let options = [];

      if(typeof this.options === 'function' && this.cachedOptions === null) {
        if(this.beforeLoadSelected !== null) {
          options.push({value: '', name: this.beforeLoadSelected, disabled: true});
        }
        options.push({value: '', name: 'Загрузка...', disabled: true});
      } else {
        if(this.cachedOptions !== null) {
          // options из кэша после их загрузки аяксом
          this.cachedOptions.forEach((option) => {
            // параметры, какие поля используются как name/value option
            let params = this.optionsParams ?? {name: 'name', value: 'value'};
            options.push({
              name: option[params.name],
              value: option[params.value]
            });
          });
        } else if (Array.isArray(this.options)) {
          // options передали массивом
          this.options.forEach((el) => {
            options.push({name: el, value: el});
          });
        } else if (typeof this.options === 'object') {
          // options передали объектом
          Object.entries(this.options).forEach((el) => {
            options.push({name: el[1], value: el[0]});
          });
        }
      }

      return options;
    },
  }
}
</script>

<style scoped>

</style>