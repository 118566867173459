<template>
  <div>
    <table class="table table-bordered table-hover table-striped table-container" :class="{'small-table':!isAdmin}">
      <thead class="thead-dark" style="z-index:20">
      <tr>
        <th class="text-end" style="width: 120px;"></th>
        <th  class="text-center"  style="width: 160px;" >Profit</th>
        <th v-if="isAdmin" class="text-center" style="width: 160px;" title="Маржа на брокерские сделки только с клиентскими сетями, где не учитываются сети проданные нашей компанией или для нашей компании">Маржа брокерская (ourwo)</th>
        <th v-if="isAdmin" class="text-center" style="width: 160px;"
             title="Всего продано IP, что в том числе является основным KPI и по продаже наших сетей">IPs</th>
        <th v-if="isAdmin" class="text-center" style="width: 160px;"
             title="Сколько IPs мы купили себе по ценам, которые считаем ниже рынка">Себе IPs (ourbuy)</th>

      </tr>
      </thead>
      <tbody>
          <template v-if="kpi?.all" v-for="row in rowsParams">
            <kpi-row :selected-year="selectedYear" :name="row.name" :val="row.val" :admin-status="adminStatus" :field="row.field" :title="row.title"></kpi-row>
          </template>

      </tbody>
    </table>
  </div>
</template>

<script>
import KpiRow from "./KpiRow";
import {mapGetters} from "vuex";
export default {
  name: "KpiTable",
  components: {KpiRow},
  props:{
    selectedYear:{
      type:String,
      default:null,
    },
    adminStatus:{
      type:Boolean,
      default: null
    },
    onlyCurrent:{
      type:Boolean,
      default: null
    },
  },
  computed:{
    isAdmin:function (){
      return this.adminStatus !== null ? this.adminStatus : this.kpi.isAdmin;
    },
    ...mapGetters({
      'kpi':'statistic/getKpi'
    }),
    rowsParams:function (){
      let rowsParams = [];
      if( this.isPeriodIncluded("firstQuarter") ){
        let firstQuarter = [
          {
            name:'1 квартал (cur)',
            field:"firstQuarter",
            val:'cur',
            title:'Текущее значение'
          },
          {
            name:'1 квартал (avg)',
            field:"firstQuarter",
            val:'avg',
            title:'Среднее значение за все годы'
          },
          {
            name:'1 квартал (max)',
            field:"firstQuarter",
            val:'max',
            title:'Максимальное значение за все годы'
          },
        ];
        rowsParams = rowsParams.concat(firstQuarter)
      }
      if( this.isPeriodIncluded("secondQuarter") ){
        let secondQuarter = [
          {
            name:'2 квартал (cur)',
            field:"secondQuarter",
            val:'cur',
            title:'Текущее значение'
          },
          {
            name:'2 квартал (avg)',
            field:"secondQuarter",
            val:'avg',
            title:'Среднее значение за все годы'
          },
          {
            name:'2 квартал (max)',
            field:"secondQuarter",
            val:'max',
            title:'Максимальное значение за все годы'
          },
        ];
        rowsParams = rowsParams.concat(secondQuarter)
      }
      if( this.isPeriodIncluded("thirdQuarter") ){
        let thirdQuarter = [{
          name:'3 квартал (cur)',
              field:"thirdQuarter",
            val:'cur',
            title:'Текущее значение'
        },
        {
          name:'3 квартал (avg)',
              field:"thirdQuarter",
            val:'avg',
            title:'Среднее значение за все годы'
        },
        {
          name:'3 квартал (max)',
              field:"thirdQuarter",
            val:'max',
            title:'Максимальное значение за все годы'
        },
        ]
        rowsParams = rowsParams.concat(thirdQuarter)
      }
      if( this.isPeriodIncluded("fourthQuarter") ){
        let fourthQuarter =  [
          {
            name:'4 квартал (cur)',
            field:"fourthQuarter",
            val:'cur',
            title:'Текущее значение'
          },
          {
            name:'4 квартал (avg)',
            field:"fourthQuarter",
            val:'avg',
            title:'Среднее значение за все годы'
          },
          {
            name:'4 квартал (max)',
            field:"fourthQuarter",
            val:'max',
            title:'Максимальное значение за все годы'
          },
        ]
        rowsParams = rowsParams.concat(fourthQuarter)
      }
      if( this.isPeriodIncluded("years")){
       let years = [
          {
            name:'Год (cur)',
            field:"years",
            val:'cur',
            title:'Текущее значение'
          },
          {
            name:'Год (avg)',
            field:"years",
            val:'avg',
            title:'Среднее значение за все годы'
          },
          {
            name:'Год (max)',
            field:"years",
            val:'max',
            title:'Максимальное значение за все годы'
          },
        ]
        rowsParams = rowsParams.concat(years)
      }
      return rowsParams;
    },

    lastCurQuarter: function(){
      let lastCurQuarter = null;
      ["firstQuarter","secondQuarter","thirdQuarter","fourthQuarter"].forEach((quarter) => {
        if( typeof this.kpi?.all[quarter] !== "undefined" && this.kpi?.all[quarter].sumIps !== null ){
          lastCurQuarter = quarter;
        }
      });
      return lastCurQuarter;
    }
  },
  methods:{
    isPeriodIncluded: function ( periodName ){
      let included;
      if( this.onlyCurrent === true ){
        included = periodName === this.lastCurQuarter;
      } else {
        included = this.kpi?.all[periodName];
      }
      return included;
    }
  },
  data:function (){
    return {
    }
  },

}
</script>

<style scoped>
.table-data th {
  color: rgba(33, 37, 41, 0.75);
}
.small-table{
  width: 480px;
  margin: auto;
}
</style>