
import {CrmApi} from "../../../library/CrmApi";
import {OfferHistory} from "../../../library/api/OfferHistory";
import {Person} from "../../../library/api/Person";
import {UserPermission} from "../../../users/UserPermission";
import {User} from "../../../library/api/User";
import {checkQuery} from "../tickets/actions";
function isParamsChange(oldQuery , newQuery) {
    let isParamsChange = false;
    let paramsToCheck = {
        page:true,
        onpage:true,
        search:true,
    }
    isParamsChange = checkQuery(paramsToCheck, oldQuery , newQuery);
    if(!isParamsChange){
        isParamsChange = checkQuery(paramsToCheck , newQuery , oldQuery)
    }
    return isParamsChange;
}
const offerHistoryActions = {


    initRates({commit}, {date}){
        return new Promise((resolve, reject) => {
            CrmApi.getRateQuery(date).then(response => {
                commit('setRates', response.data);
            }).finally(()=>{
                resolve();
            });
        });
    },


    openAddModal({state , commit, dispatch, getters , rootGetters, rootState}){
       commit('setIsEditClientOpen', false)
       commit('setPopperClientOpen', {})
        commit('setEditModule', true)
    },


    setPermission({state , commit, dispatch, getters , rootGetters, rootState}){
        UserPermission.can('system.user_list').then(()=>{

            User.getList().then((result)=>{
                commit('setUsers', result.data)
                state.canChange= true
            }).catch((error)=>{
                commit('errorPush' , CrmApi.getErrorMessage(error) , {root:true});
            })
        }).catch(()=>{})
    },


    init({state , commit, dispatch, getters , rootGetters, rootState}){
   
        dispatch('setPermission')

        let query = rootGetters.getRoute.query;
        if(typeof query.search !== 'undefined' && query.search !== null){
            commit('filters/setSearchString', query?.search ?? '');
        }
        if(typeof query.page !== 'undefined' && query.page !== null){
            commit('setPage', query.page);
        }
        if(typeof query.onpage !== 'undefined' && query.onpage !== null){
            commit('setOnPage', query.onpage);
        }
    },


    setPagination({commit} , {page, onpage, total, paginator_links}){
        commit('setPage' , page);
        commit('setTotal', total);
        commit('setOnPage', onpage);
        commit('setLinks', paginator_links)
    },
    changeRoute({state , commit, dispatch, getters , rootGetters, rootState}, {from}){
        return new Promise((resolve, reject)=>{
            commit('setLoading' , true);
            commit('clearOffers')
            commit('clearSearchMeta');
            let oldQuery = Object.assign({} , getters.getFilters);
            dispatch('getOffersHistory', {}).then((result)=>{
                let list = result.data.data;
                if(!isParamsChange(oldQuery,  getters.getFilters)){
                    commit('setOffersHistory', {offersHistory:list})
                    dispatch('setPagination',result.data )
                    commit('setFullBuyPriceUsd' , result.data.buypriceusd)
                    commit('setFullBuyPriceRu' , result.data.buypriceRU )
                    commit('setFullBuyPriceEu' , result.data.buypriceEU )
                    commit('setCountAs', result.data.countas)
                    commit('setFullSellPriceUsd' , result.data.sellpriceusd)
                    commit('setFullSellPriceRu' , result.data.sellpriceRU )
                    commit('setFullSellPriceEu' , result.data.sellpriceEU )
                    commit('setCountIP', result.data.countip )
                    commit('setAdditionalCounts', result.data);
                    commit('setGroups' , result.data.groups)
                    commit('setLoading' , false);
                    commit('setSearchMeta', result.data?.filters ?? null);
                }
                resolve()
            }).catch(()=>{
                commit('setLoading' , false);
                reject()
            }).finally(()=>{

            })
        })

    },

    getOffersHistory({state , commit, dispatch, getters , rootGetters, rootState}, {}){
        return new Promise((resolve , reject)=>{
            if (typeof rootGetters.getRoute.query !== 'undefined') {
                let query = rootGetters.getRoute.query;
                commit('filters/setSearchString', query?.search ?? '');
            }
            let oldPriceSell = getters.getFullSellPriceUsd;
            let oldPriceBuy = getters.getFullBuyPriceUsd;
            let countip = getters.getCountIP;
            let total = getters.getTotal;
            let countas = getters.getCountAs
            commit('setCountAs',0)
            commit('setFullBuyPriceUsd' , 0)
            commit('setTotal', 0 )
            commit('setCountIP', 0 )
            commit('setFullSellPriceUsd' , 0)

            let fspr = getters.getFullSellPriceRu;
            let fspe = getters.getFullSellPriceEu;
            let fbpr = getters.getFullBuyPriceRu;
            let fbpe = getters.getFullBuyPriceEu;
            let additionalCounts  = Object.assign({}, getters.getAdditionalCounts)
            let oldGroups = Object.assign({} , getters.getGroups);
            commit('setFullBuyPriceRu' , 0 )
            commit('setFullBuyPriceEu' , 0 )
            commit('setFullSellPriceRu' , 0 )
            commit('setFullSellPriceEu' , 0 )

            commit('setGroups' , {})
            let oldpaginator = Object.assign({}, state.paginator)
            if(typeof getters.getFilters.page !== 'undefined'){
                commit('setPage', getters.getFilters.page )
            }
            if(typeof getters.getFilters.onpage !== 'undefined'){
                commit('setOnPage', getters.getFilters.onpage )
            }
            OfferHistory.getList(getters.getFilters,)
                .then((result)=>{
                    resolve(result);
                })
                .catch((error)=>{
                    commit('errorPush' , CrmApi.getErrorMessage(error) , {root:true});
                    commit('setFullBuyPriceUsd' ,oldPriceBuy)
                    commit('setFullSellPriceUsd' , oldPriceSell)
                    commit('setTotal', total )
                    commit('setCountIP', countip )
                    commit('setAdditionalCounts', additionalCounts )
                    dispatch('setPagination',oldpaginator)

                    commit('setCountAs',countas)
                    commit('setFullBuyPriceRu' , fbpr )
                    commit('setFullBuyPriceEu' , fbpe )
                    commit('setFullSellPriceRu' , fspr )
                    commit('setFullSellPriceEu' , fspe )
                    commit('setGroups', oldGroups);
                    reject(error);
                }).finally(()=>{

            });
        })

    },

    editObject({state , commit, dispatch, getters , rootGetters, rootState}, {id, params}){
        return new Promise((resolve , reject)=>{
            OfferHistory.editObject(id, params).then((result)=>{
                commit('setOfferHistory', {offerHistory:result.data.result})
                resolve();
            }).catch((error)=>{

                if(error.response?.data?.validation){
                    for (let k in error.response?.data?.validation){
                        commit('errorPush' ,  error.response?.data?.validation[k] , {root:true});
                        break;
                    }

                }else {
                    if(error.response?.data?.error?.message){
                        commit('errorPush' , error.response.data.error.message , {root:true});
                    }else {
                        commit('errorPush' , CrmApi.getErrorMessage(error) , {root:true});
                    }

                }

                reject();
            })
        });
    },


    /*loadPerson({state , commit, dispatch, getters , rootGetters, rootState} , {id, count=0}){
        return new Promise((resolve , reject) =>{
            let person = getters.getPerson(id);
            if(!person.isLoad && !person.loading){
                dispatch('requestPerson' , {id:id}).finally(()=>{
                    resolve( state.persons[id])
                });
            }else {
                if(person.loading && count < 50){
                    setTimeout(()=>{
                        count++;
                        dispatch('loadPerson', {id:id , count:count}).then(()=>{
                            resolve( state.persons[id])
                        })
                    }, 200)
                }else{
                    resolve( state.persons[id])
                }

            }

        });
    },*/

    requestPerson({state , commit, dispatch, getters , rootGetters, rootState} , {id}){
        return new Promise((resolve , reject) =>{
            let person = getters.getPerson(id);
            if(!person.isLoad && !person.loading){
                person.isLoad = true;
                person.loading = true;
                let filters= {
                    _id:id
                };
                Person.getPerson(filters).then(
                    (result)=>{
                        if(typeof result.data.data[0] !== 'undefined'){
                            person.object = result.data.data[0];

                        }
                        resolve();
                    }
                ).catch(
                    (error)=>{
                        commit('errorPush' , CrmApi.getErrorMessage(error) , {root:true});
                        reject();
                    }
                ).finally(
                    ()=>{
                        person.loading = false;
                        state.persons[id] = person;
                    }
                )
            }else {
                resolve();
            }
        })
    },
    closeDeleteModalWindow({state , commit} ){
        commit('setShowDeleteModal', false);
        commit('showContextMenu' , false);
        commit('setContextMenuParams' , null);
    },


    changeCurrency({getters, commit}, newCurrency) {
        commit('setCurrentCurrency', newCurrency);
    },
}

export default offerHistoryActions;