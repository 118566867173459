import {CrmApi} from "../../../library/CrmApi";
import moment from "moment";
import SimpleMDE from "../../template/source/src/js/simplemde";
import DOMPurify from "dompurify";
import {UserPermission} from "../../../users/UserPermission";
import { marked } from "marked";

export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
           // console.warn("Copy to clipboard failed.", ex);
           // return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}
export function getMarkdown(markdown, loadImages, options = null){
    markdown =  SimpleMDE.prototype.markdown(markdown, options);
    let config = {FORBID_TAGS: loadImages ? [] : ['img']};
    if (options!== null && options?.linksInNewTab === true) {
        config['ADD_ATTR'] = ['target'];
    }
    let html = DOMPurify.sanitize(markdown, config);

    if(!loadImages) {
        html = html.replace(/url\([^\)]+\)/i, 'none');
    }
    return html;
}

export function getInlineMarkdown(markdown, options = null, config = {}) {
    return DOMPurify.sanitize(
        marked.parseInline(markdown, options),
        config
    );
}

export function changeMultySelectStyles(document){
    let ellements = document.getElementsByClassName('multiselect-tags-search-copy');
    for (let i = 0; i < ellements.length; i++) {
        ellements[i].style.height = 'auto';
    }
}

export function replaceTextObjects(text , objects , numberToShow , field, defaultValue = null){
    let tmp = [];
    let i = 0;

    for (let key in objects){
        i++;
        if(i <= numberToShow){
            let obj = objects[key];
            let objField = obj[field];

            if(defaultValue && (typeof objField !== 'string' || objField === '')) {
                objField = defaultValue;
            }

            tmp.push('"'+objField +'"');
        }
    }
    let str = tmp.join(", ");
    text = text.replace('{{items}}' , str );
    return text;
}


export function getTicketLastSeen(ticket ,  userId){
    let lastSeen = null;
    let breakObject = {};
    if(Array.isArray( ticket.last_seen) && ticket.last_seen.length !== 0){
        try{
            for (let value of ticket.last_seen){
                if(window.getId(value._id) === window.getId(userId) && userId != null ){
                    if(moment(value.date, 'YYYY-MM-DD HH:mm:ss').isValid()){
                        lastSeen = value.date;
                    }
                    throw breakObject;
                }
            }
        }catch (breakObject ){}

    }
    return lastSeen;
}

export function isTicketReaded(ticket ,userId ){
    let isTicketReaded = false;
    let lastSeen = getTicketLastSeen(ticket , userId);
    if(lastSeen !== null){
        if(window.moment(ticket.updated).unix() <= window.moment(lastSeen).unix()){
            isTicketReaded = true;
        }
    }
    return isTicketReaded;
}

export function isNotificationRead(ticket, userId) {
    return typeof ticket['watched'][userId] !== 'undefined';
}

export async function canLockTicket(ticket) {
    if (typeof ticket?.project?.name !== 'undefined' && ticket?.project?.name !== '') {
        return UserPermission.can(ticket.project.name + '.answer').then(() => true);
    } else {
        return new Promise(resolve => resolve(false));
    }
}

export function canUnlockTicket(ticket, userId) {
    return typeof ticket?.assigned?._id !== 'undefined' && window.getId(ticket?.assigned?._id) === userId;
}

export function updateTicket({ticketId,project , ticket , assigned_projects  }, accessCallback , catchCallback , finallyCallback){
    return CrmApi.call('/thread/'+ticketId+'/update', {'project': project , 'ticket':ticket , 'assigned_projects':assigned_projects}).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    })
}


export function setNotificationWatched({ticketIds} , accessCallback , catchCallback , finallyCallback){
    CrmApi.call('/notification/watched/', {'message-id': ticketIds}).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    })
}

export function setNotificationUnWatched({ticketIds}, accessCallback, catchCallback, finallyCallback) {
    CrmApi.call('/notification/notwatched/', {'message-id': ticketIds}).then(result => {
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() => {
        finallyCallback();
    })
}

function getTicketParamsByName(ticketRequestParams, name){
    if(name === "Unsorted"){
        ticketRequestParams.projectName = 'unsorted';
        ticketRequestParams.filter = "unsorted";
    }
    if(name === "Competitor"){
        ticketRequestParams.projectName = '';
        ticketRequestParams.filter = "competitor";
    }
    if(name === "Recent"){
        ticketRequestParams.projectName = '';
        ticketRequestParams.filter = "recent";
    }
    if(name === "Trash"){
        ticketRequestParams.projectName = '';
        ticketRequestParams.filter = "trash";
    }
    if(name === 'Billmanager') {
        ticketRequestParams.filter = 'billmanager';
    }
    if(name === 'Calls') {
        ticketRequestParams.filter = 'calls';
    }

    if(name === 'Tasks') {
        ticketRequestParams.filter = 'tasks';
    }

    return ticketRequestParams;
}

export function getTicketList({  query , params , name} , accessCallback , catchCallback , finallyCallback){
    let ticketRequestParams = {};
    let project = params.project ?? '';
    if (project === 'all') {
        project = query.projectName ?? project;
    }
    ticketRequestParams.projectName = project ?? '';
    ticketRequestParams.filter =  params.filter ?? '';
    ticketRequestParams = getTicketParamsByName(ticketRequestParams , name);

    CrmApi.getTicketList(query, ticketRequestParams).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    })
}

export function takeTicketToWork({ticketId} , accessCallback , catchCallback , finallyCallback){
    return CrmApi.takeToWork(ticketId).then(result=>{
        accessCallback(result , ticketId);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason) , ticketId);
    }).finally(() =>{
        finallyCallback();
    })
}

export function unlockTicket({ticketId}, successCallback, catchCallback, finallyCallback) {
    return CrmApi.unlockTicket(ticketId).then(result => {
        successCallback(result, ticketId);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason), ticketId);
    }).finally(() => {
        finallyCallback();
    });
}

export function restoreTickets({ticketIds} , accessCallback , catchCallback , finallyCallback){
    CrmApi.restoreTickets(ticketIds).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}
export function removeTickets({ticketIds} , accessCallback , catchCallback , finallyCallback){
    CrmApi.removeTickets(ticketIds).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}

export function deleteTicket({ticketIds, withTag} , accessCallback , catchCallback , finallyCallback ){
    CrmApi.deleteTickets(ticketIds , withTag).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}
export function setNotReaded({ticketIds} , accessCallback , catchCallback , finallyCallback){
    CrmApi.setNotReaded(ticketIds).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}
export function setReaded({ticketIds} , accessCallback , catchCallback , finallyCallback){
    CrmApi.setReaded(ticketIds).then(result=>{
        accessCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}

export function setAllRead({project}, successCallback, catchCallback, finallyCallback) {
    CrmApi.setAllRead(project).then(result=>{
        successCallback(result);
    }).catch(reason => {
        catchCallback(CrmApi.getErrorMessage(reason));
    }).finally(() =>{
        finallyCallback();
    });
}

export function addUrlParams( route , query , name , router){

    let oldParams = Object.assign({}, route.query);

    for (let key of Object.keys(query)) {
        oldParams[key] = query[key];
    }
    router.push({name:name , query: oldParams})
}

export function isItTicketsListRoute( name){
    return getRouteAllies(name) === "tickets";
}

export function   getRouteAllies(name){
    let allies = "";
    switch (name) {
        case "TicketsProjectFilter":
        case "Unsorted":
        case "Competitor":
        case "Recent":
        case 'Billmanager':
        case "Trash":
        case "Calls":
        case "Tasks":
            allies = "tickets";
            break;
    }
    return allies;
}


export  function getTicketListForSearch({search, project} , callBack) {
    let ticketRequestParams = {};
    let query = {}
    query.search = search;
    query.searchParams = JSON.stringify({
        _id: true,
        name: true,
        body: false,
        updated: false,
        tags: false,
        'person.email': true,
        'person.name': false,
    });
    query.strict = JSON.stringify([
        'name',
        'person.email',
        'unconfirmed',
    ]);
    query.oB = JSON.stringify({
        f: 'updated',
        v: 1,
    });
    query.page = 1;
    query.onpage = 50;
    ticketRequestParams.projectName = project ?? '';
    ticketRequestParams.filter = '';
    return CrmApi.getTicketList(query, ticketRequestParams).then(result=>{
        let out = [];

        for (const ticket of result.data.data) {
            let tmp = {
                value:ticket._id,
                name:ticket.name,
                email:ticket.person.email
            }
            out.push(tmp);
        }
        callBack(result.data);
        return out;
    }).catch(reason => {
        return [];
    })
}


export function getDateFormat(date , format){
    let formatted = "";
    if(typeof date !== 'undefined' && date != null && String(date).trim() !== ""){
        formatted =  window.moment(date).format(format);
    }
   return formatted;
}

