<template>
  <div>
    <div v-if="!isHasChange && !isHasCreated " >
      <h3 class="d-inline">{{ title }}</h3>
    </div>
    <div v-else>
      <popper class="changesPopper" :disableClickAway="true" placement="bottom" :hover="true" :interactive="false" :arrow="true">
        <h3 class="d-inline">{{ title }}</h3>
        <template #content>
          <div style="display: grid; text-align:left">
            <span v-if="isHasChange" v-for="(change, index) in template.changes"  ><span>Изменен: {{getDateFormat(change.date)}}</span>: <span>{{getAuthorName(change.author)}}</span></span>
            <span v-if="isHasCreated" ><span>Создан: {{getDateFormat(template.created)}}</span>: <span>{{getAuthorName(template.author)}}</span></span>
          </div>

        </template>
      </popper>
    </div>

  </div>
</template>

<script>
import moment from "moment";
import {getDateFormat} from "../../ticket/Api/api";
export default {
  name: "TemplateChangesPopper",
  props:{
    'template':{
      type:Object,
      require:true,
    },
    'title':{
      type:String,
      require:true,
    }
  },
  methods:{
    getDateFormat(value){
      return getDateFormat(value , 'YYYY-MM-DD HH:mm:ss')
    },
    getAuthorName(author){
      let name = "";
      if(typeof author.name !== 'undefined' && author.name != null && author.name.trim() != ''){
        name = author.name;
      }
      if(name == ""){
        name = author.login;
      }
      return name;
    }
  },
  computed:{
    isHasCreated(){
      return typeof this.template.created !== 'undefined' && typeof  this.template.author !== 'undefined' && this.template.created != null &&
          this.template.author !== null
    },
    isHasChange(){
      return typeof this.template.changes !== 'undefined' &&  this.template.changes != null && this.template.changes.length > 0
    }
  }
}
</script>

<style scoped>
.changesPopper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 6px;
  --popper-theme-box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.25);
}
</style>