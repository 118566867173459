import {CrmApi} from "../CrmApi";
import {prepareObjectToUrl} from "../Functions";


export {Utility}
class Utility {
    static getPrice(net){
        return CrmApi.call('/utility/pricecalc/' + CrmApi.prepareSubnet(net))
    }

    static getSubnetWhois(subnet, params) {
        return CrmApi.call('/utility/whois/' + CrmApi.prepareSubnet(subnet) + prepareObjectToUrl(params));
    }

    static getNetCriticalChanges(subnet) {
        return CrmApi.call('/utility/netcriticalchanges/' + CrmApi.prepareSubnet(subnet));
    }

    static getTransferInfo(subnet, params) {
        return CrmApi.call('/utility/gettransferinfo/' + CrmApi.prepareSubnet(subnet) + prepareObjectToUrl(params));
    }

    static getEgrul(vatnum = null, ogrn = null){
        let url = '/utility/egrul';
        let params = [];
        if(vatnum !== null){
            params.push('inn='+encodeURIComponent(vatnum));
        }
        if(ogrn !== null){
            params.push('ogrn='+encodeURIComponent(ogrn))
        }
        if(params.length > 0){
            url=   url + '?' + params.join("&")
        }
        return CrmApi.call(url)
    }

    static getOrgByLirid(lirid) {
        return CrmApi.call('/utility/orgbylir/?lirid=' + encodeURIComponent(lirid));
    }

}