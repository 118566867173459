<template>
  <div class="tags-wrapper">
    <div class="text-muted mb-2" v-if="disabledText" style="font-size: 10px;">
      Для добавления метки надо нажать enter или выбрать введенную опцию из списка
    </div>
    <div v-if="hasDifferentTags" class="text-muted mb-2" style="font-size: 12px;">
      Групповая операция может быть выполнена только для общих меток тикетов
    </div>
    <multiselect
        @keyup.enter.stop="{}"
        :placeholder="placeholder"
        v-model="source.tags"
        :mode="'tags'"
        :close-on-select="false"
        :searchable="true"
        :create-option="true"
        :delay="800"
        :options="updateTags"
        ref="multy"
        @search-change="checkInput"
        @select="tagsSelect"
        @deselect="tagsDeselect"
        @clear="tagsClear"
        @option="this.setDisabled(false, false)"
    ></multiselect>
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import {changeMultySelectStyles} from "../../../ticket/Api/api";
import {mapGetters} from "vuex";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  components: {
    Multiselect,
  },

  emits: ['setDisabled'],

  name: "TagsBody",

  data:function (){
    return {
      allTagsArray: [],
      initialTags: [],
      hasDifferentTags: false,
      disabledText: false,
    }
  },

  props: {
    "source": {
      type: Object,
      require: true,
    }
  },

  methods: {
    hasNoChanges() {
      return this.source.tags.length === this.initialTags.length &&
          _.isEqual(
              [...this.source.tags].sort(),
              [...this.initialTags].sort()
          );
    },
    setDisabled(value, withText = null) {
      if (withText !== null) {
        this.disabledText = withText;
      }
      this.$emit('setDisabled', value);
    },
    checkInput(query) {
      this.setDisabled(this.hasNoChanges() || String(query).trim() !== '', String(query).trim() !== '');
    },
    checkValues() {
      this.setDisabled(this.hasNoChanges());
    },
    tagsClear() {
      this.changeState(null, 'clear');
      this.$nextTick(() => {
        this.checkValues();
      });
    },
    tagsSelect(value) {
      this.changeState(value, 'select');
      this.checkValues();
    },
    tagsDeselect(value) {
      this.changeState(value, 'deselect');
      this.checkValues();
    },
    changeState(value, action) {
      if (this.hasDifferentTags) {
        this.$emit('changeState', action === 'clear' ? this.initialTags : value, action);
      }
    },
    updateTags(query){
      return CrmApi.getTags(query).then((result)=>{
        this.allTagsArray = [];
        let unique = {};
        if(this.$refs.multy.search == '' || this.$refs.multy.search == null){
          for (let key of this.initialTags){
            this.allTagsArray.push({
              value: key,
              label: key,
            });
            unique[key] = key;
          }
        }

        let tags = result.data.data;
        for (let key in tags){
          let el = tags[key];
          if(typeof unique[el.tags] === 'undefined'){
            this.allTagsArray.push({
              value:el.tags,
              label:el.tags,
            });
          }
        }

        return this.allTagsArray;
      }).catch(()=> {
        return this.allTagsArray;
      });
    },
  },
  mounted() {
    changeMultySelectStyles(document);
  },
  created() {
    this.source.tags = [];
    this.allTagsArray = [];
    let tagsObjects = {};
    let count = this.getSelectedArray.length;
    let values = [];

    for(let key in this.getSelectedObjects){
      let obj = this.getSelectedObjects[key];
      if(typeof obj.tags !== 'undefined' && Array.isArray(obj.tags) && obj.tags.length > 0){
        for (let tag of obj.tags){
          let t = tag;
          tag = tag.toLowerCase();
          if(typeof tagsObjects[tag] === 'undefined'){
            tagsObjects[tag] = {};
            tagsObjects[tag].length = 0;
            tagsObjects[tag].value = t;
          }
          tagsObjects[tag].length++;
        }
      }
    }
    for (let key in tagsObjects) {
      let obj = tagsObjects[key];
      if (obj.length === count) {
        values.push(obj.value);
        this.allTagsArray.push({
          value: obj.value,
          label: obj.value,
        });
        this.initialTags.push(obj.value);
      } else {
        this.hasDifferentTags = true;
      }
    }
    this.source.full = !this.hasDifferentTags;
    this.source.tags = values;
    this.setDisabled(true);
  },
  computed:{
    placeholder() {
      return this.hasDifferentTags ? 'У выбранных тикетов разный набор меток' : "Введите метки";
    },
    ...mapGetters({
      "getSelectedArray":  'tickets/getSelectedArray',
      'getSelectedObjects': 'tickets/getSelectedObjects',
    }),
  }
}
</script>

<style scoped>
.tags-wrapper:deep(.multiselect-clear) {
  --ms-px: 4px;
  margin-right: 16px;
}
</style>