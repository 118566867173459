<template>
  <div class="border mb-3">
    <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
      <thead class="sticky">
        <tr class="no-shadow">
          <th
              data-bs-toggle="collapse"
              :aria-expanded="!collapsed"
              class="expandable"
              data-bs-target="#billmanager-ticket-table-collapse"
              :colspan="4"
              scope="col">Запросы BILLmanager ({{ sortedRelations.length }})</th>
        </tr>
      </thead>
    </table>

    <div class="relation-table collapse" id="billmanager-ticket-table-collapse" :class="{'show': !collapsed}">
      <table class="table table-bordered thread-person-relation-table table-striped text-center mb-0">
        <thead class="sticky">
          <tr>
            <th
                class="sortable"
                :class="sortedClass('id')"
                @click="sortBy('id')">ID</th>
            <th
                class="sortable"
                :class="sortedClass('subject')"
                @click="sortBy('subject')">Тема</th>
            <th
                class="sortable"
                :class="sortedClass('date_last')"
                @click="sortBy('date_last')">Последнее сообщение</th>
            <th
                class="sortable"
                :class="sortedClass('client')"
                @click="sortBy('client')">Клиент</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="relation in sortedRelations">
            <td><a :href="relation.link" target="_blank">{{relation.id}}</a></td>
            <td class="text-start">{{relation.subject}}</td>
            <td>{{relation.date_last}}</td>
            <td>{{relation.client}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {sortFunctions} from "../Relation";

export default {
  name: "Ticket",

  props: {
    relations: {},
    collapse: {
      default: false,
    },
  },

  data() {
    return {
      collapsed: this.collapse,
      sort: 'id',
      sortDirection: -1,
    }
  },

  computed: {
    sortedRelations() {
      let result = this.relations;
      let sortTypes = {
        "id": "id",
      };
      if (this.sort && this.sortDirection) {
        result = this.relations.sort(
            sortFunctions(
                this.sort,
                this.sortDirection,
                sortTypes[this.sort] ?? 'default'
            )
        );
      }
      return result;
    }
  },

  methods: {
    sortBy(field) {
      if (this.sort === field) {
        this.sortDirection *= -1;
      } else {
        this.sort = field;
        this.sortDirection = -1;
      }
    },
    sortedClass(field) {
      return {
        'sorted-down': this.sort === field && this.sortDirection === -1,
        'sorted-up': this.sort === field && this.sortDirection === 1,
      }
    }
  },

  watch: {
    'collapse' (to) {
      if (to) {
        this.collapsed = true;
      }
    }
  }
}
</script>

<style scoped>
</style>