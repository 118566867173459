<template>
  <span v-if="!asInput"
        v-clipboard="clipboard"
        :custom-popover-placement="clipboardPopoverPlacement"
        :class="{'d-flex': trim, 'editable-text': !disabled, 'text-primary': !disabled, 'text-danger': required && !isValid}"
        :style="stringStyle"
        @click="click()"
        @dblclick="showInput()">

    <template v-if="trim">
      <string-trim :string="value"
                   :show-popper="trimShowPopper"
                   :style="trimStringStyle"
                   :popper-placement="trimPopperPlacement"
                   :popper-position="trimPopperPosition" />
    </template>

    <template v-else-if="markdown"><span v-html="getMarkdown()"></span></template>

    <template v-else>{{value}}</template>

  </span>

  <input v-if="asInput && !asTextArea"
    v-model.trim="textProxy"
    @focusout="focusout"
    @keyup.esc.stop="cancel"
    @keyup.enter.stop="edit"
    @input="isValid = true"
    ref="editInput"
    type="text"
    :class="this.class"
    :style="style"
    :disabled="disabled"
    v-focus="autofocus"
  >
  <textarea v-if="asInput && asTextArea"
            v-focus="autofocus"
            v-model.trim="textProxy"
            @focusout="focusout"
            @keyup.esc.stop="cancel"
            @keyup.enter.ctrl.stop="edit"
            v-enter.meta="edit"
            @input="textAreaOnInput"
            ref="editInput"
            type="text"
            :class="this.class"
            :style="style" />
</template>

<script>
import StringTrim from "../string/StringTrim";
import {showAbsoluteInput} from "./InputAbsolute";
import {getMarkdown} from "../ticket/Api/api";

export default {
  name: "EditableText",

  components: {StringTrim},
  emits: ["change", "close", "cancel", "open"],

  props: {
    oneClick:{
      type:Function,
      default:null,
    },
    asTextArea:{
      type:Boolean,
      default:false,
    },
    text: {
      require: true
    },
    default: {
      default: '',
    },
    callback: {
      type: Function,
    },
    cancelCallback: {
      type: Function,
    },
    callbackParams: {
      default: {},
    },
    inputClass: {
      type: String,
      default: "text-center"
    },
    inputStyle: {
      type: String,
    },
    stringStyle: {
      type: String,
    },
    trimStringStyle: {
      type: String,
    },
    startAsInput: {
      type: Boolean,
      default: false,
    },
    clipboard: {
      type: Boolean,
      default: false,
    },
    clipboardPopoverPlacement: {
      type: String,
      default: 'top',
    },
    validation: {
      type: Function,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    userChangeParam:{
      type:Boolean,
      default:null
    },
    autofocus:{
      type:Boolean,
      default:false,
    },
    focusOutCancel:{
      type:Boolean,
      default:true,
    },
    trim: {
      type: Boolean,
      default: false,
    },
    trimShowPopper: {
      type: Boolean,
      default: true,
    },
    trimPopperPlacement: {
      type: String,
      default: 'top',
    },
    trimPopperPosition: {
      type: String,
      default: 'absolute',
    },
    defaultValid:{
      type:Boolean,
      default:true,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    absoluteParent: {
      default: null,
    },
    dynamicAbsoluteWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    markdown: {
      type: Boolean,
      default: false,
    },
    markdownOptions: {
      type: Object,
      default: {}
    },
    expandable: {
      type: Boolean,
      default: false,
    },
    selectText: {
      type: Boolean,
      default: true,
    },
    preventFocusOut: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      textProxy: this.text,
      asInput: false,
      isValid: false,
      timer:null,
      inputWidth: 260,
      dynamicSymbolPx: 8.75,
      dynamicMaxWidth: 500,
      minHeight: 50,
      maxHeight: 430,
    }
  },

  mounted: function() {
    this.isValid =this.defaultValid;
    this.textProxy = this.text;
    if(this.startAsInput) {
      this.asInput = true;
      this.$nextTick(() => {
        if(this.absolute) {
          this.setAbsolute();
        }
        this.$refs.editInput.focus();
        this.$refs.editInput.select();
      })
    }
  },

  methods: {
    edit: function(e) {
      this.checkValidation();

      if(this.isValid) {
        if (typeof this.callback === 'function') {
          let result = this.callback(e.target.value, this.callbackParams);

          if (
              typeof result === 'undefined' ||
              result === null ||
              result === true
          ) {
            this.success(e);
          } else if ((typeof result === "object" || typeof result === "function") &&
              typeof result.then === "function") {
            result.then((a) => {
              this.success(e);
            }, (err) => {
              this.gotError(err);
            }).catch((err) => {
              this.gotError(err);
            });
          }
        } else {
          this.success(e);
        }
      }
    },

    gotError(error) {
      if (this.$refs?.editInput && typeof this.$refs?.editInput?.select === 'function') {
        this.$refs.editInput.select();
      }
    },

    success(e) {
      this.$emit("change", e.target.value, this.callbackParams);
      this.$emit("close", e.target.value, this.callbackParams);
      this.asInput = false;
    },

    updateTextProxy:function (value){
      this.textProxy = value;
    },
    focusout: function (e) {
      let can = true;
      if (typeof e.explicitOriginalTarget !== 'undefined') {
        if (e.explicitOriginalTarget === this.$refs.editInput) {
          can = false;
        }
      }
      if (can && !this.preventFocusOut) {
        if (this.focusOutCancel) {
          this.cancel(e);
        } else {
          this.edit(e);
        }
      }
    },
    cancel: function(e) {
      if(this?.$store?.commit){
        this.$store.commit('removeActiveWindow', 'editText');
      }
      this.textProxy = this.text;
      this.checkValidation(this.text);
      this.$emit("close", this.text, this.callbackParams);
      this.$emit("cancel", this.text, this.callbackParams);
      this.asInput = false;
    },
    click:function (){
      if(this.oneClick !== null){
        clearTimeout(this.timer)
        this.timer = setTimeout(()=>{
          this.oneClick()
        }, 300)
      }

    },
    showInput: function() {
      clearTimeout(this.timer);

      if(!this.disabled) {
        if(typeof this.$store !== 'undefined') {
          this.$store.commit('setActiveWindow', 'editText');
        }
        this.asInput = true;

        this.checkValidation(this.text);

        this.$nextTick(function() {
          if (this.asTextArea) {
            this.resizeTextArea();
          }

          if(this.absolute) {
            this.setAbsolute();
          }

          this.$refs.editInput.focus();
          if(this.selectText) {
            this.$refs.editInput.select();
          }
        }.bind(this));

        this.$emit('open');
      }
    },

    checkValidation: function(text = null) {
      text = (typeof text === 'string') ? text : this.textProxy;
      this.isValid = typeof this.validation === 'function' ?
                        (text === '' ? !this.required : this.validation(text))
                          : true;
    },

    setAbsolute() {
      let parent = this.absoluteParent === null ? $(this.$refs.editInput).parent() : this.absoluteParent;
      showAbsoluteInput(this.$refs.editInput, parent);
    },

    getMarkdown(){
      if(!this.text){
        return '<div>&nbsp;</div>';
      }
      return getMarkdown(this.text, false, this.markdownOptions);
    },

    textAreaOnInput() {
      this.isValid = true;
      this.resizeTextArea();
    },

    resizeTextArea() {
      if (this.expandable) {
        let box = this.$refs.editInput;
        $(box).height(0);
        let height = box.scrollHeight;
        if (height < this.minHeight) height = this.minHeight;
        if (height > this.maxHeight) height = this.maxHeight;
        $(box).height(height);
      }
    },
  },

  computed: {
    value: function() {
      return (typeof this.text === 'undefined' ||
              this.text === null ||
              this.text === '') ? this.default : this.text;
    },

    class: function() {
      let c = this.inputClass;
      if(!this.isValid) {
        c = c + ' is-invalid';
      }
      if(this.absolute) {
        c += ' absolute';
      }
      if (this.expandable) {
        c += ' expandable';
      }
      return c;
    },

    style() {
      let style = this.inputStyle ?? '';
      if(this.absolute) {
        if(typeof style === 'string') {
          style += ' width: ' + this.absoluteWidth + 'px !important;';
        }
      }

      return style;
    },

    absoluteWidth() {
      let width = this.inputWidth;
      if(this.dynamicAbsoluteWidth && this.dynamicWidth > width) {
        width = this.dynamicWidth;
      }

      return width;
    },

    dynamicWidth() {
      let dynamicWidth = Math.round(this.text.length * this.dynamicSymbolPx);
      if(dynamicWidth > this.dynamicMaxWidth) {
        dynamicWidth = this.dynamicMaxWidth;
      }

      return dynamicWidth;
    }
  },

  watch: {
    userChangeParam(to){
      if(to !== null){
        this.asInput = to;
        if(to){
          this.$nextTick(()=>{
            if(this.$refs?.editInput){
              this.$refs.editInput.focus();
              this.$refs.editInput.select();
            }

          });
        }

        this.checkValidation();
      }
    },
    text(to, from){
      if(to !== this.textProxy){
        this.textProxy = to;

        if(!this.disabled) {
          this.checkValidation(to);
        }
      }
    }
  }
}
</script>

<style scoped>
.editable-text {
  cursor: pointer;
}
.absolute {
  position: fixed;
}
.expandable {
  min-height: 50px;
  max-height: 430px;
}
.is-invalid:focus {
  border: 1px solid #dc3545 !important;
  outline: none;
}
.is-invalid {
  border: 1px solid #dc3545 !important;
}
</style>