<template>
  <div class="" :class="{'text-muted': loading}">
    <div v-if="company?.ripe?.lirid">
      Компания
      <popper :content="``" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <span class="badge bg-secondary">LIR</span>
        <template #content>
          LIRid: {{company.ripe.lirid}}
          <template v-if="company.ripe.org"><br/>ORG: {{company.ripe?.org}}</template>
        </template>
      </popper>
    </div>
    <div v-else>Компания:</div>
    <div style=" display: flex;" @mouseleave="showCopyMenu =false" @mouseover="showCopyMenu = true">
      <editable-text
          v-if="userCanChange"
          :text="name"
          :callback="changeName"
          :trim="true"
          default="Не заполнено"
          input-class="name-input w-75 form-control form-control-sm"
      />
      <string-trim v-else :string="name" />

      <span>
        <copy-badge :show="showCopyMenu" :content="name"></copy-badge>
      </span>
    </div>
  </div>
</template>

<script>
import EditableText from "../../../Editable/EditableText";
import CopyBadge from "../../../common/CopyBadge";
import {CrmApi} from "../../../../library/CrmApi";
import StringTrim from "../../../string/StringTrim";
import {mapGetters} from "vuex";

export default {
  name: "CompanyName",
  components: {
    StringTrim,
    CopyBadge,
    'editable-text': EditableText,
  },
  props:{
    company:{
      type:Object,
      require:true,
    },
    ticketId: {
      require: true
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
      "updatePerson",
  ],
  data:function (){
    return {
      loading:false,
      showCopyMenu:false,
    }
  },
  computed:{
    name:function (){
      return this.company?.name;
    },
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },
  methods: {
    changeName: function(name) {
      this.loading = true;
      let prevValue = this.company.name;
      this.company.name = name;
      this.api.editPerson({"change": {"company.name": name}}).then((result) => {
        if (typeof result.data?.person === 'object' && result.data.person !== null) {
          this.$emit('updatePerson', result.data.person);
        }
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.company.name = prevValue;
      }).finally(() => {
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>

</style>