const ratesGetters = {
    getRatesList:(state) => {
        return state.ratesStatistic;
    },
    loading:(state)=>{
        return state.loading
    }
}


export default ratesGetters;