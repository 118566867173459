<template>
  <tasks></tasks>
  <params></params>
  <client v-if="!isProjectNull && canViewHeaders" class="mt-2"></client>
</template>

<script>
import {UserPermission} from "../../users/UserPermission";
import Params from "./Params";
import Client from "./Client";
import Tasks from "./Tasks";

export default {
  name: "TicketMenu",

  components: {
    Params,
    Client,
    Tasks
  },

  data: function() {
    return {
      canViewHeaders: false,
    }
  },

  mounted() {
    let permissions = [
      this.getTicket() != null && this.getTicket().project != null ?
          this.getTicket().project.name + ".headers" :
          "system.headers",
      this.getTicket() != null && this.getTicket().project != null ?
          this.getTicket().project.name + ".change" :
          "system.read_pending"
    ];
    UserPermission.can(permissions); // preloading purpose
    UserPermission.can(permissions[0]).then(()=>{
      this.canViewHeaders = true;
    });
  },

  computed: {
    isProjectNull: function() {
      return !_.isObject(this.getTicket()?.project);
    },
  },

  methods: {
    getTicket: function() {
      return this.$store.state.thread.ticket;
    },
  }
}
</script>

<style scoped>
</style>