<template>
  <div class="task-row" :class="rowClass" @click="scrollToTask">
    <div v-if="this.deadlineDate" class="task-row-date">
      {{deadlineDateOutput}}
      <span v-if="isOutOfDate">(просрочена на <timediff :from="deadlineDate" :to="new Date()"></timediff>)</span>
      <span v-else>(через <timediff :from="new Date()" :to="deadlineDate"></timediff>)</span>
    </div>
    <string-trim :string="task.subject"></string-trim>
  </div>
</template>

<script>
import moment from "moment";
import StringTrim from "../../../string/StringTrim";
import Timediff from "../../../common/timediff";

export default {
  name: "TaskRow",

  components: {
    StringTrim,
    Timediff,
  },

  props: {
    task: {
      require: true,
    },
  },

  data: function (){
    return {
      formatDate: 'DD.MM.YYYY HH:mm',
    }
  },

  computed: {
    assigneeId() {
      let result = null;
      if (typeof this.task?.headers?.assignee?._id !== 'undefined' && this.task?.headers?.assignee?._id) {
        result = window.getId(this.task?.headers?.assignee?._id);
      }
      return result;
    },
    isAssignee() {
      return this.assigneeId === window.userId;
    },
    deadlineDate() {
      return this.task?.headers?.deadlinedate ?? null;
    },
    deadlineDateOutput() {
      return this.deadlineDate ? moment(this.deadlineDate).format(this.formatDate) : null;
    },
    isOutOfDate() {
      return moment().isAfter(moment(this.deadlineDate));
    },
    rowClass() {
      let result = [];
      if (this.isAssignee) {
        result.push('fw-bold');
      }
      if (this.deadlineDate) {
        if (this.isOutOfDate) {
          result.push('alert-danger');
        } else {
          result.push('alert-warning');
        }
      }
      return result;
    },
  },

  methods: {
    scrollToTask() {
      this.$emit('scrollToTask', window.getId(this.task._id));
    },
  }
}
</script>

<style scoped>
  .task-row {
    cursor: pointer;
    color: rgb(var(--bs-primary-rgb));
    background: var(--bs-alert-bg);
    border-bottom: 1px solid var(--bs-gray-dark);
    padding: 0.5rem;
    font-size: 14px;
  }
  .task-row:first-child {
    border-top: 1px solid var(--bs-gray-dark);
  }
  .task-row-date {
    color: black;
    font-size: 10px;
  }
</style>