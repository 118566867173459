<template>
  <tr :class="trClass" style="cursor: pointer;">
    <td :class="getNonReadedClass"  class="row-date text-center"  >{{messageCreated(ticket.updated)}}</td>
    <td   class="text-start"   >
      <div :class="getNonReadedClass" class="d-flex">
        <string-trim v-if="ticket.name" :string="ticket.name" />
        <span v-else class="fst-italic">[Без темы]</span>
      </div>
      <div class="row" style="font-size:12px; margin-top: 2px; position: relative ; margin-left: 0px;">
        <unconfirmed-line class="col-auto projectPaddingsOne" v-if="hasUnconfirmed" :unconfirmed="ticket.unconfirmed"></unconfirmed-line>
        <in-work-line  class="col-auto projectPaddingsOne" v-if="isInWork" :assigned="ticket.assigned"></in-work-line>
        <project-line  class="col-auto projectPaddingsOne"  v-if="isNeedProjectLine" :project="ticket.project"></project-line>
        <project-line v-if="isNeedProjectLine"  class="col-auto projectPaddingsOne" v-for="assigned_project in ticket.assigned_projects" :custom-style="{'background-color':'#B178DD'}" :project="assigned_project" ></project-line>
        <tags  v-if="isNeedTags" :tags="ticket.tags" :autodelete_at="ticket.autodelete_at ?? null" class=" projectPaddingsOne" ></tags>
        <div v-if="$route.name === 'Calls' && typeof ticket.headers !== 'undefined' && ticket.headers !== null" :class="getNonReadedClass" class="d-flex col-auto p-0">
          <span style="padding-right: 3px;">Статус:</span>
          <OutgoingStatusInfo v-if="ticket.author" :message="ticket" :hasPermission="callPermission" @play="playCall"></OutgoingStatusInfo>
          <CallStatusInfo v-else :message="ticket" :hasPermission="callPermission" @play="playCall"></CallStatusInfo>
        </div>
      </div>
    </td>
    <td :class="getNonReadedClass" v-if="$route.name === 'Billmanager'" class="text-center" style="white-space: nowrap;">
      <div class="d-flex justify-content-center">
        <string-trim :string="ticket.account.name ?? ''" />
      </div>
    </td>
    <td :class="getNonReadedClass" v-if="$route.name === 'Calls'" class="text-center" style="white-space: nowrap;">
      <div>
        {{ makePrettyPhone(ticket.headers?.anumber ?? null) ?? '&mdash;' }}
      </div>
      <div v-if="!ticket.author" class="text-muted" style=" font-size: 11px;">
        <span v-if="answerOperator">
          <popper v-if="callTo" :content="callTo" class="light" placement="top" :arrow="true" :hover="true" :interactive="false" >
            <span>{{answerOperator}}</span>
          </popper>
          <span v-else>{{ answerOperator }}</span>
        </span>
        <span v-else-if="callTo">{{callTo}}</span>
        <span v-else>&mdash;</span>
      </div>
      <div v-else>
        <popper v-if="ticket?.headers?.real_from_phone" :content="outgoingRealFromPhone" class="light" placement="top" :arrow="true" :hover="true" :interactive="false" >
          <span class="text-muted" style=" font-size: 11px;" >{{ getCallAuthor }}</span>
        </popper>
        <span v-else class="text-muted" style=" font-size: 11px;" >{{ getCallAuthor }}</span>
      </div>
    </td>
    <td :class="getNonReadedClass" v-if="$route.name === 'Calls'" class="text-center" style="white-space: nowrap;">
        <div v-if="ticket.author">Исходящий</div>
        <div v-else>Входящий</div>
    </td>
    <td :class="getNonReadedClass" v-else-if="$route.name !== 'Billmanager'" class="text-center" style="white-space: nowrap;" >
      <div v-if="isEmailDefined" class="d-flex justify-content-center">
        <string-trim :string="ticket.person.email" />
      </div>
    </td>

    <td :class="getNonReadedClass" v-if="$route.name === 'Unsorted'" class="text-center" style="white-space: nowrap;" >
      <div v-if="isRecipientDefined" class="d-flex justify-content-center">
        <string-trim :string="getRecipient" />
      </div>
    </td>
    <td :class="getNonReadedClass" v-if="showProjectCell" class="text-center" >{{ticket?.project?.name}}</td>
  </tr>
</template>

<script>
import StringTrim from "../../string/StringTrim";
import {mapGetters, mapState, mapMutations} from "vuex";
import InWorkLine from "./InWorkLine";
import UnconfirmedLine from "./UnconfirmedLine";
import {getDateFormat, isNotificationRead, isTicketReaded} from "../Api/api";
import ProjectLine from "./ProjectLine";
import Tags from "./Tags";
import CallStatusInfo from "../CallsMessage/Source/CallStatusInfo";
import {Phone} from "../../../library/Phone";
import {UserPermission} from "../../../users/UserPermission";
import OutgoingStatusInfo from "../CallsMessage/Source/OutgoingStatusInfo";


export default {
  name: "TicketRow",
  components:{
    UnconfirmedLine,
    ProjectLine,
    InWorkLine,
    'string-trim':StringTrim,
    'tags':Tags,
    CallStatusInfo,
    OutgoingStatusInfo,
  },
  data: function (){
    return {
      "hoveredRow": true,
      "pickRow":false,
      "routeProject": null,
      callPermission: false,
    }
  },
  mounted() {
    this.routeProject = this.$route.params.project;
    this.getCallPermission();
  },
  props:{
    "ticket":{
      type:Object,
      require:true,
    },

  },

  watch:{
  },

  methods:{
    messageCreated: function (date){
      return getDateFormat(date , "YYYY-MM-DD HH:mm");
    },
    makePrettyPhone: function (val) {
      let result = '';
      try {
        result = Phone.makePretty(val);
      } catch (error) { }
      return result ? result : null;
    },
    playCall(link) {
      if (this.getListenLink === link) {
        this.setListenLink(null);
      }
      this.$nextTick(() => {
        this.setListenLink(link);
      });
    },
    getCallPermission() {
      if (this.$route.name === 'Calls' && typeof this.ticket.attachments !== 'undefined' &&
          this.ticket.attachments !== null &&
          this.ticket.attachments.length > 0) {
        UserPermission.can(typeof this.ticket.project  !== 'undefined' &&
          this.ticket.project != null ? this.ticket.project.name + ".callrecord" : "system.callrecord").then(() => {
          this.callPermission = true;
        }).catch(() => {
          this.callPermission = false;
        });
      }
    },
    ...mapMutations({
      "setListenLink": "tickets/setListenLink",
    })
  },
  computed:{
    getCallAuthor() {
      let result = '';
      if(this.ticket.author.name) {
        result = this.ticket.author.name + ' (' + this.ticket.author.login + ')';
      } else {
        result = this.ticket.author.login;
      }
      return result;
    },
    isRecipientDefined(){
      return typeof this.ticket.lastMessage !== 'undefined' && typeof this.ticket.lastMessage[0] !== 'undefined'  && this.ticket.lastMessage[0].headers?.to !== 'undefined'
    },

    outgoingRealFromPhone(){
      return this.makePrettyPhone(
          this.ticket?.headers?.real_from_phone
      ) + ( this.ticket?.headers?.real_manager_ext ? "#" + this.ticket?.headers?.real_manager_ext : "");
    },

    getRecipient(){
      return typeof this.ticket.lastMessage !== 'undefined' ? this.ticket.lastMessage[0]?.headers?.to : null;
    },

    isEmailDefined(){
      return typeof this.ticket.person !== 'undefined' && typeof this.ticket.person.email !== 'undefined' && this.ticket.person.email !== null
    },

    isNeedProjectLine(){
      return  typeof this.ticket.assigned_projects !== 'undefined' && this.ticket.assigned_projects != null && this.ticket.assigned_projects.length > 0;
    },

    isNeedTags() {
      return typeof this.ticket.tags !== 'undefined' && this.ticket.tags.length > 0;
    },

    hasUnconfirmed() {
      return typeof this.ticket.unconfirmed !== 'undefined' &&
             Array.isArray(this.ticket.unconfirmed) &&
             this.ticket.unconfirmed.length;
    },

    isInWork(){
      return typeof this.ticket.assigned !== 'undefined' && this.ticket.assigned !== null
    },
    isSelected(){
      return typeof this.ticketsState.ticketSelectedControl.objects[this.ticket._id] !== 'undefined';
    },
    isLoading(){
      let isLoading = false;
      if(typeof this.ticketsState.ticketLoadingControl.objects[this.ticket._id] !== 'undefined' && typeof  this.ticketsState.ticketLoadingControl.objects[this.ticket._id].loading !== 'undefined'){
        isLoading = this.ticketsState.ticketLoadingControl.objects[this.ticket._id].loading;
      }

      return isLoading
    },

    isTicketReaded() {
      let isRead;
      if(this.$route.name === 'Calls' && this.ticket.type === 'call') {
        isRead = !this.isCallUnanswered;
      } else if (
          this.ticket.type === 'notification' ||
          typeof this.ticket.notification !== 'undefined' && this.ticket.notification
      ){
        isRead = isNotificationRead(this.ticket, window.userId);
      } else {
        isRead = isTicketReaded(this.ticket, window.userId);
      }
      return isRead;
    },

    isCallUnanswered() {
      let unanswered;

      if(this.ticket.type === 'call' && typeof this.ticket.headers?.operator === 'undefined' &&
        (typeof this.ticket.author === 'undefined' || this.ticket.author === null) &&
        (this.ticket.headers?.callback !== true)) {
        unanswered = true;
      } else {
        unanswered = false;
      }

      return unanswered;
    },

    searchAlias() {
      return typeof this.$route?.query?.searchAlias === 'string' && this.$route?.query?.searchAlias.trim() !== '' ?
          this.$route?.query?.searchAlias.trim() :
          '';
    },

    currentFolder() {
      return this.searchAlias ? this.searchAlias : this.getSelectionControl.filter;
    },

    trClass() {
      return {
        "favorite": this.ticket.favorite && !['favorite', 'Trash'].includes(this.currentFolder),
        "hoveredRow" : !this.isSelected,
        "pickRow" : this.isSelected,
        "loadingClass": this.isLoading,
        "text-muted":this.isLoading,
        "alert-warning": this.isCallNotCompleted,
      }
    },
    getNonReadedClass() {
      return {
        "notReaded": !this.isTicketReaded && (this.ticket?.new !== true && this.$route.name !== 'Trash' || this.$route.name === 'Trash') && this.$route.name !== 'Calls',
        "not-answerd-call": this.isCallMissed && this.$route.name === 'Calls',
        'new-ticket': this.ticket?.new === true && !['Calls', 'Trash'].includes(this.$route.name) ,
        'ticket-in-work': this.isInWork && this.$route.name !== 'Calls',
      }
    },
    answerOperator() {
      let result = null;
      if (typeof this.ticket?.headers?.operator?.name !== 'undefined') {
        let slug = '';
        if (typeof this.ticket?.headers?.operator?.slugList[0] !== 'undefined') {
          slug = " (" + this.ticket.headers.operator.slugList[0].toLowerCase() + ")";
        }
        result = this.ticket.headers.operator.name + slug;
      }
      return result;
    },
    callTo:function (){
      return typeof this.ticket?.headers?.number ?
          Phone.makePretty(this.ticket?.headers?.number) + (this.ticket?.headers?.code ?"#" + this.ticket?.headers?.code : "" ) :
          null;
    },
    isCallMissed() {
      let result = false;
      if (this.$route.name === 'Calls') {
        if (
            (typeof this.ticket.author === 'undefined' || !this.ticket.author) &&
            typeof this.ticket?.headers?.operator === 'undefined' &&
            (typeof this.ticket?.headers?.answered_operators === 'undefined' || this.ticket?.headers?.answered_operators.length === 0) &&
            (typeof this.ticket?.headers?.callback === 'undefined' || this.ticket?.headers?.callback != true)
        ) {
          result = true;
        }
      }
      return result;
    },
    isCallNotCompleted() {
      let result = false;
      if (this.$route.name === 'Calls') {
        if (typeof this.ticket?.headers?.completed === 'undefined' || !this.ticket.headers.completed) {
          result = true;
        }
      }
      return result;
    },
    showProjectCell() {
      return this.$route.params.project === 'all' || ['Recent', 'Competitor'].includes(this.$route.name);
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getListenLink": "tickets/getListenLink",
      "getSelectionControl": "leftMenu/getSelectionControl",
    }),
  }
}
</script>

<style scoped>
.row-date {
  min-width: 132px;
  white-space: nowrap;
}
.additional-info {
  font-size:10px;
}
.projectPaddingsOne{
  padding: 0 1px 0 2px;
}
.projectPaddingsTwo{
  padding-right: 1px;
}
.notReaded{
  font-weight: bold;
}
.hoveredRow:hover {
  background: #ecf3ff;
  --bs-table-bg: #ecf3ff;
}
.pickRow{
  background: #c5ffbb;
  --bs-table-bg: #c5ffbb;
}
.favorite{
  background: #fffa9d;
  --bs-table-bg: #fffa9d;
}
.favorite.pickRow{
  background: #9fff8f;
  --bs-table-bg: #9fff8f;
}
.ticket-in-work {
  color: #a6a5a5 !important;
}
.new-ticket {
  color: #dc3545 !important;
  font-weight: bold;
}
.not-answerd-call{
  font-weight: 700;
  color: rgb(220,53,69) !important;
}
.loadingClass{
  cursor: wait !important;
  color: white !important;
  background-color:  #d7d7d7 !important;
}
</style>