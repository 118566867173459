import {getUniqueId} from "../../../library/ID";

export default {

  clearAlerts(state){
    state.alerts = [];
  },

  extendedErrorPush(state  ,  {text = "", zIndex, ashtml = false, timer = 0 , withoutTimer = true}) {
    pushAlert(state, {
      text: text,
      alertStyle: 'text-danger',
      zIndex: zIndex,
      ashtml: ashtml,
      timer: timer,
      withoutTimer: withoutTimer,
    });
  },

  infoPush(state, text, timer, withoutTimer = true) {
    pushAlert(state, {
      text: text,
      timer: timer,
      withoutTimer: withoutTimer
    });
  },

  errorPush(state, text, timer, withoutTimer= true) {
    pushAlert(state, {
      text: text,
      alertStyle: 'text-danger',
      timer: timer,
      withoutTimer:withoutTimer
    });
  },

  htmlErrorPush(state, text, timer, withoutTimer= true) {
    pushAlert(state, {
      text: text,
      alertStyle: 'text-danger',
      ashtml: true, timer: timer,
      withoutTimer:withoutTimer
    });
  },

  htmlSuccessPush(state, text, timer) {
    pushAlert(state, {
      text: text,
      alertStyle: 'text-success',
      ashtml: true, timer: timer
    });
  },
  successPush(state, text, timer) {
    pushAlert( state, {
      text: text,
      alertStyle: 'text-success',
      timer: timer
    });
  },

  errorRemove(state, id){
    let alert = state.alerts.filter(alert => alert.id === id);
    if(typeof alert[0] !== 'undefined') {
      alert[0].show = false;
    }
    if(state.alerts.filter(alert => alert.show === true).length === 0) {
      state.alerts = [];
    }
  },

  addMovingModuleContent(state, string){
      if(typeof string !== 'undefined' && string !== null && string.trim() != ''){
        state.movingModal.value = string.trim();
        state.movingModal.isOpen = true;
      }else {
        state.movingModal.value = '';
        state.movingModal.isOpen = false;
      }


  },

  clearMovingModuleContent(state){
    state.movingModal.value = "";
  },

  closeMovingModule(state){
    state.movingModal.isOpen = false;
  },

 openMovingModule(state){
    state.movingModal.isOpen = true;
  },

  SET_ROUTE(state, {fullPath , name , params ,query , path}) {
    state.route.fullPath  = fullPath;
    state.route.name  = name;
    state.route.params  = params;
    state.route.query  = query;
    state.route.path  = path;
  },

  setRates(state, rates) {
    state.rates = rates
  },

  setActiveWindow(state, window) {
    state.activeWindows.push(window);
  },
  removeActiveWindow(state, window) {
    state.activeWindows = state.activeWindows.filter(item => item !== window);
  },
  clearActiveWindows(state) {
    state.activeWindows = [];
  },
  setUserSettings(state, value){
    state.userSettings = value;
  },
  updateUserSettings(state, settings){
    for(let key in settings){
      state.userSettings[key] = settings[key];
    }
  },
}

const pushAlert = function(state, {text = '', ashtml = false, alertStyle, zIndex = 1000, timer = 3500, withoutTimer = false} ) {
  let alert = {
    text: text,
    alertStyle: alertStyle,
    ashtml: ashtml,
    zIndex: zIndex,
    timer: timer,
    id: getUniqueId(),
    show: true,
    withoutTimer:withoutTimer,
  }

  state.alerts.push(alert);
}