<template>
  <tr>
    <th @click="orderBy('updated')" class="text-center" style="width: 179px; cursor: pointer" >Дата</th>
    <th @click="orderBy('name')" class="text-center" style="cursor: pointer; " >Тема</th>
    <th @click="orderBy('person.email')" class="text-center " style="  cursor: pointer; width: 160px;" >Отправитель</th>
    <th  class="text-center " style="  cursor: pointer; width: 160px;" >Получатель</th>
  </tr>
</template>

<script>
export default {
  name: "TableHeadRowsUnsorted",
  props:{
    'orderBy':{
      type:Function,
      require:true,
    }
  }
}
</script>

<style scoped>

</style>