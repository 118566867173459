

const offerFiltersActions = {
    openTableFilters({state, commit, rootGetters}) {
        commit('setShowFilter', true);
    },

    closeTableFilters({state, commit, dispatch}) {
        let query = state.query;
        let isAllEmpty = true;
        for (let el of state.filters) {
            if (el.value.trim() !== '') {
                isAllEmpty = false;
            }
            el.value = '';
            if (typeof query[el.field] !== 'undefined') {
                delete query[el.field];
            }
        }

        if(!isAllEmpty) {
            dispatch('thread/offers/updateOffersList', {}, {root: true});
        }

        state.query = query;
        commit('setShowFilter', false);
    },
}


export default offerFiltersActions;