import {offersRates} from "../../../library/api/OffersRates";
import {CrmApi} from "../../../library/CrmApi";

const ratesActions = {



    loadRates({state , commit, dispatch, getters , rootGetters, rootState}, {}){
        return new Promise((resolve , reject)=>{

            offersRates.getList().then((result) =>{
                commit('setRatesStatistic' , result.data);
                resolve(result)
            }).catch((error)=>{
                 commit('errorPush', CrmApi.getErrorMessage(error), {root: true});
                reject(error)
                }).finally(()=>{
            });
        });
    }

}

export default ratesActions;