import {CrmApi} from "../../../library/CrmApi";

export default {
  toLastSelect(state , value){
    state.toLastSelect = value;
  },
  clearList(state){
    state.ticketList = [];
    state.paginate = {};
  },

  addToSelectedControl (state,  ticket) {
    if(typeof ticket?._id !== 'undefined') {
      if(typeof state.ticketSelectedControl.objects[ticket._id] === 'undefined' && state.ticketLoadingControl.length === 0 ){
        state.ticketSelectedControl.lastSelectedElem = ticket._id;
        state.ticketSelectedControl.length++;
        state.ticketSelectedControl.objects[ticket._id] = ticket;
      }
    }
  },
  unSelectTicket(state,  ticket){
    if(typeof ticket !== 'undefined' &&ticket != null && typeof ticket._id !== 'undefined' &&  ticket._id != null && state.ticketLoadingControl.length === 0 && typeof state.ticketSelectedControl.objects[ticket._id] !== 'undefined' ){
      delete state.ticketSelectedControl.objects[ticket._id];
      state.ticketSelectedControl.length--;
      if(state.ticketSelectedControl.lastSelectedElem === ticket._id){
        state.ticketSelectedControl.lastSelectedElem = Object.keys(state.ticketSelectedControl.objects)[0];
      }
    }
  },

  updateSelected(state, {ticket = null, callback = null}) {
    let update = (id, ticket) => {
      state.ticketSelectedControl.objects[id] = ticket;
      if(callback !== null) {
        callback();
      }
    }

    if(ticket === null) {
      let id = Object.entries(state.ticketSelectedControl.objects)[0][0];
      return CrmApi.getTicketList( {_id: id} ).then((result) => {
        update(result.data.data[0]._id, result.data.data[0]);
      })
    }

    if(state.ticketLoadingControl.length === 0 && typeof state.ticketSelectedControl.objects[ticket._id] !== 'undefined') {
      update(ticket._id, ticket);
    }
  },

  setSelectedControl(state , value){
    state.ticketSelectedControl = value;
  },

  clearSelect(state){
    state.ticketSelectedControl = {
      length:0,
      objects:{},
      lastSelectedElem:null,
    }
  },

  addLastSelectId(state , id){
    state.lastSelectedIds.ids.push(id)
  },
  setTryToScrollAfterTick(state , value){
    state.tryToScrollAfterTick = value;
  },
  resetLastSelectedIds(state){
    state.lastSelectedIds.ids = [];
    state.lastSelectedIds.lastSelectedPosition = null;
  },
  setLastSelectedPosition(state){
    for(let key in state.ticketList){
      let ticket = state.ticketList[key];
      if(typeof state.ticketSelectedControl.objects[ticket._id] !== 'undefined'){
        state.lastSelectedIds.lastSelectedPosition = key;
        break;
      }
    }

  },

  clearLoading(state){
    state.ticketLoadingControl = {
      length:0,
      objects:{},
      loadingAll: false,
    }
  },

  addLoading(state, {id , loading}){
    if(loading){
      if(typeof state.ticketLoadingControl.objects[id] === 'undefined'){
        state.ticketLoadingControl.objects[id] = {}
        state.ticketLoadingControl.length++;
      }
      state.ticketLoadingControl.objects[id].loading = loading;
    }else {
      if(typeof state.ticketLoadingControl.objects[id] !== 'undefined'){
        state.ticketLoadingControl.length--;
      }
      delete state.ticketLoadingControl.objects[id];
    }
  },

  setLoadingAll(state, value){
    state.ticketLoadingControl.loadingAll = value;
  },



  setTickets(state, ticketList){
    state.ticketList = ticketList;
  },

  setPagination(state, {page , onpage ,  total , paginator_links}){
    state.paginate.page = page;
    state.paginate.onpage = onpage;
    state.paginate.total = total;
    state.paginate.paginator_links = paginator_links;
  },

  setTicketListAutoUpdate(state , f){
    clearTimeout(state.autoupdate);
    state.autoupdate = setTimeout(f , 10000)
  },

  resetTicketListAutoUpdate(state ){
    if(state.autoupdate){
      clearTimeout(state.autoupdate);
    }
  },

  setAutoUpdateTrigger(state, value){
    state.autoUpdateTrigger = value;
  },

  escapeRefresh(state, value){
    state.escapeRefresh = value;
  },

  setListenLink(state, link) {
    state.listenLink = link;
  },

}
