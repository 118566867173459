import findAndReplaceDOMText from "findandreplacedomtext";

export function addParamToUrl(key , value){
    let urlParams = new URLSearchParams(window.location.search);

    urlParams.set(key, value);

    window.history.replaceState(null, null, window.location.origin +window.location.pathname + "/?" + urlParams);
}

export function prepareObjectToUrl(params) {
    let result = "";
    if (typeof params === 'object' && params !== null) {
        result = "?" +
            Object.keys(params)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
                .join('&');
    }
    return result;
}

export function hasChanged(value, from) {
    value = value ?? '';
    from = from ?? '';
    let result = false;
    if (isEmpty(value) && isEmpty(from)) {
        result = false;
    } else if (value === from) {
        result = false;
    } else if (_.isArray(value)) {
        if (_.isArray(from)) {
            if (value.length !== from.length) {
                result = true;
            } else {
                value.some((val, index) => {
                    if (hasChanged(val, from[index])) {
                        result = true;
                        return true;
                    }
                });
            }
        } else {
            result = true;
        }
    } else if (_.isObjectLike(value)) {
        if (_.isObjectLike(from)) {
            _.uniq(Object.keys(value).concat(Object.keys(from))).some((key) => {
                if (hasChanged(value[key], from[key])) {
                    result = true;
                    return true;
                }
            });
        } else {
            result = true;
        }
    }else if (_.isString(value) && _.isString(from) && value.trim() !== from.trim()) {
        result = true;
    } else if (isPrimitive(value) && isPrimitive(from) && value !== from) {
        result = true;
    }
    return result;
}

const isEmpty = item => {
    let result = false;
    if (typeof item === 'undefined' || item === null || typeof item === 'string' && item.trim() === '') {
        result = true;
    } else if (_.isObjectLike(item)) {
        result = Object.keys(item).every(key => isEmpty(item[key]));
    } else if (_.isArray(item)) {
        result = item.every(v => isEmpty(v));
    }
    return result;
}

const isPrimitive = o => {
    switch (typeof o) {
        case "object": {
            return false;
        }
        case "function": {
            return false;
        }
        default: {
            return true;
        }
    }
};

export const baseHighlight = (el, searchString, selector = null) => {
    el = $(el);
    if (selector) {
        el = $(el).find(selector);
    }
    let regexp = new RegExp(_.escapeRegExp(searchString).replace(/\s+/g, '\\s+'), 'gi');
    el.each((i, value) => {
        findAndReplaceDOMText(value, {
            "find": regexp,
            "wrap": "mark",
            "wrapClass": "highlight-text",
        });
    })
}

export const popperHighlight = (el, searchString) => {
    ['.trim', '.hint-popper'].forEach(item => baseHighlight($(el).find(item), searchString));
}