<template>
  <div class="conditions">
    <div class="row" v-for="(condition, index ) in conditions">
      <div class="col-sm-3 form-style" >
        <select name="conditions.target"  v-model="condition.target" id="conditions.target" class="form-select form-select-sm"  >
          <option value="headers.subject">Тема</option>
          <option value="body">Тело</option>
          <option value="headers.from">От</option>
          <option value="headers.to">Кому</option>
          <option value="headers">Заголовки</option>
        </select>
      </div>
      <div class="col-sm-3 form-style"  >
        <select   name="conditions.type"  v-model="condition.type"   id="conditions.type"  class="form-select form-select-sm" >
          <option v-for="value in getValues(condition.target)" :value="value.key" >{{value.label}}</option>
        </select>
      </div>
      <div class="col-sm-4 form-style"   >
        <input name="conditions.value"  v-model="condition.value"   id="conditions.value"  class="form-control form-control-sm" type="text"  >
      </div>
      <div class="col-sm-2  quantity buttons_added">
        <input v-on:click="addCondition(condition)" type="button" value="+"  class="plus">
        <input :disabled="conditions.length <= 1" v-on:click="spliceCondition(index)"  type="button" value="-" class="minus">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConditionSelect",
  data:function (){
    return {
      values: [
        {
          label:"содержит",
          key:"~",
        },
        {
          label:"не содержит",
          key:"!~",
        },
        {
          label:"совпадает с",
          key:"=",
        },
        {
          label:"не совпадает с",
          key:"!=",
        },
        {
          label:"начинается с",
          key:"^~",
        },
        {
          label:"заканчивается на",
          key:"~^",
        },
      ]
    }
  },
  props:{
    'conditions':{
      type:Array,
      require:true,
    }
  },
  methods:{
    addCondition:function (old = null){
      let condition = {
        target: old?.target ?? "headers.subject",
        type: old?.type ?? "~",
        value: "",
      }
      this.conditions.push(condition);
    },
    spliceCondition:function (index){
      this.conditions.splice(index, 1);
    },
    getValues: function (type) {
      return type === 'headers' ? [this.values[0]] : this.values;
    }
  }
}
</script>

<style scoped>
@import '../filters.scss';
</style>