<template>
  <div class="mb-3">
    <h6 :class="{'d-inline-block': patterns.length}">Маска Email, домен или его часть для попадания писем в проект</h6>
    <small class="btn btn-sm text-primary pull-right pt-0" @click="toggleCheckField" v-if="patterns.length ?? false">{{checkBtnText}}</small>
    <div class="clearfix"></div>
    <ul class="list-group">
      <li class="list-group-item" v-if="checkField.show">
        <div class="input-group">
          <input type="text" class="form-control" placeholder="Введите email для проверки совпадения с масками" v-model="checkField.value">
        </div>
      </li>
      <li class="list-group-item" v-else>
        <div class="input-group">
          <input type="text" class="form-control" :class="{'is-invalid': error}" placeholder="*@ip*.ru" @input="validation" v-model="pattern">
          <popper content="Добавить паттерн" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <button class="btn btn-outline-success" type="button" id="button-addon2" :disabled="isAddDisabled" @click="addPattern">
              <i class="fa fa-plus-square pe-1" aria-hidden="true"></i> Добавить
            </button>
          </popper>
          <div class="invalid-feedback" v-if="error">{{error}}</div>
          <div class="invalid-warning text-warning" v-if="pattern">Для добавления маски в проект необходимо нажать "Добавить"</div>
        </div>
      </li>

      <li class="list-group-item" v-for="pattern in patterns">
        <span :class="{'text-success': checkPattern(pattern),'text-danger':checkField.show &&!checkPattern(pattern)}">{{pattern}}</span>
        <div class="pull-right">
          <i class="btn fa fa-times p-1 px-2" style="color: red;" @click="confirmDelete ? confirmDeletePattern(pattern) : deletePattern(pattern)"></i>
        </div>
      </li>
    </ul>
  </div>
  <modal-window v-if="deleteModal.show" @close="hideConfirmModalDeletePattern" @success="deletePattern(deleteModal.pattern, true)" ok-button-style="btn-danger">
    <template v-slot:header>
      <h5 class="modal-title text-danger">Удаление паттерна</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите навсегда удалить паттерн {{deleteModal.pattern}}?</span>
    </template>
  </modal-window>
</template>

<script>
import ModalWindow from "../tiketMenu/components/ModalWindow";

export default {
  name: "EmailPatterns",

  emits: ['update'],

  components: {
    ModalWindow
  },

  props: {
    savedPatterns: {
      type: Array,
      default: [],
    },
    confirmDelete: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      error: null,
      pattern: '',
      patterns: [],

      checkField: {
        show: false,
        value: '',
      },

      deleteModal: {
        show: false,
        pattern: null,
      },
    }
  },

  computed: {
    isAddDisabled: function() {
      return !this.pattern.trim() || this.error || this.pattern.length < 3;
    },

    checkBtnText: function() {
      return this.checkField.show ? 'добавить паттерн' : 'проверить email';
    },
  },

  methods: {
    addPattern: function() {
      this.patterns.push(this.pattern);
      this.pattern = '';
      this.$emit('update', this.patterns);
    },

    confirmDeletePattern: function(pattern) {
      this.deleteModal.show = true;
      this.deleteModal.pattern = pattern;
    },

    hideConfirmModalDeletePattern: function() {
      this.deleteModal.pattern = null;
      this.deleteModal.show = false;
    },

    deletePattern: function(pattern, afterConfirm = false) {
      if(afterConfirm) {
        this.hideConfirmModalDeletePattern();
      }

      this.patterns = this.patterns.filter(p => p !== pattern);
      if(this.patterns.length === 0) {
        this.checkField.show = false;
      }
      this.$emit('update', this.patterns);
    },

    validation: function() {
      let isValid = false;
      if(this.pattern.trim() !== '') {
        isValid = true;
        try {
          new RegExp(this.formatPseudoRegex(this.pattern));
        } catch(e) {
          isValid = false;
        }
      }

      this.error = (isValid || this.pattern.trim() === '') ? null : 'Неверный синтаксис';
    },

    toggleCheckField: function() {
      this.checkField.show = !this.checkField.show;

      if(!this.checkField.show) {
        this.checkField.value = '';
      }
    },

    checkPattern: function(pattern) {
      let check = false;

      if(this.checkField.show && this.checkField.value !== '') {
        if(this.checkField.value.match(new RegExp(this.formatPseudoRegex(pattern), 'i'))) {
          check = true;
        }
      }

      return check;
    },

    formatPseudoRegex: function(pseudoRegex) {
      return '^' + pseudoRegex.replaceAll('*', '.*') + '$';
    }
  },

  watch:  {
    'savedPatterns' () {
      this.patterns = this.savedPatterns;
    }
  },
}
</script>

<style scoped>
.invalid-warning{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
</style>