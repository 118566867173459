
export {UserPermission};
import {CrmApi} from "../library/CrmApi";

class UserPermission{
    static permissionsList = {
    };
    static permissionsPromises = {
    };

    static addPermission(permission, value){
        this.permissionsList[permission] = value;
    }

    static async canTicket(ticket, permission, allProjects = false){
        let projects = [];

        if(typeof ticket?.project?.name === 'string') {
            projects.push(ticket.project.name);
        } else {
            projects.push('system');
        }
        if(allProjects && Array.isArray(ticket?.assigned_projects ?? null)) {
            ticket.assigned_projects.forEach(project => projects.push(project.name));
        }

        let list = projects.map(project => `${project}.${permission}`);
        let result;

        if(allProjects) {
            result = new Promise((resolve, reject) => {
                        this.can(list)
                            .catch(() => {})
                            .finally((result) => {
                                if (list.some(permission => this.permissionsList[permission])) {
                                    resolve(true);
                                } else {
                                    reject(false);
                                }
                            });
                    });
        } else {
            result = this.can(list);
        }

        return result;
    }

    static async can( permission ) {
        let result = await this.getPermissions(permission);
        let list = [];
        if (typeof permission === "string") {
            list.push(permission);
        } else {
            list = permission;
        }

        for(let p in result) {
            if(
                list.indexOf(p) !== -1 &&
                result[p] === false
            ) {
                throw {message:"Permission denied"};
            }
        }
        return result;
    }

    static async getPermissions( permission ){
        let list = [];
        if (typeof permission === "string") {
            list.push(permission);
        } else {
            list = permission
        }
        let toLoad = [];
        if (Array.isArray(list)) {
            list.forEach((value, key)=>{
                if(typeof this.permissionsList[value] === 'undefined'){
                    toLoad.push(value);
                }
            });
        }else {
            throw {message:"Internal error"}
        }

        return this.checkPermissions(toLoad, this.permissionsList , list);
    }

    static async checkPermissions(toLoad, permissionsList , toCheck) {
        if(toLoad.length > 0){
            let data = {
                "permissionsList" : [],
            };
            let awaitList = [];
            toLoad.forEach((permission)=>{
                if(
                    typeof this.permissionsPromises[permission] !== "undefined" &&
                    this.permissionsPromises[permission] != null
                ){
                    awaitList.push(this.permissionsPromises[permission]);
                } else {
                    data.permissionsList.push(permission);
                }
            })


            if( data.permissionsList.length > 0 ) {
                let loadPermission = CrmApi.call("/can/", data).then(function (result) {
                        for (var key in result.data) {
                            if (result.data.hasOwnProperty(key)) {
                                permissionsList[key] = result.data[key];

                            }
                        }
                    }
                ).catch((reason) => {
                    console.warn(reason)
                }).finally(() => {
                    data.permissionsList.forEach((permission) => {
                        this.permissionsPromises[permission] = null;
                    })
                });

                data.permissionsList.forEach((permission)=>{
                    this.permissionsPromises[permission] = loadPermission;
                });
                awaitList.push(loadPermission)
            }

            for( let loading of awaitList ){
                await loading;
            }
        }
        let isPermissionDenied = false;
        toCheck.forEach((value, key)=>{
            if(typeof permissionsList[value] === 'undefined' ||  !permissionsList[value]){
                isPermissionDenied = true;
            }
        });
        return permissionsList;
    }



}
