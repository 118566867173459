<template>
  <div>
  <div style="display: flex; align-items: baseline;">
    <div>
      <span v-if="loading">Загрузка...</span>
      <price
          v-else
          :price="modelValue.price"
          quantity="all"
          :currency="modelValue.currency"
          :show-label="false"
          :subnets="subnetsToUse"
          :price1="price1"
          @update="update"
          input-class="price-input form-control form-control-sm me-2"
          custom-div-class="price-div"
          form-control=""
          custom-select-class="price-select form-control form-control-sm"
          :clipboard="true"
          :precision-all="2"
          :rates-data="rates"
          :one-price="isAs"
          :select-text="selectText"
          :recalculate-price="false"
          :focus-out-cancel="false"
      ></price>
    </div>
    <div v-if="showDescription" style="margin-left: 5px;">
      <select  class="form-control form-control-sm" style="text-align:center" @keydown.enter.ctrl.prevent.stop="$emit('enterCtrl')" v-model="description">
        <option  value="" ></option>
        <option value="i7" >i7</option>
        <option value="Atex">Atex</option>
        <option value="IP" >ИП</option>
        <option value="SDNS">SDNS</option>
        <option value="Netex">Netex</option>
        <option value="Crypto">Crypto</option>
        <option value="PayPal">PayPal</option>
        <option value="Cash">Cash</option>
        <option value="KSK">KSK</option>
        <option value="WM">WM</option>
      </select>
    </div>
  </div>
  </div>
</template>

<script>
import Price from "../../tiketMenu/components/Params/Product/Field/Price";
import {IPv4Net} from "../../../library/IPv4Net";
import {CrmApi} from "../../../library/CrmApi";
import {mapGetters} from "vuex";
import EditableText from "../../Editable/EditableText";

export default {
  name: "PriceEdit",
  emits:['update:modelValue', 'priceChange' , 'priceEmpty' , 'enterCtrl'],
  props:{
    showDescription:{
      type:Boolean,
      default:true,
    },
    modelValue:{},
    subnets:{},
    inputClass:{
      type:String,
      default:'',
    },
    loading:{
      type:Boolean,
      default: false
    },
    selectText: {
      type: Boolean,
      default: false,
    }
  },
  components:{
    EditableText,
    Price
  },
  data:function (){
    return {
      oldValues:'',
    }
  },
  mounted() {
    let modelValue = _.mapValues(this.modelValue, v => _.isNil(v) ? '' : v)
    this.$emit('update:modelValue', modelValue)
    this.oldValues = JSON.stringify(modelValue);
  },
  methods:{

    isHasChange:function (){
      return JSON.stringify(this.modelValue) !== this.oldValues
    },
    update(values, afterSave , failSave ){
      if(typeof afterSave === 'function') {
        afterSave();
      }
      if(this.modelValue.currency !==  values.currency ||  values.price !== this.modelValue.price  ||
          values.quantity === 'single' ){
          this.$emit('priceChange')
      }
      if(values.price === ''){
        this.$emit('priceEmpty')
      }

      let params = {
        comment:this.comment,
        description:this.description
      };

      params.currency = values.currency
      if(values.quantity === 'single'){
        params.price = values.price*this.ipCount;
      }else {
        params.price = values.price;
      }

      this.$emit('update:modelValue', params)
    }
  },
  computed:{
    comment:{
      get(){
        return this.modelValue?.comment ?? '';
      },
      set(value){
        this.modelValue.comment = value;
      }
    },
    description:{
      get(){
        return this.modelValue?.description ?? '';
      },
      set(value){
        this.modelValue.description = value;
      }
    },
    ...mapGetters({
      'getRates':'offerHistory/getRates',
      'isAs':'offerHistory/addEdit/isAs'
    }),
    rates:function (){
      if(this.getRates !== null){
        let obj = {};
        for (let key in this.getRates ){
          if(this.getRates[key]?.Value){
            obj[key] = this.getRates[key].Value;
          }

        }
        return obj;
      }
        return null;
    },
    subnetsToUse:function () {
      let subnetsToUse = [];
      for (let subnet of this.subnets){
        if(subnet.subnet != ''){
          let ip = new IPv4Net(subnet.subnet)
          if(ip.isIp()){
            subnetsToUse.push(subnet)
          }
        }
      }
      return subnetsToUse;
    },
    ipCount:function (){
      let count = 0;
      for (let subnet of this.subnetsToUse){
        if(subnet.subnet != ''){
          let ip = new IPv4Net(subnet.subnet)
          if(ip.isIp()){
            count += ip.count();
          }
        }

      }
      return count;
    },
    price1:function (){
      if(this.modelValue.price === ''){
        return '';
      }
      if( this.modelValue?.type === "price1" ){
        this.modelValue.price *= this.ipCount;
        this.modelValue.type = "";
        this.$emit('update:modelValue', this.modelValue)
      }
      let price1 = this.modelValue.price / this.ipCount;
      return ( typeof price1 ==='undefined' || isNaN(price1) || price1 == null) ? '' : price1
    }
  }
}
</script>

<style scoped>
:deep(.small){
  width: 59%;
  max-height: 23px;
  box-shadow:none !important;
}
:deep(.price-input){
  width: 8rem;
}
:deep(.price-div){
  display: inline-flex;
}
:deep(.price-select){

}
</style>