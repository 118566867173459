<template>
  <div v-if="buyprice" style="font-size: 14px" class="price-class">
    <price
        :not-empty-field="true"
        quantity="all"
        :price="price"
        :currency="currency"
        :show-label="false"
        :subnets="subnets"
        :price1="price1"
        :currency-to-show="currentCurrency"
        :can-change="canChange"
        :rates-data="rates"
        :absolute="true"
        :clipboard="true"
        @update="update"
        :one-price="isOnePrice"
        :content-class="classPrice"
        :span-class="spanClass"
        :without-currency-space="withoutCurrencySpace"
        :popper-placement="popperPlacement"
    ></price>
    <span style="font-size: 12px;" class="text-muted" v-if="buyprice?.description" >{{descriptionValue}}</span>
  </div>
</template>

<script>
import Price from "../../tiketMenu/components/Params/Product/Field/Price";
import {mapActions, mapGetters} from "vuex";
import {CrmApi} from "../../../library/CrmApi";
export default {
  name: "NominalPrice",
  components: {Price},
  props:{
    buyprice:{
      type:Object,
      require:true,
    },
    ipCount:{
      type:Number
    },
    subnets:{

    },
    isOnePrice:{
      default: false,
    },
    historyObjectId:{
      require: true,
    },
    withoutCurrencySpace:{
      type: Boolean,
      default:false
    },
    nameField:{
      type:String,
      require:true
    },
    rates: {
      type: Object,
      default: null,
    },
    classPrice:{
      type:String,
    },
    popperPlacement: {
      type: String,
      default: 'top',
    },
  },
  data:function (){
    return {
      spanClass:'',
    }
  },
  mounted() {

    this.spanClass = ' displayFlex '

  },
  methods:{
    getDescriptionValues(){
      return {
        'i7':'i7',
        'Atex':'Atex',
        'IP':'ИП',
        'SDNS':'SDNS',
        'Netex':'Netex',
        'Crypto':'Crypto',
        'PayPal':'PayPal',
        'Cash':'Cash',
      };
    },
    update(values, afterSave = null, failSave = null ){
      if(typeof afterSave === 'function') {
        afterSave();
      }



      if(!this.canChange){
       return;
     }
      let params = {};
      params[this.nameField] = {};
      params[this.nameField].currency = values.currency
      if(values.quantity === 'single'){
        params[this.nameField].price = values.price*this.ipCount;
      }else {
        params[this.nameField].price = values.price;
      }
      this.editObject({id:this.historyObjectId, params:params})
    },
    ...mapActions({
      'editObject':'offerHistory/editObject'
    })
  },
  computed:{

    ...mapGetters({
      'currentCurrency':'offerHistory/getCurrentCurrency',
      'canChange':'offerHistory/canChange',
      'isScreenSmall':'isScreenSmall',
    }),
    currency:function (){
      return this.buyprice?.currency
    },
    price:function (){
      return this.buyprice?.price ?? 0;
    },
    price1:function (){
      return this.price/this.ipCount
    },
    descriptionValue:function (){
        let v = this.buyprice.description;

        if( this.getDescriptionValues()[this.buyprice.description]){
          v = this.getDescriptionValues()[this.buyprice.description]
        }
        return v;
    }
  }
}
</script>

<style scoped>
:deep(.displayFlex){
  display: flex;
  justify-content: center;
}
</style>