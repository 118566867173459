const getters = {

    getTopLevel:(state) =>{
        return state.topLevel
    },
    getSelectionControl:(state) =>{
        return state.selectionControl
    },
    getCurrentRouteParams:(state) =>{
        let obj = {
            project:state.selectionControl.projectName,
            filter:state.selectionControl.filter,
        }
        return obj;
    },

    getShowAll: (state) => state.showAll,
}

export default getters