const mutations = {
    setShowCreateForm(state, value) {
        state.showCreateForm = value;
    },

    setSearchString(state, text) {
        state.searchTicketsString = text;
    },

    setSelectedProject(state, project){
        state.selectedProject = project;
    },

    setProjectsCanAnswer(state, projects) {
        state.projectsCanAnswer = projects;
    },

    setProjectsCanCreate(state, projects) {
        state.projectsCanCreate = projects;
    },

    setTickets(state, tickets) {
        state.ticketList = tickets;
    },

    setLoading(state, value) {
        state.isLoading = value;
    },


    setForceCreate(state, value) {
        state.forceCreate = value;
    },

    setPagination(state, {page, onpage, total, paginator_links}) {
        state.paginate.page = page;
        state.paginate.onpage = onpage;
        state.paginate.total = total;
        state.paginate.paginator_links = paginator_links;
    },

    setIsCreatingTicket(state, value) {
        state.isCreatingTicket = value;
    },

    setProducts(state, value) {
        state.products = value;
    },

    setProductsLoading(state, value) {
        state.isProductsLoading = value;
    },
}

export default mutations;