<template>
<div>
  <div>
    <Multiselect ref="msel"
        v-model="ticket"
        label="name"
        :class="{invalid: invalid}"
        :options="async function(query) {
          return await getSearchFunction(query)
        }"
        :filter-results="false"
        :min-chars="1"
        :resolve-on-load="false"
        :delay="600"
        :searchable="true"
        :show-options="!invalid"
        placeholder="Введите название тикета или email клиента"
        noOptionsText="Список тикетов пуст"
        @clear="reset"
        @select="selectTicket"
        @searchChange="parseTicketId"
        @remove="invalid = false"
    >
      <template v-slot:singlelabel="{ value }">
        <div class="multiselect-single-label">
          <div class="multiselect-single-label-inner">
            <div>{{value.name}}</div>
            <div style="font-size: 12px" class="text-muted">{{value.email}}</div>
          </div>
        </div>
      </template>

      <template v-slot:option="{ option }">
        <div>
          <div >{{option.name}}</div>
          <div  class="text-muted">{{option.email}}</div>
        </div>
      </template>
      <!--template v-slot:afterlist>
        <div v-show="showElem > 0">
            И еще {{showElem}} тикетов.
        </div>
      </template-->
    </Multiselect>
    <div class="invalid-feedback" :class="{'d-block': invalid}">
      Нельзя переносить сообщения в тот же тикет
    </div>
  </div>
</div>
</template>

<script>
import Multiselect from '@vueform/multiselect'
import {getTicketListForSearch} from "../../../ticket/Api/api";
export default {
  name: "SearchTicket",
  components: {
    Multiselect,
  },
  emits:[
      "selectElement",
  ],
  props:{
    'show':{
      type:Boolean
    },
    selectedObjects: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      ticket: "",
      invalid: false,
      showElem:0,
    }
  },
  methods:{
    parseTicketId: function(search) {
      if(typeof search === 'string' && search.indexOf('tickets/') !== -1) {
        search = search.substr(search.indexOf('tickets/') + 8, 24);
        if(search.length === 24) {
          this.$refs.msel.search = search;
        }
      }

      if(this.ticket === null || this.ticket === '') {
        this.validation(search);
      }
    },

    validation(string) {
      this.invalid = (typeof string !== 'string' ||
                      (string.length === 24 && typeof this.selectedObjects[string] !== 'undefined'));
    },

    reset:function (){
      this.invalid = false;
      this.ticket = "";
      this.$emit('selectElement' , "")
    },
    getTotal:function (result){
      this.showElem = result.total - 2;
    },
    getSearchFunction:function(query) {
      return this.invalid ? [] : getTicketListForSearch({search:query, specialRights: 'change'} , this.getTotal );
    },
    selectTicket: function (id, info) {
      this.validation(id);

      if(!this.invalid) {
        this.$emit('selectElement', this.ticket, info.name);
      }
    },
  },
}
</script>
<style src="@vueform/multiselect/themes/default.css"></style>

<style scoped>
.invalid {
  border-color: #dc3545 !important;
  box-shadow: none;
}
.multiselect-single-label-inner {
  max-width: 100%;
}
.multiselect-single-label-inner > div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>