<template>

  <button v-if="collapsed" @click="isCollapsed = !isCollapsed" type="button" class="btn btn-secondary btn-sm col-12 text-start">
    <template v-if="!isCollapsed">Свернуть</template>
    <template v-else>{{oneMessageLineForCollapseButton}}</template>
  </button>

  <template v-if="!collapsed || !isCollapsed">
    <span class="msg-part" ref="msgpart" v-for="part in getMessageParts" v-if="type === 'text' || !asHtml">
      <component :is="part.type" :value="part.value" :message="message" type="text"></component>
    </span>

    <span class="msg-part html-part" ref="msgpart" v-if="type === 'html' && asHtml">
      <m-text :message="message" :type="type" :load-images="loadImages"></m-text>
    </span>
  </template>

</template>

<script>
import MessageEntityText from "./Entity/MessageEntityText";
import MessageEntityReply from "./Entity/MessageEntityReply";

export default {
  name: "MessageText",
  components: {
    "m-reply": MessageEntityReply,
    "m-text": MessageEntityText
  },
  props:{
    message: {
      type: Object
    },

    collapsed: {
      type: Boolean,
      default: false,
    },

    asHtml: {
      type: Boolean,
      default: true,
    }
  },

  data: function() {
    return {
      loadImages: false,
      isCollapsed: false,
    }
  },

  mounted: function() {
    if(this.message.type === 'bounce') {
      this.findBounceErrorMessage();
    }

    if(this.collapsed) {
      this.isCollapsed = true;
    }
  },

  computed:{
    type() {
      return this.message?.bodytype ?? 'text';
    },

    oneMessageLineForCollapseButton() {
      let string = 'Развернуть';
      let foundedString = false;

      if(this.type === 'text' && this.getMessageParts.length) {
        string = this.getMessageParts[0].value;
        foundedString = true;
      } else if(this.type === 'html') {
        string = this.message.body;
        foundedString = true;
      }

      if(foundedString) {
        string = string.replace(/<style[^>]*>.*<\/style>/usg, '')
                       .replace(/<[^>]+>/g, '')
                       .replace(/(\n|  )/g, '')
                       .trim();

        if(string.length > 15) {
          string = string.substr(0, 15) + '...';
        }

        string = '> ' + string;
      }

      if(string.length === 0) {
        string = 'Развернуть';
      }

      return string;
    },

    getText() {
      return this.type === 'text' ? this.message.body : this.convertToText(this.message.body);
    },

    getMessageParts: function (){

      let parts = [];
      let pos = 0;
      let lnPos = 0;
      let replyText = "";
      let currentPart = this.getText;

      do{
        pos = currentPart.search(/^\s*>/m);
        if( pos !== -1 ) {
          parts.push({
            type: "m-text",
            value: currentPart.substr(0, pos).trim()
          });
          replyText = "";
          currentPart = currentPart.substr(pos);
          while (currentPart.search(/^\s*>/) !== -1 ) {
            lnPos = currentPart.search(/\n/);
            if (lnPos !== -1) {
              replyText += currentPart.substr(0, lnPos + 1);
              currentPart = currentPart.substr(lnPos + 1);
            } else {
              replyText += currentPart;
              currentPart = "";
            }
          }
          parts.push({
            type: "m-reply",
            value: replyText
          });
        }
      }while ( pos !== -1 );
      if( currentPart != "" ){
        parts.push({
          type: "m-text",
          value: currentPart.trim()
        });
      }

      return parts;
    }
  },

  methods: {
    convertToText: function(message) {
      let text = message.replace(/<\/?\s*br\s*\/?>/ig, "\n")
                          .replace(/(<\/\s*(div|p)\s*>[^\S\n]*)\n/ig, "$1")
                          .replace(/<\/\s*(div|p)\s*>/ig, "</$1>\n")
                          .replace(/(\n\s*){3,}/ig,"\n\n");
      let result = $("<div>" + text + "</div>");
      result.find('style').remove();
      result.find('script').remove();
      return result.text().replace(/(\n\s*){3,}/g,"\n\n");
    },
    findBounceErrorMessage: function() {
      let em = $(this.$refs.msgpart).find('div');
      let text = em.text();
      let html = em.html();
      let functions = [
        () => {
          if (text.indexOf('said:\n') > 0 && text.indexOf(' - gsmtp')) {
            // gmail bounce
            html = html.replace('said:\n', 'said:\n<span class="bounce-error-text">')
                       .replace(' - gsmtp', '</span> - gsmtp');
            return true;
          }
        },
        () => {
          if (text.indexOf('mx.yandex.ru') > 0) {
            // yandex bounce
            let part = text.match(/RCPT TO:[^\n]+\n\s*(.*)$/s);
            if(part !== null) {
              html = html.replace(part[1], '<span class="bounce-error-text">' + part[1] + '</span>');
              return true;
            }
          }
        },
        () => {
          if (text.indexOf('mxs.mail.ru') > 0) {
            // mail.ru bounce
            let part = text.match(/end of data:\n\s*(.*)$/s);
            if(part !== null) {
              html = html.replace(part[1], '<span class="bounce-error-text">' + part[1] + '</span>');
              return true;
            }
          }
        },
        () => {
          let part = text.match(/(No\s+such\s+user!|user\s+not\s+found|(recipient\s+)?address\s+rejected(:[^(\[$]*)?|relay\s+access\s+denied|service\s+timed\s+out|550\-5\.3\.0\s+Unable)/ig);
          if(part) {
            part.forEach(
                error => html = html.replaceAll(error, '<span class="bounce-error-text">' + error + '</span>')
            )
            return true;
          }
        },
        () => {
          if(text.match(/an MX or SRV record indicated no SMTP service/i)) {
            let part = text.match(/an MX or SRV record indicated no SMTP service/i);
            html = html.replace(/an MX or SRV record indicated no SMTP service/i, '<span class="bounce-error-text">' + part[0] + '</span>');
            return true;
          }
        },
        () => {
          if (text.match(/connect to [^\n]*\s*Connection\s*timed\s*out/gi)) {
            html = html.replace(/connect to [^\n]*\s*Connection\s*timed\s*out/gi, (match) => {
              return '<span class="bounce-error-text">' + match + '</span>';
            });
            return true;
          }
        },
        () => {
          if (text.match(/Delay reason: mailbox is full/gi)) {
            html = html.replace(/Delay reason: mailbox is full/gi, (match) => {
              return '<span class="bounce-error-text">' + match + '</span>';
            });
            return true;
          }
        },
        () => {
          if (text.match(/mail receiving disabled/gi)) {
            html = html.replace(/mail receiving disabled/gi, (match) => {
              return '<span class="bounce-error-text">' + match + '</span>';
            });
            return true;
          }
        },
          //
        () => {
          if (text.match(/^<[^>@]+@[^>]+>:/m)) {
            // <email@email>: ...
            let part = text.replaceAll(/^<([^>@]+@[^>]+)>:\s?([^<]*)/gms, '&lt;$1&gt;: <span class="bounce-error-text">$2</span>');
            if(html !== part) {
              html = part;
              return true;
            }
          }
        },
        () => {
          if (text.match(/was automatically rejected:/i)) {
            let part = text.match(/was automatically rejected:\s*(.*)/);
            if(part !== null) {
              html = html.replace(part[1], '<span class="bounce-error-text">' + part[1] + '</span>');
              return true;
            }
          }
        },
        () => {
          let part = text.match(/[^\s\@]+@[^\s]+\s*Unrouteable\s+address/i);
          if(part) {
            html = html.replace(part[0], '<span class="bounce-error-text">' + part[0] + '</span>');
            return true;
          }
        }
      ];

      functions.some(fn => {
        return typeof fn === 'function' && fn() === true;
      });

      em.html(html);
    }
  },

  watch: {
    'message.loadImages' (to) {
      this.loadImages = to;
    },

    'collapsed' (to) {
      if(to) {
        this.isCollapsed = true;
      }
    }
  }
}
</script>

<style scoped>
@import url(messages.scss);
.msg-part {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.html-part {
  position: relative;
  display: block;
  height: fit-content;
  max-width: 80vw;
  min-width: 20vw;
  max-height: 80vh;
  overflow: auto;
}
</style>