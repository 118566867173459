<template>
  <div class="input-group param">
    <key-label :keyword="keyword" :offset="_offset" :draggable="draggable" :border="border" />
    <type :keyword="keyword" />
    <autocomplete-type :keyword="keyword" />
    <!--shortening :keyword="keyword" /-->
    <!--declination :keyword="keyword" /-->
    <format :keyword="keyword" />
    <date :keyword="keyword" />
    <autogenerated :keyword="keyword" />
    <!--required :keyword="keyword" /-->
    <delete :keyword="keyword" />
  </div>
</template>

<script>
import KeyLabel from "./Keyword/KeyLabel.vue";
import Type from "./Keyword/Type.vue";
import AutocompleteType from "./Keyword/AutocompleteType.vue";
import Shortening from "./Keyword/Shortening.vue";
import Declination from "./Keyword/Declination.vue";
import Format from "./Keyword/Format.vue";
import Date from "./Keyword/Date.vue";
import Autogenerated from "./Keyword/Autogenerated.vue";
import Required from "./Keyword/Required.vue";
import Delete from "./Keyword/Delete.vue";

export default {
  name: "Keyword",

  components: {
    Delete,
    Required,
    Autogenerated,
    Date,
    Format,
    Declination,
    KeyLabel,
    Type,
    AutocompleteType,
    Shortening,
  },

  props: {
    keyword: Object,
    group: {
      type: String,
      default: 'ungrouped'
    },
    offset: {
      default: 0
    },
    draggable: Boolean,
    border: Object,
  },

  computed: {
    _offset() {
      return this.offset > 0 ? this.offset : this.group === 'ungrouped' ? 0 : 15;
    },

    document() {
      return this.$store.getters['document/getDocument'];
    },
  },
}
</script>

<style scoped>
.input-group {
  margin-left: 0px !important;
}
</style>