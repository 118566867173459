import vueBackspace from './vue-backspace'

let isEventAdd = false;
const plagin = {
    install (Vue) {
        Vue.directive('backspace', {
            mounted(el, binding){
                vueBackspace.update(el , binding)
            },
            beforeUnmount(el, binding) {
                vueBackspace.unbind(el , binding)
            },
        });
        if(!isEventAdd){
            isEventAdd = true;
            document.addEventListener('keyup',  (event)=>{ vueBackspace.onEvent(event)})
            document.addEventListener('keydown',  (event) =>{ vueBackspace.downEven(event)})
        }
    }
}
export default plagin