<template>
  <div v-if="type === 'html'" v-html="getHtml" class="max-width" :id="id" ref="html-message"></div>
  <div v-else ref="text-message">{{value ?? message.body}}</div>
</template>

<script>
import DOMPurify from 'dompurify';
import {mapGetters} from "vuex";
import {baseHighlight} from "../../../../library/Functions";

export default {
  name: "MessageEntityText",

  props: {
    value: {
      type: String,
    },
    message: {
      type: Object,
      require: true
    },
    type: {
      type: String,
    },
    loadImages: {
      type: Boolean,
      default: false,
    },
  },

  data: function() {
    return {
      id: 'htmlmsg_' + String(Math.random()).substr(2)
    }
  },

  mounted() {
    if (this.type !== 'html') {
      this.highlight();
    }
  },

  computed: {
    attachments() {
      let result = null;
      if (Array.isArray(this.message?.attachments)) {
        result = this.message.attachments.filter((attachment) => {
          return typeof attachment?.inline !== 'undefined' && attachment.inline && typeof attachment['content-id'] !== 'undefined';
        });
      }

      return result;
    },

    getHtml: function() {
      let html = this.message.body;

      if(this.message?.sanitized !== true)  {
        // html не обработан, нужно очистить от лишнего

        let replaceBack = false;

        if (Array.isArray(this.attachments) && this.attachments.length) {
          replaceBack = true;
          this.attachments.forEach((attachment) => {
            let link = '/message/' + window.getId(this.message._id) + '/attachment/' + window.getId(attachment.id);
            let contentId = attachment['content-id'];
            let replaceWith = this.loadImages ? '<img $1 src="' + link + '" $2>' : '<inline-image $1 src="' + link + '" $2>';
            html = html.replace(new RegExp('<img ([^>]*)src="cid:' + contentId + '"([^>]*)>', "gmi"), replaceWith);
          });
        }

        html = DOMPurify.sanitize(html, {
          ADD_TAGS: this.loadImages ? [] : ['inline-image'],
          FORBID_TAGS: this.loadImages ? [] : ['img'],
        });

        if (replaceBack && !this.loadImages) {
          html = html.replace(new RegExp('<inline-image ([^>]*)>', "gmi"), '<img $1>');
        }
      }

      if(!this.loadImages) {
        html = html.replace(/<img[^>]*src=(?:"|')(?!\/message\/\w{24}\/attachment\/\w{24})[^>]*>/gi, '');
        html = html.replace(/url\([^\)]+\)/i, 'none');
      }

      this.$nextTick(() => {
        this.handleQuotes();
        if(this.message?.sanitized !== true) {
          this.safeStyles();
        }
        // target blank для всех ссылок в письме
        $(this.$refs['html-message']).find('a').attr({target: '_blank'});

        let parentBlock = $(this.$refs['html-message']).parent().get(0);
        if (
            parentBlock &&
            parentBlock.scrollHeight > parentBlock.clientHeight &&
            $(this.$refs['html-message']).find('mark.highlight-text').length
        ) {
          $(this.$refs['html-message']).find('mark.highlight-text').get(0)?.scrollIntoView({
            behavior: 'auto',
            block: 'start',
            inline: "nearest"
          });
        }
      });

      return html;
    },

    ...mapGetters({
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {
    removeHighlight() {
      if (this.type !== 'html') {
        $(this.$refs['text-message']).text(this.value ?? this.message.body);
      }
    },
    highlight() {
      if (this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        baseHighlight(
            $(this.$refs[this.type === 'html' ? 'html-message' : 'text-message']),
            this.searchString
        );
      }
    },
    handleQuotes: function() {
      this.highlight();
      let msg = $(this.$refs['html-message']);

      msg.find('.blockquote-collapse').remove();
      msg.find('blockquote').each(function(index, quote) {
        // каждой цитате (кроме последней), добавляем кнопки показть/скрыть
        let quote_id = String(Math.random()).substr(2);
        $(quote).attr({id: quote_id}).hide();

        $('<br>').insertBefore($(quote));

        let btn_text = $(quote).text().replace(/(\s|\t|\n)*/, '').substr(0, 15) + '...';
        let show_button = $('<button>').addClass('btn btn-secondary btn-sm col-12 text-start blockquote-collapse')
            .html(btn_text).on('click', function() {
              $(this).hide();
              $('#' + quote_id + ', #' + quote_id + '_btn_hide').show();
            }).attr({id: quote_id + '_btn_show'});
        let hide_button = $('<button>').addClass('btn btn-secondary btn-sm col-12 text-start text-center blockquote-collapse')
            .html('Скрыть').on('click', function() {
              $(this).hide();
              $('#' + quote_id).hide();
              $('#' + quote_id + '_btn_show').show();
            }).attr({id: quote_id + '_btn_hide'}).hide();

        $(show_button).insertBefore($(quote));
        $(hide_button).insertBefore($(quote));
        if ($(quote).find('mark.highlight-text').length > 0) {
          $(show_button).trigger('click');
        }
      });
    },

    safeStyles: function() {
      let id = this.id;
      let el = $('#' + id);

      el.find('style').each(function() {
        $(this).html(
          $(this).html().replace(/([^\{]+)\{[^\}]+}\n?\s*/sg, function(match, part) {
            let replaced;

            if(part.split(',').length > 1) {
              let replaced_part = part.split(',');
              replaced_part.forEach((p, ind) => {
                replaced_part[ind] = '#' + id + ' ' + p.trim();
              });
              replaced_part = replaced_part.join(', ');
              replaced = match.replace(part, replaced_part);
            } else {
              replaced = '#' + id + ' ' + match;
            }

            return replaced;
          })
        );
      })
    },
  },

  watch: {
    searchString(to) {
      if (this.type !== 'html' && to.trim() === '') {
        this.removeHighlight();
      }
    },
  },
}
</script>

<style scoped>
.max-width {
  max-width: 100%;
  overflow: auto;
  white-space: normal;
  padding-bottom: 4px;
}
</style>