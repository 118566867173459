<template>
<div>
  <a :href="hrefObject" target="_blank"><span>{{object}}</span><!--span class="smile-m">{{smile}}</span --></a>
</div>
</template>
<script>
import getTypeSmile from "../source/typeSmile";
import getHref from "../source/objectHref";

export default {
  name: "ObjectCell",
  props:{
    object:{
      type:String,
      require:true,
    },
    type:{
      type:String,
      require: true,
    }
  },

  computed:{
    smile:function (){
      return getTypeSmile(this.type)
    },
    hrefObject:function (){
      return  getHref(this.type , this.object);
    }
  }
}
</script>

<style scoped>

</style>