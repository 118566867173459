const focus = {
    install (Vue) {
        Vue.directive('focus', {
            mounted(el, binding){
                if(binding.value){
                    el.focus();
                }

            }
        });
    }
}
export default focus;