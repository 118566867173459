<template>
  <button :class="{'text-muted':loading, 'custom-disabled':loading}" class="btn btn-light btn-outline-dark col-12" @click="setFavorite" v-if="showSetFavorite">
    <i class="fa fa-star-o button-icon menu-button"></i>Добавить в Избранные
  </button>
  <button :class="{'text-muted':loading, 'custom-disabled':loading}" class="btn btn-light btn-outline-dark col-12" @click="unsetFavorite" v-if="showUnsetFavorite">
    <i class="fa  fa-star button-icon menu-button"></i>Убрать из Избранных
  </button>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";

export default {
  name: "FavoriteButton",

  props: {
    callback: {}
  },

  data: function() {
    return {
      loading: false,
    }
  },

  computed: {
    showSetFavorite: function() {
      return this.tickets.filter(ticket => ticket.favorite === false).length > 0;
    },

    showUnsetFavorite: function() {
      return this.tickets.filter(ticket => ticket.favorite === true).length > 0;
    },

    tickets: function() {
      return Object.values(this.$store.getters["tickets/getSelectedObjects"]);
    }
  },

  methods: {

    setFavorite: function() {
      this.loading = true;

      CrmApi.setFavorite(this.$store.getters["tickets/getSelectedArray"]).then(() => {
        this.tickets.forEach((ticket) => { ticket.favorite = true });
        if(typeof this.callback === 'function') {
          this.callback();
        }
        if( this.tickets.length > 1){
          this.$store.commit('successPush', "Тикеты добавлены в избранное");
        }else {
          this.$store.commit('successPush', "Тикет добавлен в избранное");
        }

      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => { this.loading = false; });
    },

    unsetFavorite: function() {
      this.loading = true;

      CrmApi.unsetFavorite(this.$store.getters["tickets/getSelectedArray"]).then(() => {
        this.tickets.forEach((ticket) => { ticket.favorite = false });
        if(typeof this.callback === 'function') {
          this.callback();
        }
        if( this.tickets.length > 1){
          this.$store.commit('successPush', "Тикеты убраны из избранного");
        }else {
          this.$store.commit('successPush', "Тикет убран из избранного");
        }
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => { this.loading = false; });
    }
  }
}
</script>

<style scoped>

</style>