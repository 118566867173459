import {CrmApi} from "../CrmApi";


export {OfferHistory}

class OfferHistory {


    static getList(filters = undefined){
        let url ='/offers/history';
        if(filters !== undefined && Object.keys(filters).length !== 0){
            url = url + "?" +  Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join("&");
        }

        return CrmApi.call(url);
    }

    static editObject(id, params){
        let url ='/offers/history/' + encodeURIComponent(window.getId(id));
        return CrmApi.call(url, params);
    }

    static deleteObject(id, params){
        let url ='/offers/history/delete/' + encodeURIComponent(window.getId(id));
        return CrmApi.call(url, params);
    }


    static createObject(historyObject){
        let url = '/offers/history/';
        return CrmApi.call(url, historyObject);
    }

    static createObjectFromThread(historyObject, threadId) {
        let url = '/thread/' + encodeURIComponent(window.getId(threadId)) + '/offerhistory';
        return CrmApi.call(url, historyObject);
    }

}