<template>
<div v-if="replyto" >
  <div v-if="quoteMessage">
    <div class="text-muted quote-style">
      <div>
        <headers-block  :headers="quoteMessage.headers" :author="quoteMessage.author" ></headers-block>
      </div>
      <string-trim :string="quoteMessage.body"></string-trim>
    </div>
  </div>
  <div class="quote-style" v-if="!quoteMessage" >
    <a target="_blank" :href="'/tickets/'+ ticketId+'?message='+ replyto">Перейти к цитируемому сообщению >></a>
  </div>

</div>
</template>

<script>
import HeadersBlock from "../../Message/HeadersBlock";
import StringTrim from "../../../string/StringTrim";
export default {
  name: "QuoteMessage",
  components: {StringTrim, HeadersBlock},
  props:{
    message:{
      type:Object,
    },
  },
  data:function (){
    return {
      isFound:false,
    }
  },
  computed:{
    ticketId:function (){
      return window.getId(this.$store.getters['thread/getTicket']._id)
    },
    replyto:function (){
      let replyto = null;
      if(typeof this.message?.replyto !== 'undefined' && this.message?.replyto){
        replyto = window.getId(this.message.replyto)
      }
      return replyto;
    },
    quoteMessage:function (){
      let quoteMessage = null;
      let id = this.replyto;
      if(this.$store.getters['thread/getMessages'] !== null){
        for(let el of this.$store.getters['thread/getMessages']){
          if(window.getId(el._id) === id){
            quoteMessage = el;
            break;
          }
        }
      }
      return quoteMessage;
    },
    body:function (){
      let body = '';
      if(this.quoteMessage){
        body = this.quoteMessage.body.replace('\n' , ' ');
        body =  body.replace(/\s+/g, ' ' );
      }
      return body
    }
  }
}
</script>

<style scoped>
.quote-style{
  border-left: 2px solid gray;
  background-color: #d8d8d8;
  padding-left: 5px;
  margin-bottom: 5px;
}
</style>