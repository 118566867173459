<template>
  <div>
    <select  @change="changeState()" v-model="oneValue"  id="select_project" class="form-select form-select-sm form-style" >
      <option disabled :value="''">Выберите проект</option>
      <option  v-for="project in projects" :value="project.value" >
        {{ project.label }}
      </option>
    </select>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";

export default {
  name: "ProjectTicketSelect",
  props:{
    'action':{
      type:Object,
      require:true,
    }
  },
  data:function (){
    return {
      projects:[],
      oneValue:"",
    }
  },
  methods:{
    changeState(){
      this.action.params = [
      { key:'project',
        value:this.oneValue,
      }];
    }
  },
  created() {
    this.projects = []
    CrmApi.getProjectsNames().then((result)=> {
      for (let elem of result.data){
          this.projects.push({
            value:elem._id,
            label:elem.name,
          });
      }
    }).catch((error)=> {});

    if(typeof this.action.params[0] !== 'undefined' && this.action.params[0].value !== ""){
      for (let elem of  this.action.params){
        if(elem.key === 'project'){
          this.oneValue = elem.value;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>