<template>
  <span :class="{loading: loading, 'align-items-center': alignItemsCenter}" class="meta d-flex">
    <span class="meta-item" :class="fieldClass" :style="fieldCss">{{name}}:&nbsp;</span>
    <editable-text
        v-if="userCanChange"
        input-class="form-control d-inline"
        input-style="width: 10em;"
        ref="meta-value-input"
        :text="proxyValue"
        :callbackParams="{key: name}"
        :clipboard="true"
        clipboard-popover-placement="bottom"
        :trim="true"
        trim-popper-position="fixed"
        :string-style="metaStringStyle"
        @change="change"
        @open="alignItemsCenter = true"
        @close="alignItemsCenter = false"
    />
    <template v-else>
      <string-trim :string="proxyValue" />
    </template>
  </span>
</template>

<script>
import EditableText from "../../../../Editable/EditableText";
import StringTrim from "../../../../string/StringTrim";
import {CrmApi} from "../../../../../library/CrmApi";

export default {
  name: "Meta",

  components: {EditableText, StringTrim},

  props: {
    person: {
      require: true,
    },

    ticketId: {
      require: true,
    },

    name: {
      type: String,
      require: true,
    },

    value: {
      type: String,
      require: true,
    },

    userCanChange: {
      type: Boolean,
      default: false,
    },

    fieldClass: {},
    fieldCss: {},
  },

  data: function() {
    return {
      loading: false,
      proxyValue: this.value,
      metaStringStyle: 'display: block; text-decoration: underline; text-decoration-style: dashed; text-underline-offset: 2px;' +
                       'word-wrap: break-word; word-break: break-word;',
      alignItemsCenter: false,
    }
  },

  methods: {
    change: function(value, key) {
      let prev = { ...this.person.meta };
      this.person.meta[key.key] = value;
      this.proxyValue = value;
      this.loading = true;

      CrmApi.updateThreadPerson(this.ticketId, {meta: this.person.meta}).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.person.meta = prev;
        this.proxyValue = prev[key.key];
      }).finally(() => { this.loading = false; });
    },
  }
}
</script>

<style scoped>
:deep(.popper){
  --popper-max-wight-custom: 80vw;
}
.meta {
  width: fit-content;
}
</style>