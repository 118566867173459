<template>
<div class="col-12">
  <div v-enter.meta="openModal" v-enter.ctrl="openModal" class="col-12 text-end" ref="add_to_project">
    <button type="button" :class="{'cursor-wait': isSending}" :disabled="isSending" @click="openModal" class="btn btn-primary col-12">Добавить в проект</button>
  </div>
  <transition name="modal">
    <modal-window @close="showModal = false" v-if="showModal" @success="mergeTickets()" modal-dialog-class="modal-lg">
      <template v-slot:header>
        <h5 class="modal-title" >Прикрепить запрос к проекту</h5>
      </template>
      <template v-slot:body>
        <modal-body @changeValues="changeValues($event)" />
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="showModal = false">Отмена</button>
        <button type="button" class="btn btn-primary" :disabled="isSuccessDisabled || loading" @click="mergeTickets()">
          <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          Прикрепить
        </button>
      </template>
    </modal-window>
  </transition>
</div>
</template>

<script>
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import ModalBody from "../../tiketMenu/components/addToProject/ModalBody";
import {Ticket} from "../../../library/api/Ticket";
import {CrmApi} from "../../../library/CrmApi";
import {getId} from "../../../library/ID";

export default {
  name: "Unsorted",

  components: {
    ModalBody,
    "modal-window": ModalWindow
  },

  props: {
    ticket: Object,
  },

  data: function() {
    return {
      selected: {
        ticketId: '',
        projectId: '',
      },

      showModal: false,
      loading: false,
    }
  },

  computed: {
    isSending: function() { return this.$parent.sending; },

    selectedArray:function () {
      return this.$store.getters["tickets/getSelectedArray"];
    },

    isSuccessDisabled: function() {
      return this.selected.ticketId === '' && this.selected.projectId === '';
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    }
  },

  methods: {
    openModal(){
      this.showModal = true;
    },
    changeValues({ticketId, projectId} ){
      this.selected.ticketId = ticketId;
      this.selected.projectId = projectId;
    },

    mergeTickets(skipCompetitor = false, redirect = true) {
      if(this.isSuccessDisabled){
        return;
      }

      this.loading = true;

      if(
          !skipCompetitor &&
          this.ticket?.competitor === true
      ) {

        Ticket.competitor({ids: [getId(this.ticket._id)], competitor: false})
              .then(() => {
                if(getId(this.ticket.project?._id) !== this.selected.projectId) {
                  this.mergeTickets(true, false);
                }
              }).catch((error) => this.$store.commit('errorPush', CrmApi.getErrorMessage(error)));

      } else {
        let object = {
          tickets: [getId(this.ticket._id)],
          project: this.selected.projectId,
          ticket: this.selected.ticketId,
          route: ()=>{},
        }

        if (this.selected.ticketId === '') {
          object.action = 'transferProject';
        } else {
          object.action = 'transferTicket';
          object.from = object.tickets;
          object.to = this.selected.ticketId;
        }

        this.$store.dispatch('tickets/mergeTicketsAndProjects', object).then(() => {
          this.showModal = false;
          this.loading = false;

          if(redirect) {
            if(this.selected.ticketId === '') {
              this.$router.push({name: 'Unsorted'});
            } else {
              window.location.href = '/tickets/' + encodeURIComponent(window.getId(this.selected.ticketId));
            }
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>