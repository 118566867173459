import {createStore} from "vuex";
import {shareState} from "../common/composition";
import * as actions from "../common/actions";
import * as getters from "../common/getters";
import commonMutations from "../common/mutations";

import {moduleRates} from "./composition";
import {moduleStatistic} from "../statistic/composition";

export default createStore ( {
    namespaced: true,
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    modules:{
        'ratesStatisticModule':moduleRates,
        'statistic':moduleStatistic
    },
    plugins: []
})