<template>
<div>
  <div :class="{'checked':withAttached}"  class="form-check check-box">
    <input  style="margin-top: 2px; "   type="checkbox" class="form-check-input"  v-model="withAttached" id="attach-checkbox-filter">
    <label class="form-check-label" for="attach-checkbox-filter">
      с вложениями
    </label>
  </div>
</div>
</template>

<script>
export default {
  name: "AdditionalFilters",
  data:function (){
    return{
      withAttached:false,
    }
  },
  mounted() {
    let query = Object.assign({}, this.$route.query);
    if(typeof query.withAttachment !== 'undefined' && query.withAttachment){
      this.withAttached = true
    }else {
      this.withAttached = false
    }
  },

  methods:{
    addWithAttachedParam(){
      let query = Object.assign({}, this.$route.query);
      query.withAttachment = true;
      if(typeof  query.page === 'undefined'){
        query.page = 1;
      }
      this.$router.push({ name:  this.$router.name, query: query})
    },
    deleteWithAttachedParam(){
      let query = Object.assign({}, this.$route.query);
      if(typeof query.withAttachment !== 'undefined'){
        delete query.withAttachment;

      }
      if(typeof  query.page === 'undefined'){
        query.page = 1;
      }

      this.$router.push({ name:  this.$router.name, query: query})
    }
  },
  watch:{
    'withAttached'(to){
      if(to){
        this.addWithAttachedParam();
      }else {
        this.deleteWithAttachedParam();
      }
    }
  }
}
</script>

<style scoped>
.checked{
  background-color:#f0f8ff
}

.check-box{
  border-radius: 5px;
  border: 1px solid #b9b9b9;
  display: inline-block;
  padding: 5px 10px 5px 30px;
}
</style>