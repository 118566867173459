const offerGetters = {
    getPersonFromTicket: (state) => (ticket) => {
        return typeof ticket.person._id !== 'undefined' ? state.persons.list.get(window.getId(ticket.person._id)) : null;
    },

    getFilters: state => state.filters.query,
    getOffersList: state => state.offers.list,
    getPersonsList: state => state.persons.list,
    getLoading: state => state.loading,
    getPaginate: state => state.paginator,
    getConfirmedOffer: state => state.confirmedOffer,

    showOffersTable: state => state.showOffersTable,
    offersTableType: state => state.offersTableType,
    offersCloseCallback: state => state.offersCloseCallback,
    updateList: state => state.updateList,
    getTicketScrollPosition: state => state.ticketScrollPosition,
}

export default offerGetters;