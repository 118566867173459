<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} объединил(а) этот запрос с другим (добавилось
      <popper
          v-if="showScrollAndHighlightLink"
          placement="top"
          class="light"
          :arrow="true"
          :show="showPopper"
          content="Сообщения выделены"
          offset-distance="0"
      >
        <span
            class="text-primary text-decoration-underline"
            role="button"
            @click="scrollAndHighlight"
        >{{ messagesWord }}</span>
      </popper>
      <span v-else>{{ messagesWord }}</span>)
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import {getNumeralsWord} from "../../../library/Numerals";
import parseEvent from "./EventParser";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "MergeTicket",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  data() {
    return {
      showPopper: false,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    messagesWord() {
      return getNumeralsWord(this.params.countMergedMessages, ['сообщение', 'сообщения', 'сообщений']);
    },
    showScrollAndHighlightLink() {
      return Array.isArray(this.params?.mergedMessages) && this.params?.mergedMessages?.length;
    },
    ...mapGetters({
      "getMessagesIds": "thread/getMessagesIds",
    }),
  },

  methods: {
    scrollAndHighlight() {
      if (this.showScrollAndHighlightLink) {
        if (this.params.mergedMessages.length === 1 || this.getMessagesIds.includes(this.params.mergedMessages.slice(-1))) {
          this.setScrollAndHighlight(this.params.mergedMessages);
        } else {
          this.setHighlight(this.params.mergedMessages);
          this.showPopper = true;
          setTimeout(() => this.showPopper = false, 2000);
        }
      }
    },

    ...mapMutations({
      setScrollAndHighlight: "thread/setScrollAndHighlight",
      setHighlight: "thread/setHighlight",
    }),
  },
}
</script>

<style scoped>

</style>