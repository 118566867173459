<template>
  <div class="card">
    <div class="card-header">
      Шаги
    </div>
    <div class="card-body">
      <div v-if="!isCorrectFinalOrder" class="alert alert-danger p-2 text-center mb-1">Завершающий шаг должен быть последним</div>
      <div v-show="isChangedStepOrder || isHasNewStepOrChanged" class="attention">
        <span>Шаги были изменены, но не сохранены. Для изменения нажмите на кнопку "Сохранить"</span>
      </div>

      <draggable
          v-model="product.steps"
          item-key="order"
      >
        <template #item="{element, index}">
          <step-row :step="element" :index="index" ></step-row>
        </template>
      </draggable>
      <div v-show="addNewStep">
        <form @submit.prevent="addStep(newStepName); newStepName = ''; addNewStep=false">
          <div class="row">
            <span class="col-md-11 pe-0">
              <input type="text" class="form-control" ref="newStepName" v-model="newStepName" placeholder="Введите название шага"/>
            </span>
            <span class="col-md-1 ps-1">
              <button class="btn btn-success" type="submit">&plus;</button>
            </span>
          </div>
        </form>
      </div>
      <button type="button" v-show="!addNewStep" @click="clickAddNew()" class="btn btn-success btn-sm align-middle col-12">Добавить</button>
    </div>

  </div>
</template>

<script>
import EditableText from "../../Editable/EditableText";
import Draggable from "vuedraggable";
import ModalWindow from "../../tiketMenu/components/ModalWindow";
import StepEditNewModal from "../steps/StepEditNewModal";
import StepRow from "../steps/StepRow";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "StepList",
  components: {StepRow, StepEditNewModal, EditableText,Draggable,ModalWindow},

  props: {
    isCorrectFinalOrder: Boolean,
  },

  data:function (){
    return {
      stepElementToDelete:{},
      showDeleteModal:false,
      addNewStep: false,
      newStepName: "",
    }
  },
  mounted() {
    this.setOldStepOrders();
  },
  methods:{
    ...mapMutations({
      'addStep':"product/addStep",
      'setOldStepOrders':"product/setOldStepOrders",
    }),
    getId(data){
      return window.getId(data);
    },
    clickAddNew(){
      this.addNewStep = true;
      this.$nextTick(()=>{
        $(this.$refs.newStepName).focus();
      });
    }
  },
  computed:{
    ...mapGetters({
      'haveNonSaveChanges':'product/haveNonSaveChanges',
      'product':'product/getProduct',
      'oldStepOrders':"product/getOldStepOrders",
    }),

    isChangedStepOrder(){
      let isChangedStepOrder = false;
      let breakObject = {};
      try {
        if(this.oldStepOrders.length !== this.product.steps.length){
          isChangedStepOrder = true;

        }else {
          for (let i = 0; i < this.product.steps.length; i++) {
            if(typeof this.oldStepOrders[i] === 'undefined'){
              isChangedStepOrder = true;
              throw breakObject;
            }
            if(window.getId(this.oldStepOrders[i]._id) !== window.getId(this.product.steps[i]._id) ){
              isChangedStepOrder = true;
              throw breakObject;
            }
          }
        }
      }catch (breakObject){}
      return isChangedStepOrder;
    },

    isHasNewStepOrChanged(){
      let breakObject = {};
      let isHasNewStep = false;
      try {
        for (let value of this.product.steps) {
          if(typeof value._id === 'undefined' || value._id === null || value._id === ""){
            isHasNewStep= true;
            throw breakObject;
          }
          if(typeof value.stepChange !== 'undefined' &&  value.stepChange){
            isHasNewStep= true;
            throw breakObject;
          }
        }
      }catch (breakObject) {}
      return isHasNewStep;
    }


  }
}
</script>

<style scoped>
.attention{
  background-color: #fef3cd;
  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
}
</style>