<template>
  <div>
    <button v-backspace.ctrl="hotkeyModalDelete"  v-delete.meta="hotkeyModalDelete" v-backspace.meta="hotkeyModalDelete" v-delete.ctrl="hotkeyModalDelete" :disabled="disabled" :class="{'text-muted':loading, 'custom-disabled':loading}"  @click="showModalDelete"  class="btn btn-light btn-outline-dark col-12"><i class="fa fa-trash-o button-icon menu-button"></i>Удалить</button>
    <teleport to="body">
      <transition name="modal">
        <modal-window v-if="showModal" @close="closeModal"  @success="deleteTicket()" ok-button-style="btn-danger" >
          <template v-slot:header>
            <h5 class="modal-title text-danger" >Удаление тикета</h5>
          </template>
          <template v-slot:body>
            <span>{{getBody}}</span>
          </template>
          <template v-slot:footer>
            <div style="display: flex;  flex-wrap: nowrap;">
              <div class="btn-padding">
                <button type="button" class="btn btn-secondary"  @click="closeModal">Нет</button>
              </div>
              <div class="btn-padding">
                <button type="button"  class="btn btn-danger" @click="deleteTicket()">Да</button>
              </div>
            </div>
          </template>
        </modal-window>
      </transition>
    </teleport>
  </div>
</template>

<script>
import ModalWindow from "./ModalWindow";
import {mapGetters, mapState} from "vuex";
import {replaceTextObjects} from "../../ticket/Api/api";
import {getNumeralsWord} from "../../../library/Numerals";


export default {
  name: "DeleteButton",
  components: {"modal-window":ModalWindow},

  props: {
    callback: {
      default: null,
    },
    queue: {
      default: null,
    },
    disabled: {
      default: false,
    }
  },

  data: function (){
    return {
      showModal:false,
      hotkeyForWindows: ['ticket', 'ticketList'],
    }
  },
  methods:{
    hotkeyModalDelete() {
      if(this.hotkeyForWindows.includes(this.$store.getters['getActiveWindow'])) {
        this.showModalDelete();
      }
    },
    showModalDelete() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketDelete');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketDelete');
    },
    deleteTicket() {
      let action = () => {
        this.closeModal();
        this.$store.dispatch('tickets/deleteTickets', {
          ticketIds: this.getSelectedArray,
          route: this.callback ?? this.$route,
        });
      }
      if(this.queue === null) {
        action();
      } else {
        this.queue(action);
      }
    }
  },
  computed:{

    deletetag:function (){
      let deletetags = [];
      let tagsWithNames = [];
      for (let el of this.getProjects){
        if(typeof this.getSelectedProjects[window.getId(el._id)] !== 'undefined' &&
            typeof el.deletetag !== 'undefined' &&  el.deletetag !== ''){
          deletetags.push(el.deletetag)
          tagsWithNames.push(el.deletetag + " (" + this.getSelectedProjects[window.getId(el._id)].name +")");
        }
      }

      if(deletetags.length === 1){
        return "Удалить с меткой " + deletetags.join(', ');
      }else {
        return ''
        //return "Удалить с метками " + tagsWithNames.join(', ')
      }
    },
    getBody:function (){
      let body = "Вы уверены, что хотите перенести в корзину {{items}}";

      body = replaceTextObjects(body ,  this.getSelectedObjects, 2 , "name", "Без темы");

      if(this.getSelectedArray.length > 2){
        let n = (this.getSelectedArray.length - 2);
        body += " и еще " + getNumeralsWord(n, ['тикет', 'тикета', 'тикетов']);
      }

      body += "?";
      return body;
    },
    loading: function (){
      return this.$store.getters['tickets/isTicketLoading'];
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      'getProjects':'tickets/projects/getProjects',
      "getSelectedArray":  'tickets/getSelectedArray',
      'getSelectedObjects': 'tickets/getSelectedObjects',
      'getSelectedProjects':'tickets/getSelectedProjects',
    }),
  }
}
</script>

<style scoped>
.btn-padding{
  padding-left: 4px;
  padding-right: 4px;
}
</style>