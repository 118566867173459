<template>
<div>
  <div v-if="showComment" style="min-height: 26px; cursor:pointer" @dblclick="openCommit= !openCommit">
    <editable-text
        :user-change-param="openCommit"
        :as-text-area="true"
        input-class="form-control form-control-sm"
        :required="false" :text="comment"
        :callback="updateComment"
        :default-valid="true"
        string-style="word-break: break-all;"
    ></editable-text>
  </div>
</div>
</template>

<script>
import EditableText from "../../Editable/EditableText";
export default {
  name: "Comment",
  components: {EditableText},
  props:{
    showComment:{
      type:Boolean,
      default:true,
    },
    model:{
      type:Object,
    }
  },
  data:function (){
    return {
      openCommit:false,
    }
  },
  methods:{
    updateComment:function (value , params){
      this.model.comment = value;
    },
  },
  computed:{
    comment:{
      get(){
        return this.model?.comment ?? '';
      },
      set(value){
        this.model.comment = value;
      }
    },
  }
}
</script>

<style scoped>

</style>