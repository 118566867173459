<template>
  <teleport to="body" v-if="params">
    <transition name="modal">
      <modal-window
          @close="cancel"
          @success="change"
          modal-dialog-class="modal-lg"
      >
        <template v-slot:body>
          Для {{ type === 'ogrn' ? 'ОГРН' : 'ИНН' }} {{ params.company[type] }} найдено другое название компании - {{ params.company.name }}. Заменить все поля компании?
        </template>
        <template v-slot:footer>
          <button type="button" class="btn btn-secondary" @click="cancel">Отмена</button>
          <button type="button" class="btn btn-primary" @click="change">
            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Заменить
          </button>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import ModalWindow from "../ModalWindow";

export default {
  name: "changeCompany",

  emits: ['close', 'cancel'],
  components: {ModalWindow},

  props: {
    params: {},
    company: {},
    ticketId: {},
    type: {}, // ogrn || vatnum,
    update: {} //custom updater
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    close() {
      this.$emit('close');
    },

    cancel() {
      this.$emit('cancel');
    },

    change() {
      if(this.params) {
        if(typeof this.update === 'function') {
          this.update(this.params.company)
          this.close();
        } else {
          this.loadingChange = true;

          Object.keys(this.company).forEach((key) => {
            if(typeof this.params.company[key] === 'undefined') {
              this.params.company[key] = null;
            }
          });

          if(Array.isArray(this.company?.documents) && this.company.documents.length > 0) {
            this.params.company.documents = this.company.documents;
          } else {
            this.params.company.documents = [];
          }

          CrmApi.updateThreadPerson(this.ticketId, Object.assign(this.params, {changeCompany: true}))
                .then(result => {
                  Object.keys(this.company)
                        .forEach(key => delete this.company[key]);
                  Object.entries(result.data.person.company)
                        .forEach(([name, value]) => this.company[name] = value);

                  this.close();
                }).catch((error) => {
                  this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
                }).finally(() => {
                  this.loading = false;
                });
        }
      }
    },
  }
}
</script>

<style scoped>

</style>