import {CrmApi} from "../CrmApi";
import {prepareObjectToUrl} from "../Functions";

export {Messages};
class Messages {
    static move({message, ticket, withChain}) {
        return CrmApi.call(`/message/${message}/move/`, {
            ticket: ticket,
            withChain: withChain,
        });
    }

    static addAttachmentToPerson({message, id, query = null}) {
        let queryString = '';
        if (typeof query === 'string') {
            queryString = query;
        } else if (typeof query === 'object' && query !== null) {
            queryString = prepareObjectToUrl(query);
        }
        return CrmApi.call(`/message/${message}/attachment/${id}/addtoperson/${queryString}`, {});
    }

    static pinMessage(message) {
        return CrmApi.call(`/message/${message}/pin/`, {});
    }

    static unpinMessage(message) {
        return CrmApi.call(`/message/${message}/unpin/`, {});
    }
}