<template>
  <div>
    <entity-index :new-link="''">
      <entity-table
        settings-name="companies"
        :do-reload-page="reloadPage"
        :show-edit="showEdit"
        :columns="columns"
        :actions="actions"
        :get-entity-list="getList"
      >
        <template v-slot:[`cell.name`]="{ item }">
          {{ item.name }} <span
              v-if="item?.ta_gen"
              class="badge bg-info"
            >ipanel/TA-gen</span>
        </template>
      </entity-table>
    </entity-index>
    <alert-list></alert-list>
  </div>
</template>

<script>
import AlertList from "../ticket/Alert/AlertList";
import EntityIndex from "../common/EntityList";
import EntityTable from "../common/EntityTable";
import {mapGetters} from "vuex";
import {Companies} from "../../library/api/Companies";

export default {
  name: "CompaniesList",

  components: {
    AlertList,
    EntityIndex,
    EntityTable,
  },

  data: function () {
    return {
      reloadPage: {
        value: false,
      },
      columns: [
        {
          name: "Название",
          value: "name",
          width: 90,
        }
      ],
      actions: [
        {
          name: "Редактировать",
          handler: function (object) {
            this.showEdit(object);
          }
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      "getUserSettings": "getUserSettings"
    }),
  },

  methods: {
    showEdit: function (object) {
      window.location.href = window.location.origin + "/companies/" + encodeURIComponent(object._id);
    },
    getList: function (query) {
      if(this.getUserSettings?.companies?.onpage && !query?.onpage){
        query.onpage = this.getUserSettings.companies.onpage;
      }
      return Companies.getList(query);
    },
  }
}
</script>

<style scoped>

</style>