<template>
  <div>
    <button type="button" class="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown"  aria-expanded="false">{{value.name}}</button>
    <ul class="dropdown-menu">
      <li v-for="template in value.templates" class="dropend">
        <a  class="dropdown-item" @click="" href="#">{{template.name}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DropDown",
  props:{
    'value':{
      type:Object,
      require:true,
    }
  }
}
</script>

<style scoped>

</style>