<template>
  <project-line
      @click="showModalFunction(true)"
      class="col-auto project-line d-inline-block"
      v-for="(name, key) in projects"
      :custom-style="key === 0 ? {} : {'background-color':'#B178DD'}"
      :project="name"
      :clickable="true"
  />
  <teleport to="body">
    <transfer-modal
        v-if="showModal"
        :ticket-id="ticketId"
        :callback="callbackModal"
        @close="showModalFunction(false)"
    ></transfer-modal>
  </teleport>
</template>

<script>
import ProjectLine from "../../../ticket/List/ProjectLine";
import TransferModal from "../transfer/TransferModal";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  name: "Projects",

  components: {
    ProjectLine,
    TransferModal,
  },

  props: {
    ticketId: {
      default: null,
    },
    projects: {
      type: Array,
    },
    type: {
      type: String,
      default: 'ticket',
    },
  },

  data: function () {
    return {
      showModal: false,
    }
  },

  computed: {
    callbackModal() {
      return this.type === 'ticket' ? this.reloadTicket : null;
    },
  },

  methods: {
    showModalFunction(value) {
      this.showModal = value;
    },

    reloadTicket: function() {
      if (this.$route?.name === "Ticket" && this.ticketId !== null) {
        // TODO replace in task https://track.i7.org/issue/CRM-448/Perenos-v-store-obnovlenie-elementov-v-tikete
        return CrmApi.getTicketList({
          "_id": this.ticketId,
          "withDeleted": true,
        }).then((result) => {
          this.$store.commit('thread/setTicket', result.data.data[0]);
          this.$store.dispatch('tickets/selectElement', result.data.data[0]);
        });
      }
    },
  },
}
</script>

<style scoped>
.project-line {
  padding-right: 2px;
}
</style>