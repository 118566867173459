<template>
  <div style=" margin-top: 0px;" class="conditions" >
    <div class="row" v-for="(action, index ) in actions" >
      <div class="col-sm-4 form-style" >
        <select @change="changeAction(action)" v-model="action.type" name="actions.type"  id="actions.type"  class="form-select form-select-sm"  >
          <option value="favourites">Добавить в Избранное</option>
          <option value="delete">Переместить в Удаленные</option>
          <option value="mark">Добавить метку</option>
          <option value="transfer">Перенести в проект</option>
          <option value="assign">Подключить к проекту</option>
          <option value="notifications" v-if="showNotificationsOptions">Переместить в Рассылки</option>
        </select>
      </div>
      <div v-if="action.type === 'mark'" class="col-sm-6" style="padding-left: 5px; padding-right: 5px;" >
        <tag-select :action="action" ></tag-select>
        <input type="checkbox" v-model="action.notAddAgain" /> Не добавлять второй раз
      </div>
      <div  v-if="action.type === 'transfer'" class="col-sm-6 form-style" >
        <project-ticket-select :action="action"></project-ticket-select>
      </div>
      <div  v-if="action.type === 'assign'" class="col-sm-6" style="padding-left: 5px; padding-right: 5px;" >
        <assign-ticket-select :action="action"></assign-ticket-select>
      </div>
      <div v-if="action.type === 'notifications'" class="col-sm-6 form-style">
        <project-notification-select
          :action="action"
          :project-list="projects"
        ></project-notification-select>
      </div>
      <div :class="{'offset-6':isOffset6(action)}" class="col-sm-2  quantity buttons_added">
        <input v-on:click="addAction()" type="button" value="+"  class="plus" :disabled="action.type === 'notifications'">
        <input :disabled="actions.length <= 1" v-on:click="spliceAction(index)"  type="button" value="-" class="minus">
      </div>
    </div>
  </div>
</template>

<script>
import {CrmApi} from "../../../library/CrmApi";

import TagSelect from "./TagSelect";
import AssignTicketSelect from "./AssignTicketSelect";
import ProjectTicketSelect from "./ProjectTicketSelect";
import {UserPermission} from "../../../users/UserPermission";
import ProjectNotificationSelect from "./ProjectNotificationSelect";
export default {
  name: "ActionSelect",
  components:{
    AssignTicketSelect,
    TagSelect,
    ProjectTicketSelect,
    ProjectNotificationSelect,
  },
  data:function (){
    return {
      canFilterNotification: false,
      projects: [],
    }
  },
  props:{
    'actions':{
      type:Array,
      require:true,
    }
  },
  created() {
    UserPermission.can('system.filter_control_notification').then(() => {
      this.canFilterNotification = true;
    }).catch(() => {});
    CrmApi.getProjectsList({}).then(result => {
      this.projects = result.data;
    }).catch((error)=>{
      this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
    });
  },
  computed:{
    showNotificationsOptions() {
      return this.canFilterNotification && this.actions.length <= 1;
    }
  },
  methods:{
    isOffset6(action){
      let isOffset6 = false;
      switch (action.type){
        case 'delete':
        case 'favourites':
          isOffset6 = true;
          break;
      }
      return isOffset6;
    },
    changeAction(action){
      action.params = [];
    },
    addAction(){
      let action = {
        type: "favourites",
        params:[

        ]
      }
      this.actions.push(action);
    },
    spliceAction(index){
      this.actions.splice(index, 1);
    },

    selectTag(event , action){
      action.params.push({
        key:event,
        value:event
      });
    },
    updateTags(query){
      let allTagsArray = [];
      return CrmApi.getTags(query).then((result)=>{


        let tags = result.data.data;
        for (let key in tags){
          let el = tags[key];

          allTagsArray.push({
            value:el.tags,
            label:el.tags,
          });

        }

        return  allTagsArray;
      }).catch(()=> {
        return  allTagsArray;
      })
    },
  }
}
</script>

<style scoped>
@import '../filters.scss';
</style>