import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";


const state = () => ({
    type:'current',
    tasksSettings:[
        {
            name: 'Срок',
            id: 'deadlinedate',
            field: 'deadlinedate',
            value: true,
        },
        {
            name:"Название",
            id:"name",
            field:"name",
            value:true,
        },
        {
            name:"Ответственный",
            id:"assignee",
            field:"assignee",
            value:true,
        },
        {
            name:"Клиент",
            id:"client",
            field:"client",
            value:true,
        },
        {
            name:"Дата завершения",
            id:"completion_date",
            field:"completion_date",
            value:false,
        },
        {
            name:"Наблюдатель",
            id:"watchers",
            field:"watchers",
            value:false,
        },
        {
            name: "Проект",
            id: "project",
            field: "project.name",
            value: true,
            show: false,
        },
    ],
    callSettings:[
        {
            name: 'Дата',
            id: 'updated',
            field: 'updated',
            value: true,
        },
        {
            name:"Тема",
            id:"name",
            field:"name",
            value:true,
        },
        {
            name:"Телефон",
            id:"phones",
            field:"phones",
            value:true,
        },
        {
            name:"Содержание",
            id:"body",
            field:"body",
            value:true,
        },
        {
            name: "Проект",
            id: "project",
            field: "project.name",
            value: true,
            show: false,
        },
    ],


    billmanagerSettings: [
        {
            name: 'Дата',
            id: 'updated',
            field: 'ticket.date_last',
            value: true,
        },
        {
            name: 'Название',
            id: 'name',
            field: 'ticket.name',
            value: true,
        },
        {
            name: 'Клиент',
            id: 'person',
            field: 'account.name',
            value: true,
        }
    ],

    settings:[
        {
            name:"ID запроса",
            id:"ticket-id",
            field:"_id",
            value:true,
            show:false,
        },
        {
            name:"Дата",
            id:"updated",
            field:"updated",
            value:true,
        },
        {
            name:"Тема",
            id:"name",
            field:"name",
            value:true,
            title: "Поиск по последней и другим темам",
        },
        {
            name:"Клиент",
            id:"person",
            field:"person.email",
            value:true,
            title: "Поиск по всем данным карточки клиента",
        },
        {
            name:"Получатель",
            id:"recipient",
            field:"lastMessage.headers.to",
            value:true,
        },
        {
            name: "Проект",
            id: "project",
            field: "project.name",
            value: true,
            show: false,
        },
        {
            name:"Сообщение",
            id:"body",
            field:"body",
            value:false,
        },
        {
            name:"Непрочитанные",
            id:"unread",
            field:"unread",
            value:false,
            reloadWhenSearchStringEmpty:true,
            additionalFilter:true,
        },
        {
            name:"Новые",
            id:"new",
            field:"new",
            value:false,
            reloadWhenSearchStringEmpty:true,
            additionalFilter:true,
        },
        {
            name: "C вложениями",
            id:"withAttachment",
            field:"withAttachment",
            value:false,
            reloadWhenSearchStringEmpty:true,
            additionalFilter:true,
        }
    ],
    competitorSettings: [
        {
            name: "ID запроса",
            id: "ticket-id",
            field: "_id",
            value: true,
            show: false,
        },
        {
            name: "Дата",
            id: "updated",
            field: "updated",
            value: true,
        },
        {
            name: "Тема",
            id: "name",
            field: "name",
            value: true,
            title: "Поиск по последней и другим темам",
        },
        {
            name: "Клиент",
            id: "person",
            field: "person.email",
            value: true,
            title: "Поиск по всем данным карточки клиента",
        },
        {
            name: "Получатель",
            id: "recipient",
            field: "lastMessage.headers.to",
            value: true,
        },
        {
            name: "Проект",
            id: "project",
            field: "project.name",
            value: true,
        },
        {
            name: "Сообщение",
            id: "body",
            field: "body",
            value: false,
        },
        {
            name: "Непрочитанные",
            id: "unread",
            field: "unread",
            value: false,
            reloadWhenSearchStringEmpty: true,
            additionalFilter: true,
        },
        {
            name: "Новые",
            id: "new",
            field: "new",
            value: false,
            reloadWhenSearchStringEmpty: true,
            additionalFilter: true,
        },
        {
            name: "C вложениями",
            id: "withAttachment",
            field: "withAttachment",
            value: false,
            reloadWhenSearchStringEmpty: true,
            additionalFilter: true,
        },
        {
            name: "Избранное",
            id: "favorite",
            field: "favorite",
            value: false,
            reloadWhenSearchStringEmpty: true,
            additionalFilter: true,
        }
    ],
    defaultValues:{
        withAttachment:false,
        body: false,
        'project.name': true,
        'lastMessage.headers.to':true,
        'person.email':true,
        'name':true,
        'updated':true,
        '_id':true,
        'new': false,
        'unread': false,
        phones:true,
        favorite: false,
    },
    tasksDefaultValues: {
        "deadlinedate": true,
        "name": true,
        "assignee": true,
        "client": true,
        "completion_date": false,
        "watchers": false,
        'project.name': true,
    },
    callDefaultValues:{
        withAttachment: false,
        body: true,
        'project.name': true,
        'lastMessage.headers.to': true,
        'person.email': true,
        'name': true,
        'updated': true,
        '_id': true,
        'new': false,
        'unread': false,
        phones: true,
    },
    orderBy: null,
    search:{
        searchString:"",
        showFilter:false,
        products: null,
        users: [],
    },
    usersRequested: false,
    alias: 'all',
    aliasSet: false,
    product: null,
    user: null,
    ticketStatus: null,
    searchProject: null,
    sidebar: {
        filters: {}
    },
    isSettingShow:false,
    callType:'',
    callStatus:'',
    callsTableFilters:[
        {
            id:'updated_filter',
            placeholder:"Дата",
            field:"updated",
            value:"",
        },
        {
            id:'name_filter',
            placeholder:"Тема",
            field:"name",
            value: "",
        },
        {
            id: 'callee_filter',
            placeholder:"Звонил/Ответил",
            field: "callee",
            value: "",
        },
        {
            id: 'call_type',
            field: "call_type",
            value: "all",
            type:"select",
            default:{
                value:"Все",
                key:"all",
            },
            values: [
                {
                    value:"Входящий",
                    key:"incoming",
                },
                {
                    value:"Исходящий",
                    key:"outgoing",
                }
            ]
        },
        {
            id:'project',
            placeholder:"Проект",
            field:"project.name",
            value: ""
        },
    ],
    tasksTableFilters:[
        {
            id:'updated_filter',
            placeholder:"Дата",
            field:"headers.deadlinedate",
            value:"",
        },
        {
            id:'name_filter',
            placeholder:"Название",
            field:"subject",
            value: "",
        },
        {
            id:'assignee_filter',
            placeholder:"Ответственный",
            field:"assignee",
            value: "",
        },

        {
            id:'client_filter',
            placeholder:"Клиент",
            field:"ticket.person.email",
            value: "",
        },


        {
            id:'project',
            placeholder:"Проект",
            field:"ticket.project.name",
            value: ""
        },
    ],


    tableFilters:[
        {
            id:'updated_filter',
            placeholder:"Дата",
            field:"updated",
            value:"",
        },
        {
            id:'name_filter',
            placeholder:"Тема",
            field:"name",
            value: "",
        },
        {
            id:'person_filter',
            placeholder:"Клиент",
            field:"person.email",
            value: "",
        },


        {
            id:'recipient',
            placeholder:"Получатель",
            field:"lastMessage.headers.to",
            value: ""
        },
        {
            id:'project',
            placeholder:"Проект",
            field:"project.name",
            value: ""
        },
    ],

    billmanagerTableFilters: [
        {
            id: 'updated_filter',
            placeholder: "Дата",
            field: 'ticket.date_last',
            value: '',
        },
        {
            id: 'name_filter',
            placeholder: 'Название',
            field: 'ticket.name',
            value: '',
        },
        {
            id: 'person_filter',
            placeholder: 'Клиент',
            field: 'account.name',
            value: '',
        },
    ]

})









export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}