<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{userPart}} как продаем свою сеть
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "SetOursubnet",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    userPart() {
      return this.user ? this.user + ' пометил(а) тикет' : 'Тикет помечен';
    },
  }
}
</script>

<style scoped>

</style>