<template>
  <div>
    <entity-index new-link="/documents/new">
      <div>
        <entity-table
            settings-name="documents"
            :do-reload-page="reloadPage"
            @showDeleteModalWindow="showDeleteModalWindow($event)"
            :show-edit="showEdit"
            :columns="columns"
            :actions="actions"
            :get-entity-list="getDocumentList"
        >
          <template v-slot:[`cell.projects`]="{ item }">
            <div>
              <span v-for="project in item.projects" class="badge rounded-pill project-badge-bg mb-1 me-1">{{project.name}}</span>
            </div>
          </template>
        </entity-table>
      </div>
    </entity-index>
    <modal-window v-if="showDeleteModal" @close="showDeleteModal = false"  @success="deleteDocument" ok-button-style="btn-danger" >
      <template v-slot:header>
        <h5 class="modal-title text-danger" >Удаление документа</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите навсегда удалить документ {{documentToDelete.name}}?</span>
      </template>
    </modal-window>
    <alert-list></alert-list>
  </div>

</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import {Documents} from "../../library/api/Documents";
import EntityIndex from "../common/EntityList";
import EntityTable from "../common/EntityTable";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import AlertList from "../ticket/Alert/AlertList";
import {mapGetters} from "vuex";


export default {
  name: "DocumentList",

  components:{
    AlertList,
    EntityIndex,
    EntityTable,
    ModalWindow
  },

  data() {
    return {
      reloadPage: {
        value: false,
      },
      documentToDelete: {},
      showDeleteModal: false,
      columns:[
        {
          name: "Название",
          value: "name",
          width: 60,
        },
        {
          name: "Проекты",
          value: "projects",
          width:30
        }
      ],
      actions:[
        {
          name: "Редактировать",
          handler(product) {
            this.showEdit(product);
          }
        },
        {
          name: "Удалить",
          handler(product) {
            this.$emit('showDeleteModalWindow', product);
          },
          class: "link-danger",
        },
      ],
    }
  },

  computed:{
    ...mapGetters({
      'getUserSettings':'getUserSettings'
    })
  },
  methods:{
    deleteDocument() {
      this.reloadPage.value = false;
      Documents.deleteDocuments([ this.documentToDelete._id]).then((result) => {
        if(result.data?.status !== 'error'){
          this.reloadPage.value = true;
        }else {
          this.$store.commit('errorPush', result.data.message ?? "Ошибка удаления документа");
        }
      }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.showDeleteModal = false;
        });
    },
    showDeleteModalWindow(event) {
      this.documentToDelete = event;
      this.showDeleteModal = true;
    },
    showEdit(product) {
      window.location.href = window.location.origin + "/documents/" + encodeURIComponent(product._id);
    },
    getDocumentList(query) {
      if(this.getUserSettings?.documents?.onpage && !query?.onpage){
        query.onpage = this.getUserSettings.documents.onpage
      }
      return Documents.getDocumentsList(query);
    }
  }
}
</script>

<style scoped>
:deep(.modal-body) {
  margin: 0 !important;
}
.project-badge-bg {
  background-color: rgb(81 84 86);
}
</style>