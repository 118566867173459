<template>
<div>
  <Loading loader="dots" v-model:active="loading" />
  <table class="table table-hover table-striped ps-0">
    <thead>
      <tr v-if="fullSearch">
        <th><input type="text" class="form-control search-input" name="name" :value="requestParams.name" @change="search" @input="searchDelay" placeholder="Название"></th>
        <th>
          <select class="form-control search-input" name="project.name" :value="requestParams['project.name']" @change="search">
            <option value="" selected>Все проекты</option>
            <option v-for="project in getProjectsCanRead" :value="project.name">{{project.name}}</option>
          </select>
        </th>
        <th>
          <input type="text" class="form-control search-input" name="author.name" :value="requestParams['author.name']" @change="search" @input="searchDelay" placeholder="Изменен">
        </th>
        <th>
          <input type="text" class="form-control search-input" name="group" :value="requestParams['group']" @change="search" @input="searchDelay" placeholder="Группа">
        </th>
        <th class="switch-search-td">
          <button class="btn btn-light btn-outline-secondary form-control" @click="switchSearch">Скрыть</button>
        </th>
      </tr>
      <tr v-else>
        <th colspan="4">
          <input @change="search" @input="searchDelay" :value="requestParams.search" name="search" ref="searchInput" type="text" class="form-control" placeholder="Поиск">
        </th>
        <th>
          <button class="btn btn-lg btn-light btn-outline-secondary form-control" @click="switchSearch">
            <i class="fa fa-filter"></i>
          </button>
        </th>
      </tr>
      <tr>
        <th scope="col" width="42%">Название</th>
        <th scope="col" width="15%">Проект</th>
        <th scope="col" width="15%">Изменен</th>
        <th scope="col" width="15%">Группа</th>
        <th scope="col" width="13%"></th>
      </tr>
    </thead>
    <tbody>
      <template-row @deleteTemplate="showDeleteModalFunction" v-for="template in templates" :editurl="editurl" :template="template" :key="template._id"></template-row>
    </tbody>
  </table>
  <div>
    <paginate settings-name="template" ref="paginate" @changePage="goToPage($event)" ></paginate>
  </div>
  <modal-window v-if="showDeleteModal" @close="showDeleteModal = false"  @success="deleteTemplate" ok-button-style="btn-danger" >
    <template v-slot:header>
      <h5 class="modal-title text-danger" >Удаление шаблона</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите навсегда удалить шаблон {{templateForDelete.name}}?</span>
    </template>
  </modal-window>
</div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import TemplateRow from "./TemplateRow";
import Paginate from "../paginate/Paginate";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import {mapGetters} from "vuex";
import {getUserSettings} from "../../store/main/common/getters";
export default {
  name: "TemplateList",
  components: {
    TemplateRow,
    Paginate,
    ModalWindow
  },

  props: {
    defaultonpage: {
      require: true
    },
    editurl: {
      require: true
    }
  },

  data: function (){
    return {
      fullSearch: false,
      projectsCanRead: null,
      showDeleteModal:false,
      templates: [],
      page: 1,
      total: 0,
      requestParams: {
        page: 1,
        onpage: this.defaultonpage,

        search: '',
        name: '',
        'project.name': '',
        'author.name': '',
        'author.login': '',
      },
      searchTimeout: null,
      loading: false,
      templateForDelete:null,
    }
  },

  created: function(){
    this.requestParams = window.readGetParams();

    for(let param in {'name': '', 'project.name': '', 'author.name': ''}) {
      if(this.requestParams.hasOwnProperty(param)) {
        this.fullSearch = true;
      }
    }
    if(this.getUserSettings?.template?.onpage && !this.requestParams?.onpage ){
      this.requestParams.onpage = this.getUserSettings.template.onpage;
    }
    this.load();
  },

  computed: {
    ...mapGetters({
      'getUserSettings':'getUserSettings'
    }),
    getProjectsCanRead: function() {
      if(this.projectsCanRead === null) {
        this.loading = true;
        CrmApi.call('project/list').then(response => {
          this.projectsCanRead = response.data
          this.loading = false;
        });
      }
      return this.projectsCanRead;
    }
  },

  methods: {
    showDeleteModalFunction(object){
      this.showDeleteModal = true;
      this.templateForDelete = object;
    },
    deleteTemplate: function() {
      CrmApi.call('/template/' + encodeURIComponent(this.templateForDelete._id) + '/delete').then(response => {
        if(typeof response.data !== 'undefined') {
          if(typeof response.data.success !== 'undefined' && response.data.success === 'success') {
            let tmp = [];
            for (let obj of this.templates){
              if(window.getId(this.templateForDelete._id) !== window.getId(obj._id)){
                tmp.push(obj);
              }

            }
            this.templates = tmp;
            this.showDeleteModal = false;
          }
        }

      }).catch((error)=>{console.error(CrmApi.getErrorMessage(error))});
    },

    goToPage: function ({page , onpage}){
      this.requestParams.page =  page;
      this.requestParams.onpage  = onpage;
      this.load();
    },
    load: function (otherParams = {}) {
        let uri = '';
        let params = Object.assign(this.requestParams, otherParams);
        for(const [key, value] of Object.entries(params)) {
          if(value === '') {
            delete params[key];
          }
        }
        if(Object.keys(params).length > 0) {
          uri = '?' + new URLSearchParams(params).toString();
        }

        this.loading = true;
        CrmApi.call('template/list' + uri).then(response => {
          this.templates = response.data.data;

          this.$refs.paginate.update({
            page: response.data.page,
            onpage: response.data.onpage,
            total: response.data.total,
            links: response.data.paginator_links
          });
          this.loading = false;
        });
      this.changeBrowserUrl();
    },

    search: function(e) {
      if(this.requestParams[e.target.name] === e.target.value) {
        return;
      }
      this.requestParams[e.target.name] = e.target.value;
      this.requestParams.page = 1;
      this.load();
    },

    searchDelay: function(e) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search(e);
      }, 500);
    },

    changeBrowserUrl: function(params = {}) {
      params = Object.assign(this.requestParams, params);

      if(typeof params.page !== 'undefined' && parseInt(params.page) === 1) {
        delete params.page;
      }
      if(typeof params.onpage !== 'undefined' && parseInt(params.onpage) === this.defaultOnPage) {
        delete params.onpage;
      }

      for(const [key, value] of Object.entries(params)) {
        if(value === '') {
          delete params[key];
        }
      }

      params = new URLSearchParams(params).toString();
      let uri =location.pathname;
      if(params !== ''){
        uri +=  '?' + params;
      }

      window.history.replaceState(null, null,   uri );
    },

    switchSearch: function() {
      if(!this.fullSearch && typeof this.requestParams.search !== 'undefined' && this.requestParams.search !== '') {
        this.requestParams.search = '';
        this.load();
      }
      if(this.fullSearch) {
        let needReload = false;
        for(let param in {'name': '', 'project.name': '', 'author.login': '', 'author.name': ''}) {
          if(typeof this.requestParams[param] !== 'undefined' && this.requestParams[param] !== '') {
            this.requestParams[param] = '';
            needReload = true;
          }
        }
        if(needReload) {
          this.load();
        }
      }

      this.fullSearch = !this.fullSearch;
    },
  }
}
</script>

<style scoped>
a {
  cursor: pointer;
}
.switch-search-td {
  display: block;
  width: 100%;
  border: none;
}
</style>