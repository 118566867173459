<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Клиент <a :href="'/tickets/' +params.buyer_id" target="_blank" ><span>{{params.buyer_name}} ({{params.buyer_email}})</span></a>
      выиграл аукцион со ставкой {{prettyPrice}} за 1 IP
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import {PricePrettyView} from "../../../library/PricePrettyView";

export default {
  name: "CompleteAuction",
  props: {
    event: {
      type: Object,
      require: true,
    }
  },
  setup: (props) => parseEvent(props.event),
  computed:{
    prettyPrice:function (){
      let pr =  new PricePrettyView(this.params.price1, 'USD')
      return  pr.getPrettyPrice('', true, 2, false , " " );
    }
  }
}
</script>

<style scoped>

</style>