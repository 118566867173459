<template>
  <span class="add-to-auction">
      <i  class=" fa fa-gavel p-1"
          role="button" title="Выставить на продажу"
          @click="addToAuction"></i>
      <teleport to="body">
        <set-lot-modal
            v-if="show"
            :subnet="subnetString"
            :value="isNeedSetOnAuction"
            @cancel="cancel"
            @confirm-all-subnets="saveSetLotAllSubnets"
            class="subnet-setlot-modal"
        ></set-lot-modal>
      </teleport>
  </span>
</template>


<script>
import SetLotModal from "./SetLotModal.vue";
import {AddToAuctionSetup} from "../../../../auction/AddToAuctionSetup";

export default {
  name: "AddToAuction",
  components: {SetLotModal},
  setup(props){
    return  AddToAuctionSetup(props);
  },
  methods:{
    addToAuction:function (){
      this.show = true;
    }
  }
}
</script>



<style scoped>
.add-to-auction{
  display: none;
}
</style>