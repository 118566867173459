export {PricePrettyView};
class PricePrettyView{


    constructor(price, currency) {

        this.price = price;
        this.currency = currency;
        this.roundMethod = PricePrettyView.roundMethodCeil2Precision;
    }
    static getCurrencySymbol(symbol){
        switch(symbol) {
            case 'USD':
                symbol = '$';
                break;
            case 'EUR':
                symbol = '€';
                break;
            case 'RUB':
                symbol = '₽';
                break;
        }
        return symbol;
    }

    static roundMethodMath2Precision(price){
        return Math.round(price * 100) / 100;
    }
    static roundMethodCeil2Precision(price){
        return Math.ceil(price * 100) / 100;
    }
    static roundMethodFloor2Precision(price){
        return Math.floor(price * 100) / 100;
    }
    static withoutRound(price){
        return price;
    }
    getPriceCurrencySymbol(){
        return PricePrettyView.getCurrencySymbol(this.currency);
    }

    getPrettyPrice(defaultSymbol = '—' , notShowZero = false, precision = 2, currencySymbolAtEnd = true, betweenPrice = ' ' , withoutCurrencySymbol = false , options = {}){
        let string = defaultSymbol;
        if(!isNaN(parseFloat(this.price)) && (typeof this.price === 'string' || typeof this.price === 'number') && this.price !== '') {
            let params = {maximumFractionDigits: precision}
            params = Object.assign(params, options);
            let price = parseFloat(this.price).toLocaleString('ru', params );
            if(!notShowZero){
                price += ((price.indexOf(',') === -1) ? ',00' : '');
            }


            if(withoutCurrencySymbol){
                string = price;
            }else {
                string = currencySymbolAtEnd ? price + betweenPrice + this.getPriceCurrencySymbol() : this.getPriceCurrencySymbol() + betweenPrice + price;
            }
        }

        return string;
    }

    getReducePricesWithCurrency(defaultSymbol = '—',  precision = 2, currencySymbolAtEnd = true){
        let price = this.getReducePrices(defaultSymbol, precision);
        let string = currencySymbolAtEnd ? price + '' + this.getPriceCurrencySymbol() : this.getPriceCurrencySymbol() + '' + price;
        return string;
    }





    getReducePrices(defaultSymbol = '—',  precision = 2){
        let price = defaultSymbol;
        if(!isNaN(parseFloat(this.price)) && (typeof this.price === 'string' || typeof this.price === 'number') && this.price !== '') {
            price =  this.price;
            if(typeof price === 'string'){
                price = price.replace(" " , "")
                price = price.replace("," , ".")
            }
            price =  parseFloat(price).toFixed(precision)
        }
        return price;
    }
    static getCurrenciesArrayWithDate(price , currency , actualRates){
        let currencies = {};
        if(actualRates !== null) {
            price = String(price).replace(" ", "").replace(",", '.')
            switch(currency) {
                case 'USD':
                    currencies['USD'] = {value: price, date:null,  type:null};
                    currencies['RUB'] = {
                        value: (price * actualRates['USD'].value),
                        date:actualRates['USD'].date,
                        type:'ЦБ',
                        rateValue:actualRates['USD'].value,
                    };
                    if(actualRates?.['EUR/USD']){
                        currencies['EUR'] = {
                            value: (price / actualRates['EUR/USD'].value),
                            date:actualRates['EUR/USD'].date,
                            type:'ECB',
                            rateValue:1/actualRates['EUR/USD'].value,
                        }
                    }
                    break;
                case 'EUR':
                    currencies['EUR'] =
                        {value:price, date:null,  type:null };
                    currencies['RUB'] =  {
                        value:price * actualRates['EUR'].value,
                        date: actualRates['EUR'].date,
                        type:'ЦБ',
                        rateValue:actualRates['EUR'].value,
                    };
                    if(actualRates?.['EUR/USD']){
                        currencies['USD'] = {
                            value:price * actualRates['EUR/USD'].value,
                            date: actualRates['EUR/USD'].date,
                            type:'ECB',
                            rateValue:actualRates['EUR/USD'].value,
                        }
                    }
                    break;
                case 'RUB':
                    ['USD', 'EUR'].forEach(currency => currencies[currency] = {
                        value: price / actualRates[currency].value ,
                        date:actualRates[currency].date,
                        type:'ЦБ',
                        rateValue:1/actualRates[currency].value
                    });
                    currencies['RUB'] = {
                        value:price,date:null,  type:null
                    };
                    break;
            }
        }
        return currencies;
    }

    static getCurrenciesArray(price , currency , actualRates){
        let currencies = {};
        if(actualRates !== null) {
            price = String(price).replace(" ", "").replace(",", '.')
            switch(currency) {
                case 'USD':
                    currencies['USD'] = price;
                    currencies['RUB'] = price * actualRates['USD'];
                    if(actualRates?.['EUR/USD']){
                        currencies['EUR'] = price / actualRates['EUR/USD']
                    }else {
                        currencies['EUR'] = currencies['RUB'] / actualRates['EUR'];
                    }

                    break;
                case 'EUR':
                    currencies['EUR'] = price;
                    currencies['RUB'] = price * actualRates['EUR'];
                    if(actualRates?.['EUR/USD']){
                        currencies['USD'] = price * actualRates['EUR/USD']
                    }else {
                        currencies['USD'] = currencies['RUB'] / actualRates['USD'];
                    }
                    break;
                case 'RUB':
                    ['USD', 'EUR'].forEach(currency => currencies[currency] = price / actualRates[currency]);
                    currencies['RUB'] = price;
                    break;
            }
        }
        return currencies;
    }

   static parseCurrency(string, defaultCurrency = 'USD'){
        let currency = null;

        if(string !== '') {
            string = string.replace(/[0-9]|\.|\,/g, "").trim();
            let c_rules = {
                'USD': ['$', 'usd', 'дол', 'u', 'd' , 'долларов', 'доллар',],
                'EUR': ['€', 'eur', 'евр', 'e' , 'евро' , 'euro'],
                'RUB': ['₽', 'rub', 'руб', 'р.', 'р', 'p' , 'рубль'],
            }
            currency = defaultCurrency;

            for(let [c, rules] of Object.entries(c_rules)) {
                if(rules.some(rule => { return (string.toLowerCase() === rule ) })) {
                    currency = c;
                    break;
                }
            }
        }
        return currency;
    }
}