import {CrmApi} from "../CrmApi";

export {Statistic}

class Statistic{
    static getKpi(filters){
        let url ='/statistic/kpi';
        if(filters !== undefined && Object.keys(filters).length !== 0){
            url = url + "?" +  Object.keys(filters).map(key => {
                return encodeURIComponent(key) + '=' + encodeURIComponent(filters[key])
            }).join("&");
        }
        return CrmApi.call(url);
    }
    static getWaitingOrders(){
        let url ='/statistic/waitingorders';
        return CrmApi.call(url);
    }
}