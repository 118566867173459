import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = () => ({
    showCreateForm: false,
    projectsCanAnswer: [],
    projectsCanCreate: [],
    ticketList: [],
    isLoading: false,
    forceCreate: false,
    paginate:{},
    searchTicketsString: null,
    selectedProject: null,
    isCreatingTicket: false,
    products: [],
    isProductsLoading: false,
});

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}