<template>
  <div>
      <textarea :class="ariaClass" class="mb-2 "
                :name="name"
                :value="modelValue"
                @input="handleInput($event.target.value)"
                :style="styles"
                ref="expandableText"
                id="textarea-element"
                :placeholder="placeholder"
                @click.exact="click(false)"
                @mousedown.exact="click(true)"
                style="padding-bottom: 10px; overflow-wrap:break-word "
                :disabled="disabled"

      />
  </div>
</template>

<script>

export default {
  name: "ExpandableTextArea",

  emits: ['update'],

  props:{
    ariaClass:{
      type:Object,
      default:{},
    },
    modelValue: String,
    value: String,
    name: String,
    startMinHeight:{
      type:Number,
      default:60,
    },
    maxHeight:{
      type:Number,
      default:300,
    },
    placeholder: {
      default: ''
    },
    disabled: {
      type:Boolean,
      default:false,
    },
  },
  data:function (){
    return {

      autoResize:true,
      offset:null,
      clickAction:false,
      mouseMove:false,
    }
  },

  mounted() {
      this.initialize();
       new ResizeObserver(this.resize).observe(this.$refs.expandableText)
      this.offset = this.$refs.expandableText.offsetHeight - this.$refs.expandableText.clientHeight;
      this.update();
      let height = Math.round(parseFloat( this.$refs.expandableText.style.height ));
      let maxHeight = Math.round(parseFloat(this.$refs.expandableText.style.maxHeight))
      this.addOverflow(height , maxHeight)
  },

  updated(){
    if(this.autoResize){
      this.update();
      let height = Math.round(parseFloat( this.$refs.expandableText.style.height ));
      let maxHeight = Math.round(parseFloat(this.$refs.expandableText.style.maxHeight))
      this.addOverflow(height , maxHeight)
    }

  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  computed:{
    styles:function (){
      let styles = {};
      if(this.autoResize){
        styles.height = this.startMinHeight;
      }
      return styles;
    },
  },
  methods:{
    focus() {
      this.$refs['expandableText'].focus();
    },

    click(val){
      this.clickAction = val;
    },
    addOverflow( height , maxHeight){
      if(height > maxHeight){
        if(this.$refs.expandableText.style.overflow !== 'scroll'){
          this.$refs.expandableText.style.overflow = 'scroll'
        }
      }else {
        if(this.$refs.expandableText.style.overflow !== 'hidden'){
          this.$refs.expandableText.style.overflow = 'hidden'
        }
      }
    },
    update:function (){
      let scrollHeight = Math.round(parseFloat( this.$refs.expandableText.scrollHeight));
      let clientHeight = Math.round(parseFloat(this.$refs.expandableText.clientHeight));
      let height =  clientHeight;
      if(scrollHeight >= clientHeight ){
        height = scrollHeight
      }
      this.$refs.expandableText.style.height = '';
      this.$refs.expandableText.style.height = height  +   this.offset + 'px';
    },
    resize:function (ev){
      if(this.clickAction){
        this.autoResize = false
        this.$refs.expandableText.style.maxHeight = (2 * this.maxHeight) +'px';
      }
      if(!this.autoResize) {
        if(this.$refs.expandableText.style.overflow !== 'auto'){
          this.$refs.expandableText.style.overflow = 'auto'
        }
      }
    },

    initialize:function (){
      this.$refs.expandableText.style.minHeight = (this.startMinHeight) +  'px';
      this.$refs.expandableText.style.maxHeight = (this.maxHeight) + 'px';
    },
    handleInput(val) {
      this.$emit('update:modelValue', val);
      this.$emit('update');
    },
  }
}
</script>

<style scoped >

</style>