<template>
  <div class="">
    <button @click=" onClick() " :class="{'btn-light': !show , 'btn-primary':show } " class="btn  btn-outline-secondary form-control" type="button">
      <i :class="{'filter-light':show, 'filter-black':!show }" class="fa fa-filter"></i></button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "ShowFilterButtom",
  data: function (){
    return {

    }
  },
  methods:{
    ...mapActions({
      'closeTableFilters':'tickets/filters/closeTableFilters',
      'openTableFilters':'tickets/filters/openTableFilters'
    }),
    onClick:function (){
      this.show = !this.show;
      if( !this.show){
        this.closeTableFilters();
      }else {
        this.openTableFilters();
      }
    },
    ...mapMutations(
      {
        setShowFilter:'tickets/filters/setShowFilter',
      }),
  },

  props:{
    "isEmptyFilters":{
      type:Function,
    }
  },
  computed:{
    show:{
      get(){
        return  this.showFilter
      },
      set(value){
        this.setShowFilter(value);
      },
    },
    ...mapGetters({
      "showFilter":  "tickets/filters/showFilter",
    }),
  }
}
</script>

<style scoped>

.filter-light{
  color: white;
}
.filter-black{
  color: rgba(128, 128, 128, 0.99);
}

</style>