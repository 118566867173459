<template>
  <div style="position: relative;">
    <div class="list-group-item" :class="{'alert-danger': step?.type === 'final'}">
      <div class="row" >
        <div class="col-11">
          <span style="cursor: pointer; display: inline-flex;" @click="showEditModal()" >
            <span>{{index+1}}. </span>
            <span style="margin-left: 4px; overflow-wrap: anywhere;"   class="editable-text text-primary" >{{step.name}}</span>
            <popper @click="showEditModal()" v-if="!isStepExist || isStepChanged" content="Шаг не сохранен" class="light" placement="top" :hover="true" :interactive="false"><span style="margin-left: 10px;" class="text-warning">(!)</span></popper></span>
        </div>
        <div class="col-1 float-end text-end">
          <!-- a v-if="element._id" :href="'/products/steps/'+getId(element._id)" target="_blank"><i class="fa fa-pencil"></i></a -->
          <span style="cursor: pointer;" class="text-danger ps-1" @click="showDeleteModalWindow()"><i class="fa fa-trash"></i></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepEditNewModal from "./StepEditNewModal";

import {mapGetters, mapMutations} from "vuex";
export default {
  name: "StepRow",
  components: { StepEditNewModal},
  props:{
    step:{
      type:Object,
      require:true,
    },
    index:{
      type:Number,
      require: true,
    }
  },
  data:function (){
    return {
      showDeleteModal:false,
      showEditNew:false,
    }
  },

  computed:{
    isStepExist(){
      return (typeof this.step._id !== 'undefined' && this.step._id != null);
    },
    isStepChanged(){
      return typeof this.step.stepChange !== 'undefined' && this.step.stepChange;
    },
    ...mapGetters({

      "openedStep":"product/getCurrentOpenedStep",
      "product":"product/getProduct"
    }),
    route(){
      return window.location.href;
    }
  },

  methods:{
    ...mapMutations({
      "selectStepForDelete":"product/selectStepForDelete",
      "selectStep":"product/selectStep",

    }),
    showEditModal(){

      // если редактируем шаг
      if(typeof this.step._id !== 'undefined'){
        if(this.product?._id){
          this.$router.push({
            name: "Step",
            params: {
              step: window.getId( this.step._id),
              product: window.getId(this.product._id),
            }
          })
        }else {
          this.$router.push({
            name: "Step",
            params: {
              step: window.getId( this.step._id),
              product: 'new',
            }
          })
        }

      }else {

        // если редактируем новый шаг
        this.selectStep(this.step);
        if(this.product?._id){
          this.$router.push({
            name: "Step",
            params: {
              step: 'new',
              product: window.getId(this.product._id),
            }
          })
        }else {
          this.$router.push({
            name: "Step",
            params: {
              step: 'new',
              product: 'new',
            }
          })
        }

      }

    },
    showDeleteModalWindow(){
      this.selectStepForDelete(this.step);
    },

  },


}
</script>

<style scoped>
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.list-group-item{
  --bs-list-group-item-padding-y:0.5rem;
  --bs-list-group-item-padding-x:1rem;
  --bs-list-group-color:#212529;
  --bs-list-group-bg:#fff;
  --bs-list-group-border-width:1px;
  --bs-list-group-border-color:rgba(0, 0, 0, 0.125);
}
.list-group-item.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
</style>