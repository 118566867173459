<template>
  <div class="text-center">
    <div >
      <span>{{getHint}}</span>
      <span style="white-space: nowrap;">
          <span v-if="toDate && fromDate" class="ms-1">(<timediff :from="fromDate" :to="toDate"></timediff>)</span>
          <span v-if="canPlay" @click="play" class="text-primary pointer ms-1"><i class="fa fa-play-circle"></i></span>
      </span>
    </div>
  </div>
</template>

<script>

import {getHintStatus} from "../../../../store/main/sip/PhonesFunctions";
import Timediff from "../../../common/timediff";
import moment from "moment";

export default {
  name: "OutgoingStatusInfo",

  emits: ['play'],
  components: {Timediff},
  props:{
    message:{
      type:Object,
      require:true,
    },
    hasPermission: {
      type: Boolean,
      default: null,
    },
  },
  computed:{
    fromDate:function (){
      let date = null;
      if(typeof this.callinfo?.event?.og_client_answer?.timestamp !== 'undefined'){
        try {
          date = moment(this.callinfo?.event?.og_client_answer?.timestamp * 1000).format()
        }catch (e) {}
      }
      return date
    },
    toDate:function (){
      let date = null;
      if(typeof this.callinfo?.event?.end?.timestamp !== 'undefined'){
        try {
          date = moment(this.callinfo?.event?.end?.timestamp * 1000).format()
        }catch (e) {}
      }
      return date
    },
    attachmentid:function (){
      if(typeof this.message.attachments ==='undefined' || typeof this.message.attachments[0] === 'undefined'){
        return null;
      }
      return window.getId(this.message.attachments[0].id);
    },
    callinfo:function (){
      return this.message.headers;
    },
    messageid:function (){
      return window.getId(this.message._id);
    },
    getHint:function (){
      return getHintStatus(this.message?.headers?.status , this.message);
    },
    fileLink: function() {
      return '/message/' + this.messageid + '/attachment/' + this.attachmentid;
    },
    canPlay() {
      return this.attachmentid !== null && this.hasPermission;
    }
  },

  methods: {
    play: function() {
      this.$emit('play', this.fileLink);
    },
  }

}
</script>

<style scoped>
.pointer { cursor: pointer }
</style>