<template>
  <div class="event-block">
    <div class="event-date">{{ date }}</div>
    <div class="event-message">
      Клиент <a :href="buyerLink" target="_blank">{{params.buyer.name}} ({{params.buyer.email}})</a> поднял ставку {{params.price.old}} USD -> {{params.price.new}} USD за 1 IP
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "RaiseBidOnLot",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    buyerLink() {
      return '/tickets/' + window.getId(this.params.buyer.id);
    }
  }
}
</script>

<style scoped>

</style>