<template>
  <div class="py-5 col-md-6 m-auto" id="template-edit-form">
    <div class="mb-3">
      <label for="input-name" class="form-label">Название</label>
      <input type="text" v-model="name" class="form-control" name="name" id="input-name" required>
    </div>
    <div class="mb-3">
      <label for="input-language" class="form-label">Язык</label>
      <select v-model="language" class="form-control" name="language" id="input-language">
        <option :value="null">—</option>
        <option value="RU">Русский</option>
        <option value="EN">Английский</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="input-urls-faq" class="form-label">FAQ</label>
      <input type="url" pattern="https?://.+" v-model="urls.faq" class="form-control" name="urls-faq" placeholder="Ссылка на FAQ" id="input-urls-faq" required>
    </div>
    <div class="mb-3">
      <label for="input-urls-glossary" class="form-label">Глоссарий</label>
      <input type="url" pattern="https?://.+" v-model="urls.glossary" class="form-control" name="urls-glossary" placeholder="Ссылка на глоссарий" id="input-urls-glossary" required>
    </div>
    <div class="mb-3">
      <popper content="Группа для отправки уведомлений о событиях в проекте" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <label for="input-rocket-notifygroup" class="form-label">Группа Rocket.Chat</label>
      </popper>
      <input type="text" v-model="rocket.notifygroup" class="form-control" name="rocket-notifygroup" placeholder="#group" id="input-rocket-notifygroup" required>
    </div>
    <div class="mb-3">
      <label class="form-label">Провайдер в BILLmanager</label>
      <input type="text" class="form-control" v-if="billmanager.loading" disabled/>
      <multiselect  placeholder="Проект не связан с провайдерами BILLmanager"
                    v-model="billmanager.providers"
                    :mode="'tags'"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="false"
                    :object="true"
                    :delay="1"
                    :options="updateProjects"
                    v-else
      >
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div :class="{'project-class': option.type === 'project' , 'department-class': option.type === 'department'}" class="multiselect-tag is-user" >
            <span>{{ option.label }} </span>
            <span
                v-if="!disabled"
                class="multiselect-tag-remove"
                @mousedown.prevent="handleTagRemove(option, $event)"
            >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
          </div>
        </template>
      </multiselect>
    </div>
    <div class="mb-3">
      <div class="form-check">
        <label class="form-check-label" for="ipspam">IP SPAM</label>
        <input class="form-check-input" type="checkbox" v-model="ipspam" id="ipspam">
      </div>
      <div class="text-muted" style="font-size: 11px">Активировать кнопку IP SPAM, которая переносит тикеты в папку "Конкуренты"</div>
    </div>
    <div class="mb-3">
      <div>Шаблон приветствия</div>
      <div class="row">
        <div class="col-6">
          <label for="greeting-template" style="font-size: 12px;" class="text-muted">RU</label>
          <expandable-text-area
              :aria-class="{'form-control':true}"
              id="greeting-template"
              v-model="greetingtemplate"
              placeholder="Здравствуйте,"
          ></expandable-text-area>
        </div>
        <div class="col-6">
          <label for="greeting-template-en" style="font-size: 12px;" class="text-muted">EN</label>
          <expandable-text-area
              :aria-class="{'form-control':true}"
              id="greeting-template-en"
              v-model="greetingtemplate_en"
              placeholder="Hello,"
          ></expandable-text-area>
        </div>
      </div>
      <div class="form-text">{name} будет заменено на имя контакта</div>
    </div>

    <div class="double-buttons-group">
      <div>
        <a class="btn btn-secondary" href="/projects/">Назад</a>
      </div>
      <div>
        <button class="btn btn-primary" @click="updateProject" :disabled="updateProjectDisabled">Изменить</button>
      </div>
    </div>
    <div class="mb-3 mt-4">
      <h6>Email алиасов для обратных ответов</h6>
      <ul class="list-group" id="project-emails">
        <li class="list-group-item">
          <div class="input-group">
            <input type="email" class="form-control" :class="{'is-invalid': errors.newEmail}" placeholder="mail@example.com" aria-describedby="validationNewEmailFeedback" v-model="fields.newEmail" :disabled="savingEmail" ref="newEmailInput">
            <popper content="Добавить email в проект" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
              <button class="btn btn-outline-success" type="button" id="button-addon1" :disabled="newEmailDisabled" @click="addEmail(fields.newEmail, 'emails')">
                <i class="fa fa-plus-square pe-1" aria-hidden="true"></i> Добавить
              </button>
            </popper>
            <div id="validationNewEmailFeedback" class="invalid-feedback" v-if="errors.newEmail">{{errors.newEmail}}</div>
            <div id="validationNewEmailWarning" class="invalid-warning text-warning"  v-if="errors.newEmailWarning" >{{errors.newEmailWarning}}</div>
          </div>
        </li>
        <project-form-email
            v-for="email in saved.emails"
            :email="email"
            :main="(email === saved.primarymail)"
            :signature="getSignature(email)"
            :emailparams="getEmailparams(email)"
            type="emails"
            action="update"
            :project="project"
            :smtp="smtp"
            :ats-endpoints="atsEndpoints"
            :callback="{changePrimaryEmail: changePrimary,
                        deleteEmail: deleteEmail,
                        updateSignature: updateSignature}"
            @deleteAlias="showDeleteModalFunction"
        ></project-form-email>
      </ul>
    </div>
    <hr>
    <email-patterns :saved-patterns="saved.patterns" @update="updatePatterns" :confirm-delete="true"></email-patterns>
  </div>
  <modal-window v-if="showDeleteModal" @close="showDeleteModal = false" @success="deleteAlias" ok-button-style="btn-danger">
    <template v-slot:header>
      <h5 class="modal-title text-danger">Удаление алиаса</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите навсегда удалить алиас {{aliasToDelete.email}}?</span>
    </template>
  </modal-window>
  <alert-list></alert-list>
</template>

<script>
import ProjectFormEmail from "./ProjectFormEmail";
import {CrmApi} from "../../library/CrmApi";
import ExpandableTextArea from "../common/textarea/ExpandableTextArea";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import AlertList from "../ticket/Alert/AlertList";
import EmailPatterns from "./EmailPatterns";

import Multiselect from "@vueform/multiselect";
import {Project} from "../../library/api/Project";
export default {
  name: "ProjectCreateForm",

  components: {
    Multiselect,
    ExpandableTextArea,
    ProjectFormEmail,
    ModalWindow,
    AlertList,
    EmailPatterns,
  },

  props: {
    project: {
      require: true,
    }
  },

  data: function() {
    return {
      allTagsArray:[],
      name: '',
      urls: {
        faq: '',
        glossary: '',
      },
      rocket: {
        notifygroup: ''
      },
      deletetag:{
        tag:''
      },
      billmanager: {
        id: null,
        providers: [],
        departments:[],
        projects: [],
        loading: false,
      },
      greetingtemplate: '',
      greetingtemplate_en: '',
      language: null,
      primarymail: '',

      saved: {
        emails: [],
      },
      fields: {
        newEmail: '',
      },
      errors: {
        newEmail: false,
        newEmailWarning:false,
      },
      savingEmail: false,
      savingProject: false,
      showDeleteModal: false,
      aliasToDelete: null,
      smtp: [],
      ipspam: false,
      _projectChanged: false,
      atsEndpoints: [],
    }
  },

  created() {
    Project.getAtsEndpoints().then((result) => {
      if (typeof result?.data?.data !== 'undefined' && Array.isArray(result?.data?.data)) {
        this.atsEndpoints = result.data.data.map(item => item?.resource ?? null).filter(item => !!item);
      }
    })
  },

  mounted: function() {
    if(typeof this.project.lang === 'undefined') {
      this.project.lang = null;
    }
    if(typeof this.project.deletetag === 'undefined'){
      this.project.deletetag = ""
    }
    this.saved = this.project;
    this.name = this.project.name;
    this.language = this.project.lang;
    if(typeof this.project.urls !== 'undefined') {
      for(let url in this.urls) {
        this.urls[url] = (this.urls[url] !== this.project.urls[url]) ? this.project.urls[url] : '';
      }
    }
    this.greetingtemplate = this.project.greetingtemplate;
    this.greetingtemplate_en = this.project.greetingtemplate_en;
    this.rocket.notifygroup = this.project?.rocket?.notifygroup ?? '';
    if(typeof this.project.billmanager === 'undefined' || this.project.billmanager === null){
      this.project.billmanager = {};
    }
    this.billmanager.id = this.project?.billmanager?.id ?? null;

    if( typeof this.project?.billmanager?.providers === 'undefined' ||  this.project?.billmanager?.providers === null ) {
      this.project.billmanager.providers = [];
    }
    let providersHashmap = {};
    if( this.project?.billmanager?.providers){

      for (let el of this.project.billmanager.providers){
        providersHashmap[el + 'project'] = 1;

      }
    }

    if( this.project?.billmanager?.departments){
      for (let el of this.project.billmanager.departments){
        providersHashmap[el + 'department'] = 1;
      }
    }

    if(typeof this.billmanager.id !== 'undefined' && this.billmanager.id != null ){
      providersHashmap[this.billmanager.id + 'project'] = 1;
    }
    this.ipspam = this.project.ipspam;

    this.primarymail = this.project.primarymail;
    this.saved.emails = Object.values(this.project.emails ?? {}).sort();
    this.saved.patterns = Object.values(this.project.emailpatterns ?? {});
    if(typeof this.project.deletetag !== 'undefined' ){
      this.deletetag.tag = this.project.deletetag;
      if(this.deletetag.tag !== ''){
        this.allTagsArray.push({
          value:this.deletetag.tag,
          label:this.deletetag.tag,
        });
      }

    }
    CrmApi.getSmtpList({}).then((result) => {
      if (typeof result.data.data !== 'undefined') {
        this.smtp = result.data.data;
      }
    });
    this.billmanager.loading = true;
    CrmApi.getBillmanagerProjects().then((result) => {
      this.billmanager.projects = result.data.projects;
      this.billmanager.departments = result.data.departments;

      this.billmanager.providers =[]

      for (let project of   this.billmanager.projects){
        let key = project.id + 'project';
        let object = {
          value: key,
          id:project.id,
          label: project.name,
          type:'project',
        }
        if(typeof providersHashmap[key] !== 'undefined'){
          this.billmanager.providers.push(object)
        }
      }

      for (let project of   this.billmanager.departments){
        let key = project.id + 'department';
        let object = {
          value: key,
          id:project.id,
          label: "Отдел: " +project.name,
          type:'department',
        }
        if(typeof providersHashmap[key] !== 'undefined'){
          this.billmanager.providers.push(object)
        }
      }

    }).catch(() => {
      this.$store.commit('errorPush', 'Ошибка при загрузке проектов BILLmanager');
    }).finally(() => {
      this.billmanager.loading = false;
    });
  },

  computed: {
    newEmailDisabled: function() {
      let validation = this.emailValidation(this.fields.newEmail,
          this.$refs.newEmailInput,
          [ ...this.saved.emails ]);

      if(validation === true) {
        this.errors.newEmail = false;
        this.errors.newEmailWarning = "Для добавления почты необходимо нажать \"Добавить\"";
      } else {
        this.errors.newEmailWarning = false;
        this.errors.newEmail = validation;
        validation = false;
      }

      return !validation;
    },
    isNotifygroupEqial(){
      let np =  this.project?.rocket?.notifygroup;
      if(typeof np ==='undefined' || np === null){
        np = ''
      }
      return  this.rocket.notifygroup !== np
    },
    fieldsWasChanged:function (){
      // _projectChanged нужен чтобы обновился fieldsWasChanged когда после изменения перезаписывается props project
      let changed = this._projectChanged;
      this._projectChanged = false;
      let fieldsWasChanged = false;

      if(this.name !== this.project.name ||
          this.language !== this.project.lang ||
          this.greetingtemplate !== this.project.greetingtemplate ||
          this.greetingtemplate_en !== this.project.greetingtemplate_en ||
          this.isNotifygroupEqial
         ) {
        fieldsWasChanged = true;
      }
      if(!fieldsWasChanged && this.deletetag.tag !== this.project.deletetag){
        fieldsWasChanged = true;
      }

      if(!fieldsWasChanged && typeof this.project.urls !== 'undefined') {
        for(let url in this.urls) {
          if(this.urls[url] !== this.project.urls[url]) {
            if(this.urls[url] !== '' || this.project.urls[url] !== null) {
              fieldsWasChanged = true;
              break;
            }
          }
        }
      } else if(this.urls.faq !== '' || this.urls.glossary !== '') {
        fieldsWasChanged = true;
      }

      if (this.ipspam != this.project.ipspam) {
        fieldsWasChanged = true;
      }
      if(
          this.billmanager.providers.length !== this.project.billmanager.providers.length ||
          this.billmanager.providers.filter(x => !this.project.billmanager.providers.includes(x)).length > 0
      ) {
        fieldsWasChanged = true;
      }

      return fieldsWasChanged;
    },
    updateProjectDisabled: function() {
      return (!this.name || !this.fieldsWasChanged || this.savingProject);
    }
  },

  methods: {
    updateProjects(query){
      return (new Promise(
            (resolve)=>{
              let checkBillmanagerLoaded = () => {
                if( this.billmanager.loading ){
                  setTimeout(checkBillmanagerLoaded,100);
                } else {
                  resolve();
                }
              };
              checkBillmanagerLoaded();
            }))
          .then(()=>{
                  let list = [];
                  for(let project of this.billmanager.projects){
                    if( query == null || project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ){
                      let key = project.id + 'project';
                      let object = {
                        value: key,
                        id:project.id,
                        label: project.name,
                        type:'project',
                      }
                      list.push(object)


                    }
                  };
                 for(let project of  this.billmanager.departments){
                    if( query == null || project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ){
                      let key = project.id + 'department';
                      let object ={
                        value: key,
                        id:project.id,
                        label: "Отдел: "+ project.name,
                        type:'department',
                      }
                      list.push(object)

                    }
                  };

                  return list;
          })
    },

    updateTags(query){
      return CrmApi.getTags(query).then((result)=>{
        this.allTagsArray = [];
        let unique = {};
        if(this.deletetag.tag !== null && this.deletetag.tag !== ''){
          this.allTagsArray.push({
            value:this.deletetag.tag,
            label:this.deletetag.tag,
          });
          unique[this.deletetag.tag] = this.deletetag.tag;
        }

        let tags = result.data.data;
        for (let key in tags){
          let el = tags[key];
          if(typeof unique[el.tags] === 'undefined'){
            unique[el.tags]= el.tags;
            this.allTagsArray.push({
              value:el.tags,
              label:el.tags,
            });
          }
        }

        return  this.allTagsArray;
      }).catch(()=> {
        return  this.allTagsArray;
      })
    },
    showDeleteModalFunction(email, type){
      this.showDeleteModal = true;
      this.aliasToDelete = {email: email, type: type};
    },
    emailValidation(model, input, list) {
      let validation = false;

      if(!model){}
      else if(!input.value.match(/\S+@\S+\.\S+/)) {
        validation = 'Поле должно содержать корректный email';
      } else if(list.indexOf(model) > -1) {
        validation = 'Такой email уже добавлен в проект';
      } else {
        validation = true;
      }

      return validation;
    },

    changePrimary: function(newPrimaryEmail) {
      this.saved.primarymail = newPrimaryEmail;
      return this.update({primarymail: newPrimaryEmail}).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.saved.primarymail = this.project.primarymail;
      });
    },

    deleteEmail: function(email, type) {
      let emails = [ ...this.saved[type] ];
      emails = emails.filter((val) => { return val !== email; })
      let params = {};
      params[type] = emails;

      return this.update(params).then(() => {
        this.saved[type] = emails;
        this.$store.commit('successPush', 'Алиас ' + email + ' удален');
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => this.showDeleteModal = false);
    },

    deleteAlias: function () {
      if (typeof this.aliasToDelete !== 'undefined') {
        return this.deleteEmail(this.aliasToDelete.email, this.aliasToDelete.type);
      }
    },

    updatePatterns: function(patterns) {
      return this.update({emailpatterns: patterns}).then(() => {
        this.saved.patterns = patterns;
      });
    },

    updateSignature: function(email, signature, emailParams = null) {
      let signatures = { ...this.saved.signature };
      signatures[email] = signature;
      let params = { ...this.saved.emailparams };
      let data = {signature: signatures};
      if (emailParams) {
        params[email] = emailParams;
        data['emailparams'] = params;
        for (const dataKey in data['emailparams']) {
          let val = data['emailparams'][dataKey];
          if (typeof val['smtp'] !== 'undefined' && val['smtp'] !== null) {
            val['smtp'] = window.getId(val['smtp']);
          }
        }
      }

      return this.update(data).then(() => {
        this.saved.signature = signatures;
        if (emailParams) {
          this.saved.emailparams = params;
        }
        this.$store.commit('successPush', 'Изменения сохранены');
      });
    },

    getSignature: function(email) {
      if(typeof this.project.signature !== 'undefined' && typeof this.project.signature[email] !== 'undefined') {
        return {
          ru: this.project.signature[email].ru ?? '',
          en: this.project.signature[email].en ?? ''
        };
      } else {
        return {ru: '', en: ''};
      }
    },

    getEmailparams: function (email) {
      if (typeof this.project.emailparams !== 'undefined' && typeof this.project.emailparams[email] !== 'undefined') {
        return {
          name: this.project.emailparams[email].name ?? '',
          smtp: window.getId(this.project.emailparams[email].smtp ?? ''),
          phone: this.project.emailparams[email].phone ?? '',
          additional_phones: this.project.emailparams[email]?.additional_phones ?? [],
          outphoneendpoint: this.project.emailparams[email]?.outphoneendpoint ?? null,
        };
      } else {
        return {name: '', smtp: '', phone: '', additional_phones: [], outphoneendpoint: null};
      }
    },

    addEmail: function(email, type) {
      if(type === 'emails') {
        this.savingEmail = true;
      }

      let emails = [ ...this.saved[type] ];
      emails.push(email);

      let params = {};
      params[type] = emails;

      this.update(params).then((response) => {
        if(type === 'emails') {
          this.fields.newEmail = '';
          this.savingEmail = false;
        }

        this.saved[type] = emails;
        this.$store.commit('successPush', 'Email добавлен в проект');
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));

        if(type === 'emails') {
          this.savingEmail = false;
        }
      });
    },

    updateProject: function() {
      this.savingProject = true;
      let params = {};
      if(this.name !== this.project.name) {
        params.name = this.name;
      }
      if(typeof this.project.urls !== 'undefined') {
        params.urls = {};
        for(let url in this.urls) {
          if(this.urls[url] !== this.project.urls[url]) {
            params.urls[url] = this.urls[url];
          }
        }
      } else {
        params.urls = this.urls;
      }
      if(this.greetingtemplate !== this.project.greetingtemplate) {
        params.greetingtemplate = this.greetingtemplate;
      }
      if(this.greetingtemplate_en !== this.project.greetingtemplate_en) {
        params.greetingtemplate_en = this.greetingtemplate_en;
      }

      if(this.language !== this.project.lang) {
        params.lang = this.language;
      }
      params.billmanager = null;
      if(this.billmanager?.providers){
        params.billmanager = {
          providers:[],
          departments:[],
        };
        for (let el of this.billmanager?.providers){
          if(el.type === 'project'){
            params.billmanager.providers.push(el.id)
          }else {
            params.billmanager.departments.push(el.id)
          }
        }

      }

      if (this.rocket.notifygroup !== this.project?.rocket?.notifygroup) {
        params.rocket = {notifygroup: this.rocket.notifygroup};
      }
      if(typeof this.deletetag.tag === 'undefined' || this.deletetag.tag === null){
        this.deletetag.tag = '';
      }
      params.deletetag = this.deletetag.tag.trim();

      if (this.ipspam !== this.project.ipspam) {
        params.ipspam = this.ipspam;
      }

      this.update(params).then((result) => {
        this.$store.commit('successPush', 'Проект обновлен');
        Object.keys(result.data.project).forEach((key) => {
          this.project[key] = result.data.project[key];
        });
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error), 8000);
      }).finally(() => {
        this.savingProject = false;
      });
    },

    update: function(params) {
      return CrmApi.call('/project/' + encodeURIComponent(this.project._id), params);
    }
  }
}
</script>

<style scoped>
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.invalid-warning{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
.department-class{
  background-color:  #c158c1;
}
</style>