<template>
  <diadoc-event :event-id="eventId" :date="date" :from="params.company" :to="params.recipientCompany">
    <template #body>
      <b>Уведомление: Контрагент принял приглашение для обмена документами</b> <a :href="params.link" target="_blank" class="text-decoration-none">&#10095;&#10095;</a>
    </template>
  </diadoc-event>
</template>

<script>
import parseEvent from "../EventParser";
import DiadocEvent from "./DiadocEvent";

export default {
  name: "Accept",

  components: {DiadocEvent},

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event)
}
</script>

<style scoped>

</style>