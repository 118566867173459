<template>
  <div v-if="Object.values(notification)" class="text-muted sub-notify pt-0">

    <span v-if="byRange">
      интересуют сети
      <template v-if="notification.range_from && notification.range_to">/{{notification.range_from}} - /{{notification.range_to}}</template>
      <template v-else-if="notification.range_from">/{{notification.range_from}} и больше</template>
      <template v-else-if="notification.range_to">/{{notification.range_to}} и меньше</template>
      <template v-if="byPrice">, </template>
    </span>

    <span v-if="byPrice">
      цена
      <template v-if="notification.price_from && notification.price_to">${{notification.price_from}} - ${{notification.price_to}}</template>
      <template v-else-if="notification.price_from">≥${{notification.price_from}}</template>
      <template v-else-if="notification.price_to">≤${{notification.price_to}}</template>
      /IP
    </span>

  </div>
</template>

<script>

export default {
  name: "SubnetNotification",

  props: {
    notification: Object,
  },

  data: function() {
    return {
      loading: false,
      showCopyMenu:false,
    }
  },

  computed: {
    byRange() {
      return (this.notification.range_from || this.notification.range_to);
    },

    byPrice() {
      return (this.notification.price_from || this.notification.price_to);
    }
  },

  methods: {
  },

}
</script>

<style scoped>
.sub-notify {
  font-size: 0.65em;
}
.sub-notify:first-letter {
  text-transform: uppercase;
}
</style>