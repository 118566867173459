<template>
  <div>
    <button class="btn btn-default btn-success form-control" @click="openModal()" >➕ Добавить</button>
  </div>
</template>

<script>
export default {
  name: "AddButton",
  props:{

  },
  methods:{
    openModal:function (){

      this.$store.dispatch('offerHistory/openAddModal');
      this.$nextTick(()=>{
        this.$store.commit('offerHistory/setPopperClientOpen', {})
      })


    }
  }
}
</script>

<style scoped>

</style>