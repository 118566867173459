<template>
  <div class="offers" v-esc:offersEsc="close">
    <div class="manage px-2">
      <i role="button" class="fa fa-close" @click="close"></i>
    </div>

    <search />

    <div v-if="loading" class="loading">
      <loading-list />
    </div>

    <div class="offers-table">
      <component :is="component" @setOffer="setOffer" />

      <div class="py-1 pe-3">
        <paginate
            id="offer-paginate"
            @changePage="setPagination($event)"
            :update-by-object="true"
            :paginator="paginator"
            ref="paginate"
        />
      </div>
    </div>

    <modal-confirm-offer
      v-if="confirm.show"
      :offer="confirm.offer"
      :type="type"
      @close="closeOfferConfirm"
      @confirm="successOfferConfirm"
    />
  </div>
</template>

<script>
import Search from "./Filters/Search";
import Buy from "./Buy/Buy";
import Sell from "./Sell/Sell";
import {mapActions, mapGetters} from "vuex";
import Paginate from "../../paginate/Paginate";
import LoadingList from "../List/LoadingList";
import ModalConfirmOffer from "./ModalConfirmOffer";

export default {
  name: "Offers",

  components: {
    Paginate,
    Search,
    Buy,
    Sell,
    LoadingList,
    ModalConfirmOffer
  },

  data() {
    return {
      confirm: {
        show: false,
        offer: null,
      }
    }
  },

  computed: {
    type() {
      return this.$store.getters['thread/offers/offersTableType'] ?? '';
    },

    component() {
      return this.type === 'buyer' ? 'sell' : 'buy';
    },

    loading() {
      return this.$store.getters['thread/offers/getLoading'];
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    },

    ...mapGetters({
      paginator: 'thread/offers/getPaginate',
      updateList: 'thread/offers/updateList',
    })
  },

  mounted() {
    this.setSearchString();
    this.getOffers();
  },

  methods: {
    setSearchString() {
      let fields = this.ticket.assigned_product.fields ?? {};
      let searchString = '';

      if(this.type === 'seller') {
        if(fields?.type === 'PI') {
          searchString = 'PI';
        }
      } else {
        if(typeof fields?.subnets === 'object' && fields.subnets.length) {
          let subnet = fields.subnets[0];
          if(subnet.type === 'PA') {
            searchString = 'PA';
          }
        }
      }

      if(searchString !== '') {
        this.$store.commit('thread/offers/filters/setSearchString', searchString);
      }
    },

    close() {
      let close = this.$store.getters['thread/offers/offersCloseCallback'];
      if(typeof close === 'function') {
        close();
      }
    },

    setOffer(offer) {
      offer.person = this.$store.getters['thread/offers/getPersonFromTicket'](offer);
      this.confirm.offer = offer;
      this.confirm.show = true;
      this.$store.commit('setActiveWindow', 'confirmOfferModal');
    },

    closeOfferConfirm() {
      this.confirm.show = false;
      this.confirm.offer = null;
      this.$store.commit('removeActiveWindow', 'confirmOfferModal');
    },

    successOfferConfirm() {
      this.$store.commit('thread/offers/setConfirmedOffer', this.confirm.offer);
      this.close();
    },

    ...mapActions({
      getOffers: 'thread/offers/getOffers',
      setPagination: 'thread/offers/setPagination',
      updateOffersList: 'thread/offers/updateOffersList',
    })
  },

  watch:{
    paginator:{
      handler(val){
        const updateRefPaginate = () => {
          let updated = false;
          if( this.$refs.paginate != null ){
            this.$refs.paginate.update(val);
            updated = true;
          }
          return updated;
        }
        if( !updateRefPaginate() ){
          this.$nextTick(() => {
            updateRefPaginate();
          })
        }
      },
      deep:true
    }
  }
}
</script>

<style scoped>
.offers {
  position: relative;
  flex-grow: 1;
  background-color: #cecece;
  border-radius: 5px;
  height: fit-content;
  padding: 4px;
}
.offers-table {
  max-height: calc(100vh - 140px);
  overflow-x: auto;
}
.offers > .manage {
  height: 28px;
}
.offers > .offers-table {
  background-color: white;
  margin: unset;
}
:deep(.offer-selected) {
  background-color: #c5ffbb;
}
.loading:deep(.vld-overlay) {
  margin-top: 3em;
}
</style>