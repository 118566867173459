<template>
    <history-row   :class="{'loading-row':isLoading}" class="offer-table-text" v-for="[key, offerHistory] in getList" :offer-history="offerHistory" :id="key" ></history-row>
</template>

<script>
import HistoryRow from "./HistoryRow";
import {mapGetters} from "vuex";
export default {
  name: "HistoryBody",
  components: {HistoryRow},
  computed:{
    ...mapGetters({
      'getList':'offerHistory/getOffersHistoryList',
      "isLoading":"offerHistory/getLoading"

    })
  },

}
</script>

<style scoped>
.loading-row{
  pointer-events: none;
  opacity: 50%;
}
</style>