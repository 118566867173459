export function getTemplateEditLink(id ){
    return window.location.origin + "/template/" + encodeURIComponent(id);
}

export function getProductEditLink(id){
    return window.location.origin + "/products/" +encodeURIComponent(id);
}
export function getStepEditLink(id, productId){
    return window.location.origin + "/products/"+ encodeURIComponent(productId) +"/step/"  + encodeURIComponent(id);
}
export function getUserMessagesLink(query, project = 'all', alias = 'new') {
    let queryString = typeof query !== 'undefined' && query !== null ? ("?"+ Object.keys(query).map(key => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
    }).join('&')) : "";
    return window.location.origin + '/tickets/' + project + '/' + alias + queryString;
}

export function getTicketLink({_id, ticket_id}) {
    let message = _id && window.getId(_id) ? '?message=' + encodeURIComponent(window.getId(_id)) : '';
    return window.location.origin + '/tickets/' + encodeURIComponent(window.getId(ticket_id)) + message;
}

export function getFilterLink(id) {
    return window.location.origin + '/filter/' + encodeURIComponent(window.getId(id))
}