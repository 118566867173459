<template>
  <tr class="events-table-text">
    <td v-if="offerEvent?.rowspan"  :rowspan="offerEvent.rowspan">
      <div>{{date}}</div>
    </td>
    <td v-if="offerEvent?.rowspan"  :rowspan="offerEvent.rowspan" >
      <div>
        <a style="color: black; text-decoration: none" :href="hrefSubnet" target="_blank">{{subnet}}</a>
      </div>
    </td>
    <td>
      <div>
        {{offerEvent.subject.type}}:
      </div>
      <div style="display: flex; justify-content: center;">
        <object-cell :object="object" :type="type" style="margin-right: 4px"></object-cell>
        <relations  :relation_path="relation_path"></relations>
      </div>
    </td>
    <td>
      <changes v-if="changes.length > 0 && offerEvent.type === 'UPDATE'" :changes="changes"></changes>
      <div  v-if="offerEvent.type === 'DELETE'" class="p-0  mb-0 alert alert-danger margin">Объект {{offerEvent.subject.object}} удален</div>
      <div v-if="offerEvent.type === 'INSERT'" class="p-0  mb-0 alert alert-success margin">Объект {{offerEvent.subject.object}} создан</div>
    </td>
  </tr>
</template>

<script>
import getFields from "../source/eventsFields";
import Changes from "../DataCells/Changes";
import ObjectCell from "../DataCells/ObjectCell";
import Relations from "../DataCells/Relations";

export default {
  name: "EventsRow",
  components: {Relations, ObjectCell, Changes},
  props:{
    offerEvent:{
      type:Object,
      require:true,
    }
  },
  setup(props, context){
    const {date, subnet, object, type, changes, hrefSubnet, relation_path} = getFields(props, context);
    return {date, subnet, object, type, changes, hrefSubnet, relation_path};
  }
}
</script>

<style scoped>
.margin{
  margin-left: 8%;
  width: 86%;
}
td{
  padding-top: 5px;
  padding-bottom: 5px;
}
.events-table-text{
  text-align: center;
  vertical-align: middle;
}
:deep(.smile-m) {
  margin-left: 4px;
  margin-right: 4px;
}
tbody:hover td[rowspan], tr:hover td {
  background: rgba(0, 0, 0, 0.075);
}
</style>