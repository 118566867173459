<template>
  <button class="btn btn-light btn-outline-dark col-12" style="right: 0.5em;" @click="tasksFilter = !tasksFilter">
    <i class="fa fa-tasks"></i>
    <span v-if="!tasksFilter"> Задачи</span>
    <span v-else> Вся переписка</span>
  </button>
</template>

<script>
export default {
  name: "TasksFilter",

  computed: {
    tasksFilter: {
      get() {
        return this.$store.getters['thread/tasksFilter'];
      },
      set(value) {
        this.$store.commit('thread/setTasksFilter', value);
      }
    },
  },
}
</script>

<style scoped>

</style>