<template>
<div>
  <timediff :from="from" :to="to" ></timediff>
</div>
</template>

<script>
import Timediff from "../../common/timediff";
import moment from "moment";
export default {
  name: "CallDuretion",
  components: {Timediff},
  props:{
    fromUnix:{
      default:null,
    }
  },
  data:function (){
    return {
      from:null,
      to:null,
      timer:null,
    }
  },
  mounted() {
      if(this.fromUnix !== null){
        this.from = moment(this.fromUnix*1000).format();
      }else {
        this.from = moment().format();
      }

      this.to = moment().format();
      this.timeS();
  },
  methods:{
    timeS:function (){
     clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.to = moment().format();
        this.timeS();
      }, 1000)
    }
  },
}
</script>

<style scoped>

</style>