import {createStore} from "vuex";
import {shareState} from "./composition";
import * as actions from "./actions";
import * as getters from "./getters";
import commonMutations from "./mutations";

export default createStore({
    modules:{
    },
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    plugins: []
})
