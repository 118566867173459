<template>
  <div class="d-flex align-content-center justify-content-center">
    <fieldset class="form-group mt-5 col-4">
      <div class="form-group mb-4">
        <label>Имя пользователя</label>
        <input type="text" name="username" v-model="form.login" class="form-control" :disabled="!canSubmit" />
      </div>
      <div class="form-group mb-4">
        <div>
          <label>ФИО</label>
          <popper content="ФИО для отображения в подписи" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
            <input type="text" name="username" v-model="form.name" class="form-control" disabled/>
          </popper>
        </div>
        <div>
          <span class="text-muted small">Задается и изменяется только в LDAP. Соответствует атрибуту sn</span>
        </div>
      </div>
      <div class="form-group mb-4">
        <div>
          <label>Добавочный номер</label>
          <popper content="Добавочный номер для прямого звонка" class="light" :hover="true" :interactive="false" :arrow="true" placement="top">
            <input type="text" name="username" v-model="form.phoneext" class="form-control" disabled/>
          </popper>
        </div>
        <div>
          <span class="text-muted small">Задается и изменяется только в LDAP. Соответствует атрибуту telephoneNumber</span>
        </div>
      </div>
      <div class="form-group mb-4">
        <div class="form-check mb-0">
          <input class="form-check-input" type="checkbox" v-model="disable_notify_unsorted" id="disable_notify_unsorted">
          <label class="form-check-label" for="disable_notify_unsorted">
            Отключить уведомления по неразобранным
          </label>
        </div>
      </div>
      <div class="form-group mb-4" v-if="user.role != null">
        <div>
          <span>Роли при последней авторизации: </span>
          <roles-list :role="user.role"></roles-list>
        </div>
        <div>
          <span class="text-muted small">Задается и изменяется только в LDAP</span>
        </div>
      </div>
      <div class="form-group mb-4">
        <label>Проекты</label>

        <ul class="list-group py-1" id="project-emails">
          <project-item v-for="project in projects"
                        :user="user"
                        :project="project"
                        :key="project._id"
                        :checked="projectChecked(project._id)"
                        :messagesFrom="messagesFrom(project._id)"
                        :userProject="getUserProject(project._id)"
                        :userProjectSettings="getUserProjectSettings(project._id)"
                        :canSubmit="canSubmit"
                        @checkbox="projectCheckbox"
                        @changeProjectData="projectMessagesFrom"
                        @changeProjectSettings="projectInputData"
          ></project-item>
        </ul>

      </div>

      <div class="double-buttons-group">
        <div>
          <a class="btn btn-secondary" href="/users/">Назад</a>
        </div>
        <div>
          <button type="submit" class="btn btn-primary" @click="update" :disabled="disabledUpdate || saving || !canSubmit">Сохранить</button>
        </div>
      </div>
    </fieldset>
    <alert-list></alert-list>
  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import ProjectItem from "./ProjectItem";
import popper from "../../library/vue3-popper/popper.esm";
import rolesList from "./RolesList";
import AlertList from "../ticket/Alert/AlertList";

export default {
  name: "UserEditForm",

  components: {
    AlertList,
    'project-item': ProjectItem,

    popper,
    rolesList
  },

  props: {
    user: {
      require: true
    },

    roles: {
      require: true
    },

    projects: {
      require: true
    },
  },

  data: function() {
    return {
      form: {},
      actualProjects: null,
      actualProjectsSettings: null,

      canSubmit: false,
      saving: false
    }
  },

  created: function() {
    if (typeof this.user.settings === 'undefined' || this.user.settings === null) {
      this.user.settings = {}
    }
    this.form = { ...this.user };
    this.canSubmit = this.user.canSubmit;
    this.actualProjects = [ ...this.user.projects ] ?? [];
  },

  computed: {
    disabledUpdate: function() {
      return (this.actualProjects === null &&
              this.actualProjectsSettings === null &&
              this.form.login === this.user.login &&
              this.form.role === this.user.role);
    },
    disable_notify_unsorted: {
      get() {
        return this.user?.settings?.disable_notify_unsorted ?? false;
      },
      set(value) {
        if(typeof this.user?.settings === 'undefined' || this.user?.settings === null) {
          this.user.settings = {}
        }
        this.user.settings.disable_notify_unsorted = value;
      }
    },
  },

  methods: {
    projectChecked: function(project) {
      return (typeof this.actualProjects !== 'undefined' &&
          this.actualProjects.find(u_project => this.getId(u_project._id) === project) !== undefined);
    },

    messagesFrom: function(project) {
      if(this.user.projects.find(u_project => this.getId(u_project._id) === project) !== undefined) {
        return this.user.projects.find(u_project => this.getId(u_project._id) === project).messages_from ?? null;
      } else {
        return null;
      }
    },

    getUserProject: function (project) {
      return this.user.projects.find(u_project => this.getId(u_project._id) === project);
    },

    getUserProjectSettings: function (project) {
      return typeof this.user.projectsettings !== 'undefined' &&
              typeof this.user.projectsettings[project] !== 'undefined'
              ? this.user.projectsettings[project]
              : {};
    },

    projectCheckbox: function(data) {
      if(this.actualProjects === null) {
        this.actualProjects = [ ...this.user.projects ] ?? [];
      }
      if (this.actualProjectsSettings === null) {
        this.actualProjectsSettings = { ...this.user.projectsettings } ?? {};
      }

      if(!data.checkbox) {
        if(this.actualProjects.find(a_proj => this.getId(a_proj._id) === data.project._id)) {
          this.actualProjects = this.actualProjects.filter(p => p._id !== data.project._id);
        }
        if (typeof this.actualProjectsSettings[data.project._id] !== 'undefined') {
          delete this.actualProjectsSettings[data.project._id];
        }
      } else {
        let object = Object.assign({}, data.project);
        if (object.messages_from) {
          object.messages_from = data.form.messages_from;
        }
        object.hidden = data.form.hidden;
        this.actualProjects.push(object);
        this.actualProjectsSettings[data.project._id] = data.projectsettings;
      }
    },

    projectMessagesFrom: function(data) {
      if(this.actualProjects === null) {
        this.actualProjects = [ ...this.user.projects ] ?? [];
      }

      if(this.actualProjects.find(a_proj => this.getId(a_proj._id) === data.project._id)) {
        let project = this.actualProjects.find(a_proj => this.getId(a_proj._id) === data.project._id);
        if (data.messages_from) {
          project.messages_from = data.messages_from;
        } else {
          delete project.messages_from;
        }
        project.hidden = data.hidden;
      }
    },

    projectInputData: function(data) {
      if (this.actualProjectsSettings === null) {
        this.actualProjectsSettings = { ...this.user.projectsettings } ?? {};
      }

      this.actualProjectsSettings[data.project._id] = data.projectsettings;
    },

    filterActualProjectsSettings: function() {
      const filterEmptyStrings = (obj) => {
        Object.keys(obj).forEach(key => {
          if (typeof obj[key] === 'undefined' || obj[key] === null) {
            delete obj[key];
          }
        });
      }

      if (this.actualProjectsSettings !== null && typeof this.actualProjectsSettings !== 'undefined' && Object.keys(this.actualProjectsSettings).length) {
        Object.keys(this.actualProjectsSettings).forEach((key) => filterEmptyStrings(this.actualProjectsSettings[key]));
        Object.keys(this.actualProjectsSettings).forEach((key) => {
          if (Object.keys(this.actualProjectsSettings[key]).length === 0) {
            delete this.actualProjectsSettings[key];
          }
        })
      }
    },

    update: function() {
      let params = {
        settings: this.user.settings,
      };

      if(this.actualProjects !== null) {
        params.projects = this.actualProjects;
      }
      this.filterActualProjectsSettings();
      if (this.actualProjectsSettings !== null && Object.keys(this.actualProjectsSettings).length) {
        params.projectsettings = this.actualProjectsSettings;
      }
      if(this.form.login !== this.user.login) {
        params.login = this.form.login;
      }

      this.saving = true;

      if(Object.keys(params).length > 0) {
        CrmApi.call('/user/' + encodeURIComponent(this.getId(this.user._id)), params).then((response) => {
         /*
          this.$refs.alert.show('Пользователь обновлен', 'success')

          window.scrollTo(0, this.$refs['alert'].offsetTop);

          */
          this.$store.commit('successPush', 'Пользователь обновлен')
          this.saving = false;
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error) )
          /*
          this.$refs.alert.show(CrmApi.getErrorMessage(error), 'danger');
          window.scrollTo(0, this.$refs['alert'].offsetTop);

           */
          this.saving = false;
        });
      }
    },

    getId: function(id) { return id.$oid ?? id; }
  },

}
</script>

<style scoped>
@import 'project.css';

.light {
  display: block !important;
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>