<template>
  <div>
    <button :class="{'text-muted':loading, 'custom-disabled':loading}"  @click="restoreTickets()"  class="btn btn-light btn-outline-dark col-12"><i class="fa fa-undo  button-icon menu-button" ></i>Восстановить</button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "RestoreButton",
  methods:{
    restoreTickets(){
      this.$store.dispatch('tickets/restoreTickets' ,{
        ticketIds:this.getSelectedArray,
        route:this.$route,
      });
    }
  },
  computed:{
    loading: function (){
      return this.$store.getters['tickets/isTicketLoading'];
    },
    ...mapGetters({
      "getSelectedArray":  'tickets/getSelectedArray',
    }),
  }
}
</script>

<style scoped>

</style>