<template>
  <div class="col-12" >
    <span @mouseover="showContextButton = true"  @mouseout="showContextButton = false" class="message message-from">
      <div class="datetime-stamp">{{messageDate}}</div>
      <div class="" style="position: relative">
        <div class="col-md-11 sender-block">
          <headers-block
              :message="message"
              :headers="message.headers"
              :is-project-null="isProjectNull"
          ></headers-block>
        </div>

        <div v-show="showContextButton" class="contextFromButton" style="">
          <context-button
             :messageid="message._id"
             :custom-button-style="{
               'border-style':'none',
               'background-color': '#eef8ff',
               'outline':'none'
                }"
             :show="showFunctions"
             :callbacks="{
               loadImages: loadImages,
               hideImages: hideImages,
               moveMessages: moveMessages,
               hideHtml: hideHtml,
               showHtml: showHtml
             }"
          ></context-button>
        </div>
      </div>

      <div class="attachments-block" v-if="message.attachments">
        <template v-for="attachment in message.attachments">
          <attachment v-if="attachment?.inline !== true" :attachment="attachment" :messageid="message._id" :key="attachment.id" :can-open-attachment="isAuthor"></attachment>
        </template>
      </div>
      <message-text :message="message" :collapsed="collapsed" :as-html="asHtml"></message-text>
    </span>
  </div>
</template>

<script>
import Attachment from "./Attachment";
import MessageText from "./Text";
import HeadersBlock from "./HeadersBlock";
import ContextButton from "./ContextMenu/ContextButton";
import {getDateFormat} from "../Api/api";
import {mapGetters} from "vuex";

export default {
  name: "MessageFrom",

  components: {
    ContextButton,
    HeadersBlock,
    MessageText,
    Attachment},

  props:{
    "message": {
      type:Object,
      require:true
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    changePermission: {
      type: Boolean,
      default: false,
    },
  },

  data: function (){
    return {
      showContextButton: false,
      asHtml: true,
    }
  },

  computed:{
    messageDate: function (){
      return getDateFormat(this.message.created , window.dateFormatWeekDaySeconds);
    },

    isProjectNull: function() {
      return !_.isObject(this.message.ticket?.project);
    },

    showFunctions: function() {
      let functions = ['openSource'];
      if (this.message.bodytype === 'html') {
        if (this.message.body.match(/<img/)) {
          if ((typeof this.message.loadImages === 'undefined' || !this.message.loadImages)) {
            functions.push('loadImages');
          } else if (typeof this.message.loadImages !== 'undefined' && this.message.loadImages) {
            functions.push('hideImages');
          }
        }
        functions.push(this.asHtml ? 'hideHtml' : 'showHtml');
      }
      if (this.changePermission && !this.isTicketDeleted) {
        functions.push('moveMessages');
      }
      return functions;
    },

    isAuthor() {
      return this.message.author != null && typeof this.message.author._id !== 'undefined' && window.getId(this.message.author._id) === window.userId;
    },

    isTicketDeleted() {
      return (this.ticket?.deleted === true);
    },

    ...mapGetters({
      "userCanAnswer": "thread/userCanAnswer",
      ticket: "thread/getTicket",
    }),
  },

  methods:{
    loadImages: function() {
      this.message.loadImages = true;
    },
    hideImages: function() {
      this.message.loadImages = false;
    },
    moveMessages: function() {
      this.$emit('moveMessages', this.message);
    },
    hideHtml: function () {
      this.asHtml = false;
    },
    showHtml: function () {
      this.asHtml = true;
    },
  }
}
</script>

<style scoped>

</style>