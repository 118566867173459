<template>
  <div v-if="canView" class="text-center">
    <button class="p-0 m-0 w-100" @click="openModal" :disabled="buttonDisabled">
      Связи
      <span v-if="relationsCount > 0">
        (<span style="color: red;">{{relationsCount}}</span>)
      </span>
      <span v-if="loading.all"
            class="spinner-border spinner-border-sm ms-1"
            style="color: gray;"
            role="status"
            aria-hidden="true"></span>
    </button>
  </div>
  <teleport to="body">
    <transition name="modal">
      <modal-window v-if="showModal"
                    @close="closeModal"
                    @success="false"
                    :modal-dialog-class="'modal-dialog modal-xl modal-dialog-scrollable edit-modal relation-modal ' + modalDialogClass"
                    :show-footer="false"
      >
        <template v-slot:header>
          <h5 class="modal-title">Связи {{ clientInfo }}</h5>
          <div class="btn-group me-3">
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="hideAll">Свернуть все</button>
            <button type="button" class="btn btn-sm btn-outline-secondary" @click="showAll">Развернуть все</button>
          </div>
        </template>
        <template v-slot:body>
          <div ref="relationBody" class="d-flex flex-column" :class="bodyClass">
            <history
                v-if="relations.history"
                :relations="relations.history"
                :collapse="collapseAll"
                ref="history"
            />
            <ticket-crm
                v-if="relations.crm"
                :relations="relations.crm"
                :collapse="collapseAll"
                ref="ticketCRM"
            />

            <template v-if="relations.billmanager">
              <account
                  v-if="groupRelations('billmanager').customer_accounts"
                  ref="accountBillManager"
                  :collapse="collapseAll"
                  :relations="groupRelations('billmanager').customer_accounts" />
              <client
                  v-if="groupRelations('billmanager').client"
                  ref="clientBillManager"
                  :collapse="collapseAll"
                  :relations="groupRelations('billmanager').client" />
              <!--domain-contact
                  v-if="groupRelations(relation).service_profile"
                  :relations="groupRelations(relation).service_profile" /-->
              <item
                  v-if="groupRelations('billmanager').item"
                  ref="itemBillManager"
                  :collapse="collapseAll"
                  :relations="groupRelations('billmanager').item" />
              <ticket-billmanager
                  v-if="groupRelations('billmanager').ticket"
                  ref="ticketBillManager"
                  :collapse="collapseAll"
                  :relations="groupRelations('billmanager').ticket" />
            </template>

            <template v-if="relations.bitrix" ref="bitrixRelations">
              <template v-for="(items, group) in groupRelations('bitrix', 'ENTITY_TYPE')">
                <entity
                    :relations="items"
                    :collapse="collapseAll"
                    :title="bitrixTitle(group, items)" />
              </template>
            </template>
          </div>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import Client from "./Relations/Billmanager/Client";
import History from "./Relations/History/History";
import DomainContact from "./Relations/Billmanager/DomainContact";
import TicketBillmanager from "./Relations/Billmanager/Ticket";
import Item from "./Relations/Billmanager/Item";
import Entity from "./Relations/Bitrix/Entity";
import Loading from "vue3-loading-overlay";
import Account from "./Relations/Billmanager/Account";
import TicketCrm from "./Relations/CRM/Ticket";
import ModalWindow from "../ModalWindow";
import {mapGetters} from "vuex";
import {Collapse} from "bootstrap";

export default {
  name: "Relations",

  components: {
    History,
    Loading,
    Client,
    DomainContact,
    TicketBillmanager,
    TicketCrm,
    Item,
    Entity,
    Account,
    ModalWindow,
  },

  props: {
    person: {},
    ticketId: {},
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      showModal: false,
      relations: {},
      relationsCount: 0,
      services: ['bitrix', 'billmanager', 'crm', 'history'],
      loading: {
        all: false,
        bitrix: false,
        billmanager: false,
        crm: false,
      },
      bodyClass: 'one-screen',
      collapseAll: false,
      modalDialogClass: '',
    }
  },

  computed: {
    clientInfo() {
      let result = '';
      let name = this.person?.name ?? '';
      let contact = '';
      if (typeof this.person?.email === 'string' && this.person?.email.trim() !== '') {
        contact = this.person.email;
      } else if (
          typeof this.person?.phone !== 'undefined' &&
          Array.isArray(this.person?.phone) &&
          this.person?.phone.length &&
          typeof this.person.phone[0]?.number === 'string' &&
          this.person.phone[0]?.number.trim() !== ''
      ) {
        contact = this.person.phone[0]?.number;
      }
      if (name && contact) {
        result = `${name} (${contact})`;
      } else if (name) {
        result = name;
      } else if (contact) {
        result = contact;
      }
      return result;
    },

    buttonDisabled() {
      return this.relationsCount === 0 || this.loading.all;
    },

    ...mapGetters({
      "canView": "thread/userRelationPermission",
    }),
  },

  mounted: function() {
    this.loadingRelations();
  },

  methods: {
    getRelationTypeLabel: function (typeName){
      let map = {
        "crm": "CRM",
        "bitrix": "Bitrix24",
        "billmanager": "BILLmanager",
        "history": "История трансферов"
      };

      return map[typeName] ?? typeName;
    },

    bitrixTitle: function(group, items) {
      switch(group) {
        case 'DEAL':
          group = 'Сделки Bitrix24';
          break;
        case 'LEAD':
          group = 'Лиды Bitrix24';
          break;
        case 'CONTACT':
          group = 'Контакты Bitrix24';
          break;
        case 'COMPANY':
          group = 'Компании Bitrix24';
          break;
      }
      if (Array.isArray(items)) {
        group += ` (${items.length})`;
      }

      return group;
    },

    groupRelations: function(type, field = 'type') {
      let relations = {};
      let groups = [ ...new Set( this.relations[type].map(r => r[field]) ) ];
      groups.forEach(group => {
        relations[group] = this.relations[type].filter(r => r[field] === group).map(r => {
          r = {...r};
          delete r[field];
          return r;
        });
      });

      return relations;
    },

    openModal: function() {
      this.modalDialogClass = '';
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketRelations');
      this.$nextTick(() => {
        this.initHeights();
        setTimeout(() => this.modalDialogClass = 'opened', 1000);
      });
    },

    loadingRelations() {
      let requests = [];
      this.services.forEach(type => {
        if(typeof this.relations[type] === 'undefined') {
          this.loading.all = true;

          requests.push(this.getRelation(type).then(response => {
            if(typeof response?.data[type] !== 'undefined' && response.data[type].length) {
              this.relations[type] = response.data[type];
            }
          }));
        }
      });

      if(requests.length) {
        Promise.all(requests).finally(() => {
          this.loading.all = false;
          Object.entries(this.relations).forEach(([, relations]) => this.relationsCount += relations.length);
        });
      }
    },

    getRelation(type) {
      return CrmApi.getThreadPersonRelations(this.ticketId, type).catch((error) => {
        this.$store.commit('errorPush', {
          text: `Ошибка при загрузке связей '${this.getRelationTypeLabel(type)}'`,
          description: CrmApi.getErrorMessage(error)
        });
      });
    },

    closeModal: function() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketRelations');
    },

    initHeights() {
      this.$nextTick(() => {
        this.calculateHeights();
        // if (this.bodyClass !== 'one-screen') {
        //   this.collapseAll = true;
        // }
      });
    },

    calculateHeights() {
      let className = '';
      if (this.$refs.relationBody) {
        let totalHeight = document.querySelector('.relation-modal .modal-content')?.clientHeight ?? 0 -
                          document.querySelector('.relation-modal .modal-header')?.offsetHeight ?? 0;
        let tables = Object.keys(this.$refs).filter(name => name !== 'relationBody');
        let currentHeights = tables.reduce((height, name) => {
          return $(this.$refs[name].$el).outerHeight() + height;
        }, 0);
        if (currentHeights < totalHeight || tables.length <= 1) {
          className = 'one-screen';
        }
      }
      this.bodyClass = className;
    },

    getAllCollapses() {
      let result = [];
      if (this.$refs.relationBody) {
        this.$refs.relationBody.querySelectorAll('.collapse').forEach(item => {
          result.push(Collapse.getOrCreateInstance(item));
        });
      }
      return result;
    },

    hideAll() {
      this.getAllCollapses().forEach((item) => {
        item.hide();
      });
    },

    showAll() {
      this.getAllCollapses().forEach((item) => {
        item.show();
      });
    },
  },
}
</script>

<style scoped>
:deep(.modal-body) {
  margin: 0;
}
:deep(.modal-dialog) {
  height: calc(100vh - 3rem);
  min-height: calc(100vh - 3rem);
  overflow: hidden;
}
:deep(.expandable) {
  cursor: pointer;
  --bs-accordion-color: #212529;
  --bs-accordion-bg: #fff;
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: 1px;
  --bs-accordion-border-radius: 0.375rem;
  --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: #212529;
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.5rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #86b7fe;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #0c63e4;
  --bs-accordion-active-bg: #e7f1ff;
}
:deep(.expandable::after) {
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: 8px;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
:deep(.expandable:not([aria-expanded="true"])::after) {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
:deep(.opened .expandable) {
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
}
@media (min-width: 1520px) {
  :deep(.modal-dialog) {
    --bs-modal-width: 75vw;
  }
}
:deep(.sorted-down::after) {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
:deep(.sorted-up::after) {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent;
}
:deep(.sortable) {
  cursor: pointer;
}
:deep(thead.sticky > tr.no-shadow:last-child) {
  box-shadow: inset -1px 1px #dde2e6;
}
.relation-card:not(:last-child) {
  margin-bottom: 1em;
}

.relation-service-title:first-letter {
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .modal-xl {
    --bs-modal-width: 550px !important;
  }
}
@media (min-width: 992px) {
  .modal-xl {
    --bs-modal-width: 900px !important;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px !important;
  }
}
</style>