<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Обнаружено изменения в ЕГРЮЛ:
      <span v-for="(change, index) in params.changes">
        <popper :content="popperContent(change)" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
          {{change.name}}
        </popper>
        <template v-if="params.changes.length > 1 && index < params.changes.length - 1">,&nbsp;</template>
      </span>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "EgrulChange",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  methods: {
    popperContent(change) {
      let content = '';

      if(change?.old && change?.new) {
        content = `${change.old} => ${change.new}`;
      } else if(change?.old) {
        content = `Удалено значение ${change.old}`;
      } else if(change?.new) {
        content = `Добавлено значение ${change.new}`;
      }

      return content;
    }
  }
}
</script>

<style scoped>

</style>