<template>
  <select class="form-select" v-model="value">
    <option :value="undefined">{{placeholder}}</option>

    <option v-for="option in object.options" :value="option.value">
      {{ option.name }}
    </option>
  </select>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  name: "FilterSelect",

  props: {
    placeholder: {
      type: String,
      default: '',
    },
    field: {
      type: String,
      require: true,
    },
    object: {
      type: Object,
    }
  },

  computed: {
    value: {
      get() {
        return this.getFilterByName(this.field).value;
      },

      set(value) {
        let obj = Object.assign({}, this.object);
        obj.value = value;
        this.setFilterValue(obj);
        this.setPage(1);

        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.updateOffersList();
        }, 600)
      }
    },

    ...mapGetters({
      isLoading: 'thread/offers/getLoading',
      show: 'thread/offers/filters/showFilter',
      getFilterByName: 'thread/offers/filters/getFilterByName',
    }),
  },

  methods:{
    ...mapMutations({
      setFilterValue: 'thread/offers/filters/setFilterValue',
      setPage: 'thread/offers/setPage',
    }),
    ...mapActions({
      updateOffersList: 'thread/offers/updateOffersList',
    })
  }
}
</script>

<style scoped>

</style>