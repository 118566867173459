<template>
  <tr v-if="loading">
    <td :colspan="colSpanDefault" style="text-align: center; vertical-align: middle">Loading...</td>
  </tr>
</template>

<script>


export default {
  name: "LoadingRow",
  props:{
    colSpanDefault:{
      type:String,
      default:"6",
    },
    loading:{},
  },

}
</script>

<style scoped>

</style>