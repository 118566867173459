
export default {

    setMessages(state, value) {
        state.messages = value;
    },

    setCommentMessage(state, value){
      state.commentMessage = value;
    },

    showCommentForm(state) {
        state.showCommentForm = true;
    },

    closeCommentForm(state) {
        state.showCommentForm = false;
    },

    setShowAddTaskForm(state, value) {
        state.showAddTaskForm = value;
    },

    showEditForm(state) {
        state.showEditForm = true;
    },

    showHeadersForm(state, show = true) {
        state.showHeadersForm = show;
    },

    setHeadersFromValid(state, valid) {
        state.headersFormValid = valid;
    },

    setEditMessage(state, message) {
        state.editMessage = message;
    },

    closeEditForm(state) {
        state.showEditForm = false;
    },

    setLastSeen(state, lastSeen) {
        state.lastSeen = lastSeen;
    },

    setTicket(state, ticket) {
        if(ticket !== null && typeof ticket === 'object'
           && Array.isArray(ticket?.last_seen)) {

            state.lastSeen = ticket.last_seen;
            delete ticket.last_seen;
        }

       if(JSON.stringify(state.ticket) !== JSON.stringify(ticket)) {
           state.ticket = ticket;
       }
    },

    setTicketFields(state, value){
        if(!state.ticket?.assigned_product){
            state.ticket.assigned_product = {
                fields:{}
            };
        }
        state.ticket.assigned_product.fields =value;
    },

    setPerson(state, person) {
        state.person = person;
    },

    setHasNewChangesForDraft(state, value) {
        if (value === false || state.settingDraft === false) {
            state.hasNewChangesForDraft = value;
        }
    },

    setTicketType(state, type) {
        state.ticketType = type;
    },

    reloadLastMessage(state) {
        state.reloadLastMessage = true;
    },

    reloadMessage(state, messageId) {
        state.reloadMessage = messageId;
    },

    selectTemplate(state , template){
        state.selectedTemplate = template;
    },

    removeSelectedTemplate(state){
        state.selectedTemplate = null;
    },

    setSelectMessageExistInTicket(state , value){
        state.isSelectMessageExistInTicket = value;
    },

    setSelectMessageStatus(state , value){
        state.selectMessageStatus = value;
    },

    setUserCanAnswer(state, value) {
        state.userCanAnswer = value;
    },

    setUserCanChange(state, value) {
        state.userCanChange = value;
    },

    setUserCanSetLot(state, value) {
        state.userCanSetLot = value;
    },

    setUserCanCall(state, value) {
        state.userCanCall = value;
    },

    setUserTaskCreatePermission(state, value) {
        state.userTaskCreatePermission = value;
    },

    setUserTaskDeletePermission(state, value) {
        state.userTaskDeletePermission = value;
    },
    
    setUserUpdatePersonPermission(state, value) {
        state.userUpdatePersonPermission = value;
    },

    setUserRelationPermission(state, value) {
        state.userRelationPermission = value;
    },

    setIsDirectLink(state, value){
        state.isDirectLink = value;
    },

    setUserAttachmentPermission(state, value) {
        state.userAttachmentPermission = value;
    },

    setUserDocumentsPermission(state, value) {
        state.userDocumentsPermission = value;
    },

    setUserOpenClosePermission(state, value) {
        state.userOpenClosePermission = value;
    },

    setLoading(state, value){
        state.loading = value;
    },

    loading(state){
        state.loading = true;
    },
    unloading(state){
        state.loading = false;
    },
    setUserCallRecordPermission(state, value){
        state.userCallRecordPermission = value;
    },

    setAttachmentsFilter(state, value) {
        state.attachmentsFilter = value;
        if (value === true) {
            state.tasksFilter = false;
        }
    },

    setTasksFilter(state, value) {
        state.tasksFilter = value;
        if (value === true) {
            state.attachmentsFilter = false;
        }
    },

    toggleTicketWindowSize(state) {
        state.ticketWindowFullSize = !state.ticketWindowFullSize;
    },

    setTicketWindowSize(state, value) {
        state.ticketWindowFullSize = value;
    },

    setTicketChangeWindowSizeCallback(state, callback) {
        state.ticketChangeWindowSizeCallback = callback;
    },

    setBuyer(state, buyer) {
        state.params.buyer = buyer ?? {};
    },

    setPrice(state, price) {
        state.params.price = price;
    },

    setScrollToMessage(state, value) {
        state.scrollToMessage = value;
    },

    setScrollAndHighlight(state, value) {
        state.scrollAndHighlight = value;
    },

    setHighlight(state, value) {
        state.highlight = value;
    },

    setDocument(state, document) {
        state.document = document;
    },

    setDocuments(state, documents) {
        state.documents = documents;
    },

    setProjectAliases(state, aliases) {
        state.aliases.project = aliases;
    },
    setTicketAliases(state, aliases) {
        state.aliases.ticket = aliases;
    },
    setCreateNewTicket(state, value) {
        state.createNewTicket = value;
    },

    clearSignatures(state) {
        state.signatures.list.clear();
    },

    setSignature(state, {_id, alias, text}) {
        state.signatures.list.set(window.getId(_id) + `_${alias}`, text);
    },

    deleteSignature(state, _id, alias) {
        state.signatures.list.delete(_id + `_${alias}`);
    },

    showSignature(state) {
        state.showSignature = true;
    },

    hideSignature(state) {
        state.showSignature = false;
    },

    setLoadingSignature(state, value) {
        state.loadingSignature = value;
    },

    setGreeting(state, value) {
        state.greeting = value;
    },

    resetGreeting(state) {
        state.greeting = {
            original: null,
            name: null,
            projects: {},
            canUse: false,
            current: {},
            currentTemplate: '',
            currentValue: '',
        }
    },

    setAnsweringTicket(state, value) {
        state.isAnsweringTicket = value;
    },

    setSmartTemplates(state, value) {
        state.smartTemplates = value;
    },

    setGroupOrder(state, value) {
        state.groupOrder = value;
    },

    setLoadingTemplates(state, value) {
        state.loadingTemplates = value;
    },

    setCurrentGreetingSettings(state, value) {
        state.greeting.current = value;
    },

    setCurrentGreeting(state, {currentTemplate, currentValue}) {
        state.greeting.currentTemplate = currentTemplate;
        state.greeting.currentValue = currentValue;
    },

    setNewTicketLang(state, value) {
        state.newTicketLang = value;
    },

    setNewTicketLangChanged(state, value) {
        state.newTicketLangChanged = value;
    },

    setApi(state, api) {
        state.api = api;
    },

    setPinnedMessages(state, value) {
        state.pinnedMessages = value;
    },

    clearPinnedMessages(state) {
        state.pinnedMessages = [];
    },

    addToPinnedMessages(state, value) {
        let date = value.created;
        let array = [...state.pinnedMessages];
        let index = array.findIndex((item) => item.created >= date);
        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 0, value);
        }
        state.pinnedMessages = array;
    },

    removeFromPinnedMessages(state, value) {
        state.pinnedMessages = state.pinnedMessages.filter(item => window.getId(item._id) !== window.getId(value._id));
    },

    updatePinnedMessage(state, value) {
        let el = state.pinnedMessages.find(item => window.getId(item._id) === window.getId(value._id));
        if (el) {
            el.body = value.body;
        }
    },

    setShowSearchField(state, value) {
        state.showSearchField = value;
    },

    setSearchString(state, value) {
        state.searchString = value;
    },

    setSettingDraft(state, value) {
        state.settingDraft = value;
    },
}
