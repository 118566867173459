<template>
  <component :is="html === 'table' ? 'tr' : 'div'">
    <component :is="html === 'table' ? 'td' : 'div'">Комментарий</component>

    <component :is="html === 'table' ? 'td': 'div'" :class="{loading: loading}">
      <div v-if="!showArea && shouldBeTrimmed"
           style="overflow-wrap: break-word;"
           :class="{'trimmed': showTrimmed, 'p-1 ps-2 flex-grow-1 d-block': html === 'div', 'cursor-pointer': userCanChange}"
           @click="toggle"
           v-html="content"
      ></div>
      <div v-else-if="!showArea && !shouldBeTrimmed"
           style="overflow-wrap: break-word;"
           :class="{'p-1 ps-2 flex-grow-1 d-block': html === 'div', 'cursor-pointer': userCanChange}"
           v-html="comment"
           @dblclick="open()"
      ></div>
      <div v-else>
        <textarea
            class="form-control"
            type="text"
            style="max-height: 700px; min-height: 100px"
            v-model.trim="proxyvalue"
            ref="comment"
            :disabled="loading"
            @focusout="cancelFocusout"
            @keyup.ctrl.enter.stop.exact="edit"
            @keyup.esc.stop="cancel"
        />
      </div>
    </component>
  </component>
</template>

<script>
import {getMarkdown} from "../../../ticket/Api/api";
import {CrmApi} from "../../../../library/CrmApi";
import {mapGetters} from "vuex";

export default {
  name: "Comment",
  emits: [
      'updatePerson'
  ],
  data:function(){
    return{
      showArea: false,
      proxyvalue: "",
      loading: false,
      showTrimmed: true,
      clicked: false,
      timer: null,
    }
  },
  props: {
    person: {},
    ticketId: {
      require: true
    },
    html: {
      default: 'div',
    },
    default: {
      default: '—',
    },
    trim: {
      type: Boolean,
      default: false,
    },
    trimLength: {
      type: Number,
      default: 140,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    if (typeof this.person.comment === 'undefined' || this.person.comment === null) {
      this.person.comment = '';
    }
    this.proxyvalue = this.person.comment;
    if(typeof   this.$refs.comment !== 'undefined' &&   this.$refs.comment !== null){
      this.$refs.comment.focus();
    }
  },
  methods:{
    open(){
      if(this.userCanChange) {
        this.showArea = true;
        this.proxyvalue = this.person.comment;
        this.$nextTick(()=>{
          this.$refs.comment.focus();
          this.$refs.comment.select();
        })
      }
    },
    edit() {
      this.loading = true;
      this.api.editPerson({"change": {"comment": this.proxyvalue}}).then((result) => {
        this.showArea = false;
        this.person.comment = this.proxyvalue;
        if (typeof result.data?.person === 'object' && result.data.person !== null) {
          this.$emit('updatePerson', result.data.person);
        }
        this.$store.commit('successPush', 'Изменения сохранены');
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.loading = false;
      });
    },
    cancelFocusout(e) {
      let can = true;
      if (typeof e.explicitOriginalTarget !== 'undefined') {
        if (e.explicitOriginalTarget === this.$refs.comment) {
          can = false;
        }
      }
      if (can && !this.loading) {
        this.cancel();
      }
    },
    toggleShowTrimmed() {
      this.showTrimmed = !this.showTrimmed;
    },
    toggle() {
      if (this.clicked) {
        this.clicked = false;
        this.open();
        clearTimeout(this.timer);
      } else {
        this.clicked = true;
        this.timer = setTimeout(() => {
          this.toggleShowTrimmed();
          this.clicked = false;
        }, 500);
      }
    },
    getEffectiveLength(str) {
      return str.split('').reduce((acc, char) => acc + (char === "\n" ? 25 : 1), 0);
    },
    getSubstring(str, length = 140) {
      let result = '';
      let counter = 0;
      if (typeof str === 'string') {
        for (const char of str.split('')) {
          counter += char === "\n" ? 25 : 1;
          if (counter <= length) {
            result += char;
          } else {
            break;
          }
        }
      }
      return result;
    },
    cancel() {
      if (this.showArea) {
        this.showArea = false;
        this.proxyvalue = this.person.comment;
      }
    }
  },
  computed: {
    comment: function() {
      return (typeof this.person.comment === 'string' && this.person.comment !== '')
                            ? getMarkdown(_.escape(this.person.comment), false)
                            : this.default;
    },
    content() {
      return this.showTrimmed ? this.trimmedComment : this.comment;
    },
    shouldBeTrimmed() {
      let result = false;
      if (this.trim) {
        if (typeof this.person.comment === 'string' && this.person.comment !== '') {
          result = this.getEffectiveLength(this.person.comment) >= this.trimLength;
        }
      }
      return result;
    },
    trimmedComment() {
      let result = '';
      let arrow = '<span class="angle-down-container"><i class="fa fa-angle-down" aria-hidden="true"></i></span>';
      if (typeof this.person.comment === 'string') {
        result = getMarkdown(this.shouldBeTrimmed ?
            _.escape(this.getSubstring(this.person.comment, this.trimLength)) + '...' + arrow :
            _.escape(this.person.comment),
          false);
      }
      return result;
    },
    ...mapGetters({
      "api": "thread/getApi",
    }),
  },
  watch:{
    showArea(to, from){
      if( to && to !== from  ){
        this.$nextTick(() => {
          $(this.$refs.comment).height(0);
          $(this.$refs.comment).height($(this.$refs.comment).prop("scrollHeight") + "px");
        })
      }
    }
  }
}
</script>

<style scoped>
tr, td {
  border: inherit;
}
.cursor-pointer {
  cursor: pointer;
}
:deep(.angle-down-container) {
  display: block;
  text-align: center;
  font-size: 24px;
  line-height: 24px;
}
:deep(.trimmed) {
  position: relative;
}
:deep(.trimmed .angle-down-container) {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f8f9fa;
  background: linear-gradient(180deg, rgba(248,249,250,0.5) 0%, rgba(248,249,250,0.8) 30%, rgb(248, 249, 250) 100%);
  color: #6c757d;
}
</style>