import tasksActions from "./tasksActions";
import tasksMutations from "./tasksMutations";
import tasksGetters from "./tasksGetters";

const state = () => ({
    users: [],
    canTasksControl: false,
    canUserList: false,
    tasks: [],
});

export default {
    namespaced: true,
    state: state,
    actions: tasksActions,
    getters: tasksGetters,
    mutations: tasksMutations,
    modules: {},
    plugins: []
}