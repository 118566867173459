<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      <span v-if="byFilter">
        Фильтр <a v-if="filter.url" :href="filter.url" target="_blank" class="filter-name"><string-trim :string="filter.name" /></a>
        <span v-else class="filter-name"><string-trim :string="filter.name" /></span>
        переместил
      </span>
      <span v-else>{{user}} переместил(ла)</span>
      тикет в "Удаленные"<template v-if="params?.tag"> с меткой "{{params.tag}}"</template>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import StringTrim from "../../string/StringTrim";

export default {
  name: "DeletedByUser",

  components: {
    StringTrim,
  },

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event)
}
</script>

<style scoped>
.filter-name {
  max-width: 150px;
  display: inline-block;
  white-space: nowrap;
}
</style>