<template>
  <tr class="offer-table-text" style="cursor: pointer;   "  >
    <td>
      <subnet :offer="offer"></subnet>
    </td>
    <td style="cursor: default;">
      <price-sell :offer="offer" ></price-sell>
    </td>
    <td>
      <date-td :offer="offer"></date-td>
    </td>
    <td   v-on:dblclick="openTicket(offer._id)" >
      <div>
        {{status}}
      </div>
    </td>
    <td>
      <Client :offer="offer"></Client>
    </td>
    <comment-t-d :offer="offer"></comment-t-d>
  </tr>
</template>

<script>

import Subnet from "../DataCells/Subnet";
import PriceSell from "../DataCells/PriceSell"
import getSellFields from "./OfferSellComputeds";
import Client from "../DataCells/Client";
import {toRef} from "vue";
import CommentTD from "../DataCells/CommentTD";
import openTicket from "../Methods";
import DateTD from "../DataCells/DateTD";

export default {
  name: "SellRow",
  components: {CommentTD, Client, PriceSell, Subnet, 'date-td': DateTD},
  props:{
    'offer':{
      type:Object,
      require:true,
      default:{},
    }
  },
  setup(props){

    const offer = toRef(props, 'offer')
    const { status, comment} = getSellFields({offer});
    return {
      status,
      comment,
      openTicket
    }
  },

}
</script>

<style scoped>

</style>