<template>
  <div :class="{loading: loading}">
    <subnets
        :withoutAddToAuction="withoutAddToAuction"
        :subnets="subnets"
        :offer="offer"
        :show-label="false"
        :show-table="true"
        :user-can-change="canChange"
        :user-can-set-lot="canSetLot"
        @update="update"
        :show-auctions="false"
    />
  </div>
</template>

<script>

import updateFields from "../../source/updateFields";
import getSellFields from "../Sell/OfferSellComputeds";
import {toRef} from "vue";
import Subnets from "../../../tiketMenu/components/Params/Product/Field/Subnets";

export default {
  name: "Subnet",

  components: {Subnets},

  props:{
    'offer':{
      type:Object,
      require:true,
    }
  },

  data: function() {
    return {
      loading: false,
      withoutAddToAuction:true,
    }
  },

  setup(props){
    const offer = toRef(props, 'offer')
    const { subnets, canChange, canSetLot } = getSellFields({offer});
    return {
      subnets, canChange, canSetLot
    }
  },

  methods:{
    update: function(values, afterSave = null, beforeSave = null) {
      this.loading = true;
      updateFields(this.offer, values, afterSave, beforeSave).then(() => this.loading = false);
    }
  }
}
</script>

<style scoped>
</style>