<template>
  <div class="col-md-4" style="position:fixed; bottom:0px; right:0;z-index:1;" :style="{
    'z-index':zIndex,
  }">
    <alert v-for="alert in alertsList" :alert="alert" :without-timer="alert.withoutTimer"></alert>
  </div>
</template>

<script>
import Alert from "./Alert";

export default {
  name: "AlertList",

  components: {
    'alert': Alert
  },

  data: function (){
    return {

    }
  },
  computed:{
    alertsList:function (){
      return this.$store.state.alerts;
    },
    zIndex:function (){
      let zIndex = 10000;
      if(typeof this.$store.state.alerts.zIndex !== 'undefined' &&  this.$store.state.alerts.zIndex != null ){
        zIndex = this.$store.state.alerts.zIndex;
      }
      return zIndex;
    }
  },
}
</script>

<style scoped>

</style>