<template>
  <div class="sidebar-item-body">
    <div :class="offsetlabel" class="v-sidebar-item" @click="doAction()">
      <span v-if="icon" class="icon-container">
        <i :class="this.iconStyle"></i>
      </span>
      <span v-else>
        <i class="fa fa-chevron-down" v-if="collapse"></i>
        <i class="fa fa-chevron-right" v-else></i>
      </span>
      {{label}}
    </div>
    <div :class="offset" class="overflow-hidden">
      <transition name="fade">
        <div v-if="collapse">
          <sidebar-item v-for="item in content" :icon="item.icon" :level="realLevel + 1" :label="item.label" :content="item.content" :clickevent="item.clickevent" :key="item.id"></sidebar-item>
          <hr/>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarItem",
  props: {
    "level": {
      type: Number,
    },
    "label": {
      type: String,
      require: true,
    },
    "content": {
      type: Array,
    },
    "clickevent": {
      type: Function,
    },
    "root": {
      type: Boolean,
      default:false
    },
    "icon": {
      type: String,
      default:null
    },
  },
  data: function (){
    return {
      "collapse": false
    }
  },
  computed:{
    iconStyle: function (){
      return "fa fa-" + this.icon;
    },
    offsetlabel:function (){
      return this.offset + ( this.root ? " v-sidebar-item-root" : "");
    },
    offset:function (){
      return "offset-" + this.realLevel.toString();
    },
    realLevel: function (){
      return typeof this.level === 'undefined' ? 0: this.level;
    }
  },
  methods:{
    "doAction": function (){
      if( typeof this.content !== 'undefined' ){
        this.collapse = !this.collapse;
      }
      if( typeof this.clickevent !== 'undefined' ){
        this.clickevent(this);
      }
    }
  }
}
</script>

<style scoped>
.v-sidebar-item{
  cursor: pointer;
  padding-left: 5px;
  border-radius: 5px;
}
.icon-container {
  width: 20px;
  display: inline-block;
  text-align: center;
}
.v-sidebar-item:hover{
  background-color: #d3fad3;
}

hr{
  margin: 10px 0;
}
.v-sidebar-item-root{
  font-weight: 600;
}
.sidebar-item-body{
  color: rgba(0, 0, 0, 0.65);
  background-color: #ececec;
}
.fade-enter-active {
  animation: bounce-in 0.5s;
}
.fade-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 100%;
    margin-top: 0px;
  }
}
</style>