<template>
<div class="col-12" style="text-align: center;">
  <span class="message message-to comment col-sm-12" :class="{'message-edit': edit}" style="border-bottom-right-radius: 20px; position: relative;"
        @mouseenter="showEditButton = true, showContextButton= true" @mouseleave="showEditButton = false; showContextButton = false">
    <div class="col-12 datetime-stamp text-end">{{commentDate}}</div>
    <div class="" style="position: relative">
      <div class="sender-block me-1">
        <span>
          Автор: <span v-if="comment.author.name">{{comment.author.name}}</span><span v-else>{{comment.author.login}}</span>
        </span>
        <div class="pull-right" style="display: flex">
          <span  class="text-secondary" style="margin-right: 28px">Внутренний комментарий</span>
          <context-button
              v-if="showContextButton"
              :custom-button-style="customButtonStyle"
              :messageid="comment._id"
              :show="showFunctions"
              :callbacks="{
                   pinMessage: pinMessage,
                   unpinMessage: unpinMessage,
                 }"
          ></context-button>
        </div>
      </div>
    </div>
    <div>
        <quote-message style="margin-top: 5px;" :message="comment"></quote-message>
    </div>

    <div class="attachments-block" v-if="comment.attachments">
      <attachment v-for="attachment in comment.attachments" :attachment="attachment" :messageid="comment._id" :key="attachment.id" :can-open-attachment="isAuthor"></attachment>
    </div>

    <span v-html="getMarkdown()" class="comment-quotes max-width" ref="html-message"></span>

    <div class="d-flex  align-items-center mt-2 justify-content-between">
      <span class="add-comment comment-button" v-if="userCanAnswer && !isTicketDeleted" @click="showCommentForm">Откомментировать</span>
      <last-editing :comment="comment" style="margin-right: 30px;"></last-editing>
      <template v-if="canEdit">
        <div class="edit-menu">
            <span class="edit" title="Редактировать" @click.stop="editMessage" v-if="showEditButton"><i class="fa fa-pencil"></i></span>
        </div>
      </template>
    </div>
  </span>
</div>
</template>

<script>

import Attachment from "../Message/Attachment";
import {UserPermission} from "../../../users/UserPermission";
import ContextButton from "../Message/ContextMenu/ContextButton";
import QuoteMessage from "./CommentMessageFields/QuoteMessage";
import {CrmApi} from "../../../library/CrmApi";
import {getMarkdown} from "../Api/api";
import {mapGetters} from "vuex";
import LastEditing from "./LastEditing";
import {baseHighlight} from "../../../library/Functions";

export default {
  name: 'Comment',

  components: {
    LastEditing,
    QuoteMessage,
    ContextButton,
    Attachment,
  },

  props:{
    comment: {
      type: Object,
      require: true
    },

    collapsed: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      showEditButton: false,
      canEdit: false,
      edit: false,
      showContextButton: false,
      customButtonStyle: {
        position: "absolute",
        right: "-4px",
        top: "-4px",
        "background-color": "#f3f3f3",
        "border-radius": "50px",
      },
    }
  },

  mounted: function() {
    UserPermission.getPermissions(['system.edit_comment']).then((result) => {
      if(result['system.edit_comment'] || window.getId(this.comment.author._id) === this.$parent.userId) {
        this.canEdit = true;
      }
    });
    this.highlight();
  },

  computed: {
    commentDate: function () {
      return moment(this.comment.created).format(window.dateFormatWeekDaySeconds);
    },

    isAuthor() {
      return typeof this.comment.author._id !== 'undefined' && window.getId(this.comment.author._id) === window.userId;
    },

    ticket() {
      return this.$store.getters['thread/getTicket'];
    },

    isTicketDeleted() {
      return (this.ticket?.deleted === true);
    },

    showFunctions() {
      let result = [];
      if (this.userCanAnswer) {
        if (typeof this.comment?.pinned !== 'undefined' && this.comment?.pinned === true) {
          result.push('unpinMessage');
        } else {
          result.push('pinMessage');
        }
      }
      return result;
    },

    ...mapGetters({
      "userCanAnswer": "thread/userCanAnswer",
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {

    highlight() {
      if (this.showSearchField && typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        this.$nextTick(() => {
          baseHighlight(
              $(this.$refs['html-message']),
              this.searchString
          );
        });
      }
    },

    showCommentForm: function() {
      this.$store.dispatch('thread/removeSelectedTemplate');
      this.$store.commit('thread/showCommentForm');
      this.$store.commit('thread/setCommentMessage', this.comment)
    },
    getMarkdown(){
      if(typeof this.comment?.body === 'undefined'){
        return ''
      }
      return getMarkdown(this.comment.body , false);
    },
    editMessage: function() {

      this.closeEditForm();
      this.edit = true;
      let quoteMessage = null;
      if(this.comment?.type === 'comment' && this.comment?.replyto){
        let id = window.getId(this.comment.replyto);
        if(typeof id !== 'undefined' && this.$store.getters['thread/getMessages'] !== null){
          for(let el of this.$store.getters['thread/getMessages']){
            if(window.getId(el._id) === id){
              quoteMessage = el;
              break;
            }
          }
        }

      }
      if( this.comment?.replyto && quoteMessage === null){
        CrmApi.getThread( window.getId(this.ticket._id), window.getId(this.comment.replyto) ).then((result) => {
          let message = result.data.messages.data[0];
          this.$store.commit('thread/setCommentMessage', message);
        }).catch(()=>{
          this.$store.commit('thread/setCommentMessage', null);
        })
      }else {
        this.$store.commit('thread/setCommentMessage', quoteMessage);
      }


      // нужно дождаться unmount формы редактирования
      this.$nextTick(() => {
        this.$store.dispatch('thread/removeSelectedTemplate')
        this.$store.commit('thread/showEditForm');
        this.$store.commit('thread/setEditMessage', {
          message: Object.assign({}, this.comment),
          close: this.closeEditForm,
          updated: this.closeEditForm,
          messageObject:this.comment,
        });
        this.$emit('editMessage');
      })
    },

    closeEditForm: function() {
      this.edit = false;
      this.$store.commit('thread/setCommentMessage', null);
      this.$store.commit('thread/setEditMessage', null);
      this.$store.commit('thread/closeEditForm');
    },

    pinMessage: function () {
      this.$store.dispatch('thread/pinMessage', this.comment).then(result => {
        if (result) {
          this.comment.pinned = true;
        }
      });
    },

    unpinMessage: function () {
      this.$store.dispatch('thread/unpinMessage', this.comment).then(result => {
        if (result) {
          this.comment.pinned = false;
        }
      });
    },
  },

  watch: {
    'threadState.editMessage' (to) {
      if(to === null || to.message._id !== this.message._id) {
        // отключаем редактирование если открыти редактирование другого сообщения
        this.edit = false;
      }
    }
  }
}
</script>

<style scoped>
.comment-quotes {
  overflow-wrap: break-word;
}
.comment-quotes :deep(blockquote){
  padding-left: 3px;
  background-color: #d2d2d2;
}
.comment {
  background-color: #f3f3f3;
}
.edit-menu {
  right: 1.3em;
  opacity: 0.8;
  cursor: pointer;
  position: absolute;
}
.edit-menu span:hover {
  opacity: 1;
}
.message-edit {
  cursor: wait;
  opacity: .5;
}

.add-comment {
  cursor: pointer;
  font-size: 10px;
}
.comment-button{
  border: 1px solid #b2b2b2;
  border-radius: 7px;
  padding: 0 5px;
  background-color: #eeeeee;
}
</style>
