<template>
<div class="p-2" v-show="show" ref="form">
  <div class="input-group input-group-sm mb-1" v-if="showFromField">
    <span class="input-group-text" id="message-from" style="width: 55px;">От</span>
    <select class="form-select  from-select"  v-model="from" vname="from" vtype="emails" :class="{'is-invalid': showFromError, 'text-muted': from === null}" @change="change($event); createNewTicket(); $emit('updateHeaders', 'from', from);" :disabled="loading.aliases">
      <option v-if="from === null" :value="null" disabled selected>Выберете алиас для ответа</option>
      <option v-for="from in fromAliases" :value="from" v-if="fromAliases">{{from}}</option>
      <option v-else>Loading...</option>
    </select>
    <div class="invalid-feedback" :class="{'d-block': showFromError && from === null}">Не удается определить алиасы для ответа автоматически. Необходимо задать вручную.</div>
  </div>
  <div class="input-group input-group-sm mb-1" v-if="showToField">
    <span class="input-group-text" id="message-to" style="width: 55px;">Кому</span>
    <div class="form-control p-0" :class="{'is-invalid': !isValidField('to')}">
      <multiselect
        v-model="to"
        mode="tags"
        :close-on-select="true"
        :searchable="true"
        :create-option="true"
        :append-new-option="clientEmailsOptions.length === 0"
        :options="clientEmailsOptions"
        :disabled="loading.headers"
        no-results-text=""
        @change="(value) => { changeMultiselect(value, 'to', 'to'); $emit('updateHeaders', 'to', value); }"
      >
        <template v-slot:tag="{option, handleTagRemove}">
            <span class="multiselect-tag">
              <string-trim :string="option.label" style="max-width: 350px;"></string-trim>
              <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                <span class="multiselect-tag-remove-icon"></span>
              </span>
            </span>
        </template>
      </multiselect>
    </div>
    <div class="invalid-feedback" :class="{'d-block': !isValidField('to')}">{{validation.to}}</div>
  </div>
  <div class="input-group input-group-sm mb-1" v-for="(field, index) in fields" v-if="can">
    <div class="input-group-text input-group-sm p-0">
      <select class="form-select text-center h-100" :value="field.key" @change="changeFieldHeaderType" :vname="field.name" :disabled="loading.headers" >
        <option v-for="option in fieldsSelect" :value="option">{{option}}</option>
      </select>
    </div>

    <template v-if="can">
      <template v-if="field.key === 'CC' || field.key === 'BCC'">
        <div class="form-control p-0 dynamic-multiselect" :class="{'is-invalid': !isValidField(field.name)}" style="flex: 1 1 auto;">
          <multiselect
              v-model="field.value"
              mode="tags"
              :close-on-select="true"
              :searchable="true"
              :create-option="true"
              :options="clientEmailsOptions"
              :disabled="loading.headers"
              no-results-text=""
              @change="(value) => { changeMultiselect(value, field.key, field.name); }"
          >
            <template v-slot:tag="{option, handleTagRemove}">
              <span class="multiselect-tag">
                <string-trim :string="option.label" style="max-width: 300px;"></string-trim>
                <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </span>
            </template>
          </multiselect>
        </div>
      </template>
      <template v-else>
        <input type="text" v-model="field.value" class="form-control" :class="{'is-invalid': !isValidField(field.name)}" :vname="field.name" vtype="emails" :disabled="loading.headers" @change="change">
      </template>
    </template>

    <div>
      <button class="btn btn-secondary m-0" type="button" @click="addHeaderField" v-if="fields.length === 1 || index === (fields.length - 1)">+</button>
      <button class="btn btn-danger m-0" type="button" @click="removeHeaderField(field.name)" v-else>-</button>
    </div>
    <div class="invalid-feedback" :class="{'d-block': !isValidField(field.name)}">{{validation[field.name]}}</div>
  </div>
  <div class="input-group input-group-sm mb-1" v-if="showSubjectField">
    <span class="input-group-text" id="message-subject" style="width: 55px;">Тема</span>
    <input type="text" class="form-control" v-model="subject" :class="{'is-invalid': !isValidField('subject')}" vname="subject" vtype="required" :disabled="loading.headers" @change="change">
    <div class="invalid-feedback" v-if="!isValidField('subject')">{{validation.subject}}</div>
  </div>
  <div class="d-flex mt-1" v-if="isCreateNewTicket && !editMessage">
    <div class="text-danger" style="font-size: 0.875em;">
      При смене алиаса переписка начинается с новой цепочки и без цитирования. Для отсутствия аффилированности,
      пожалуйста, задайте тему отличную от той, что была ранее.
    </div>
    <div class="ms-auto language-select-container">
      <span class="me-1">Язык:</span>
      <editable-select
          :options="{RU: 'Русский', EN: 'Английский'}"
          :selected="this.lang ?? null"
          :callback="changeLanguage"
      ></editable-select>
    </div>
  </div>
</div>
<changes v-if="!show && changes.length" :changes="changes" @remove="removeChange"></changes>
</template>

<script>
import {UserPermission} from "../../users/UserPermission";
import {CrmApi} from "../../library/CrmApi";
import Changes from "./HeadersForm/Changes";
import Multiselect from "@vueform/multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
import StringTrim from "../string/StringTrim";
import {Email} from "../../library/Email";
import EditableSelect from "../Editable/EditableSelect";

export default {
  name: "HeadersForm",

  emits: ['resizeMessagesbox', 'updateFormSize', 'updateShowButton', 'updateHeaders',],

  components: {
    StringTrim,
    Changes,
    Multiselect,
    EditableSelect,
  },

  props: {
    projectId: {
      type: String,
      require: true
    },
    ticketId: {
      type: String,
      require: true
    },
  },

  data: function() {
    return {
      can: null,
      fieldsSelect: ['CC', 'BCC', 'Reply-To'],
      fields: [],
      subject: '',
      to: [],
      from: '',
      fromAliases: null,
      hasMultiAlias: false,
      lastMessageHeaders: null,
      loading: {
        headers: false,
        aliases: false,
      },
      projectEmailsHashmap:{},
      ticketEmailsHashmap:{},
      lastHeight: null,
      validation: {},
      draft: {},
      changes: [],
      oldSubnet:null
    }
  },
  created() {
    this.loadData();
  },
  computed: {
    show: {
      get() { return this.$store.getters['thread/isShowHeadersForm']; },
      set(value = true) { this.$store.commit('thread/showHeadersForm', value); }
    },
    valid: {
      get() { return this.$store.getters['thread/headersFormValid']; },
      set(value) { this.$store.commit('thread/setHeadersFromValid', value); }
    },

    lang: {
      get() {
        return this.newTicketLang;
      },
      set(value) {
        this.setNewTicketLang(value);
      }
    },

    clientEmailsOptions: function() {
      let options = [];

      let emailString = (email, name = null) => {
        return (name === null) ? email : name + ' <' + email + '>';
      }

      options = options.concat(this.getDefaultValue('to'));

      if(this.person !== null) {
        if (typeof this.person.email !== 'undefined' && this.person.email !== null && this.person.email !== '') {
          options.push(emailString(this.person.email, this.person.name ?? null));
        }
        if(Array.isArray(this.person.contact_emails ?? null)) {
          this.person.contact_emails.forEach((item) => {
            options.push(emailString(item.mail, item.name ?? null));
          })
        }
      }

      options = options.length ? [ ...new Set(options) ] : [];

      return options;
    },

    showFromField() {
      return this.can || this.ticketAliasesEmpty || this.hasMultiAlias;
    },

    showToField() {
      return this.can || this.clientEmailsOptions.length === 0;
    },

    showSubjectField() {
      return this.can || this.getDefaultValue('subject') === '';
    },

    ticketAliasesEmpty() {
      return Object.keys(this.ticketEmailsHashmap).length === 0 &&
          Object.keys(this.projectEmailsHashmap).length > 0;
    },

    showFromError() {
      return this.ticketAliasesEmpty && this.from === null;
    },

    ...mapGetters({
      person: 'thread/getPerson',
      isCreateNewTicket:'thread/createNewTicket',
      editMessage: 'thread/getEditMessage',
      "newTicketLang": "thread/newTicketLang",
    }),
  },

  methods: {
    loadData() {
      this.loadLastMessageHeaders().then(()=>{
        this.loadAliases().then(()=>{
          this.setDefaultValues();
          this.checkTicketAliasesEmpty();
          if(
              this.editMessage == null &&
              this.from != "" &&
              typeof this.ticketEmailsHashmap[this.from] === 'undefined' &&
              !this.ticketAliasesEmpty
          ) {
            this.$store.commit('thread/setCreateNewTicket' , true)
            this.oldSubnet = this.subject
            this.subject = ''
            this.show = true;
          }
        }).catch(() => {});
      });
    },
    loadLastMessageHeaders() {
      return new Promise((resolve, reject) => {
        // загружаем headers последнего сообщения
        this.loading.headers = true;
        CrmApi.call('/thread/' + encodeURIComponent(this.ticketId) + '/headers/').then((result) => {
          this.lastMessageHeaders = result.data;
          resolve()
        }).catch((error) => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
          reject()
        }).finally(() => {
          this.loading.headers = false;
        });
      });
    },
    loadAliases(){
      return new Promise((resolve, reject) => {
        this.loading.aliases = true;
        this.getAliases().then((response)=>{
          if (typeof response.data?.hasMultiAlias === "boolean") {
            this.hasMultiAlias = response.data?.hasMultiAlias;
          }

          if (this.hasMultiAlias === true) {
            this.show = true;
          }

          this.projectEmailsHashmap = {};
          for (let el of response.data.project) {
            this.projectEmailsHashmap[el] = el;
          }
          this.fromAliases = [];
          this.ticketEmailsHashmap = {};

          for (let el of response.data.ticket) {
            if (typeof this.projectEmailsHashmap[el] !== 'undefined') {
              this.fromAliases.push(el);
            }
            this.ticketEmailsHashmap[el] = el;
          }
          for (let el of response.data.ticket) {
            if (typeof this.projectEmailsHashmap[el] === 'undefined' && this.can) {
              this.fromAliases.push(el);
            }
          }

          for (let el of response.data.project) {
            if (typeof this.ticketEmailsHashmap[el] === 'undefined' && this.can) {
              this.fromAliases.push(el);
            }
          }

          resolve(response);
        }).catch((error) => {
          this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
          reject();
        }).finally(() => {
          this.loading.aliases = false;
        });
      });

    },


    getInput(vname) {
        let filter = '[vname="' + vname + '"]';
        return $('input' + filter)[0] ?? $('select' + filter)[0] ?? null;
    },

    addHeaderField: function(key = null, value = null) {
      let fieldName = this.getRandomFieldName();
      key = (typeof key !== 'string') ? 'CC' : key;

      if(key === 'CC' || key === 'BCC') {
        if(value === null || value === '') {
          value = [];
        } else {
          let emails = value.split(',').map(email => email.trim());
          emails.forEach(email => {
            if(this.clientEmailsOptions.indexOf(email) === -1) {
              this.clientEmailsOptions.unshift(email);
            }
          });
          value = emails;
        }
      }

      this.fields.push({
        name: fieldName,
        key: key,
        value: (value === null) ? '' : value,
      });
      this.validation[fieldName] = true;
      return fieldName;
    },

    removeHeaderField: function(fieldName) {
      delete this.validation[fieldName];
      this.fields = this.fields.filter(field => field.name !== fieldName);

      if(!this.fields.length) {
        this.addHeaderField();
      }
    },

    isValidField: function(name) {
      return (typeof this.validation[name] === 'undefined' || this.validation[name] === true);
    },

    getRandomFieldName: function() {
      return Math.random();
    },

    formValidation: function() {
      if(this.lastMessageHeaders !== null) {
        $('input[vname]').each((index, el) => {
          this.fieldValidation({target: el});
        });

      this.fieldValidation({
        target: {
          attributes: {
            vname: {value: String('to')},
            vtype: {value: 'emails-required'},
          },
          value: Array.isArray(this.to) ? this.to.join(', ') : '',
        },
      });

      let correct = true;
      for(let v in this.validation) {
        if(this.validation[v] !== true) {
          correct = false;
          break;
        }
      }

        return correct;
      }
    },

    changeMultiselect: function(value, key, fieldName, validation = true) {
      let vtype = key === 'to' ? 'emails-required' : 'emails';
      let e = {
        target: {
          attributes: {
            vname: {value: String(fieldName)},
            vtype: {value: vtype},
          },
          value: Array.isArray(value) ? value.join(', ') : '',
        },
      };
      this.change(e, validation);
    },
    createNewTicket:function (){
      if(this.editMessage !== null || this.ticketAliasesEmpty) {
        return;
      }
      if(typeof  this.ticketEmailsHashmap[this.from] === 'undefined'){
        this.$store.commit('thread/setCreateNewTicket' , true)
        if( this.subject != ''){
          this.oldSubnet = this.subject
        }

        this.subject = ''
        this.show = true;
      }else {
        if(this.isCreateNewTicket){
          this.$store.commit('thread/setCreateNewTicket' , false);
          if( this.oldSubnet !== null ) {
            this.subject = this.oldSubnet;
            this.removeChange('subject', false);
            this.fieldValidation({
              target: {
                attributes: {
                  vname: {value: 'subject'},
                  vtype: {value: 'required'},
                },
                value: this.subject,
              },
            });
          }
        }

      }
    },

    change: function(e, validation = true) {
      if(typeof e.target === 'undefined') {
        e = {target: e};
      }

      if(validation) {
        this.fieldValidation(e);
      }

      let field = e.target.attributes.vname.value;
      let value = e.target.value.trim();

      if(typeof this[field] !== 'undefined') {
        let lastValue = this.getDefaultValue(field, false) ?? 'Удалить';

        if(Array.isArray(lastValue)) {
          lastValue = lastValue.join(', ');
          lastValue = lastValue === '' ? 'Удалить' : lastValue;
        }

        if(field === 'subject' && value !== lastValue || field !== 'subject' && value !== '' && value !== lastValue) {
          let existChange = this.changes.filter(change => change.field === field);
          if(existChange.length) {
            existChange[0].value = value;
          } else {
            this.changes.push({
              field: field,
              value: value,
              popper: lastValue,
            });
          }
        } else {
          this.removeFromChanges(field);
        }
      } else {
        if(value !== '') {
          let field_obj = this.fields.filter(f => f.name == field)[0];
          let field_name = field_obj.key;

          let existChange = this.changes.filter(change => change.name === field);
          if(existChange.length) {
            existChange[0].value = value;
            existChange[0].field = field_name;
          } else {
            this.changes.push({
              field: field_name,
              name: field,
              value: value,
              popper: 'Удалить',
            });
          }
        } else {
          this.removeChange(field, false);
        }
      }
    },

    changeFieldHeaderType: function(e, validation = true) {
      let field = this.fields.filter(f => f.name == e.target.attributes.vname.value)[0];
      let isMultiselect = (e.target.value === 'BCC' || e.target.value === 'CC');

      if(!Array.isArray(field.value)) {
        if(field.value === '') {
          if(isMultiselect) {
            field.value = [];
          }
        } else {
          let emails = field.value.split(',').map(email => email.trim());
          emails.forEach(email => {
            if(this.clientEmailsOptions.indexOf(email) === -1) {
              this.clientEmailsOptions.unshift(email);
            }
          });

          field.value = emails;
        }
      } else if(!isMultiselect) {
        field.value = field.value.join(', ');
      }

      field.key = e.target.value;
      this.$nextTick(() => {
        if(isMultiselect) {
          this.changeMultiselect( field.value, field.key, field.name, validation );
        } else {
          this.change($(e.target).parent().parent().find('input[vtype=emails]')[0], validation);
        }
      })
    },

    isMainField: function(fieldname) {
      return typeof this[fieldname] !== 'undefined' &&
          (typeof this[fieldname] === 'string' || Array.isArray(this[fieldname]));
    },

    removeChange: function(fieldname, removeHeaderField = true) {
      if (this.isMainField(fieldname)) {
        let value = Array.isArray(this[fieldname]) ? [] : '';
        this[fieldname] = this.getDefaultValue(fieldname, false) ?? value;
        if (fieldname === 'from') {
          this.createNewTicket();
        }
      } else {
        if(removeHeaderField) {
          this.removeHeaderField(fieldname);
        }
      }
      this.removeFromChanges(fieldname);
    },

    removeFromChanges: function (fieldname) {
      if (this.isMainField(fieldname)) {
        this.changes = this.changes.filter(change => change.field !== fieldname);
      } else {
        this.changes = this.changes.filter(change => change.name !== fieldname);
      }
    },

    fieldValidation: function(e) {
      let type = e.target.attributes.vtype.value;
      let inputName = e.target.attributes.vname.value;
      let value = e.target.value.trim();

      if(
          inputName === 'to' && !this.showToField ||
          inputName === 'subject' && !this.showSubjectField ||
          inputName === 'from' && !this.showFromField
      ) {
        // не проверяем значение полей, если они не доступны пользователю
        return;
      }

      if(type === 'emails' || type === 'emails-required') {
        if (value === '') {
          this.validation[inputName] = (type === 'emails-required') ? 'Обязательное поле' : true;
        } else {
          value = value.split(',');
          this.validation[inputName] = value.every(email => Email.validateFullString(email, false)) ?
              true : 'Неккоректный email / список emails';
        }
        if (inputName.match(/^header\-\d+$/)) {
          this.fields = this.fields.concat();
        }
      } else if (type === 'required') {
        this.validation[inputName] = (e.target.value === '') ? 'Обязательное поле' : true;
      }
    },

    convertHeaders(object, getHeader = false) {
      let result = [];
      if (typeof object === 'object' && object !== null) {
        result = Object.keys(object).filter(
            key => this.fieldsSelect.includes(String(key).toLocaleUpperCase())
        ).map(key => {
          return {
            key: String(key).toLocaleUpperCase(),
            value: getHeader ? this.getLastHeader(key).join(', ') : object[key],
          }
        });
      }
      return result;
    },

    prepareMailBox(to) {
      if(typeof to === 'string') {
        to = to.split(',');
        to = to.map(el => el.trim());
      }

      return to;
    },

    getDefaultValue(name, withDraft = true) {
      let value =  this.editMessage?.message?.[name] ??
          this.editMessage?.message?.headers?.[name] ??
          ((withDraft && this.editMessage === null) ? this.draft?.[name] : null) ??
          this.getLastHeader(name);

      if(name === 'to') {
        value = this.prepareMailBox(value);
      }

      return value;
    },

    setDefaultValues: function() {
      this.validation = {};
      let headers = null;

      this.from = this.getDefaultValue('from');
      this.subject = this.getDefaultValue('subject');
      this.to = this.getDefaultValue('to');

      if(this.to?.length && typeof this.lastMessageHeaders?.to === 'undefined') {
        // если задан to, и to не был получен в ticket/headers, значит это редактируемое сообщение интерна
        // где интерн ввел to.
        this.show = true;
      }

      if(this.editMessage !== null){
        if(typeof this.editMessage?.message?.headers === 'object') {
          headers = this.convertHeaders(this.editMessage?.message?.headers);
        }
      } else {
        if(!(typeof this.draft.headers === 'undefined' || this.draft.headers === null || this.draft.headers.length === 0)) {
          headers = this.draft.headers;
        } else if (typeof this.lastMessageHeaders !== 'undefined' && this.lastMessageHeaders !== null) {
          headers = this.convertHeaders(this.lastMessageHeaders, true);
        }
      }
      this.fields = [];

      if (typeof headers === 'object' && Array.isArray(headers) && headers.length) {
        headers.forEach((h) => {
          let fieldname = this.addHeaderField(h.key, h.value);
          this.$nextTick(() => {
            if(h.key === 'CC' || h.key === 'BCC') {
              this.changeMultiselect(h.value, h.key, fieldname, false);
            } else {
              this.change(this.getInput(fieldname), false);
            }
          })
        });
      } else {
        this.addHeaderField('CC', '');
      }

      this.$nextTick(this.loadValuesFromDraft);
    },

    loadValuesFromDraft() {
      Object.entries(this.draft).forEach((el) => {
        if(this.getInput(el[0]) !== null) {
          this.change(this.getInput(el[0]), false);
        }
      });
      if(!this.formValidation() && !this.show) {
        this.show = true;
      }
      this.$nextTick(() => {
        this.setSettingDraft(false);
      });
    },

    getLastHeader: function(name) {
      let result = '';
      if(this.lastMessageHeaders !== null) {
        let lastHeaders = this.lastMessageHeaders;

        switch(name) {
          case 'subject':
            result = lastHeaders[name] ?? '';
            break;
          case 'from':
            result = lastHeaders[name].email;
            break;
          case 'cc':
          case 'bcc':
          case 'to':
            result = [];
            if (typeof lastHeaders[name] !== 'undefined' && Array.isArray(lastHeaders[name])) {
              for (let k in lastHeaders[name]) {
                let el = lastHeaders[name][k];
                result.push((el.name === null)
                    ? el.email
                    : el.name + ' <' + el.email + '>');
              }
            }
            break;
        }
      }
      return result;
    },

    fromAliasesSelected: function(from) {
      return this.lastMessageHeaders !== null && this.lastMessageHeaders.from.email === from;
    },

    checkPermission: function(ticket) {
      if(this.can === null) {
        UserPermission.canTicket(ticket, 'headers_modify').then(() => {
          this.can = true;
          this.$emit('updateShowButton', true);
          this.$nextTick(() => {
            this.setFormResizer();
          })
        }).catch((e) => {
          this.can = false;
        });

      }
    },

    setFormResizer: function() {
      let form = this.$refs?.form;

      if(form instanceof HTMLElement) {
        new ResizeObserver(() => {
          // следим за размерами headers-form
          let currentHeight = $(form).height();
          if (this.show) {
            this.$emit('resizeMessagesbox', (this.lastHeight ? this.lastHeight : 0) - currentHeight);
            this.lastHeight = currentHeight;
          } else {
            this.$emit('resizeMessagesbox', this.lastHeight ? this.lastHeight : 0);
            this.lastHeight = null;
          }

          this.$nextTick(() => this.$emit('updateFormSize'));

        }).observe(form);
      }
    },

    toggleHeadersForm: function() {
      if(!this.show || this.valid) {
        this.show = !this.show;
      }
    },
    getNewHeaders:function (){
      return {
        from: this.from,
        to: this.to,
        subject: this.subject,
      };
    },
    getHeaders: function() {
      let returnHeaders = {headers: []};

      Object.entries({
        to: this.showToField,
        from: this.showFromField,
        subject: this.showSubjectField,
      }).forEach(([headerName, isShow]) => {
        if(isShow && typeof this[headerName] !== 'undefined') {
          returnHeaders[headerName] = Array.isArray(this[headerName])
                                        ? this[headerName].join(', ')
                                        : this[headerName];
        }
      });
      this.fields.forEach(item => {
        let header = {
          key: item.key,
          value: Array.isArray(item.value) ? item.value.join(', ') : item.value,
        };
        if( header.value.trim() !== "" ) {
          returnHeaders.headers.push(header)
        }
      });
      return returnHeaders;
    },

    addDraft: function(draft) {
      this.draft = draft;
      this.toggleHeadersForm();
    },

    checkTicketAliasesEmpty: function () {
      if (this.ticketAliasesEmpty) {
        if (typeof this.draft?.from !== 'undefined' && this.draft.from) {
          if (this.from !== this.draft.from) {
            this.from = null;
          }
        } else {
          this.from = null;
        }
        if (this.from === null) {
          this.valid = false;
          this.show = true;
        }
      }
    },

    changeLanguage(lang) {
      this.lang = lang;
      this.setNewTicketLangChanged(true);
    },

    ...mapActions({
      'getAliases':'thread/getAliases',
    }),
    ...mapMutations({
      setPerson: 'thread/setPerson',
      setHasNewChangesForDraft: 'thread/setHasNewChangesForDraft',
      "setNewTicketLang": "thread/setNewTicketLang",
      "setNewTicketLangChanged": "thread/setNewTicketLangChanged",
      "setSettingDraft": "thread/setSettingDraft",
    })
  },

  watch: {
    "$store.state.thread.ticket" (ticket) {
      // ждем подгрузки тикета и проверяем права
      this.checkPermission(ticket);
      this.formValidation();
    },
    validation: {
      handler (to) {
        this.valid = (Object.values(to).filter(field => field !== true).length === 0) && this.from !== null;
        if(!this.valid && !this.show) {
          this.show = true;
        }
      },
      deep: true
    },
    changes: {
      handler () {
        this.setHasNewChangesForDraft(true);
      },
    deep: true
    },
    ticketAliasesEmpty() {
      this.checkTicketAliasesEmpty();
    }
  },
}
</script>

<style scoped>
.from-select option {
  text-align: left;
}
.dynamic-multiselect > div {
  height: 100%;
}
:deep(.option-trim) {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}
.language-select-container {
  font-size: 14px;
  flex-grow: 1;
  flex-shrink: 0;
  padding-left: 1rem;
}
</style>