<template>
  <div v-if="!canChange">
    {{name}}
  </div>
  <div v-if="canChange && userArray.length > 0">
    <editable-select v-if="!isCollapsed"
      :options="users"
      :selected="id"
      :show-not-selected="false"
      span-class="offer-history-black"
      class="offer-history-black"
      @change="changeSelect"
    ></editable-select>
    <editable-select  v-if="isCollapsed"
      :options="logins"
      :selected="id"
      :show-not-selected="false"
      span-class="offer-history-black"
      class="offer-history-black"
      @change="changeSelect"
    ></editable-select>
  </div>
</template>

<script>
import EditableSelect from "../../Editable/EditableSelect";
import getUserName, {getName} from "../../../library/userName";
import {mapActions, mapGetters} from "vuex";
import {ref, toRef} from "vue";
export default {
  name: "Manager",
  components: {EditableSelect},
  props:{
    "manager":{
      type:Object,
    },
    "fieldName":{
      type:String,
    },
    historyObjectId:{
      require: true,
    },
  },
  data:function (){
    return {

    }
  },
  setup(props){
    let managerRef = toRef(props, 'manager')
    let {name, login, id} = getUserName({managerRef:managerRef});
    return {name, login, id};
  },
  computed:{
    isCollapsed:function (){
      let isCollapsed = this.getSellManagerCollapsed;
      if( this.fieldName == 'buymanager' ){
        isCollapsed = this.getBuyManagerCollapsed;
      }
      return isCollapsed;
    },
    logins: function () {
      let result = [...this.getLogins];
      if (this.id && !result.find(item => window.getId(item?.value) === window.getId(this.id))) {
        result.push({
          name: this.login,
          value: window.getId(this.id),
        })
      }
      return result;
    },
    users: function () {
      let result = [...this.getUsers];
      if (this.id && !result.find(item => window.getId(item?.value) === window.getId(this.id))) {
        result.push({
          name: this.name,
          value: window.getId(this.id),
        })
      }
      return result;
    },
    ...mapGetters({
      'getLogins':'offerHistory/getLoginsForSelect',
      'getUsers':'offerHistory/getUsersForSelect',
      'canChange':'offerHistory/canChange',
      'getSellManagerCollapsed':'offerHistory/getSellManagerCollapsed',
      'getBuyManagerCollapsed':'offerHistory/getBuyManagerCollapsed',
    }),
    userArray:function (){
      return Object.values(this.getUsers);
    },
  },

  methods:{
    ...mapActions({
      'editObject':'offerHistory/editObject'
    }),
    changeSelect:function (newid){
      let params = {};
      if(newid !== this.id){
        let lastid = null;
        if(this.manager?._id){
           lastid =    this.manager._id;
          this.manager._id = newid;
        }


        params[this.fieldName] = {};
        params[this.fieldName]['_id']= newid;
        this.editObject({id:this.historyObjectId , params:params}).catch(()=>{
          if(lastid === null){
            this.manager = null;
          }else {
            this.manager._id = lastid;
          }

        })
      }

    }
  }
}
</script>

<style scoped>

</style>