<template>
  <div>
    <div>
      <div>
        <Loading loader="dots" :is-full-page="false" v-model:active="loading" />
      </div>
      <table class="table table-hover table-striped ps-0" v-if="itemsList.length !== 0">
        <thead>
        <tr>
          <th v-for="column in columns" scope="col" :width="column.width? column.width.toString()+'%' : ''"><span :role="tableHeadRole" @click="sortIf(column.value)">{{column.name}}</span></th>
          <th v-if="actions.length !== 0" scope="col">Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr class="hoveredRow" v-for="(item, index) in itemsList" v-on:dblclick="showEdit(item)">
          <td v-for="column in columns">
            <slot :name="'cell.' + column.value" :column="column" :item="item">
              <column-value :column="column" :item="item"/>
            </slot>
          </td>
          <td v-if="actions.length !== 0" class="text-end">
            <div class="dropdown">
              <button class="btn btn-sm btn-light btn-outline-secondary dropdown-toggle" type="button" :id="'dropdownMenuX' + index.toString()" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Функции
              </button>
              <ul class="dropdown-menu" :aria-labelledby="'dropdownMenuX' + index.toString()">
                <li v-for="action in actions">
                  <span @click="callAction(action, item, $event)"
                        :class="'dropdown-item' + (action.class ? ' ' + action.class : '' )"
                        :disabled="typeof action.disabled !== 'undefined' ? (typeof action.disabled === 'function' ? action.disabled.call(this, item) : action.disabled) : false"
                        role="button">{{ typeof action.name === 'function' ? action.name.call(this, item) : action.name }}</span>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-show="showPaginate">
      <paginate :settings-name="settingsName" id="ticket-paginate"
                ref="paginate"  @changePage="goToPage($event)" ></paginate>
    </div>
  </div>
</template>

<script>

import Paginate from "../paginate/Paginate";
import ColumnValue from "./library/ColumnValue";
import Loading from "vue3-loading-overlay";

export default {
  name: "EntityTable",
  components:{
    ColumnValue,
    Paginate,
    Loading,
  },
  props:{
    settingsName: {
      type:String,
      default:null,
    },
    columns:{
      type: Array,
      required: true
    },
    actions:{
      type: Array,
      default: []
    },
    showEdit:{
      type: Function,
      required: true
    },
    getEntityList:{
      type: Function,
      required: true,
    },
    doReloadPage:{
      type:Object,
      default: {value:false},
    }
  },

  data:function (){
    return {
      itemsList:[],
      loading:false,
      showPaginate: false
    }
  },

  computed:{
    tableHeadRole: function () {
      return this.$attrs.onSort ? "button" : "";
    }
  },

  methods:{
    goToPage: function({page , onpage}){
      let query = {};
      query.page = page;
      query.onpage = onpage;

      window.location.replace( window.location.origin + window.location.pathname + "?" + new URLSearchParams(query).toString())
    },
    getList: function(query){
      this.itemsList = [];
      this.loading = true;
      this.getEntityList(query)
          .then((result)=>{
              this.itemsList = result.data.data;
              if( typeof result.data.onpage !== 'undefined' ) {
                this.$refs.paginate.update({
                  page: result.data.page,
                  onpage: result.data.onpage,
                  total: result.data.total,
                  links: result.data.paginator_links
                });
                this.showPaginate = true;
              }
          })
          .catch(()=>{})
          .finally(()=>{
              this.loading = false;
          });
    },
    reloadPage: function (){
      this.getList( window.readGetParams());
    },
    callAction: function (action, item,event){
      return action.handler.call(this, item, event);
    },
    sortIf: function (value) {
      if (this.$attrs.onSort) {
        this.$emit('sort', value);
        this.reloadPage();
      }
    }
  },
  watch:{
    'doReloadPage.value'(to){
      if(to){
        this.reloadPage();
        this.doReloadPage.value = false;
      }
    }
  },
  mounted() {
    this.reloadPage();
  },
}
</script>

<style scoped>
 span[disabled="true"] {
   cursor: not-allowed;
 }
</style>