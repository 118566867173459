import {CrmApi} from "../CrmApi";

export {Ticket}
class Ticket {
    static competitor({ids, competitor}) {
        return CrmApi.call('/thread/competitor', {ids, competitor});
    }

    static deleteNotifications({ids}) {
        return CrmApi.call('/notification/delete', {ids});
    }

    static convertNotifications(ids) {
        return CrmApi.call('/notification/convert', {ids});
    }

    static setAnswered(ids) {
        return CrmApi.call('/thread/answered', {ids});
    }

    static setUnanswered(ids) {
        return CrmApi.call('/thread/unanswered', {ids});
    }

    static mergeTickets({from, to}) {
        return CrmApi.call('/thread/mergetickets/', {from, to});
    }

    static changeProject({tickets, project, assigned_projects, assigned_projects_data}) {
        return CrmApi.call('/thread/changeproject/', {tickets, project, assigned_projects, assigned_projects_data});
    }

    static changeTags({ids, tags, tags_data}) {
        return CrmApi.call('/ticket/tags/', {ids, tags, tags_data});
    }

    static closeTickets(ids) {
        return CrmApi.call('/thread/close', {ids});
    }

    static reopenTickets(ids) {
        return CrmApi.call('/thread/reopen', {ids});
    }
}