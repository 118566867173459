<template>
  <div class="popper-style">
    <div v-if="!loading">
      <div v-if="person">
        <div>{{name}}</div>
        <hr v-if="name && (email || person.org || person.lirid )"/>
        <!-- div v-for="phone in phones"  class="padding">
          <div v-if="phone.number" class="list"  >
            <div style="  overflow-wrap: anywhere; ">{{phone.number}}</div>
            <div v-if="phone.type">({{getTypes(phone.type)}})</div>
          </div>
        </div>
        <hr v-if="phones.length > 0" / -->
        <div  class="padding" >{{email}}</div>
        <div  v-for="email in contact_emails" class="padding">
          <div v-if="email.mail" class="list"  >
            <div >{{email.mail}}</div>
            <div v-if="email.type">({{email.type}})</div>
          </div>
        </div>
        <div v-if="person.org && (typeSubnet === 'PI' || typeSubnet === 'ASN')" class="list padding " >
            <span>{{person.org}}</span>
        </div>
        <div v-if="person.lirid && typeSubnet === 'PA'"  class="list padding" >
           <span>{{person.lirid}}</span>
        </div>
      </div>
      <div v-else>Информация не найдена</div>

      <div v-if="manager?.login" class="list padding" style="margin-top: 10px">
        Менеджер: {{manager.name}} ({{manager.login}})
      </div>
    </div>
    <div v-else>
      Загружаем...
    </div>
  </div>

</template>

<script>

import {toRef} from "vue";
import getPersonFields from "../table/PersonComputeds";

export default {
  name: "ClientPopper",
  props:{
    loading:{
      type:Boolean,
      require:true,
    },
    person:{
      require: true
    },
    typeSubnet: {},
    manager: Object
  },
  setup(props){
   let person = toRef(props, 'person');
    let  {id, name , email, phones, contact_emails} = getPersonFields({person:person})
    return {id, name , email, phones, contact_emails}
  },
  methods:{
    getTypes:function (type){
      switch (type){
        case 'рабочий':{
          type = 'раб.'
          break;
        }
        case 'другой':{
          type = 'др.'
          break;
        }
      }
      return type;
    }
  },
  computed:{
    name:function (){
      return  typeof this.person?.name !='undefined' ? this.person?.name : '';
    },
    phones:function (){
      return  typeof this.person?.phone !='undefined' ? this.person?.phone : [];
    },
    contact_emails:function (){
      return  typeof this.person?.contact_emails !='undefined' ? this.person?.contact_emails : [];
    }
  }
}
</script>

<style scoped>
.popper-style{
  z-index: 10;
  background-color: white;
  border-radius: 4px;
  padding: 5px 8px 5px;
  border:1px solid #ccc;
  font-size: 14px;
  max-width: 70vw;
}
.popper-style hr{
  margin-top: 4px;
  margin-bottom: 2px;
}
.popper-style .list{

}
.padding{
  overflow-wrap: anywhere;
  padding: 1px 10px;
  text-align: center;
}
</style>