<template>
  <div v-esc="closeWindow" class="modal-mask">
    <div  class="modal-dialog">
      <div class="modal-content" style="position: relative; max-height:510px; overflow: auto">
        <div  class="closeWindow" style="z-index: 99999">
          <span class="close" data-dismiss="alert" @click="closeWindow()" >&times;</span>
        </div>
        <div class="modal-body" style="overflow: auto;">
          <slot name="body">
            <markdown-guide></markdown-guide>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarkdownGuide from "./markdown-guide";
export default {
  name: "markdown-modal",
  components: {MarkdownGuide},
  emits:['close'],
  data:function () {
    return {

    }
  },
  methods:{
    closeWindow(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.modal-content{
  background: whitesmoke;
}
.modal-dialog{
  max-width: 400px;
  margin: 1.75rem auto;
}
.modal-mask {
  position: var(--markdown-modal , absolute);
  left: var(--markdown-modal-left , -400px);
  bottom: var(--markdown-modal-bottom , 100px);
  z-index: 9;
  height: 100%;
  transition-duration: 500ms;
  transition-property: margin-right;
}



.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.closeWindow{
  right: 10px;
  top:0px;
  cursor: pointer;
  font-size: 21px;
  text-align:end;
  margin-right: 30px;
  height: 2px;
}
</style>