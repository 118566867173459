<template>
<span>{{this.objectValue}}</span>
</template>

<script>
export default {
  name: "ColumnValue",
  props:{
    item:{
      type:Object,
      required: true
    },
    column:{
      type:Object,
      required:true
    }
  },
  computed:{
    objectValue: function (){
      let val = null;
      if( typeof this.column.getter === 'function'){
        val = this.column.getter(this.item);
      } else if( this.column.value != null ) {
        let parts = this.column.value.split(".");
        val = typeof this.item[parts[0]] === 'undefined' ? null : this.item[parts[0]];
        if (val != null) {
          for (let i = 1; i < parts.length; i++) {
            if (typeof val[parts[i]] === 'undefined') {
              val = null;
              break;
            }
            val = val[parts[i]];
          }
        }
      }
      return val;
    },
  }
}
</script>

<style scoped>

</style>