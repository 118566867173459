<template>
  <a :href="userHref" style="padding: 0px 10px" @click="clickFunction()" class="dropdown-item" :target="userTarget">{{name}}</a>
</template>

<script>
export default {
  name: "ContextLinkItem",
  props:{
    "name":{
      require:true
    },
    "onClick":{
      type:Function,
    },
    "messageid":{
      type:String
    },
    "userHref":{
      type:String,
      default:"",
    },
    "userTarget":{
      type:String,
      default: "_blank",
    }

  },
  methods:{
    clickFunction:function () {
      if(this.onClick){
        this.onClick(this.messageid);
      }
    }
  }
}
</script>

<style scoped>

</style>