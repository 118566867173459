<template>
  <div class="modal modal-mask">
  <div :class="modalDialogClass" class="modal-dialog">
    <div v-esc="close"  v-enter="success"  class="modal-content" style="position: relative">
      <div style="position: absolute; right: 10px; top:0px; cursor: pointer; font-size: 21px;  ">
        <span class="close" data-dismiss="alert" @click="$emit('close')" >&times;</span>
      </div>
      <div class="modal-header" >
        <slot name="header">
          {{header}}
        </slot>
      </div>
      <div class="modal-body" :class="customModalBody" >
        <slot name="body">
          {{body}}
        </slot>
      </div>
      <div class="modal-footer" v-if="showFooter">
        <slot name="footer">
            <button type="button" class="btn btn-secondary" :class="{disabled: loading}"  @click="$emit('close')">{{buttonsText.close}}</button>
            <button type="button" :class="okButtonStyle + (loading ? ' disabled' : '')" class="btn" @click="$emit('success')">
              <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              {{buttonsText.success}}
            </button>
        </slot>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "ModalWindow",
  props:{
    body:{
      type:String
    },
    header:{
      type:String
    },
    "okButtonStyle":{
      type:String,
      default:"btn-primary",
    },
    "modalDialogClass":{
      type:String,
      default: ""
    },
    customModalBody:{
      type:String,
      default:''
    },
    buttonsText: {
      type: Object,
      default: {
        close: 'Нет',
        success: 'Да'
      }
    },
    showFooter: {
      type: Boolean,
      default: true,
    },
    loading: Boolean,
  },
  methods:{
    close:function () {
      this.$emit('close')
    },
    success:function (){
      this.$emit('success');
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

</style>