import {CrmApi} from "../CrmApi";

export {Companies};
class Companies {
    static getList(query) {
        return CrmApi.getEntityList('companies', query);
    }

    static editCompany(id, params) {
        let url = '/companies/' + encodeURIComponent(window.getId(id)) + '/';
        return CrmApi.call(url, params);
    }
}