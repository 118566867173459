<template>
  <tr>
    <th @click="orderBy('updated')"  style=" cursor: pointer" >Дата</th>
    <th @click="orderBy('name')"  style="cursor: pointer; " >Тема</th>
    <th @click="orderBy('person.email')" style="  cursor: pointer;" >Клиент</th>
    <th v-if="showProjectCell" @click="orderBy('project.name')" style="cursor: pointer; width: 160px;">Проект</th>
  </tr>
</template>

<script>
export default {
  name: "TableHeadRowsProjects",
  props:{
    'orderBy':{
      type:Function,
      require:true,
    }
  },
  computed: {
    showProjectCell() {
      return this.$route.params.project === 'all' || ['Recent', 'Competitor'].includes(this.$route.name);
    },
  },
}
</script>

<style scoped>

</style>