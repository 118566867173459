<template>
   <div class="elem-style additional-info" :style="customStyle" @click="searchUser">
     <i class="fa fa-lock button-icon"></i>
     <span :class="nameClass">{{name}}</span>
   </div>
</template>

<script>
import {getDateFormat} from "../Api/api";
export default {
  name: "InWorkLine",
  props:{
    "assigned":{
      type:Object,
      require:true
    },
    customStyle:{
      type:Object,
      default:{},
    },
  },
  computed:{
    nameClass: function (){
      return {
        'current-user': window.userId != null && window.userId === window.getId(this.assigned._id)
      };
    },
    name:function (){
      let name = "";
      if(typeof this.assigned.name === 'undefined' || this.assigned.name == null || this.assigned.name.trim() === ""   ){
        name = this.assigned.login;
      }else {
        name = this.assigned.name;
      }
      return name;
    },
    date:function (){
      return getDateFormat(this.assigned.date , "YYYY-MM-DD HH:mm");
    },
  },

  methods: {
    searchUser() {
      this.$store.commit('tickets/filters/updateUser', window.getId(this.assigned._id));
      this.$store.dispatch('tickets/filters/changeUser');
      this.$store.commit('tickets/filters/updateSettingShow', true);
    }
  }
}
</script>

<style scoped>
.elem-style {
  color: grey;
}
.elem-style:hover {
  color: black;
}
.elem-style > i {
  padding-right: 4px;
}
.inWorkPopper {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 5px;
  --popper-theme-box-shadow: 0 2px 15px -2px rgba(0, 0, 0, 0.25);
}
.current-user{
  color: red !important;
}
</style>