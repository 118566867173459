<template>
<li class="list-group-item" :class="isSaving">
  <span>{{email}}</span>
  <div class="pull-right">
    <popper :content="popperContentChangePrimary" class="light" placement="top" :hover="true" :interactive="false" :arrow="true" v-if="showPrimaryBtn">
      <i class="fa fa-star p-1 px-2" aria-hidden="true" :class="main ? '' : 'opacity-25 pointer'" @click="main ? null : changePrimaryEmail(email)"></i>
    </popper>
    <popper :content="popperContentDeleteEmail" class="light" placement="top" :hover="true" :interactive="false" :arrow="true" v-if="showDeleteBtn">
      <i class="fa fa-times p-1 px-2" style="color: red;" aria-hidden="true" :class="main ? '' : 'pointer'" @click="main ? null : deleteEmail(email)"></i>
    </popper>
    <div v-if="showSignatureBtn" class="d-inline-block">
      <i class="fa fa-pencil p-1 px-2 pointer" aria-hidden="true" @click="toggleSignatureForm"></i>
    </div>
  </div>
  <div class="my-3" v-if="editSignature">
    <alert ref="alert"></alert>

    <div class="form-group row">
      <label for="input-emailparams-name" class="col-sm-3">Имя отправителя</label>
      <div class="col-sm-9">
        <input name="name" id="input-emailparams-name" v-model="form.emailparams.name" class="form-control">
      </div>
    </div>

    <div class="form-group row">
      <label for="smtp-server" class="col-sm-3">SMTP</label>
      <div class="col-sm-9">
        <select id="smtp-server" class="form-select" aria-label="SMTP" v-model="form.emailparams.smtp">
          <option value="">Использовать SMTP-сервер по умолчанию</option>
          <option v-for="item in smtp" :value="item._id">{{ item.name }}</option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-sm-3">Входящий номер <popper class="light" :arrow="true" :hover="true"
                                                     :content="ingoingPhonePopperDescription">
        <i class="fa fa-question-circle-o"></i>
      </popper></label>
      <div class="col-sm-9">
        <div class="input-group mb-2">
          <phone
              type="phone"
              :class="{
                'border-danger': this.hasDuplicate(form.emailparams.phone, -1),
              }"
              v-model="form.emailparams.phone"
          ></phone>
          <button
              type="button"
              class="btn btn-outline-primary"
              @click="addAdditionalPhone"
          >+</button>
        </div>
        <div class="text-muted" style="font-size: 11px;">
          Если номер указан с добавочным, то будут регистрироваться вызовы только при вводе абонентом этого добавочного
        </div>
        <div v-if="hasAdditionalPhones" class="mt-2">
          <div>
            Дополнительные номера
            <popper
              class="light"
              :arrow="true"
              :hover="true"
              content="Работают для определения принадлежности входящего звонка аналогично основному номеру алиаса"
            ><i class="fa fa-question-circle-o"></i></popper>
          </div>
          <div class="input-group mb-1"
               v-for="(phone, index) of form.emailparams['additional_phones']"
          >
            <phone
              type="phone"
              :class="{
                'border-danger': this.hasDuplicate(form.emailparams['additional_phones'][index].number, index),
              }"
              v-model="form.emailparams['additional_phones'][index].number"
            ></phone>
            <button
              type="button"
              class="btn btn-outline-danger"
              @click="removeAdditionalPhone(index)"
            >&times;</button>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <label for="out-phone-endpoint" class="col-sm-3">Исходящий шлюз</label>
      <div class="col-sm-9">
        <select id="out-phone-endpoint" class="form-select" aria-label="Исходящий шлюз" v-model="form.emailparams.outphoneendpoint">
          <option :value="null">Не задан (исходящий вызов невозможен)</option>
          <option v-for="item in sortedEndpoints" :value="item">{{ item }}</option>
          <option
              v-if="showNonStandardAtsEndpoint"
              :value="form.emailparams.outphoneendpoint"
          >{{ form.emailparams.outphoneendpoint }} (не найден в списке шлюзов!)</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label for="textarea-signature-ru" class="form-label">Подпись RU</label>
        <textarea class="form-control mb-2" name="signature" id="textarea-signature-ru" v-model="form.signature.ru" rows="4" @change="parseSignature('ru')" @input="parseSignatureDelay('ru')"></textarea>
      </div>
      <div class="col-6">
        <label for="textarea-signature-en" class="form-label">Подпись EN</label>
        <textarea class="form-control mb-2" name="signature" id="textarea-signature-en" v-model="form.signature.en" rows="4" @change="parseSignature('en')" @input="parseSignatureDelay('en')"></textarea>
      </div>
      <div class="col-12 pb-2">
        <span class="text-muted">&#123;user.name&#125; - имя пользователя; &#123;user.phone_ext&#125; - добавочный номер телефонии; &#123;project.phone&#125; - номер телефона проекта / алиаса</span>
      </div>
      <div class="col-6">
        <textarea class="form-control mb-2" rows="4" v-model="form.signatureParsed.ru" disabled v-if="action === 'update'"></textarea>
      </div>
      <div class="col-6">
        <textarea class="form-control mb-2" rows="4" v-model="form.signatureParsed.en" disabled v-if="action === 'update'"></textarea>
      </div>
    </div>
    <div class="double-buttons-group">
      <div>
        <button class="btn btn-secondary" @click="closeSignatureForm(true)" :disabled="savingSignature">Отмена</button>
      </div>
      <div>
        <button class="btn btn-primary" v-if="action === 'update'" @click="updateSignature" :disabled="updateSignatureDisabled">Изменить</button>
        <button class="btn btn-primary" v-else @click="updateNewData" :disabled="updateSignatureDisabled">Изменить</button>
      </div>
    </div>
  </div>
</li>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import Alert from "../alert/Alert";
import Phone from "../common/Phone/Phone";
import {hasChanged} from "../../library/Functions";

export default {
  name: "ProjectFormEmail",

  components: {
    'alert': Alert,
    Phone,
  },

  props: {
    email: {
      type: String,
      require: true,
    },

    main: {
      type: Boolean,
      require: true,
    },

    callback: {
      type: Object,
      require: true,
    },

    signature: {
      type: Object,
    },

    emailparams: {
      type: Object,
    },

    type: {
      type: String,
      require: true,
    },

    action: {
      type: String,
      require: true
    },

    project:{
      type: Object,
      require: true,
    },

    atsEndpoints: {
      type: Array,
      default: [],
    },

    smtp: {
      type: Array,
      default: [],
    }
  },

  data: function() {
    return {
      editSignature: false,
      form: {
        signature: {ru: '', en: ''},
        signatureParsed: {ru: '', en: ''},
        emailparams: {name: '', smtp: '', phone: '', additional_phones: [], outphoneendpoint: null},
      },
      saved: {
        signature: {ru: '', en: ''},
        emailparams: {name: '', smtp: '', phone: '', additional_phones: [], outphoneendpoint: null},
      },
      signatureParseLastRequest: {ru: '', en: ''},

      savingSignature: false,
      saving: false,
      signatureParseTimeout: null,
      ingoingPhonePopperDescription: "Если у компании несколько добавочных, то нужно прописать определенный формат добавочного для " +
          "того чтобы звонок попал в нужный проект. В подписи не будет отображаться такой формат и его нужно дописать вручную. Если " +
          "телефон указан с добавочным, то только звонки по добавочному будут регистрироваться по данному алиасу. Если телефон указан " +
          "без добавочного, то будут регистрироваться все звонки добавочный по которым не задан в других алиасах. Добавочный null указывает, " +
          "что требуется регистрировать только вызовы, по которым клиент не набрал добавочный.",
    }
  },

  mounted: function() {
    this.form.signature = { ...this.signature };
    this.saved.signature = { ...this.signature };
    this.form.emailparams = this.sortAdditionalPhones(_.cloneDeep(this.emailparams));
    this.saved.emailparams = this.sortAdditionalPhones(_.cloneDeep(this.emailparams));
  },

  computed: {
    popperContentChangePrimary: function() {
      return this.main ? 'Основная почта' : 'Сделать основной почтой проекта';
    },

    popperContentDeleteEmail: function() {
      return this.main ? 'Удалить основную почту нельзя' : 'Удалить email из проекта';
    },

    popperContentSignatureTemplate: function() {
      return 'Задать шаблон подписи';
    },

    isSaving: function() {
      if(this.saving) {
        return 'cursor-wait text-muted bg-light';
      }
    },

    sortedEndpoints: function(){
      return this.atsEndpoints.sort();
    },

    updateSignatureDisabled: function() {
      let filterEmpty = item => typeof item?.number === 'string' && item.number.trim() !== '';
      return (this.savingSignature ||
              this.hasDuplicatesInPhones ||
              (this.form.signature['ru'] === this.saved.signature['ru'] &&
                  this.form.signature['en'] === this.saved.signature['en'] &&
                  this.form.emailparams['name'] === this.saved.emailparams['name'] &&
                  this.form.emailparams['smtp'] === this.saved.emailparams['smtp'] &&
                  this.form.emailparams['outphoneendpoint'] === this.saved.emailparams['outphoneendpoint'] &&
                  _.isEqual(
                      [...this.form.emailparams['additional_phones']].filter(filterEmpty),
                      [...this.saved.emailparams['additional_phones']].filter(filterEmpty)
                  ) &&
                  this.form.emailparams['phone'] === this.saved.emailparams['phone']));
    },

    showPrimaryBtn: function() {
      return (this.type !== 'notifications');
    },

    showDeleteBtn: function() {
      return true;
    },

    showSignatureBtn: function() {
      return (this.type !== 'notifications');
    },

    showNonStandardAtsEndpoint() {
      return typeof this.form.emailparams?.outphoneendpoint !== 'undefined' &&
             this.form.emailparams.outphoneendpoint !== null &&
             this.form.emailparams.outphoneendpoint !== '' &&
             !this.atsEndpoints.includes(this.form.emailparams.outphoneendpoint);
    },

    hasAdditionalPhones() {
      return typeof this.form.emailparams['additional_phones'] === 'object' &&
             Array.isArray(this.form.emailparams['additional_phones']) &&
             this.form.emailparams['additional_phones'].length > 0;
    },

    hasDuplicatesInPhones() {
      let result = false;
      if (this.hasAdditionalPhones) {
        let phones = [...this.form.emailparams['additional_phones']]
            .filter(item => typeof item?.number === 'string' && item.number.trim() !== '')
            .map(item => this.normalizePhone(item?.number));
        phones.push(this.normalizePhone(this.form.emailparams.phone));
        result = new Set(phones).size !== phones.length;
      }
      return result;
    },
  },

  emits: [
      'deleteAlias'
  ],

  methods: {
    sortAdditionalPhones(data) {
      if (typeof data['additional_phones'] === 'object' &&
          Array.isArray(data['additional_phones']) &&
          data['additional_phones'].length > 0) {
        data['additional_phones'] = data['additional_phones'].sort((a, b) =>  a.order - b.order);
      }
      return data;
    },
    hasDuplicate(phone, index) {
      let result = false;
      let check = this.normalizePhone(phone ?? '');
      if (this.hasAdditionalPhones && check) {
        let phones = [...this.form.emailparams['additional_phones']]
            .filter(item => typeof item?.number === 'string' && item.number.trim() !== '')
            .map(item => this.normalizePhone(item?.number));
        if (index !== -1) {
          phones.push(this.normalizePhone(this.form.emailparams.phone));
        }
        result = phones.filter((item, i) => check === item && i !== index).length > 0;
      }
      return result;
    },
    normalizePhone(number) {
      let result;
      let ext = null;
      let regEx = /(.*),?\s*\(?((до[бп]|ext|,|вн?|внутр?|внутренний|внеш(ний)?|д|#|int)\.?\s*(\sтел)?:?\s*?(\(?([\d\-]+)\)?|([nN][uU][lL][lL])))\)?$/ui;
      result = number.replace(regEx, (matches, m1, m2, m3, m4, m5, m6) => {
        ext = m6?.trim() ?? '';
        return m1?.trim() ?? '';
      });
      return result + (ext ? '#' + ext : '');
    },
    toggleSignatureForm: function() {
      if(this.editSignature) {
        this.closeSignatureForm(true);
      } else {
        this.editSignature = true;
        if(this.action === 'update') {
          this.parseSignature();
        }
      }
    },

    deleteEmail: function() {
      if(this.action === 'create') {
        this.callback.deleteEmail(this.email, this.type);
        this.$el.parentNode.removeChild(this.$el);
      } else {
        this.$emit('deleteAlias', this.email, this.type);
      }
    },

    updateSignature: function() {
      this.savingSignature = true;

      this.callback.updateSignature(this.email, this.form.signature, this.form.emailparams).then((response) => {
        this.closeSignatureForm();
        this.savingSignature = false;
        this.saved.signature = { ...this.form.signature };
        this.saved.emailparams = _.cloneDeep(this.form.emailparams);
      }).catch((error) => {
        this.$refs.alert.show(CrmApi.getErrorMessage(error), 'danger', 0);
        this.savingSignature = false;
      })
    },

    updateNewData() {
      this.callback.updateSignature(this.email, this.form.signature, this.form.emailparams);
      this.closeSignatureForm();
    },

    closeSignatureForm: function(returnFieldValue = false) {
      // скрываем алерт
      this.$refs.alert.hide();

      if(returnFieldValue) {
        // возвращаем исходные значения полей
        this.form.signature = { ...this.saved.signature };
        this.form.emailparams = _.cloneDeep(this.saved.emailparams);
        this.form.signatureParsed = {ru: '', en: ''};
      }

      // скрываем форму
      this.editSignature = false;
    },

    parseSignature: function(lang) {
      if(typeof lang === 'undefined') {
        this.parseSignature('ru');
        this.parseSignature('en');
        return;
      }

      if(this.action === 'create') {
        this.callback.updateSignature(this.email, this.form.signature);
        return;
      }

      if(this.form.signature[lang] === '') {
        this.form.signatureParsed[lang] = '';
        return;
      }

      this.signatureParseLastRequest[lang] = this.form.signature[lang];
      CrmApi.call('/project/template/signature/', {
        template: this.form.signature[lang],
        project: this.project._id,
        alias: this.email,
        lang: lang
      }).then((response) => {
        if(typeof response.data.result !== 'undefined') {
          this.form.signatureParsed[lang] = response.data.result;
        }
      });
    },

    parseSignatureDelay: function(lang) {
      clearTimeout(this.signatureParseTimeout);
      this.signatureParseTimeout = setTimeout(() => {
        this.parseSignature(lang);
      }, 1000);
    },

    changePrimaryEmail: function() {
      if(this.action === 'create') {
        this.callback.changePrimaryEmail(this.email)
      } else {
        this.saving = true;
        this.callback.changePrimaryEmail(this.email).then(() => { this.saving = false; });
      }
    },

    addAdditionalPhone: function () {
      let order = 0;
      if (
          typeof this.form.emailparams['additional_phones'] !== 'object' ||
          !Array.isArray(this.form.emailparams['additional_phones'])
      ) {
        this.form.emailparams['additional_phones'] = [];
      } else {
        order = this.form.emailparams['additional_phones'].reduce((a, b) => Math.max(a?.order ?? 0, b?.order ?? 0), 0);
      }
      this.form.emailparams['additional_phones'].push({
        number: "",
        order: order + 1,
      });
    },

    removeAdditionalPhone: function (index) {
      this.form.emailparams['additional_phones'].splice(index, 1);
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
</style>