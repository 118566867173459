const offerMutations = {
    setOffers(state, {offers}){
        for (let offer of offers){
            state.offers.list.set(offer._id , offer);
        }
    },

    clearOffers(state){
        state.offers.list.clear();
    },

    clearPaginator(state){
        state.paginator = {
            page:1,
            onpage:50,
            links:[],
            total:0,
        }
    },

    clearPersons(state) {
        state.persons.list.clear();
    },

    setPersons(state, {persons}) {
        for (let person of persons) {
            state.persons.list.set(window.getId(person._id), person);
        }
    },

    setPerson(state, person) {
        state.persons.list.set(window.getId(person._id), person);
    },

    deletePerson(state, {_id}) {
        state.persons.list.delete(_id);
    },

    deleteOffer(state , {_id}){
        state.offers.list.delete(_id);
    },

    setLoading(state , value){
        state.loading = value;
    },
    setPage(state , value){
        state.paginator.page = value;
    },
    setOnPage(state, value){
        state.paginator.onpage = value;
    },
    setTotal(state, value){
        state.paginator.total = value;
    },
    setLinks(state, value){
        state.paginator.links = value;
    },
    setConfirmedOffer(state, offer) {
        state.confirmedOffer = offer;
    },
    setTicketScrollPosition(state, pos) {
        state.ticketScrollPosition = pos;
    }
}

export default offerMutations;