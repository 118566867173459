<template>
  <div :class="{'cursor-wait':loading}">
    <a :class="{'text-muted':loading, 'custom-disabled':loading}" :href="ticketLink"  v-show="idList.length === 1" class="btn btn-light btn-outline-dark col-12"><i class="fa fa-folder-open button-icon menu-button"></i>Открыть</a>
    <read-and-not-read-buttons :id-list="idList"></read-and-not-read-buttons>
    <toggle-answered v-if="userCan.answer && showAnsweredToggleButtons && hasNotClosedTickets" />
    <reopen-button v-if="userCan.open_close && hasClosedTickets"></reopen-button>
    <transfer-button v-if="userCan.transfer || userCan.transferAny" :disabled="!userCan.transfer"></transfer-button>
    <tags-button v-if="userCan.tags"></tags-button>
    <favorite-button :callback="favoriteCallback"></favorite-button>
    <lock-and-unlock-buttons v-if="hasNotClosedTickets" :id-list="idList"></lock-and-unlock-buttons>
    <close-button v-if="userCan.open_close && hasNotClosedTickets"></close-button>
    <delete-with-spam-button v-if="userCan.delete || userCan.deleteAny" :disabled="!userCan.delete" v-show="idList.length > 0" :ipspam="ipspam" :ipspam-value="ipspamValue" :competitor-callback="competitorCallback" />
    <delete-button v-if="userCan.delete || userCan.deleteAny" :disabled="!userCan.delete" v-show="idList.length > 0"></delete-button>
  </div>

</template>

<script>
import DeleteButton from "./DeleteButton";
import TransferButton from "./transfer/TransferButton";
import {UserPermission} from "../../../users/UserPermission";
import ReadAndNotReadButtons from "./ReadAndNotReadButtons";
import TagsButton from "./TagsButton";
import FavoriteButton from "./FavoriteButton";
import LockAndUnlockButtons from "./LockAndUnlockButtons";
import DeleteWithTagButton from "./DeleteWithTagButton";
import {mapGetters} from "vuex";
import DeleteWithSpamButton from "./DeleteWithSpamButton";
import ToggleAnswered from "./ToggleAnswered";
import CloseButton from "./CloseButton";
import ReopenButton from "./ReopenButton";
import {prepareObjectToUrl} from "../../../library/Functions";

export default {
  name: "Buttons",
  components: {
    ToggleAnswered,
    DeleteWithSpamButton,
    DeleteWithTagButton,
    TagsButton,
    FavoriteButton,
    ReadAndNotReadButtons,
    LockAndUnlockButtons,
    "delete-button":DeleteButton,
    "transfer-button":TransferButton,
    CloseButton,
    ReopenButton,
  },
  props:{
    'idList':{
      type:Array
    }
  },
  data: function (){
    return {
      userCan: {
        answer: false,
        delete: false,
        deleteAny: false,
        transfer: false,
        transferAny: false,
        tags: false,
        open_close: false,
      }
    }
  },
  mounted() {
    UserPermission.can("system.tag_control").then(() => {
      this.userCan.tags = true;
    })
    this.checkPermission();
  },
  watch: {
    '$store.state.tickets.ticketSelectedControl': function(){

      this.checkPermission();
    }
  },
  methods:{
    checkPermission() {
      let permissions = {
        delete: [],
        change: [],
        answer: [],
        open_close: [],
      }

      Object.values(this.getSelectedObjects).forEach(ticket => {
        ['delete', 'change'].forEach(name => permissions[name].push(`${ticket.project.name}.${name}`));
        [ticket.project.name].concat(
            (ticket?.assigned_projects?.map(project => project.name) ?? [])
        ).forEach(name => permissions['answer'].push(`${name}.answer`, `${name}.open_close`));
      });

      let allPermissions = [];
      Object.keys(permissions).forEach(name => {
        permissions[name] = _.uniq(permissions[name]);
        allPermissions = allPermissions.concat(permissions[name]);
      });

      //preload
      UserPermission.can(allPermissions).catch(() => {});

      this.userCan.delete = false;
      this.userCan.deleteAny = false;

      UserPermission.can(permissions.delete)
                    .then(() => this.userCan.delete = true)
                    .catch(()=>{});

      Promise.any(
          permissions.delete.map((permission) => UserPermission.can(permission))
      ).then(() => {
        this.userCan.deleteAny = true;
      }).catch(()=>{});

      this.userCan.transfer = false;
      this.userCan.transferAny = false;

      UserPermission.can(permissions.change)
                    .then(() => this.userCan.transfer = true)
                    .catch(()=>{});

      Promise.any(
          permissions.change.map((permission) => UserPermission.can(permission))
      ).then(() => {
        this.userCan.transferAny = true;
      }).catch(()=>{});

      this.userCan.answer = false;

      Promise.all(Object.values(this.getSelectedObjects)
                        .map(ticket => UserPermission.canTicket(ticket, 'answer', true)))
             .then(() => this.userCan.answer = true)
             .catch(() => {});

      this.userCan.open_close = false;

      Promise.all(Object.values(this.getSelectedObjects)
                        .map(ticket => UserPermission.canTicket(ticket, 'open_close', true)))
             .then(() => this.userCan.open_close = true)
             .catch(() => {});
    },

    competitorCallback() {
      this.$store.commit('successPush', 'Обновлено', {root:true});
      this.$store.dispatch('tickets/autoUpdateTicketList', this.$route);
    },

    favoriteCallback: function() {
      // обновление списка тикетов после добавление/удаление из избранного, если открыт раздел "избранное"
      if(typeof this.$route.params.filter !== 'undefined' &&
         this.$route.params.filter === 'favorite') {

          this.$store.dispatch('tickets/autoUpdateTicketList', this.$route);
      }
    }
  },
  computed:{
    showAnsweredToggleButtons() {
      return this.$route?.name !== 'Competitor';
    },

    hasNotClosedTickets() {
      let result = false;
      for (let el of Object.values(this.getSelectedObjects)) {
        if (el?.closed !== true) {
          result = true;
          break;
        }
      }
      return result;
    },

    hasClosedTickets() {
      let result = false;
      for (let el of Object.values(this.getSelectedObjects)) {
        if (el?.closed === true) {
          result = true;
          break;
        }
      }
      return result;
    },

    ticketLink:function (){
      if(this.loading){
        return "#";
      }
      let query = null;
      if (typeof this.searchString === 'string' && this.searchString.trim() !== '') {
        let ticket = this.getSelectedObjects[this.idList[0]] ?? null;
        if (ticket &&
            typeof ticket?.sub_bodysearch === 'object' &&
            Array.isArray(ticket.sub_bodysearch) &&
            ticket.sub_bodysearch.length
        ) {
          query = {
            search: this.searchString.trim()
          };
        }
      }
      return "/tickets/" + this.idList[0] + prepareObjectToUrl(query);
    },
    loading:function (){
      return this.$store.getters["tickets/isTicketLoading"];
    },
    deleteTag() {
      let result = null;
      let deleteTags = [];
      for (let el of this.getProjects){
        if(typeof this.getSelectedProjects[window.getId(el._id)] !== 'undefined' &&
            typeof el.deletetag !== 'undefined' && el.deletetag !== '' && deleteTags.indexOf(el.deletetag) === -1){
          deleteTags.push(el.deletetag)
        }
      }
      if (deleteTags.length === 1) {
        result = deleteTags.pop();
      }

      return result;
    },
    ipspam() {
      return Object.values(this.getSelectedProjects).some(project => project.ipspam);
    },
    ipspamValue() {
      let result = null;
      let values = [];
      if (this.ipspam === true) {
        this.$store.getters["tickets/getSelectedArray"].forEach((object)=> {
          values.push(this.$store.getters["tickets/getSelectedObjects"][object]?.competitor ?? false);
        });
        if (values.filter((v, i, a) => a.indexOf(v) === i).length === 1) {
          result = values[0];
        }
      }
      return result;
    },
    ...mapGetters({
      'getProjects':'tickets/projects/getProjects',
      "getSelectedArray":  'tickets/getSelectedArray',
      'getSelectedObjects': 'tickets/getSelectedObjects',
      'getSelectedProjects':'tickets/getSelectedProjects',
      "searchString": 'tickets/filters/getSearchString',
    }),
  }
}
</script>

<style scoped>

</style>