<template>
  <span :class="{loading: loading, 'align-items-center': !(asInput && absolute), 'justify-content-between': contentBetween}"
        class="d-inline-flex w-100 position-relative"
        @mouseover="showCopy= true"
        @mouseleave="showCopy = false">
    <popper :content="nameToShow" :class="{'w-100': contentBetween}" class="light phone-width" placement="top" :hover="true" :interactive="false" :arrow="true"  >
      <span class="d-inline-flex">
        <editable-phone
            v-model="phoneValue"
            :callback="change"
            :is-call-permission="isCallPermission"
            :phone="person.phone[index]"
            :input-class="inputClass"
            :input-style="inputStyle"
            :absolute="absolute"
            :absolute-parent="absoluteParent"
            :dynamic-absolute-width="dynamicAbsoluteWidth"
            :trim="true"
            :user-can-change="userCanChange"
            :disabled="loading"
            @open="asInput = true"
            @close="asInput = false"
            ref="phone-input"
        />

        <span v-show="showCopy && !asInput">
         <copy-badge :content="phoneValue" />
        </span>
      </span>
    </popper>

    <span class="ps-1" style="margin: 0 0 auto 0;" :class="typeClass">
      <template v-if="userCanChange && editableType">
        (<editable-select
          :options="options"
          :selected="type"
          @change="changePhoneType"
          :select-class="selectClass"
          :select-style="selectStyle"
        />)
      </template>
      <template v-else-if="type">
        <template v-if="getIcon(type)">
          <i class="fa" :class="getIcon(type)" style="margin-top: 0.25em;"></i>
        </template>
        <template v-else> ({{getType(type)}})</template>
      </template>
    </span>
  </span>
</template>

<script>
import EditableSelect from "../../../../Editable/EditableSelect";
import EditablePhone from "../../../../common/Phone/EditablePhone";
import {CrmApi} from "../../../../../library/CrmApi";
import {mapGetters, mapMutations} from "vuex";
import CopyBadge from "../../../../common/CopyBadge";
import StringTrim from "../../../../string/StringTrim";
export default {
  name: "Phone",

  components: {StringTrim, EditableSelect, EditablePhone, CopyBadge},

  props: {
    name:{
      type:String,
      default:null,
    },
    person: {
      require: true,
    },
    ticketId: {
      require: true,
    },
    phone: {
      type: String,
      require: true,
    },
    type: {
      type: String,
    },
    cutType: {
      type: Boolean,
      default: false,
    },
    editableType: {
      type: Boolean,
      default: false,
    },
    isCallPermission: {
      type: Boolean,
      default: false,
    },
    inputClass: {
      type: String,
      default: 'form-control d-inline',
    },
    inputStyle: {
      type: String,
      default: 'width: 15em',
    },
    selectClass: {
      type: String,
      default: 'm-1 d-inline text-center form-control'
    },
    selectStyle: {
      type: String,
      default: 'width: 7em'
    },
    typeClass: {
      type: String,
    },
    absolute: {
      type: Boolean,
      default: false
    },
    absoluteParent: {
      default: null
    },
    dynamicAbsoluteWidth: {
      type: Boolean,
      default: false,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    },
    contentBetween: {
      type: Boolean,
      default: true
    },
    index: {
      default: 0,
    }
  },

  data: function() {
    return {
      showCopy: false,
      asInput: false,
      loading: false,
      options: ['рабочий', 'мобильный', 'telegram', 'whatsapp', 'другой'],
    }
  },

  computed: {
    phoneValue:{
      set(value){

      },
      get(){
        return this.phone;
      }
    },

    nameToShow:function (){
      let nameToShow = this.name;
      if(typeof nameToShow === 'string'){
        nameToShow = nameToShow.trim()
      }
      return nameToShow ?? null;
    },

    change: function () {
      return this.editableType ? this.changePhone : this.editPhone;
    },

    ...mapGetters({
      "api": "thread/getApi",
    }),
  },

  methods: {
    getType: function (type) {
      let cuttype;
      if(type === 'другой') {
        cuttype = 'др.';
      } else {
        cuttype = (this.cutType && type.length > 4) ? type.substr(0, 3) + '.' : type;
      }
      return cuttype;
    },

    getIcon: function(type) {
      let icon = null;

      switch(type) {
        case 'telegram':
          icon = 'fa-telegram';
          break;
        case 'whatsapp':
          icon = 'fa-whatsapp';
          break;
      }

      return icon;
    },

    editPhone: function (phone) {
      let data = {}
      data.rem = {
        phone: [
          this.phone
        ]
      }
      if (phone !== '') {
        data.add = {
          phone: {}
        }
        data.add.phone[this.index] = phone;
      }
      this.loading = true;
      return new Promise((resolve, reject) => {
        this.api.editPerson(data).then(result => {
          if (typeof result.data.person === 'object' && result.data.person !== null) {
            this.setPerson(result.data.person);
            this.$emit('update', result.data.person);
          }
          resolve(result.data);
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          reject(error);
        }).finally(() => {
          this.loading = false;
        });
      });
    },

    changePhone: function(phone) {
      let send = [ ...this.person.phone ].map(item => {
        return {...item};
      });
      let el = send[this.index];

      if (phone === '') {
        send.splice(this.index, 1);
      } else {
        el.number = phone;
      }

      return this.updatePerson({phone: send});
    },

    remove: function() {
      this.person.phone.splice(this.index, 1);
    },

    changePhoneType: function(type) {
      let el = this.person.phone[this.index];
      let prev = el.type ?? undefined;
      el.type = type;

      return this.updatePerson({phone: this.person.phone}, () => {
        el.type = prev;
      });
    },

    updatePerson: function (data, catchCallback = null, finallyCallback = () => { this.loading = false;}) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        CrmApi.updateThreadPerson(this.ticketId, data).then(result => {
          this.setPerson(result.data.person);
          this.$emit('update', result.data.person);
          resolve(result.data);
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          if (catchCallback !== null) {
            catchCallback();
          }
          reject(error);
        }).finally(() => {
          finallyCallback();
        });
      });
    },

    ...mapMutations({
      setPerson: 'thread/setPerson',
    })
  },
}
</script>

<style scoped>
.phone-width {
  display: inline-flex;
  max-width: 300px;
}
:deep(.phone-width > div.popper) {
  max-width: 100%;
}
:deep(.phone-width > div:not(.popper)) {
  display: inline-flex;
  width: 100%;
}
</style>