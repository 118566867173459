<template>
  <tr class="task-row alert "  :class="trClass" >
    <td class="text-center"   >
      <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <template #content>
          <div>
            Создана: {{created}}
          </div>
          <div v-if="closed">
            Дата завершения: {{closed}}
          </div>
        </template>
        <span :class="{'text-danger':isClosedAndExpired}">{{date}}</span>
      </popper>
    </td>
    <td>
      <div>
        <string-trim
          :class-string="{'fw-bold':isUserTask, 'text-decoration-line-through': message.headers.status === 'DONE' }"
          :string="message.subject"
        ></string-trim>
      </div>
      <div>
        <string-trim
            :class-string="{'body-class':true,'text-decoration-line-through': message.headers.status === 'DONE' }"
            :string="message.body"
        ></string-trim>
      </div>
    </td>
    <td class="text-center align-top"  >
      <popper  class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <template #content>
          <div>
            Постановщик: <span>{{author}}</span>
          </div>
          <div v-if="hasWatchers" >
            <span>Наблюдатели: </span><span>{{watchers}}</span>
          </div>
          <div v-if="completedByName">Выполнил: <span>{{ completedByName }}</span></div>
        </template>
          <span>{{ assignee }}</span>
      </popper>
    </td>
    <td class="text-center align-top">
      <string-trim :string="message.ticket?.person?.email ?? '—'" />
    </td>
    <td  v-if="$route.params.project === 'all'" class="text-center align-top" >{{message?.ticket?.project?.name ?? '—'}}</td>
  </tr>
</template>

<script>
import moment from "moment";
import StringTrim from "../../../string/StringTrim";
import {mapState} from "vuex";
import {getNameWithLogin} from "../../../../library/userName";

export default {
  name: "TasksRow",
  components: {StringTrim},
  props:{
    message:{
      type:Object,
      require:true,
    }
  },
  computed:{
    isLoading(){
      let isLoading = false;
      if(typeof this.ticketsState.ticketLoadingControl.objects[this.message._id] !== 'undefined' && typeof  this.ticketsState.ticketLoadingControl.objects[this.message._id].loading !== 'undefined'){
        isLoading = this.ticketsState.ticketLoadingControl.objects[this.message._id].loading;
      }

      return isLoading
    },
    author:function (){
      let author = "—";
      if(this.message?.author){
        author = getNameWithLogin(this.message.author, "—")
      }
      return author;
    },
    assignee:function (){
      let assinge = "—";
      if(this.message?.headers?.assignee){
         assinge = getNameWithLogin(this.message.headers.assignee, "—")
      }
      return assinge;
    },
    completedBy() {
      return this.message?.headers?.completed_by ?? null;
    },
    completedByName() {
      return this.completedBy && this.message?.headers?.status === 'DONE' ? getNameWithLogin(this.completedBy) : '';
    },
    hasWatchers() {
      return this.message?.headers?.watchers &&
             Array.isArray(this.message?.headers?.watchers) &&
             this.message?.headers?.watchers.length > 0;
    },
    watchers:function (){
      let watchers = [];
      if (this.hasWatchers) {
        for (let watcher of this.message.headers.watchers){
          let l = getNameWithLogin(watcher, "—");
          if(l !== "—"){
            watchers.push(l)
          }
        }
      }
      return watchers.join(', ');
    },
    created:function (){
      return moment(this.message.created).format("YYYY-MM-DD HH:mm")
    },
    closed:function (){
      return this.message?.headers?.completed ? moment(this.message.headers.completed).format("YYYY-MM-DD HH:mm") : null;
    },
    date:function (){

      return this.message?.headers?.deadlinedate ? moment(this.message.headers.deadlinedate).format("YYYY-MM-DD HH:mm") : "—";
    },
    isSelected(){
      return typeof this.ticketsState.ticketSelectedControl.objects[this.message._id] !== 'undefined';
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    isExpired:function (){
      let isExpired = false;
      if(this.message?.headers?.deadlinedate){
        isExpired =  moment(this.message.headers.deadlinedate).unix() < moment().unix() && this.message.headers.status !== 'DONE';
      }
      return isExpired;
    },
    isOpen:function (){
      return this.message?.headers?.deadlinedate && this.message.headers.status !== 'DONE';
    },
    isUserTask:function(){
      return this.message.headers.status !== 'DONE' && window.getId( this.message?.headers?.assignee?._id)  === window.userId
    },
    isClosedAndExpired:function (){
      let isExpired = false;
      if(this.message?.headers?.deadlinedate && this.message?.headers?.completed){
        isExpired =  moment(this.message.headers.deadlinedate).unix() < moment( this.message.headers.completed).unix() && this.message.headers.status === 'DONE';
      }
      return isExpired;
    },
    trClass() {
      return {

        "alert alert-warning":this.isOpen && !this.isExpired,
        "alert alert-danger":this.isExpired,
        "hoveredRow" : !this.isSelected,
        "pickRow" : this.isSelected,
        "loadingClass": this.isLoading,
        "text-muted":this.isLoading,
      }
    },
  }
}
</script>

<style scoped>
.task-row{
  height: 44px;
}
.hoveredRow:hover {
  background: #ecf3ff;
  --bs-table-bg:#ecf3ff;
}
.pickRow{
  background:#c5ffbb;
  --bs-table-bg:#c5ffbb;
}
:deep(.body-class){
  font-size: 12px;
  color: grey;
}
.alert-danger{
  --bs-alert-color:none;
}
.background-danger{
  background-color: #f8d7da;
}
.background-warning{
  background-color: #f2e8c2;
}
:deep(.popper) {
  position: fixed !important;
  z-index: 1200;
}
</style>