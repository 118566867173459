const mutations = {
    setTopLevel(state, value) {
        state.topLevel = value;
    },
    setSelectionId(state , id){
        state.selectionControl.id = id
    },
    setSelectionFilter(state , filter){
        state.selectionControl.filter = filter
    },
    setSelectionProjectName(state , projectName){
        state.selectionControl.projectName = projectName
    },
    resetSelectionControl(state){
        state.selectionControl = {
            id:null,
            projectName:null,
            filter:null,
        }
    },
    setShowAll(state, value) {
        state.showAll = value;
    },
}

export default mutations;