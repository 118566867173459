import {createLogger, createStore} from "vuex";
import commonMutations from "../common/mutations";
import * as actions from "../common/actions";
import * as getters from "../common/getters";
import {shareState} from "../common/composition";


export default createStore({
    modules:{
    },
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    plugins: []
})
