import {IPv4Net} from "../../../library/IPv4Net";
import moment from "moment";
import {PricePrettyView} from "../../../library/PricePrettyView";


export default function parseString(value){
    let object = null;
    try {
        if(isFirstTemplate(value)){
            object =  parseFirstTemplate(value);
        }

        if(isSecondTemplate(value)){
            object =  parseSecondTemplate(value)
        }
    }catch (nothing){}

    return object;
}



function isFirstTemplate(value){
    return value.indexOf('The RIPE NCC has completed') !== -1;
}


function isSecondTemplate(value){
    let matches = value
        .trim()
        .match(/([^\s]+)\t+([^\s]+)\t+([^\s]+)\t+([^\(]*)\s+\(([^\)]*)\)\s*to\s*([^\(]*)\s+\(([^\)]*)\)(.*)/);
    let isSecondTemplate = true;
    if(matches !== null){
        if(matches.length < 4){
            isSecondTemplate = false;
        }
        if(!matches?.[1]){
            let exp = matches[1].split(' ');
            for (let value of exp){
                if(value.trim() != '' && !(new IPv4Net(value.trim())).isIp()){
                    isSecondTemplate = false;
                    break;
                }
            }

        }
        if(!matches?.[2] || !matches[2].match(/^[0-9]+$/)){
            isSecondTemplate = false;
        }

        if(!matches?.[3] || !moment(matches[3], 'DD.MM.YYYY').isValid()){
            isSecondTemplate = false;
        }
    }else {
        isSecondTemplate = false;
    }

    return isSecondTemplate;
}

function parseSecondTemplate(value){
    //213.5.75.0/24 256 26.04.2021 UnionLine LLC (ORG-UL41-RIPE) to Resource Group LLC (ORG-RGL18-RIPE, v.sozontov@tetron.ru )
    // 193.42.108.0/22	1024	16.12.2022	LIR LLC (ru.lir) to лоЛ (lv.ua-hosting, finance@ua-hosting.company)	$4,83 (4654,3€)	$9,77 ($10000)
    let matches = value
        .trim()
        .match(/^(.*)\t+([^\s]+)\t+([^\s]+)\t+([^\(]*)\s+\(([^\)]*)\)\s*to\s*([^\(]*)\s+\(([^\)]*)\)(.*)/);

    let object = {};
    object.subnet = [];
    let exp = matches[1].split(' ');
    for (let value of exp){
        if(value.trim() !== ''){
            object.subnet.push(value);
        }
    }

    object.date= matches[3].trim();
    object.seller = {
        name:'',
        email:'',
        contact_emails:[ ],
        org:'',
        lirid:'',
    };
    object.seller.name = matches?.[4] ?? '';
    if(matches?.[5]){
        let s = parseLidEmail(matches[5]);
        object.seller.org = s?.org ?? ''
        object.seller.org = object.seller.org.trim();
        object.seller.lirid = s?.lirid ?? ''
        object.seller.lirid= object.seller.lirid.trim()
        object.seller.email =  s?.email ?? ''
        object.seller.email = object.seller.email.trim()
        object.seller.contact_emails =  s?.contact_emails ?? ''
    }
    object.buyer = {
        name:'',
        email:'',
        contact_emails:[ ],
        org:'',
        lirid:'',
    };
    object.buyer.name =  matches?.[6] ?? '';
    object.buyer.name = object.buyer.name.trim()
    if(matches?.[7]){
        let s = parseLidEmail(matches[7]);
        object.buyer.org = s?.org ?? ''
        object.buyer.org = object.buyer.org.trim();
        object.buyer.lirid = s?.lirid ?? ''
        object.buyer.lirid =  object.buyer.lirid.trim()
        object.buyer.email =  s?.email ?? ''
        object.buyer.email = object.buyer.email.trim();
        object.buyer.contact_emails =  s?.contact_emails ?? ''
    }

    if(
        object.seller?.lirid && object.buyer?.lirid &&
        !object.seller?.org && !object.buyer?.org
    ){
        object.subnettype = "PA";
    }
    if(
        !object.seller?.lirid && !object.buyer?.lirid &&
        object.seller?.org && object.buyer?.org
    ){
        object.subnettype = "PI";
    }

    if(matches?.[8]){
        let priceMatch = matches?.[8].trim().match(/([^\(]+\([^\(]+\))\s*([^\(]+\([^\(]+\))?/);
        if(priceMatch !== null){
            if(priceMatch?.[1]){
                object.buyprice = parsePrice(priceMatch?.[1].trim());
            }
            if(priceMatch?.[2]){
                object.sellprice = parsePrice(priceMatch?.[2].trim());
            }

            if(object.buyprice?.currencyNotEqual || object.sellprice?.currencyNotEqual){
                let tmp = []
                if(typeof object.buyprice?.priceonepretty !== 'undefined'){
                    tmp.push(object.buyprice?.priceonepretty)
                }
                if(typeof object.sellprice?.priceonepretty !== 'undefined'){
                    tmp.push(object.sellprice?.priceonepretty)
                }
                object.comment = tmp.join('/');
            }
            delete object.buyprice?.priceonepretty;
            delete object.sellprice?.priceonepretty
            delete object.buyprice?.currencyNotEqual;
            delete object.sellprice?.currencyNotEqual
        }else {
            // парсинг строки у которой цены только за 1 ip
            /// 176.53.224.0/19	8192	02.11.2021	PulNet Limited (n.gugnin@oyster-telecom.ru) to OOO "Network of data-centers "Selectel" (ru.selectel)	$43,5 $48
            priceMatch = matches?.[8].trim().match(/([^\s]+)\s*(.*)$/);
            if(priceMatch !== null){
                object.buyprice = parsePriceForOne(priceMatch?.[1])
                object.sellprice = parsePriceForOne(priceMatch?.[2])
            }
        }

    }

    return object;

}

function parsePriceForOne(value) {
    let object = {
        'currency':'RUB',
        'price':0,
        'type':"price1"
    };
    if(typeof value !== undefined && value !== null){
        value = value.trim();

        object.price = value

        let singleValueCurrency = PricePrettyView.parseCurrency(object.price, null);
        if(singleValueCurrency != null){
            object.currency = singleValueCurrency;
        }

        let n = object.price.replace(/[^0-9\.\,]/g,"").replace(",", ".");
        object.price = parseFloat(n)
    }

    return object;
}

function parsePrice(value){

    let object = {
        'currency':'RUB',
        'price':0,
        'type':"price"
    };
    value = value.trim();
    let matches = value.match(/^(.*?)\((.*?)\)/);
    let mainValue = matches?.[2];

    let totalValueCurrency = PricePrettyView.parseCurrency(mainValue, null);
    let singleValueCurrency = PricePrettyView.parseCurrency(matches?.[1], null);

    // если цены в скобках нет то берем без скобок
    if(!mainValue){
        mainValue =  matches?.[1];
        object.type = "price1";
    }


    if(totalValueCurrency == 'RUB'){
        totalValueCurrency = null
    }
    if(singleValueCurrency == 'RUB'){
        singleValueCurrency = null;
    }
    object.currencyNotEqual = false;
    if(singleValueCurrency != totalValueCurrency){
        object.currencyNotEqual = true;
    }
    if(singleValueCurrency == null){
        singleValueCurrency = 'RUB';
    }
    let p =new PricePrettyView(matches?.[1].replace(/[^0-9\.\,]/g,"").replace(",", "."),singleValueCurrency);
    p.roundMethod = PricePrettyView.withoutRound;
    object.priceonepretty = (p)
        .getPrettyPrice('', true, 6, false , "" );



    // Если цена в скобках не рубли то приоритет отдается цене за 1 не в скобках
    // 145 ($ 4445)  - берем 145
    /*
    if(
        mainValue == null ||
        totalValueCurrency !== null && singleValueCurrency === null
    ) {
        mainValue =  matches?.[1];
        object.type = "price1";
    }
    */
    /*
    if(
        matches?.[2] != null && matches?.[1] != null &&
        totalValueCurrency != singleValueCurrency
    ){
        if( totalValueCurrency == null ){
            object.comment = (new PricePrettyView(matches[1].replace(/[^0-9\.\,]/g,"").replace(",", "."),singleValueCurrency)).getPrettyPrice("",true);
        }
        if( singleValueCurrency == null ){
            object.comment = (new PricePrettyView(matches[2].replace(/[^0-9\.\,]/g,"").replace(",", "."),totalValueCurrency)).getPrettyPrice("",true);;
        }
    }
    */
    if(mainValue){
        let n = mainValue;
        let p = PricePrettyView.parseCurrency(mainValue, null)
        if(p !== null){
            object.currency = p;
        }
        n = n.replace(/[^0-9\.\,]/g,"").replace(",", ".");
        object.price = parseFloat(n)
    }
    return object;
}
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}
function getSubnets(value, splitString , type) {
    let subnets = [];
    const PAparts = value.split(splitString);
    let temp = [];
    for (let el of PAparts){
        let matches =  el.match(/\s*([0-9\.\/\-]+)/);
        if(matches !== null && matches?.[1] && (new IPv4Net(matches[1])).isIp() ){
            temp.push(matches[1].trim());
        }
    }
    let unique = temp.filter(onlyUnique)
    for (let el of unique){
        subnets.push({subnet:el, type:type})
    }
    return subnets;
}

function parseFirstTemplate(value){

    let type = "PA";
    let matches = value.match(/from(.*)\s*\(([^\)]+)\)\s*to\s*(.*)\s*\(([^\)]+)\)\:\s*IPv4 allocation\:\s*([0-9\.\/\-]+)/);
    if( matches == null ){
        matches = value.match(/from(.*)\s*\(([^\)]+)\)\s*to\s*(.*)\s*\(([^\)]+)\)\:\s*IPv4 assignment\:\s*([0-9\.\/\-]+)/);
        type = "PI";
    }
    let object = null;

    if(matches !== null){
        let subnets = [];
        for (let el of getSubnets(value, "IPv4 allocation:" , 'PA')){
            subnets.push(el);
        }
        for (let el of getSubnets(value, "IPv4 assignment:" , 'PI')){
            subnets.push(el);
        }
        object = {
            subnettype: type,
            subnets:subnets,
        };

        let sp = value.split('Dear');
        if(sp?.[0]){
            let dateparce = sp[0].match(/Support\)\s*(.*)\s*$/)

            if(dateparce !== null && dateparce?.[1]){
                let date = moment(dateparce[1].trim())
                if(date.isValid()){
                    object.date = date.format('DD.MM.YYYY');
                }
            }
        }


        object.seller = {
            name:'',
            email:'',
            contact_emails:[ ],
            org:'',
            lirid:'',
        }
        object.seller.name = matches?.[1] ?? '';
        object.seller.name =  object.seller.name.trim()
        if(matches?.[2]){
            let s = parseLidEmail(matches[2]);
            object.seller.org = s?.org ?? ''
            object.seller.org = object.seller.org.trim();
            object.seller.lirid = s?.lirid ?? ''
            object.seller.lirid= object.seller.lirid.trim()
            object.seller.email =  s?.email ?? ''
            object.seller.email = object.seller.email.trim()
            object.seller.contact_emails =  s?.contact_emails ?? ''
        }
        object.buyer = {
            name:'',
            email:'',
            contact_emails:[ ],
            org:'',
            lirid:'',
        };
        object.buyer.name =  matches?.[3] ?? '';
        object.buyer.name = object.buyer.name.trim()
        if(matches?.[4]){
            let s = parseLidEmail(matches[4]);
            object.buyer.org = s?.org ?? ''
            object.buyer.org = object.buyer.org.trim();
            object.buyer.lirid = s?.lirid ?? ''
            object.buyer.lirid =  object.buyer.lirid.trim()
            object.buyer.email =  s?.email ?? ''
            object.buyer.email = object.buyer.email.trim();
            object.buyer.contact_emails =  s?.contact_emails ?? ''
        }
        object.subnet = matches?.[5] ?? '';
    }

    return object;
}

function parseLidEmail(value){
    let ar = value.split(',');
    let object = {
        lirid:'',
        org:''
    }
    if(ar?.[0]){
        if(ar[0].toUpperCase().indexOf('ORG') === 0){
            object.org = ar[0]
        }else {
            object.lirid = ar[0]
        }
    }
    if(ar?.[1]){
        object.email = ar[1]
        object.contact_emails = [];
        for (let el of ar.slice(2)){
            object.contact_emails.push({
              mail:el.trim(),
              type:'рабочий'
            })
        }
    }
    return object;
}