<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      <template v-if="user">
        {{user}} задал(а) <a :href="buyerTicketLink" target="_blank">покупателя</a>
      </template>
      <template v-else>
        Задан <a :href="buyerTicketLink" target="_blank">покупатель</a>
      </template> ({{params.buyer.email}}) для сделки
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "SetBuyer",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    buyerTicketLink() {
      return '/tickets/' + encodeURIComponent(window.getId(this.params.buyer._id));
    }
  }
}
</script>

<style scoped>

</style>