<template>
  <div class="col-12" style="text-align: right; ">
      <span style="position: relative" class="message message-to internal" :class="{'message-edit': edit}" @mouseover="showContextButton = true"  @mouseout="showContextButton = false">
         <div class="col-12 datetime-stamp text-end">{{messageDate}}</div>
        <div class="" style="position: relative">

          <div class="sender-block">
           <headers-block :headers="headers"
                          :message="message"
                          :author="message.author"
                          :changedSubject="changedSubject"
                          :unconfirmed="true" />
         </div>

         <div v-show="showContextButton" class="contextFromButton" style="">
             <context-button
                 :messageid="message._id"
                 :custom-button-style="{
                   'border-style':'none',
                    'background': '#f9edd7',
                   'outline':'none'
                    }"
                 :show="showFunctions"
                 :callbacks="{createAsNew: createAsNew}"
                 :left-align="true"
             ></context-button>
         </div>
        </div>

        <div class="attachments-block" v-if="message.attachments">
          <attachment v-for="attachment in message.attachments" :attachment="attachment" :messageid="message._id" :key="attachment.id" :can-open-attachment="isAuthor"></attachment>
        </div>

        <message-text :message="message" :collapsed="collapsed"></message-text>

        <div class="d-flex justify-content-between align-items-center mt-2" v-if="!isTicketDeleted">
          <span class="add-comment pull-left comment-button" v-if="userCanAnswer" @click="showCommentForm">Откомментировать</span>
          <div class="internal-menu pull-right deep-black">
            <span class="delete" :class="{'btn-disabled': edit}" title="Удалить" @click="openConfirmDeleteModal" v-if="canManage"><i class="fa fa-trash-o"></i></span>
            <span class="edit" title="Редактировать" @click.stop="editMessage" v-if="canEdit"><i class="fa fa-pencil"></i></span>
            <span class="confirm" title="Подтвердить" @click="approveMessage" v-if="canManage && !isTicketClosed"><i class="fa fa-paper-plane-o"></i></span>
          </div>
        </div>
        <div>
           <last-editing :comment="message" :show-last-date="true" />
        </div>
      </span>
      <teleport to="body">
        <transition name="modal" style="text-align: left">
          <modal-window
              v-if="showModalConfirmDelete"
              @close="closeConfirmDeleteModal"
              @success="deleteMessage()"
              ok-button-style="btn-danger"
              :buttons-text="{close: 'Отмена', success: 'Удалить'}">
            <template v-slot:header>
              <h5 class="modal-title text-danger">Удаление сообщения</h5>
            </template>
            <template v-slot:body>
              <span>Вы точно хотите безвозвратно удалить сообщение?</span>
            </template>
          </modal-window>
        </transition>
      </teleport>
  </div>
</template>

<script>
import Attachment from "./Message/Attachment";
import HeadersBlock from "./Message/HeadersBlock";
import MessageText from "./Message/Text";
import ContextButton from "./Message/ContextMenu/ContextButton";
import {getDateFormat} from "./Api/api";
import {UserPermission} from "../../users/UserPermission";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import {CrmApi} from "../../library/CrmApi";
import {mapActions, mapGetters, mapState} from "vuex";
import LastEditing from "./Comment/LastEditing";

export default {
  name: "UnconfirmedMessage",

  components: {
    LastEditing,
    ContextButton,
    MessageText,
    HeadersBlock,
    Attachment,
    'modal-window': ModalWindow,
  },

  props:{
    message: {
      type: Object,
      require: true
    },
    userId: {
      require: true
    },
    collapsed: {
      type: Boolean,
      default: false,
    }
  },

  computed:{
    headers() {
      let headers = this.message.headers;

      if(typeof this.message.subject === 'string' && typeof headers['subject'] === 'undefined') {
        headers['subject'] = this.message.subject;
      }

      return headers;
    },

    changedSubject() {
      return (typeof this.message.headers.subject === 'string' &&
              this.message.headers.subject !== this.message.ticket.name);
    },

    messageDate: function (){
      return getDateFormat(this.message.created ,window.dateFormatWeekDaySeconds);
    },

    ...mapState( {
      threadState: state => state.thread,
    }),

    ...mapGetters({
      getShowCommentForm: 'thread/getShowCommentForm',
      userCanAnswer: "thread/userCanAnswer",
      "getProducts": "tickets/product/getProducts",
      ticket: 'thread/getTicket',
    }),

    isTicketDeleted() {
      return (this.ticket?.deleted === true);
    },

    isTicketClosed() {
      return this.ticket?.closed === true;
    },

    hasSelectedProduct : function (){
      let hasSelectedProduct = true;
      if (Array.isArray(this.getProducts) && this.getProducts.length) {
        hasSelectedProduct = typeof this.ticket?.assigned_product?._id !== 'undefined';
      }
      return hasSelectedProduct;
    },

    isAuthor() {
      return typeof this.message.author._id !== 'undefined' && window.getId(this.message.author._id) === window.userId;
    },

    showFunctions() {
      let result = ['openSource'];
      if (this.userCanAnswer && !this.isTicketDeleted) {
        result.push('createAsNew');
      }
      return result;
    },
  },

  data: function (){
    return {
      showContextButton: false,
      canManage: false,
      canEdit: false,

      showModalConfirmDelete: false,
      edit: false,
    }
  },

  mounted: function() {
    let permissions = [];
    let projects = this.ticket?.assigned_projects?.map(project => project.name) ?? [];
    projects.push(this.message.ticket.project.name);
    projects.forEach(project => {
      permissions.push(project + '.answer');
      permissions.push(project + '.internal_answer');
    })

    UserPermission.getPermissions(permissions).then((result) => {
      // узнаем права для отображения кнопок
      if(Object.entries(result).filter(([name]) => name.includes('.answer')).find(([,can]) => can)) {

        this.canManage = this.canEdit = true;

      } else if(
          Object.entries(result).filter(([name]) => name.includes('.internal_answer')).find(([,can]) => can) &&
          window.getId(this.message.author._id) === this.$parent.userId
      ) {

        this.canEdit = true;

      }
    });

    if(this.message?.subject && !this.message.headers?.subject) {
      this.message.headers.subject = this.message.subject;
    }
  },

  methods: {
    createAsNew: function() {
      this.$emit('createAsNew', this.message);
    },

    showProductError() {
      let focusOnSelect = () => {
        this.$store.commit('tickets/product/focusSelectMenu')
        this.$nextTick(() => {
          this.$store.commit('tickets/product/unFocusSelectMenu');
        })
      }

      if(this.$store.getters['thread/isTicketWindowFullSize']) {
        this.$store.commit('thread/setTicketChangeWindowSizeCallback', focusOnSelect);
        this.$store.commit('thread/setTicketWindowSize', false);
      } else {
        focusOnSelect();
      }
    },

    approveMessage() {
      if (!this.hasSelectedProduct) {
        this.showProductError();
      } else {
        this.$parent.sending = true;

        CrmApi.call('/message/' + encodeURIComponent(window.getId(this.message._id)) + '/approve/', {}).then(() => {
          this.redirectToTickets();
        }).catch((error) => {
          if (typeof error?.response?.data?.message !== 'undefined' && error.response.data.message === 'Необходимо выбрать услугу') {
            this.showProductError();
          } else {
            this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          }
        }).finally(() => {
          this.$parent.sending = false;
        });
      }
    },

    editMessage: function() {
      this.closeEditForm();
      this.edit = true;
      // нужно дождаться unmount формы редактирования
      this.$nextTick(() => {
        this.$store.dispatch('thread/removeSelectedTemplate')
        this.$store.commit('thread/showEditForm');
        this.$store.commit('thread/setEditMessage', {
          message: Object.assign({}, this.message),
          close: this.closeEditForm,
          updated: this.closeEditForm
        });
        this.$emit('editMessage');
      })
    },

    closeEditForm: function() {
      this.edit = false;
      this.$store.commit('thread/setEditMessage', null);
      this.$store.commit('thread/closeEditForm');
    },

    openConfirmDeleteModal() {
      this.showModalConfirmDelete = true;
      this.$store.commit('setActiveWindow', 'confirmDeleteModal');
    },

    closeConfirmDeleteModal() {
      this.showModalConfirmDelete = false;
      this.$store.commit('removeActiveWindow', 'confirmDeleteModal');
    },

    deleteMessage: function() {
      this.closeConfirmDeleteModal();
      this.$parent.sending = true;

      CrmApi.call('/message/' + encodeURIComponent(window.getId(this.message._id)) + '/delete/', {}).then(() => {
        this.$el.parentNode.removeChild(this.$el);
      }).catch((error) => {
        this.$store.commit('htmlErrorPush', CrmApi.getErrorMessage(error));
      }).finally(() => {
        this.$parent.sending = false;
      });
    },

    showCommentForm: function() {
      this.$store.dispatch('thread/removeSelectedTemplate');
      this.$store.commit('thread/showCommentForm');
      this.$store.commit('thread/setCommentMessage', this.message)
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    }),
  },

  watch: {
    'threadState.editMessage' (to) {
        if(to === null || to.message._id !== this.message._id) {
          // отключаем редактирование если открыти редактирование другого сообщения
          this.edit = false;
        }
    }
  }
}
</script>

<style scoped>
.deep-black{
  min-height: 22px;
}
.deep-black > span{
  color: black !important;
  opacity: 1 !important;
  line-height: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.deep-black > span:hover{
  font-size: 15px;
}
.internal {
  background-color: #f9edd7;
  padding-bottom: 0.7em;
  border: 2px solid #f58f8f;
}
.contextFromButton{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1030;
}
.internal-menu span {
  padding: 0.2em;
  margin-right: 0.1em;
  margin-left: 0.1em;
  opacity: 0.8;
  cursor: pointer;
}
.internal-menu span:hover {
  opacity: 1;
}
.message-edit {
  cursor: wait;
  opacity: .5;
}
.add-comment {
  cursor: pointer;
  font-size: 10px;
}
.comment-button{
  border: 1px solid #b2b2b2;
  border-radius: 7px;
  padding: 0 5px;
  background-color: #eeeeee;
}
</style>