<template>
  <entity-list :new-link="newLink">
    <entity-table :show-edit="showEdit" :columns="columns" :actions="actions" :get-entity-list="getProjectsList" @sort="sortFunction"></entity-table>
  </entity-list>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import EntityList from "../common/EntityList";
import EntityTable from "../common/EntityTable";

export default {
  name: "ProjectList",

  components: {
    EntityTable,
    EntityList,
  },

  props: {
    canCreate: {
      type: Boolean,
      require: true
    }
  },

  data: function() {
    return {
      columns: [
        {
          name: "Название",
          value: "name",
          width: 50
        },
        {
          name: "Основной email",
          value: "primarymail",
          width: 40
        },
      ],

      actions: [
        {
          name: "Редактировать",
          handler: function (project){
            this.showEdit(project);
          }
        }
      ],
      sort: {
        orderBy: 'name',
        orderDirection: 'asc',
      }
    }
  },

  computed: {
    newLink: function (){
      return this.canCreate ? "/project/new" : '';
    },
  },

  methods: {
    showEdit: function ( project ){
      window.location.href = window.location.origin + "/project/edit/" + encodeURIComponent(project._id);
    },
    getProjectsList: function( filters) {
      return new Promise((resolve, reject) => {
        CrmApi.getProjectsList(this.sort).then((result) => {
          resolve({
            data: {
              data: result.data
            }
          });
        }).catch((error) => {
          reject(error);
        });
      });
    },
    sortFunction: function (sortBy) {
      if (this.sort.orderBy === sortBy) {
        this.sort.orderDirection = this.sort.orderDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort.orderBy = sortBy;
        this.sort.orderDirection = 'asc';
      }
    }
  }
}
</script>

<style scoped>

</style>