<template>
  <div>
    <div v-if="showField('fullname')" class="company-field">
      <div class="col-sm-3">Название (полное):</div>
      <div class="col-sm-9"  >
        <input  :disabled="loading" class="form-control form-control-sm"
                :value="fullname"   @input="update('fullname', $event.target.value)" />
      </div>
    </div>
    <div v-if="showField('name')" class="company-field">
      <div class="col-sm-3">Название:</div>
      <div class="col-sm-9"  >
        <input  :disabled="loading" class="form-control form-control-sm"
                :value="name"   @input="update('name', $event.target.value)" />
      </div>
    </div>
    <div v-if="showField('country')" class="company-field">
      <div class="col-sm-3">Страна:</div>
      <div class="col-sm-9">
        <select :disabled="loading" class="form-select form-select-sm" :value="country" @change="update('country', $event.target.value)">
          <option value="">Не выбрана</option>
          <option v-for="country in countries" :value="country">{{country}}</option>
        </select>
      </div>
    </div>
    <div v-if="showField('legaltype')" class="company-field">
      <div class="col-sm-3">Статус:</div>
      <div class="col-sm-9">
        <select :disabled="loading" class="form-select form-select-sm" :value="legaltype" @change="update('legaltype', $event.target.value)">
          <option value="">Не выбран</option>
          <option value="company">Компания</option>
          <option value="entrepreneur">ИП</option>
          <option value="person">Физ. лицо</option>
        </select>
      </div>
    </div>
    <div  v-if="showField('vatnum')" class="company-field">
      <div class="col-sm-3" >ИНН:</div>
      <div class="col-sm-9"  >
        <input  :disabled="loading" class="form-control form-control-sm" placeholder="VAT, БИН/ИНН"
                :value="vatnum"   @input="update('vatnum', $event.target.value)" />
      </div>
    </div>
    <div  v-if="showField('ogrn')" class="company-field">
      <div class="col-sm-3" >ОГРН:</div>
      <div class="col-sm-9"  >
        <input  :disabled="loading" class="form-control form-control-sm" placeholder="Registration number, ОГРНИП, не указывается для КЗ, УЗ"
                :value="ogrn"   @input="update('ogrn', $event.target.value)" />
      </div>
    </div>
    <div  v-if="showField('kpp')" class="company-field">
      <div class="col-sm-3" >КПП:</div>
      <div class="col-sm-9"  >
        <input :disabled="loading"  class="form-control form-control-sm" placeholder="Указывается только для РФ"
                :value="kpp"   @input="update('kpp', $event.target.value)" />
      </div>
    </div>

    <template v-if="showField('company.signer')">
      <div class="company-group-title">Подписант</div>

      <div class="company-field" v-if="showField('company.signer.name')">
        <div class="col-sm-3">Подписант (ФИО):</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.signer?.name" @input="update('signer.name', $event.target.value)" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.signer.position')">
        <div class="col-sm-3">Должность:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.signer?.position" @input="update('signer.position', $event.target.value)" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.signer.documenttype')">
        <div class="col-sm-3">Документ:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.signer?.documenttype" @input="update('signer.documenttype', $event.target.value)" />
        </div>
      </div>
    </template>

    <template v-if="showField('company.address')">
      <div class="company-group-title">Адреса</div>

      <div class="company-field d-flex align-items-baseline" v-if="showField('company.address.legal')">
        <div class="col-sm-3">Адрес (юр.):</div>
        <div class="col-sm-9">
          <span class="address-inputs-button pull-right link-primary"
                role="button"
                @click="toggleFullAddress('legal')">
            {{ showFullAddress.legal ? 'Свернуть' : 'Изменить' }}
          </span>
          <input class="form-control form-control-sm input-disabled"
                 role="button"
                 readonly
                 :value="addressLegalString"
                 @click="toggleFullAddress('legal')" />
        </div>
      </div>

      <full-address-fields v-if="showFullAddress.legal"
                           :address="company.address?.legal"
                           :disabled="loading"
                           type="CompanyShow"
                           @update="(field, value) => updatePartAddress('legal', field, value)" />

      <div class="company-field d-flex align-items-baseline" v-if="showField('company.address.real')">
        <div class="col-sm-3">Адрес (факт.):</div>
        <div class="col-sm-9">
          <span class="address-inputs-button pull-right link-primary"
                role="button"
                @click="toggleFullAddress('real')">
            {{ showFullAddress.real ? 'Свернуть' : 'Изменить' }}
          </span>
          <input class="form-control form-control-sm input-disabled"
                 role="button"
                 readonly
                 :value="addressRealString"
                 @click="toggleFullAddress('real')" />
        </div>
      </div>

      <full-address-fields v-if="showFullAddress.real"
                           :address="company.address?.real"
                           :disabled="loading"
                           type="CompanyShow"
                           @update="(field, value) => updatePartAddress('real', field, value)" />

    </template>

    <template v-if="showField('company.bank')">
      <div class="company-group-title">Банковские реквизиты</div>

      <div class="company-field" v-if="showField('company.bank.name')">
        <div class="col-sm-3">Название банка:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.bank?.name" @input="update('bank.name', $event.target.value)" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.bank.bik')">
        <div class="col-sm-3">БИК:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm" placeholder="МФО"
                  :value="company.bank?.bik" @input="update('bank.bik', $event.target.value)" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.bank.account')">
        <div class="col-sm-3">Расчетный счет:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.bank?.account" @input="update('bank.account', $event.target.value)" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.bank.coraccount')">
        <div class="col-sm-3">Кор. счет:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                  :value="company.bank?.coraccount" @input="update('bank.coraccount', $event.target.value)" />
        </div>
      </div>

    </template>

    <template v-if="showField('company.ripe')">
      <div class="company-group-title">RIPE</div>

      <div class="company-field" v-if="showField('company.ripe.lirid')">
        <div class="col-sm-3">LIRid:</div>
        <div class="col-sm-9">
          <input :disabled="loading" class="form-control form-control-sm"
                 :value="company.ripe?.lirid" @input="updateLirid" />
        </div>
      </div>
      <div class="company-field" v-if="showField('company.ripe.org')">
        <div class="col-sm-3">ORG:</div>
        <div class="col-sm-9">
          <input :disabled="loading || loadingOrg" class="form-control form-control-sm"
                 :value="company.ripe?.org" @input="update('ripe.org', $event.target.value)" />
        </div>
      </div>

    </template>

    <documents
        :person="person"
        :company="company"
        :ticket-id="ticketId"
        @set-disabled="val => $emit('setDisabled', val)"
    />

  </div>

  <change-company :params="changeCompany"
                  :company="company"
                  :ticket-id="ticketId"
                  :type="changeCompanyType"
                  :update="(fields) => updateCompany(fields, false)"
                  @close="closeChangeCompany"
                  @cancel="cancelChangeCompany" />
</template>

<script>
import EditableText from "../../../Editable/EditableText";
import {Utility} from "../../../../library/api/Utility";
import ChangeCompany from "./changeCompany";
import {countries} from "../../../../library/Client";
import Documents from "./Documents.vue";
import FullAddressFields from "./Address/FullAddressFields.vue";
import {CrmApi} from "../../../../library/CrmApi";

export default {
  name: "CompanyShow",
  components: {FullAddressFields, Documents, EditableText, ChangeCompany},
  emits:[
    'setCompany',
    'updateField',
    'setLoading',
    'save',
    'resetCompanyFields',
    'setShowCompanyLink',
    'setDisabled',
  ],
  props:{
    person: Object,
    company: Object,
    ticketId: {},
    showAll: {
      type: Boolean,
      default: false,
    }
  },
  data:function (){
    return {
      timer: null,

      loading: false,
      loadingOrg: false,

      changeCompany: null,
      changeCompanyPrev: null,
      changeCompanyType: null,
      countries: Object.values(countries),
      liridTimeout: null,
      show: {
        fullname: false,
        name: false,
        country: false,
        legaltype: false,
        vatnum: false,
        ogrn: false,
        kpp: false,
        "company.signer": false,
        "company.signer.name": false,
        "company.signer.position": false,
        "company.signer.documenttype": false,
        "company.address": false,
        "company.address.legal": false,
        "company.address.real": false,
        "company.bank": false,
        "company.bank.name": false,
        "company.bank.bik": false,
        "company.bank.account": false,
        "company.bank.coraccount": false,
        "company.ripe": false,
        "company.ripe.lirid": false,
        "company.ripe.org": false,
      },
      showFullAddress: { legal: false, real: false },
      allowCopyAddress: { legal: null, real: null },
    }
  },
  mounted() {
    this.getShownItems();

    ['legal', 'real'].forEach(
        adrType => this.allowCopyAddress[adrType] = ((this.person.company.address?.[adrType] ?? null) === null)
    );
  },
  methods: {
    getShownItems() {
      let allShown = true;
      const getVal = (o,i) => o && typeof o[i] !== 'undefined' && o[i] ? o[i]: null;
      const splitVal = (str, obj) => str.split('.').reduce(getVal, obj);
      Object.keys(this.show).forEach((key) => {
        this.show[key] = this.hasField(splitVal(key, this));
        if (this.show[key] === false) {
          allShown = false;
        }
      });
      this.$emit('setShowCompanyLink', !allShown);
    },
    closeChangeCompany() {
      this.changeCompany = null;
    },

    cancelChangeCompany() {
      if(typeof this.changeCompanyPrev.vatnum !== 'undefined') {
        this.update('vatnum', this.changeCompanyPrev.vatnum, false);
      }
      if(typeof this.changeCompanyPrev.ogrn !== 'undefined') {
        this.update('ogrn', this.changeCompanyPrev.ogrn, false);
      }

      this.changeCompanyPrev = null;
      this.closeChangeCompany();
    },

    hasField(field) {
      let show = false;

      if(typeof field === 'string' && field.trim() !== '') {
        show = true;
      } else if(typeof field === 'undefined' || field === null) {
        show = false;
      } else if(typeof field === 'object' && !Array.isArray(field)) {
        let values = Object.values(field);
        show = (values.length === 0 || Object.values(field).filter(subfield => subfield !== null).length > 0);
      }

      return show;
    },

    showField(field) {
      return this.showAll || this.show[field];
    },

    updateText:function ({type, value}){
      if(this.$refs[type +"-text"]){
        this.$refs[type +"-text"].updateTextProxy(value)
      }

    },
    getCompanyInfo(num, param){
      num = num.trim();

      clearTimeout(this.timer);
      if(typeof num === 'string' && [9, 12, 10, 13, 15].includes(num.length)){
        this.$emit('setDisabled', true);
        this.timer = setTimeout(() => {
          if([(this.vatnum ?? '').trim(), (this.ogrn ?? '').trim()].includes(num)) {
            this.loading = true;
            this.$emit('setLoading', true);

            Utility.getEgrul(num.length < 13 ? num : null, num.length >= 13 ? num : null)
                   .then(result => this.parseCompanyInfo(result.data, param))
                   .catch(error => {
                     if(error?.response?.status === 404) {
                       let by = param === 'vatnum' ? 'ИНН' : 'ОГРН'
                       this.$store.commit('infoPush', `Не найдена информация о компании по ${by} ${num} или компания ликвидирована`);
                     }
                   })
                  .finally(()=>{
                    this.loading = false;
                    this.$emit('setLoading', false);
                    this.$emit('setDisabled', false);
                  });
          }
        }, 500);
      } else {
        this.$emit('setDisabled', false);
      }

    },

    parseCompanyInfo(data, param) {
      if(typeof this.company.name === 'string') {
        if(data.name.toLowerCase() !== this.company.name.toLowerCase()) {
          this.changeCompanyType = param;
          this.changeCompany = {company: data};
        }
      } else {
        this.updateCompany(data, false);
      }
    },

    updateCompany(company, save = true) {
      this.$emit('resetCompanyFields');
      this.$emit('setCompany', company);
      this.getShownItems();

      if(save) {
        this.$emit('save');
      }
    },

    updatePartAddress(type, field, value) {
      let anotherType = type === 'real' ? 'legal' : 'real';

      if(this.allowCopyAddress[anotherType]) {
        Object.entries(this.company.address?.[type] ?? {})
              .filter(([k, v]) => k !== field && typeof v === 'string')
              .forEach(([k, v]) => this.update(`address.${anotherType}.${k}`, v));

        this.update(`address.${anotherType}.${field}`, value);
        this.show[`company.address.${anotherType}`] = true;
      }
      this.allowCopyAddress[type] = false;

      this.update(`address.${type}.${field}`, value);
    },

    update(field, value, getCompanyInfo = true) {
      if(getCompanyInfo && ['vatnum', 'ogrn'].includes(field) && value !== (this.company[field] ?? '')) {
        this.changeCompanyPrev = {};
        this.changeCompanyPrev[field] = this.company[field] ?? '';
        this.getCompanyInfo(value, field);
      }

      this.$emit('updateField', {
        value: value.trim(),
        type: field
      })
    },

    updateLirid(e) {
      let lirid = e.target.value;
      this.update('ripe.lirid', lirid);
      clearTimeout(this.liridTimeout);

      if(
          lirid.match(/[a-zA-Z]{2}\..{2,}/) &&
          (typeof this.company.ripe?.org !== 'string' || this.company.ripe.org.trim().length === 0)
      ) {
        this.liridTimeout = setTimeout(() => {

          this.loadingOrg = true;
          this.$emit('setLoading', true);
          Utility.getOrgByLirid(lirid)
                 .then((result) => {
                    this.company.ripe.org = result.data.org;
                 }).finally(() => {
                   this.loadingOrg = false
                   this.$emit('setLoading', false);
                 });

        }, 500)
      }
    },

    concatAddress(addressObject) {
      return [
        addressObject?.index,
        addressObject?.country,
        addressObject?.state,
        addressObject?.city,
        addressObject?.street,
      ].filter(part => typeof part === 'string' && part.length)
       .map(part => part.trim())
       .join(', ');
    },

    toggleFullAddress(type) {
      this.showFullAddress[type] = !this.showFullAddress[type];
    }

  },
  computed:{

    vatnum() {
      return this.company?.vatnum;
    },
    name() {
      return this.company?.name;
    },
    fullname() {
      return this.company?.fullname;
    },
    country() {
      return this.company?.country ?? null;
    },
    legaltype() {
      return this.company?.legaltype ?? null;
    },
    ogrn() {
      return this.company?.ogrn;
    },
    kpp() {
      return this.company?.kpp;
    },
    addressLegalString() {
      return this.concatAddress(this.company?.address?.legal);
    },
    addressRealString() {
      return this.concatAddress(this.company?.address?.real);
    }

  },
  watch:{
    loading(to) {
      this.$emit('setLoading', to)
    }
  }
}
</script>

<style scoped>
:deep(.company-field) {
  display: flex; justify-content: space-between;
}
:deep(.company-field:not(:first-child)) {
  margin-top: 2px;
}
.company-group-title {
  margin-top: 3px;
  font-weight: bold;
}
.address-inputs-button {
  font-size: 0.7em;
}
</style>