import vueKeyF from "./vue-f";
let isEventAdd = false;
const plagin = {
    install (Vue) {
        Vue.directive('keyF', {
            mounted(el, binding){
                vueKeyF.update(el , binding)
            },
            beforeUnmount(el, binding) {
                vueKeyF.unbind(el , binding)
            },
        });
        if(!isEventAdd){
            isEventAdd = true;
            document.addEventListener('keyup',  (event) =>{
                vueKeyF.onEvent(event)
            });
            document.addEventListener('keydown',  (event) =>{
                vueKeyF.downEven(event)
            });
        }
    }
}
export default plagin