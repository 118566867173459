import { createStore, createLogger } from 'vuex'

import {moduleProduct} from "./product/composition";
import commonMutations from "./common/mutations"
import {shareState} from "./common/composition";
import * as actions from "./common/actions";
import * as getters from "./common/getters";

export default createStore({
    modules:{
        product: moduleProduct,
    },
    state:() => (shareState),
    actions:  actions ,
    getters: getters,
    mutations:commonMutations,
    plugins: []
})
