<template>
    <div>
      <button :class="{'text-muted':loading || state.isTicketsReaded, 'custom-disabled':loading ||state.isTicketsReaded}" v-show="idList.length > 0" @click="setReaded()" class="btn btn-light btn-outline-dark col-12"><i class="fa fa-eye button-icon menu-button"></i>Прочитано</button>
      <button  :class="{'text-muted':loading || state.isTicketsNotReaded, 'custom-disabled':loading || state.isTicketsNotReaded}" v-show="idList.length > 0" @click="setNotReaded()" class="btn btn-light btn-outline-dark col-12"><i class="fa fa-eye-slash  button-icon menu-button"></i>Не прочитано</button>
    </div>
</template>

<script>
import {isTicketReaded} from "../../ticket/Api/api";

export default {
  name: "ReadAndNotReadButtons",
  props:{
    'idList':{
      type:Array
    }
  },
  data:function (){
    return {
      state:{
        isTicketsReaded:false,
        isTicketsNotReaded:false,
      }

    }
  },
  watch:{
    'ticketsObject'(to , from){
      this.state.isTicketsNotReaded = this.isAllTicketsNotReaded();
      this.state.isTicketsReaded = this.isAllTicketsReaded();
    }
  },
  mounted() {
    this.state.isTicketsNotReaded = this.isAllTicketsNotReaded();
    this.state.isTicketsReaded = this.isAllTicketsReaded();
  },
  methods:{
    setReaded: function (){
      if(this.idList.length > 0){
        this.$store.dispatch('tickets/setReadedOrNotReaded' ,{
          ticketIds:this.idList,
          route:this.$route,
          valueRead:this.state,
          callback: () => this.$store.commit('successPush', this.idList.length === 1
                                                                ? "Тикет помечен как прочитанный"
                                                                : "Тикеты помечены как прочитанные")
        });
      }
    },
    setNotReaded: function (){
      if(this.idList.length > 0){
        this.$store.dispatch('tickets/setReadedOrNotReaded' ,{
          ticketIds:this.idList,
          route:this.$route,
          readed:"setNotReaded",
          valueRead: this.state,
          callback: () => this.$store.commit('successPush', this.idList.length === 1
                                                                ? "Тикет помечен как непрочитанный"
                                                                : "Тикеты помечены как непрочитанные")
        });
      }
    },
    isAllTicketsReaded(){
      let isTicketsReaded = true;
      let breakObject = {};
      try {
        for (let key in this.ticketsObject){
          let ticket = this.ticketsObject[key];

          let a =  isTicketReaded(ticket , window.userId);
          if(!a){
            throw breakObject;
          }
        }
      }catch (breakObject){
        isTicketsReaded = false;
      }

      return isTicketsReaded;
    },

    isAllTicketsNotReaded(){
      let isTicketsReaded = true;
      let breakObject = {};
      try {
        for (let key in this.ticketsObject){
          let ticket = this.ticketsObject[key];
          let a =  isTicketReaded(ticket , window.userId);
          if(a){
            throw breakObject;
          }
        }
      }catch (breakObject){
        isTicketsReaded = false;
      }

      return isTicketsReaded;
    }
  },
  computed:{
    loading:function (){
      return this.$store.getters["tickets/isTicketLoading"];
    },
    ticketsObject(){
      return this.$store.getters["tickets/getSelectedObjects"];
    },



  }
}
</script>

<style scoped>

</style>