import {getHintStatus, isHasEvent} from "./PhonesFunctions";


export const getPhone = state => {
    return state.phone;
}

export const getPhoneFormate = state =>{
    return state.phone;
}

export const getRealFromPhone = (state, getters) =>{
    if(getters?.getMessage?.headers?.real_from_phone === undefined){
        return ''
    }
    return getters?.getMessage?.headers?.real_from_phone;
}

export const getRealManagerExt = (state, getters) =>{
    if(getters?.getMessage?.headers?.real_manager_ext === undefined){
        return ''
    }
    return getters?.getMessage?.headers?.real_manager_ext;
}


export const getTicket = (state, getters , rootGetters) =>{
    let ticket = null;
    if(rootGetters.thread.ticket !== null){
        ticket = rootGetters.thread.ticket;
    }else {
        ticket = state.ticket;
    }
    return ticket;
}


export const getMessage = (state) => {
    return state.message;
}



export const isTimerStart = state => {
    return state.startTimer;
}

export const isWindowOpen = state => {
    return state.windowOpen;
}



export const getCurrentCall = state =>{
    return state.currentCall;
}

export const getStatus = state => {
    return state.status;
}

export const getHint = (state , getters) => {
    return getHintStatus(state.status, getters.getMessage);
}
export const isCalling = (state , getters )=>{
    return isHasEvent(getters.getMessage, 'og_client_answer');
}

export const getClientAnswerTimestamp = (state , getters) => {
    let time = getters.getMessage?.headers?.event?.og_client_answer?.timestamp;
    if(typeof time === 'undefined'){
        time = null;
    }

    return time
}