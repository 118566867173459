<template>
  <div :class="{'text-muted': loading}" @mouseenter="showAddBtn = true" @mouseleave="showAddBtn = false" ref="phonelist">
    <div class="pe-1 pb-0">Телефон:
      <i class="fa fa-plus-circle" style="color:#6262f5;cursor:pointer;" @click="showAddPhoneField = true" v-if="userCanChange && showAddBtn"></i>
    </div>

    <div class="ps-2" style="min-height: 30px;" v-if="phones.length">
      <span v-for="(phone,index) in phones" class="phone-item" :key="phone.number">
        <phone
            :name="phone.name"
            :person="person"
            :ticket-id="ticketId"
            :phone="phone.number"
            :type="phone.type"
            :cut-type="true"
            :editable-type="false"
            :is-call-permission="canCall"
            :absolute="inputAbsolute"
            :absolute-parent="$refs.phonelist"
            :dynamic-absolute-width="true"
            :user-can-change="userCanChange"
            :index="index"
            type-class="pull-right"
            input-class="w-100"
            input-style="width: 100%;"
        />
      </span>
    </div>

    <div style="min-height: 40px;" :class="{'ps-2': !inputAbsolute, 'pt-1': inputAbsolute}" v-if="showAddPhoneField">
      <editable-phone
          v-model="newPhone.value"
          input-class="form-control d-inline"
          input-style="max-width: 300px;"
          :callback="addPhone"
          :start-as-input="true"
          :disabled="loading"
          @close="hideAddPhoneField"
      />
    </div>
  </div>
</template>

<script>
import {CrmApi} from "../../../../library/CrmApi";
import Phone from "./Entity/Phone";
import EditablePhone from "../../../common/Phone/EditablePhone";
import {mapGetters} from "vuex";

export default {
  name: "PhoneList",

  components: {Phone, EditablePhone},
  emits: ['updatePerson'],

  props: {
    person: {},
    ticketId: {
      require: true
    },
    selectOnMount:{
      default:false,
    },
    userCanChange: {
      type: Boolean,
      default: false,
    }
  },

  data: function() {
    return {
      loading: false,
      newPhone:{value:""},
      showAddBtn: false,
      showAddPhoneField: false,

      inputAbsolute: false,
    }
  },

  computed: {
    phones: function() {
      return this.person !== null ? (this.person.phone ?? []) : [];
    },
    canCall() {
      return this.userCanCall && this.ticket?.deleted !== true;
    },
    ...mapGetters({
      userCanCall: 'thread/userCanCall',
      ticket: 'thread/getTicket',
      "api": "thread/getApi",
    }),
  },

  mounted() {
    this.inputAbsolute = ($(this.$refs.phonelist).width() < 260);
  },

  methods: {

    addPhone(){
      if (typeof this.newPhone.value === 'string' && this.newPhone.value.trim() !== '') {
        let data = {}
        data[this.phones.length + 1] = this.newPhone.value;
        this.loading = true;
        return new Promise((resolve, reject) => {
          this.api.editPerson({"add": {"phone": data}}).then(result => {
            this.$emit('updatePerson', result.data.person);
            resolve(result.data.person.phone);
          }).catch((error) => {
            this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
            reject(error);
          }).finally(() => {
            this.loading = false;
          });
        });
      } else {
        this.hideAddPhoneField();
      }
    },

    hideAddPhoneField() {
      this.newPhone.value = '';
      this.showAddPhoneField = false;
    },

    changePhones: function(obj = null) {
      if (typeof this.person.phone === 'undefined' || this.person.phone == null) {
        this.person.phone = [];
      }
      let prevArray = [...this.person.phone];
      let tmp = [];
      for (let el of this.person.phone) {
        if (typeof el.type === 'undefined') {
          el.type = 'другой';
        }
        if (typeof el.number !== 'undefined' && el.number.trim() !== '') {
          tmp.push(el);
        }
      }
      if (obj !== null) {
        tmp.push(obj);
      }
      this.loading = true;
      return new Promise((resolve, reject) => {
        CrmApi.updateThreadPerson(this.ticketId, {phone: tmp}).then(result => {
          this.$emit('updatePerson', result.data.person);
          this.person.phone = result.data.person.phone ?? [];
          resolve(result.data.person.phone);
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
          this.person.phone = prevArray;
          reject(error);
        }).finally(() => {
          this.loading = false;
        });
      });
    },
  },

  watch: {
    person (person) {
      if(person !== null) {
        this.name = person.name ?? null;
      }
    },
  }
}
</script>

<style scoped>
.phone-item {
  display: flex;
}
</style>