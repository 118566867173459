<template>
  <div class="params-item">
    <popper content="Домены и Email-адреса которые используются в переписке" class="light" placement="top" open-delay="350" :hover="true" :interactive="true" :arrow="true">
      <span>Домен(ы): <span v-for="domainInfo in ticket.domains" v-if="ticket" class="badge bg-secondary">{{domainInfo.name}}</span></span>
    </popper>
  </div>
</template>

<script>

export default {
  name: "Affiliated",

  props: {
    ticket: {},
  },

  data: function() {
    return {
    }
  },

  methods: {
  },
}
</script>

<style scoped>

</style>