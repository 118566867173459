<template>
  <div class="input-group align-items-center flex-nowrap">
    <template v-if="withLabel">LIRid:&nbsp;</template>
    <editable-text
        v-if="userCanChange"
        :text="lirid"
        :default="inputDefault"
        :string-style="stringStyle"
        :trim-string-style="!withLabel ? 'text-align: center; width: 100%;' : ''"
        input-class="form-control"
        :trim="true"
        @change="string => $emit('update', {lirid: string})"
    />
    <template v-else><value-or-trim :is-need-trim="true"
    :value="value"></value-or-trim></template>
  </div>
</template>

<script>
import EditableText from "../../../../../Editable/EditableText";
import ValueOrTrim from "../../../../../common/ValueOrTrim";

export default {
  name: "LIRid",

  components: {ValueOrTrim, EditableText},

  props: {
    lirid: {},
    withLabel: {
      type: Boolean,
      default: true,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    value:function (){
      return this.lirid ??  this.inputDefault
    },
    inputDefault: function() {
      return this.withLabel ? '—' : '';
    },

    stringStyle: function() {
      return this.withLabel ? '' : 'text-align: center; color: #6c757d!important; width: 100%; height: 1em; font-size: 13px;';
    }
  }
}
</script>

<style scoped>

</style>