<template>
  <span class="input-group-text d-flex align-items-baseline">
    <input v-if="typeof keyword.required === 'boolean'" type="checkbox" v-model="keyword.required" class="form-check-input" />
    <input v-else type="checkbox" checked disabled class="form-check-input disabled" />
  </span>
</template>

<script>
export default {
  name: "Required",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>