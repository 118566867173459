<template>
  <button @click="toggleFilters" class="btn btn-outline-secondary form-control" type="button">
    <i class="fa fa-filter"></i>
  </button>
</template>

<script>
export default {
  name: "FilterButton",

  computed: {
    show: {
      get() { return this.$store.getters['thread/offers/filters/showFilter']; },
      set(value) { this.$store.commit('thread/offers/filters/setShowFilter', value); }
    }
  },

  methods: {
    toggleFilters() {
      this.show = !this.show;

      if(!this.show) {
        this.$store.dispatch('thread/offers/filters/closeTableFilters');
      }
    }
  }
}
</script>

<style scoped>

</style>