<template>
  <div class="pt-5 col-md-6 m-auto">
    <div>
      <Loading loader="dots" :is-full-page="false" v-model:active="loading" />
    </div>
    <form  method="post" @submit.prevent="onSubmit()">
      <div class="form-group row">
        <label for="productname" class="col-sm-3 col-form-label">Имя услуги</label>
        <div class="col-sm-9">
          <input type="text" class="form-control"  v-model="product.name" id="productname"  placeholder="Введите имя услуги">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Проект</label>
        <div class="col-sm-9">
          <multiselect
              placeholder="Добавить проект к услуге"
              v-model="projectIds"
              :mode="'tags'"
              :close-on-select="false"
              :searchable="true"
              :create-option="false"
              :options="loadedProjects"
              ref="multi-project"
          >
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div class="multiselect-tag is-user">
                <span>{{ option.label }}</span>
                <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Шаблоны</label>
        <div class="col-sm-9">
          <multiselect  placeholder="Добавить шаблоны к услуге"
                        v-model="templateIds"
                        :mode="'tags'"
                        :close-on-select="false"
                        :searchable="true"
                        :create-option="false"
                        :delay="800"
                        :options="updateTemplateList"
                        ref="multy"
          >
            <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div  class="multiselect-tag is-user" >
                <a class="link-style" target="_blank" :href="getTemplateEditLink(option)">{{ option.label }} </a>
                <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div class="form-group row">
        <label for="urls-faq" class="col-sm-3 col-form-label">FAQ</label>
        <div class="col-sm-9">
          <input type="url" class="form-control" pattern="https?://.+"  v-model="product.urls.faq" id="urls-faq" placeholder="Ссылка на FAQ">
        </div>
      </div>
      <div class="form-group row">
        <label for="additional-fields" class="col-sm-3 col-form-label" >Дополнительные поля</label>
        <div class="col-sm-9">
          <select v-model="additional_fields" class="form-select" id="additional-fields">
            <option :value="null">—</option>
            <option v-for="(name, value) in additionalFieldsOptions" :value="value">{{name}}</option>
          </select>
        </div>
      </div>

      <step-list ref="step-list" :is-correct-final-order="isCorrectFinalOrder" />
      <billmanager-prices :tariffs="product.tariffs" @add="addTariff" @remove="removeTariff"
                          ref="billmanager-prices-list" />

      <div class="double-buttons-group">
        <div>
          <button @click="cancel()" type="button" class="btn btn-secondary">Назад</button>
        </div>
        <div>
          <button type="submit" :disabled="isDisabled || loading || !isCorrectFinalOrder"
                  class="btn btn-primary">Сохранить</button>
        </div>
      </div>
    </form>
    <alert-list></alert-list>
    <transition name="modal">
      <step-edit-new-modal :additional_fields="additional_fields"  v-if="currentStep !== null"></step-edit-new-modal>
    </transition>
    <modal-window  v-if="stepForDelete !== null" @close="resetDeleteStep()"
                   @success="deleteStep(stepForDelete); resetDeleteStep() "
                   ok-button-style="btn-danger" >
      <template v-slot:header>
        <h5 class="modal-title text-dark" >Убрать шаг</h5>
      </template>
      <template v-slot:body>
        <span class="text-dark">Убрать {{stepForDelete.name}}?</span><br/>
        <span class="text-muted ">Шаг удалится окончательно после нажатия кнопки "Сохранить"</span>
      </template>
    </modal-window>

  </div>
</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import Loading from "vue3-loading-overlay";
import AlertList from "../ticket/Alert/AlertList";
import Multiselect from "@vueform/multiselect";
import StepList from "./includes/StepList";
import {changeMultySelectStyles} from  "../ticket/Api/api";
import {getProductEditLink, getStepEditLink, getTemplateEditLink} from "../../library/Locations";
import {mapGetters, mapMutations} from "vuex";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import StepEditNewModal from "./steps/StepEditNewModal";
import BillmanagerPrices from "./includes/BillmanagerPrices";

export default {
  name: "ProductsNewEdit",
  components: {
    StepList,
    BillmanagerPrices,
    Loading,
    AlertList,
    Multiselect,
    StepEditNewModal,
    ModalWindow
  },
  data:function (){
    return {
      initialTemplates:[],

      isDisable:true,
      allTemplatesArray: [],
      loadedTemplatesMap: {},
      selectedProject: "",
      templateIds: [],
      projectIds: [],
      loadedProjects: [],

      additionalFieldsOptions: {
        buy_ip: 'Покупка IP',
        sell_ip: 'Продажа IP',
        ip: 'IP',
      },
      additional_fields: null,
    }
  },
  methods:{
    getTemplateEditLink(template){
      return getTemplateEditLink(template.value);
    },
    onSubmit(){
      let product = JSON.parse( JSON.stringify(this.product) );

      this.$store.state.product.loading = true;
      product.templates = [];
      this.templateIds.forEach((templateId) => {
        product.templates.push( this.loadedTemplatesMap[templateId] );
      });
      product.projects = this.selectedProjects;
      product.additional_fields = this.additional_fields !== null ? [{type: this.additional_fields}] : [];
      let startOrder = 1;
      product.steps.forEach((step) => {
        step.order = startOrder++;
        if( step._id ){
          step._id = window.getId(step._id);
        }
      });
      if (typeof product?.tariffs === 'object' && Array.isArray(product.tariffs)) {
        product.tariffs = product.tariffs.map(tariff => {
          return {
            id: tariff.id,
            default_period: tariff.default_period ?? null
          };
        });
      }

      if(typeof product._id !== 'undefined' &&  product._id != null){
        CrmApi.editProduct(product).then((result)=>{
          if (result.data.message) {
            this.$store.commit('errorPush', result.data.message);
          } else {
            this.$store.commit('successPush', 'Услуга обновлена');
          }
          this.setProduct(result.data.product);
          this.processData();
          this.setOldStepOrders();
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.$store.state.product.loading = false;
        });
      }else{

        CrmApi.addProduct(product).then((result)=>{
          window.location.replace( window.location.origin + "/products/" /*+ encodeURIComponent(this.getId(result.data._id ))*/)
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.$store.state.product.loading = false;
        });
        /*
        CrmApi.editProduct(this.product).then(()=>{
          window.location.reload();
        }).catch((error)=>{
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(()=>{
          this.loading = false;
        });

         */
      }
    },
    cancel(){
      window.location.replace( window.location.origin + "/products/")
    },

    updateTemplateList(query){
      let filter = {};
      if( query !== null ){
        filter.name = query;
      }
      return CrmApi.getTemplatesList(filter).then((result)=>{

        this.allTemplatesArray = [];
        let unique = {};
        if(this.$refs.multy.search == '' || this.$refs.multy.search == null){
          this.initialTemplates.forEach((template) => {
            this.allTemplatesArray.push({
              value:window.getId(template._id),
              label:template.name,
            });
            unique[window.getId(template._id)] = true;
            this.addLoadedTemplate(template);
          });
        }

        result.data.data.forEach((template)=>{
          if(typeof unique[template._id] === 'undefined') {
            this.allTemplatesArray.push({
              value: window.getId(template._id),
              label: template.name,
            });
            this.addLoadedTemplate(template);
          }
        });

        return  this.allTemplatesArray;
      }).catch(()=> {
        return  this.allTemplatesArray;
      })
    },
    getStepById(id){
      let breakObj;
      let currentStep;
      try {
        for (let step of this.product.steps){
          if(window.getId(step._id) == window.getId(id)){
            currentStep = step;
            throw breakObj;
          }
        }
      }catch (breakObj){}


      return currentStep;

    },

    addLoadedTemplate( template ){
      let tmpTemplate = Object.assign({},template);
      tmpTemplate._id = window.getId(tmpTemplate._id);
      this.loadedTemplatesMap[tmpTemplate._id] = tmpTemplate;
    },
    getId( id){
      return window.getId(id);
    },
    processData() {
      this.initialTemplates = this.product.templates;

      this.additional_fields = (Array.isArray(this.product.additional_fields) && this.product.additional_fields.length)
                                        ? this.product.additional_fields[0].type : null;
      this.templateIds = [];
      this.product.templates.forEach((template) => {
        this.templateIds.push(window.getId(template._id));
        this.addLoadedTemplate(template);
      });
      if (Array.isArray(this.product.projects) && this.product.projects.length) {
        this.projectIds = this.product.projects.map((project) => window.getId(project._id));
      }

      this.$nextTick(() => this.$refs['billmanager-prices-list'].setInitialTariffs());
    },

    addTariff(tariff) {
      if(!Array.isArray(this.product?.tariffs ?? null)) {
        this.product.tariffs = [];
      }
      this.product.tariffs.push(tariff);
    },

    removeTariff(id) {
      this.product.tariffs = this.product.tariffs.filter(tariff => tariff.id !== id);
    },

    ...mapMutations({
      'resetStep':'product/resetStep',
      'selectStep':'product/selectStep',
      'deleteStep':'product/deleteStep',
      'resetDeleteStep':'product/resetDeleteStep',
      'setProduct': 'product/setProduct',
      'setOldStepOrders': 'product/setOldStepOrders',
    }),
  },
  mounted() {
    changeMultySelectStyles(document);

    this.processData();
  },
  computed:{
    ...mapGetters({
      "loading":  "product/isLoading",
      "product":"product/getProduct",
      "projects":"product/getProjects",
      "currentStep":"product/getCurrentOpenedStep",
      "stepForDelete":"product/getStepForDelete"
    }),
    isDisabled(){
      let isDisabled = false;
      if(this.product.name.trim() === ''){
        isDisabled = true;
      }
      if (this.projectIds.length === 0) {
        isDisabled = true;
      }

      return isDisabled;
    },
    selectedProjects() {
      return this.projectIds.map((id) => {
        return this.projects.list.find((project) => {
          return window.getId(project._id) === id;
        });
      });
    },

    isCorrectFinalOrder() {
      let _final = false;
      return !Object.values(this.product.steps).some(step => {
        if(step.type === 'final') {
          _final = true;
        }
        return _final && step.type !== 'final';
      });
    }
  },
  watch:{
    '$route'(to, from) {
      if(to.name ==='Products'){
        this.resetStep();
      }else if (to.name === "Step" && typeof to.params.step !=='undefined'){
          let currentStep = this.getStepById(to.params.step);
          if(typeof currentStep !== 'undefined' && currentStep !== null){
            this.selectStep(currentStep);
          }
      }


    },
    'projects.list'(to) {
      this.loadedProjects = Array.isArray(to) ? to.map((project) => {
        return {
          value: window.getId(project._id),
          label: project.name
        };
      }) : [];
    }
  }
}
</script>

<style scoped>
@import '../../library/multyselectionLinkStyle.scss';

</style>