<template>
  <event-block :event-id="eventId" :date="date" :title="{left: 'Счет BILLmanager'}">
    <template #body>
      <div>
        <span class="sender-block fw-bold">Создан: </span>
        <template v-if="account.isNew">
          <a :href="account.link" target="_blank">пользователь</a> ({{account.email}}),
        </template>
        <template v-if="profile.isNew">
          <a :href="profile.link" target="_blank">плательщик</a>,
        </template>
        <a :href="order.link" target="_blank">заказ{{order.products.length > 1 ? 'ы' : ''}}</a>,
        <a :href="order.payment.link" target="_blank">счет</a>.
      </div>
      <div>
        <span class="sender-block fw-bold">Автор:</span> {{user}}
      </div>

      <div v-if="events.length" class="mt-1">
        Дополнительная информация:
        <ul>
          <li v-for="event in events">{{getEventText(event)}}</li>
        </ul>
      </div>

      <table class="table table-bordered table-striped mt-2 mb-0">
        <thead>
          <tr>
            <th width="7%">Тариф</th>
            <th width="50%">Название</th>
            <th width="43%">Цена</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in products">
            <td><a :href="product.link" target="_blank">{{product.item_id}}</a></td>
            <td>{{product.name}}</td>
            <td>
              <popper v-if="product?.discount?.value" :content="`${Number(product.fullprice)} ${product.currency.iso}`" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
                {{getPriceCaption(product)}}
              </popper>
              <template v-else>
                {{getPriceCaption(product)}}
              </template>

              &nbsp;/ {{getPeriodString(product.period)}}

              <span v-if="getDiscountString(product) !== ''">
                (<b>{{getDiscountString(product)}}</b>)
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-2">
        <span class="sender-block fw-bold">Счет: </span>
        <span v-if="invoice">
          <simple-attachment
              :attachment="invoice"
              :messageid="eventId"
              :key="invoice.id"
              :can-view="true"
              style="font-weight: bold;"
          />
          <span v-if="invoiceNoStamp" class="fw-bold">
            [<simple-attachment
                :attachment="invoiceNoStamp"
                name="без печати"
                :messageid="eventId"
                :key="invoice.id"
                :can-view="true"
                style="font-weight: bold;"
                link-style=""
            />]
          </span>
        </span>
        <i v-else>отсутствует</i>
      </div>
    </template>
  </event-block>
</template>

<script>
import parseEvent from "../EventParser";
import EventBlock from "../EventBlock";
import {getNumeralsWord} from "../../../../library/Numerals";
import SimpleAttachment from "../../Message/SimpleAttachment";
import {PricePrettyView} from "../../../../library/PricePrettyView";

export default {
  name: "Invoice",
  components: {EventBlock, SimpleAttachment},

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    account() {
      return this.params.account;
    },

    profile() {
      return this.params.profile;
    },

    order() {
      return this.params.order;
    },

    invoice() {
      return this.getAttachment(0);
    },

    invoiceNoStamp() {
      return this.getAttachment(1);
    },

    events() {
      return [].concat(this.account.events ?? []);
    },

    products() {
      return this.params.order.products;
    }
  },

  methods: {
    getAttachment(index) {
      let result = null;
      if (typeof this.attachments === 'object' && Array.isArray(this.attachments) && this.attachments.length) {
        if (typeof this.attachments[index] === 'object' && this.attachments[index] !== null) {
          result = this.attachments[index];
        }
      }
      return result;
    },

    getEventText(event) {
      let text = `${event.name}: ${event.value}`;
      switch(event.name) {
        case 'SET_GROUP':
          text = `Клиент включен в группу ${event.value}`;
          break;
      }
      return text;
    },

    getPeriodString(months) {
      let string;

      if(months % 12 === 0) {
        string = getNumeralsWord(months / 12, ['год', 'года', 'лет']);
      } else {
        string = `${months} мес.`;
      }

      return string;
    },
    getPriceCaption(product) {
      return (new PricePrettyView(product.price, product.currency.iso)).getPrettyPrice();
    },

    getDiscountString(product) {
      let string = '';

      if(product?.discount && product.discount?.value) {
        if(product.discount.type === 0) {
          string = `скидка ${product.discount.value}%`;
        } else if(product.discount.type === 1) {
          string = `фиксированная цена`;
        }
      }

      return string;
    }
  }
}
</script>

<style scoped>

</style>