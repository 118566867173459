import mutations from "./offerEventsMutations";
import offerEventsActions from "./offerEventsActions";
import offerEventsGetters from "./offerEventsGetters";


const OfferEventsState = {
    events: {
        list:new Map(),
        listByRow:[],
    },
    paginator:{
        page:1,
        onpage:256,
        links:[],
        total:0,
    },
    loading:false,
};

export const moduleOffersEvents = {
    namespaced: true,
    state:OfferEventsState,
    mutations: mutations,
    actions:  offerEventsActions ,
    getters: offerEventsGetters,

}
