<template>
  <div class="input-group align-items-center flex-nowrap">
    ORG:&nbsp;
    <editable-text
        v-if="userCanChange"
        :text="org"
        default="—"
        trim-string-style="text-align: center; width: 100%;"
        input-class="form-control"
        :trim="true"
        @change="string => $emit('update', {org: string})"
    />
    <template v-else>
      <value-or-trim :is-need-trim="true"
                     :value="org" />
    </template>
  </div>
</template>

<script>
import EditableText from "../../../../../Editable/EditableText";
import ValueOrTrim from "../../../../../common/ValueOrTrim";

export default {
  name: "ORG",

  components: {EditableText, ValueOrTrim},
  emits: ['update'],

  props: {
    org: {},
    userCanChange: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>

</style>