export const getDocument = state => {
    return state.document;
}

export const isLoading = state => {
    return state.loading;
}

export const getProjects = state => {
    return state.projects;
}

export const haveNonSaveChanges = state => {
    return state.haveNonSaveChanges;
}

export const getPersonParamOptions = state => {
    return { ...state.commonOptions, ...state.personOptions }
}
export const getTicketOptions = state => state.ticketOptions;
export const getAdditionalCompanyOptions = state => state.additionalCompanyParamOptions;
export const getCompanyOptions = state => {
    let params = Object.entries(state.commonOptions)
                                                   .filter(([key]) => key.indexOf('company.') === 0);
    params = Object.fromEntries(params);
    return Object.assign(params, state.additionalCompanyParamOptions);
}
export const getDeclensionOptions = state => state.declensionOptions;