<template>
  <div class="d-flex flex-column align-content-center">
    <div class="m-auto main-container" id="nav-menu">
      <div>
        <nav-bar />
      </div>
    </div>
    <div class="event-table flex-column align-items-center">
      <offers-events-list></offers-events-list>
    </div>

    <alert-list />
  </div>
</template>

<script>
import AlertList from "../ticket/Alert/AlertList";
import NavBar from "../offer/navigation/NavBar";
import OffersEventsList from "./table/OffersEventsList";
import {IPv4Net} from "../../library/IPv4Net";
export default {
  name: "Events",
  components: {OffersEventsList, NavBar, AlertList},
}
</script>

<style scoped>
.main-container {
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
}
.event-table {
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
  margin: auto;
}
</style>