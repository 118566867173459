<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      <span v-if="byFilter">
        Фильтр <a v-if="filter.url" :href="filter.url" target="_blank" class="filter-name"><string-trim :string="filter.name" /></a>
        <span v-else class="filter-name"><string-trim :string="filter.name" /></span>
        добавил
      </span>
      <span v-else>{{user}} добавил(ла)</span>
      {{ label }} {{ content }}
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import StringTrim from "../../string/StringTrim";

export default {
  name: "AddThreadTags",

  components: {
    StringTrim,
  },

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    label() {
      return Array.isArray(this.params?.tags) && this.params.tags.length > 1 ? 'метки:' : 'метку';
    },
    content() {
      return Array.isArray(this.params?.tags) ? this.params.tags.map(tag => "'" + tag + "'").join(', ') : '';
    }
  }
}
</script>

<style scoped>
.filter-name {
  max-width: 150px;
  display: inline-block;
  white-space: nowrap;
}
</style>