<template>
  <div class="d-flex">
    <popper :content="popperText" class="light me-1" placement="top" :hover="true" :interactive="false" :arrow="true">
      Дата:
    </popper>
    <editable-date
        v-if="userCanChange"
        default="&mdash;"
        input-class="form-control"
        :text="date"
        :callback="updateDate"
        :disabled="disabled"
        :mode="dateMode"
        :format-label="labelFormat"
        :format-datepicker="datepickerFormat"
        placeholder="Готовность сети к сделке"
    ></editable-date>
    <template v-else>{{ showValue ? showValue : '&mdash;' }}</template>
  </div>

  <teleport to="body">
    <transition name="modal" style="text-align: left">
      <modal-window
          v-if="pastDateConfirmation.show"
          @close="closePastDateConfirmation"
          @success="updateDate(pastDateConfirmation.date, true)"
          ok-button-style="btn-primary"
          :buttons-text="{close: 'Отмена', success: 'Подтвердить'}">
        <template v-slot:header>
          <h5 class="modal-title text-danger">Изменение даты с активным лотом</h5>
        </template>
        <template v-slot:body>
          <p>Сеть(и) тикета выставлены на продажу. При изменении даты продажи менее текущего момента, аукцион сразу завершится.</p>
          <p>Вы уверены, что хотите выставить дату продажи {{pastDateConfirmation.date}}?</p>
        </template>
      </modal-window>
    </transition>
  </teleport>
</template>

<script>
import EditableDate from "../../../../../Editable/EditableDate";
import moment from "moment";
import ModalWindow from "../../../ModalWindow.vue";

export default {
  name: "Date",
  components: {ModalWindow, EditableDate},
  emits: ['update'],

  props: {
    date: {},

    popperText: {
      type: String,
      default: 'Дата',
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    userCanChange: {
      type: Boolean,
      default: false
    },

    hasLot: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pastDateConfirmation: {
        show: false,
        date: null,
      }
    }
  },

  computed: {
    showValue: function() {
      let result = '';
      if (typeof this.date === 'undefined' || this.date === null || this.date === '') {
        result = '';
      } else {
        result = this.parseMoment(this.date, this.datepickerFormat, this.labelFormat, '');
      }
      return result;
    },

    datepickerFormat() {
      return this.hasLot ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    },

    labelFormat() {
      return this.hasLot ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY';
    },

    dateMode() {
      return this.hasLot ? "datetime" : "date";
    }
  },

  methods: {
    closePastDateConfirmation() {
      this.pastDateConfirmation = {
        show: false,
        date: null
      }
    },

    parseMoment(value, backupFormat = 'DD.MM.YYYY', outputFormat = 'DD.MM.YYYY', defaultOutput = '') {

      let momentObject = moment(value);
      if (!momentObject.isValid()) {
        momentObject = moment(value, backupFormat);
      }
      return momentObject.isValid() ? momentObject.format(outputFormat) : defaultOutput;
    },
    updateDate(date, confirmed = false) {
      let dateObject = moment(date, this.datepickerFormat);

      if(this.hasLot && dateObject < moment()) {
        if(confirmed === true) {
          this.closePastDateConfirmation();
        } else {
          this.pastDateConfirmation.date = date;
          this.pastDateConfirmation.show = true;
          return;
        }
      }

      date = dateObject.isValid() ? dateObject : null;
      this.$emit('update', {date: date});
    },
  }

}
</script>

<style scoped>

</style>