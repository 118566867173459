


export default function getTypeSmile(type){
    let smile = ''
    switch (type.toLowerCase()) {
        case 'organisation':
            smile = '🏢';
            break;
        case 'person':
            smile = '🚹';
            break;
        case 'role':
            smile = '📂';
            break;
        case 'mntner':
            smile = '🔧';
            break;
        case 'aut-num':
            smile = '💻';
            break;
    }
    return smile
}