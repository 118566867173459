<template>
  <div class="headers-block" ref="headersBlock">
    <div v-if="author">
      <b>From:</b>&nbsp;<span v-if="author.name">{{author.name}}</span><span v-else>{{author.login}}</span>

      <span v-if="unconfirmed && safeHeader.from" class="ps-1">({{safeHeader['from']}})</span>
      <span class="show-link" v-if="showAllLink">
        [<span @click="toggleShowAll" class="text-primary fst-italic text-decoration-underline" role="button">
          {{showAll ? 'Скрыть' : 'Подробно'}}
        </span>]
      </span>
    </div>
    <div v-else-if="safeHeader.from" class="nobr d-flex" ref="from">
      <b>From:</b>&nbsp;<header-clipboard :header="safeHeader['from']" />
      <span class="show-link" v-if="showAllLink">
        [<span @click="toggleShowAll" class="text-primary fst-italic text-decoration-underline" role="button">
          {{showAll ? 'Скрыть' : 'Подробно'}}
        </span>]
      </span>
    </div>
    <div v-if="typeof safeHeader.to === 'string'" class="nobr d-flex" ref="to">
      <b>To:</b>&nbsp;<header-clipboard :header="safeHeader['to']" :trim="!showAll" />
    </div>
    <div v-if="safeHeader.cc" class="nobr d-flex" ref="cc">
      <b>CC:</b>&nbsp;<header-clipboard :header="safeHeader['cc']" :trim="!showAll" />
    </div>
    <div v-if="safeHeader.bcc" class="nobr d-flex" ref="bcc">
      <b>BCC:</b>&nbsp;<header-clipboard :header="safeHeader['bcc']" />
    </div>
    <div v-if="safeHeader['reply-to']" class="nobr d-flex" ref="reply-to">
      <b>Reply-To:</b>&nbsp;<header-clipboard :header="safeHeader['reply-to']" />
    </div>
    <div v-if="(showAll || showSubject) && subject" class="nobr d-flex" ref="subject">
      <b>Subject:</b>&nbsp;<trim :string="subject" />
    </div>
    <div style="margin-bottom: 5px"></div>
  </div>
</template>

<script>
import StringTrim from "../../string/StringTrim";
import HeaderEmailClipboard from "./HeaderEmailClipboard";
import {mapGetters} from "vuex";
import {popperHighlight} from "../../../library/Functions";

export default {
  name: "HeadersBlock",

  components: {
    'header-clipboard': HeaderEmailClipboard,
    'trim': StringTrim
  },

  props:{
    "headers":{
      type:Object,
      require:true
    },
    "author":{
      type:Object,
      default: null
    },
    "message":{
      type: Object,
      default: null
    },
    isProjectNull: {
      type: Boolean,
      default: false
    },
    changedSubject: {
      type: Boolean,
      default: false,
    },
    unconfirmed: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      showAll: false,
    }
  },

  mounted() {
    this.addHeader();

    if(this.unconfirmed && this.changedSubject) {
      this.showAll = true;
    }

    if (this.showSubject) {
      popperHighlight(this.$refs.subject, this.searchString);
    }
  },

  computed:{
    safeHeader: function(){
      return this.headers ?? {};
    },
    subject() {
      return this.safeHeader['subject'] ?? this.message?.subject ?? null;
    },
    showAllLink() {
      let result = false;
      if (this.subject) {
        result = true;
      } else {
        if (
            typeof this.safeHeader['to'] === 'string' &&
            this.safeHeader['to'] !== '' &&
            this.safeHeader['to'].split(',').filter(item => item.trim() !== '').length > 10 ||
            typeof this.safeHeader['cc'] === 'string' &&
            this.safeHeader['cc'] !== '' &&
            this.safeHeader['cc'].split(',').filter(item => item.trim() !== '').length > 10
        ) {
          result = true;
        }
      }
      return result;
    },
    showSubject() {
      let result = false;
      if (typeof this.subject === 'string' && this.subject.trim() !== '') {
        if (
            this.showSearchField &&
            this.searchString.trim() !== '' &&
            this.subject.indexOf(this.searchString.trim()) > -1
        ) {
          result = true;
        }
      }
      return result;
    },
    ...mapGetters({
      "isTicketWindowFullSize": "thread/isTicketWindowFullSize",
      "showSearchField": "thread/showSearchField",
      "searchString": "thread/searchString",
    }),
  },

  methods: {
    addHeader() {
      this.$nextTick(() => {
        Object.entries(this.safeHeader).forEach(([name, value]) => {
          let el = this.$refs[name];
          if (value && el) {
            if (this.elWidth(el) > this.headersBlockWidth()) {
              el.classList.add('flex-wrap');
            }
          }
        });
      });
    },
    headersBlockWidth() {
      let el = this.$refs.headersBlock;
      return el ? el.clientWidth - parseFloat(getComputedStyle(el).paddingRight) : 0;
    },
    elWidth(el) {
      return el ? el.scrollWidth : 0;
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
      this.addHeader();
    }
  },

  watch: {
    'isTicketWindowFullSize': function () {
      this.addHeader();
    },
  }
}
</script>

<style scoped>
.headers-block {
  padding-right: 2.3em;
}
:deep(.header-wrapper) {
  display: inline-block;
  white-space: nowrap;
}
.show-link {
  display: inline-block;
  white-space: nowrap;
  margin-left: 4px;
}
</style>