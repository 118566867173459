<template>
  <div class="col-12" style="text-align: center">
    <div class="message event-document position-relative d-inline-block"
         :class="{'event-document-sending': upload}"
         @mouseover="showContextButton = true"
         @mouseout="showContextButton = false"
    >
      <div class="col-12 datetime-stamp text-end">{{date}}</div>
      <div class="event-document-label">
        <span class="text-secondary" style="margin-right: 30px;">Документ</span>
        <div v-show="showContextButton" class="contextFromButton">
          <context-button
              :custom-button-style="{
                'border-style':'none',
                'background-color':'#f3f3f3',
                'outline':'none'
              }"
              :messageid="eventId"
              :show="showFunctions"
              :callbacks="{
                showRevisions: showRevisionsFn,
                hideRevisions: hideRevisionsFn,
                replaceAttachment: replaceAttachmentFn,
              }"
          ></context-button>
        </div>
      </div>
      <input v-if="canReplace"
             ref="attachment"
             type="file"
             :id="'attachment-input_' + event._id"
             class="d-none"
             accept=".docx"
             @change="uploadAttachment">
      <div class="event-document-header sender-block">
        <div>Шаблон: {{ params?.template?.name }}</div>
        <div>Автор: {{ user }}</div>
        <div v-if="lastModify">Изменен: {{ lastModify }}</div>
      </div>
      <div class="event-document-links" v-if="canViewRevisionFiles()">
        <div class="d-inline-flex align-items-center" :class="{'with-add-to-person': canAddToPerson && !checkAlreadyInPersonDocx}">
          <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="getFileName(this.event)">
            <a :href="documentLink" target="_blank" class="mx-1 text-primary">
              <i class="fa fa-file-word-o"></i>
            </a>
          </popper>
          <button
              v-if="canAddToPerson && !checkAlreadyInPersonDocx"
              class="add-to-person-btn blue-icon mx-1"
              type="button"
              :class="{'disabled-icon': loading}"
              :disabled="loading"
              @click="addToPerson()"
          >
            <popper content="Добавить файл в карточку клиента" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
              <i class="fa fa-plus-circle"></i>
            </popper>
          </button>
        </div>
        <div class="d-inline-flex align-items-center" :class="{'with-add-to-person': canAddToPerson && !checkAlreadyInPersonPdf}">
          <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="getFileName(this.event,'pdf')">
            <a :href="documentLink + '?convert=pdf'" target="_blank" class="mx-1 text-danger">
              <i class="fa fa-file-pdf-o"></i>
            </a>
          </popper>
          <button
              v-if="canAddToPerson && !checkAlreadyInPersonPdf"
              class="add-to-person-btn blue-icon mx-1"
              type="button"
              :class="{'disabled-icon': loading}"
              :disabled="loading"
              @click="addToPerson('pdf')"
          >
            <popper content="Добавить файл в карточку клиента" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
              <i class="fa fa-plus-circle"></i>
            </popper>
          </button>
        </div>
      </div>
      <div class="event-document-revisions" v-if="revisions.length" v-show="showRevisions">
        <table class="table table-bordered table-striped">
          <tr>
            <th>Дата</th>
            <th>Автор</th>
            <th>Файлы</th>
          </tr>
          <tr v-for="revision in revisions">
            <td>{{ formatDate(revision.date) }}</td>
            <td>{{ getName(revision.user) }}</td>
            <td>
              <div v-if="canViewRevisionFiles(revision)" class="revisions-files">
                <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="getFileName(revision)">
                  <a :href="getDocumentLink(revision.id)" target="_blank" class="ms-1 text-primary">
                    <i class="fa fa-file-word-o"></i>
                  </a>
                </popper>
                <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true" :content="getFileName(revision, 'pdf')">
                  <a :href="getDocumentLink(revision.id) + '?convert=pdf'" target="_blank" class="ms-1 text-danger">
                    <i class="fa fa-file-pdf-o"></i>
                  </a>
                </popper>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {parseReactiveEvent} from "./EventParser";
import ContextButton from "../Message/ContextMenu/ContextButton";
import {getName} from "../../../library/userName";
import {Documents} from "../../../library/api/Documents";
import {CrmApi} from "../../../library/CrmApi";
import {getDateFormat} from "../Api/api";
import {toRef} from "vue";
import {mapGetters} from "vuex";
import {userDocumentsPermission} from "../../../store/main/thread/getters";
import {Messages} from "../../../library/api/Messages";

export default {
  name: "DocumentGenerated",

  components: {
    ContextButton,
  },

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  emits: [
    'update',
  ],

  setup: (props) => {
    const { user, date, eventId, params } = parseReactiveEvent(toRef(props, 'event'));
    return { user, date, eventId, params };
  },

  data: function (){
    return {
      showContextButton: false,
      showRevisions: false,
      upload: false,
      loading: false,
    }
  },

  computed: {
    documentLink() {
      return this.getDocumentLink();
    },

    lastModify() {
      let result = null;
      if (typeof this.params?.last_modify !== 'undefined') {
        result = moment(this.params?.last_modify).format('YYYY-MM-DD (ddd) HH:mm');
      }
      return result;
    },

    showFunctions() {
      let result = [];
      if (this.canReplace) {
        result.push('replaceAttachment');
      }
      if (this.revisions.length) {
        result.push(this.showRevisions ? 'hideRevisions' : 'showRevisions');
      }
      return result;
    },

    revisions() {
      return typeof this.params?.revisions !== 'undefined' &&
             Array.isArray(this.params?.revisions) ?
             [...this.params?.revisions].sort((a,b) => b.id - a.id) :
             [];
    },

    canAddToPerson() {
      return this.canViewRevisionFiles() && this.canUpdatePerson;
    },

    checkAlreadyInPersonDocx() {
      return this.checkAlreadyInPerson('docx');
    },

    checkAlreadyInPersonPdf() {
      return this.checkAlreadyInPerson('pdf');
    },

    attachmentId() {
      let result = null;
      if (
          typeof this.event?.attachments === 'object' &&
          Array.isArray(this.event?.attachments) &&
          typeof this.event?.attachments[0] === 'object' &&
          this.event?.attachments[0]?.id
      ) {
        result = window.getId(this.event?.attachments[0]?.id);
      }
      return result;
    },

    ...mapGetters({
      "userAttachmentPermission": "thread/userAttachmentPermission",
      "canReplace": "thread/userDocumentsPermission",
      "canUpdatePerson": "thread/isUserUpdatePersonPermission",
      "person": "thread/getPerson",
    }),
  },

  methods: {
    canViewRevisionFiles(revision = null) {
      let result = false;
      let userId = null;
      if (this.userAttachmentPermission) {
        result = true;
      } else if (this.canReplace) {
        if (revision && typeof revision?.user?._id !== 'undefined') {
          userId = window.getId(revision.user._id);
        } else if (typeof this.params?.user?._id !== 'undefined') {
          userId = window.getId(this.params?.user?._id);
        }
        result = userId === window.userId;
      }
      return result;
    },
    getDocumentLink(revision = null) {
      let ticketId = encodeURIComponent(window.getId(this.event.ticket._id));
      let eventId = encodeURIComponent(window.getId(this.event._id));
      let base = `/ajax/thread/${ticketId}/doctemplates/${eventId}/revision/`;
      return base + (revision ? revision + '/' : '');
    },
    formatDate(date) {
      return getDateFormat(date, window.dateFormatWeekDaySeconds);
    },
    showRevisionsFn() {
      this.showRevisions = true;
    },
    hideRevisionsFn() {
      this.showRevisions = false;
    },
    replaceAttachmentFn() {
      this.$refs.attachment.click();
    },
    uploadAttachment(e) {
      if ([...e.target.files].length === [...e.target.files].filter(file => {
        let extension = file.name.indexOf('.') > 0 ? file.name.split('.').pop() : '';
        return extension === 'docx';
      }).length) {
        this.upload = true;
        Documents.replaceDocuments(
            window.getId(this.event.ticket._id),
            window.getId(this.event._id),
            [...e.target.files]
        ).then((response) => {
          if (typeof response.data?.event !== 'undefined') {
            this.$emit('update', response.data?.event);
          }
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.upload = false;
          e.target.value = '';
        });
      } else {
        this.$store.commit('errorPush', 'Необходимо загрузить docx файл');
      }
    },
    getFileName(revision, extension = '') {
      let result = '';
      if (typeof revision?.attachments !== 'undefined' &&
          Array.isArray(revision?.attachments) &&
          revision?.attachments.length > 0) {
        result = revision?.attachments[0]?.filename ?? '';
        if (extension) {
          if (result.indexOf('.') > 0) {
            result = result.substring(0, result.lastIndexOf('.'));
          }
          result = result + '.' + extension;
        }
      }
      return result;
    },
    getName(user) {
      return getName(user);
    },
    addToPerson(type = null) {
      let query = {
        message: window.getId(this.event._id)
      }
      query.id = this.attachmentId;
      if (query.id) {
        if (type) {
          query.query = {
            convert: type,
          }
        }
        this.loading = true;
        Messages.addAttachmentToPerson(query).then((result) => {
          if (result.data?.success === true) {
            this.$store.commit('successPush', 'Вложения добавлены');
          } else {
            this.$store.commit('errorPush', 'Вложения не добавлены');
          }
        }).catch((error) => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        }).finally(() => {
          this.loading = false;
        });
      }
    },

    checkAlreadyInPerson(type) {
      let result = false;
      if (typeof this.person?.company?.documents !== 'undefined' && Array.isArray(this.person?.company?.documents)) {
        result = Boolean(
          this.person.company.documents
            .filter(doc => doc?.attachmentid)
            .find(doc => window.getId(doc?.attachmentid) === this.attachmentId && doc.filename.endsWith(type))
        );
      }
      return result;
    },
  },
}
</script>

<style scoped>
.event-document {
  min-width: 75%;
  background: #f3f3f3;
  text-align: left;
  border-radius: 20px;
  padding-bottom: 0;
}
.event-document-label {
  position: absolute;
  top: 4px;
  right: 16px;
}
.event-document-sending {
  cursor: wait !important;
  opacity: 0.5;
}
.event-document-sending * {
  cursor: wait !important;
}
.event-document-links a {
  font-size: 30px;
}
.revisions-files i {
  font-size: 20px;
}
.blue-icon {
  color: #6262f5;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}
.blue-icon i {
  font-size: 16px;
}
.disabled-icon {
  opacity: 0.8;
  cursor: wait !important;
}
.with-add-to-person .add-to-person-btn {
  display: none;
}
.with-add-to-person:hover .add-to-person-btn {
  display: initial;
}
</style>