<template>
  <div v-if="crm_offers_rates" class="d-flex flex-column align-content-center" id="rates-list">
    <div class="m-auto main-container bordered-bottom" id="nav-menu">
      <div class="row">
        <div class="col-10">
          <nav-bar />
        </div>
      </div>
    </div>
    <div class="history-table flex-column align-items-center">
      <div class="col-12">
        <loading-list v-if="loading"></loading-list>
        <div v-if="!loading" id="history-list" ref="history-list" style="padding-top: 34px; ">
          <div >
            <div class="text-center">
              <div style="display: inline-block">
                <table class="table table-bordered table-hover table-striped table-container lg-padding"
                       style="margin: 5px; width: 500px;" >
                  <thead>
                  <tr>
                    <th class="text-center"></th>
                    <th class="text-center" style="font-weight: bold" >PA</th>
                    <th  class="text-center"  style="font-weight: bold">PI</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td  class="text-center"  >Мы продаем</td>
                    <td  class="text-center" title="Средняя цена на рынке для /17 и менее за 3М (ALL) от 10% и до 20%"  >{{sell_pa}} - {{sell_pa15}}</td>
                    <td  class="text-center" :title="sell_pi_title"  >{{sell_pi}}</td>
                  </tr>
                  <tr>
                    <td class="text-center" >Мы покупаем</td>
                    <td  class="text-center"  :title="buy_title" colspan="2" >{{buy_from}} - {{buy_to}}</td>
                  </tr>
                  <tr>
                    <td class="text-center" >Мы покупаем себе</td>
                    <td  class="text-center"  :title="buy_our_title" colspan="2" >{{buy_our_from}} - {{buy_our_to}}</td>
                  </tr>
                  </tbody>
                </table>
                <div class="text-center">
                  <a class="text-danger" style="text-decoration: none" href="https://wiki.i7.org/pages/viewpage.action?pageId=109052089#id-Команднаяцельиориентирыцен-Команднаяцель" target="_blank">Инструкция</a>
                </div>
              </div>
              <div  style="display: inline-block">
                <kpi-table :selected-year="currentYear" :admin-status="false" :only-current="true"></kpi-table>
                <div class="text-center">
                  <a class="text-danger" style="text-decoration: none" href="https://wiki.i7.org/pages/viewpage.action?pageId=109052089#id-Команднаяцельиориентирыцен-Ценынапокупкуипродажу" target="_blank">Инструкция</a>
                </div>
              </div>
            </div>
            <div>
              <div class="text-center" style="margin-top: 20px;">
                Исключение: цена продажи PI для тендеров (сложные долгие сделки, через площадки) - <span style="font-weight: bold">1 990 000 ₽</span>.
              </div>
            </div>
            <hr v-if="freeSubnets !== null || waiting24"/>
            <div class="text-center" v-if="isHasFreesale">
              Сейчас на нашем LIR свободно под продажу примерно <b>~{{prettyFreeSum}}</b> IPv4 ({{prettyip22}} /22; {{prettyip23}} /23; {{prettyip24}} /24)
            </div>
            <br/>
            <template v-if="freeSubnets !== null">
              <div class="text-center">
                Сейчас на нашем LIR свободно под аренду примерно ~<b>{{freeSubnets}}</b> {{numeralBlockString}} /24
              </div>
            </template>
            <div class="text-center" v-if="waiting24">
              В ожидание выделения оплачено <b>{{waiting24}}</b> {{waiting24String}} /24
            </div>
          </div>
        </div>
      </div>

  </div>
    <alert-list />
  </div>
</template>

<script>
import NavBar from "../offer/navigation/NavBar";
import {mapActions, mapGetters} from "vuex";
import AlertList from "../ticket/Alert/AlertList";
import {PricePrettyView} from "../../library/PricePrettyView";
import {UserPermission} from "../../users/UserPermission";
import LoadingList from "../ticket/List/LoadingList";
import KpiTable from "../statistic/Kpi/KpiTable";
import moment from "moment";
import {getNumeral} from "../../library/Numerals";

const STAT_24_AMOUNT_MAP = {
  "24": 1,
  "23": 2,
  "22": 4,
  "21": 8,
  "20": 16,
  "19": 32,
}
export default {
  name: "RatesMain",
  components: {
    KpiTable,
    AlertList,
    NavBar,
    LoadingList,
  },
  props:{
    additionalParams:{
      default:{},
    }
  },
  data:function (){
    return {
      crm_offers_rates:false,
    }
  },
  created() {

    UserPermission.can('system.crm_offers_rates').then(()=>{
      this.crm_offers_rates = true
    }).catch(()=>{});
    let promise = [];
    promise.push(this.loadRates({}));
    promise.push(this.getKpi({isAdmin:"off"}));
    promise.push(this.getWaitingOrders());
    this.loading = true;
    Promise.all(promise).then((values) => {
    }).finally(()=>{
      this.loading = false;
    });
  },
  methods:{
    ...mapActions({
      'loadRates':'ratesStatisticModule/loadRates',
      'getKpi':'statistic/getKpi',
      'getWaitingOrders':'statistic/getWaitingOrders'
    }),
    preparePrice(value){
      if(typeof value === "undefined" || value === null){
        return ''
      }
      let p = new PricePrettyView(value, 'USD')
      return p.getPrettyPrice('',true, 1, false)
    }
  },
  computed:{
    loading:{
      set(value){
        this.$store.commit('statistic/setLoading' , value);
      },
      get(){
        return this.$store.getters['statistic/isLoading'];
      }
    },
    waiting24:function(){
      return this.waitingOrders.reduce((accum, value) => value?.metainfo?.ipv == "4" && value?.metainfo?.size !== null && STAT_24_AMOUNT_MAP[value?.metainfo?.size] ?
          accum + STAT_24_AMOUNT_MAP[value?.metainfo?.size] : accum,
          0);
    },
    isHasFreesale:function (){
      return this.getRates?.freesale
    },

    prettyFreeSum:function (){
      return parseFloat(this.getRates.freesale.sumFree).toLocaleString(undefined,  {maximumFractionDigits: 0} )
    },
    prettyip24:function (){
      return parseFloat(this.getRates.freesale.ip24).toLocaleString(undefined,  {maximumFractionDigits: 0} )
    },
    prettyip23:function (){
      return parseFloat(this.getRates.freesale.ip23).toLocaleString(undefined,  {maximumFractionDigits: 0} )
    },
    prettyip22:function (){
      return parseFloat(this.getRates.freesale.ip22).toLocaleString(undefined,  {maximumFractionDigits: 0} )
    },
    waiting24String(){
      return typeof this.waiting24 === 'number'
          ? getNumeral(this.waiting24, ['блок', 'блока', 'блоков'])
          : null;
    },
    currentYear:function (){
      return moment().format("YYYY");
    },
    buy_our_title:function (){
      return  this.additionalParams?.buy_our_title ?? '';
    },
    buy_title:function (){
      return  this.additionalParams?.buy_title ?? '';
    },
    sell_pi_title:function (){
      return this.additionalParams?.sell_pi_title ?? '';
    },
    ...mapGetters({
      'getRates':'ratesStatisticModule/getRatesList',
      'waitingOrders':'statistic/getWaitingOrders',
    }),
    sell_pa:function (){
      return this.preparePrice(this.getRates?.sell?.pa)
    },
    sell_pa15:function (){
      return this.preparePrice(this.getRates?.sell?.pa15)
    },
    sell_pi:function (){
      return this.preparePrice(this.getRates?.sell?.pi)
    },
    buy_our_from:function (){
      return this.preparePrice(this.getRates?.buy_our?.from)
    },
    buy_our_to:function (){
      return this.preparePrice(this.getRates?.buy_our?.to)
    },
    buy_from:function (){
      return this.preparePrice(this.getRates?.buy?.from)
    },
    buy_to:function (){
      return this.preparePrice(this.getRates?.buy?.to)
    },
    freeSubnets() {
      return typeof this.getRates?.subnets === 'number'
                ? this.getRates.subnets
                : null;
    },
    numeralBlockString(){
      return typeof this.getRates?.subnets === 'number'
          ? getNumeral(this.getRates.subnets, ['блок', 'блока', 'блоков'])
          : null;
    },
  }
}
</script>

<style scoped>
.main-container {
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
}
.history-table {
  margin-left: 12px;
  max-width: calc(100% - 24px);
  min-width: 1000px;
  width: 99vw;
  overflow-x: auto;
}
.bordered-bottom{
  border-bottom: 1px solid;
  border-color: #dee2e6;
}
.lg-padding td{
  padding-left: 25px;
  padding-right: 25px;
}
:deep(.small-table){
  margin: 5px;
}
</style>
