<template>
  <div>
    <div ref="timer-value" class="text-style" >{{timeToWatch}}</div>
  </div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

export default {
  name: "Timer",
  props:{
    startWith:{
      default:3,
    }
  },
  emits:[
      'finish'
  ],
  data:function (){
    return {
      timer:null,

      seconds:3,
    }
  },
  computed:{
    timeToWatch:function (){
      let string = this.seconds;
      /*
      if(this.seconds < 10){
       string = "0" + string;
      }

       */
      return string;
    }
  },
  mounted() {
    this.start();
  },
  beforeUnmount() {
    this.reset();
  },
  methods:{
    start () {
      this.isRunning = true
      if (!this.timer) {
        anime({
          targets:this.$refs['timer-value'],
          scale:[
            0, 1
          ],


          duration:900
        });
        this.timer = setInterval( () => {
          if (this.seconds > 0) {
            this.seconds--

            anime({
              targets:this.$refs['timer-value'],
              scale:[
                0, 1
              ],
              duration:900
            });
          } else {
            clearInterval(this.timer)
            this.reset()
            this.$emit('finish');
          }
        }, 1000 )
      }
    },
    stop () {
      this.isRunning = false
      clearInterval(this.timer)
      this.timer = null
    },
    reset () {
      this.stop()
      this.seconds = 3
    },
  },
}
</script>

<style scoped>
.text-style{
  font-size: 32px;
  font-weight: bold;
}
</style>