import {getNameWithLogin} from "../../../../library/userName";

const tasksGetters = {
    getUsers: state => state.users,
    getUsersForSelect: (state) => {
        let names = {};
        for (let el of state.users){

            names[window.getId(el._id)] = getNameWithLogin(el)
        }
        return names;
    },
    canTasksControl: state => state.canTasksControl,
    canUserList: state => state.canUserList,
    getTasks: state => state.tasks,
};

export default tasksGetters;