<template>
  <select
    class="form-select"
    v-model="value"
    >
    <option :value="''" selected>{{placeholder}}</option>
    <option
        v-for="option in object.options"
        :value="option.value"
    >{{ option.name }}</option>
  </select>
</template>

<script>
import addToQueryWithTimer from "./timer";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "FilterSelect",

  setup() {
    return {
      addToQueryWithTimer
    };
  },

  props: {
    'placeholder': {
      type: String,
      default: "",
    },
    'field': {
      type: String,
      require: true,
    },
    'object': {
      type: Object,
    }
  },

  computed: {
    value: {
      get() {
        return this.getFilterByName(this.field).value;
      },
      set(value) {
        let obj = Object.assign({}, this.object);
        obj.value = value;
        this.setFilterValue(obj);
        this.addToQueryWithTimer(value, this.field);
      }
    },
    ...mapGetters({
      "isLoading": "offer/getLoading",
      "show": "offer/filters/showFilter",
      "getFilterByName": "offer/filters/getFilterByName",
    }),
  },

  methods:{
    ...mapMutations({
      'setFilterValue':'offer/filters/setFilterValue',
    }),
  }
}
</script>

<style scoped>

</style>