<template>
  <div class="pt-5 col-md-6 m-auto" id="template-edit-form">
    <div class="mb-3">
      <label for="input-name" class="form-label">Название</label>
      <input type="text" v-model="project.name" class="form-control" name="name" id="input-name" required>
    </div>
    <div class="mb-3">
      <label for="input-language" class="form-label">Язык</label>
      <select v-model="project.language" class="form-control" name="language" id="input-language">
        <option :value="null" selected>—</option>
        <option value="RU">Русский</option>
        <option value="EN">Английский</option>
      </select>
    </div>
    <div class="mb-3">
      <label for="input-urls-faq" class="form-label">FAQ</label>
      <input type="url" pattern="https?://.+" v-model="project.urls.faq" class="form-control" name="urls-faq" id="input-urls-faq" placeholder="Ссылка на FAQ" required>
    </div>
    <div class="mb-3">
      <label for="input-urls-glossary" class="form-label">Глоссарий</label>
      <input type="url" pattern="https?://.+" v-model="project.urls.glossary" class="form-control" name="urls-glossary"  placeholder="Ссылка на глоссарий" id="input-urls-glossary" required>
    </div>
    <div class="mb-3">
      <popper content="Группа для отправки уведомлений о событиях в проекте" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
        <label for="input-rocket-notifygroup" class="form-label">Группа Rocket.Chat</label>
      </popper>
      <input type="text" v-model="project.rocket.notifygroup" class="form-control" name="rocket-notifygroup" placeholder="#group" id="input-rocket-notifygroup" required>
    </div>
    <div class="mb-3">
      <label class="form-label">Провайдер в BILLmanager</label>
      <input type="text" class="form-control" v-if="billmanager.loading" disabled/>
      <multiselect  placeholder="Проект не связан с провайдерами BILLmanager"
                    v-model="billmanager.providers"
                    :mode="'tags'"
                    :close-on-select="false"
                    :searchable="true"
                    :create-option="false"
                    :delay="1"
                    :object="true"
                    :options="updateProjects"
                    v-else
      >
        <template v-slot:tag="{ option, handleTagRemove, disabled }">
          <div :class="{'project-class': option.type === 'project' , 'department-class': option.type === 'department'}" class="multiselect-tag is-user" >
            <span>{{ option.label }} </span>
            <span
                v-if="!disabled"
                class="multiselect-tag-remove"
                @mousedown.prevent="handleTagRemove(option, $event)"
            >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
          </div>
        </template>
      </multiselect>
    </div>
    <div class="mb-3">
      <div class="form-check">
        <label class="form-check-label" for="ipspam">IP SPAM</label>
        <input class="form-check-input" type="checkbox" v-model="ipspam" id="ipspam">
      </div>
      <div class="text-muted" style="font-size: 11px">Активировать кнопку IP SPAM, которая переносит тикеты в папку "Конкуренты"</div>
    </div>
    <div class="mb-3">
      <div>Шаблон приветствия</div>
      <div class="row">
        <div class="col-6">
          <label for="greeting-template" style="font-size: 12px;" class="text-muted">RU</label>
          <expandable-text-area
              :aria-class="{'form-control':true}"
              id="greeting-template"
              v-model="project.greetingtemplate"
              placeholder="Здравствуйте,"
          ></expandable-text-area>
        </div>
        <div class="col-6">
          <label for="greeting-template-en" style="font-size: 12px;" class="text-muted">EN</label>
          <expandable-text-area
              :aria-class="{'form-control':true}"
              id="greeting-template-en"
              v-model="project.greetingtemplate_en"
              placeholder="Hello,"
          ></expandable-text-area>
        </div>
      </div>
      <div class="form-text">{name} будет заменено на имя контакта</div>
    </div>

    <div class="mb-3">
      <label for="project-emails" class="form-label">Email алиасов для обратных ответов</label>
      <ul class="list-group" id="project-emails">
        <li class="list-group-item">
          <div class="input-group has-validation">
            <input type="email" class="form-control" :class="{'is-invalid': errors.newEmail}" placeholder="mail@example.com" aria-describedby="validationNewEmailFeedback" v-model="fields.newEmail" ref="newEmailInput">
            <popper content="Добавить email в проект" class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
              <button class="btn btn-outline-success" type="button" id="button-addon1" :disabled="newEmailDisabled" @click="addEmail(fields.newEmail)">
                <i class="fa fa-plus-square pe-1" aria-hidden="true"></i> Добавить
              </button>
            </popper>
            <div id="validationNewEmailFeedback" class="invalid-feedback" v-if="errors.newEmail">{{errors.newEmail}}</div>
            <div id="validationNewEmailWarning" class="invalid-warning text-warning"  v-if="errors.newEmailWarning" >{{errors.newEmailWarning}}</div>
          </div>
        </li>

        <project-form-email
            v-for="email in project.emails"
            :email="email"
            :main="(email === this.project.primarymail)"
            type="emails"
            action="create"
            :smtp="smtp"
            :ats-endpoints="atsEndpoints"
            :emailparams="getEmailparams(email)"
            :signature="getSignature(email)"
            :callback="{changePrimaryEmail: changePrimary,
                        deleteEmail: deleteEmail,
                        updateSignature: updateSignature}"
        ></project-form-email>
      </ul>
    </div>
    <email-patterns @update="updatePatterns"></email-patterns>
    <div class="double-buttons-group">
      <div>
        <a role="button" class="btn btn-secondary col-6" href="/projects/" :disabled="this.saving">Назад</a>
      </div>
      <div>
        <button class="btn btn-primary col-6" @click="save" :disabled="saveDisabled || this.saving">Сохранить</button>
      </div>
    </div>
  </div>
  <modal-window v-if="showDeleteModal" @close="showDeleteModal = false" @success="deleteAlias" ok-button-style="btn-danger">
    <template v-slot:header>
      <h5 class="modal-title text-danger">Удаление алиаса</h5>
    </template>
    <template v-slot:body>
      <span>Вы действительно хотите навсегда удалить алиас {{aliasToDelete.email}}?</span>
    </template>
  </modal-window>
  <alert-list></alert-list>
</template>

<script>
import ProjectFormEmail from "./ProjectFormEmail";
import {CrmApi} from "../../library/CrmApi";
import ExpandableTextArea from "../common/textarea/ExpandableTextArea";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import EmailPatterns from "./EmailPatterns";
import AlertList from "../ticket/Alert/AlertList";
import Multiselect from "@vueform/multiselect";
import {Project} from "../../library/api/Project";

export default {
  name: "ProjectCreateForm",

  components: {
    Multiselect,
    ExpandableTextArea,
    ProjectFormEmail,
    AlertList,
    ModalWindow,
    EmailPatterns,
  },

  data: function() {
    return {
      allTagsArray:[],
      deletetag:{
        tag:''
      },
      billmanager: {
        id: null,
        providers: [],
        departments:[],
        projects: [],
        loading: false,
      },
      project: {
        name: '',
        urls: {
          faq: '',
          glossary: '',
        },
        rocket: {
          notifygroup: ''
        },
        language: null,
        emails: [],
        primarymail: null,
        signature: {},
        emailparams: {},
        greetingtemplate: '',
        greetingtemplate_en: '',
      },
      fields: {
        newEmail: '',
      },
      errors: {
        newEmail: false,
        newEmailWarning:false,
      },
      saving: false,
      showDeleteModal: false,
      aliasToDelete: null,
      smtp: [],
      ipspam: false,
      atsEndpoints: [],
    }
  },

  computed: {
    newEmailDisabled: function() {
      let validation = this.emailValidation(this.fields.newEmail,
                                            this.$refs.newEmailInput,
                                            [ ...this.project.emails ]);
      if(validation === true) {
        this.errors.newEmail = false;
        this.errors.newEmailWarning = "Для добавления почты необходимо нажать \"Добавить\"";
      } else {
        this.errors.newEmailWarning = false;
        this.errors.newEmail = validation;
        validation = false;
      }

      return !validation;
    },

    saveDisabled: function() {
      return (!this.project.name ||
          this.project.emails === [] ||
          !this.project.primarymail);
    },
  },

  created() {
    Project.getAtsEndpoints().then((result) => {
      if (typeof result?.data?.data !== 'undefined' && Array.isArray(result?.data?.data)) {
        this.atsEndpoints = result.data.data.map(item => item?.resource ?? null).filter(item => !!item);
      }
    })
  },

  mounted: function() {
    CrmApi.getSmtpList({}).then((result) => {
      if (typeof result.data.data !== 'undefined') {
        this.smtp = result.data.data;
      }
    });

    this.billmanager.loading = true;
    CrmApi.getBillmanagerProjects().then((result) => {
      this.billmanager.projects = result.data.projects;
      this.billmanager.departments = result.data.departments;

    }).catch(() => {
      this.$store.commit('errorPush', 'Ошибка при загрузке проектов BILLmanager');
    }).finally(()=>{
      this.billmanager.loading = false;
    });
  },

  methods: {
    updateProjects(query){
      return (new Promise(
          (resolve)=>{
            let checkBillmanagerLoaded = () => {
              if( this.billmanager.loading ){
                setTimeout(checkBillmanagerLoaded,100);
              } else {
                resolve();
              }
            };
            checkBillmanagerLoaded();
          }))
          .then(()=>{
            let list = [];
            for(let project of this.billmanager.projects){
              if( query == null || project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ){
                let key = project.id + 'project';
                let object = {
                  value: key,
                  id:project.id,
                  label: project.name,
                  type:'project',
                }
                list.push(object)


              }
            }
            for(let project of  this.billmanager.departments){
              if( query == null || project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ){
                let key = project.id + 'department';
                let object ={
                  value: key,
                  id:project.id,
                  label: project.name,
                  type:'department',
                }
                list.push(object)

              }
            }
            return list;

          })
    },
    updateTags(query){
      return CrmApi.getTags(query).then((result)=>{
        this.allTagsArray = [];
        let unique = {};
        if(this.deletetag.tag !== null && this.deletetag.tag !== ''){
          this.allTagsArray.push({
            value:this.deletetag.tag,
            label:this.deletetag.tag,
          });
          unique[this.deletetag.tag] = this.deletetag.tag;
        }

        let tags = result.data.data;
        for (let key in tags){
          let el = tags[key];
          if(typeof unique[el.tags] === 'undefined'){
            unique[el.tags] = el.tags
            this.allTagsArray.push({
              value:el.tags,
              label:el.tags,
            });
          }
        }

        return  this.allTagsArray;
      }).catch(()=> {
        return  this.allTagsArray;
      })
    },
    showDeleteModalFunction(email, type){
      this.showDeleteModal = true;
      this.aliasToDelete = {email: email, type: type};
    },
    emailValidation(model, input, list) {
      let validation = false;

      if(!model);
      else if(!input.value.match(/\S+@\S+\.\S+/)) {
        validation = 'Поле должно содержать корректный email';
      } else if(list.indexOf(model) > -1) {
        validation = 'Такой email уже добавлен в проект';
      } else {
        validation = true;
      }

      return validation;
    },

    changePrimary: function(newPrimaryEmail) {
      this.project.primarymail = newPrimaryEmail;
    },

    deleteEmail: function(email, type) {
      this.showDeleteModal = false;
      delete this.project[type][this.project[type].indexOf(email)];
    },

    deleteAlias: function () {
      if (typeof this.aliasToDelete !== 'undefined') {
        return this.deleteEmail(this.aliasToDelete.email, this.aliasToDelete.type);
      }
    },

    updateSignature: function(email, signature, emailParams = null) {
      if(typeof this.project.signature[email] !== 'undefined' && signature === '') {
        delete this.project.signature[email];
      } else {
        this.project.signature[email] = signature;
      }
      if (emailParams) {
        for (const dataKey in emailParams) {
          let val = emailParams[dataKey];
          if (typeof val['smtp'] !== 'undefined' && val['smtp'] !== null) {
            val['smtp'] = window.getId(val['smtp']);
          }
        }
        this.project.emailparams[email] = emailParams;
      }
    },

    updatePatterns: function(patterns) {
      this.project.emailpatterns = patterns;
    },

    addEmail: function(email) {
      this.project.emails.push(email);
      if(this.project.emails.length === 1) {
        this.project.primarymail = email;
      }
      this.fields.newEmail = '';
    },

    save: function() {
      this.saving = true;
      let params = {}
      if(typeof this.deletetag.tag === 'undefined' || this.deletetag.tag === null){
        this.deletetag.tag = '';
      }
      params.deletetag = this.deletetag.tag.trim();
      CrmApi.call('/project/new/', {
        name: this.project.name,
        lang: this.project.language,
        urls: this.project.urls,
        greetingtemplate: this.project.greetingtemplate,
        greetingtemplate_en: this.project.greetingtemplate_en,
        emails: this.project.emails.filter(Boolean),
        billmanager: {
          providers: this.billmanager.providers.filter((elem)=>{
            return elem.type == 'project';
          }).map((elem)=>{
            return elem.id;
          }),
          departments: this.billmanager.providers.filter((elem)=>{
            return elem.type == 'department';
          }).map((elem)=>{
            return elem.id;
          }),
        },
        emailpatterns: this.project.emailpatterns,
        emailparams: this.project.emailparams,
        primarymail: this.project.primarymail,
        signature: this.project.signature,
        rocket: this.project.rocket,
        deletetag:params.deletetag,
        ipspam: this.ipspam,
      }).then((response) => {
        window.location.href = '/projects/';
      }).catch((error) => {
        this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        this.saving = false;
      });
    },

    getEmailparams(email) {
      if (typeof this.project.emailparams !== 'undefined' && typeof this.project.emailparams[email] !== 'undefined') {
        return {
          name: this.project.emailparams[email].name ?? '',
          smtp: window.getId(this.project.emailparams[email].smtp ?? ''),
          phone: this.project.emailparams[email].phone ?? '',
          additional_phones: this.project.emailparams[email]?.additional_phones ?? [],
          outphoneendpoint: this.project.emailparams[email]?.outphoneendpoint ?? null,
        };
      } else {
        return {name: '', smtp: '', phone: '', additional_phones:[], outphoneendpoint: null};
      }
    },

    getSignature: function(email) {
      if(typeof this.project.signature !== 'undefined' && typeof this.project.signature[email] !== 'undefined') {
        return {
          ru: this.project.signature[email].ru ?? '',
          en: this.project.signature[email].en ?? ''
        };
      } else {
        return {ru: '', en: ''};
      }
    },
  }
}
</script>

<style scoped>
.light {
  --popper-theme-background-color: #ffffff;
  --popper-theme-background-color-hover: #ffffff;
  --popper-theme-text-color: #333333;
  --popper-theme-border-width: 1px;
  --popper-theme-border-style: solid;
  --popper-theme-border-color: #eeeeee;
  --popper-theme-border-radius: 6px;
  --popper-theme-padding: 1em;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
.invalid-warning{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
}
.department-class{
  background-color:  #c158c1;
}
</style>