import {computed,  toRefs} from "vue";
import moment from "moment";


export default function getFields(props, context){
    const {offerEvent} = toRefs(props);

    const date = computed({
        get(){
            let date = '';
            if(offerEvent.value?.generalDate){
                date = moment(offerEvent.value?.generalDate).format("YYYY-MM-DD HH:mm")
            }
            return date
        },
        set(value){
            offerEvent.value.generalDate = value;
        }});

    const subnet = computed(()=>{
        return offerEvent.value?.subnet ?? '';
    });

    const object = computed(()=>{
        return offerEvent.value?.subject?.object ?? '';
    });
    const type = computed(()=>{
        return offerEvent.value?.subject?.type ?? '';
    });

    const changes = computed(()=>{
        return offerEvent.value?.changes ?? [];
    })

    const hrefSubnet = computed(()=>{
        return 'http://www2.whois7.ru/?q=' + encodeURIComponent(subnet.value) + "#whois";
    })

    const relation_path = computed(()=>{
      return   offerEvent.value?.relation_path ?? [];
    })


    return {date, subnet, object, type, changes , hrefSubnet, relation_path}
}