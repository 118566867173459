export const Phone = {
    getPrepareHandlers(){
        return [
            Phone.replaceEight,
            Phone.addPlus,

        ]
    },
    getCountryPatterns(){
        return [
            Phone.doPrettyRuNumber,
            Phone.doPrettyUsaNumber,
            Phone.doPrettyUkrNumber,
            Phone.doPrettyByNumber,
        ];
    },

    addPlus(phone){
        phone = ('' + phone).trimLeft();
        if(phone.charAt(0) !== '' && phone.charAt(0) !== '+'){
            phone = '+'+phone;
        }
        return phone;
    },

    /**
     * Возвращает преобразованный номер в человекочитаемый вид
     * @param {string} phone
     * @returns {*|string}
     */
    makePretty( phone ){
        let newPhone = phone;
        if (typeof phone !== 'undefined' && phone !== null) {
            for (let handler of Phone.getPrepareHandlers()) {
                if (handler) {
                    newPhone = handler(newPhone)
                }
            }
            for (let pattern of Phone.getCountryPatterns()) {
                if (pattern) {
                    newPhone = pattern(newPhone)
                }
            }
        } else {
            newPhone = null;
        }

        return newPhone;
    },

    replaceEight(phone){
        if((''+phone).charAt(0) === '8'){
            phone = (''+phone).replace('8', '+7')
        }
        return phone;
    },
    isPhoneRu(phone){
        return (''+phone).slice(0, 2) === '+7';
    },
    isPhoneUSA(phone){
        return (''+phone).slice(0, 2) === '+1';
    },
    isPhoneUKR(phone){
        return (''+phone).slice(0, 4) === '+380';
    },
    isPhoneBy(phone){
        return (''+phone).slice(0, 4) === '+375';
    },
    doPrettyRuNumber(phoneValue){
        let phone = (''+phoneValue).trim();
        if(phone.length === 12 && Phone.isPhoneRu(phone) && Phone.isNumber(phone.slice(1, phone.length))){
            let cleaned = ('' + phone).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                phone =  '+'+match[1]+' (' + match[2] + ') ' + match[3] + '-' + match[4] + '-'  + match[5] ;
            }
            phoneValue = phone;
        }
        return phoneValue;
    },
    doPrettyUsaNumber(phoneValue){
        let phone = (''+phoneValue).trim();
        if(phone.length === 12 && Phone.isPhoneUSA(phone) && Phone.isNumber(phone.slice(1, phone.length))){
            let cleaned = ('' + phone).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                phone =  '+'+match[1]+' (' + match[2] + ') ' + match[3] + '-' + match[4];
            }
            phoneValue = phone;
        }
        return phoneValue;
    },

    doPrettyUkrNumber(phoneValue){
        let phone = (''+phoneValue).trim();
        if(phone.length === 13 && Phone.isPhoneUKR(phone) && Phone.isNumber(phone.slice(1, phone.length))){
            let cleaned = ('' + phone).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                phone =  '+'+match[1]+' (' + match[2] + ') ' + match[3] + '-' + match[4] +'-' + match[5];
            }
            phoneValue = phone;
        }
        return phoneValue;
    },

    doPrettyByNumber(phoneValue){
        let phone = (''+phoneValue).trim();
        if(phone.length === 13 && Phone.isPhoneBy(phone) && Phone.isNumber(phone.slice(1, phone.length))){
            let cleaned = ('' + phone).replace(/\D/g, '');
            let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})(\d{2})(\d{2})$/);
            if (match) {
                phone =  '+'+match[1]+' (' + match[2] + ') ' + match[3] + '-' + match[4] +'-' + match[5];
            }
            phoneValue = phone;
        }
        return phoneValue;
    },

    isNumber(number){
        return  !isNaN(number);
    }
}