<template>
  <div style="font-size: 14px; position: relative">
    <div class="field" >
      <div><span class="" >Имя</span></div>
      <div v-if="!transferInfoLoading" ><span style=" display: flex;">
        <editable-text input-class="form-control form-control-sm"
                       string-style="word-break: break-word;"
                       :default-valid="true"
                       :default="'—'"
                       :disabled="isDisabled('name')"
                       :required="false"
                       :text="name"
                       :focus-out-cancel="false"
                       :callback="updateName" /></span>
      </div>
      <div v-else>
        {{download}}
      </div>
    </div>
    <div class="field" v-if="historyObject?.subnets?.[0]?.type === 'PA' || historyObject?.subnets?.[0]?.type === 'PI'  || lirid
 || historyObject?.asn?.name ">
      <div>
        <span  >LIRid</span></div>
      <div  v-if="!subnetPersonsLoading" >
        <span class="d-flex" >
          <editable-text
              :one-click="openLiridLink"
              :default="'—'"
              input-class="form-control form-control-sm"
              string-style="word-break: break-word;"
              :default-valid="true"
              :disabled="isDisabled('lirid')"
              :required="liridRequired"
              :validation="liridValidation"
              :text="lirid"
              :focus-out-cancel="false"
              :callback="updateLIRid"
              ref="liridField" />
        </span>
      </div>
      <div v-else>
        {{download}}
      </div>
    </div>
    <div class="field" v-if="historyObject?.subnets?.[0]?.type === 'PI' || org || historyObject?.asn?.name" >
      <div>ORG</div>
      <div v-if="!subnetPersonsLoading">
        <span class="d-flex" >
          <editable-text
              :one-click="openOrgLink"
              :default="'—'"
              input-class="form-control form-control-sm"
              string-style="word-break: break-word;"
              :default-valid="true"
              :disabled="isDisabled('org')"
              :required="false"
              :focus-out-cancel="false"
              :text="org"
              :callback="updateORG" />
        </span>
      </div>
      <div v-else>
        {{download}}
      </div>
    </div>

    <div class="field" >
      <div><span class="">Email</span></div>
      <div v-if="!transferInfoLoading">
        <editable-text
            :default="'—'"
            input-class="form-control form-control-sm"
            string-style="word-break: break-word;"
            :default-valid="true"
            :disabled="isDisabled('email')"
            :required="false"
            :focus-out-cancel="false"
            :text="email"
            :callback="updateMainEmail" />

        <div v-for="(contact_email, index) in contact_emails" >
          <additional-email
              :focus-out-cancel="false"
              :email="contact_email.mail"
              :type="contact_email.type"
              :index="index"
              :disabled="isDisabled('contact_email')"
              @editAdditionalEmail="updateAdditionalEmail" />
        </div>
      </div>
      <div v-else>
        {{download}}
      </div>
    </div>

    <!-- div class="field" >
      <div>Телефон:</div>
      <div v-if="!transferInfoLoading">
        <div v-for="(phone,index) in phones" >
          <additional-phone :phone-obj="phone" @editAdditionalPhone="editAdditionalPhone" :phone="phone.number" :type="phone.type" :index="index"></additional-phone>
        </div>
      </div>
      <div v-else>
        {{download}}
      </div>
    </div -->
    <div class="field">
      <div>Ссылка на тикет</div>
      <div>
        <editable-text
            input-class="form-control form-control-sm"
            :default-valid="true"
            :disabled="isDisabled('ticket')"
            :required="false"
            :text="ticket"
            default="—"
            :one-click="openTicket"
            :callback="updateTicketId" />
      </div>
    </div>

  </div>
</template>

<script>
import EditableText from "../../Editable/EditableText";
import AdditionalPhone from "./Source/AdditionalPhone";
import {toRefs} from "vue";

import {isId, parseTicketId} from "../../../library/ID";
import AdditionalEmail from "./Source/AdditionalEmail";
import getFields from "./computed";
import {mapGetters} from "vuex";
export default {
  name: "EditPerson",
  components: {AdditionalEmail, AdditionalPhone, EditableText},
  emits: [
    'update:modelValue',
    'editName',
    'editEmail',
    'editAdditionalEmail',
    'editAdditionalPhone',
    'editLIRid',
    'editORG',
  ],
  props:{
    'modelValue':{},
    'transferInfoLoading':{
      type:Boolean,
    },
    'subnetPersonsLoading':{
      type:Boolean,
    },
    disabled: {
      type: [Boolean, Object],
      default: false
    },
    type: String,
  },
  data:function (){
    return {
      oldValues:'',
    }
  },
  mounted() {

    this.oldValues = Object.assign({} , this.modelValue);
  },
  setup(props, context){
    const {modelValue} = toRefs(props);
    return  getFields({person:modelValue})
  },
  computed:{
    ...mapGetters({
      'historyObject':'offerHistory/addEdit/getHistoryObject',
    }),
    download:function (){
      return 'Загрузка...'
    },
    liridRequired() {
      let required = false;
      if(this.subnetType === 'PA') {
        if(this.type === 'buyer') {
          required = true;
        } else {
          required = _.isEmpty(this.historyObject?.seller?.org ?? null);
        }
      }
      return required;
    },
    subnetType() {
      return this.historyObject?.subnets?.[0]?.type;
    }

  },
  methods: {
    openTicket() {
      if (this.ticket && !this.isDisabled('ticket')) {
        window.open(window.location.origin + '/tickets/' + window.getId(this.ticket));
      }
    },
    isDisabled(fieldname) {
      return typeof this.disabled === 'boolean' ? this.disabled : (this.disabled[fieldname] ?? false);
    },
    liridValidation(val) {
      return this.liridRequired ? !_.isEmpty(val) : true;
    },
    openOrgLink:function (){
      if(this.org.trim() !== '' ){
        window.open(
            "https://www2.whois7.ru/?s=manual&q=-T+organisation+"+
            encodeURIComponent(this.org.trim())
            +"&w=whois.ripe.net#whois", "_blank");
      }
    },
    openLiridLink:function (){
      if(this.lirid.trim() !== '' ){
        let lirid = this.lirid.trim()
        let country = lirid.split('.');
        if(country?.[0]){
          let c = country[0]
          window.open(
              "https://www-public.tem-tsp.eu/~maigron/RIR_Stats/RIPE_Allocations/Allocs/"+
              encodeURIComponent(c.toUpperCase())
              +".html#"+encodeURIComponent(lirid),
              "_blank");
        }

      }
    },
    isHasChange:function (){
      let isHasChange = false;
      for (let el of ['email' , 'isour' , 'lirid' , 'name' , 'org' , 'ticket']){
        if(this.modelValue?.[el] !=  this.oldValues?.[el]){
          isHasChange = true;
          break;
        }
      }
      return isHasChange;
    },
    updateAdditionalEmail:function (object){
      this.modelValue.contact_emails[object.key] = {
        number:object.value,
        type:object.type
      }
    },
    editAdditionalPhone:function (object){
        if(!this.modelValue?.phone){
          this.modelValue.phone = [];
        }
        this.modelValue.phone[object.key] = {
          number:object.value,
          type:object.type
        }
    },
    updateMainEmail:function (email){
      this.modelValue.email = email
    },
    updateName:function (value){
      this.modelValue.name = value;

    },
    updateLIRid(value) {
      this.modelValue.lirid = value;
    },
    updateORG(value) {
      this.modelValue.org = value;
      this.$refs.liridField.checkValidation();
    },
    updateTicketId: function(id) {
      if(!isId(id) && parseTicketId(id)) {
        id = parseTicketId(id);
      }
      this.modelValue.ticket = id;
    },
  },

  watch: {
    subnetType(to) {
      if(to === 'PA' && this.liridRequired) {
        this.$nextTick(() => this.$refs.liridField.checkValidation());
      }
    },
  }
}
</script>

<style scoped>
.field {
  display: flex;
  align-items: baseline;
}
.field > div:nth-child(1) {
  width: 30%;
}
.field > div:nth-child(2) {
  width: 70%;
}
span.required:after
{
  color: red;
  content: "*";
}
.cursor-pointer{
  cursor: pointer;
}
</style>