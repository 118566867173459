<template>
    <div v-if="ticketsState.ticketSelectedControl.length" @click="clearSelect()" :style="closeSelect">&#10006;</div>
    <div v-if="getRoute?.params?.filter === 'unread' && ticketsState.ticketSelectedControl.length === 0 && ticketsState.ticketList.length > 0">
      <button class="btn btn-light btn-outline-dark col-12" @click="setAllTicketsRead"><i class="fa fa-eye button-icon"></i> Отметить как прочитанное</button>
    </div>
    <div v-show="ticketsState.ticketSelectedControl.length !== 0" >
      <div v-show="oneSelected" v-if="type === 'ticket'">
        <div  class="col-md-12 fw-bolder" style="text-align: center; line-height: 40px; background-color: #ececec;">Информация о запросе</div>
        <div class="meta-table">
          <table v-show="!loading" >
            <tr>
              <td>Тема:</td>
              <td><span class="d-flex"><string-trim :string="metadata.name" :clipboard="true" /></span></td>
            </tr>
            <tr v-if="oneSelectedTicket?.assigned_product?.name">
              <td>Услуга:</td>
              <td><span class="d-flex"><string-trim :string="oneSelectedTicket.assigned_product.name" /></span></td>
            </tr>
            <tr v-if="oneSelectedTicket?.assigned_product?.current_step?.name">
              <td>Шаг:</td>
              <td><span class="d-flex"><string-trim :string="oneSelectedTicket.assigned_product.current_step.name" /></span></td>
            </tr>
            <tr v-if="metadata.client">
              <td>Клиент:</td>
              <td><span class="d-flex"><string-trim :string="metadata.client" :clipboard="true" popper-placement="bottom" /></span></td>
            </tr>
            <tr v-if="metadata.created">
              <td>Создан:</td>
              <td>{{createdDate}}</td>
            </tr>
            <tr v-if="metadata.updated">
              <td>Изменен:</td>
              <td>{{updatedDate}}</td>
            </tr>
            <tr v-if="metadata.countAnswer">
              <td>Сообщений:</td>
              <td>{{metadata.countAnswer}}</td>
            </tr>
            <tr v-if="metadata.lastOperator">
              <td>Отвечал:</td>
              <td><span class="d-flex"><string-trim :string="metadata.lastOperator" /></span></td>
            </tr>
            <tr v-if="projects.length">
              <td v-if="projects.length === 1">Проект:</td>
              <td v-if="projects.length > 1">Проекты:</td>
              <td>
                <projects
                  :projects="projects"
                  type="list"
                ></projects>
              </td>
            </tr>
            <tr v-if="typeof oneSelectedTicket?.tags === 'object' && Array.isArray(oneSelectedTicket?.tags) && oneSelectedTicket?.tags.length > 0">
              <td>Метки:</td>
              <td>
                <tags
                  :tags="oneSelectedTicket.tags"
                  :ticket="oneSelectedTicket"
                  class="projectPaddingsOne"
                  type="list"
                ></tags>
              </td>
            </tr>
            <tr v-if="oneSelectedTicket?.domains">
              <td>Домен(ы):</td>
              <td>
                <span v-for="domainInfo in oneSelectedTicket?.domains" class="badge bg-secondary">{{domainInfo.name}}</span>
              </td>
            </tr>
          </table>
          <div  v-show="loading" class="centerPlace" >Loading...</div>
        </div>
      </div>
      <div style="margin: 10px 0;"  class="centerPlace" v-show="ticketsState.ticketSelectedControl.length > 1" >
        <span >Выбрано {{ticketsState.ticketSelectedControl.length}} запроса (ов).</span>
      </div>
      <div style="margin-top: 20px;" v-if="type === 'ticket'"></div>
      <div v-if="type === 'ticket'">
        <buttons :id-list="getSelectedArray"></buttons>
      </div>
      <div v-else-if="type === 'notification'">
        <button class="btn btn-light btn-outline-dark col-12" @click="openNotification" v-if="ticketsState.ticketSelectedControl.length === 1"><i class="fa fa-folder-open button-icon"></i> Открыть</button>
        <button class="btn btn-light btn-outline-dark col-12" :disabled="isAllNotificationRead" @click="notificationWatched"><i class="fa fa-eye button-icon"></i> Прочитано</button>
        <button class="btn btn-light btn-outline-dark col-12" :disabled="isAllNotificationUnRead" @click="notifitionUnWatched"><i class="fa fa-eye-slash  button-icon menu-button"></i>Не прочитано</button>
        <convert-notification-button v-if="userCan.convertNotification" />
        <delete-notification-button
            :disabled="!userCan.deleteNotification"
            v-if="userCan.deleteNotification || userCan.deleteAnyNotification"
        ></delete-notification-button>
      </div>
    </div>

</template>

<script>
import {CrmApi} from "../../library/CrmApi";
import Buttons from "./components/Buttons";
import ProjectLine from "../ticket/List/ProjectLine";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {getDateFormat, isNotificationRead} from "../ticket/Api/api";
import {setAllRead} from "../../store/main/tickets/actions";
import StringTrim from "../string/StringTrim";
import DeleteNotificationButton from "./components/DeleteNotificationButton";
import {UserPermission} from "../../users/UserPermission";
import Tags from "./components/Params/Tags";
import ConvertNotificationButton from "./components/ConvertNotificationButton.vue";
import Projects from "./components/Params/Projects";

export default {
  name: "Menu",
  components: {
    ConvertNotificationButton,
    ProjectLine,
    Buttons,
    StringTrim,
    DeleteNotificationButton,
    Tags,
    Projects,
  },

  props:{
    "ticketSelectedControl":{
      require:true,
    }
  },
  data: function (){
    return {
      metadata:{},
      projects: [],
      loadingStyle:{
       'text-align': 'center',
      },
      lastLoadingId:null,
      type: null,
      loading:true,
      timer:null,
      userCan: {
        convertNotification: false,
        deleteNotification: false,
        deleteAnyNotification: false,
      },
    }
  },
  methods: {
    checkPermission: function () {
      let deletePermissions = [];
      let convertPermissions = [];

      this.$store.getters["tickets/getSelectedArray"].forEach((object) => {
        let ticket = this.$store.getters["tickets/getSelectedObjects"][object];
        if (ticket.type === 'notification') {
          let projectName = this.$store.getters["tickets/getSelectedObjects"][object].ticket?.project?.name

          let deletePermission = `${projectName}.notification_delete`;
          if (deletePermissions.indexOf(deletePermission) === -1) {
            deletePermissions.push(deletePermission);
          }

          let convertPermission = `${projectName}.notifications_convert`;
          if (convertPermissions.indexOf(convertPermission) === -1) {
            convertPermissions.push(convertPermission);
          }
        }
      });
      // preload
      UserPermission.can(deletePermissions).catch(() => {});
      UserPermission.can(convertPermissions).catch(() => {});

      this.userCan.convertNotification = false;
      this.userCan.deleteNotification = false;
      this.userCan.deleteAnyNotification = false;

      UserPermission.can(deletePermissions)
                    .then(() => this.userCan.deleteNotification = true)
                    .catch(() => {});
      UserPermission.can(convertPermissions)
                    .then(() => this.userCan.convertNotification = true)
                    .catch(() => {});

      Promise.any(
          deletePermissions.map((permission) => UserPermission.can(permission))
      ).then(() => {
        this.userCan.deleteAnyNotification = true;
      }).catch(() => {
      });
    },

    notificationWatched: function() {
      this.setNotificationWatched({
        ticketIds: this.getSelectedArray,
        route: this.$route,
      }).then(() => {
        this.$store.state.tickets.resetCounter = true;
        this.$store.commit('tickets/toLastSelect', false);
        this.redirectToTickets();
        this.$store.commit('successPush', "Тикет помечен как непрочитанный");
      });
    },

    notifitionUnWatched() {
      this.setNotificationUnWatched({
        ticketIds: this.getSelectedArray,
        route: this.$route,
      })
    },

    openNotification: function() {
      this.$router.push({name: 'Notification',  params: { id: this.getSelectedArray[0] } });
    },
    setAllTicketsRead: function () {
      this.setAllRead({
        project: this.getRoute?.params?.project ?? 'all',
        route: this.$route,
      });
    },
    ...mapMutations({
      'clearSelect':'tickets/clearSelect',
    }),
    ...mapActions('tickets' ,[
      'getAllTickets',
      'setNotificationWatched',
      'setNotificationUnWatched',
      "setAllRead",
    ]),
  },
  mounted() {
    this.checkPermission();
  },
  watch:{
    'ticketsState.ticketSelectedControl': {
      handler(val){
           if(val.length === 1){

             let first = Object.values(val.objects)[0];

             let projects = [first.project.name];
             if(typeof first.assigned_projects && Array.isArray(first.assigned_projects)) {
               projects = projects.concat( first.assigned_projects.map(project => project.name) );
             }
             this.projects = projects;

             if(
                 typeof first.type !== 'undefined' && first.type === 'notification' ||
                 typeof first.notification !== 'undefined' && first.notification
             ) {
               this.type = 'notification';
               this.loading = false;
               this.lastLoadingId = null;
             } else {
               this.type = 'ticket';
               let id = this.getSelectedArray[0];

               if(this.timer !== null){
                 clearTimeout(this.timer);
               }
               if(this.lastLoadingId !== id){
                 this.loading = true;
               }

               this.timer = setTimeout(() => {
                 if(this.getSelectedArray.length === 1 && id === this.getSelectedArray[0]){
                   CrmApi.getThreadMetaData(id).then( (result) =>{
                     if (
                         this.ticketsState.ticketSelectedControl.length === 1 &&
                         this.getSelectedArray[0] === id &&
                         typeof result.data === 'object'
                     ) {
                       this.metadata = result.data;
                       this.lastLoadingId = id;
                     }
                   }).finally( () => {
                     if (
                         this.ticketsState.ticketSelectedControl.length === 1 &&
                         this.getSelectedArray[0] === id
                     ) {

                       this.loading = false;
                     }
                   });
                 }

               } , 400)

             }
           }else {
             this.loading = false;
             this.lastLoadingId = null;
           }
      },
      deep: true
    },
    'getSelectedArray'() {
      this.checkPermission();
    }
  },
  computed:{
    oneSelected() {
      return this.ticketsState.ticketSelectedControl.length === 1;
    },
    oneSelectedTicket() {
      if(this.oneSelected) {
        return Object.entries(this.selectedObjects)[0]?.[1] ?? null;
      }
    },
    createdDate: function (){

      return getDateFormat(this.metadata.created ,"YYYY-MM-DD HH:mm" );
    },
    updatedDate: function (){
      return getDateFormat(this.metadata.updated ,"YYYY-MM-DD HH:mm" );
    },
    isAllNotificationRead() {
      let result = true;
      if (this.ticketsState.ticketSelectedControl.length) {
        for (let key in this.selectedObjects) {
          let ticket = this.selectedObjects[key];
          if (!isNotificationRead(ticket, window.userId)) {
            result = false;
            break;
          }
        }
      }
      return result;
    },
    isAllNotificationUnRead() {
      let result = true;
      if (this.ticketsState.ticketSelectedControl.length) {
        for (let key in this.selectedObjects) {
          let ticket = this.selectedObjects[key];
          if (isNotificationRead(ticket, window.userId)) {
            result = false;
            break;
          }
        }
      }
      return result;
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getSelectedArray": "tickets/getSelectedArray",
      selectedObjects: 'tickets/getSelectedObjects',
      "getRoute": "getRoute",
    }),
    closeSelect:function (){
      let styles = {
        cursor: 'pointer',
        position: 'absolute',
        'font-size':'14px',
        'top':'3px',
        'left':'2px',
      };
      if(this.type !== 'ticket'){
        styles.position = 'relative';
        styles.display = 'inline-flex'
      }
      return styles;
    }
  },
}
</script>

<style scoped>
.centerPlace{
  text-align: center;
  vertical-align: center;
}

.meta-table {
  min-height: 180px;
  margin-top: 10px;
}
.meta-table > table > tr > td:first-child {
  padding-left: 3px;
  font-weight: bold;
}

.project-line {
  padding-right: 2px;
}
</style>