import {CrmApi} from "../../../library/CrmApi";
import offerStore from "../../../store/main/offer";

export default function updateFields( offer, fields, afterSave, failSave) {
    Object.entries(fields).forEach(field => {
        let [name, value] = field;

        if(typeof value === 'undefined') {
            fields[name] = null;
        }
        if(typeof value === 'string') {
            fields[name] = value.trim();
        }
        if(offer?.assigned_product?.fields?.[name] === value) {
            delete fields[name];
        }
    });

    if(Object.keys(fields).length) {
        return CrmApi.updateThreadParams(offer._id, {product_fields: fields}).then(response => {
            offer.assigned_product.fields = { ...offer.assigned_product.fields,
                                              ...response.data.fields };
            if(typeof afterSave === 'function') {
                afterSave();
            }
        }).catch(error => {
            if(typeof failSave === 'function') {
                failSave(error);
            } else {
                if(typeof error?.response?.data?.html !== 'undefined' && error.response.data.html === true) {
                    offerStore.commit('htmlErrorPush', error.response.data.message);
                } else {
                    offerStore.commit('errorPush', CrmApi.getErrorMessage(error));
                }
            }
        });
    } else {
        if(typeof afterSave === 'function') {
            afterSave();
        }
        return new Promise((resolve, reject) => { resolve(); });
    }
}
