import {getName, getUserNameOrId} from "../../../library/userName";

const offerHistoryGetters = {
    getFilters:(state  , getters , rootState , rootGetter) =>{
        return rootGetter.getRoute.query;
    },

    getOffersHistoryList: (state, commit, dispatch, getters, rootGetters, rootState) => {
        return state.offersHistory.list;
    },
    getLoading:(state)=>{
        return state.loading;
    },
    getPaginate:(state) => {
        return state.paginator;
    },

    getTotal:(state)=>{
       return state.paginator.total
    },

    getGroups:(state) =>  {
        return state.groups
    },

    getPerson: (state, getters, rootGetters, rootState) => (id)=>{
        let person;
        if(typeof state.persons[id] === 'undefined' ){
            person = {
                isLoad:false,
                loading:false,
                object:null,
            }
            state.persons[id] = person;
        }
        return state.persons[id];
    },

    isEditClientOpen:(state)=>{
        return state.isEditClientOpen;
    },

    getContextMenuParams:(state)=>{
      return state.contextMenuParams
    },
    getUsers:(state)=>{
        return state.users;
    },
    getUsersForSelect: (state) => {
        let names = [];
        for (let el of state.users) {
            names.push({
                name: getUserNameOrId(el),
                value: window.getId(el._id)
            });
        }
        names = names.sort((a, b) => {
            if (a.name === '—') {
                return -1;
            }
            return a.name.localeCompare(b.name);
        });
        names.unshift({
            name: '—',
            value: '',
        });
        return names;
    },

    getLoginsForSelect: (state) => {
        let names = [];
        for (let el of state.users) {
            names.push({
                name: el?.login ?? window.getId(el._id),
                value: window.getId(el._id)
            })
        }
        names = names.sort((a, b) => {
            if (a.name === '—') {
                return -1;
            }
            return a.name.localeCompare(b.name);
        });
        names.unshift({
            name: '—',
            value: '',
        });
        return names;
    },

    canChange:(state)=>{
      return state.canChange;
    },

    isEditModuleOpen:(state)=>{
        return state.showEditModal;
    },
    getPopperClientOpen:(state)=>{
      return state.popperClientOpen
    },
    getCurrentCurrency:(state)=>{
        return state.currentCurrency;
    },

    getFullBuyPriceUsd:(state) => {
        return state.fullBuyPriceUsd
    },
    getFullSellPriceUsd:(state) => {
        return state.fullSellPriceUsd
    },

    getFullBuyPriceRu:(state) => {
        return state.fullBuyPriceRu
    },
    getFullSellPriceEu:(state) => {
        return state.fullSellPriceEu
    },
    getFullBuyPriceEu:(state) => {
        return state.fullBuyPriceEu
    },
    getFullSellPriceRu:(state) => {
        return state.fullSellPriceRu
    },
    getEditObjectId:(state) =>{
        return state.editObjectId;
    },
    getNeedChangeRoute:(state) => {
        return state.needChangeRoute;
    },
    getOpenEdit:(state)=>{
      return state.openEdit;
    },
    getRates:(state)=>{
        return state.rates;
    },
    getCountIP:(state)=>{
        return state.countIP
    },

    isShowContextMenu:(state) =>{
        return state.showContextMenu;
    },

    getCountAs:(state)=>{
        return state.countas
    },
    getShowDeleteModal:(state)=>{
        return state.showDeleteModal;
    },
    getAdditionalCounts:(state)=>{
        return state.additionalCounts;
    },
    getSellManagerCollapsed:(state)=>{
        return state.sellManagerCollapsed;
    },
    getBuyManagerCollapsed:(state)=>{
        return state.buyManagerCollapsed;
    },
    getUserHistorySettings(state, getters, rootState, rootGetters){
        return rootGetters['getUserSettings']?.history  ?? {};
    },
    getSearchMeta: (state) => {
        return state.searchMeta;
    },
}


export default offerHistoryGetters;