import {getUniqueId} from "../../../library/ID";

class Keys {

    static keyMapAllies = {
        'alt':'altKey',
        'ctrl':'ctrlKey',
        'shift':'shiftKey',
        'meta':'metaKey',
    }
    static keyMap = {
        'altKey':true,
        'ctrlKey':true,
        'shiftKey':true,
        'metaKey':true,
    }
    

    constructor(keyCode) {
        this.keyCode = keyCode;
        this.eventsArrayOrder = [];
        this.eventsObject = {};
        this.metaKeyEvent = null;
        this.downMeta = false
    }

    onEvent = function (event) {

        this.downMeta = false;
        if (event.keyCode === this.keyCode ) {

            if( this.eventsArrayOrder.length > 0){

                let elem = this.eventsArrayOrder[this.eventsArrayOrder.length - 1];

                elem = this.getAppropriateEvent(event, elem.element )

                if(elem !== null && elem.value){
                    if(elem.modifiers?.targetBody){
                        if(!$(event.target).is('body') ||  !$(event.delegateTarget.activeElement).is('body')){
                            return;
                        }
                    }

                    elem.value.call(elem.element , event);
                }

            }
        }

    }


    downEven = function (event){
        if (event.keyCode === this.keyCode && event.metaKey ) {
            if( this.eventsArrayOrder.length > 0){
                let elem = this.eventsArrayOrder[this.eventsArrayOrder.length - 1];
                elem = this.getAppropriateEvent(event, elem.element )

                if(!this.downMeta &&  elem !== null && elem.value){

                    if(elem.modifiers?.targetBody){

                        if(!$(event.target).is('body') ||  !$(event.delegateTarget.activeElement).is('body')){

                            return;
                        }
                    }

                    this.downMeta = true;
                    elem.value.call(elem.element , event);
                }

            }
        }
    }

    getAppropriateEvent(event , el){
        let elId = $(el).attr("common-event-id");
        let events = this.eventsObject[elId];
        let appropriateEvent =null;
        for (let e of events){
            let isRespond = true;
            let trueModifiers = {};
            for (let modifier in e.modifiers){
                if(e.modifiers[modifier]){
                    let alias = getModifierAlias(modifier);
                    trueModifiers[alias] = true;
                    if(typeof event[alias] !== 'undefined' && !event[alias]){
                        isRespond = false;
                        break;
                    }
                }
            }
            if(isRespond){
                for (let key in Keys.keyMap){
                    if(typeof trueModifiers[key] === 'undefined' && event[key]){
                        isRespond = false;
                        break;
                    }
                }
                if(isRespond){
                    appropriateEvent = e;
                    break;
                }
            }
        }

        return appropriateEvent;
    }

    update(el, binding){

        let windowKey;

        if(typeof $(el).attr("window-event-id") === 'undefined' || $(el).attr("window-event-id") == null ){
            windowKey = getUniqueId(true);
            $(el).attr("window-event-id" , windowKey);
        }
        windowKey = $(el).attr("window-event-id");
        let elId;
        if(typeof binding.arg !== 'undefined'){
            elId = binding.arg;
        }else {
            elId = windowKey;
        }
        if(typeof $(el).attr("common-event-id") === 'undefined' || $(el).attr("common-event-id") == null ){
            $(el).attr("common-event-id" , elId);
        }
        elId = $(el).attr("common-event-id");



        if (typeof binding.value !== 'function') {
            throw new Error('Argument must be a function')
        }
        let o = {
            element:el,
            value:binding.value,
            modifiers:binding.modifiers
        };
        this.eventsArrayOrder.push(
            o
        );
        if(typeof this.eventsObject[elId] === 'undefined'){
            this.eventsObject[elId] = []
        }
        this.eventsObject[elId].push(o);
        this.eventsObject[elId] = sortByModifiersCount(this.eventsObject[elId]);

    }

    unbind = function (el, binding) {
        let tmp = [];
        let elId = $(el).attr("common-event-id");
        let windowKey = $(el).attr("window-event-id");
        if(typeof elId !== 'undefined' && typeof this.eventsObject[elId] !== 'undefined'){
            let ev = [];
            for (let elem of  this.eventsObject[elId]){
                let w = $(elem.element).attr("window-event-id");
                if(w !== windowKey){
                    ev.push(elem);
                }
            }
            if(ev.length > 0){
                this.eventsObject[elId] = ev;
            }else {
                delete   this.eventsObject[elId];
            }

        }
        for (let elem of this.eventsArrayOrder ){
            let eid = $(elem.element).attr("window-event-id");
            if(eid != elId){
                tmp.push(elem);
            }
        }

        this.eventsArrayOrder = tmp;
    }

}
export default Keys

function sortByModifiersCount(array){
   array.sort(function(a, b) {
       let countA = Object.keys(a.modifiers).length;
       let countB = Object.keys(b.modifiers).length;
       if (countA < countB) {
           return 1;
       }
       if (countA > countB) {
           return -1;
       }

       return 0;
   });
   return array;
}


function getModifierAlias(mod){
    let alias = mod;
    if(typeof Keys.keyMapAllies[mod] !== 'undefined'){
        alias = Keys.keyMapAllies[mod];
    }
    return alias;
}
