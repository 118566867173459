<template>
  <div class="alert text-center px-0" style="background-color: aliceblue" :class="alert.alertStyle" @mouseenter="stopTimer" @mouseleave="startTimer" v-if="alert.show">
    <div style="width: 90%;display: inline-block;">
      <span v-if="alert.ashtml" v-html="text"></span>
      <span v-else>
        <span class="d-block" v-if="Array.isArray(text)" v-for="line in text">{{line}}</span>
        <template v-else>
          <template v-if="description">
            <span class="description-btn" @click="showDescription = !showDescription">{{text}}</span>
            <code v-if="showDescription" class="d-block">{{description}}</code>
          </template>
          <template v-else>{{text}}</template>
        </template>
      </span>
    </div>
    <div style="position: absolute;right: 10px;cursor: pointer;font-size: 23px;top: 0px;">
      <span class="close" data-dismiss="alert" @click="errorRemove()" >&times;</span>
    </div>
    <div v-if="!withoutTimer" class="timer" :style="{width: alert.timer_percent + '%'}"></div>
  </div>
</template>

<script>

export default {
  name: "Alert",

  props: {
    alert: {
      type: Object,
      require: true
    },
    withoutTimer:{
      type:Boolean,
      default:false,
    }
  },

  data: function() {
    return {
      interval: null,
      showDescription: false,
    }
  },

  mounted: function() {
    if(!this.withoutTimer){
      this.alert.timer_percent = 100;
      this.startTimer();
    }

  },

  computed: {
    text: function() {
      return this.alert.text.text ?? this.alert.text;
    },
    description: function() {
      return this.alert.text.description ?? null;
    }
  },

  methods:{
    errorRemove:function () {
      this.$store.commit('errorRemove', this.alert.id);
    },

    stopTimer: function() {
      this.alert.timer_percent = 100;
      clearInterval(this.interval);
    },

    startTimer: function() {
      this.interval = setInterval(() => {
        this.alert.timer_percent = this.alert.timer_percent - 1;

        if(this.alert.timer_percent === 0) {
          clearInterval(this.interval);
          this.errorRemove();
        }
      }, this.alert.timer / 100);
    }
  },
}
</script>

<style scoped>
.timer {
  height: 3px;
  background-color: black;
  position: relative;
  bottom: -14px;
  transition: width 0.1s ease-in-out;
}
.text-danger .timer {
  background-color: red;
}
.text-success .timer {
  background-color: green;
}
.description-btn {
  border-bottom: 1px dashed #858585;
  cursor: pointer;
}
</style>