import {CrmApi} from "../../../library/CrmApi";
import {prepareStep} from "./commonFunctions";

export const setProjects = ({state , commit} ,payload ) => {
    state.projects.loading = true;
    CrmApi.getProjectsList({}).then(( result )=>{
        commit('setProjects' ,result.data );
    }).catch((error)=>{
        commit('errorPush' , CrmApi.getErrorMessage(error) , true)
    }).finally(()=>{
        state.projects.loading = false;
    })
}