<template>
  <button class="btn btn-light btn-outline-dark col-12" style="right: 0.5em;" @click="attachmentsFilter = !attachmentsFilter">
    <i class="fa fa-paperclip"></i>
    <span v-if="!attachmentsFilter"> Вложения</span>
    <span v-else> Вся переписка</span>
  </button>
</template>

<script>
export default {
  name: "AttachmentsFilter",

  computed: {
    attachmentsFilter: {
      get() { return this.$store.getters['thread/attachmentsFilter']; },
      set(value) { this.$store.commit('thread/setAttachmentsFilter', value); }
    },
  },
}
</script>

<style scoped>

</style>