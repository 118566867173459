import {isNeedSearchAlias} from "./actions";

function needToshow(object , route) {
    let show = true;
    if(typeof object.show !== 'undefined'){
        return  object.show;
    }
    if(object.id == 'recipient' && route.name !== 'Unsorted'){
        show= false;
    }
    if(object.id == 'project' && route.params.project !== 'all'){
        show = false;
    }
    if(object.id === 'withAttachment' && route.name === 'Calls'){
        show = false;
    }
    if (object.id === 'unread' && !(
            ['Unsorted', 'Competitor', 'Trash', 'Recent'].includes(route.name) ||
            ['new', 'unconfirmed', 'answered', 'closed', 'favorite'].includes(route.params.filter)
        )
    ) {
        show = false;
    }
    if (object.id === 'new' && route?.params?.filter !== 'new') {
        show = false;
    }
    return show;
}


function needToShowTableFilters(object , route){
    let show = true;
    if(object.id == 'recipient' && route.name !== 'Unsorted'){
        show= false;
    }
    if(object.id == 'project' && route.params.project !== 'all'){
        show = false;
    }


    return show;
}
const getters = {


    getType:(state, getters, rootState, rootGetters)=>{
        return state.type;
    },

    getTableFilters:(state,  getters , rootState, rootGetters) =>{
        if(rootGetters.getRoute.name === 'Calls'){
            return state.callsTableFilters;
        }
        if(rootGetters.getRoute.name == 'Tasks'){
            return  state.tasksTableFilters;
        }
        return state.tableFilters;
    },

    getTableFilterToShow:(state  , getters , rootState) =>{
        let tableFilters = [];

        let filters = (router.currentRoute.value.name === 'Billmanager')
                            ? state.billmanagerTableFilters : getters.getTableFilters;

        for (let el of filters){
            if(needToShowTableFilters(el , rootState.route )){
                tableFilters.push(el);
            }
        }
        return tableFilters;
    },

    isSettingShow:(state)=>{
        return state.isSettingShow;
    },

    isSearchSettingsInDefault:(state, getters) => {
      let  isSearchSettingsInDefault = true;

      if(state.search.searchString !== ''){
          if(state.alias !== 'all'){
              isSearchSettingsInDefault = false;
          }
          for (let el of getters.getSettingsToShow){
              if(getters.getSearchDefaultValues[el.field] !== el.value){
                  isSearchSettingsInDefault = false
                  break;
              }
          }
      }else {
          for (let el of getters.getSettingsToShow){
              if(el?.reloadWhenSearchStringEmpty && getters.getSearchDefaultValues[el.field] !== el.value){
                  isSearchSettingsInDefault = false
                  break;
              }
          }
      }



      return isSearchSettingsInDefault;
    },

    getAlias:(state) =>{
        return state.alias;
    },

    getProduct: (state) => {
        return state.product;
    },

    getSettingsParams:(state, getters , rootState , rootGetters)=> {
        if(rootGetters.getRoute.name === 'Calls'){
            return  state.callSettings;
        }
        if(rootGetters.getRoute.name === 'Tasks'){
            return state.tasksSettings;
        }
        if (rootGetters.getRoute.name === 'Competitor') {
            return state.competitorSettings;
        }
        return state.settings;
    },

    getSidebarFilters:(state) => {
        return state.sidebar.filters;
    },
    getSettingsToShow:(state , getters , rootState )=>{
        let settingsToShow = [];
        for(let el of getters.getSettingsParams){
            if (rootState.route?.name === 'Ticket' || needToshow(el , rootState.route)){
                settingsToShow.push(el);
            }
        }
        return settingsToShow;
    },
    getBillmanagerSettingsToShow: (state, getters, rootState) => {
        let settingsToShow = [];
        for(let el of state.billmanagerSettings){
            if (rootState.route?.name === 'Ticket' || needToshow(el , rootState.route)){
                settingsToShow.push(el);
            }
        }
        return settingsToShow;
    },

    getSearchDefaultValues: (state, getters, rootState, rootGetters) => {
        if (rootGetters.getRoute?.name === 'Calls') {
            return state.callDefaultValues;
        }
        if (rootGetters.getRoute?.name === 'Tasks') {
            return state.tasksDefaultValues;
        }
        return state.defaultValues;
    },

    getSearchSettingsToShow(state , getters , rootState){
        let settingsToShow = [];
        for(let el of getters.getSettingsToShow){
            if(!el.additionalFilter){
                settingsToShow.push(el)
            }
        }
        return settingsToShow;
    },
    getAdditionalSearchSettingsToShow(state , getters , rootState){
        let settingsToShow = [];
        for(let el of getters.getSettingsToShow){
            if(el.additionalFilter){
                settingsToShow.push(el)
            }
        }
        return settingsToShow;
    },

    getSearchParamsString:(state, getters) =>{
        let obj = {};
        for (let el of getters.getSettingsToShow){
            if(el.value !== getters.getSearchDefaultValues[el.field]){
                obj[el.field] = el.value;
            }
        }
        return JSON.stringify(obj);
    },

    getBillmanagerSearchParamsString: (state, getters) => {
        let obj = {};
        for (let el of getters.getBillmanagerSettingsToShow){
            if(el.value !== getters.getSearchDefaultValues[el.field]){
                obj[el.field] = el.value;
            }
        }
        return JSON.stringify(obj);
    },

    getFiltersQueryWithDifferentSearchAliasSubstitution:(state, getters,rootState, rootGetters) => {
        let query = {};
        let needSearchAliasCheck = false;
        if (getters.getSearchString !== '') {
            query.search = getters.getSearchString;
            needSearchAliasCheck = true;
        }

        let searchParams = getters.getSearchParamsString;
        if (searchParams !== '{}') {
            query.searchParams = searchParams;
        }

        let searchProduct = getters.getProduct;
        if (searchProduct !== null) {
            query.searchProduct = searchProduct;
            needSearchAliasCheck = true;
        }

        let searchUser = getters.getUser;
        if (searchUser !== null) {
            query.searchUser = searchUser;
            needSearchAliasCheck = true;
        }

        let ticketStatus = getters.getTicketStatus;
        if (ticketStatus !== null) {
            query.ticketStatus = ticketStatus;
            needSearchAliasCheck = true;
        }

        if (needSearchAliasCheck || getters.getAliasSet) {
            if (isNeedSearchAlias(rootGetters.getRoute)) {
                query.searchAlias = state.alias;
            }
        }

        let oB = getters.getOrderBy;
        if(oB !== null) {
            query.oB = oB;
        }

        return query;
    },

    getFiltersQuery:(state, getters,rootState, rootGetters) => {
        let query = getters.getFiltersQueryWithDifferentSearchAliasSubstitution;

        if (isNeedSearchAlias(rootGetters.getRoute)) {
            query = Object.assign({}, query, {searchAlias: state.alias});
        }

        return query;
    },
    getFullQuery:(state, getters,rootState, rootGetters) =>{
        let q = Object.assign({}, rootGetters.getRoute.query);
        let q2 = Object.assign({} , getters.getFiltersQuery);
        return Object.assign(q , q2);
    },

    getOrderBy: (state) => {
        return state.orderBy;
    },

    getSearch: ( state ) => {
        return state.search;
    },

    showFilter: (state) =>{
        return state.search.showFilter;
    },

    getSearchString: (state) =>{
        return state.search.searchString;
    },

    getProducts: (state) => {
        return state.search.products;
    },

    getUsers: (state) => {
        return state.search.users;
    },

    getUsersRequested: (state) => {
        return state.usersRequested;
    },

    getUser: (state) => {
        return state.user;
    },

    getTicketStatus: (state) => {
        return state.ticketStatus;
    },

    getSearchProject: (state) => {
        return state.searchProject;
    },

    getAliasSet: (state) => {
        return state.aliasSet;
    },

    getCallType:(state) =>{
        return state.callType;
    },

    getCallStatus:(state) =>{
        return state.callStatus
    }
}


export default getters;