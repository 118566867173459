<template>
  <div v-if="canDelete">
    <button :disabled="isDisabled"  v-backspace.ctrl="hotkeyModalDelete" v-backspace.meta="hotkeyModalDelete" v-delete.meta="hotkeyModalDelete" v-delete.ctrl="hotkeyModalDelete" :class="{'text-muted':isDisabled, 'custom-disabled':isDisabled}" @click="showModalDelete" class="btn btn-light btn-outline-dark col-12"><i class="fa fa-trash-o button-icon menu-button"></i>Удалить</button>
    <teleport to="body">
      <transition name="modal">
        <modal-window v-if="showModal" @close="closeModal" @success="removeTickets()" ok-button-style="btn-danger" >
          <template v-slot:header>
            <h5 class="modal-title text-danger" >Удаление тикета</h5>
          </template>
          <template v-slot:body>
            <span>{{getBody}}</span>
          </template>
          <template v-slot:footer>
            <button type="button" class="btn btn-secondary"  @click="closeModal">Отмена</button>
            <button type="button" class="btn btn-danger"   @click="removeTickets()">БЕЗВОЗВРАТНО УДАЛИТЬ</button>
          </template>
        </modal-window>
      </transition>
    </teleport>
  </div>
</template>

<script>
import ModalWindow from "./ModalWindow";
import {mapGetters, mapState} from "vuex";
import {UserPermission} from "../../../users/UserPermission";
import {replaceTextObjects} from "../../ticket/Api/api";


export default {
  name: "RemoveButton",
  components: {"modal-window":ModalWindow},
  data: function (){
    return {
      showModal:false,
      canDelete:false,
      checked: false,

      hotkeyForWindows: ['ticket', 'ticketList'],
    }
  },
  props:{
    callback: {
      default: null,
    },
    queue: {
      default: null,
    }
  },
  watch: {
    '$store.state.tickets.ticketSelectedControl': function(){
      this.checkPermission();
    }
  },

  mounted: function() {
    if(!this.checked && this.$store.state.tickets.ticketSelectedControl.length) {
      this.checkPermission();
    }
  },

  methods:{
    hotkeyModalDelete() {
      if(this.hotkeyForWindows.includes(this.$store.getters['getActiveWindow'])) {
        this.showModalDelete();
      }
    },
    showModalDelete() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'ticketRemove');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'ticketRemove');
    },
    removeTickets:function(){
      if(this.isDisabled){
        return;
      }
      let action = () => {
        this.closeModal();
        this.$store.dispatch('tickets/removeTickets', {
          ticketIds: this.getSelectedArray,
          route: this.callback ?? this.$route,
        });
      }
      if(this.queue === null) {
        action();
      } else {
        this.queue(action);
      }
    },


    checkPermission: function (){
      let permissions = [];

      let _this = this;
      this.getSelectedArray.forEach((object)=>{
        let val;
        if(_.isObject(this.getSelectedObjects[object].project)) {
          val = this.getSelectedObjects[object].project.name + ".ticket_delete";
        } else {
          val = "system.delete_pending";
        }

        if( permissions.indexOf(val) === -1 ) {
          permissions.push(val);
        }
      });

      _this.canDelete = false;

      UserPermission.can(permissions).then(()=>{
        _this.canDelete = true;
        _this.checked = true;
      }).catch(()=>{
      });
    },
  },
  computed:{
    isDisabled:function (){
      return this.loading
    },
    isSelectedObjectsHasTags(){
      let isSelectedObjectsHasTags = false;
      for(let key in   this.getSelectedObjects){
        let obj = this.getSelectedObjects[key]
        if(typeof obj.tags !== 'undefined' && obj.tags.length > 0){
          isSelectedObjectsHasTags = true
        }
      }
      return isSelectedObjectsHasTags;
    },
    getBody:function (){
      let body = "Вы уверены что хотите безвозвратно удалить {{items}}";

      body = replaceTextObjects(body ,  this.getSelectedObjects, 2 , "name");

      if(this.getSelectedArray.length > 2){
        let n =(this.getSelectedArray.length - 2);
        body += " и еще " + n +" тикетов";
      }

      body += "?";
      return body;
    },
    loading: function (){
      return this.$store.getters['tickets/isTicketLoading'];
    },
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      "getSelectedArray":  'tickets/getSelectedArray',
      'getSelectedObjects': 'tickets/getSelectedObjects',
    }),
  }
}
</script>

<style scoped>
</style>