<template>
  <div  v-show="countSelect > 0"  @click="clearSelect()"  class="close-select">&#10006;</div>
  <div v-show="countSelect > 0" >
    <restore-button></restore-button>
    <tags-button></tags-button>
    <remove-button></remove-button>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import RestoreButton from "./components/RestoreButton";
import RemoveButton from "./components/RemoveButton";
import TagsButton from "./components/TagsButton"

export default {
  name: "TrashMenu",
  components: {RemoveButton, RestoreButton , TagsButton},
  computed:{
    ...mapState( {
      ticketsState: state => state.tickets,
    }),
    ...mapGetters({
      'countSelect':'tickets/selectedCount'
    }),
  },
  methods:{
    ...mapMutations({
      'clearSelect':'tickets/clearSelect',
    }),
  }
}
</script>

<style scoped>
.close-select{
  cursor: pointer;
  font-size: 14px;
  padding-left: 3px;
  display: inline-flex;
}
</style>