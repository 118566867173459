<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{user}} {{eventText}}
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";

export default {
  name: "SetCompetitor",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    eventText() {
      return this.params.value ? "перенес(ла) тикет в папку Конкуренты" : "убрал(а) тикет из папки Конкуренты";
    }
  }
}
</script>

<style scoped>

</style>