

function isHas(value){
    if(typeof value === 'string'){
        value = value.trim();
    }
    return value;
}

const offerHistoryAddEditGetters = {
    getSubnetType:(state)=>{
        return state.subnetType
    },
    isAs:(state)=>{
        return state.isAs
    },
    getIsOurSeller:(state) =>{
      return state.isourSeller;
    },
    getIsOurBuyer:(state) =>{
        return state.isourBuyer;
    },
    getSeller:(state  ) =>{

        return state.historyObject.seller;
    },
    getBuyer:(state  ) =>{
        return state.historyObject.buyer;
    },
    getHistoryObject:(state) =>{
        return state.historyObject;
    },
    getDate:(state)=>{
        return state.historyObject.date;
    },
    loading:(state) =>{
      return state.loading
    },
    getLoadingNetStatus:(state)=>{
        return state.loadingNetStatus;
    },
    isDisabled:(state,getters)=>{
      let disabled = false;

      if( !isHas(state.historyObject?.buyer?.name) || ( !isHas(state.historyObject?.buyer?.email) && !getters.getIsOurBuyer )||
          !isHas(state.historyObject?.seller?.name) ||  ( !isHas(state.historyObject?.seller?.email  ) && !getters.getIsOurSeller) ||
          state.historyObject.date === ''  || (state.historyObject.sellprice.price === '' && !getters.getIsOurBuyer) ||
          state.historyObject.buyprice.price === ''
      ){
          disabled = true;
      }
      if(!disabled){
          if(getters.getSubnetType !== 'ASN'){
              let hasSubnet = false;
              for (let el of state.historyObject.subnets ){
                  if(el.subnet.trim() !== ''){
                      hasSubnet = true;
                  }
              }
              if(!hasSubnet){
                  disabled = true
              }
          }else{
              if(!isHas(state.historyObject?.asn?.name)){
                  disabled = true;
              }
          }

      }
      if(state.historyObject?.subnets?.[0]?.type === 'PA'){
         disabled = true;

         if(
             isHas(state.historyObject?.buyer?.lirid) && (
                 isHas(state.historyObject?.seller?.lirid) ||
                 isHas(state.historyObject?.seller?.org)
             )
         ) {
             disabled = false;
         }
      }

        if(state.historyObject?.subnets?.[0]?.type === 'PI' || state.historyObject?.subnets?.[0]?.type === 'ASN'){
            if( ( !isHas(state.historyObject?.buyer?.org) && !isHas(state.historyObject?.buyer?.lirid) )
                || ( !isHas(state.historyObject?.seller?.org)  && !isHas(state.historyObject?.seller?.lirid) )){
                disabled = true
            }
        }

      return disabled;

    }
}


export default offerHistoryAddEditGetters;