

export const isLoading = state => {
    return state.loading;
}

export const getKpi = state => {
    return state.kpi;
}
export const getWaitingOrders = state => {
    return state.waitingOrders;
}