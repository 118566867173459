import {OfferHistory} from "../../../../library/api/OfferHistory";

import {Utility} from "../../../../library/api/Utility";


const offerHistoryAddEditActions = {


    edit({state, getters}){
        return new Promise((resolve ,reject) =>{
            state.loading = true;
            let historyObject = Object.assign({} , state.historyObject)
            historyObject.seller.isour = getters.getIsOurSeller;
            historyObject.buyer.isour = getters.getIsOurBuyer;
            historyObject.subnets = historyObject.subnets.filter(subnet => subnet.subnet.trim() != '');
            historyObject.subnetsType = getters.getSubnetType;
            OfferHistory.editObject(historyObject._id, historyObject).then(()=> {
                resolve();
            }).catch((error)=>{
                reject(error);
            }).finally(
                ()=>{  state.loading = false;}
            )
        });
    },


    create({state, getters} ){
        return new Promise((resolve ,reject) =>{
            state.loading = true;
            let historyObject = Object.assign({} , state.historyObject)
            historyObject.seller.isour = getters.getIsOurSeller;
            historyObject.buyer.isour = getters.getIsOurBuyer;
            historyObject.subnets = historyObject.subnets.filter(subnet => subnet.subnet.trim() != '');
            historyObject.subnetsType = getters.getSubnetType;
            let creator = state.threadId === null ? OfferHistory.createObject: OfferHistory.createObjectFromThread;
            creator(historyObject, state.threadId ?? null).then((result)=> {
                    resolve(result);
                }).catch((error)=>{
                    reject(error);
                }).finally(
                    ()=>{  state.loading = false;}
                )
        });
    },

    loadSubnetStatusFromRIPE({state}, {index}){
        return new Promise((resolve, reject)=>{
            if( typeof state.historyObject.subnets[index] !== 'undefined' ) {
                state.loading = true;
                state.loadingNetStatus[index]  = true;
                state.historyObject.subnets[index].type = '';
                let lastSubnet = state.historyObject.subnets[index].subnet
                Utility.getSubnetWhois(state.historyObject.subnets[index].subnet).then((result) => {
                    if(lastSubnet == state.historyObject.subnets[index].subnet){
                        if (typeof result.data.status !== 'undefined' && ['PA', 'PI'].indexOf(result.data.status.substr(-2).toUpperCase()) !== -1) {
                            state.historyObject.subnets[index].type = result.data.status.substr(-2);
                        }
                    }


                }).finally(()=>{
                    state.loading = false;
                    state.loadingNetStatus[index]  = false;
                    resolve();
                });
            }else {
                resolve();
            }
        });

    },


    init({state , commit} , {userHistoryObject }){
        state.subnetType= 'PA'
        state.isAs = false;
        state.historyObject = {};
        if(typeof userHistoryObject !== 'undefined' && userHistoryObject !== null ){
            state.historyObject =  JSON.parse(JSON.stringify(userHistoryObject))
        }

        if(typeof  state.historyObject.subnets === 'undefined' || state.historyObject.subnets === null){
            state.historyObject.subnets = [];
        }
        if(typeof  state.historyObject?.asn?.name === 'undefined' || state.historyObject?.asn?.name === null){
            state.historyObject.asn = {
                name:''
            }
        }
        if(typeof  state.historyObject.buyer === 'undefined' || state.historyObject.buyer === null ){
            commit('resetClient' ,'buyer')
        }else {
            if(state.historyObject?.buyer?.isour){
                state.isourBuyer = state.historyObject?.buyer?.isour;
            }
        }

        if(typeof  state.historyObject.seller === 'undefined' || state.historyObject.seller === null ){
            commit('resetClient', 'seller')
        }else {
            if(state.historyObject?.seller?.isour){
                state.isourSeller = state.historyObject?.seller?.isour;
            }
        }

        if(typeof  state.historyObject.date === 'undefined' || state.historyObject.date === null ){
            state.historyObject.date = '';
        }

        if(typeof  state.historyObject.comment === 'undefined' || state.historyObject.comment === null ){
            state.historyObject.comment = '';
        }



        if(typeof  state.historyObject.sellmanager === 'undefined' || state.historyObject.sellmanager === null ){
            state.historyObject.sellmanager = {};
        }
        if(typeof  state.historyObject.buymanager === 'undefined' || state.historyObject.buymanager === null ){
            state.historyObject.buymanager = {};
        }
        if(typeof  state.historyObject.buyprice === 'undefined' || state.historyObject.buyprice === null){
            commit('resetPrice','buyprice')
        }

        if(!state.historyObject.buyprice?.comment){
            state.historyObject.buyprice.comment = '';
        }
        if(!state.historyObject.buyprice?.description){
            state.historyObject.buyprice.description = '';
        }

        if(typeof  state.historyObject.sellprice === 'undefined' || state.historyObject.sellprice === null){
           commit('resetPrice' ,'sellprice')
        }

        if(!state.historyObject.sellprice?.comment){
            state.historyObject.sellprice.comment = '';
        }
        if(!state.historyObject.sellprice?.description){
            state.historyObject.sellprice.description = '';
        }
        state.isourSeller = false;
        state.isourBuyer = false;
    }
}


export default offerHistoryAddEditActions;