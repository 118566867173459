<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      Клиент задал новый номер на аукционе: <span>"{{params.phone}}"</span>
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "AddPersonPhone",
  props: {
    event: {
      type: Object,
      require: true,
    }
  },
  setup: (props) => parseEvent(props.event),
}
</script>

<style scoped>

</style>