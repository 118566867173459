<template>
  <div class="params-item" v-if="showTags">
    <span class="me-1">Метки:</span>
    <span>
      <tags
        :tags="ticket.tags"
        :ticket="ticket"
        class="projectPaddingsOne"
      ></tags>
    </span>
  </div>
</template>

<script>
import Tags from "./Tags";

export default {
  name: "TicketTags",

  components: {
    Tags,
  },

  props: {
    ticket: {},
  },

  computed: {
    showTags() {
      return typeof this.ticket?.tags === 'object' && Array.isArray(this.ticket?.tags) && this.ticket?.tags.length > 0;
    }
  }
}
</script>

<style scoped>

</style>