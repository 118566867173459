<template>

  <div class="list-item d-flex">

    <template v-if="uploading">
      <div class="link d-flex">
        <string-trim :string="document.filename" />
      </div>
      <div class="loading">
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </div>
    </template>

    <template v-else>
      <div class="link d-flex">
        <span v-if="saved" role="button" class="link-primary text-decoration-none" @click="downloadDocument">
          <string-trim :string="document.filename" />
        </span>
        <span v-else-if="document?.file" role="button" class="text-decoration-none" @click="openPreview">
          <string-trim :string="document.filename" />
        </span>
        <span v-else>
          <string-trim :string="document.filename" />
        </span>

        <popper
          v-if="fromAuction"
          content="Документ загружен клиентом в профиле аукциона"
          class="light"
          placement="top"
          :hover="true"
          :interactive="true"
          :arrow="true"
        >
          <i class="fa fa-gavel ps-1"></i>
        </popper>
      </div>

      <div v-if="date" class="text-nowrap text-muted px-1" style="min-width: 6em;">{{date}}</div>

      <div class="text-nowrap text-end px-2" style="min-width: 5em;">
        {{prettyBytes(document.size)}}
      </div>

      <div class="remove">
            <span class="text-danger" @click="deleteDocument">
              <i class="fa fa-times"></i>
            </span>
      </div>
    </template>

  </div>

</template>

<script>
import prettyBytes from "pretty-bytes";
import StringTrim from "../../../string/StringTrim.vue";

export default {
  name: "Document",
  components: {StringTrim},

  props: {
    document: Object,
    ticketId: {},
    saved: Boolean,
  },

  computed: {
    date() {
      return this.document?.date ? moment(this.document.date).format('YYYY-MM-DD') : null;
    },
    uploading() {
      return typeof this.document.id === 'undefined';
    },
    fromAuction() {
      return typeof this.document?.externalid !== 'undefined' && this.document?.externalid;
    },
  },

  methods: {
    prettyBytes,

    downloadDocument() {
      window.open(`/tickets/${this.ticketId}/person/document/${window.getId(this.document.id)}/`, 'download');
    },

    openPreview() {
      if (typeof this.document?.file === 'object' && this.document?.file !== null) {
        window.open(this.generatePreview(this.document.file), 'download');
      }
    },

    deleteDocument() {
      this.$emit('delete');
    },

    generatePreview(file) {
      let fileSrc = URL.createObjectURL(file);

      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1500);
      return fileSrc;
    },
  }
}
</script>

<style scoped>
.list-item .link {
  width: 90%;
}
.list-item .remove {
  cursor: pointer;
}
.list-item .loading {
  width: 15%;
  text-align: right;
}
</style>