
export function showAbsoluteInput(el, parent = $(el).parent()) {
    if(el instanceof jQuery) {
        el = el[0];
    }

    parent = $(parent);
    let windowWidth = document.documentElement.clientWidth
    let pxFromRightEdge = 8;

    let pos = el.getBoundingClientRect();
    let parentPos = $(el).parent()[0].getBoundingClientRect();
    let parentMiddleLeft = $(el).position().left
                           - (($(el).outerWidth() - parent.outerWidth()) / 2);
    let top = parentPos.y + window.pageYOffset;

    $(el).addClass('fixed-editable');
    $(el).css({left: parentMiddleLeft});

    if (( pos.x + $(el).outerWidth() ) > windowWidth) {
        $(el).css({
            top: top,
            left: windowWidth
                  - $(el).outerWidth()
                  + document.documentElement.scrollLeft });
    }

    if(( $(el).offset().left + $(el).outerWidth() ) >= windowWidth) {
        $(el).css({
            top: top,
            left: $(el).position().left - pxFromRightEdge
        });
    }

    if($(el).css('position') === 'fixed') {
        setScrollWatchAndObserver(el);
    }
}

function getScrollParent(element) {
    let style = getComputedStyle(element);
    let overflowRegex = /(auto|scroll)/;
    let scrollParent = document.body;

    for (let parent = element; (parent = parent.parentElement);) {
        style = getComputedStyle(parent);

        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
            scrollParent = parent;
            break;
        }
    }

    return scrollParent;
}

function setScrollWatchAndObserver(el) {
    let scrollParent = getScrollParent(el);
    let scrollPosLast = {top: $(scrollParent).scrollTop(), left: $(scrollParent).scrollLeft()};

    $(scrollParent).on('scroll.inputAbsolute', () => {
        let top = $(scrollParent).scrollTop();
        let left = $(scrollParent).scrollLeft();

        $(el).css({
            top: Number.parseFloat($(el).css('top')) + (scrollPosLast.top - top),
            left: Number.parseFloat($(el).css('left')) + (scrollPosLast.left - left)
        });

        scrollPosLast = {top: top, left: left};
    });

    let observer = new MutationObserver((e) => {
        if($(el).is(e)) {
            $(scrollParent).off('scroll.inputAbsolute');
            observer.disconnect();
        }
    });
    observer.observe($(el).parent()[0], {childList: true});
}