<template>
  <div>
    <form method="post" @submit.prevent="onSubmit()" >
      <div class="form-group row">
        <label for="input-name" class="col-sm-2 col-form-label">Название</label>
        <div class="col-sm-10" style="margin-top: 5px;">
          <input type="text" class="form-control form-control-sm"  v-model="template.name" id="input-name"  placeholder="Введите имя шаблона">
        </div>
      </div>
      <div class="form-group row" v-if="!isEdit">
        <label for="project" class="col-sm-2 form-label">Проект</label>
        <div class="col-sm-10">
          <select   v-model="template.project"  id="project" class="form-select form-select-sm form-style" >
            <option disabled :value="''">Выберите проект</option>
            <option  v-for="project in projects" :value="project.value" >
              {{ project.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group row">
        <groups :template="template"></groups>
      </div>
      <div class="form-group row">
        <relations :project="getProject()"  :template="template"></relations>
      </div>
      <div class="form-group row" v-if="isEdit">
        <label for="input-usage-counter" class="col-sm-2 col-form-label">Популярность</label>
        <div class="col-sm-10" style="margin-top: 5px;">
          <input type="number" class="form-control form-control-sm" v-model="template.usage_counter" id="input-usage-counter">
        </div>
      </div>
      <div class="mb-3">
        <label for="textarea-body" class="form-label">Шаблон</label>
        <expandable-text-area :aria-class="{'form-control':true}" id="textarea-body" v-model="template.body"  ></expandable-text-area>
      </div>
      <div v-if="!showEng" style=" margin-bottom: 20px; text-align: end;">
        <a @click="show()"  style="color: blue; cursor: pointer;">Добавить вариант на английском</a>
      </div>
      <div v-if="showEng" class="mb-3"  >
        <label for="textarea-body-en" class="form-label">Шаблон (EN)</label>
        <expandable-text-area :aria-class="{'form-control':true}" id="textarea-body-en" v-model="template.body_en"  ></expandable-text-area>
      </div>
      <div class="mb-3" >
        <label for="comment" class="form-label">Комментарий</label>
        <mde-text-area id="comment" v-model="template.comment" ref="comment"  ></mde-text-area>
      </div>
      <div class="col-12" style="margin-top: 30px;">
        <a class="btn btn-secondary col-6" @click="back()" >Назад</a>
        <button type="submit" class="btn btn-primary col-6" :disabled="isDisable" id="form-submit-btn" >Сохранить</button>
      </div>
    </form>
  </div>
  <alert-list />
</template>

<script>
import Simplemde from "./source/Simplemde";
import {CrmApi} from "../../library/CrmApi";
import MdeTextArea from "./source/MdeTextArea";
import TemplateFormGroup from "./TemplateFormGroup";
import Groups from "./source/Groups";
import Relations from "./source/Relations";
import ExpandableTextArea from "../common/textarea/ExpandableTextArea";
import AlertList from "../ticket/Alert/AlertList";

export default {
  name: "TemplateEditNew",
  components: {
    ExpandableTextArea,
    Relations,
    Groups,
    TemplateFormGroup,
    MdeTextArea,
    Simplemde,
    AlertList,
  },
  data:function (){
    return {
      content:"",
      template:{
        name:"",
        body:"",
        body_en:"",
        project:"",
        group:"",
        comment:"",
        relations:[],
      },
      initialUsageCounter: null,
      showEng:true,
      projects:[],
      configs:{
      },
      loading: false,
      usage_counter: '',
    }
  },
  methods:{
    show:function (){
      this.showEng = !this.showEng

    },
    back:function (){
      window.location.replace( window.location.origin + "/templates/")
    },
    getProject(){
      return this.templateEdit ? this.templateEdit.project : null;
    },
    onSubmit: function () {
      this.loading = true;
      let promise;
      if (this.templateEdit === null) {
        promise = CrmApi.createTemplate(this.template).then((result) => {
          window.location.href = '/templates/';
        });
      } else {
        let object = Object.assign({},this.template);
        if( object.usage_counter == this.initialUsageCounter ){
          delete object.usage_counter;
        }
        promise = CrmApi.editTemplate(object).then(() => {
          this.$store.commit('successPush', 'Шаблон обновлен');
        });
      }
      promise
          .catch(error => { this.$store.commit('errorPush', CrmApi.getErrorMessage(error)); })
          .finally(() => { this.loading = false; });
    },

  },

  computed:{
    isDisable:function (){
      return this.loading ||
          this.template.name === '' ||
          (this.template.body === '' && this.template.body_en === ''  ) ||
          (this.isNew && this.template.project === '');
    },
    isEdit(){
      return this.templateEdit !== null
    },
    isNew(){
      return this.templateEdit === null
    }
  },
  props:{
    'templateEdit':{
      type:Object,
      default: null,
    },
    'allowedProjects':{
      type:Array,
      default: [],
    },
  },
  created() {
    for (let el of this.allowedProjects){
      this.projects.push({
        value:el._id,
        label:el.name,
      });
    }

    if(this.templateEdit !== null){
      this.template = Object.assign(this.template , this.templateEdit);
      if(typeof this.template.comment === 'undefined' || this.template.comment === null){
        this.template.comment ="";
      }
      if(typeof this.template.name === 'undefined' || this.template.name === null){
        this.template.name = "";
      }
      if(typeof this.template.body === 'undefined' || this.template.body === null){
        this.template.body = "";
      }
      if(typeof this.template.body_en === 'undefined' || this.template.body_en === null){
        this.template.body_en = "";
      }
      if(typeof this.template.group === 'undefined' || this.template.group === null){
        this.template.group = "";
      }
      if(typeof this.template.usage_counter === 'undefined' || this.template.usage_counter === null){
        this.template.usage_counter = 0;
      }
      this.initialUsageCounter = this.template.usage_counter;
    }
    if(this.template.body_en == '' || this.template.body_en == null){
      this.showEng = false;
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
  .row{
    --bs-gutter-x: 0rem;
  }
  @import '~simplemde/dist/simplemde.min.css';
</style>