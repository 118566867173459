<template>
  <input v-if="keyword.type === 'date'" type="text" v-model="keyword.dateformat" class="form-control" placeholder="Формат даты" />
</template>

<script>
export default {
  name: "Date",

  props: {
    keyword: Object
  }

}
</script>

<style scoped>

</style>