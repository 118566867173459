



const offerFiltersMutations = {
    setSearchString(state , text){
        state.searchString = text;
        state.query.search = text;
    },
    clearFilters(state ){
        state.searchString = '';
        delete state.query.search;
    },
    setShowFilter(state, value) {
        state.showFilter = value;
    },
    setOrderBy(state, fieldName) {
        if(typeof state.query?.oB?.f !== 'undefined' && state.query.oB.f === fieldName) {
            state.query.oB.v = -state.query.oB.v;
        } else {
            state.query.oB = {
                f: fieldName,
                v: -1,
            };
        }
    },
    setFilterValue(state, obj) {
        if (state.filters.find(el => el.field === obj.field)) {
            state.filters.find(el => el.field === obj.field).value = obj.value;
        }

        if(obj.value && obj.value.length) {
            state.query[obj.field] = obj.value;
        } else {
            delete state.query[obj.field];
        }

    },
    clearFilterValues(state) {
        state.filters.forEach((filter) => filter.value = '');
    }
}


export default offerFiltersMutations;