<template>
  <input
    type="text"
    class="form-control"
    :class="this.class"
    :style="style"
    :value="modelValue"
    :placeholder="placeholder"
    @input="inputHandler($event.target.value)"
    @keyup.enter.stop="$emit('enter')"
    ref="phone"
    maxlength="50"
    :disabled="disabled"
  />
</template>

<script>
import {Phone} from "../../../library/Phone";
import {showAbsoluteInput} from "../../Editable/InputAbsolute";

export default {
  name: "Phone",
  props: {
    'modelValue':{
      type:String,
      require:true,
    },
    'selectOnMount':{
      type:Boolean,
      default:false,
    },
    'type': {
      type: String,
      default: 'both',
    },
    'disabled':{
      type:Boolean,
      default:false,
    },
    'placeholder': {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
    },
    inputStyle: {
      type: String,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
    absoluteParent: {
      default: null,
    },
    dynamicAbsoluteWidth: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['update:modelValue', 'enter'],

  data:function (){
    return {
      phoneFormatter:true,

      inputWidth: 260,
      dynamicSymbolPx: 8.75,
      dynamicMaxWidth: 500,
    }
  },
  mounted() {
    if(this.selectOnMount){
      this.$refs.phone.focus();
      this.$refs.phone.select();
    }
    this.$nextTick(() => {
      if(this.absolute) {
        this.setAbsolute();
      }
    })
  },

  computed: {
    typePhone: function () {
      return this.type === 'phone' || this.type === 'both';
    },
    typeLogin: function () {
      return this.type === 'login' || this.type === 'both';
    },
    class() {
      let c = this.inputClass;
      if(this.absolute) {
        c += ' absolute';
      }
      return c;
    },
    style() {
      let style = this.inputStyle ?? '';
      if(this.absolute) {
        if(typeof style === 'string') {
          style += ' width: ' + this.absoluteWidth + 'px !important;';
        }
      }

      return style;
    },
    absoluteWidth() {
      let width = this.inputWidth;
      if(this.dynamicAbsoluteWidth && this.dynamicWidth > width) {
        width = this.dynamicWidth;
      }

      return width;
    },
    dynamicWidth() {
      let dynamicWidth = Math.round(this.modelValue.length * this.dynamicSymbolPx);
      if(dynamicWidth > this.dynamicMaxWidth) {
        dynamicWidth = this.dynamicMaxWidth;
      }

      return dynamicWidth;
    }
  },
  methods:{
    focus(){
      this.$refs.phone.focus();
    },
    select(){
      this.$refs.phone.select();
    },
    inputHandler(valueEvent){
      let value = this.preparePhone(valueEvent);
      this.$emit('update:modelValue', value)
      this.$refs.phone.value = value;  // исправляет проблему не обновления input при paste
    },

    preparePhone(phone){
      let newPhone = phone;
      if (this.typeLogin) {
        if (this.isNickname(phone)) {
          return newPhone[0] === "@" ? newPhone : "@" + newPhone;
        }
      }
      if (this.typePhone) {
        newPhone = Phone.makePretty(phone);
      }

      return newPhone;
    },

    isNickname(phone){
      return phone.charAt(0) === '@' || phone.match(/^\s*[0-9a-zA-Zа-яА-ЯёЁ]*[a-zA-Zа-яА-ЯёЁ]+[0-9a-zA-Zа-яА-ЯёЁ]*/) !== null;
    },

    setAbsolute() {
      let parent = this.absoluteParent === null ? $(this.$refs.editInput).parent() : this.absoluteParent;
      showAbsoluteInput(this.$refs.phone, parent);
    }
  }
}
</script>

<style scoped>
.absolute {
  position: fixed;
}
</style>