<template>
  <div class="event-block">
    <div class="event-date">{{date}}</div>
    <div class="event-message">
      {{ filterLabel }}
      <template v-for="filter of filters">
        <a v-if="filter.url" :href="filter.url" target="_blank" class="filter-name"><string-trim :string="filter.name" /></a>
        <span v-else class="filter-name"><string-trim :string="filter.name" /></span>
      </template>
      {{ filterAction }} сообщение в Рассылки
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "./EventParser";
import StringTrim from "../../string/StringTrim";
import {getFilterLink} from "../../../library/Locations";

export default {
  name: "AddToNotificationsByFilter",

  components: {
    StringTrim,
  },

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    filters() {
      return typeof this.event?.params?.filters === 'object' && Array.isArray(this.event?.params?.filters) ?
          this.event?.params?.filters.map(filter => {
            return {
              url: filter?._id ? getFilterLink(window.getId(filter?._id)) : null,
              name: typeof filter?.name === 'string' && filter?.name.trim() !== '' ? filter?.name : '—',
            }
          }) : [];
    },
    filterLabel() {
      return this.filters.length > 1 ? 'Фильтры' : 'Фильтр';
    },
    filterAction() {
      return this.filters.length > 1 ? 'переместили' : 'переместил';
    },
  }
}
</script>

<style scoped>
.filter-name {
  max-width: 150px;
  display: inline-block;
  white-space: nowrap;
}
</style>