<template>
  <div class="event-block">
    <div class="event-date">{{ date }}</div>
    <div class="event-message">
      Клиент <a :href="buyerLink" target="_blank">{{params.buyer.name}}</a> сделал ставку на сеть {{params.subnet}} {{params.price1usd}} $ / IP
    </div>
    <div class="right-offset"></div>
  </div>
</template>

<script>
import parseEvent from "../EventParser";

export default {
  name: "BuyerMadeBet",

  props: {
    event: {
      type: Object,
      require: true,
    }
  },

  setup: (props) => parseEvent(props.event),

  computed: {
    buyerLink() {
      return '/tickets/' + window.getId(this.params.buyer._id) + '/';
    }
  }
}
</script>

<style scoped>

</style>