<template>
  <button
      v-if="showButtons"
      @click="openModal"
      class="btn btn-light btn-outline-dark col-12"
  >
    <i class="fa button-icon menu-button fa-plus"></i> Переоткрыть
  </button>
  <reopen-modal
      v-if="showModal"
      @close="closeModal"
      @success="reopenTickets"
  ></reopen-modal>
</template>

<script>
import {Ticket} from "../../../library/api/Ticket";
import {mapActions} from "vuex";
import ReopenModal from "./ReopenModal";

export default {
  name: "ReopenButton",

  components: {
    ReopenModal,
  },

  data() {
    return {
      loading: false,
      showModal: false,
    }
  },

  computed: {
    showButtons() {
      return Object.keys(this.selectedObjects).length > 0;
    },

    selectedObjects() {
      return this.$store.getters['tickets/getSelectedObjects'];
    },
  },

  methods: {
    openModal() {
      this.showModal = true;
      this.$store.commit('setActiveWindow', 'reopenTicket');
    },
    closeModal() {
      this.showModal = false;
      this.$store.commit('removeActiveWindow', 'reopenTicket');
    },
    reopenTickets() {
      this.loading = true;
      let ids = Object.keys(this.selectedObjects);
      Ticket.reopenTickets(ids).then(() => {
        Object.values(this.selectedObjects).forEach(ticket => {
          ticket.closed = false;
          ticket.open = true;
        });
        this.$store.commit('successPush', ids.length === 1
            ? 'Тикет переоткрыт'
            : 'Тикеты переоткрыты')

        if (this.$route?.name !== 'Ticket') {
          this.reloadList();
        }
      }).finally(() => this.loading = false);
    },

    reloadList() {
      if(['answered', 'closed', 'new'].includes(this.$route?.params?.filter)) {
        this.$store.dispatch('tickets/getAllTickets', this.$route);
      }
    },

    ...mapActions({
      "redirectToTickets": "thread/redirectToTickets",
    })
  },
}
</script>

<style scoped>

</style>