import vueDelete from './vue-delete'

let isEventAdd = false;
const plagin = {
    install (Vue) {
        Vue.directive('delete', {
            mounted(el, binding){
                vueDelete.update(el , binding)
            },
            beforeUnmount(el, binding) {
                vueDelete.unbind(el , binding)
            },
        });
        if(!isEventAdd){
            isEventAdd = true;
            document.addEventListener('keyup',  (event)=>{vueDelete.onEvent(event)})
            document.addEventListener('keydown',  (event) =>{vueDelete.downEven(event)})
        }
    }
}
export default plagin