<template>
  <div>
    <entity-list new-link="/filter/new">
      <entity-table
          settings-name="filters"
          :get-entity-list="getFilterList"
          :show-edit="showEdit"
          :columns="columns"
          :actions="actions"
          :do-reload-page="reloadPage"
          @showDeleteModalWindow="showDeleteModalWindow"
      >
        <template v-slot:[`cell.name`]="{ item }">
          <popper class="light" placement="top" :hover="true" :interactive="false" :arrow="true">
            <div v-if="item?.name">
              {{ item.name }}
            </div>
            <div v-else class="trimmed-text-container">
              <span class="trimmed-text" v-html="getConditionsString(item)"></span>
            </div>
            <template #content>
              <span v-html="getConditionsString(item, '<br>')"></span>
            </template>
          </popper>
          <div>
            <string-trim :string="getActionsString(item)" style="font-size: 12px;" />
          </div>
        </template>
      </entity-table>
    </entity-list>
    <modal-window v-if="showDeleteModal" @close="showDeleteModal = false"  @success="deleteFilter" ok-button-style="btn-danger" >
      <template v-slot:header>
        <h5 class="modal-title text-danger" >Удаление фильтра</h5>
      </template>
      <template v-slot:body>
        <span>Вы действительно хотите навсегда удалить фильтр {{filterToDelete.name}}?</span>
      </template>
    </modal-window>
  </div>

</template>

<script>
import EntityList from "../common/EntityList";
import EntityTable from "../common/EntityTable";
import {CrmApi} from "../../library/CrmApi";
import ModalWindow from "../tiketMenu/components/ModalWindow";
import StringTrim from "../string/StringTrim";
import DOMPurify from "dompurify";
import {mapGetters} from "vuex";
export default {
  name: "FilterList",
  components: {StringTrim, EntityTable, EntityList , ModalWindow},
  data: function (){
    return {
      reloadPage:{
        value: false
      },
      showDeleteModal:false,
      filterToDelete:{},
      columns: [
        {
          name: "Название",
          value: "name",
          width: 60,
        },
        {
          name: "Автор",
          getter: function (filter) {
            let name;
            let author = filter.author;
            if(
                typeof author.name !== 'undefined' &&
                author.name.trim() !== ''
            ){
              name =  author.name;
            }else {
              name = author.login;
            }
            return name;
          },
          width:30
        }
      ],
      actions: [
        {
          name: "Удалить",
          handler: function (filter){
            this.$emit('showDeleteModalWindow' , filter);
          },
          class: "link-danger"
        }
      ],
      projects: {},
    };
  },
  computed:{
    ...mapGetters({
      'getUserSettings':'getUserSettings'
    })
  },
  methods: {

    deleteFilter(){
      CrmApi.deleteFilters([ this.filterToDelete._id])
          .finally(()=>{
            this.showDeleteModal = false;
            this.filterToDelete = null;
            this.reloadPage.value = true;
          });
    },

    showDeleteModalWindow(filter){
      this.filterToDelete = filter;
      this.showDeleteModal = true;
    },
    showEdit:function( filter ){
      window.location.href = window.location.origin + "/filter/" + encodeURIComponent(filter._id);
    },
    getFilterList: function (query){
      if(this.getUserSettings?.filters?.onpage && !query?.onpage){
        query.onpage = this.getUserSettings.filters.onpage
      }
      return CrmApi.getFiltersList(query);
    },

    getSingleConditionString(condition) {
      let result = '';
      let targetMap = {
        "headers.subject": "Тема",
        "body": "Тело",
        "headers.from": "От",
        "headers.to": "Кому",
        "headers": "Заголовки",
      }
      let typeMap = {
        "~": "содержит",
        "!~": "не содержит",
        "=": "совпадает с",
        "!=": "не совпадает с",
        "^~": "начинается с",
        "~^": "заканчивается на",
      }
      if (typeof targetMap[condition?.target] === 'string') {
        result += `<b>${targetMap[condition?.target]}</b> `;
      }
      if (typeof typeMap[condition?.type] === 'string') {
        result += typeMap[condition?.type] + ' ';
      }
      if (typeof condition?.value === 'string') {
        result += '"' + DOMPurify.sanitize(condition.value) + '"';
      }
      return result.trim();
    },

    getConditionsString(filter, glue = null) {
      let result = '';
      if (typeof filter === 'object' && filter) {
        if (typeof filter?.conditions !== 'undefined' && Array.isArray(filter?.conditions)) {
          let subGlue = (filter.type === 'and' ? ' И ' : ' ИЛИ ');
          glue = glue ? subGlue + glue : subGlue;
          result = filter.conditions.map(this.getSingleConditionString).join(glue);
        }
      }
      return result;
    },

    getSingleActionString(action) {
      let result = '';
      let params = null;
      let values = '';
      if (typeof action?.type !== 'undefined') {
        if (typeof action?.params !== 'undefined' && Array.isArray(action.params)) {
          params = action?.params;
          if (action.type === 'mark') {
            values = params.map((item) => item?.value).filter(i => !!i).map(item => '"' + item + '"').join(', ');
          } else {
            values = params.map((item) => item?.value).filter(i => !!i).map(item => '"' + this.getProjectName(item) + '"').join(', ');
          }
        }
        switch (action.type) {
          case 'favourites':
            result = 'Добавить в Избранное';
            break;
          case 'delete':
            result = 'Переместить в Удаленные';
            break;
          case 'mark':
            result = 'Добавить ';
            if (params) {
              result += params.length <= 1 ? 'метку ' : 'метки ';
              result += values;
            }
            break;
          case 'transfer':
            result = 'Перенести в проект ';
            result += values;
            break;
          case 'assign':
            result = 'Подключить к ';
            if (params) {
              result += params.length <= 1 ? 'проекту ' : 'проектам ';
              result += values;
            }
            break;
          case 'notifications':
            result = 'Переместить в Рассылки для ';
            if (params) {
              result += params.length <= 1 ? 'проекта ' : 'проектов ';
              result += values;
            }
            break;
          default:
            result = 'Неизвестное действие';
        }
      }
      return result.trim();
    },

    getProjectName(id) {
      let result = window.getId(id);
      if (typeof this.projects[window.getId(id)] === 'string') {
        result = this.projects[window.getId(id)];
      }
      return result;
    },

    getActionsString(filter) {
      let result = 'Пусто';
      if (typeof filter === 'object' && filter) {
        if (typeof filter?.actions !== 'undefined' && Array.isArray(filter?.actions)) {
          let glue = '; ';
          result = filter?.actions.map(this.getSingleActionString).join(glue);
        }
      }
      return result;
    },
  },

  created() {
    CrmApi.getProjectsNames().then(result => {
      if (typeof result?.data !== 'undefined' && result.data && Array.isArray(result.data) && result.data.length) {
        result.data.forEach((item) => {
          this.projects[window.getId(item._id)] = item.name;
        });
      }
    }).catch((error) => {});
  },
}
</script>

<style scoped>
.trimmed-text-container {
  display: grid;
}
.trimmed-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>