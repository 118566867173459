<template>
  <tr>
    <th @click="orderBy('updated')"  style=" cursor: pointer">Дата</th>
    <th @click="orderBy('name')" >Тема</th>
    <th class="text-center" >Абонент</th>
    <th class="text-center" style="width: 120px;">Тип</th>
    <th v-if="$route.params.project === 'all'" @click="orderBy('project.name')" class="text-center" style="cursor: pointer; width: 160px;">Проект</th>
  </tr>
</template>

<script>
export default {
  name: "TableHeadRowsCalls",

  props: {
    "orderBy": {
      type: Function,
      require: true,
    }
  }
}
</script>

<style scoped>

</style>