<template>
  <div class="pt-4 col-md-10 m-auto">
    <form @submit.prevent="calc" v-if="!onlyKpi">
      <div class="d-flex justify-content-center mb-3">
        <div class="me-2">
          <select v-model="selectedType" class="form-select" name="month" id="input-type" @change="calc">
            <option value="tickets">Тикеты</option>
            <option value="calls">Звонки</option>
            <option value="kpi">KPI</option>
          </select>
        </div>
        <div  v-if="historystat_full && selectedType === 'kpi'" class="me-2" >
          <select v-model="selectedYear" class="form-select" name="month" id="input-kpi-year" @change="calc">
            <option :value="currentYear">{{currentYear}}</option>
            <option v-for="year in years" :value="year">{{ year }}</option>
          </select>
        </div>
        <div v-if="selectedType !== 'kpi'" class="me-2">
          <select v-model="selectedMonth" class="form-select" name="month" id="input-month" @change="calc">
            <option :value="null">Текущий месяц</option>
            <option v-for="month in months" :value="month.value">{{ month.name }}</option>
          </select>
        </div>
        <div v-if="selectedType !== 'kpi'"  class="">
          <select v-model="selectedProject" class="form-select" name="project" id="input-project" @change="calc">
            <option :value="null">Все проекты</option>
            <option v-for="project in projects" :value="project._id">{{ project.name }}</option>
          </select>
        </div>
      </div>
    </form>
    <loading-list v-if="loading"></loading-list>
    <div v-if="selectedType === 'kpi'" >
      <kpi-table :selected-year="selectedYear"></kpi-table>
      <div v-if="getKpiValue.isAdmin" style="text-align: center;">Global (avg): <span v-if="hilcoAvgLess17" title="/17 и менее за 3М (ALL)">{{hilcoAvgLess17}}</span> /
        Our (avg): <span title="PA за 3М" >{{ourAvg}}</span></div>
    </div>
    <div v-if="data.length > 0 && selectedType !== 'kpi'">
      <template v-for="item in data">
        <statistic-table
          v-if="selectedType === 'tickets'"
          :period="item?.period"
          :project="selectedProjectName"
          :data="item?.data"
        ></statistic-table>
        <statistic-table-calls
          v-if="selectedType === 'calls'"
          :period="item?.period"
          :project="selectedProjectName"
          :data="item?.data"
        ></statistic-table-calls>
      </template>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import {CrmApi} from "../../library/CrmApi";
import StatisticTable from "./StatisticTable";
import LoadingList from "../ticket/List/LoadingList";
import StatisticTableCalls from "./StatisticTableCalls";
import {mapActions, mapGetters} from "vuex";
import KpiTable from "./Kpi/KpiTable";

import {PricePrettyView} from "../../library/PricePrettyView";
import {UserPermission} from "../../users/UserPermission";

export default {
  name: "Statistic",
  components: {
    KpiTable,
    StatisticTableCalls,
    StatisticTable,
    LoadingList,
  },

  props: {
    projects: {
      type: Array,
      default: [],
    },
    onlyKpi: {
      type: Boolean,
      default: false
    }
  },

  data: function () {
    return {
      data: [],
      months: [],
      selectedMonth: null,
      selectedProject: null,
      selectedYear:null,
      selectedType: 'tickets',
      loading: 0,
      historystat_full:false,
    }
  },

  computed: {
    currentYear(){
      return  moment().format('YYYY');
    },
    years(){
      let years = [];
      let currentYear = this.currentYear;

      do{
        currentYear--;
        years.push(currentYear);
      }while (currentYear > 2018);
      return years;
    },


    ...mapGetters({
      'getKpiValue':'statistic/getKpi'
    }),
    hilcoAvgAll(){
      if(!this.getKpiValue?.hilco?.avg?.all){
        return  '';
      }
      return this.preparePrice(this.getKpiValue?.hilco?.avg?.all);
    },
    hilcoAvgLess17(){
      if(!this.getKpiValue?.hilco?.avg?.less17){
        return  '';
      }
      return this.preparePrice(this.getKpiValue?.hilco?.avg?.less17);
    },
    ourAvg(){
      if(!this.getKpiValue?.our?.usdAvgForOneIPsellprice){
        return '';
      }
      return this.preparePrice(this.getKpiValue?.our?.usdAvgForOneIPsellprice);
    },
    period() {
      let period = [];
      if (this.selectedMonth) {
        period.push(this.periodFromMonth(this.selectedMonth));
      } else {
        period.push({
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().add(1,"day").format('YYYY-MM-DD'),
        });
      }
      return period;
    },

    selectedProjectName() {
      let result = 'all';
      if (this.selectedProject) {
        let selectedProjectObject = this.projects.find(project => window.getId(project._id) === this.selectedProject);
        if (selectedProjectObject) {
          result = selectedProjectObject.name;
        }
      }
      return result;
    },
  },
  created() {
    this.historystat_full = false;
    UserPermission.can('system.historystat_full').then(() => {
      this.historystat_full = true;
    }).catch(() => {});
  },
  methods: {
    preparePrice(price, currencyToShow = 'USD' , precision = 2){
      let pr =  new PricePrettyView(price, currencyToShow)
      return  pr.getPrettyPrice('', true, precision, currencyToShow !== 'USD' , " " );
    },
    ...mapActions({
      'getKpi':'statistic/getKpi'
    }),
    calc() {
      let query = {}
      if(this.selectedType === 'kpi' && this.selectedYear){
        query.year = this.selectedYear;
      }
      if(this.selectedType !== 'kpi'){
        if (this.selectedMonth) {
          query.from = this.selectedMonth;
        }
        if (this.selectedProject) {
          query.project = this.selectedProject;
        }
      }

      if (this.selectedType) {
        query.type = this.selectedType;
      }
      this.$router.replace({
        path: this.$route.path,
        query: query,
      });
      if(this.selectedType !== 'kpi'){
        this.receiveData();
      }else {
        this.loading++;
        this.getKpi({year:this.selectedYear}).finally(()=>{
          this.loading--;
        })
      }

    },
    periodFromMonth(date, format = 'YYYY-MM-DD') {
      return {
        from: moment(date).startOf('month').format(format),
        to: moment(date).endOf('month').add(1,"day").format(format),
      }
    },
    receiveData() {
      this.data = [];
      this.period.forEach((period, index) => {
        this.loading++;
        CrmApi.getStatistic({period: period, project: this.selectedProject, type: this.selectedType}).then((result) => {
          if (typeof result.data.data != 'undefined') {
            this.data[index] = {
              data: result.data.data,
              period: period,
            }
          }
        }).catch((error => {
          this.$store.commit('errorPush', CrmApi.getErrorMessage(error));
        })).finally(() => {
          this.loading--;
        })
      });
    }
  },

  mounted() {
    if(this.onlyKpi ){
      this.$router.replace({
        path: this.$route.path,
        query: {
          type:"kpi"
        },
      });
    }
    for (let i = 1; i <= 12; i++) {
      this.months.push({
        value: moment().subtract(i, 'months').format('YYYY-MM'),
        name: moment().subtract(i, 'months').format('MMMM YYYY'),
      });
    }
    this.$router.isReady().then(() => {
      this.selectedYear = this.currentYear;
      if (typeof this.$route.query.type !== 'undefined' && this.$route.query.type) {
        this.selectedType = this.$route.query.type;
      }
      if(this.selectedType === 'kpi'){
        if (typeof this.$route.query.year !== 'undefined' && this.$route.query.year) {
          this.selectedYear = this.$route.query.year;
        }
      }else {
        if (typeof this.$route.query.from !== 'undefined' && this.$route.query.from) {
          this.selectedMonth = moment(this.$route.query.from).format('YYYY-MM');
        }
        if (typeof this.$route.query.project !== 'undefined' && this.$route.query.project) {
          this.selectedProject = this.$route.query.project;
        }
      }

      this.calc();
    }).catch(() => {});
  }
}
</script>

<style scoped>

</style>