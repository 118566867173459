<template>

  <div class="row mb-2">
    <label class="form-label" :for="inputId" ref="label">
      {{param.label ?? param.key}}
      <span v-if="isRequired" class="required-field">*</span>
    </label>
    <div class="d-flex align-items-center">
      <vue-date-picker
          v-model="date"
          @update:modelValue="update"
          :input-class-name="'form-control' + (!valid ? ' is-invalid' : '')"
          :format="pickerDateformat"
          :text-input="true"
          :auto-apply="true"
          :month-change-on-scroll="false"
          :enable-time-picker="false"
          :teleport="true" />
    </div>
  </div>

</template>

<script>
import '@vuepic/vue-datepicker/dist/main.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import Datepicker from "vue3-datepicker";
export default {
  name: "DateParam",

  components: {Datepicker, VueDatePicker},

  emits: ['update'],

  props: {
    param: Object,
  },

  data() {
    return {
      text: '',
      date: null,
      valid: true,
      inputId: 'input-' + String(Math.random()).substr(2),
    }
  },

  computed: {
    pickerDateformat() {
      return this.param.dateformat.replace(/D/g, 'd')
                                  .replace(/Y/g, 'y');
    },

    isRequired() {
      return this.param?.required === true || typeof this.param?.required !== 'boolean';
    },
  },

  methods: {
    update(date) {
      if(date instanceof Date) {
        this.text = moment(date).format(this.param.dateformat);
      } else {
        this.text = '';
      }

      this.$emit('update', this.text);
      this.isValid();
    },

    isValid() {
      this.valid = (this.param?.required === false || ![null, ''].includes(this.text));
      return this.valid;
    },

    focus() {
      this.$refs.label.focus();
    }
  },
}
</script>


<style scoped>
:deep(.dp__input.is-invalid:hover) {
  border-color: #dc3545;
}
</style>