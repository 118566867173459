





const offerEventsGetters = {
    getFilters:(state  , getters , rootState , rootGetter) =>{
        return rootGetter.getRoute.query;
    },
    getEvents:(state ) => {
        return state.events.list;
    },

    getLoading:(state)=>{
        return state.loading;
    },
    getPaginate:(state) => {
        return state.paginator;
    },


    getListByRow:(state) =>{
        return state.events.listByRow
    }
};

export default offerEventsGetters ;
