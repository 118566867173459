import {getUniqueId} from "../../../library/ID";

export function prepareStep(step){
    if(typeof step.uid === 'undefined'){
        step.uid = getUniqueId(true);
    }
    if( typeof step.hint === 'undefined' || step.hint === null){
        step.hint = "";
    }
    if( typeof step.templates === 'undefined' || step.templates === null){
        step.templates = [];
    }
    if( typeof step.hint === 'undefined' || step.hint === null){
        step.hint = "";
    }
    if( typeof step.templates === 'undefined' || step.templates === null){
        step.templates = [];
    }
}
